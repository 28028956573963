import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import './WidgetLayout.style.css';
import {
    TileLayout,
    TileLayoutItem,
    TilePosition,
    TileLayoutRepositionEvent,
} from '@progress/kendo-react-layout';

import { setWidgetLayout, getLocalStorageValue } from 'src/utilis/utilis';
import { useLocation } from 'react-router-dom';
import { StorageKeys } from 'src/types/global';

import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import WidgetsFeatureHeader from './WidgetsFeatureHeader/WidgetsFeatureHeader';
import { tileLayoutStyle } from './styles';
import { StyledWidgetLayout } from './WidgetLayout.style';

export interface IWidgetLayout {
    tiles: Array<TileLayoutItem>;
    dTilePosition?: Array<TilePosition>;
}

const WidgetLayout: FunctionComponent<IWidgetLayout> = ({ tiles, dTilePosition }: IWidgetLayout) => {
    // const { t } = useTranslation();
    const location = useLocation();

    const maxRows = 28;
    const minRows = 8;

    const defaultTilePosition = dTilePosition || [
        {
            col: 1,
            colSpan: 1,
            rowSpan: 18,
        },
        {
            col: 1,
            colSpan: 1,
            rowSpan: 10,
        },
    ];

    const [tilePosition, setTilePosition] = useState<Array<TilePosition>>(defaultTilePosition);

    const [layout, setLayout] = useState<Array<TilePosition>>(tilePosition);

    const handleReposition = (e: TileLayoutRepositionEvent) => {
        const [firstElement, secoundElement] = e.value;

        if (firstElement.rowSpan !== tilePosition[0].rowSpan)
            secoundElement.rowSpan = maxRows - firstElement.rowSpan;

        if (secoundElement.rowSpan !== tilePosition[1].rowSpan)
            firstElement.rowSpan = maxRows - secoundElement.rowSpan;

        if (firstElement.rowSpan <= minRows || secoundElement.rowSpan <= minRows) return;

        setTilePosition([firstElement, secoundElement]);
        setLayout([firstElement, secoundElement]);
    };

    useEffect(() => {
        const widgets = JSON.parse(getLocalStorageValue(StorageKeys.WidgetLayout) || '{}');

        if (!widgets[location.pathname]) return;

        const cachedLayout = widgets[location.pathname];
        setTilePosition(cachedLayout);
        setLayout(cachedLayout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setWidgetLayout(location.pathname, tilePosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tilePosition]);

    return (
        <StyledWidgetLayout>
            <Grid container direction="column">
                <div style={{ marginTop: '20px' }}>
                    <Grid item xs={2}>
                        {/* <WidgetsFeatureHeader /> */}
                    </Grid>
                </div>
                <Grid>
                    <TileLayout
                        autoFlow="column"
                        columns={1}
                        rowHeight={22}
                        positions={layout}
                        gap={{
                            rows: 10,
                            columns: 10,
                        }}
                        style={tileLayoutStyle}
                        items={tiles}
                        onReposition={handleReposition}
                    />
                </Grid>
            </Grid>
        </StyledWidgetLayout>
    );
};

export default WidgetLayout;
