import React, { PropsWithChildren } from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const Overlay = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const LoadingComponent: React.FC<PropsWithChildren<{ isLoading: boolean }>> = ({ isLoading, children }) => {
    return (
        <>
            {isLoading && (
                <Overlay>
                    <CircularProgress />
                </Overlay>
            )}
        </>
    );
};

export default LoadingComponent;
