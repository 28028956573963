import { AxiosResponse } from 'axios';
import querystring from 'querystring';
import { MINUTE } from 'src/constants/times';
import instance, {
    buildEncriptedUrl as buildEncryptedUrl,
    getInstanceWithTime as getInstanceWithTimeout,
} from '..';
import { createPayload } from '../utilis';

interface CallConfig<P> {
    dbUrl: string;
    endpoint: string;
    payload: P;
}

/**
 * ###Deprecated###!!! Makes an API call to the given endpoint with the given payload.
 */
export const makeApiCall = async <T, P>({
    dbUrl,
    payload,
    endpoint,
}: CallConfig<P>): Promise<AxiosResponse<T>> => {
    // console.log({ dbUrl, payload, endpoint });
    const data = querystring.stringify({
        ...createPayload(payload),
    });
    return instance.post(buildEncryptedUrl(dbUrl, endpoint), data);
};

interface NewCallConfig {
    dbUrl: string;
    endpoint: string;
    requestParams: any;
}

export const newMakeApiCall = async <T>({
    dbUrl,
    requestParams,
    endpoint,
}: NewCallConfig): Promise<AxiosResponse<T>> => {
    const requestParamsStr = querystring.stringify({
        ...createPayload(requestParams),
    });
    const req = instance.post(buildEncryptedUrl(dbUrl, endpoint), requestParamsStr);
    // console.log(req);
    // req.then((json) => console.log(json));
    return req;
};
