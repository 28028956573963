import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store/hooks';
import { toggleSuccessModalAction } from 'src/store/slices/generalFeatures/generalFeaturesSlice';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import * as Styles from 'src/screens/ManualOrder/ManualOrderTable/Shiftcell/Shiftcell.Style';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import hooks from '../hooks.uploadFiles';

const NO_NAME_TEXT = 'ללא שם';

const SuccessModal: React.FC<any> = ({}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(toggleSuccessModalAction(false));
    };

    const { sliceState } = hooks.useFileFtSlice();

    return (
        <Dialog open={sliceState.ui.successModal.isOpen} onClose={close}>
            <Styles.ModalContainer>
                <Styles.CircleModal>
                    <AttachFileIcon fontSize="large" htmlColor="#494949" />
                </Styles.CircleModal>
                <div style={{ padding: '0 1rem' }}>
                    <Styles.ModalBody>
                        <div style={{ fontSize: 20 }}>{t('fileSavedSuccessfully')}</div>
                        <div style={{ fontSize: 16, paddingTop: '5px', overflowWrap: 'anywhere' }}>
                            {t('fileName')}: {sliceState.ui.fileInput?.fileName || NO_NAME_TEXT}
                        </div>
                    </Styles.ModalBody>
                </div>
                <Styles.ModalAction>
                    <Button onClick={close} color="primary">
                        {t('ok')}
                    </Button>
                </Styles.ModalAction>
            </Styles.ModalContainer>
        </Dialog>
    );
};

export default SuccessModal;
