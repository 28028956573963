import { makeApiCall, newMakeApiCall } from '../reportsApi/utils';
import { AxiosRes, WsResponse } from '../types';
import { ReqGetDropsConfig } from './types';

/**
 * Makes a request to the given endpoint.
 * @param {ReqGetDropsConfig} requestConfig - The configuration object for the request.
 * @returns The response from the request.
 */

// FC REQUEST

export const reqGetDrops = (requestConfig: ReqGetDropsConfig): AxiosRes<WsResponse<any>> =>
    newMakeApiCall<WsResponse<any>>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Drop_Lines',
    });
