import React from 'react';
import useCommons from 'src/hooks/common/useCommons';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import useBaseApiParams from '../../../hooks/api/useBaseApiParams';
import {
    courseBuildingFormSelector,
    coursesBuildingActions,
    selectedCourseIdSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { ApiCallStatus, ErrorMessages } from 'src/types/apiCommon.types';
import { asTSR } from 'src/utilis/utilis';
import { useTriggerCount } from './useFetchCourseStations';

const useFetchSelectedCourseTimes = () => {
    const { t, dispatch, useRootAppSelector, dispatchI18nErrMsg } = useCommons();
    const selectedCourseId = useRootAppSelector(selectedCourseIdSelector);

    const { dbUrl, token } = useBaseApiParams();

    const updateApiCall = (status: ApiCallStatus, data: any[]) => {
        dispatch(
            coursesBuildingActions.updateApiCall({
                data,
                callName: 'getCourseTimes',
                status,
            })
        );
    };

    const triggerCount = useTriggerCount('getCourseTimes');

    const fetchData = async () => {
        updateApiCall('LOADING', []);

        try {
            if (selectedCourseId && dbUrl && token) {
                const r = await coursesBuildingRoutes.getCourseTimes({
                    requestConfig: {
                        dbUrl,
                        requestParams: {
                            courseCode: selectedCourseId,
                            token,
                        },
                    },
                });

                if (r.code === CommonCodes.Ok && Array.isArray(r.data.data)) {
                    updateApiCall('SUCCESS', r.data.data);
                } else {
                    updateApiCall('FAILED', []);
                    dispatchI18nErrMsg(r.data.message || ErrorMessages.GeneralError);
                }
            }
        } catch (error: any) {
            // handle error
            updateApiCall('FAILED', []);
            dispatchI18nErrMsg(error.message || ErrorMessages.GeneralError);
        }
    };

    React.useEffect(() => {
        if (selectedCourseId) fetchData();
    }, [selectedCourseId, triggerCount]);
};

export default useFetchSelectedCourseTimes;
