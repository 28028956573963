import React, { useEffect, useState } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressAutoCompleteProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/types.ManualAddressControlled';
import TextBtn from 'src/components/buttons/TextBtn';
import AddressAutoComplete from 'src/components/Inputs/AddressAutoComplete';
import { ToggleableAddressAutocompleteHooks } from 'src/components/Inputs/ToggleableAddressAutocomplete.tsx/ToggleableAddressAutocomplete.tsx/ToggleableAddressAutocomplete';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import useCommons from 'src/hooks/common/useCommons';
import { DeleteBtn } from 'src/screens/Main/components/AddLineForm/components/formSections/components/stations/PassengersAccordionForStation/PassengerRowActions/PassengerRowActions';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    formTempDisabledSelector,
    removeErrorByStationIdAction,
    setMapIsExpandedAction,
    setMapIsOnEditModeAction,
    setTempDisabledAction,
    setNewCenterAction,
    uiSelector,
    setIsLocationChangedAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { colors } from 'src/style/themes/defaultTheme';
import { CourseStationTypes } from 'src/types/lines/api/types';
import { asTSR, isTypeofNumber } from 'src/utilis/utilis';

import { InputAdornment } from '@material-ui/core';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button } from '@mui/material';

import { stringifyAddress } from '../../../../../PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/utils';
import { ICourseStation } from '../../../CourseInfoWidget/StationsAndTimesPanel';
import { StationsAndTimesPanelStyled } from '../../../CourseInfoWidget/styles';
import { StationsPanelStyles as styles } from './styles';
import {
    SelectedTimeDisplay,
    TimeFromPrevStationComponentTypes,
    useTimeFromPrevStation,
} from './TimeFromPrevStationInput';
import { getLabel, isSmallScreen, StationValidationErrors, useStationType } from './utils.StationInputBox';
import { StationSchema } from 'src/types/coursesBuilding/form/formTypes';
import { isStationSchema } from '../../utils/common';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import TextInput from 'src/components/Inputs/TextInput';
import TextInputV3 from 'src/components/ReactHookFormInputs/TextInputV3';
import styled from 'styled-components';

export const RemarkInput = styled.input`
  width: 100%;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px;
  height: 10px;
  outline: none;
  :focus {border: 2px solid #2196f3},
`;
// width: '100%', border: '1px solid #D2D2D2', borderRadius: '4px', marginTop: '5px', padding: '15px', height: '15px'

export const LocationSelectionBtn: React.FC<{
    isOnEditMode: boolean;
    handleEdit: () => void;
    errorMsg: '' | StationValidationErrors;
}> = (props) => {
    const { t } = useTranslation();

    return (
        <TextBtn
            isDisabled={props.isOnEditMode}
            text={
                props.errorMsg === StationValidationErrors.MissingCoords ? t('addToPool') : t('editLocation')
            }
            onClick={props.handleEdit}
        />
    );
};

const useTempDisablingFormOnInputChange = (input: string) => {
    const dispatch = useAppDispatch();

    const isTempDisabled = useRootAppSelector(formTempDisabledSelector);

    // temp disable on input change
    // To allow waiting for validations to complete before allowing to save the form
    React.useEffect(() => {
        if (!isTempDisabled) {
            dispatch(setTempDisabledAction(true));
        }
    }, [input]);
};

const TimeFromPrevStationComponent: React.FC<{
    stationData: ICourseStation;
    index: number;
    fieldArray: UseFieldArrayReturn<any>;
}> = (props) => {
    const { stationData, index, fieldArray } = props;

    const { t } = useCommons();

    const setTimeOnFormManually = (input: number | null, isManualTimeFromPrev = true) => {
        const oldData = fieldArray.fields[index];
        if (isStationSchema(oldData)) {
            const newData: StationSchema = {
                ...oldData,
                timeFromPrev: input,
                isManualTimeFromPrev,
            };

            fieldArray.update(index, newData);
        }
    };

    const { TimeInput, timeFromPrevStationComponentType, handleInputTimeBtnClick } = useTimeFromPrevStation({
        stationData,
        setTimeOnForm: (num: number) => setTimeOnFormManually(num),
    });

    if (index === 0) return null;

    if (
        timeFromPrevStationComponentType === TimeFromPrevStationComponentTypes.TextDisplay &&
        isTypeofNumber(stationData.timeFromPrev)
    ) {
        return (
            <SelectedTimeDisplay
                time={stationData.timeFromPrev}
                resetValue={() => setTimeOnFormManually(null, false)}
            />
        );
    }

    if (timeFromPrevStationComponentType === TimeFromPrevStationComponentTypes.Input) {
        return <TimeInput />;
    }

    return (
        <Button sx={styles.outlinedBtnSx} onClick={handleInputTimeBtnClick} variant="outlined">
            {t('setTimeFromPrevStation')}
        </Button>
    );
};
interface AddressWithCoords {
    city?: string;
    street?: string;
    houseNum?: string;
    lat?: number;
    lng?: number;
    timing?: number;
    distanceFromPrevStation?: number | undefined;
}

// ^ INDEX
const StationInputBox: React.FC<{
    stationData: ICourseStation;
    index: number;
    fieldArray: UseFieldArrayReturn<any>;
}> = ({ stationData, index, fieldArray }) => {
    const { t, dispatch } = useCommons();

    const errorMsg = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.errors[stationData.stationId]
    );

    const isOnEditMode = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map.isOnEditMode);

    const stationsErrors = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.errors
    );
    const updateFormFromLocationTriggerCounter = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.updateFormFromLocationTrigger.counter
    );
    const updatedStationData = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.updateFormFromLocationTrigger.newData
    );
    const lastIndexEdited = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.lastIndexEdited
    );
    const isLocationChanged = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.courseStationsTab.isLocationChanged
    );
    const isConfirmLocationButton = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.courseStationsTab.isConfirmLocationButton
    );

    const { getStationType } = useStationType(fieldArray.fields.length);

    const [inputText, setInputText] = useState(stringifyAddress(stationData));

    useTempDisablingFormOnInputChange(inputText);

    const updateAddressOnFormCb = (newData: AddressWithCoords) => {
        const oldData = fieldArray.fields[index] as unknown as StationSchema;

        if (!oldData) return;

        fieldArray.update(index, { ...oldData, ...newData });

        dispatch(setIsLocationChangedAction({ isLocationChanged: true }));

        if (newData.lat && newData.lng && (newData.lat !== oldData.lat || newData.lng !== oldData.lng)) {
            dispatch(setNewCenterAction({ newCoords: { lat: newData.lat, lng: newData.lng } }));
        }
    };

    useEffect(() => {
        if (
            updateFormFromLocationTriggerCounter === 0 ||
            lastIndexEdited === null ||
            !updatedStationData ||
            !isConfirmLocationButton
        ) {
            return;
        }

        console.log('updatedStationData', updatedStationData);

        fieldArray.update(lastIndexEdited, updatedStationData);

        if (
            updatedStationData.city !== undefined &&
            updatedStationData.city !== null &&
            updatedStationData.stationId === stationData.stationId &&
            isLocationChanged
        ) {
            setInputText(updatedStationData.city);
        }
    }, [updateFormFromLocationTriggerCounter]);

    const { getAddressProps } = ToggleableAddressAutocompleteHooks.useAddressData(updateAddressOnFormCb);

    const addressFieldProps = {
        label: getLabel(index, fieldArray.fields.length),
    } as const;

    const handleEdit = () => {
        dispatch(setMapIsExpandedAction(true));

        dispatch(
            setMapIsOnEditModeAction({
                isOnEditMode: true,
                editedStationId: stationData.stationId,
            })
        );

        if (stationData.lat && stationData.lng) {
            dispatch(setNewCenterAction({ newCoords: { lat: stationData.lat, lng: stationData.lng } }));
        }
    };

    const autocompleteProps = React.useMemo(() => {
        const stationAddressInfo = {
            id: stationData.stationId,
            city: stationData.city,
            street: stationData.street,
            houseNum: stationData.houseNum,
            placeName: '',
        };

        const props: AddressAutoCompleteProps = {
            ...getAddressProps(stationAddressInfo, stationAddressInfo.id).autocompleteConfig.props,
            showErrorMsg: false,
            useCoords: true,
            freeSolo: true,
            defaultValue: stringifyAddress(stationData),
            inputTextControl: {
                inputText,
                setInputText,
            },
            textFieldProps: {
                InputProps:
                    inputText && !isSmallScreen
                        ? {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <LocationSelectionBtn
                                          errorMsg={errorMsg}
                                          isOnEditMode={isOnEditMode}
                                          handleEdit={handleEdit}
                                      />
                                  </InputAdornment>
                              ),
                          }
                        : undefined,
            },
        };

        return props;
    }, [getAddressProps]);

    return (
        <div style={{ borderRight: '1px solid #c5e5ff', paddingRight: '12px' }}>
            <strong>{addressFieldProps.label}</strong>
            <span style={{ color: colors.muiRed, paddingRight: '10px' }}>
                {errorMsg ? t(asTSR(errorMsg)) : ''}
            </span>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row-reverse' }}>
                <styles.StationInputContainer
                    removeBottomPadding={isSmallScreen}
                    className="StationInputContainer"
                    error={!!errorMsg}
                >
                    <styles.Row1>
                        <div style={{ position: 'relative', marginRight: '-8px' }}>
                            <StationsAndTimesPanelStyled.Circle
                                bordered={getStationType(index) !== CourseStationTypes.Normal}
                                isForStationsBuildingForm
                            />
                        </div>
                        <DragIndicatorIcon fontSize="small" />
                        <AddressAutoComplete {...autocompleteProps} />
                        <TimeFromPrevStationComponent {...{ stationData, index, fieldArray }} />
                    </styles.Row1>
                    {isSmallScreen ? (
                        <div style={{ paddingRight: '30px' }}>
                            <LocationSelectionBtn
                                errorMsg={errorMsg}
                                isOnEditMode={isOnEditMode}
                                handleEdit={handleEdit}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div style={{ paddingRight: '36px' }}>
                        <RemarkInput
                            value={stationData.stationRemark}
                            onChange={(e) => {
                                // old object: {id: ..., city: ... remarks: asdasdasda} (stationData from props)
                                // updated = {...old, remarks: e.target.value}
                                const updated = { ...stationData, stationRemark: e.target.value };
                                fieldArray.update(index, updated);
                            }}
                            placeholder={t('typeRemarkToStation')}
                        />
                    </div>
                </styles.StationInputContainer>
                <DisplayWrapper show style={{ position: 'absolute', left: '-20px', top: '-15px' }}>
                    {fieldArray.fields.length !== 2 && (
                        <DeleteBtn
                            onClick={() => {
                                fieldArray.remove(index);
                                dispatch(removeErrorByStationIdAction(stationData.stationId));
                            }}
                        />
                    )}
                </DisplayWrapper>
            </div>
        </div>
    );
};

export default StationInputBox;
