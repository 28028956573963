import styled, { css } from 'styled-components';

const TESTING = 0;
const testBorderGray = TESTING ? 'border: 3px dotted #9999' : '';
const testBorderBlue = TESTING ? 'border: 3px dotted blue' : '';
const testBorderBrown = TESTING ? 'border: 3px dotted brown' : '';

const StyledFormCard = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 0 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const StyledTabScroll = styled.div`
    height: 72vh;
    width: 100%;
    overflow: auto;
`;

const FromBodyContainer = styled.div<{ ht?: string; pd?: string }>`
    height: 100%;
    ${({ ht, pd }) => css`
        padding: ${pd || '10px 24px 0 24px'};
        // height: ${ht || '83%'};
        ${testBorderGray};
    `}
`;

const FormBtnsContainer = styled.div`
    ${testBorderGray};
    border-top: 1px solid #40a8e2;
    margin-top: auto;
`;

const BtnsBox = styled.div`
    justify-content: space-between;
    ${testBorderBlue};
    padding: 20px;
    display: flex;
`;
const FormHeader = styled.div`
    padding: 16px 24px 14px 24px;
    // border: 1px solid blue;
`;

export const formStyles = {
    FormBtnsContainer,
    FromBodyContainer,
    FormHeader,
    BtnsBox,
    StyledTabScroll,
};

export { StyledFormCard };
