import React from 'react';

const useWidgetsDrawer = () => {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    return { isDrawerOpen, toggleDrawer };
};

export default useWidgetsDrawer;
