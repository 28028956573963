import { FormControl, Select } from '@material-ui/core';
import styled from 'styled-components/macro';

export const Container = styled(FormControl)`
    width: 100%;
`;

export const StyledSelect = styled(Select)`
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(180, 190, 201, 0.303);
    .MuiInput-underline:before {
        content: none;
    }
    .MuiSelect-select:focus {
        background-color: transparent;
    }
`;

export const ShiftTime = styled.div<{
    color: string;
}>`
    background: ${({ color }) => color};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97px;
    margin-left: 10px;
    padding: 0 7px;
`;
