import React, { FunctionComponent, ReactChild, useState, memo } from 'react';
import { Coords } from 'google-map-react';
import Tooltip from '../ToolTip/ToolTip';

export interface IMarker extends Coords {
    message: ReactChild | string;
}

const MarkerGps: FunctionComponent<IMarker> = ({ message }: IMarker) => {
    const [isShown, setIsShown] = useState<boolean>(false);

    return (
        <div>
            <Tooltip title={message} open={isShown} arrow placement="top">
                <div
                    style={{
                        border: '2px solid #23DD04',
                        borderRadius: '50%',
                        width: '7px',
                        height: '7px',
                        cursor: 'pointer',
                        // transform: 'translate(-50%, -50%)',
                    }}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                />
            </Tooltip>
        </div>
    );
};

export default memo(MarkerGps, (prevProps: IMarker, nextProps: IMarker) => {
    return prevProps.lat === nextProps.lat && prevProps.lng === nextProps.lng;
});
