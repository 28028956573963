import { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    allFiltersSelector,
    filtersByReportSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectedReportNameSelector,
} from 'src/store/selectores/reportsSelectors';
import { asRootReducer } from 'src/store/utils';
import { PassengersFilterValues } from 'src/types/reports/generalReportsTypes';
import {
    DetailedOrdersFilters,
    ReportName,
    ReportNames,
    ShortOrdersFilters,
    VisasFilters,
} from '../../../store/slices/reports/types';

// Types

const useFilterValuesBySelectedReport = () => {
    const selectedReportName = useRootAppSelector(selectedReportNameSelector);
    const currFilters = useRootAppSelector(allFiltersSelector);

    const applyFiltersBtnClickCount = useAppSelector(selectApplyFiltersBtnClickCount);

    const [valuesForReportFetch, setValuesForReportFetch] = useState(currFilters);

    const getFilterValuesByReport = (
        type: 'curr' | 'applied',
        reportName: ReportNames = selectedReportName
    ) => {
        const filterValues = type === 'curr' ? currFilters : valuesForReportFetch;

        let values:
            | PassengersFilterValues
            | VisasFilters
            | DetailedOrdersFilters
            | ShortOrdersFilters
            | null = null;

        switch (reportName) {
            case 'passengers':
                values = filterValues.byReport.passengers;
                break;

            case 'visas': {
                values = {
                    fromDate: filterValues.currFiltersValues.fromDate,
                    toDate: filterValues.currFiltersValues.toDate,
                } as VisasFilters;
                break;
            }
            case 'detailedOrders': {
                values = {
                    fromDate: filterValues.currFiltersValues.fromDate,
                    toDate: filterValues.currFiltersValues.toDate,
                    pickupTime: filterValues.currFiltersValues.pickupTime,
                    dropTime: filterValues.currFiltersValues.dropTime,
                    departmentCode: filterValues.currFiltersValues.departmentCode,
                    rideType: filterValues.currFiltersValues.rideType,
                } as DetailedOrdersFilters;
                break;
            }
            default:
                // shortOrders
                values = {
                    fromDate: filterValues.currFiltersValues.fromDate,
                    toDate: filterValues.currFiltersValues.toDate,
                    pickupTime: filterValues.currFiltersValues.pickupTime,
                    dropTime: filterValues.currFiltersValues.dropTime,
                    departmentCode: filterValues.currFiltersValues.departmentCode,
                    rideType: filterValues.currFiltersValues.rideType,
                } as ShortOrdersFilters;
                break;
        }

        return values;
    };

    useEffect(() => {
        setValuesForReportFetch(currFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFiltersBtnClickCount, selectedReportName]);

    return { getFilterValuesByReport };
};

export default useFilterValuesBySelectedReport;
