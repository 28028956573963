/* eslint-disable import/no-cycle */
import { takeLatest, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga/index';
import { IDelShiftOrderSaga, ISetShiftOrderSaga, IShifts } from 'src/types/manualOrders/api.types';
import {
    setPassngerShifts,
    delPassngerShifts as delPassngerShiftsApi,
    getShifts,
} from 'src/api/manualOrderApi/manualOrderApi';
import { FcResponseState, IResponse } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import { PassengersShiftActionType } from '../actions/actionType';

import {
    onAddPassngerShiftsSuccess,
    onAddPassngerShiftsFaild,
    onDelPassngerShiftsSuccess,
    onDelPassngerShiftsFaild,
    onRetriveShiftsSuccess,
} from '../actions/PassengersShiftActionType';
import { ISagaRequest } from '../type';
import { setTokenRefreshStatus } from '../actions/loginAction';

function* addPassngerShifts({ payload }: ISagaRequest<ISetShiftOrderSaga>): Iterator<any> {
    try {
        if (!payload) yield put(onAddPassngerShiftsFaild([]));
        const { passenger, ...requestToken } = payload;
        const apiResponse: any = yield setPassngerShifts(requestToken);
        const { response, passengers } = apiResponse.data;

        if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            const { dropOrders, pickupOrders } = passengers.length && passengers[0];
            passenger.dates = passenger.dates.map((x) => {
                if (x.relativeDate === requestToken.relative_date && passengers.length)
                    return {
                        ...x,
                        ...{ dropOrders },
                        ...{ pickupOrders },
                    };
                return { ...x };
            });

            yield put(onAddPassngerShiftsSuccess(passenger));
        } else yield put(onAddPassngerShiftsFaild([]));
    } catch (error) {
        yield put(onAddPassngerShiftsFaild([]));
    }
}

function* delPassngerShifts({ payload }: ISagaRequest<IDelShiftOrderSaga>): Iterator<any> {
    try {
        if (!payload) yield put(onDelPassngerShiftsFaild([]));

        const { passenger, isPickUp, ...requestToken } = payload;
        const apiResponse: any = yield delPassngerShiftsApi({
            ...requestToken,
            orders: requestToken.orders.join(),
        });

        const { response } = apiResponse.data;

        if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            passenger.dates = passenger.dates.map((x) => {
                if (x.relativeDate === requestToken.relative_date)
                    return {
                        ...x,
                        ...{
                            dropOrders: !isPickUp
                                ? x.dropOrders.filter((y) => requestToken.orders.indexOf(y.orderCode) === -1)
                                : x.dropOrders,
                        },
                        ...{
                            pickupOrders: isPickUp
                                ? x.pickupOrders.filter(
                                      (y) => requestToken.orders.indexOf(y.orderCode) === -1
                                  )
                                : x.pickupOrders,
                        },
                    };
                return { ...x };
            });
            yield put(onDelPassngerShiftsSuccess(passenger));
        } else yield put(onDelPassngerShiftsFaild([]));
    } catch (error) {
        yield put(onDelPassngerShiftsFaild([]));
    }
}

function* onRetriveShifts({ payload }: ISagaRequest<any>): Iterator<any> {
    try {
        const res: any = yield getShifts(payload);

        if (res?.data.response === '0') yield put(onRetriveShiftsSuccess(res.data.data));
        else yield put(onRetriveShiftsSuccess([]));
    } catch (error) {
        yield put(onRetriveShiftsSuccess([]));
    }
}

export default function* shiftPassengerSaga(): SagaIterator<any> {
    yield takeLatest(PassengersShiftActionType.addPassngerShifts, addPassngerShifts);
    yield takeLatest(PassengersShiftActionType.delPassngerShifts, delPassngerShifts);
    yield takeLatest(PassengersShiftActionType.retriveShifts, onRetriveShifts);
}
