/* eslint-disable import/no-cycle */

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { SingupType } from 'src/types/login';

import { composeWithDevTools } from 'redux-devtools-extension';
import { setter, getter } from '@progress/kendo-react-common';
import {
    setLocalStorageValue,
    getLocalStorageValue,
    setSessionStorageValue,
    getSessionStorageValue,
    jsonDateReviver,
    mergeDeep,
    RemoveLocalStorageKey,
    RemoveSessionStorageKey,
} from 'src/utilis/utilis';
import { StorageKeys, StorageType } from 'src/types/global';
import { configureStore } from '@reduxjs/toolkit';
import { IRootReducer, rootReducer } from './reducers/index';
import rootSaga from './sagas/index';

// const composeEnhancers = composeWithDevTools({
//    trace: true,
// });

const saga = createSagaMiddleware();

const windowPersistedState = JSON.parse(
    getSessionStorageValue(StorageKeys.ReduxState) || '{}',
    jsonDateReviver
);
const browserPersistedState = JSON.parse(
    getLocalStorageValue(StorageKeys.ReduxState) || '{}',
    jsonDateReviver
);

const initialState = mergeDeep(windowPersistedState, browserPersistedState);

// array to store all middlewares - used in create store
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware: Array<any> = [saga];

// const store = createStore(
//    rootReducer,
//    initialState,
//    composeEnhancers(applyMiddleware(...middleware)),
// );
const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    // enhancers: composeEnhancers(applyMiddleware(...middleware)),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat([...middleware]),
    devTools: { trace: true },
});

const whitelist: Array<{ path: string; storageType: StorageType }> = [
    {
        path: 'loginReducer.selectedFcAccount',
        storageType: StorageType.LocalStorage,
    },
    {
        path: 'loginReducer.loginType',
        storageType: StorageType.SessionStorag,
    },
    {
        path: 'loginReducer.selectedDate',
        storageType: StorageType.SessionStorag,
    },
];

const onLoginType: Function = (storeState: IRootReducer) => {
    const { loginReducer }: IRootReducer = storeState;

    if (loginReducer && loginReducer.loginType === SingupType.OnTimeLogin) {
        RemoveLocalStorageKey(StorageKeys.DeviceToken);

        setSessionStorageValue(StorageKeys.OnTimeLogin, JSON.stringify(loginReducer));
    } else {
        RemoveSessionStorageKey(StorageKeys.OnTimeLogin);
    }
};

const saveToLocalStorage: Function = (storeState: IRootReducer) => {
    const storageObject = {};

    whitelist
        .filter((ele) => ele.storageType === StorageType.LocalStorage)
        .forEach((ele) => {
            const satterObject = setter(ele.path);
            const getterObject = getter(ele.path);
            satterObject(storageObject, getterObject(storeState));
        });

    setLocalStorageValue(StorageKeys.ReduxState, JSON.stringify(storageObject));
};

const saveToSessionStorage: Function = (storeState: IRootReducer) => {
    const sessionObject = {};

    whitelist
        .filter((ele) => ele.storageType === StorageType.SessionStorag)
        .forEach((ele) => {
            const satterObject = setter(ele.path);
            const getterObject = getter(ele.path);
            satterObject(sessionObject, getterObject(storeState));
        });

    setSessionStorageValue(StorageKeys.ReduxState, JSON.stringify(sessionObject));
};

store.subscribe(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const storeState: IRootReducer | any = store.getState();

    onLoginType(storeState);

    saveToLocalStorage(storeState);

    saveToSessionStorage(storeState);
});

// root saga means watcher saga
saga.run(rootSaga); // runs the actions listener (rootSaga)

// * Toolkit types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default { store };
