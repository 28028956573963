/* eslint-disable import/no-cycle */
import { put, takeLatest, call } from 'redux-saga/effects';
import {
    fetchGeneralSettings,
    fetchSettings,
    generalSettingsSubmitForm,
    submitPermManForm,
} from 'src/api/rideSettingsApi/rideSettingsApi';
import { FcResponseState, ResponseTypes } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';

import { SagaIterator } from 'redux-saga';
import i18n from 'src/i18n';
import { AxiosResponse } from 'axios';
import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import { IGeneralFormSubmitReqConfig, ISetSettingSaga } from 'src/types/rideSettings/store/types.sagas';
import bl from 'src/api/dataMappers/rideSettingsBl';
import {
    GetPermsMngSettingsResponse,
    IGeneralSettingsResponse,
    IRideSettingsRequest,
    ISetSettingsRequest,
    SubmitFormResponseData,
} from '../../types/rideSettings/api/types.api';
import { setErrorMessage, setTokenRefreshStatus } from '../actions/loginAction';
import { SettingTypes, SlSettingTypes } from '../../types/rideSettings/store/types.store';

import {
    dispatchGlobalErrorAction,
    getErrorCodeByResponse,
    handleBadStatus,
    handleResponseV3,
    handleUnknownError,
} from './utils/sagasUtils';
import { ISagaRequest } from '../type';

const {
    onFetchSettingsSuccessAction,
    onFetchSettingsFail,
    getGeneralSettingsFetch,
    onSubmitFormFailure,
    onSubmitFormSuccess,
    onFetchSettings,
    onErrorAction,
    formSubmitAction,
    onGeneralSettingsFormSubmit,
} = actions;

function* closeAllAccordions() {
    yield put(actions.setSettingsAccordionExpansion({ type: null, newState: 'closeAll' }));
}

function* fetchGeneralSettingsSaga<TData>({ payload }: ISagaRequest<IRideSettingsRequest<TData>>) {
    try {
        if (!payload) yield put(onFetchSettingsFail(SettingTypes.GeneralSettings));

        const apiResponse: AxiosResponse<IGeneralSettingsResponse> = yield call(() =>
            fetchGeneralSettings(payload)
        );

        // -- handling bad status
        const { status } = apiResponse;
        if (status !== 200) {
            yield handleBadStatus({ status, onErrorAction });
            yield put(onFetchSettingsFail(SettingTypes.GeneralSettings));
            return;
        }

        const { response, GeneralSetting } = apiResponse.data;

        if (String(response) === FcResponseState.TokenExpired)
            setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            yield put(
                onFetchSettingsSuccessAction({
                    settingType: SettingTypes.GeneralSettings,
                    settings: bl.processGeneralSettingsResponseData(GeneralSetting),
                })
            );
        } else {
            const errorCode = response;
            const errorPayload = {
                msg: `invalid response code - ${errorCode}`,
            };
            if (errorCode === '1') {
                errorPayload.msg = i18n.t('expiredTokenRefreshPage');
            }

            yield put(onErrorAction(errorPayload));
            yield put(onFetchSettingsFail(SettingTypes.GeneralSettings));
        }
        // fetching
    } catch (error) {
        handleUnknownError(onErrorAction, error);
    }
}

function* fetchPermissionManagementSettingsSaga<TData>({
    payload,
}: ISagaRequest<IRideSettingsRequest<TData>>) {
    try {
        if (!payload) yield put(onFetchSettingsFail(SettingTypes.PermissionManagement));

        // FC REQUEST

        const { data, status }: AxiosResponse<GetPermsMngSettingsResponse> = yield call(() =>
            fetchSettings<GetPermsMngSettingsResponse, any>({
                ...payload,
                endpoint: 'ws_MyWayStudio_Get_Internet_Permissions',
            })
        );

        if (status !== 200) {
            yield handleBadStatus({ status, onErrorAction });
            yield put(onFetchSettingsFail(SettingTypes.PermissionManagement));
            return;
        }

        const { response, permissions } = data;

        if (response === ResponseTypes.TokenExpiredOrDoesntExist)
            setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            yield put(
                onFetchSettingsSuccessAction({
                    settingType: SettingTypes.PermissionManagement,
                    settings: { permissions },
                })
            );
        } else {
            const errorCode = getErrorCodeByResponse(response);
            const errorPayload = {
                msg: `invalid response code - ${errorCode}`,
            };
            yield put(onErrorAction(errorPayload));
            yield put(onFetchSettingsFail(SettingTypes.PermissionManagement));
        }
    } catch (error) {
        handleUnknownError(onErrorAction, error);
    }
}

function* setPermissionManagementSettingsSaga({ payload }: ISagaRequest<ISetSettingsRequest<any>>) {
    try {
        if (!payload)
            yield put(
                onSubmitFormFailure({
                    failureMsg: 'No payload provided',
                })
            );

        const apiResponse: AxiosResponse<SubmitFormResponseData> = yield call(() =>
            submitPermManForm(payload)
        );
        const { status } = apiResponse;

        if (status !== 200) {
            yield handleBadStatus({ status, onErrorAction });
            yield put(onSubmitFormFailure({}));
        } else {
            const { response, message } = apiResponse.data;

            if (String(response) === FcResponseState.TokenExpired)
                setTokenRefreshStatus(RefreshTokenStatus.Invalid);

            if (+response === 0) {
                yield put(
                    onSubmitFormSuccess({
                        settingType: 'permissionManagement',
                        settings: { permissions: payload.data },
                    })
                );
                yield closeAllAccordions();
            } else {
                const errorCode = getErrorCodeByResponse(String(response));
                const errorPayload = {
                    msg: `Invalid response code - ${errorCode}`,
                };

                yield put(onErrorAction(errorPayload));
                yield put(onSubmitFormFailure({ failureMsg: message }));
            }
        }
        // fetching
    } catch (error) {
        handleUnknownError(onErrorAction, error);
    }
}

function* setSettingsSaga({ payload }: ISagaRequest<ISetSettingSaga>) {
    try {
        const apiResponse: AxiosResponse<SubmitFormResponseData> = yield call(() =>
            generalSettingsSubmitForm(payload.requestPayload)
        );
        const { status } = apiResponse;
        const { response, message } = apiResponse.data;

        yield handleResponseV3({
            status,
            response: String(response),
            *onSuccess() {
                yield put(
                    onSubmitFormSuccess({
                        settingType: payload.settingType,
                        settings: payload.updatedSettings,
                    })
                );
                yield closeAllAccordions();
            },
            *onFailure() {
                yield put(onSubmitFormFailure({ failureMsg: message }));
            },
            errorMessagesEnum: {
                1: i18n.t('expiredTokenRefreshPage'),
                2: i18n.t('oopsSomethingWentWrongTryRefreshing'),
                99: 'Unknown SQL server error',
            },
        });
    } catch (error) {
        // console.log(error);
        // handleUnknownError(onErrorAction, error);
        yield put(onSubmitFormFailure({ failureMsg: '' }));
        yield dispatchGlobalErrorAction(error);
    }
}

export default function* rideSettingsSaga(): SagaIterator<any> {
    yield takeLatest(getGeneralSettingsFetch, fetchGeneralSettingsSaga);
    yield takeLatest(formSubmitAction as any, setPermissionManagementSettingsSaga);
    yield takeLatest(onGeneralSettingsFormSubmit as any, setSettingsSaga);
    yield takeLatest(onFetchSettings, fetchPermissionManagementSettingsSaga);
}
