import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Col = styled.div`
    display: flex;
    width: 48%;
`;
