import { Moment } from 'moment';

export enum ReasonForBlockedDate {
    DatePassed = 'datePassed',
    DateOnBlockedList = 'dateOnBlockedList',
    Unknown = 'unknown',
    PassengerHasTwoOrdersOnDate = 'passengerHasTwoOrdersOnDate',
}

export interface PassengerSelected {
    passId: string;
    fullName: string;
}
export type GeneralDate = string | Date | Moment;

export enum ShiftType {
    Auto = 'auto',
    Manual = 'manual',
}
