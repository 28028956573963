import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
// STORE
import { useSelector } from 'react-redux';
import { isConnectSelector } from 'src/store/selectores/loginSelectors';
// ROUTES
import { routesEndpoints } from './routes_endpoints';

interface IPrivateRoute {
    component: ReactElement;
    path: string;
    exact: boolean;
}

const PrivateRoute = ({ component, path, exact }: IPrivateRoute) => {
    const isAuth = useSelector((state: any) => isConnectSelector(state));

    return isAuth === 'SUCCESS' ? (
        component
    ) : (
        <Redirect to={`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP}`} />
    );
};

export default PrivateRoute;
