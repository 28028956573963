import styled from 'styled-components';

const SectionContainer = styled.div`
    padding: 10px 1.6875em 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const styles = { SectionContainer };
export default styles;
