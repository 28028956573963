import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontFamilies, msColors } from 'src/style/themes/defaultTheme';
import MyRefreshIcon from 'src/components/Icons/MyRefreshIcon';
import { useAppDispatch } from 'src/store/hooks';
import { onRefreshBtnClickAction } from 'src/store/slices/reports/reportsSlice';
import * as CommonStyles from '../commonStyles';
import * as Styles from './styles';
// import useOrderInfo from './useOrderInfo';

const StyledWidgetsFeatureHeader = styled.p`
    font-family: ${fontFamilies.Rubik};
    width: 100%;
    font-weight: normal;
    font-size: 33px;
    color: ${msColors.darkTextcolor};
    text-align: right;
`;

interface Props {}
const WidgetWrapper: FC<Props> = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [refreshed, setRefreshed] = useState(false);

    const reload = () => {
        dispatch(onRefreshBtnClickAction());
        setRefreshed(true);
    };

    useEffect(() => {
        if (!refreshed) return;
        setTimeout(() => setRefreshed(false), 1000);
    }, [refreshed, setRefreshed]);

    return (
        <CommonStyles.Root className="widget-root-container">
            <CommonStyles.Header className="widget-header-container">
                <StyledWidgetsFeatureHeader>{/* {t('widgetsTitle')} */}</StyledWidgetsFeatureHeader>
                <CommonStyles.ReloadBtn disabled={refreshed} onClick={reload}>
                    <MyRefreshIcon width="16px" height="16px" color="black" />

                    <p style={{ paddingRight: '8px' }}>{t('refresh')}</p>
                </CommonStyles.ReloadBtn>
            </CommonStyles.Header>
            <Styles.Container className="widget-body-container">{children}</Styles.Container>
        </CommonStyles.Root>
    );
};

export default WidgetWrapper;
