import { msColors } from 'src/style/themes/defaultTheme';
import styled from 'styled-components';

export const StyledContainer = styled('div')<{ count?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid #c4c4c4;
    color: ${msColors.darkTextcolor};
    border-radius: 4px;
    padding: 0 6px;
    opacity: ${(props) => (props.count ? 1 : 0.4)};
`;
