import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import { WsResponse, ReqConfig } from '../../../api/types';

// -- ws - Get_CarType
export interface WsGetCarTypesParams {
    token: string;
}
export interface WsGetCarTypeResponseDataItem {
    carTypeCode: number;
    carType: string;
    passQty: number; // 0 if not defined
    clientPrice?: string;
}

export type WsGetCarTypeResponse = WsResponse<WsGetCarTypeResponseDataItem[]>;

export type WsGetCarTypeReqConfig = ReqConfig<WsGetCarTypesParams>;

// -- ws - Set_Line
export interface CreateLineReqParams {
    clientCode: string;
    token: string;
    lineDate: string;
    startTime: string;
    lineDescription: string;
    stationsJson: string;
    longRemarks: string;
    flightNumber: string;
    invContactName: string;
    invContactPhone: string;
    passQty: number | null;
    carTypeCode: string | null;
    clientOrderCode: number | string;
    endTime?: string;
    courseCode?: string | null;
    departmentCode?: string | null;
}
//

export type CreateLineResponse = WsResponse<number | string>;

export type CreateLineRequestConfig = ReqConfig<CreateLineReqParams>;

// -- ws - Delete_Line
export enum DeleteLineResponseCodes {
    Ok = 0,
    InvalidToken = 1,
    MissingParams = 2,
    COTError = 3,
    NotCreatedByStudio = 4,
    SQLError = 99,
}
export interface DelLineReqParams {
    token: string;
    lineCode: string;
}

export type DelLineResponse =
    | {
          response: DeleteLineResponseCodes;
          data: '';
      }
    | '';

export type DelLineRequestConfig = ReqConfig<DelLineReqParams>;

// -- Set Line Request
export interface IFcStation {
    city: string;
    passengers: { passCode: string; stationId: string }[];
    stationIndex: string;
    street?: string;
    houseNum?: string;
    addressIndex?: string;
}
export interface IStationJson {
    stations: IFcStation[];
}

export interface AddressJson {
    address: [
        {
            remarks: string;
            addressIndex: '1';
            isDefault: '1';
            city: string;
            street: string;
            houseNum: string;
            addressCode: string;
        }
    ];
}

// -- ws - Get_Passengers_Short_Details
export interface GetPassengersShortDetailsParams {
    clientCode: number;
    token: string;
}
export type GetPassengersShortDetailsResponse = WsResponse<ExistingPassenger[]>;
export type GetPassengersShortDetailsReqConfig = ReqConfig<GetPassengersShortDetailsParams>;

// -- ws - Set_Passenger
export interface ReqSetPassengerResponse {
    response: string;
    passCode: string;
}

export interface INewPassenger {
    internalCode: string;
    passCode?: string;
    addressJson?: string;
    token?: string;
    firstName: string;
    lastName: string;
    mobilePhone?: string;
    eMail?: string;
    isActive: 1;
}

export interface ReqSetPassengerParams extends INewPassenger {
    clientCode: string;
    token: string;
}

export type ReqSetPassengerConfig = ReqConfig<ReqSetPassengerParams>;

// -- ws - Get_Course_Stations
export interface GetCourseStationsReqParams {
    token: string;
    courseCode: string;
}
export enum CourseStationTypes {
    Origin = '0',
    Normal = '1',
    Destination = '2',
}

export interface CoursePassenger {
    stationCode: string;
    passCode: string;
    passId: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    clientCode: string;
    passQty: string;
    index: string;
    remarks: string;
    addressCode: string;
    city: string;
    street: string;
    houseNum: string;
}

export type ParsedCoursePassenger = ExistingPassenger;
export interface CourseStationSettingWsDataItem {
    time: string;
    city: string;
    street: string;
    house: string;
    type: CourseStationTypes;
    index: string;
    stationCode: string;
    timing?: string; // numeric string or empty string
    lat: string | null; // if not received, should use google's
    lon: string | null; // if not received, should use google's
    stationRemark: string | null;
}
export interface CourseStation {
    time: string;
    city: string;
    street: string;
    house: string;
    type: CourseStationTypes;
    passengers: CoursePassenger[];
    lat?: number;
    lng?: number;
    timing?: number;
}
export interface ParsedCourseStation {
    stationCode: string;
    time: string;
    city: string;
    street: string;
    house: string;
    type: CourseStationTypes;
    passengers: string[];
    lat?: number;
    lng?: number;
    timing?: number;
    distanceFromPrevStation?: number | undefined;
}

export type GetCourseStationsResponse = WsResponse<CourseStation[]>;
export type GetCourseStationsRequest = ReqConfig<GetCourseStationsReqParams>;
