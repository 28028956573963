import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { centeredFlexItem } from 'src/style/styledCSS';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import CellEditBtn from 'src/components/KendoGridCutomeColumn/CellEditBtn';
import { iconButtonStyle } from 'src/components/styles/styles';
import {
    openFormAction,
    setEditModeAction,
    toggleConfirmationDialogAction,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import cotValidationHooks from 'src/hooks/reactHookForm/useCotValidation';
import moment from 'moment';
import { IEditLine, ILine } from 'src/types/line';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { useHistory } from 'react-router-dom';
import { getLineByCode } from 'src/api/api';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { AxiosResponse } from 'axios';
import { IResponse } from 'src/api/types';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { setLocalStorageValue, uuid } from 'src/utilis/utilis';
import {
    optimizationsRootSelector,
    setCenterMapOnEditAction,
    setIsDeleteModalOpenAction,
    setIsEditFormAction,
    setIsFormOpenAction,
    setMapIsExpandedAction,
    setWaypointsOnFormEditAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import FlagIcon from '@mui/icons-material/Flag';
import { useSetOptimizationWaypoints } from '../hooks/useSetOptimizationWaypoints';

export interface IKendoDeleteEditeCell {
    hoveredWaypointId: number | null;
    cellProps: React.PropsWithChildren<GridCellProps>;
}

const OptionsCell: FunctionComponent<IKendoDeleteEditeCell> = ({
    hoveredWaypointId,
    cellProps,
}: IKendoDeleteEditeCell) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const dispatchAlert = useDispatchAlert();
    const setOptimizationWaypointsFromServer = useSetOptimizationWaypoints();

    const isShow = hoveredWaypointId && hoveredWaypointId === cellProps.dataItem.waypointId ? true : false;
    const {
        data: {
            getWaypointsFromServer: { data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const onEditWaypointClick = async () => {
        if (cellProps && cellProps.dataItem) {
            dispatch(
                setWaypointsOnFormEditAction({ waypoints: [{ ...cellProps.dataItem, localId: uuid() }] })
            );
            dispatch(setIsEditFormAction({ isEditForm: true }));
            dispatch(setCenterMapOnEditAction({ lat: cellProps.dataItem.lat, lng: cellProps.dataItem.lng }));
            dispatch(setMapIsExpandedAction({ isExpended: true }));
            dispatch(setIsFormOpenAction({ isFormOpen: true }));
        }
    };

    const onDeleteClick = () => {
        dispatch(
            setIsDeleteModalOpenAction({
                isDeleteModalOpen: true,
                isDeleteFromRow: true,
                deleteDataItem: cellProps.dataItem,
            })
        );
    };

    const onOriginDestinationWaypointClick = async () => {
        if (cellProps && cellProps.dataItem && cellProps.dataItem) {
            console.log('onOriginDestinationWaypointClick', cellProps.dataItem);

            const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);

            if (currentTargetWaypoint) {
                setOptimizationWaypointsFromServer.setOptimizationWaypoints([
                    { ...cellProps.dataItem, isTarget: true },
                    { ...currentTargetWaypoint, isTarget: false },
                ]);
            } else {
                setOptimizationWaypointsFromServer.setOptimizationWaypoints([
                    { ...cellProps.dataItem, isTarget: true },
                ]);
            }
        }
    };

    return (
        <td className="optionsCell">
            {isShow && (
                <div style={{ display: 'flex', gap: '5px' }}>
                    <MuiTooltipV3 title={t('originDestinationPoint')}>
                        <IconButton
                            onClick={(ev) => onOriginDestinationWaypointClick()}
                            style={iconButtonStyle}
                            aria-label={t('originDestinationPoint')}
                            size="small"
                        >
                            <FlagIcon height="18px" />
                        </IconButton>
                    </MuiTooltipV3>
                    <MuiTooltipV3 title={t('edit')}>
                        <IconButton
                            onClick={(ev) => onEditWaypointClick()}
                            style={iconButtonStyle}
                            aria-label="עריכה"
                            size="small"
                        >
                            <MyEditPencilIcon height="18px" color="" />
                        </IconButton>
                    </MuiTooltipV3>

                    <MuiTooltipV3 title={t('delete')}>
                        <IconButton
                            onClick={() => onDeleteClick()}
                            style={iconButtonStyle}
                            aria-label="מחיקה"
                            size="small"
                        >
                            <TrashIcon height="18" color="" />
                        </IconButton>
                    </MuiTooltipV3>
                </div>
            )}
        </td>
    );
};

export default OptionsCell;
