import styled, { CSSObject } from 'styled-components';

const HeaderRow = styled.div<{}>`
    display: flex;
    width: 100%;
    padding-bottom: 8px;
`;

const HeaderCell = styled.div<{ flex?: number }>`
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.08px;
    color: #939393;
    flex: ${({ flex }) => flex || 1};
    padding-right: 23px;
`;

const BodyRow = styled.div<{ flex?: number; isSelected: boolean; isHovered: boolean }>`
    display: flex;
    border: 1px solid rgba(180, 190, 201, 0.303017);
    border-radius: 9px;
    min-height: 67px;
    text-align: right;
    width: 100%;

    ${({ isSelected, isHovered }) => {
        if (isHovered && !isSelected) {
            return `
            background-color: #eeeeee;
            transition: background-color 0.3s;
            `;
        }
        if (isSelected) {
            return `
            background-color: #FFEDB1;
            transition: background-color 0.3s;
            `;
        }
    }}
`;

const BodyCell = styled.div<{ flex?: number; style?: CSSObject }>`
    font-style: normal;
    font-weight: 400;
    text-align: right;
    flex: ${({ flex }) => flex || 1};
    font-family: 'Rubik';
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    padding-right: 23px;
    ${({ style }) => style}
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const BodyRowsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
    overflow-x: scroll;
    height: 70vh;
    @media screen and (max-height: 1100px) {
        height: 75vh;
    }
    @media screen and (max-height: 900px) {
        height: 70vh;
    }
    @media screen and (max-height: 800px) {
        height: 69vh;
    }
    @media screen and (max-height: 700px) {
        height: 67vh;
    }
    @media screen and (max-height: 600px) {
        height: 65vh;
    }
`;

const BodyCellText = styled.p<{ style?: CSSObject }>`
    border-right: 2px solid #eeeeee;
    padding-right: 5px;
    ${({ style }) => style}
    color: #494949;
`;

const actionBtnStyle = {
    width: '32px',
    height: '32px',
} as const;

const actionBtnsContainerStyle = {
    display: 'flex',
    gap: '12px',
    paddingLeft: '8px',
    paddingRight: '8px',
} as const;

const styles = {
    HeaderRow,
    HeaderCell,
    BodyRow,
    BodyCell,
    TableContainer,
    BodyRowsContainer,
    BodyCellText,
    actionBtnStyle,
    actionBtnsContainerStyle,
};

export default styles;
