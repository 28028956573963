import {
    IEditShift,
    IPassengerShifts,
    IPassengerShiftUpdate,
    IRemoveShift,
    ISelectedShifts,
    ISelectedSingleShift,
    IShifts,
} from 'src/types/manualOrders/api.types';
import { ReasonForBlockedDate } from 'src/screens/ManualOrder/types';
import { DateAvailabilityStatus } from 'src/screens/ManualOrder/components/PlacemenTransportation/types';
import { type } from 'os';
import { PassengersShiftActionType } from './actionType';
import { ReduxDispatch } from '../type';
import {
    BlockedOrdersDatesForPassengers,
    IDateRangeNew,
    IShiftSelection,
} from '../reducers/passengersShiftReudcer';

export const onResetShiftSelection = (payload: any) => {
    return {
        type: PassengersShiftActionType.onResetShiftSelection,
        payload,
    };
};
export const updateDateAvailabilityStatus = (payload: { newStatus: DateAvailabilityStatus }) => {
    return {
        type: PassengersShiftActionType.updateDateAvailabilityStatus,
        payload,
    };
};
export const addToBlockedDates = (payload: {
    passId: string;
    date: string;
    reason: ReasonForBlockedDate;
}) => {
    return {
        type: PassengersShiftActionType.addToBlockedDates,
        payload,
    };
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectTableWeek = (payload: { dateRange: IDateRangeNew }) => {
    return {
        type: PassengersShiftActionType.selectTableWeek,
        payload,
    };
};

export const onRetrivePassenger = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.retrivePassenger,
        payload,
    };
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onChangedShiftSelection = (payload: IShiftSelection) => {
    return {
        type: PassengersShiftActionType.onChangedShiftSelection,
        payload,
    };
};

export const onRetrivePassengerSuccess = (payload: any): ReduxDispatch<any> => {
    return {
        type: PassengersShiftActionType.retrivePassengerSuccess,
        payload,
    };
};

export const onRetrivePassengerFaild = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.retrivePassengerFail,
        payload,
    };
};

export const onAddPassngerShifts = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.addPassngerShifts,
        payload,
    };
};

export const onAddPassngerShiftsSuccess = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.addPassngerShiftsSuccess,
        payload,
    };
};

export const onAddPassngerShiftsFaild = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.addPassngerShiftsFaild,
        payload,
    };
};

export const onDelPassngerShifts = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.delPassngerShifts,
        payload,
    };
};

export const onDelPassngerShiftsSuccess = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.delPassngerShiftsSuccess,
        payload,
    };
};

export const onDelPassngerShiftsFaild = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.delPassngerShiftsFaild,
        payload,
    };
};

export const onRetriveShifts = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: PassengersShiftActionType.retriveShifts,
        payload,
    };
};

export const onRetriveShiftsSuccess = (payload: Array<IShifts>): ReduxDispatch<Array<IShifts>> => {
    return {
        type: PassengersShiftActionType.retriveShiftsSuccess,
        payload,
    };
};

export const onSetSelectedShift = (
    payload: ISelectedShifts | undefined
): ReduxDispatch<ISelectedShifts | undefined> => {
    return {
        type: PassengersShiftActionType.setSelectedShift,
        payload,
    };
};

export const setAsOnlySelectedPassenger = (payload: { passId: string }) => {
    return {
        type: PassengersShiftActionType.setAsOnlySelectedPassenger,
        payload,
    };
};

export const setSelectedPassenger = (payload: { pass: IPassengerShifts; isSelected: boolean } | any): any => {
    return {
        type: PassengersShiftActionType.setSelectedPassenger,
        payload,
    };
};
export const setSelectedAllPassengers = (payload: {
    isSelected?: boolean;
    passengers: IPassengerShifts[];
}): any => {
    return {
        type: PassengersShiftActionType.setSelectedAllPassengers,
        payload,
    };
};

export const setSelectedSingleShift = (payload: ISelectedSingleShift | null): any => {
    return {
        type: PassengersShiftActionType.setSelectedSingleShift,
        payload,
    };
};

export const updatePassengersShift = (payload: IPassengerShiftUpdate[]): any => {
    return {
        type: PassengersShiftActionType.updatePassengersShift,
        payload,
    };
};

export const removePassengersShift = (payload: IRemoveShift): any => {
    return {
        type: PassengersShiftActionType.removePassengersShift,
        payload,
    };
};

export const setEditShift = (payload: IEditShift | null): any => {
    return {
        type: PassengersShiftActionType.editShift,
        payload,
    };
};

export const onFormShowChange = (
    payload:
        | {
              isOpen: boolean;
              resetEditShift?: boolean;
          }
        | boolean
): any => {
    return {
        type: PassengersShiftActionType.onFormShowChange,
        payload,
    };
};

export const onClickedBlockedTable = (): any => {
    return {
        type: PassengersShiftActionType.onClickedBlockedTable,
        payload: null,
    };
};

export const refreshPassengersOrdersCount = (payload: { newCount: BlockedOrdersDatesForPassengers }): any => {
    return {
        type: PassengersShiftActionType.refreshPassengersOrdersCount,
        payload,
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setSelectedPassengerIds = (payload: { selectedPassengersIds: string[] }) => {
    return {
        type: PassengersShiftActionType.setSelectedPassengersIds,
        payload,
    };
};
