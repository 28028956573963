/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './KendoGrid.css';
import React, { FunctionComponent, PropsWithChildren, CSSProperties, useEffect, useState } from 'react';
import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridProps,
    GridColumnProps,
    GridColumnReorderEvent,
    GridColumnResizeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import { setLocalStorageValue, getLocalStorageValue, getCurrentPath } from 'src/utilis/utilis';
import { useLocation } from 'react-router-dom';
import { StorageKeys } from 'src/types/global';
import { useSelector, useDispatch } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { onClickedBlockedTable } from 'src/store/actions/PassengersShiftActionType';
import { ReqStatus } from 'src/api/types';
import { passengerRootDataSelector } from 'src/store/selectores/passengerShiftsSelectores';
import useGridDataLoadingStatus from '../../hooks/useGridDataLoadingStatus';
import Spinner from '../Spinner/Spinner';

export interface KendoColumnProps extends GridColumnProps {
    keyTable?: string;
}

export interface TableProp extends PropsWithChildren<GridProps> {
    columns: Array<KendoColumnProps>;
    style?: CSSProperties;
    wrapperDivStyle?: CSSProperties;
    isSavedColumnState?: boolean;
}

interface TableWithExport extends TableProp {
    excelExportRef?: any;
}

const KendoGrid: FunctionComponent<TableWithExport> = ({
    columns,
    excelExportRef,
    wrapperDivStyle,
    isSavedColumnState,
    onColumnReorder,
    onColumnResize,
    ...prop
}: PropsWithChildren<TableWithExport>) => {
    const { t } = useTranslation();
    const location = useLocation();

    const { data }: any = prop;
    const totalRecords = (data && Array.isArray(data) && data.length) || data?.total || 0;

    const saveGridColumn = ({ columns: changedColumns }: GridColumnReorderEvent | GridColumnResizeEvent) => {
        if (!isSavedColumnState) return;

        const savedColumens = JSON.parse(getLocalStorageValue(StorageKeys.GridColumns) || '{}');

        savedColumens[location.pathname] = changedColumns.map((x) => ({
            width: x.width,
            orderIndex: x.orderIndex,
            field: x.field,
        }));

        setLocalStorageValue(StorageKeys.GridColumns, JSON.stringify(savedColumens));
    };

    const onSavedColumnReorder = (event: GridColumnReorderEvent) => {
        if (onColumnReorder && typeof onColumnReorder === 'function') onColumnReorder(event);

        saveGridColumn(event);
    };

    const onSavedColumnResize = (event: GridColumnResizeEvent): void => {
        if (onColumnResize && typeof onColumnResize === 'function') onColumnResize(event);

        if (!isSavedColumnState) return;

        const savedColumens = event.columns.map((x) => {
            if (x.id === event.targetColumnId) return { ...x, width: event.newWidth };

            return x;
        });

        saveGridColumn({ ...event, columns: savedColumens });
    };

    const dataStatus = useGridDataLoadingStatus();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const path = getCurrentPath();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!dataStatus[path]) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (dataStatus[path].status === ReqStatus.LOADING) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [dataStatus]);

    return isLoading ? (
        <Spinner />
    ) : (
        <div
            className="k-rtl kendo-grid"
            // eslint-disable-next-line react/no-unknown-property
            no-rows={totalRecords === 0 ? 'no-rows' : undefined}
            style={wrapperDivStyle}
        >
            <div>
                <ExcelExport data={data} ref={excelExportRef}>
                    <Grid
                        {...prop}
                        onColumnReorder={onSavedColumnReorder}
                        onColumnResize={onSavedColumnResize}
                        className="grid2"
                    >
                        <GridNoRecords>{t('noRecordWasFound')}</GridNoRecords>
                        {columns.map((column) => {
                            return <GridColumn key={column.keyTable || column.field} {...column} />;
                        })}
                    </Grid>
                </ExcelExport>
            </div>
        </div>
    );
};

export default KendoGrid;
