/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootReducer } from 'src/store/reducers';
import { createMsg } from 'src/store/utils';
import {
    ChosenCourse,
    WsCourseDataItem,
    CourseCodes,
    CourseType,
} from 'src/types/passengersManager/courseChoosingTypes';
import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';
import { deepClone, getCurrDateAsString } from 'src/utilis/utilis';

// Define the initial state using that type
// * Slice
export type ApiCallStatus = 'ON_STAND_BY' | 'SUCCESS' | 'LOADING' | 'FAILED';

export interface CourseListForm {
    coursesSelectedChanged: boolean;
    isOpen: boolean;
    currAddressCode: string | null;
    coursesOnForm: ChosenCourse[];
    wasTouched: boolean;
}

export interface UiState {
    chosenCourses: ChosenCourse[];
    initialChosenCourses: ChosenCourse[];
    coursesListForm: CourseListForm;
    common: {
        error: {
            msg: string;
            code: string | null;
        };
    };
}
export interface PassengersManagerSliceState {
    ui: UiState;
    data: {
        courses: WsCourseDataItem[];
    };
}

const DEFAULT_COURSE = {
    addressCode: '0',
    pickup: '',
    drop: '',
};

const initialUiState = {
    chosenCourses: [DEFAULT_COURSE],
    initialChosenCourses: [DEFAULT_COURSE],
    coursesListForm: {
        isOpen: false,
        currAddressCode: null,
        coursesOnForm: [DEFAULT_COURSE],
        wasTouched: false,
        coursesSelectedChanged: false,
    },
    common: {
        error: {
            msg: '',
            code: null,
        },
    },
};
const initialState: PassengersManagerSliceState = {
    ui: initialUiState,
    data: {
        courses: [],
    },
};
export const passengersManagerSlice = createSlice({
    name: 'passengersManagerSlice',
    initialState,
    reducers: {
        // ^ UI reducers
        // * Header
        onGetAccountCoursesSuccess: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{ courses: WsCourseDataItem[] }>
        ) => {
            state.data.courses = action.payload.courses;
        },
        changePickCourseFormShow: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{
                show: boolean;
                addressCode?: string | null;
            }>
        ) => {
            state.ui.coursesListForm.isOpen = action.payload.show;
            state.ui.coursesListForm.currAddressCode = action.payload.addressCode || null;
            state.ui.coursesListForm.coursesOnForm = state.ui.chosenCourses;
        },
        setChosenCourses: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{ chosenCourses: ChosenCourse[] }>
        ) => {
            state.ui.chosenCourses = action.payload.chosenCourses;
            state.ui.coursesListForm.coursesOnForm = action.payload.chosenCourses;
        },
        setInitialChosenCourses: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{
                initialChosenCourses: ChosenCourse[];
            }>
        ) => {
            state.ui.initialChosenCourses = action.payload.initialChosenCourses;
        },
        setCoursesOnForm: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{ chosenCourses: ChosenCourse[] }>
        ) => {
            state.ui.coursesListForm.coursesOnForm = action.payload.chosenCourses;
            state.ui.coursesListForm.wasTouched = true;
        },
        onAddAddress: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{ addressIndex: string }>
        ) => {
            state.ui.chosenCourses.push({
                addressCode: action.payload.addressIndex,
                pickup: '',
                drop: '',
            });
        },
        onRemoveAddress: (
            state: PassengersManagerSliceState,
            action: PayloadAction<{
                addressCode: string;
                addressIndex: string;
            }>
        ) => {
            const { addressCode, addressIndex } = action.payload;
            const foundAddressByCode = state.ui.chosenCourses.some(
                (course) => course.addressCode === addressCode
            );
            const filteredCourses: ChosenCourse[] = [];
            deepClone(state.ui.chosenCourses).forEach((course: ChosenCourse) => {
                const addressIdentifier = foundAddressByCode ? addressCode : addressIndex;
                if (course.addressCode === addressIdentifier) return;
                filteredCourses.push(course);
            });
            state.ui.chosenCourses = filteredCourses;
        },
        resetCoursesUiState: (state: PassengersManagerSliceState) => {
            state.ui = initialUiState;
        },
        onFormSave: (state: PassengersManagerSliceState) => {
            state.ui.chosenCourses = state.ui.coursesListForm.coursesOnForm;
            state.ui.coursesListForm.wasTouched = false;
            state.ui.coursesListForm.coursesSelectedChanged = true;
            state.ui.coursesListForm.isOpen = false;
            state.ui.coursesListForm.currAddressCode = null;
        },
        // * Common
        onError: (state: PassengersManagerSliceState, action: PayloadAction<ErrorActionPayload>): void => {
            if (!action.payload) {
                state.ui.common.error = {
                    msg: '',
                    code: null,
                };
                return;
            }
            const { msg, details } = action.payload;

            state.ui.common.error = {
                msg: createMsg({ msg, details }),
                code: getCurrDateAsString(),
            };
        },
    },
});

// * Exports
// ^ Actions
export const {
    onGetAccountCoursesSuccess: onGetAccountCoursesSuccessAction,
    changePickCourseFormShow: changePickCourseFormShowAction,
    setChosenCourses: setChosenCoursesAction,
    resetCoursesUiState: resetCoursesUiStateAction,
    setCoursesOnForm: setCoursesOnFormAction,
    onFormSave: onFormSaveAction,
    onAddAddress: onAddAddressAction,
    onRemoveAddress: onRemoveAddressAction,
    setInitialChosenCourses: setInitialChosenCoursesAction,
    onError: onErrorAction,
} = passengersManagerSlice.actions;

// ^ Selectors
export const isCoursePickFormOpenSelector = (state: IRootReducer): boolean =>
    state.passengersManagerSlice.ui.coursesListForm.isOpen;
export const uiSelector = (state: IRootReducer): UiState => state.passengersManagerSlice.ui;
export const chosenCoursesSelector = (state: IRootReducer): ChosenCourse[] =>
    state.passengersManagerSlice.ui.chosenCourses;
export const formSelector = (state: IRootReducer): CourseListForm =>
    state.passengersManagerSlice.ui.coursesListForm;
export const coursesSelector = (state: IRootReducer): WsCourseDataItem[] =>
    state.passengersManagerSlice.data.courses;
export const coursesOnFormSelector = (state: IRootReducer): ChosenCourse[] =>
    state.passengersManagerSlice.ui.coursesListForm.coursesOnForm;

// ^ Reducer export
const passengersManagerReducer = passengersManagerSlice.reducer;
export default passengersManagerReducer;
