/* eslint-disable react/button-has-type */
import { Button, Fab, Slide } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParentSize } from '@cutting/use-get-parent-size';
import { useAppSelector } from 'src/store/hooks';
import { widgetsUiSelector } from 'src/store/selectores/reportsSelectors';
import { asRootReducer } from 'src/store/utils';
import { containers } from '../styles';

interface Props {
    withClosing?: boolean;
    isOpen?: boolean;
}

const WidgetsContainerV2: FC<Props> = ({ children, withClosing, isOpen }) => {
    return (
        <>
            <containers.WidgetsContainer className="widgets-container" isOpen={isOpen}>
                <Slide direction="right" in={isOpen} appear={false}>
                    <div
                        style={{
                            display: isOpen ? 'block' : 'none',
                            transition: 'display 2s ease',
                            width: '100%',
                        }}
                    >
                        {children}
                    </div>
                </Slide>
            </containers.WidgetsContainer>
        </>
    );
};

export default WidgetsContainerV2;
// const StyledFabContainer = styled.div<{ isOpen: boolean }>`
//    //    background: transparent;
//    //    display: flex;
//    //    align-items: center;
//    //    justify-content: flex-start;
//    //    width: 40px;
//    //    height: 40px;
//    //    // position: fixed;
//    //    // top: 47%;
//    //    ${({ isOpen }) => css`
//       //       ${isOpen ? '' : 'left: 11px'}
//       //
//    `}
//    //
// `;
