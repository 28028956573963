import _ from 'lodash';
import { IEditLine, IPassenger } from 'src/types/line';
import { AddressJson, IStationJson, CreateLineReqParams } from 'src/types/lines/api/types';
import { isTruthy, parseJsonIfValid, stringifyDate } from 'src/utilis/utilis';
import {
    AddressInput,
    AddressInputWithCoords,
    Inputs,
    Station,
    ValidationError,
} from './components/AddLineForm/types';
import { da } from 'date-fns/locale';
import moment from 'moment';

export const getErrorMessage = (inputErrors: ValidationError | null): string => {
    if (!inputErrors || _.isEmpty(inputErrors)) return '';

    const err = Object.values(inputErrors)[0];
    if (!err) return '';

    if (err?.type === 'required') {
        return 'נדרש להזין כתובת מלאה';
    }

    return err.message || '';
};

/**
 * Builds a JSON string for the Set_Passenger WS (only for the add line ft).
 * @param {string} remarks - the remarks to inject into the JSON string.
 * @returns {string} - the JSON string.
 */
export const buildAddressJsonForNewPassengerOnAddLine = (
    remarks: string,
    city: string,
    street = '',
    houseNum = '',
    addressCode = ''
): string => {
    const addressObject: AddressJson = {
        address: [
            {
                remarks,
                addressIndex: '1',
                isDefault: '1',
                city,
                street,
                houseNum,
                addressCode,
            },
        ],
    };
    return JSON.stringify(addressObject);
};

/**
 * Takes in an array of stations and returns a string of the stations in JSON format.
 * @param {Station[]} stations - the array of stations to convert to JSON format.
 * @returns {string} - the stations in JSON format.
 */

const STATION_INDEXES = {
    START_ADDRESS: {
        STATION: '1',
        ADDRESS: '8',
    },
    END_ADDRESS: {
        ADDRESS: '9',
    },
} as const;

export const buildStationsJson = (
    stations: Station[],
    startAddress: AddressInput,
    endAddress: AddressInput,
    editLine: IEditLine | null,
    isAllStationsWithCoords: boolean
): string => {
    console.log('endAddress', endAddress);

    const buildStation = (
        data: Station | AddressInputWithCoords,
        stationIndex: string | null,
        stationIdOnEdit: string | null,
        addressIndex?: '1' | '8' | '9'
    ) => {
        return {
            city: data.city || '',
            street: data.street,
            houseNum: data.houseNum,
            passengers:
                'passengers' in data
                    ? data.passengers.map((passenger: string) => ({
                          passCode: passenger,
                          stationId: editLine ? data.stationId : '',
                      }))
                    : [],
            stationIndex,
            addressIndex,
            // stationCode: stationIdOnEdit
            stationCode: stationIdOnEdit ? stationIdOnEdit : null,
            lat: data.lat,
            lng: data.lng,
            timing: isAllStationsWithCoords ? data.timing : 0,
        };
    };

    console.log('stations', stations, 'editLine', editLine);

    let startStationId = null;
    let endStationId = null;

    if (editLine) {
        editLine.stations.forEach((editSt) => {
            if (editSt.type === '0') {
                startStationId = editSt.code;
            }
            if (editSt.type === '2') {
                endStationId = editSt.code;
            }
        });
    }

    const fcFormatStations = [
        buildStation(startAddress, null, startStationId, STATION_INDEXES.START_ADDRESS.ADDRESS),
    ];

    stations.forEach((st) => {
        let stationIdOnEdit: string | null = null;

        if (editLine) {
            editLine.stations.forEach((editSt) => {
                if (editSt.code === st.stationId) {
                    stationIdOnEdit = editSt.code;
                }
            });
        }
        fcFormatStations.push(buildStation(st, null, stationIdOnEdit));
    });

    fcFormatStations.push(buildStation(endAddress, null, endStationId, STATION_INDEXES.END_ADDRESS.ADDRESS));

    const stationsWithIndex = fcFormatStations.map((st, i) => ({ ...st, stationIndex: String(i + 1) }));

    const stationsObj: IStationJson = {
        stations: stationsWithIndex,
    };

    return JSON.stringify(stationsObj);
};
// function to check if value is truthy

// const getOnlyTruthyValuesFromObj = (obj: Object): any[] => {
//     return Object.values(obj).filter(isTruthy);
// };

export const buildLineDescription = (startAddress: AddressInput, endAddress: AddressInput): string => {
    return `${startAddress.placeName || startAddress.city} - ${endAddress.placeName || endAddress.city}`;
};

/**
 * Builds the request parameters for creating a line.
 * @param {Inputs} formData - The form data from the user.
 * @param {string} token - The token for the user.
 * @param {Station[]} stations - The stations for the line.
 * @returns {CreateLineReqParams} The request parameters for creating a line.
 */
export const buildSetLineRequestParams = (
    formData: Inputs,
    token: string,
    stations: Station[],
    isOnEdit: IEditLine | null,
    endAddress: AddressInput,
    startAddress: AddressInput,
    isAllStationsWithCoords: boolean
): CreateLineReqParams => {
    console.log('formData', formData);

    const result = {
        token,
        clientCode: String(formData.clientCode),
        lineDate: stringifyDate(formData.date),
        startTime: formData.startTime,
        lineDescription: formData.description || buildLineDescription(startAddress, endAddress),
        stationsJson: buildStationsJson(
            stations,
            startAddress,
            endAddress,
            isOnEdit,
            isAllStationsWithCoords
        ),
        longRemarks: formData.lineRemarks,
        endTime: formData.endTime ? formData.endTime : formData.startTime,
        flightNumber: formData.flightNum,
        invContactName: formData.invContactName,
        invContactPhone: `${formData.invContactPhonePrefix}${formData.invContactPhone}`,
        clientOrderCode: formData.clientOrderCode,
        passQty: formData.passQty,
        carTypeCode: String(formData.carId) === '-1' ? null : formData.carId,
        departmentCode: formData.departmentCode,
        courseCode: formData.courseCode,
    };

    return result;
};

/**
 * Takes in a string of addressJson and returns the remarks of the address.
 * @param {string | undefined} addressJson - the addressJson to parse
 * @returns {string} - the remarks of the address
 */
export const getPartsFromAddressJson = (
    addressJson: string
): {
    remarks: string;
    city: string;
    street: string;
    houseNum: string;
    addressCode: string;
} => {
    if (!addressJson)
        return {
            remarks: '',
            city: '',
            street: '',
            houseNum: '',
            addressCode: '',
        };

    const parsedAddressJson: AddressJson = parseJsonIfValid(addressJson);

    const { remarks, city, street, houseNum, addressCode } = parsedAddressJson.address[0];

    return { remarks, city, street, houseNum, addressCode };
};

/**
 * Determines whether or not to show the station or passenger component.
 * @returns {boolean} - Whether or not to show the station or passenger component.
 */
export const showStationOrPassengerComponent = (
    loggedUserDeptCode: string,
    passengerOrStation: IPassenger
) => {
    // if (
    //     !loggedUserDeptCode ||
    //     (!passengerOrStation.departmentCode && !passengerOrStation.fullName) ||
    //     loggedUserDeptCode === passengerOrStation.departmentCode
    // ) {
    //     return true;
    // }
    // return false;

    // may need to replace tamir matz

    if (!loggedUserDeptCode) {
        return true;
    }

    if (!passengerOrStation.departmentCode) {
        return true;
    }

    if (loggedUserDeptCode === passengerOrStation.departmentCode) {
        return true;
    }

    return false;
};

// Gets start and end date and generate an array of dates

export const getDatesInRange = (startDate: Date, endDate: Date): string[] => {
    const dates: string[] = [];
    const currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
        const formattedDate = currentDate.format('YYYY-MM-DD');
        dates.push(formattedDate);
        currentDate.add(1, 'day');
    }

    return dates;
};
