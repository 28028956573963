import { CSSProperties } from 'react';
import themes from 'src/style/themes';

export const containerStyle: CSSProperties = {
    display: 'flex',
    color: themes.msColors.primaryTextcolor,
    fontFamily: themes.fontFamilies.Rubik,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    margin: '1px 0 0 0',
};

export const dividerStyle: CSSProperties = {
    padding: '2px 5px 0 5px',
    margin: 0,
};
