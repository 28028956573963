import React from 'react';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';
import { getMapsAPIKeyNoRestrict } from 'src/utilis/utilis';
import { IOptimizationCsvWaypoint } from '../api/types';
import { AddressTypes as AddressComponents } from 'src/components/Inputs/types';
/* eslint-disable no-debugger */

export const useFetchCoordsFromAddress = () => {
    const googleCounterUp = useGoogleApiCounterUp();

    const fetchCoordsFromAddress = async (address: string) => {
        try {
            googleCounterUp.googleApiCounterUp();

            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${getMapsAPIKeyNoRestrict()}`
            );
            const data = await response.json();

            if (data.status === 'ZERO_RESULTS') {
                return null;
            }

            if (
                data.results &&
                data.results[0] &&
                data.results[0].geometry &&
                data.results[0].geometry.location &&
                data.results[0].geometry.location.lat &&
                data.results[0].geometry.location.lng
            ) {
                const lat = data.results[0].geometry.location.lat;
                const lng = data.results[0].geometry.location.lng;

                return { lat, lng };
            }

            return null;
        } catch (error) {
            console.log('error: api');
            return null;
        }
    };

    return {
        fetchCoordsFromAddress,
    };
};
