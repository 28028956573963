import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { GeneralSettingTypes } from 'src/types/rideSettings/store/types.store';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';

export const useFormController = () => {
    const dispatch = useAppDispatch();

    const formState = useRootAppSelector(selectors.formSelector);

    const handleChange = <T>(type: GeneralSettingTypes, newVal: T): void => {
        dispatch(actions.updateGenSetsFormValueAction({ type, newVal }));
    };

    const getValueByType = (type: GeneralSettingTypes) => {
        if (formState.valuesByForm.generalSettings) {
            return formState.valuesByForm.generalSettings[type];
        }
        return null;
    };

    return { formState, handleChange, getValueByType };
};
