/* eslint-disable no-plusplus */
import moment, { Moment } from 'moment';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { IPassengerShifts } from 'src/types/manualOrders/api.types';
import { RELATIVE, SHORT } from 'src/constants/dates';
import { ReasonForBlockedDate } from 'src/screens/ManualOrder/types';
import {
    datePassed,
    isDayOnBlockedList,
    rangeDate,
    getShiftBlockedDays,
    getPassengerIdsWithTwoOrdersOneDate,
} from 'src/screens/ManualOrder/utilis';
import { updateDateAvailabilityStatus } from 'src/store/actions/PassengersShiftActionType';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { BlockedOrdersDatesForPassengers } from 'src/store/reducers/passengersShiftReudcer';
import {
    formSelector,
    shiftsSelector,
    tableDatesSelector,
    tableSelector,
    uiSelector,
} from 'src/store/selectores/passengerShiftsSelectores';
import { PASSENGER_ID_INDEX } from 'src/store/slices/manualOrders/helpers';
import {
    passengersOrdersCountPerDateSelector,
    selectedPassengersIdsSelector,
    setBlockedDatesAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { enumerateDaysBetweenDates, formatDateToString, stringifyDate } from 'src/utilis/utilis';
import { PlacemenContext } from '../context';
import {
    SelectableConfig,
    SelectableStatus,
    DateDataForOrdering,
    IPlacment,
    DateAvailabilityStatus,
} from '../types';
import { isRelativeDate } from '../utilis';

// * Constants
const INITIAL_SELECTABLE_CONFIG: SelectableConfig = {
    status: null,
    reason: null,
} as const;
export const DAYS_FOR_ORDERS_RANGE = {
    GRID: 6,
    PLACEMENT_WIDGET: 27,
} as const;

// * types

const { NonSelectable, Selectable, SelectableByCondition } = SelectableStatus;
const { PassengerHasTwoOrdersOnDate, DatePassed, DateOnBlockedList } = ReasonForBlockedDate;

// * utils
// const getStartAndEndDates = (relativeInitialDate: string) =>
//    rangeDate(relativeInitialDate);

const getAllDates = (startDate: Moment, endDate: Moment) => {
    return enumerateDaysBetweenDates(startDate, endDate).map((date) => {
        return {
            date,
            selectableConfig: INITIAL_SELECTABLE_CONFIG,
        };
    });
};

const getSelectableConfig = (
    date: Moment,
    blockedOrdersDatesForPassengers: BlockedOrdersDatesForPassengers,
    selectedPassengersIds: string[],
    shifts: any[],
    selectedShiftIds: number[]
): DateAvailabilityStatus => {
    // console.log(date.toString());
    const dateAsDate = date.toDate();

    if (datePassed(date)) {
        // console.log(date.toString(), DatePassed);
        const status = { status: NonSelectable, reason: DatePassed };
        return { ...status, date: dateAsDate };
    }

    if (isDayOnBlockedList(getShiftBlockedDays(shifts, selectedShiftIds), date)) {
        const status = {
            status: NonSelectable,
            reason: DateOnBlockedList,
        };
        return { ...status, date: dateAsDate };
    }

    const passengers = selectedPassengersIds.map((p) => ({
        passId: p,
    }));

    const passengerCausingStatus = getPassengerIdsWithTwoOrdersOneDate(
        date,
        passengers,
        blockedOrdersDatesForPassengers
    );
    if (passengerCausingStatus.length) {
        const status = {
            status: SelectableByCondition,
            reason: PassengerHasTwoOrdersOnDate,
        };

        return { ...status, date: dateAsDate, passengerCausingStatus };
    }

    const status = {
        status: Selectable,
        reason: null,
    };

    return { ...status, date: dateAsDate, passengerCausingStatus };
};

// * Hook
const useDatesStatus = (selectedPassengersFullData: IPassengerShifts[]): DateDataForOrdering[] => {
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();
    const [placment] = useContext(PlacemenContext);

    // const { autoOrder, manualOrder } = placment;
    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));
    const formState = useSelector((state: IRootReducer) => state.passengersShiftReudcer.ui.form);
    const tableDates = useSelector((state: IRootReducer) => tableDatesSelector(state));

    const blockedOrdersDatesForPassengers = useRootAppSelector(passengersOrdersCountPerDateSelector);

    const initialDatesForOrders: DateDataForOrdering[] = useMemo(() => {
        const startingDate = moment(tableDates.startingDate, SHORT);
        const endDate = startingDate.clone().add(DAYS_FOR_ORDERS_RANGE.PLACEMENT_WIDGET, 'days');

        return getAllDates(startingDate, endDate);
    }, [tableDates.startingDate]);

    const [datesForOrderWithStatus, setDatesForOrderWithStatus] = useState<DateDataForOrdering[]>([]);

    const selectedShiftsIds = useMemo(() => {
        const ids: number[] = [];
        const isOnAuto = !!placment.autoOrder.shiftId;

        if (isOnAuto) {
            ids.push(+placment.autoOrder.shiftId);
        } else {
            const dropId = placment.manualOrder.drop.dropShiftId;
            const pickupId = placment.manualOrder.pickup.pickupShiftId;
            ids.push(+dropId);
            ids.push(+pickupId);
        }
        return ids;
    }, [
        placment.autoOrder.shiftId,
        placment.manualOrder.drop.dropShiftId,
        placment.manualOrder.pickup.pickupShiftId,
    ]);

    useEffect(() => {
        if (!formState.isOpen) return;

        const updatedDates: DateDataForOrdering[] = [];
        const datesAvailabilityStatus: DateAvailabilityStatus[] = [];

        for (let i = 0; i < initialDatesForOrders.length; i++) {
            const currDate = initialDatesForOrders[i];

            const dateSelectabilityConfig = getSelectableConfig(
                currDate.date,
                blockedOrdersDatesForPassengers,
                selectedPassengersFullData.map((p) => p?.passId),
                shifts,
                selectedShiftsIds
            );

            datesAvailabilityStatus.push(dateSelectabilityConfig);

            const { status, reason } = dateSelectabilityConfig;
            updatedDates.push({
                ...currDate,
                selectableConfig: { status, reason },
            });
        }

        setDatesForOrderWithStatus(updatedDates);

        const blockedDays = datesAvailabilityStatus.filter(
            (dateAvailabilityStatus) => dateAvailabilityStatus.reason
        );

        tkDispatch(setBlockedDatesAction(blockedDays));
    }, [
        blockedOrdersDatesForPassengers,
        dispatch,
        initialDatesForOrders,
        placment,
        selectedPassengersFullData,
        selectedShiftsIds,
        shifts,
        formState.isOpen,
        tkDispatch,
    ]);

    return datesForOrderWithStatus;
};

export default useDatesStatus;

// const formattedInitialDate = useMemo(() => {
//     return isRelativeDate(relativeInitialDate)
//         ? relativeInitialDate
//         : moment(relativeInitialDate, SHORT).format(RELATIVE);
// }, [relativeInitialDate]);

// const { startDate, endDate } = useMemo(() => {
//     return rangeDate(formattedInitialDate, DAYS_FOR_ORDERS_RANGE.PLACEMENT_WIDGET, firstDayOfWeek);
// }, [firstDayOfWeek, formattedInitialDate]);
