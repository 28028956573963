import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Position } from 'src/types/global';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { stringifyDate } from 'src/utilis/utilis';
import moment from 'moment';
import { RELATIVE, DISPLAY_UI_LONG } from 'src/constants/dates';
import * as Styles from './SingleProgressBar.Style';

interface Props {
    totalCount: number;
    position: keyof typeof Position;
    label: string;
    max: number;
    date: string;
    code: string;
}
interface TollTopContentProps {
    totalCount: number;
    date: string;
    label: string;
}

const TollTopContent: FC<TollTopContentProps> = ({ totalCount, date, label }) => {
    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'right' }}>
            <div>{`${t('infoFor')} ${date}`}</div>
            <Styles.TooltipLable>{`${label} - ${totalCount}`}</Styles.TooltipLable>
        </div>
    );
};

const SingleProgressBar: FC<Props> = ({ totalCount, position, label = '', max, date, code }: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    return (
        <>
            <Styles.Container position={position} data-for={code} data-tip>
                <Styles.Label>{label}</Styles.Label>
                <Styles.Progress
                    count={totalCount}
                    color="#81B1D8"
                    position={position}
                    withBottomRadius={false}
                    height={(totalCount / max) * 99}
                />
                <Styles.Count>{totalCount}</Styles.Count>
            </Styles.Container>
            <ReactTooltip id={code} place="right" type="light" borderColor="gray" border>
                <TollTopContent
                    label={label}
                    totalCount={totalCount}
                    date={stringifyDate(moment(date, RELATIVE), DISPLAY_UI_LONG)}
                />
            </ReactTooltip>
        </>
    );
};

export default SingleProgressBar;
