/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import DATE_FORMATS from 'src/constants/dates';
import { stringifyDate } from 'src/utilis/utilis';
import { getDetailedOrdersReportColumnsWidth as getColWidth, getFieldValueByLocalStorage } from './utils';
import useColumnsFromPrevSession from './useColumnsFromPrevSessions';

enum ColIds {
    PassId = 'col-detailedOrders-passId',
    FullName = 'col-detailedOrders-fullName',
    DepartmentName = 'col-detailedOrders-departmentName',
    City = 'col-detailedOrders-city',
    StreetWithHouseNum = 'col-detailedOrders-streetWithHouseNum',
    ShiftPickupTime = 'col-detailedOrders-shiftPickupTime',
    RideType = 'col-detailedOrders-rideType',
    RelativeDate = 'col-detailedOrders-relativeDate',
}

const useDetailedOrdersReportColumns = (): {
    columns: Array<GridColumnProps>;
} => {
    const { t } = useTranslation();
    const { localStorageCols } = useColumnsFromPrevSession();

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'passId',
                title: t('passId'),
                id: ColIds.PassId,
                minResizableWidth: 50,
                headerClassName: 'broken-header-col',
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.PassId,
                    'orderIndex',
                    undefined
                ),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.PassId, 'width', 70),
            },
            {
                field: 'fullName',
                title: t('fullName'),
                minResizableWidth: 50,
                id: ColIds.FullName,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.FullName,
                    'width',
                    getColWidth('passName')
                ),
                orderIndex: getFieldValueByLocalStorage(localStorageCols, ColIds.FullName, 'orderIndex', 1),
            },
            {
                field: 'departmentName',
                id: ColIds.DepartmentName,
                title: t('department'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DepartmentName,
                    'orderIndex',
                    2
                ),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.DepartmentName, 'width', 70),
            },
            {
                field: 'city',
                id: ColIds.City,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.City, 'width', 100),
                title: t('city'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.City,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'streetWithHouseNum',
                id: ColIds.StreetWithHouseNum,
                title: t('street'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.StreetWithHouseNum,
                    'orderIndex',
                    undefined
                ),
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.StreetWithHouseNum,
                    'width',
                    getColWidth('street')
                ),
            },
            {
                field: 'shiftPickupTime',
                id: ColIds.ShiftPickupTime,
                title: t('shiftTime'),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.ShiftPickupTime, 'width', 120),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.ShiftPickupTime,
                    'orderIndex',
                    undefined
                ),
                minResizableWidth: 50,
            },
            {
                field: 'rideType',
                id: ColIds.RideType,
                title: t('rideType'),
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.RideType,
                    'width',
                    getColWidth('rideType')
                ),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.RideType,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'relativeDate',
                id: ColIds.RelativeDate,
                title: t('date'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.RelativeDate, 'width', 80),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.RelativeDate,
                    'orderIndex',
                    undefined
                ),
                cell: (cellProps) => (
                    <td style={{ padding: '0 5px 0 0' }}>
                        <div>
                            {cellProps.dataItem.relativeDate
                                ? stringifyDate(cellProps.dataItem.relativeDate, DATE_FORMATS.DISPLAY_UI)
                                : ''}
                        </div>
                    </td>
                ),
            },
        ],
        [localStorageCols, t]
    );

    return { columns };
};

export default useDetailedOrdersReportColumns;
