import React, { FC, useEffect } from 'react';
import { IRootReducer } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { passengerSelector } from 'src/store/selectores/passengersSelector';
import { containers } from 'src/Layouts/styles';
import Passengers from './components/Passengers/Passengers';
import styles from './PassengerManger.style';
import PassengerWidgetLayout from './components/PassengerWidgetLayout/PassengerWidgetLayout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const PassengerManger: FC<Props> = () => {
    return (
        <styles.Container>
            <containers.MainSectionContainer className="MainSectionContainer">
                {/* <styles.GridItem item xs={12}> */}
                <styles.LinesContainer className="LinesContainer">
                    <Passengers />
                </styles.LinesContainer>
                {/* </styles.GridItem> */}
            </containers.MainSectionContainer>
            <containers.WidgetsContainer isOpen className="hidden-scrollbar WidgetsContainer">
                <PassengerWidgetLayout />
            </containers.WidgetsContainer>
        </styles.Container>
    );
};

export default PassengerManger;
