import React, { useCallback } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';

type AuthPayload = {
    dbUrl: string;
    token: string;
};

export const useProxyApi = () => {
    const { selectedFcAccount, token = '' } = useAppSelector((state: IRootReducer) => loginSelector(state));
    const { dbUrl = '' } = selectedFcAccount || {};

    const config = React.useMemo(
        () => ({
            dbUrl,
            token,
        }),
        [token, dbUrl]
    );

    const proxyApi = useCallback(
        <T extends {}, N>({ cb, restParams }: { cb: (param: T & AuthPayload) => N; restParams: T }) => {
            return cb({
                dbUrl: dbUrl || '',
                token: token || '',
                ...restParams,
            });
        },
        [token, dbUrl]
    );

    return {
        proxyApi,
        config,
        isValidConfig: config.dbUrl && config.token ? true : false,
    };
};

export default useProxyApi;
