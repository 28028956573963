/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootReducer } from 'src/store/reducers';

import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    editShiftSelector,
    formSelector,
    selectedSingleShiftSelector,
    shiftsSelector,
    tableSelector,
    uiSelector,
} from 'src/store/selectores/passengerShiftsSelectores';
import moment, { Moment } from 'moment';

import {
    onFormShowChange,
    removePassengersShift,
    setEditShift,
    setSelectedAllPassengers,
} from 'src/store/actions/PassengersShiftActionType';
import {
    IEditShift,
    IPassengerShiftUpdate,
    SetPassengersResponseCodes,
} from 'src/types/manualOrders/api.types';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { delPassngerShifts, setPassengersOrders } from 'src/api/manualOrderApi/manualOrderApi';
import Alert from 'src/components/commons/Alert/Alert';
import useStateReset from 'src/hooks/useStateReset';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { DISPLAY_UI, SHORT } from 'src/constants/dates';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    displayPlacementFormAction,
    passengersOrdersCountPerDateSelector,
    screenClientCodeSelector,
    selectedAddressForPlacementSelector,
    selectedPassengersSelector,
    setPlacementAddressAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { PASSENGER_ID_INDEX } from 'src/store/slices/manualOrders/helpers';
import { useErrorMessageDispatcher } from 'src/hooks/useErrorHandler';
import { manualOrdersApiConsts } from 'src/api/manualOrderApi/constants';
import { findNextIdByCellIndex } from '@progress/kendo-react-data-tools/dist/npm/navigation/utils';
import { AxiosResponse } from 'axios';
import { Slide } from '@mui/material';
import i18n from 'src/i18n';
import { manualOrdersConstants as constants } from 'src/constants/byScreen/manualOrders/components';
import { ManualPlacement as ManualPlacementObj } from 'src/screens/ManualOrder/utilis';
import * as Styles from './PlacemenTransportation.Style';
import Regularshiftplacement from './components/Regularshiftplacement/Regularshiftplacement';
import { initialAutoOrderState, initialManualOrderState, initialState } from './context';
import ManualPlacement from './components/Manualplacement/Manualplacement';
import MoreDays from './components/Moredays/MoreDays';
import {
    deepClone,
    formatDateToString,
    formatDateToString as stringifyDate,
} from '../../../../utilis/utilis';
import { RELATIVE } from '../../../../constants/dates';
import OrderDatesDetails from './components/OrderDatesDetails/index';
import { buildPlacementShiftObj, findFirstNonPastDate, getUpdatedOrdersCount } from '../../utilis';
import usePlacement from '../../hook/usePlacement';
import { ShiftType } from '../../types';
import { useIsSubmitDisabled } from './hooks/useIsSubmitDisabled';
import useDisableDelay from '../../../../hooks/useDelayV2';
import { Order, placementTransportationHooks } from './hooks/common';
import { PASSENGER_LOCATION_INDEX } from '../../../../store/slices/manualOrders/helpers';
import { manualOrdersCommonHooks } from '../../hook/manualOrdersHooks.common';
import { buildAddresseesParams } from './utilis';
import PlacementAddressSelection from './components/PlacementAddressSelection/index.PlacementAddressSelection';

const SUCCESS = 0;

export enum TABS {
    AUTO = 0,
    MANUAL = 1,
}

const ERROR_STINGS = {
    1: i18n.t('invalidToken'),
    2: i18n.t('dateOrTimeBeforeCurrent'),
    3: i18n.t('invalidDepartment'),
    4: i18n.t('sqlError'),
    5: i18n.t('blockedPickupUp'),
    6: i18n.t('blockedDrop'),
    7: i18n.t('blockedForToday'),
    8: i18n.t('blockedForTomorrow'),
    9: i18n.t('blockedForWeekend'),
    10: i18n.t('blockedForSunday'),
    11: i18n.t('clientNotFound'),
};

interface Props {
    date: Date | Moment;
    handleClose: () => void;
    editShift?: IEditShift;
    selectedClinet: any;
}
const PlacemenTransportation: FC<Props> = ({
    date,
    handleClose,
    editShift,
    selectedClinet: selectedClient,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    placementTransportationHooks.useDataReFetchingForPlacement();

    const [dispatchErrorMsg] = useErrorMessageDispatcher();

    const { onClickClose: onClikClose } = manualOrdersCommonHooks.usePlacementFormUtils();

    // & Contexts
    const { placment: placement, setPlacment, resetToInitialShiftData } = usePlacement();

    // & Local states
    const [selctedTab, setSelectedTab] = useState<TABS>(TABS.AUTO);

    const [errorState, setErrorState] = useState<{
        isShow: boolean;
        message: string;
        severity: 'error' | 'success';
    }>({
        isShow: false,
        message: '',
        severity: 'error',
    });

    // & Selectors
    const selectedAddresses = useRootAppSelector(selectedAddressForPlacementSelector);

    const relativeDateOfCurrShiftCell: string = useSelector((state: IRootReducer) => {
        if (selectedSingleShiftSelector(state)?.relativeDate) {
            return moment(selectedSingleShiftSelector(state)?.relativeDate || '', DISPLAY_UI).format(
                RELATIVE
            );
        }
        if (editShiftSelector(state)?.date) {
            return stringifyDate(editShiftSelector(state)?.date || '', RELATIVE);
        }
        return findFirstNonPastDate(moment(tableSelector(state).dateRange.startingDate, SHORT));
    });
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const passengers = useSelector((state: IRootReducer) => state.passengersShiftReudcer.passengers);
    // -- Shake logic
    const blockedTableClickCount: number = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form.blockedTableClickCount
    );
    const countWithDelay = useStateReset(blockedTableClickCount, 0, 100);

    const editShiftRedux = useSelector((state: IRootReducer) => editShiftSelector(state));
    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));
    const selectedManualShift = useSelector(
        (state: IRootReducer) => uiSelector(state).form.selectedManualShift
    );
    const clientCode = useRootAppSelector(screenClientCodeSelector);
    const editingShift = useSelector((state: IRootReducer) => state.passengersShiftReudcer.editShift);
    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    placementTransportationHooks.useEditedShiftAsInitialManualOrderData({
        editShift,
        selectedTab: selctedTab,
    });

    useEffect(() => {
        // Reset selected address on state to avoid display bad addresses
        dispatch(setPlacementAddressAction(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, JSON.stringify(selectedPassengers)]);

    // & Global variables
    const selectedPassengersFullData = useMemo(() => {
        return selectedPassengers.map(([, index]) => passengers[index]);
    }, [passengers, selectedPassengers]);

    // & Event handlers

    const handleChange = (e: any, newValue: number) => {
        setSelectedTab(newValue);
        resetToInitialShiftData(selctedTab === 0 ? ShiftType.Auto : ShiftType.Manual, true);
    };

    const handleResponse = (data: {
        message: IPassengerShiftUpdate[];
        response: SetPassengersResponseCodes;
    }) => {
        try {
            if (data.response === SUCCESS) {
                setErrorState({
                    message: t('successOrder'),
                    isShow: true,
                    severity: 'success',
                });

                setTimeout(() => {
                    dispatch(
                        setSelectedAllPassengers({
                            isSelected: false,
                            passengers: [],
                        })
                    );
                    onClikClose(handleClose);
                    dispatch(setEditShift(null));
                }, 1);
            } else {
                setErrorState({
                    message:
                        String(data.response) === '99'
                            ? JSON.stringify(data.message)
                            : ERROR_STINGS[data.response],
                    isShow: true,
                    severity: 'error',
                });
            }
        } catch (error) {
            // logToLocalStorage(error, 'thrown error');
            setErrorState({
                message: t('genralError'),
                isShow: true,
                severity: 'error',
            });
        }
    };

    const [tempDisabled, setTempDisabled] = useDisableDelay(3000);

    const changedOrderData = placementTransportationHooks.useChangedOrderData({
        isAutoOrder: selctedTab === TABS.AUTO,
        placement,
    });

    const currOrders: Order = useMemo(() => changedOrderData.getCurrOrder(), [changedOrderData.getCurrOrder]);

    const onSubmitAuto = async () => {
        try {
            const { proxyUrl, dbUrl = '' } = selectedFcAccount || {};
            const { pickupTime, dropTime, shiftId } = placement.autoOrder;

            setTempDisabled(true);

            const res = await setPassengersOrders({
                proxyUrl,
                dbUrl,
                jsonData: {
                    token,
                    passengers: selectedPassengers.map((p) => p[PASSENGER_ID_INDEX]).join(','),
                    pickupTime,
                    shiftId,
                    dropTime,
                    dates: placement.dates,
                },
                ...buildAddresseesParams(selectedAddresses),
                clientCode: selectedClient.value,
            });

            handleResponse(res.data);
        } catch (error) {
            console.error(error);
            setErrorState({
                message: t('genralError'),
                isShow: true,
                severity: 'error',
            });
        }
    };

    const onSubmitManual = async () => {
        try {
            setTempDisabled(true);

            const { pickupTime, pickupShiftId } = placement.manualOrder.pickup;
            const { dropTime, dropShiftId } = placement.manualOrder.drop;

            if (!pickupShiftId && !dropShiftId) return;

            const { proxyUrl, dbUrl = '' } = selectedFcAccount || {};
            const basePayload = {
                proxyUrl,
                dbUrl,
                jsonData: {
                    token,
                    passengers: selectedPassengers.map((p) => p[PASSENGER_ID_INDEX]).join(','),
                    dates: placement.dates,
                },
            };

            const responses: {
                pickup: null | AxiosResponse<{
                    message: IPassengerShiftUpdate[];
                    response: SetPassengersResponseCodes;
                }>;
                drop: null | AxiosResponse<{
                    message: IPassengerShiftUpdate[];
                    response: SetPassengersResponseCodes;
                }>;
            } = {
                pickup: null,
                drop: null,
            };

            // If selected pickup AND [ deleted pickup OR not had pickup to delete ]
            // if (
            //     currOrders.pickup.time &&
            //     (changedOrderData.deletedOrder?.pickupTime || !editShift?.pickupTime)
            // ) {
            let madeSetOrderRequests = false;

            if (
                currOrders.pickup.time &&
                (changedOrderData.deletedOrder?.pickupTime || !editShift?.pickupTime) &&
                pickupShiftId &&
                pickupShiftId !== String(constants.FILLER_ID)
            ) {
                madeSetOrderRequests = true;
                responses.pickup = await setPassengersOrders({
                    ...basePayload,
                    jsonData: {
                        ...basePayload.jsonData,
                        pickupTime: currOrders.pickup.time,
                        shiftId: currOrders.pickup.id,
                    },
                    ...buildAddresseesParams(selectedAddresses),
                    clientCode: selectedClient.value,
                });
            }

            // If selected drop AND [ deleted drop OR not had drop to delete ]
            // if (currOrders.drop.time && (changedOrderData.deletedOrder?.dropTime || !editShift?.dropTime)) {
            if (
                currOrders.drop.time &&
                (changedOrderData.deletedOrder?.dropTime || !editShift?.dropTime) &&
                dropTime &&
                dropShiftId !== String(constants.FILLER_ID)
            ) {
                madeSetOrderRequests = true;
                responses.drop = await setPassengersOrders({
                    ...basePayload,
                    jsonData: {
                        ...basePayload.jsonData,
                        dropTime: currOrders.drop.time,
                        shiftId: currOrders.drop.id,
                    },
                    ...buildAddresseesParams(selectedAddresses),
                    clientCode: selectedClient.value,
                });
            }

            const handleBadResponse = (data: {
                message: IPassengerShiftUpdate[] | string;
                response: SetPassengersResponseCodes;
            }) => {
                setTempDisabled(false);
                setErrorState({
                    message:
                        String(data.response) === '99'
                            ? JSON.stringify(data.message)
                            : ERROR_STINGS[data.response],
                    isShow: true,
                    severity: 'error',
                });
            };

            // -- If one of the calls failed, display an error,
            // -- otherwise handle both the responses
            if (responses.pickup && +responses.pickup.data.response !== SUCCESS) {
                handleBadResponse(responses.pickup.data);
                return;
            }
            if (responses.drop && +responses.drop.data.response !== SUCCESS) {
                handleBadResponse(responses.drop.data);
                return;
            }

            if (responses.pickup) handleResponse(responses.pickup.data);
            if (responses.drop) handleResponse(responses.drop.data);
            if (!madeSetOrderRequests) {
                const mockOkResponse = {
                    response: 0,
                    message: [] as any[],
                } as const;
                handleResponse(mockOkResponse);
            }
        } catch (error) {
            console.log(error);
            setErrorState({
                message: t('genralError'),
                isShow: true,
                severity: 'error',
            });
        }
    };

    // console.log({ deletedOrder: changedOrderData.deletedOrder });

    const deleteOldOrder = async () => {
        const { codes = [], passId = '' } = editingShift || {};
        const orderToDelete = changedOrderData.deletedOrder;

        if (!orderToDelete) return;

        if (selectedFcAccount && clientCode) {
            const reqPayload = {
                dbUrl: selectedFcAccount.dbUrl,
                proxyUrl: selectedFcAccount?.proxyUrl,
                orders: [orderToDelete.pickupCode, orderToDelete.dropCode].join(','),
                relative_date: moment(date).format('YYYY-MM-DD'),
                pass_id: passId,
                token,
                pickupTime: orderToDelete.pickupTime,
                dropTime: orderToDelete.dropTime,
                clientCode,
            };

            try {
                const {
                    data: { response },
                } = await delPassngerShifts(reqPayload);

                if (+response === SUCCESS) {
                    if (!editShift)
                        dispatch(
                            removePassengersShift({
                                passId,
                                orderCodes: codes,
                            })
                        );
                    return 0;
                }

                const errorMessage =
                    manualOrdersApiConsts.EDIT_DEL_ORDERS_REQ_ERROR_STINGS[response] ||
                    t('oopsSomethingWentWrongTryAgain');

                dispatchErrorMsg(errorMessage);
            } catch (error) {
                console.log(error);
                dispatchErrorMsg(`ERROR - ${t('oopsSomethingWentWrongTryAgain')}`);
            }
            return -1;
        }
    };

    const onSubmit = async () => {
        let deleteSuccess = true;

        if (editingShift) {
            deleteSuccess = (await deleteOldOrder()) === 0;
        }

        if (!deleteSuccess) return;

        if (selctedTab === TABS.AUTO) onSubmitAuto();
        else if (selctedTab === TABS.MANUAL) onSubmitManual();
    };

    const handleCloseAlert = () => setErrorState((pre) => ({ ...pre, isShow: false }));

    // & utils
    const disabledSubmit = useIsSubmitDisabled(selctedTab, selectedPassengersFullData);

    const getPassengersDetails = useCallback(() => {
        if (selectedPassengers.length === 1) {
            try {
                const idx = selectedPassengers[0][PASSENGER_LOCATION_INDEX];
                return passengers[idx]?.fullName;
            } catch (err) {
                console.log(err);
                return '';
            }
        }

        return `${selectedPassengers.length} ${t('passengers')}`;
    }, [passengers, selectedPassengers, t]);

    // & Effects

    const isInShortFormat = (dateStr: string): boolean => dateStr.length === 8;

    useEffect(() => {
        if (!relativeDateOfCurrShiftCell) return;
        // console.log(523);

        setPlacment((place) => ({
            ...place,
            dates: [
                {
                    relativeDate: stringifyDate(
                        relativeDateOfCurrShiftCell,
                        RELATIVE,
                        isInShortFormat(relativeDateOfCurrShiftCell) ? SHORT : RELATIVE
                    ),
                },
            ],
        }));
    }, [relativeDateOfCurrShiftCell, setPlacment]);

    // useEffect(() => {
    //     if (date) setInitalDate(date);
    // }, [date]);

    useEffect(() => {
        if (editShiftRedux) {
            const { pickupTime = '', dropTime = '' } = editShiftRedux;
            // resetPlacement();
            const editedShiftDateRelative = formatDateToString(editShiftRedux.date, RELATIVE);

            if (!selectedManualShift) {
                const { shiftId } = shifts.find((shift) => shift.pickupTime === pickupTime) || {};
                setSelectedTab(TABS.AUTO);
                setPlacment((pre) => ({
                    ...pre,
                    autoOrder: {
                        shiftId: `${shiftId}`,
                        pickupTime,
                        dropTime,
                    },
                    manualOrder: initialManualOrderState,
                    dates: [
                        {
                            relativeDate: editedShiftDateRelative,
                        },
                    ],
                }));
            } else if (selectedManualShift) {
                const { shiftId: pickupShiftId } =
                    shifts.find((shift) => shift.pickupTime === pickupTime) || {};
                const { shiftId: dropShiftId } = shifts.find((shift) => shift.dropTime === dropTime) || {};

                setPlacment((pre) => ({
                    ...pre,
                    autoOrder: initialAutoOrderState,
                    manualOrder: {
                        pickup: {
                            pickupShiftId: pickupTime ? `${pickupShiftId}` : '',
                            pickupTime,
                        },
                        drop: {
                            dropShiftId: dropTime ? `${dropShiftId}` : '',
                            dropTime,
                        },
                    },
                    dates: [
                        {
                            relativeDate: editedShiftDateRelative,
                        },
                    ],
                }));
                setSelectedTab(TABS.MANUAL);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editShift, shifts, setPlacment, editShiftRedux]);

    // & Returned JSX

    return (
        // eslint-disable-next-line react/jsx-boolean-value
        <Slide in unmountOnExit direction="up">
            <Styles.Container
                blockedTableClickCount={countWithDelay}
                className={blockedTableClickCount ? 'container shake' : 'container'}
            >
                {/* TITLE AND TIME */}
                <Styles.Title>
                    {t('placemenTransportation')}
                    <span>{`${t('for')} ${getPassengersDetails()}`}</span>
                </Styles.Title>
                <Styles.Hr />
                <OrderDatesDetails />
                {/* <DatePickerWithPrevNext {...datePickerProps} /> */}
                <Styles.Hr />

                {/* TABS */}
                <Styles.StyledTabs
                    value={selctedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label={t('regularShiftPlacement')} />
                    <Tab label={t('manualPlacement')} />
                </Styles.StyledTabs>

                {/* SHIFT PICK  */}
                <Styles.TabContainer>
                    {selctedTab === TABS.AUTO && <Regularshiftplacement shifts={shifts} />}
                    {selctedTab === TABS.MANUAL && <ManualPlacement shifts={shifts} />}
                </Styles.TabContainer>

                {/* MORE DAYS INPUTS */}
                <Styles.DaysContainer>
                    <MoreDays
                        selectedPassengersFullData={selectedPassengersFullData}
                        // initialDate={initalDate}
                        // relativeInitialDate={relativeDateOfCurrShiftCell}
                    />
                </Styles.DaysContainer>

                {selectedPassengers.length === 1 && (
                    <PlacementAddressSelection
                        selectedPassengerCode={selectedPassengers[0][0]}
                        selectedTab={selctedTab === TABS.AUTO ? 'auto' : 'manual'}
                    />
                )}
                {/* BTNS */}
                <Styles.Footer>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        style={{ width: '183px', height: '44px' }}
                        onClick={onSubmit}
                        disabled={!!(disabledSubmit || tempDisabled || !selectedPassengersFullData.length)}
                        loading={tempDisabled}
                    >
                        {editShiftRedux ? t('update') : t('ok')}
                    </LoadingButton>
                    <Button onClick={() => onClikClose()}>{t('cancel')}</Button>
                </Styles.Footer>

                <Alert open={errorState.isShow} onClose={handleCloseAlert} severity={errorState.severity}>
                    {errorState.message}
                </Alert>
            </Styles.Container>
        </Slide>
    );
};

PlacemenTransportation.displayName = 'PlacemenTransportation';
export default PlacemenTransportation;
// useEffect(() => {
//    const { proxyUrl, dbUrl = '' } = selectedFcAccount || {};
//    const fromDate = moment(initalDate).format('YYYY-MM-DD'); // TODO
//    const toDate = moment(initalDate).format('YYYY-MM-DD'); // TODO

//    getShifts({ proxyUrl, dbUrl, token, fromDate, toDate }).then((res) => {
//       const { data = [] } = res.data;
//       setShifts(data);
//    });
// }, [selectedFcAccount, token, initalDate]);

// // * Closes component if there are no passengers selected
// useEffect(() => {
//    if (!selectedPassengersCount) onClikClose();
// }, [onClikClose, selectedPassengersCount]);

// // * component is mounted or not
// useEffect(() => {
//    isMounted.current = true;
//    return () => {
//       dispatch(onFormShowChange(false));
//       isMounted.current = false;
//    };
// }, [dispatch]);

// // * Closes form when component unmounts
// useEffect(() => {
//    if (!isFirstRender && isMounted.current === false)
//       dispatch(onFormShowChange(false));
// }, [dispatch, isFirstRender, isMounted]);

// useEffect(() => {
//     if (selectedPassengerIds.length > 1 && editShiftRedux) {
//         onClikClose();
//     }
// }, [editShiftRedux, onClikClose, selectedPassengerIds.length]);

// // * Open form when component mounts
// useEffect(() => {
//     // console.log(31531);
//     // if (!isFormOpen && selectedPassengers.length) {
//     //     dispatch(
//     //         onFormShowChange({
//     //             isOpen: true,
//     //             selectedPassengersIds: selectedPassengers.map((psngr) => psngr[PASSENGER_ID_INDEX]),
//     //         })
//     //     );
//     // }
// }, [dispatch, isFormOpen, selectedPassengers]);

// useEffect(() => {
//     if (!selectedPassengers.length) onClikClose(handleClose);
// }, [handleClose, onClikClose, selectedPassengers.length]);
