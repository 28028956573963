import React from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/styles';
import styled from 'styled-components';
import GoBackArrowIcon from 'src/components/Icons/GoBackArrowIcon';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import {
    addPassengerFormDisplayChangeAction,
    newPassengerFormSelector,
    passengersFormSelector,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { useCurrFormType } from '../addLinesForm.hooks';

const StyledHeader = styled.div`
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    // line-height: 33px;
    display: flex;
    // height: 33px;
    align-items: center;
    justify-content: space-between;
`;

// ** Is used on all sub-forms headers
export const localStyles = {
    mainHeaderStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    StyledHeader,
};

const getHeaderText = (
    isAddingNewPassenger: boolean,
    isForExistingStation: boolean,
    isNewPassengerFormOpen: boolean
) => {
    if (isNewPassengerFormOpen) {
        return isAddingNewPassenger ? 'addingPassenger' : 'passengerEditing';
    }
    return isForExistingStation ? 'addingPassengersToStation' : 'addingPassengers';
};

const TitleText = (props: {}) => {
    const { isOpen: isNewPassengerFormOpen, isAddingNewPassenger } = useAppSelector((state: any) =>
        newPassengerFormSelector(state)
    );

    const isForExistingStation = useAppSelector((state: any) => !!passengersFormSelector(state).stationId);
    const { t } = useTranslation();
    return (
        <p>
            {t(
                getAsTemplateStrArr(
                    getHeaderText(isAddingNewPassenger, isForExistingStation, isNewPassengerFormOpen)
                )
            )}
        </p>
    );
};

type Props = { onCancel: () => void };

const PassengersFormHeader: React.FunctionComponent<Props> = ({ onCancel }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onGoBackToExistingPassengers = () => {
        dispatch(addPassengerFormDisplayChangeAction({ isOpen: false }));
    };

    const currForm = useCurrFormType();

    return (
        <localStyles.StyledHeader>
            <div style={localStyles.mainHeaderStyle}>
                <styles.GoBackBtn
                    onClick={currForm === 'newPassenger' ? onGoBackToExistingPassengers : onCancel}
                >
                    <GoBackArrowIcon />
                </styles.GoBackBtn>
                <TitleText />
            </div>
            <DisplayWrapper
                show={
                    currForm === 'addingPassengersToStation' || currForm === 'creatingStationsFromPassengers'
                }
            >
                <AddIconNoText
                    disabled={false}
                    onClick={() =>
                        dispatch(
                            addPassengerFormDisplayChangeAction({
                                isOpen: true,
                            })
                        )
                    }
                />
            </DisplayWrapper>
        </localStyles.StyledHeader>
    );
};

export default PassengersFormHeader;
