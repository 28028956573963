import { useFormContext, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DropDownV2 from 'src/components/DropDown/DropDownV2';
import { fullFormHooks } from 'src/screens/Main/components/AddLineForm/components/formSections/hooks.FullForm';
import React, { ReactNode } from 'react';

// T is inputs type

interface Props<T> {
    fieldName: Path<T>;
}

const CarTypeDropdownV2 = <T extends Record<string, unknown>>(props: Props<T> & { children?: ReactNode }) => {
    const { fieldName } = props;

    const { t } = useTranslation();
    const { menuItems } = fullFormHooks.useCarTypes();

    const fullFormMethods = useFormContext<T>();
    const {
        formState: { errors },
        control,
        getValues,
    } = fullFormMethods;

    const shrink = !!getValues(fieldName);

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
                <DropDownV2
                    {...{
                        labelName: t('wantedCarType'),
                        fullWidth: true,
                        formControlProp: {
                            style: { width: '100%' },
                            variant: 'outlined',
                            size: 'small',
                        },
                        selectProps: {
                            ...field,
                            menuItems,
                            notched: shrink,
                            id: 'car-type-dropdown-select',
                        } as any,
                        inputLabelProps: {
                            shrink,
                        },
                    }}
                />
            )}
        />
    );
};

export default CarTypeDropdownV2;
