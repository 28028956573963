import { ChangeEventValue, Coords, fitBounds } from 'google-map-react';
/* eslint-disable no-param-reassign */

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import styles from './styles';
import MapType2 from 'src/components/Map/MapType2/MapType2';
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';
import React, { useState, ReactChild, useEffect, useRef } from 'react';
import {
    setIsDirectionsTriggeredFromEditAction,
    setIsFloatMapExpandedAction,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { useFormContext } from 'react-hook-form';
import { Inputs, Station } from '../types';
import useDirectionsApiLines, {
    isAllObjectsContainsCoords,
} from 'src/screens/Main/hooks/useDirectionsApiLines';
import { mapHelpers } from 'src/screens/CoursesBuilding/components/CourseForm/utils/common';
import {
    DistanceDisplay,
    DurationDisplayWindow,
} from 'src/screens/CoursesBuilding/components/CourseForm/CourseBuildingMap/windows';
import { triggerDirectionsApiAction } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

export const defaultCenter: Coords = {
    lat: 31.896997821344108,
    lng: 34.80000972747803,
};

const DailyRideFloatMap: React.FunctionComponent<{}> = () => {
    const { rendererProps } = useDirectionsApiLines();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>(defaultCenter);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const { triggerDirections, isInjectionCompleted } = useRootAppSelector(
        (state) => state.linesSlice.ui.form
    );

    const isFloatMapExpanded = useRootAppSelector((state) => state.linesSlice.ui.form.isFloatMapExpanded);
    const { endAddress, startAddress, stations } = useRootAppSelector(
        (state) => state.linesSlice.ui.form.data
    );
    const myRef = useRef<HTMLInputElement | null>(null);

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        onLoad: (googleMap) => {
            // Saw this on official docs, not doing anything with it yet
            // setMap(googleMap);
            // dispatch(triggerDirectionsAction());
            setMap(googleMap);
        },
        onUnmount: () => {
            // Saw this on official docs, not doing anything with it yet
            setMap(null);
        },
        options: {
            fullscreenControl: false,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    useEffect(() => {
        console.log({ map, isInjectionCompleted });
        if (map && isInjectionCompleted) {
            dispatch(triggerDirectionsAction());
            dispatch(setIsDirectionsTriggeredFromEditAction({ isFromEdit: false }));
        }
    }, [dispatch, isInjectionCompleted, map]);

    React.useEffect(() => {
        const allStations = [...stations, startAddress, endAddress];

        const isCoordsExistOnAllStations = isAllObjectsContainsCoords(allStations);

        if (isCoordsExistOnAllStations && allStations.length > 1 && myRef.current) {
            // Calculate the bounding box of the selected waypoints

            const bounds = (allStations as Station[]).reduce((acc, { lat, lng }) => {
                const latLng = new google.maps.LatLng(lat ?? 0, lng);
                const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                return acc.extend(coords);
            }, new google.maps.LatLngBounds());

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const neCoords: Coords = {
                lat: ne.lat(),
                lng: ne.lng(),
            };

            const swCoords: Coords = {
                lat: sw.lat(),
                lng: sw.lng(),
            };

            const rect = myRef.current?.getBoundingClientRect();

            if (rect) {
                const fittedBounds = fitBounds(
                    { ne: neCoords, sw: swCoords },
                    { width: rect.width, height: rect.height }
                );

                setCenter(fittedBounds.center);
                setZoom(fittedBounds.zoom);
            }
        }
    }, [stations, startAddress, endAddress]);

    const getTotals = () => {
        const relevantStations = [...stations, endAddress];

        const totalTime = relevantStations.reduce((acc, station) => {
            if (station && station.timing) {
                acc += station.timing;
            }
            return acc;
        }, 0);

        const totalDistance = relevantStations.reduce((acc, station) => {
            if (station && station.distanceFromPrevStation) {
                acc += station.distanceFromPrevStation;
            }
            return acc;
        }, 0);

        return { totalTime, totalDistance };
    };

    return (
        <styles.Container className="Container" id="map-container-tamir">
            <styles.MapDiv className="MapDiv" isOpen={isFloatMapExpanded} ref={myRef}>
                {isAllObjectsContainsCoords([...stations, startAddress, endAddress]) && (
                    <div>
                        <DurationDisplayWindow totalTime={getTotals().totalTime} />
                        <DistanceDisplay
                            isMapExpanded={isFloatMapExpanded}
                            totalDistance={getTotals().totalDistance}
                        />
                    </div>
                )}
                <styles.ExpandMapIcon
                    style={{ position: 'absolute', zIndex: 999 }}
                    onClick={() => {
                        dispatch(setIsFloatMapExpandedAction({ isExpanded: !isFloatMapExpanded }));
                    }}
                >
                    <img style={{ width: '20px' }} src={styles.imgUrl} alt="expand-map" />
                </styles.ExpandMapIcon>

                <MapType2 GoogleMapProps={GoogleMapProps}>
                    {rendererProps ? <DirectionsRenderer {...rendererProps} /> : null}

                    {startAddress && startAddress.lat && startAddress.lng && (
                        <Marker
                            icon={{
                                url: mapHelpers.getIconUrl(0),
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 25),
                            }}
                            key={startAddress?.lat}
                            position={{ lat: startAddress.lat, lng: startAddress.lng }}
                        />
                    )}
                    {endAddress && endAddress.lat && endAddress.lng && (
                        <Marker
                            icon={{
                                url: mapHelpers.getIconUrl(0, true),
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 25),
                            }}
                            key={endAddress?.lat}
                            position={{ lat: endAddress.lat, lng: endAddress.lng }}
                        />
                    )}

                    {stations.map(
                        (station: Station, index) =>
                            station &&
                            station.lat &&
                            station.lng && (
                                <Marker
                                    icon={{
                                        url: mapHelpers.getIconUrl(index + 1),
                                        scaledSize: new window.google.maps.Size(30, 30),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(15, 25),
                                    }}
                                    key={station.lat}
                                    position={{ lat: station.lat, lng: station.lng }}
                                />
                            )
                    )}
                </MapType2>
            </styles.MapDiv>
        </styles.Container>
    );
};

export default DailyRideFloatMap;
