import { ReqConfig } from 'src/types/apiCommon.types';
import {
    DayRowCode,
    ISetCourseStationJson,
    ISetCourseTimesSettingsJsonItem,
    SetAccountCodeParams,
} from 'src/api/coursesApi/coursesApi';
import { isUuid, uuid, isTypeofNumber } from '../../utilis/utilis';
import { CourseStationTypes } from 'src/types/lines/api/types';
import { ICourseStation } from 'src/screens/CoursesBuilding/components/CourseInfoWidget/StationsAndTimesPanel';
import { StationSchema, TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import { DayNum } from '../../types/global';
import timeHelpers from '../../utilis/timeHelpers';
import { CourseBuildingTabs } from 'src/types/coursesBuilding/storeTypes';

export interface CourseStationField extends StationSchema {
    id?: string;
    placeName?: string;
    type?: '';
}
export interface CourseFormReqParams extends SetAccountCodeParams {
    stationsFields: CourseStationField[];
    allStations: ICourseStation[];
    times: TimesSettingsBox[];
}

export const courseFormErrorMessages = {
    CourseDetailsTabFailed: CourseBuildingTabs.Details,
    StationsTabsFailed: CourseBuildingTabs.CourseStationsBuilding,
    TimesSettingsTabFailed: CourseBuildingTabs.TimesAndFrequencySettings,
};

const getStationType = (index: number, stationCount: number): CourseStationTypes => {
    if (index === 0) return CourseStationTypes.Origin;
    if (index === stationCount - 1) return CourseStationTypes.Destination;
    return CourseStationTypes.Normal;
};

const getCity = (station: CourseStationField) => {
    if (station.placeName && station.city) {
        return `${station.placeName}, ${station.city}`;
    }
    if (station.placeName) {
        return station.placeName;
    }
    if (station.city) {
        return station.city;
    }
    return '';
};

const buildStationsJson = (
    updatedStations: CourseStationField[],
    originalStations: ICourseStation[]
): string => {
    const stations: ISetCourseStationJson[] = [];

    const createJsonStation = (
        station: ICourseStation | CourseStationField,
        index: number,
        isDeleted: boolean
    ): ISetCourseStationJson => {
        const type =
            isDeleted && 'type' in station
                ? (station.type as CourseStationTypes) || ('' as const)
                : getStationType(index, updatedStations.length);

        return {
            stationCode: isUuid(station.stationId) ? '' : station.stationId,
            city: station.city || '',
            street: station.street || '',
            house: station.houseNum || '',
            type,
            lat: station.lat,
            lon: station.lng,
            timing: isTypeofNumber(station.timeFromPrev) ? String(station.timeFromPrev) : null,
            index: isDeleted ? 0 : index + 1,
            isActive: isDeleted ? '0' : '1',
            stationRemark: station.stationRemark ? station.stationRemark : '',
        };
    };

    updatedStations.forEach((rawStation, index) => {
        const city = getCity(rawStation);
        const street = rawStation.street || '';
        const houseNum = rawStation.houseNum || '';
        const timeFromPrev = rawStation.timeFromPrev;

        const station: ISetCourseStationJson = createJsonStation(
            { ...rawStation, type: '', city, street, houseNum, timeFromPrev },
            index,
            false
        );

        stations.push(station);
    });

    originalStations.forEach((originalStation) => {
        // was not deleted
        if (
            updatedStations.some((updatedStation) => updatedStation.stationId === originalStation.stationId)
        ) {
            return;
        }
        // station was deleted so adding as inactive
        stations.push(createJsonStation(originalStation, 0, true));
    });

    return JSON.stringify(stations);
};

const buildTimesSettingsJson = (times: TimesSettingsBox[]): string => {
    const separatedByDays: ISetCourseTimesSettingsJsonItem[] = [];

    const getTimeStr = (t: string | Date) => {
        if (typeof t === 'string') {
            return t;
        }
        return timeHelpers.getDateTime(t);
    };

    times.forEach((timeBox) => {
        const { days, id, daysRowsCodes, ...rest } = timeBox;

        const handledDays: number[] = [];

        // Handling old days
        timeBox.daysRowsCodes.forEach((day) => {
            const dayWasRemoved = !timeBox.days.includes(+day.day);

            const timeItem = {
                ...rest,
                day: +day.day as DayNum,
                startTime: getTimeStr(rest.startTime),
                endTime: getTimeStr(rest.endTime),
                isActive: rest.isActive && !dayWasRemoved ? '1' : '0',
                code: day.code || null,
            } as const;

            // When day doesnt have a code it is considered active by BE
            if (!timeItem.code && timeItem.isActive === '0') return;

            separatedByDays.push(timeItem);

            handledDays.push(+day.day);
        });

        // Handling new days
        timeBox.days.forEach((dayNum) => {
            if (handledDays.includes(dayNum)) return;

            const timeItem = {
                ...rest,
                day: dayNum as DayNum,
                startTime: getTimeStr(rest.startTime),
                endTime: getTimeStr(rest.endTime),
                isActive: rest.isActive ? '1' : '0',
                code: null,
            } as const;

            if (!timeItem.code && timeItem.isActive === '0') return;

            separatedByDays.push(timeItem);
        });
    });

    // console.log('separatedByDays', separatedByDays);

    return JSON.stringify(separatedByDays);
};

const getReqConfigs = (initialConfig: ReqConfig<CourseFormReqParams>) => {
    const { requestParams } = initialConfig;
    const { stationsFields, times, courseCode, token, allStations, ...rest } = requestParams;

    const detailsConfig = { ...initialConfig, requestParams: { ...rest, courseCode, token } };

    const stationsConfig = {
        ...initialConfig,
        requestParams: {
            courseCode,
            jsonData: buildStationsJson(stationsFields, allStations),
            token,
        },
    };

    const timesConfig = {
        ...initialConfig,
        requestParams: { courseCode, jsonData: buildTimesSettingsJson(times), token },
    };

    return { stationsConfig, timesConfig, detailsConfig };
};

// Course times settings helpers
const getDaysNumsV2 = (days: DayRowCode[]): number[] => {
    const ALL_DAYS_KEY = '0';

    if (!days.length) return [];

    if (days[0].day === ALL_DAYS_KEY) {
        const ALL_DAYS_NUMS = [1, 2, 3, 4, 5, 6, 7];

        return ALL_DAYS_NUMS;
    }

    return days.map((day) => +day.day);
};

const getDaysRowsCodes = (days: DayRowCode[]): DayRowCode[] => {
    const ALL_DAYS_KEY = '0';

    if (!days.length) return [];

    if (days[0].day === ALL_DAYS_KEY) {
        const ALL_DAYS_NUMS = [1, 2, 3, 4, 5, 6, 7];
        return ALL_DAYS_NUMS.map((dayNum) => ({ day: String(dayNum), code: days[0].code }));
    }

    return days;
};

export const coursesRoutesHelpers = {
    getReqConfigs,
    getDaysRowsCodes,
    getDaysNumsV2,
};
