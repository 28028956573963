import React, { FC } from 'react';
import Autocomplete, { AutocompleteProps, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';

export type IAutocompleteChangeReason = AutocompleteChangeReason;
export type IComboBox = AutocompleteProps<any, boolean, boolean, boolean>;

const ComboBox: FC<IComboBox> = (prop: IComboBox) => {
    return <Autocomplete {...prop} />;
};

export default ComboBox;
