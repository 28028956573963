/* eslint-disable react/button-has-type */
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store/hooks';
import {
    resetStateAction,
    setSelectedClientCodeAction,
} from 'src/store/slices/generalFeatures/generalFeaturesSlice';
import CloseIcon from '@mui/icons-material/Close';
import hooks from '../hooks.uploadFiles';
import Styled from '../styles.uploadFile';
import SuccessModal from '../Components/SuccessModal';
import DropDown from 'src/components/DropDown/DropDown';
import { getClientFilterProp } from 'src/screens/RideSettings/components/props/props';
import useClientFilters from 'src/hooks/useClientFilter';
import UploadSection from '../Components/UploadSections';
import FileDisplaySection from '../Components/ExistingFileDisplaySection';
import DialogV2 from '../../../components/ConfirmationDialog/DialogV2';
import { ApiReqStatus } from 'src/store/slices/generalFeatures/types.generalFeatures';
import DropDownV2 from 'src/components/DropDown/DropDownV2';

const FEATURE_ID = 1;

const DialogTitle: React.FC<{ handleExitClick: () => void }> = ({ handleExitClick }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getFile = hooks.useGetFile();

    const [clientFilter, setClientFilter, isLoadingClients] = useClientFilters({
        routeScreenId: FEATURE_ID,
        isPrivateRoute: false,
    });

    const isModalOpen = hooks.useFileFtSlice().sliceState.ui.mainModal.isOpen;
    const clientCode = hooks.useFileFtSlice().sliceState.data.selectedClientCode;

    useEffect(() => {
        const newClientCodeVal = clientFilter.value ? String(clientFilter.value) : null;

        dispatch(setSelectedClientCodeAction(newClientCodeVal));
    }, [clientFilter.value, dispatch]);

    useEffect(() => {
        if (clientCode && isModalOpen) {
            // fetch client's file
            getFile.handleGetFile();
        }
    }, [isModalOpen, clientCode]);

    return (
        <Styled.Title className="Title">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {t('uploadFile')}
                <div style={{ padding: '0 15px 0 15px' }}>
                    <DropDownV2
                        {...getClientFilterProp({
                            t,
                            clinetFilter: clientFilter,
                            setClinetFilter: setClientFilter,
                            style: { width: '208px' },
                        })}
                        labelName="שם אתר"
                        isLoading={isLoadingClients}
                    />
                </div>
            </div>
            <IconButton onClick={handleExitClick}>
                <CloseIcon sx={{ color: '#494949' }} />
            </IconButton>
        </Styled.Title>
    );
};

const DialogBody: React.FC<{ withExistingFileDisplay: boolean }> = ({ withExistingFileDisplay }) => {
    return (
        <Styled.ModalBodyContainer
            style={withExistingFileDisplay ? { minHeight: '600px' } : {}}
            className="ModalBodyContainer"
        >
            <UploadSection withBorder={withExistingFileDisplay} />

            {withExistingFileDisplay ? <FileDisplaySection /> : <></>}
        </Styled.ModalBodyContainer>
    );
};

// Index
interface Props {}

const UploadFileModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const fileFtState = hooks.useFileFtSlice();

    const withExistingFileDisplay =
        !!fileFtState.sliceState.data.fileData &&
        fileFtState.sliceState.data.actionsStatus.get !== ApiReqStatus.Idle;

    const handleExitClick = () => {
        fileFtState.actions.toggleModal(null);
        dispatch(resetStateAction());
    };

    return (
        <>
            {fileFtState.sliceState.ui.mainModal.isOpen ? (
                <DialogV2
                    emptyActions
                    isDialogOpen={fileFtState.sliceState.ui.mainModal.isOpen}
                    onCloseDialog={handleExitClick}
                    onConfirmClick={() => {}}
                    DialogContent={<DialogBody withExistingFileDisplay={withExistingFileDisplay} />}
                    DialogTitle={<DialogTitle handleExitClick={handleExitClick} />}
                    disableBackdropClick
                    disableEscapeKeyDown
                    onEntering={() => {
                        // console.log('entering');
                    }}
                    PaperProps={{
                        style: withExistingFileDisplay ? { minWidth: '900px', minHeight: '700px' } : {},
                    }}
                />
            ) : null}
            <SuccessModal />
        </>
    );
};

export default UploadFileModal;
