import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_UI_LONG, RELATIVE } from 'src/constants/dates';
import { formatDateToString, getDateFormat } from 'src/utilis/utilis';
import { formSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { PlacemenContext } from '../../context';
import { StyledContainer } from './OrderDatesDetails.style';
import { RELATIVE_US } from '../../../../../../constants/dates';

interface Props {}

const Text = () => {
    const [placment] = useContext(PlacemenContext);
    const { t } = useTranslation();
    // const isShiftSelected = useSelector(
    //    (state: IRootReducer) => formSelector(state).isShiftSelected,
    // );
    const [detailsText, setDetailsText] = useState('');

    useEffect(() => {
        const { dates } = placment;
        if (
            !dates.length ||
            dates[0].relativeDate === 'Invalid date'
            // !isShiftSelected
        ) {
            setDetailsText('----.--.--');
        } else if (dates.length >= 2) {
            setDetailsText(`${t('pickedDays')}: ${dates.length}`);
        } else {
            setDetailsText(formatDateToString(dates[0].relativeDate, DISPLAY_UI_LONG, RELATIVE));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placment.dates, t]);
    return <div>{detailsText}</div>;
};

const OrderDatesDetails = (props: Props) => {
    const [placment] = useContext(PlacemenContext);

    return (
        <StyledContainer count={placment.dates.length}>
            <Text />
        </StyledContainer>
    );
};

export default OrderDatesDetails;
