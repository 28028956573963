import React from 'react';

const PseudoRelativeWrapper: React.FC<{
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}> = ({ children, left = 0, top = 0, bottom = 0 }) => {
    return (
        <div style={{ position: 'relative', width: 0, height: 0 }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: `${left}px`,
                    top: `${top}px`,
                    bottom: `${bottom}px`,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default PseudoRelativeWrapper;
