import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { centeredFlexItem } from 'src/style/styledCSS';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import CellEditBtn from 'src/components/KendoGridCutomeColumn/CellEditBtn';
import { iconButtonStyle } from 'src/components/styles/styles';
import {
    openFormAction,
    setEditModeAction,
    setEditedLineClientIdAction,
    setIsDirectionsTriggeredFromEditAction,
    toggleConfirmationDialogAction,
    toggleCopyRideModalAction,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch } from 'src/store/hooks';
import { setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import cotValidationHooks from 'src/hooks/reactHookForm/useCotValidation';
import moment from 'moment';
import { IEditLine, ILine } from 'src/types/line';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { useHistory } from 'react-router-dom';
import { getLineByCode } from 'src/api/api';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { AxiosResponse } from 'axios';
import { IResponse } from 'src/api/types';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { InputNames } from '../../AddLineForm/types';
import useSetValue from 'src/hooks/reactHookForm/useSetValue';
import { setLocalStorageValue } from 'src/utilis/utilis';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// export interface IKendoDeleteEditeCell extends GridCellProps {
//     onEdit?: (dataItem: any) => unknown;
//     onDelete?: (dataItem: any) => unknown;
//     handleSetIsViewingOptions?: any;
//     handleHover: (isHover: boolean) => {};
//     hover: boolean;
// }

enum IsCreatedByStudio {
    True = '1',
    False = '0',
    Unknown = '-1',
}
const useLineActionAuth = (dataItem: unknown) => {
    const { getClientCOTVal } = cotValidationHooks.useClientCOT();

    const lineData = 'lineCode' in Object(dataItem) ? (dataItem as ILine) : null;

    const isCreatedInStudio =
        'isCreatedByStudio' in Object(lineData) ? lineData?.isCreatedByStudio : IsCreatedByStudio.Unknown;

    const [isValidByCOT, setIsValidByCOT] = useState<null | boolean>(null);

    useEffect(() => {
        if (!lineData) return;

        const currTime = moment(new Date());

        const cot = getClientCOTVal(String(lineData.accountCode));

        const lineStartTime = moment(lineData.startTime);

        const minValidTime = currTime.clone().add(cot, 'minutes');

        setIsValidByCOT(lineStartTime.isAfter(minValidTime));
    }, [dataItem, getClientCOTVal]);

    return { isValidByCOT, isCreatedInStudio };
};
const DELETED_LINE_KEY = '*** נסיעה בוטלה ***';

export interface IKendoDeleteEditeCell {
    hoveredLineCode: string;
    cellProps: React.PropsWithChildren<GridCellProps>;
}

const OptionsCell: FunctionComponent<IKendoDeleteEditeCell> = ({
    hoveredLineCode: lineHovered,
    cellProps,
}: IKendoDeleteEditeCell) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { dataItem } = cellProps;
    const history = useHistory();
    const { token, selectedFcAccount } = useSelector((state: IRootReducer) => loginSelector(state));
    const dispatchAlert = useDispatchAlert();

    const { isValidByCOT, isCreatedInStudio } = useLineActionAuth(cellProps.dataItem);

    const isSameLineAsRow = lineHovered && lineHovered === String(cellProps.dataItem.lineCode) ? true : false;

    const isLineDeleted = dataItem.lineDescription.includes(DELETED_LINE_KEY);

    const showOptionsBtns = isCreatedInStudio === IsCreatedByStudio.True && isSameLineAsRow && !isLineDeleted;

    const handleResponse = (res: AxiosResponse<IResponse<IEditLine>>) => {
        if (res.data && res.data.response === '0') {
            dispatch(openFormAction(null));
            dispatch(setEditModeAction({ isOnEdit: true, lineOnEdit: res.data.data }));
            dispatch(setIsDirectionsTriggeredFromEditAction({ isFromEdit: true }));
            // dispatch(triggerDirectionsAction());

            // add /editLine to curr url
            history.push(routesEndpoints.EDIT_LINE);
        } else if (res.data && res.data.response === '1') {
            dispatchAlert('error', t('unknown'));
        } else {
            dispatchAlert('error', t('unknown'));
        }
    };

    const onEditLineClick = async () => {
        try {
            if (!selectedFcAccount || !selectedFcAccount.dbUrl || !dataItem || !dataItem.lineCode) return;

            if (dataItem.accountCode) {
                dispatch(setEditedLineClientIdAction({ editedLineClientId: dataItem.accountCode }));
            }

            const res = await getLineByCode({
                dbUrl: selectedFcAccount.dbUrl,
                token,
                lineCode: dataItem.lineCode,
            });

            handleResponse(res);
        } catch (error) {
            dispatchAlert('error', t('unknown'));
        }
    };

    const onCopyLineClick = async () => {
        dispatch(toggleCopyRideModalAction({ isOpen: true, dataItem }));
    };

    return (
        <td className="optionsCell">
            {showOptionsBtns ? (
                <div style={{ display: 'flex', gap: '5px' }}>
                    <MuiTooltipV3 title={t('copyRide')}>
                        <IconButton
                            onClick={(ev) => onCopyLineClick()}
                            style={iconButtonStyle}
                            aria-label="העתקה"
                            size="small"
                        >
                            <ContentCopyIcon sx={{ height: '18px', width: '18px' }} htmlColor="black" />
                        </IconButton>
                    </MuiTooltipV3>
                    <MuiTooltipV3 title={t('edit')}>
                        <IconButton
                            onClick={(ev) => onEditLineClick()}
                            style={iconButtonStyle}
                            aria-label="עריכה"
                            size="small"
                        >
                            <MyEditPencilIcon height="18px" color="" />
                        </IconButton>
                    </MuiTooltipV3>

                    <MuiTooltipV3 title={t('delete')}>
                        <IconButton
                            onClick={() => {
                                if (!isValidByCOT) {
                                    dispatch(
                                        setAlertConfigAction({
                                            severity: 'warning',
                                            message: t('deleteBlockedByCOT'),
                                        })
                                    );
                                    return;
                                }
                                dispatch(toggleConfirmationDialogAction({ isOpen: true, dataItem }));
                            }}
                            style={iconButtonStyle}
                            aria-label="מחיקה"
                            size="small"
                        >
                            <TrashIcon height="18" color="" />
                        </IconButton>
                    </MuiTooltipV3>
                </div>
            ) : null}
            {/* <MyReactTooltip id="disabledActions">
                <p>
                    {isCreatedInStudio === '0' && isSameLineAsRow
                        ? t('actionsOnlyValidOnLinesCreatedOnStudio')
                        : t('cannotUseActionsOnThisLine')}
                </p>
            </MyReactTooltip> */}
        </td>
    );
};
// <CellEditBtn
//                     onClick={() => {
//                         if ('lineCode' in cellProps.dataItem) {
//                             // Closing form
//                             dispatch(openFormAction({ editedLineId: '', open: false }));
//                             dispatch(
//                                 openFormAction({
//                                     editedLineId: String(cellProps.dataItem.lineCode),
//                                     open: true,
//                                 })
//                             );
//                         }
//                     }}
//                 /> */
// const OptionsCell = (props: any) => {
//     const [hover, setHover] = useState(false);
//     return (
//         // <KendoDeleteEditeCell
//         //     {...props}
//         //     onEdit={() => {
//         //         console.log('edit');
//         //     }}
//         //     onDelete={() => {
//         //         console.log('delete');
//         //     }}
//         //     // handleSetIsViewingOptions={handleSetIsViewingOptions}
//         // />
//         <td
//             style={{ width: '100%', height: '100%', border: '1px solid cyan' }}
//             className="OptionsCell"
//             onMouseLeave={() => setHover(false)}
//             onMouseEnter={() => setHover(true)}
//         >
//             {hover ? <button type="submit">options</button> : <p>not hover</p>}
//         </td>
//     );
// };
export default OptionsCell;

// useEffect(() => {
//     try {
//         const listener = (event: any) => {
//             if (lineHovered && !event.path.some((ele: any) => ele.className === 'optionsCell')) {
//                 setLineHovered('');
//             }
//         };
//         window.addEventListener('mouseover', listener, false);
//         return () => {
//             window.removeEventListener('mouseover', listener);
//         };
//     } catch (error) {
//         console.log(error);
//     }
// }, [lineHovered]);
