/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from 'react';
import { useRootAppSelector } from 'src/store/hooks';

import { rideSettingsSelectors as selectors } from '../../../../store/slices/rideSettings/rideSettings';
import { SettingTypes } from '../../../../types/rideSettings/store/types.store';
import styled from '../../styles/styledComponents';
import AccountDeptsFormBody from './AccountDeptsFormBody/AccountDeptsFormBody';
import GeneralSettingsFormBody from './GeneralSettingsFormBody/GeneralSettingsFormBody.index';
import PermissionManagementFormBody from './PermissionsManagementFormBody/PermissionsManagementFormBody';

interface Props {}

const FormBody: React.FC = ({}: Props): JSX.Element => {
    const currentSettingBeingEdited = useRootAppSelector(selectors.currentSettingOnFormSelector);

    const GetCorrectForm = (): JSX.Element => {
        let C;
        switch (currentSettingBeingEdited) {
            case SettingTypes.GeneralSettings:
                C = <GeneralSettingsFormBody />;
                break;
            case SettingTypes.PermissionManagement:
                C = <PermissionManagementFormBody />;
                break;
            case SettingTypes.AccountDepartments:
                // C = <AccountDeptsFormBody {...props} />;
                C = <></>;
                break;

            default:
                return <></>;
        }
        return C;
    };
    return GetCorrectForm();
};

export default FormBody;
