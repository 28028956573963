/* eslint-disable no-alert */
/* eslint-disable no-case-declarations */
import { useEffect } from 'react';
import { ApiRequestActionPayload } from 'src/api/reportsApi/types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    fetchReportAction,
    fetchShortOrdersAction,
    onChangeFilterByReportAction,
} from 'src/store/slices/reports/reportsSlice';
import { ReportNames } from 'src/store/slices/reports/types';
import {
    selectedReportNameSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectRefreshBtnClickCount,
    departmentsSelector,
    clientIdSelector,
    filtersByReportSelector,
} from 'src/store/selectores/reportsSelectors';
import { CurrFiltersValues, PassengersFilterValues } from 'src/types/reports/generalReportsTypes';
import { FilterTypes } from 'src/store/reducers/reportsReducers/uiReducers';
import { asRootReducer } from 'src/store/utils';
import useRequestPayload from './useRequestPayload';
import {
    extractFetchPassengersReportPayload,
    extractFetchVisasReportPayload,
    isValidPayload,
    processDetailedReportApiData,
} from './utils';
import useErrorHandler from '../../../../../../hooks/useErrorHandler';
import { IRootReducer } from '../../../../../../store/reducers/index';

const useSetFilterWithAllDepartments = () => {
    const departments = useAppSelector((state) => departmentsSelector(asRootReducer(state)));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (departments) {
            dispatch(
                onChangeFilterByReportAction({
                    filterType: FilterTypes.DepartmentCodes,
                    newValue: departments.map((dep) => String(dep.code)),
                    reportName: 'passengers',
                })
            );
        }
    }, [departments, dispatch]);
};

const useReportFetch: () => void = () => {
    // & Hooks
    const dispatch = useAppDispatch();

    // & Helper custom hooks
    const { createPayload } = useRequestPayload();
    const { handleError } = useErrorHandler();

    useSetFilterWithAllDepartments();
    // const { handleTimeout } = useTimeoutHandling();
    // & Selectors
    const selectedReportName: ReportNames = useAppSelector((state) => selectedReportNameSelector(state));
    const filters = useAppSelector((state) =>
        selectedReportName === 'passengers'
            ? filtersByReportSelector(asRootReducer(state), 'passengers')
            : filtersSelector(asRootReducer(state))
    );
    const applyFiltersBtnClickCount = useAppSelector((state) =>
        selectApplyFiltersBtnClickCount(asRootReducer(state))
    );
    const fetchedDepartments = useAppSelector((state) => !!departmentsSelector(state));
    const refreshBtnClickCount = useAppSelector((state) => selectRefreshBtnClickCount(asRootReducer(state)));
    const clientId = useAppSelector((state) => clientIdSelector(state));

    // & Effects
    useEffect(() => {
        if (
            !clientId
            // / !currProviderClients.includes(Number(clientId))
        )
            return;
        try {
            const fullPayload = createPayload(filters, selectedReportName);

            if (fullPayload && isValidPayload(fullPayload)) {
                switch (selectedReportName) {
                    case 'shortOrders':
                        if (fetchedDepartments) {
                            dispatch(fetchShortOrdersAction(fullPayload));
                        }
                        break;
                    // FC REQUEST

                    case 'detailedOrders':
                        if (fetchedDepartments) {
                            dispatch(
                                fetchReportAction({
                                    ...fullPayload,
                                    endpoint: 'ws_MyWayStudio_Get_Detailed_Orders_Report',
                                    reportName: 'detailedOrders',
                                    dataProcessFunc: processDetailedReportApiData,
                                })
                            );
                        }
                        break;
                    case 'visas':
                        dispatch(fetchReportAction(extractFetchVisasReportPayload(fullPayload)));
                        break;
                    case 'passengers':
                        dispatch(fetchReportAction(extractFetchPassengersReportPayload(fullPayload as any)));
                        break;
                    default:
                        break;
                }
            } else if (fullPayload && !isValidPayload(fullPayload)) {
                handleError({
                    msg: 'invalid payload',
                    details: { payload: fullPayload },
                });
            }
        } catch (err) {
            handleError({
                msg: 'thrown exception at useReportFetch',
                err,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReportName, applyFiltersBtnClickCount, refreshBtnClickCount, fetchedDepartments, clientId]);
};

export default useReportFetch;
// const selectedReportName = 'detailedOrders' as ReportNames;
// const fetchedDepartments = useMemo(
//    (): boolean => !!departments.length,
//    [departments.length],
// );

// const currProviderClients = useSelector((state: IRootReducer) => {
//    const authToken = authorizationTokenSelector(state);
//    if (authToken) {
//       return authToken['4'].clients;
//    }
//    return [];
// });
// console.log(currProviderClients);
