import React from 'react';
// import { styleIcon } from '../../../../Passengers/css-styles';
import { Button } from '@material-ui/core';
import { colors } from 'src/style/themes/defaultTheme';
import { fontFamilies } from '../../style/themes/defaultTheme';

interface Props {
    handleClick?: any;
    disabled: boolean;
    style?: any;
    text: string;
    Icon?: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyButton: React.FC<Props> = ({ handleClick, disabled, style, text, Icon }) => {
    return (
        <>
            <Button
                onClick={handleClick}
                disabled={disabled}
                style={{
                    color: disabled ? colors.gray : colors.blue,
                    ...style,
                    height: '25px',
                    padding: '5px',
                    margin: '5px -5px 0 0',
                    fontFamily: fontFamilies.Rubik,
                    fontWeight: 400,
                }}
            >
                <Icon />
                {text}
            </Button>
        </>
    );
};
export default MyButton;
