import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Container = styled.div`
    background: ${({ theme }) => theme.colors.white};
    // box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 0.4em 1em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FilterContainer = styled.div`
    display: flex;
    background-color: #e6f6fe;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.625em;
`;

const Title = styled.p<{ withUnderline?: boolean; fontSize?: string }>`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: 500px;
    font-size: ${(props) => props.fontSize || '16px'};
    margin: 0;
    margin-left: 0.4375em;
    color: ${({ theme }) => theme.msColors.primaryTextcolor};
    ${({ withUnderline = false }) => withUnderline && 'text-decoration: underline;'}
`;

const Btn = styled(Button)`
    height: 25px;
`;

const Row = styled.div<{ mr?: number; ml?: number; mt?: number; mb?: number }>`
    display: flex;
    align-items: center;
    ${({ mr }) => mr && `margin-right: ${mr}px`};
    ${({ ml }) => ml && `margin-left: ${ml}px`};
    ${({ mt }) => mt && `margin-top: ${mt}px`};
    ${({ mb }) => mb && `margin-bottom: ${mb}px`};
`;

const Label = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    color: #bebebe;

    font-weight: 400;
    font-size: 1em;
    line-height: 19px;
    text-align: center;
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.05em;
    margin: 0 7px;
`;

const PassengersConatiner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    margin-top: 1em;
`;

const EmptyLine = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img<{
    src: string;
}>`
    background: #ffedb1;
    border-radius: 50%;
    padding: 3px;
    margin-left: 10px;
    // margin-right: 10px;
`;

export const accordionStyles = {
    main: {
        border: 'none',
        margin: '0px !important',
        padding: '0px !important',
        ':before': { opacity: 0 },
        // '.MuiAccordionSummary-content': { margin: '2px' },
    },
    summary: {
        border: 'none',
        margin: '0px',
        padding: '0px',
        '.MuiAccordionSummary-content': { margin: '2px' },
        '&.MuiAccordionSummary-root': {
            minHeight: '0px',
            height: 'auto',
        },
    },
    summaryText: {
        borderLeft: '1px solid #BEBEBE',
        paddingLeft: '1em',
    },
    details: { padding: '0px' },
};
export default {
    Image,
    Container,
    Header,
    FilterContainer,
    PassengersConatiner,
    EmptyLine,
    Title,
    Btn,
    Row,
    Label,
    Text,
};
