import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import * as React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { colors } from 'src/style/themes/defaultTheme';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useAppSelector } from 'src/store/hooks';
import { passengersFormSelector } from 'src/store/slices/lines/linesSlice';

type Props = {
    stationInputConfig: {
        removeStationInput: () => void;
    };
    passengersConfig: {
        handleClick: () => void;
        isDisabled: boolean;
    };
    isDisabled: boolean;
};

export default function StationActionsMenu({
    stationInputConfig,
    passengersConfig,
    isDisabled = false,
}: Props): JSX.Element {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                onClick={handleClick}
                sx={{ minWidth: '40px', height: '40px', padding: '0px' }}
                disabled={isDisabled}
            >
                <MoreVertOutlinedIcon
                    sx={{ color: isDisabled ? colors.gray : colors.blue }}
                    fontSize="small"
                />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        passengersConfig.handleClick();
                        handleClose();
                    }}
                    disabled={passengersConfig.isDisabled}
                >
                    <p style={{ fontFamily: 'Rubik' }}>{t('addingPassengersToStation')}</p>
                </MenuItem>
                <MenuItem
                    style={{ fontFamily: 'Rubik' }}
                    onClick={() => stationInputConfig.removeStationInput()}
                >
                    <p> {t('deleteStation')}</p>
                </MenuItem>
            </Menu>
        </div>
    );
}
