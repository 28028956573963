import { Moment } from 'moment';
import { AccountShift } from 'src/api/reportsApi/types';
import { ReqStatus } from 'src/api/types';
import { DateFormats } from 'src/types/dates';
import { ErrorState, IObject, DayNum } from 'src/types/global';
import {
    CurrFiltersValues,
    PassengersFilterValues,
    PassengersFilterValuesKeys,
    ProcessedStatistics,
} from 'src/types/reports/generalReportsTypes';
import { BudgetData } from 'src/types/reports/slice.types';
import { VisasReportRow } from 'src/types/reports/visasReportTypes';

export type RideTypes = 'all' | 'pickup' | 'drop';
export interface ShortDepartmentData {
    code: number;
    departmentName: string;
}
export type SelectedClientId = string | null;

export interface IOrdersData {
    dailyCount: number;
    weeklyCount: number;
    dropCount: number;
    pickupCount: number;
    fromDate: string;
    toDate: string;
    ordersComparison: IObject;
}
export type IRidesStatistics = ProcessedStatistics | any;
export interface IWidgets {
    // ordersData: IOrdersData;
    ridesStatistics: IRidesStatistics;
    budget: {
        request: {
            isSuccess: boolean;
        };
        data: BudgetData;
    };
}
export interface VisasFilters {
    readonly fromDate: Date;
    readonly toDate: Date;
}

export interface DetailedOrdersFilters {
    readonly fromDate: Date;
    readonly toDate: Date;
    readonly pickupTime: string | undefined;
    readonly dropTime: string | undefined;
    readonly departmentCode: string | undefined;
    readonly rideType: RideTypes | undefined;
}

export interface ShortOrdersFilters {
    readonly fromDate: Date;
    readonly toDate: Date;
    readonly pickupTime: string | undefined;
    readonly dropTime: string | undefined;
    readonly departmentCode: string | undefined;
    readonly rideType: RideTypes | undefined;
}

export type ReportsTypes = 'rides' | 'orders';
export interface UiState {
    main: {
        selectedReport: ReportNames;
        selectedReportType: ReportsTypes;
        selectedClientId: SelectedClientId;
        filters: {
            byReport: {
                passengers: PassengersFilterValues;
            };
            currFiltersValues: CurrFiltersValues;
            applyFiltersBtnClickCount: number;
            resetFiltersClickCount: number;
        };
    };
    common: {
        refreshPageBtnClickCount: number;
        error: ErrorState;
        isPrinting: boolean;
    };
    widgets: {
        refreshWidgetsBtnClickCount: number;
        isOpen: boolean;
        dateOnWidget: Date;
    };
}

export interface ReportState<T> {
    sortedData: T[];
    reportData: T[];
    apiCallStatus: ReqStatus;
    date?: string;
}
export interface DataState {
    reports: {
        visas: ReportState<VisasReportRow>;
        shortOrders: ReportState<ShortReportRow>;
        detailedOrders: ReportState<DetailedReportRow>;
        passengers: ReportState<PassengerReportRow>;
    };
    departments: ShortDepartmentData[];
    shiftTimes: {
        fullData: AccountShift[];
        dropTimes: Array<string>;
        pickupTimes: Array<string>;
    };
    widgets: IWidgets;
}
export interface IReportsState {
    ui: UiState;
    data: DataState;
    general: {
        authorizedReports: ReportNames[];
    };
}

export enum ReportNames {
    shortOrdersReport = 'shortOrders',
    detailedOrdersReport = 'detailedOrders',
    visasReport = 'visas',
    passengersReport = 'passengers',
}

export type ReportName = 'shortOrders' | 'detailedOrders' | 'visas' | 'passengers';

export interface OnSelectReportPayload {
    reportName: ReportNames;
}

export interface OnChangeDateForFilteringPayload {
    dateForFiltering: string;
}

export type DataProcessFunc = (data: any, arg?: any) => any;

export interface FetchActionPayload {
    dbUrl: string;
    requestParams: {
        relativeDate: string;
        proxyUrl: string;
        token: string;
        clientCode: number;
    };
    reportName?: ReportNames;
    dataProcessFunc?: DataProcessFunc;
    dateKey?: string;
}

export interface ShiftData {
    shiftTime: string;
    is_pickup: '0' | '1';
}

export interface ShortReportRow {
    passId: string;
    fullName: string;
    departmentCode: string;
    city: string;
    streetWithHouseNum: string;
    pickupCourseId: string;
    dropCourseId: string;
    workStart: string;
    workEnd: string;
    departmentName: string;
    relativeDate: Date | '';
}

export interface DetailedReportRow {
    passId: string;
    fullName: string;
    departmentCode: string;
    city: string;
    streetWithHouseNum: string;
    shiftPickupTime: string;
    isPickup: '0' | '1';
    shiftTime: string;
    departmentName: string;
    relativeDate: Date | '';
    rideType: 'איסוף' | 'פיזור';
}

// -- passengers report
export interface RideCountPerDate {
    date: Date;
    count: number;
}
export interface PassengerReportRow {
    passCode: string;
    internalCode: string;
    firstName: string;
    lastName: string;
    departmentName: string;
    // shiftName: string;
    totalRidesCount: number;
    ridesCountPerDate: RideCountPerDate[];
}
