import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditPassengerBtn from 'src/components/EditPassengerBtn/EditPassengerBtn';
import { Endpoints } from 'src/types/rideSettings/components/types.screen';
import { getAllowedLevelsStr, isLastItem } from 'src/screens/RideSettings/utils/general';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { rideSettingsSelectors } from 'src/store/slices/rideSettings/rideSettings';
import { Permission, SettingTypes } from 'src/types/rideSettings/store/types.store';
import { mediumLargeRubik, mediumLargeRubikBold } from 'src/style/styledCSS';

import { List, ListItem, styled } from '@mui/material';

import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import { SpanBoldItalic } from 'src/screens/RideSettings/styles/styledComponents';
import _ from 'lodash';
import { getAsTemplateStrArr as asTSR } from 'src/utilis/utilis';
import { getItemStyle } from 'src/screens/RideSettings/styles/styledCSS';
import { noPaddingNMargin } from '../../../../../../style/styledCSS';
import { StyledList } from './styles';
import { listItemStyle } from '../../../widgets/PermissionsManagementFormBody/stylesCss';

type Props = {};

const ListItemWithHover: FC<{
    permissionCode: number;
    handleEdit?: () => void;
    idx: number;
    settingBeingHovered: number | null;
    setSettingBeingHovered: React.Dispatch<React.SetStateAction<number | null>>;
    settingsCount: number;
}> = ({ children, permissionCode, settingBeingHovered, setSettingBeingHovered, idx, settingsCount }) => {
    const isLastItemInList = idx === settingsCount - 1;
    const [isBeingHovered, setIsBeingHovered] = useState(false);

    useEffect(() => {
        setIsBeingHovered(idx === settingBeingHovered);
    }, [idx, settingBeingHovered]);

    return (
        <div
            onMouseEnter={() => setSettingBeingHovered(idx)}
            onMouseLeave={() => setSettingBeingHovered(null)}
        >
            <ListItem key={permissionCode} sx={getItemStyle(isLastItemInList, idx === settingBeingHovered)}>
                {children}
                {/* {isBeingHovered && <EditPassengerBtn onEdit={handleEdit} />} */}
            </ListItem>
        </div>
    );
};

const PermissionsManagementAccordionBody = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // * Selectors
    const settings: Permission[] | undefined = useRootAppSelector(
        (state) =>
            rideSettingsSelectors.selectData(state)[SettingTypes.PermissionManagement].settings.permissions
    );

    // * Local state
    const [settingBeingHovered, setSettingBeingHovered] = useState<number | null>(null);

    // * Handlers
    // const handleEdit = () => {
    //     dispatch(
    //         actions.editSettingClick({
    //             settingType: SettingTypes.PermissionManagement,
    //             submitEndpoint: Endpoints.setPermMng,
    //         })
    //     );
    //     dispatch(actions.onOpenForm({ formType: 'permissionManagement', isOnEditMode: true }));
    // };

    const settingsReversed = _.cloneDeep(settings)?.reverse() || [];

    return Array.isArray(settingsReversed) && settingsReversed.length ? (
        <StyledList sx={{ padding: 0 }}>
            {settingsReversed.map((setting, idx) => {
                return (
                    <ListItemWithHover
                        permissionCode={setting.permissionCode}
                        idx={idx}
                        settingBeingHovered={settingBeingHovered}
                        setSettingBeingHovered={setSettingBeingHovered}
                        settingsCount={settingsReversed.length}
                        key={setting.permissionCode}
                    >
                        <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                            <span style={mediumLargeRubik}>{t(asTSR(setting.permissionType))}:&nbsp;</span>
                            <SpanBoldItalic style={{ fontSize: '18px' }}>
                                {getAllowedLevelsStr(setting) || t('permissionLvlsNotAssigned')}
                            </SpanBoldItalic>
                        </div>
                    </ListItemWithHover>
                );
            })}
        </StyledList>
    ) : (
        <div>שגיאה בקבלת הגדרות - אנא נסע שנית</div>
    );
};

export default PermissionsManagementAccordionBody;
