import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TripDirections } from 'src/types/line';
import { breakPoints } from 'src/style/themes/defaultTheme';
import styled, { css } from 'styled-components';
import { StyledInputContainer } from '../../../styles';
import { Inputs } from '../../../types';
import ControlledTimePicker from '../../Inputs/TimePicker';

type Props = {};
const StyledContainer = styled.div`
    display: flex;
    gap: 10px;
    @media (max-width: 1720px) {
        flex-direction: column;
    }
`;
const StyledPar = styled.p<{ hide?: boolean }>`
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    ${(props) => css`
        ${props.hide
            ? css`
                  opacity: 0;
              `
            : ''}
    `}
`;

export default function TripTimesInput({}: Props) {
    const fullFormMethods = useFormContext<Inputs>();
    const { t } = useTranslation();

    const tripDirection = fullFormMethods.watch('tripDirection');

    const startTime = fullFormMethods.getValues('startTime');
    const endTime = fullFormMethods.getValues('endTime');
    const returnTripStartTime = fullFormMethods.getValues('returnTripStartTime');
    const returnTripEndTime = fullFormMethods.getValues('returnTripEndTime');

    useEffect(() => {
        if (startTime) {
            fullFormMethods.trigger(['returnTripEndTime', 'endTime', 'returnTripStartTime']);
        }
    }, [fullFormMethods.trigger, returnTripStartTime, tripDirection, endTime, startTime, returnTripEndTime]);

    return (
        <StyledContainer className="TripTimesInputContainer">
            <div style={{ textAlign: 'right', width: '100%' }}>
                <StyledPar hide={tripDirection !== TripDirections.RoundTrip}>הלוך</StyledPar>
                <StyledInputContainer
                    gap="10px"
                    className="StyledInputContainer"
                    style={{ paddingBottom: '0' }}
                >
                    <ControlledTimePicker label={t('start')} inputName="startTime" />
                    {tripDirection !== TripDirections.OneWay ? (
                        <ControlledTimePicker label={t('end')} inputName="endTime" />
                    ) : (
                        <div style={{ width: '100%' }} />
                    )}
                </StyledInputContainer>
            </div>

            {tripDirection === TripDirections.RoundTrip ? (
                <div style={{ textAlign: 'right' }}>
                    <StyledPar>חזור</StyledPar>
                    <StyledInputContainer gap="10px" className="StyledInputContainer">
                        <ControlledTimePicker label={t('start')} inputName="returnTripStartTime" />
                        {fullFormMethods.getValues('tripDirection') !== 'oneWay' ? (
                            <ControlledTimePicker label={t('end')} inputName="returnTripEndTime" />
                        ) : (
                            <div style={{ width: '100%' }} />
                        )}
                    </StyledInputContainer>
                </div>
            ) : (
                <></>
            )}
        </StyledContainer>
    );
}
