import { uniqueId } from 'lodash';
import moment from 'moment';
import { TripDirections } from 'src/types/line';
import { stringifyDate } from 'src/utilis/utilis';
import { AddressInput, FormSchema, InputNames, PassengerInput, StationInput } from './types';

export const initialAddress: AddressInput = {
    city: '',
    street: '',
    houseNum: '',
    placeName: '',
};

export const initialStation: StationInput = {
    ...initialAddress,
    stationId: uniqueId(),
    passengers: [],
};

export const initialNewPassenger: PassengerInput = {
    internalCode: '',
    firstName: '',
    lastName: '',
    passengerRemarks: '',
    phone: '',
    phonePrefix: '',
    city: '',
    street: '',
    houseNum: '',
    email: '',
};
export const DEFAULT_TIME_VALUE = '';

export const emptyValues: { [key in InputNames]: any } = {
    clientCode: 0,
    date: undefined,
    startTime: DEFAULT_TIME_VALUE,
    startAddress: initialAddress,
    endAddress: initialAddress,
    lineRemarks: '',
    flightNum: '',
    invContactName: '',
    invContactPhone: '',
    invContactPhonePrefix: '',
    clientOrderCode: null,
    passQty: null,
    carId: null,
    tripDirection: TripDirections.OneWay,
    endTime: DEFAULT_TIME_VALUE,
    returnTripEndTime: DEFAULT_TIME_VALUE,
    returnTripStartTime: null,
    departmentCode: null,
    courseCode: null,
    linePrice: null,
    description: null,
};

export const ALL_CARS_VALUE = '-1';
