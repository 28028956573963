import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { GenericInputConfig } from '../../types';
import React from 'react';

interface Props extends GenericInputConfig<any, any> {
    test?: any;
}
export const getTranslatedErrorMessage = (t: Function, name: string, errors: { [x: string]: any }) => {
    const messageParts = errors[name]?.message.split('-');
    if (messageParts.length > 1) {
        return `${t(messageParts[0])} - ${messageParts[1]}`;
    }
    return t(messageParts[0]);
};

function GenericTextInput({ errors, register, name, type, required }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', textAlign: 'right' }}>
            <TextField
                {...register(name)}
                error={!!errors[name]}
                variant="outlined"
                size="small"
                label={t(getAsTemplateStrArr(name))}
                fullWidth
                required={required}
                type={type || 'text'}
                style={{ backgroundColor: 'white' }}
            />
            {errors[name] && <p>{getTranslatedErrorMessage(t, name, errors)}</p>}
        </div>
    );
}

export default GenericTextInput;
