import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setMapIsExpandedAction,
    setUpdatedCoordsOnMapAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { isValidCoords } from './OptimizationWidgetMap';
import { ChangeEventValue, Coords } from 'google-map-react';
import React, { useState, ReactChild } from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import MapType2 from 'src/components/Map/MapType2/MapType2';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { OptimizationEditInstructionsWindow } from './OptimizationEditInstructionsWindow';
import { OptimizationConfirmationWindow } from './OptimizationConfirmationWindow';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';

const OptimizationFloatMap: React.FunctionComponent<{}> = () => {
    const allWaypoints = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );
    console.log(allWaypoints, 'allWaypoints');

    const formWaypointsList = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypoints
    );
    const lastWaypointSelected = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.lastSelected
    );
    const waypointsSelectedIds = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsSelectedIds
    );
    const isExpanded = useRootAppSelector((state) => optimizationsRootSelector(state).ui.isMapExpended);
    const isMapEditMode = useRootAppSelector((state) => optimizationsRootSelector(state).ui.isMapEditMode);
    const {
        updatedCoords,
        lastWaypointEditedIndex,
        waypointOnMapEditMode,
        centerMapOnEdit,
        isEditForm,
        zoomMapOnEdit,
    } = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form);

    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>(defaultCenter);

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    // if waypoints are loaded, setCenter to be the lat and lng of the middle waypoint.
    React.useEffect(() => {
        if (allWaypoints && allWaypoints.length > 0) {
            const waypointsWithValidCoords = allWaypoints.find((waypoint) =>
                isValidCoords({ lat: waypoint.lat, lng: waypoint.lng })
            );
        }
    }, [allWaypoints]);

    React.useEffect(() => {
        if (lastWaypointSelected) {
            setCenter({
                lat: lastWaypointSelected.lat,
                lng: lastWaypointSelected.lng,
            });

            setZoom(12);
        }
    }, [waypointsSelectedIds]);

    React.useEffect(() => {
        if (formWaypointsList && formWaypointsList.length > 0 && lastWaypointEditedIndex !== null) {
            const waypoint = formWaypointsList[lastWaypointEditedIndex];

            if (waypoint) {
                const lat = waypoint.lat;
                const lng = waypoint.lng;

                if (lat && lng) {
                    setCenter({
                        lat,
                        lng,
                    });
                    setZoom(12);
                }
            }
        }
    }, [formWaypointsList]);

    React.useEffect(() => {
        if (centerMapOnEdit) {
            setCenter(centerMapOnEdit);
        }
    }, [centerMapOnEdit]);

    React.useEffect(() => {
        if (zoomMapOnEdit) {
            setZoom(zoomMapOnEdit);
        }
    }, [zoomMapOnEdit]);

    // * Event handlers

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    return (
        <styles.Container className="Container">
            <styles.MapDiv className="MapDiv" isOpen={isExpanded}>
                {((isMapEditMode && isExpanded && !updatedCoords) ||
                    (isMapEditMode &&
                        isExpanded &&
                        waypointOnMapEditMode?.lat === updatedCoords?.lat &&
                        waypointOnMapEditMode?.lng === updatedCoords?.lng)) && (
                    <OptimizationEditInstructionsWindow />
                )}
                {isMapEditMode &&
                    isExpanded &&
                    updatedCoords &&
                    waypointOnMapEditMode?.lat !== updatedCoords?.lat &&
                    waypointOnMapEditMode?.lng !== updatedCoords?.lng && <OptimizationConfirmationWindow />}
                <styles.ExpandMapIcon
                    style={{ position: 'absolute', zIndex: 999 }}
                    onClick={() => {
                        dispatch(setMapIsExpandedAction({ isExpended: !isExpanded }));
                    }}
                >
                    <img style={{ width: '20px' }} src={styles.imgUrl} alt="expand-map" />
                </styles.ExpandMapIcon>

                <MapType2 GoogleMapProps={GoogleMapProps}>
                    {allWaypoints && !isEditForm
                        ? allWaypoints.map((waypoint, i) =>
                              isValidCoords({ lat: waypoint.lat, lng: waypoint.lng }) ? (
                                  <Marker
                                      icon={{
                                          url: '/regularMarker.svg',
                                          scaledSize: new window.google.maps.Size(30, 30),
                                          origin: new window.google.maps.Point(0, 0),
                                          anchor: new window.google.maps.Point(15, 25),
                                      }}
                                      key={waypoint.waypointId}
                                      position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                  />
                              ) : (
                                  <></>
                              )
                          )
                        : null}
                    {formWaypointsList
                        ? formWaypointsList.map((waypoint, i) =>
                              waypoint.lat && waypoint.lng ? (
                                  <Marker
                                      icon={{
                                          url:
                                              waypointOnMapEditMode?.localId === waypoint.localId &&
                                              isMapEditMode
                                                  ? '/editedMarker.svg'
                                                  : '/regularMarker.svg',
                                          scaledSize: new window.google.maps.Size(30, 30),
                                          origin: new window.google.maps.Point(0, 0),
                                          anchor: new window.google.maps.Point(15, 25),
                                      }}
                                      key={waypoint.localId}
                                      position={
                                          waypointOnMapEditMode?.localId === waypoint.localId &&
                                          isMapEditMode &&
                                          updatedCoords
                                              ? { lat: updatedCoords.lat, lng: updatedCoords.lng }
                                              : { lat: waypoint.lat, lng: waypoint.lng }
                                      }
                                      draggable={
                                          waypointOnMapEditMode?.localId === waypoint.localId && isMapEditMode
                                              ? true
                                              : false
                                      }
                                      onDragEnd={(e) => {
                                          if (!e.latLng) return;
                                          dispatch(
                                              setUpdatedCoordsOnMapAction({
                                                  lat: e.latLng.lat(),
                                                  lng: e.latLng.lng(),
                                              })
                                          );
                                      }}
                                  />
                              ) : (
                                  waypointOnMapEditMode?.localId === waypoint.localId &&
                                  isMapEditMode && (
                                      <Marker
                                          icon={{
                                              url: '/editedMarker.svg',
                                              scaledSize: new window.google.maps.Size(30, 30),
                                              origin: new window.google.maps.Point(0, 0),
                                              anchor: new window.google.maps.Point(15, 25),
                                          }}
                                          key={waypoint.localId}
                                          position={
                                              waypointOnMapEditMode?.localId === waypoint.localId &&
                                              isMapEditMode &&
                                              updatedCoords
                                                  ? { lat: updatedCoords.lat, lng: updatedCoords.lng }
                                                  : defaultCenter
                                          }
                                          draggable={
                                              waypointOnMapEditMode?.localId === waypoint.localId &&
                                              isMapEditMode
                                                  ? true
                                                  : false
                                          }
                                          onDragEnd={(e) => {
                                              if (!e.latLng) return;
                                              dispatch(
                                                  setUpdatedCoordsOnMapAction({
                                                      lat: e.latLng.lat(),
                                                      lng: e.latLng.lng(),
                                                  })
                                              );
                                          }}
                                      />
                                  )
                              )
                          )
                        : null}
                </MapType2>
            </styles.MapDiv>
        </styles.Container>
    );
};

export default OptimizationFloatMap;
