import { IPassenger } from '../../../../../api/passengerMangerApi/types';

export const updatePassengers = (oldPassengers: IPassenger[], passCode: number | undefined): IPassenger[] => {
    const updatedPassengers = oldPassengers.map((passenger) => {
        if (passenger.isSelected) {
            const updated = { ...passenger, isSelected: false };
            return updated;
        }
        return passenger;
    });
    return updatedPassengers;
};
