/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IReportsState, ReportNames } from 'src/store/slices/reports/types';

const onUpdateAuthorizedReportsList = (
    state: IReportsState,
    action: PayloadAction<{ authorizedReports: ReportNames[] }>
): void => {
    state.general.authorizedReports = action.payload.authorizedReports;
};
const generalReducers = {
    onUpdateAuthorizedReportsList,
};

export default generalReducers;
