import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export const styles: { [key: string]: CSSProperties } = {
    stationInputContainer: {
        textAlign: 'right',
        // border: '1px solid gray',
    },
    inputAndPassengerAddingBtnContainer: {
        display: 'flex',
        width: '100%',
        gap: '15px',
    },

    addPassengerBtn: { height: '100%', width: '100%', margin: 0 },
    nakedBtnsContainer: { paddingTop: '5px' },
};

const StyledTableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    // border: 1px solid blue;
`;
const StyledTableRow = styled.tr<{
    isDataRow?: boolean;
    hover: boolean;
}>`
    justify-content: space-between;
    text-align: right;
    display: flex;
    font-size: 14px;
    // border: 1px solid orange;
    ${(props) =>
        props.hover
            ? css`
                  background-color: #e4e7eb;
              `
            : ''}
`;

const StyledTableBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
`;

const StyledTableCell = styled.div<{ flex?: number; isTd?: boolean }>`
    flex: ${({ flex }) => flex || 1};
    text-align: right;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 5px;
    ${(props) => css`
        ${props.isTd
            ? `
   height: 40px;
     `
            : ``}
    `}
`;

export const stationPassengersTableStyles = {
    StyledTableCell,
    StyledTableHeader,
    StyledTableRow,
    StyledTableBody,
};
