import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    clientIdSelector,
    selectedReportNameSelector,
    selectedReportSelector,
} from 'src/store/selectores/reportsSelectors';
import { fetchDepartmentsStatisticsAction } from 'src/store/slices/reports/reportsSlice';
import { DetailedReportRow, ShortReportRow } from 'src/store/slices/reports/types';
import { asRootReducer } from 'src/store/utils';
import { StatisticsRequestConfig } from 'src/types/reports/generalReportsTypes';
import widgetsHooks from '../../hooks/common.hooks';

export interface Count {
    ordersCountResult: { pickups: number; drops: number };
    passengersCount: number;
}
const INITIAL_COUNT = {
    pickups: 0,
    drops: 0,
};
const INITIAL_DATA = {
    ordersCountResult: INITIAL_COUNT,
    passengersCount: 0,
};

const getShortOrdersStatistics = (data: ShortReportRow[]): Count => {
    let pickups = 0;
    let drops = 0;
    const allPsngrsData: string[] = [];

    data.forEach((row) => {
        if (row.workStart) pickups += 1;
        if (row.workEnd) drops += 1;
        allPsngrsData.push(`${row.fullName.trim()}-${row.passId.trim()}-${row.streetWithHouseNum.trim()}`);
    });

    const ordersCountResult = { pickups, drops };

    return {
        ordersCountResult,
        passengersCount: new Set(allPsngrsData).size,
    };
};

const getDetailedOrdersStatistics = (data: DetailedReportRow[]): Count => {
    let pickups = 0;
    let drops = 0;
    const allPsngrsData: string[] = [];

    data.forEach((row) => {
        if (+row.isPickup) {
            pickups += 1;
        }
        if (!+row.isPickup) {
            drops += 1;
        }
        allPsngrsData.push(`${row.fullName}-${row.passId}`);
    });

    const ordersCountResult = { pickups, drops };

    return {
        ordersCountResult,
        passengersCount: new Set(allPsngrsData).size,
    };
};

const useStatistics = () => {
    const sortedReportData = useSelector((state) => selectedReportSelector(asRootReducer(state)).sortedData);
    const selectedReport = useSelector((state) => selectedReportNameSelector(asRootReducer(state)));

    const { ordersCountResult, passengersCount } = useMemo(() => {
        switch (selectedReport) {
            case 'shortOrders':
                return getShortOrdersStatistics(sortedReportData);
            case 'detailedOrders':
                return getDetailedOrdersStatistics(sortedReportData);
            default:
                return INITIAL_DATA;
        }
    }, [selectedReport, sortedReportData]);

    const ordersCount = {
        pickupsCount: ordersCountResult.pickups,
        dropsCount: ordersCountResult.drops,
        totalCount: ordersCountResult.drops + ordersCountResult.pickups,
    };
    return { ordersCount, passengersCount };
};

export default useStatistics;
