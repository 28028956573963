import React from 'react';
import styled, {
    css,
    CSSObject,
    DefaultTheme,
    FlattenSimpleInterpolation,
    ThemedCssFunction,
} from 'styled-components';

const FullContainer = styled.div<{
    style?: CSSObject;
}>`
    width: 100%;
    height: 100%;
    ${(props) =>
        css`
            ${props.style}
        `}
`;

export default FullContainer;
