import React from 'react';
import useCommons from 'src/hooks/common/useCommons';
import useGetCourseDataByCode from 'src/screens/CoursesBuilding/hooks/useGetCourseDataByCode';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    coursesBuildingSelectors,
    setCurrTabAction,
    timesSettingsTabSelector,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tabsSx } from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/Components/CoursesList/CourseTypeTabs/styles';
import FullContainer from 'src/components/Wrappers/FullContainer';
import { CourseBuildingTabs } from 'src/types/coursesBuilding/storeTypes';
import { asTSR } from 'src/utilis/utilis';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import { useFormContext } from 'react-hook-form';
import { CourseDetailsFormSchema } from 'src/types/coursesBuilding/form/formTypes';
import dateHelpers from '../../../../../utilis/dateHelpers';
import { RELATIVE } from 'src/constants/dates';
import detailsSchema from '../reactHookFormConfigs/detailsSchema';
import { FillerValues } from 'src/constants/misc';
import { carTypesLstSelector } from 'src/store/slices/lines/linesSlice';
import TimesSettingsPanel from './TimesSettingsPanel/TimesSettingsPanel';
import Spinner from 'src/components/Spinner/Spinner';
import useFormDisplayDelay from 'src/screens/CoursesBuilding/hooks/formHooks/useFormDisplayDelay';
import StationsPanel from './StationsPanel/StationsPanel';
import StyledDisabledDiv from 'src/components/Wrappers/StyledDisabledDiv';

const FormTabs = ({}: {}) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const currTab = useRootAppSelector(
        (state) => coursesBuildingSelectors.uiSelector(state).courseBuildingForm.currTab
    );

    return (
        <FullContainer>
            <Tabs
                value={currTab}
                onChange={(e, newValue) => {
                    if (newValue && newValue !== currTab) {
                        dispatch(setCurrTabAction(newValue));
                    }
                }}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                sx={tabsSx}
            >
                <Tab value={CourseBuildingTabs.Details} label={t(CourseBuildingTabs.Details)} />
                <Tab
                    value={CourseBuildingTabs.CourseStationsBuilding}
                    label={t(asTSR(CourseBuildingTabs.CourseStationsBuilding))}
                />
                <Tab
                    value={CourseBuildingTabs.TimesAndFrequencySettings}
                    label={t(asTSR(CourseBuildingTabs.TimesAndFrequencySettings))}
                />
            </Tabs>
            <div className="TabContainer" style={{ paddingTop: '15px' }}>
                <DisplayWrapper show={currTab === CourseBuildingTabs.Details}>
                    <DetailsPanel />
                </DisplayWrapper>
                <DisplayWrapper show={currTab === CourseBuildingTabs.CourseStationsBuilding}>
                    <StationsPanel />
                </DisplayWrapper>
                <DisplayWrapper show={currTab === CourseBuildingTabs.TimesAndFrequencySettings}>
                    <TimesSettingsPanel />
                </DisplayWrapper>
            </div>
        </FullContainer>
    );
};

const useImportCourseDataToForm = () => {
    const { courseCode } = useRootAppSelector(courseBuildingFormSelector);

    const { dispatchI18nErrMsg } = useCommons();

    const { getCourseDataByCode } = useGetCourseDataByCode();

    const { setValue, trigger, getValues, reset } = useFormContext<CourseDetailsFormSchema>();

    React.useEffect(() => {
        const selectedCourse = getCourseDataByCode(courseCode);

        const handlePrevDate = (prevDate: string) => {
            const dateAsDate = dateHelpers.dateify(prevDate, RELATIVE);
            if (dateAsDate === -1) {
                dispatchI18nErrMsg('invalidDate2');
                return new Date();
            }
            return dateAsDate;
        };

        if (selectedCourse) {
            setValue('courseId', selectedCourse.courseId);
            setValue('courseDescription', selectedCourse.courseDescription);
            setValue('remarks', selectedCourse.courseRemarks);
            setValue('departmentCode', selectedCourse.departmentCode);
            setValue('carTypeCode', selectedCourse.carTypeCode);
            setValue('lineTypeCode', selectedCourse.lineTypeCode);
            setValue('activeFromDate', handlePrevDate(selectedCourse.activeFromDate));
            setValue('activeUntilDate', handlePrevDate(selectedCourse.activeUntilDate));
            setValue('courseDirection', selectedCourse.courseDirection || FillerValues.Without);
            setValue('isActive', selectedCourse.isActive);

            trigger();
        } else if (!selectedCourse) {
            reset();
        }
    }, [courseCode, getCourseDataByCode, setValue]);
};

type Props = {};

const useIsBlocked = () => {
    const isOnEditMode = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map.isOnEditMode);
    // const isDisabled = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.isDisabled);

    if (isOnEditMode) {
        return true;
    }
    return false;
};

const CourseFormBody = (props: Props) => {
    useImportCourseDataToForm();

    const { showSpinner } = useFormDisplayDelay();

    const isBlocked = useIsBlocked();

    return (
        <div>
            {showSpinner ? <Spinner /> : <></>}

            <StyledDisabledDiv disabled={isBlocked}>
                <DisplayWrapper show={!showSpinner}>
                    <FormTabs />
                </DisplayWrapper>
            </StyledDisabledDiv>
        </div>
    );
};

export default CourseFormBody;
