import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import rideSettingsActions from 'src/store/actions/rideSettings/rideSettingsActions';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';
import { Permission, settingTypeByStr, SettingTypes } from 'src/types/rideSettings/store/types.store';
import { DefaultReqPayload, IFormSubmitPayload } from 'src/types/rideSettings/store/types.sagas';
import { useAccordionExpansion } from 'src/screens/RideSettings/hooks/useAccordionExpansion';

type Props = {};

const useBtnsConfig = ({}: Props) => {
    const dispatch = useAppDispatch();

    const { closeAllAccordions } = useAccordionExpansion();

    // *Selectors
    const formState = useRootAppSelector((state) => selectors.formSelector(state));
    const currentSettingOnForm = useRootAppSelector(selectors.currentSettingOnFormSelector);

    const selectedFcAccount = useSelector((state) => selectedFcAccountSelector(state));
    const token = useSelector((state: IRootReducer) => tokenSelector(state));
    const clientCode = useRootAppSelector(
        (state: IRootReducer) => selectors.clientDataSelector(state).clientCode
    );

    const submitConfigs = useRootAppSelector(
        (state) => selectors.formSelector(state).apiSettings.submitConfigs
    );

    const currentSettings = useRootAppSelector((state) => {
        const currForm = formState.ui.currentForm;
        return currForm ? selectors.selectData(state)[currForm].settings : null;
    });

    const [formChanged, setFormChanged] = useState(false);

    useEffect(() => {
        const currForm = formState.ui.currentForm;

        if (currForm && _.isEqual(currentSettings, formState.valuesByForm[currForm])) {
            setFormChanged(false);
            return;
        }

        setFormChanged(true);
    }, [currentSettings, formState.ui.currentForm, formState.valuesByForm]);

    // * Handlers
    const onSubmit = () => {
        // check if data has changed
        const { dbUrl, proxyUrl = '' } = selectedFcAccount || {};

        if (!dbUrl || !clientCode || !currentSettingOnForm) return;

        const baseReqPayload: DefaultReqPayload = {
            clientCode,
            dbUrl,
            proxyUrl,
            token,
        };

        const {
            valuesByForm,
            ui: { currentForm },
        } = formState;

        if (currentForm === 'permissionManagement' && valuesByForm.permissionManagement) {
            const payload: IFormSubmitPayload<Permission[]> = {
                ...baseReqPayload,
                data: valuesByForm.permissionManagement.permissions,
                settingType: settingTypeByStr(currentSettingOnForm),
                submitConfigs,
            };

            dispatch(rideSettingsActions.formSubmitAction<Permission[]>(payload));
        }

        if (currentForm === 'generalSettings') {
            const formValues = valuesByForm.generalSettings;

            if (formValues) {
                dispatch(
                    rideSettingsActions.onGeneralSettingsFormSubmit({
                        settingType: 'generalSettings',
                        updatedSettings: formValues,
                        requestPayload: {
                            params: {
                                token: baseReqPayload.token,
                                clientCode: baseReqPayload.clientCode,
                                ...formValues,
                            },
                            dbUrl,
                            proxyUrl,
                        },
                    })
                );
            }
        }
    };

    const onCancel = () => {
        dispatch(rideSettingsActions.onCloseForm());
        closeAllAccordions();
    };

    // * Effect
    return { onSubmit, onCancel, formChanged };
};

export default useBtnsConfig;
