import { breakPoints } from 'src/style/themes/defaultTheme';
import styled from 'styled-components';

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
const ResponsiveHeaderContainer = styled.div<{ show?: boolean }>`
    @media ${breakPoints.laptopS} {
        ${({ show }) => (show ? '' : 'display: none')};
    }
`;
const Text = styled.p<{
    color?: string;
}>`
    font-family: ${({ theme }) => theme.fontFamilies.SalsaRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 100%;
    color: ${({ color = 'black' }) => color};
    margin: 0;
`;

export default {
    TextContainer,
    Text,
    ResponsiveHeaderContainer,
};
