/* eslint-disable no-param-reassign */
import { colors } from '@material-ui/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import googleMapReact, { Coords } from 'google-map-react';
import _ from 'lodash';
import { number } from 'prop-types';
import { FillerValues } from 'src/constants/misc';
import { StationsErrors } from 'src/screens/CoursesBuilding/components/CourseForm/CourseFormBody/StationsPanel/hooks.StationsPanel';
import {
    IOptimizationCsvWaypoint,
    IOptimizationFormWaypoint,
    IOptimizationWaypoint,
    IOptimumRouteData,
    ISimulationResult,
} from 'src/screens/Optimizations/api/types';
import { RootState } from 'src/store';
import { IRootReducer } from 'src/store/reducers';
import { ApiCallStatus } from 'src/types/apiCommon.types';

import { DataState, OptimizationsSliceState, UiState } from 'src/types/optimizations/sliceTypes';
import { uuid } from 'src/utilis/utilis';

const BEGINNING_OF_TIME = '1980-01-01 00:00:00';
// const COLOR_PLATE = ['#ff4242', '#796bff', '#ffc72d', '#fc8f00', '#ff9bee', '#dbdbdb', '#21CDF3', '#90B248', '#E221F3']
const COLOR_PLATE = ['lightGreen', '', 'black', 'gray', 'orange', 'red', 'blue', 'green', 'purple'];

export const initialWaypoints: IOptimizationFormWaypoint[] = [
    {
        city: '',
        street: '',
        houseNum: '',
        remarks: '',
        localId: uuid(),
        placeName: '',
        isTarget: false,
        passId: '',
        firstName: '',
        lastName: '',
    },
];

const initialUiState: UiState = {
    clientCode: null,
    filters: {
        freeSearch: '',
    },
    refreshBtnClickCount: 0,
    waypointsSelectedIds: [],
    lastSelected: null,
    isFormOpen: false,
    isParameterFormOpen: false,
    isEdit: false,
    isMapExpended: true,
    isMapEditMode: false,
    form: {
        waypointsErrors: {},
        waypoints: initialWaypoints,
        lastWaypointEditedIndex: null,
        waypointOnMapEditMode: null,
        updatedCoords: null,
        centerMapOnEdit: null,
        zoomMapOnEdit: null,
        triggerInputByCoords: 0,
        isEditForm: false,
        isDirty: false,
        lastEditedWaypointsPassIds: [],
    },
    isDeleteModalOpen: false,
    isDeleteFromRow: false,
    deleteDataItem: null,
    csvWaypointsObject: null,
    isCsvLoadDoneModalOpen: false,
    isGeneralLoading: false,
    lastMapFittedBounds: null,
    optimizationSimulationScreen: {
        isOpen: false,
        routesSelectedIdsWithColor: [],
        colorPlate: COLOR_PLATE,
        isEditRouteNameModalOpen: false,
        routeOnEdit: null
    },
    parametersForm: {
        maxTravelTime: 0,
        walkingDistanceFromStation: 0,
        maxTravelTimeRingTwo: 0,
        travelMethod: 1,
        travelType: 1,
        vehicleCapacity: 0,
    },
};

const initialDataState: DataState = {
    getWaypointsFromServer: {
        data: [],
        timeDelta: BEGINNING_OF_TIME,
        isGetNotActive: false,
        status: 'ON_STAND_BY',
    },
    getSimulationResult: {
        data: null,
        firstRingData: null,
        status: 'ON_STAND_BY',
        simulationHashId: null,
    },
};

const initialState: OptimizationsSliceState = {
    ui: initialUiState,
    data: initialDataState,
};

export const optimizationsSlice = createSlice({
    name: 'optimizations',
    initialState,
    reducers: {
        // ^ UI reducers ---------------------------------------------------------------------------
        // * Common
        setClientCode: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ clientCode: string }>
        ): void => {
            state.data.getWaypointsFromServer.data = [];
            state.data.getWaypointsFromServer.timeDelta = BEGINNING_OF_TIME;
            state.ui = { ...initialUiState, clientCode: action.payload.clientCode };
        },
        selectWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypointId: string }>
        ): void => {
            let foundIndex = -1;

            state.ui.waypointsSelectedIds.forEach((id, index) => {
                if (id === action.payload.waypointId) {
                    foundIndex = index;
                }
            });

            if (foundIndex !== -1) {
                state.ui.waypointsSelectedIds.splice(foundIndex, 1);
            } else {
                state.ui.waypointsSelectedIds.push(action.payload.waypointId);
            }
        },

        selectAllWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isSelected: boolean }>
        ): void => {
            state.ui.waypointsSelectedIds = [];

            if (action.payload.isSelected === false) {
                const currentTargetWaypoint = state.data.getWaypointsFromServer.data.find(
                    (waypoint) => waypoint.isTarget
                );

                if (currentTargetWaypoint) {
                    state.ui.waypointsSelectedIds.push(currentTargetWaypoint.waypointId);
                }
            }

            if (action.payload.isSelected) {
                state.data.getWaypointsFromServer.data.forEach((waypoint) => {
                    state.ui.waypointsSelectedIds.push(waypoint.waypointId);
                });
            }
        },
        setLastSelected: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypoint: IOptimizationWaypoint }>
        ): void => {
            state.ui.lastSelected = action.payload.waypoint;
        },
        setIsFormOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isFormOpen: boolean }>
        ): void => {
            state.ui.isFormOpen = action.payload.isFormOpen;
        },
        setIsParametersFormOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isParameterFormOpen: boolean }>
        ): void => {
            state.ui.isParameterFormOpen = action.payload.isParameterFormOpen;
        },
        setIsEdit: (state: OptimizationsSliceState, action: PayloadAction<{ isEdit: boolean }>): void => {
            state.ui.isEdit = action.payload.isEdit;
        },
        setMapIsExpanded: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isExpended: boolean }>
        ): void => {
            state.ui.isMapExpended = action.payload.isExpended;
        },
        setWaypointsErrors: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                errors: StationsErrors;
            }>
        ): void => {
            const waypointsErrors = _.cloneDeep(action.payload.errors);

            state.ui.form.waypointsErrors = waypointsErrors;
        },
        updateFormWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypoints: IOptimizationFormWaypoint[] }>
        ): void => {
            state.ui.form.waypoints = action.payload.waypoints;
        },
        setLastWaypointEditedIndex: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ lastWaypointEditedIndex: number }>
        ): void => {
            state.ui.form.lastWaypointEditedIndex = action.payload.lastWaypointEditedIndex;
        },
        setIsMapEditMode: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                isMapEditMode: boolean;
                waypointOnEditMode: IOptimizationFormWaypoint | null;
            }>
        ): void => {
            state.ui.isMapEditMode = action.payload.isMapEditMode;
            if (action.payload.waypointOnEditMode) {
                state.ui.form.waypointOnMapEditMode = action.payload.waypointOnEditMode;
            }
        },
        setUpdatedCoordsOnMap: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                lat: number;
                lng: number;
            } | null>
        ): void => {
            state.ui.form.updatedCoords = action.payload;
        },
        setCenterMapOnEdit: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                lat: number;
                lng: number;
            } | null>
        ): void => {
            state.ui.form.centerMapOnEdit = action.payload;
        },
        setZoomOnEdit: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ zoomLevel: number }>
        ): void => {
            state.ui.form.zoomMapOnEdit = action.payload.zoomLevel;
        },
        triggerInputByCoords: (state: OptimizationsSliceState): void => {
            state.ui.form.triggerInputByCoords += 1;
        },
        setWaypointStock: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                waypointStock: IOptimizationWaypoint[];
            }>
        ): void => {
            state.data.getWaypointsFromServer.data = action.payload.waypointStock;
        },
        setTimeDelta: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                timeDelta: string;
            }>
        ): void => {
            state.data.getWaypointsFromServer.timeDelta = action.payload.timeDelta;
        },
        setIsGetNotActive: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isGetNotActive: boolean }>
        ): void => {
            state.data.getWaypointsFromServer.isGetNotActive = action.payload.isGetNotActive;
        },
        setWaypointsOnFormEdit: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypoints: IOptimizationFormWaypoint[] }>
        ): void => {
            const lastEditedWaypointsPassIds: string[] = [];

            action.payload.waypoints.forEach((waypoint) => {
                lastEditedWaypointsPassIds.push(waypoint.passId);
            });

            state.ui.form.waypoints = action.payload.waypoints;
            state.ui.form.lastEditedWaypointsPassIds = lastEditedWaypointsPassIds;
        },
        resetLastEditedWaypointsPassIds: (state: OptimizationsSliceState): void => {
            state.ui.form.lastEditedWaypointsPassIds = [];
        },
        resetSelectedWaypointsIds: (state: OptimizationsSliceState): void => {
            state.ui.waypointsSelectedIds = [];
        },
        setIsEditForm: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isEditForm: boolean }>
        ): void => {
            state.ui.form.isEditForm = action.payload.isEditForm;
        },
        setIsFormDirty: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isDirty: boolean }>
        ): void => {
            state.ui.form.isDirty = action.payload.isDirty;
        },

        setIsDeleteModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                isDeleteModalOpen: boolean;
                isDeleteFromRow: boolean;
                deleteDataItem: IOptimizationWaypoint | null;
            }>
        ): void => {
            state.ui.isDeleteModalOpen = action.payload.isDeleteModalOpen;
            state.ui.isDeleteFromRow = action.payload.isDeleteFromRow;
            state.ui.deleteDataItem = action.payload.deleteDataItem;
        },
        setCsvWaypointsObject: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                csvWaypointsObject: {
                    validWaypoints: IOptimizationCsvWaypoint[];
                    invalidWaypoints: IOptimizationCsvWaypoint[];
                    unRecognizedWaypoints: IOptimizationCsvWaypoint[];
                } | null;
            }>
        ): void => {
            state.ui.csvWaypointsObject = action.payload.csvWaypointsObject;
        },
        setIsCsvLoadDoneModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            state.ui.isCsvLoadDoneModalOpen = action.payload.isOpen;
        },
        setIsEditRouteNameModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            state.ui.optimizationSimulationScreen.isEditRouteNameModalOpen = action.payload.isOpen;
        },
        setRouteOnEdit: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ route: IOptimumRouteData, }>
        ): void => {
            state.ui.optimizationSimulationScreen.routeOnEdit = action.payload.route;
        },
        setOptimumRoutes: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ route: IOptimumRouteData[], }>
        ): void => {

            if (state.data.getSimulationResult.data) {

                state.data.getSimulationResult.data.optimumRouteResult = action.payload.route;
            }
        },
        setIsGeneralLoading: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isLoading: boolean }>
        ): void => {
            state.ui.isGeneralLoading = action.payload.isLoading;
        },
        setGetWaypointsFromServerStatus: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ status: string }>
        ): void => {
            state.data.getWaypointsFromServer.status = action.payload.status;
        },
        setGetSimulationResult: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ status: string }>
        ): void => {
            state.data.getSimulationResult.status = action.payload.status;
        },
        setLastMapFittedBounds: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                bounds: {
                    center: {
                        lat: number;
                        lng: number;
                    };
                    zoom: number;
                    newBounds: googleMapReact.Bounds;
                };
            }>
        ): void => {
            state.ui.lastMapFittedBounds = action.payload.bounds;
        },
        setIsOptimizationSimulationScreenOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            state.ui.optimizationSimulationScreen.isOpen = action.payload.isOpen;
        },
        setSimulationResult: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult;
            }>
        ): void => {
            state.data.getSimulationResult.data = action.payload.simulationResult;
        },
        setSimulationResultFirstRing: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult | null;
            }>
        ): void => {
            state.data.getSimulationResult.firstRingData = action.payload.simulationResult;
        },
        selectSimulationRoute: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ routeId: string }>
        ): void => {
            let foundIndex = -1;

            state.ui.optimizationSimulationScreen.routesSelectedIdsWithColor.forEach(
                (selectedIdWithColor, index) => {
                    if (selectedIdWithColor.routeId === action.payload.routeId) {
                        foundIndex = index;
                    }
                }
            );

            if (foundIndex !== -1) {
                const spliced = state.ui.optimizationSimulationScreen.routesSelectedIdsWithColor.splice(
                    foundIndex,
                    1
                );

                if (spliced.length === 1) {
                    state.ui.optimizationSimulationScreen.colorPlate.push(spliced[0].color);
                }
            } else if (state.ui.optimizationSimulationScreen.colorPlate.length > 0) {
                const poppedColor = state.ui.optimizationSimulationScreen.colorPlate.pop();

                if (poppedColor) {
                    state.ui.optimizationSimulationScreen.routesSelectedIdsWithColor.push({
                        routeId: action.payload.routeId,
                        color: poppedColor,
                    });
                }
            }
        },
        setSimulationHashId: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                hashId: string | null;
            }>
        ): void => {
            state.data.getSimulationResult.simulationHashId = action.payload.hashId;
        },
        setMaxTravelTime: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxTravelTime: number;
                maxValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue) {
                state.ui.parametersForm.maxTravelTime =
                    action.payload.maxValue - action.payload.maxTravelTime;

                if (
                    state.ui.parametersForm.maxTravelTimeRingTwo !== null &&
                    state.ui.parametersForm.maxTravelTimeRingTwo <=
                    action.payload.maxValue - action.payload.maxTravelTime
                ) {
                    state.ui.parametersForm.maxTravelTimeRingTwo =
                        action.payload.maxValue - action.payload.maxTravelTime;
                }
            } else {
                state.ui.parametersForm.maxTravelTime = action.payload.maxTravelTime;
                if (
                    state.ui.parametersForm.maxTravelTimeRingTwo !== null &&
                    state.ui.parametersForm.maxTravelTimeRingTwo <= action.payload.maxTravelTime
                ) {
                    state.ui.parametersForm.maxTravelTimeRingTwo = action.payload.maxTravelTime;
                }
            }
        },
        setMaxTravelTimeRingTwo: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxTravelTimeRingTwo: number;
                maxValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue) {
                state.ui.parametersForm.maxTravelTimeRingTwo =
                    action.payload.maxValue - action.payload.maxTravelTimeRingTwo;
                if (
                    state.ui.parametersForm.maxTravelTime !== null &&
                    state.ui.parametersForm.maxTravelTime >=
                    action.payload.maxValue - action.payload.maxTravelTimeRingTwo
                ) {
                    state.ui.parametersForm.maxTravelTime =
                        action.payload.maxValue - action.payload.maxTravelTimeRingTwo;
                }
            } else {
                state.ui.parametersForm.maxTravelTimeRingTwo = action.payload.maxTravelTimeRingTwo;
                if (
                    state.ui.parametersForm.maxTravelTime !== null &&
                    state.ui.parametersForm.maxTravelTime >= action.payload.maxTravelTimeRingTwo
                ) {
                    state.ui.parametersForm.maxTravelTime = action.payload.maxTravelTimeRingTwo;
                }
            }
        },
        setWalkingDistanceFromStation: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                walkingDistanceFromStation: number;
                maxValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue) {
                state.ui.parametersForm.walkingDistanceFromStation =
                    action.payload.maxValue - action.payload.walkingDistanceFromStation;
            } else {
                state.ui.parametersForm.walkingDistanceFromStation =
                    action.payload.walkingDistanceFromStation;
            }
        },
        setVehicleCapacity: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                vehicleCapacity: number;
                maxValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue) {
                state.ui.parametersForm.vehicleCapacity =
                    action.payload.maxValue - action.payload.vehicleCapacity;
            } else {
                state.ui.parametersForm.vehicleCapacity = action.payload.vehicleCapacity;
            }
        },
        setTravelMethod: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                travelMethod: number;
            }>
        ): void => {
            state.ui.parametersForm.travelMethod = action.payload.travelMethod;
        },
        setTravelType: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                travelType: number;
            }>
        ): void => {
            state.ui.parametersForm.travelType = action.payload.travelType;
        },
    },
});

// * Exports
// ^ Actions
export const {
    setClientCode: setClientCodeAction,
    selectWaypoint: selectWaypointAction,
    setLastSelected: setLastSelectedAction,
    setIsFormOpen: setIsFormOpenAction,
    setIsParametersFormOpen: setIsParametersFormOpenAction,
    setIsEdit: setIsEditAction,
    setMapIsExpanded: setMapIsExpandedAction,
    updateFormWaypoint: updateFormWaypointAction,
    setLastWaypointEditedIndex: setLastWaypointEditedIndexAction,
    setWaypointsErrors: setWaypointsErrorsAction,
    setIsMapEditMode: setIsMapEditModeAction,
    setUpdatedCoordsOnMap: setUpdatedCoordsOnMapAction,
    setCenterMapOnEdit: setCenterMapOnEditAction,
    triggerInputByCoords: triggerInputByCoordsAction,
    setWaypointStock: setWaypointStockAction,
    setTimeDelta: setTimeDeltaAction,
    setIsGetNotActive: setIsGetNotActiveAction,
    setWaypointsOnFormEdit: setWaypointsOnFormEditAction,
    setIsEditForm: setIsEditFormAction,
    setIsDeleteModalOpen: setIsDeleteModalOpenAction,
    setIsFormDirty: setIsFormDirtyAction,
    setCsvWaypointsObject: setCsvWaypointsObjectAction,
    setIsCsvLoadDoneModalOpen: setIsCsvLoadDoneModalOpenAction,
    setIsGeneralLoading: setIsGeneralLoadingAction,
    selectAllWaypoint: selectAllWaypointAction,
    setGetWaypointsFromServerStatus: setGetWaypointsFromServerStatusAction,
    setLastMapFittedBounds: setLastMapFittedBoundsAction,
    setZoomOnEdit: setZoomOnEditAction,
    setIsOptimizationSimulationScreenOpen: setIsOptimizationSimulationScreenOpenAction,
    setSimulationResult: setSimulationResultAction,
    setSimulationResultFirstRing: setSimulationResultFirstRingAction,
    selectSimulationRoute: selectSimulationRouteAction,
    setGetSimulationResult: setGetSimulationResultAction,
    setSimulationHashId: setSimulationHashIdAction,
    setTravelType: setTravelTypeAction,
    setTravelMethod: setTravelMethodAction,
    setWalkingDistanceFromStation: setWalkingDistanceFromStationAction,
    setMaxTravelTime: setMaxTravelTimeAction,
    setMaxTravelTimeRingTwo: setMaxTravelTimeRingTwoAction,
    resetLastEditedWaypointsPassIds: resetLastEditedWaypointsPassIdsAction,
    resetSelectedWaypointsIds: resetSelectedWaypointsIdsAction,
    setVehicleCapacity: setVehicleCapacityAction,
    setIsEditRouteNameModalOpen: setIsEditRouteNameModalOpenAction,
    setRouteOnEdit: setRouteOnEditAction,
    setOptimumRoutes: setOptimumRoutesAction,
} = optimizationsSlice.actions;

export const optimizationsActions = optimizationsSlice.actions;

// ^ Selectors

export const optimizationsRootSelector = (state: IRootReducer) => state.optimizationSlice;

// ^ Reducer export
const optimizationsReducer = optimizationsSlice.reducer;
export default optimizationsReducer;
