import React, { useEffect, useRef, useState } from 'react';
import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import useCommons from 'src/hooks/common/useCommons';
import FormHeader from 'src/components/formComponents/FormHeader';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { msColors } from 'src/style/themes/defaultTheme';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import { setIsParametersFormOpenAction } from 'src/store/slices/optimizations/optimizationsSlice';
import TextInput from 'src/components/Inputs/TextInput';

const Input = styled(MuiInput)`
    width: 42px;
`;

export interface SliderProps {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSliderChange: (event: Event, newValue: number | number[], activeThumb: number) => void;
    value: number;
    min: number;
    max: number;
    step: number;
}

const NumberSlider: React.FC<SliderProps> = ({
    handleInputChange,
    handleSliderChange,
    value,
    min,
    max,
    step,
}) => {
    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs sx={{ transform: 'scaleX(-1)' }}>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        max={max}
                        min={min}
                    />
                </Grid>
                <Grid item>
                    <TextInput
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        inputProps={{
                            step,
                            min,
                            max,
                            type: 'string',
                            'aria-labelledby': 'input-slider',
                        }}
                        sx={{ width: '55px' }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
export default NumberSlider;
