import { CreateLineRequestConfig } from 'src/types/lines/api/types';
import { FormSchema, Station } from 'src/screens/Main/components/AddLineForm/types';
import { buildLineDescription, buildStationsJson } from 'src/screens/Main/utils';
import { getMapsAPIKeyNoRestrict, stringifyDate } from 'src/utilis/utilis';
import useDirectionsApiLines, {
    getStationsWithUpdatedTimeFromPrev,
    isAllObjectsContainsCoords,
} from 'src/screens/Main/hooks/useDirectionsApiLines';
import { string } from 'prop-types';
import axios from 'axios';
import { error } from 'console';

export interface FormDataWithStations extends FormSchema {
    stationsLst: Station[];
}
const transitProxy = 'https://interfaceserviceapi.y-it.co.il/ProxyService/ProxyService.svc/Proxy?url=';

const buildProxyUrl = (url: string, encodedParams: string): string => `${transitProxy}${url}${encodedParams}`;

const calculateReturnRouteTiming = async (endAddress: any, startAddress: any, stations: any) => {
    const parsedStations: String[] = [];

    stations.forEach((station: any) => {
        parsedStations.push(`${station.lat},${station.lng}`);
    });

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Host: 'example.com',
        },
    };

    const directionsAPI = `https://maps.googleapis.com/maps/api/directions/json?`;
    const encodedParams = encodeURIComponent(
        `origin=${startAddress.lat},${startAddress.lng}&destination=${endAddress.lat},${
            endAddress.lng
        }&waypoints=${parsedStations}&mode=driving&key=${getMapsAPIKeyNoRestrict()}`
    );
    const res = buildProxyUrl(directionsAPI, encodedParams);

    try {
        const response: any = await fetch(res, requestOptions);

        const data = await response.json();

        if (response && data && data.routes && data.routes[0] && data.routes[0].legs) {
            const stationsObject = getStationsWithUpdatedTimeFromPrev(
                data.routes[0].legs,
                startAddress,
                stations
            );

            return stationsObject;
        }
    } catch (err) {
        console.log('error directionsAPI', err);
    }
};

const createBothTripPayloads = async (
    formDataWithStations: FormDataWithStations,
    token: string,
    dbUrl: string
) => {
    const { startAddress, endAddress, stationsLst, description } = formDataWithStations;

    const isAllStationsWithCoords = isAllObjectsContainsCoords([...stationsLst, endAddress, startAddress]);

    const startLinePayload: CreateLineRequestConfig = {
        dbUrl,
        requestParams: {
            token,
            clientCode: String(formDataWithStations.clientCode),
            lineDate: stringifyDate(formDataWithStations.date),
            startTime: formDataWithStations.startTime,
            lineDescription: description ? description : buildLineDescription(startAddress, endAddress),
            stationsJson: buildStationsJson(
                stationsLst,
                startAddress,
                endAddress,
                null,
                isAllStationsWithCoords
            ),
            longRemarks: formDataWithStations.lineRemarks || '',
            endTime: formDataWithStations.endTime,
            flightNumber: formDataWithStations.flightNum || '',
            invContactName: formDataWithStations.invContactName || '',
            invContactPhone: `${formDataWithStations.invContactPhonePrefix}${formDataWithStations.invContactPhone}`,
            clientOrderCode: Number(formDataWithStations.clientOrderCode) || '',
            passQty: formDataWithStations.passQty || null,
            carTypeCode: formDataWithStations.carId || null,
        },
    };

    const copiedArray = JSON.parse(JSON.stringify(stationsLst));
    const reversedStations = copiedArray.reverse();

    const returnAllStations: any = [endAddress, ...reversedStations, startAddress];

    let currentTiming = 0;

    const stationsWithTiming: any = [];

    returnAllStations.forEach((station: any) => {
        const tempTiming = station.timing ?? 0;

        stationsWithTiming.push({ ...station, timing: currentTiming });

        currentTiming = tempTiming;
    });

    const stationsObject = await calculateReturnRouteTiming(
        startAddress,
        endAddress,
        [...stationsLst].reverse()
    );

    const endLinePayload: CreateLineRequestConfig = {
        dbUrl,
        requestParams: {
            ...startLinePayload.requestParams,
            startTime: formDataWithStations.returnTripStartTime || '',
            lineDescription: description ? description : buildLineDescription(endAddress, startAddress),
            stationsJson: stationsObject
                ? buildStationsJson(
                      stationsObject.stationsWithTimingAndDistance,
                      stationsObject.endAddressWithPrevTimeAndDistance,
                      startAddress,
                      null,
                      isAllStationsWithCoords
                  )
                : buildStationsJson(
                      [...stationsLst].reverse(),
                      endAddress,
                      startAddress,
                      null,
                      isAllStationsWithCoords
                  ),
            endTime: formDataWithStations.returnTripEndTime,
        },
    };

    return { startLine: startLinePayload, endLine: endLinePayload };
};

export const linesHelpers = {
    createBothTripPayloads,
};
