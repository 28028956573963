import React, { CSSProperties, FC } from 'react';
import styled, { useTheme } from 'styled-components';

const TextContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const Text = styled.p<{
    color?: string;
}>`
    font-family: ${({ theme }) => theme.fontFamilies.SalsaRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 100%;
    color: ${({ color = 'black' }) => color};
    margin: 0;
`;

const AppName: FC = () => {
    const { msColors } = useTheme();
    const headlineCss: CSSProperties = { fontSize: '30px' };

    return (
        <TextContainer className="hidden-wl-1280">
            <Text style={headlineCss} color={msColors.darkTextcolor}>
                Studio
            </Text>
            <Text style={{ whiteSpace: 'break-spaces' }}> </Text>
            <Text style={headlineCss} color={msColors.yellow}>
                MyWay
            </Text>
        </TextContainer>
    );
};

export default AppName;
