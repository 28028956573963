/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTestingFullBox } from 'src/style/testing';

import ReportTypeDropdown from './ReportTypeDropdown/ReportTypeDropdown';
import menuStyles, { MenuButton } from './Menu.style';
import ActionButtons from './ActionButtons/ActionButtons';

type Props = {
    // eslint-disable-next-line react/no-unused-prop-types
    tableRef: any;
    excelExportRef: any;
};

const Menu = (props: Props) => {
    return (
        <div>
            <menuStyles.menuContainer>
                <ReportTypeDropdown />
                <ActionButtons {...props} />
            </menuStyles.menuContainer>
        </div>
    );
};

export default Menu;
