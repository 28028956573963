import { Select, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { Card } from '../../Orderinfo.Style';

const { height } = window.screen;

export const Container = styled(Card)`
    width: 100%;
    // min-height: 400px;
    height: ${height > 800 ? '55%' : '250px'};

    padding: 1rem 1rem 0 1rem;
`;

export const Dropdown = styled(Select)`
    width: 100%;
    height: 44px;
    border-radius: 4px;
    padding: 0 8px;
    .MuiSelect-select:focus {
        background-color: transparent;
    }

    &::before,
    ::after {
        content: none;
    }
`;

export const StyledTabs = styled(Tabs)`
    margin-top: 5px;
    font-size: 16px;
    .MuiTab-root {
        /* max-width: 30%; */
    }
    .MuiTab-root {
        width: 33%;

        @media all and (min-width: 600px) {
            width: 33%;
            min-width: unset;
        }
    }
    display: block;
    &:after {
        content: '';
        display: block;
        height: 0.5px;
        width: 100%;
        margin-top: -5px;
        background: #b4bec9;
    }
`;

export const ProgressContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    position: relative;
`;
