/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/extensions */
/* eslint-disable prettier/prettier */
import React, { FC, useState, ChangeEvent, useEffect, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import sortBy from 'lodash.sortby';
import useLineStatusTranslate from 'src/screens/Main/components/Lines/hooks/useLineStatusTranslate';
import { IPassenger, LineStatus } from 'src/types/line';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { images } from 'src/assets/index';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { lineSelector } from 'src/store/selectores/linesSelectores';
import Input from 'src/components/commons/Input/Input';
import { StyledWidgetHeaderContainer } from 'src/screens/styles/Widgets.style';
import styles, { accordionStyles } from './LineDetails.style';
import Passenger from './Passenger/Passenger';
import passengerStyles from './Passenger/Passenger.style';

// import {StyledBtnsGroup} from './StyledBtnsGroup.style'
import './lineDetails.css';
import DetailsAccordionBody from './DetailsAccordionBody/StyledDetailsAccordionBody/DetailsAccordionBody';
import AccordionExpandIcon, { useExpand } from './AccordionExpandIcon/index.AccordionExpandIcon';
import { useRootAppSelector } from 'src/store/hooks';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { showStationOrPassengerComponent } from '../../utils';

enum Sort {
    PASSENGER,
    TIME,
}

interface Props {}

const LineDetails: FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const selectedLine = useSelector((state) => lineSelector(state));
    const {
        visaNumber,
        passengers: allStationsAndPassengers,
        startTime,
        lineDescription,
    } = selectedLine || {};

    const getLineTranslation = useLineStatusTranslate();

    const [stationsAndPassengers, setStationsAndPassengers] = useState(allStationsAndPassengers);
    const [sortByType, setSortByType] = useState<string>();

    const [filterState, setfilterState] = useState({
        sortBy: Sort.PASSENGER,
        textFilter: '',
    });

    useEffect(() => {
        setStationsAndPassengers(allStationsAndPassengers);
    }, [allStationsAndPassengers]);

    const onSortBy = (event: React.MouseEvent<HTMLElement>, sortKey: string) => {
        setSortByType(sortKey);
        const sortablePassengers = sortBy(stationsAndPassengers, [sortKey]);
        setStationsAndPassengers(sortablePassengers);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const textFilter = e.target.value;
        setfilterState((preState) => ({
            ...preState,
            textFilter,
        }));

        if (!textFilter) {
            setStationsAndPassengers(allStationsAndPassengers);
            return;
        }

        setStationsAndPassengers(allStationsAndPassengers?.filter((p) => p.fullName.includes(textFilter)));
    };

    let color = '';
    if (selectedLine && selectedLine.lineStatus !== LineStatus.Undifined)
        color = selectedLine.lineStatus === LineStatus.Ended ? '#23DD04' : '#EBB835';

    const elipesStyle: CSSProperties = {
        backgroundColor: `${color}`,
        height: '5px',
        width: '5px',
        borderRadius: '50%',
    };

    const alignIconText: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    };

    const { isExpanded, onClick } = useExpand();

    const loggedUser = useRootAppSelector(userDetailsSelector);

    return (
        <>
            {!selectedLine ? (
                <styles.EmptyLine>
                    <styles.Text> {t('selectLineFromList')}</styles.Text>
                </styles.EmptyLine>
            ) : (
                <>
                    {/* Line widget header */}
                    <styles.Header>
                        <StyledWidgetHeaderContainer className="StyledWidgetHeaderContainer">
                            <styles.Row
                                mt={0} // margin top - was 5
                                mb={0} // margin bottom - was 20
                                style={{
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                }}
                            >
                                {/* Icon */}
                                <styles.Image src={images.alartIcon} />
                                {/* נסיעה Title */}
                                <styles.Title fontSize="30px">{t('line')}</styles.Title>
                                {/* Number of visa */}
                                {visaNumber && <styles.Title>{`(${visaNumber})`}</styles.Title>}
                            </styles.Row>
                            <styles.Row style={{ marginLeft: '2%' }}>
                                <styles.Text>{`${getLineTranslation(selectedLine.lineStatus)}`}</styles.Text>
                                {selectedLine.lineStatus !== 0 && <span style={elipesStyle} />}
                            </styles.Row>
                        </StyledWidgetHeaderContainer>
                    </styles.Header>

                    {/* Line details */}
                    <Accordion sx={accordionStyles.main} className="non-box-shadow" expanded={isExpanded}>
                        <AccordionSummary sx={accordionStyles.summary}>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    paddingLeft: '5px',
                                }}
                                onClick={onClick}
                            >
                                <styles.Row>
                                    <styles.Text style={accordionStyles.summaryText}>
                                        {moment(startTime).format('HH:mm')}
                                    </styles.Text>
                                    <styles.Text>{lineDescription}</styles.Text>
                                </styles.Row>
                                <AccordionExpandIcon isExpanded={isExpanded} onClick={() => {}} />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionStyles.details}>
                            <DetailsAccordionBody />
                        </AccordionDetails>
                    </Accordion>

                    {/* Line filtering */}
                    <styles.FilterContainer
                        style={{ fontSize: '0.8em', padding: '10px' }}
                        className="line-details"
                    >
                        {/* "Passengers" title */}
                        <styles.Title style={{ fontWeight: 'bold' }}>
                            {`${t('passengers')} (${selectedLine.passQty})`}
                        </styles.Title>

                        {/* Free search input */}
                        <Input
                            style={{ width: '40%', backgroundColor: 'white' }}
                            size="small"
                            label={t('search')}
                            // label='חיפוש חופשי'
                            labelWidth={50}
                            value={filterState.textFilter}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon style={{ height: '0.8em' }} />
                                </InputAdornment>
                            }
                        />

                        {/* Sort input */}
                        <styles.Row>
                            {/* Label */}
                            <styles.Label style={{ paddingLeft: '1em' }}>מיון לפי</styles.Label>
                            {/* Buttons group */}
                            <ToggleButtonGroup
                                style={{ backgroundColor: 'white' }}
                                size="small"
                                exclusive
                                value={sortByType}
                                onChange={onSortBy}
                            >
                                {/* <StyledBtnsGroup> */}
                                <ToggleButton value="fullName">{t('passenger')}</ToggleButton>
                                <ToggleButton value="time">{t('time')}</ToggleButton>
                                {/* </StyledBtnsGroup> */}
                            </ToggleButtonGroup>
                        </styles.Row>
                    </styles.FilterContainer>
                    <passengerStyles.Row mt={20} mr={7}>
                        <passengerStyles.Text opacity="0.5" width="20%" style={alignIconText}>
                            <QueryBuilderOutlinedIcon fontSize="small" />
                            {t('time')}
                        </passengerStyles.Text>

                        <passengerStyles.Text opacity="0.5" width="30%" style={alignIconText}>
                            <PersonOutlineIcon fontSize="small" />
                            <span>{t('passengerName')}</span>
                        </passengerStyles.Text>

                        <passengerStyles.Text opacity="0.5" width="50%" style={alignIconText}>
                            <RoomIcon fontSize="small" />
                            {t('station')}
                        </passengerStyles.Text>
                    </passengerStyles.Row>
                    <styles.PassengersConatiner className="scroll-sm-size" style={{ overflowX: 'hidden' }}>
                        {stationsAndPassengers?.map((passenger) =>
                            showStationOrPassengerComponent(loggedUser.departmentCode || '', passenger) ? (
                                <Passenger key={passenger.code} {...passenger} />
                            ) : null
                        )}
                    </styles.PassengersConatiner>
                </>
            )}
        </>
    );
};

export default LineDetails;

// <styles.Text>
//     {longRemarks
//         ? `${t('longRemarks')}: ${longRemarks}`
//         : ''}
// </styles.Text>
// <styles.Text>
//     {mobilePhone
//         ? `${t('phone')} ${t(
//               'driver'
//           )}: ${mobilePhone}`
//         : ''}
// </styles.Text>
// <styles.Text>
//     {departmentName ? `${t('department')}: ` : ''}{' '}
//     {departmentName}
// </styles.Text>
// {/* <styles.Text>
//     {invContactName
//         ? `${t(
//               'invContactName'
//           )}: ${invContactName}`
//         : ''}
// </styles.Text>
// <styles.Text>

//     {invContactPhone
//         ? `${t(
//               'invContactPhone'
//           )}: ${invContactPhone}`
//         : ''}
// </styles.Text>
// <styles.Text>
//     {flightNumber
//         ? `${t('flightNum')}: ${flightNumber}`
//         : ''}
// </styles.Text>
// <styles.Text>
//     {clientOrderCode
//         ? `${t(
//               'clientOrderCode'
//           )}: ${clientOrderCode}`
//         : ' '}
// </styles.Text> */}
