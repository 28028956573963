import React, { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import { useAppSelector } from 'src/store/hooks';
import { formSelector, newPassengerFormSelector } from 'src/store/slices/lines/linesSlice';
import { useCurrFormType } from '../../addLinesForm.hooks';
import { FormTypes } from '../../types';
import ExistingPassengersForm from './passengersForms/ExistingPassengersForm';
import NewPassengerForm from './passengersForms/NewPassengerForm';

type Props = {
    form: any;
};
const passengersFormTypes: FormTypes[] = [
    'addingPassengersToStation',
    'creatingStationsFromPassengers',
    'editingPassenger',
    'newPassenger',
];
const PassengersForm: React.FunctionComponent<Props> = ({ form }) => {
    const currForm = useCurrFormType();
    const isNewPassengerForm = useMemo(
        () => currForm !== 'addingPassengersToStation' && currForm !== 'creatingStationsFromPassengers',

        [currForm]
    );
    return (
        <FormProvider {...form}>
            <DisplayWrapper show={passengersFormTypes.includes(currForm)}>
                {isNewPassengerForm ? <NewPassengerForm /> : <ExistingPassengersForm />}
            </DisplayWrapper>
        </FormProvider>
    );
};

export default PassengersForm;
