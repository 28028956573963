import React from 'react';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { asTSR } from 'src/utilis/utilis';

const useCommons = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const dispatchI18nAlertErrMsg = React.useCallback(
        (msg?: string | null | undefined) => {
            dispatch(setErrorMessage(t(asTSR(msg || ErrorMessages.GeneralError))));
        },
        [dispatch, t]
    );

    return {
        t,
        dispatch,
        useRootAppSelector,
        dispatchI18nErrMsg: dispatchI18nAlertErrMsg,
    };
};

export default useCommons;
