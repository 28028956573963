import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { CSSObject } from 'styled-components';
import { fontFamilies, msColors } from 'src/style/themes/defaultTheme';
import MyRefreshIcon from 'src/components/Icons/MyRefreshIcon';
import { useAppDispatch } from 'src/store/hooks';
import { onRefreshBtnClickAction } from 'src/store/slices/reports/reportsSlice';
import * as Styles from './styles';
// import useOrderInfo from './useOrderInfo';

const StyledWidgetsFeatureHeader = styled.p`
    font-family: ${fontFamilies.Rubik};
    width: 100%;
    font-weight: normal;
    font-size: 33px;
    color: ${msColors.darkTextcolor};
    text-align: right;
`;

interface Props {
    onReload?: () => void;
    containerStyle?: CSSObject;
}

const WidgetWrapperV2: FC<Props> = ({ onReload, children, containerStyle }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [refreshed, setRefreshed] = useState(false);

    const reload = () => {
        if (onReload) {
            onReload();
            setRefreshed(true);
            return;
        }

        dispatch(onRefreshBtnClickAction());
        setRefreshed(true);
    };

    useEffect(() => {
        if (!refreshed) return;
        setTimeout(() => setRefreshed(false), 1000);
    }, [refreshed, setRefreshed]);

    return (
        <Styles.Root style={containerStyle || {}} className="widget-root-container">
            {onReload ? (
                <Styles.Header className="widget-header-container">
                    {/* <StyledWidgetsFeatureHeader>{t('widgetsTitle')}</StyledWidgetsFeatureHeader> */}
                    <div />
                    <Styles.ReloadBtn disabled={refreshed} onClick={reload}>
                        <MyRefreshIcon width="16px" height="16px" color="black" />

                        <p style={{ paddingRight: '8px' }}>{t('refresh')}</p>
                    </Styles.ReloadBtn>
                </Styles.Header>
            ) : null}
            {children}
        </Styles.Root>
    );
};

export default WidgetWrapperV2;
