import { IShifts, OrderAddresses } from 'src/types/manualOrders/api.types';
import { IAddress } from 'src/types/misc';

export const isRegularShift = ({
    pickupTime,
    dropTime,
    shifts,
}: {
    pickupTime: string;
    dropTime: string;
    shifts: IShifts[];
}): boolean => {
    return shifts.some((shift) => shift.pickupTime === pickupTime && shift.dropTime === dropTime);
};

/**
 * Checks if the given date is in the format of a relative date.
 * @param {string} date - the date to check
 * @returns {boolean} - true if the date is in the correct format, false otherwise.
 */
export const isRelativeDate = (date: string): boolean => {
    if (date.length !== 10) return false;
    if (date.includes('.')) return false;
    if (date.includes('/')) return false;
    if (!date.includes('-')) return false;

    const parts = date.split('-');
    if (parts.length !== 3) return false;
    if (parts[0].length !== 4) return false;
    if (parts[1].length !== 2) return false;
    if (parts[2].length !== 2) return false;

    return true;
};

export const buildAddresseesParams = (addresses: {
    pickup: IAddress | null;
    drop: IAddress | null;
}): OrderAddresses => {
    const { pickup, drop } = addresses;
    return {
        pickupCity: pickup?.city || '',
        pickupStreet: pickup?.street || '',
        pickupHouseNum: pickup?.houseNum || '',
        dropCity: drop?.city || '',
        dropStreet: drop?.street || '',
        dropHouseNum: drop?.houseNum || '',
    };
};
