import { GridColumnProps } from '@progress/kendo-react-grid';
import React, { useCallback } from 'react';
import { ReportName } from 'src/store/slices/reports/types';
import { isDict } from '../../../../../../../utilis/utilis';

export interface LocalStorageColumns {
    [key: string]: {
        width: string | number | undefined;
        orderIndex: number | undefined;
    };
}

// type guard for LocalStorageColumns
export const isLocalStorageColumns = (obj: any): obj is LocalStorageColumns => {
    if (!isDict(obj)) {
        return false;
    }

    Object.keys(obj).forEach((key) => {
        const element = obj[key];
        if (typeof element !== 'object') {
            return false;
        }
        if (!('width' in element) || !('orderIndex' in element)) {
            return false;
        }
    });

    return true;
};

const useColumnsFromPrevSession = () => {
    const [localStorageCols, setLocalStorageCols] = React.useState<LocalStorageColumns | null>(null);

    const syncColumnsFromLocalStorage = useCallback(() => {
        try {
            const localStorageItem = localStorage.getItem('reportsTableColumns');
            const parsed = JSON.parse(localStorageItem || '');

            if (isLocalStorageColumns(parsed)) {
                setLocalStorageCols(parsed);
                return;
            }

            setLocalStorageCols(null);
        } catch (e) {
            console.log(e);
            setLocalStorageCols(null);
        }
    }, []);

    React.useEffect(() => {
        // Sync after first render
        syncColumnsFromLocalStorage();

        window.addEventListener('storage', syncColumnsFromLocalStorage);

        return () => {
            window.removeEventListener('storage', syncColumnsFromLocalStorage);
        };
    }, [syncColumnsFromLocalStorage]);

    return { localStorageCols };
};

export default useColumnsFromPrevSession;
