/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { CSSProperties, FunctionComponent, useCallback, useRef, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { Slide, Zoom } from '@mui/material';
import { centeredFlexItem } from 'src/style/styledCSS';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import { iconButtonStyle } from '../styles/styles';
import EditPassengerBtn from '../EditPassengerBtn/EditPassengerBtn';
import { RestorePassengerIcon } from '../../screens/PassengerManger/components/Passengers/PassengersGrid/RestorePassengerIcon';
import { useTranslation } from 'react-i18next';
import { MuiTooltipV3 } from '../MuiTooltip/MuiTooltip';

export interface IKendoDeleteEditeCell extends GridCellProps {
    onEdit: (dataItem: any) => unknown;
    onDelete: (dataItem: any) => unknown;
    onRestore: (dataItem: any) => unknown;
    hoverRowItemCode: any;
}

const KendoDeleteEditeCell: FunctionComponent<IKendoDeleteEditeCell> = ({
    dataItem,
    onEdit,
    onDelete,
    onRestore,
    hoverRowItemCode,
}: IKendoDeleteEditeCell) => {
    const { t } = useTranslation();

    // eslint-disable-next-line no-console
    if (!onEdit) console.warn('onEdit was not supplied');
    // eslint-disable-next-line no-console
    if (!onDelete) console.warn('onDelete was not supplied');

    const isHover =
        dataItem && hoverRowItemCode ? String(dataItem.passCode) === String(hoverRowItemCode) : false;

    return (
        <td className="optionsCell" style={{ padding: 0 }}>
            {dataItem && isHover ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <EditPassengerBtn onEdit={onEdit} dataItem={dataItem} />
                    {dataItem.isActive === '1' ? (
                        // Delete passenger btn
                        <MuiTooltipV3 title={t('delete')}>
                            <IconButton
                                onClick={() => typeof onDelete === 'function' && onDelete(dataItem)}
                                style={iconButtonStyle}
                                aria-label="מחיקה"
                                size="small"
                            >
                                <TrashIcon height="18" color="" />
                            </IconButton>
                        </MuiTooltipV3>
                    ) : (
                        // restore passenger btn
                        <MuiTooltipV3 title={t('restore')}>
                            <IconButton
                                onClick={() => onRestore(dataItem)}
                                style={iconButtonStyle}
                                aria-label="שחזור"
                                size="small"
                            >
                                <SettingsBackupRestoreOutlinedIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </MuiTooltipV3>
                    )}
                </div>
            ) : null}
        </td>
    );
};

export default KendoDeleteEditeCell;
