import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const GridContainer = styled(Grid)`
    height: 100%;
    overflow: hidden;
`;

const GridItem = styled(Grid)`
    height: 100%;
    /* border: 2px solid ${({ color = 'black' }) => color}; */
`;

const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    // display: flex;
    display: grid;
    grid-template-columns: 1fr 11fr;
    // overflow: auto;
`;
const FullContainer = styled.div`
    // overflow: auto;
    // display: grid;
    width: 100%;
    // height: 100%;
    height: 100vh;
`;
export const mainStyles = { MainContainer, FullContainer };

export default { Container, GridContainer, GridItem };
