import useCommons from 'src/hooks/common/useCommons';
import { coursesBuildingSelectors } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

const useCoursesData = () => {
    const { useRootAppSelector } = useCommons();

    const allCourses = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getAccountCourses.data
    );

    const getCourseByCode = (code: string) => {
        return allCourses.find((course) => course.courseCode === code);
    };

    return { getCourseByCode, allCourses };
};

export default useCoursesData;
