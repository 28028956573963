import { CarType } from 'src/types/lines/api/apiProccessed.types';
import { WsGetCarTypeResponseDataItem } from 'src/types/lines/api/types';
import numberHelpers from '../../utilis/numberHelpers';

const processGetCarTypes = (data: WsGetCarTypeResponseDataItem[]): CarType[] => {
    const processed = data.map((car, i) => {
        return {
            id: String(car.carTypeCode),
            type: car.carType,
            passQty: car.passQty || null,
            price: numberHelpers.isNumericString(car.clientPrice) ? Number(car.clientPrice) : null,
        };
    });
    return processed;
};

export const linesBl = {
    processGetCarTypes,
};
