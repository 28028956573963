import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MultiProgressbar from 'src/components/MultiProgressbar/MultiProgressbar';
import { v4 } from 'uuid';
import styled from 'styled-components';

import { getByLabelText } from '@testing-library/react';
import { IProgressData } from '../../../types';

const ProgressBarsListContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    height: 100%;
    padding-bottom: 5px;
    justify-content: space-around;
`;
interface Props {
    data: IProgressData[];
    max: number;
    releativeDate: string;
    dataType: string;
}
const ProgressbarList: FC<Props> = ({ data, max, releativeDate, dataType }) => {
    const { t } = useTranslation();
    const getLabeByDataType = () => {
        if (dataType === 'depts') return t('withoutDept');
        return t('withoutShift');
    };
    return (
        <ProgressBarsListContainer>
            {data.length === 0 ? (
                <div style={{ marginTop: 5 }}>אין מידע</div>
            ) : (
                data.map((d) => (
                    <MultiProgressbar
                        key={d.code}
                        code={d.code || v4()}
                        drop={d.drop}
                        pickup={d.pick}
                        position="Vertical"
                        label={d.name || `(${getLabeByDataType()})`}
                        max={max}
                        date={releativeDate}
                    />
                ))
            )}
        </ProgressBarsListContainer>
    );
};

export default ProgressbarList;
