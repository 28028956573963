import _ from 'lodash';

export const INITIAL_CODE = -1;
export const CODE_KEY = 'code';

const INITIAL_RESULT = {
    [CODE_KEY]: INITIAL_CODE,
    data: {
        data: null,
        message: null,
    },
} as const;

export const getInitialResultCopy = () => _.cloneDeep(INITIAL_RESULT);
