import styled from 'styled-components';

export const FiltersDropdownContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
`;

export const CountAndClearFiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
