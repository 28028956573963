/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coords } from 'google-map-react';
import _ from 'lodash';
import { FillerValues } from 'src/constants/misc';
import { StationsErrors } from 'src/screens/CoursesBuilding/components/CourseForm/CourseFormBody/StationsPanel/hooks.StationsPanel';
import { IRootReducer } from 'src/store/reducers';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import { CoursesFilters, CourseTypes } from 'src/types/coursesBuilding/commonTypes';
import { StationSchema, TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import {
    ApiCallsNames,
    CoursesBuildingSliceState,
    CourseBuildingTabs,
    UiState,
    TimesSettingsErrors,
} from 'src/types/coursesBuilding/storeTypes';
import { uuid } from 'src/utilis/utilis';

const ALL_DAYS_NUMS = [1, 2, 3, 4, 5, 6, 7];
// * Slice
const getDefaultEmptySetting = (): TimesSettingsBox => {
    return {
        id: uuid(),
        days: ALL_DAYS_NUMS,
        startTime: new Date(new Date().setHours(0, 0, 0, 0)),
        endTime: new Date(new Date().setHours(0, 0, 0, 0)),
        carTypeCode: '',
        carsQty: 1,
        isActive: true,
        daysRowsCodes: ALL_DAYS_NUMS.map((day) => ({ day: String(day), code: '' })),
    };
};

const initialUiState: UiState = {
    clientCode: null,
    filters: {
        courseType: CourseTypes.Active,
        freeSearch: '',
        courseId: FillerValues.All,
        departmentId: '',
    },
    refreshBtnClickCount: 0,
    selectedCourseId: '',
    restoreModal: { isOpen: false, courseCode: '' },
    deleteModal: {
        isOpen: false,
        courseCode: '',
    },
    courseBuildingForm: {
        isOpen: false,
        tempDisabled: false,
        currTab: CourseBuildingTabs.Details,
        courseCode: '',
        isReadyToDisplay: false,
        isDisabled: false,
        timesSettingsTab: {
            settingsList: [],
            errors: {
                invalidTimes: false,
                invalidDays: false,
                // invalidSettingsCount: false,
            },
            isDirty: false,
        },
        courseStationsTab: {
            isLocationChanged: false,
            errors: {},
            lastIndexEdited: null,
            updateFormFromLocationTrigger: {
                counter: 0,
                newData: null,
            },
            isConfirmLocationButton: false,
        },
        map: {
            isExpanded: false,
            fieldsCopy: [],
            isOnEditMode: false,
            editedStationId: null,
            updatedCoords: null,
            triggerFieldCoordsUpdateCount: 0,
            triggerCenterUpdateCount: 0,
            centerCoords: null,
            totalDistance: null,
        },
        triggers: {
            formStations: {
                count: 0,
                data: null,
            },
            directionsApi: {
                count: 0,
            },
        },
    },
};

const initialState: CoursesBuildingSliceState = {
    ui: initialUiState,
    data: {
        apiData: {
            getAccountCourses: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
            getDepartments: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
            getCourseTimes: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
            getCourseStations: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
            getLineTypes: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
            getCourseTimesSettings: {
                status: 'ON_STAND_BY',
                data: [],
                triggerCount: null,
            },
        },
    },
};

export const coursesBuildingSlice = createSlice({
    name: 'coursesBuildingSlice',
    initialState,
    reducers: {
        // ^ UI reducers ---------------------------------------------------------------------------
        // * Common
        setClientCode: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ clientCode: string }>
        ): void => {
            state.ui = { ...initialUiState, clientCode: action.payload.clientCode };
            state.data = initialState.data;
        },

        setFilter: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ filter: CoursesFilters; value: any }>
        ): void => {
            const { filter, value } = action.payload;
            state.ui.filters[filter] = value;
        },
        resetFilters: (state: CoursesBuildingSliceState): void => {
            state.ui.filters = initialUiState.filters;
        },

        resetState: (state: CoursesBuildingSliceState): void => {
            state.ui = initialUiState;
            state.data = initialState.data;
        },
        incrementRefreshPageBtnClickCount: (state: CoursesBuildingSliceState): void => {
            state.ui.refreshBtnClickCount += 1;
        },
        setSelectedCourseId: (state: CoursesBuildingSliceState, action: PayloadAction<string>): void => {
            state.ui.selectedCourseId = action.payload;

            if (!action.payload) {
                state.data.apiData.getCourseTimes = initialState.data.apiData.getCourseTimes;
                state.data.apiData.getCourseStations = initialState.data.apiData.getCourseStations;
                state.data.apiData.getCourseTimesSettings = initialState.data.apiData.getCourseTimesSettings;
            }
        },
        toggleDeleteModal: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ isOpen: boolean; courseCode: string }>
        ): void => {
            state.ui.deleteModal.isOpen = action.payload.isOpen;
            state.ui.deleteModal.courseCode = action.payload.courseCode;
        },
        // Course restore modal
        toggleRestoreModal: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ isOpen: boolean; courseCode: string }>
        ): void => {
            state.ui.restoreModal.isOpen = action.payload.isOpen;
            state.ui.restoreModal.courseCode = action.payload.courseCode;
        },

        // Form reducers ---------------------------------------------------------------------------
        setCurrTab: (state: CoursesBuildingSliceState, action: PayloadAction<CourseBuildingTabs>): void => {
            state.ui.courseBuildingForm.currTab = action.payload;
        },
        openCourseForm: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ courseCode?: string }>
        ): void => {
            state.ui.courseBuildingForm.isOpen = true;
            state.ui.courseBuildingForm.map.isExpanded = true;

            const courseCode = action.payload.courseCode;

            if (courseCode) {
                state.ui.courseBuildingForm.courseCode = courseCode;
            }
        },
        closeCourseForm: (state: CoursesBuildingSliceState): void => {
            state.ui.courseBuildingForm.isOpen = false;
        },
        setCourseCodeOnForm: (state: CoursesBuildingSliceState, action: PayloadAction<string>): void => {
            const courseCode = action.payload;
            state.ui.courseBuildingForm.courseCode = courseCode;

            if (!courseCode) {
                state.data.apiData.getCourseStations.data = [];
                state.data.apiData.getCourseStations.status = 'ON_STAND_BY';

                state.data.apiData.getCourseTimesSettings.data = [];
                state.data.apiData.getCourseTimesSettings.status = 'ON_STAND_BY';
            }
        },
        resetCourseForm: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ keepOpen?: boolean }>
        ): void => {
            const keepOpen = action.payload.keepOpen;

            state.ui.courseBuildingForm = {
                ...initialState.ui.courseBuildingForm,
                isOpen: keepOpen ? true : false,
                isReadyToDisplay: false,
            };
        },
        setTempDisabled: (state: CoursesBuildingSliceState, action: PayloadAction<boolean>): void => {
            state.ui.courseBuildingForm.tempDisabled = action.payload;
        },
        setFormIsReadyToDisplay: (state: CoursesBuildingSliceState, action: PayloadAction<boolean>): void => {
            state.ui.courseBuildingForm.isReadyToDisplay = action.payload;
        },
        // Times settings tab reducers --------------------------------------------------------------
        setSettingsList: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<TimesSettingsBox[]>
        ): void => {
            state.ui.courseBuildingForm.timesSettingsTab.settingsList = action.payload;
        },
        addSettingsBox: (
            state: CoursesBuildingSliceState,
            action?: PayloadAction<TimesSettingsBox | undefined>
        ): void => {
            state.ui.courseBuildingForm.timesSettingsTab.isDirty = true;

            if (action?.payload) {
                state.ui.courseBuildingForm.timesSettingsTab.settingsList.push(action.payload);
                return;
            }

            state.ui.courseBuildingForm.timesSettingsTab.settingsList.push(getDefaultEmptySetting());
        },
        updateSettingBoxById: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<TimesSettingsBox>
        ): void => {
            const settingBoxData = action.payload;
            const settingBoxMatch = state.ui.courseBuildingForm.timesSettingsTab.settingsList.find(
                (box) => box.id === settingBoxData.id
            );
            if (settingBoxMatch) {
                Object.assign(settingBoxMatch, settingBoxData);
                state.ui.courseBuildingForm.timesSettingsTab.isDirty = true;
            }
        },
        removeSettingBoxById: (state: CoursesBuildingSliceState, action: PayloadAction<string>): void => {
            const settingBoxId = action.payload;

            state.ui.courseBuildingForm.timesSettingsTab.isDirty = true;

            const updated = state.ui.courseBuildingForm.timesSettingsTab.settingsList.map((box) => {
                if (box.id === settingBoxId) {
                    box.isActive = false;
                }
                return box;
            });

            state.ui.courseBuildingForm.timesSettingsTab.settingsList = updated;
        },
        updateTimesSettingsTabErrors: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ actionType: 'add' | 'remove'; errorType: TimesSettingsErrors }>
        ): void => {
            const { actionType, errorType } = action.payload;
            const errors = state.ui.courseBuildingForm.timesSettingsTab.errors;

            if (actionType === 'add') {
                errors[errorType] = true;
            } else {
                errors[errorType] = false;
            }
        },
        // Stations tab reducers ---------------------------------------------------------------------
        setStationsErrors: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{
                errors: StationsErrors;
            }>
        ): void => {
            const stationsErrors = _.cloneDeep(action.payload.errors);

            state.ui.courseBuildingForm.courseStationsTab.errors = stationsErrors;
        },
        removeErrorByStationId: (state: CoursesBuildingSliceState, action: PayloadAction<string>): void => {
            const stationId = action.payload;

            if (stationId in state.ui.courseBuildingForm.courseStationsTab.errors) {
                delete state.ui.courseBuildingForm.courseStationsTab.errors[stationId];
            }
        },
        // Map reducers ---------------------------------------------------------------------------
        setTotalDistance: (state: CoursesBuildingSliceState, action: PayloadAction<number | null>): void => {
            state.ui.courseBuildingForm.map.totalDistance = action.payload;
        },
        setFieldsCopy: (state: CoursesBuildingSliceState, action: PayloadAction<StationSchema[]>): void => {
            state.ui.courseBuildingForm.map.fieldsCopy = action.payload;
        },
        setMapIsExpanded: (state: CoursesBuildingSliceState, action: PayloadAction<boolean>): void => {
            state.ui.courseBuildingForm.map.isExpanded = action.payload;
        },
        setMapIsOnEditMode: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ isOnEditMode: boolean; editedStationId: string | null }>
        ): void => {
            state.ui.courseBuildingForm.map.isOnEditMode = action.payload.isOnEditMode;
            state.ui.courseBuildingForm.map.editedStationId = action.payload.editedStationId;
        },
        setUpdatedCoordsOnMap: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{
                lat: number;
                lng: number;
            } | null>
        ): void => {
            state.ui.courseBuildingForm.map.updatedCoords = action.payload;
        },
        triggerFieldCoordsUpdateCount: (state: CoursesBuildingSliceState): void => {
            state.ui.courseBuildingForm.map.triggerFieldCoordsUpdateCount += 1;
        },
        setNewCenter: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ newCoords: Coords } | null>
        ): void => {
            state.ui.courseBuildingForm.map.centerCoords = action.payload?.newCoords ?? null;
        },
        setIsLocationChanged: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ isLocationChanged: boolean }>
        ): void => {
            state.ui.courseBuildingForm.courseStationsTab.isLocationChanged =
                action.payload.isLocationChanged;
        },
        setLastIndexEdited: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ index: number }>
        ): void => {
            state.ui.courseBuildingForm.courseStationsTab.lastIndexEdited = action.payload.index;
        },
        setUpdateFormFromLocationTrigger: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ counter: number; data: StationSchema }>
        ): void => {
            state.ui.courseBuildingForm.courseStationsTab.updateFormFromLocationTrigger.counter =
                action.payload.counter;
            state.ui.courseBuildingForm.courseStationsTab.updateFormFromLocationTrigger.newData =
                action.payload.data;
        },
        setIsConfirmLocationButton: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{ isConfirmLocationButton: boolean }>
        ): void => {
            state.ui.courseBuildingForm.courseStationsTab.isConfirmLocationButton =
                action.payload.isConfirmLocationButton;
        },
        triggerFieldUpdate: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<StationSchema[] | null>
        ): void => {
            state.ui.courseBuildingForm.triggers.formStations.count += 1;
            state.ui.courseBuildingForm.triggers.formStations.data = action.payload;
        },
        triggerDirectionsApi: (state: CoursesBuildingSliceState): void => {
            state.ui.courseBuildingForm.triggers.directionsApi.count += 1;
        },

        setIsDisabled: (state: CoursesBuildingSliceState, action: PayloadAction<boolean>): void => {
            state.ui.courseBuildingForm.isDisabled = action.payload;
        },
        // ^ Data reducers ---------------------------------------------------------------------------
        updateApiCallStatus: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{
                callName: ApiCallsNames;
                status: ApiCallStatus;
            }>
        ): void => {
            const { callName, status } = action.payload;

            if (callName === 'getAccountCourses') {
                state.ui.selectedCourseId = '';
            }

            state.data.apiData[callName].status = status;
        },
        updateApiCall: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<{
                callName: ApiCallsNames;
                data: any;
                status: ApiCallStatus;
            }>
        ): void => {
            const { callName, data, status } = action.payload;
            state.data.apiData[callName].data = data;
            state.data.apiData[callName].status = status;
        },
        removeCourseByCode: (state: CoursesBuildingSliceState, action: PayloadAction<string>): void => {
            const courseId = state.data.apiData.getAccountCourses.data.find(
                (course) => course.courseCode === action.payload
            )?.courseId;

            state.data.apiData.getAccountCourses.data = state.data.apiData.getAccountCourses.data.filter(
                (course) => course.courseCode !== action.payload
            );

            if (!courseId) return;

            // Removing filter by courseId if exists
            const filterCourseId = state.ui.filters.courseId;

            if (filterCourseId === courseId) {
                state.ui.filters.courseId = FillerValues.All;
            }
        },
        triggerApiCall: (
            state: CoursesBuildingSliceState,
            action: PayloadAction<ApiCallsNames | ApiCallsNames[]>
        ): void => {
            const callsToTrigger = typeof action.payload === 'string' ? [action.payload] : action.payload;
            callsToTrigger.forEach((callName) => {
                const currCount = state.data.apiData[callName].triggerCount || 0;
                state.data.apiData[callName].triggerCount = currCount + 1;
            });
        },
    },
});

// * Exports
// ^ Actions
export const {
    setClientCode: setClientCodeAction,
    incrementRefreshPageBtnClickCount: incrementRefreshPageBtnClickCountAction,
    resetState: resetStateAction,
    // Api call reducers
    updateApiCallStatus: updateApiCallStatusAction,
    updateApiCall: updateApiCallDataAction,
    triggerApiCall: triggerApiCallAction,
    // Table reducers
    // -- Data actions
    removeCourseByCode: removeCourseByCodeAction,
    // -- Misc actions
    setFilter: setFilterAction,
    resetFilters: resetFiltersAction,
    setSelectedCourseId: setSelectedCourseIdAction,
    // Modals
    toggleDeleteModal: toggleDeleteModalAction,
    toggleRestoreModal: toggleRestoreModalAction,
    // Form
    openCourseForm: openCourseFormAction,
    closeCourseForm: closeCourseFormAction,
    setCourseCodeOnForm: setCourseCodeOnFormAction,
    resetCourseForm: resetCourseFormAction,
    setSettingsList: setSettingsListAction,
    addSettingsBox: addSettingsBoxAction,
    removeErrorByStationId: removeErrorByStationIdAction,
    updateSettingBoxById: updateSettingBoxByIdAction,
    removeSettingBoxById: removeSettingBoxByIdAction,
    setStationsErrors: setStationsErrorsAction,
    updateTimesSettingsTabErrors: updateTimesSettingsTabValidAction,
    setTempDisabled: setTempDisabledAction,
    setCurrTab: setCurrTabAction,
    setFormIsReadyToDisplay: setFormIsReadyToDisplayAction,
    triggerFieldUpdate: triggerFieldUpdateAction,
    setIsDisabled: setIsDisabledAction,
    triggerDirectionsApi: triggerDirectionsApiAction,
    setIsLocationChanged: setIsLocationChangedAction,
    setLastIndexEdited: setLastIndexEditedAction,
    setUpdateFormFromLocationTrigger: setUpdateFormFromLocationTriggerAction,
    setIsConfirmLocationButton: setIsConfirmLocationButtonAction,
    // -- Map
    setFieldsCopy: setFieldsCopyAction,
    setMapIsExpanded: setMapIsExpandedAction,
    setMapIsOnEditMode: setMapIsOnEditModeAction,
    setUpdatedCoordsOnMap: setUpdatedCoordsOnMapAction,
    triggerFieldCoordsUpdateCount: triggerFieldCoordsUpdateCountAction,
    setNewCenter: setNewCenterAction,
    setTotalDistance: setTotalDistanceAction,
} = coursesBuildingSlice.actions;

export const coursesBuildingActions = coursesBuildingSlice.actions;

// ^ Selectors
export const uiSelector = (state: IRootReducer) => state.coursesBuildingSlice.ui;
export const filtersSelector = (state: IRootReducer) => uiSelector(state).filters;
export const selectedClientCodeSelector = (state: IRootReducer) => uiSelector(state).clientCode;
export const dataSelector = (state: IRootReducer) => state.coursesBuildingSlice.data;
export const apiDataSelector = (state: IRootReducer) => state.coursesBuildingSlice.data.apiData;
export const selectedCourseIdSelector = (state: IRootReducer) => uiSelector(state).selectedCourseId;
export const courseBuildingFormSelector = (state: IRootReducer) => uiSelector(state).courseBuildingForm;
export const restoreModalSelector = (state: IRootReducer) => uiSelector(state).restoreModal;
export const apiCallTriggerCountSelector = (state: IRootReducer, apiCallName: ApiCallsNames) =>
    dataSelector(state).apiData[apiCallName].triggerCount;
export const timesSettingsTabSelector = (state: IRootReducer) =>
    uiSelector(state).courseBuildingForm.timesSettingsTab;
export const formTriggerSelector = (state: IRootReducer) => uiSelector(state).courseBuildingForm.triggers;
export const formTempDisabledSelector = (state: IRootReducer) =>
    courseBuildingFormSelector(state).tempDisabled;

export const coursesBuildingSelectors = {
    uiSelector,
    dataSelector,
    apiDataSelector,
};

// ^ Reducer export
const coursesBuildingReducer = coursesBuildingSlice.reducer;
export default coursesBuildingReducer;
