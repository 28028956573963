import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { authorizationTokenSelector } from 'src/store/selectores/loginSelectors';

import { useTranslation } from 'react-i18next';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import UpdateIcon from '@material-ui/icons/Update';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Main from 'src/screens/Main/Main';
import { ManualOrder } from 'src/screens/ManualOrder/ManualOrder';
import PassengerManger from 'src/screens/PassengerManger/PassengerManger';
import Setting from 'src/screens/Setting/Setting';
import { IAuthorization } from 'src/api/types';
import { useAppDispatch } from 'src/store/hooks';
import { onPathChangeAction } from 'src/store/slices/common/commonSlice';
import { getCurrentPath } from 'src/utilis/utilis';
import { INavigtionRoute } from '../type';
import { routesEndpoints } from '../routes_endpoints';
import RideSettings from '../../screens/RideSettings/components/RideSettingsScreen';
import ReportsScreen from '../../screens/Reports/ReportsScreen';
import CoursesBuildingScreen from 'src/screens/CoursesBuilding/CoursesBuildingScreen';
import CoursesBuildingIcon from 'src/components/Icons/CoursesBuildingIcon';
import OptimizationsScreen from 'src/screens/Optimizations/OptimizationsScreen';
import TuneIcon from '@mui/icons-material/Tune';

export const isAuthorized = (screenId: number, authToken: IAuthorization): boolean => {
    return authToken[screenId] && authToken[screenId].clients.length > 0;
};
const useAppRoute = (): { routes: INavigtionRoute[] } => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authorizationToken = useSelector((state: IRootReducer) => authorizationTokenSelector(state)) || {};

    // eslint-disable-next-line react-hooks/exhaustive-deps

    // const disabledRoutes = [
    //    'courses',
    //    'shift',
    //    'reports',
    //    'passengerSettings',
    // ];
    const currPath = getCurrentPath();

    useEffect(() => {
        dispatch(onPathChangeAction());
    }, [currPath, dispatch]);

    const routes = useMemo<INavigtionRoute[]>(
        () => [
            {
                path: routesEndpoints.HOME,
                exact: false,
                routeName: t('dailyRides2'),
                main: () => <Main />,
                icon: (isActive: boolean) => <DirectionsBusIcon color={isActive ? 'primary' : 'inherit'} />,
            },
            {
                path: routesEndpoints.MANUAL_ORDER.SCREEN_PATH,
                exact: true,
                routeName: t('passengersOrders'),
                main: () => <ManualOrder />,
                icon: (isActive: boolean) => <ImportContactsIcon color={isActive ? 'primary' : 'inherit'} />,
                isDisabled: !isAuthorized(routesEndpoints.MANUAL_ORDER.SCREEN_ID, authorizationToken),
            },
            {
                path: routesEndpoints.COURSES_BUILDING.SCREEN_PATH,
                exact: true,
                routeName: t('coursesBuilding'),
                main: () => <CoursesBuildingScreen />,
                icon: (isActive: boolean) => <CoursesBuildingIcon color={isActive ? '#1976d2' : '#494949'} />,
                isDisabled: !isAuthorized(routesEndpoints.COURSES_BUILDING.SCREEN_ID, authorizationToken),
            },
            {
                path: routesEndpoints.OPTIMIZATIONS.SCREEN_PATH,
                exact: true,
                routeName: t('optimizations'),
                main: () => <OptimizationsScreen />,
                icon: (isActive: boolean) => <TuneIcon color={isActive ? 'primary' : 'inherit'} />,
                isDisabled: true,
            },
            {
                path: routesEndpoints.MANAGE_PASSENGERS.SCREEN_PATH,
                exact: true,
                routeName: t('managePassengers'),
                main: () => <PassengerManger />,
                icon: (isActive: boolean) => <PeopleAltIcon color={isActive ? 'primary' : 'inherit'} />,
                isDisabled: !isAuthorized(routesEndpoints.MANAGE_PASSENGERS.SCREEN_ID, authorizationToken),
            },
            {
                path: routesEndpoints.REPORTS.SCREEN_PATH,
                exact: true,
                routeName: t('reports'),
                main: () => <ReportsScreen />,
                icon: (isActive: boolean) => <AssignmentIcon color={isActive ? 'primary' : 'inherit'} />,
            },
            {
                path: routesEndpoints.PASSENGER_SETTINGS.SCREEN_PATH,
                exact: true,
                routeName: t('settings'),
                main: () => <RideSettings />,
                icon: (isActive: boolean) => <SettingsIcon color={isActive ? 'primary' : 'inherit'} />,
                isDisabled: false,
                // isDisabled: !isAuthorized(
                //    routesEndpoints.PASSENGER_SETTINGS.SCREEN_ID,
                //    authorizationToken,
                // ),
            },
            {
                path: routesEndpoints.SETTINGS.SCREEN_PATH,
                exact: true,
                routeName: t('settings'),
                main: () => <Setting />,
                icon: (isActive: boolean) => <SettingsIcon color={isActive ? 'primary' : 'inherit'} />,
                isHidden: true,
                isDisabled: false,
            },
        ],
        [authorizationToken, t]
    );

    return { routes };
};

export default useAppRoute;
