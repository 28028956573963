import React from 'react';
import { useTranslation } from 'react-i18next';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TableChartIcon from '@mui/icons-material/TableChart';
import { IRootReducer } from 'src/store/reducers';
import { getStrAsTemplateStringArr } from 'src/utilis/utilis';
import {
    allFiltersSelector,
    clientIdSelector,
    selectedReportSelector,
    uiSelector,
} from 'src/store/selectores/reportsSelectors';
import { useReactToPrint } from 'react-to-print';
import { onPrintStatusUpdateAction } from 'src/store/slices/reports/reportsSlice';
import menuStyles, { MenuButton, buttonSx } from '../Menu.style';
import { useAppDispatch, useAppSelector, useRootAppSelector } from '../../../../../../store/hooks';
import { getVisasExcelReport } from 'src/api/api';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { setErrorMessage } from 'src/store/actions/loginAction';
import useProxyApi from 'src/adrianTodosAndUtils/useProxyApi';
import { RELATIVE } from 'src/constants/dates';
import moment from 'moment';
import useVisasReportExcelExport from './useVisasReportExcelExport';
import HTMLTableToExcel from 'src/components/HTMLTableToExcel';

interface ExcelExportBtnProps {
    table?: string;
    filename?: string;
    onClick?: Function;
}
export const ExportToExcelBtn = (props: ExcelExportBtnProps) => {
    const { t } = useTranslation();

    const selectedReportName = useAppSelector((state) => uiSelector(state).main.selectedReport);

    const isVisasReport = selectedReportName === 'visas';

    const { handleVisasReport, isLoading } = useVisasReportExcelExport();

    return (
        <MenuButton
            startIcon={<TableChartIcon />}
            onClick={async () => {
                if (isVisasReport) {
                    handleVisasReport();
                }
            }}
            sx={buttonSx}
            loading={isLoading}
        >
            {isVisasReport ? `${t('exportTo')} EXCEL` : <HTMLTableToExcel />}
        </MenuButton>
    );
};

const ActionButtons = ({ excelExportRef, tableRef }: any) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        onAfterPrint: () => {
            dispatch(onPrintStatusUpdateAction({ isPrinting: false }));
        },
    });

    return (
        <menuStyles.BtnsContainer>
            <ExportToExcelBtn />
            <MenuButton
                sx={buttonSx}
                startIcon={<LocalPrintshopIcon />}
                onClick={() => {
                    dispatch(onPrintStatusUpdateAction({ isPrinting: true }));

                    setTimeout(() => {
                        handlePrint();
                    }, 1);
                }}
            >
                {t('print')}
            </MenuButton>
        </menuStyles.BtnsContainer>
    );
};
export default ActionButtons;

// const getPrintJsProps = (
//     t: TFunction<Namespace<'he'>>,
//     reportName: ReportNames
// ): { field: string; displayName: string }[] => {
//     switch (reportName) {
//         case 'shortOrders':
//             return getShortReportCols(t);
//         case 'detailedOrders':
//             return getDetailedReportCols(t);
//         case 'visas':
//             return getVisasReportCols(t);
//         default:
//             throw new Error(`no report found for ${reportName}`);
//     }
// };
