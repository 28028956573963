import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    setExistingFileDataAction,
    toggleModalAction,
    updateActionStatusAction,
    uploadFileFtSelector,
} from 'src/store/slices/generalFeatures/generalFeaturesSlice';
import { useTranslation } from 'react-i18next';
import { CODE_KEY, UploadFileEnums, UploadFileMappers } from 'src/types/uploadFileFt/uploadFileApiTypes';
import { useSelector } from 'react-redux';
import uploadFileRoutes, { UploadFileClientData } from 'src/mockServer/routes/uploadFileRoutes';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';
import helpers from './helpers.uploadFile';
import { setErrorMessage } from 'src/store/actions/loginAction';
import React from 'react';
import { SECOND } from 'src/constants/times';
import { FileActions, ApiReqStatus } from 'src/store/slices/generalFeatures/types.generalFeatures';
import useCommons from 'src/hooks/common/useCommons';

const { errorMessagesByCode } = UploadFileMappers;
const { GeneralErrorMessages } = UploadFileEnums;

const useFileFtSlice = () => {
    const dispatch = useAppDispatch();

    const sliceState = useRootAppSelector(uploadFileFtSelector);

    const actions = {
        toggleModal: (payload: boolean | null) => dispatch(toggleModalAction(payload)),
        updateActionStatus: (payload: { actionType: FileActions; status: ApiReqStatus }) =>
            dispatch(updateActionStatusAction(payload)),
    };

    return { actions, sliceState };
};

// hook to check if file url expired
const useIsFileUrlExpired = () => {
    const fileFtSlice = useFileFtSlice();

    const [isExpired, setIsExpired] = React.useState(false);

    const isFileUrlExpired = React.useCallback(() => {
        const { fetchedAt } = fileFtSlice.sliceState.data.fileData || {};

        if (!fetchedAt) return -1;

        const now = new Date();
        const diff = now.getTime() - fetchedAt.getTime();

        const diffInMinutes = Math.floor(diff / 1000 / 60);

        const expirationByMinutes = fileFtSlice.sliceState.data.fileData?.expirationByMinutes || Infinity;
        return diffInMinutes > expirationByMinutes;
    }, [fileFtSlice.sliceState.data.fileData]);

    // check if file url expired every X seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!fileFtSlice.sliceState.data.fileData) return;

            const isExpiredResult = isFileUrlExpired();

            if (isExpiredResult === -1) return; // Should not happen

            setIsExpired(isExpiredResult);
        }, SECOND * 30);

        return () => clearInterval(interval);
    }, [fileFtSlice.sliceState.data.fileData, isFileUrlExpired]);

    return {
        isExpired,
    };
};

// -- API Hooks --------------------------------------------------------------------------------
const useApi = () => {
    const { accountCode, accountGuid } = useSelector(selectedFcAccountSelector) || {};

    const sliceData = useFileFtSlice();

    const baseParams = {
        account_code: accountCode ? String(accountCode) : '',
        account_guid: accountGuid || '',
        site_id: sliceData.sliceState.data.selectedClientCode || '',
    };

    const uploadFileToServer = async (file: { name: string; data: string }) => {
        try {
            if (!helpers.isValidParams(baseParams)) return -1;

            const clientData: UploadFileClientData = {
                requestConfig: {
                    ...baseParams,
                    file_name: file.name,
                    File_data: file.data,
                },
            };
            const routeRes = await uploadFileRoutes.uploadFile(clientData);
            return routeRes;
        } catch (error) {
            console.log(error);
            return -1;
        }
    };

    // get file from server
    const getFileFromServer = async () => {
        try {
            if (!helpers.isValidParams(baseParams)) return -1;

            const clientData = {
                requestConfig: {
                    ...baseParams,
                },
            };

            const routeRes = await uploadFileRoutes.getFile(clientData);

            return routeRes;
        } catch (error) {
            console.log(error);
            return -1;
        }
    };

    // Delete file from server
    const deleteFileFromServer = async () => {
        try {
            if (!helpers.isValidParams(baseParams)) return -1;

            const clientData = {
                requestConfig: {
                    ...baseParams,
                },
            };

            const routeRes = await uploadFileRoutes.deleteFile(clientData);
            return routeRes;
        } catch (error) {
            console.log(error);
            return -1;
        }
    };

    return {
        uploadFileToServer,
        getFileFromServer,
        deleteFileFromServer,
    };
};

const useDeleteFileClick = () => {
    const { dispatch, t } = useCommons();
    const { deleteFileFromServer } = useApi();
    const fileFtSlice = useFileFtSlice();

    const dispatchStatusUpdate = (newStatus: ApiReqStatus, actionType: FileActions) => {
        fileFtSlice.actions.updateActionStatus({
            actionType,
            status: newStatus,
        });
    };

    const handleDeleteFileClick = async () => {
        try {
            dispatchStatusUpdate(ApiReqStatus.Loading, 'delete');

            const deleteFileRes = await deleteFileFromServer();

            // Success
            if (helpers.isApiReqSuccessful(deleteFileRes)) {
                // update status on slice
                dispatchStatusUpdate(ApiReqStatus.Idle, 'get');
                dispatchStatusUpdate(ApiReqStatus.Succeeded, 'delete');

                // reset file on slice
                dispatch(setExistingFileDataAction(null));

                return;
            }

            dispatchStatusUpdate(ApiReqStatus.Failed, 'delete');

            const errorCode =
                deleteFileRes !== -1 && CODE_KEY in deleteFileRes ? deleteFileRes[CODE_KEY] : null;

            throw new Error(helpers.getErrorMessageByCode(errorCode, GeneralErrorMessages.DeleteFailed));
        } catch (error: any) {
            // console.log(error);
            dispatch(setErrorMessage(t(error.message || '')));
        }
    };

    return {
        handleDeleteFileClick,
    };
};

const useGetFile = () => {
    const fileFtSlice = useFileFtSlice();
    const api = useApi();

    const { t, dispatch } = useCommons();

    const dispatchStatusUpdate = (newStatus: ApiReqStatus) => {
        fileFtSlice.actions.updateActionStatus({
            actionType: 'get',
            status: newStatus,
        });
    };

    const handleGetFile = async () => {
        try {
            dispatchStatusUpdate(ApiReqStatus.Loading);

            const getFileRes = await api.getFileFromServer();

            // Success
            if (helpers.isApiReqSuccessful(getFileRes) && helpers.isGetFileResult(getFileRes)) {
                const hasFile = getFileRes.data.data?.FileLink ? true : false;

                // set file on slice
                if (hasFile) {
                    const fileData = {
                        fileName: getFileRes.data.data?.FileName || '',
                        fileLink: getFileRes.data.data?.FileLink || '',
                        expirationByMinutes: getFileRes.data.data?.ExpirationByMinutes || Infinity,
                        fetchedAt: new Date(),
                    };

                    dispatch(setExistingFileDataAction(fileData));
                } else {
                    dispatch(setExistingFileDataAction(null));
                }

                // update status on slice
                dispatchStatusUpdate(ApiReqStatus.Succeeded);

                return;
            }

            dispatchStatusUpdate(ApiReqStatus.Failed);

            const errorCode = getFileRes !== -1 && CODE_KEY in getFileRes ? getFileRes[CODE_KEY] : null;

            throw new Error(helpers.getErrorMessageByCode(errorCode, GeneralErrorMessages.GetFailed));
        } catch (error: any) {
            console.log(error);
            dispatch(setErrorMessage(t(error.message || '')));
        }
    };

    return {
        handleGetFile,
    };
};
// ----------------------------------------------------------------------------------------

// -- Exports ----------------------------------------------------------------
const uploadFileHooks = {
    useCommons,
    useFileFtSlice,
    useApi,
    useGetFile,
    useIsFileUrlExpired,
    useDeleteFileClick,
};

export default uploadFileHooks;
