/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { StyledFormButtonsContainer } from 'src/screens/styles/Widgets.style';
// import { formBtnStyle } from 'src/screens/styles/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import { useSelector } from 'react-redux';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import { IRootReducer } from 'src/store/reducers';
import { formSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { useAppSelector } from 'src/store/hooks';
import { isPassengerValid } from '../utils';
import { isValidForm } from './AddressesBox/utils';

interface Props {
    onSubmit: any;
    onCancel: any;
    modefiedPassenger: IModefiedPassenger;
}

const CancelAndApproveBtns: React.FC<Props> = ({ onCancel, onSubmit, modefiedPassenger }) => {
    const { t } = useTranslation();
    const formState = useSelector((state: IRootReducer) => passengersUISelector(state).form);
    const isOnEdit = useSelector((state: IRootReducer) => passengersUISelector(state).modifyingPassenger);

    const coursesFormData = useAppSelector((state: any) => formSelector(state));
    return (
        <StyledFormButtonsContainer>
            <Button
                style={{ width: '183px', height: '44px' }}
                onClick={onSubmit}
                type="button"
                fullWidth
                disabled={!isValidForm(modefiedPassenger, formState, coursesFormData)}
                variant="contained"
                color="primary"
            >
                {isOnEdit ? t('update') : t('add2')}
            </Button>
            <Button
                // style={formBtnStyle}
                type="button"
                // fullWidth
                // variant="contained"
                onClick={() => {
                    onCancel();
                }}
                // color="primary"
            >
                {t('cancel')}
            </Button>
        </StyledFormButtonsContainer>
    );
};

export default CancelAndApproveBtns;
