import styled from 'styled-components';

export const StyledMainPassengerDetailsDisplay = styled.div`
    background-color: ${({ theme }) => theme.colors.veryLightGray};
    border: 1px solid ${({ theme }) => theme.colors.mediumLightGray};
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    height: 130px;
    border-radius: 4px;
    // *MAIN_CONTAINER:
    & > div {
        padding: 10px;
    }
    // *DETAILS_CONTAINER:
    & > div > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: right;
    }
`;

export const StyledParagraph = styled.p`
   font-family: ${({ theme }) => theme.fontFamilies.Rubik};
   color: ${({ theme }) => theme.msColors.primaryTextcolor}
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 17px;
   margin-right: 5px;
`;

export const DetailContainer = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    display: flex;
    align-items: center;
    padding-bottom: 20px;
`;
export const StyledPropTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    color: ${({ theme }) => theme.msColors.veryLightTextColor};
    margin: 0;
    padding: 0;
`;

export const StyledDetailsProperty = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    padding: 10px;
`;
export const StyledTabPanel = styled.div<{ count: number }>`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    display: flex;
    justify-content: ${(props) => (props.count > 2 ? 'space-between' : 'center')};
`;
export const StyledTabsContainer = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    width: 100%;
    border-bottom: 1px solid gray;
`;

export const StyledIconContainer = styled.div`
    padding: 0 5px;
`;
