import React, { useEffect } from 'react';
import useFetchDepartments from 'src/api/hooks/useFetchDepartments';
import useCommons from 'src/hooks/common/useCommons';
import { coursesBuildingActions, uiSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import { ApiCallsNames } from 'src/types/coursesBuilding/storeTypes';
import { asTSR } from 'src/utilis/utilis';

export const useUpdateApiCall = () => {
    const { dispatch } = useCommons();

    const updateApiCall = React.useCallback(
        <T>(callName: ApiCallsNames, status: ApiCallStatus, data: T) => {
            dispatch(
                coursesBuildingActions.updateApiCall({
                    data,
                    callName,
                    status,
                })
            );
        },
        [dispatch]
    );

    return { updateApiCall };
};

const useFilteringFetching = () => {
    const { dispatch, dispatchI18nErrMsg, useRootAppSelector } = useCommons();

    const { fetch: fetchDepartments } = useFetchDepartments({ withLoading: false });

    const selectedClientCode = useRootAppSelector((state) => uiSelector(state).clientCode);
    const refreshBtnClickCount = useRootAppSelector((state) => uiSelector(state).refreshBtnClickCount);

    const { updateApiCall } = useUpdateApiCall();

    useEffect(() => {
        if (selectedClientCode) {
            fetchDepartments({
                clientCode: selectedClientCode,
                onSuccess: (data) => {
                    const parsedData = data.map((dept) => ({
                        ...dept,
                        code: String(dept.code),
                    }));

                    updateApiCall('getDepartments', 'SUCCESS', parsedData);
                },
                onFailure: (code, msg) => {
                    updateApiCall('getDepartments', 'FAILED', []);
                    dispatchI18nErrMsg(msg);
                },
            });
        }
    }, [
        dispatch,
        refreshBtnClickCount,
        selectedClientCode,
        fetchDepartments,
        updateApiCall,
        dispatchI18nErrMsg,
    ]);
};

export default useFilteringFetching;
