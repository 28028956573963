// test
import axios, { AxiosResponse } from 'axios';
import querystring from 'querystring';
import { AVLTypeEnum, IturanResponse } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import { ILine, IClient, IDepartment, ILocation, IEditLine } from 'src/types/line';
import instance, { gpsServerUrl, myWayUrl, buildEncriptedUrl, ITURAN_API_URL } from './index';
import { makeApiCall } from './reportsApi/reportsApi';
import {
    IResponse,
    FcLogin,
    ILinesRequest,
    ILineRequest,
    IPassengerTypeResponse,
    IFcRequest,
    IFcResponseGetUserDetails,
    AxiosRes,
    IturanLocationRequestPayload,
    IEditLineRequest,
} from './types';
import { createPayload } from './utilis';

// import { xmlParser } from '../screens/Main/components/LineMap/utils';

// FC REQUEST

export const validateToken = async (payload: { deviceToken: string }): Promise<{ data: object }> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Validate_Token`, data);
};

// FC REQUEST

export const sendSMS = async (payload: {
    userMobile: string;
    // deviceToken: string | null;
}): Promise<any> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Send_Sms`, data);
};

// FC REQUEST

export const validateSms = async (payload: { userMobile: string; verifyCode: string }): Promise<any> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Validate_Mobile`, data);
};

// FC REQUEST

export const setLoginType = async (payload: {
    deviceToken: string;
    userUUID: string;
    autologin: number;
    userName?: string;
    userPass?: string;
    pinCode?: string;
}): Promise<FcLogin> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance
        .post(`${myWayUrl}/ws_MyWayStudio_Update_Auto_Login_Type`, data)
        .then((result) => result.data);
};

// FC REQUEST

export const loginByPassword = (payload: {
    deviceToken: string;
    userName?: string;
    userPass?: string;
}): Promise<FcLogin> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Login_By_UserName`, data).then((result) => result.data);
};

// FC REQUEST

export const loginByPinCode = (payload: { deviceToken: string; pinCode: string }): Promise<FcLogin> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Login_By_PinCode`, data).then((result) => result.data);
};

// FC REQUEST

export const logout = (payload: { deviceToken?: string; userUUID: string }): Promise<FcLogin> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_UnRegister`, data).then((result) => result.data);
};

export interface AVLCredentials {
    AVLUser: string; // account username on ituran
    AVLPass: string; // account password on ituran
}
export interface AVLData extends AVLCredentials {
    AVLType: AVLTypeEnum; // 0 - no gps system, 1 - ituran gps system
}
export interface GetActualDriverResponseData extends AVLData {
    accountCode: string;
    driverCode: string;
    response: string;
}

// FC REQUEST

export const getActualDriver = async (payload: {
    accountCode: string | number;
    lineCode: string | number;
}): Promise<{
    response: string;
    data: GetActualDriverResponseData;
}> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(`${myWayUrl}/ws_MyWayStudio_Get_Actual_Driver`, data);
};

// FC REQUEST

export const updateToken = ({
    dbUrl,
    ...payload
}: {
    proxyUrl?: string;
    dbUrl: string;
    token: string;
    contactUUID: string;
}): Promise<any> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    // return instance.post(`${proxy}${dbUrl}/ws_MyWayStudio_Update_Token`, data);

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Update_Token'), data);
};

// FC REQUEST
export const getUserDetails = async ({
    clinetProxy,
    dbUrl,
    ...payload
}: any): Promise<AxiosResponse<IFcResponseGetUserDetails>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_User_Details'), data);
};

// FC REQUEST

export const getLines = async ({
    clinetProxy,
    dbUrl,
    ...payload
}: ILinesRequest): Promise<IResponse<ILine>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Lines'), data);
};

// FC REQUEST

export const getLineByCode = async ({
    dbUrl,
    ...payload
}: IEditLineRequest): Promise<AxiosResponse<IResponse<IEditLine>>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Line'), data);
};

// FC REQUEST

export const getOldLocation = async ({
    gps_server,
    ...payload
}: ILineRequest): Promise<IResponse<Array<ILocation>>> => {
    const data = querystring.stringify({
        ...payload,
    });

    return instance.post(`${gpsServerUrl}/ws_MyWayStudio_Get_CurrentPos`, data);
};

// FC REQUEST

export const getLocation = async ({
    gps_server,
    ...payload
}: ILineRequest): Promise<AxiosResponse<ILocation>> => {
    const data = querystring.stringify({
        ...payload,
    });

    return instance.post(`${gpsServerUrl}/ws_MyWayStudio_Get_GPSData`, data);
};

// FC REQUEST

export const getIturanLocation: any = async ({
    ...payload
}: IturanLocationRequestPayload): Promise<IturanResponse> => {
    return axios.post(ITURAN_API_URL, payload);
};

// FC REQUEST

export const getClients = async ({
    proxyUrl,
    dbUrl,
    token,
}: any): Promise<IResponse<{ clients: IClient[]; response: string }>> => {
    const data = querystring.stringify({
        ...createPayload({ token }),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Clients'), data);
};

export interface GetDepartmentsArgs {
    proxyUrl: string | null | undefined;
    dbUrl: string;
    token: string;
    clientCode: string | number | null | undefined;
}

// FC REQUEST

export const getDepartments = async ({
    proxyUrl,
    dbUrl,
    token,
    clientCode,
}: GetDepartmentsArgs): Promise<AxiosResponse<{ departments: IDepartment[]; response: string }>> => {
    const data = querystring.stringify({
        ...createPayload({ token, clientCode }),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Departments'), data);
};

export const getVisasExcelReport = async ({
    dbUrl,
    clientCode,
    fromDate,
    toDate,
}: {
    dbUrl: string;
    clientCode: string | number;
    fromDate: string;
    toDate: string;
}): Promise<
    AxiosResponse<{
        fileData: null | number[];
        message: string;
        response: string;
    }>
> => {
    const data = querystring.stringify({
        ...createPayload({ clientCode, fromDate, toDate }),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Excel_Lines_Report'), data);
};

// FC REQUEST

export const getPassengerType = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: IFcRequest): Promise<AxiosResponse<IPassengerTypeResponse>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_PassTypes'), data);
};

// export const getAccountShifts = (
//    requestConfig: GetAccountShiftsReqConfig,
// ): AxiosRes<any> => makeApiCall<BaseParams, any>(requestConfig);
