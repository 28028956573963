import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IShifts } from 'src/types/manualOrders/api.types';
import { IRootReducer } from 'src/store/reducers';
import { shiftsSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { getLaterHour } from '../../../../../../../utilis/utilis';
import {
    displayWithRedFontStyle,
    getShiftById,
    isPlacedOnNextDay,
    nonDisplayStyle,
    OrderTimesProp,
} from '../../../../../utilis';

interface Props {
    // shiftName?: string;
    shift?: IShifts;
    orderTimes: OrderTimesProp;
}
const ManualShiftItemChild = ({ orderTimes, shift }: Props) => {
    const placeOnNextDay = useMemo(() => isPlacedOnNextDay(orderTimes), [orderTimes]);

    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));

    // console.log({ orderTimes, shift });
    return (
        <div>
            <span>
                {orderTimes.dropTime} -{' '}
                {shift?.shiftName || getShiftById(shifts, shift?.shiftId || undefined)?.shiftName}
            </span>
            <span style={placeOnNextDay ? displayWithRedFontStyle : nonDisplayStyle}>(+1d)</span>
        </div>
    );
};

export default ManualShiftItemChild;
