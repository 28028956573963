/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReqGetDropsConfig, WsDrop } from 'src/api/dropsDisplay/types';
import { DUMMY_DATA } from 'src/screens/DropsDisplay/constants';
import { IRootReducer } from 'src/store/reducers';
import { IObject } from 'src/types/global';
import { ApiCallStatus } from '../../../types/rideSettings/store/types.store';

// Define the initial state using that type
// * Slice

export interface DropLine {
    id: string;
    time: string;
    description: string;
    remarks: string;
    vehicleType: string;
    driverName: string;
    colorIndex: 0 | 1;
}

export interface UiState {
    isScreenDisplayed: boolean;
    date: Date;
    clientCode: number | null;
    settings: {
        displayTimeRange: {
            minutesBefore: number;
            minutesAfter: number;
        };
    };
}
export interface DropsDisplaySliceState {
    ui: UiState;
    data: {
        getDrops: {
            status: ApiCallStatus;
            data: DropLine[];
            lastReqTime: null | Date;
        };
    };
}

const MINUTES_BEFORE = 50;
const MINUTES_AFTER = 10;
// const initialDataState = {};
const initialUiState: UiState = {
    isScreenDisplayed: false,
    date: new Date(),
    clientCode: null,
    settings: {
        displayTimeRange: {
            minutesBefore: MINUTES_BEFORE,
            minutesAfter: MINUTES_AFTER,
        },
    },
};

const initialState: DropsDisplaySliceState = {
    ui: initialUiState,
    data: {
        getDrops: {
            status: 'ON_STAND_BY',
            data: [],
            lastReqTime: null,
        },
    },
};
export const dropDisplaySlice = createSlice({
    name: 'dropDisplaySlice',
    initialState,
    reducers: {
        // ^ UI reducers
        // * Common
        exitScreen: (state: DropsDisplaySliceState): void => {
            state.ui.isScreenDisplayed = false;
        },
        setClientCode: (
            state: DropsDisplaySliceState,
            action: PayloadAction<{ clientCode: number }>
        ): void => {
            state.ui.clientCode = action.payload.clientCode;
        },
        enterScreen: (state: DropsDisplaySliceState): void => {
            state.ui.isScreenDisplayed = true;
            state.ui.date = new Date();
        },
        reqGetDrops: (
            state: DropsDisplaySliceState,
            action: PayloadAction<{ requestConfig: ReqGetDropsConfig }>
        ): void => {
            state.data.getDrops.lastReqTime = new Date();
            state.data.getDrops.status = 'LOADING';
        },
        reqGetDropsStatusUpdate: (
            state: DropsDisplaySliceState,
            action: PayloadAction<{
                status: ApiCallStatus;
                data?: DropLine[];
            }>
        ) => {
            const { status, data } = action.payload;

            state.data.getDrops.status = status;

            if (status === 'SUCCESS' && data) {
                state.data.getDrops.status = status;
                state.data.getDrops.data = data;
            } else {
                // failed etc
                state.data.getDrops.status = status;
            }
        },
    },
});

// * Exports
// ^ Actions
export const {
    exitScreen: exitScreenAction,
    enterScreen: enterScreenAction,
    reqGetDrops: reqGetDropsAction,
    reqGetDropsStatusUpdate: reqGetDropsStatusUpdateAction,
    setClientCode: setClientCodeAction,
} = dropDisplaySlice.actions;

// ^ Selectors
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uiSelector = (state: IRootReducer) => state.dropsDisplaySlice.ui;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dataSelector = (state: IRootReducer) => state.dropsDisplaySlice.data;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const settingsSelector = (state: IRootReducer) => state.dropsDisplaySlice.ui.settings;

const displayTimeRangeSelector = (state: IRootReducer) =>
    state.dropsDisplaySlice.ui.settings.displayTimeRange;

export const dropsDisplaySelectors = {
    uiSelector,
    dataSelector,
    settingsSelector,
    clientCodeSelector: (state: IRootReducer): number | null => state.dropsDisplaySlice.ui.clientCode,
    displayTimeRangeSelector,
};
// ^ Reducer export
const dropsDisplayReducer = dropDisplaySlice.reducer;
export default dropsDisplayReducer;
