import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerWithPrevNext, { IDatePickerProps } from 'src/components/DatePicker/DatePickerWithPrevNext';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { getAsTemplateStrArr as asTSR } from 'src/utilis/utilis';
import { Inputs } from '../../types';

export const getDateErrorMessage = (dateErrors: FieldError | undefined): TemplateStringsArray => {
    if (!dateErrors) return asTSR('');

    switch (dateErrors.type) {
        case 'min':
            return asTSR('invalidDate2');
        case 'required':
            return asTSR('isRequired');
        case 'typeError':
            return asTSR('invalidDate2');
        default:
            return asTSR(dateErrors.message || '');
    }
};

const datePickerProps = {
    showTodayButton: true,
    inputVariant: 'outlined',
    style: { width: '100%' },
    size: 'small' as const,
    format: 'dd.MM.yyyy',
};

const ControlledDatePicker: React.FunctionComponent<{
    minDate?: Date;
}> = () => {
    const { t } = useTranslation();

    const {
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext<Inputs>();

    const [date, setDate] = useState<Date | null>(null);

    const value = getValues('date');

    useEffect(() => {
        setDate(value || null);
    }, [value]);

    return (
        <div
            style={{
                textAlign: 'right',
                width: '100%',
            }}
            className="ControllerChild"
        >
            <DatePickerWithPrevNext
                {...(datePickerProps as IDatePickerProps)}
                value={date}
                error={!!errors.date}
                placeholder="dd.MM.yyyy"
                InputLabelProps={{ shrink: true }}
                onChange={(newDate) => {
                    if (newDate) {
                        setValue('date', newDate, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                        });
                    }
                }}
                style={{ ...datePickerProps.style, borderRight: '1px solid #a6a6a6y' }}
            />
            <p>{t(getDateErrorMessage(errors.date))}</p>
        </div>
    );
};

export default ControlledDatePicker;
