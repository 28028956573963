import React from 'react';
import { Path, PathValue, UnpackNestedValue, UseFormSetValue } from 'react-hook-form';

const useSetValue = <T>() => {
    const setValue = (
        inputName: Path<T>,
        vanillaSetValue: UseFormSetValue<any>,
        value: any
    ) =>
        vanillaSetValue(inputName as any, value, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        });
    return [setValue];
};

export default useSetValue;
