import { containers } from 'src/Layouts/styles';

import React, { useEffect, useRef, useState } from 'react';
import WidgetsContainerV2 from 'src/Layouts/WidgetsContainer/WidgetsContainerV2';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Alert from 'src/components/commons/Alert/Alert';
import { useAlertV2, useDispatchAlert } from 'src/hooks/useAlert';
import RefWrapper from 'src/components/Wrappers/RefWrapper/RefWrapper';
import WidgetWrapperV2 from 'src/components/Wrappers/Widgets/WidgetCardWrapperV2/WidgetCardWrapperV2';
import WidgetsLayoutContainer from '../CoursesBuilding/components/WidgetsLayoutContainer';
import useDelayedWidgetsDisplay from '../CoursesBuilding/hooks/useDelayedWidgetsDisplay';
import HeaderWithDropdown from 'src/components/HeaderWithDropdown/HeaderWithDropdown';
import {
    optimizationsRootSelector,
    setClientCodeAction,
    setIsDeleteModalOpenAction,
    setIsFormOpenAction,
    setMapIsExpandedAction,
    setWaypointStockAction,
    setTimeDeltaAction,
    selectWaypointAction,
    setIsCsvLoadDoneModalOpenAction,
    setCsvWaypointsObjectAction,
    setIsGeneralLoadingAction,
    setWaypointsOnFormEditAction,
    setIsEditFormAction,
    setCenterMapOnEditAction,
    setZoomOnEditAction,
    setIsOptimizationSimulationScreenOpenAction,
    setSimulationResultAction,
    setIsParametersFormOpenAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import { Input, Slide, Switch } from '@material-ui/core';
import SearchInput from 'src/components/SearchInput/SearchInput';
import ComboBox from 'src/components/ComboBox/ComboBox';
import { FormControlLabel, IconButton, MenuItem, MenuList, Popover } from '@mui/material';
import WaypointStockGrid from './WaypointStockGrid/WaypointStockGrid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import TrashIcon from '../PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import { iconStyle } from '../Reports/components/ReportsSection/Table/DataGrids/VisasReportPassengersGrid/styles';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import { disabledIcon, styleIcon } from '../PassengerManger/components/Passengers/styles/css-styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { avatarType } from '@progress/kendo-react-layout/dist/npm/card/interfaces/Enums';
import CourseWidgetMap from '../CoursesBuilding/components/CourseMap/CourseWidgetMap';
import Map from 'src/components/Map/Map';
import OptimizationWidgetLayoutContainer from './components/OptimizationWidgetLayoutContainer';
import CourseForm from '../CoursesBuilding/components/CourseForm/CourseForm';
import OptimizationForm from './components/optimizationForm/OptimizationForm';
import OptimizationWidgetMap from './components/OptimizationWidgetMap';
import OptimizationFloatMap from './components/OptimizationFloatMap';
import {
    deleteOptimizationWaypoints,
    getOptimizationSimulationResult,
    getServerOptimizationWaypoints,
} from './api/optimizationApi';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { IOptimizationFormWaypoint, IOptimizationWaypoint, ISimulationResult } from './api/types';
import { WsResponse } from 'src/api/types';
import moment from 'moment';
import { buildRouteName, buildWaypointName } from './helpers';
import DeleteConfirmModal from 'src/components/Modals/DeleteConfirmModal';
import { borderColor } from '@mui/system';
import { useGetOptimizationWaypoints } from './hooks/useGetOptimizationWaypoints';
import { useDelOptimizationWaypoints } from './hooks/useDelOptimizationWaypoints';
import Papa from 'papaparse';
import { useCsvReverseGeo } from './hooks/useCsvReverseGeo';
import ActionConfirmModal from 'src/components/Modals/ActionConfirmModal';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WrapperLoadingIndication from 'src/components/Wrappers/WrapperLoadingIndication';
import LoadingComponent from 'src/components/commons/Spinner/LoadingComponent';
import useOptimizationFilters from './hooks/useOptimizationFilters';
import Spinner from 'src/components/Spinner/Spinner';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { uuid } from 'src/utilis/utilis';
import { useGetOptimizationSimulation } from './hooks/useGetOptimizationSimulationResult';
import { useSetOptimizationSimulation } from './hooks/useSetOptimizationSimulation';
import OptimizationTargetSection from './components/OptimizationTargetSection';
import OptimizationParametersForm from './components/optimizationForm/OptimizationParametersForm';
import OptimizationBusIcon from 'src/components/Icons/OptimizationBusIcon';

const optimizationSimulationButtonStyle = {
    backgroundColor: 'white',
    border: '1px solid rgb(33, 150, 243) ',
    borderRadius: '5px',
    padding: '8px',
    color: 'rgb(33, 150, 243)',
    fontSize: '16px',
    cursor: 'pointer',
};

const optimizationSimulationDisabledButtonStyle = {
    backgroundColor: 'white',
    border: '1px solid #bebebe ',
    borderRadius: '5px',
    padding: '8px',
    color: '#bebebe',
    fontSize: '16px',
};

const WaypointManagementScreen = () => {
    const { t } = useTranslation();
    const {
        ui: {
            waypointsSelectedIds,
            isFormOpen,
            isParameterFormOpen,
            clientCode,
            isDeleteModalOpen,
            isDeleteFromRow,
            deleteDataItem,
            lastMapFittedBounds,
        },
        data: {
            getWaypointsFromServer: { data: waypointStock, timeDelta, status: getWaypointsFromServerStatus },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const dispatchAlert = useDispatchAlert();
    const csvWaypointsObject = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.csvWaypointsObject
    );
    const isCsvLoadDoneModalOpen = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.isCsvLoadDoneModalOpen
    );
    const isGeneralLoading = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.isGeneralLoading
    );
    const [isDeleting, setIsDeleting] = useState(false);

    const onDelete = () => {};
    const onEdit = () => {};
    const onRestore = () => {};

    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState<CompositeFilterDescriptor>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const getOptimizationWaypointsFromServer = useGetOptimizationWaypoints();
    const deleteOptimizationWaypointsFromServer = useDelOptimizationWaypoints();
    const optimizationFilter = useOptimizationFilters();
    const setOptimizationSimulation = useSetOptimizationSimulation();

    const onLoadCsv = useCsvReverseGeo();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const OnRefreshBtnClick = () => {
        getOptimizationWaypointsFromServer.getOptimizationWaypoints();
    };

    const headerWithDropdownProps = {
        setClientIdAction: setClientCodeAction,
        onRefreshClick: OnRefreshBtnClick,
        routeScreenId: routesEndpoints.COURSES_BUILDING.SCREEN_ID,
        style: { paddingTop: '32px', paddingBottom: '20px' },
        isPrivateRoute: true,
    };

    useEffect(() => {
        if (!clientCode) return;

        getOptimizationWaypointsFromServer.getOptimizationWaypoints();
    }, [clientCode]);

    const onAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onLoadWaypointsFromCsv = () => {
        setAnchorEl(null);
        if (inputFileRef.current) {
            // `current` points to the mounted file input element
            inputFileRef.current.click();
        }
    };

    const resetFileInput = () => {
        if (inputFileRef.current) {
            // `current` points to the mounted file input element
            inputFileRef.current.value = '';
        }
    };

    const onAddWaypointsManually = () => {
        setAnchorEl(null);
        dispatch(setMapIsExpandedAction({ isExpended: true }));
        dispatch(setIsFormOpenAction({ isFormOpen: true }));
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let updatedWaypoints = [...waypointStock];

        if (Array.isArray(updatedWaypoints)) {
            updatedWaypoints = updatedWaypoints.map((waypoint) => {
                const selected = waypointsSelectedIds.includes(waypoint.waypointId);
                return { ...waypoint, isSelected: selected };
            });
            dispatch(setWaypointStockAction({ waypointStock: updatedWaypoints }));
        }
    }, [waypointsSelectedIds]);

    const onDeleteSelectedWaypoints = () => {
        dispatch(
            setIsDeleteModalOpenAction({
                isDeleteModalOpen: true,
                isDeleteFromRow: false,
                deleteDataItem: null,
            })
        );
    };

    const onEditSelectedWaypoints = () => {
        const selectedWaypoints = waypointStock.filter((waypoint) =>
            waypointsSelectedIds.includes(waypoint.waypointId)
        );

        const formSelectedWaypoints: IOptimizationFormWaypoint[] = selectedWaypoints.map(
            (selectedWaypoint) => {
                return {
                    ...selectedWaypoint,
                    localId: uuid(),
                };
            }
        );

        dispatch(setIsEditFormAction({ isEditForm: true }));
        dispatch(setWaypointsOnFormEditAction({ waypoints: formSelectedWaypoints }));
        dispatch(setMapIsExpandedAction({ isExpended: true }));
        dispatch(setIsFormOpenAction({ isFormOpen: true }));

        if (lastMapFittedBounds) {
            dispatch(
                setCenterMapOnEditAction({
                    lat: lastMapFittedBounds.center.lat,
                    lng: lastMapFittedBounds.center.lng,
                })
            );
            dispatch(setZoomOnEditAction({ zoomLevel: lastMapFittedBounds.zoom }));
        }
    };

    const onDeleteConfirmationModalClose = () => {
        dispatch(
            setIsDeleteModalOpenAction({
                isDeleteModalOpen: false,
                isDeleteFromRow: true,
                deleteDataItem: null,
            })
        );
    };

    const onLoadCsvDoneModal = () => {
        dispatch(setIsCsvLoadDoneModalOpenAction({ isOpen: false }));
        dispatch(setCsvWaypointsObjectAction({ csvWaypointsObject: null }));
    };

    const onDeleteConfirmationModalConfirm = async () => {
        setIsDeleting(true);
        await deleteOptimizationWaypointsFromServer.onDeleteConfirmationModalConfirm();

        setTimeout(() => {
            setIsDeleting(false);
        }, 500);
    };

    const onShowUnRecoginizedWaypointsClick = () => {
        optimizationFilter.setIsShowUnrecognizedWaypoints(!optimizationFilter.isShowUnrecognizedWaypoints);
    };

    const onOptimizationSimulation = async () => {
        const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);
        if (currentTargetWaypoint) {
            dispatch(setIsParametersFormOpenAction({ isParameterFormOpen: true }));
        } else {
            dispatchAlert('error', t('targetWaypointIsRequired'));
        }
    };

    const getIsOptimizationSimulationEnabled = () => {
        const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);

        if (waypointsSelectedIds.length > 0) {
            if (currentTargetWaypoint && waypointsSelectedIds.length === 1) {
                return false;
            }
            return true;
        }

        return false;
    };

    return (
        <>
            <containers.ScreenContainer className="screen-container">
                {/* <OptimizationBusIcon /> */}

                <LoadingComponent isLoading={isGeneralLoading} />

                {csvWaypointsObject && (
                    <ActionConfirmModal
                        Body={() => (
                            <div style={{ textAlign: 'center', padding: '20px', paddingTop: '0px' }}>
                                <div style={{ fontSize: '20px', fontWeight: 400, marginBottom: '8px' }}>
                                    {t('theFileUploadedSuccessfully')}
                                </div>
                                <p style={{ color: '#494949' }}>
                                    {t('added')}{' '}
                                    {csvWaypointsObject.unRecognizedWaypoints.length +
                                        csvWaypointsObject.validWaypoints.length}{' '}
                                    {t('waypoints')},
                                    {csvWaypointsObject.unRecognizedWaypoints.length > 0 && (
                                        <span>
                                            {' '}
                                            {t('fromThem')} {csvWaypointsObject.unRecognizedWaypoints.length}{' '}
                                            {t('notRecognized')}
                                        </span>
                                    )}
                                </p>
                                {csvWaypointsObject.invalidWaypoints.length > 0 && (
                                    <p style={{ color: '#494949' }}>
                                        {csvWaypointsObject.invalidWaypoints.length} {t('waypoints')}{' '}
                                        {t('notValid')}
                                    </p>
                                )}
                            </div>
                        )}
                        isRedirectPopup
                        isOpen={isCsvLoadDoneModalOpen}
                        onConfirm={onLoadCsvDoneModal}
                        Img={() => <AttachFileIcon style={{ color: '#494949' }} fontSize="large" />}
                    />
                )}

                <DeleteConfirmModal
                    isOpen={isDeleteModalOpen}
                    Body={() => (
                        <>
                            {isDeleting ? (
                                <Spinner />
                            ) : (
                                <div>
                                    {isDeleteFromRow
                                        ? t('deleteThisWaypoint')
                                        : `${t('toDelete')} ${waypointsSelectedIds.length} ${t('waypoints')}`}
                                </div>
                            )}
                        </>
                    )}
                    onClose={onDeleteConfirmationModalClose}
                    onConfirm={onDeleteConfirmationModalConfirm}
                />
                <containers.MainSectionContainer
                    className="main-section-container"
                    style={isParameterFormOpen ? { cursor: 'not-allowed' } : {}}
                >
                    {isFormOpen && <OptimizationFloatMap />}
                    <div style={isParameterFormOpen ? { pointerEvents: 'none' } : {}}>
                        <HeaderWithDropdown {...headerWithDropdownProps} />
                        <div style={{ paddingLeft: '1.76em', paddingRight: '1.76em', paddingTop: '1.76em' }}>
                            <input
                                onChange={(ev) => {
                                    dispatch(setIsGeneralLoadingAction({ isLoading: true }));
                                    onLoadCsv.changeHandler(ev);
                                    resetFileInput();
                                }}
                                type="file"
                                id="file"
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                accept="text/csv"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '15px',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}
                            >
                                {t('waypointsList')} ({waypointStock?.length})
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '15px',
                                }}
                            >
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <AddIconNoText withDivider onClick={onAdd} />
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopOverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuList sx={{ borderRadius: '8px' }}>
                                            <MenuItem
                                                onClick={onLoadWaypointsFromCsv}
                                                sx={{ fontSize: '16px', fontWeight: 400, color: '#494949' }}
                                            >
                                                {t('loadWaypointsFromFile')} CSV
                                            </MenuItem>
                                            <hr
                                                style={{
                                                    backgroundColor: '#DFDFDF',
                                                    marginLeft: '5px',
                                                    marginRight: '5px',
                                                    marginTop: '1px',
                                                    marginBottom: '1px',
                                                    height: '1px',
                                                    border: 'none',
                                                }}
                                            />
                                            <MenuItem
                                                onClick={onAddWaypointsManually}
                                                sx={{ fontSize: '16px', fontWeight: 400, color: '#494949' }}
                                            >
                                                {t('addWaypointsManually')}
                                            </MenuItem>
                                        </MenuList>
                                    </Popover>
                                    <div
                                        style={{
                                            borderLeft: '2px solid #bebebe',
                                            paddingLeft: '7px',
                                            maxHeight: '44px',
                                        }}
                                    >
                                        <MuiTooltipV3 title={t('edit')}>
                                            <IconButton
                                                size="small"
                                                disabled={waypointsSelectedIds.length === 0}
                                                onClick={onEditSelectedWaypoints}
                                            >
                                                <div
                                                    style={
                                                        waypointsSelectedIds.length === 0
                                                            ? disabledIcon
                                                            : styleIcon
                                                    }
                                                >
                                                    <MyEditPencilIcon color="" />
                                                </div>
                                            </IconButton>
                                        </MuiTooltipV3>
                                    </div>
                                    <MuiTooltipV3 title={t('delete')}>
                                        <IconButton
                                            size="small"
                                            disabled={waypointsSelectedIds.length === 0}
                                            onClick={onDeleteSelectedWaypoints}
                                        >
                                            <div
                                                style={
                                                    waypointsSelectedIds.length === 0
                                                        ? disabledIcon
                                                        : styleIcon
                                                }
                                            >
                                                <TrashIcon
                                                    color={
                                                        waypointsSelectedIds.length === 0
                                                            ? 'rgba(0, 0, 0, 0.26)'
                                                            : '#2196F3'
                                                    }
                                                />
                                            </div>
                                        </IconButton>
                                    </MuiTooltipV3>
                                    <SearchInput
                                        style={{ marginRight: '8px' }}
                                        onChange={(ev) => {
                                            optimizationFilter.setFilterText(ev.target.value);
                                        }}
                                    />
                                    {/* gone be dropdown */}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '5px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ fontSize: '14px', fontWeight: 400 }}>
                                        {t('displayUnReconizedWaypoints')}
                                    </div>
                                    <Switch onClick={onShowUnRecoginizedWaypointsClick} color="primary" />
                                    <button
                                        onClick={onOptimizationSimulation}
                                        style={
                                            getIsOptimizationSimulationEnabled()
                                                ? optimizationSimulationButtonStyle
                                                : {
                                                      ...optimizationSimulationDisabledButtonStyle,
                                                      pointerEvents: 'none',
                                                  }
                                        }
                                        type="button"
                                    >
                                        החל אופטימיזציה
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '2em', paddingRight: '2em', marginBottom: '15px' }}>
                            <OptimizationTargetSection />
                        </div>

                        <div style={{ paddingLeft: '2em', paddingRight: '0.5em' }}>
                            {getWaypointsFromServerStatus === 'SUCCESS' && (
                                <WaypointStockGrid
                                    data={optimizationFilter.filteredWaypoints}
                                    compositeFilters={filters}
                                    onDelete={onDelete}
                                    onEdit={onEdit}
                                    onRestore={onRestore}
                                />
                            )}
                            {getWaypointsFromServerStatus === 'FAILURE' && (
                                <div>{t('errorFetchingData')}</div>
                            )}
                            {getWaypointsFromServerStatus === 'LOADING' && <Spinner />}
                        </div>
                    </div>
                </containers.MainSectionContainer>
                <WidgetsContainerV2 isOpen>
                    {/* Widgets */}
                    <Slide direction="up" in={isFormOpen} unmountOnExit>
                        {isFormOpen ? <OptimizationForm /> : <div />}
                    </Slide>

                    <Slide direction="up" in={isParameterFormOpen}>
                        {isParameterFormOpen ? <OptimizationParametersForm /> : <div />}
                    </Slide>

                    {!isFormOpen && !isParameterFormOpen ? (
                        <WidgetWrapperV2 containerStyle={{ padding: '20px 0' }}>
                            {/* <WidgetsLayoutContainer /> */}
                            <OptimizationWidgetLayoutContainer />
                        </WidgetWrapperV2>
                    ) : null}
                </WidgetsContainerV2>
            </containers.ScreenContainer>
        </>
    );
};

// consadasd

export default WaypointManagementScreen;
