import { IAuthorization, ReqStatus } from 'src/api/types';
import { PassengersErrorMessagesEnum } from 'src/types/global';

import { IAccount, RefreshTokenStatus, SingupType } from 'src/types/login';
import { ILoginState } from '../type';
import { IRootReducer } from '../reducers/index';

export const loginSelector = (state: IRootReducer): ILoginState => state.loginReducer;
export const isConnectSelector = (state: IRootReducer): ReqStatus | null =>
    loginSelector(state).isAuthenticated;
export const selectedFcAccountSelector = (state: any): IAccount | null | undefined =>
    loginSelector(state).selectedFcAccount;
export const selectedLoginType = (state: any): SingupType | null => loginSelector(state).loginType;
export const tokenSelector = (state: IRootReducer): string => loginSelector(state).token;
export const authorizationTokenSelector = (state: IRootReducer): IAuthorization | undefined =>
    loginSelector(state).authorizationToken;
export const selectedDate = (state: IRootReducer): Date | undefined => loginSelector(state).selectedDate;
export const mobileSelector = (state: IRootReducer): string | undefined => loginSelector(state).mobile;
export const userUUIDSelector = (state: IRootReducer): string | undefined => loginSelector(state).userUUID;
export const errorMessageSelector = (state: IRootReducer): string | undefined | null =>
    loginSelector(state).errorMassege;
export const errorCodeSelector = (state: IRootReducer): PassengersErrorMessagesEnum | undefined | null =>
    loginSelector(state).errorCode;
export const refreshTokenStatusSelector = (state: IRootReducer): RefreshTokenStatus | undefined | null =>
    loginSelector(state).refreshTokenStatus;
