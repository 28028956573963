import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.style';
import yit from '../../assets/images/yit.png';

export interface IFooter {
    style?: CSSProperties;
}

const Footer = ({ style = {} }: IFooter): JSX.Element => {
    const { t } = useTranslation();

    return (
        <styles.Container style={style}>
            <img src={yit} alt="" />
            <styles.TextLink href="http://y-it.co.il/" target="_blank">
                {t('whoWeAre')}
            </styles.TextLink>
        </styles.Container>
    );
};

export default Footer;
