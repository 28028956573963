import { useSelector } from 'react-redux';
import { getOptimizationSimulationResult, getServerOptimizationWaypoints } from '../api/optimizationApi';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    selectAllWaypointAction,
    setGetSimulationResultAction,
    setGetWaypointsFromServerStatusAction,
    setIsGeneralLoadingAction,
    setIsOptimizationSimulationScreenOpenAction,
    setSimulationHashIdAction,
    setSimulationResultAction,
    setSimulationResultFirstRingAction,
    setTimeDeltaAction,
    setWaypointStockAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { WsResponse, WsResponseWithDelta } from 'src/api/types';
import { IOptimizationWaypoint, ISimulationResult } from '../api/types';
import { buildRouteName, buildWaypointName, updateWaypoints } from '../helpers';
import useCommons from 'src/hooks/common/useCommons';
import moment from 'moment';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { uuid } from 'src/utilis/utilis';
import { useState } from 'react';

const MAXIMUM_GET_OPTIMIZATIONS_REPEATS = 30;

export const useGetOptimizationSimulation = () => {
    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();
    const firstRingSimulationResult = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getSimulationResult.firstRingData
    );

    const handleSimulationResponse = (
        res: ISimulationResult,
        intervalId: NodeJS.Timeout,
        isRouteMethod: boolean,
        isMerge: boolean
    ) => {
        console.log('res1', res);

        if (res && res.isSuccess) {
            dispatch(setGetSimulationResultAction({ status: 'SUCCESS' }));
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
            dispatch(setSimulationHashIdAction({ hashId: null }));

            const parsedOptimumRouteResult = res.optimumRouteResult.map((route) => {
                const routeName = buildRouteName(route);
                return { ...route, routeName, isSelected: false, localId: uuid() };
            });

            // isMerge

            if (!isRouteMethod) {
                dispatch(
                    setSimulationResultAction({
                        simulationResult: { ...res, optimumRouteResult: parsedOptimumRouteResult },
                    })
                );
                dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: true }));
            } else if (isMerge) {
                const firstRoutes = firstRingSimulationResult?.optimumRouteResult;

                if (firstRoutes) {
                    dispatch(
                        setSimulationResultAction({
                            simulationResult: {
                                ...res,
                                optimumRouteResult: [...parsedOptimumRouteResult, ...firstRoutes],
                            },
                        })
                    );
                    dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: true }));
                }
            } else {
                dispatch(
                    setSimulationResultFirstRingAction({
                        simulationResult: { ...res, optimumRouteResult: parsedOptimumRouteResult },
                    })
                );
            }

            clearInterval(intervalId);
        }

        // Error Code 1 no hashId in DB

        // MAXIMUM TASK LIMIT

        // ERROR CODE 3 OPTIMIZATION NOT STARTED

        // ERROR CODE 6 NO ROUTES RETURNED
        else if (res && res.errorCode === 4) {
            console.log('Process Percent', res.calculationPercentage);
        } else {
            clearInterval(intervalId);
            dispatch(setGetSimulationResultAction({ status: 'FAILURE' }));
            dispatchAlert('error', t('unknown'));
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
        }
    };

    const getOptimizationSimulation = async (hashID: string, isRouteMethod: boolean, isMerge: boolean) => {
        try {
            let counter = 0;

            const intervalId = setInterval(async () => {
                if (counter > MAXIMUM_GET_OPTIMIZATIONS_REPEATS) {
                    clearInterval(intervalId);
                }

                console.log('counter', counter);

                const res = await getOptimizationSimulationResult(hashID);
                handleSimulationResponse(res.data, intervalId, isRouteMethod, isMerge);

                counter++;
            }, 1000);

            console.log('intervalId', intervalId);
        } catch (error) {
            dispatchAlert('error', t('unknown'));
            console.log('error', error);
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
        }
    };

    return {
        getOptimizationSimulation,
    };
};
