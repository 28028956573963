export enum LoginActionType {
    initialConfiguration = 'initialConfiguration',
    initialConfigurationSuccess = 'initialConfigurationSuccess',
    initialConfigurationFail = 'initialConfigurationFail',
    setAuthorizationToken = 'setAuthorizationToken',
    ConnectFail = 'ConnectFail',
    UserSetting = 'UserSetting',
    UserData = 'UserData',
    SetFcAccount = 'SetFcAccount',
    SetDate = 'SetDate',
    Logout = 'Logout',
    SetLoginType = 'SetLoginType',
    SetTokenRefreshStatus = 'SetTokenRefreshStatus',
    SetErrorMessage = 'SetErrorMessage',
    SetErrorCode = 'SetErrorCode',
}

export enum LineActionType {
    retriveLines = 'retriveLines',
    retriveLineSuccess = 'retriveLineSuccess',
    retriveLineFaild = 'retriveLineFaild',
    selectedLineChange = 'selectedLineChange',
    removeLine = 'removeLine',
}

export enum PassengersShiftActionType {
    onResetShiftSelection = 'onResetShiftSelection',
    setAsOnlySelectedPassenger = 'setAsOnlySelectedPassenger',
    setSelectedPassengersIds = 'setSelectedPassengersIds',
    onSwitchPath = 'onSwitchPath',
    onFormShowChange = 'onFormShowChange',
    retrivePassenger = 'RetrivePassengerShifts',
    retrivePassengerSuccess = 'RetrivePassengerShiftsSuccess',
    addPassngerShifts = 'addPassngerShifts',
    addPassngerShiftsSuccess = 'addPassngerShiftsSuccess',
    addPassngerShiftsFaild = 'addPassngerShiftsFaild',
    delPassngerShifts = 'delPassngerShifts',
    delPassngerShiftsSuccess = 'delPassngerShiftsSuccess',
    delPassngerShiftsFaild = 'delPassngerShiftsFaild',
    retrivePassengerFail = 'RetrivePassengerShiftsFail',
    retriveShifts = 'RetriveShifts',
    retriveShiftsSuccess = 'RetriveShiftsSuccess',
    setSelectedShift = 'setSelectedShift',
    setSelectedPassenger = 'setSelectedPassenger',
    setSelectedAllPassengers = 'setSelectedAllPassengers',
    setSelectedSingleShift = 'setSelectedSingleShift',
    updatePassengersShift = 'updatePassengersShift',
    removePassengersShift = 'removePassengersShift',
    editShift = 'editShift',
    onClickedBlockedTable = 'onClickedBlockedTable',
    onChangedShiftSelection = 'onChangedShiftSelection',
    addToBlockedDates = 'addToBlockedDates',
    refreshPassengersOrdersCount = 'refreshPassengersOrdersCount',
    updateDateAvailabilityStatus = 'updateDateAvailabilityStatus',
    selectTableWeek = 'selectTableWeek',
}

export enum PassengerActionType {
    changedForm = 'changedForm',
    resetFormState = 'resetFormState',
    retrivePassenger = 'RetrivePassenger',
    retrivePassengerSuccess = 'RetrivePassengerSuccess',
    setClientId = 'setClientId',
    setModefiedPassenger = 'modefiedPassenger',
    retrivePassengerFail = 'RetrivePassengerFail',
    changeSelection = 'ChangeSelection',
    deletePassenger = 'DeletePassenger',
    deletePassengerSuccess = 'DeletePassengerSuccess',
    deletePassengerFail = 'DeletePassengerFail',
    setPassenger = 'setPassenger',
    setPassengerSuccess = 'setPassengerSuccess',
    setPassengerFail = 'setPassengerFail',
    onAddPassengerBtnClicked = 'onAddPassengerBtnClicked',
    setFormAddress = 'setFormAddress',
    onUnSelectPassenger = 'onUnSelectPassenger',
    onChangeAddress = 'onChangeAddress',
}
