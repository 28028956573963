import { AxiosResponse } from 'axios';
import { ILine } from 'src/types/line';

export interface IResponse<T> {
    response: FcResponseState;
    data: T;
}
export interface GeneralWsResponse<T> {
    response: string;
    data: T;
}
export interface ErrorMessage {
    [errorCode: string]: string;
}

export enum QueryKey {
    VALIDATE_TOKEN = 'VALIDATE_TOKEN',
    VALIDATE_SMS = 'VALIDATE_SMS',
}

export enum ReqStatus {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    ON_STAND_BY = 'ON_STAND_BY',
}
export enum ReqStatus2 {
    Success = 'SUCCESS',
    Fail = 'FAIL',
    OnStandBy = 'ON_STAND_BY',
    Pending = 'PENDING',
}
export interface IturanLocationRequestPayload {
    userName: string;
    Password: string;
    Plate: string;
    Start: string;
    End: string;
    UAID: number;
    MaxNumberOfRecords: number; // if 0 - returns all
}
export interface IturanRecord {
    Head: string;
    Lat: number;
    Lon: number;
    Date: Date;
}
export interface IFcRequest {
    token: string;
    proxyUrl?: string;
    dbUrl: string;
}

export interface FcAccount {
    accountCode: number;
    accountName: string;
    proxyUrl?: string;
    dbUrl: string;
    contactUUID: string;
    accountGuid: string; // used on uploading file ft
}

export interface FcData {
    token: string;
    gps_server: string;
    gps_token: string;
    fcAccounts: FcAccount[];
}

export interface FcLogin {
    response: string;
    data: FcData[];
}

export interface ILinesRequest {
    relativeDate: Date | string;
    token: string;
    clinetProxy: string;
    dbUrl: string;
}

export interface IEditLineRequest {
    token: string;
    lineCode: string;
    dbUrl: string;
}

export interface ILineRequest {
    token: string;
    gps_server: string;
    driverCode: number;
    accountCode: number;
    from: string;
    to: string;
    getGPSData: number;
}

export enum FcResponseState {
    Ok = '0',
    TokenExpired = '1',
    MissingProprtis = '2',
}

export enum ResponseTypes {
    Ok = '0',
    TokenExpiredOrDoesntExist = '1',
    MissingProperties = '2',
    SqlError = '99',
}
export interface IPassengerType {
    passTypeCode: number;
    passType: string;
}

export interface IPassengerTypeResponse {
    passTypes: IPassengerType[];
    response: FcResponseState;
}

export interface IUserDetails {
    code: number;
    userName: string;
    departmentCode: string;
    email?: string;
}

export interface IScreenAuthorization {
    screendId: number;
    clients: number[];
}

export interface IAuthorization {
    [screendId: number]: IScreenAuthorization;
}

export interface IFcAuthorization {
    optionID: number;
    clients: number[];
}

export interface IFcResponseGetUserDetails {
    response: FcResponseState;
    data: IUserDetails;
    options: IFcAuthorization[];
}

export interface BaseParams {
    token: string;
    clientCode: string;
}
export interface ReqConfig<T> {
    dbUrl: string;
    endpoint?: string;
    proxyUrl?: string;
    requestParams: T;
}

/**
 * A response from the WebService.
 * @typedef {Object} WsResponse
 * @typedef {Object} T the data type
 * @property {string} response - The response from the server.
 * @property {T} data - The data from the server.
 */
export interface WsResponse<T> {
    response: '0' | '1' | '99' | string;
    data: T;
}

export interface WsResponseWithDelta<T> {
    response: '0' | '1' | '99' | string;
    lastRetrievalTime: string;
    data: T;
}

export type AxiosRes<T> = Promise<AxiosResponse<T>>;

export interface BaseReqParams {
    clientCode: string;
    token: string;
}

export type GetAccountShiftsReqConfig = ReqConfig<BaseParams>;
