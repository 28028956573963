import React, { FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialOutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { v4 as GuidService } from 'uuid';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface InputProp extends OutlinedInputProps {
    label: string;
    inputStyle?: CSSProperties;
    size: 'medium' | 'small';
}

const Input: FC<InputProp> = ({ size, label, style, inputStyle, ...inputProp }: InputProp) => {
    const guid = GuidService();

    return (
        <FormControl variant="outlined" size={size} style={style}>
            <InputLabel htmlFor={guid}>{label}</InputLabel>
            <MaterialOutlinedInput style={inputStyle} id={guid} type="text" {...inputProp} />
        </FormControl>
    );
};

export default Input;
