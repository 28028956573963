/* eslint-disable no-plusplus */
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RELATIVE } from 'src/constants/dates';
import { onResetShiftSelection } from 'src/store/actions/PassengersShiftActionType';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { formSelector, passengerSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { PASSENGER_ID_INDEX, PASSENGER_LOCATION_INDEX } from 'src/store/slices/manualOrders/helpers';
import {
    blockedDatesSelector,
    selectedPassengersIdsSelector,
    selectedPassengersSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { IObject } from 'src/types/global';
import { deepClone, stringifyDate } from 'src/utilis/utilis';
import {
    PlacemenContext,
    initialManualOrderState,
    initialAutoOrderState,
    initialState,
} from '../components/PlacemenTransportation/context';
import useOrderUtils from '../components/PlacemenTransportation/hooks/useUtils/useUtils';
import { IPlacment } from '../components/PlacemenTransportation/types';
import { ShiftType } from '../types';
// eslint-disable-next-line import/no-cycle
import { datePassed } from '../utilis';

/**
 * Returns a context object that contains the placment object and functions to modify it.
 * @returns {PlacementContext} - the context object containing the placment object and functions to modify it.
 */

const isPlacementStateChanged = (currState: IPlacment) => {
    return !_.isEqual(currState, initialState);
};

const usePlacement = () => {
    const [placement, setPlacement] = useContext(PlacemenContext);
    const { shiftAlreadyOrderedForDate } = useOrderUtils();

    // const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));

    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    const formIsOpen = useSelector((state: IRootReducer) => formSelector(state).isOpen);
    // const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));
    const isShiftOrdered = useCallback(
        (pickupTime: string, dropTime: string): boolean => {
            // console.log('4');

            let orderedAlready = false;
            for (let i = 0; i < selectedPassengers.length; i++) {
                const currPsngr = selectedPassengers[i];
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                placement.dates.forEach((dateData) => {
                    const { relativeDate } = dateData;
                    if (
                        shiftAlreadyOrderedForDate(
                            relativeDate,
                            { pickupTime, dropTime },
                            currPsngr[PASSENGER_ID_INDEX]
                        )
                    ) {
                        orderedAlready = true;
                    }
                });
            }
            return orderedAlready;
        },
        [placement.dates, selectedPassengers, shiftAlreadyOrderedForDate]
    );

    const addDateToPlacement = useCallback(
        (date: Moment | Date) => {
            // console.log('5');

            if (datePassed(moment(date))) console.log('FATAL ERROR - ordered day that passed');

            setPlacement((prev) => {
                const datesCopy = [...prev.dates];
                datesCopy.push({
                    relativeDate: moment(date).format(RELATIVE),
                });
                return {
                    ...prev,
                    dates: datesCopy,
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const removeDateFromPlacement = useCallback(
        (date: Date | Moment): number | undefined => {
            // console.log('6');

            const newDates = placement.dates.filter((d) => d.relativeDate !== moment(date).format(RELATIVE));
            if (newDates.length === placement.dates.length) return;

            setPlacement((pre) => ({ ...pre, dates: newDates }));
            console.log('removed date from placement', stringifyDate(date));
            // eslint-disable-next-line consistent-return
            return 0;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [placement.dates]
    );

    const placementIncludesDate = useCallback(
        (date: Date | Moment) => {
            // const dateAsRelativeStr = moment(date).format(RELATIVE);
            return placement.dates.some((d) => moment(d.relativeDate, RELATIVE).isSame(moment(date)));
        },

        [placement.dates]
    );

    const addShiftToPlacement = useCallback(
        (shiftType: ShiftType, shiftData: any) => {
            // console.log('78');

            if (shiftType === ShiftType.Auto) {
                setPlacement((prev) => {
                    return {
                        ...prev,
                        autoOrder: shiftData,
                        manualOrder: initialManualOrderState,
                    };
                });
                return;
            }
            setPlacement((prev) => {
                return {
                    ...prev,
                    manualOrder: shiftData,
                    autoOrder: initialAutoOrderState,
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const removeManualShiftShift = (type: 'pickup' | 'drop') => {
        // console.log(32);
        let isChanged = false;
        const updatedManualOrder = deepClone<typeof placement.manualOrder>(placement.manualOrder);
        if (type === 'pickup' && !updatedManualOrder.pickup.pickupTime) {
            isChanged = true;
            updatedManualOrder.pickup = {
                pickupTime: '',
                pickupShiftId: '',
            };
        } else if (updatedManualOrder.drop.dropTime) {
            isChanged = true;

            updatedManualOrder.drop = {
                dropTime: '',
                dropShiftId: '',
            };
        }

        if (isChanged) {
            setPlacement((prev) => {
                return {
                    ...prev,
                    manualOrder: updatedManualOrder,
                };
            });
        }
    };

    /**
     * Resets the shift data to the initial state.
     * @param {ShiftType} shiftType - the type of shift to reset.
     * @returns None
     */
    const dispatch = useDispatch();

    const resetToInitialShiftData = useCallback(
        (shiftType: ShiftType, clearRedux: boolean | undefined = false) => {
            // console.log(3214);

            if (clearRedux) dispatch(onResetShiftSelection(null));

            setPlacement((prev) => {
                return {
                    ...prev,
                    autoOrder: shiftType === ShiftType.Auto ? initialAutoOrderState : prev.autoOrder,
                    manualOrder: shiftType === ShiftType.Manual ? initialManualOrderState : prev.manualOrder,
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const resetPlacement = useCallback(() => setPlacement(initialState), [setPlacement]);

    const resetDates = useCallback(() => setPlacement((prev) => ({ ...prev, dates: [] })), [setPlacement]);

    const includesPassengers = useCallback(
        (passIds: string[]) => {
            const passIdsInPlacement = passIds.filter((passId) => {
                return selectedPassengers.some((passenger) => passenger[PASSENGER_ID_INDEX] === passId);
            });
            return passIdsInPlacement.length > 0;
        },
        [selectedPassengers]
    );

    useEffect(() => {
        // console.log(13643);

        if (!placement.dates) return;
        placement.dates.forEach((date) => {
            if (datePassed(moment(date.relativeDate, RELATIVE))) {
                removeDateFromPlacement(moment(date.relativeDate, RELATIVE));
                console.log('removed past date from placement');
            }
        });
    }, [placement.dates, removeDateFromPlacement]);

    // -- resetting placement state if the form is closed and it changed
    useEffect(() => {
        // console.log(23412);

        if (!formIsOpen && isPlacementStateChanged(placement)) {
            setPlacement(initialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formIsOpen]);

    // const blockedDates = useRootAppSelector(blockedDatesSelector);

    // -- If a date on placement is on blocked dates list, it should be removed
    // useEffect(() => {
    //     if (!placement.dates.length) return;

    //     placement.dates.forEach(({ relativeDate }) => {
    //         const placementDate = moment(relativeDate, RELATIVE);
    //         // console.log(23412);

    //         if (
    //             blockedDates.some((blockedDateStatus) => moment(blockedDateStatus.date).isSame(placementDate))
    //         ) {
    //             removeDateFromPlacement(placementDate);
    //         }
    //     });
    // }, [blockedDates, placement.dates, removeDateFromPlacement]);

    return {
        placment: placement,
        setPlacment: setPlacement,
        removeDateFromPlacement,
        addDateToPlacement,
        placementIncludesDate,
        addShiftToPlacement,
        resetToInitialShiftData,
        resetPlacement,
        resetDates,
        includesPassengers,
        isShiftOrdered,
        removeManualShiftShift,
    };
};

export default usePlacement;
