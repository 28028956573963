/* eslint-disable react/prop-types */
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { changedForm } from 'src/store/actions/passengerAction';
import { useDispatch, useSelector } from 'react-redux';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import { IRootReducer } from 'src/store/reducers';
import { selectedPassengerPasscodeSelector } from '../../../../../../../store/selectores/passengersSelector';

interface Props {
    addressSetter: any;
    currRemarks: string | undefined;
}

const RemarksInput: FC<Props> = ({ addressSetter, currRemarks }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // * Local states
    const [initialRemarks] = useState(currRemarks);
    const [inputValue, setInputValue] = useState(currRemarks);
    const [inputChanged, setInputChanged] = useState(false);

    // * Selectors
    const selectedPassengerPasscode = useSelector((state: IRootReducer) =>
        selectedPassengerPasscodeSelector(state)
    );

    // * Effects
    useEffect(() => setInputChanged(false), [selectedPassengerPasscode]);

    useEffect(() => {
        if (initialRemarks === currRemarks || !inputChanged) return;
        const timeOutId = setTimeout(() => dispatch(changedForm()), 500);
        // eslint-disable-next-line consistent-return
        return () => clearTimeout(timeOutId);
    }, [currRemarks, dispatch, inputChanged, initialRemarks]);

    // * JSX
    return (
        <TextField
            onChange={(e) => {
                setInputValue(e.target.value);
                addressSetter((prev: any) => {
                    return { ...prev, remarks: e.target.value };
                });
                setInputChanged(true);
            }}
            size="small"
            variant="outlined"
            label={t('remarks')}
            fullWidth
            value={inputValue}
        />
    );
};

export default RemarksInput;
