import { useRootAppSelector } from 'src/store/hooks';
import { coursesBuildingSelectors } from '../../../store/slices/coursesBuilding/coursesBuildingSlice';
import React from 'react';

const useGetCourseDataByCode = () => {
    const allCourses = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getAccountCourses.data
    );

    const getCourseDataByCode = React.useCallback(
        (code: string) => {
            return allCourses.find((course) => course.courseCode === code);
        },
        [allCourses]
    );

    return { getCourseDataByCode };
};

export default useGetCourseDataByCode;
