import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAuthorization } from 'src/api/types';
import { isAuthorized } from 'src/routes/hooks/useNavRoute';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { authorizationTokenSelector } from 'src/store/selectores/loginSelectors';
import { authorizedReportsSelector } from 'src/store/selectores/reportsSelectors';
import { onUpdateAuthorizedReportsListAction } from 'src/store/slices/reports/reportsSlice';
import { ReportNames } from 'src/store/slices/reports/types';

const getAuthorizedReportsList = (authToken: IAuthorization): ReportNames[] => {
    const lst = [ReportNames.visasReport, ReportNames.passengersReport];
    if (isAuthorized(routesEndpoints.MANUAL_ORDER.SCREEN_ID, authToken)) {
        lst.push(ReportNames.shortOrdersReport, ReportNames.detailedOrdersReport);
    }
    return lst;
};
const useAuthorizedReports = () => {
    const dispatch = useAppDispatch();

    const currAuthorizedReports: ReportNames[] = useAppSelector((state: any) =>
        authorizedReportsSelector(state)
    );
    const authorizationToken = useSelector((state: IRootReducer) => authorizationTokenSelector(state)) || {};

    useEffect(() => {
        const authorizedReports = getAuthorizedReportsList(authorizationToken);
        if (_.isEqual(currAuthorizedReports, authorizedReports)) return;
        dispatch(onUpdateAuthorizedReportsListAction({ authorizedReports }));
        // -- next line exp - comparing curr state to new possible one -
        // --    if removed might cause infinite dispatching
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorizationToken, dispatch]);

    return { authorizedReports: currAuthorizedReports };
};

export default useAuthorizedReports;
