import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer } from '@mui/material';

import { GridDetailRowProps } from '@progress/kendo-react-grid';
import { VisaReportPsngr } from 'src/types/reports/visasReportTypes';
import { emptyPassengersLstStyle, tableContainerStyle } from './styles';
import PassengerDataGridRow from './PassengerDataGridRow/PassengerDataGridRow';
import PassengersTableHead from './PassengersTableHead/PassengersTableHead';
import generalReducers from '../../../../../../../store/reducers/reportsReducers/generalReducers';
import {
    getWeekdayByDate,
    isStrOfLength,
    arrsHaveSomeMatchingElement,
} from '../../../../../../../utilis/utilis';

const getRows = (passengers: VisaReportPsngr[]) =>
    passengers.map((psngr: VisaReportPsngr, i: number) => (
        <PassengerDataGridRow key={psngr.passCode} passengers={passengers} psngr={psngr} idx={i} />
    ));

const VisasReportPassengersGrid = (props: GridDetailRowProps): JSX.Element => {
    const { dataItem } = props;
    const { t } = useTranslation();

    if (dataItem?.passengers?.length) {
        const { passengers } = dataItem;
        return (
            <TableContainer sx={tableContainerStyle}>
                <Table>
                    <PassengersTableHead t={t} />
                    <TableBody>{getRows(passengers)}</TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <div style={emptyPassengersLstStyle}>
            <p>אין נוסעים בנסיעה זו</p>
        </div>
    );
};

export default VisasReportPassengersGrid;
