import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import useDate from 'src/hooks/useDate';
import Dialog from '@material-ui/core/Dialog';
import { images } from 'src/assets';
import { Button } from '@material-ui/core';
import { useRootAppSelector } from 'src/store/hooks';
import {
    selectedPassengersSelector,
    shiftCapsulesIdsSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Styles from '../Shiftcell.Style';

interface Props {
    isOpen: boolean;
    onClose: (val: boolean) => void;
    onDelete: () => void;
    startTime: string;
    endTime: string;
    date: string;
    showSpinner?: boolean;
}
const textStyle = {
    paddingTop: '5px',
};
const DeleteModal: FC<Props> = ({ isOpen, onClose, onDelete, startTime, endTime, date, showSpinner }) => {
    const { t } = useTranslation();
    const { fullDays } = useDate();
    const close = () => onClose(false);

    const shiftCapsulesForDeletionCount = useRootAppSelector(shiftCapsulesIdsSelector).length;

    return (
        <Dialog open={isOpen} onClose={close}>
            <Styles.ModalContainer>
                <Styles.CircleModal>
                    <img width={27} height={27} src={images.grayTrash} alt="" />
                </Styles.CircleModal>
                <Styles.ModalBody>
                    <div style={{ fontSize: 20 }}>
                        {t('deleteShifts')}
                        {shiftCapsulesForDeletionCount ? <span> ({shiftCapsulesForDeletionCount})</span> : ''}
                    </div>
                    <div style={textStyle}>{`${startTime}-${endTime}`}</div>
                    <div style={textStyle}>{`${t('day')} ${fullDays[moment(date).day()]}, ${moment(
                        date
                    ).format('DD.MM')} `}</div>
                </Styles.ModalBody>
                <Styles.ModalAction>
                    <LoadingButton onClick={onDelete} color="primary" loading={showSpinner}>
                        {t('ok')}
                    </LoadingButton>
                    <Button onClick={close} style={{ color: 'gray' }}>
                        {t('cancel')}
                    </Button>
                </Styles.ModalAction>
            </Styles.ModalContainer>
        </Dialog>
    );
};

export default DeleteModal;
