import { DayNum } from 'src/types/global';
import { v4 } from 'uuid';
import { IRootReducer } from './reducers';
import { PassengerReportRow, RideCountPerDate } from './slices/reports/types';

export const createMsg = ({ msg = '', details = '' }: { msg: string | undefined; details: unknown }) => {
    if (details && typeof details === 'string' && details.length < 100) {
        return details;
    }
    if (details instanceof Error) {
        return details.message;
    }
    return msg || '';
};

export const asRootReducer = (state: unknown) => {
    return state as unknown as IRootReducer;
};
