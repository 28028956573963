import React from 'react';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';
import { getMapsAPIKeyNoRestrict } from 'src/utilis/utilis';
import { IOptimizationCsvWaypoint } from '../api/types';
import { AddressTypes as AddressComponents } from 'src/components/Inputs/types';
/* eslint-disable no-debugger */

export const useFetchAddressFromCoords = () => {
    const googleCounterUp = useGoogleApiCounterUp();

    const extractClearAddressData = (
        placeDetails: google.maps.places.PlaceResult,
        lat: number,
        lng: number
    ) => {
        const data: IOptimizationCsvWaypoint | any = {
            city: '',
            street: '',
            houseNum: '',
            lat,
            lng,
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { address_components } = placeDetails;
        if (!address_components) return null;

        // -- Handling city
        data.city =
            address_components.find((component) => component.types.includes(AddressComponents.City))
                ?.short_name || '';
        // -- Handling street
        data.street =
            address_components.find((component) => component.types.includes(AddressComponents.Street))
                ?.short_name || '';

        // -- Handling house number
        data.houseNum =
            address_components.find((component) => component.types.includes(AddressComponents.HouseNum))
                ?.short_name || '';

        return data;
    };

    const fetchAddressFromCoords = async (lat: number, lng: number) => {
        try {
            googleCounterUp.googleApiCounterUp();

            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${getMapsAPIKeyNoRestrict()}`
            );
            const data = await response.json();

            if (data && data.results && data.results[0] && data.results[0].address_components) {
                const coordsWithAddress = extractClearAddressData(data.results[0], lat, lng);

                if (coordsWithAddress) {
                    return coordsWithAddress;
                }
            }

            return null;
        } catch (error) {
            console.log('error: api');
        }
    };

    return {
        fetchAddressFromCoords,
    };
};
