/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import main from 'jss-rtl';
import { IAddress, IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import { ChosenCourse } from 'src/types/passengersManager/courseChoosingTypes';
import { RawInputAddress } from './Components/AddressesBox/AddressForm/types';
import { IPassenger } from '../../../../api/passengerMangerApi/types';
import { getInputValues, isValidAddressObj } from './Components/AddressesBox/AddressForm/utils';

export const isPassengerValid = (
    modefiedPassenger: IModefiedPassenger,
    myTouched: boolean,
    touchedCoursesPickForm: boolean
): boolean => {
    return (
        (modefiedPassenger.lastName &&
            modefiedPassenger.firstName &&
            modefiedPassenger.passId &&
            (modefiedPassenger.isDirty || myTouched || touchedCoursesPickForm) &&
            true) ||
        false
    );
};

/**
 * @param departmentCode - department's code
 * @param departmentsMap - map of departments and their code and name
 */
export const getDepartmentName = (departmentCode: string | undefined, departmentsMap: any): string => {
    let departmentName = '';
    // *finds department name by department code:
    if (departmentsMap && departmentCode) {
        departmentsMap.forEach((departmentInfo: any) => {
            if (departmentInfo.value === departmentCode) departmentName = departmentInfo.name;
        });
    }
    return departmentName;
};

export const buildAddressesArr = (
    mainAddressObj: RawInputAddress,
    secondaryAddressOneObj: RawInputAddress,
    secondaryAddressTwoObj: RawInputAddress,
    currAddresses: IAddress[],
    chosenCourses: ChosenCourse[]
): { addressesArr: any[]; passedTest: boolean } => {
    // const currAddressesCodes = currAddresses.map(
    //    (address) => address.addressCode,
    // );
    // console.log(mainAddressObj, secondaryAddressOneObj, secondaryAddressTwoObj);

    const addressData: Array<[RawInputAddress, string]> = [];
    if (isValidAddressObj(mainAddressObj, chosenCourses)) addressData.push([mainAddressObj, 'main']);
    if (isValidAddressObj(secondaryAddressOneObj, chosenCourses))
        addressData.push([secondaryAddressOneObj, 'secondaryOne']);
    if (isValidAddressObj(secondaryAddressTwoObj, chosenCourses))
        addressData.push([secondaryAddressTwoObj, 'secondaryTwo']);

    const adrsTypeIdx = 1;
    // console.log(addressData);
    const addressesArr = addressData.map((adrsData, i) => {
        const { addressCode, secondaryIdentifier } = adrsData[0];
        return {
            ...adrsData[0],
            addressIndex: String(i + 1),
            isDefault: adrsData[adrsTypeIdx] === 'main' ? '1' : '0',
            pickupCourse: chosenCourses.find(
                (course) => course.addressCode === addressCode || course.addressCode === secondaryIdentifier
            )?.pickup,
            dropCourse: chosenCourses.find(
                (course) => course.addressCode === addressCode || course.addressCode === secondaryIdentifier
            )?.drop,
        };
    });
    // console.log(addressesArr);
    const passedTest = !addressesArr.find((a): boolean => {
        if (a.dropCourse === undefined || a.dropCourse === undefined) return false;
        return true;
    });
    return { addressesArr, passedTest };
};

export const isEmpty = (obj: object): boolean | null => {
    if (!obj) return null;
    return Object.keys(obj).length === 0;
};

export const hasDepartment = (passenger: IModefiedPassenger): boolean => {
    if (passenger.departmentCode) return +passenger.departmentCode > -1;
    return false;
};
