import useCommons from 'src/hooks/common/useCommons';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import {
    openCourseFormAction,
    resetCourseFormAction,
    setSelectedCourseIdAction,
    toggleDeleteModalAction,
    toggleRestoreModalAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

const useRowActions = () => {
    const { dispatch, t } = useCommons();

    const onDeleteIconClick = (courseCode: string) => {
        dispatch(toggleDeleteModalAction({ isOpen: true, courseCode }));
    };

    const onEdit = (courseCode: string) => {
        dispatch(resetCourseFormAction({ keepOpen: true }));
        dispatch(openCourseFormAction({ courseCode }));
        dispatch(setSelectedCourseIdAction(courseCode));
    };

    const onRestore = async (courseData: WsCourseDataItem) => {
        dispatch(toggleRestoreModalAction({ isOpen: true, courseCode: courseData.courseCode }));
    };

    return {
        onDelete: onDeleteIconClick,
        onEdit,
        onRestore,
    };
};

export default useRowActions;
