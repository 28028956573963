/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import { Button, Select } from '@mui/material';
import { AccordionSummary } from '@material-ui/core';
import { colors } from '../../../../../style/themes/defaultTheme';

const FiltersBox = styled.div`
    margin: 10px 0;
`;
const BtnsContainer = styled.div`
    padding: 10px 0;
`;

const FiltersAccordion = muiStyled(MuiAccordion)({
    '&.MuiAccordion-focused': {
        backgroundColor: 'none',
    },
    '&.MuiAccordion-root': {
        backgroundColor: '#f5f5f5',
    },
    // '.css-i4bv87-MuiSvgIcon-root': {
    //    pointerEvents: 'click',
    //    color: colors.blue,
    // },
    '&.MuiAccordionSummary-root': {
        pointerEvents: 'none',
    },
    // border: `1px solid ${}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
});
const MyAccordionSummary = muiStyled(AccordionSummary)({
    '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
});

export const FiltersButton = muiStyled(Button)({
    width: '100%',
    // backgroundColor: 'white',
    // minWidth: '100px',
});

const AccordionBtnsContainer = styled.div<{
    paddingLeft: string;
    gtc?: string;
}>`
    display: grid;
    grid-template-columns: ${(props) => props.gtc || '1.2fr repeat(4, 1fr) 80px 80px'};
    gap: 1em;
    width: 100%;

    // padding-left: ${(props) => props.paddingLeft};
`;
const FiltersSelect = muiStyled(Select)({
    direction: 'ltr',
    // backgroundColor: 'white',
});

export const styledMui = {
    FiltersAccordion,
    FiltersButton,
    FiltersSelect,
    MyAccordionSummary,
};
const s = {
    AccordionBtnsContainer,
    FiltersBox,
    BtnsContainer,
};
export default s;
