import React, { useEffect, useMemo, useState } from 'react';
import { ChosenCourse, CourseType } from 'src/types/passengersManager/courseChoosingTypes';
import { CourseData } from '../Components/CoursesList/CourseTypeTabs/CourseChoiceCard/utils';

type Props = {
    coursesOnForm: ChosenCourse[];
    currAddressCode: string | null;
    courseCode: string;
    isPickup: boolean;
    i: number;
};

const useCardProps = ({
    coursesOnForm,
    currAddressCode,
    courseCode,
    isPickup,
    i,
}: Props): { cardProps: { isChosen: boolean } } => {
    const currCourse = useMemo(
        (): ChosenCourse | undefined =>
            coursesOnForm.find(
                (findLoopCourse: ChosenCourse) => findLoopCourse.addressCode === currAddressCode
            ),
        [coursesOnForm, currAddressCode]
    );

    const [chosenCourse, setChosenCourse] = useState<null | CourseData>(null);

    useEffect(() => {
        if (!currCourse) setChosenCourse(null);
        else if (currCourse.pickup === courseCode && isPickup) {
            setChosenCourse({ code: currCourse.pickup, idx: i });
        } else if (currCourse.drop === courseCode && !isPickup) {
            setChosenCourse({ code: currCourse.drop, idx: i });
        }
    }, [courseCode, currCourse, i, isPickup]);

    const cardProps = useMemo(() => {
        if (!currCourse) return { isChosen: false };
        return {
            isChosen: isPickup
                ? chosenCourse?.code === currCourse.pickup
                : chosenCourse?.code === currCourse.drop,
        };
    }, [chosenCourse, currCourse, isPickup]);

    return { cardProps };
};
export default useCardProps;
