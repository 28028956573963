import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import { sendSMS } from 'src/api/api';
import { mobilePhoneRegex, digitRegex, getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';

import styles from './Phone.style';

interface Props {
    onSubmit: (p: string) => void;
    onError: (errorMassage: string) => void;
}

const Phone = ({ onSubmit, onError }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [isPresstine, setPresstine] = useState(true);

    const onSendSMS = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setisLoading(true);
            // const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
            // const res = await sendSMS({ deviceToken, userMobile: phone });
            const res = await sendSMS({ userMobile: phone });

            setisLoading(false);

            if (res.data.response === '0') {
                onSubmit(phone);
                return;
            }

            if (res.data.response === '2') {
                onError(t('phoneNumberWasntFound'));
                return;
            }

            if (res.data.response === '9') {
                onError(t('smsPunishment'));
                return;
            }

            onError(t('genralError'));
        } catch (error) {
            setisLoading(false);
            onError(t('genralError'));
            console.log('e', error);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPresstine(false);
        if (!digitRegex.test(e.target.value) && e.target.value) {
            e.preventDefault();
            return;
        }

        setPhone(e.target.value);
    };

    return (
        <styles.Form onSubmit={onSendSMS}>
            <styles.Center>
                <styles.Text>{t('typePhoneNumber')}</styles.Text>
                <styles.Input
                    autoFocus
                    error={!mobilePhoneRegex.test(phone) && !isPresstine}
                    inputProps={{
                        style: { textAlign: 'center', fontSize: 18 },
                        maxLength: 10,
                    }}
                    value={phone}
                    onChange={handleChange}
                />
            </styles.Center>
            <styles.Button
                disabled={!mobilePhoneRegex.test(phone)}
                color="primary"
                variant="contained"
                size="large"
                type="submit"
            >
                {/* TODO */}
                {false ? (
                    <div>
                        <CircularProgress color="inherit" size={26} />
                    </div>
                ) : (
                    t('enterTheSystem')
                )}
            </styles.Button>
        </styles.Form>
    );
};

export default Phone;
