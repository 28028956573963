import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentsStatistics, getShiftsStatistics } from 'src/api/manualOrderApi/manualOrderApi';
import useFilterValuesBySelectedReport from 'src/screens/Reports/hooks/useFilterValuesByReport';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    selectApplyFiltersBtnClickCount,
    selectedReportNameSelector,
    widgetsUiSelector,
} from 'src/store/selectores/reportsSelectors';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { ReportNames } from 'src/store/slices/reports/types';
import { asRootReducer } from 'src/store/utils';
import widgetsHooks from '../../../hooks/common.hooks';
import { IProgressData, IProgressDataPayload } from '../../../types';

const defaultFormatDate = 'YYYY-MM-DD';
const uiFormatDate = 'DD.MM.YYYY';

const emptyStatistics = {
    data: [],
    fromDate: '',
    toDate: '',
};

const useSummaryDepatment = (filterBy: number, clientCode: number | undefined) => {
    // Selectors
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    // const dateOnWidgets = useAppSelector((state) => widgetsUiSelector(asRootReducer(state)).dateOnWidget);
    const reportName = useAppSelector(selectedReportNameSelector);
    const applyFiltersBtnClickCount = useRootAppSelector(selectApplyFiltersBtnClickCount);

    // Custom hooks
    const departmentFilterParams = widgetsHooks.useSingleDepartmentFilter();

    // States
    const [daylyStatisticsData, setDaylyStatistics] = useState<{
        data: IProgressDataPayload[];
        fromDate: string;
        toDate: string;
    }>(emptyStatistics);
    const [weeklyStatisticsData, setWeeklyStatistics] = useState<{
        data: IProgressDataPayload[];
        fromDate: string;
        toDate: string;
    }>(emptyStatistics);
    const [monthlyStatisticsData, setMonthlyStatistics] = useState<{
        data: IProgressDataPayload[];
        fromDate: string;
        toDate: string;
    }>(emptyStatistics);

    const { getFilterValuesByReport } = useFilterValuesBySelectedReport();
    // Funcs
    const getStatistics = async (
        display: number
    ): Promise<{
        data: IProgressDataPayload[];
        fromDate: string;
        toDate: string;
    }> => {
        return new Promise((resolve, reject): void => {
            try {
                const { dbUrl } = selectedFcAccount || {};
                if (dbUrl) {
                    const dateToUse = getFilterValuesByReport('curr', reportName)?.fromDate;
                    if (filterBy === 1) {
                        getDepartmentsStatistics({
                            dbUrl,
                            display,
                            clientCode: clientCode || -1,
                            token,
                            relativeDate: moment(dateToUse).format('YYYY-MM-DD'),
                            ...departmentFilterParams,
                        })
                            .then((r) => {
                                const { departmentsStatistics, fromDate, toDate } = r.data;
                                return resolve({
                                    data:
                                        departmentsStatistics.map((d) => ({
                                            name: d.departmentName,
                                            code: d.departmentCode,
                                            ...d,
                                        })) || [],
                                    fromDate,
                                    toDate,
                                });
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    } else {
                        getShiftsStatistics({
                            dbUrl,
                            display,
                            clientCode: clientCode || -1,
                            token,
                            relativeDate: moment(dateToUse).format('YYYY-MM-DD'),
                            ...departmentFilterParams,
                        })
                            .then((r) => {
                                const { shiftsStatistics, fromDate, toDate } = r.data;
                                return resolve({
                                    data:
                                        shiftsStatistics.map((s) => ({
                                            name: s.shiftName,
                                            code: s.shiftCode,
                                            ...s,
                                        })) || [],
                                    fromDate,
                                    toDate,
                                });
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    }
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (!clientCode) {
            return;
        }
        (async () => {
            try {
                const resDaylyStatistics = await getStatistics(1);
                const resWeeklyStatistics = await getStatistics(2);
                const resMonthlyStatistics = await getStatistics(3);
                setDaylyStatistics(resDaylyStatistics);
                setWeeklyStatistics(resWeeklyStatistics);
                setMonthlyStatistics(resMonthlyStatistics);
            } catch (error) {
                console.log({ error });
                dispatch(setErrorMessage((error as Error).message));
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientCode, dispatch, applyFiltersBtnClickCount, filterBy]);

    const normilazeDate = (fromDate: string, toDate: string): string => {
        if (toDate === fromDate) return moment(fromDate, defaultFormatDate).format(uiFormatDate);
        return `${moment(fromDate, defaultFormatDate).format(uiFormatDate)}-${moment(
            toDate,
            defaultFormatDate
        ).format(uiFormatDate)}`;
    };

    const normilazeStatisc = useCallback(
        (statistics: { data: IProgressDataPayload[]; fromDate: string; toDate: string }) => {
            const { fromDate, toDate } = statistics;
            const data = statistics.data.reduce((acc: IProgressData[], curr) => {
                if (!curr) return acc; // TODO

                const { code, name, totalOrders, isPickup } = curr;
                const statisticsIndex = acc.findIndex((s) => s.code === code);
                if (statisticsIndex === -1) {
                    acc.push({
                        code,
                        name,
                        pick: isPickup === '1' ? totalOrders : 0,
                        drop: isPickup === '0' ? totalOrders : 0,
                    });
                } else {
                    acc[statisticsIndex] = {
                        ...acc[statisticsIndex],
                        pick: isPickup === '1' ? totalOrders : acc[statisticsIndex].pick,
                        drop: isPickup === '0' ? totalOrders : acc[statisticsIndex].drop,
                    };
                }

                return acc;
            }, []);

            const max = Math.max(...data.flatMap((d) => d.pick + d.drop));

            return {
                data,
                max: max === -Infinity ? 0 : max,
                relativeDate: normilazeDate(fromDate, toDate),
            };
        },
        []
    );

    const daylyStatistics = useMemo(
        () => normilazeStatisc(daylyStatisticsData),
        [daylyStatisticsData, normilazeStatisc]
    );

    const weeklyStatistics = useMemo(
        () => normilazeStatisc(weeklyStatisticsData),
        [weeklyStatisticsData, normilazeStatisc]
    );
    const monthlyStatistics = useMemo(
        () => normilazeStatisc(monthlyStatisticsData),
        [monthlyStatisticsData, normilazeStatisc]
    );

    return {
        daylyStatistics,
        weeklyStatistics,
        monthlyStatistics,
    };
};

export default useSummaryDepatment;
