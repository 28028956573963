import { Tab } from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import { ridesInfoSummaryDataSelector } from 'src/store/selectores/reportsSelectors';
import { SummaryData } from '../../types';
import ProgressbarList from './ProgressbarList';
import * as Styles from './Summarycard.Style';
import TypeOfSummaryDropdown from './TypeOfSummaryDropdown/TypeOfSummaryDropdown';

// interface Props {
//    date: Date;
//    clientCode: number;
//    statistics?: SummaryData; // TODO make this primary way of getting data
// }

enum DataTypes {
    Passengers = 'passengers',
    Rides = 'rides',
}

const RidesSummaryCard: FC = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [orderBy, setOrderBy] = useState(2);

    // todo: remove and use the data from the store
    const { rides, passengers } = useAppSelector((state: any) => ridesInfoSummaryDataSelector(state));

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleChangeSelect = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setOrderBy(event.target.value as number);
    }, []);

    const { Passengers, Rides } = DataTypes;
    return (
        <Styles.Container>
            <TypeOfSummaryDropdown t={t} orderBy={orderBy} handleChangeSelect={handleChangeSelect} />
            <Styles.StyledTabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label={t('daily')} />
                <Tab label={t('weekly')} />
                <Tab label={t('monthly')} />
            </Styles.StyledTabs>
            <Styles.ProgressContainer>
                {selectedTab === 0 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? Passengers : Rides}
                        data={orderBy === 2 ? rides.dailyStatistics.data : passengers.dailyStatistics.data}
                        max={orderBy === 2 ? rides.dailyStatistics.max : passengers.dailyStatistics.max}
                        relativeDate={
                            orderBy === 2
                                ? rides.dailyStatistics.relativeDate
                                : passengers.dailyStatistics.relativeDate
                        }
                    />
                )}
                {selectedTab === 1 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? Passengers : Rides}
                        data={orderBy === 2 ? rides.weeklyStatistics.data : passengers.weeklyStatistics.data}
                        max={orderBy === 2 ? rides.weeklyStatistics.max : passengers.weeklyStatistics.max}
                        relativeDate={
                            orderBy === 2
                                ? rides.weeklyStatistics.relativeDate
                                : passengers.weeklyStatistics.relativeDate
                        }
                    />
                )}
                {selectedTab === 2 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? Passengers : Rides}
                        data={
                            orderBy === 2 ? rides.monthlyStatistics.data : passengers.monthlyStatistics.data
                        }
                        max={orderBy === 2 ? rides.monthlyStatistics.max : passengers.monthlyStatistics.max}
                        relativeDate={
                            orderBy === 2
                                ? rides.monthlyStatistics.relativeDate
                                : passengers.monthlyStatistics.relativeDate
                        }
                    />
                )}
            </Styles.ProgressContainer>
        </Styles.Container>
    );
};

export default RidesSummaryCard;
