import React, { useEffect, useMemo } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import ManualAddressControlled from 'src/components/AddressInputs/ManualAddressControlled/ManualAddressControlled';
import ControlledManualAndGoogleAddressInput from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import Ver2ManualAndGoogleAddressInput from 'src/components/AddressInputs/ManualAndGoogleAddressInput/ManualAndGoogleAddressInputVer2';
import { AddressInfo, UpdateValueFunc } from 'src/components/AddressInputs/types';
import { StyledInputContainer, StyledSectionContainer } from 'src/screens/Main/components/AddLineForm/styles';
import {
    ExistingPassenger,
    PassengerInput,
    PassengerInputNames,
} from 'src/screens/Main/components/AddLineForm/types';
import { useRootAppSelector } from 'src/store/hooks';
import { oldPassengerDataSelector } from 'src/store/slices/lines/linesSlice';
import GenericTextInput from '../../Inputs/GenericTextInput';
import PhoneInput from '../../Inputs/PhoneInput';
import { newPassengerFormHooks as hooks } from './hooks.NewPassengerForm';

type Props = {};

const NewPassengerForm: React.FunctionComponent<Props> = () => {
    const passengersForm = useFormContext<PassengerInput>();
    const {
        register,
        formState: { errors },
    } = passengersForm;

    hooks.usePreEditValues();

    const getInputConfig = (name: PassengerInputNames) => ({
        register,
        errors,
        name,
    });

    const { getAddressProps } = hooks.useProps();

    return (
        <StyledSectionContainer>
            <StyledInputContainer className="StyledInputContainer">
                <GenericTextInput {...getInputConfig('firstName')} />
                <GenericTextInput {...getInputConfig('lastName')} />
            </StyledInputContainer>
            <StyledInputContainer style={{ paddingTop: 20 }} className="StyledInputContainer">
                <GenericTextInput {...getInputConfig('internalCode')} />
            </StyledInputContainer>
            <div style={{ paddingTop: 20 }}>
                <PhoneInput
                    errors={errors}
                    register={register}
                    names={{ mainPart: 'phone', prefix: 'phonePrefix' }}
                />
            </div>

            <div style={{ paddingTop: 20 }}>
                <Ver2ManualAndGoogleAddressInput {...getAddressProps()} />
            </div>

            <StyledInputContainer style={{ paddingTop: 20 }} className="StyledInputContainer">
                <GenericTextInput {...getInputConfig('email')} />
            </StyledInputContainer>

            <StyledInputContainer style={{ paddingTop: 20 }} className="StyledInputContainer">
                <GenericTextInput {...getInputConfig('passengerRemarks')} />
            </StyledInputContainer>
        </StyledSectionContainer>
    );
};

export default NewPassengerForm;

// {
//    /* <ManualAddressControlled
//             values={addressValues}
//             onClickExit={() => alert('exit')}
//             withExitBtn={false}
//             errors={errors}
//             setValue={updateValue}
//          /> */
// }
