/* eslint-disable no-empty-pattern */
import React, { FC } from 'react';
// import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { formHeaderFont } from 'src/style/styledCSS';

import Typography from '@material-ui/core/Typography';

import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';
import { getAsTemplateStrArr as asTSA } from 'src/utilis/utilis';
import styles from '../../styles/styledComponents';

interface Props {}

const FormHeader: FC<Props> = ({}) => {
    const { t } = useTranslation();
    const currentSettingBeingEdited = useRootAppSelector(selectors.currentSettingOnFormSelector);

    return (
        <div>
            <Typography align="left" style={formHeaderFont}>
                {t(asTSA(currentSettingBeingEdited || ''))}
            </Typography>
        </div>
    );
};

export default FormHeader;
