import styled, { css, CSSObject } from 'styled-components';
import MaterialOutlinedInput from '@material-ui/core/OutlinedInput';
import MaterialTextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { breakPoints, deviceSizes } from 'src/style/themes/defaultTheme';
import { IObject } from 'src/types/global';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25em 0;
`;

const HeaderContainer = styled.div<{
    h?: string;
    inPassengers?: boolean;
}>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6875em;
    height: 7%;
    @media ${breakPoints.laptopS} {
        justify-content: flex-end;
    }
    ${({ inPassengers }) => css`
        ${inPassengers &&
        `
         padding: 10px 1.6875em;
      `}
    `}
`;

const LeftHeaderContainer = styled.div<{ gap?: string }>`
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: ${(props) => {
        return props.gap || 0;
    }};
    /* gap: 20px; */
`;

const FilterContainer = styled.div<{ inMain?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding-right: ${(props) => (props.inMain ? '1.6875em' : '0em')};
    padding-left: ${(props) => (props.inMain ? '30px' : '0px')};
    height: ${(props) => (props.inMain ? '' : '8%')};
    @media ${breakPoints.laptopS} {
        flex-wrap: wrap;
        gap: 5px;
    }
`;

const FlexContainerWithSpace = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 9px;
    // background-color: lightGray;
`;

const FilterHeaderContianer = styled('div')<{ inMain?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: ${(props) => (props.inMain ? '1.6875em' : '0em')};
    padding-left: ${(props) => (props.inMain ? '30px' : '0px')};
    padding-bottom: ${(props) => (props.inMain ? '1px' : '0')};
    // background-color: orange;
    // height: 5%;
`;

const TableContainer = styled.div<{
    style?: CSSObject;
    mediaQuery?: IObject;
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em 1.6875em 0 1.6875em;
    ${({ mediaQuery }) =>
        mediaQuery
            ? css`
                  @media screen and ${mediaQuery.arg} {
                      ${mediaQuery.style}
                  }
              `
            : ''}
    ${(props) =>
        css`
            ${props.style}
        `};

    // height: 79%;
    // box-sizing: border-box;
    // overflow: hidden;
`;
const OutlinedInput = styled(MaterialOutlinedInput)`
    max-width: 18em;
`;

const TextField = styled(MaterialTextField)`
    font-size: 2rem;
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Text = styled.p<{
    color?: string;
}>`
    font-family: ${({ theme }) => theme.fontFamilies.SalsaRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 100%;
    color: ${({ color = 'black' }) => color};
    margin: 0;
`;

const Btn = styled(Button)`
    background: none;
    border: none;
    margin: 0;
`;

const FilterText = styled.p`
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    // text-decoration: underline;
`;

const Hr = styled.hr`
    width: 100%;
    opacity: 0.3;
    margin-bottom: 0;
`;

const Label = styled.label`
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
`;
const LinesInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    // gap: 16px;
    width: 100%;
    // padding-top: 10px;
    // max-height: 70px;
`;
// export default {
//     Container,
//     FilterContainer,
//     FlexContainerWithSpace,
//     HeaderContainer,
//     TextContainer,
//     LeftHeaderContainer,
//     FilterHeaderContianer,
//     TableContainer,
//     TextField,
//     OutlinedInput,
//     Text,
//     Btn,
//     FilterText,
//     Hr,
//     Label,
//     LinesInputsContainer,
// };

const styles = {
    Container,
    FilterContainer,
    FlexContainerWithSpace,
    HeaderContainer,
    TextContainer,
    LeftHeaderContainer,
    FilterHeaderContianer,
    TableContainer,
    TextField,
    OutlinedInput,
    Text,
    Btn,
    FilterText,
    Hr,
    Label,
    LinesInputsContainer,
};

export default styles;
