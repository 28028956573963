/* eslint-disable no-return-assign */
import React, { FunctionComponent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { v4 as GuidService } from 'uuid';
import { MenuItem } from '@mui/material';
import { Checkbox, ListItemText } from '@material-ui/core';
import { DefaultMenuProps } from '../DropDown/DropDown';

export interface MenuItemProps {
    name: string;
    value: any;
    checked: boolean;
    disabled?: boolean;
    child?: JSX.Element;
    renderValue?: Function;
    sx?: any;
}

export interface MultipleDropDownProps extends SelectProps {
    open?: boolean;
    label: string;
    formControlProp: FormControlProps;
    menuItems: Array<MenuItemProps>;
    onClickFormControl?: any;
    refObj?: React.MutableRefObject<any>;
}

const MultipleDropdown = ({
    label,
    renderValue,
    formControlProp,
    menuItems,
    onClickFormControl,
    open,
    refObj,
    // selected,
    ...selectedElementProp
}: MultipleDropDownProps): JSX.Element => {
    const guid = GuidService();
    // React.useEffect(() => {
    //    console.log({ open });
    // }, [open]);
    return (
        <FormControl onClick={onClickFormControl} {...formControlProp}>
            <InputLabel id={guid}>{label}</InputLabel>
            <Select
                {...selectedElementProp}
                labelId={guid}
                label={label}
                renderValue={(selected: any) => (renderValue ? renderValue(selected) : selected.join(', '))}
                open={open}
                MenuProps={{
                    ...DefaultMenuProps,
                    getContentAnchorEl: null,
                }}
                // sx={{}}
            >
                {Array.isArray(menuItems) &&
                    menuItems.map((d: MenuItemProps) => (
                        <MenuItem
                            ref={(element) => {
                                if (refObj) {
                                    // eslint-disable-next-line no-param-reassign
                                    refObj.current = element;
                                }
                            }}
                            disabled={d.disabled}
                            sx={d.sx}
                            key={d.value}
                            value={d.value}
                        >
                            <Checkbox color="primary" checked={d.checked} />
                            <ListItemText primary={d.name} />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default MultipleDropdown;
