import styled, { css, CSSObject } from 'styled-components';

const UploadedSectionContainer = styled.div<{}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    gap: 20px;
    padding-left: 10px;
`;
const UploadBtnContainer = styled.div<{}>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border: 1px dashed #494949;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 250px;
    height: 85px;
    padding: 10px 0;
    cursor: pointer;
    :hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    }
`;

const FileDisplaySectionContainer = styled.div<{}>`
    display: flex;
    flex: 2;
    flex-direction: column;
    padding-right: 15px;
    gap: 20px;
    border-right: 2px solid #dee0e2;
`;

const ModalBodyContainer = styled.div<{ style?: CSSObject }>`
    display: flex;
    justify-content: space-between;
    min-height: 130px;
    ${({ style = {} }) => css(style)}
`;

const Title = styled.div<{}>`
    display: flex;
    justify-content: space-between;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #494949;
`;

const DeleteBtnContainer = styled.div<{}>`
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 28px;
    height: 28px;
    border-radius: 50%;
`;
const uploadFilesStyles = {
    UploadedSectionContainer,
    UploadBtnContainer,
    FileDisplaySectionContainer,
    ModalBodyContainer,
    Title,
    DeleteBtnContainer,
};

export default uploadFilesStyles;
