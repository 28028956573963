import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { FormTypeHeaderStyle } from '../../styles/styles';

interface Props {
    modifyingPassenger: boolean;
    fullName: string;
}

// get proper header for the form depending on whether we are adding or editing a passenger
// eslint-disable-next-line react/prop-types
const FormTypeHeader: FC<Props> = ({ modifyingPassenger, fullName }) => {
    const { t } = useTranslation();

    return (
        // <Typography variant="h4" gutterBottom style={FormTypeHeaderStyle}>
        //    {modifyingPassenger
        //       ? `${t('editPassenger')} - ${fullName}`
        //       : t('addPassenger')}
        // </Typography>
        <div>
            {modifyingPassenger ? (
                <div>
                    {t('editPassenger')}
                    <span> - {fullName}</span>
                </div>
            ) : (
                <div>{t('addPassenger')}</div>
            )}
        </div>
    );
};

export default FormTypeHeader;
