/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from 'src/api/api';
import {
    IAddress,
    IModefiedPassenger,
    IPassenger,
    ISetSagaPassenger,
} from 'src/api/passengerMangerApi/types';
import DropDown, { IProps as IDropDown, MenuItemProps } from 'src/components/DropDown/DropDown';
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber';
import { myLog } from 'src/screens/PassengerManger/utils';
import { onSetModefiedPassenger, onSetPassengers } from 'src/store/actions/passengerAction';
import { IRootReducer } from 'src/store/reducers';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import { clientIdSelector, passengerCitySelector } from 'src/store/selectores/passengersSelector';
import { buidAdress as buildAddress, mailRegex } from 'src/utilis/utilis';
import { v4 as GuidService } from 'uuid';

import { Grid, TextField } from '@material-ui/core';

import _ from 'lodash';
import { string } from 'yup/lib/locale';
import { Card } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import {
    coursesOnFormSelector,
    isCoursePickFormOpenSelector,
} from 'src/store/slices/passengersManager/passengersManagerSlice';
import { asRootReducer } from 'src/store/utils';
import AddressesBox from './Components/AddressesBox/AddressesBox';
// import GoogleAddressSearchBar from './Components/AddressesBox/AddressForm/old/GoogleAddressSearchBar';
// import { StyledFormButtonsContainer } from 'src/screens/styles/Widgets.style';
// import { createAddressForm, stringifyAddress } from './Components/AddressesBox/AddressForm/utils';
import CancelAndApproveBtns from './Components/CancelAndApproveBtns';
import FormTypeHeader from './Components/Headers/FormTypeHeader';
import PassengerDetailsHeader from './Components/Headers/PassengerDetailsHeader';
import SeparationLine from './Components/SeperationLine';
import { cardStyle, FormStyling, getCardStyle, zeroVerticalPadding } from './styles/styles';
import {
    addressTypes,
    useAddressInputs,
    useModefiedPsngrCurrCourses,
    useUpdatedModefiedPassengerData,
} from './hooks';
import { INITIAL_ADDRESS_OBJECT } from './Components/AddressesBox/utils';
import { hasDepartment, buildAddressesArr, getDepartmentName, isPassengerValid } from './utils';
import PhonesBox from './Components/PhonesBox/PhonesBox';
import { stringifyAddress } from './Components/AddressesBox/AddressForm/utils';
import useAccountCourses from './Components/coursePicking/hooks/useAccountCourses';
import CoursesList from './Components/coursePicking/Components/CoursesList/CoursesList';
import { setErrorMessage } from '../../../../store/actions/loginAction';
import { proxy } from '../../../../api/index';

// const initalAddressState: InitalAddressState = {
//    addressData: '',
//    remarks: '',
// };
export interface IPassengerForm {
    passanger: IPassenger;
    modifyingPassenger: boolean;
}

const PassengerForm: FC<IPassengerForm> = ({ passanger, modifyingPassenger }: IPassengerForm) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useAccountCourses();
    // *Selectors
    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));
    const token = useSelector((state: IRootReducer) => tokenSelector(state));

    const clientId = useSelector((state: IRootReducer) => clientIdSelector(state));

    const citys = useSelector((state: IRootReducer) => passengerCitySelector(state));

    const chosenCourses = useAppSelector((state: any) => coursesOnFormSelector(state));
    // ^ End of selectors

    // *Local states
    const [modefiedPassenger, setModefiedPassenger] = useState<IModefiedPassenger>(passanger);

    useModefiedPsngrCurrCourses(modefiedPassenger.address); // # not using the one on the redux
    // # state because its the initial state, before fetching data
    const handleSetModefiedPassenger = (newState: IModefiedPassenger) => setModefiedPassenger(newState);

    useUpdatedModefiedPassengerData({
        setModefiedPassenger,
        proxyUrl: selectedFcAccount?.proxyUrl || '',
        dbUrl: selectedFcAccount?.dbUrl || '',
        token,
        clientCode: clientId,
    });

    const [permissions] = useState<MenuItemProps[]>([
        { name: '1', value: '1' }, // [...Array(5).keys()].map((x) => {
        { name: '2', value: '2' }, //    return { name: x, value: x };
        { name: '3', value: '3' }, // }),
        { name: '4', value: '4' },
        { name: '5', value: '5' },
    ]);

    // ~ map of department name to department code
    const [departmentOptions, setDepartmentOptions] = useState<MenuItemProps[]>([]);
    // ^ End of local states

    // * Custom Hooks
    const addressesState = useAddressInputs(modefiedPassenger);

    // * Dispatchers
    const onSetPassengersDispatch = (payload: ISetSagaPassenger) => {
        dispatch(onSetPassengers(payload));
    };
    // ^ End of dispatchers
    const { mainAddress, secondaryOne, secondaryTwo } = addressesState;

    // * Effects
    // ~ map passenger to Modefied Passenger and update it
    useEffect(() => {
        let { address } = passanger;
        if (!passanger.address || !passanger.address.length) {
            address = [{ isDefault: '1', isNew: true }];
        }

        setModefiedPassenger({
            ...passanger,
            departmentName: hasDepartment(modefiedPassenger)
                ? getDepartmentName(modefiedPassenger.departmentCode, departmentOptions)
                : '',
            noSuppliermobileNumer: passanger.mobilePhone.length > 3 ? passanger.mobilePhone.substring(3) : '',
            supplierMobileNumer: passanger.mobilePhone.length > 3 ? passanger.mobilePhone.substr(0, 3) : '',
            supplierphone1: passanger.phone1.length > 3 ? passanger.phone1.substr(0, 3) : '',
            noSupplierPhone1: passanger.phone1.length > 3 ? passanger.phone1.substring(3) : '',
            address,
        });
    }, [passanger]);

    // ~ Effect for fetching departments data
    useEffect(() => {
        if (selectedFcAccount) {
            const { proxyUrl } = selectedFcAccount;

            getDepartments({
                proxyUrl,
                dbUrl: selectedFcAccount.dbUrl,
                token,
                clientCode: clientId,
            }).then((res) => {
                const { departments } = res.data;

                setDepartmentOptions([
                    { value: '-1', name: 'ללא מחלקה' },
                    ...departments.map((d) => ({
                        value: String(d.code),
                        name: d.departmentName,
                    })),
                ]);
            });
        }
    }, [selectedFcAccount, clientId, token]);

    // ^ End of effects

    // * Handlers
    // & Form submit handler
    const onSubmit = () => {
        const { dbUrl, proxyUrl } = selectedFcAccount || {};
        // ~ setting up the addresses
        if (!clientId || !dbUrl || !token) return;
        // & Formatting the phone numbers
        const phone1 = `${modefiedPassenger.phone1 || ''}`;
        const mobilePhone = modefiedPassenger.mobilePhone || '';

        const { addressesArr, passedTest } = buildAddressesArr(
            mainAddress,
            secondaryOne,
            secondaryTwo,
            passanger.address,
            chosenCourses
        );
        if (!passedTest) {
            setErrorMessage('שגיאה');
        }
        // & Payload for the set passenger dispatch
        const payload: ISetSagaPassenger = {
            clientCode: clientId,
            dbUrl,
            proxyUrl,
            token,
            // ~ when updating a passenger via the form, both this ("passengers" prop) and the next props need to be updated
            passenger: {
                ...modefiedPassenger,
                mainAdress: stringifyAddress(
                    {
                        ...mainAddress,
                        addressIndex: '0',
                        isDefault: '1',
                    },
                    true
                ),
                remarks: mainAddress.remarks,
                fullName: `${modefiedPassenger.firstName} ${modefiedPassenger.lastName}`,
                // remarks: mainAddress?.remarks,
                departmentName: hasDepartment(modefiedPassenger)
                    ? getDepartmentName(modefiedPassenger.departmentCode, departmentOptions)
                    : '',
                address: addressesArr,
                phone1,
                mobilePhone,
                passCode: modefiedPassenger.passCode,
            },
            address: addressesArr,
            firstName: modefiedPassenger.firstName,
            lastName: modefiedPassenger.lastName,
            fullName: `${modefiedPassenger.firstName} ${modefiedPassenger.lastName}`,
            contactName: modefiedPassenger.contactName,
            contactPhone1: modefiedPassenger.contactPhone1,
            isActive: modefiedPassenger.isActive,
            departmentCode: hasDepartment(modefiedPassenger) ? modefiedPassenger.departmentCode : '',
            mobilePhone,
            phone1,
            departmentName: hasDepartment(modefiedPassenger)
                ? getDepartmentName(modefiedPassenger.departmentCode, departmentOptions)
                : '',
            eMail: (mailRegex.test(modefiedPassenger.eMail) && modefiedPassenger.eMail) || undefined,
            internalCode: modefiedPassenger.passId,
            internetLevel: modefiedPassenger.internetLevel,
            passCode: modefiedPassenger.passCode,
        };
        // console.log(payload);
        onSetPassengersDispatch(payload);
    };

    // & Exiting form handler
    const onCancel = () => {
        dispatch(onSetModefiedPassenger(undefined));
    };

    const isValidLength = (phonePart: string, value: string | undefined) => {
        if (!value) return true;

        const lengthLimit = 10;
        if (phonePart === 'phoneNumber' && value.length > lengthLimit) return false;
        return true;
    };

    const onChangePhoneNumber = (prop: 'supplier' | 'phoneNumber') => (value: string | undefined) => {
        if (!isValidLength(prop, value)) return;
        setModefiedPassenger({
            ...modefiedPassenger,
            isDirty: true,
            mobilePhone: value ? value : '',
        });
    };

    const onChangePhone1 = (prop: 'supplier' | 'phoneNumber') => (value: string | undefined) => {
        if (!isValidLength(prop, value)) return;
        setModefiedPassenger({
            ...modefiedPassenger,
            isDirty: true,
            phone1: value ? value : '',
        });
    };

    // & Handle input change
    /**
      @param: prop The name of the property on the passenger object
      @returns: function
   */
    const handleChange = (prop: keyof IPassenger, charLimit = 100) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            if (String(event.target.value).length > charLimit) return;
            setModefiedPassenger({
                ...modefiedPassenger,
                isDirty: true,
                [prop]: event.target.value,
            });
        };
    };
    // ^ End of handlers

    // * Props building
    const departmentProp: IDropDown = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '100%' },
            size: 'small',
        },

        autoWidth: false,
        multiple: false,
        labalName: t('department'),
        label: t('department'),
        menueItem: departmentOptions,
        native: false,
        value: modefiedPassenger.departmentCode,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string | undefined | any;
            }>
        ) => {
            const { value } = event.target;

            setModefiedPassenger({
                ...modefiedPassenger,
                isDirty: true,
                departmentCode: value,
            });
        },
    };

    const permissionProp: IDropDown = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '100%' },
            size: 'small',
        },

        autoWidth: false,
        multiple: false,
        labalName: t('passLevel'),
        label: t('passLevel'),
        menueItem: permissions,
        native: false,
        value: modefiedPassenger.internetLevel,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string | undefined | any;
            }>
        ) => {
            const { value } = event.target;

            setModefiedPassenger({
                ...modefiedPassenger,
                isDirty: true,
                internetLevel: value,
            });
        },
    };

    const phonesBoxProps = {
        modefiedPassenger,
        onChangePhoneNumber,
        onChangePhone1,
        setModefiedPassenger,
    };
    // ^ End of props building
    // console.log('passenger form rendered');
    const isCoursePickFormOpen = useAppSelector((state) =>
        isCoursePickFormOpenSelector(asRootReducer(state))
    );

    // * JSX
    return (
        <div
            style={{
                paddingTop: '20px',
                height: '100%',
                overflow: 'auto',
            }}
        >
            <Card sx={getCardStyle(true)}>
                {/* Main header "הוספת עובד \ עריכת עובד" */}
                <CoursesList show={isCoursePickFormOpen} />
                <div style={isCoursePickFormOpen ? { display: 'none' } : {}}>
                    <FormStyling.Body>
                        <FormStyling.Header>
                            <FormTypeHeader
                                modifyingPassenger={modifyingPassenger}
                                fullName={modefiedPassenger.fullName}
                            />
                        </FormStyling.Header>
                        {/* Secondary header - פרטי עובד etc */}
                        <SeparationLine />
                        <PassengerDetailsHeader text="passengerDetails" isUsingI18n />

                        <Grid container spacing={3} alignContent="space-around">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={modefiedPassenger.passId}
                                    error={!modefiedPassenger.passId}
                                    onChange={handleChange('passId', 10)}
                                    variant="outlined"
                                    size="small"
                                    label="קוד נוסע (ת.ז / תג עובד / דרכון)"
                                    fullWidth
                                    required
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    required
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange('firstName')}
                                    value={modefiedPassenger.firstName}
                                    error={!modefiedPassenger.firstName}
                                    label={t('privateName')}
                                    fullWidth
                                    autoComplete="cc-name"
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    value={modefiedPassenger.lastName}
                                    size="small"
                                    onChange={handleChange('lastName')}
                                    required
                                    error={!modefiedPassenger.lastName}
                                    variant="outlined"
                                    label={t('familyName')}
                                    fullWidth
                                    autoComplete="cc-number"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <DropDown {...departmentProp} />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: 0, paddingBottom: 0 }}>
                                <PhonesBox {...phonesBoxProps} />
                            </Grid>
                            {/* <TextField
                              size="small"
                              value={modefiedPassenger.phone1}
                              onChange={handleChange('phone1')}
                              variant="outlined"
                              label={t('anotherPhone')}
                              fullWidth
                           /> */}
                            {/* <AddIconBox /> */}
                            <Grid item xs={12} md={12} style={{ paddingBottom: 0 }}>
                                <AddressesBox
                                    addressesState={addressesState}
                                    modefiedPassenger={modefiedPassenger}
                                    setModifiedPassenger={handleSetModefiedPassenger}
                                    citys={citys}
                                />
                            </Grid>
                            {/* {createAddressForm(
                        modefiedPassenger.address,
                        true,
                        setModefiedPassenger,
                        citys,
                     )} */}
                            <Grid item xs={12} md={12} style={zeroVerticalPadding}>
                                <SeparationLine mt={0} />
                                <PassengerDetailsHeader
                                    style={{ padding: '10px 0 0 0' }}
                                    text="פרטים נוספים"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className="input-ltr"
                                    size="small"
                                    type="email"
                                    inputMode="email"
                                    error={!mailRegex.test(modefiedPassenger.eMail)}
                                    value={modefiedPassenger.eMail}
                                    onChange={handleChange('eMail')}
                                    variant="outlined"
                                    label={t('email')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <DropDown {...permissionProp} />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ paddingBottom: 10 }}>
                                <TextField
                                    size="small"
                                    value={modefiedPassenger.contactName}
                                    onChange={handleChange('contactName')}
                                    variant="outlined"
                                    label={t('nameOfContractName')}
                                    fullWidth
                                />
                            </Grid>

                            {/* {createAddressForm(
                        modefiedPassenger.address,
                        false,
                        setModefiedPassenger,
                        citys,
                     )} */}

                            {/* <Grid item xs={12} md={12} /> */}
                        </Grid>
                    </FormStyling.Body>
                    <FormStyling.Footer>
                        <CancelAndApproveBtns
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            modefiedPassenger={modefiedPassenger}
                        />
                    </FormStyling.Footer>
                </div>
            </Card>
        </div>
    );
};

export default PassengerForm;
