/* eslint-disable import/no-cycle */

import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { ReqGetDropsConfig } from 'src/api/dropsDisplay/types';
import { processDropsDisplayWsData } from 'src/api/dataMappers/dropsDisplayBl';
import { setErrorMessage } from '../actions/loginAction';
import { reqGetDropsAction, reqGetDropsStatusUpdateAction } from '../slices/dropsDisplay/dropDisplaySlice';
import { handleResponseV3, makeRequest } from './utils/sagasUtils';
import { reqGetDrops } from '../../api/dropsDisplay/dropsDisplay';

function* reqGetDropsWatcher(action: { payload: { requestConfig: ReqGetDropsConfig } }): any {
    const failureAction = () =>
        reqGetDropsStatusUpdateAction({
            status: 'FAILED',
        });

    try {
        const { status, response, data } = yield makeRequest(() => reqGetDrops(action.payload.requestConfig));
        // console.log(status, response, data);
        yield handleResponseV3({
            status,
            response,
            *onSuccess() {
                const processedData = processDropsDisplayWsData(data);
                yield put(
                    reqGetDropsStatusUpdateAction({
                        status: 'SUCCESS',
                        data: processedData,
                    })
                );
            },
            *onFailure() {
                yield put(failureAction());
            },
        });
    } catch (error) {
        yield put(failureAction());
        yield put(setErrorMessage((error as Error).message));
    }
}
export default function* dropsDisplaySaga(): SagaIterator<any> {
    yield takeLatest(reqGetDropsAction, reqGetDropsWatcher);
}
