import React from 'react';
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material';

const MuiTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'white',
        color: '#c20000',
        fontSize: 12,
        border: '1px solid lightGray',
    },
}))(Tooltip);

const tooltipStylesV2 = {
    backgroundColor: 'white',
    padding: '14px',
    fontSize: 14,
    boxShadow: '0px 5px 6px rgba(217, 226, 233, 0.5)',
    borderRadius: '9px',
    color: 'black',
    border: '1px solid rgba(180, 190, 201, 0.303017)',
    fontWeight: 400,
    textAlign: 'right',
    fontFamily: 'Rubik',
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        '&:before': {
            border: '1px solid rgba(180, 190, 201, 0.303017)',
        },
    },
} as const;

export const MuiTooltipV2 = withStyles(() => ({
    tooltip: {
        ...tooltipStylesV2,
    },
}))(Tooltip);

export const StyledMuiTooltipV3 = withStyles(() => ({
    tooltip: {
        ...tooltipStylesV2,
        padding: '5px',
    },
}))(Tooltip);

export const MuiTooltipV3: React.FC<TooltipProps> = ({ children, ...props }) => {
    return (
        <MuiTooltipV2 arrow placement="right" {...(props as any)}>
            <div>{children}</div>
        </MuiTooltipV2>
    );
};

export default MuiTooltip;
