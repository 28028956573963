import React, { FunctionComponent, ReactNode } from 'react';
import GoogleMap, { Props, BootstrapURLKeys } from 'google-map-react';
import { getMapsAPIKey, isEnvProduction } from 'src/utilis/utilis';
import { myStyles } from './MapType2/MapType2';

export interface IMap extends Props {
    children?: ReactNode;
}

const KEY = getMapsAPIKey();

const Map: FunctionComponent<IMap> = ({ bootstrapURLKeys, children, ...prop }: IMap) => {
    const googleUrl: BootstrapURLKeys = {
        key: KEY,
        language: 'iw',
        region: 'IL',
    };

    const options = {
        ...(prop.options || {}),
        styles: myStyles as any,
    };

    return (
        <GoogleMap {...prop} options={options} bootstrapURLKeys={googleUrl}>
            {children}
        </GoogleMap>
    );
};

export default Map;
