import React, { useState } from 'react';
import { CoreTextFieldV2 } from 'src/components/Inputs/TextInput';
import { ICourseStation } from '../../../CourseInfoWidget/StationsAndTimesPanel';
import useCommons from 'src/hooks/common/useCommons';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { StationsPanelStyles as styles } from './styles';
import numberHelpers from 'src/utilis/numberHelpers';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { isTypeofNumber } from 'src/utilis/utilis';

export enum TimeFromPrevStationComponentTypes {
    Input = 'INPUT',
    Btn = 'BUTTON',
    TextDisplay = 'TEXT_DISPLAY',
}

export const SelectedTimeDisplay: React.FC<{ time: number; resetValue: () => void }> = ({
    time,
    resetValue,
}) => {
    const { t } = useCommons();

    return (
        <styles.TextContainer>
            <span>{`${time} ${t('timeFromPrevStation')}`}</span>{' '}
            <styles.X
                onClick={() => {
                    resetValue();
                }}
            >
                X
            </styles.X>
        </styles.TextContainer>
    );
};

const TimeInputWithBtns: React.FC<{
    setTimeFromPrevStationComponentType: (arg0: TimeFromPrevStationComponentTypes) => void;
    updateValue: (arg0: number) => void;
}> = ({ setTimeFromPrevStationComponentType, updateValue }) => {
    const [input, setInput] = React.useState('');

    return (
        <div>
            <CoreTextFieldV2
                type="number"
                style={{
                    width: '90px',
                }}
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                value={input}
                onChange={(e) => {
                    const { value } = e.target;
                    if (value && (+value > 1000 || +value < 1)) return;

                    setInput(value);
                }}
            />
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        zIndex: 999,
                    }}
                >
                    <styles.JiraBtn
                        type="button"
                        onClick={() => {
                            setTimeFromPrevStationComponentType(TimeFromPrevStationComponentTypes.Btn);
                        }}
                    >
                        <ClearIcon fontSize="small" htmlColor="#696969" />
                    </styles.JiraBtn>
                    <styles.JiraBtn
                        isDisabled={!input}
                        type="button"
                        onClick={() => {
                            setTimeFromPrevStationComponentType(
                                TimeFromPrevStationComponentTypes.TextDisplay
                            );
                            if (numberHelpers.isNumericString(input)) {
                                updateValue(+input);
                            }
                        }}
                    >
                        <DoneIcon fontSize="small" htmlColor="#696969" />
                    </styles.JiraBtn>
                </div>
            </div>
        </div>
    );
};

export const useTimeFromPrevStation = (args: {
    stationData: ICourseStation;
    setTimeOnForm: (newVal: number) => void;
}) => {
    const { stationData, setTimeOnForm } = args;

    const [timeFromPrevStationComponentType, setTimeFromPrevStationComponentType] = React.useState(
        typeof stationData.timeFromPrev === 'number'
            ? stationData.timeFromPrev
            : TimeFromPrevStationComponentTypes.Btn
    );

    React.useEffect(() => {
        if (isTypeofNumber(stationData.timeFromPrev)) {
            setTimeFromPrevStationComponentType(TimeFromPrevStationComponentTypes.TextDisplay);
        }
    }, [stationData.timeFromPrev]);

    const handleInputTimeBtnClick = () => {
        setTimeFromPrevStationComponentType(TimeFromPrevStationComponentTypes.Input);
    };

    const TimeInput = React.useMemo(() => {
        const Component = () => (
            <TimeInputWithBtns
                setTimeFromPrevStationComponentType={setTimeFromPrevStationComponentType}
                updateValue={setTimeOnForm}
            />
        );
        return Component;
    }, [setTimeOnForm]);

    return { TimeInput, timeFromPrevStationComponentType, handleInputTimeBtnClick };
};
