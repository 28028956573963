import { ChangeEventValue, Coords, fitBounds } from 'google-map-react';
/* eslint-disable no-param-reassign */

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import styles from './styles';
import MapType2 from 'src/components/Map/MapType2/MapType2';
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';
import React, { useState, ReactChild, useEffect, useRef, useMemo } from 'react';
import { setIsFloatMapExpandedAction } from 'src/store/slices/lines/linesSlice';
import { useFormContext } from 'react-hook-form';

import useDirectionsApiLines, {
    isAllObjectsContainsCoords,
} from 'src/screens/Main/hooks/useDirectionsApiLines';
import { mapHelpers } from 'src/screens/CoursesBuilding/components/CourseForm/utils/common';
import {
    DistanceDisplay,
    DurationDisplayWindow,
} from 'src/screens/CoursesBuilding/components/CourseForm/CourseBuildingMap/windows';
import { IOptimumRouteData, IOptimumWaypoint } from '../../api/types';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';
import { getRoutesByIds } from '../../helpers';
import useSimulationDirections, { getColorByRoute } from '../../hooks/useSimulationDirections';
import { uuid } from 'src/utilis/utilis';
import CustomNumberedMarker from 'src/components/CustomNumberedMarker';
import ReactDOMServer from 'react-dom/server';

export const defaultCenter: Coords = {
    lat: 31.896997821344108,
    lng: 34.80000972747803,
};

const SimulationWidgetMap: React.FunctionComponent<{}> = () => {
    const {
        ui: {
            optimizationSimulationScreen: { isOpen, routesSelectedIdsWithColor },
        },
        data: {
            getSimulationResult: { data: simulationResult },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const getSvgUrl = (index: number, route: IOptimumRouteData) => {
        const startPath = 'data:image/svg+xml;charset=UTF-8,';
        const routeColor = getColorByRoute(route, routesSelectedIdsWithColor);
        console.log('routeColor', routeColor);
        const svgString = ReactDOMServer.renderToString(
            <CustomNumberedMarker markerIndex={index} markerColor={routeColor} textColor="white" />
        );
        const result = startPath + svgString;

        return result;
    };

    console.log('map rendered');

    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>(defaultCenter);
    const { rendererProps } = useSimulationDirections();

    const myRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    const selectedRoutes = useMemo(
        () =>
            simulationResult
                ? getRoutesByIds(routesSelectedIdsWithColor, simulationResult.optimumRouteResult)
                : null,
        [routesSelectedIdsWithColor]
    );

    React.useEffect(() => {
        const allWayPointsSelected: IOptimumWaypoint[] = [];

        if (selectedRoutes) {
            selectedRoutes.forEach((route) => {
                allWayPointsSelected.push(...route.optimumRoute);
            });
        }

        if (allWayPointsSelected.length > 1 && selectedRoutes && myRef.current) {
            // Calculate the bounding box of the selected waypoints

            const bounds = allWayPointsSelected.reduce((acc, { lat, lng }) => {
                const latLng = new google.maps.LatLng(lat, lng);
                const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                return acc.extend(coords);
            }, new google.maps.LatLngBounds());

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const neCoords: Coords = {
                lat: ne.lat(),
                lng: ne.lng(),
            };

            const swCoords: Coords = {
                lat: sw.lat(),
                lng: sw.lng(),
            };

            const rect = myRef.current.getBoundingClientRect();

            const fittedBounds = fitBounds(
                { ne: neCoords, sw: swCoords },
                { width: rect.width, height: rect.height }
            );

            setCenter(fittedBounds.center);
            setZoom(fittedBounds.zoom - 1);
        }
    }, [routesSelectedIdsWithColor]);

    return (
        <styles.Container className="Container">
            <styles.MapDiv className="MapDiv" ref={myRef} isOpen>
                <MapType2 GoogleMapProps={GoogleMapProps}>
                    {rendererProps.map((rendererProp) => {
                        // todo
                        return <DirectionsRenderer {...rendererProp} key={uuid()} />;
                    })}

                    {simulationResult &&
                        selectedRoutes &&
                        selectedRoutes.map((route) => {
                            const routeLength = route.optimumRoute.length;

                            return route.optimumRoute.map(
                                (waypoint: IOptimumWaypoint, index: number) =>
                                    waypoint &&
                                    waypoint.lat &&
                                    waypoint.lng && (
                                        <Marker
                                            icon={{
                                                url:
                                                    routeLength - 1 !== index
                                                        ? getSvgUrl(index, route)
                                                        : mapHelpers.getIconUrl(0, true),
                                                scaledSize: new window.google.maps.Size(30, 30),
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(15, 25),
                                            }}
                                            key={uuid()}
                                            position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                        />
                                    )
                            );
                        })}
                </MapType2>
            </styles.MapDiv>
        </styles.Container>
    );
};

export default SimulationWidgetMap;
