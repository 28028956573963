import React from 'react';
import DropDownV2 from 'src/components/DropDown/DropDownV2';
import { FillerValues } from 'src/constants/misc';

import useCommons from 'src/hooks/common/useCommons';
import { buildCarTypeMenuItems } from 'src/screens/Main/components/AddLineForm/components/formSections/hooks.FullForm';
import { useRootAppSelector } from 'src/store/hooks';
import { updateSettingBoxByIdAction } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { carTypesLstSelector } from 'src/store/slices/lines/linesSlice';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';

const CarTypeDropdown: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();

    const displayableCarTypesLst = useRootAppSelector(carTypesLstSelector);
    const [hiddenCarTypeCode, setHiddenCarTypeCode] = React.useState<string>('');

    const menuItems = React.useMemo(() => {
        const items = buildCarTypeMenuItems(displayableCarTypesLst || []);
        const selectedCarCode = boxData.carTypeCode;

        const pushProviderCarMenuItem = (value: string) => {
            items.push({
                value,
                name: 'סוג רכב הוזן ע"י הספק',
            });
        };

        // add custom menu item for car types that aren't in the car types list from WS
        if (
            selectedCarCode &&
            // DONT have car with same id
            !displayableCarTypesLst.some(
                (displayableCar) => String(selectedCarCode) === String(displayableCar.id)
            ) &&
            selectedCarCode !== FillerValues.Without // not a filler value
        ) {
            setHiddenCarTypeCode(selectedCarCode); // For future use
            pushProviderCarMenuItem(selectedCarCode);
        } else if (hiddenCarTypeCode && !items.some((item) => item.value === hiddenCarTypeCode)) {
            pushProviderCarMenuItem(hiddenCarTypeCode);
        }

        return items;
    }, [boxData.carTypeCode, displayableCarTypesLst, hiddenCarTypeCode]);

    const shrink = !!boxData.carTypeCode;

    return (
        <>
            <DropDownV2
                {...{
                    labelName: t('wantedCarType'),
                    fullWidth: true,
                    formControlProp: {
                        style: { width: '100%' },
                        variant: 'outlined',
                        size: 'small',
                    },
                    selectProps: {
                        menuItems,
                        notched: shrink,
                        id: 'car-type-dropdown-select',
                        onChange: (event: any) => {
                            const carTypeCode = String(event.target.value) || '';
                            dispatch(updateSettingBoxByIdAction({ ...boxData, carTypeCode }));
                        },
                        value: boxData.carTypeCode,
                    } as any,
                    inputLabelProps: {
                        shrink,
                    },
                }}
            />
        </>
    );
};

export default CarTypeDropdown;
