import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getAsTemplateStrArr as asTSR } from 'src/utilis/utilis';

type Props = {
    onClick: Function;
    isOpen?: boolean;
};

function ShowSectionBtn({ onClick, isOpen }: Props) {
    const { t } = useTranslation();

    return (
        <div style={{}}>
            <IconButton
                onClick={() => onClick()}
                style={{
                    background: '#FFEDB1',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '60px',
                    width: '176px',
                    height: '33px',
                    position: 'absolute',
                    bottom: '100px',
                    left: '37%',
                    zIndex: 2,
                }}
            >
                <div
                    style={{
                        color: '#242424',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Rubik',
                    }}
                >
                    <span>{t('displayExtraDetails')}</span>
                    <KeyboardArrowDownIcon />
                </div>
            </IconButton>
        </div>
    );
}

export default ShowSectionBtn;
