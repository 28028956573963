import { ReportNames } from 'src/store/slices/reports/types';

const REPORTS_WITH_DATE_RANGE: ReportNames[] = [
    ReportNames.shortOrdersReport,
    ReportNames.visasReport,
    ReportNames.detailedOrdersReport,
];

const reportsScreenConstants = { REPORTS_WITH_DATE_RANGE };

export default reportsScreenConstants;
