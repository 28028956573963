import { useSelector } from 'react-redux';
import useCommons from 'src/hooks/common/useCommons';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    optimizationsRootSelector,
    setIsCsvLoadDoneModalOpenAction,
    setIsGeneralLoadingAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { setServerOptimizationWaypoints } from '../api/optimizationApi';
import { WsResponse } from 'src/api/types';
import { IOptimizationCsvWaypoint, IOptimizationWaypoint } from '../api/types';
import { useGetOptimizationWaypoints } from './useGetOptimizationWaypoints';

export const useSetOptimizationWaypoints = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const formWaypointsList = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypoints
    );
    const isEditForm = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form.isEditForm);
    const getOptimizationWaypointsFromServer = useGetOptimizationWaypoints();
    const csvWaypointsObject = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.csvWaypointsObject
    );

    const {
        ui: { clientCode },
        data: {
            getWaypointsFromServer: { timeDelta, data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();

    const handleSetOptimizationWaypointsResponse = async (
        res: WsResponse<IOptimizationWaypoint[]>,
        isCsv: boolean,
        waypointsFromTarget: IOptimizationWaypoint[] | null | undefined
    ) => {
        const responseNum = Number(res.response);

        const isFromTarget = waypointsFromTarget ? true : false;

        dispatch(setIsGeneralLoadingAction({ isLoading: false }));

        if (responseNum === 0) {
            if (isEditForm) {
                dispatchAlert('success', t('editWaypointSuccess'));
            } else if (isCsv) {
                dispatch(setIsCsvLoadDoneModalOpenAction({ isOpen: true }));
            } else if (waypointsFromTarget) {
                dispatchAlert('success', t('waypointSetAsTargetSuccessfully'));
            } else {
                dispatchAlert('success', t('addWaypointsSuccess'));
            }

            getOptimizationWaypointsFromServer.getOptimizationWaypoints(isFromTarget);
        } else if (responseNum === 1) {
            dispatchAlert('error', t('errorReceived'));
        }
    };

    const getWaypointsToSend = (
        waypointsFromTarget: IOptimizationWaypoint[] | undefined | null,
        csvWaypoints: IOptimizationCsvWaypoint[] | undefined
    ) => {
        if (waypointsFromTarget) return waypointsFromTarget;
        if (csvWaypoints) return csvWaypoints;
        return formWaypointsList;
    };

    const setOptimizationWaypoints = async (
        waypointsFromTarget?: IOptimizationWaypoint[] | null,
        csvWaypoints?: IOptimizationCsvWaypoint[]
    ) => {
        try {
            const isCsv = csvWaypoints ? true : false;

            const waypointsToSend = getWaypointsToSend(waypointsFromTarget, csvWaypoints);

            if (selectedFcAccount && selectedFcAccount.dbUrl && clientCode && (formWaypointsList || isCsv)) {
                const res = await setServerOptimizationWaypoints({
                    dbUrl: selectedFcAccount?.dbUrl,
                    requestParams: {
                        token,
                        jsonData: JSON.stringify(waypointsToSend),
                        isUpdate: isEditForm,
                        clientCode,
                    },
                });

                if (res && res.data) {
                    handleSetOptimizationWaypointsResponse(res.data, isCsv, waypointsFromTarget);
                } else {
                    dispatchAlert('error', t('errorReceived'));
                }
            }
        } catch (error) {
            dispatchAlert('error', t('errorReceived'));
            console.log('error');
        }
    };

    return {
        setOptimizationWaypoints,
    };
};
