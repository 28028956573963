import { Slide } from '@mui/material';
import React, { FC } from 'react';

import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import { StyledCompatibilityWrapper } from '../../styles/styledComponents';
import FormBody from './FormBody';
import FormHeader from './FormHeader';
import useBtnsConfig from './hooks/useBtnsConfig';

interface Props {}

const SettingForm: FC<Props> = React.forwardRef((props, ref) => {
    const { onSubmit, onCancel, formChanged } = useBtnsConfig({});

    return (
        <StyledCompatibilityWrapper
            ref={ref as React.RefObject<HTMLDivElement>}
            className="StyledCompatibilityWrapper"
        >
            <WidgetFormWrapper
                btnsConfig={{
                    onSubmit,
                    onCancel,
                    disabled: !formChanged,
                }}
                HeaderComponent={FormHeader as () => JSX.Element}
            >
                <FormBody />
            </WidgetFormWrapper>
        </StyledCompatibilityWrapper>
    );
});

export default SettingForm;
