const deg2rad = (number: number) => {
    return (number * Math.PI) / 180;
};
// Function to calculate the distance between two coordinates
export const getDistanceBetweenCoordinates = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
): number | null => {
    try {
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1); // deg2rad below
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to get the coordinates on the center of two coordinates
export const getCenterCoordinates = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    try {
        const cLat = (lat1 + lat2) / 2;
        const cLng = (lon1 + lon2) / 2;
        return { lat: cLat, lng: cLng };
    } catch (error) {
        console.error(error);
        return null;
    }
};

const distanceHelpers = {
    deg2rad,
    getDistanceBetweenCoordinates,
    getCenterCoordinates,
};

export default distanceHelpers;
