/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function ExpandIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2_5990)">
                <rect x="4" y="2" width="32" height="32" rx="4" fill="#2196F3" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0041 20.9959C16.8748 20.8666 16.6994 20.7939 16.5166 20.7939C16.3337 20.7939 16.1583 20.8666 16.029 20.9959L10.3793 26.6455V22.8276C10.3793 22.6447 10.3067 22.4693 10.1773 22.3399C10.048 22.2106 9.87256 22.1379 9.68966 22.1379C9.50675 22.1379 9.33133 22.2106 9.202 22.3399C9.07266 22.4693 9 22.6447 9 22.8276V28.3103C9 28.4933 9.07266 28.6687 9.202 28.798C9.33133 28.9273 9.50675 29 9.68966 29H15.1724C15.3553 29 15.5307 28.9273 15.6601 28.798C15.7894 28.6687 15.8621 28.4933 15.8621 28.3103C15.8621 28.1274 15.7894 27.952 15.6601 27.8227C15.5307 27.6933 15.3553 27.6207 15.1724 27.6207H11.3545L17.0041 21.971C17.1334 21.8417 17.2061 21.6663 17.2061 21.4834C17.2061 21.3006 17.1334 21.1252 17.0041 20.9959ZM22.9959 15.0041C23.1252 15.1334 23.3006 15.2061 23.4834 15.2061C23.6663 15.2061 23.8417 15.1334 23.971 15.0041L29.6207 9.35448V13.1724C29.6207 13.3553 29.6933 13.5307 29.8227 13.6601C29.952 13.7894 30.1274 13.8621 30.3103 13.8621C30.4933 13.8621 30.6687 13.7894 30.798 13.6601C30.9273 13.5307 31 13.3553 31 13.1724V7.68966C31 7.50675 30.9273 7.33133 30.798 7.202C30.6687 7.07266 30.4933 7 30.3103 7H24.8276C24.6447 7 24.4693 7.07266 24.3399 7.202C24.2106 7.33133 24.1379 7.50675 24.1379 7.68966C24.1379 7.87256 24.2106 8.04798 24.3399 8.17731C24.4693 8.30665 24.6447 8.37931 24.8276 8.37931H28.6455L22.9959 14.029C22.8666 14.1583 22.7939 14.3337 22.7939 14.5166C22.7939 14.6994 22.8666 14.8748 22.9959 15.0041Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_2_5990"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_5990" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2_5990"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default ExpandIcon;
