import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Container = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40%;
`;

const Card = styled(Button)`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 610px;
    height: 72px;
    & .MuiButton-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;

const TitleContianer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10%;
`;

const Title = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
`;

export default { Container, Text, CardsContainer, Card, Title, TitleContianer };
