import React, { useEffect } from 'react';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import { getMapsAPIKey, updateGoogleApiCounter } from 'src/utilis/utilis';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import styled, { CSSObject, css } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { centeredFlexItem } from 'src/style/styledCSS';
import { useRootAppSelector } from 'src/store/hooks';
import useCommons from 'src/hooks/common/useCommons';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from 'src/store/slices/common/commonSlice';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

export const StyledMapWindow = styled.div<{ style?: CSSObject }>`
    background: #000000b5;
    border-radius: 3px;
    position: absolute;
    padding: 8px;
    display: flex;
    z-index: 10;
    color: white;
    ${({ style = {} }) => css(style)};
`;

export const MapWindow: React.FC<{ style?: CSSObject }> = ({ children, style = {} }) => {
    return <StyledMapWindow style={style}>{children}</StyledMapWindow>;
};

// Artificial delay for loading the map
const useLoadingDelay = () => {
    const [delay, setDelay] = React.useState(true);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setDelay(false);
        }, 800);
        return () => clearTimeout(timeout);
    }, []);

    return delay;
};

const Loader = () => {
    return (
        <div style={{ ...centeredFlexItem, backgroundColor: '#f2f3f5', zIndex: 333 }}>
            <CircularProgress
                sx={{
                    color: '#2196F3',
                }}
            />
        </div>
    );
};

interface Props {
    // options?: google.maps.MapOptions;
    GoogleMapProps?: React.ComponentProps<typeof GoogleMap>;
}

export const myStyles: google.maps.MapTypeStyle[] = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
];

const MapType2: React.FC<Props> = (props) => {
    const { children, GoogleMapProps = {} } = props;

    const googleCounterUp = useGoogleApiCounterUp();

    useEffect(() => {
        googleCounterUp.googleApiCounterUp();
    }, []);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: getMapsAPIKey(),
    });

    const delay = useLoadingDelay();

    if (!isLoaded) {
        return <Loader />;
    }

    if (loadError) {
        console.log('LoadError', loadError);
    }

    const { zoom, center, options, ...restProps } = GoogleMapProps;
    return (
        <>
            {delay ? <Loader /> : null}
            <GoogleMap
                mapContainerStyle={delay ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                center={GoogleMapProps.center || defaultCenter}
                zoom={GoogleMapProps.zoom || 8}
                options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    styles: myStyles,
                    ...GoogleMapProps.options,
                }}
                {...restProps}
            >
                {/* Child components, such as markers, info windows, etc. */}
                {children}
            </GoogleMap>
        </>
    );
};

export default MapType2;
