/* eslint-disable react/button-has-type */
import '@progress/kendo-theme-default/dist/all.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import './App.css';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import defaultTheme from './style/themes';
import './style/font.css';

import i18n from './i18n/index';

import storeObj from './store/index';
import MainApp from './MainApp';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';

const queryClient = new QueryClient();

function App(): JSX.Element {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
                window.location.reload();
            }}
        >
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={defaultTheme}>
                    <I18nextProvider i18n={i18n}>
                        <Provider store={storeObj.store}>
                            <BrowserRouter>
                                <div className="App" style={{ maxHeight: '100%' }}>
                                    <MainApp />
                                </div>
                            </BrowserRouter>
                        </Provider>
                    </I18nextProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
