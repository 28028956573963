/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useState, useCallback, FunctionComponent, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { State, SortDescriptor, CompositeFilterDescriptor, process } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import KendoGrid, { KendoColumnProps, TableProp } from 'src/components/KendoGrid/KendoGrid';
import moment from 'moment';
import {
    deepClone,
    getAsTemplateStrArr as asTSR,
    getDatesBetween,
    removeDuplicates,
} from 'src/utilis/utilis';
import EmplyeeColumn from 'src/components/KendoGridCutomeColumn/EmplyeeColumn';
import {
    setSelectedPassenger,
    setSelectedAllPassengers,
    onFormShowChange,
} from 'src/store/actions/PassengersShiftActionType';
import { IPassengerShifts, IShifts } from 'src/types/manualOrders/api.types';
import { IRootReducer } from 'src/store/reducers';
import { editShiftSelector, passengerSelector } from 'src/store/selectores/passengerShiftsSelectores';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import {
    displayPlacementFormAction,
    passengerIdToIndexDictSelector,
    selectedPassengersIdsSelector,
    selectedPassengersSelector,
    setSelectedPassengersAction,
    uiSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import useDisableDelay from 'src/hooks/useDelayV2';
import { PASSENGER_ID_INDEX, PASSENGER_LOCATION_INDEX } from 'src/store/slices/manualOrders/helpers';
import { getSelectedDaysRange } from '../utilis';
import OutlinedBtn from '../../../components/buttons/OutlinedBtn/OutlinedBtn';
import Shiftcell from './Shiftcell/Shiftcell';
import manualOrdersHooks from '../hook/hooks.manualOrdersTable';
import { manualOrdersCommonHooks } from '../hook/manualOrdersHooks.common';
import EmployeeColHeaderCell, { Options } from './EmployeeColHeaderCell';

const DATA_ITEM_KEY = 'passId';
const SELECTED_FIELD = 'isSelected';
const idGetter = getter(DATA_ITEM_KEY);

interface IProps {
    startDate: Date;
    endDate: Date | undefined;
    data: IPassengerShifts[];
    compositeFilters: CompositeFilterDescriptor | undefined;
    shifts: IShifts[];
}

const pageSize = 30;

const usePassengersSelection = () => {
    const dispatch = useAppDispatch();

    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector);

    const addToSelectedPassengers = useCallback(
        (passIds: string[]): void => {
            const updatedSelectedPassengers: string[] = [...selectedPassengersIds];

            passIds.forEach((passId) => {
                updatedSelectedPassengers.push(passId);
            });

            dispatch(setSelectedPassengersAction(removeDuplicates(updatedSelectedPassengers)));
        },
        [dispatch, selectedPassengersIds]
    );

    const removeFromSelectedPassengers = useCallback(
        (passIds: string[]): void => {
            const updatedSelectedPassengers: string[] = selectedPassengersIds.filter(
                (sid) => !passIds.includes(sid)
            );

            dispatch(setSelectedPassengersAction(removeDuplicates(updatedSelectedPassengers)));
        },
        [dispatch, selectedPassengersIds]
    );

    const setAllAsSelected = useCallback(
        (passIds: string[]) => {
            const updatedSelectedPassengers: string[] = [];

            passIds.forEach((passId) => {
                updatedSelectedPassengers.push(passId);
            });

            dispatch(setSelectedPassengersAction(removeDuplicates(updatedSelectedPassengers)));
        },
        [dispatch]
    );

    const isSelected = useCallback(
        (passId: string): boolean => {
            return selectedPassengersIds.some((pid) => pid === passId);
        },
        [selectedPassengersIds]
    );

    const syncSelectedToFiltered = useCallback(
        (filteredData: IPassengerShifts[]) => {
            const updatedSelectedPassengers: string[] = [];

            const filteredPassengersIds = filteredData.map((p) => p.passId);
            selectedPassengersIds.forEach((pid) => {
                if (filteredPassengersIds.includes(pid)) {
                    updatedSelectedPassengers.push(pid);
                }
            });

            dispatch(setSelectedPassengersAction(updatedSelectedPassengers));
        },
        [dispatch, selectedPassengersIds]
    );

    return {
        isSelected,
        addToSelectedPassengers,
        removeFromSelectedPassengers,
        setAllAsSelected,
        syncSelectedToFiltered,
    };
};

const ManualOrderTable: FunctionComponent<IProps> = ({
    data,
    startDate,
    endDate,
    compositeFilters,
    shifts = [],
}: IProps) => {
    // console.log('ManualOrderTable');

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [take, setTake] = useState<number>(pageSize);
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector);
    const selectedSort = useRootAppSelector((state) => uiSelector(state).selectedEmployeeColSort);

    const columns = useMemo<Array<KendoColumnProps>>(() => {
        const dates = getDatesBetween(startDate, endDate || startDate, true);
        const result: Array<KendoColumnProps> = [
            {
                field: SELECTED_FIELD,
                headerSelectionValue: data.length > 0 && data.length === selectedPassengersIds.length,
                className: 'isSelected-col',
                width: 30,
            },
            {
                field: 'fullName',
                title: 'רשימת נוסעים',
                editor: 'text',
                filter: 'text',
                width: (dates.length === 1 && '150') || 200 || undefined,
                cell: EmplyeeColumn,
                headerCell: EmployeeColHeaderCell,
                sortable: false,
                minResizableWidth: 50,
            },
        ];
        dates.forEach((date) => {
            const dayName: string = moment(date).format('dddd').toLowerCase();

            result.push({
                field: moment(date).format('DD.MM.YY'),
                title: `${t(asTSR(dayName))} ${moment(date).format('DD.MM.YY')}`,
                editor: 'numeric',
                sortable: false,
                cell: (props) => <Shiftcell {...props} shifts={shifts} />,
            });
        });

        return result;
    }, [startDate, endDate, data.length, selectedPassengersIds.length, t, shifts]);

    const dataState: State = {
        take,
        skip,
        sort,
        filter: compositeFilters,
    };

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setTake(event.dataState?.take || pageSize);
            setSkip(event.dataState?.skip || 0);
            setSort(event.dataState?.sort || []);
        },
        [setTake, setSkip, setSort]
    );

    const editedShift = useSelector((state: IRootReducer) => editShiftSelector(state));
    const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));

    const {
        addToSelectedPassengers,
        removeFromSelectedPassengers,
        setAllAsSelected,
        syncSelectedToFiltered,
    } = usePassengersSelection();

    // decided to remove sync with the selected rows with filters, if necessary to sync just remove from comment below

    // useEffect(() => {
    //     syncSelectedToFiltered(data);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data]);

    const tkDispatch = useAppDispatch();

    const onSelectionChange = useCallback(
        (e: GridSelectionChangeEvent) => {
            const isSelected = e.nativeEvent.target.checked;
            const pass: IPassengerShifts = e.dataItem;
            const dateRange = getSelectedDaysRange(startDate, endDate);
            // -- closing placement form if selecting passenger while...
            // -- editing shift
            if (editedShift) {
                dispatch(onFormShowChange(false));
                tkDispatch(displayPlacementFormAction(false));

                // const editedPassengerFullData = getPassengerDataById(editedShift.passId);

                // -- unselecting passenger whose shift is being edited
                removeFromSelectedPassengers([editedShift.passId]);
            }

            if (!pass) return;

            if (isSelected) {
                addToSelectedPassengers([pass.passId]);
            } else {
                removeFromSelectedPassengers([pass.passId]);
            }
        },
        [
            addToSelectedPassengers,
            dispatch,
            editedShift,
            endDate,
            removeFromSelectedPassengers,
            startDate,
            tkDispatch,
        ]
    );

    const onHeaderSelectionChange = useCallback(
        (e: GridHeaderSelectionChangeEvent) => {
            const isSelected = e.nativeEvent.target.checked;

            const filteredPassengersIds = data.map((passenger) => passenger.passId);

            if (isSelected) {
                setAllAsSelected(filteredPassengersIds);
            } else {
                setAllAsSelected([]);
            }
        },
        [data, setAllAsSelected]
    );

    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        const sortData = (unsorted: IPassengerShifts[]) => {
            // Avoiding unnecceray sort
            if (!selectedSort) return data;

            // Avoiding changing original array
            const copy = [...unsorted];

            copy.sort((a, b) => {
                if (selectedSort === Options.CityAsc) {
                    return a.city < b.city ? -1 : 1;
                }
                if (selectedSort === Options.CityDesc) {
                    return b.city < a.city ? -1 : 1;
                }
                if (selectedSort === Options.NameAsc) {
                    return a.fullName < b.fullName ? -1 : 1;
                }
                if (selectedSort === Options.NameDesc) {
                    return b.fullName < a.fullName ? -1 : 1;
                }
                if (selectedSort === Options.DepartmentAsc) {
                    return a.departmentName < b.departmentName ? -1 : 1;
                }
                // Only value left is DepartmentDesc
                return b.departmentName < a.departmentName ? -1 : 1;
            });
            return copy;
        };

        setSortedData(sortData(data));
    }, [selectedSort, data.length, data]);

    const processedData = useMemo(
        () =>
            process(
                sortedData.map((currPassenger) => {
                    return {
                        ...currPassenger,
                        isSelected: selectedPassengers.some(
                            (sp) => sp[PASSENGER_ID_INDEX] === currPassenger.passId
                        ),
                    };
                }),
                dataState
            ),
        [dataState, selectedPassengers, sortedData]
    );
    // const loadingPassengersStatus = useSelector(
    //    (state: IRootReducer) => state.passengersShiftReudcer.loadingPassengers,
    // );

    const tableProp: TableProp = useMemo(
        () => ({
            columns,
            data: processedData,
            total: processedData.total,
            className: 'k-rtl',
            style: {
                height: '100%',
                width: '100%',
                borderRadius: '0.3em',
                maxHeight: '74vh',
                // pointerEvents: isFormOpen ? 'none' : 'auto',
            },
            wrapperDivStyle: {
                height: '100%',
                width: '100%',
            },
            dataItemKey: DATA_ITEM_KEY,
            rowHeight: 80,
            selectedField: SELECTED_FIELD,
            selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: 'multiple',
            },
            navigatable: false,
            pageSize,
            skip,
            sort,
            sortable: true,
            resizable: false,
            reorderable: false,
            scrollable: 'virtual',
            onDataStateChange,
            onSelectionChange,
            onHeaderSelectionChange,
        }),
        [columns, onDataStateChange, onHeaderSelectionChange, onSelectionChange, processedData, skip, sort]
    );

    // ------------------------------------------------------------------

    const { DataIsLoadingSpinner, containerStyle, showSpinner } = manualOrdersHooks.useDataLoadingSpinner(
        allPassengers.length
    );

    return (
        <div style={containerStyle}>
            <KendoGrid {...tableProp} />
            {showSpinner && <DataIsLoadingSpinner />}
        </div>
    );
};

export default ManualOrderTable;
