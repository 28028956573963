/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useLayoutEffect } from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
    StylesProvider,
    jssPreset,
    ThemeProvider,
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const rtlTheme = createMuiTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#f44336',
        },
    },
});

// interface Prop {
//    children: React.ReactNode;
// }

function RTL({ children }) {
    useLayoutEffect(() => {
        document.body.setAttribute('dir', 'rtl');
    }, []);
    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={rtlTheme}>{children}</ThemeProvider>
        </StylesProvider>
    );
}

export default RTL;
