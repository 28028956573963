import { FieldError, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import * as yup from 'yup';
import { TripDirections } from 'src/types/line';
import { addressSchema, passengerSchema, schema, stationSchema } from './schema';
import { ParsedCourseStation } from 'src/types/lines/api/types';

export type ValidationError = {
    [key: string]: FieldError | undefined;
};

// -- Form inputs
// * full form
export type AddressInput = yup.InferType<typeof addressSchema>;
export type StationSch = yup.InferType<typeof stationSchema>;
export interface StationInput extends StationSch {
    passengers: string[];
    lat?: number;
    lng?: number;
    timing?: number | undefined;
    distanceFromPrevStation?: number | undefined;
}

export interface AddressInputWithCoords extends AddressInput {
    lat?: number;
    lng?: number;
    timing?: number;
    distanceFromPrevStation?: number | undefined;
}

export const inputNamesArr = [
    'clientCode',
    'lineRemarks',
    'date',
    'startTime',
    'startAddress',
    'endAddress',
    'invContactName',
    'invContactPhone',
    'clientOrderCode',
    'invContactPhonePrefix',
    'flightNum',
    'passQty',
    'carId',
    'tripDirection',
    'endTime',
    'returnTripEndTime',
    'returnTripStartTime',
    'courseCode',
    'departmentCode',
    'linePrice',
    'description',
] as const;

export interface Inputs {
    startAddress: AddressInputWithCoords;
    endAddress: AddressInputWithCoords;
    lineRemarks: string;
    date: Date;
    startTime: string;
    clientCode: number;
    clientOrderCode: number;
    flightNum: string;
    invContactName: string;
    invContactPhone: string;
    invContactPhonePrefix: string;
    passQty: number | null;
    carId: string | null;
    tripDirection: TripDirections;
    endTime: string;
    returnTripEndTime: string;
    returnTripStartTime: string;
    courseCode: string;
    departmentCode: string;
    linePrice: number | null;
    description: string | null;
}

export type InputNames = (typeof inputNamesArr)[number];

// * new passenger form
export type PassengerInput = yup.InferType<typeof passengerSchema>;
export type PassengerInputNames = keyof PassengerInput;

export type FormSchema = yup.InferType<typeof schema>;

// -- Props
// * Common form
export interface InputProps<T extends FieldValues> {
    errors: FieldErrors;
    register: UseFormRegister<T>;
}

// -- configs
// * Full form
export interface MainFormInputConfig extends InputProps<Inputs> {
    name: InputNames;
    type?: string;
    required?: boolean;
}
/**
 * A generic input configuration object.
 * @param {T} T - Inputs names interface
 * @param {P} P - Inputs interface.
 * @param {string} [type="text"] - The type of input.
 * @param {boolean} [required=false] - Whether or not the input is required.
 */
export interface GenericInputConfig<T, P extends FieldValues> extends InputProps<P> {
    name: T;
    type?: string;
    required?: boolean;
}
// * Station passengers
export interface PassengersFormInputConfig extends InputProps<PassengerInput> {
    name: PassengerInputNames;
    type?: string;
    required?: boolean;
}

// -- redux
export interface StationAddress {
    city: string;
    street: string | undefined;
    houseNum: string | undefined;
    placeName?: string | undefined;
    lat?: number | undefined;
    lng?: number | undefined;
    timing?: number | undefined;
    distanceFromPrevStation?: number | undefined;
}
export interface StationPayload extends StationAddress {
    stationId: string;
}

// -- common
export interface NewPassenger extends PassengerInput {
    passengerId: string;
}
export interface Station extends StationInput {
    stationId: string;
    isFromImportedCourse?: boolean;
    isFromPassenger?: boolean;
}
export enum StationsErrorMessages {
    StationNotFound = 'שגיאה במציאת תחנה - אנא נסע לרענן את העמוד',
}

export interface CourseLocations {
    stations: ParsedCourseStation[];
    startAddress: ParsedCourseStation | null;
    endAddress: ParsedCourseStation | null;
}
export interface BtnsConfigArgs {
    handleSubmit: (e?: React.MouseEvent<any>) => void;
    onCancel: () => void;
    isDirty?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    isSubmitting?: boolean;
}

export type FormTypes =
    | 'newPassenger'
    | 'editingPassenger'
    | 'fullForm'
    | 'addingPassengersToStation'
    | 'creatingStationsFromPassengers'
    | 'storedCourses';
// create Station object from PassengerInputs

// -- Api
export interface ExistingPassenger {
    addressCode: string;
    passCode: string;
    internalCode: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    remarks: string;
    city: string;
    street: string;
    houseNum: string;
    email: string;
    isActive?: 1;
}
