import React, { CSSProperties } from 'react';
import styles from 'src/screens/RideSettings/styles/styledComponents';
import { largeRubik } from 'src/style/styledCSS';

import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionProps, AccordionSummary, styled, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { css } from 'styled-components';
import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import { colors } from 'src/style/themes/defaultTheme';
import StationPassengersTable from './StationPassengersTable';

type Props = {
    passengers: ExistingPassenger[];
    stationId: string;
    actionsAreDisabled: boolean;
};

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `none`,
        backgroundColor: colors.veryLightGray,
    })
);

const summaryParStyle = {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'right',
    color: '#242424',
} as CSSProperties;

const StationPassengersAccordion = ({ passengers, stationId, actionsAreDisabled = false }: Props) => {
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary
                disabled={false}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div>
                    <p style={summaryParStyle}>{`${passengers.length} ${t('passengersOnThisStations')}`}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
                <StationPassengersTable
                    stationId={stationId}
                    passengers={passengers}
                    actionsAreDisabled={actionsAreDisabled}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default StationPassengersAccordion;
