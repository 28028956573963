import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import SortIcon from '@mui/icons-material/Sort';
import MenuItem from '@mui/material/MenuItem';
import { ErrorMessagesOld } from 'src/store/type';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { setEmployeeColSortAction, uiSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { MenuList } from '@mui/material';
import { getAsTemplateStrArr } from 'src/utilis/utilis';

export enum Options {
    CityAsc = 'CITY_ASC',
    CityDesc = 'CITY_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    DepartmentAsc = 'DEPARTMENT_ASC',
    DepartmentDesc = 'DEPARTMENT_DESC',
}

const options = [
    { name: 'city', value: Options.CityAsc, dir: 'asc' },
    { name: 'city', value: Options.CityDesc, dir: 'desc' },
    { name: 'passengerName', value: Options.NameAsc, dir: 'asc' },
    { name: 'passengerName', value: Options.NameDesc, dir: 'desc' },
    { name: 'department', value: Options.DepartmentAsc, dir: 'asc' },
    { name: 'department', value: Options.DepartmentDesc, dir: 'desc' },
];

const ITEM_HEIGHT = 48;
type Props = {};

function LongMenu(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const [selectedSort, setSelectedSort] = useState<null | Options>();
    const selectedSort = useRootAppSelector((state) => uiSelector(state).selectedEmployeeColSort);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (e: any, value: Options) => {
        handleClose();
        dispatch(setEmployeeColSortAction(value));
    };

    return (
        <div style={{}}>
            <IconButton size="small" id="long-button" onClick={handleClick}>
                <SortIcon fontSize="small" />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            onClick={(e) => handleSelect(e, option.value)}
                            selected={selectedSort === option.value}
                        >
                            <span>{t(getAsTemplateStrArr(option.name))}&nbsp;&nbsp;</span>
                            <span style={{ fontWeight: 'bold' }}>{option.dir === 'asc' ? '⭡' : '⭣'}</span>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

const EmployeeColHeaderCell = (props: Props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
            <p>רשימת נוסעים</p>
            <LongMenu {...props} />
        </div>
    );
};

export default EmployeeColHeaderCell;
