import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import moment from 'moment';
import { KendoColumnProps } from 'src/components/KendoGrid/KendoGrid';
import {
    getAsTemplateStrArr,
    getDatesBetween,
    getStrAsTemplateStringArr,
    stringifyDate,
} from 'src/utilis/utilis';
import { DAY_NAME, DISPLAY_UI } from 'src/constants/dates';

const { width } = window.screen;

const getWidth = (field: string): number | string | undefined => {
    switch (field) {
        case 'description':
            if (width < 1660) {
                if (width > 1420) return 60;
                return 50;
            }
            return 70;
        default:
            break;
    }
};
const [startDate, endDate] = [new Date(), new Date()];
const useWeeklyOrdersCols = (): {
    columns: Array<GridColumnProps>;
} => {
    const { t } = useTranslation();
    const columns = useMemo<Array<KendoColumnProps>>(() => {
        const dates = getDatesBetween(startDate, endDate || startDate);
        const result: Array<KendoColumnProps> = [
            {
                field: 'fullName',
                title: 'רשימת נוסעים',
                editor: 'numeric',
                width: (dates.length === 1 && '200px') || undefined,
                sortable: true,
                minResizableWidth: 50,
            },
        ];

        dates.forEach((date) => {
            const dayName: string = stringifyDate(date, DAY_NAME).toLowerCase();
            result.push({
                field: stringifyDate(date, DISPLAY_UI),
                title: `${t(getAsTemplateStrArr(dayName))} ${stringifyDate(date, DISPLAY_UI)}`,
                editor: 'numeric',
                sortable: false,
                // cell: (props) =>
                //    <WeeklyOrdersCell {...props} ordersTimes={['14:00']} />
            });
        });

        return result;
    }, [t]);

    return { columns };
};

export default useWeeklyOrdersCols;
