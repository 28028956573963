import React, { useState, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import styles from './Code.style';

interface Props {
    onSubmit: (code: string) => void;
    Title: JSX.Element;
    btnText: string;
    isAutoSubmit: boolean;
    // eslint-disable-next-line react/require-default-props
    isInputSecure?: boolean;
    // eslint-disable-next-line react/require-default-props
    isRefresh?: boolean;
}

const Code = ({ onSubmit, Title, btnText, isAutoSubmit, isInputSecure, isRefresh }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');
    const numberOfInput = 4;

    const handleChange = (otp: string) => {
        setCode(otp);

        if (otp.length === numberOfInput && isAutoSubmit) onSubmit(otp);
    };

    const onClickLogIn = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(code);
    };

    useEffect(() => {
        if (isRefresh) {
            setCode('');
        }
    }, [isRefresh]);

    return (
        <styles.Form onSubmit={onClickLogIn}>
            <styles.Center>
                <styles.Div>{Title}</styles.Div>
                <OtpInput
                    isInputSecure={isInputSecure}
                    isInputNum
                    value={code}
                    onChange={handleChange}
                    numInputs={numberOfInput}
                    shouldAutoFocus
                    containerStyle={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        width: '295px',
                    }}
                    inputStyle={{
                        width: '64px',
                        height: '72px',
                        fontSize: '18px',
                    }}
                />
            </styles.Center>

            {!isAutoSubmit && (
                <styles.Button
                    disabled={code.length !== numberOfInput}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                >
                    {btnText}
                </styles.Button>
            )}
        </styles.Form>
    );
};

export default Code;
