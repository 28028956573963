import { AxiosResponse } from 'axios';
import querystring from 'querystring';
import { IGeneralFormSubmitReqConfig } from 'src/types/rideSettings/store/types.sagas';

import instance, { buildEncriptedUrl } from '..';
import { ResponseTypes } from '../types';
import { createPayload } from '../utilis';
import {
    IRideSettingsRequest,
    IGeneralSettingsResponse,
    SubmitFormResponseData,
} from '../../types/rideSettings/api/types.api';

export const fetchGeneralSettings = <TData>({
    proxyUrl,
    dbUrl,
    ...payload
}: IRideSettingsRequest<TData>): Promise<AxiosResponse<IGeneralSettingsResponse>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    // FC REQUEST

    const response = instance.post(
        buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Client_General_Settings'),
        data
    );
    return response;
};

export const fetchSettings = <T, TData>({
    proxyUrl,
    dbUrl,
    endpoint,
    ...payload
}: IRideSettingsRequest<TData>): Promise<AxiosResponse<T>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    const response = instance.post(buildEncriptedUrl(dbUrl, endpoint), data);
    return response;
};

export const submitPermManForm = <TData>({
    submitConfigs,
    proxyUrl,
    dbUrl,
    ...payload
}: IRideSettingsRequest<TData>): Promise<AxiosResponse<SubmitFormResponseData>> => {
    const { token, clientCode } = payload;
    // console.log(submitConfigs);

    if (!submitConfigs) {
        throw new Error('Missing submitConfigs');
    }

    if (!submitConfigs.endpoint) {
        throw new Error('Missing endpoint');
    }

    const rawData = submitConfigs.stringify
        ? {
              [submitConfigs.dataParamName]: JSON.stringify(payload.data),
          }
        : payload.data;

    const processedPayload = { token, clientCode, ...rawData };

    const data = querystring.stringify({
        ...createPayload(processedPayload),
    });

    const response = instance.post(buildEncriptedUrl(dbUrl, submitConfigs.endpoint), data);

    return response;
};

export const generalSettingsSubmitForm = ({
    proxyUrl,
    dbUrl,
    ...payload
}: IGeneralFormSubmitReqConfig): Promise<AxiosResponse<SubmitFormResponseData>> => {
    const params = querystring.stringify({
        ...createPayload(payload.params),
    });

    // FC REQUEST

    const response = instance.post(
        buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Set_Client_General_Settings'),
        params
    );

    return response;
};
