export interface IPassenger {
    code: string;
    passCode: string;
    passId: string;
    fullName: string;
    phone1: string;
    phone2: string;
    orderStation: string;
    time: string;
    city: string;
    street: string;
    house: string;
    actualTime: string;
    remarks?: string;
    departmentCode?: string;
    departmentName?: string;
    onBoard: boolean;
    lat?: number | null;
    lng?: number | null;
}

export interface IEditLinePassenger {
    code: string;
    actualTime: string;
    city: string;
    street: string;
    house: string;
    remarks?: string;
    passQty: number;
    passCode: string;
    passId: string;
    fullName: string;
    phone1: string;
    phone2: string;
    departmentCode?: string;
    departmentName?: string;
    onBoard: boolean;
}

export interface ILine {
    lineCode: number;
    startTime: string | Date;
    endTime: string | Date;
    lineDescription: string;
    lineStatus: number;
    passQty: number;
    visaNumber: string;
    driverName: string;
    carTypeName: string;
    driverCode: number;
    carNumber: string;
    departmentName: string;
    totalKm: string;
    passengers: IPassenger[];
    clientPrice: string;
    accountCode: number;
    departmentCode: string;
    mobilePhone?: string;
    clientName?: string;
    longRemarks?: string;
    invContactName: string;
    invContactPhone: string;
    flightNumber: string;
    clientOrderCode: string;
    isSelected: boolean;
    isActive?: '1' | '0';
    isCreatedByStudio?: '1' | '0';
    createdBy?: string; // empty str or the user name, if from studio will be empty
    creationType?: '1' | '0' | '9' | '2'; // 1 - pickup, 2 - drop, 9 - created by manual / auto placement on FC
}

export interface IEditLine {
    lineDate: string | Date;
    lineStatus: number;
    startTime: string | Date;
    endTime: string | Date;
    lineDescription: string;
    driverName: string;
    carTypeCode: string;
    lineCode: number;
    carTypeName: string;
    carNumber: string;
    passQty: number;
    visaNumber: string;
    departmentCode: string;
    departmentName: string;
    totalKm: string;
    clientPrice: string;
    accountCode: number;
    clientName?: string;
    driverCode: number;
    mobilePhone?: string;
    longRemarks?: string;
    flightNumber: string;
    invContactName: string;
    invContactPhone: string;
    clientOrderCode: string;
    isActive?: '1' | '0';
    stations: IStation[];
}

export interface IStation {
    time: string;
    city: string;
    street: string;
    house: string;
    remarks: string;
    index: string;
    code: string;
    type: string;
    addressIndex?: string;
    stationIndex?: string;
    passengers: IEditLinePassenger[];
    lat?: number;
    lng?: number;
    timing?: number;
    distanceFromPrevStation?: number;
}

export enum GpsResponseEnum {
    currenLoactionExiest = 0, // קיים מיקום נוכחי ויש להציג את המיקום הנוכחי (רכב על המפה) + זמן עדכון אחרון.
    dirverLocationNotExiest = 1, // לא קיים מיקום נוכחי של הנהג
    tripEndedWithCurrentPostion = 2, // הנסיעה הסתיימה (הצגת המסלול של הנהג לאותה נסיעה במדיה והתבצעה איכון, במידה ולא , לא יוצג מסלול )
    driverCureentPostionNotUpdated = 3, // מיקום נוכחי של הנהג אינו מעודכן (רכב על המפה באיכון האחרון) + זמן עדכון אחרון.
    tripEnded = 4, // נסיעה הסתיימה , מיקום הנהג לנסיעה זו אינו זמין יותר
    tripHaventStarted = 5, // נסיעה עדיין לא התחילה. ניתן להציג מיקום רק 10 דקות לפני תחילת הנסיעה.
}

export interface GpsData {
    id: number;
    lt: number;
    lg: number;
    t: Date;
}

export interface CurrentPos {
    lt: number;
    lg: number;
    t: Date;
    s: string;
    h: string;
}

export interface ILocation {
    response: GpsResponseEnum;
    gpsData: GpsData[];
    currentPos: CurrentPos;
}

export enum LineStatus {
    Undifined = 0,
    Ride = 3,
    Ended = 4,
}

export interface IClient {
    accountCode: number;
    clientName: string;
    firstDayOfWeek: number;
    maxMinuteToBlockNewLine: string;
    referEmailList: string;
    languageForAddresses: string;
}

export interface IDepartment {
    code: number;
    departmentName: string;
}

export interface IItem {
    value: string | number;
    name: string | number | any;
}

export interface StrictDropdownItem {
    value: string;
    name: string;
}
export enum TripDirections {
    OneWay = 'oneWay',
    RoundTrip = 'roundTrip',
    Linked = 'linked',
}

export type DropDownItem<P> = P & { value: number; name: string };
