/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import { passengerWillGetMoreThenTwoShiftsOnOneDay } from 'src/screens/ManualOrder/utilis';
import { IRootReducer } from 'src/store/reducers';
import { editShiftSelector, formSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { arrsHaveSomeMatchingElement, isSameDate, stringifyDate } from 'src/utilis/utilis';
import moment, { Moment } from 'moment';
import { blockedDatesSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { ReasonForBlockedDate } from 'src/screens/ManualOrder/types';
import { manualOrdersConstants as constants } from 'src/constants/byScreen/manualOrders/components';
import { TABS } from '../PlacemenTransportation';
import { IEditShift, IPassengerShifts } from '../../../../../types/manualOrders/api.types';
import { DateAvailabilityStatus, IPlacment } from '../types';

const { PassengerHasTwoOrdersOnDate } = ReasonForBlockedDate;

const isValidBySelectability = (placement: IPlacment, datesAvailabilityStatus: DateAvailabilityStatus[]) => {
    const selectedRelativeDates = placement.dates.map((d) => d.relativeDate);
    const blockedDays = datesAvailabilityStatus.map((d) => stringifyDate(d.date));
    const isValid = !arrsHaveSomeMatchingElement(blockedDays, selectedRelativeDates);

    return isValid;
};

const isReplacingOrderOnDayWithMaxOrders = (
    editedShift: IEditShift,
    datesAvailabilityStatus: DateAvailabilityStatus[]
): boolean => {
    const daySelectabilityData = datesAvailabilityStatus.find((d) => isSameDate(d.date, editedShift.date));

    return daySelectabilityData?.reason === PassengerHasTwoOrdersOnDate;
};

export const useIsSubmitDisabled = (selctedTab: TABS, selectedPassengersFullData: IPassengerShifts[]) => {
    const { placment: placement } = usePlacement();

    const editShiftRedux = useSelector((state: IRootReducer) => editShiftSelector(state));

    const datesAvailabilityStatus = useSelector(blockedDatesSelector);

    // const isShiftSelected = useSelector((state: IRootReducer) => formSelector(state).isShiftSelected);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    useEffect(() => {
        const isDisabled = () => {
            // -- did not pick date
            if (placement.dates.length === 0) return true;

            // -- date for some reason is invalid
            if (placement.dates.length === 1 && placement.dates[0].relativeDate === 'Invalid date') {
                return true;
            }

            // -- on auto shift tab but did not pick autoshift
            if (selctedTab === TABS.AUTO && !placement.autoOrder.shiftId) return true;

            // -- on manual shift tab but did not pick manual shift
            const { pickupShiftId } = placement.manualOrder.pickup;
            const { dropShiftId } = placement.manualOrder.drop;

            if (selctedTab === TABS.MANUAL && !pickupShiftId && !placement.manualOrder.drop.dropTime)
                return true;

            // -- Selected ONLY filler shifts OR not selected shift (None)
            if (
                selctedTab === TABS.MANUAL &&
                (dropShiftId === String(constants.FILLER_ID) || !dropShiftId) &&
                (pickupShiftId === String(constants.FILLER_ID) || !pickupShiftId)
            )
                return true;

            if (!isValidBySelectability(placement, datesAvailabilityStatus)) {
                if (
                    editShiftRedux &&
                    isReplacingOrderOnDayWithMaxOrders(editShiftRedux, datesAvailabilityStatus)
                ) {
                    return false;
                }
                return true;
            }

            // -- shift will be third on same date
            if (
                passengerWillGetMoreThenTwoShiftsOnOneDay(placement, selectedPassengersFullData) &&
                !editShiftRedux
            ) {
                return true;
            }

            return false;
        };

        const isDisabledResult = isDisabled();

        if (isDisabledResult !== isSubmitDisabled) {
            setIsSubmitDisabled(isDisabledResult);
        }
    }, [
        datesAvailabilityStatus,
        editShiftRedux,
        isSubmitDisabled,
        placement,
        selctedTab,
        selectedPassengersFullData,
    ]);

    return isSubmitDisabled;
};
