import { useTranslation } from 'react-i18next';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';

import AddressAutoComplete from 'src/components/Inputs/AddressAutoComplete';
import { cs } from 'src/style/styledComponents.style';
import { AddressInfo, UpdateValueFunc } from 'src/components/AddressInputs/types';
import WithManualInputBtn from 'src/components/AddressInputs/ManualAndGoogleAddressInput/WithManualInputBtn/WithManualInputBtn';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import { Station, ValidationError } from 'src/screens/Main/components/AddLineForm/types';
import ManualAddressControlled from '../../ManualAddressControlled/ManualAddressControlled';
import { AddressAutoCompleteProps } from '../types.ManualAddressControlled';
import {
    formSelector,
    setIsTriggeredFromImportAction,
    toggleAutoCompleteAction,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    AddressInputComponentIds,
    AutoCompleteInputStatus as Status,
} from 'src/types/lines/addLineForm.types';
import { disabledStyle } from 'src/screens/ManualOrder/hook/hooks.manualOrdersTable';
import { IStation } from 'src/types/line';

export const buildStationName = (station: IStation) => {
    const fields = [station.city, station.street, station.house];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildStationNameV2 = (station: Station) => {
    const fields = [station.city, station.street, station.houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

const initialAddressInfo: AddressInfo = {
    city: '',
    street: '',
    houseNum: '',
};

export type ManualAndGoogleAddressInputProps = {
    addressInfo: AddressInfo | undefined;
    autocompleteConfig: {
        props: AddressAutoCompleteProps;
    };
    manualConfig: {
        props: {
            isStation?: boolean;
            setValue: UpdateValueFunc;
            style?: Object;
            shrink?: boolean;
            withExitBtn?: boolean;
            errors?: ValidationError | string[];
            useJoinedErrorsArr?: boolean;
            register?: Function;
        };
    };
    componentId: string;
    disabled?: boolean;
    stationIndex?: number;
    currStation?: Station;
};

const ControlledManualAndGoogleAddressInput = React.memo(
    ({
        addressInfo,
        manualConfig,
        autocompleteConfig,
        componentId,
        disabled = false,
        stationIndex,
        currStation,
    }: ManualAndGoogleAddressInputProps) => {
        // const [usingAutocomplete, setUsingAutocomplete] = useState(!addressInfo?.city);
        const dispatch = useAppDispatch();
        const usingAutocomplete = useRootAppSelector((state) =>
            formSelector(state).addressesInputConfig.autoCompleteInputIds.includes(componentId)
        );
        const { stations, startAddress, endAddress } = useRootAppSelector(
            (state) => state.linesSlice.ui.form.data
        );
        const { lineOnEdit, importedCourseCode } = useRootAppSelector((state) => state.linesSlice.ui.form);

        const [inputText, setInputText] = useState('');

        const toggleAutoComplete = (newStatus: Status) => {
            dispatch(toggleAutoCompleteAction({ status: newStatus, componentId }));
        };

        useEffect(() => {
            if (lineOnEdit) {
                if (componentId === 'start-address-id')
                    setInputText(buildStationName(lineOnEdit.stations[0]));
                else if (componentId === 'end-address-id')
                    setInputText(buildStationName(lineOnEdit.stations[lineOnEdit.stations.length - 1]));
                else {
                    const relevantIndex = lineOnEdit.stations.findIndex((station) =>
                        componentId.includes(station.code)
                    );
                    if (relevantIndex !== -1) {
                        setInputText(buildStationName(lineOnEdit.stations[relevantIndex]));
                    }
                }
            }
        }, [lineOnEdit]);

        useEffect(() => {
            if (importedCourseCode && startAddress && endAddress) {
                if (componentId === 'start-address-id')
                    setInputText(buildStationName(startAddress as IStation));
                else if (componentId === 'end-address-id')
                    setInputText(buildStationName(endAddress as IStation));
                else {
                    const relevantIndex = stations.findIndex((station) =>
                        componentId.includes(station.stationId)
                    );
                    if (relevantIndex !== -1) {
                        setInputText(buildStationName(stations[relevantIndex] as any));
                    }
                    console.log('stations', stations[relevantIndex]);
                }
            }
        }, [importedCourseCode]);

        const autocompleteProps = {
            ...autocompleteConfig.props,
            inputTextControl: {
                inputText,
                setInputText,
            },
            freeSolo: true,
        };

        useEffect(() => {
            if (currStation && currStation.isFromPassenger) {
                setInputText(buildStationNameV2(currStation));
            }
        }, [currStation]);

        return (
            <cs.FullWidth>
                <cs.FullWidth>
                    <AddressAutoComplete
                        {...autocompleteProps}
                        // error={!!autocompleteProps.errorMessage}
                    />
                    <div style={disabled ? disabledStyle : {}}>
                        {/* <WithManualInputBtn
                            usingManualInput={!usingAutocomplete}
                            setUsingManualInput={(val: boolean) =>
                                toggleAutoComplete(val ? Status.Off : Status.On)
                            }
                        /> */}
                    </div>
                </cs.FullWidth>
            </cs.FullWidth>
        );
    }
);

export default ControlledManualAndGoogleAddressInput;
