import React, { FC } from 'react';

import { mediumLargeRubik, mediumLargeRubikBold } from '../../../../../style/styledCSS';

type Props = {
    mainText: string;
    secondaryText?: string;
};

const SettingDescription: FC<Props> = ({ mainText, secondaryText }) => {
    return (
        <div>
            <span style={mediumLargeRubikBold}>{mainText}&nbsp;</span>
            <span style={mediumLargeRubik}>{secondaryText || ''}</span>
        </div>
    );
};

export default SettingDescription;
