import React, { CSSProperties, FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { passengerDetailsHeaderStyle } from '../../styles/styles';

interface Props {
    text: any;
    isUsingI18n?: boolean;
    style?: CSSProperties;
}

// eslint-disable-next-line react/prop-types
const PassengerDetailsHeader: FC<Props> = ({ text, isUsingI18n, style }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography
                style={style ? { ...passengerDetailsHeaderStyle, ...style } : passengerDetailsHeaderStyle}
                // variant="h6"
                // gutterBottom
            >
                {isUsingI18n ? t(text) : text}
            </Typography>
        </>
    );
};

export default PassengerDetailsHeader;
