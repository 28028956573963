/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { myLog } from 'src/screens/PassengerManger/utils';
import { IAddress, IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import { Button } from '@mui/material';
// import ClearIcon from '@mui/icons-material/Clear';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { colors } from 'src/style/themes/defaultTheme';
import { generateWeakUuid } from 'src/utilis/utilis';
import { useAppSelector } from 'src/store/hooks';
import { chosenCoursesSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import ClearIcon from '@mui/icons-material/Clear';
import { IRootReducer } from '../../../../../../../store/reducers/index';
import { passengerRootDataSelector } from '../../../../../../../store/selectores/passengersSelector';
import RemarksInput from './RemarksInput';
import { AddressTypes } from '../../../types';
import AddressAutoCompleteInput from './AddressAutoCompleteInput';
import { RawInputAddress } from './types';
import { stringifyAddress } from './utils';
import ManualAddressInput from './ManualAddressInput/ManualAddressInput';
import ChooseCourseBtn from '../../coursePicking/Components/ChooseCourseBtn';

const ManualInputBtn: FC<{
    usingManualInput: boolean;
    setUsingManualInput: any;
}> = ({ usingManualInput, setUsingManualInput }) => {
    return (
        <div style={{ textAlign: 'right' }}>
            <Button
                sx={{ color: colors.blue }}
                disabled={usingManualInput}
                onClick={() => setUsingManualInput(true)}
            >
                לא מוצא את הכתובת שלך?
            </Button>
        </div>
    );
};

// & Type definitions
interface Props {
    addressSetter: any;
    addressInfo: RawInputAddress;
    modefiedPassenger: IModefiedPassenger;
    setModifiedPassenger: any;
    addressCode?: string;
    indexForCoursePicking: '0' | '1' | '2';
    // addresses: any;
}
const inputRowStyle = {
    paddingBottom: '22px',
};

// & Component

export const XBtn = ({
    onClick,
    style,
    variant,
}: {
    onClick: () => void;
    style?: Object;
    variant?: 'outlined';
}): JSX.Element => (
    <Button
        sx={{
            height: 'fit-content',
            minWidth: '10px',
            color: `${colors.blue}`,
            ...style,
        }}
        variant={variant}
        onClick={onClick}
    >
        <ClearIcon fontSize="small" />
    </Button>
);

export const AddressInput: FC<Props> = ({
    addressSetter,
    addressInfo,
    modefiedPassenger,
    setModifiedPassenger,
    addressCode,
    indexForCoursePicking,
}) => {
    // * Selectors
    // console.log('address input rendered');
    // myLog('AddressInput', addresses);
    const addingPassenger = useSelector(
        (state: IRootReducer) => passengerRootDataSelector(state).ui.addingPassenger
    );
    const chosenCourses = useAppSelector((state: any) => chosenCoursesSelector(state));
    // console.log(addressInfo);
    const [changesCount, setChangesCount] = useState(0);
    const [userTriedGoogleAutocomplete, setUserTriedGoogleAutocomplete] = useState(false);
    const [usingManualInput, setUsingManualInput] = useState(false);
    const [displayUseManualInputBtn, setDisplayUseManualInputBtn] = useState(true);

    // * Effects
    useEffect(() => {
        if (changesCount > 10) setUserTriedGoogleAutocomplete(true);
    }, [changesCount]);

    // & state resetting on switching passenger
    useEffect(() => {
        // console.log('6');
        setChangesCount(0);
        setUserTriedGoogleAutocomplete(false);
        setUsingManualInput(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modefiedPassenger.passCode]);

    useEffect(() => {
        // console.log('7');
        if (userTriedGoogleAutocomplete && !usingManualInput) {
            setDisplayUseManualInputBtn(true);
        } else {
            // setDisplayUseManualInputBtn(false);
        }
    }, [userTriedGoogleAutocomplete, usingManualInput]);

    // * Props
    const addressInputProps = {
        modefiedPassenger,
        setModifiedPassenger,
        setAddress: addressSetter,
        addressInfo,
    };

    // * utils
    const isChooseCourseBtnDisabled = useMemo((): boolean => {
        return !addressInfo.city;
    }, [addressInfo.city]);

    const isCourseSelected = useMemo((): boolean => {
        const selectedCoursesForCurrAddress = chosenCourses.find((course) => {
            if (course.addressCode === addressInfo.addressCode || addressInfo.secondaryIdentifier) {
                return true;
            }
            return false;
        });
        // console.log({ selectedCoursesForCurrAddress });
        if (selectedCoursesForCurrAddress?.pickup || selectedCoursesForCurrAddress?.drop) {
            return true;
        }
        return false;
    }, [addressInfo.addressCode, addressInfo.secondaryIdentifier, chosenCourses]);

    // * Jsx
    return (
        <>
            <Grid item xs={12} md={12}>
                {!usingManualInput && (
                    <div
                        style={
                            displayUseManualInputBtn
                                ? { padding: 0, margin: 0, display: 'flex' }
                                : inputRowStyle
                        }
                    >
                        <div style={{ flex: 3 }}>
                            <AddressAutoCompleteInput
                                {...addressInputProps}
                                addressTxt={addingPassenger ? '' : stringifyAddress(addressInfo)}
                                setChangesCount={setChangesCount}
                            />
                        </div>
                        <ChooseCourseBtn
                            addressCode={addressCode || indexForCoursePicking}
                            isDisabled={isChooseCourseBtnDisabled}
                            isCourseSelected={isCourseSelected}
                        />
                    </div>
                )}
                {displayUseManualInputBtn && !usingManualInput && (
                    <ManualInputBtn
                        usingManualInput={usingManualInput}
                        setUsingManualInput={setUsingManualInput}
                    />
                )}
                {usingManualInput && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 4 }}>
                                    <ManualAddressInput {...addressInputProps} />
                                </div>
                                <div>
                                    <ChooseCourseBtn
                                        addressCode={addressCode || indexForCoursePicking}
                                        isDisabled={isChooseCourseBtnDisabled}
                                        isCourseSelected={isCourseSelected}
                                    />
                                </div>
                            </div>
                            <Button sx={{ color: colors.blue }} onClick={() => setUsingManualInput(false)}>
                                חפש כתובת ממאגר
                            </Button>
                        </div>
                    </>
                )}
                <div style={inputRowStyle}>
                    <RemarksInput
                        addressSetter={addressSetter}
                        currRemarks={addingPassenger ? '' : addressInfo?.remarks}
                    />
                </div>
            </Grid>
        </>
    );
};

export default AddressInput;
