import React, { ReactNode } from 'react';

import { FieldError } from 'react-hook-form';
import DropDownV2, { IDropdownV2Config } from 'src/components/DropDown/DropDownV2';
import { uuid } from 'src/utilis/utilis';
import { getTranslatedErrorMessage } from 'src/components/ReactHookFormInputs/TextInputV3';

export interface RHFSelectProps<T> extends IDropdownV2Config {
    field: T;
    shrink?: boolean;
    errors: FieldError | undefined;
}

const RHFSelect = <T extends Record<string, unknown>>(
    props: RHFSelectProps<T> & { children?: ReactNode }
) => {
    const { selectProps, labelName, inputLabelProps, field, shrink, errors } = props;

    return (
        <div>
            <DropDownV2
                {...{
                    labelName,
                    fullWidth: true,
                    formControlProp: {
                        style: { width: '100%' },
                        variant: 'outlined',
                        size: 'small',
                        error: !!errors?.message,
                    },
                    selectProps: {
                        ...field,
                        notched: shrink,
                        id: `DropdownV2-${uuid()}-${labelName}`,
                        ...selectProps,
                    },
                    inputLabelProps: {
                        ...inputLabelProps,
                    },
                }}
            />
            {errors?.message && (
                <p style={{ textAlign: 'right' }}>{getTranslatedErrorMessage(errors?.message)}</p>
            )}
        </div>
    );
};

export default RHFSelect;
