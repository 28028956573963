/* eslint-disable import/no-cycle */

import { AnyAction, combineReducers, Reducer } from 'redux';
import { ILoginState } from 'src/store/type';
import dropDisplayReducer, { DropsDisplaySliceState } from '../slices/dropsDisplay/dropDisplaySlice';
import loginReducer from './loginReducer';
import lineReducer, { ILineState } from './lineReducer';
import passengersShiftReudcer, { IPassengerShiftState } from './passengersShiftReudcer';
import passengerReudcer, { IPassengerState } from './passengerReducer';
import rideSettingsSliceReducer from '../slices/rideSettings/rideSettings';
import { RideSettingsState } from '../../types/rideSettings/store/types.store';
import reportsReducer from '../slices/reports/reportsSlice';
import { IReportsState } from '../slices/reports/types';
import passengersManagerReducer from '../slices/passengersManager/passengersManagerSlice';
import commonSliceReducer, { CommonSliceState } from '../slices/common/commonSlice';
import linesSliceReducer from '../slices/lines/linesSlice';
import { ManualOrdersSliceState } from '../slices/manualOrders/types';
import manualOrdersSliceReducer from '../slices/manualOrders/manualOrdersSlice';
import coursesBuildingReducer from '../slices/coursesBuilding/coursesBuildingSlice';
import generalFeaturesSliceReducer from '../slices/generalFeatures/generalFeaturesSlice';
import { CoursesBuildingSliceState } from 'src/types/coursesBuilding/storeTypes';
import { LinesSliceState } from 'src/types/lines/slice.types';
import { GeneralFeaturesSliceState } from '../slices/generalFeatures/types.generalFeatures';
import { OptimizationsSliceState } from 'src/types/optimizations/sliceTypes';
import optimizationsReducer from '../slices/optimizations/optimizationsSlice';

export interface IRootReducer extends AnyAction {
    loginReducer: ILoginState;
    lineReducer: ILineState;
    passengersShiftReudcer: IPassengerShiftState;
    passengerReudcer: IPassengerState;
    rideSettings: RideSettingsState;
    reports: IReportsState;
    passengersManagerSlice: any;
    linesSlice: LinesSliceState;
    commonSlice: CommonSliceState;
    dropsDisplaySlice: DropsDisplaySliceState;
    manualOrdersSlice: ManualOrdersSliceState;
    generalFeaturesSlice: GeneralFeaturesSliceState;
    coursesBuildingSlice: CoursesBuildingSliceState;
    optimizationSlice: OptimizationsSliceState;
}

export const rootReducer = combineReducers<Reducer<IRootReducer>>({
    linesSlice: linesSliceReducer,
    coursesBuildingSlice: coursesBuildingReducer,
    generalFeaturesSlice: generalFeaturesSliceReducer,
    commonSlice: commonSliceReducer,
    loginReducer,
    lineReducer,
    passengersShiftReudcer,
    passengerReudcer,
    rideSettings: rideSettingsSliceReducer,
    reports: reportsReducer,
    passengersManagerSlice: passengersManagerReducer,
    dropsDisplaySlice: dropDisplayReducer,
    manualOrdersSlice: manualOrdersSliceReducer,
    optimizationSlice: optimizationsReducer,
});
