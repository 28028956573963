const { width } = window.screen;
export const getDetailedOrdersReportColumnsWidth = (field: string): number | string | undefined => {
    switch (field) {
        case 'passName':
            if (width < 1660) {
                if (width > 1420) return 120;
                return 100;
            }
            return 150;
        case 'street':
            if (width < 1660) {
                if (width > 1420) return 120;
                return 100;
            }
            return 'auto';
        case 'passId':
            if (width < 1660) {
                if (width > 1420) return 90;
                return 60;
            }
            return 110;
        case 'rideType':
            return 70;
        default:
            break;
    }
};

export const getFieldValueByLocalStorage = (
    localStorageCols: unknown | null,
    id: string,
    property: 'width' | 'orderIndex',
    defaultVal: any
) => {
    try {
        if (localStorageCols && typeof localStorageCols === 'object' && localStorageCols[id]) {
            if (typeof localStorageCols[id] === 'object' && localStorageCols[id][property]) {
                return localStorageCols[id][property];
            }
            return defaultVal;
        }
        return defaultVal;
    } catch (error) {
        console.log(error);
        return defaultVal;
    }
};
