/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { initialFormState } from 'src/store/slices/rideSettings/initialStates';
import { IFormSubmitPayload } from 'src/types/rideSettings/store/types.sagas';
import {
    RideSettingsState,
    SettingTypes,
    Department,
    GeneralSettingTypes,
    SlSettingTypes,
} from '../../../types/rideSettings/store/types.store';
import { ReqStatus } from '../../../api/types';
import { initialSubmitConfigs } from '../../slices/rideSettings/initialStates';

const onFormSubmitReducer = (state: RideSettingsState, action: PayloadAction<any>) => {
    state.form.apiCallsStatus.formSubmitStatus = ReqStatus.LOADING;
};

const onGeneralSettingsFormSubmitReducer = (state: RideSettingsState, action: PayloadAction<any>) => {
    state.form.apiCallsStatus.formSubmitStatus = ReqStatus.LOADING;
};

const onSubmitFormFailureReducer = (
    state: RideSettingsState,
    action: PayloadAction<{ failureMsg?: string }>
) => {
    state.form.apiCallsStatus.formSubmitStatus = ReqStatus.FAIL;

    if (action.payload.failureMsg) {
        state.form.apiCallsStatus.responseMsg = action.payload.failureMsg;
    }
};

const onSubmitFormSuccessReducer = (state: RideSettingsState, action: any) => {
    const { settingType, settings } = action.payload;

    state.form.ui.isOpen = false;
    state.form.ui.currentForm = null;

    state.data[settingType].settings = settings;
    state.form.apiCallsStatus.formSubmitStatus = ReqStatus.SUCCESS;
};

const onFormChanged = <T>(
    state: RideSettingsState,
    action: PayloadAction<{
        settingType: SettingTypes;
        updatedValues: T;
    }>
) => {
    const { settingType, updatedValues } = action.payload;
    state.form.valuesByForm[settingType] = updatedValues;
};

const updateGenSetsFormValue = (
    state: RideSettingsState,
    action: PayloadAction<{ type: GeneralSettingTypes; newVal: any }>
) => {
    const { type, newVal } = action.payload;
    if (state.form.valuesByForm.generalSettings) {
        state.form.valuesByForm.generalSettings[type] = newVal;
    }
};

const onOpenForm = (
    state: RideSettingsState,
    action: PayloadAction<{
        isOnEditMode: boolean;
        formType: SlSettingTypes;
    }>
) => {
    state.form.ui.isOpen = true;
    state.form.ui.isOnEditMode = action.payload.isOnEditMode;
    state.form.ui.currentForm = action.payload.formType;

    state.form.valuesByForm.generalSettings = state.data.generalSettings.settings;
    state.form.valuesByForm.permissionManagement = state.data.permissionManagement.settings;

    state.form.apiCallsStatus = {
        formSubmitStatus: ReqStatus.ON_STAND_BY,
        responseMsg: '',
    };
};

const onCloseForm = (state: RideSettingsState) => {
    state.form = initialFormState;
};
const hasSubmitConfigs = (state: RideSettingsState, action: any) => {
    state.form.apiSettings.submitConfigs = {
        ...state.form.apiSettings.submitConfigs,
        ...action.payload,
    };
};

const clearSubmitConfigs = (state: RideSettingsState) => {
    state.form.apiSettings.submitConfigs = initialSubmitConfigs;
};

export default {
    hasSubmitConfigs,
    clearSubmitConfigs,
    onFormChanged,
    onOpenForm,
    onCloseForm,
    onFormSubmitReducer,
    onSubmitFormFailureReducer,
    onSubmitFormSuccessReducer,
    updateGenSetsFormValue,
    onGeneralSettingsFormSubmitReducer,
};
