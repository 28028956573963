import { ReqStatus } from 'src/api/types';
import { DayNum } from 'src/types/global';
import { IReportsState, ReportNames, RideTypes } from './types';
import { getDaysInMonth } from 'src/screens/Reports/components/ReportsSection/Filters/FiltersAccordionForEachReport/PassengerReportFilters/PassengersReportDatePicker';

export const initialErrorState = {
    code: null,
    msg: null,
};
export const initialReportState = {
    sortedData: [],
    reportData: [],
    apiCallStatus: ReqStatus.ON_STAND_BY,
};
export const initialFiltersValues = {
    fromDate: new Date(),
    toDate: new Date(),
    departmentCode: '0',
    pickupTime: '0',
    dropTime: '0',
    rideType: 'all' as RideTypes,
    // freeSearch: '',
};

const todayDate = new Date();
export const initialPassengerFiltersValues = {
    departmentCodes: [],
    days: [1, 2, 3, 4, 5, 6, 7] as DayNum[],
    shiftIds: [],
    fromTime: '00:00',
    toTime: '23:59',
    dates: getDaysInMonth(todayDate.getMonth(), todayDate.getFullYear()),
    fromDate: todayDate,
    toDate: todayDate,
    // freeSearch: '',
};

export const initialByReportFiltersValues = {
    passengers: initialPassengerFiltersValues,
};

export const initialStatistics = {
    data: [],
    max: 0,
    relativeDate: '',
};

export const initialSummaryData = {
    rides: {
        dailyStatistics: initialStatistics,
        weeklyStatistics: initialStatistics,
        monthlyStatistics: initialStatistics,
    },
    passengers: {
        dailyStatistics: initialStatistics,
        weeklyStatistics: initialStatistics,
        monthlyStatistics: initialStatistics,
    },
};
const reports: IReportsState = {
    ui: {
        main: {
            selectedReport: ReportNames.passengersReport,
            selectedReportType: 'rides',
            selectedClientId: null,
            filters: {
                byReport: {
                    passengers: initialPassengerFiltersValues,
                },
                currFiltersValues: initialFiltersValues,
                applyFiltersBtnClickCount: 0,
                resetFiltersClickCount: 0,
            },
        },
        common: {
            isPrinting: false,
            refreshPageBtnClickCount: 0,
            error: initialErrorState,
        },
        widgets: {
            refreshWidgetsBtnClickCount: 0,
            isOpen: true,
            dateOnWidget: new Date(),
        },
        // dateForFiltering: null,
    },
    data: {
        reports: {
            shortOrders: initialReportState,
            detailedOrders: initialReportState,
            visas: initialReportState,
            passengers: initialReportState,
        },
        departments: [],
        shiftTimes: {
            fullData: [],
            dropTimes: [],
            pickupTimes: [],
        },
        widgets: {
            ridesStatistics: {
                weeklyCounts: {
                    rides: 0,
                    passengers: 0,
                    fromDate: '',
                    toDate: '',
                },
                summaryData: initialSummaryData,
            },
            budget: {
                request: {
                    isSuccess: true,
                },
                data: {
                    monthlyBudget: 0,
                    dailyCost: 0,
                    totalVisasCost: 0,
                    currencyId: '₪',
                },
            },
        },
    },
    general: {
        authorizedReports: [],
    },
};

const initialStates = { reports };
export default initialStates;
