import {
    CreateLineRequestConfig,
    CreateLineResponse,
    CreateLineReqParams,
    GetPassengersShortDetailsReqConfig,
    GetPassengersShortDetailsResponse,
    GetPassengersShortDetailsParams,
    ReqSetPassengerConfig,
    ReqSetPassengerResponse,
    ReqSetPassengerParams,
    WsGetCarTypeReqConfig,
    WsGetCarTypeResponse,
    WsGetCarTypesParams,
    DelLineRequestConfig,
    DelLineResponse,
    DelLineReqParams,
    GetCourseStationsReqParams,
    GetCourseStationsResponse,
} from 'src/types/lines/api/types';
import { uploadFilesApi } from '../miscApis/uploadFileServiceApi';
import { makeApiCall } from '../reportsApi/reportsApi';
import { AxiosRes } from '../types';
import { GetCourseStationsRequest } from '../../types/lines/api/types';

/*
      @{requestConfig} - {
         dbUrl,
         endpoint,
         requestParams
      }
*/

// FC REQUEST

export const reqSetLine = (requestConfig: CreateLineRequestConfig): AxiosRes<CreateLineResponse> => {
    return makeApiCall<CreateLineReqParams, CreateLineResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_Line',
    });
};

// FC REQUEST

export const deleteLineWs = (requestConfig: DelLineRequestConfig): AxiosRes<DelLineResponse> => {
    return makeApiCall<DelLineReqParams, DelLineResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Delete_Line',
    });
};
/**
 * Makes a request to the API to get the short details of the passengers.
 * @param {GetPassengersShortDetailsReqConfig} requestConfig - The request configuration object.
 * @returns The response from the API.
 */

export const reqGetPassengersShortDetails = (
    requestConfig: GetPassengersShortDetailsReqConfig
): AxiosRes<GetPassengersShortDetailsResponse> =>
    makeApiCall<GetPassengersShortDetailsParams, GetPassengersShortDetailsResponse>(requestConfig);

/**
 * Makes a request to set the passenger on the given request config.
 * @param {ReqSetPassengerConfig} requestConfig - The request config object.
 * @returns The response from the API call.
 */

export const reqSetPassenger = (requestConfig: ReqSetPassengerConfig): AxiosRes<ReqSetPassengerResponse> =>
    makeApiCall<ReqSetPassengerParams, ReqSetPassengerResponse>(requestConfig);

export const reqGetCarTypes = (requestConfig: WsGetCarTypeReqConfig): AxiosRes<WsGetCarTypeResponse> =>
    makeApiCall<WsGetCarTypesParams, WsGetCarTypeResponse>(requestConfig);

// FC REQUEST

export const getCourseStations = (
    requestConfig: GetCourseStationsRequest
): AxiosRes<GetCourseStationsResponse> =>
    makeApiCall<GetCourseStationsReqParams, GetCourseStationsResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Course_Stations',
    });
