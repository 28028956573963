export const googleMapsApiLanguage = {
    Afrikaans: 'af',
    Amharic: 'am',
    Arabic: 'ar',
    Assamese: 'as',
    Azerbaijani: 'az',
    Belarusian: 'be',
    Bulgarian: 'bg',
    Bengali: 'bn',
    Breton: 'br',
    Bosnian: 'bs',
    Catalan: 'ca',
    Czech: 'cs',
    Chuvash: 'cv',
    Welsh: 'cy',
    Danish: 'da',
    German: 'de',
    Dzongkha: 'dz',
    Ewe: 'ee',
    Greek: 'el',
    English: 'en',
    Esperanto: 'eo',
    Spanish: 'es',
    Estonian: 'et',
    Basque: 'eu',
    Persian: 'fa',
    Finnish: 'fi',
    Faroese: 'fo',
    French: 'fr',
    'Western Frisian': 'fy',
    Irish: 'ga',
    'Scottish Gaelic': 'gd',
    Galician: 'gl',
    Gujarati: 'gu',
    Hebrew: 'he',
    Hindi: 'hi',
    Croatian: 'hr',
    Haitian: 'ht',
    Hungarian: 'hu',
    Armenian: 'hy',
    Indonesian: 'id',
    Icelandic: 'is',
    Italian: 'it',
    Japanese: 'ja',
    Javanese: 'jv',
    Georgian: 'ka',
    Kazakh: 'kk',
    'Central Khmer': 'km',
    Kannada: 'kn',
    Korean: 'ko',
    Kurdish: 'ku',
    Kirghiz: 'ky',
    Luxembourgish: 'lb',
    Lao: 'lo',
    Lithuanian: 'lt',
    Latvian: 'lv',
    Malagasy: 'mg',
    Macedonian: 'mk',
    Malayalam: 'ml',
    Mongolian: 'mn',
    Marathi: 'mr',
    Malay: 'ms',
    Maltese: 'mt',
    'Norwegian Bokmål': 'nb',
    Nepali: 'ne',
    Dutch: 'nl',
    'Norwegian Nynorsk': 'nn',
    Oriya: 'or',
    Ossetian: 'os',
    Punjabi: 'pa',
    Polish: 'pl',
    Pashto: 'ps',
    Portuguese: 'pt',
    Quechua: 'qu',
    Romansh: 'rm',
    Rundi: 'rn',
    Romanian: 'ro',
    Russian: 'ru',
    Kinyarwanda: 'rw',
    Sami: 'se',
    Sinhalese: 'si',
    Slovak: 'sk',
    Slovenian: 'sl',
    Somali: 'so',
    Albanian: 'sq',
    Serbian: 'sr',
    Swedish: 'sv',
    Swahili: 'sw',
    Tamil: 'ta',
    Telugu: 'te',
    Thai: 'th',
    Tigrinya: 'ti',
    Turkmen: 'tk',
    Tagalog: 'tl',
    Tswana: 'tn',
    Turkish: 'tr',
    Tsonga: 'ts',
    Uighur: 'ug',
    Ukrainian: 'uk',
    Urdu: 'ur',
    Uzbek: 'uz',
    Vietnamese: 'vi',
    Volapük: 'vo',
    Walloon: 'wa',
    Xhosa: 'xh',
    Yiddish: 'yi',
    Yoruba: 'yo',
    Chinese: 'zh',
    Zulu: 'zu',
};
