// import { TextField } from '@mui/material';
import React from 'react';
import { TextField } from '@mui/material';
import { TextField as CoreTextField, TextFieldProps } from '@material-ui/core';
import { styled } from '@mui/system';

const TextInput = styled(TextField)({
    width: '100%',
    '.MuiOutlinedInput-notchedOutline': {
        textAlign: 'right ',
    },
    // '.MuiInputLabel-root': {
    //    position: 'absolute',
    //    right: '25px',
    //    top: '-1px',
    // },
    '.MuiInputLabel-root': {
        transformOrigin: 'top right',
        lineHeight: '',
        left: 'inherit',
        // position: 'absolute',
        // backgroundColor: 'orange',
        // left: '30px',
        // top: '0px',
    },
    '.MuiOutlinedInput-root.Mui-error': {
        fieldset: {
            borderColor: '#f44336',
        },
    },
    '.MuiInputLabel-root.Mui-error': {
        color: '#f44336',
    },
});

export const CoreTextFieldV2 = (props: TextFieldProps) => {
    return (
        <CoreTextField
            variant="outlined"
            size="small"
            fullWidth
            style={{ backgroundColor: 'white' } as const}
            {...props}
        />
    );
};

export default TextInput;
