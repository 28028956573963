import { IconButton } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
    toggleExpansionForAll: () => void;
    allExpandedVal: boolean;
}

const sx = {
    position: 'absolute',
    right: '3px',
    top: '3px',
    zIndex: 999,
};

const AllExpansionBtn = ({ toggleExpansionForAll, allExpandedVal }: Props): JSX.Element => {
    return (
        <IconButton sx={sx} onClick={toggleExpansionForAll} size="small">
            {allExpandedVal ? <RemoveIcon fontSize="inherit" /> : <AddIcon fontSize="inherit" />}
        </IconButton>
    );
};

export default AllExpansionBtn;
