import React from 'react';
import { useTranslation } from 'react-i18next';
// import { ExportToExcelBtn } from 'src/screens/Reports/components/ReportsSection/Menu/ActionButtons/ActionButtons';
import { MenuButton, buttonSx } from 'src/screens/Reports/components/ReportsSection/Menu/Menu.style';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TableChartIcon from '@mui/icons-material/TableChart';
import HTMLTableToExcel from 'src/components/HTMLTableToExcel';

export const blocked = {
    opacity: 0.5,
    pointerEvents: 'none',
} as const;

const defaultStyle = {
    display: 'flex',
    justifyContent: 'left',
    paddingBottom: '5px',
    paddingTop: '0px',
} as const;

interface ExcelExportBtnProps {
    table?: string;
    filename?: string;
    onClick?: Function;
}
export const ExportToExcelBtn = (props: ExcelExportBtnProps) => {
    const { onClick, ...rest } = props;

    return (
        <MenuButton startIcon={<TableChartIcon />} onClick={(onClick as any) || (() => {})} sx={buttonSx}>
            <HTMLTableToExcel {...rest} />
        </MenuButton>
    );
};

export interface Props {
    isSwitchingClient: boolean;
    exports: {
        tableRef: React.MutableRefObject<any>;
        handlePrint: () => void;
        isPrinting: boolean;
    };
}

const ExportBtns: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <div
            style={
                props.isSwitchingClient || props.exports.isPrinting
                    ? { ...blocked, ...defaultStyle }
                    : { ...defaultStyle }
            }
        >
            <ExportToExcelBtn table="manual-orders-table" filename="דו''ח הזמנות שבועי" />
            <MenuButton sx={buttonSx} startIcon={<LocalPrintshopIcon />} onClick={props.exports.handlePrint}>
                {t('print')}
            </MenuButton>
        </div>
    );
};

export default ExportBtns;
