import { GridColumnProps } from '@progress/kendo-react-grid';
import React from 'react';
import { useAppSelector } from 'src/store/hooks';

import { ReportNames } from 'src/store/slices/reports/types';
import {
    selectedReportNameSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectRefreshBtnClickCount,
    isPrintingSelector,
} from 'src/store/selectores/reportsSelectors';
import { IRootReducer } from 'src/store/reducers';
import useDetailedOrdersReportColumns from './useDetailedOrdersReportColumns';
import useShortOrdersReportColumns from './useShortOrdersReportColumns';
import useVisasReportColumns from './useVisasReportCols';
import useWeeklyOrdersCols from './useWeeklyOrdersCols';
import usePassengerReportColumns from './usePassengersReportCols';

const useColumns = (): GridColumnProps[] => {
    const isPrinting: boolean = useAppSelector((state) => isPrintingSelector(state));
    const selectedReportName = useAppSelector((state: unknown) =>
        selectedReportNameSelector(state as IRootReducer)
    );
    const shortOrdersCols = useShortOrdersReportColumns().columns;
    const detailedOrdersCols = useDetailedOrdersReportColumns().columns;
    const visasCols = useVisasReportColumns(isPrinting).columns;
    const passengersCols = usePassengerReportColumns(isPrinting).columns;
    const weeklyOrdersCols = useWeeklyOrdersCols().columns;

    switch (selectedReportName) {
        case 'shortOrders':
            return shortOrdersCols;
        case 'detailedOrders':
            return detailedOrdersCols;
        case 'visas':
            return visasCols;
        case 'passengers':
            return passengersCols;
        default:
            return weeklyOrdersCols;
    }
};
export default useColumns;
