import axios from 'axios';
import { uploadFileConstants as constants } from 'src/constants/uploadFileFtConstants';
import {
    DeleteFileParams,
    GetFileParams,
    UploadFileParams,
    UploadFileResponse,
} from 'src/types/uploadFileFt/uploadFileApiTypes';
import { AxiosRes } from '../types';
import { GetFileResponse } from '../../types/uploadFileFt/uploadFileApiTypes';

const createUrl = (endpoint: string) => `${constants.MAIN_URL}/${endpoint}`;

const addDollarSignPrefix = (bodyObj: Object) => {
    const newObj = {};
    Object.keys(bodyObj).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[`$${key}`] = bodyObj[key];
    });
    return newObj;
};
const uploadFile = (reqBody: UploadFileParams): AxiosRes<UploadFileResponse> => {
    return axios.post(createUrl(constants.ENDPOINTS.UPLOAD_FILE), addDollarSignPrefix(reqBody));
};

const deleteFile = (reqBody: DeleteFileParams): AxiosRes<UploadFileResponse> => {
    return axios.post(createUrl(constants.ENDPOINTS.DELETE_FILE), addDollarSignPrefix(reqBody));
};

const getFile = (reqBody: GetFileParams): AxiosRes<GetFileResponse> => {
    return axios.post(createUrl(constants.ENDPOINTS.GET_FILE), addDollarSignPrefix(reqBody));
};

export const uploadFilesApi = {
    reqUploadFile: uploadFile,
    reqDeleteFile: deleteFile,
    reqGetFile: getFile,
};
