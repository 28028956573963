/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import PassengerDetailsHeader from 'src/screens/PassengerManger/components/PassengerForm/Components/Headers/PassengerDetailsHeader';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { StyledMainPassengerDetailsDisplay, StyledParagraph, DetailContainer } from './.styles';
import { AddressIcon, MailIcon, PermissionsIcon, PhoneIcon } from './Icons/Icons';

interface Props {
    selectedPassenger: IPassenger | any;
}

const MainPassengerDetailsDisplay: React.FC<Props> = ({ selectedPassenger }) => {
    const { t } = useTranslation();
    const passengerDetailsHeaderStyling = {
        padding: 0,
        margin: 0,
    };

    const showText = (text: string | undefined) => {
        return text || t('notAssigned');
    };

    const hasAtLeastOneDetail = () => {
        if (
            selectedPassenger.mobilePhone ||
            selectedPassenger.email ||
            selectedPassenger.mainAdress ||
            selectedPassenger.internetLevel
        )
            return true;
        return false;
    };
    return (
        <StyledMainPassengerDetailsDisplay>
            <div>
                <PassengerDetailsHeader
                    text="passengerDetails"
                    isUsingI18n
                    style={passengerDetailsHeaderStyling}
                />
                <div>
                    {selectedPassenger?.mobilePhone && (
                        <DetailContainer>
                            <PhoneIcon />
                            <StyledParagraph>
                                {`${t('phone')}: ${showText(selectedPassenger?.mobilePhone)}`}
                            </StyledParagraph>
                        </DetailContainer>
                    )}
                    {selectedPassenger?.eMail && (
                        <DetailContainer>
                            <MailIcon />
                            <StyledParagraph>{`דוא"ל: ${showText(
                                selectedPassenger?.eMail
                            )}`}</StyledParagraph>
                        </DetailContainer>
                    )}
                    {selectedPassenger?.mainAdress && (
                        <DetailContainer>
                            <AddressIcon />
                            <StyledParagraph>{`${t('address')}: ${showText(
                                selectedPassenger?.mainAdress
                            )}`}</StyledParagraph>
                        </DetailContainer>
                    )}
                    {selectedPassenger?.internetLevel && (
                        <DetailContainer>
                            <PermissionsIcon />
                            <StyledParagraph>{`${t('passLevel')}: ${showText(
                                selectedPassenger?.internetLevel
                            )}`}</StyledParagraph>
                        </DetailContainer>
                    )}
                    {!hasAtLeastOneDetail() && <p>{t('thereAreNoMorePassengerDetails')}</p>}
                </div>
            </div>
        </StyledMainPassengerDetailsDisplay>
    );
};

export default MainPassengerDetailsDisplay;
