import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { FcData, FcLogin, ReqStatus } from 'src/api/types';

import { initConnectSuccess } from 'src/store/actions/loginAction';
import Alert from 'src/components/commons/Alert/Alert';
import BtnLink from 'src/components/commons/Buttons/BtnLink/BtnLink';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { IAccount, ResUserLogin, SingupType } from 'src/types/login';
import Password from '../../../../components/Password/Password';
import { loginByPassword, updateToken } from '../../../../api/api';
import { routesEndpoints } from '../../../../routes/routes_endpoints';

import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';

import { SpaceAround, HeaderText, Header, LinkStyle } from './User.style';

const User: FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const onInitConnect = (payload: any) => dispatch(initConnectSuccess(payload));
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const onSubmit = async (userName: string, password: string) => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
        const fcResponse: FcLogin = await loginByPassword({
            deviceToken,
            userName,
            userPass: password,
        });

        const promises: Array<Promise<void>> = [];
        const { data = [] } = fcResponse;

        const loginData: FcData = data[0] || {};
        const fcAccounts: IAccount[] = loginData.fcAccounts || [];
        const token: string = loginData.token || '';

        fcAccounts.forEach(({ proxyUrl, contactUUID, dbUrl }) => {
            promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
        });

        Promise.all(promises).then(() => {
            onInitConnect({
                loginType: SingupType.User,
                isAuthenticated: ReqStatus.SUCCESS,
                ...data[0],
            });
            history.push(`${routesEndpoints.HOME}`);
        });

        if (fcResponse.response === ResUserLogin.SUCCESS) {
            history.push(routesEndpoints.HOME);
        } else if (fcResponse.response === ResUserLogin.WRONG_USER_OR_CODE) {
            setShowErrorAlert(true);
        }
    };

    const onForgotPassword = () => {
        history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP}`);
    };

    const navigateToSinupOptions = () => {
        history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP_OPTIONS}`);
    };

    const handleCloseSnack = () => setShowErrorAlert(false);

    return (
        <Container>
            <Title>{t('login')}</Title>
            <Card>
                <Header>
                    <HeaderText>{t('setPasswordLabel')}</HeaderText>
                </Header>
                <SpaceAround>
                    <Password
                        onSubmit={onSubmit}
                        userNamelabel={t('userName')}
                        passwordLable={t('password')}
                        buttonLable={t('connect')}
                    />
                </SpaceAround>
                <LinkStyle
                    style={{ fontWeight: 'bold', color: theme.colors.blue }}
                    onClick={onForgotPassword}
                >
                    {t('forgotPasswordText')}
                </LinkStyle>
            </Card>

            <BtnLink btnText={t('anotherSignup')} onClick={navigateToSinupOptions} />

            <Alert open={showErrorAlert} onClose={handleCloseSnack} severity="error">
                {t('wrongUserOrPassword')}
            </Alert>
        </Container>
    );
};

export default User;
