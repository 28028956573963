/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { GridCellProps } from '@progress/kendo-react-grid';
import _ from 'lodash';
import moment from 'moment';
import React, { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IPassengerShifts, IShifts } from 'src/types/manualOrders/api.types';
import { DISPLAY_UI, RELATIVE, SHORT } from 'src/constants/dates';
import {
    onClickedBlockedTable,
    setAsOnlySelectedPassenger,
    setSelectedSingleShift,
} from 'src/store/actions/PassengersShiftActionType';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    selectedSingleShiftSelector,
    editShiftSelector,
    formSelector,
    shiftsSelector,
} from 'src/store/selectores/passengerShiftsSelectores';
import {
    selectedPassengersIdsSelector,
    setSelectedPassengersAction,
    shiftCapsulesSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { IShiftCapsule } from 'src/store/slices/manualOrders/types';
import { colors } from 'src/style/themes/defaultTheme';
import { isSameDate, logToLocalStorage } from 'src/utilis/utilis';
import { ContextStateType, PlacemenContext } from '../../components/PlacemenTransportation/context';
import { shiftHooks } from '../../hook/hooks.Shift';
import { manualOrdersCommonHooks } from '../../hook/manualOrdersHooks.common';
import usePlacement from '../../hook/usePlacement';
import { getOrdersCount, getShiftColorById } from '../../utilis';
import Shift from './components/Shift';
import * as Styles from './Shiftcell.Style';

interface Props extends Omit<GridCellProps, 'dataItem'> {
    dataItem: IPassengerShifts;
    shifts: IShifts[];
}

export const FIELD_DATE_FORMAT = DISPLAY_UI;

export const getStyle = (isShiftSelected: boolean, formIsOpen: boolean): CSSProperties => {
    return isShiftSelected && formIsOpen
        ? {
              cursor: 'not-allowed',
          }
        : {};
};
const CLASS_NAME_OF_CELL_WITH_ADDING_SHIFT_FUNC = ['AddMoreShift', 'shift-cell-container'];

const Shiftcell: FC<Props> = (item) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { dataItem, field } = item;

    const { placment: placement, setPlacment: setPlacement, placementIncludesDate } = usePlacement();

    const [isHover, setIsHover] = useState(false);
    // * Selectors
    const isShiftSelected: boolean = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form.isShiftSelected
    );
    const isOpen: boolean = useSelector((state: IRootReducer) => state.passengersShiftReudcer.ui.form.isOpen);
    const selectedSingleShiftState = useSelector((state: IRootReducer) => selectedSingleShiftSelector(state));
    const editShiftState = useSelector((state: IRootReducer) => editShiftSelector(state));
    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector);
    const capsulesForCell: IShiftCapsule[] = useRootAppSelector((state) => {
        const allCapsules = shiftCapsulesSelector(state);
        if (allCapsules) {
            const capsulesForPassenger = allCapsules[dataItem.passId];
            if (Array.isArray(capsulesForPassenger)) {
                return (
                    capsulesForPassenger.filter((capsule) =>
                        isSameDate(capsule.date, moment(field, FIELD_DATE_FORMAT))
                    ) || ([] as IShiftCapsule[])
                );
            }
        }
        return [] as IShiftCapsule[];
    });

    // * Variables
    const isEditOrAddingShiftOnCell: boolean = useMemo(() => {
        if (!item.dataItem.isSelected) return false;

        if (!placementIncludesDate(moment(item.field, DISPLAY_UI))) return false;

        const passengerId = item.dataItem.passId;
        const cellDate = moment(item.field, DISPLAY_UI);
        if (!selectedSingleShiftState && !editShiftState) return false;
        const ssPassId = selectedSingleShiftState?.passId;
        const ssDate = selectedSingleShiftState?.date;

        const esDate = editShiftState?.date;
        const esPassId = editShiftState?.passId;

        const isForSamePassenger = ssPassId === passengerId || esPassId === passengerId;

        if (!isForSamePassenger) return false;
        // console.log('isForSamePassenger');

        const isForSameDate = moment(ssDate).isSame(cellDate) || moment(esDate).isSame(cellDate);
        if (!isForSameDate) return false;
        return true;
    }, [editShiftState, item.dataItem, item.field, placementIncludesDate, selectedSingleShiftState]);

    const isBlocked = useMemo(() => moment(field, FIELD_DATE_FORMAT).isBefore(moment(), 'D'), [field]);
    const isEmpty = capsulesForCell.length === 0;

    const { passId, fullName } = dataItem;

    const isTargetToScheduling = useMemo(
        () =>
            placement.dates.some(
                (d) => d.relativeDate === moment(field, FIELD_DATE_FORMAT).format('YYYY-MM-DD')
            ) && selectedPassengersIds.some((selectedPassId) => selectedPassId === passId),
        [field, passId, placement.dates, selectedPassengersIds]
    );

    const tkDispatch = useAppDispatch();

    const openForm = manualOrdersCommonHooks.useClickOpen();

    const onClickAddShift = useCallback(() => {
        if (!field) {
            console.log('FATAL ERROR - no field');
            return;
        }
        openForm();
        const newPlacementState = {
            ...placement,
            dates: [
                ...placement.dates,
                {
                    relativeDate: moment(field, FIELD_DATE_FORMAT).format(RELATIVE),
                },
            ],
        };
        setPlacement(newPlacementState);

        if (!_.isEqual(selectedPassengersIds, [passId])) {
            // dispatch(setAsOnlySelectedPassenger({ passId: dataItem.passId }));
            tkDispatch(setSelectedPassengersAction([dataItem.passId]));
        }

        dispatch(
            setSelectedSingleShift({
                passId,
                date: moment(field, FIELD_DATE_FORMAT),
                relativeDate: field,
                fullName,
            })
        );
    }, [
        dataItem.passId,
        dispatch,
        field,
        fullName,
        openForm,
        passId,
        placement,
        selectedPassengersIds,
        setPlacement,
        tkDispatch,
    ]);

    const getClickFunction = useCallback(
        (e) => {
            try {
                if (!e.target === e.currentTarget) return () => {};
                // ↓ checking if the target has a class name that is valid for clicking to add shift
                const targetClassName: string = e.target.className || '';
                if (
                    !CLASS_NAME_OF_CELL_WITH_ADDING_SHIFT_FUNC.some(
                        (className) => targetClassName.split(' ').indexOf(className) >= 0
                    )
                )
                    return () => {};
                if (
                    !isShiftSelected &&
                    isHover &&
                    getOrdersCount(capsulesForCell) < 3
                    // validForBeingAddedToPlacement
                ) {
                    return onClickAddShift;
                }
                return () => {};
            } catch (error: any) {
                console.log(error.message);
                // logToLocalStorage(error, 'getClickFunction');
                return () => {};
            }
        },
        [isHover, isShiftSelected, onClickAddShift, capsulesForCell]
    );

    const getContainerBorderStyle = useCallback(() => {
        if (
            isHover &&
            !isShiftSelected &&
            isEmpty &&
            !isBlocked &&
            // validForBeingAddedToPlacement &&
            !isTargetToScheduling
        )
            return {
                border: '1px dashed black',
                cursor: 'pointer',
                borderRadius: '4px',
            };
        if (isTargetToScheduling && isOpen)
            return {
                border: `2px dashed ${colors.blue}`,
                borderRadius: '9px',
                transition: 'all 0.1s',
            };
    }, [isBlocked, isEmpty, isHover, isOpen, isShiftSelected, isTargetToScheduling]);

    return (
        <td style={{ height: '1px', padding: 0 }}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    cursor: isShiftSelected && isOpen ? 'not-allowed' : 'auto',
                    ...getContainerBorderStyle(),
                }}
                onClick={() => {
                    isShiftSelected && dispatch(onClickedBlockedTable());
                }}
            >
                <Styles.Container
                    className="shift-cell-container"
                    isBlocked={isBlocked}
                    isEmpty={isEmpty}
                    formIsOpen={isOpen}
                    isShiftSelected={isShiftSelected}
                    isEditOrAddingShiftOnCell={isEditOrAddingShiftOnCell}
                    isTargetToScheduling={isTargetToScheduling}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onClick={(e) => getClickFunction(e)()}
                >
                    {!isShiftSelected && isEmpty && !isBlocked && isHover ? (
                        <Styles.EmptyContainer onClick={onClickAddShift}>
                            <span>&#43;</span>
                            <div>
                                <span>{t('addShift')}</span>
                            </div>
                        </Styles.EmptyContainer>
                    ) : (
                        <>
                            {capsulesForCell
                                .sort((a, b) => +b.startTime - +a.startTime)
                                .map((capsule) => (
                                    <Shift
                                        shiftId={capsule.shiftId}
                                        startTime={capsule.startTime}
                                        endTime={capsule.endTime}
                                        color={capsule.color}
                                        codes={capsule.orderCodes}
                                        date={capsule.date}
                                        capsuleId={capsule.id}
                                        isPast={isBlocked}
                                        passId={passId}
                                        fullName={fullName}
                                    />
                                ))}
                            {!isShiftSelected && capsulesForCell.length < 2 && isHover && (
                                <Styles.AddMoreShift
                                    className="AddMoreShift"
                                    isShiftSelected={isShiftSelected}
                                    isTargetToScheduiling={isTargetToScheduling}
                                    onClick={onClickAddShift}
                                >
                                    <span>&#43;</span>
                                </Styles.AddMoreShift>
                            )}
                        </>
                    )}
                </Styles.Container>
            </div>
        </td>
    );
};

export default Shiftcell;
