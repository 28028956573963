import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import commonRoutes from 'src/mockServer/routes/commonRoutes';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { IDepartment } from 'src/types/line';
import { CommonCodes } from 'src/types/mockServer/routes.types';

const useFetchDepartments = ({ withLoading }: { withLoading?: false }) => {
    const [loading, setLoading] = useState(false);

    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const { dbUrl } = selectedFcAccount || {};

    const fetch = React.useCallback(
        async ({
            clientCode,
            onSuccess,
            onFailure,
        }: {
            clientCode: string;
            onSuccess: (data: IDepartment[]) => void;
            onFailure: (code: CommonCodes, message: string) => void;
        }) => {
            if (withLoading) setLoading(true);

            if (!dbUrl || !clientCode || !token) return -1;

            const res = await commonRoutes.getDepartments({
                requestConfig: { clientCode, dbUrl, token, proxyUrl: null },
            });

            if (res.code === CommonCodes.Ok && Array.isArray(res.data.data)) {
                onSuccess(res.data.data);
            } else {
                onFailure(res.code, res.data.message || ErrorMessages.GeneralError);
            }

            if (withLoading) setLoading(false);
        },
        [dbUrl, token, withLoading]
    );

    return { fetch, loading };
};

export default useFetchDepartments;
