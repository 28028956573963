import moment from 'moment';
import { useCallback, useEffect } from 'react';
import {
    UseFormClearErrors,
    UseFormGetValues,
    UseFormReturn,
    UseFormSetError,
    UseFormWatch,
} from 'react-hook-form';
import { startTimeSchema } from 'src/screens/Main/components/AddLineForm/schema';
import { Inputs } from 'src/screens/Main/components/AddLineForm/types';
import { useRootAppSelector } from 'src/store/hooks';
import { clientsSelector } from 'src/store/slices/lines/linesSlice';

const useCotValidationMethods = (formMethods: UseFormReturn<Inputs, any>) => {
    const validateStartTime = useCallback(
        (time: string, dateArg: Date, COT: number) => {
            const optionsContext: { cot: number; selectedDate: moment.Moment } = {
                cot: COT,
                selectedDate: moment(dateArg),
            };

            const validationResultPromise = startTimeSchema
                .validate(time, { context: optionsContext })
                .then(() => {
                    formMethods.clearErrors('startTime');
                    return true;
                })
                .catch((error: any) => {
                    formMethods.setError('startTime', { type: 'test', message: error?.message });
                    return false;
                });

            return Promise.all([validationResultPromise]).then((val) => {
                return val[0];
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formMethods.setError, formMethods.clearErrors]
    );
    return { validateStartTime };
};

const MIN_TIME_STR_LEN = 4;
const useClientCOT = () => {
    const clients = useRootAppSelector(clientsSelector);

    const getClientCOTVal = useCallback(
        (clientCode: string) => {
            const clientMatch = clients.find((client) => client.clientCode === String(clientCode));
            if (clientMatch) return clientMatch.maxMinuteToBlockNewLine || 0;
            return null;
        },
        [clients]
    );

    return { getClientCOTVal };
};

const useCotValidation = (formMethods: UseFormReturn<Inputs, any>) => {
    const [startTime, date, clientCode] = formMethods.getValues(['startTime', 'date', 'clientCode']);

    const { getClientCOTVal } = useClientCOT();

    const { validateStartTime } = useCotValidationMethods(formMethods);

    useEffect(() => {
        const COT = getClientCOTVal(String(clientCode));

        if (startTime.length < MIN_TIME_STR_LEN || typeof COT !== 'number') return;

        setTimeout(() => {
            validateStartTime(startTime, date, COT);
        }, 1);
    }, [clientCode, date, getClientCOTVal, startTime, validateStartTime]);

    return { validateStartTime };
};

const cotValidationHooks = {
    useCotValidation,
    useCotValidationMethods,
    useClientCOT,
};
export default cotValidationHooks;
