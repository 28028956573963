import React, { FC } from 'react';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';

interface Props {
    isOpen: boolean;
    onClickCancel: () => void;
    onClickConfirm: () => void;
    loadingConfirm: boolean;
    loadingCancel: boolean;
}
const warningMsg = 'שים לב , פעולה זו תדרוס חלק מהנתונים שקיימים בטופס';

const StationsImportDialog: FC<Props> = ({
    isOpen,
    onClickCancel,
    onClickConfirm,
    loadingConfirm,
    loadingCancel,
}) => {
    return (
        <ConfirmationDialog
            isDialogOpen={isOpen}
            onCloseDialog={onClickCancel}
            onConfirmClick={onClickConfirm}
            DialogContent={(() => (
                <p>{warningMsg}</p>
            ))()}
            DialogTitle={(() => (
                <></>
            ))()}
            disableBackdropClick
            disableEscapeKeyDown
            loadingConfirm={loadingConfirm}
            loadingCancel={loadingCancel}
        />
    );
};

export default StationsImportDialog;
