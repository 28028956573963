import { CommonCodes } from '../../types/mockServer/routes.types';

import {
    CoursesBuildingApiEnums,
    CoursesBuildingApiMappers,
    IGetCourseTimesResDataItem,
} from 'src/types/coursesBuilding/apiTypes';
import { isWsResponse, responseValidators } from 'src/api/utilis';
import { getAccountCourses } from 'src/api/reportsApi/reportsApi';
import { WsCourseDataItem, GetAccountCoursesRequest } from 'src/types/passengersManager/courseChoosingTypes';
import { ErrorMessages, ReqConfig, Result } from 'src/types/apiCommon.types';
import courseApi, {
    GetCoursesTimesReqConfig,
    GetCourseTimesSettingsReqConfig,
    GetCourseTimesSettingsWsRes,
    SetCourseConfig,
} from 'src/api/coursesApi/coursesApi';
import { IExtendedCourseData } from 'src/types/coursesBuilding/commonTypes';
import { removeDuplicatesObjs, uuid } from '../../utilis/utilis';
import {
    GetCourseStationsRequest,
    CourseStationSettingWsDataItem,
    CourseStationTypes,
} from 'src/types/lines/api/types';
import { ICourseStation } from 'src/screens/CoursesBuilding/components/CourseInfoWidget/StationsAndTimesPanel';
import responseHandlers from '../helpers/responseHandlers';
import { getInitialResultCopy } from '../mockServerConstants';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import timeHelpers from 'src/utilis/timeHelpers';
import {
    courseFormErrorMessages,
    CourseFormReqParams,
    coursesRoutesHelpers,
} from '../helpers/coursesRoutesHelpers';

const { UniqueMockServerCodes } = CoursesBuildingApiEnums;

export interface GetCoursesClientData {
    requestConfig: GetAccountCoursesRequest;
}

const getAccountCoursesRoute = async (clientData: GetCoursesClientData) => {
    const defaultErrorCode = CommonCodes.UnknownError;

    const result: Result<WsCourseDataItem[]> = getInitialResultCopy();

    try {
        const httpRes = await getAccountCourses(clientData.requestConfig);

        if (responseValidators.isOk(httpRes)) {
            result.code = CommonCodes.Ok;
            result.data.data = httpRes.data.data;

            return result;
        }

        if (responseValidators.isMissingWs(httpRes)) {
            result.code = CommonCodes.MissingWs;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        if (responseValidators.isBadToken(httpRes)) {
            result.code = CommonCodes.BadToken;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        throw new Error('GeneralBadHttpResponse');
    } catch (error) {
        console.log(error);

        result.code = defaultErrorCode;
        result.data.data = null;
        result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode];

        return result;
    }
};

export interface GetCoursesTimesClientData {
    requestConfig: GetCoursesTimesReqConfig;
}

const getDaysNums = (day: string) => {
    const ALL_DAYS_KEY = '0';

    if (day === ALL_DAYS_KEY) {
        return [1, 2, 3, 4, 5, 6, 7];
    }

    return [+day];
};
const parseCourseTimes = (rawData: IGetCourseTimesResDataItem[]): IExtendedCourseData[] => {
    const parsed: IExtendedCourseData[] = [];

    rawData.forEach((rawItem) => {
        const days = getDaysNums(rawItem.day);

        days.forEach((day) => {
            const matchByDay = parsed.find((time) => time.day === day);

            const parsedTimes = rawItem.times.map((time) => ({
                start: time.startTime,
                end: time.endTime,
            }));

            if (matchByDay) {
                matchByDay.times.push(...parsedTimes);
                matchByDay.carTypes.push(...rawItem.carTypes);
                matchByDay.times = removeDuplicatesObjs(matchByDay.times);
                matchByDay.carTypes = removeDuplicatesObjs(matchByDay.carTypes);
                return;
            }

            const parsedItem: IExtendedCourseData = {
                times: parsedTimes,
                carTypes: rawItem.carTypes,
                day,
            };
            parsed.push(parsedItem);
        });
    });

    return parsed;
};

const getCourseTimesRoute = async (clientData: GetCoursesTimesClientData) => {
    const defaultErrorCode = UniqueMockServerCodes.getAccountCoursesFailed;

    const result: Result<IExtendedCourseData[]> = getInitialResultCopy();

    try {
        const httpRes = await courseApi.getCourseTimes(clientData.requestConfig);

        if (responseValidators.isOk(httpRes) && Array.isArray(httpRes.data.data)) {
            result.code = CommonCodes.Ok;
            result.data.data = parseCourseTimes(httpRes.data.data);

            return result;
        }

        if (responseValidators.isMissingWs(httpRes)) {
            result.code = CommonCodes.MissingWs;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        if (responseValidators.isBadToken(httpRes)) {
            result.code = CommonCodes.BadToken;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        if (isWsResponse(httpRes)) {
            result.code = Number(httpRes.data.response);
            result.data.data = null;
            result.data.message = ErrorMessages.GeneralError;

            return result;
        }

        throw new Error('GeneralBadHttpResponse');
    } catch (error) {
        console.log(error);

        result.code = defaultErrorCode;
        result.data.data = null;
        result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode];

        return result;
    }
};

const parseStation = (rawStation: CourseStationSettingWsDataItem): ICourseStation => {
    const timeFromPrev = rawStation.timing && +rawStation.timing > 0 ? +rawStation.timing : null;

    return {
        stationId: rawStation.stationCode || uuid(),
        city: rawStation.city,
        street: rawStation.street,
        houseNum: rawStation.house,
        timeFromPrev,
        lat: rawStation.lat ? +rawStation.lat : 0,
        lng: rawStation.lon ? +rawStation.lon : 0,
        type: rawStation.type,
        isManualTimeFromPrev: timeFromPrev ? true : false,
        stationRemark: rawStation.stationRemark ? rawStation.stationRemark : '',
    };
};

const parseCourseStations = (rawData: CourseStationSettingWsDataItem[]) => {
    const stations: ICourseStation[] = [];

    const origin = rawData.find((station) => station.type === CourseStationTypes.Origin);
    const destination = rawData.find((station) => station.type === CourseStationTypes.Destination);

    const sortedByIndex = rawData.sort((a, b) => +a.index - +b.index);

    sortedByIndex.forEach((rawStationData) => {
        // Skipping origin and destination because we add them later on
        if (
            rawStationData.type === CourseStationTypes.Origin ||
            rawStationData.type === CourseStationTypes.Destination
        )
            return;
        stations.push(parseStation(rawStationData));
    });

    if (origin) {
        stations.unshift(parseStation(origin));
    }

    if (destination) {
        stations.push(parseStation(destination));
    }

    if (stations.length) {
        // setting first station's timeToNextStation to null because we don't want to display it in the UI
        const lastStation = stations[0];
        lastStation.timeFromPrev = null;
    }

    return stations;
};

const getCourseStationsRoute = async (clientData: { requestConfig: GetCourseStationsRequest }) => {
    const defaultErrorCode = UniqueMockServerCodes.getAccountCoursesFailed;

    const result: Result<ICourseStation[]> = getInitialResultCopy();

    try {
        const httpRes = await courseApi.getCourseStationsSettings(clientData.requestConfig);

        if (responseValidators.isOk(httpRes) && Array.isArray(httpRes.data.data)) {
            result.code = CommonCodes.Ok;
            result.data.data = parseCourseStations(httpRes.data.data);

            return result;
        }

        if (responseValidators.isMissingWs(httpRes)) {
            result.code = CommonCodes.MissingWs;
            result.data.data = null;
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[CommonCodes.MissingWs];

            return result;
        }

        if (responseValidators.isBadToken(httpRes)) {
            result.code = CommonCodes.BadToken;
            result.data.data = null;
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[CommonCodes.BadToken];

            return result;
        }

        if (isWsResponse(httpRes, 'message')) {
            result.code = Number(httpRes.data.response);
            result.data.data = null;
            result.data.message = ErrorMessages.GeneralError;

            return result;
        }

        throw new Error('GeneralBadHttpResponse');
    } catch (error) {
        console.log(error);

        result.code = defaultErrorCode;
        result.data.data = null;
        result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode];

        return result;
    }
};

const deleteCourseRoute = async (clientData: { requestConfig: SetCourseConfig }) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    const result: Result<string> = getInitialResultCopy();

    const requestConfig = { ...clientData.requestConfig };
    requestConfig.requestParams.isActive = '0';

    try {
        const httpRes = await courseApi.setAccountCourseDetails(requestConfig);
        return responseHandlers.getResult(httpRes, result, { dataKey: 'message' });
    } catch (error) {
        return responseHandlers.getErrorResult(
            result,
            error,
            CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode],
            defaultErrorCode
        );
    }
};

const setCourseFormRoute = async (clientData: { requestConfig: ReqConfig<CourseFormReqParams> }) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    const result: Result<string> = getInitialResultCopy();

    const requestConfig = { ...clientData.requestConfig };

    try {
        const configs = coursesRoutesHelpers.getReqConfigs(requestConfig);

        // Course details ws call
        const courseDetailsHttpRes = await courseApi.setAccountCourseDetails(configs.detailsConfig);
        const courseDetailsRouteResult = responseHandlers.getResult(courseDetailsHttpRes, result, {});

        const courseCode = courseDetailsRouteResult.data.data || '';
        if (courseDetailsRouteResult.code !== CommonCodes.Ok || !courseCode) {
            result.data.message = courseFormErrorMessages.CourseDetailsTabFailed;
            return result;
        }

        // Course Stations ws call
        const setStationsConfig = { ...configs.stationsConfig };

        setStationsConfig.requestParams.courseCode = courseCode;

        const setStationsHttpRes = await courseApi.setCourseStations(configs.stationsConfig);
        const setStationsRouteResult = responseHandlers.getResult(setStationsHttpRes, result, {});

        if (setStationsRouteResult.code !== CommonCodes.Ok) {
            result.data.message = courseFormErrorMessages.StationsTabsFailed;
            return result;
        }

        // Course Times settings ws call
        configs.timesConfig.requestParams.courseCode = courseCode;

        const timesSettingsHttpRes = await courseApi.setCourseTimesSettingsWs(configs.timesConfig);
        const timesSettingsRouteResult = responseHandlers.getResult(timesSettingsHttpRes, result, {});

        if (timesSettingsRouteResult.code !== CommonCodes.Ok) {
            result.data.message = courseFormErrorMessages.TimesSettingsTabFailed;
            return result;
        }

        // Success
        result.code = CommonCodes.Ok;
        result.data.data = null;

        return result;
    } catch (error) {
        return responseHandlers.getErrorResult(
            result,
            error,
            CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode],
            defaultErrorCode
        );
    }
};

const getCourseTimesSettingsRoute = async (clientData: {
    requestConfig: GetCourseTimesSettingsReqConfig;
}) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    const result: Result<TimesSettingsBox[]> = getInitialResultCopy();

    const dataParser = (rawData: GetCourseTimesSettingsWsRes['data']) => {
        const timesSettings: TimesSettingsBox[] = [];

        if (rawData.length) {
            rawData.forEach((rawTimesSettings) => {
                timesSettings.push({
                    id: uuid(),
                    startTime: timeHelpers.timeToDate(rawTimesSettings.startTime),
                    endTime: timeHelpers.timeToDate(rawTimesSettings.endTime),
                    daysRowsCodes: coursesRoutesHelpers.getDaysRowsCodes(rawTimesSettings.daysRowsCode),
                    days: coursesRoutesHelpers.getDaysNumsV2(rawTimesSettings.daysRowsCode),
                    carTypeCode: rawTimesSettings.carTypeCode,
                    carsQty: rawTimesSettings.carsQty ? +rawTimesSettings.carsQty : 1,
                    isActive: true,
                });
            });
        }

        return timesSettings;
    };

    const requestConfig = { ...clientData.requestConfig };

    try {
        const httpRes = await courseApi.getCourseTimesSettingsWs(requestConfig);
        const routeResult = responseHandlers.getResult(httpRes, result, { dataParser });

        return routeResult;
    } catch (error) {
        return responseHandlers.getErrorResult(
            result,
            error,
            CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode],
            defaultErrorCode
        );
    }
};

const restoreCourseRoute = async (clientData: { requestConfig: SetCourseConfig }) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    const result: Result<string> = getInitialResultCopy();

    const requestConfig = { ...clientData.requestConfig };
    requestConfig.requestParams.isActive = '1';

    try {
        const httpRes = await courseApi.setAccountCourseDetails(requestConfig);
        const routeResult = responseHandlers.getResult(httpRes, result);

        return routeResult;
    } catch (error) {
        return responseHandlers.getErrorResult(
            result,
            error,
            CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode],
            defaultErrorCode
        );
    }
};

const coursesBuildingRoutes = {
    getAccountCourses: getAccountCoursesRoute,
    getCourseTimes: getCourseTimesRoute,
    getCourseStations: getCourseStationsRoute,
    deleteCourse: deleteCourseRoute,
    setCourseForm: setCourseFormRoute,
    getCourseTimesSettings: getCourseTimesSettingsRoute,
    restoreCourse: restoreCourseRoute,
};

export default coursesBuildingRoutes;
