import Papa from 'papaparse';
import useCommons from 'src/hooks/common/useCommons';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { IOptimizationCsvWaypoint, IOptimizationFormWaypoint, IOptimizationWaypoint } from '../api/types';
import { useFetchAddressFromCoords } from './useFetchAddressFromCoords';
import { useFetchCoordsFromAddress } from './useFetchCoordsFromAddress';
import { number } from 'prop-types';
import { useSetOptimizationWaypoints } from './useSetOptimizationWaypoints';
import {
    optimizationsRootSelector,
    setCsvWaypointsObjectAction,
    setIsCsvLoadDoneModalOpenAction,
    setIsGeneralLoadingAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { useRootAppSelector } from 'src/store/hooks';
/* eslint-disable no-debugger */

interface MyObject {
    [key: string]: any;
}

const filterObjectArray = (arr: MyObject[]): MyObject[] => {
    const allowedKeys: string[] = [
        'city',
        'street',
        'houseNum',
        'lat',
        'lng',
        'passId',
        'firstName',
        'lastName',
    ];
    return arr.map((obj) => {
        const filteredObj: MyObject = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (allowedKeys.includes(key)) {
                filteredObj[key] = value;
            }
        });
        return filteredObj;
    });
};

const buildAddressStringFromWaypoint = (waypoint: IOptimizationCsvWaypoint) => {
    return `${waypoint.street ?? ''} ${waypoint.houseNum ?? ''} ${waypoint.city ?? ''}`;
};

export const checkPassIdDuplicates = (
    waypoints: IOptimizationCsvWaypoint[] | IOptimizationWaypoint[] | IOptimizationFormWaypoint[]
) => {
    const passIds = new Set();

    for (let i = 0; i < waypoints.length; i++) {
        const passId = waypoints[i]['passId'];

        if (passIds.has(passId)) {
            return true;
        }

        passIds.add(passId);
    }
    return false;
};

export const useCsvReverseGeo = () => {
    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();
    const fetchAddressFromCoords = useFetchAddressFromCoords();
    const fetchCoordsFromAddress = useFetchCoordsFromAddress();
    const setOptimizationWaypointsFromServer = useSetOptimizationWaypoints();

    const {
        data: {
            getWaypointsFromServer: { data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const validatePassengerIdUniqueness = (waypointsObject: {
        validWaypoints: IOptimizationCsvWaypoint[];
        invalidWaypoints: IOptimizationCsvWaypoint[];
        unRecognizedWaypoints: IOptimizationCsvWaypoint[];
    }) => {
        waypointStock.forEach((waypoint: IOptimizationWaypoint) => {
            waypointsObject.validWaypoints.forEach((csvWaypoint: IOptimizationCsvWaypoint, index) => {
                if (csvWaypoint.passId === waypoint.passId) {
                    const splicedWaypoint = waypointsObject.validWaypoints.splice(index, 1);

                    if (splicedWaypoint.length > 0) {
                        waypointsObject.invalidWaypoints.push(splicedWaypoint[0]);
                    }
                }
            });

            waypointsObject.unRecognizedWaypoints.forEach((csvWaypoint: IOptimizationCsvWaypoint, index) => {
                if (csvWaypoint.passId === waypoint.passId) {
                    const splicedWaypoint = waypointsObject.unRecognizedWaypoints.splice(index, 1);

                    if (splicedWaypoint.length > 0) {
                        waypointsObject.invalidWaypoints.push(splicedWaypoint[0]);
                    }
                }
            });
        });

        return waypointsObject;
    };

    const getWaypointsObject = async (waypoint: IOptimizationCsvWaypoint) => {
        const innerValidWaypoints: IOptimizationCsvWaypoint[] = [];
        const innerInvalidWaypoints: IOptimizationCsvWaypoint[] = [];
        const innerUnRecognizedWaypoints: IOptimizationCsvWaypoint[] = [];

        const isLatNumeric = !Number.isNaN(Number(waypoint.lat));
        const isLngNumeric = !Number.isNaN(Number(waypoint.lng));

        // valid waypoint

        if (
            waypoint.city &&
            waypoint.street &&
            waypoint.lat &&
            waypoint.lng &&
            isLatNumeric &&
            isLngNumeric &&
            waypoint.passId
        ) {
            innerValidWaypoints.push({ ...waypoint, lat: +waypoint.lat, lng: +waypoint.lng });
        }

        // valid Waypoints that Needs To Get Coords
        else if (
            waypoint.city &&
            waypoint.street &&
            waypoint.passId &&
            (!waypoint.lat || !waypoint.lng || !isLngNumeric || !isLatNumeric)
        ) {
            // call api for coords

            const address = buildAddressStringFromWaypoint(waypoint);

            const coords = await fetchCoordsFromAddress.fetchCoordsFromAddress(address);

            if (coords !== null && coords !== undefined && coords.lat && coords.lng) {
                const waypointWithCoords = { ...waypoint, lat: coords.lat, lng: coords.lng };

                innerValidWaypoints.push(waypointWithCoords);
            } else {
                // not recognized waypoint

                innerUnRecognizedWaypoints.push(waypoint);
            }
        }

        // valid Waypoints that Needs To Get Address
        else if (
            waypoint.lat &&
            waypoint.lng &&
            waypoint.passId &&
            isLngNumeric &&
            isLatNumeric &&
            (!waypoint.city || !waypoint.street)
        ) {
            // call api for address

            const waypointWithAddress = await fetchAddressFromCoords.fetchAddressFromCoords(
                Number(waypoint.lat),
                Number(waypoint.lng)
            );

            if (waypointWithAddress) {
                innerValidWaypoints.push({
                    ...waypointWithAddress,
                    passId: waypoint.passId,
                    firstName: waypoint.firstName,
                    lastName: waypoint.lastName,
                });
            } else {
                console.log('invalid address');
            }
        } else {
            innerInvalidWaypoints.push(waypoint);
        }

        return { innerValidWaypoints, innerInvalidWaypoints, innerUnRecognizedWaypoints };
    };

    const dataValidation = async (data: IOptimizationCsvWaypoint[]) => {
        dispatch(setIsGeneralLoadingAction({ isLoading: true }));

        const validWaypoints: IOptimizationCsvWaypoint[] = [];
        const invalidWaypoints: IOptimizationCsvWaypoint[] = [];
        const unRecognizedWaypoints: IOptimizationCsvWaypoint[] = [];
        const promises: Promise<{
            innerValidWaypoints: IOptimizationCsvWaypoint[];
            innerInvalidWaypoints: IOptimizationCsvWaypoint[];
            innerUnRecognizedWaypoints: IOptimizationCsvWaypoint[];
        }>[] = [];

        data.forEach((waypoint) => {
            promises.push(getWaypointsObject(waypoint));
        });

        return Promise.all(promises).then((results) => {
            results.forEach((result) => {
                validWaypoints.push(...result.innerValidWaypoints);
                invalidWaypoints.push(...result.innerInvalidWaypoints);
                unRecognizedWaypoints.push(...result.innerUnRecognizedWaypoints);
            });

            return { validWaypoints, invalidWaypoints, unRecognizedWaypoints };
        });
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (!file) {
            return;
        }

        dispatch(setIsGeneralLoadingAction({ isLoading: true }));

        Papa.parse(file, {
            complete: async (results) => {
                console.log(results);
                // Do something with the parsed data
                const header = results.meta.fields;
                const KEYS_AMOUNT = 5;

                if (header) {
                    header.map((field) => field.trim());

                    if (
                        header.length < KEYS_AMOUNT ||
                        header[0] !== 'city' ||
                        header[1] !== 'street' ||
                        header[2] !== 'houseNum' ||
                        header[3] !== 'lat' ||
                        header[4] !== 'lng' ||
                        header[5] !== 'passId' ||
                        header[6] !== 'firstName' ||
                        header[7] !== 'lastName'
                    ) {
                        dispatch(setIsGeneralLoadingAction({ isLoading: false }));

                        dispatchAlert('error', 'כותרת הקובץ אינה תקינה');

                        return;
                    }
                } else {
                    dispatch(setIsGeneralLoadingAction({ isLoading: false }));

                    dispatchAlert('error', 'כותרת הקובץ אינה תקינה');
                    return;
                }

                // Process the data

                const data = results.data as IOptimizationCsvWaypoint[];

                if (data.length > 100) {
                    dispatchAlert('error', 'ניתן לקלוט עד 100 כתובות');
                    dispatch(setIsGeneralLoadingAction({ isLoading: false }));

                    return;
                }

                const dataWithoutUnesseseryKeys = filterObjectArray(data);

                const isPassIdDuplicates = checkPassIdDuplicates(
                    dataWithoutUnesseseryKeys as IOptimizationCsvWaypoint[]
                );

                console.log(
                    'dataWithoutUnesseseryKeys',
                    dataWithoutUnesseseryKeys,
                    'isPassIdDuplicates',
                    isPassIdDuplicates
                );

                if (isPassIdDuplicates) {
                    dispatchAlert('error', 'לא ניתן לקלוט נקודות ציון עם קוד לקוח זהה');
                    dispatch(setIsGeneralLoadingAction({ isLoading: false }));
                    return;
                }

                const waypointsObject = await dataValidation(
                    dataWithoutUnesseseryKeys as IOptimizationCsvWaypoint[]
                );

                // passengerId Uniqueness Validation

                const waypointsObjectAfterValidation = validatePassengerIdUniqueness(waypointsObject);

                dispatch(setCsvWaypointsObjectAction({ csvWaypointsObject: waypointsObjectAfterValidation }));

                const waypointsSentToServer = [
                    ...waypointsObjectAfterValidation.unRecognizedWaypoints,
                    ...waypointsObjectAfterValidation.validWaypoints,
                ];

                if (waypointsSentToServer.length > 0) {
                    // send to server
                    setOptimizationWaypointsFromServer.setOptimizationWaypoints(null, waypointsSentToServer);
                } else {
                    dispatch(setIsGeneralLoadingAction({ isLoading: false }));
                    dispatch(setIsCsvLoadDoneModalOpenAction({ isOpen: true }));
                }
            },
            header: true, // Assumes the first row contains column headers
            encoding: 'windows-1255',
            transformHeader: (header: string) => header.trim().replace(/\s/g, '_'), // trim and replace spaces in headers
            skipEmptyLines: true,
        });
    };

    return {
        changeHandler,
    };
};
