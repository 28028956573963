import { GeneralSettingsData } from 'src/types/rideSettings/store/types.store';

const processGeneralSettingsResponseData = (data: {
    hidePassPhone?: '1' | '0' | '';
    firstDayOfWeek?: '1' | '2' | '3' | '4' | '5' | '6' | '7';
    languageForAddresses?:
        | 'af'
        | 'am'
        | 'ar'
        | 'as'
        | 'az'
        | 'be'
        | 'bg'
        | 'bn'
        | 'br'
        | 'bs'
        | 'ca'
        | 'cs'
        | 'cv'
        | 'cy'
        | 'da'
        | 'de'
        | 'dz'
        | 'ee'
        | 'el'
        | 'en'
        | 'eo'
        | 'es'
        | 'et'
        | 'eu'
        | 'fa'
        | 'fi'
        | 'fo'
        | 'fr'
        | 'fy'
        | 'ga'
        | 'gd'
        | 'gl'
        | 'gu'
        | 'he'
        | 'hi'
        | 'hr'
        | 'ht'
        | 'hu'
        | 'hy'
        | 'id'
        | 'is'
        | 'it'
        | 'ja'
        | 'jv'
        | 'ka'
        | 'kk'
        | 'km'
        | 'kn'
        | 'ko'
        | 'ku'
        | 'ky'
        | 'lb'
        | 'lo'
        | 'lt'
        | 'lv'
        | 'mg'
        | 'mk'
        | 'ml'
        | 'mn'
        | 'mr'
        | 'ms'
        | 'mt'
        | 'nb'
        | 'ne'
        | 'nl'
        | 'nn'
        | 'or'
        | 'os'
        | 'pa'
        | 'pl'
        | 'ps'
        | 'pt'
        | 'qu'
        | 'rm'
        | 'rn'
        | 'ro'
        | 'ru'
        | 'rw'
        | 'se'
        | 'si'
        | 'sk'
        | 'sl'
        | 'so'
        | 'sq'
        | 'sr'
        | 'sv'
        | 'sw'
        | 'ta'
        | 'te'
        | 'th'
        | 'ti'
        | 'tk'
        | 'tl'
        | 'tn'
        | 'tr'
        | 'ts'
        | 'ug'
        | 'uk'
        | 'ur'
        | 'uz'
        | 'vi'
        | 'vo'
        | 'wa'
        | 'xh'
        | 'yi'
        | 'yo'
        | 'zh'
        | 'zu';
}): GeneralSettingsData => {
    return {
        hidePassPhone: data.hidePassPhone || '0',
        firstDayOfWeek: data.firstDayOfWeek || '1',
        languageForAddresses: data.languageForAddresses || 'he',
    };
};

const methods = {
    processGeneralSettingsResponseData,
};

export default methods;
