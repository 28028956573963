import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginType } from 'src/api/api';
import { userUUIDSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';

import { setLoginType as setLoginTypeAction } from 'src/store/actions/loginAction';
import BtnLink from 'src/components/commons/Buttons/BtnLink/BtnLink';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { SingupType } from 'src/types/login';
import Alert from 'src/components/commons/Alert/Alert';

import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';

import Code from '../../Signup/InitialIdentification/Code/Code';
import styles from './InitPinCode.style';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const InitPinCode: FC<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [pinCode, setPinCode] = useState<string>('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const userUUID = useSelector((state: IRootReducer) => userUUIDSelector(state));

    const onChangeLoginType = (payload: any) => dispatch(setLoginTypeAction(payload));

    const onSubmitfirstStage = (code: string) => {
        setPinCode(code);
    };

    const onSubmit = async (code: string) => {
        if (code !== pinCode) {
            setPinCode('');
            setShowErrorAlert(true);
            return;
        }

        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
        if (deviceToken && userUUID) {
            const res = await setLoginType({
                autologin: SingupType.PinCode,
                pinCode: code,
                userUUID,
                deviceToken,
            });

            if (res.response === '0') {
                onChangeLoginType(SingupType.PinCode);
                history.push(routesEndpoints.HOME);
                return;
            }

            setPinCode('');
        } else {
            setShowErrorAlert(true);
            setPinCode('');
        }
    };

    const navigateToSinupOptions = () => {
        history.goBack();
    };

    const TitleComponent = <styles.Text>{t('enterPinCode')}</styles.Text>;
    const TitleValidateComponent = <styles.Text>{t('validatePinCode')}</styles.Text>;
    const handleCloseSnack = () => setShowErrorAlert(false);

    const isAutoSubmit = false;

    return (
        <Container>
            {/* <Title>{t('signup')}</Title> */}
            <Card>
                <styles.Header>
                    <styles.HeaderText>{t('loginWithPinCode')}</styles.HeaderText>
                </styles.Header>
                <styles.SpaceAround>
                    {(!pinCode && (
                        <Code
                            key="validate"
                            isInputSecure
                            isAutoSubmit={isAutoSubmit}
                            onSubmit={onSubmitfirstStage}
                            Title={TitleComponent}
                            btnText={t('ok')}
                        />
                    )) || (
                        <Code
                            key="confirim"
                            isInputSecure
                            isAutoSubmit={isAutoSubmit}
                            onSubmit={onSubmit}
                            Title={TitleValidateComponent}
                            btnText={t('ok')}
                        />
                    )}
                </styles.SpaceAround>
            </Card>
            {/* <BtnLink
            btnText={t('anotherSignup')}
            onClick={navigateToSinupOptions}
         /> */}
            <Alert open={showErrorAlert} onClose={handleCloseSnack} severity="error">
                {t('wrongCode')}
            </Alert>
        </Container>
    );
};

export default InitPinCode;
