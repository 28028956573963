import styled, { css } from 'styled-components';

export const StyledScreenContainer = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
`;

export const StyledWidgetsContainer = styled.div`
    // z-index: 1;
    background: #ffedb1;
    box-shadow: inset 6px 2px 20px rgba(0, 0, 0, 0.4);
`;

export const StyledFabContainer = styled.div<{ isOpen: any }>`
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40px;
    height: 40px;
    // position: fixed;
    // top: 47%;
    ${({ isOpen }) => css`
        ${isOpen ? '' : 'left: 11px'}
    `}
`;

const closeArrowIconSx = { position: 'relative', right: '1px' };
const openArrowIconSx = { position: 'relative' };

const fabSx = {
    backgroundColor: 'white',
};
export const cssStyles = {
    fabSx,
    closeArrowIconSx,
    openArrowIconSx,
};
