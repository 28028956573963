import React from 'react';
import { AddressInfo } from 'src/components/AddressInputs/types';
import { ValidationError } from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormHeader from './components/FormHeader';
import PassengersFormHeader from './components/PassengersFormHeader';
import StoredCoursesHeader from './components/subForms/storedCourses/StoredCoursesHeader/StoredCoursesHeader.index';
import { FormTypes, BtnsConfigArgs } from './types';

export const getValidationErrors = (schema: OptionalObjectSchema<any>, obj: any) => {
    try {
        schema.validateSync(obj);
    } catch (error: unknown) {
        return (error as ValidationError).errors;
    }
    return [];
};

export const getFormHeader = (currForm: FormTypes, btnsConfigArgs: BtnsConfigArgs) => {
    if (currForm === 'fullForm') {
        return FormHeader;
    }
    if (currForm === 'storedCourses') {
        return () => <StoredCoursesHeader onCancel={btnsConfigArgs.onCancel} />;
    }

    return () => <PassengersFormHeader onCancel={btnsConfigArgs.onCancel} />;
};
