import React, { useState } from 'react';
import { IProps as DropdownProps } from 'src/components/DropDown/DropDown';

const usePassengerDeptFilter = (deptFilterProps: DropdownProps) => {
    const [value, setValue] = useState(deptFilterProps.value);

    const componentProps: DropdownProps = {
        disabled: deptFilterProps.disabled,
        formControlProp: deptFilterProps.formControlProp,
        menueItem: deptFilterProps.menueItem,
        value,
        onChange: (e: any) => {
            setValue(String(e.target.value));
        },
        labalName: 'מחלקה לנוסע',
    };

    const resetValue = () => setValue('');

    return {
        value,
        componentProps,
        resetValue,
    };
};

export default usePassengerDeptFilter;
