/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRidesStatistics } from 'src/api/reportsApi/reportsApi';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    LineStatistics,
    ProcessedStatistics,
    RidesStatisticsResponse,
    StatisticsRequestConfig,
    WeeklyCounts,
} from 'src/types/reports/generalReportsTypes';
import { stringifyDate } from 'src/utilis/utilis';
import moment from 'moment';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    onErrorAction,
    onFetchRidesStatisticsSuccessAction,
    resetRidesStatisticsDataAction,
} from 'src/store/slices/reports/reportsSlice';
import useErrorHandling from 'src/hooks/useErrorHandling';
import {
    departmentsSelector,
    filtersByReportSelector,
    filtersSelector,
    refreshWidgetsBtnClickCountSelector,
    selectApplyFiltersBtnClickCount,
    selectedReportNameSelector,
    selectResetFiltersClickCount,
} from 'src/store/selectores/reportsSelectors';
import { allResponsesValid, groupLinesObj, processAllStatistics } from 'src/screens/Reports/utils';
import { ErrorMessagesOld } from 'src/store/type';
import { isValidResponse } from 'src/api/utilis';
import { RidesProgressData, Statistics, SummaryData } from '../../types';
import { DISPLAY_UI } from '../../../../../../constants/dates';
import { departments } from '../../../../../../utilis/mock';
import widgetsHooks from '../../hooks/common.hooks';

const useDepartmentCodesByReport = (): string[] => {
    const selectedReportName = useRootAppSelector(selectedReportNameSelector);

    const passengersReportSelectedDepartment = useAppSelector(
        (state) => filtersByReportSelector(state, 'passengers').departmentCodes
    );

    const departmentCodeOnFilter = useAppSelector((state) => filtersSelector(state).departmentCode);

    const allDepartmentsCodes = useRootAppSelector(departmentsSelector).map((d) => String(d.code));

    switch (selectedReportName) {
        case 'passengers':
            return passengersReportSelectedDepartment;

        case 'visas':
            return allDepartmentsCodes;

        default:
            return [departmentCodeOnFilter || ''];
    }
};

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
const _useHelpers = () => {
    // Selectors
    const { token } = useSelector((state: IRootReducer) => loginSelector(state));

    const departmentCodesOnFilter = useDepartmentCodesByReport();
    const departmentFilterParams = widgetsHooks.useMultDepartmentsForFiltering(departmentCodesOnFilter);

    // FC REQUEST

    const getConfig = useCallback(
        (args: { clientCode: string; display: 1 | 2 | 3; date: Date; dbUrl: string }) => {
            return {
                endpoint: 'ws_MyWayStudio_Get_Lines_Statistics',
                dbUrl: args.dbUrl,
                requestParams: {
                    token,
                    display: args.display,
                    clientCode: args.clientCode,
                    relativeDate: stringifyDate(args.date),
                    ...departmentFilterParams,
                },
            };
        },
        [departmentFilterParams, token]
    );

    return { getConfig };
};

const useRidesCardsApiData = ({ date, clientCode }: { date: Date; clientCode: number | undefined }): void => {
    const dispatch = useAppDispatch();

    const { dispatchErrorAction } = useErrorHandling();
    const { getConfig } = _useHelpers();

    const applyFiltersBtnClickCount = useRootAppSelector(selectApplyFiltersBtnClickCount);
    const refreshBtnClickCount = useAppSelector((state) => refreshWidgetsBtnClickCountSelector(state));
    const reportName = useAppSelector(selectedReportNameSelector);
    const { selectedFcAccount } = useSelector((state: IRootReducer) => loginSelector(state));
    const { dbUrl } = selectedFcAccount || {};

    useEffect(() => {
        try {
            const fetchAllStatistics = async (): Promise<RidesStatisticsResponse[] | -1> => {
                if (!clientCode || !dbUrl) return -1;

                const configs = {
                    daily: getConfig({ clientCode: String(clientCode), date, display: 1, dbUrl }),
                    weekly: getConfig({ clientCode: String(clientCode), date, display: 2, dbUrl }),
                    monthly: getConfig({ clientCode: String(clientCode), date, display: 3, dbUrl }),
                };

                if (!configs.daily || !configs.weekly || !configs.monthly) return -1;

                const dailyRes = await getRidesStatistics(configs.daily);
                const weeklyRes = await getRidesStatistics(configs.weekly);
                const monthlyRes = await getRidesStatistics(configs.monthly);

                const allResponsesValidResult = allResponsesValid(
                    [dailyRes, weeklyRes, monthlyRes],
                    dispatchErrorAction
                );

                if (allResponsesValidResult) {
                    return [dailyRes.data, weeklyRes.data, monthlyRes.data];
                }

                return -1;
            };

            fetchAllStatistics().then((result) => {
                if (result === -1) return;

                const [daily, weekly, monthly] = result;
                const precessed = processAllStatistics(daily, weekly, monthly);

                dispatch(
                    onFetchRidesStatisticsSuccessAction({
                        statistics: precessed,
                    })
                );
            });
        } catch (err) {
            dispatchErrorAction(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportName, clientCode, applyFiltersBtnClickCount, refreshBtnClickCount, stringifyDate(date)]);
};

export default useRidesCardsApiData;
