import styled from 'styled-components';

export const StyledWidgetLayout = styled.div`
    // padding: 0 31px;
    height: 100%;
    width: 100%;
    // overflow: auto;
`;

export const StyledWidgetLayoutWithTitle = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 5% 95%;
`;
