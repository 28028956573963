import { GridRowProps } from '@progress/kendo-react-grid';
import React from 'react';
import { colors, msColors } from '../../../../../style/themes/defaultTheme';

export const rowRender = (
    trElement:
        | React.ReactElement<HTMLTableRowElement, string | React.JSXElementConstructor<any>>
        | React.DetailedReactHTMLElement<any, HTMLElement>,
    props: GridRowProps,
    setHoverRowItemCode: (itemCode: any) => void
) => {
    const isActive = props.dataItem.isActive === '1';
    const defaultColor = {
        color: msColors.darkTextcolor,
    };
    const gray = {
        color: colors.gray,
    };
    const style = isActive ? defaultColor : gray;

    const { dataItem } = props;

    return React.cloneElement(trElement, {
        // ...trProps,
        style: { ...trElement.props.style, ...style },
        onMouseLeave: () => {
            setHoverRowItemCode(null);
        },
        onMouseEnter: () => {
            setHoverRowItemCode(dataItem?.passCode);
        },
    });
};
