/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import { uiSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

/**
 * A React hook that returns true if the widgets slide should be mounted to avoid UI glitch
 * caused by the Mui Slider component.
 * @returns {boolean} - true if the widgets slide should be mounted.
 */
const useDelayedWidgetsDisplay = () => {
    const editCourseForm = useRootAppSelector((state) => uiSelector(state).courseBuildingForm);

    const [mountWidgetsSlide, setMountWidgetsSlide] = useState(true);

    React.useEffect(() => {
        let timeout: NodeJS.Timeout;

        const DELAY = 500;

        if (!editCourseForm.isOpen) {
            timeout = setTimeout(() => {
                setMountWidgetsSlide(true);
            }, DELAY);

            return;
        }

        // is form open
        setMountWidgetsSlide(false);

        return () => clearTimeout(timeout);
    }, [editCourseForm.isOpen]);

    return mountWidgetsSlide;
};

export default useDelayedWidgetsDisplay;
