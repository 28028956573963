/* eslint-disable import/no-named-as-default */
import React, { FC, useState, useEffect, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import {
    clientIdSelector,
    passengerSelector,
    passengersUISelector,
} from 'src/store/selectores/passengersSelector';

import { Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { StyledWidgetHeaderContainer } from 'src/screens/styles/Widgets.style';
import { useTranslation } from 'react-i18next';
import defaultTheme from 'src/style/themes';
import styles from './PassengerDetails.style';
import DepartmentAndPassTypeDisplay from './components/DepartmentAndPassTypeDisplay';
import MainPassengerDetailsDisplay from './components/MainPassengerDetailsDisplay';
import ExtraDetailsDisplay from './components/ExtraDetailsDisplay';
import PassengerName from './components/PassengerName';
import { personIconStyle } from './styles';
import { StyledIconContainer } from './components/.styles';
import useSelectedPassenger from '../../hooks/useSelectedPassenger';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

export const EmptyDataMsg = () => {
    const { t } = useTranslation();
    const selectedPassengersCount = useSelector(
        (state: IRootReducer) => passengersUISelector(state).selectedPassengers.length
    );
    return (
        <h4>
            {selectedPassengersCount >= 2 ? t('disabledDueToMultipleSelection') : t('noPassengerSelected')}
        </h4>
    );
};

const PassengerDetails: FC<IProps> = () => {
    const selectedPassenger = useSelectedPassenger();
    // const selectedPassengersCount = useSelector(
    //    (state: IRootReducer) =>
    //       passengersUISelector(state).selectedPassengers.length,
    // );
    return (
        <>
            {(!selectedPassenger && <EmptyDataMsg />) || (
                <>
                    <styles.Header>
                        <StyledWidgetHeaderContainer>
                            <Box
                                display="flex"
                                // width="70%"
                                alignItems="center"
                                flexWrap="nowrap"
                                // fontWeight="bold"
                            >
                                <div style={personIconStyle}>
                                    <PersonIcon
                                    // fontSize="medium"
                                    />
                                </div>
                                <PassengerName selectedPassenger={selectedPassenger} />
                            </Box>
                        </StyledWidgetHeaderContainer>

                        {/* Passenger department & pass level section */}
                        <DepartmentAndPassTypeDisplay selectedPassenger={selectedPassenger} />
                    </styles.Header>
                    {/* passenger details section header */}
                    <styles.StyledDetailsDisplay>
                        <MainPassengerDetailsDisplay selectedPassenger={selectedPassenger} />
                        <ExtraDetailsDisplay selectedPassenger={selectedPassenger} />
                    </styles.StyledDetailsDisplay>
                </>
            )}
        </>
    );
};

export default PassengerDetails;
