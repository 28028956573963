import { ApiCallStatus } from '../apiCommon.types';
import { CourseTypes, IExtendedCourseData } from './commonTypes';
import { BaseDepartment } from '../../utilis/utilis';
import { ExtendedCourse } from 'src/screens/CoursesBuilding/components/CourseInfoWidget/common';
import { FillerValues } from 'src/constants/misc';
import { ICourseStation } from 'src/screens/CoursesBuilding/components/CourseInfoWidget/StationsAndTimesPanel';
import { StationSchema, TimesSettingsBox } from './form/formTypes';
import { StationsErrors } from 'src/screens/CoursesBuilding/components/CourseForm/CourseFormBody/StationsPanel/hooks.StationsPanel';
import { Coords } from 'google-map-react';

export enum CourseBuildingTabs {
    Details = 'courseDetails',
    CourseStationsBuilding = 'courseBuilding',
    TimesAndFrequencySettings = 'timesAndFreqSettings',
}

export type TimesSettingsErrors = 'invalidTimes' | 'invalidDays';
export interface UiState {
    clientCode: string | null;
    filters: {
        courseType: CourseTypes;
        freeSearch: string;
        courseId: string | FillerValues;
        departmentId: string;
    };
    refreshBtnClickCount: number;
    selectedCourseId: string;
    restoreModal: {
        isOpen: boolean;
        courseCode: string;
    };
    deleteModal: {
        isOpen: boolean;
        courseCode: string;
    };
    courseBuildingForm: {
        isOpen: boolean;
        currTab: CourseBuildingTabs;
        courseCode: string;
        isReadyToDisplay: boolean;
        tempDisabled: boolean;
        isDisabled: boolean;
        timesSettingsTab: {
            settingsList: TimesSettingsBox[];
            errors: { [key in TimesSettingsErrors]: boolean };
            isDirty: boolean;
        };
        courseStationsTab: {
            errors: StationsErrors;
            isLocationChanged: boolean;
            lastIndexEdited: number | null;
            updateFormFromLocationTrigger: {
                counter: number;
                newData: StationSchema | null;
            };
            isConfirmLocationButton: boolean;
        };
        map: {
            isExpanded: boolean;
            fieldsCopy: StationSchema[];
            isOnEditMode: boolean;
            editedStationId: string | null;
            updatedCoords: {
                lat: number;
                lng: number;
            } | null;
            triggerFieldCoordsUpdateCount: number;
            triggerCenterUpdateCount: number;
            centerCoords: Coords | null;
            totalDistance: number | null;
        };
        triggers: {
            formStations: {
                count: number;
                data: null | StationSchema[];
            };
            directionsApi: {
                count: number;
            };
        };
    };
}

export type ApiCallsNames = keyof DataState['apiData'];

export interface ApiData<T> {
    status: ApiCallStatus;
    data: T;
    triggerCount: number | null;
}
export interface DataState {
    apiData: {
        getAccountCourses: ApiData<ExtendedCourse[]>;
        getDepartments: ApiData<BaseDepartment[]>;
        getCourseTimes: ApiData<IExtendedCourseData[]>;
        getCourseStations: ApiData<ICourseStation[]>;
        getLineTypes: ApiData<
            {
                lineType: string;
                lineTypeCode: string;
            }[]
        >;
        getCourseTimesSettings: ApiData<TimesSettingsBox[]>;
    };
}

export interface CoursesBuildingSliceState {
    ui: UiState;
    data: DataState;
}
