import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { fontFamilies, colors, fontSizes, msColors } from '../../../../../style/themes/defaultTheme';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const menuContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 0 10px 0 0;
    justify-content: grid-end;
    align-items: center;
`;
const BtnsContainer = styled.div`
    // background-color: orange;
    display: flex;
    justify-content: flex-end;
`;

export const MenuButton = muiStyled(LoadingButton)({
    color: colors.blue,
    '&	.MuiButton-startIcon': {
        paddingLeft: '10px',
        color: msColors.primaryTextcolor,
    },
});
const menuStyles = { menuContainer, BtnsContainer };
export const buttonSx = {
    fontSize: fontSizes.m,
    fontFamily: fontFamilies.Rubik,
    fontWeight: '400',
};
export default menuStyles;
