import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import styles from 'src/screens/RideSettings/styles/styledCSS';
import { useAppDispatch } from 'src/store/hooks';
import { StyledToggleButton, StyledToggleButtonGroup } from 'src/style/styledMuiComponents';
import { Permission, SlPermissionTypes } from 'src/types/rideSettings/store/types.store';

import { getFormattedNewSettings, getLvlsWithPerm, prmsAreEquals } from '../../../utils/general';

interface Props {
    initialSettings: Permission | undefined;
    setNewSettingsData: React.Dispatch<
        React.SetStateAction<{
            DEPARTMENT_MGR: Permission | null;
            WEB_ACCESS: Permission | null;
        }>
    >;
    permissionType: SlPermissionTypes;
}

const ToggleBtns: FC<Props> = ({ initialSettings, permissionType, setNewSettingsData }) => {
    // * Hooks
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // * Local States
    const [havePermissions, setHavePermissions] = useState<SlPermissionTypes[]>(
        getLvlsWithPerm(initialSettings)
    );

    const [wasEdited, setWasEdited] = useState(false);

    // * Handlers
    const handleFormat = (event: React.MouseEvent<HTMLElement>, newHavePermissions: SlPermissionTypes[]) => {
        setHavePermissions(newHavePermissions);

        if (prmsAreEquals(initialSettings, newHavePermissions)) return;

        setWasEdited(true);
    };
    // * Effects
    // & handle the form changed event
    useEffect(() => {
        if (wasEdited) {
            setNewSettingsData((prev) => {
                return {
                    ...prev,
                    [permissionType]: getFormattedNewSettings(havePermissions, initialSettings),
                };
            });
        }
    }, [dispatch, havePermissions, initialSettings, permissionType, setNewSettingsData, wasEdited]);

    // // & if form rendered after first render, it means it was edited
    // useEffect(() => {
    //    // if (!isFirstRender) setWasEdited(true);
    // }, [isFirstRender]);

    return (
        <StyledToggleButtonGroup
            fullWidth
            // sx={styles.toggleBtnGroup}
            value={havePermissions}
            onChange={handleFormat}
        >
            <StyledToggleButton value="level1">{`${t('level')} 1`}</StyledToggleButton>
            <StyledToggleButton value="level2">{`${t('level')} 2`}</StyledToggleButton>
            <StyledToggleButton value="level3">{`${t('level')} 3`}</StyledToggleButton>
            <StyledToggleButton value="level4">{`${t('level')} 4`}</StyledToggleButton>
            <StyledToggleButton value="level5">{`${t('level')} 5`}</StyledToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default ToggleBtns;
