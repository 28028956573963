import React from 'react';
import { useFormContext, Controller, Path, UseFormSetValue } from 'react-hook-form';
import DropDown from 'src/components/DropDown/DropDown';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import { TripDirections } from 'src/types/line';
import { useTranslation } from 'react-i18next';
import useSetValue from 'src/hooks/reactHookForm/useSetValue';
import { Inputs } from '../../../types';
import { DEFAULT_TIME_VALUE } from '../../../constants';

type Props = { disabled: boolean };

export const useTripDirectionHelpers = () => {
    const fullFormMethods = useFormContext<Inputs>();
    const [setValue] = useSetValue<Inputs>();

    const resetAllFields = (
        resetSingleField: (field: Path<Inputs>) => void,
        newTripDirectionValue: TripDirections
    ) => {
        switch (newTripDirectionValue) {
            case TripDirections.OneWay:
                resetSingleField('returnTripEndTime');
                resetSingleField('returnTripStartTime');
                resetSingleField('endTime');
                break;
            case TripDirections.Linked:
                resetSingleField('returnTripEndTime');
                resetSingleField('returnTripStartTime');
                break;
            default:
                break;
        }
    };

    const resetUnusedTimeFields = (newTripDirectionValue: TripDirections) => {
        const resetField = (field: Path<Inputs>) => {
            setValue(field, fullFormMethods.setValue, DEFAULT_TIME_VALUE);
        };

        resetAllFields(resetField, newTripDirectionValue);
    };

    // todo: refactor this into the v1 function above (resetUnusedTimeFields)
    const resetUnusedTimeFieldsV2 = (
        newTripDirectionValue: TripDirections,
        setValueArg: UseFormSetValue<Inputs>
    ) => {
        const resetField = (field: Path<Inputs>) => {
            setValueArg(field, DEFAULT_TIME_VALUE);
        };

        resetAllFields(resetField, newTripDirectionValue);
    };

    return { resetUnusedTimeFields, resetUnusedTimeFieldsV2 };
};

export default function TripDirectionDropdown({ disabled }: Props) {
    const fullFormMethods = useFormContext<Inputs>();
    const { t } = useTranslation();
    const { control } = fullFormMethods;

    const { resetUnusedTimeFields } = useTripDirectionHelpers();

    return (
        <InputWithErrorsWrapper style={{ flex: 1 }}>
            <Controller
                control={control}
                name="tripDirection"
                render={({ field }) => {
                    const { OneWay, Linked, RoundTrip } = TripDirections;
                    return (
                        <DropDown
                            {...{
                                ...field,
                                formControlProp: {
                                    variant: 'outlined',
                                    style: { width: '100%' },
                                    size: 'small',
                                },
                                labalName: t('tripDirection'),
                                label: t('tripDirection'),
                                menueItem: [
                                    { name: t(OneWay), value: OneWay },
                                    { name: t(Linked), value: Linked },
                                    { name: t(RoundTrip), value: RoundTrip },
                                ],
                                onChange: (e) => {
                                    if (Object.values(TripDirections).includes(e.target.value as any)) {
                                        resetUnusedTimeFields(e.target.value as TripDirections);
                                    }
                                    field.onChange(e);
                                },
                                disabled,
                            }}
                        />
                    );
                }}
            />
        </InputWithErrorsWrapper>
    );
}
