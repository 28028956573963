export enum SingupType {
    Quick = 1,
    PinCode,
    User,
    OnTimeLogin,
}

export enum RefreshTokenStatus {
    Init = 1,
    Ok,
    Invalid,
}

export interface IAccount {
    accountCode: number; // eg 5 (Maya Tour)
    accountName: string; // eg Maya Tour
    accountGuid?: string;
    contactUUID: string; // ? Id of contact signed in to Studio in selected...
    // ? ... account's DB?
    dbUrl: string; // Maya tours Db url
    proxyUrl?: string; // ?
}

export enum ResPinCodeSignup {
    SUCCESS = '0',
    MESS_TOKEN = '1',
    WRONG_PIN_CODE = '2',
    EXPIRED_TOKEN = '3',
    INVALID_TOKEN = '4',
    WRONG_PIN_CODE_TO_MANY_TIMES = '9',
    SQL_ERROR = '99',
}
export enum ResPinCodeLogin {
    SUCCESS = '0',
    INVALID_INPUTS = '1',
    INVALID_TOKEN = '2',
    WRONG_PIN_CODE = '3',
}

export enum ResUserLogin {
    SUCCESS = '0',
    MISSING_DETAILES = '1',
    WRONG_USER_OR_CODE = '2',
}
