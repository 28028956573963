import React from 'react';

import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { CourseDetailsFormSchema } from 'src/types/coursesBuilding/form/formTypes';
import { useTranslation } from 'react-i18next';
import TextInputV3 from 'src/components/ReactHookFormInputs/TextInputV3';
import { CourseDirection } from 'src/types/passengersManager/courseChoosingTypes';
import RHFSelect, { RHFSelectProps } from 'src/components/ReactHookFormInputs/Select';
import { useDepartmentMenuItems } from 'src/screens/CoursesBuilding/hooks/filtersHooks';
import { FillerValues } from 'src/constants/misc';
import CarTypeDropdownV2 from 'src/components/ReactHookFormInputs/CarTypeDropdownV2/CarTypeDropdownV2';
import commonRoutes from 'src/mockServer/routes/commonRoutes';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import { useApiCallStatusUpdate } from 'src/screens/CoursesBuilding/hooks/useAllCoursesFetch';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    coursesBuildingSelectors,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { addWithoutMenuItem } from 'src/utilis/utilis';
import RHFDatePicker from 'src/components/ReactHookFormInputs/DatePicker/RHFDatePicker';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Spinner from 'src/components/Spinner/Spinner';

type CourseDetailsField = ControllerRenderProps<CourseDetailsFormSchema, any>;

const useDropdownProps = () => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useFormContext<CourseDetailsFormSchema>();

    const getCourseDirectionDropdownProps = (
        field: CourseDetailsField
    ): RHFSelectProps<CourseDetailsField> => {
        return {
            field,
            labelName: t('courseDirection'),
            errors: errors[field.name],
            selectProps: {
                menuItems: [
                    { name: 'ללא', value: FillerValues.Without },
                    { name: '1', value: CourseDirection.Start },
                    { name: '2', value: CourseDirection.Return },
                    { name: '3', value: CourseDirection.RoundTrip },
                ],
            },
        };
    };

    const deptMenuItems = useDepartmentMenuItems();

    const getDeptProps = (field: CourseDetailsField): RHFSelectProps<CourseDetailsField> => {
        return {
            field,
            labelName: t('department'),
            errors: errors[field.name],
            selectProps: {
                menuItems: deptMenuItems.menuItems.filter((item) => item.value !== FillerValues.All),
            },
        };
    };

    const lineTypesApiResult = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getLineTypes
    );

    const getLineTypesProps = (field: CourseDetailsField) => {
        let menuItems = [];

        if (lineTypesApiResult.status === 'SUCCESS') {
            menuItems = addWithoutMenuItem(
                lineTypesApiResult.data.map((lineType) => ({
                    name: lineType.lineType,
                    value: lineType.lineTypeCode,
                }))
            );
        }

        return {
            field,
            labelName: t('lineType'),
            errors: errors[field.name],
            selectProps: {
                menuItems,
            },
        };
    };

    const updateValue = (name: string, newValue: any) => {
        setValue(name as any, newValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    const activeFromDateProps = {
        currValue: watch('activeFromDate'),
        errors: errors['activeFromDate'],
        setValue: (value: Date | null | undefined | '') => {
            updateValue('activeFromDate', value);
        },
        label: 'מתאריך',
    };

    const activeUntilDateProps = {
        currValue: watch('activeUntilDate'),
        errors: errors['activeUntilDate'],
        setValue: (value: Date | null | undefined | '') => {
            updateValue('activeUntilDate', value);
        },
        label: 'עד תאריך',
    };

    return {
        getCourseDirectionDropdownProps,
        getDeptProps,
        getLineTypesProps,
        activeFromDateProps,
        activeUntilDateProps,
    };
};

export default useDropdownProps;
