import React, { useCallback, useEffect, useState } from 'react';
import useCommons from 'src/hooks/common/useCommons';
import { formControlProp, inputsStyle } from 'src/components/Inputs/misc';
import { courseTypesMenuItems } from '../constants';
import { CoursesFilters, enums } from 'src/types/coursesBuilding/commonTypes';
import { MuiCoreChangeEvent } from 'src/types/inputs';
import useDebounce from 'src/hooks/common/useDebounce';
import {
    coursesBuildingSelectors,
    filtersSelector,
    resetFiltersAction,
    setFilterAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { getDepartmentFilterMenuItems } from 'src/utilis/utilis';
import { MenuItemProps } from '../../../components/DropDown/DropDown';
import i18n from 'src/i18n';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { FillerValues } from 'src/constants/misc';
import typeGuards from 'src/utilis/typeGuards';

const { apiDataSelector } = coursesBuildingSelectors;

const useDepartmentFilterAuth = () => {
    const { t, dispatch, useRootAppSelector } = useCommons();

    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;

    const filtersVals = useRootAppSelector(filtersSelector);

    useEffect(() => {
        if (!filtersVals.departmentId && loggedUserDepartmentCode) {
            dispatch(setFilterAction({ filter: 'departmentId', value: loggedUserDepartmentCode }));
        }
    }, [dispatch, filtersVals.departmentId, loggedUserDepartmentCode]);

    return { loggedUserDepartmentCode };
};

export const useDepartmentMenuItems = () => {
    const { useRootAppSelector } = useCommons();
    const { loggedUserDepartmentCode } = useDepartmentFilterAuth();

    const departments = useRootAppSelector((state) => apiDataSelector(state).getDepartments);

    const menuItems = React.useMemo(
        () =>
            getDepartmentFilterMenuItems(
                departments.status === 'SUCCESS' ? departments.data : [],
                loggedUserDepartmentCode
            ),
        [departments.data, departments.status, loggedUserDepartmentCode]
    );

    return { menuItems };
};

const buildCourseIdMenuItems = (allCourses: WsCourseDataItem[]) => {
    const menuItems: MenuItemProps[] = [
        {
            name: i18n.t('all'),
            value: FillerValues.All,
        },
        {
            name: i18n.t('without'),
            value: FillerValues.Without,
        },
    ];

    allCourses.forEach((course) => {
        const exists = menuItems.find((item) => item.value === course.courseId);
        if (exists) return;

        if (course.courseId) {
            menuItems.push({
                name: course.courseId,
                value: course.courseId,
            });
        }
    });

    return menuItems;
};
const useFiltersDropdownsProps = () => {
    const { t, dispatch, useRootAppSelector } = useCommons();

    const isFirstRender = useIsFirstRender();

    const filtersVals = useRootAppSelector(filtersSelector);
    const deptMenuItems = useDepartmentMenuItems();
    const { getAccountCourses } = useRootAppSelector((state) => apiDataSelector(state));

    const [searchVal, setSearchVal] = useState('');
    const debouncedSearchVal = useDebounce(searchVal, 300);

    const dispatchFilterUpdate = useCallback(
        (filter: CoursesFilters, newVal: any) => {
            dispatch(setFilterAction({ filter, value: newVal }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (isFirstRender) return;

        dispatchFilterUpdate('freeSearch', debouncedSearchVal);
    }, [debouncedSearchVal, dispatchFilterUpdate, isFirstRender]);

    const searchDDProp = React.useMemo(
        () => ({
            labalName: t('search'),
            placeholder: t('search'),
            onChange: (e: any) => {
                if (typeGuards.hasEventValue(e)) {
                    setSearchVal(String(e.target.value));
                }
            },
            value: filtersVals.freeSearch,
            formControlProp,
            style: inputsStyle,
        }),
        [filtersVals.freeSearch, t]
    );

    const departmentDDProp = React.useMemo(
        () => ({
            labalName: t('department'),
            menueItem: deptMenuItems.menuItems,
            onChange: (e: MuiCoreChangeEvent) => {
                dispatchFilterUpdate('departmentId', e.target.value);
            },
            value: filtersVals.departmentId,
            formControlProp,
            style: inputsStyle,
        }),
        [deptMenuItems.menuItems, dispatchFilterUpdate, filtersVals.departmentId, t]
    );

    const courseIdDDProp = React.useMemo(
        () => ({
            value: filtersVals.courseId,
            labalName: t('lineNumber'),
            menueItem: buildCourseIdMenuItems(getAccountCourses.data),
            onChange: (e: MuiCoreChangeEvent) => {
                dispatchFilterUpdate('courseId', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
        }),
        [dispatchFilterUpdate, filtersVals.courseId, getAccountCourses.data, t]
    );

    const courseTypeDDProp = React.useMemo(
        () => ({
            labalName: t('courseStatus'),
            menueItem: courseTypesMenuItems,
            onChange: (e: MuiCoreChangeEvent) => {
                dispatchFilterUpdate('courseType', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
            defaultValue: enums.CourseTypes.Active,
            value: filtersVals.courseType,
        }),
        [dispatchFilterUpdate, filtersVals.courseType, t]
    );

    return { searchDDProp, departmentDDProp, lineNumDDProp: courseIdDDProp, courseTypeDDProp };
};

const filtersHooks = {
    useFiltersDropdownsProps,
};

export default filtersHooks;
