import { AxiosResponse } from 'axios';
import i18n from 'src/i18n';
import { CommonCodes } from './mockServer/routes.types';

export type ApiCallStatus = 'ON_STAND_BY' | 'SUCCESS' | 'LOADING' | 'FAILED';

/**
 * A response from the WebService.
 * @typedef {Object} WsResponse
 * @typedef {Object} T the data type
 * @property {string} response - The response from the server.
 * @property {T} data - The data from the server.
 */

export interface WsResponse<T> {
    response: '0' | '1' | '99' | string | number;
    data: T;
}

export type AxiosRes<T> = Promise<AxiosResponse<T>>;

export enum ResponseTypes {
    Ok = '0',
    TokenExpiredOrDoesntExist = '1',
    MissingProperties = '2',
    SqlError = '99',
}

export interface ReqConfig<T> {
    dbUrl: string;
    requestParams: T;
    proxyUrl?: string;
    timeout?: number; // only for v2 of makeApiCall
}

export enum HttpStatusCodes {
    Ok = 200,
    MissingWs = 202,
    NotFound = 404,
}

export enum ErrorMessages {
    MissingWs = 'missingWs',
    GeneralError = 'oopsSomethingWentWrongTryAgain',
    SqlError = 'oopsSomethingWentWrongTryAgain',
    BadToken = 'expiredTokenRefreshPage',
    ExpiredToken = 'expiredTokenRefreshPage',
    ExceptionCaught = 'oopsSomethingWentWrongTryAgain',
    AuthenticationError = 'authenticationError',
    OperationFailed = 'operationFailed',
}

export const commonErrorMessagesByCode = {
    [CommonCodes.BadToken]: ErrorMessages.ExpiredToken,
    [CommonCodes.MissingWs]: ErrorMessages.MissingWs,
    [CommonCodes.UnknownError]: ErrorMessages.GeneralError,
    [CommonCodes.UnknownFailure]: ErrorMessages.GeneralError,
    [CommonCodes.ExceptionCaught]: ErrorMessages.ExceptionCaught,
    [CommonCodes.OperationFailed]: ErrorMessages.OperationFailed,
};

export const errorMessagesI18 = {
    missingWs: i18n.t(ErrorMessages.MissingWs),
    generalError: i18n.t(ErrorMessages.GeneralError),
};

export interface Result<T> {
    code: number;
    data: {
        data: null | T;
        message: null | string; // should only be truthy if request failed!
    };
}
