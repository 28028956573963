/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ControlledManualAndGoogleAddressInput from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import { styles } from '../../formSections/components/styles';
import StationPassengersAccordion from '../../formSections/components/stations/PassengersAccordionForStation/StationPassengersAccordion';
import StationActionsMenu from '../../formSections/components/stations/StationActionsMenu/StationActionsMenu';
import { stationInputHooks as hooks } from './hooks.StationInput';
import { fullFormHooks } from '../../formSections/hooks.FullForm';

type Props = {
    stationId: string;
    stationIndex: number;
    removeStation: Function;
    isDisabled: boolean;
};

const StationInput: React.FunctionComponent<Props> = ({
    stationIndex,
    stationId,
    removeStation: removeStationInput,
    isDisabled,
}) => {
    // -- local states
    // const [isInputEmpty, setIsInputEmpty] = useState();

    // -- utility hooks
    const [handleClick] = hooks.useStationPassengers(stationId);

    const { passengersOnStationFullData, currStation } = hooks.useStationData(stationId);

    const { getAddressProps } = hooks.useProps(stationId, stationIndex);

    // -- jsx
    return (
        <div style={styles.stationInputContainer}>
            <div style={styles.inputAndPassengerAddingBtnContainer}>
                <ControlledManualAndGoogleAddressInput
                    {...{ ...getAddressProps(), disabled: isDisabled, stationIndex, currStation }}
                />
                <StationActionsMenu
                    stationInputConfig={{
                        removeStationInput: () => removeStationInput(stationId),
                    }}
                    passengersConfig={{
                        handleClick,
                        isDisabled: !currStation,
                    }}
                    isDisabled={isDisabled}
                />
            </div>
            <div style={{ paddingTop: '8px' }}>
                <StationPassengersAccordion
                    passengers={passengersOnStationFullData}
                    stationId={stationId}
                    actionsAreDisabled={isDisabled}
                />
            </div>
        </div>
    );
};

export default StationInput;
