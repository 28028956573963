/* eslint-disable no-continue */
import React, { useState } from 'react';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    formTriggerSelector,
    setIsDisabledAction,
    setTotalDistanceAction,
    setUpdateFormFromLocationTriggerAction,
    triggerFieldUpdateAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

import { DirectionsRendererProps } from '@react-google-maps/api';
import _ from 'lodash';
import { Coords } from 'google-map-react';
import moment from 'moment';
import { StationSchema } from 'src/types/coursesBuilding/form/formTypes';
/* eslint-disable import/no-cycle */
import { isValidCoords } from '../components/CourseMap/CourseWidgetMap';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useTranslation } from 'react-i18next';
import { updateGoogleApiCounter } from 'src/utilis/utilis';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiCounterExceededPopUpOpenAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from 'src/store/slices/common/commonSlice';

const EMPTY_STATIONS_COORDS_JSON = JSON.stringify([
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 },
]);

const TIME_FORM_PREV_KEY = 'timeFromPrev';

//  2am date
const today = new Date();
// today.setHours(2, 0, 0, 0);
const departureTime = moment(today).add(1, 'day').toDate();

type ServiceResponseCallBack = (
    a: google.maps.DirectionsResult | null,
    b: google.maps.DirectionsStatus
) => void;

const allCoordsAreValid = (coords: Coords[]) => {
    return coords.every((coord) => isValidCoords(coord));
};

const getDistance = (legs: google.maps.DirectionsLeg[]) => {
    const totalDistanceInKm = legs.reduce((acc, curr) => acc + (curr?.distance?.value || 0), 0) / 1000;

    return Number(totalDistanceInKm.toFixed(1));
};

const badResponsesLst = [
    google.maps.DirectionsStatus.INVALID_REQUEST,
    google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED,
    google.maps.DirectionsStatus.OVER_QUERY_LIMIT,
    google.maps.DirectionsStatus.REQUEST_DENIED,
    google.maps.DirectionsStatus.UNKNOWN_ERROR,
];

const getStationsWithUpdatedTimeFromPrev = (
    legs: google.maps.DirectionsLeg[],
    fieldsCopy: StationSchema[],
    isLocationChanged: boolean
): StationSchema[] | -1 => {
    const clonedFields = _.cloneDeep(fieldsCopy);

    try {
        for (let i = 0; i < clonedFields.length; i++) {
            const station = clonedFields[i];

            // to avoid overriding the initial time user entered

            if (station.isManualTimeFromPrev && !isLocationChanged) {
                continue;
            }

            if (i === 0) {
                // first station should not have time from previous station
                station[TIME_FORM_PREV_KEY] = 0;
                station.isManualTimeFromPrev = false;
                continue;
            }

            const currLeg = legs[i - 1];
            if (currLeg) {
                const newValue = currLeg.duration_in_traffic?.value || currLeg.duration?.value || 0;
                station[TIME_FORM_PREV_KEY] = Math.round(newValue / 60); // to get minutes value
                station.isManualTimeFromPrev = false;
            }
        }

        return clonedFields;
    } catch (error) {
        console.error(error);
        return -1;
    }
};

export const useGoogleApiCounterUp = () => {
    const googleApiInterfaceInterval = useRootAppSelector(
        (state) => state.commonSlice.googleApiInterfaceInterval
    );
    const selectedFcAccount = useRootAppSelector((state) => state.loginReducer.selectedFcAccount);
    const dispatch = useAppDispatch();

    const googleApiCounterUp = () => {
        if (selectedFcAccount && googleApiInterfaceInterval) {
            updateGoogleApiCounter(selectedFcAccount.accountCode, googleApiInterfaceInterval).then((data) => {
                if (data !== null) {
                    dispatch(setGoogleApiInterfaceIntervalAction({ interval: data.ClientsRequestsInterval }));
                    dispatch(
                        setIsGoogleApiInterfaceCounterExceededAction({ isExceeded: data.IsCounterExceeded })
                    );
                    dispatch(
                        setIsGoogleApiCounterExceededPopUpOpenAction({ isOpen: data.IsCounterExceeded })
                    );
                }
            });
        }
    };

    return {
        googleApiCounterUp,
    };
};

const useDirections = ({ toggleFullView }: { toggleFullView: () => void }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isLocationChanged = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.isLocationChanged
    );
    const lastIndexEdited = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.lastIndexEdited
    );
    const updateFormFromLocationTriggerCounter = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.updateFormFromLocationTrigger.counter
    );

    const googleCounterUp = useGoogleApiCounterUp();

    const fieldsCopy = useRootAppSelector((state) => courseBuildingFormSelector(state).map.fieldsCopy);
    const directionsApiTriggerCount = useRootAppSelector(
        (state) => formTriggerSelector(state).directionsApi.count
    );
    const [rendererProps, setRendererProps] = useState<DirectionsRendererProps | null>(null);

    const [fieldsCoords, setFieldsCoords] = useState<string>('');

    React.useEffect(() => {
        // wait 200 ms
        // const timeout = setTimeout(() => {
        setFieldsCoords(
            JSON.stringify(
                fieldsCopy.map((field) => ({
                    lat: field.lat,
                    lng: field.lng,
                }))
            )
        );
        // }, 200);

        // return () => clearTimeout(timeout);
    }, [fieldsCopy]);

    // fetchDirections as promise
    const fetchDirections = (directionsService: google.maps.DirectionsService) => {
        return new Promise((resolve) => {
            try {
                let origin = { lat: fieldsCopy[0]?.lat, lng: fieldsCopy[0]?.lng };

                let destination = {
                    lat: fieldsCopy[fieldsCopy.length - 1].lat,
                    lng: fieldsCopy[fieldsCopy.length - 1].lng,
                };

                const waypoints = fieldsCopy.slice(1, fieldsCopy.length - 1).map((station) => {
                    return {
                        location: { lat: station.lat, lng: station.lng },
                        stopover: true,
                    };
                });

                // Avoid sending empty coords to google maps
                // if (!allCoordsAreValid([origin, destination, ...waypoints.map((wp) => wp.location)])) {
                //     setRendererProps(null);
                //     resolve(-1);
                //     return;
                // }

                if (fieldsCopy.length === 2) {
                    if (
                        origin.lat === 0 &&
                        origin.lng === 0 &&
                        destination.lat !== 0 &&
                        destination.lng !== 0
                    ) {
                        origin = destination;
                    }

                    if (
                        destination.lat === 0 &&
                        destination.lng === 0 &&
                        origin.lat !== 0 &&
                        origin.lng !== 0
                    ) {
                        destination = origin;
                    }
                }

                const request: google.maps.DirectionsRequest = {
                    origin,
                    destination,
                    waypoints,
                    travelMode: google.maps.TravelMode.DRIVING,
                    avoidTolls: true,
                    drivingOptions: {
                        departureTime,
                        trafficModel: google.maps.TrafficModel.OPTIMISTIC,
                    },
                };

                const serviceResponseCb: ServiceResponseCallBack = (response, status) => {
                    googleCounterUp.googleApiCounterUp();

                    if (status === google.maps.DirectionsStatus.OK && response) {
                        // update to the address input by the directions api

                        const fieldsForUpdate = _.cloneDeep(fieldsCopy);

                        if (
                            lastIndexEdited !== null &&
                            response.routes &&
                            response.routes[0] &&
                            response.routes[0].legs
                        ) {
                            let lastEditedTextForUpdate = '';

                            if (
                                fieldsForUpdate.length - 1 === lastIndexEdited &&
                                response.routes[0].legs[lastIndexEdited - 1] &&
                                response.routes[0].legs[lastIndexEdited - 1].end_address
                            ) {
                                lastEditedTextForUpdate =
                                    response.routes[0].legs[lastIndexEdited - 1].end_address;
                            } else if (
                                response.routes[0].legs[lastIndexEdited] &&
                                response.routes[0].legs[lastIndexEdited].start_address
                            ) {
                                lastEditedTextForUpdate =
                                    response.routes[0].legs[lastIndexEdited].start_address;
                            }

                            if (
                                fieldsForUpdate &&
                                fieldsForUpdate[lastIndexEdited] &&
                                fieldsForUpdate[lastIndexEdited].city
                            ) {
                                fieldsForUpdate[lastIndexEdited].city = lastEditedTextForUpdate;
                            }

                            dispatch(
                                setUpdateFormFromLocationTriggerAction({
                                    data: fieldsForUpdate[lastIndexEdited],
                                    counter: updateFormFromLocationTriggerCounter + 1,
                                })
                            );
                        }

                        setRendererProps({
                            directions: response,
                            options: {
                                suppressMarkers: true,
                                preserveViewport: true,
                            },
                        });

                        // To center the map on the route
                        toggleFullView();

                        const firstRouteLegs = response.routes[0].legs;

                        const stationsWithUpdatedTimesFromPrev = getStationsWithUpdatedTimeFromPrev(
                            firstRouteLegs,
                            fieldsCopy,
                            isLocationChanged
                        );

                        // Setting route distance on slice
                        dispatch(setTotalDistanceAction(getDistance(firstRouteLegs) || null));

                        if (stationsWithUpdatedTimesFromPrev === -1) {
                            resolve(0);
                            return;
                        }

                        // trigger stations field update on form
                        dispatch(triggerFieldUpdateAction(stationsWithUpdatedTimesFromPrev));

                        //  resolve with 0 to indicate success
                        resolve(0);
                        return;
                    }

                    if (badResponsesLst.includes(status)) {
                        console.log(status);
                        dispatch(setErrorMessage(t('errorCreatingCourseTryAgain')));
                    }

                    //  resolve with -1 to indicate error
                    setRendererProps(null);
                    resolve(-1);
                };

                directionsService.route(request, serviceResponseCb);
            } catch (error) {
                console.error(error);
                setRendererProps(null);
                //  resolve with -1 to indicate error
                resolve(-1);
            }
        });
    };

    React.useEffect(() => {
        if (fieldsCoords === EMPTY_STATIONS_COORDS_JSON || directionsApiTriggerCount === 0) return;
        const handleFetchingDirections = async () => {
            dispatch(setIsDisabledAction(true));

            // Delay the fetch to avoid fetching before the map is ready
            const directionsService = new google.maps.DirectionsService();

            await fetchDirections(directionsService);

            dispatch(setIsDisabledAction(false));
        };

        handleFetchingDirections();
    }, [fieldsCoords, directionsApiTriggerCount]);

    return { rendererProps };
};

export default useDirections;
