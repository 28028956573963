import styled, { css, CSSObject, CSSProperties } from 'styled-components';

const ScreenContainer = styled.div`
    height: 100%;
    display: flex;
`;
export const MainSectionContainer = styled.div<{
    style?: CSSObject;
    mediaQuery?: Object;
}>`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    flex: 2;
    width: 100%;
    ${({ style }) =>
        css`
            ${style}
        `}
`;
const WidgetsContainer = styled.div<{
    isOpen?: boolean;
    style?: CSSObject;
}>`
    background: #ffedb1;
    display: flex;
    box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.25);
    width: 100%;
    flex: 1;
    padding: 0px 30px;
    overflow-y: auto;
    -moz-transition: 0.25s ease -in -out;
    -o-transition: 0.25s ease -in -out;
    transition: 0.25s ease -in -out;
    ${({ isOpen }) => css`
        ${isOpen
            ? `
              min-width: 480px;
              `
            : `
              max-width: 0;
              padding-right: 0;
           `};
    `};
    ${({ style }) =>
        css`
            ${style};
        `}
`;

export const containers = {
    ScreenContainer,
    WidgetsContainer,
    MainSectionContainer,
};
