/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { ClientCodePayload, RideSettingsState } from 'src/types/rideSettings/store/types.store';

const onSelectClientId = (state: RideSettingsState, action: PayloadAction<ClientCodePayload>) => {
    state.clientData.clientCode = action.payload.clientCode;
};

const clientDataReducers = { onSelectClientId };
export default clientDataReducers;
