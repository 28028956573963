import React, { useMemo } from 'react';
import useCommons from 'src/hooks/common/useCommons';
import { ErrorMessages } from 'src/types/apiCommon.types';
import {
    removeCourseByCodeAction,
    restoreModalSelector,
    selectedClientCodeSelector,
    toggleRestoreModalAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { useDispatchAlert } from 'src/hooks/useAlert';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import { DEFAULT_BODY_STYLE } from 'src/components/Modals/ActionConfirmModal';
import useCoursesData from './useCourseData';
import { images } from 'src/assets';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const useRestoreModal = () => {
    const { dispatch, dispatchI18nErrMsg, t, useRootAppSelector } = useCommons();

    const { getRouteClientData } = useBaseApiParams();

    const dispatchAlert = useDispatchAlert();

    const { isOpen, courseCode } = useRootAppSelector(restoreModalSelector);

    const selectedClientCode = useRootAppSelector(selectedClientCodeSelector);

    const { getCourseByCode } = useCoursesData();

    const courseData = useMemo(() => getCourseByCode(courseCode), [courseCode, getCourseByCode]);

    const [isLoadingRestore, setIsLoadingRestore] = React.useState(false);

    const onConfirmClick = async () => {
        try {
            if (!courseData) {
                throw new Error('Course data not found');
            }

            setIsLoadingRestore(true);

            const routeClientData = getRouteClientData({ ...courseData, clientCode: selectedClientCode });

            const routeRes = await coursesBuildingRoutes.restoreCourse(routeClientData as any);

            setIsLoadingRestore(false);

            // Success
            if (routeRes.code === 200) {
                dispatchAlert('success', t('operationWasSuccessful'));
                // remove course from slice by code
                dispatch(removeCourseByCodeAction(courseData.courseCode));
            } else {
                // Failure
                dispatchI18nErrMsg(routeRes.data.message || ErrorMessages.OperationFailed);
            }

            // Closing modal
            dispatch(toggleRestoreModalAction({ isOpen: false, courseCode: '' }));
        } catch (err) {
            console.log('error while restoring course', err);
            dispatch(toggleRestoreModalAction({ isOpen: false, courseCode: '' }));
            setIsLoadingRestore(false);
            dispatchI18nErrMsg(ErrorMessages.OperationFailed);
        }
    };

    const onClose = () => {
        dispatch(toggleRestoreModalAction({ isOpen: false, courseCode: '' }));
    };

    const Body = () => {
        return (
            <div style={DEFAULT_BODY_STYLE}>
                <p style={{ fontSize: '20px' }}>{t('restoreThisCourseQ')}</p>
                <p>{courseData?.courseDescription}</p>
            </div>
        );
    };

    return {
        props: {
            isOpen,
            Body,
            onConfirm: onConfirmClick,
            onClose,
            loading: isLoadingRestore,
            Img: () => <SettingsBackupRestoreIcon fontSize="large" htmlColor="#494949" />,
        },
    };
};

export default useRestoreModal;
