/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import React, { PureComponent, useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import { IconButton, withStyles, createStyles } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import heLocal from 'date-fns/locale/he';
import moment from 'moment';
import { getWeekStartAndEndDates } from 'src/utilis/utilis';
import DatePickerArrows from './DatePickerArrows';

export interface IWeekDatePicker {
    prop: KeyboardDatePickerProps;
    withArrows?: boolean;
    firstDayOfWeek?: number;
    classes?: any;
}

class WeekDatePicker extends PureComponent<IWeekDatePicker> {
    renderWrappedWeekDay = (
        date: MaterialUiPickersDate,
        selectedDate: MaterialUiPickersDate,
        dayInCurrentMonth: boolean
    ): JSX.Element => {
        const { classes, firstDayOfWeek } = this.props;

        const dateClone = moment(date);
        const selectedDateClone = moment(selectedDate);

        let start;
        let end;

        if (typeof firstDayOfWeek === 'number') {
            const datesResult = getWeekStartAndEndDates(firstDayOfWeek, selectedDateClone);
            start = datesResult.start;
            end = datesResult.end;
        } else {
            start = selectedDateClone.startOf('week').clone();
            end = selectedDateClone.endOf('week').clone();
        }

        const dayIsBetween = dateClone.isBetween(start, end, undefined, '()');
        const isFirstDay = dateClone.isSame(start, 'day');
        const isLastDay = dateClone.isSame(end, 'day');

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        // console.log({ selectedDate });
        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {dateClone.date()}</span>
                </IconButton>
            </div>
        );
    };

    render() {
        const { prop, withArrows, firstDayOfWeek } = this.props;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocal}>
                <KeyboardDatePicker
                    {...prop}
                    allowKeyboardControl
                    renderDay={this.renderWrappedWeekDay}
                    // keyboardIcon={<KeyboardArrowUpIcon />}
                />
                {withArrows && (
                    <DatePickerArrows
                        value={prop.value}
                        onChange={prop.onChange}
                        firstDayOfWeek={firstDayOfWeek}
                    />
                )}
            </MuiPickersUtilsProvider>
        );
    }
}

const styles = createStyles((theme: any) => ({
    dayWrapper: {
        position: 'relative',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    customDayHighlight: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '2px',
        right: '2px',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: 'highlight',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    endHighlight: {
        extend: 'highlight',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export default withStyles(styles)(WeekDatePicker);
