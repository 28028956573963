import { MenuItem, Tab, FormControl, InputLabel } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultMenuProps } from 'src/components/DropDown/DropDown';
import Spinner from 'src/components/Spinner/Spinner';
import { IDateRange } from 'src/types/global';
import { FilterBy } from '../../types';
import ProgressbarList from './ProgressbarList';
import * as Styles from './Summarycard.Style';
import useSummaryChart from './useSummaryChart';

interface Props {
    date: Date;
    clientCode: number;
    selectedWeek: IDateRange;
    selectedDepartmentCode: string;
    allDepartmentsCodes: string[];
}

const filters = [
    {
        value: 'מחלקות',
        id: FilterBy.Departments,
    },
    {
        value: 'משמרות',
        id: FilterBy.Shifts,
    },
];

const spinnerContainerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const useSpinner = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (!isFetching) {
            setTimeout(() => {
                setShowSpinner(false);
            }, 500);
        } else {
            setShowSpinner(true);
        }
    }, [isFetching]);

    return { showSpinner, setIsFetching };
};

const SummaryCard: FC<Props> = ({
    date,
    clientCode,
    selectedWeek,
    selectedDepartmentCode,
    allDepartmentsCodes,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [orderBy, setOrderBy] = useState(FilterBy.Shifts);

    const { showSpinner, setIsFetching } = useSpinner();

    const { daylyStatistics, weeklyStatistics, monthlyStatistics } = useSummaryChart(
        date,
        orderBy,
        clientCode,
        selectedWeek,
        setIsFetching,
        selectedDepartmentCode,
        allDepartmentsCodes
    );

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrderBy(event.target.value as number);
    };

    return (
        <Styles.Container>
            <FormControl variant="outlined">
                <InputLabel variant="outlined" id="summaryOrderBy-label">
                    {t('summaryOrderBy')}
                </InputLabel>

                <Styles.Dropdown
                    id="summaryOrderBy-label"
                    defaultValue={1}
                    label={t('summaryOrderBy')}
                    value={orderBy}
                    onChange={handleChangeSelect}
                    MenuProps={DefaultMenuProps}
                >
                    {filters.map((d) => (
                        <MenuItem value={d.id}>{d.value}</MenuItem>
                    ))}
                </Styles.Dropdown>
            </FormControl>
            <Styles.StyledTabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label={t('daily')} />
                <Tab label={t('weekly')} />
                <Tab label={t('monthly')} />
            </Styles.StyledTabs>
            {showSpinner ? (
                <Spinner containerStyle={spinnerContainerStyle} />
            ) : (
                <Styles.ProgressContainer>
                    {selectedTab === 0 && daylyStatistics && (
                        <ProgressbarList
                            dataType={orderBy === FilterBy.Departments ? 'depts' : 'shifts'}
                            data={daylyStatistics.data}
                            max={daylyStatistics.max}
                            releativeDate={daylyStatistics.datesRange}
                        />
                    )}
                    {selectedTab === 1 && weeklyStatistics && (
                        <ProgressbarList
                            dataType={orderBy === FilterBy.Departments ? 'depts' : 'shifts'}
                            data={weeklyStatistics.data}
                            max={weeklyStatistics.max}
                            releativeDate={weeklyStatistics.datesRange}
                        />
                    )}
                    {selectedTab === 2 && monthlyStatistics && (
                        <ProgressbarList
                            dataType={orderBy === FilterBy.Departments ? 'depts' : 'shifts'}
                            data={monthlyStatistics.data}
                            max={monthlyStatistics.max}
                            releativeDate={monthlyStatistics.datesRange}
                        />
                    )}
                </Styles.ProgressContainer>
            )}
        </Styles.Container>
    );
};

export default SummaryCard;
