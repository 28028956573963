import { LineActionType } from './actionType';
import { ReduxDispatch } from '../type';

export const onRetriveLinesSuccess = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LineActionType.retriveLineSuccess,
        payload,
    };
};

export const onRetriveLinesFaild = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LineActionType.retriveLineFaild,
        payload,
    };
};

export const onRetriveLines = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LineActionType.retriveLines,
        payload,
    };
};

export const onSelectedLineChange = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LineActionType.selectedLineChange,
        payload,
    };
};
export const removeLine = (payload: { deletedLineCode: number }) => {
    return {
        type: LineActionType.removeLine,
        payload,
    };
};
