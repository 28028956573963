import { select } from 'redux-saga/effects';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
// /* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PassengerDates } from 'src/screens/ManualOrder/utilis';
import { IRootReducer } from 'src/store/reducers';
import { isSameDate } from 'src/utilis/utilis';
import { DateAvailabilityStatus } from 'src/screens/ManualOrder/components/PlacemenTransportation/types';
import { IClient } from 'src/types/line';
import { IAddress, IAddressWithId, IAddressWithIdAndIsDefault } from 'src/types/misc';
import { Options } from 'src/screens/ManualOrder/ManualOrderTable/EmployeeColHeaderCell';
import { IPassengerShifts } from '../../../types/manualOrders/api.types';
import { ChartData } from 'src/screens/ManualOrder/components/Orderinfo/components/Summarycard/helpers';
import { ApiCallRequest } from '../../../api/reportsApi/types';
import { ApiCallStatus } from '../passengersManager/passengersManagerSlice';
import {
    PassengersDict,
    UiState,
    ManualOrdersSliceState,
    ShiftToDelete,
    ApiCallTypes,
    ShiftCapsulesByPassenger,
    PassengersOrdersCountPerDate,
} from './types';
import { PASSENGER_ID_INDEX, PASSENGER_LOCATION_INDEX, setCapsulesDeletionData } from './helpers';

// Define the initial state using that type

// const initialDataState = {};
const initialShiftDeletionState = {
    shiftTargetedToDeletion: null,
    shiftCapsulesIds: [],
    orderCodes: [],
    passengersWithDeletedOrders: [],
};
const initialSelectedAddressState = {
    pickup: null,
    drop: null,
};
const initialUiState: UiState = {
    selectedPassengers: [],
    shiftDeletion: initialShiftDeletionState,
    placementForm: {
        displayPlacementForm: false,
        passengersOrdersCountPerDate: {},
        blockedDates: [],
        selectedAddresses: initialSelectedAddressState,
    },
    screenProviderAccountCode: null,
    screenClientCode: window.localStorage.getItem('selectedClient') || null,
    selectedEmployeeColSort: null,
};

const initialState: ManualOrdersSliceState = {
    ui: initialUiState,
    data: {
        apiCallStatuses: {
            isDeletingOrders: 'ON_STAND_BY',
            placementGetPassengerShifts: 'ON_STAND_BY',
            tableGetPassengerShifts: 'ON_STAND_BY',
        },
        passengersDict: {},
        statistics: {
            weekly: { data: null, status: 'ON_STAND_BY' },
            daily: { data: null, status: 'ON_STAND_BY' },
        },
    },
    misc: {
        providerClients: [],
    },
};

const resetState = (state: ManualOrdersSliceState) => {
    state.data = { ...initialState.data };
    state.ui = { ...initialState.ui };
};

export const manualOrdersSlice = createSlice({
    name: 'manualOrdersSlice',
    initialState,
    reducers: {
        // ^ UI reducers
        // * Common
        resetState: (state: ManualOrdersSliceState): void => {
            resetState(state);
        },
        setShiftTargetedToDelete: (
            state: ManualOrdersSliceState,
            action: PayloadAction<ShiftToDelete | null>
        ): void => {
            const shiftTargeted = action.payload;
            const { shiftDeletion } = state.ui;
            shiftDeletion.shiftTargetedToDeletion = shiftTargeted;

            if (!shiftTargeted) {
                state.ui.shiftDeletion = initialShiftDeletionState;
                return;
            }

            setCapsulesDeletionData(state, true);
        },

        setScreenClientCode: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{ clientCode: string | null; withStateReset?: boolean }>
        ): void => {
            const { clientCode, withStateReset } = action.payload;
            if (withStateReset) {
                resetState(state);
            }
            state.ui.screenClientCode = clientCode;
        },
        setEmployeeColSort: (state: ManualOrdersSliceState, action: PayloadAction<Options | null>): void => {
            state.ui.selectedEmployeeColSort = action.payload;
        },
        setScreenProviderAccountCode: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{ accountCode: string | null; withStateReset?: boolean }>
        ): void => {
            const { accountCode, withStateReset } = action.payload;
            if (withStateReset) {
                resetState(state);
            }
            state.ui.screenProviderAccountCode = accountCode;
        },

        setCapsulesForDeletionByAllSelectedPassengers: (state: ManualOrdersSliceState): void => {
            if (!state.ui.shiftDeletion.shiftTargetedToDeletion) return;

            setCapsulesDeletionData(state, false);
        },

        resetShiftDeletion: (state: ManualOrdersSliceState): void => {
            state.ui.shiftDeletion.shiftTargetedToDeletion = null;
            state.ui.shiftDeletion.shiftCapsulesIds = [];
        },

        buildPassengersDict: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{
                passengers: IPassengerShifts[];
            }>
        ): void => {
            const dict = {};
            const { passengers } = action.payload;
            // build dict where key is passenger code and value is its index on passengers array
            passengers.forEach((passenger, index) => {
                dict[passenger.passId] = index;
            });
            state.data.passengersDict = dict;
        },

        setShiftCapsulesByPassenger: (
            state: ManualOrdersSliceState,
            action: PayloadAction<ShiftCapsulesByPassenger>
        ): void => {
            state.data.shiftCapsulesByPassenger = action.payload;
        },

        setSelectedPassengers: (state: ManualOrdersSliceState, action: PayloadAction<string[]>): void => {
            const { passengersDict } = state.data;
            const passIds = action.payload;

            if (!passengersDict) return;

            state.ui.selectedPassengers = passIds.map((passId) => {
                const locationIndex = passengersDict[passId];

                return [passId, locationIndex];
            });
        },

        // -- Placement form reducers
        displayPlacementForm: (state: ManualOrdersSliceState, action: PayloadAction<boolean>): void => {
            const isOpen = action.payload;
            state.ui.placementForm.displayPlacementForm = isOpen;
            state.ui.placementForm.blockedDates = [];
        },
        setBlockedDates: (
            state: ManualOrdersSliceState,
            action: PayloadAction<DateAvailabilityStatus[]>
        ): void => {
            state.ui.placementForm.blockedDates = action.payload;
        },
        setPassengersOrdersCountPerDate: (
            state: ManualOrdersSliceState,
            action: PayloadAction<PassengersOrdersCountPerDate>
        ) => {
            state.ui.placementForm.passengersOrdersCountPerDate = action.payload;
        },

        setPlacementAddress: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{ type: 'pickup' | 'drop'; addressData: IAddressWithIdAndIsDefault } | null>
        ) => {
            if (action.payload === null) {
                state.ui.placementForm.selectedAddresses = initialSelectedAddressState;
                return;
            }

            const { type, addressData } = action.payload;
            state.ui.placementForm.selectedAddresses[type] = addressData;
        },

        // * Data
        setPassengerDates: (state: ManualOrdersSliceState, action: PayloadAction<PassengerDates[]>): void => {
            state.data.passengersDates = action.payload;
        },

        setClients: (state: ManualOrdersSliceState, action: PayloadAction<{ clients: IClient[] }>): void => {
            const clientsList = Array.isArray(action.payload.clients) ? action.payload.clients : [];

            state.misc.providerClients = clientsList.map((client) => {
                client.firstDayOfWeek -= 1;
                return client;
            });
        },

        setWidgetStatistics: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{
                dateRange: 'weekly' | 'daily';
                data: ChartData;
            }>
        ): void => {
            const { dateRange, data } = action.payload;
            state.data.statistics[dateRange].data = data;
        },
        updateWidgetsStatisticsStatus: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{
                dateRange: 'weekly' | 'daily';
                status: ApiCallStatus;
            }>
        ): void => {
            const { dateRange, status } = action.payload;
            state.data.statistics[dateRange].status = status;
        },

        // * API call reducers
        setApiCallStatus: (
            state: ManualOrdersSliceState,
            action: PayloadAction<{
                type: ApiCallTypes;
                newStatus: ApiCallStatus;
            }>
        ): void => {
            const { apiCallStatuses } = state.data;
            apiCallStatuses[action.payload.type] = action.payload.newStatus;
        },
    },
});

// * Exports
// ^ Actions
export const {
    buildPassengersDict: buildPassengersDictAction,
    setShiftTargetedToDelete: setShiftTargetedToDeleteAction,
    setShiftCapsulesByPassenger: setShiftCapsulesByPassengerAction,
    setSelectedPassengers: setSelectedPassengersAction,
    setApiCallStatus: setApiCallStatusAction,
    resetShiftDeletion: resetShiftDeletionAction,
    setPassengerDates: setPassengerDatesAction,
    setCapsulesForDeletionByAllSelectedPassengers: setCapsulesForDeletionByAllSelectedPassengersAction,
    displayPlacementForm: displayPlacementFormAction,
    setScreenClientCode: setScreenClientCodeAction,
    setPassengersOrdersCountPerDate: setPassengersOrdersCountPerDateAction,
    setBlockedDates: setBlockedDatesAction,
    setScreenProviderAccountCode: setScreenProviderAccountCodeAction,
    resetState: resetStateAction,
    setClients: setClientsAction,
    setPlacementAddress: setPlacementAddressAction,
    setEmployeeColSort: setEmployeeColSortAction,
    setWidgetStatistics: setWidgetStatisticsAction,
    updateWidgetsStatisticsStatus: updateWidgetsStatisticsStatusAction,
    // setClients: setClientsAction,
} = manualOrdersSlice.actions;

// ^ Selectors
export const uiSelector = (state: IRootReducer) => state.manualOrdersSlice.ui;
export const shiftTargetedToDeleteSelector = (state: IRootReducer) =>
    uiSelector(state).shiftDeletion.shiftTargetedToDeletion;
export const dataSelector = (state: IRootReducer) => state.manualOrdersSlice.data;
export const statisticsSelector = (state: IRootReducer) => dataSelector(state).statistics;
export const passengerIdToIndexDictSelector = (state: IRootReducer) =>
    state.manualOrdersSlice.data.passengersDict;
export const shiftCapsulesByPassengerSelector = (state: IRootReducer) =>
    state.manualOrdersSlice.data.shiftCapsulesByPassenger;
export const selectedPassengersSelector = (state: IRootReducer): [string, number][] =>
    uiSelector(state).selectedPassengers;
export const selectedPassengersIdsSelector = (state: IRootReducer): string[] =>
    uiSelector(state).selectedPassengers.map((passenger) => passenger[PASSENGER_ID_INDEX]);
export const passengersDatesSelector = (state: IRootReducer) => dataSelector(state).passengersDates;
export const shiftCapsulesIdsSelector = (state: IRootReducer) =>
    uiSelector(state).shiftDeletion.shiftCapsulesIds;
export const orderCodesToDeleteSelector = (state: IRootReducer) => uiSelector(state).shiftDeletion.orderCodes;
export const passengersWithDeletedOrdersSelector = (state: IRootReducer) =>
    uiSelector(state).shiftDeletion.passengersWithDeletedOrders;
export const apiCallStatusesSelector = (state: IRootReducer, type: ApiCallTypes) =>
    dataSelector(state).apiCallStatuses[type];
export const displayPlacementFormSelector = (state: IRootReducer) =>
    uiSelector(state).placementForm.displayPlacementForm;
export const screenClientCodeSelector = (state: IRootReducer) => uiSelector(state).screenClientCode;
export const passengersOrdersCountPerDateSelector = (state: IRootReducer) =>
    uiSelector(state).placementForm.passengersOrdersCountPerDate;
export const blockedDatesSelector = (state: IRootReducer) => uiSelector(state).placementForm.blockedDates;
export const shiftCapsulesSelector = (state: IRootReducer) => dataSelector(state).shiftCapsulesByPassenger;
export const miscSelector = (state: IRootReducer) => state.manualOrdersSlice.misc;
export const providerClientsSelector = (state: IRootReducer) => miscSelector(state).providerClients;
export const selectedAddressForPlacementSelector = (state: IRootReducer) =>
    uiSelector(state).placementForm.selectedAddresses;
export const manualOrdersSliceSelectors = {
    uiSelector,
    dataSelector,
};

// ^ Reducer export
const manualOrdersSliceReducer = manualOrdersSlice.reducer;
export default manualOrdersSliceReducer;
