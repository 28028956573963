/* eslint-disable consistent-return */
import _ from 'lodash';
import { objHasTruthyValue } from 'src/utilis/utilis';
import { ChosenCourse } from 'src/types/passengersManager/courseChoosingTypes';
import { IAddress } from '../../../../../../../api/passengerMangerApi/types';
import { RawInputAddress } from './types';

const getTextWithSeparator = (text: string | undefined | null | number, separator = ','): string => {
    return text ? `${text}${separator}` : '';
};

export const stringifyAddress = (
    rawAddress: { city?: string; street?: string; houseNum?: string } | undefined,
    cityFirst?: boolean
): string => {
    if (!rawAddress) return '';

    if (rawAddress.city || rawAddress.houseNum || rawAddress.street) {
        if (cityFirst) {
            return `${getTextWithSeparator(rawAddress.city)} ${rawAddress.street || ''} ${
                rawAddress.houseNum || ''
            }`.trim();
        }

        return `${rawAddress.street || ''} ${getTextWithSeparator(rawAddress.houseNum)} ${
            rawAddress.city || ''
        }`.trim();
    }

    return '';
};

export const stringifyAddressV2 = (
    rawAddress: { city?: string; street?: string; houseNum?: string; placeName?: string } | undefined,
    cityFirst?: boolean
): string => {
    if (!rawAddress) return '';

    if (rawAddress.placeName) {
        const res = rawAddress.placeName;

        if (rawAddress.city) {
            return `${res}, ${rawAddress.city}`;
        }

        return res;
    }
    if (rawAddress.city || rawAddress.houseNum || rawAddress.street) {
        if (cityFirst) {
            return `${getTextWithSeparator(rawAddress.city)} ${rawAddress.street || ''} ${
                rawAddress.houseNum || ''
            }`.trim();
        }

        return `${rawAddress.street || ''} ${getTextWithSeparator(rawAddress.houseNum)} ${
            rawAddress.city || ''
        }`.trim();
    }

    return '';
};

export const getInputValues = (rawAddress: RawInputAddress) => {
    const { city, street, houseNum } = rawAddress;
    return { city, street, houseNum };
};

export const isValidAddressObj = (
    rawAddress: RawInputAddress | undefined,
    chosenCourses: ChosenCourse[]
): boolean | undefined => {
    if (!rawAddress) return false;

    if (objHasTruthyValue(getInputValues(rawAddress)) || rawAddress.addressCode) return true;

    return chosenCourses.some(
        (course: ChosenCourse) =>
            course.addressCode === rawAddress.addressCode ||
            course.addressCode === rawAddress.secondaryIdentifier
    );
};
