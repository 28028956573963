// * Slice
export type FileActions = 'get' | 'upload' | 'delete';

export enum ApiReqStatus {
    Idle = 'IDLE',
    Loading = 'LOADING',
    Succeeded = 'SUCCEEDED',
    Failed = 'FAILED',
}

export interface ExistingFileData {
    fileName: string;
    fileLink: string;
    expirationByMinutes: number;
    fetchedAt: Date;
}

export interface UploadFileFtState {
    data: {
        fileData: ExistingFileData | null;
        actionsStatus: {
            [key in FileActions]: ApiReqStatus | null;
        };
        selectedClientCode: string | null;
    };
    ui: {
        mainModal: {
            isOpen: boolean;
        };
        successModal: {
            isOpen: boolean;
        };
        fileInput: {
            fileName: string;
        } | null;
    };
}

export interface GeneralFeaturesSliceState {
    uploadFileFt: UploadFileFtState;
}
