/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MenuItemLink } from '@progress/kendo-react-layout';
import moment, { Moment } from 'moment';
import { TFunction, Namespace } from 'react-i18next';
import {
    ApiRequestActionPayload,
    ApiRequestActionPayloadV2,
    FetchReportActionPayload,
    FetchReportActionPayloadV2,
    GetPassengersReportRequestParams,
    GetPassengersReportRow,
} from 'src/api/reportsApi/types';
import { MenuItemProps } from 'src/components/DropDown/DropDown';
import { DAYS_HE, DAYS_NUMBERS, RELATIVE } from 'src/constants/dates';
import i18n from 'src/i18n';
import { getDepartmentName } from 'src/screens/Reports/utils';
import { DayNum } from 'src/types/global';
import { VisasReportRow } from 'src/types/reports/visasReportTypes';
import { dateify } from 'src/utilis/utilis';
import {
    DataProcessFunc,
    DetailedReportRow,
    PassengerReportRow,
    ReportNames,
    ShortDepartmentData,
    ShortReportRow,
} from '../../../../../../store/slices/reports/types';

export const getDepartmentFilterMenuItems = (departments: any[], loggedUserDepartmentCode: string | null) => {
    const menuItems = loggedUserDepartmentCode
        ? []
        : [
              {
                  name: i18n.t('all'),
                  value: 0,
              },
              {
                  name: i18n.t('withoutDepartment'),
                  value: -1,
              },
          ];
    try {
        departments.forEach((department: { departmentName: any; code: any }) => {
            menuItems.push({
                name: i18n.t(department.departmentName),
                value: department.code,
            });
        });
    } catch (error) {
        console.log(error);
        return menuItems;
    }
    return menuItems;
};

export const getShiftTimeMenuItems = (times: string[], t: TFunction<Namespace<'he'>>) => {
    const menuItems: MenuItemProps[] = [
        {
            name: t('all'),
            value: '0',
        },
    ];
    try {
        times.forEach((time: string) => {
            menuItems.push({
                name: time,
                value: time,
            });
        });
    } catch (error) {
        console.log(error);
        return menuItems;
    }
    return menuItems;
};

export const isValidPayload = (payload: any, useEndpoint?: boolean): boolean => {
    if (!payload) return false;
    if (!payload.dbUrl || !payload?.requestParams || (useEndpoint && !payload.endpoint)) return false;
    return true;
};

const buildPsngrObjWithMoreDetails = (psngrs: any): any[] => {
    return psngrs.map((psngr: { city: any; street: any; house: any }) => ({
        ...psngr,
        address: `${psngr.city}, ${psngr.street} ${psngr.house}`,
    }));
};
const processVisasReportApiData: DataProcessFunc = (apiData: any[]): VisasReportRow[] => {
    const processedData: VisasReportRow[] = [];
    apiData.forEach((visaRow) => {
        processedData.push({
            orderCode: visaRow.orderCode,
            rideCode: visaRow.lineCode,
            startTime: visaRow.startTime,
            endTime: visaRow.endTime,
            description: visaRow.lineDescription,
            duration: visaRow.orderDriveTime,
            km: visaRow.totalKm,
            passengersCount: visaRow.passQty,
            routeCode: visaRow.courseCode,
            carNumber: visaRow.carNumber,
            carType: visaRow.carTypeName,
            driverName: visaRow.driverName,
            passengers: buildPsngrObjWithMoreDetails(visaRow.passengers),
            relativeDate: visaRow.relativeDate ? dateify(visaRow.relativeDate, RELATIVE) : '',
            visaClientPrice: visaRow.clientPrice,
            expanded: false,
        });
    });
    processedData.sort((a, b) => {
        const aTime = moment(a.relativeDate);
        const bTime = moment(b.relativeDate);
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });
    return processedData;
};

// FC REQUEST

export const extractFetchVisasReportPayload = (
    fullPayload: ApiRequestActionPayload
): FetchReportActionPayload => {
    const { dbUrl, requestParams } = fullPayload;
    const { clientCode, proxyUrl, token, fromDate, toDate, relativeDate } = requestParams;

    return {
        dbUrl,
        requestParams: {
            relativeDate,
            fromDate,
            toDate,
            clientCode,
            proxyUrl,
            token,
        },
        reportName: 'visas' as ReportNames,
        endpoint: 'ws_MyWayStudio_Get_Lines_Report',
        dataProcessFunc: processVisasReportApiData,
    };
};

const processPassengersReportApiData = (data: GetPassengersReportRow[]): PassengerReportRow[] => {
    return data.map((d) => ({
        passCode: d.passCode,
        internalCode: d.internalCode,
        firstName: d.firstName,
        lastName: d.lastName,
        departmentName: d.departmentName,
        totalRidesCount: d.totalLines,
        ridesCountPerDate: d.dates.map((date) => ({
            date: moment(date.date, RELATIVE).startOf('day').toDate(),
            count: date.totalLinesPerDay,
        })),
    }));
};

// FC REQUEST

export const extractFetchPassengersReportPayload = (
    fullPayload: ApiRequestActionPayloadV2<GetPassengersReportRequestParams>
): FetchReportActionPayloadV2<GetPassengersReportRequestParams> => {
    const { dbUrl, proxyUrl, requestParams } = fullPayload;
    const {
        clientCode,
        token,
        fromDate,
        daysInWeek,
        departmentCodes,
        toTime,
        fromTime,
        isAllDepartments,
        dates,
    } = requestParams;

    return {
        dbUrl,
        proxyUrl,
        requestParams: {
            departmentCodes,
            clientCode,
            token,
            fromDate,
            daysInWeek,
            toTime,
            fromTime,
            isAllDepartments,
            dates,
        },
        reportName: ReportNames.passengersReport,
        endpoint: 'ws_MyWayStudio_Get_Passengers_Report',
        dataProcessFunc: processPassengersReportApiData,
        dateKey: 'fromDate',
    };
};

export const shortOrdersOperator = (item: ShortReportRow, currValue: string) => {
    if (!item) return false;
    const psngerIdMatch = item.passId?.indexOf(currValue) !== -1;
    return (
        item &&
        (psngerIdMatch ||
            item.fullName?.includes(currValue) ||
            item.city?.includes(currValue) ||
            item.streetWithHouseNum?.includes(currValue) ||
            item.departmentName?.includes(currValue))
    );
};
export const passengersOperator = (item: PassengerReportRow, currValue: string) => {
    if (!item) return false;
    const psngerIdMatch = item.internalCode?.indexOf(currValue) !== -1;
    return (
        item &&
        (psngerIdMatch ||
            item.firstName?.includes(currValue) ||
            item.lastName?.includes(currValue) ||
            item.departmentName?.includes(currValue))
        // item.shiftName.includes(currValue))
    );
};
export const visasOperator = (item: VisasReportRow, currValue: string) => {
    return (
        item &&
        (String(item.orderCode)?.includes(currValue) ||
            String(item.rideCode)?.includes(currValue) ||
            item.startTime?.includes(currValue) ||
            item.endTime?.includes(currValue) ||
            item.description?.includes(currValue) ||
            item.carNumber?.includes(currValue) ||
            item.carType?.includes(currValue) ||
            item.driverName?.includes(currValue))
    );
};
export const getOperatorPerReport = (reportName: ReportNames) => {
    switch (reportName) {
        case 'shortOrders':
            return shortOrdersOperator;

        case 'detailedOrders':
            return shortOrdersOperator;
        case 'passengers':
            return passengersOperator;

        default:
            return visasOperator;
    }
};

export const processDetailedReportApiData: DataProcessFunc = (
    apiData: any[],
    departments: ShortDepartmentData[]
): DetailedReportRow[] => {
    const processedData: DetailedReportRow[] = [];

    apiData.forEach((passengerRow) => {
        const { street, houseNum, ...neededProperties } = passengerRow;

        const departmentName = getDepartmentName(passengerRow, departments);

        // -- building full passenger row obj and pushing to list
        processedData.push({
            ...neededProperties,
            streetWithHouseNum: `${passengerRow.street}, ${passengerRow.houseNum}`,
            shiftPickupTime: passengerRow.shiftTime,
            rideType: +passengerRow.isPickup ? 'איסוף' : 'פיזור',
            departmentName,
            relativeDate: passengerRow.relativeDate ? dateify(passengerRow.relativeDate, RELATIVE) : '',
        });
    });
    processedData.sort((a, b) => {
        const aTime = moment(a.relativeDate);
        const bTime = moment(b.relativeDate);
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });
    return processedData;
};

export const buildDepartmentsMap = (departments: ShortDepartmentData[]) => {
    const map = {};
    const DEPT_DATA_IDX = 1;

    Object.entries(departments).forEach((deptTuple) => {
        map[deptTuple[DEPT_DATA_IDX].code] = deptTuple[DEPT_DATA_IDX].departmentName;
    });
    return map;
};

export const buildObjMap = (data: { [key: string]: string }[], codeKey: string, nameKey: string) => {
    const map = {};
    const DATA_VALUE_IDX = 1;

    Object.entries(data).forEach((dataTuple) => {
        map[dataTuple[DATA_VALUE_IDX][codeKey]] = dataTuple[DATA_VALUE_IDX][nameKey];
    });
    return map;
};

export const getMaxDate = (startDate: Date | Moment, maxDays: number) => {
    return moment(startDate).add(maxDays, 'days');
};
// fullPayload: ApiRequestActionPayload,
// ): FetchReportActionPayload => {
//    const { dbUrl, requestParams } = fullPayload;
//    const { relativeDate, clientCode, proxyUrl, token } =
//       requestParams;

//    return {
//       dbUrl,
//       requestParams: {
//          fromDate,
//          toDate,
//          clientCode,
//          proxyUrl,
//          token,
//       },
//       reportName: 'weeklyOrders' as ReportNames,
//       endpoint: `${myWayStudioWsPrefix}Get_Weekly_Orders_Report`,

//       dataProcessFunc: processVisasReportApiData,
//    };
// };

// export const buildWeeklyReportsPayload = ({fromDate, toDate}) => {

// }
