/* eslint-disable react/button-has-type */
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Paper } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'src/style/themes/defaultTheme';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.lightYellow};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
`;

const Card = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 90%;
    width: 100%;
`;
const StyledP = styled.p`
    padding-top: 30px;
    color: ${colors.blue};
    font-weight: bold;
    font-size: 24px;
`;
function ErrorFallback({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: any }): JSX.Element {
    const { t } = useTranslation();
    return (
        <Container>
            <Card>
                <StyledP>{t('oopsSomethingWentWrongTryRefreshing')}</StyledP>
                <pre>{error.message}</pre>
                <Button variant="contained" onClick={resetErrorBoundary}>
                    {t('refreshPage')}
                </Button>
            </Card>
        </Container>
    );
}

export default ErrorFallback;
