import styled, { css } from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import { colors } from 'src/style/themes/defaultTheme';

export const StyledTabs = styled(Tabs)`
    font-size: 16px;
    .MuiTab-root {
        width: 100%;
    }
    display: block;
    &:after {
        content: '';
        display: block;
        height: 0.5px;
        width: 100%;
        margin-top: -5px;
        background: #b4bec9;
    }
`;
export const TabContainer = styled.div`
    margin-top: 15px;
`;
export const Par = styled.p<{
    flex?: number;
    fontSize?: string;
    styledCss?: any;
}>`
    ${({ flex = 1, fontSize, styledCss }) => css`
        font-family: 'Rubik';
        font-style: normal;
        font-size: ${fontSize || '14px'};
        display: flex;
        align-items: center;
        text-align: right;
        padding-left: 5px;
        flex: ${flex};
        ${css`
            ${styledCss}
        `}
    `}
`;
export const StyledCourseChoiceCard = styled.div<{
    isChosen?: boolean;
}>`
    ${({ isChosen }) => css`
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        min-height: 30px;
        background: #ffffff;
        border: 1px solid rgba(180, 190, 201, 0.303017);
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 5px;
        :hover {
            cursor: pointer;
            background-color: #d7d7d7;
            transition: background-color 0.2s ease-out;
        }
        ${isChosen &&
        css`
            border: 2px solid #40a8e2;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        `}
    `}
`;
export const titleStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    border: '1px solid gray',
};
export const StyledTitle = styled.div`
    display: flex;
    padding: 0 5px 5px 5px;
    font-family: 'Rubik';
    font-style: normal;
    text-align: right;
    color: #242424;
`;
export const getParStyle = (flex: number, justifyContent?: string) =>
    ({
        flex,
        paddingLeft: '5px',
    } as const);

export const tabsSx = {
    '.MuiTab-root': {
        borderBottom: '1px solid gray',
        fontFamily: 'Rubik',
    },
    '.Mui-selected': {
        color: '#2196F3 !important',
        fontWeight: 'bold',
    },
    '.MuiTabs-indicator': {
        backgroundColor: '#2196F3',
    },
};
export const Styles = { StyledTabs, TabContainer };
