import { getRandColor } from 'src/style/testing';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #f9f9f9;
    border: 1px solid #e8ebef;
    width: 100%;
    height: 100%;
    // border: 1px solid ${getRandColor()};
`;

const DetailBox = styled.div`
    // border-bottom: 1px solid #e8ebef;
    display: flex;
    min-height: 60px;
    align-items: center;
    padding-right: 12px;
    // border: 1px solid ${getRandColor()};
`;

const TitleBox = styled.div`
    // border: 1px solid ${getRandColor()};
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    height: 100%;
    min-width: 84px;
    text-align: right;
    color: #494949;
`;

const Details = styled.div`
    // border: 1px solid ${getRandColor()};
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: right;
    padding-left: 24px;
`;

const Name = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;

    // border: 1px solid ${getRandColor()};
    text-align: right;
    color: #bebebe;
`;

const Value = styled.p`
    // border: 1px solid ${getRandColor()};
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #494949;
    text-align: right;
`;

const Hr = styled.div`
    background: rgba(180, 190, 201, 0.303);
    min-height: 1px;
    margin: 0 12px;
`;
export const Styled = {
    Wrapper,
    DetailBox,
    Title: TitleBox,
    Details,
    Name,
    Value,
    Hr,
};
