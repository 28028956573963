import { IturanRecord } from 'src/api/types';
import { parseJsonIfValid } from 'src/utilis/utilis';

export const specialCharsRegex = /[^a-zA-Z0-9]/g;

export const getRecordsJSON: (xml: string) => string | IturanRecord[] = (xml) => {
    if (!xml) return [];
    const returnCode = xml
        .split('ReturnCode', 2)[1]
        .split('Username', 2)[0]
        .replaceAll(specialCharsRegex, '');

    if (returnCode !== 'OK') return returnCode.split('Reason', 2)[0];

    const s = xml
        .split('Records', 2)[1] // splitting the xml in order to get the records part
        .slice(2) // cleaning leftover chars
        .replace('\\', ''); // removing \\ chars
    // console.log({ s });
    if (s.length < 7) return [];

    const json: IturanRecord[] | string = parseJsonIfValid(s.slice(0, s.length - 7)); // cleaning leftover chars and parsing

    return json;
};
