import { AxiosResponse } from 'axios';
import { ShortReportRow } from 'src/store/slices/reports/types';
import {
    GetAccountCoursesRequest,
    GetAccountCoursesResponse,
    GetAccountCoursesReqParams,
} from 'src/types/passengersManager/courseChoosingTypes';
import {
    DepartmentStatisticsResponse,
    RidesStatisticsResponse,
    StatisticsReqParams,
    StatisticsRequestConfig,
} from 'src/types/reports/generalReportsTypes';
import { AxiosRes, IResponse, ReqConfig, WsResponse } from '../types';
import {
    ApiCallRequest,
    ApiRequest,
    GetDetailedReportResponse,
    GetReportRequest,
    GetShortReportRequestParams,
    ShortOrdersApiDataElement,
    ShortReportGetRequest,
} from './types';
import { newMakeApiCall } from './utils';
import {
    GetClientBudgetParams,
    GetClientBudgetRequestConfig,
    GetClientBudgetResponse,
} from '../../types/reports/api.types';
/**
 * Makes an API call to the given endpoint with the given requestParams.
 * @type {T} T - the requestParams type
 * @type {R} R - the response type
 * @param {ReqConfig<T>} requestConfig - The request configuration object.
 * @returns A promise that resolves to the response object.
 */
export const makeApiCall = <T, R>(requestConfig: ReqConfig<T>): Promise<AxiosResponse<R>> => {
    const { dbUrl, endpoint, requestParams } = requestConfig;
    return newMakeApiCall<R>({
        requestParams,
        dbUrl,
        endpoint: endpoint as string,
    });
};

// FC REQUEST

export const getShortReport = (
    requestConfig: any
): Promise<AxiosResponse<WsResponse<ShortOrdersApiDataElement>>> => {
    const { dbUrl, ...restOfConfigs } = requestConfig;
    return newMakeApiCall<any>({
        requestParams: restOfConfigs.requestParams,
        dbUrl,
        endpoint: 'ws_MyWayStudio_Get_Concentrated_Orders_Report',
    });
};
export const getReport = <T, P>(requestConfig: ApiCallRequest<P>): Promise<AxiosResponse<any>> => {
    const { dbUrl, ...restOfConfigs } = requestConfig;
    return newMakeApiCall<any>({
        requestParams: restOfConfigs.requestParams,
        dbUrl,
        endpoint: requestConfig.endpoint as string,
    });
};

/**
 * Makes a call to the API to get the departments statistics .
 * @param {StatisticsRequestConfig} requestConfig - The request configuration object.
 * @returns A promise that resolves to the response from the API.
 */
export const getDepartmentsStatistics = (
    requestConfig: StatisticsRequestConfig
): AxiosRes<DepartmentStatisticsResponse> =>
    makeApiCall<StatisticsReqParams, DepartmentStatisticsResponse>(requestConfig);

/**
 * Makes a request to the  API to get the courses for the given account.
 * @param {GetAccountCoursesRequest} requestConfig - The request configuration object.
 * @returns {AxiosRes<GetAccountCoursesResponse>} - The response from the API.
 */

// FC REQUEST

export const getAccountCourses = (
    requestConfig: GetAccountCoursesRequest
): AxiosRes<GetAccountCoursesResponse> =>
    makeApiCall<GetAccountCoursesReqParams, GetAccountCoursesResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Account_Courses',
    });

export const getRidesStatistics = (
    requestConfig: StatisticsRequestConfig
): AxiosRes<RidesStatisticsResponse> =>
    makeApiCall<StatisticsReqParams, RidesStatisticsResponse>(requestConfig);

export const getClientBudget = (
    requestConfig: GetClientBudgetRequestConfig
): AxiosRes<GetClientBudgetResponse> =>
    makeApiCall<GetClientBudgetParams, GetClientBudgetResponse>(requestConfig);
