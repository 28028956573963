import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';
import {
    dateOnReportSelector,
    filtersByReportSelector,
    selectedReportSelector,
} from 'src/store/selectores/reportsSelectors';
import { PassengerReportRow, RideCountPerDate } from 'src/store/slices/reports/types';
import moment from 'moment';
import { DAYS_HE_SHORT, DISPLAY_UI_SHORT, RELATIVE } from 'src/constants/dates';
import { IRootReducer } from 'src/store/reducers';
import { asRootReducer } from 'src/store/utils';
import { dateify, datesAreOnSameDay, getDaysInMonthUTC } from 'src/utilis/utilis';
import { GridDetailRowProps } from '@progress/kendo-react-grid';
import DayCount from './components/DayCount';
import { buildDayCountProps } from './utils';

export interface PassengerDataItem extends PassengerReportRow {
    expanded: boolean;
}

interface Props extends GridDetailRowProps {
    dataItem: PassengerDataItem;
}

const getCountsForAllDays = (currDatesOnly: RideCountPerDate[], date: Date): RideCountPerDate[] => {
    const allDays = getDaysInMonthUTC(date.getUTCMonth() + 1, date.getUTCFullYear());
    return allDays.map((d) => {
        const match = currDatesOnly.find((passengerDay) => datesAreOnSameDay(passengerDay.date, d));

        return {
            date: d,
            count: match ? match.count : 0,
        };
    });
};

const PassengersReportRidesCountGrid = React.memo<Props>(({ dataItem }) => {
    const dateOnReport = useAppSelector((state) => dateOnReportSelector(asRootReducer(state)));

    const [countForAllDays, setCountForAllDays] = React.useState<RideCountPerDate[]>([]);

    useEffect(() => {
        // const countsForAllDays: RideCountPerDate[] = [];
        if (dateOnReport) {
            setCountForAllDays(getCountsForAllDays(dataItem.ridesCountPerDate || [], dateify(dateOnReport)));
        }
    }, [dataItem.ridesCountPerDate, dateOnReport]);

    return (
        <table
            style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            {countForAllDays.map((countPerDate) => {
                const dayCountProps = buildDayCountProps(countPerDate);

                return <DayCount {...dayCountProps} />;
            })}
        </table>
    );
});

export default PassengersReportRidesCountGrid;
