import { isSameDate } from 'src/utilis/utilis';
import { ManualOrdersSliceState } from './types';

export const PASSENGER_ID_INDEX = 0;
export const PASSENGER_LOCATION_INDEX = 1;

export const moSliceConstants = {
    PASSENGER_DATA_INDEX: PASSENGER_ID_INDEX,
    PASSENGER_LOCATION_INDEX,
};

export const setCapsulesDeletionData = (state: ManualOrdersSliceState, isSinglePassenger: boolean) => {
    const { shiftDeletion, selectedPassengers } = state.ui;
    const { shiftTargetedToDeletion: shiftTargeted } = shiftDeletion;

    if (!shiftTargeted) {
        return;
    }

    let allSelectedPassengerIds: string[];
    const capsuleIds: string[] = [];
    const orderCodes: string[] = [];
    const passengersWithDeletedOrders: string[] = [];

    if (!isSinglePassenger) {
        allSelectedPassengerIds = selectedPassengers.map((p) => p[PASSENGER_ID_INDEX]);
    } else {
        allSelectedPassengerIds = [shiftTargeted.passId];
    }

    const { shiftCapsulesByPassenger } = state.data;

    if (!shiftCapsulesByPassenger) {
        return;
    }

    allSelectedPassengerIds.forEach((passId) => {
        const shiftCapsules = shiftCapsulesByPassenger[passId];

        if (shiftCapsules) {
            shiftCapsules.forEach((shiftCapsule) => {
                if (
                    isSameDate(shiftCapsule.date, shiftTargeted.date) &&
                    shiftCapsule.times === shiftTargeted.times
                ) {
                    capsuleIds.push(shiftCapsule.id);
                    orderCodes.push(...shiftCapsule.orderCodes);
                    passengersWithDeletedOrders.push(passId);
                }
            });

            shiftDeletion.shiftCapsulesIds = capsuleIds;
            shiftDeletion.orderCodes = orderCodes;
            shiftDeletion.passengersWithDeletedOrders = passengersWithDeletedOrders;
        }
    });
};
