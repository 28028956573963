const { width } = window.screen;

const getShortOrdersColWidth = (field: string) => {
    switch (field) {
        case 'passName':
            if (width < 1660) {
                if (width > 1420) return 120;
                return 100;
            }
            return 150;
        case 'street':
            if (width < 1660) {
                if (width > 1420) return 120;
                return 100;
            }
            return 'auto';
        case 'passId':
            if (width < 1660) {
                if (width > 1420) return 90;
                return 60;
            }
            return 110;
        case 'routeCode':
            if (width < 1660) {
                if (width > 1420) return 85;
                return 70;
            }
            return 100;
        default:
            break;
    }
};
export const shortOrdersWidths: { [key: string]: any } = {
    passId: getShortOrdersColWidth('passId'),
    passName: getShortOrdersColWidth('passName'),
    streetWithHouseNum: getShortOrdersColWidth('street'),
    pickupCourseId: getShortOrdersColWidth('routeCode'),
    departmentName: 70,
    city: 75,
    workStart: 80,
    workEnd: 80,
    dropCourseId: getShortOrdersColWidth('routeCode'),
};
