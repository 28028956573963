/* eslint-disable import/no-cycle */

import { takeLatest, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga/index';
import { IDeletePassengerSagaRequest, ISetSagaPassenger } from 'src/api/passengerMangerApi/types';
import { deletePassenger, addOrUpdatePassenger } from 'src/api/passengerMangerApi/passengerMangerApi';
import { FcResponseState } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import { PassengersErrorMessagesEnum } from 'src/types/global';

import { ChosenCourse } from 'src/types/passengersManager/courseChoosingTypes';
import { PassengerActionType } from '../actions/actionType';

import {
    onDeletePassengersFaild,
    onDeletePassengersSuccess,
    onSetPassengersFaild,
    onSetPassengersSuccess,
} from '../actions/passengerAction';
import { ISagaRequest } from '../type';
import { setErrorCode, setTokenRefreshStatus } from '../actions/loginAction';
import { stringifyAddress } from '../../screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/utils';
import { IAddress } from '../../api/passengerMangerApi/types';

export const getErrorCodeByResponse = (response: string): PassengersErrorMessagesEnum => {
    let errorCode = PassengersErrorMessagesEnum.GenralError;

    if (response === '2') errorCode = PassengersErrorMessagesEnum.ClientCodeIsMissing;
    if (response === '3') errorCode = PassengersErrorMessagesEnum.InternalPassengerIsMissing;
    if (response === '4') errorCode = PassengersErrorMessagesEnum.InternalPassengerCodeExiest;

    return errorCode;
};

function* setPassnger({ payload }: ISagaRequest<ISetSagaPassenger>): Iterator<any> {
    try {
        if (!payload) yield put(onDeletePassengersFaild());
        const { address, passenger, ...requestToken } = payload;
        // console.log({ payload });

        const apiResponse: any = yield addOrUpdatePassenger({
            addressJson: JSON.stringify({ address: address || [] }),
            ...requestToken,
        });
        // console.log({ apiResponse });

        const { response, passCode } = apiResponse.data;

        if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            yield put(
                onSetPassengersSuccess({
                    ...passenger,
                    passCode: Number(passCode),
                })
            );
        } else {
            const errorCode = getErrorCodeByResponse(response);
            yield put(setErrorCode(errorCode));
            yield put(onSetPassengersFaild());
        }
    } catch (error) {
        console.log(error);
        yield put(setErrorCode(PassengersErrorMessagesEnum.GenralError));
    }
}

function* deletePassnger({ payload }: ISagaRequest<IDeletePassengerSagaRequest>): Iterator<any> {
    try {
        if (!payload) yield put(onDeletePassengersFaild());
        const { passengers, ...requestToken } = payload;

        const apiResponse: any = yield deletePassenger({
            passengers: passengers.join(',').toString(),
            ...requestToken,
        });
        const { response } = apiResponse.data;

        if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            yield put(onDeletePassengersSuccess(passengers));
        } else {
            const errorCode = getErrorCodeByResponse(response);

            yield put(setErrorCode(errorCode));
            yield put(onDeletePassengersFaild());
        }
    } catch (error) {
        console.log(error);
        yield put(setErrorCode(PassengersErrorMessagesEnum.GenralError));
        yield put(onDeletePassengersFaild());
    }
}

export default function* passengerSaga(): SagaIterator<any> {
    yield takeLatest(PassengerActionType.setPassenger, setPassnger);
    yield takeLatest(PassengerActionType.deletePassenger, deletePassnger);
}
