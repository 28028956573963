import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { centeredFlexItem } from 'src/style/styledCSS';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import CellEditBtn from 'src/components/KendoGridCutomeColumn/CellEditBtn';
import { iconButtonStyle } from 'src/components/styles/styles';
import {
    openFormAction,
    setEditModeAction,
    toggleConfirmationDialogAction,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import cotValidationHooks from 'src/hooks/reactHookForm/useCotValidation';
import moment from 'moment';
import { IEditLine, ILine } from 'src/types/line';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { useHistory } from 'react-router-dom';
import { getLineByCode } from 'src/api/api';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { AxiosResponse } from 'axios';
import { IResponse } from 'src/api/types';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { setLocalStorageValue, uuid } from 'src/utilis/utilis';
import {
    optimizationsRootSelector,
    setCenterMapOnEditAction,
    setIsDeleteModalOpenAction,
    setIsEditFormAction,
    setIsEditRouteNameModalOpenAction,
    setIsFormOpenAction,
    setMapIsExpandedAction,
    setRouteOnEditAction,
    setWaypointsOnFormEditAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import FlagIcon from '@mui/icons-material/Flag';
import { useSetOptimizationWaypoints } from '../hooks/useSetOptimizationWaypoints';
import useCommons from 'src/hooks/common/useCommons';

export interface IKendoDeleteEditeCell {
    hoverRowRouteId: string | null;
    cellProps: React.PropsWithChildren<GridCellProps>;
}

const SimulationOptionsCell: FunctionComponent<IKendoDeleteEditeCell> = ({
    hoverRowRouteId,
    cellProps,
}: IKendoDeleteEditeCell) => {
    const routes = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getSimulationResult.data?.optimumRouteResult
    );

    const { t, dispatch } = useCommons();

    const isShow = hoverRowRouteId !== '' && hoverRowRouteId === cellProps.dataItem.localId ? true : false;

    const onEditWaypointClick = async () => {
        if (cellProps && cellProps.dataItem) {
            console.log('edit', cellProps.dataItem);
            dispatch(setRouteOnEditAction({ route: cellProps.dataItem }));
            dispatch(setIsEditRouteNameModalOpenAction({ isOpen: true }));
        }
    };

    return (
        <td className="optionsCell">
            {isShow && (
                <div style={{ display: 'flex', gap: '5px' }}>
                    <MuiTooltipV3 title={t('edit')}>
                        <IconButton
                            onClick={(ev) => onEditWaypointClick()}
                            style={iconButtonStyle}
                            aria-label="עריכה"
                            size="small"
                        >
                            <MyEditPencilIcon height="18px" color="" />
                        </IconButton>
                    </MuiTooltipV3>
                </div>
            )}
        </td>
    );
};

export default SimulationOptionsCell;
