import moment from 'moment';
import { DAYS_HE_SHORT, DISPLAY_UI_SHORT } from 'src/constants/dates';
import { RideCountPerDate } from 'src/store/slices/reports/types';

export const buildDayCountProps = (countPerDate: RideCountPerDate) => {
    return {
        day: `${DAYS_HE_SHORT[countPerDate.date.getDay() + 1]}'`,
        date: moment(countPerDate.date).format(DISPLAY_UI_SHORT),
        count: countPerDate.count,
    };
};
