import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { alertConfigSelector, setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import { ErrorState } from 'src/types/global';
import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';

const useAlert = (
    errorSelector: {
        (state: IRootReducer): ErrorState;
        (arg0: any): any;
    },
    errorAction: ActionCreatorWithPayload<ErrorActionPayload>
): {
    alertProps: {
        open: boolean;
        onClose: () => void;
        severity: 'error';
    };
    errorMsg: string;
} => {
    const dispatch = useAppDispatch();
    const error = useAppSelector((state: any) => errorSelector(state));
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const handleCloseSnack = useCallback(() => {
        dispatch(errorAction(null));
        setShowErrorAlert(false);
    }, [dispatch, errorAction]);

    useEffect(() => {
        if (error.msg) {
            setShowErrorAlert(true);
        }
    }, [error, error.msg]);

    const alertProps = {
        open: showErrorAlert,
        onClose: handleCloseSnack,
        severity: 'error' as const,
    };

    return { alertProps, errorMsg: error.msg };
};

export type Severity = 'error' | 'success' | 'info' | 'warning';
export const useAlertV2 = (): {
    props: {
        open: boolean;
        onClose: () => void;
        severity: Severity | undefined;
    };
    message: string;
} => {
    const dispatch = useAppDispatch();

    const { message, severity } = useRootAppSelector(alertConfigSelector);

    const handleCloseSnack = useCallback(() => {
        dispatch(setAlertConfigAction(null));
    }, [dispatch]);

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                handleCloseSnack();
            }, 6000);
        }
    }, [handleCloseSnack, message]);

    const alertProps = {
        open: !!message.length,
        onClose: handleCloseSnack,
        severity: severity as Severity,
    };

    return { props: alertProps, message };
};

export const useDispatchAlert = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = (severity: Severity, message: string) => {
        dispatch(
            setAlertConfigAction({
                severity,
                message,
            })
        );
    };
    return dispatchAlert;
};

const useBaseAlert = () => {
    const dispatch = useAppDispatch();

    const dispatchAlert = useCallback(
        (message: string) => {
            dispatch(setErrorMessage(message));
        },
        [dispatch]
    );

    return dispatchAlert;
};
export const alertHooks = {
    useAlert,
    useAlertV2,
    useDispatchAlert,
    useBaseAlert,
};
export default useAlert;
