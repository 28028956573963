import { cardStyle } from 'src/screens/PassengerManger/components/PassengerForm/styles/styles';

import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const settingFormCardStyle: CSSProperties = {
    // ...cardStyle,
    // height: '96%',
    // marginBottom: '20px',
    height: '100%',
    width: '100%',
    padding: '20px 0',
    display: 'grid',
    gridTemplateRows: '0.5fr 10fr 0.7fr',
    borderRadius: '15px',
};

export const baseWidgetsCardStyle: CSSProperties = {
    borderRadius: '15px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: 'fit-content',
    marginBottom: '30px',
};

export const testBorder: CSSProperties = {
    border: '1px dotted #gray',
};

export const listItemStyle = {
    height: `${window.screen.height * 0.05}px`,
    display: 'flex',
    alignItems: 'center',
};
export const getItemStyle = (isLastItem: boolean, isBeingHovered: boolean) => {
    return {
        ...listItemStyle,
        justifyContent: 'space-between',
        borderBottom: isLastItem ? '' : '1px solid rgba(0, 0, 0, 0.12)',
    };
};

const ACTIVATE_BORDERS = 1;
const border = ACTIVATE_BORDERS && '3px dotted orange';

const formListItem: CSSProperties = { textAlign: 'right', border };

const styles = { formListItem };
export default styles;
