import React, { FC, useEffect, useState } from 'react';
import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import useCommons from 'src/hooks/common/useCommons';
import FormHeader from 'src/components/formComponents/FormHeader';
import useFormHeader from '../../hooks/useFormHeader';
import {
    optimizationsRootSelector,
    resetLastEditedWaypointsPassIdsAction,
    setCenterMapOnEditAction,
    setIsEditFormAction,
    setIsFormDirtyAction,
    setIsFormOpenAction,
    setIsMapEditModeAction,
    setUpdatedCoordsOnMapAction,
    updateFormWaypointAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import OptimizationWaypointInputBox from '../OptimizationWaypointInputBox';
import { useRootAppSelector } from 'src/store/hooks';
import NakedBtn from 'src/components/buttons/NakedBtn';
import { uuid } from 'src/utilis/utilis';
import { useSelector } from 'react-redux';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useSetOptimizationWaypoints } from '../../hooks/useSetOptimizationWaypoints';

interface Props {}

const OptimizationForm: FC<Props> = React.forwardRef((props, ref) => {
    const { dispatch } = useCommons();
    const formWaypointsList = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypoints
    );
    const { t } = useCommons();
    const isEditForm = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form.isEditForm);
    const waypointsErrors = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypointsErrors
    );
    const isMapEditMode = useRootAppSelector((state) => optimizationsRootSelector(state).ui.isMapEditMode);
    const isDirty = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form.isDirty);
    const setOptimizationWaypointsFromServer = useSetOptimizationWaypoints();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onCancel = () => {
        dispatch(
            updateFormWaypointAction({
                waypoints: [
                    {
                        city: '',
                        street: '',
                        houseNum: '',
                        remarks: '',
                        localId: uuid(),
                        placeName: '',
                        isTarget: false,
                        passId: '',
                        firstName: '',
                        lastName: '',
                    },
                ],
            })
        );

        // reset form

        dispatch(setIsFormOpenAction({ isFormOpen: false }));
        dispatch(
            setIsMapEditModeAction({
                isMapEditMode: false,
                waypointOnEditMode: null,
            })
        );
        dispatch(setUpdatedCoordsOnMapAction(null));
        dispatch(setCenterMapOnEditAction(null));
        dispatch(setIsEditFormAction({ isEditForm: false }));
        dispatch(setIsFormDirtyAction({ isDirty: false }));
    };

    const onSubmit = async () => {
        setIsSubmitting(true);

        await setOptimizationWaypointsFromServer.setOptimizationWaypoints();

        // reset form

        // tamir - may need to add isActive 1

        dispatch(
            updateFormWaypointAction({
                waypoints: [
                    {
                        city: '',
                        street: '',
                        houseNum: '',
                        remarks: '',
                        localId: uuid(),
                        placeName: '',
                        isTarget: false,
                        passId: '',
                        firstName: '',
                        lastName: '',
                    },
                ],
            })
        );
        dispatch(setIsFormOpenAction({ isFormOpen: false }));
        dispatch(
            setIsMapEditModeAction({
                isMapEditMode: false,
                waypointOnEditMode: null,
            })
        );
        dispatch(setUpdatedCoordsOnMapAction(null));
        dispatch(setCenterMapOnEditAction(null));
        dispatch(setIsEditFormAction({ isEditForm: false }));
        dispatch(setIsFormDirtyAction({ isDirty: false }));
        setIsSubmitting(false);
    };

    const addWaypoint = () => {
        const formWaypointsListCopy = [...formWaypointsList];
        formWaypointsListCopy.push({
            city: '',
            street: '',
            houseNum: '',
            remarks: '',
            localId: uuid(),
            placeName: '',
            passId: '',
            firstName: '',
            lastName: '',
        });
        dispatch(updateFormWaypointAction({ waypoints: formWaypointsListCopy }));
        dispatch(setIsFormDirtyAction({ isDirty: true }));
    };

    const headerTexts = useFormHeader();

    useEffect(() => {
        return () => {
            dispatch(resetLastEditedWaypointsPassIdsAction());
        };
    }, []);

    return (
        <div
            ref={ref as React.RefObject<HTMLDivElement>}
            style={{ height: '100%', paddingTop: '30px', textAlign: 'right' }}
        >
            <WidgetFormWrapper
                btnsConfig={{
                    onSubmit,
                    onCancel,
                    isSubmitting,
                    withLoader: true,
                    disabled:
                        Object.keys(waypointsErrors).length !== 0 || isMapEditMode || !isDirty ? true : false,
                    submitText: isEditForm ? t('update') : t('save'),
                }}
                HeaderComponent={() =>
                    FormHeader({
                        mainText: headerTexts.mainText,
                        secondaryText: '',
                    })
                }
            >
                <div style={{ height: '75vh' }}>
                    {!isEditForm && (
                        <NakedBtn
                            style={{
                                height: '5%',
                            }}
                            onClick={addWaypoint}
                            type="add"
                            text={t('addWaypoint')}
                        />
                    )}
                    <div style={{ overflow: 'auto', height: '95%' }}>
                        {formWaypointsList.map((waypoint) => {
                            return <OptimizationWaypointInputBox waypoint={waypoint} />;
                        })}
                    </div>
                </div>
            </WidgetFormWrapper>
        </div>
    );
});

export default OptimizationForm;
