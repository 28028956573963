import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { arrsHaveSomeMatchingElement, getAsTemplateStrArr, updateGoogleApiCounter } from 'src/utilis/utilis';
// import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import _ from 'lodash';
import { initialAddress } from 'src/screens/Main/components/AddLineForm/constants';
import InputWithErrorsWrapper from '../Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import { AddressAutoCompleteProps } from '../AddressInputs/ManualAndGoogleAddressInput/types.ManualAddressControlled';
import { AddressTypes, AddressTypes as AT, PlaceTypes, AutocompleteAddress } from './types';
import useDebounce from 'src/hooks/useDebounce';
import langHelpers from 'src/utilis/lanugageHelpers';
import styled, { css } from 'styled-components';
import { useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    setIsConfirmLocationButtonAction,
    setIsLocationChangedAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import useCommons from 'src/hooks/common/useCommons';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';
import { stat } from 'fs';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

//

const sx = {
    width: '100%',
    '& .MuiInputBase-root': {
        padding: '4px',
    },
} as const;

const isValid = (addressStr: string, comps: any[] = []) => {
    if (!comps || !addressStr) return true;
    const s = addressStr.replace(',', ' ').split(' ');

    const city = comps.find((component: { types: string | string[] }) => component.types.includes(AT.City));

    if (!city) return false;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { short_name, long_name } = city;
    if (!s.includes(short_name) && !s.includes(long_name)) return false;
};

const isPlaceOfInterest = (placeTypes: string[]): boolean => {
    const addressTypes: string[] = [AddressTypes.City, AddressTypes.Street, PlaceTypes.FullAddress];

    return !arrsHaveSomeMatchingElement(addressTypes, placeTypes);
};

const DisableWrapper = styled.div<{ isDisabled: boolean }>`
    width: 100%;
    ${({ isDisabled }) => {
        return isDisabled
            ? css`
                  background-color: #f0f0f0;
                  pointer-events: none;
              `
            : '';
    }}
`;

const DEBOUNCE_MS = 1000;

const AddressAutoComplete: React.FunctionComponent<AddressAutoCompleteProps> = ({
    setAddressData,
    defaultValue = '',
    errorMessage,
    error,
    setIsInputEmpty,
    label,
    showErrorMsg = true,
    useCoords = false,
    freeSolo = false,
    currAddressData = {},
    textFieldProps = {},
    inputTextControl,
    disabled = false,
}) => {
    const { t } = useTranslation();
    // -- google api hook --------------------------------
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
        debounce: DEBOUNCE_MS,
    });

    // whether the user selected an address from the google autocomplete list
    const [selectedGoogleAddress, setSelectedGoogleAddress] = useState(false);
    const [isInputTextFirstRender, setIsInputTextFirstRender] = useState(true);

    // input text on the text field
    const [inputText, setInputText] = React.useState(defaultValue);

    const inputTextController = {
        text: inputTextControl ? inputTextControl.inputText : inputText,
        setText: inputTextControl ? inputTextControl.setInputText : setInputText,
    };

    const updatedStationData = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.updateFormFromLocationTrigger.newData
    );
    const { dispatch } = useCommons();
    const { isDirectionsTriggeredFromEdit } = useRootAppSelector((state) => state.linesSlice.ui.form);

    const isConfirmLocationButton = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.isConfirmLocationButton
    );

    const settings = useRootAppSelector((state) => selectors.selectData(state).generalSettings.settings);

    const googleCounterUp = useGoogleApiCounterUp();

    // -- helpers --------------------------------
    const extractClearAddressData = React.useCallback(
        (placeDetails: google.maps.places.PlaceResult): AutocompleteAddress | null => {
            const data: AutocompleteAddress = {
                city: '',
                street: '',
                houseNum: '',
                placeName: '',
            };

            if (useCoords && placeDetails?.geometry?.location) {
                const { lat, lng } = placeDetails.geometry.location;
                data.lat = lat();
                data.lng = lng();
            }

            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { address_components } = placeDetails;
            if (!address_components) return null;

            // -- Handling place name
            if (placeDetails.types && isPlaceOfInterest(placeDetails.types)) {
                data.placeName = placeDetails.name || '';
            }

            // -- Handling city
            data.city =
                address_components.find((component) => component.types.includes(AT.City))?.short_name || '';

            if (!data.city && data.placeName) {
                data.city = data.placeName;
            }
            // -- Handling street
            data.street =
                address_components.find((component) => component.types.includes(AT.Street))?.short_name || '';

            // -- Handling house number
            data.houseNum =
                address_components.find((component) => component.types.includes(AT.HouseNum))?.short_name ||
                '';

            return data;
        },
        [useCoords]
    );

    const setAddressWithPlaceApiDetails = useCallback(
        (addressDetails: google.maps.places.AutocompletePrediction, inputLang?: 'iw' | 'en' | 'ar') => {
            googleCounterUp.googleApiCounterUp();
            setIsInputTextFirstRender(true);

            placesService?.getDetails(
                {
                    placeId: addressDetails.place_id,
                    language: inputLang || 'iw',
                },
                (placeDetails) => {
                    if (placeDetails) {
                        if (!isValid(addressDetails.description, placeDetails.address_components)) {
                            setAddressData(initialAddress);
                        }

                        const addressData = extractClearAddressData(placeDetails);

                        if (addressData) setAddressData(addressData);
                    }
                }
            );
        },
        [extractClearAddressData, placesService, setAddressData]
    );

    // -- event handlers --------------------------------
    const handleChange = useCallback(
        (e: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
            if (newValue) {
                const addressDetails = placePredictions.find((place) => place.description === newValue);
                if (addressDetails) {
                    setSelectedGoogleAddress(true);
                    dispatch(setIsConfirmLocationButtonAction({ isConfirmLocationButton: false }));

                    setAddressWithPlaceApiDetails(
                        addressDetails,
                        langHelpers.detectLanguageByCharCount(newValue)
                    );
                    return;
                }
                setSelectedGoogleAddress(false);
            } else {
                setAddressData(initialAddress);
            }
        },
        [placePredictions, setAddressData, setAddressWithPlaceApiDetails]
    );

    const handleInputTextChange = () => {
        console.log('handleInputTextChange IN');
        if (
            freeSolo &&
            typeof inputTextController.text === 'string' &&
            !selectedGoogleAddress &&
            defaultValue !== inputTextController.text // to avoid accidentally resetting the field on initial render
        ) {
            console.log('handleInputTextChange IN IF');
            const updatedAddress = { ...currAddressData, ...initialAddress, city: inputTextController.text };

            if (useCoords && !(updatedStationData && updatedStationData.city === inputTextController.text)) {
                console.log('resetting coords');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                updatedAddress['lat'] = 0;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                updatedAddress['lng'] = 0;
            }

            setAddressData(updatedAddress);
        }
    };

    const debouncedInputText = useDebounce(inputTextController.text, DEBOUNCE_MS);

    React.useEffect(() => {
        console.log('isInputTextFirstRender', isInputTextFirstRender);
        console.log('isDirectionsTriggeredFromEdit', isDirectionsTriggeredFromEdit);

        if (inputTextController.text && !isInputTextFirstRender && !isDirectionsTriggeredFromEdit) {
            // tamir matz

            googleCounterUp.googleApiCounterUp();
        }

        if (isDirectionsTriggeredFromEdit) {
            handleInputTextChange();
        }
    }, [debouncedInputText]);

    React.useEffect(() => {
        setIsInputTextFirstRender(false);
    }, []);

    const getTextFieldParams = (autocompleteParams: AutocompleteRenderInputParams) => {
        const baseParams = {
            label: label || 'כתובת',
            variant: 'outlined',
            error,
            size: 'small',
            InputLabelProps: { shrink: true },
            ...textFieldProps,
        } as const;

        const params = {
            ...autocompleteParams,
            ...baseParams,
            InputProps: { ...autocompleteParams.InputProps, ...(baseParams.InputProps || {}) },
            InputLabelProps: {
                ...(autocompleteParams.InputLabelProps || {}),
                ...(baseParams.InputLabelProps || {}),
            },
        };
        return params;
    };

    // -- JSX ---------------------------------------------------
    return (
        <DisableWrapper isDisabled={disabled}>
            <InputWithErrorsWrapper
                errorMessage={showErrorMsg ? t(getAsTemplateStrArr(errorMessage || '')) : ''}
            >
                <Autocomplete
                    disablePortal
                    id="size-small-standard"
                    size="small"
                    sx={sx}
                    freeSolo={freeSolo}
                    options={placePredictions.map((place) => place.description)}
                    filterOptions={(options) => options}
                    renderInput={(params) => <TextField autoFocus {...getTextFieldParams(params)} />}
                    inputValue={inputTextController.text}
                    onInputChange={(evt, value) => {
                        inputTextController.setText(value);
                        setIsInputTextFirstRender(false);

                        if (freeSolo) {
                            setSelectedGoogleAddress(false); // because address necessarily changed
                        }

                        if (!value) {
                            if (setIsInputEmpty) setIsInputEmpty(true);
                        } else {
                            getPlacePredictions({
                                input: value,
                                componentRestrictions: { country: 'isr' },
                                language: settings.languageForAddresses,
                            });
                            if (setIsInputEmpty) setIsInputEmpty(false);
                        }
                    }}
                    onChange={(e, newValue) => {
                        handleChange(e, newValue);
                    }}
                />
            </InputWithErrorsWrapper>
        </DisableWrapper>
    );
};

export default AddressAutoComplete;

/*
    {
        city...
        isDirtyByUser: false
    }

*/
