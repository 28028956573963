import { DayNum } from 'src/types/global';

export type RelativeDateFormat = 'YYYY-MM-DD';
export type DisplayUiLong = 'DD.MM.YYYY';

export const format: RelativeDateFormat = 'YYYY-MM-DD';
export const SHORT = 'DD-MM-YY';
export const DISPLAY_UI = 'DD.MM.YY';
export const DAY_MONTH = 'DD-MM';
export const DISPLAY_UI_SHORT = 'DD.MM';
export const DISPLAY_UI_LONG = 'DD.MM.YYYY';
export const LONG_LTR = 'DD-MM-YYYY';
export const LONG_RTL = 'YYYY-MM-DD';
export const RELATIVE: RelativeDateFormat = 'YYYY-MM-DD';
export const RELATIVE_US = 'YYYY-DD-MM';
export const DAY_NAME = 'dddd';
export const DAYS_NUMBERS: DayNum[] = [1, 2, 3, 4, 5, 6, 7];
export const DAYS_HE = {
    1: 'ראשון',
    2: 'שני',
    3: 'שלישי',
    4: 'רביעי',
    5: 'חמישי',
    6: 'שישי',
    7: 'שבת',
} as const
export const DAYS_HE_SHORT = {
    1: 'א',
    2: 'ב',
    3: 'ג',
    4: 'ד',
    5: 'ה',
    6: 'ו',
    7: 'ש',
} as const;

const DATE_FORMATS = {
    DAY_MONTH,
    SHORT,
    LONG: format,
    RELATIVE: format,
    LONG_LTR: 'DD-MM-YYYY',
    DISPLAY_UI,
};

export const TIME_FORMATS = {
    TWENTY_FOUR: 'HH:mm',
    TWELVE: 'hh:mm',
};
export default DATE_FORMATS;
