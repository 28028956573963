/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uiReducers, { OnChangeFilterPayload } from 'src/store/reducers/reportsReducers/uiReducers';
import generalReducers from 'src/store/reducers/reportsReducers/generalReducers';
import {
    ErrorActionPayload,
    ProcessedStatistics,
    StatisticsRequestConfig,
} from 'src/types/reports/generalReportsTypes';
import { OnFetchBudgetDataStatusUpdatePayload } from 'src/types/reports/slice.types';
import initialStates from './initialStates';
import dataReducers from '../../reducers/reportsReducers/dataReducers';
import { IReportsState, OnSelectReportPayload, ReportNames, ShortDepartmentData } from './types';
import { departmentFilter } from '../../../components/Table/DepartmentFilter/DepartmentFilter';
import { CallGetShiftRequest, callGetShiftTimes } from '../../../api/reportsApi/filters/filtersApi';

// Define the initial state using that type
// * Slice
export const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialStates.reports,
    reducers: {
        // ^ UI reducers
        // * Header
        onSelectClientId: (state: IReportsState, action: PayloadAction<any>) =>
            uiReducers.onSelectClientId(state, action),
        onPrintStatusUpdate: (state: IReportsState, action: PayloadAction<any>) => {
            state.ui.common.isPrinting = action.payload.isPrinting;
        },

        // * Main
        onSelectReport: (state: IReportsState, action: PayloadAction<OnSelectReportPayload>) =>
            uiReducers.onSelectReport(state, action),

        // * Filters
        onChangeFilter: (state: IReportsState, action: PayloadAction<any>) =>
            uiReducers.onChangeFilter(state, action),
        incrementApplyFiltersBtnClickCount: (state: IReportsState) =>
            uiReducers.incrementApplyFiltersBtnClickCount(state),
        incrementRefreshPageBtnClickCount: (state: IReportsState) =>
            uiReducers.incrementRefreshPageBtnClickCount(state),
        clearFilters: (state: IReportsState) => uiReducers.clearFilters(state),
        onChangeFilterByReport: (state: IReportsState, action: PayloadAction<OnChangeFilterPayload>) =>
            uiReducers.onChangeFilterByReport(state, action),
        setPassengersReportDateFilter: uiReducers.setPassengersReportDateFilter,
        // * Sorting
        onSortOrFilter: (state: IReportsState, action: PayloadAction<any>) =>
            uiReducers.onSort(state, action),

        // * Common
        onError: (state: IReportsState, action: PayloadAction<ErrorActionPayload>) =>
            uiReducers.onError(state, action),

        resetState: (state: IReportsState) => uiReducers.resetState(state),

        // * Widgets
        onRefreshBtnClick: (state: IReportsState) => uiReducers.onRefreshBtnClick(state),

        toggleWidgetDrawer: (state: IReportsState) => {
            state.ui.widgets.isOpen = !state.ui.widgets.isOpen;
        },
        setDateOnWidget: (
            state: IReportsState,
            action: PayloadAction<{
                newDate: Date;
            }>
        ) => {
            uiReducers.setDateOnWidget(state, action);
        },

        // ^ Api reducers
        // * Data requests
        // -- reports data
        fetchShortOrders: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.fetchShortOrders(state, action),
        fetchReport: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.fetchReport(state, action),
        onApiCallTimeout: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onApiCallTimeout(state, action),

        // -- filters data
        onFetchDepartments: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onFetchDepartments(state, action),
        fetchShiftTimes: (state: IReportsState, action: PayloadAction<CallGetShiftRequest>) =>
            dataReducers.fetchShiftTimes(state, action),
        onFetchShiftTimes: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onFetchShiftTimes(state, action),

        // -- widgets data
        fetchDepartmentsStatistics: (state: IReportsState, action: PayloadAction<StatisticsRequestConfig>) =>
            dataReducers.fetchDepartmentsStatistics(state, action),

        onFetchRidesStatisticsSuccess: (
            state: IReportsState,
            action: PayloadAction<{ statistics: ProcessedStatistics }>
        ) => dataReducers.onFetchRidesStatisticsSuccess(state, action),

        onFetchBudgetDataStatusUpdate: (
            state: IReportsState,
            action: PayloadAction<OnFetchBudgetDataStatusUpdatePayload>
        ) => {
            if (!action.payload) {
                state.data.widgets.budget.request.isSuccess = false;

                state.data.widgets.budget.data.monthlyBudget = -1;
                state.data.widgets.budget.data.dailyCost = -1;
                state.data.widgets.budget.data.totalVisasCost = -1;

                return;
            }

            const { data } = action.payload;

            state.data.widgets.budget.request.isSuccess = true;

            state.data.widgets.budget.data = data;
        },

        // * Request's statuses
        onFetchShortOrdersStatusUpdate: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onFetchShortOrdersStatusUpdate(state, action),
        onFetchReportSuccess: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onFetchReportSuccess(state, action),
        onFetchReportStatusUpdate: (state: IReportsState, action: PayloadAction<any>) =>
            dataReducers.onFetchReportStatusUpdate(state, action),
        resetRidesStatisticsData: (state: IReportsState) => dataReducers.resetRidesStatisticsData(state),

        // ^ general reducers
        onUpdateAuthorizedReportsList: (state: IReportsState, action: PayloadAction<any>) =>
            generalReducers.onUpdateAuthorizedReportsList(state, action),
    },
});

// * Exports
// ^ Actions
export const {
    onSelectReport: onSelectReportAction,
    fetchShortOrders: fetchShortOrdersAction,
    onFetchShortOrdersStatusUpdate: onFetchShortOrdersStatusUpdateAction,
    onChangeFilter: onChangeFilterAction,
    onSelectClientId: onSelectClientIdAction,
    onFetchDepartments: onFetchDepartmentsAction,
    fetchShiftTimes: fetchShiftTimesAction,
    onFetchShiftTimes: onFetchShiftTimesAction,
    onSortOrFilter: onSortOrFilterAction,
    onFetchReportSuccess: onFetchReportSuccessAction,
    onFetchReportStatusUpdate: onFetchReportStatusUpdateAction,
    fetchReport: fetchReportAction,
    incrementApplyFiltersBtnClickCount: incrementApplyFiltersBtnClickCountAction,
    incrementRefreshPageBtnClickCount: incrementRefreshPageBtnClickCountAction,
    onError: onErrorAction,
    clearFilters: clearFiltersAction,
    onUpdateAuthorizedReportsList: onUpdateAuthorizedReportsListAction,
    onApiCallTimeout: onApiCallTimeoutAction,
    resetState: resetStateAction,
    fetchDepartmentsStatistics: fetchDepartmentsStatisticsAction,
    onPrintStatusUpdate: onPrintStatusUpdateAction,
    onFetchRidesStatisticsSuccess: onFetchRidesStatisticsSuccessAction,
    onRefreshBtnClick: onRefreshBtnClickAction,
    resetRidesStatisticsData: resetRidesStatisticsDataAction,
    toggleWidgetDrawer: toggleWidgetDrawerAction,
    onChangeFilterByReport: onChangeFilterByReportAction,
    setDateOnWidget: setDateOnWidgetAction,
    onFetchBudgetDataStatusUpdate: onFetchBudgetDataStatusUpdateAction,
    setPassengersReportDateFilter: setPassengersReportDateFilterAction,
} = reportsSlice.actions;

// ^ Reducer export
const reportsReducer = reportsSlice.reducer;
export default reportsReducer;
