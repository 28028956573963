import React, { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Alert from 'src/components/commons/Alert/Alert';
import NavBar from 'src/components/NavBar/NavBar';
import { setErrorCode, setErrorMessage } from 'src/store/actions/loginAction';
import { IRootReducer } from 'src/store/reducers';
import { errorMessageSelector, errorCodeSelector } from 'src/store/selectores/loginSelectors';
import { PassengersErrorMessagesEnum } from 'src/types/global';
import DropDisplayScreen from 'src/screens/DropsDisplay/DropDisplayScreen';
import { useAppSelector } from 'src/store/hooks';
import { uiSelector } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import UploadFileModal from 'src/features/uploadFile/UploadFileModal/UploadFileModal';
import useEnvAuth from 'src/hooks/useEnvAuth';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { env } from 'process';
import useAuthorization from '../hooks/useAuthorization';
import useNavRoute from '../hooks/useNavRoute';
import useRefreshToken from '../hooks/useRefreshToken';
import styles, { mainStyles } from './Home.style';
import DisplayWrapper from '../../components/Wrappers/DisplayWrapper/index';
import { useAlertV2 } from 'src/hooks/useAlert';
// eslint-disable-next-line @typescript-eslint/no-empty-interface

type ErrorDic = {
    [index in PassengersErrorMessagesEnum]: string;
};

const position = {
    vertical: 'top',
    horizontal: 'center',
} as const;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHome {}
const EnvWarningAlert = () => {
    const envAuth = useEnvAuth();

    return (
        <Snackbar
            open={envAuth.alertDisplayState.showAlert}
            autoHideDuration={6000}
            anchorOrigin={position}
            onClose={() => envAuth.alertDisplayState.setShowAlert(false)}
            sx={{ width: '500px' }}
        >
            <MuiAlert
                sx={{
                    position: 'absolute',
                    top: '80px',
                    backgroundColor: 'red',
                    fontSize: '20px',
                    color: 'white',
                }}
                severity="error"
            >
                שים לב אתה עובד על סביבת פרודקשן של לקוח
            </MuiAlert>
        </Snackbar>
    );
};
const Home: FC<IHome> = () => {
    const { routes } = useNavRoute();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const errorMessagesDic = useMemo<ErrorDic>(() => {
        const result: any = {};

        result[PassengersErrorMessagesEnum.ClientCodeIsMissing] = t('clientCodeNotExiest');
        result[PassengersErrorMessagesEnum.InternalPassengerCodeExiest] = t('internalPassengerCodeExiest');
        result[PassengersErrorMessagesEnum.ClientCodeIsMissing] = t('clientCodeNotExiest');
        result[PassengersErrorMessagesEnum.GenralError] = t('genralError');

        return result;
    }, [t]);

    const errorMessage = useSelector((state: IRootReducer) => errorMessageSelector(state));

    const errorCode = useSelector((state: IRootReducer) => errorCodeSelector(state));

    const alertConfig = useAlertV2();

    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const handleCloseSnack = () => {
        dispatch(setErrorMessage(undefined));
        dispatch(setErrorCode(undefined));
        setShowErrorAlert(false);
    };

    useRefreshToken();
    useAuthorization();

    // custome errors
    useEffect(() => {
        if (errorMessage) {
            setShowErrorAlert(true);
            setError(errorMessage);
        }
    }, [errorMessage]);

    // errors
    useEffect(() => {
        if (errorCode && errorCode in errorMessagesDic) {
            setShowErrorAlert(true);
            setError(errorMessagesDic[errorCode]);
        }
    }, [errorCode, errorMessagesDic]);

    const isOnDropsDisplay = useAppSelector((state) => uiSelector(state).isScreenDisplayed);

    return (
        <>
            {isOnDropsDisplay && <DropDisplayScreen />}
            <EnvWarningAlert />
            <DisplayWrapper show={!isOnDropsDisplay}>
                <mainStyles.MainContainer>
                    <mainStyles.FullContainer>
                        <NavBar routes={routes} />
                    </mainStyles.FullContainer>
                    <mainStyles.FullContainer>
                        <Switch>
                            {routes.map((route) => (
                                // Render more <Route>s with the same paths as
                                // above, but different components this time.
                                <Route key={route.routeName} path={route.path} exact={route.exact}>
                                    {route.main}
                                </Route>
                            ))}
                        </Switch>
                        <UploadFileModal />
                        <Alert open={showErrorAlert} onClose={handleCloseSnack} severity="error">
                            {error}
                        </Alert>
                        <Alert {...alertConfig.props}>{alertConfig.message}</Alert>
                    </mainStyles.FullContainer>
                </mainStyles.MainContainer>
            </DisplayWrapper>
        </>
    );
};

export default Home;
