/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import Dialog from 'src/components/Dialog/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import { PaperProps as PaperPropsType } from '@mui/material';
import { TransitionHandlerProps } from '@mui/material/transitions';

export interface IConfirmationDialogProps {
    onCloseDialog: () => void;
    onConfirmClick: () => void;
    isDialogOpen: boolean;
    DialogContent: ReactNode;
    DialogTitle: ReactNode;
    isRestoreDialog?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    loadingConfirm?: boolean;
    loadingCancel?: boolean;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
    onCloseDialog,
    onConfirmClick,
    isDialogOpen,
    DialogContent,
    DialogTitle,
    isRestoreDialog,
    disableBackdropClick,
    disableEscapeKeyDown,
    loadingConfirm,
    loadingCancel,
}: IConfirmationDialogProps) => {
    const { t } = useTranslation();

    const DialogAction = () => {
        return (
            <>
                <LoadingButton loading={loadingConfirm} onClick={onConfirmClick} color="primary">
                    {isRestoreDialog ? t('approve') : t('ok')}
                </LoadingButton>
                <LoadingButton loading={loadingCancel} onClick={onCloseDialog} color="primary">
                    {isRestoreDialog ? t('cancel') : t('no')}
                </LoadingButton>
            </>
        );
    };

    return (
        <Dialog
            DialogActionsChildren={DialogAction()}
            DialogContentChildren={DialogContent}
            DialogTitleChildren={DialogTitle}
            open={isDialogOpen}
            onClose={onCloseDialog}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
        />
    );
};

export default ConfirmationDialog;
