import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    modefiedPassengerSelector,
    selectedPassengerPasscodeSelector,
    passengersUISelector,
    modifyingOrAddingPassengerUiSelecter,
} from 'src/store/selectores/passengersSelector';
import {
    setChosenCoursesAction,
    setInitialChosenCoursesAction,
} from 'src/store/slices/passengersManager/passengersManagerSlice';
import { ChosenCourse } from 'src/types/passengersManager/courseChoosingTypes';
import { getPassengers } from 'src/api/passengerMangerApi/passengerMangerApi';
import { onErrorAction } from 'src/store/slices/reports/reportsSlice';
import { getInitialAddressState, findAddress } from './Components/AddressesBox/utils';
import { useIsFirstRender } from '../../../../hooks/useIsFirstRender';
import { IPassenger, IAddress } from '../../../../api/passengerMangerApi/types';
import { RawInputAddress, SecondaryIdentifier } from './Components/AddressesBox/AddressForm/types';

export const addressTypes = ['mainAddress', 'secondaryOne', 'secondaryTwo'];

export const useAddressInputs = (modefiedPassenger: IModefiedPassenger): any => {
    // const isFirstRender = useIsFirstRender();
    const initialMainAddress = getInitialAddressState(findAddress('main', modefiedPassenger), '0');
    const initialSecondaryOne = getInitialAddressState(findAddress('secondaryOne', modefiedPassenger), '1');

    const initialSecondaryTwo = getInitialAddressState(findAddress('secondaryTwo', modefiedPassenger), '2');

    const [mainAddress, setMainAddress] = useState<RawInputAddress>({
        ...initialMainAddress,
        addressCode: initialMainAddress.addressCode,
        secondaryIdentifier: '0',
    });
    // useEffect(() => {
    //    console.log({
    //       modefiedPassenger,
    //       mainAddress,
    //       initialMainAddress,
    //    });
    // }, [initialMainAddress, mainAddress, modefiedPassenger]);
    const [secondaryOne, setSecondaryOne] = useState<RawInputAddress>({
        ...initialSecondaryOne,
        secondaryIdentifier: '1',
    });
    const [secondaryTwo, setSecondaryTwo] = useState<RawInputAddress>({
        ...initialSecondaryTwo,
        secondaryIdentifier: '2',
    });

    useEffect(() => {
        setMainAddress({
            ...initialMainAddress,
            addressCode: initialMainAddress.addressCode,
            secondaryIdentifier: '0',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialMainAddress.addressCode]);

    return {
        mainAddress,
        secondaryOne,
        secondaryTwo,
        setMainAddress,
        setSecondaryOne,
        setSecondaryTwo,
    };
};

const getInitialChosenCourses = (modefiedPassengerAddresses: any[]): ChosenCourse[] => {
    return modefiedPassengerAddresses.map(
        (address: { addressCode: any; secondaryIdentifier: any; dropCourse: any; pickupCourse: any }) => ({
            addressCode: address.addressCode || address.secondaryIdentifier || '',
            drop: address.dropCourse || '',
            pickup: address.pickupCourse || '',
        })
    );
};
export const useModefiedPsngrCurrCourses = (modefiedPassengerAddress: any[]): void => {
    const dispatch = useAppDispatch();

    const selectedPassenger = useSelector((state: IRootReducer) => selectedPassengerPasscodeSelector(state));
    const isAddingPassenger = useSelector(
        (state: IRootReducer) => passengersUISelector(state).addingPassenger
    );

    useEffect(() => {
        if (!selectedPassenger || isAddingPassenger) return;
        const chosenCourses: ChosenCourse[] = getInitialChosenCourses(modefiedPassengerAddress);
        dispatch(setChosenCoursesAction({ chosenCourses }));
    }, [dispatch, isAddingPassenger, modefiedPassengerAddress, selectedPassenger]);
};

export const useUpdatedModefiedPassengerData = (args: {
    setModefiedPassenger: Function;
    proxyUrl: string;
    dbUrl: string;
    token: string;
    clientCode: string | number | undefined;
}): void | undefined => {
    const { setModefiedPassenger, ...rest } = args;
    // const { passCode } = modefiedPassenger;

    const { addingPassenger: isAddingNewPassenger } = useSelector((state: IRootReducer) =>
        modifyingOrAddingPassengerUiSelecter(state)
    );
    const passCode = useSelector((state: IRootReducer) => modefiedPassengerSelector(state)?.passCode || '');
    const [u, setU] = useState<any>(null);

    const dispatch = useAppDispatch();

    const dispatchErrorAction = useCallback(
        (details: any) => {
            dispatch(
                onErrorAction({
                    msg: 'שגיאה בעריכת הנוסע, אנא רענן את עמוד ונסה שנית',
                    details,
                })
            );
        },
        [dispatch]
    );

    const fetchPassengerData = useCallback(async () => {
        const payload = { ...rest, passCode: String(passCode) };
        const { data } = await getPassengers(payload);
        const { response, passList } = data;
        if (response === '0') {
            setModefiedPassenger(passList[0]);
            // eslint-disable-next-line prefer-destructuring
            setU(passList[0]);
        } else {
            dispatchErrorAction({ response, passList });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchErrorAction, rest, setModefiedPassenger]);

    if (!passCode && !isAddingNewPassenger) {
        dispatchErrorAction({ passCode });
    }

    useEffect(() => {
        // console.log('asdz2');
        if (isAddingNewPassenger) return;
        fetchPassengerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passCode, isAddingNewPassenger]);

    // useEffect(() => {
    //    console.log(u?.address);
    // }, [u]);
    useEffect(() => {
        // console.log('1213');
        if (u) {
            const initialChosenCourses = getInitialChosenCourses(u.address);
            dispatch(setInitialChosenCoursesAction({ initialChosenCourses }));
        }
    }, [dispatch, u]);
};
