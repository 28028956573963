import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import { images } from 'src/assets';
import { Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Styles from 'src/screens/ManualOrder/ManualOrderTable/Shiftcell/Shiftcell.Style';

interface Props {
    isOpen: boolean;
    onClose: (e: any) => void;
    onConfirm: (e: any) => void;
    Body: () => JSX.Element;
    loading?: boolean;
}

const DeleteConfirmModal: FC<Props> = ({ isOpen, onClose, onConfirm, Body, loading: showSpinner }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Styles.ModalContainer>
                <Styles.CircleModal>
                    <img width={27} height={27} src={images.grayTrash} alt="" />
                </Styles.CircleModal>
                <Styles.ModalBody>{Body()}</Styles.ModalBody>
                <Styles.ModalAction>
                    <LoadingButton onClick={onConfirm} color="primary" loading={showSpinner}>
                        {t('ok')}
                    </LoadingButton>
                    <Button onClick={onClose} style={{ color: 'gray' }}>
                        {t('cancel')}
                    </Button>
                </Styles.ModalAction>
            </Styles.ModalContainer>
        </Dialog>
    );
};

export default DeleteConfirmModal;
