import React, { CSSProperties, FC } from 'react';

import { IconButton } from '@material-ui/core';
import Autorenew from '@material-ui/icons/Autorenew';
import { useTranslation } from 'react-i18next';
import { StyledRefreshBtn } from './RefreshButton.style';
import MyRefreshIcon from '../Icons/MyRefreshIcon';

export interface IRefreshButton {
    onClick: () => void;
    disabled?: boolean | undefined;
}

const getRefreshButtonCss: (disabled: any) => CSSProperties = (disabled) => ({
    border: disabled ? '1px solid gray' : '1px solid #2196F3',
    borderRadius: '4px',
    // padding: '6px',
    marginRight: '5px',
    fontSize: '30px',
    width: '40px',
    height: '40px',
});

const RefreshButton: FC<IRefreshButton> = ({ onClick, disabled }: IRefreshButton) => {
    const { t } = useTranslation();
    return (
        <IconButton
            style={getRefreshButtonCss(disabled)}
            color="primary"
            onClick={onClick}
            size="small"
            disabled={disabled}
        >
            {/* <Autorenew
               color="primary"
               titleAccess={t('refresh')}
               fontSize="inherit"
            /> */}
            <MyRefreshIcon width="24.5px" height="24.5px" color={disabled ? 'gray' : '#2196F3'} />
            {/* {t('refresh')} */}
        </IconButton>
    );
};

export default RefreshButton;
