import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { lineSelector } from 'src/store/selectores/linesSelectores';
import { Styled } from './styled.DetailsAccordionBody';

interface Details {
    title: string;
    details: {
        name: string;
        value: string;
    }[];
}
const DetailBox: React.FC<{
    details: Details;
    last?: boolean;
}> = ({ details: { title, details }, last }) => {
    return (
        <>
            <Styled.DetailBox>
                <Styled.Title>{title}</Styled.Title>
                {details.map((detail) => {
                    return (
                        <Styled.Details>
                            <Styled.Name>{detail.name}</Styled.Name>
                            <Styled.Value>{detail.value}</Styled.Value>
                        </Styled.Details>
                    );
                })}
            </Styled.DetailBox>
            {!last && <Styled.Hr />}
        </>
    );
};

const hasAtLeastOneTrueVal = (details: Details): boolean => {
    return details.details.some((detail) => detail.value !== '-');
};

type Props = {};

function DetailsAccordionBody({}: Props) {
    const { t } = useTranslation();
    const selectedLine = useSelector((state) => lineSelector(state));

    const {
        driverName,
        mobilePhone,
        invContactName,
        invContactPhone,
        flightNumber,
        clientOrderCode,
        longRemarks,
        createdBy,
    } = selectedLine || {};

    const driverDetailsProp: Details = useMemo(
        () => ({
            title: t('driverDetails'),
            details: [
                {
                    name: t('driverName'),
                    value: driverName || '-',
                },
                {
                    name: t('driverPhone'),
                    value: mobilePhone || '-',
                },
            ],
        }),
        [driverName, mobilePhone, t]
    );

    const generalInfoProp: Details = useMemo(
        () => ({
            title: t('generalInfo'),
            details: [
                {
                    name: t('contactName'),
                    value: invContactName || '-',
                },
                {
                    name: t('contactPhone'),
                    value: invContactPhone || '-',
                },
                {
                    name: t('flightNum'),
                    value: flightNumber || '-',
                },
                {
                    name: t('clientOrderCode'),
                    value: clientOrderCode || '-',
                },
            ],
        }),
        [clientOrderCode, flightNumber, invContactName, invContactPhone, t]
    );

    const remarksProp: Details = useMemo(
        () => ({
            title: t('remarks'),
            details: [{ name: '', value: longRemarks || '-' }],
        }),
        [longRemarks, t]
    );

    return (
        <Styled.Wrapper>
            {createdBy && (
                <Styled.DetailBox>
                    <Styled.Title>{t('lineCreator')}</Styled.Title>
                    <Styled.Details>
                        <Styled.Value>{createdBy}</Styled.Value>
                    </Styled.Details>
                </Styled.DetailBox>
            )}
            {hasAtLeastOneTrueVal(driverDetailsProp) && <DetailBox details={driverDetailsProp} />}
            {hasAtLeastOneTrueVal(generalInfoProp) && <DetailBox details={generalInfoProp} />}
            {hasAtLeastOneTrueVal(remarksProp) && <DetailBox details={remarksProp} last />}
        </Styled.Wrapper>
    );
}

export default DetailsAccordionBody;
