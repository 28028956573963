import { TableHead } from '@material-ui/core';
import React from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { StyledTableRow, StyledTableCell, colHeaderStyle, headerCellStyle, iconStyle } from '../styles';

const PassengersTableHead = ({ t }: { t: Function }): JSX.Element => {
    return (
        <TableHead>
            <StyledTableRow className="tr-visas">
                <StyledTableCell component="th" sx={colHeaderStyle}>
                    <div style={headerCellStyle}>
                        <AccessTimeOutlinedIcon sx={iconStyle} />
                        {t('time')}
                    </div>
                </StyledTableCell>
                <StyledTableCell component="th" sx={colHeaderStyle}>
                    <div style={headerCellStyle}>
                        <PersonOutlineOutlinedIcon sx={iconStyle} />
                        {t('fullName')}
                    </div>
                </StyledTableCell>
                <StyledTableCell component="th" sx={colHeaderStyle}>
                    <div style={headerCellStyle}>
                        <FmdGoodOutlinedIcon sx={iconStyle} />
                        {t('address')}
                    </div>
                </StyledTableCell>
                <StyledTableCell component="th" sx={colHeaderStyle}>
                    <div style={headerCellStyle}>
                        <LockOpenIcon sx={iconStyle} />
                        {t('passId')}
                    </div>
                </StyledTableCell>
            </StyledTableRow>
        </TableHead>
    );
};

export default PassengersTableHead;
