import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    margin: 40px 0;
`;

interface Props {
    children: string;
}

const Title = ({ children }: Props): JSX.Element => {
    return <Text>{children}</Text>;
};

export default Title;
