import { useTranslation } from 'react-i18next';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    orderCodesToDeleteSelector,
    passengersDatesSelector,
    passengersOrdersCountPerDateSelector,
    passengersWithDeletedOrdersSelector,
    resetShiftDeletionAction,
    screenClientCodeSelector,
    selectedPassengersIdsSelector,
    selectedPassengersSelector,
    setApiCallStatusAction,
    setCapsulesForDeletionByAllSelectedPassengersAction,
    setShiftTargetedToDeleteAction,
    shiftCapsulesByPassengerSelector,
    shiftCapsulesIdsSelector,
    shiftTargetedToDeleteSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { delPassngerShifts } from 'src/api/manualOrderApi/manualOrderApi';
import { removePassengersShift } from 'src/store/actions/PassengersShiftActionType';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import { asRootReducer } from 'src/store/utils';
import { useErrorMessageDispatcher } from 'src/hooks/useErrorHandler';
import { isSameDate } from 'src/utilis/utilis';
import { moSliceConstants } from 'src/store/slices/manualOrders/helpers';
import { ShiftToDelete } from 'src/store/slices/manualOrders/types';
import { ApiCallStatus } from 'src/types/rideSettings/store/types.store';
import { setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import { manualOrdersApiConsts } from 'src/api/manualOrderApi/constants';
import { getOrderPassCodesForPassIdAndDate, getUpdatedOrdersCount } from '../utilis';

export interface BaseShiftCapsuleData {
    date: string;
    passId: string;
    codes?: string[];
}

const TIME_UNTIL_PICK_ALL_SHIFTS = 500;
const { PASSENGER_DATA_INDEX: ID_INDEX } = moSliceConstants;

const useHelpers = () => {
    const allShiftCapsules = useRootAppSelector(shiftCapsulesByPassengerSelector);
    const getCapsuleId = (passId: string, date: Date, times: string) => {
        if (!allShiftCapsules) return null;

        const capsuleForPassenger = allShiftCapsules[passId];
        if (!capsuleForPassenger) return null;

        const capsuleForDate = capsuleForPassenger.filter((cfp) => isSameDate(cfp.date, date));
        if (!capsuleForDate) return null;

        return capsuleForDate.find((cfd) => cfd.times === times)?.id || null;
    };

    return { getCapsuleId };
};
const useTimer = () => {
    const [reachedTime, setReachedTime] = useState(false);
    const [running, setRunning] = useState(false);

    const start = useCallback(() => setRunning(true), []);
    const stop = useCallback(() => setRunning(false), []);
    const reset = useCallback(() => {
        setReachedTime(false);
        setRunning(false);
    }, []);

    useEffect(() => {
        // console.log(3);
        let timeout: NodeJS.Timeout | undefined;

        if (running) {
            timeout = setTimeout(() => {
                setReachedTime(true);
            }, TIME_UNTIL_PICK_ALL_SHIFTS);
            // console.log(3.1);
        } else if (!running && timeout) {
            clearTimeout(timeout);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [running]);

    return { reachedTime, start, stop, reset, running };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useMultiDeleteTimer = (shiftCapsuleData: ShiftToDelete, isDeleteModalOpen: boolean) => {
    const dispatch = useAppDispatch();
    const timer = useTimer();

    // -- Selectors
    const shiftTargetedToDelete = useRootAppSelector(shiftTargetedToDeleteSelector);
    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector) || [];

    // -- Functions
    const handleOnMouseEnter = useCallback(() => {
        timer.start();
        dispatch(setShiftTargetedToDeleteAction(shiftCapsuleData));
    }, [dispatch, shiftCapsuleData, timer]);

    const handleOnMouseLeave = useCallback(() => {
        timer.stop();
        if (!isDeleteModalOpen) {
            timer.reset();
            dispatch(setShiftTargetedToDeleteAction(null));
        }
    }, [dispatch, isDeleteModalOpen, timer]);

    useEffect(() => {
        // console.log(4);
        if (shiftTargetedToDelete) {
            const targetedShiftIsForSelectedPassenger = selectedPassengersIds.includes(
                shiftTargetedToDelete.passId
            );

            if (timer.reachedTime && selectedPassengersIds.length && targetedShiftIsForSelectedPassenger) {
                // console.log(4.1);
                dispatch(setCapsulesForDeletionByAllSelectedPassengersAction());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, timer.reachedTime]);

    // -- resetting timer after delete modal display state change
    const resetShiftTargetingAndTimer = useCallback(() => {
        if (!isDeleteModalOpen && shiftTargetedToDelete) {
            timer.reset();
            dispatch(setShiftTargetedToDeleteAction(null));
        }
    }, [dispatch, isDeleteModalOpen, shiftTargetedToDelete, timer]);

    useEffect(() => {
        resetShiftTargetingAndTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteModalOpen, timer.reset]);
    return { handleOnMouseEnter, handleOnMouseLeave };
};

const useShiftCapsuleDeletionHelpers = () => {
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();
    const { t } = useTranslation();
    const [dispatchErrorMsg] = useErrorMessageDispatcher();
    // -- Selectors

    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector);
    const orderCodesToDelete = useRootAppSelector(orderCodesToDeleteSelector);
    const selectedFcAccount = useSelector((state) => selectedFcAccountSelector(state));
    const token = useSelector((state) => tokenSelector(asRootReducer(state)));
    const { startTime, endTime } = useRootAppSelector(shiftTargetedToDeleteSelector) || {};
    // const ordersCounter = useRootAppSelector(
    //     passengersOrdersCountPerDateSelector
    // );
    const passengersDates = useRootAppSelector(passengersDatesSelector);
    const shiftToDelete = useRootAppSelector(shiftTargetedToDeleteSelector);
    const targetedCapsules = useRootAppSelector(shiftCapsulesIdsSelector);
    const passengersWithDeletedOrders = useRootAppSelector(passengersWithDeletedOrdersSelector);
    const clientCode = useRootAppSelector(screenClientCodeSelector);

    // -- Local states
    const [isOnDeleteBtn, setIsOnDeleteBtn] = useState(false);

    // -- Functions
    const dispatchStatusUpdate = useCallback(
        (newStatus: ApiCallStatus) =>
            dispatch(
                setApiCallStatusAction({
                    type: 'isDeletingOrders',
                    newStatus,
                })
            ),
        [dispatch]
    );

    const isTargetedToDelete = useCallback(
        (capsuleId) => {
            if (isOnDeleteBtn || targetedCapsules.includes(capsuleId)) return true;
        },
        [isOnDeleteBtn, targetedCapsules]
    );

    const removeShiftCapsules = useCallback(
        (
            deletionData: {
                codes: string;
                passIds: string;
            }
            // eslint-disable-next-line consistent-return
        ) => {
            if (!passengersDates || !shiftToDelete) return -1;
            deletionData.passIds.split(',').forEach((passId: string) => {
                const orderCodes = getOrderPassCodesForPassIdAndDate(
                    passId,
                    shiftToDelete,
                    passengersDates,
                    orderCodesToDelete
                );
                dispatch(
                    removePassengersShift({
                        passId,
                        orderCodes,
                    })
                );
            });
        },
        [dispatch, orderCodesToDelete, passengersDates, shiftToDelete]
    );

    const onDelete = useCallback(
        async (shiftCapsule: BaseShiftCapsuleData, closeModal) => {
            if (!passengersDates || !shiftToDelete) {
                dispatchErrorMsg();
                return;
            }

            dispatchStatusUpdate('LOADING');

            const isSelected = selectedPassengersIds.includes(shiftCapsule.passId);

            const deletionData = {
                codes: isSelected ? orderCodesToDelete.join(',') : shiftCapsule.codes?.join(',') || '',
                passIds: isSelected ? passengersWithDeletedOrders.join(',') : String(shiftCapsule.passId),
            };

            if (selectedFcAccount && clientCode) {
                const requestData = {
                    dbUrl: selectedFcAccount.dbUrl || '',
                    proxyUrl: selectedFcAccount.proxyUrl || '',
                    orders: deletionData.codes || '',
                    relative_date: shiftCapsule.date,
                    pass_id: deletionData.passIds,
                    token,
                    pickupTime: startTime || '',
                    dropTime: endTime || '',
                    clientCode,
                };

                try {
                    const res = await delPassngerShifts(requestData);
                    const response = +res.data.response;

                    if (response === 0) {
                        removeShiftCapsules(deletionData);
                        dispatchStatusUpdate('SUCCESS');
                        dispatch(
                            setAlertConfigAction({
                                severity: 'success',
                                message: t('deleteSuccess'),
                            })
                        );
                    } else {
                        const errorMessage =
                            manualOrdersApiConsts.DEL_ORDERS_REQ_ERROR_STINGS[response] ||
                            t('oopsSomethingWentWrongTryAgain');
                        dispatchErrorMsg(errorMessage);
                        dispatchStatusUpdate('FAILED');
                    }
                } catch (error) {
                    dispatchErrorMsg(`ERROR - ${t('oopsSomethingWentWrongTryAgain')}`);
                }

                closeModal();
                tkDispatch(resetShiftDeletionAction());
            }
        },
        [
            clientCode,
            dispatch,
            dispatchErrorMsg,
            dispatchStatusUpdate,
            endTime,
            orderCodesToDelete,
            passengersDates,
            passengersWithDeletedOrders,
            removeShiftCapsules,
            selectedFcAccount,
            selectedPassengersIds,
            shiftToDelete,
            startTime,
            t,
            tkDispatch,
            token,
        ]
    );

    return { isTargetedToDelete, setIsOnDeleteBtn, onDelete };
};

export const shiftHooks = {
    useShiftCapsuleDeletionHelpers,
    useMultiDeleteTimer,
    useHelpers,
};
