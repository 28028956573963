import { useTranslation } from 'react-i18next';
import React from 'react';
import NakedBtn from 'src/components/buttons/NakedBtn';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { fullFormHooks } from '../../../hooks.FullForm';

interface Props {
    addStation: () => void;
    stationsCount: number;
    addPassengers: () => void;
    disabled?: boolean;
}

const AddPassengersOrStationBtns = ({
    addStation,
    stationsCount,
    addPassengers,
    disabled = false,
}: Props) => {
    const { t } = useTranslation();

    const { reachedCarPassQtyLimit } = fullFormHooks.useDidReachPassQtyLimit();
    const dispatchAlert = useDispatchAlert();

    return (
        <div style={{ display: 'flex' }}>
            <NakedBtn
                onClick={addStation}
                type="add"
                disabled={stationsCount > 50 || disabled}
                text={t('addStation')}
            />
            <NakedBtn
                onClick={() => {
                    if (reachedCarPassQtyLimit) {
                        dispatchAlert('warning', 'הרכב מלא, לא ניתן להוסיף נוסעים נוספים');
                        return;
                    }
                    addPassengers();
                }}
                type="add"
                text={t('addingPassengers')}
                disabled={disabled}
            />
        </div>
    );
};

export default AddPassengersOrStationBtns;
