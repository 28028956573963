import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import { loginByPinCode, updateToken } from 'src/api/api';
import BtnLink from 'src/components/commons/Buttons/BtnLink/BtnLink';
import Alert from 'src/components/commons/Alert/Alert';
import { initConnectSuccess } from 'src/store/actions/loginAction';
import { useHistory } from 'react-router-dom';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { ILoginState } from 'src/store/type';
import { IAccount, ResPinCodeLogin, SingupType } from 'src/types/login';
import { FcData, ReqStatus } from 'src/api/types';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { v4 as GuidService } from 'uuid';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import Code from '../../Signup/InitialIdentification/Code/Code';
import styles from './PinCode.style';

interface Props {}

const PinCode = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const disptach = useDispatch();
    const onSetLoginData = (payload: ILoginState) => disptach(initConnectSuccess(payload));
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [keyCode, setKeyCode] = useState<string>(GuidService());

    const onSubmit = async (code: string) => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);

        const res = await loginByPinCode({
            deviceToken,
            pinCode: code,
        });

        if (res.response === ResPinCodeLogin.SUCCESS) {
            // const payload = {}
            const { data = [] } = res;

            const promises: Array<Promise<void>> = [];

            const loginData: FcData = data[0] || {};
            const fcAccounts: IAccount[] = loginData.fcAccounts || [];
            const token: string = loginData.token || '';

            fcAccounts.forEach(({ proxyUrl, contactUUID, dbUrl }, i) => {
                promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
                /*
                get default account's client fron local storage
                if client is on fc accounts list, that means its the default account

                updatetoken.then:
                    if is default account:
                        onSetLoginData({
                        loginType: SingupType.PinCode,
                        isAuthenticated: ReqStatus.SUCCESS,
                        selectedFcAccount: fcAccounts[i],
                        ...data[i],
                        });
                        history.push(`${routesEndpoints.HOME}`);
                    else:
                        return
                */
            });

            Promise.all(promises).then(() => {
                onSetLoginData({
                    loginType: SingupType.PinCode,
                    isAuthenticated: ReqStatus.SUCCESS,
                    selectedFcAccount: fcAccounts[0],
                    ...data[0],
                });
                history.push(`${routesEndpoints.HOME}`);
            });
        } else if (res.response === ResPinCodeLogin.WRONG_PIN_CODE) {
            setShowErrorAlert(true);
            setKeyCode(GuidService());
        }
    };

    const navigateToSinupOptions = () => {
        history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP_OPTIONS}`);
    };

    const onPressForgetDetailes = () => {
        history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP}`);
    };

    const TitleComponent = <styles.Text>{t('enterPinCode')}</styles.Text>;

    const handleCloseSnack = () => setShowErrorAlert(false);
    const isAutoSubmit = false;

    return (
        <Container>
            <Title>{t('login')}</Title>
            <Card>
                <styles.SpaceAround>
                    <Code
                        key={keyCode}
                        isInputSecure
                        isAutoSubmit={isAutoSubmit}
                        onSubmit={onSubmit}
                        Title={TitleComponent}
                        btnText={t('connect')}
                    />
                </styles.SpaceAround>
                <styles.Bootom>
                    <ButtonBase onClick={onPressForgetDetailes}>
                        <styles.BootomText>{t('forgotPasswordText')}</styles.BootomText>
                    </ButtonBase>
                </styles.Bootom>
            </Card>
            {/* <BtnLink
            btnText={t('anotherSignup')}
            onClick={navigateToSinupOptions}
         /> */}

            <Alert open={showErrorAlert} onClose={handleCloseSnack} severity="error">
                {t('wrongCode')}
            </Alert>
        </Container>
    );
};

export default PinCode;
