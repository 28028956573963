import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enterScreenAction } from 'src/store/slices/dropsDisplay/dropDisplaySlice';

import { setAuthorizationToken, setLogout, setTokenRefreshStatus } from 'src/store/actions/loginAction';
import { FcResponseState } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Popover from '@material-ui/core/Popover';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { IRootReducer } from 'src/store/reducers';

import { getUserDetails, logout } from 'src/api/api';

import metropolineImage from 'src/assets/images/MaskGroup.png';
import SiteVersion from 'src/components/SiteVersion/SiteVersion';
import { useAppDispatch } from 'src/store/hooks';
import ExpandIcon from 'src/components/Icons/ExpandIcon';
import { FEATURES_IDS } from 'src/constants/auth';
import { setUserDetailsAction } from 'src/store/slices/common/commonSlice';
import styles from './Profile.style';
import Languages from './Languages';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { toggleModalAction } from 'src/store/slices/generalFeatures/generalFeaturesSlice';
import { routesEndpoints } from 'src/routes/routes_endpoints';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

const Profile: FC<IProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const onSetLogout = () => dispatch(setLogout());

    const { selectedFcAccount, userUUID, token, mobile } = useSelector((state: IRootReducer) =>
        loginSelector(state)
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userName, setUserName] = useState('');

    const handleClose = () => {
        setAnchorEl(null);
    };
    const tkDispatch = useAppDispatch();

    const openDropsDisplay = useCallback(() => {
        tkDispatch(enterScreenAction());
        handleClose();
    }, [tkDispatch]);

    useEffect(() => {
        if (selectedFcAccount) {
            const { proxyUrl, dbUrl } = selectedFcAccount;
            getUserDetails({
                clinetProxy: proxyUrl,
                dbUrl,
                token,
                mobile,
            }).then((res) => {
                const {
                    data = { userName: '', departmentCode: null, email: '' },
                    options = [],
                    response,
                } = res.data;

                if (response === FcResponseState.TokenExpired)
                    dispatch(setTokenRefreshStatus(RefreshTokenStatus.Invalid));

                const name = data.userName || '';
                setUserName(name);

                const allClients: number[] = [];
                options.forEach((option) =>
                    option.clients.forEach((clientCode) => allClients.push(clientCode))
                );
                const dictionary = Object.assign(
                    {},
                    ...options.map((x) => ({
                        [x.optionID]: {
                            screenId: x.optionID,
                            optionID: x.optionID,
                            clients: x.clients,
                        },
                    })),
                    {
                        [routesEndpoints.REPORTS.SCREEN_ID]: {
                            screenId: routesEndpoints.REPORTS.SCREEN_ID,
                            optionId: routesEndpoints.REPORTS.SCREEN_ID,
                            clients: allClients,
                        },
                    }
                );

                dispatch(setAuthorizationToken(dictionary));
                tkDispatch(
                    setUserDetailsAction({
                        departmentCode: data.departmentCode,
                        email: data.email || '',
                        userName: data.userName || '',
                    })
                );
            });
        }
    }, [selectedFcAccount, token, mobile, dispatch, tkDispatch]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onLogout = async () => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
        if (deviceToken && userUUID) {
            const res = await logout({
                deviceToken,
                userUUID,
            });
            if (res.response === '0') onSetLogout();
            setAnchorEl(null);
            return;
        }

        onSetLogout();
        setAnchorEl(null);
    };

    const navigateToSettings = () => {
        history.push('/settings');
        handleClose();
    };

    const handleUploadFileItemClick = () => {
        dispatch(toggleModalAction(null));
    };

    return (
        <styles.Container>
            <styles.AvatarContianer>
                <img src={metropolineImage} alt="" />
            </styles.AvatarContianer>
            <styles.Btn onClick={handleClick}>
                <styles.Text>{userName}</styles.Text>
                <KeyboardArrowDownIcon />
            </styles.Btn>
            <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <styles.LanguageContainer>
                    <styles.ItemText>{t('language')}</styles.ItemText>
                    <Languages />
                </styles.LanguageContainer>
                <styles.Item onClick={navigateToSettings}>
                    <styles.ItemText>{t('userSettings')}</styles.ItemText>
                </styles.Item>
                <styles.Item onClick={openDropsDisplay}>
                    <styles.ItemText>{t('dropsBoard')}</styles.ItemText>
                    <div
                        style={{
                            display: 'flex',
                            paddingRight: '10px',
                            marginTop: '4px',
                        }}
                    >
                        <ExpandIcon />
                    </div>
                </styles.Item>
                <styles.Item onClick={handleUploadFileItemClick}>
                    <styles.ItemText>{t('uploadFile')}</styles.ItemText>
                    <div
                        style={{
                            display: 'flex',
                            paddingRight: '10px',
                            marginTop: '4px',
                        }}
                    >
                        <AttachFileOutlinedIcon fontSize="small" />
                    </div>
                </styles.Item>
                <styles.Item onClick={onLogout}>
                    <styles.ItemText isLogout>{t('logout')}</styles.ItemText>
                </styles.Item>
                <SiteVersion />
            </Popover>
        </styles.Container>
    );
};

export default Profile;
