/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import defaultTheme, { colors, fontSizes } from 'src/style/themes/defaultTheme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import { Card, styled as muiStyled } from '@mui/material';

const Footer = styled.div`
    border-top: 1px solid ${colors.blue};
    padding: 10px 20px;
    background-color: white;
`;
const Body = styled.div`
    padding: 20px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: auto;
    height: 90vh;
`;
const Header = styled.div`
    font-size: ${fontSizes.m};
    padding-bottom: 10px;
    text-align: right;
    span {
        font-size: ${fontSizes.s};
    }
`;
export const FormStyling = {
    Footer,
    Body,
    Header,
};
export const cardStyleShadow = '0px 0px 20px rgba(0, 0, 0, 0.2)';
export const cardStyle = {
    borderRadius: '15px',
    boxShadow: cardStyleShadow,
};
export const getCardStyle = (isForm: boolean) => ({
    height: '100%',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderBottomLeftRadius: isForm ? 0 : '15px',
    borderBottomRightRadius: isForm ? 0 : '15px',
    boxShadow: cardStyleShadow,
    display: 'grid',
    gridTemplateRows: '10fr auto',
    backgroundColor: 'white',
});

export const passengerDetailsHeaderStyle: CSSProperties = {
    fontFamily: defaultTheme.fontFamilies.Rubik,
    fontStyle: 'normal',
    color: defaultTheme.msColors.primaryTextcolor,
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'right',
    padding: '15px 0',
    lineHeight: '21px',
};

export const FormTypeHeaderStyle: CSSProperties = {
    fontFamily: defaultTheme.fontFamilies.Rubik,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '28px',
    color: defaultTheme.msColors.darkTextcolor,
    textAlign: 'right',
};

export const separationLineStyle: CSSProperties = {
    border: '1px solid #EEF1F5',
    width: '100%',
};

export const zeroVerticalPadding: CSSProperties = {
    paddingTop: '0px',
    paddingBottom: '0px',
};

export const inputLabelStyle: CSSProperties = {
    textAlign: 'right',
    margin: '0 0 10px 0',
    fontSize: '14px',
    lineHeight: '18px',
};
