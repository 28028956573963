// import { Reducer } from 'redux';
import { DefaultRootState } from 'react-redux';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import { ReqStatus } from 'src/api/types';
import { addOrUpdateItemById } from 'src/utilis/utilis';

import { RawInputAddress } from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/types';
import { IReduxProvider } from '../type';
import { PassengerActionType } from '../actions/actionType';

export interface IPassengerState extends DefaultRootState {
    passengers: Array<IPassenger>;
    citys: Array<string>;
    clientId?: string | number | undefined;
    modefiedPassenger?: IPassenger;
    loadingPassengers: ReqStatus;
    ui: {
        // selectedPassengerPasscode?: string;
        modifyingPassenger: boolean;
        addingPassenger: boolean;
        selectedPassengers: number[];
        activeSelectedPassengers: number[];
        passengerManagerUI: {};
        form: {
            hasValidAddress: boolean;
            touchedAddress: boolean;
            myTouched: boolean;
        };
    };
}

const initialState: IPassengerState = {
    passengers: [],
    citys: [],
    loadingPassengers: ReqStatus.LOADING,
    ui: {
        modifyingPassenger: false,
        selectedPassengers: [],
        activeSelectedPassengers: [],
        addingPassenger: false,
        passengerManagerUI: {},
        form: {
            touchedAddress: false,
            hasValidAddress: true,
            myTouched: false,
        },
    },
};

const onChangeAddress = (state: IPassengerState, payload: boolean | any): any => {
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                hasValidAddress: payload,
                touchedAddress: true,
            },
        },
    };
};

const changedForm = (state: IPassengerState): any => {
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                myTouched: true,
            },
        },
    };
};

const resetFormState = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                hasValidAddress: true,
                myTouched: false,
                touchedAddress: false,
            },
        },
    };
};

const onRetrivePassenger = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.LOADING,
    };
};

const onRetrivePassengerSuccess = (state: IPassengerState, payload: Array<IPassenger> | any): any => {
    // & Getting passengers cities
    const citys = new Set<string>();
    payload.forEach((passenger: IPassenger) => {
        passenger.address.forEach((address) => {
            if (address.city) {
                citys.add(address.city.trim());
            }
        });
    });

    let passengersFetchStatus = ReqStatus.LOADING;
    if (payload.length > 0) {
        passengersFetchStatus = ReqStatus.SUCCESS;
    }

    return {
        ...state,
        citys: Array.from(citys).sort((a, b) => a.localeCompare(b)),
        passengers: [...payload],
        loadingPassengers: passengersFetchStatus,
    };
};

export const onSetClientId = (state: IPassengerState, payload: string | number | undefined | any): any => {
    return {
        ...state,
        clientId: payload,
    };
};

export const onSetModefiedPassenger = (
    state: IPassengerState,
    payload: IPassenger | undefined | any
): any => {
    // console.log(payload);
    return {
        ...state,
        modefiedPassenger: payload,
    };
};
// reducer
export const onChangeSelection = (
    state: IPassengerState,
    payload:
        | {
              passengers: Array<IPassenger>;
              //   selectedPassengerPasscode: string;
              activeSelectedPassengers: Array<number>;
              modifingPassenger: boolean;
          }
        | any
): IPassengerState => {
    return {
        ...state,
        passengers: [...payload.passengers],
        ui: {
            ...state.ui,
            // selectedPassengerPasscode: payload.selectedPassengerPasscode,
            modifyingPassenger: payload.modifyingPassenger,
            activeSelectedPassengers: payload.activeSelectedPassengers,
            addingPassenger: false,
            selectedPassengers: payload.selectedPassengers,
        },
    };
};

const onUnSelectPassenger = (
    state: IPassengerState,
    payload:
        | {
              updatedPassengers: Array<IPassenger>;
              //   activeSelectedPassengers: Array<number>;
          }
        | any
): IPassengerState => {
    return {
        ...state,
        passengers: [...payload],
        ui: {
            ...state.ui,
            // selectedPassengerPasscode: undefined,
            selectedPassengers: [],
            activeSelectedPassengers: [],
            addingPassenger: false,
        },
    };
};
const onRetrivePassengerFail = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.FAIL,
    };
};

const onDeletePassenger = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.LOADING,
    };
};

const onDeletePassengerSuccess = (state: IPassengerState, payload: Array<string> | any): IPassengerState => {
    return {
        ...state,
        passengers: state.passengers.map((item: IPassenger) => ({
            ...item,
            isActive: payload.indexOf(item.passCode) > -1 ? '0' : item.isActive,
        })),
        modefiedPassenger: state.modefiedPassenger && {
            ...state.modefiedPassenger,
            isActive: payload.indexOf(state.modefiedPassenger) > -1 ? '0' : state.modefiedPassenger.isActive,
        },
        loadingPassengers: ReqStatus.SUCCESS,
    };
};

const onDeletePassengerFail = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.FAIL,
    };
};

const onSetPassenger = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.LOADING,
    };
};

const onSetPassengerSuccess = (state: IPassengerState, payload: IPassenger | any): IPassengerState => {
    const citys = new Set<string>();

    [...state.passengers, payload].forEach((passenger: IPassenger) => {
        passenger.address.forEach((address) => {
            if (address.city) citys.add(address.city.trim());
        });
    });

    return {
        ...state,
        passengers: addOrUpdateItemById(state.passengers, payload, 'passCode'),
        modefiedPassenger: undefined,
        citys: Array.from(citys).sort((a, b) => a.localeCompare(b)),
        loadingPassengers: ReqStatus.SUCCESS,
    };
};

const onSetPassengerFail = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.FAIL,
    };
};

const onAddPassengerBtnClicked = (state: IPassengerState): IPassengerState => {
    return {
        ...state,
        ui: {
            ...state.ui,
            modifyingPassenger: false,
            addingPassenger: true,
        },
    };
};

const handlerTypes: {
    [index: string]: IReduxProvider<IPassengerState>;
} = {
    [PassengerActionType.retrivePassenger]: onRetrivePassenger,
    [PassengerActionType.retrivePassengerSuccess]: onRetrivePassengerSuccess,
    [PassengerActionType.retrivePassengerFail]: onRetrivePassengerFail,
    [PassengerActionType.changeSelection]: onChangeSelection,
    [PassengerActionType.setClientId]: onSetClientId,
    [PassengerActionType.setModefiedPassenger]: onSetModefiedPassenger,
    [PassengerActionType.deletePassenger]: onDeletePassenger,
    [PassengerActionType.deletePassengerSuccess]: onDeletePassengerSuccess,
    [PassengerActionType.deletePassengerFail]: onDeletePassengerFail,
    [PassengerActionType.setPassenger]: onSetPassenger,
    [PassengerActionType.setPassengerSuccess]: onSetPassengerSuccess,
    [PassengerActionType.setPassengerFail]: onSetPassengerFail,
    [PassengerActionType.onAddPassengerBtnClicked]: onAddPassengerBtnClicked,
    [PassengerActionType.onUnSelectPassenger]: onUnSelectPassenger,
    [PassengerActionType.onChangeAddress]: onChangeAddress,
    [PassengerActionType.resetFormState]: resetFormState,
    [PassengerActionType.changedForm]: changedForm,
};

const passengerReudcer = (
    state = initialState,
    { type, payload }: { type: PassengerActionType; payload: IPassengerState }
): any => {
    const handler = handlerTypes[type];
    if (handler) {
        return handler(state, payload);
    }
    return state;
};

export default passengerReudcer;
