import React, { useEffect, useState } from 'react';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { useTranslation } from 'react-i18next';

import {
    accountCoursesResponseSelector,
    resetCoursesFormStateAction,
    setSelectedCourseCodeAction,
} from 'src/store/slices/lines/linesSlice';
import { stringifyDate } from 'src/utilis/utilis';
import Spinner from 'src/components/Spinner/Spinner';
import SearchInput from 'src/components/SearchInput/SearchInput';
import {
    StyledTitle,
    StyledCourseChoiceCard,
    Par,
    getParStyle,
} from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/Components/CoursesList/CourseTypeTabs/styles';
import { css } from 'styled-components';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { cs } from 'src/style/styledComponents.style';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { DialogControl, LoadingState, storedCoursesHooks } from '../storedCourses.hooks';
import { storedCoursesFormSelector } from '../../../../../../../../store/slices/lines/linesSlice';
import StationsImportDialog from '../StationsImportDialog/StationsImportDialog.index';

interface Props {
    clientCode: string;
    date: Date;
    dialogControl: DialogControl;
}

const getTitleStyle = (flex = 1) => {
    return { ...getParStyle(flex), fontSize: '14px' } as const;
};

const defaultTitleStyle = getTitleStyle();

const StoredCoursesFormBody = React.memo(({ clientCode, date, dialogControl }: Props) => {
    // -- Vanilla hooks
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // -- selectors
    const sliceState = useRootAppSelector(storedCoursesFormSelector);
    const { status: reqStatus, data: coursesData } = useRootAppSelector(accountCoursesResponseSelector);

    // -- Helper hooks
    storedCoursesHooks.useCoursesFetch({ clientCode, relativeDate: stringifyDate(date) });

    // -- Local states
    const [filtered, setFiltered] = useState<WsCourseDataItem[]>(coursesData || []);

    // -- Effects
    useEffect(() => {
        if (coursesData) setFiltered(coursesData);
    }, [coursesData]);

    // clear form state on component unmount
    useEffect(() => () => dispatch(resetCoursesFormStateAction()), [dispatch]);

    // -- Helpers
    const onFilterChange = React.useCallback(
        (e: any) => {
            try {
                const val = e?.target?.value;
                setFiltered(
                    coursesData.filter(
                        (course) =>
                            course.courseId.includes(val) ||
                            course.courseDescription.includes(val) ||
                            course.departmentName.includes(val)
                    )
                );
            } catch (error) {
                dispatch(setErrorMessage(t(ErrorMessages.GeneralError)));
            }
        },
        [coursesData]
    );

    const onCourseCardClick = async (courseCode: string) => {
        if (courseCode === sliceState.selectedCourseData.code) {
            dispatch(setSelectedCourseCodeAction({ courseCode: '' }));
            return;
        }
        dispatch(setSelectedCourseCodeAction({ courseCode }));
    };

    const { onClickCancel, onClickConfirm, state } = dialogControl;
    return (
        <cs.FullWidth>
            <p style={{ textAlign: 'right', fontSize: '14px', paddingBottom: '10px' }}>
                {t('copyAllDetailsOfCourse')}
            </p>
            <SearchInput onChange={onFilterChange} style={{ paddingBottom: '20px' }} />
            {reqStatus === 'LOADING' && <Spinner />}
            {reqStatus === 'SUCCESS' && (
                <>
                    <StyledTitle>
                        <p style={defaultTitleStyle}>{t('courseId')}</p>
                        <p style={defaultTitleStyle}>{t('departmentName')}</p>
                        <p style={getTitleStyle(2)}>{t('courseDescription')}</p>
                        <p style={defaultTitleStyle}>{t('carTypeName')}</p>
                        <p style={defaultTitleStyle}>{t('passQty')}</p>
                        <p style={defaultTitleStyle}>{t('priceBeforeVat')}</p>
                    </StyledTitle>
                    {filtered.map((course) => {
                        return (
                            <StyledCourseChoiceCard
                                isChosen={sliceState.selectedCourseData.code === course.courseCode}
                                key={course.courseCode}
                                onClick={() => onCourseCardClick(course.courseCode)}
                            >
                                <Par fontSize="12px">{course.courseId}</Par>
                                <Par fontSize="12px">{course.departmentName}</Par>
                                <Par fontSize="12px" flex={2} styledCss={{ width: '100%' }}>
                                    {course.courseDescription}
                                </Par>
                                <Par fontSize="12px">{course.carTypeName}</Par>
                                <Par fontSize="12px">{course.passQty}</Par>
                                <Par
                                    fontSize="12px"
                                    styledCss={css`
                                        text-align: left;
                                        padding-right: 3px;
                                    `}
                                >
                                    {course.clientPrice}
                                </Par>
                            </StyledCourseChoiceCard>
                        );
                    })}
                    <StationsImportDialog
                        {...{
                            isOpen: state.isOpen,
                            onClickCancel,
                            onClickConfirm,
                            loadingCancel: state.isLoading === LoadingState.LoadingCancel,
                            loadingConfirm: state.isLoading === LoadingState.LoadingConfirm,
                        }}
                    />
                </>
            )}
            {reqStatus === 'FAILED' && <p>{t('errorFetchingData')}</p>}
        </cs.FullWidth>
    );
});

export default StoredCoursesFormBody;
