import { useTranslation } from 'react-i18next';
import { LineStatus } from 'src/types/line';

const useLineStatusTranslate = (): ((status: number | undefined) => string) => {
    const { t } = useTranslation();

    return (status: number | undefined): string => {
        if (!status) return '';

        if (status === LineStatus.Ride) return t('lineStatusRide');

        if (status === LineStatus.Ended) return t('lineStatusEnded');

        return '';
    };
};

export default useLineStatusTranslate;
