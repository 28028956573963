/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import initialStates, {
    initialByReportFiltersValues,
    initialFiltersValues,
    initialSummaryData,
} from 'src/store/slices/reports/initialStates';
import { IReportsState, ReportName, ReportNames } from 'src/store/slices/reports/types';
import { createMsg } from 'src/store/utils';
import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';
import { getCurrDateAsString } from 'src/utilis/utilis';

import { DateFormats } from 'src/types/dates';
import _ from 'lodash';
import { OnSelectReportPayload } from '../../slices/reports/types';
// import { getFilteredData } from './utils';
import { CurrFiltersValues } from '../../../types/reports/generalReportsTypes';

export interface OnChangeFilterDatePayload {
    newDate: Date;
}

export enum FilterTypes {
    DepartmentCode = 'departmentCode',
    DepartmentCodes = 'departmentCodes',
    PickupTime = 'pickupTime',
    DropTime = 'dropTime',
    RideType = 'rideType',
    Days = 'days',
    ShiftIds = 'shiftIds',
    FromTime = 'fromTime',
    ToTime = 'toTime',
    FromDate = 'fromDate',
    ToDate = 'toDate',
}

const onSelectReport = (state: IReportsState, action: PayloadAction<OnSelectReportPayload>): void => {
    const reportTypes = {
        rides: ['visas', 'passengers'],
        orders: ['shortOrders', 'detailedOrders'],
    };
    const { reportName } = action.payload;

    const { departmentCode, ...initialFilterValuesExcludingDepartment } = initialFiltersValues;

    const prevDepartmentCode = state.ui.main.filters.currFiltersValues.departmentCode;

    state.ui.main.filters.currFiltersValues = {
        ...initialFilterValuesExcludingDepartment,
        departmentCode: prevDepartmentCode,
    };

    state.ui.main.selectedReport = reportName;
    state.ui.main.selectedReportType = reportTypes.rides.includes(reportName) ? 'rides' : 'orders';
};

const testKeysMatchType = (obj: Object, type: string) => {
    if (!Object.keys(obj).includes(type)) {
        console.log(Object.keys(obj), { type });
        alert('Error - obj keys doesnt match type');
    }
};

// -- filters
const onChangeFilter = (
    state: IReportsState,
    action: PayloadAction<{
        filterType: FilterTypes;
        newValue: any;
    }>
): void => {
    const { filterType, newValue } = action.payload;
    const { currFiltersValues } = state.ui.main.filters;

    // -- tests
    testKeysMatchType(currFiltersValues, filterType);

    currFiltersValues[filterType] = newValue;
};

export interface OnChangeFilterPayload {
    filterType: FilterTypes;
    newValue: any;
    reportName: ReportName;
}
const onChangeFilterByReport = (state: IReportsState, action: PayloadAction<OnChangeFilterPayload>): void => {
    const { filterType, newValue, reportName } = action.payload;
    // -- type check
    testKeysMatchType(state.ui.main.filters.byReport, reportName);

    const reportFilter = state.ui.main.filters.byReport[reportName];

    // -- type check
    testKeysMatchType(reportFilter, filterType);

    reportFilter[filterType] = newValue;
};

const clearFilters = (state: any): void => {
    state.ui.main.filters.currFiltersValues = initialFiltersValues;
    state.ui.main.filters.byReport = initialByReportFiltersValues;
    state.ui.main.filters.resetFiltersClickCount += 1;
};

const onSelectClientId = (state: IReportsState, action: PayloadAction<{ clientCode: string }>): void => {
    state.ui.main.selectedClientId = action.payload.clientCode;
    state.ui.main.selectedReport = 'passengers' as ReportNames;
    state.ui.main.selectedReportType = 'rides';
};
const incrementApplyFiltersBtnClickCount = (state: IReportsState): void => {
    state.ui.main.filters.applyFiltersBtnClickCount += 1;

    const { selectedReport } = state.ui.main;

    // -- resetting date on widget to date on filters
    const { currFiltersValues } = state.ui.main.filters;
    const { byReport: filtersByReport } = state.ui.main.filters;

    const getDate = (filters: any): any | null => {
        if (!filters) return null;

        if ('fromDate' in filters) {
            return filters.fromDate;
        }
        if ('date' in filters) {
            return filters.date;
        }
        return null;
    };

    const dateOnFilters = Object.keys(filtersByReport).includes(selectedReport)
        ? getDate(filtersByReport[selectedReport])
        : currFiltersValues.fromDate;

    if (dateOnFilters instanceof Date) {
        state.ui.widgets.dateOnWidget = dateOnFilters;
        return;
    }
    alert('Invalid date on filters');
};

const incrementRefreshPageBtnClickCount = (state: IReportsState): void => {
    state.ui.common.refreshPageBtnClickCount += 1;
};
const resetState = (state: IReportsState): void => {
    state = initialStates.reports;
};

const onSort = (
    state: IReportsState,
    action: PayloadAction<{
        processedData: any[];
        reportName: ReportNames;
    }>
): void => {
    const { processedData } = action.payload;
    const currReportName = state.ui.main.selectedReport;
    state.data.reports[currReportName].sortedData = processedData;
};

const onError = (state: IReportsState, action: PayloadAction<ErrorActionPayload>): void => {
    if (!action.payload) {
        state.ui.common.error = {
            msg: '',
            code: null,
        };
        return;
    }
    const { msg, details } = action.payload;

    state.ui.common.error = {
        msg: createMsg({ msg, details }),
        code: getCurrDateAsString(),
    };
};
const onRefreshBtnClick = (state: IReportsState): void => {
    state.ui.widgets.refreshWidgetsBtnClickCount += 1;
};
const setDateOnWidget = (
    state: IReportsState,
    action: PayloadAction<{
        newDate: Date;
    }>
) => {
    state.ui.widgets.dateOnWidget = action.payload.newDate;
};

const uiReducers = {
    onSelectReport,
    // onChangeFilterDate,
    onRefreshBtnClick,
    onSort,
    onSelectClientId,
    onChangeFilter,
    incrementApplyFiltersBtnClickCount,
    incrementRefreshPageBtnClickCount,
    onError,
    resetState,
    onChangeFilterByReport,
    clearFilters,
    setDateOnWidget,
    setPassengersReportDateFilter: (state: IReportsState, action: PayloadAction<any>) => {
        const { days, month } = action.payload;
        state.ui.main.filters.byReport.passengers.dates = days;
        state.ui.main.filters.byReport.passengers.fromDate = month;
    },
};

export default uiReducers;
