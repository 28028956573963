import React from 'react';
import ErrorFetchingDataText from 'src/components/ErrorFetchingDataText';
import Spinner from 'src/components/Spinner/Spinner';
import { ApiCallStatus } from 'src/types/apiCommon.types';

const ApiGeneratedDataWrapper: React.FC<{ apiCallStatus: ApiCallStatus; displayOnStandBy?: boolean }> = ({
    apiCallStatus,
    displayOnStandBy = true,
    children,
}) => {
    return (
        <>
            {apiCallStatus === 'SUCCESS' || (displayOnStandBy && apiCallStatus === 'ON_STAND_BY')
                ? children
                : null}
            {apiCallStatus === 'LOADING' ? <Spinner /> : null}
            {apiCallStatus === 'FAILED' ? <ErrorFetchingDataText /> : null}
        </>
    );
};

export default ApiGeneratedDataWrapper;
