import React, { FC } from 'react';
import { Position } from 'src/types/global';
import * as Styles from './Progressbar.Style';

interface Props {
    color: '#BCEFFF' | '#81B1D8';
    count: number;
    position: keyof typeof Position;
}

const Progressbar: FC<Props> = ({ count, position, color }: Props) => {
    return (
        <Styles.Container position={position}>
            <Styles.Progress count={count} color={color} position={position} />
            <Styles.Count>{count}</Styles.Count>
        </Styles.Container>
    );
};

export default Progressbar;
