import React from 'react';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';

const useGetApiRequestBasicParams = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    return {
        dbUrl: selectedFcAccount?.dbUrl || '',
        token,
        proxyUrl: selectedFcAccount?.proxyUrl,
        contactUUID: selectedFcAccount?.contactUUID,
    };
};

export default useGetApiRequestBasicParams;
