import { activeEffect } from 'src/style/elementStyleAttributes';
import { colors } from 'src/style/themes/defaultTheme';
import styled from 'styled-components';

const TESTING = 0;
const testBorderGray = TESTING ? 'border: 3px dotted #9999' : '';
const testBorderBlue = TESTING ? 'border: 3px dotted blue' : '';
const testBorderBrown = TESTING ? 'border: 3px dotted brown' : '';

const HeaderContainer = styled.div`
    border-bottom: 2px solid #eef1f5;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px 18px 0 18px;
`;

const FormHeaderContainer = styled.div`
    border-bottom: 2px solid #eef1f5;
    padding: 0 20px;
    ${testBorderGray};
`;

const FromBodyContainer = styled.div`
    min-width: 100%;
    ${testBorderGray};
    overflow: auto;
`;
const FormBtnsContainer = styled.div`
    ${testBorderGray};
    border-top: 1px solid #40a8e2;
    height: 100%;
    min-width: 100%;
    height: 10%;
`;
const BtnsBox = styled.div`
    justify-content: space-between;
    padding: 20px;
    ${testBorderBlue};
    display: flex;
`;

const formContainer = styled.div<{ show?: boolean }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    height: 98vh;
`;
const titleText = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    text-align: right;
`;
const GoBackBtn = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    :active: {
        opacity: 0.5;
        transition: opacity 0.2s;
    }
    :hover {
        opacity: 0.8;
    }
`;
export const styles = {
    HeaderContainer,
    FormHeaderContainer,
    FormBtnsContainer,
    FromBodyContainer,
    BtnsBox,
    formContainer,
    titleText,
    GoBackBtn,
};
