import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { coursesSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { useTranslation } from 'react-i18next';
import { WsCourseDataItem, CourseType } from 'src/types/passengersManager/courseChoosingTypes';
import SearchInput from 'src/components/SearchInput/SearchInput';
import CourseChoiceCard from '../CourseChoiceCard/CourseChoiceCard';
import { titleStyle, getParStyle, StyledTitle } from '../styles';

interface Props {
    selectedTab: CourseType;
    value: CourseType;
}

const Panel = ({ selectedTab, value }: Props) => {
    const { t } = useTranslation();
    const courses = useAppSelector((state) => coursesSelector(state));

    // -- Local states
    const [filtered, setFiltered] = useState<WsCourseDataItem[]>(courses);

    // -- Effects
    useEffect(() => {
        setFiltered(courses);
    }, [courses, selectedTab]);

    // -- Helpers
    const onFilterChange = React.useCallback(
        (e) => {
            const val = e.target.value;
            setFiltered(
                courses.filter(
                    (course) =>
                        course.courseCode.includes(val) ||
                        course.courseDescription.includes(val) ||
                        course.departmentName.includes(val) ||
                        course.groupName.includes(val)
                )
            );
        },
        [courses]
    );

    return (
        <div role="tabpanel" hidden={selectedTab !== value} id={`simple-tabpanel-${value}`}>
            {selectedTab === value && (
                <div style={{ padding: '0 24px 24px 24px' }}>
                    <SearchInput onChange={onFilterChange} style={{ paddingBottom: '20px' }} />
                    <StyledTitle>
                        <p style={getParStyle(1)}>{t('courseCode')}</p>
                        <p style={getParStyle(1)}>{t('department')}</p>
                        <p style={getParStyle(2)}>{t('description')}</p>
                        <p style={getParStyle(1)}>{t('group')}</p>
                    </StyledTitle>
                    {filtered.length ? (
                        filtered.map((course, i) => (
                            <CourseChoiceCard
                                key={course.courseCode}
                                courseType={selectedTab}
                                course={course}
                                i={i}
                            />
                        ))
                    ) : (
                        <p
                            style={{
                                borderTop: '1px solid  rgba(180, 190, 201, 0.303017)',
                                paddingTop: '5px',
                            }}
                        >
                            לא נמצאו מסלולים
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Panel;
