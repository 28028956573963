import axios, { AxiosResponse } from 'axios';
import { deleteLineWs, getCourseStations, reqSetLine } from 'src/api/linesApi/linesApi';
import { getAccountCourses } from 'src/api/reportsApi/reportsApi';
import { responseValidators } from 'src/api/utilis';
import { ErrorMessages } from 'src/types/apiCommon.types';
import {
    CourseStation,
    DeleteLineResponseCodes,
    DelLineRequestConfig,
    ParsedCoursePassenger,
    ParsedCourseStation,
} from 'src/types/lines/api/types';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { CommonCodes, Data, MockRouteResponse } from '../../types/mockServer/routes.types';
import { FormDataWithStations, linesHelpers } from '../helpers/linesHelpers';
import { v4 as uuid } from 'uuid';
import { Station } from 'src/screens/Main/components/AddLineForm/types';

export enum Codes {
    Ok = 1200,
    ExceptionThrown = 1701,
    UnknownError = 1702,
}
enum SetLinesUniqueCodes {
    StartLineFailed = 1601,
    EndLineFailed = 1602,
}
export interface DelLineClientData {
    requestConfig: DelLineRequestConfig;
}
export type SetLinesCodes = SetLinesUniqueCodes | CommonCodes;

export interface RoundTripRes {
    createdLinesCode: {
        startLineCode: string;
        endLineCode: string;
    };
}
const setRoundTripLineRoute = async (clientData: {
    formDataWithStations: FormDataWithStations;
    token: string;
    dbUrl: string;
}): Promise<MockRouteResponse<RoundTripRes>> => {
    const response: MockRouteResponse<RoundTripRes> = {
        code: null,
        data: {
            data: {
                createdLinesCode: {
                    startLineCode: '',
                    endLineCode: '',
                },
            },
        },
    };

    const { formDataWithStations, token, dbUrl } = clientData;

    try {
        const payload = await linesHelpers.createBothTripPayloads(formDataWithStations, token, dbUrl);

        const startLineRes = await reqSetLine((await payload).startLine);

        if (responseValidators.isOk(startLineRes)) {
            const returnLineRes = await reqSetLine((await payload).endLine);

            if (responseValidators.isOk(returnLineRes)) {
                response.code = CommonCodes.Ok;
                response.data = {
                    data: {
                        createdLinesCode: {
                            startLineCode: String(startLineRes.data.data),
                            endLineCode: String(returnLineRes.data.data),
                        },
                    },
                };
                return response;
            }

            response.code = SetLinesUniqueCodes.EndLineFailed;
            response.data = { message: 'endLineFailed' };

            return response;
        }

        response.code = SetLinesUniqueCodes.StartLineFailed;
        response.data = {
            message: responseValidators.isMissingWs(startLineRes)
                ? ErrorMessages.MissingWs
                : 'startLineFailed',
        };
    } catch (error) {
        console.log(error);
        response.code = CommonCodes.ExceptionCaught;
        response.data = { message: ErrorMessages.GeneralError };
    }

    return response;
};

const handleWsResponse: <T>(
    responseObj: MockRouteResponse<any>,
    wsResponse: AxiosResponse<any>,
    dataParser?: ((rawData: any) => T) | undefined
) => {
    code: number | null;
    data: Data<T>;
} = (responseObj, wsResponse, dataParser) => {
    const response = { ...responseObj };

    if (responseValidators.isOk(wsResponse)) {
        response.code = CommonCodes.Ok;
        if (dataParser) {
            response.data.data = dataParser(wsResponse.data.data);
        } else {
            response.data.data = wsResponse.data.data;
        }

        return response;
    }

    if (responseValidators.isMissingWs(wsResponse)) {
        response.code = CommonCodes.MissingWs;
        response.data = {
            message: ErrorMessages.MissingWs,
        };
        return response;
    }

    if (responseValidators.isBadToken(wsResponse)) {
        response.code = CommonCodes.BadToken;
        response.data = {
            message: ErrorMessages.BadToken,
        };
        return response;
    }

    response.code = CommonCodes.UnknownFailure;
    response.data = {
        message: ErrorMessages.GeneralError,
    };

    return response;
};

export interface GetCourseStationByIdData {
    stations: ParsedCourseStation[];
    passengers: ParsedCoursePassenger[];
}

const FILLER_ID = '0';

const parser = (rawData: CourseStation[]): GetCourseStationByIdData => {
    const stations: ParsedCourseStation[] = [];
    const passengers: ParsedCoursePassenger[] = [];

    rawData.forEach(({ passengers: rawPassengers, ...rawStationData }) => {
        // removing filler passengers
        const filteredPassengers = rawPassengers.filter(({ passCode }) => passCode && passCode !== FILLER_ID);

        const parsedStationData: ParsedCourseStation = {
            stationCode: uuid(),
            time: rawStationData.time,
            city: rawStationData.city,
            street: rawStationData.street,
            house: rawStationData.house,
            type: rawStationData.type,
            passengers: filteredPassengers.map((p) => p.passCode),
            lat: rawStationData.lat,
            lng: rawStationData.lng,
            timing: rawStationData.timing,
        };

        stations.push(parsedStationData);

        filteredPassengers.forEach((p) => {
            const parsed: ParsedCoursePassenger = {
                passCode: p.passCode,
                addressCode: p.addressCode,
                internalCode: p.passId,
                firstName: p.firstName,
                lastName: p.lastName,
                mobilePhone: p.mobilePhone,
                email: p.email,
                city: p.city,
                street: p.street,
                houseNum: p.houseNum,
                remarks: p.remarks,
            };

            passengers.push(parsed);
        });
    });

    return { stations, passengers };
};

const getCourseStationsById = async (clientData: { token: string; dbUrl: string; courseCode: string }) => {
    let response: MockRouteResponse<GetCourseStationByIdData> = {
        code: null,
        data: {},
    };

    try {
        const { token, dbUrl, courseCode } = clientData;

        const wsResponse = await getCourseStations({
            dbUrl,
            requestParams: {
                token,
                courseCode,
            },
        });

        response = handleWsResponse(response, wsResponse, parser);

        return response;
    } catch (error) {
        console.log(error);
        response.code = CommonCodes.ExceptionCaught;
        response.data = { message: ErrorMessages.GeneralError };
    }

    return response;
};

type DeleteLineResult = MockRouteResponse<string>;
const deleteLine = async (clientData: DelLineClientData): Promise<DeleteLineResult> => {
    const result: DeleteLineResult = {
        code: null,
        data: {},
    };

    try {
        const wsRes = await deleteLineWs(clientData.requestConfig);

        if (wsRes.status === 202 || wsRes.data === '') {
            result.code = 202;
            result.data = { message: ErrorMessages.MissingWs };
            return result;
        }

        if (wsRes.status === 200 && wsRes.data.response === DeleteLineResponseCodes.Ok) {
            result.code = Codes.Ok;
            result.data.data = clientData.requestConfig.requestParams.lineCode;

            return result;
        }

        result.code = Codes.UnknownError;
        result.data = { message: ErrorMessages.OperationFailed };

        return result;
    } catch (error) {
        result.code = Codes.ExceptionThrown;
        result.data = { message: ErrorMessages.OperationFailed };

        return result;
    }
};

const linesRoutes = {
    setRoundTripLineRoute,
    getCourseStationsById,
    deleteLine,
};

export default linesRoutes;
// const editLine = async (clientData: {
//     requestConfig: ReqConfig<EditLineReqParams>;
// }): Promise<MockRouteResponse<any>> => {
//     const result: any = {
//         code: null,
//         data: null,
//     };
// try {
//     const res = await reqSetLine(clientData.requestConfig);

//     if (res.status === 200 && res.data.response === '0') {
//         result.code = Codes.Ok;
//         result.data = clientData.requestConfig.requestParams.lineCode;

//         return result;
//     }

//     result.code = Codes.UnknownError;
//     result.data = {};

//     return result;
// } catch (error) {
//     result.code = Codes.ExceptionThrown;
//     result.data = {};

//     return result;
// }
// };

// const setLine = async (clientData): MockRouteResponse<any> => {
//     const response: any = {
//         code: null,
//         data: null,
//     };

//     const payload = {
//         startLine: {},
//         endLine: {},
//     };

//     const startLineRes = await reqSetLine(payload.startLine);

//     if (startLineRes.isOk) {
//         const returnLineRes = await reqSetLine(payload.endLine);

//         if (returnLineRes.isOk) {
//             response.code = SetLinesCodes.Ok;
//             response.data = {};
//             return response;
//         }
//         response.code = SetLinesCodes.EndLineFailed;
//         response.data = {};
//         return response;
//     }

//     response.code = SetLinesCodes.StartLineFailed;
//     response.data = {};
//     return response;
// };
