import { CSSProperties } from 'react';
import defaultTheme, { colors } from 'src/style/themes/defaultTheme';
import styled, { css, CSSObject } from 'styled-components';

const TESTING = 0;
const testBorderGray = TESTING ? 'border: 3px dotted #9999' : '';
const testBorderBlue = TESTING ? 'border: 3px dotted blue' : '';
// const testBorderBrown = TESTING ? 'border: 3px dotted brown' : '';

const HeaderContainer = styled.div`
    border-bottom: 2px solid #dfdfdf;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6875em;
    height: 91px;
    // position: fixed;
`;

const FormHeaderContainer = styled.div`
    border-bottom: 2px solid #eef1f5;
    padding: 0 20px;
    ${testBorderGray};
`;

const FromBodyContainer = styled.div`
    margin: 0 0 20px 0;
    min-width: 100%;
    ${testBorderGray};
`;
const FormBtnsContainer = styled.div`
    ${testBorderGray};
    // background-color: orange;
    border-top: 1px solid #40a8e2;
    height: 100%;
    min-width: 100%;
`;
const BtnsBox = styled.div`
    justify-content: space-between;
    // gap: 180px;
    padding: 20px 20px 0 20px;
    ${testBorderBlue};
    display: flex;
    // background-color: orange;
`;

export const SettingsListContainer = styled.div`
    padding: 28px;
    ${testBorderGray};
`;

export const PaddedAccordionContainer = styled.div`
    ${testBorderGray};
    padding-bottom: 20px;
`;
// !!!! bottom comp's hover effect should be deleted
const AccordionSettingsContainer = styled.div`
    border-bottom: 0;
    // border-top: 1px solid rgba(0, 0, 0, 0.12);
    &:hover {
        // background-color: ${colors.veryLightGray};
    }
`;

const AccountDeptsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCompatibilityWrapper = styled.div`
    margin-top: 30px;
    width: 100%;
`;

const styles = {
    AccordionSettingsContainer,
    AccountDeptsContainer,
    HeaderContainer,
    FormHeaderContainer,
    FormBtnsContainer,
    FromBodyContainer,
    BtnsBox,
};

export const SpanBoldItalic = styled.span<{ style?: CSSObject }>`
    font-weight: bold;
    font-style: italic;
    color: ${defaultTheme.msColors.primaryTextcolor};
    ${({ style }) => css(style || {})};
`;

export default styles;
