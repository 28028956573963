/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { RideSettingsState } from 'src/types/rideSettings/store/types.store';
import { createMsg } from 'src/store/utils';
import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';
import { ActiveStatus } from '../../../types/global';

const onActiveStatusChange = (
    state: any,
    action: PayloadAction<{
        payload: ActiveStatus;
    }>
) => {
    state.ui.filters.activeStatus = action.payload;
};
const onError = (state: RideSettingsState, action: PayloadAction<ErrorActionPayload>): void => {
    if (!action.payload) {
        state.ui.common.error = {
            msg: '',
        };
        return;
    }
    const { msg, details } = action.payload;

    state.ui.common.error = {
        msg: createMsg({ msg, details }),
    };
};

const uiReducers = { onActiveStatusChange, onError };
export default uiReducers;
