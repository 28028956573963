import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getClients } from 'src/api/api';
import { DropDownState } from 'src/screens/PassengerManger/components/Passengers/hooks/useFilters';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { IClient } from 'src/types/line';
import { isAuthorized } from 'src/utilis/utilis';

/**
 * Returns a tuple of the current client filter state and a function to update the state.
 * @param {number} routeScreenId - the id of the screen that the filter is applied to.
 * @param {boolean} [isPrivateRoute=false] - whether or not the filter is applied to a private route.
 * @returns {[DropDownState, Function]} - a tuple of the current client filter state and a function to update the state.
 */
const useClientFilters = ({
    routeScreenId = -1,
    isPrivateRoute = false,
    defaultValue,
}: {
    routeScreenId?: number;
    isPrivateRoute?: boolean;
    defaultValue?: string;
}): [DropDownState, Function, boolean] => {
    const [clientFilter, setClientFilter] = useState<DropDownState>({
        options: [],
        value: defaultValue || '',
    });
    // * Selectors
    const {
        selectedFcAccount,
        token,
        authorizationToken = {},
    } = useSelector((state: IRootReducer) => loginSelector(state));

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};

        if (selectedFcAccount) {
            setIsLoading(true);

            getClients({
                proxyUrl,
                token,
                dbUrl: selectedFcAccount.dbUrl,
            })
                .then((res) => {
                    const { clients = [] } = res.data;
                    let authorizedClients: IClient[];
                    if (isPrivateRoute) {
                        if (routeScreenId !== -1) {
                            authorizedClients = clients.filter((client) =>
                                isAuthorized(
                                    // routesEndpoints.MANAGE_PASSENGERS.SCREEN_ID,
                                    routeScreenId,
                                    +client.accountCode,
                                    authorizationToken
                                )
                            );
                        }
                    } else {
                        authorizedClients = clients;
                    }
                    //
                    setClientFilter((preState: any) => ({
                        value: authorizedClients.some((client) => client.accountCode === preState.value)
                            ? preState.value || ''
                            : authorizedClients.find((client) => client)?.accountCode,
                        options: authorizedClients.map((c) => ({
                            value: c.accountCode,
                            name: c.clientName,
                        })),
                    }));
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    }, [authorizationToken, isPrivateRoute, routeScreenId, selectedFcAccount, token]);

    return [clientFilter, setClientFilter, isLoading];
};

export default useClientFilters;
