import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 104px;
    min-height: 7vh;
    width: 100%;
    border-top: 1px solid rgba(180, 190, 201, 0.303);
    border-bottom: 1px solid rgba(180, 190, 201, 0.303);
    box-sizing: border-box;
`;

const LeftContainer = styled.div`
    flex: 0.7;
    display: flex;
    width: 60%;
    max-width: 60%;
    min-width: 60%;
    white-space: pre;
    overflow-x: clip;
    flex-direction: column;
    justify-content: center;
    padding: 0 1em;
    box-sizing: border-box;
`;

const RightContainer = styled.div`
    flex: 0.3;
    display: flex;
    width: 40%;
    max-width: 40%;
    min-width: 40%;
    white-space: pre;
    overflow-x: clip;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 1em;
    box-sizing: border-box;
`;

const Label = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: bold;
    font-size: 0.7vw;
    text-align: center;
    margin-left: 0.4375em;
`;

const Text = styled.span<{
    isLarge?: boolean;
    opacity?: string;
    width?: string;
}>`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: 0.6vw;
    font-weight: normal;
    font-size: ${(isLarge) => (isLarge ? '13px' : '7px')};
    ${({ opacity }) => opacity && `opacity: ${opacity}`};
    ${({ width }) => width && `width: ${width}`};

    /* identical to box height */
    text-align: right;
    letter-spacing: 0.05em;
    margin: 0;
    margin-left: 1em;
`;

const Row = styled.div<{ mr?: number; ml?: number; mt?: number }>`
    display: flex;
    align-items: center;
    white-space: pre;
    text-overflow: ellipsis;
    width: 100%;

    ${({ mr }) => mr && `margin-right: ${mr}px;`}
    ${({ ml }) => ml && `margin-left: ${ml}px;`}
   ${({ mt }) => mt && `margin-top: ${mt}px;`}
`;

const Vr = styled.div`
    display: flex;
    width: 1px;
    height: 100%;
    border: 1px solid rgba(180, 190, 201, 0.303);
`;

const Div = styled.div`
    display: flex;
    align-items: center;
`;

const AccordionContainer = styled.div`
    width: 100%;
    border-top: 1px solid rgba(180, 190, 201, 0.303);
    border-bottom: 1px solid rgba(180, 190, 201, 0.303);
    box-sizing: border-box;
`;

export default {
    Container,
    LeftContainer,
    RightContainer,
    Row,
    Label,
    Text,
    Vr,
    Div,
    AccordionContainer,
};
