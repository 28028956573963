/* eslint-disable react/button-has-type */
/* eslint-disable no-fallthrough */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import KendoGrid, { TableProp } from 'src/components/KendoGrid/KendoGrid';
import './KendoDataGrid.css';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { useAppSelector } from 'src/store/hooks';
import { onSortOrFilterAction } from 'src/store/slices/reports/reportsSlice';
import { selectedReportNameSelector, selectedReportSelector } from 'src/store/selectores/reportsSelectors';

import { GridColumnProps, GridColumnResizeEvent, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { VisasReportRow } from 'src/types/reports/visasReportTypes';
import { ReportNames } from 'src/store/slices/reports/types';
import useColumns from '../../hooks/columnsHooks/useColumns';
import VisasReportPassengersGrid from '../VisasReportPassengersGrid/VisasReportPassengersGrid';
import { isPrintingSelector } from '../../../../../../../store/selectores/reportsSelectors';
import PassengersReportRidesCountGrid from '../PassengersReportRidesCountGrid/PassengersReportRidesCountGrid';
import { IRootReducer } from '../../../../../../../store/reducers/index';
import AllExpansionBtn from './AllExpansionBtn/AllExpansionBtn.index';
import useExpandConfig, { getExpandKey } from '../../hooks/useExpandConfig';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { buildDictByKey } from 'src/utilis/utilis';
import { LocalStorageColumns } from '../../hooks/columnsHooks/useColumnsFromPrevSessions';

interface Props {
    excelExportRef: any;
    setClientSideFilters: any;
    clinetSideFilters: any;
    isPrinting: boolean;
    parentDimensions: any;
}
export const getMaxHeight = () => {
    const { height } = window.screen;
    if (height > 900) return '65vh';
    if (height > 800) return '60vh';
    if (height > 700) return '60vh';
    if (height > 600) return '50vh';
};

const getFieldsConfigDict = (cols: GridColumnProps[]) => {
    const fieldsConfigDict = {};

    cols.forEach((c) => {
        if (!c.id) return;

        fieldsConfigDict[c.id] = { width: c.width, orderIndex: c.orderIndex };
    });

    return fieldsConfigDict;
};

const initialSort: SortDescriptor[] = [];
const pageSize = 25;

const useSelectors: () => {
    reportName: ReportNames;
    reportData: any[];
    isPrinting: boolean;
} = () => {
    const reportName: ReportNames = useAppSelector((state: any) => selectedReportNameSelector(state));
    const reportData = useAppSelector(
        (state: unknown) => selectedReportSelector(state as IRootReducer).reportData
    );
    const isPrinting: boolean = useAppSelector((state: any) => isPrintingSelector(state));
    return { reportName, reportData, isPrinting };
};

// & React Component
const KendoDataGrid: FunctionComponent<Props> = ({ excelExportRef, clinetSideFilters }: Props) => {
    const dispatch = useDispatch();
    const columns = useColumns();
    const { reportData, isPrinting, reportName } = useSelectors();
    // * Selectors

    // * Local State
    const [sort, setSort] = useState(initialSort);
    const [processedData, setProcessedData] = useState(reportData);

    const {
        expandChange,
        toggleExpansionForAll,
        allExpandedVal,
        showAllExpansionBtn,
        getExpendableComponent,
        isExpendable,
    } = useExpandConfig(processedData, setProcessedData);
    // * Variables

    // * Effects
    // & sorting and filtering
    useEffect(() => {
        const ordered = sort ? orderBy(reportData, sort) : reportData;
        const filtered = clinetSideFilters ? filterBy(ordered, clinetSideFilters) : ordered;
        setProcessedData(filtered);
        dispatch(onSortOrFilterAction({ processedData: filtered }));
    }, [sort, clinetSideFilters, dispatch, reportData]);

    const [, setReportsTableColumns] = useLocalStorage<LocalStorageColumns>('reportsTableColumns', {});

    // * Props builders
    const tableProp: TableProp = useMemo(
        () => ({
            columns,
            className: 'report-grid k-rtl',
            style: {
                maxHeight: isPrinting ? 'none' : getMaxHeight(),
                borderRadius: '0.3em',
            },
            wrapperDivStyle: {
                height: '100%',
                maxWidth: '100%',
                width: isPrinting ? 'auto' : ' 100%',
                position: isPrinting ? 'absolute' : 'static',
            },
            rowHeight: 40,
            selectable: {
                cell: false,
                enabled: true,
                mode: 'single',
                drag: false,
            },
            navigatable: false,
            data: processedData,
            total: reportData.length,
            pageSize,
            resizable: !isPrinting,
            reorderable: true,
            sortable: true,
            sort,
            detail: isExpendable ? getExpendableComponent() : null,
            onExpandChange: (e) => {
                expandChange(e, getExpandKey(reportName));
            },
            expandField: isExpendable ? 'expanded' : undefined,
            onSortChange: (e) => {
                setSort(e.sort);
            },
            onColumnReorder: (e) => {
                setReportsTableColumns((prev) => ({ ...prev, ...getFieldsConfigDict(e.columns) }), true);
            },
            onColumnResize: (e) => {
                if (e.end && typeof e.targetColumnId === 'string' && e.newWidth) {
                    // update local storage item
                    const colId = e.targetColumnId;
                    const orderIndex = e.columns.find((c) => c.id === colId)?.orderIndex;

                    setReportsTableColumns(
                        (prev) => ({
                            ...prev,
                            [colId]: { width: e.newWidth, orderIndex },
                        }),
                        true
                    );
                }
            },
        }),
        [
            columns,
            expandChange,
            getExpendableComponent,
            isExpendable,
            isPrinting,
            processedData,
            reportData.length,
            reportName,
            sort,
            setReportsTableColumns,
        ]
    );

    return (
        <div>
            {showAllExpansionBtn && (
                <AllExpansionBtn
                    toggleExpansionForAll={toggleExpansionForAll}
                    allExpandedVal={allExpandedVal}
                />
            )}
            <KendoGrid {...tableProp} excelExportRef={excelExportRef} />
        </div>
    );
};

export default KendoDataGrid;
