import { useTranslation } from 'react-i18next';
import React, { useMemo, useEffect, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { ManualAndGoogleAddressInputProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import { AddressInfo, AddressKeys } from 'src/components/AddressInputs/types';
import { asAddressInfo } from 'src/components/AddressInputs/utils';
import { useRootAppSelector, useAppSelector, useAppDispatch } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    passengersListForStationIdSelector,
    passengersDataSelector,
    openPassengersFormAction,
    setIsStationValidAction,
    formSelector,
    setStationAction,
    storedCoursesImportFormSelector,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { alertHooks, useDispatchAlert } from 'src/hooks/useAlert';
import { addressSchema, stationSchema } from '../../../schema';
import { StationsErrorMessages, Station, StationAddress } from '../../../types';
import { getValidationErrors } from '../../../utils';
import { fullFormHooks } from '../../formSections/hooks.FullForm';

// -- return the selectors needed
const useSelectors = (stationId: string) => {
    const stations = useRootAppSelector((state) => formSelector(state).data.stations);
    const passengersIdsOnStation = useAppSelector((state) =>
        passengersListForStationIdSelector(state, stationId)
    );
    const allPassengers = useAppSelector(passengersDataSelector);
    const coursePassengers = useRootAppSelector(
        (state) => storedCoursesImportFormSelector(state).selectedCourseData.passengers
    );

    return { stations, passengersIdsOnStation, allPassengers, coursePassengers };
};

// -- returns the handler functions for the passengers form btn
const useStationPassengers = (stationId: string) => {
    const dispatch = useAppDispatch();

    const { reachedCarPassQtyLimit } = fullFormHooks.useDidReachPassQtyLimit();
    const dispatchAlert = useDispatchAlert();

    const handleStationPassengersBtnClick = () => {
        if (reachedCarPassQtyLimit) {
            dispatchAlert('warning', 'הרכב מלא, לא ניתן להוסיף נוסעים נוספים');
            return;
        }
        dispatch(openPassengersFormAction({ stationId }));
    };

    return [handleStationPassengersBtnClick];
};

// -- handles the validation of the station address on redux
// -- and returns the errors
const useStationValidation = (currStation: Station) => {
    const { setError, clearErrors } = useFormContext<any>();
    const stations = useRootAppSelector((state: IRootReducer) => formSelector(state).data.stations);
    //  const stationAddress = currStation;
    const dispatch = useAppDispatch();

    const errors = useMemo(
        () => (stations.length ? getValidationErrors(stationSchema, currStation) : []),
        [currStation, stations.length]
    );

    useEffect(() => {
        if (errors.length) {
            console.log(errors);
            dispatch(setIsStationValidAction({ isValid: false }));
        } else {
            dispatch(setIsStationValidAction({ isValid: true }));
        }
    }, [clearErrors, dispatch, errors.length, setError]);

    return errors;
};

const useGetPassengersFullData = (stationId: string) => {
    const { passengersIdsOnStation, allPassengers, coursePassengers } = useSelectors(stationId);

    const passengersOnStationFullData = useMemo(() => {
        // get passengers from client's passengers list
        const passengers = allPassengers.filter((passenger) =>
            passengersIdsOnStation.includes(passenger.passCode)
        );

        if (passengers.length !== passengersIdsOnStation.length) {
            // get passengers from course's passengers list
            const passengersNotOnClientsPassengersList = passengersIdsOnStation.filter(
                (passengerId) => !passengers.find((passenger) => passenger.passCode === passengerId)
            );

            coursePassengers.forEach((passenger) => {
                if (passengersNotOnClientsPassengersList.includes(passenger.passCode)) {
                    passengers.push(passenger);
                }
            });
            // tamir add fallback for the info from maya
        }

        return passengers;
    }, [allPassengers, passengersIdsOnStation]);

    return { passengersOnStationFullData };
};

const DUMMY_STATION: Station = {
    stationId: '',
    street: '',
    city: '',
    houseNum: '',
    passengers: [],
};

// -- returns data about the station
const useStationData = (stationId: string) => {
    const { stations } = useSelectors(stationId);
    const passengersFullData = useGetPassengersFullData(stationId);
    const dispatchAlert = alertHooks.useBaseAlert();

    const currStationData = useMemo(() => {
        const stationMatch = stations.find((st) => st.stationId === stationId);
        if (stationMatch) {
            return stationMatch;
        }

        // Should not reach here
        dispatchAlert(StationsErrorMessages.StationNotFound);
        return DUMMY_STATION;
    }, [dispatchAlert, stationId, stations]);

    return {
        passengersOnStationFullData: passengersFullData.passengersOnStationFullData,
        currStation: currStationData,
    };
};

// -- return the function to create the props for the address inputs
type GetAddressPropsFunc = () => ManualAndGoogleAddressInputProps;

const useProps = (stationId: string, stationIndex: number): { getAddressProps: GetAddressPropsFunc } => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { triggerDirections, isDirectionsTriggeredFromEdit } = useRootAppSelector(
        (state) => state.linesSlice.ui.form
    );

    const { currStation } = useStationData(stationId);

    const errors = useStationValidation(currStation);

    const setAddressData = React.useCallback(
        (data: StationAddress) => {
            if (!isDirectionsTriggeredFromEdit) {
                dispatch(
                    setStationAction({
                        station: {
                            ...data,
                            stationId,
                        },
                    })
                );
                dispatch(triggerDirectionsAction());
            }
        },
        [dispatch, stationId, isDirectionsTriggeredFromEdit]
    );

    const getAddressProps = useCallback((): ManualAndGoogleAddressInputProps => {
        const stationAddress = asAddressInfo(currStation);

        return {
            componentId: `${currStation.stationId}-id`,
            addressInfo: stationAddress,
            autocompleteConfig: {
                props: {
                    setAddressData,
                    label: `${t('station')} ${stationIndex + 1}`,
                    errorMessage: t(getAsTemplateStrArr(errors[0])),
                    error: !!errors[0],
                    usePlaceNameAsStreet: true,
                    useCoords: true,
                },
            },
            manualConfig: {
                props: {
                    setValue: (field: AddressKeys, value: string) => {
                        const newAddressData: AddressInfo = {
                            ...stationAddress,
                            [field]: value,
                        };
                        setAddressData(newAddressData);
                    },
                    withExitBtn: true,
                    errors,
                    useJoinedErrorsArr: Array.isArray(errors),
                },
            },
        };
    }, [currStation, errors, setAddressData, stationIndex, t]);

    return { getAddressProps };
};

export const stationInputHooks = {
    useStationPassengers,
    useStationValidation,
    useSelectors,
    useStationData,
    useProps,
};
