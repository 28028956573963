import { Moment } from 'moment';
import { IShifts } from 'src/types/manualOrders/api.types';
import { PassengerSelected, ReasonForBlockedDate } from '../../types';

export enum SelectableStatus {
    Selectable = 'selectable',
    NonSelectable = 'nonSelectable',
    SelectableByCondition = 'selectableByCondition',
}
export interface SelectableConfig {
    status: SelectableStatus | null;
    reason: ReasonForBlockedDate | null;
}
export interface DateAvailabilityStatus extends SelectableConfig {
    date: Date;
    passengerCausingStatus?: string[];
}

export interface DateDataForOrdering {
    date: Moment;
    selectableConfig: SelectableConfig;
}

export interface Shift {
    pickupTime: string;
    dropTime: string;
    shiftId: string;
}

export interface PlacementDate {
    relativeDate: string;
}
export interface IPlacment {
    autoOrder: Shift;
    manualOrder: {
        pickup: {
            pickupTime: string;
            pickupShiftId: string;
        };
        drop: {
            dropTime: string;
            dropShiftId: string;
        };
    };
    dates: PlacementDate[];
    shiftsAlreadyOrdered: Array<number | null>;
}

export interface SortedShiftsTimes {
    dropTimes: IShifts[];
    pickupTimes: IShifts[];
}
export type Time = 'dropTime' | 'pickupTime';
