import { reqSetLine } from 'src/api/linesApi/linesApi';
import { responseValidators } from 'src/api/utilis';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { SetPassengersResponseCodes } from 'src/types/manualOrders/api.types';
import { CommonCodes, MockRouteResponse } from '../../types/mockServer/routes.types';

export enum EditOrder {
    DeleteFailed = 1499,
    SetFailed = 1498,
}

const editOrder = async (clientData: {
    requestPayloads: {
        deleteOrder: {
            dbUrl: string;
            proxyUrl: string | undefined;
            orders: string;
            relative_date: string;
            pass_id: string;
            token: string;
            pickupTime: string;
            dropTime: string;
            clientCode: string;
        };
        setOrder: any;
    };
}): Promise<MockRouteResponse<{ response?: SetPassengersResponseCodes }>> => {
    const response: MockRouteResponse<{}> = {
        code: null,
        data: {},
    };

    try {
        const { requestPayloads } = clientData;
        const payload = requestPayloads;

        // const delOrderRes = await reqSetLine(payload.deleteOrder);

        // if (responseValidators.isOk(delOrderRes)) {
        //     const setOrderRes = await reqSetLine(payload.setOrder);

        //     if (responseValidators.isOk(setOrderRes)) {
        //         response.code = CommonCodes.Ok;
        //         response.data = {};
        //         return response;
        //     }

        //     response.code = EditOrder.SetFailed;
        //     response.data = { message: ErrorMessages.GeneralError, response: setOrderRes.data.response };

        //     return response;
        // }

        // response.code = EditOrder.DeleteFailed;
        // response.data = {
        //     message: responseValidators.isMissingWs(delOrderRes)
        //         ? ErrorMessages.MissingWs
        //         : ErrorMessages.GeneralError,
        //     response: delOrderRes.data.response,
        // };
    } catch (error) {
        console.log(error);
        response.code = CommonCodes.ExceptionCaught;
        response.data = { message: ErrorMessages.GeneralError };
    }

    return response;
};

const manualOrdersRoutes = {
    editOrder,
};

export default manualOrdersRoutes;
