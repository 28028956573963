import styled, { css, CSSObject } from 'styled-components';

export const Root = styled.div`
    height: 100%;
    width: 100%;
`;
//
export const Container = styled.div<{ style?: CSSObject }>`
    border-radius: 20px;
    height: 91%;
    width: 100%;
    margin-top: 20px;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    padding: 15px 24px;
    justify-content: space-between;
    flex-wrap: wrap;
    ${({ style }) => css(style || {})}
`;

export const Header = styled.div<{ style?: CSSObject }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    ${({ style }) => css(style || {})}
`;

export const OrderHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.p`
    font-size: 25px;
    font-weight: 500;
`;

export const WidgetTitle = styled.p`
    font-size: 30px;
`;

export const ReloadBtn = styled.p`
    display: flex;
    align-items: center;
    color: #494949;
    :hover {
        cursor: pointer;
    }
    :active {
        transform: translateY(2px);
    }
`;

export const Card = styled.div<{
    justifyContent?: string;
    style?: CSSObject;
}>`
    background: white;
    box-shadow: 0px 0px 8px 0px rgba(106, 106, 106, 0.2);
    border-radius: 15px;
    height: 100px;
    width: 45%;
    display: flex;
    flex-direction: column;
    ${({ justifyContent }) =>
        justifyContent &&
        css`
            justify-content: ${justifyContent};
        `}
    ${({ style }) => css(style || {})}
`;

export const Lable = styled.p`
    font-weight: 700;
    font-size: 15px;
    color: rgba(73, 73, 73, 1);
`;

export const Value = styled.p`
    font-weight: 700;
    font-size: 50px;
    color: rgba(73, 73, 73, 1);
`;

export const Icon = styled.img`
    background-color: ${({ theme }) => theme.colors.lightYellow};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
`;
