import React from 'react';
import styled from 'styled-components';

export const Card = styled.div<{ margin?: string }>`
    background: white;
    box-shadow: 0px 0px 8px 0px rgba(106, 106, 106, 0.2);
    border-radius: 15px;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${(props) => {
        return `margin: ${props.margin};`;
    }}
`;
const CountText = styled.p`
    font-weight: 700;
    font-size: 50px;
    color: rgba(73, 73, 73, 1);
`;
const Text = styled.p`
    font-weight: 700;
    font-size: 15px;
    color: rgba(73, 73, 73, 1);
`;

interface Props {
    count: number;
    text: string;
    margin?: string;
    tooltipId?: string;
}

const InfoCard = ({ count, text, margin, tooltipId }: Props): JSX.Element => {
    return (
        <Card margin={margin}>
            <div data-for={tooltipId} data-tip>
                <CountText>{count}</CountText>
                <Text>{text}</Text>
            </div>
        </Card>
    );
};

export default InfoCard;
