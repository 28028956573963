import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { isAuthorized } from 'src/utilis/utilis';

type IsAuthForFunc = (feature: number, cId: number | string) => boolean;

const useIsAuth: (
    optionOrScreenId?: number | null,
    clientId?: number | null
) => {
    isAuth: boolean;
    isAuthFor: IsAuthForFunc;
} = (optionOrScreenId, clientId) => {
    // * Selectors
    const { authorizationToken = {} } = useSelector((state: IRootReducer) => loginSelector(state));

    // -- indicates whether the client is authorized for the feature
    // -- (optionOrScreenId)
    const [isAuth, setIsAuth] = useState(false);

    // -- updates the isAuth state on client or feature change
    useEffect(() => {
        if (!clientId || !optionOrScreenId) {
            setIsAuth(false);
            return;
        }

        setIsAuth(isAuthorized(optionOrScreenId, clientId, authorizationToken));
    }, [authorizationToken, clientId, optionOrScreenId]);

    // -- Func to check if the user is authorized to use the feature.
    const isAuthFor: IsAuthForFunc = useCallback(
        (feature, cId) => {
            return isAuthorized(feature, +cId, authorizationToken);
        },
        [authorizationToken]
    );

    return { isAuth, isAuthFor };
};
export default useIsAuth;
