import { CSSProperties } from 'react';
import { colors, msColors } from 'src/style/themes/defaultTheme';

const titleText: CSSProperties = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    textAlign: 'right',
    color: msColors.primaryTextcolor,
};

export const styles: { [key: string]: CSSProperties } = {
    screen: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '80px',
        backgroundColor: '#EDF7FE',
        padding: '0 33px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 2fr)',
        gridColumnGap: '0px',
        alignContent: 'center',
    },
    titleAndClientFilter: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    clientDropdown: {
        position: 'relative',
        top: '2px',
    },
    headerTitle: {
        // border: '1px solid gray',
        ...titleText,
        gridArea: '1 / 1 / 2 / 2',
        display: 'flex',
        alignItems: 'center',
        width: '200px',
    },
    headerDate: {
        gridArea: '1 / 2 / 2 / 3 }',
        // border: '1px solid yellow',
        ...titleText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerEnding: {
        // border: '1px solid orange',
        gridArea: '1 / 3 / 2 / 4 ',
        display: 'flex',
        justifySelf: 'flex-end',
        alignSelf: 'center',
        justifyContent: 'space-between',
    },
    table: {},
    exitAppIcon: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        right: '15px',
    },
    dropdown: { maxWidth: '300px', backgroundColor: 'white' },
};

//  gridArea: "1 / 1 / 2 / 2 }",
//  gridArea: "1 / 2 / 2 / 3 }",
//  gridArea: "1 / 3 / 2 / 4 }",
