import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Progressbar from 'src/components/Progressbar/Progressbar';
import { DISPLAY_UI } from 'src/constants/dates';
import { stringifyDate } from 'src/utilis/utilis';
import widgetsHooks from '../../../hooks/common.hooks';
import * as Styles from './Dailycard.Style';

interface Props {
    pickup: number;
    drop: number;
}

const Dailycard: FC<Props> = ({ pickup, drop }) => {
    const { t } = useTranslation();
    const { dateRangeStr } = widgetsHooks.useDatesRange();

    return (
        <Styles.Container>
            <Styles.Header>
                <Styles.Text>{`${t('comparisonForPeriod')} ${dateRangeStr}`}</Styles.Text>
            </Styles.Header>
            <Styles.Body>
                <Styles.Row>
                    <Styles.Text>{t('pickUp')}</Styles.Text>
                    <Progressbar count={pickup} position="Horizontal" color="#81B1D8" />
                </Styles.Row>
                <Styles.Row>
                    <Styles.Text>{t('drop')}</Styles.Text>
                    <Progressbar count={drop} position="Horizontal" color="#BCEFFF" />
                </Styles.Row>
            </Styles.Body>
        </Styles.Container>
    );
};

export default Dailycard;
