import _ from 'lodash';
import React from 'react';
import { DeleteBtn, DuplicateBtn, EditBtn } from 'src/components/buttons/ActionsBtns/ActionBtns';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';

import useCommons from 'src/hooks/common/useCommons';
import {
    addSettingsBoxAction,
    removeSettingBoxByIdAction,
    updateTimesSettingsTabValidAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import { uuid } from 'src/utilis/utilis';
import styled, { CSSObject, css } from 'styled-components';
import styles from '../../../CoursesTable.styles';
import CarQtyInput from './CarQtyInput';
import CarTypeDropdown from './CarTypeDropdown';
import DaysInput from './DaysInput';
import TimesInput from './TimesInput';

const BoxContainer = styled.div<{ style?: CSSObject }>`
    background: #f9f9f9;
    border: 1px solid #e8ebef;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    ${({ style }) => css(style || {})}
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    color: #242424;
`;

const ActionBtns: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();

    return (
        <div style={{ display: 'flex', gap: '12px' }}>
            <DuplicateBtn
                iconBtnStyle={styles.actionBtnStyle}
                onClick={(e) => {
                    e.stopPropagation();
                    // Removing all codes because we are creating new rows on DB, not updating existing ones
                    const newBoxData = _.cloneDeep(boxData);

                    for (let i = 0; i < newBoxData.daysRowsCodes.length; i++) {
                        const row = newBoxData.daysRowsCodes[i];
                        row.code = '';
                    }
                    newBoxData.id = uuid();

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    dispatch(addSettingsBoxAction(newBoxData));
                }}
            />
            <DeleteBtn
                iconBtnStyle={styles.actionBtnStyle}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(removeSettingBoxByIdAction(boxData.id));
                }}
            />
        </div>
    );
};

const TimeSettingsBox: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();

    return (
        <BoxContainer>
            <div style={{}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title>{t('setHoursAccordingToRideDays')}</Title>
                    <ActionBtns boxData={boxData} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <DaysInput boxData={boxData} />
                    <TimesInput boxData={boxData} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                        <div style={{ flex: 3 }}>
                            <CarTypeDropdown boxData={boxData} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <CarQtyInput boxData={boxData} />
                        </div>
                    </div>
                </div>
            </div>
        </BoxContainer>
    );
};

export default TimeSettingsBox;
