import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import { IRootReducer } from 'src/store/reducers';

import {
    selectedPassengerPasscodeSelector,
    passengerSelector,
} from 'src/store/selectores/passengersSelector';
import { getOnlySelectedPassenger } from '../utils';

type Props = {};

const useSelectedPassenger = () => {
    const [selectedPassenger, setSelectedPassenger] = useState<IPassenger | undefined>();

    const passengers = useSelector((state: IRootReducer) => passengerSelector(state));

    const selectedPassengerPasscode = useSelector((state: IRootReducer) =>
        selectedPassengerPasscodeSelector(state)
    );

    useEffect(() => {
        const passenger = getOnlySelectedPassenger(passengers, selectedPassengerPasscode);
        setSelectedPassenger(passenger);
    }, [passengers, selectedPassengerPasscode]);

    return selectedPassenger;
};

export default useSelectedPassenger;
