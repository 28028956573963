import React, { ReactElement, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ReqStatus } from 'src/api/types';
import ReportsScreen from 'src/screens/Reports/ReportsScreen';
import { getCurrentPath } from 'src/utilis/utilis';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { onPathChangeAction } from 'src/store/slices/common/commonSlice';
import DropDisplayScreen from 'src/screens/DropsDisplay/DropDisplayScreen';
import { uiSelector } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Home from './Home/Home';
import useInitalRoute from './hooks/useInitalRoute';
import Auth from './Auth/Auth';
import PrivateRoute from './PrivateRoute';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default function Routes(): ReactElement {
    const { isAuthenticated } = useInitalRoute();

    if (isAuthenticated === ReqStatus.LOADING) return <div />;

    return (
        <Switch>
            <Route path="/auth" component={Auth} />
            <PrivateRoute path="/" component={<Home />} exact={false} />

            {/* <Redirect path="/" to="/auth/signup" /> */}
        </Switch>
    );
}
