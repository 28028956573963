import { AxiosRes, ReqConfig, WsResponse, WsResponseWithDelta } from 'src/api/types';
import { makeApiCallV2 } from 'src/api/utilis';
import {
    IOptimizationFormWaypoint,
    IOptimizationWaypoint,
    IOptimumWaypoint,
    ISimulationResult,
} from 'src/screens/Optimizations/api/types';
import axios, { AxiosResponse } from 'axios';

export type Response = WsResponseWithDelta<IOptimizationWaypoint[]>;
export type SetWaypointsConfigResponse = WsResponse<{ response: string; data: '' }>;

export type GetWaypointsConfig = ReqConfig<{
    token: string;
    clientCode: string | null;
    lastAction: string;
    isGetNotActive: number;
}>;
export type SetWaypointsConfig = ReqConfig<{
    token: string;
    jsonData: string;
    isUpdate: boolean;
    clientCode: string;
}>;
export type DeleteWaypointsConfig = ReqConfig<{ token: string; codes: string }>;
export type SetOptimizationSimulationConfig = {
    hashID: string;
    maxValueLimitInRoute: number;
    passengerLimitInVehicle: number;
    maxWalkingDistanceFromWayPoint: number;
    toTarget: boolean;
    waypointsJson: IOptimumWaypoint[];
};
export type GetOptimizationSimulationConfig = { hashID: string };
export type SetStationsConfig = ReqConfig<{ token: string; jsonData: string; clientCode: string }>;

// FC REQUEST

export const getServerOptimizationWaypoints = (requestConfig: GetWaypointsConfig): AxiosRes<Response> =>
    makeApiCallV2<
        { token: string; clientCode: string | null; lastAction: string; isGetNotActive: number },
        Response
    >({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_WayPoints',
    });

export const setServerOptimizationWaypoints = (requestConfig: SetWaypointsConfig): AxiosRes<Response> =>
    makeApiCallV2<{ token: string; jsonData: string; isUpdate: boolean; clientCode: string }, Response>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_WayPoints',
    });

export const deleteOptimizationWaypoints = (requestConfig: DeleteWaypointsConfig): AxiosRes<Response> =>
    makeApiCallV2<{ token: string; codes: string }, Response>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Del_WayPoints',
    });

export const setOptimizationStations = (requestConfig: SetStationsConfig): AxiosRes<Response> =>
    makeApiCallV2<{ token: string; jsonData: string; clientCode: string }, Response>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_Optimization_Stations',
    });

// Optimization Simulation

const OPTIMIZATION_SIMULATION_SERVICE_URL = 'https://optimizationapp.y-it.co.il/OptServices';

const createUrl = (endpoint: string, hashId?: string) =>
    hashId
        ? `${OPTIMIZATION_SIMULATION_SERVICE_URL}/${endpoint}/?hashID=${encodeURIComponent(hashId)}`
        : `${OPTIMIZATION_SIMULATION_SERVICE_URL}/${endpoint}`;

const ENDPOINTS = {
    SET_WAYPOINTS_POINTS_FOR_OPTIMIZATIONS: 'setWayPointsForOptimization',
    GET_OPTIMUM_ROUTE_RESULT: 'getOptimumRouteResults',
    INIT_OPTIMIZATION_PROCESS: 'initOptimizationProcess',
} as const;

export const getOptimizationSimulationResult = (hashId: string): AxiosRes<ISimulationResult> => {
    return axios.post(createUrl(ENDPOINTS.GET_OPTIMUM_ROUTE_RESULT, hashId));
};

export const setWayPointsForOptimization = (
    requestConfig: SetOptimizationSimulationConfig
): AxiosRes<ISimulationResult> => {
    return axios.post(createUrl(ENDPOINTS.SET_WAYPOINTS_POINTS_FOR_OPTIMIZATIONS), requestConfig);
};

export const initOptimizationProcess = (): AxiosRes<string> => {
    return axios.post(
        `${OPTIMIZATION_SIMULATION_SERVICE_URL}/${ENDPOINTS.INIT_OPTIMIZATION_PROCESS}?token=token`
    );
};
