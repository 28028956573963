import moment from 'moment';
import {
    IEditShift,
    IPassengerShifts,
    ISelectedShifts,
    ISelectedSingleShift,
    IShifts,
} from 'src/types/manualOrders/api.types';
import { SHORT as SHORT1 } from 'src/constants/dates';
import { IRootReducer } from '../reducers';
import {
    BlockedOrdersDatesForPassengers,
    IManualOrderTable,
    IPassengerShiftState,
} from '../reducers/passengersShiftReudcer';

export const passengerRootDataSelector = (state: IRootReducer): IPassengerShiftState =>
    state.passengersShiftReudcer;

export const passengerSelector = (state: IRootReducer): Array<IPassengerShifts> =>
    passengerRootDataSelector(state).passengers;

export const shiftsSelector = (state: IRootReducer): Array<IShifts> =>
    passengerRootDataSelector(state).shifts;

export const selectedShiftSelector = (state: IRootReducer): ISelectedShifts | undefined | null =>
    passengerRootDataSelector(state).selectedShift;

export const selectedSingleShiftSelector = (state: IRootReducer): ISelectedSingleShift | undefined =>
    passengerRootDataSelector(state).selectedSingleShift;

export const editShiftSelector = (state: IRootReducer): IEditShift | undefined | null =>
    passengerRootDataSelector(state).editShift;

export const uiSelector = (state: IRootReducer) => passengerRootDataSelector(state).ui;

export const formSelector = (state: IRootReducer) => uiSelector(state).form;

export const tableSelector = (state: IRootReducer): IManualOrderTable => uiSelector(state).table;
export const tableDatesSelector = (state: IRootReducer) => {
    return uiSelector(state).table.dateRange;
};
