import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootReducer } from 'src/store/reducers';
import { authorizationTokenSelector } from 'src/store/selectores/loginSelectors';
import { routesEndpoints } from '../routes_endpoints';

const useAuthorization = (): void => {
    const history = useHistory();
    const location = useLocation();

    const authorizationToken = useSelector((state: IRootReducer) => authorizationTokenSelector(state));

    useEffect(() => {
        const isAuthorized = () => {
            const routObject: any = routesEndpoints;

            const routArray: Array<{ SCREEN_ID: number; SCREEN_PATH: string } | any> = Object.values(
                routObject
            ).filter((x: any) => x && x.SCREEN_PATH && x.SCREEN_ID);

            if (
                !authorizationToken ||
                !location.pathname ||
                !routArray.some((x) => x.SCREEN_PATH === location.pathname)
            )
                return;

            const screenId: number | undefined = routArray.find(
                (x) => x.SCREEN_PATH === location.pathname
            )?.SCREEN_ID;

            if (!screenId) return;

            if (!authorizationToken[screenId]) {
                history.push(routesEndpoints.HOME);
                return;
            }

            if (authorizationToken[screenId].clients.length === 0) history.push(routesEndpoints.HOME);
        };

        isAuthorized();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorizationToken]);
};

export default useAuthorization;
