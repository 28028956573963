import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import {
    providerClientsSelector,
    screenClientCodeSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { IDateRange, DateTypeEnum } from 'src/types/global';

interface IProps {
    weeklyDate: IDateRange;
    dayilyDate: Date;
    dateType: DateTypeEnum;
}

const useDateFilter = ({ weeklyDate, dayilyDate, dateType }: IProps): IDateRange => {
    const [selectedDates, setSelectedDate] = useState<IDateRange>(
        dateType === DateTypeEnum.weekly
            ? weeklyDate
            : {
                  startDate: dayilyDate || new Date(),
                  endDate: moment(dayilyDate).endOf('day').toDate(),
              }
    );

    useEffect(() => {
        setSelectedDate(
            dateType === DateTypeEnum.weekly
                ? weeklyDate
                : {
                      startDate: dayilyDate || new Date(),
                      endDate: moment(dayilyDate).endOf('day').toDate(),
                  }
        );
    }, [dayilyDate, weeklyDate, dateType]);

    return selectedDates;
};

export default useDateFilter;
