/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import initialGeneralFeaturesSliceState from './contants.generalFeatures';
import {
    GeneralFeaturesSliceState,
    ExistingFileData,
    FileActions,
    ApiReqStatus,
} from './types.generalFeatures';

const uploadFileReducers = {
    setExistingFileData: (
        state: GeneralFeaturesSliceState,
        action: PayloadAction<ExistingFileData | null>
    ) => {
        state.uploadFileFt.data.fileData = action.payload;
    },
    toggleModal: (state: GeneralFeaturesSliceState, action: PayloadAction<boolean | null>) => {
        if (action.payload === null) {
            state.uploadFileFt.ui.mainModal.isOpen = !state.uploadFileFt.ui.mainModal.isOpen;
            return;
        }
        state.uploadFileFt.ui.mainModal.isOpen = action.payload;
    },
    toggleSuccessModal: (state: GeneralFeaturesSliceState, action: PayloadAction<boolean | null>) => {
        if (action.payload === null) {
            state.uploadFileFt.ui.successModal.isOpen = !state.uploadFileFt.ui.successModal.isOpen;
            return;
        }
        state.uploadFileFt.ui.successModal.isOpen = action.payload;
    },
    updateActionStatus: (
        state: GeneralFeaturesSliceState,
        action: PayloadAction<{
            actionType: FileActions;
            status: ApiReqStatus;
        }>
    ) => {
        const { actionType, status } = action.payload;
        state.uploadFileFt.data.actionsStatus[actionType] = status;
    },
    setFileInputData: (
        state: GeneralFeaturesSliceState,
        action: PayloadAction<{ fileName: string } | null>
    ) => {
        if (action.payload === null) {
            state.uploadFileFt.ui.fileInput = null;
            return;
        }
        state.uploadFileFt.ui.fileInput = {
            fileName: action.payload.fileName,
        };
    },
    setSelectedClientCode: (state: GeneralFeaturesSliceState, action: PayloadAction<string | null>) => {
        state.uploadFileFt.data.selectedClientCode = action.payload;
    },
    resetState: (state: GeneralFeaturesSliceState) => {
        state.uploadFileFt = initialGeneralFeaturesSliceState.uploadFileFt;
    },
};

export default uploadFileReducers;
