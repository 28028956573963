import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonMUI from '@material-ui/core/Button';

const Container = styled.div`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 610px;
    height: 412px;
`;

const SpaceAround = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 90%;
`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.div`
    width: 100%;
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue};
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-start;
`;

const TitleHeader = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.blue};
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
`;

const Input = styled(OutlinedInput)`
    &.MuiOutlinedInput-root {
        width: 296px;
        text-align: center;
    }
`;

const Button = styled(ButtonMUI)`
    && {
        background-color: #2196f3;
        width: 296px;
    }
`;

const PhoneText = styled.bdi`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
`;

export default {
    Container,
    Header,
    TitleHeader,
    Text,
    Input,
    Button,
    SpaceAround,
    Center,
    PhoneText,
};
