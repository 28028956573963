import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getClientFilterProp,
    getProviderDropdownProp,
} from 'src/screens/RideSettings/components/props/props';
import useClientFiltering from 'src/hooks/useClientFiltering';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import styled, { css, CSSObject } from 'styled-components';
import styles from 'src/components/StyledComponents/StyledComponents.style';
import { headlineCss } from 'src/style/styledCSS';
import { msColors } from 'src/style/themes/defaultTheme';
import { Func } from 'src/types/global';
import { StrictDropdownItem } from 'src/types/line';
import DropDown from '../DropDown/DropDown';
import RefreshButton from '../RefreshButton/RefreshButton';
import DropDownV2 from '../DropDown/DropDownV2';
import { warningRed } from '../NavBar/NavBar.style';
import useEnvAuth from 'src/hooks/useEnvAuth';
import { HeaderContainer } from '../HeaderWithDropdown/HeaderWithDropdown';
import { IconButton } from '@material-ui/core';
import { styleIcon } from 'src/screens/PassengerManger/components/Passengers/styles/css-styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

type Props = {
    onBackClick: Func;
    style?: CSSObject;
};

const HeaderWithBack = ({ onBackClick, style }: Props): JSX.Element => {
    // * Selectors

    const envAuth = useEnvAuth();

    return (
        <HeaderContainer
            withWarningColors={envAuth.isUsingProdAccountOnDev}
            style={style}
            className="header-container"
        >
            <div>
                <styles.TextContainer>
                    <styles.Text style={headlineCss} color={msColors.darkTextcolor}>
                        Studio
                    </styles.Text>
                    <styles.Text style={{ whiteSpace: 'break-spaces' }}> </styles.Text>
                    <styles.Text style={headlineCss} color={msColors.yellow}>
                        MyWay
                    </styles.Text>
                </styles.TextContainer>
            </div>
            <styles.LeftHeaderContainer gap="20px">
                <IconButton size="small" disabled={false} onClick={onBackClick}>
                    <div style={styleIcon}>
                        <ArrowBackOutlinedIcon />
                    </div>
                </IconButton>
            </styles.LeftHeaderContainer>
        </HeaderContainer>
    );
};

export default HeaderWithBack;
