import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import { isConnectSelector, loginSelector } from './store/selectores/loginSelectors';
import { IRootReducer } from './store/reducers';
import Fotter from './components/Footer/Footer';
import Routes from './routes/Routes';
import { ReqStatus } from './api/types';
import Dialog from './components/Dialog/Dialog';
import { muiTheme } from './style/themes/materialTheme';
import { full } from './style/styledCSS';
import { useAppDispatch, useRootAppSelector } from './store/hooks';
import {
    commonRootSelector,
    onPathChangeAction,
    setIsGoogleApiCounterExceededPopUpOpenAction,
    setIsGoogleApiInterfaceCounterExceededAction,
    setIsRedirectPopupOpenAction,
} from './store/slices/common/commonSlice';
import { getCurrentPath, getLocalStorageValue } from './utilis/utilis';
import ActionConfirmModal from './components/Modals/ActionConfirmModal';
import instance, { encryptProxy } from './api';
import { setLogout } from './store/actions/loginAction';
import { StorageKeys } from './types/global';
import { logout } from './api/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default function MainApp(props: Props): ReactElement {
    const { t } = useTranslation();
    const NEW_CONNECTION_NEEDED_MESSAGE = t('newConnectionNeeded');
    const SYSTEM_DISCONNECTED_MESSAGE = t('systemDisconnected');

    const { selectedFcAccount, userUUID, token, mobile } = useSelector((state: IRootReducer) =>
        loginSelector(state)
    );

    const isRedirectPopupOpen = useRootAppSelector((state) => state.commonSlice.isRedirectPopupOpen);
    const isGoogleApiCounterExceededPopUpOpen = useRootAppSelector(
        (state) => state.commonSlice.isGoogleApiCounterExceededPopUpOpen
    );

    const dispatch = useDispatch();

    const documentIE: any = document;
    const isIE = false || !!documentIE.documentMode;

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(isIE);

    const onCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const DialogAction = () => {
        return (
            <>
                <Button onClick={onCloseDialog} color="primary">
                    {t('ok')}
                </Button>
            </>
        );
    };

    const onSetLogout = () => dispatch(setLogout());

    instance.interceptors.response.use(
        (response) => {
            // Do something with the response data

            if (
                response &&
                response.status &&
                response.status === 200 &&
                response.config &&
                response.config.url &&
                encryptProxy &&
                response.config.url.includes(encryptProxy)
            ) {
                // token not valid

                if (
                    !response.config.url.includes('Get_Excel_Lines_Report') &&
                    response.data &&
                    response.data.response === '1'
                ) {
                    dispatch(setIsRedirectPopupOpenAction({ isRedirectPopupOpen: true }));
                }
            }

            return response;
        },
        (error) => {
            // Do something with the response error
            return Promise.reject(error);
        }
    );

    const onRedirectConfirm = async () => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
        if (deviceToken && userUUID) {
            const res = await logout({
                deviceToken,
                userUUID,
            });
            if (res.response === '0') onSetLogout();
            dispatch(setIsRedirectPopupOpenAction({ isRedirectPopupOpen: false }));

            return;
        }

        onSetLogout();
        dispatch(setIsRedirectPopupOpenAction({ isRedirectPopupOpen: false }));
    };

    const onExceededPopUpConfirm = async () => {
        dispatch(setIsGoogleApiCounterExceededPopUpOpenAction({ isOpen: false }));
    };

    return (
        <>
            <ThemeProvider theme={muiTheme}>
                <ActionConfirmModal
                    Body={() => (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <div>{SYSTEM_DISCONNECTED_MESSAGE}</div>
                            <div>{NEW_CONNECTION_NEEDED_MESSAGE}</div>
                        </div>
                    )}
                    width={380}
                    height={200}
                    isRedirectPopup
                    isOpen={isRedirectPopupOpen}
                    onConfirm={onRedirectConfirm}
                    Img={() => <PriorityHighIcon fontSize="large" />}
                />

                <ActionConfirmModal
                    Body={() => (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <div>{t('googleCounterApiExceeded')}</div>
                        </div>
                    )}
                    isRedirectPopup
                    isOpen={isGoogleApiCounterExceededPopUpOpen}
                    onConfirm={onExceededPopUpConfirm}
                    Img={() => <PriorityHighIcon fontSize="large" />}
                />
                <Routes />
                {/* <Fotter /> */}
                <Dialog
                    DialogActionsChildren={DialogAction()}
                    DialogContentChildren={<h1>{t('interntExplorerError')}</h1>}
                    DialogTitleChildren={<h1>{t('interntExplorerError')}</h1>}
                    open={isDialogOpen}
                    onClose={onCloseDialog}
                />
            </ThemeProvider>
        </>
    );
}
