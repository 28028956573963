/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UseMultipleDropDownProps } from 'src/components/MultipleDropDown/hooks';
import { DAYS_NUMBERS, DAYS_HE, DAYS_HE_SHORT } from 'src/constants/dates';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { FilterTypes } from 'src/store/reducers/reportsReducers/uiReducers';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import {
    departmentsSelector,
    dataSelector,
    filtersSelector,
    selectedReportNameSelector,
    filtersByReportSelector,
    clientIdSelector,
} from 'src/store/selectores/reportsSelectors';
import { onChangeFilterByReportAction } from 'src/store/slices/reports/reportsSlice';
import { asRootReducer } from 'src/store/utils';
import { DayNum } from 'src/types/global';
import { useAppSelector } from '../../../../../../store/hooks';
import { buildDepartmentsMap, buildObjMap } from './utils';

export const useSelectors = () => {
    const departments = useAppSelector((state) => departmentsSelector(asRootReducer(state))) || [];
    const shiftsTimes = useAppSelector((state) => dataSelector(asRootReducer(state)).shiftTimes);

    const selectedReport = useAppSelector((state) => selectedReportNameSelector(state));

    const filtersVal = useAppSelector((state) => filtersSelector(state, selectedReport));
    return { departments, shiftsTimes, filtersVal, selectedReport };
};

export const useMultipleDaysDropdownConfig = () => {
    const { t } = useTranslation();

    const days = useAppSelector((state: any) => filtersByReportSelector(state, 'passengers').days);

    const daysDropdownConfig: UseMultipleDropDownProps<DayNum> = {
        selectedValsKeys: days,
        allValuesKeys: DAYS_NUMBERS,
        allValuesMap: DAYS_HE,
        labelText: t('days'),
        useShortText: true,
        shortTextValuesMap: DAYS_HE_SHORT,
        onChangeAction: (result: DayNum[]) =>
            onChangeFilterByReportAction({
                filterType: FilterTypes.Days,
                newValue: result,
                reportName: 'passengers',
            }),
    };

    return daysDropdownConfig;
};

export const useMultipleDepartmentsDropdownConfig = () => {
    const { t } = useTranslation();
    const departments = useAppSelector((state) => departmentsSelector(state)) || [];
    const selectedDepts = useAppSelector(
        (state) => filtersByReportSelector(asRootReducer(state), 'passengers').departmentCodes
    );

    const departmentsDropdownConfig: UseMultipleDropDownProps<string> = {
        selectedValsKeys: selectedDepts,
        allValuesKeys: departments.map((dep) => String(dep.code)),
        allValuesMap: buildDepartmentsMap(departments),
        labelText: t('departments'),
        onChangeAction: (result: string[]) =>
            onChangeFilterByReportAction({
                filterType: FilterTypes.DepartmentCodes,
                newValue: result,
                reportName: 'passengers',
            }),
    };

    return departmentsDropdownConfig;
};

export const useMultipleShiftsDropdownConfig = () => {
    // const { t } = useTranslation();
    // const dispatch = useDispatch();
    // const selectedFcAccount = useSelector((state: any) =>
    //    selectedFcAccountSelector(state),
    // );
    // // const selectedShiftsIds = useAppSelector(
    // //    (state: any) =>
    // //       filtersByReportSelector(state, 'passengers').shiftIds,
    // // );
    // const token = useSelector((state: any) => tokenSelector(state));
    // const clientCode = useAppSelector(clientIdSelector);
    // const [shifts, setShifts] = useState({});
    // useEffect(() => {
    //    const { proxyUrl } = selectedFcAccount || {};
    //    if (selectedFcAccount?.dbUrl && token && clientCode) {
    //       getAccountShifts({
    //          proxyUrl,
    //          dbUrl: selectedFcAccount.dbUrl,
    //          endpoint: 'ws_MyWayStudio_Get_Account_Shifts',
    //          requestParams: {
    //             token,
    //             clientCode,
    //          },
    //       }).then((res) => {
    //          if (res.status === 200 && res.data.response === '0') {
    //             const { accountShifts } = res.data;
    //             const rawShifts = accountShifts.map((s: any) => ({
    //                shiftCode: s.shiftCode,
    //                shiftName: s.shiftName,
    //             }));
    //             setShifts(
    //                buildObjMap(rawShifts, 'shiftCode', 'shiftName'),
    //             );
    //          } else {
    //             dispatch(setErrorMessage('שגיאה במשיכת משמרות'));
    //          }
    //       });
    //    }
    // }, [clientCode, dispatch, selectedFcAccount, token]);
    // const shiftsDropdownConfig: UseMultipleDropDownProps<string> = {
    //    selectedValsKeys: selectedShiftsIds,
    //    allValuesKeys: Object.keys(shifts),
    //    allValuesMap: shifts,
    //    labelText: t('shifts'),
    //    onChangeAction: (result: string[]) =>
    //       onChangeFilterByReportAction({
    //          filterType: FilterTypes.ShiftIds,
    //          newValue: result,
    //          reportName: 'passengers',
    //       }),
    // };
    // return shiftsDropdownConfig;
};
