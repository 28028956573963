import { IDeletePassengerSagaRequest, IPassenger, ISetSagaPassenger } from 'src/api/passengerMangerApi/types';
import { PassengerActionType } from './actionType';
import { ReduxDispatch } from '../type';

export const onRetrivePassengersSuccess = (payload: Array<IPassenger>): ReduxDispatch<Array<IPassenger>> => {
    return {
        type: PassengerActionType.retrivePassengerSuccess,
        payload,
    };
};

export const onChangeAddress = (payload: boolean): ReduxDispatch<any> => {
    return {
        type: PassengerActionType.onChangeAddress,
        payload,
    };
};

export const changedForm = (): ReduxDispatch<any> => {
    return {
        type: PassengerActionType.changedForm,
        payload: null,
    };
};

export const onRetrivePassengersFaild = (payload: never): ReduxDispatch<never> => {
    return {
        type: PassengerActionType.retrivePassengerFail,
        payload,
    };
};

interface SelectionChangeData {
    passengers: IPassenger[];
    selectedPassengerPasscode: number;
    selectedPassengers: number[];
    activeSelectedPassengers: number[];
    modifyingPassenger: boolean;
}
// change selection action dispatched
export const onChangeSelection = (payload: SelectionChangeData): ReduxDispatch<SelectionChangeData> => {
    return {
        type: PassengerActionType.changeSelection,
        payload,
    };
};

export const onUnSelectPassenger = (payload: IPassenger[]): ReduxDispatch<IPassenger[]> => {
    return {
        type: PassengerActionType.onUnSelectPassenger,
        payload,
    };
};

export const onSetClientId = (
    payload: string | number | undefined | null
): ReduxDispatch<string | number | undefined | null> => {
    return {
        type: PassengerActionType.setClientId,
        payload,
    };
};

export const onSetModefiedPassenger = (
    payload: IPassenger | undefined | null
): ReduxDispatch<IPassenger | undefined | null> => {
    return {
        type: PassengerActionType.setModefiedPassenger,
        payload,
    };
};

export const onAddPassengerBtnClicked = (payload: null): ReduxDispatch<IPassenger | undefined | null> => {
    return {
        type: PassengerActionType.onAddPassengerBtnClicked,
        payload,
    };
};

export const onDeletePassengers = (
    payload: IDeletePassengerSagaRequest
): ReduxDispatch<IDeletePassengerSagaRequest> => {
    return {
        type: PassengerActionType.deletePassenger,
        payload,
    };
};

export const onDeletePassengersSuccess = (
    payload: number[] | string[]
): ReduxDispatch<number[] | string[]> => {
    return {
        type: PassengerActionType.deletePassengerSuccess,
        payload,
    };
};

export const onDeletePassengersFaild = (): ReduxDispatch<unknown> => {
    return {
        type: PassengerActionType.deletePassengerFail,
        payload: null,
    };
};

export const onSetPassengers = (payload: ISetSagaPassenger): ReduxDispatch<ISetSagaPassenger> => {
    return {
        type: PassengerActionType.setPassenger,
        payload,
    };
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resetFormState = (): ReduxDispatch<any> => {
    return { type: PassengerActionType.resetFormState, payload: null };
};

export const onSetPassengersSuccess = (payload: IPassenger): ReduxDispatch<IPassenger> => {
    return {
        type: PassengerActionType.setPassengerSuccess,
        payload,
    };
};

export const onSetPassengersFaild = (): ReduxDispatch<unknown> => {
    return {
        type: PassengerActionType.setPassengerFail,
        payload: null,
    };
};

export const onSetFormAddress = (payload: any): ReduxDispatch<any> => {
    return {
        type: PassengerActionType.setFormAddress,
        payload,
    };
};
