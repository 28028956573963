import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Position } from 'src/types/global';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import * as Styles from './MultiProgressbar.Style';

interface Props {
    pickup: number;
    drop: number;
    position: keyof typeof Position;
    label: string;
    max: number;
    date: string;
    code: string;
}
interface TollTopContentProps {
    pickup: number;
    drop: number;
    date: string;
}

const TollTopContent: FC<TollTopContentProps> = ({ pickup, drop, date }) => {
    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'right' }}>
            <div>{`${t('infoFor')} ${date}`}</div>
            <Styles.TooltipLable isPick>{`${pickup} ${t('pickUp')}`}</Styles.TooltipLable>
            <Styles.TooltipLable isPick={false}>{`${drop} ${t('drop')}`}</Styles.TooltipLable>
        </div>
    );
};

const MultiProgressbar: FC<Props> = ({ pickup, drop, position, label = '', max, date, code }: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    return (
        <>
            <Styles.Container position={position} data-for={code} data-tip>
                <Styles.Label>{label}</Styles.Label>

                <Styles.Progress
                    count={pickup}
                    color="#81B1D8"
                    position={position}
                    withBottomRadius
                    height={(pickup / max) * 99}
                />
                <Styles.Progress
                    count={drop}
                    color={colors.blueOcean}
                    position={position}
                    withBottomRadius={false}
                    height={(drop / max) * 99}
                />

                <Styles.Count>{pickup + drop}</Styles.Count>
            </Styles.Container>
            <ReactTooltip id={code} place="right" type="light" borderColor="gray" border>
                <TollTopContent pickup={pickup} drop={drop} date={date} />
            </ReactTooltip>
        </>
    );
};

export default MultiProgressbar;
