import { FormControlProps } from '@material-ui/core/FormControl';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IShifts } from 'src/types/manualOrders/api.types';
import DropDown from 'src/components/DropDown/DropDown';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import { ShiftType } from 'src/screens/ManualOrder/types';
import ClearSelectionBtn from '../ClearSelectionBtn/ClearSelectionBtn';
import * as Styles from './Manualplacement.Style';
import {
    useDidUserPickShift,
    useSortedShiftTimes,
    useDropsDropdownProps,
    usePickupDropdownProps,
    useRemoveShiftFromPlacementIfDisabled,
    useSimplifiedManualOrderData,
} from './hooks';

interface Props {
    shifts: IShifts[];
}
const formControl: FormControlProps = {
    variant: 'outlined',
    style: { width: '100%' },
    size: 'small',
};

const ManualPlacement: FC<Props> = ({ shifts }) => {
    const { t } = useTranslation();
    const { placment } = usePlacement();

    // getting unique shift times for shift time dropdown
    const distinctShiftsTimes = useSortedShiftTimes(shifts);

    useRemoveShiftFromPlacementIfDisabled(shifts, distinctShiftsTimes);

    //  Dropdown props
    const { pickups, onChangePickup } = usePickupDropdownProps(shifts, distinctShiftsTimes);
    const { drops, onChangeDrop } = useDropsDropdownProps(shifts, distinctShiftsTimes);

    // Insuring user picks at least one shift
    const isValid = useDidUserPickShift();

    return (
        <>
            <Styles.Container>
                <Styles.Col>
                    <DropDown
                        labalName={t('pickTime')}
                        formControlProp={formControl}
                        menueItem={pickups}
                        onChange={onChangePickup}
                        value={placment.manualOrder.pickup.pickupShiftId}
                        error={!isValid}
                    />
                </Styles.Col>
                <Styles.Col>
                    <DropDown
                        labalName={t('dropTime')}
                        formControlProp={formControl}
                        menueItem={drops}
                        onChange={onChangeDrop}
                        value={placment.manualOrder.drop.dropShiftId}
                        error={!isValid}
                    />
                </Styles.Col>
            </Styles.Container>
            <ClearSelectionBtn shiftTypeForSelection={ShiftType.Manual} />
        </>
    );
};

export default ManualPlacement;
// const [isValid, setIsValid] = useState<boolean>(false);

// const editShift = useSelector((state: IRootReducer) =>
//    editShiftSelector(state),
// );

// useEffect(() => {
//    if (editShift) {
//       const { shiftId, dropTime, pickupTime } = editShift;
//       if (!shiftId && (dropTime || pickupTime)) {
//          let selectedShiftId;
//          const shiftData = (() => {
//             shifts.forEach((shift: IShifts) => {
//                if (
//                   shift.dropTime === dropTime ||
//                   shift.pickupTime === pickupTime
//                )
//                   selectedShiftId = String(shift.shiftId);
//             });
//             return {
//                pickup: {
//                   pickupTime: pickupTime || '',
//                   pickupShiftId: pickupTime ? selectedShiftId : '',
//                },
//                drop: {
//                   dropTime: dropTime || '',
//                   dropShiftId: dropTime ? selectedShiftId : '',
//                },
//             };
//          })();
//          // addShiftToPlacement('manual', shiftData);
//       }
//    }
//    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [editShift]);

// useEffect(() => {
//    // -- removing drop time from placement context if it was removed
//    // -- from the form because its not included in the distinct shift time
//    const { dropTimes, pickupTimes } = distinctShiftsTimes;
//    if (
//       !dropTimes
//          .map((d) => d.dropTime)
//          .includes(placment.manualOrder.drop.dropTime)
//    ) {
//       removeManualShiftShift('drop');
//    }
//    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [distinctShiftsTimes]);
// const pickups: MenuItemProps[] =
//    distinctShiftsTimes?.pickupTimes.map((s) => ({
//       value: s.shiftId,
//       name: s.pickupTime ?? '',
//       disabled: isShiftItemDisabled(
//          s,
//          placment.dates,
//          isShiftOrdered,
//          {
//             shiftType: 'manual',
//             isPickup: true,
//          },
//       ),
//    }));

/*
const onChangeDrop = useCallback(
      (
         val: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
         }>,
      ): void => {
         const { value } = val.target;
         const selectShift = shifts.find(
            (s) => s.shiftId === Number(value),
         );

         if (!selectShift) return;

         const { dropTime = '', shiftId } = selectShift;
         dispatch(
            onChangedShiftSelection({
               isShiftSelected: true,
               selectedManualShift: true,
               dropOffShiftId: shiftId,
            }),
         );
         setPlacment((pre) => ({
            ...pre,
            manualOrder: {
               ...pre.manualOrder,
               drop: {
                  dropTime,
                  dropShiftId: `${shiftId}`,
               },
            },
         }));
      },
      [dispatch, setPlacment, shifts],
   );


*/

// const onChangePickup = useCallback(
//    (
//       val: React.ChangeEvent<{
//          name?: string | undefined;
//          value: unknown;
//       }>,
//    ): void => {
//       const { value } = val.target;
//       const selectShift = shifts.find(
//          (s) => s.shiftId === Number(value),
//       );

//       if (!selectShift) {
//          dispatch(
//             onChangedShiftSelection({
//                isShiftSelected: false,
//                selectedManualShift: null,
//             }),
//          );
//          return;
//       }
//       const { pickupTime = '', shiftId } = selectShift;
//       dispatch(
//          onChangedShiftSelection({
//             isShiftSelected: true,
//             shiftId,
//             selectedManualShift: true,
//          }),
//       );

//       setPlacment((pre) => ({
//          ...pre,
//          manualOrder: {
//             ...pre.manualOrder,
//             pickup: {
//                pickupTime,
//                pickupShiftId: `${shiftId}`,
//             },
//          },
//       }));
//    },
//    [dispatch, setPlacment, shifts],
// );

// const drops: MenuItemProps[] = distinctShiftsTimes?.dropTimes.map(
//    (s) => ({
//       value: s.shiftId,
//       disabled: isShiftItemDisabled(
//          s,
//          placment.dates,
//          isShiftOrdered,
//          {
//             shiftType: 'manual',
//             isPickup: false,
//          },
//       ),
//       child: (
//          <ManualShiftItemChild
//             orderTimes={{
//                pickupTime: placment.manualOrder.pickup.pickupTime,
//                dropTime: s.dropTime || '',
//             }}
//          />
//       ),
//       name: '',
//    }),
// );
