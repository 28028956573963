import React, { CSSProperties } from 'react';

const size = '30px';

export const iconButtonStyle: CSSProperties = {
    backgroundColor: '#EDBD41',
    fill: 'black',
    color: 'black',
    marginLeft: '5px',
    height: size,
    width: size,
};
