import { useSelector } from 'react-redux';
import useCommons from 'src/hooks/common/useCommons';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    optimizationsRootSelector,
    selectWaypointAction,
    setIsDeleteModalOpenAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { deleteOptimizationWaypoints, setServerOptimizationWaypoints } from '../api/optimizationApi';
import { WsResponse } from 'src/api/types';
import { IOptimizationWaypoint } from '../api/types';
import { useGetOptimizationWaypoints } from './useGetOptimizationWaypoints';

export const useDelOptimizationWaypoints = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const getOptimizationWaypointsFromServer = useGetOptimizationWaypoints();

    const {
        ui: { clientCode, isDeleteFromRow, deleteDataItem, waypointsSelectedIds },
        data: {
            getWaypointsFromServer: { timeDelta, data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();

    const handleDeleteOptimizationWaypointsResponse = async (res: WsResponse<IOptimizationWaypoint[]>) => {
        const responseNum = Number(res.response);

        if (responseNum === 0) {
            dispatchAlert('success', t('deleteWaypointsSuccess'));

            await getOptimizationWaypointsFromServer.getOptimizationWaypoints();
        } else if (responseNum === 1) {
            console.log('error');
        }
    };

    const onDeleteConfirmationModalConfirm = async () => {
        let wayPointsCodes = '';

        if (isDeleteFromRow && deleteDataItem) {
            wayPointsCodes = deleteDataItem.waypointId.toString();
            dispatch(selectWaypointAction({ waypointId: deleteDataItem.waypointId }));
        } else {
            waypointsSelectedIds.forEach((selectedId) => {
                dispatch(selectWaypointAction({ waypointId: selectedId }));
            });
            wayPointsCodes = waypointsSelectedIds.join(',');
        }

        try {
            if (selectedFcAccount && selectedFcAccount.dbUrl) {
                const res = await deleteOptimizationWaypoints({
                    dbUrl: selectedFcAccount?.dbUrl,
                    requestParams: {
                        token,
                        codes: wayPointsCodes,
                    },
                });

                if (res && res.data) {
                    handleDeleteOptimizationWaypointsResponse(res.data);
                } else {
                    dispatchAlert('error', t('errorReceived'));
                }
            }
        } catch (error) {
            console.log('error');
            dispatchAlert('error', t('errorReceived'));
        }

        dispatch(
            setIsDeleteModalOpenAction({
                isDeleteModalOpen: false,
                isDeleteFromRow: true,
                deleteDataItem: null,
            })
        );
    };

    return {
        onDeleteConfirmationModalConfirm,
    };
};
