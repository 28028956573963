import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getClientFilterProp,
    getProviderDropdownProp,
} from 'src/screens/RideSettings/components/props/props';
import useClientFiltering from 'src/hooks/useClientFiltering';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import styled, { css, CSSObject } from 'styled-components';
import styles from 'src/components/StyledComponents/StyledComponents.style';
import { headlineCss } from 'src/style/styledCSS';
import { msColors } from 'src/style/themes/defaultTheme';
import { Func } from 'src/types/global';
import { StrictDropdownItem } from 'src/types/line';
import DropDown from '../DropDown/DropDown';
import RefreshButton from '../RefreshButton/RefreshButton';
import DropDownV2 from '../DropDown/DropDownV2';
import { warningRed } from '../NavBar/NavBar.style';
import useEnvAuth from 'src/hooks/useEnvAuth';

export const HeaderContainer = styled.div<{ style?: CSSObject; withWarningColors: boolean }>`
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.6875em;
    padding-right: 1.6875em;
    // width: 100%;
    // height: 91px;
    // min-height: 91px;
    ${(props) => css(props.style || {})}
    ${({ withWarningColors }) =>
        withWarningColors
            ? css`
                  background-color: ${warningRed};
              `
            : ''}
`;

type Props = {
    setClientIdAction: Func | Function;
    onRefreshClick?: Func;
    routeScreenId: number;
    delay?: boolean;
    style?: CSSObject;
    isPrivateRoute?: boolean;
};

const HeaderWithDropdown = ({
    setClientIdAction,
    onRefreshClick,
    routeScreenId,
    delay,
    style,
    isPrivateRoute,
}: Props): JSX.Element => {
    const { onRefresh, providers, clinetFilter, setClinetFilter, onSetSelectedFcAccount } =
        useClientFiltering({ setClientIdAction }, routeScreenId, isPrivateRoute);

    // * Selectors
    const { fcAccounts, selectedFcAccount } = useSelector((state: IRootReducer) => loginSelector(state));

    const envAuth = useEnvAuth();

    return (
        <HeaderContainer
            withWarningColors={envAuth.isUsingProdAccountOnDev}
            style={style}
            className="header-container"
        >
            <div>
                <styles.TextContainer>
                    <styles.Text style={headlineCss} color={msColors.darkTextcolor}>
                        Studio
                    </styles.Text>
                    <styles.Text style={{ whiteSpace: 'break-spaces' }}> </styles.Text>
                    <styles.Text style={headlineCss} color={msColors.yellow}>
                        MyWay
                    </styles.Text>
                </styles.TextContainer>
            </div>
            <styles.LeftHeaderContainer gap="20px">
                <DropDownV2
                    {...getClientFilterProp({
                        clinetFilter,
                        setClinetFilter,
                    })}
                />
                <DropDownV2
                    {...getProviderDropdownProp({
                        providers,
                        selectedFcAccount,
                        fcAccounts,
                        onSetSelectedFcAccount,
                    })}
                />
                <RefreshButton disabled={delay} onClick={onRefreshClick || onRefresh} />
            </styles.LeftHeaderContainer>
        </HeaderContainer>
    );
};

export default HeaderWithDropdown;
