import moment from 'moment';
import { UI_TIME } from 'src/screens/ManualOrder/utilis';
import { TIME_FORMATS } from '../constants/dates';

const isValidTimeStr = (time: string) => {
    return time.length === 5 && time.includes(':');
};

/**
 * Takes in a string of time and returns a Date object with the time set.
 * @param {string} time - the time to convert to a Date object.
 * @returns {Date} - the Date object with the time set.
 */
const timeToDate = (time: string): Date => {
    if (!isValidTimeStr(time)) {
        console.log('error - {TimeToDate} bad time: ', +'' + time);
    }

    const [hour = '0', minute = '0'] = time.trim().split(':');

    const date = new Date();
    date.setHours(+hour);
    date.setMinutes(+minute);
    return date;
};

const getDateTime = (date: Date): string => {
    return moment(date).format(TIME_FORMATS.TWENTY_FOUR);
};

const timeHelpers = {
    timeToDate,
    isValidTimeStr,
    getDateTime,
};

export default timeHelpers;
