import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    modefiedPassengerSelector,
    modifyingOrAddingPassengerUiSelecter,
} from 'src/store/selectores/passengersSelector';
import { IRootReducer } from 'src/store/reducers';
import { TileLayoutItem } from '@progress/kendo-react-layout';

import WidgetLayout from 'src/components/WidgetLayout/WidgetLayout';

import { StyledWidgetLayout } from 'src/components/WidgetLayout/WidgetLayout.style';
import { Slide } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { isCoursePickFormOpenSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import PassengerDetails from '../PassengerDetails/PassengerDetails';
import PassengerLines from '../PassengerLines/PassengerLines';
import PassengerForm from '../PassengerForm/PassengerForm';

const PassengerWidgetLayout: FunctionComponent<{}> = () => {
    // Selectors
    const modefiedPassenger = useSelector((state: IRootReducer) => modefiedPassengerSelector(state));

    const { modifyingPassenger, addingPassenger } = useSelector((state: IRootReducer) =>
        modifyingOrAddingPassengerUiSelecter(state)
    );

    // Local states
    const tiles: Array<TileLayoutItem> = [
        {
            item: <PassengerDetails />,
            resizable: 'vertical',
        },
        {
            item: <PassengerLines />,
            resizable: 'vertical',
        },
    ];

    return (
        /*
         if modifying or adding passenger and "modifyiedPassenger" object is not undefined:
            we render the form.
         else we render the widget
      */
        <>
            {(modifyingPassenger || addingPassenger) && modefiedPassenger !== undefined ? (
                <Slide direction="up" in unmountOnExit>
                    <StyledWidgetLayout>
                        <PassengerForm
                            passanger={modefiedPassenger}
                            modifyingPassenger={modifyingPassenger}
                        />
                    </StyledWidgetLayout>
                </Slide>
            ) : (
                <WidgetLayout tiles={tiles} />
            )}
        </>
    );
};

export default PassengerWidgetLayout;
