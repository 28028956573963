import { useTranslation } from 'react-i18next';
import React from 'react';
import { FormControl, InputAdornment, InputLabel, TextField } from '@material-ui/core';
import MaterialOutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { IObject } from 'src/types/global';

type Props = { onChange: (e: any) => void; style?: IObject };

const SearchInput: React.FunctionComponent<Props> = ({ onChange, style = {} }) => {
    const { t } = useTranslation();
    return (
        <div style={{ width: '100%', ...style }}>
            <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-free-search">{t('search')}</InputLabel>
                <MaterialOutlinedInput
                    fullWidth
                    style={{ width: '100%', backgroundColor: 'white' }}
                    id="outlined-free-search"
                    type="text"
                    onChange={onChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    labelWidth={45}
                />
            </FormControl>
        </div>
    );
};

export default SearchInput;
