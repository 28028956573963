import { IAddress, IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import { CourseListForm } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { isPassengerValid } from '../../utils';
import { RawInputAddress, SecondaryIdentifier } from './AddressForm/types';
import { stringifyAddress } from './AddressForm/utils';

export const INITIAL_ADDRESS_OBJECT = {
    city: '',
    street: '',
    houseNum: '',
    remarks: '',
};
export const getInitialAddressData = (secondaryIdentifier: SecondaryIdentifier): RawInputAddress => {
    return {
        ...INITIAL_ADDRESS_OBJECT,
        secondaryIdentifier,
    };
};

export const getInitialAddressState = (
    address: IAddress | undefined,
    secondaryIdentifier: SecondaryIdentifier
): RawInputAddress => {
    // console.log(address);
    if (!address) return getInitialAddressData(secondaryIdentifier);
    const { city, street, houseNum, remarks, addressCode } = address;
    // if (secondaryIdentifier === '0') console.log({ address });
    const a: RawInputAddress = {
        ...address,
        addressCode,
        city: city || '',
        street: street || '',
        houseNum: houseNum || '',
        remarks: remarks || '',
        secondaryIdentifier,
    };
    // console.log({ a });
    return a;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dirtyUp = (
    modefiedPassenger: IModefiedPassenger,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setModifiedPassenger: any
) => {
    setModifiedPassenger({ ...modefiedPassenger, isDirty: true });
};

export const getInitialInputsCount = (
    addingPassenger: boolean,
    addresses: string | any[],
    chosenCoursesCount: number
): number => {
    if (chosenCoursesCount > 0) return chosenCoursesCount - 1;
    if (addingPassenger || !addresses.length) return 0;

    const count = addresses ? addresses.length - 1 : 0;
    return count;
};

export const findAddress = (
    type: 'main' | 'secondaryOne' | 'secondaryTwo',
    modefiedPassenger: any
): IAddress | undefined => {
    let adrs;
    let res;
    switch (type) {
        case 'main':
            adrs = modefiedPassenger.address.find(
                (address: { isDefault: string }) => address.isDefault === '1'
            );
            res = {
                remarks: adrs?.remarks ? adrs.remarks : '',
                ...adrs,
            };
            // console.log({ res });
            return res;
        case 'secondaryOne':
            adrs = modefiedPassenger.address.find(
                (address: { isDefault: string }) => address.isDefault === '0'
            );
            return {
                remarks: adrs?.remarks ? adrs.remarks : '',
                ...adrs,
            };
        default:
            // eslint-disable-next-line prefer-destructuring
            adrs = modefiedPassenger.address.filter(
                (address: { isDefault: string }) => address.isDefault === '0'
            )[1];
            return {
                remarks: adrs?.remarks ? adrs.remarks : '',
                ...adrs,
            };
    }
};

export const isValidForm = (
    modefiedPassenger: IModefiedPassenger,
    formState: any,
    coursesFormData: CourseListForm
): boolean => {
    if (
        isPassengerValid(modefiedPassenger, formState.myTouched, coursesFormData.coursesSelectedChanged) &&
        formState.hasValidAddress
    ) {
        if (!modefiedPassenger.lastName || !modefiedPassenger.firstName || !modefiedPassenger.passId)
            return false;
        if (
            coursesFormData.coursesSelectedChanged ||
            formState.touchedAddress ||
            formState.myTouched ||
            modefiedPassenger.isDirty
        ) {
            return true;
        }
        return false;
    }
    return false;
};
