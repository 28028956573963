import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Ver2AddressInputProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/ManualAndGoogleAddressInputVer2';
import { AddressKeys, UpdateValueFunc } from 'src/components/AddressInputs/types';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { oldPassengerDataSelector } from 'src/store/slices/lines/linesSlice';
import { initialAddress } from '../../../constants';
import { AddressInput, PassengerInput, ExistingPassenger } from '../../../types';

export const isTypeExistingPassenger = (object: {} | ExistingPassenger): object is ExistingPassenger => {
    return 'firstName' in object && 'lastName' in object;
};

const ADDRESS_FIELDS: AddressKeys[] = ['city', 'street', 'houseNum', 'placeName'];

const usePreEditValues = () => {
    const { setValue } = useFormContext<PassengerInput>();
    const preEditValues = useRootAppSelector(oldPassengerDataSelector);

    useEffect(() => {
        if (isTypeExistingPassenger(preEditValues)) {
            setValue('firstName', preEditValues.firstName);
            setValue('lastName', preEditValues.lastName);
            setValue('phone', preEditValues.mobilePhone);
            setValue('passengerRemarks', preEditValues.remarks);
            setValue('city', preEditValues.city);
            setValue('street', preEditValues.street);
            setValue('houseNum', preEditValues.houseNum);
            setValue('internalCode', preEditValues.internalCode);
            setValue('email', preEditValues.email);
        }
    }, [preEditValues, setValue]);
};

export const useAddressHandling = (form: UseFormReturn<any>): [AddressInput, UpdateValueFunc] => {
    const { setValue, watch } = form;

    const updateValue = React.useCallback(
        (inputType, newValue) => {
            setValue(inputType, newValue, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [setValue]
    );

    const [watchCity, watchStreet, watchHouseNum, watchPlaceName] = watch(ADDRESS_FIELDS);

    const addressValues = useMemo(
        () => ({
            city: watchCity || '',
            street: watchStreet || '',
            houseNum: watchHouseNum || '',
            placeName: watchPlaceName || '',
        }),
        [watchCity, watchHouseNum, watchPlaceName, watchStreet]
    );

    useEffect(() => {
        form.trigger(ADDRESS_FIELDS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressValues, form.trigger]);
    return [addressValues, updateValue];
};

type GetAddressPropsFunc = () => Ver2AddressInputProps;

const useProps = (): { getAddressProps: GetAddressPropsFunc } => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const passengersForm = useFormContext<PassengerInput>();
    const preEditValues = useRootAppSelector(oldPassengerDataSelector);

    const hasPrevValues = useMemo(() => isTypeExistingPassenger(preEditValues), [preEditValues]);

    const {
        formState: { errors },
    } = passengersForm;

    const [, updateValue] = useAddressHandling(passengersForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFullAddressData = useCallback(
        (newAddress: AddressInput) => {
            updateValue('city', newAddress.city);
            updateValue('street', newAddress.street || '');
            updateValue('houseNum', newAddress.houseNum || '');
            updateValue('placeName', newAddress.placeName || '');
        },
        [updateValue]
    );

    // if city street or houseNum are not valid, we return an error msg
    const errorMessage = useMemo(() => {
        if (errors.city || errors.street || errors.houseNum) return 'כתובת אינה תקינה';

        return '';
    }, [errors.city, errors.houseNum, errors.street]);

    const getAddressProps = useCallback((): Ver2AddressInputProps => {
        return {
            defaultType: hasPrevValues ? 'manual' : 'autocomplete',
            autocompleteConfig: {
                props: {
                    setFullAddressData,
                    label: t('address'),
                },
                errorMessage,
            },
            manualConfig: {
                props: {
                    formMethods: passengersForm as any,
                    onClickExit: () => {
                        setFullAddressData(initialAddress);
                    },
                },
            },
        };
    }, [errorMessage, hasPrevValues, passengersForm, setFullAddressData, t]);
    return { getAddressProps };
};

export const newPassengerFormHooks = {
    useProps,
    useAddressHandling,
    usePreEditValues,
};
