import { MenuItem, Tab, FormControl, InputLabel } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultMenuProps } from 'src/components/DropDown/DropDown';
import { IProgressData } from '../../../types';
import ProgressbarList from './ProgressbarList';
import * as Styles from './Summarycard.Style';
import useSummaryDepartment from './useSummaryDepatment';

interface Statistics {
    data: IProgressData[];
    max: number;
    relativeDate: string;
}

interface Props {
    date: Date;
    clientCode: number;
    stats: {
        daylyStatistics: Statistics;
        weeklyStatistics: Statistics;
        monthlyStatistics: Statistics;
    };
    setOrderBy: Function;
    orderBy: number;
}

const filters = [
    {
        value: 'מחלקות',
        id: 1,
    },
    {
        value: 'משמרות',
        id: 2,
    },
];

const SummaryCard: FC<Props> = ({ stats, setOrderBy, orderBy }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrderBy(event.target.value as number);
    };

    return (
        <Styles.Container>
            <FormControl variant="outlined">
                <InputLabel variant="outlined" id="summaryOrderBy-label">
                    {t('summaryOrderBy')}
                </InputLabel>

                <Styles.Dropdown
                    id="summaryOrderBy-label"
                    defaultValue={1}
                    label={t('summaryOrderBy')}
                    value={orderBy}
                    onChange={handleChangeSelect}
                    MenuProps={DefaultMenuProps}
                >
                    {filters.map((d) => (
                        <MenuItem value={d.id}>{d.value}</MenuItem>
                    ))}
                </Styles.Dropdown>
            </FormControl>
            <Styles.StyledTabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label={t('daily')} />
                <Tab label={t('weekly')} />
                <Tab label={t('monthly')} />
            </Styles.StyledTabs>
            <Styles.ProgressContainer>
                {selectedTab === 0 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? 'depts' : 'shifts'}
                        data={stats.daylyStatistics.data}
                        max={stats.daylyStatistics.max}
                        releativeDate={stats.daylyStatistics.relativeDate}
                    />
                )}
                {selectedTab === 1 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? 'depts' : 'shifts'}
                        data={stats.weeklyStatistics.data}
                        max={stats.weeklyStatistics.max}
                        releativeDate={stats.weeklyStatistics.relativeDate}
                    />
                )}
                {selectedTab === 2 && (
                    <ProgressbarList
                        dataType={orderBy === 1 ? 'depts' : 'shifts'}
                        data={stats.monthlyStatistics.data}
                        max={stats.monthlyStatistics.max}
                        releativeDate={stats.monthlyStatistics.relativeDate}
                    />
                )}
            </Styles.ProgressContainer>
        </Styles.Container>
    );
};

export default SummaryCard;
