import styled, { css, CSSProperties } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { breakPoints, widgetsSidebarConfig } from 'src/style/themes/defaultTheme';
import { warningRed } from 'src/components/NavBar/NavBar.style';

const HeaderText = styled.span`
    font-size: 1.7rem;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const WidgetContainer = styled.div`
    min-width: 650px;
    box-shadow: inset 6px 2px 20px rgba(0, 0, 0, 0.4);
    max-width: 650px;
    height: 100%;
    display: flex;
    background-color: ${({ theme }) => theme.colors.lightYellow};
    padding: 0 31px;
    @media ${breakPoints.laptopM} {
        min-width: ${widgetsSidebarConfig.mediumWidth};
        max-width: ${widgetsSidebarConfig.mediumWidth};
    }
    @media ${breakPoints.laptopS} {
        min-width: ${widgetsSidebarConfig.smallWidth};
        max-width: ${widgetsSidebarConfig.smallWidth};
    }
`;

const MainContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const LinesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const GridContainer = styled(Grid)`
    /* height: 100%; */
    width: 100%;
    .manual-orders-filters {
        background-color: cyan;
    }
`;

const GridItem = styled(Grid)`
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    height: auto;
`;

const LayotGridItem = styled(Grid)`
    height: 100%;
    background: #ffedb1;
    box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.25);
`;
const TableContainer = styled('div')<{ isFormOpen: boolean }>`
    height: 100%;
    width: 100%;
    // margin-top: 20px;
    ${(props) =>
        props.isFormOpen &&
        `
      * {
         // cursor: not-allowed;
      }
   `}
`;

const Hr = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1.6875em;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    width: 100%;
    background-color: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    .MuiToggleButton-root {
        font-size: 16px;
        line-height: 18.96px;
        border: none;
        color: black;
    }
    .MuiToggleButton-root.Mui-selected {
        font-weight: 700;
        color: #2196f3;
        background-color: white;
        border-bottom: 3px solid #2196f3;
    }
`;

const LayoutGridItem = styled.div`
    height: 100%;
    overflow-y: auto;
    min-width: 650px;
    max-width: 650px;
    background: #ffedb1;
    box-shadow: inset 6px 2px 20px rgba(0, 0, 0, 0.4);
    padding: 0 31px;
    @media ${breakPoints.laptopM} {
        min-width: ${widgetsSidebarConfig.mediumWidth};
        max-width: ${widgetsSidebarConfig.mediumWidth};
    }
    @media ${breakPoints.laptopS} {
        min-width: ${widgetsSidebarConfig.smallWidth};
        max-width: ${widgetsSidebarConfig.smallWidth};
    }
`;

export const ManualOrdersFiltersContainer = styled.div`
    width: 100%;
    padding: 8px 1.6875em 0 1.6875em;
`;

export const ManualOrdersHeaderContainer = styled.div<{
    withWarningColors: boolean;
}>`
    display: flex;
    justify-content: space-between;
    padding: 12px 1.6875em 12px 1.6875em;
    width: 100%;
    ${({ withWarningColors }) =>
        withWarningColors
            ? css`
                  background-color: ${warningRed};
              `
            : ''}
`;

const FilterContainer = styled.div<{ style?: CSSProperties }>`
    display: flex;
    // padding: 0 0 0 1.12em;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
`;
const FilterItem = styled.div<{ style?: CSSProperties; flex?: number }>`
    ${(props) => css`
        flex: ${props.flex || 1};
    `}
`;

export default {
    Container,
    FilterContainer,
    FilterItem,
    GridContainer,
    GridItem,
    LinesContainer,
    LayotGridItem,
    HeaderText,
    WidgetContainer,
    MainContainer,
    TableContainer,
    Hr,
    Row,
    StyledToggleButtonGroup,
    LayoutGridItem,
    ManualOrdersFiltersContainer,
    ManualOrdersHeaderContainer,
};
