/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import React, { CSSProperties, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import { Tabs, TabsProps } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { StyledTabPanel, StyledDetailsProperty, StyledPropTitle, StyledTabsContainer } from './.styles';
import { mediumRubik } from '../../../../../style/styledCSS';
import { stringifyAddress } from '../../PassengerForm/Components/AddressesBox/AddressForm/utils';

interface Props {
    selectedPassenger: IPassenger | any;
}

const MoreInfoPropertyBox: React.FC<{ title: string; value: string }> = ({ title, value }) => {
    return (
        <StyledDetailsProperty>
            <StyledPropTitle>{title} </StyledPropTitle>
            <p style={{ margin: 0, fontSize: '16px' }}>{value}</p>
        </StyledDetailsProperty>
    );
};

const MoreCommunicationInfoTabPanel: React.FC<Props> = ({ selectedPassenger }) => {
    const { t } = useTranslation();

    const detailsCount = (() => {
        let count = 0;
        if (selectedPassenger.phone1) count++;
        if (selectedPassenger.contactName) count++;
        if (selectedPassenger.contactPhone1) count++;
        return count;
    })();

    return (
        <StyledTabPanel count={detailsCount}>
            {selectedPassenger.phone1 && (
                <MoreInfoPropertyBox
                    title={t('anotherPhoneNotCell')}
                    value={selectedPassenger.phone1 || t('notAssigned')}
                />
            )}
            {selectedPassenger.contactName && (
                <MoreInfoPropertyBox
                    title={t('contact')}
                    value={selectedPassenger.contactName || t('notAssigned')}
                />
            )}
            {selectedPassenger.contactPhone1 && (
                <MoreInfoPropertyBox
                    title={t('contactPhone')}
                    value={selectedPassenger.contactPhone1 || t('notAssigned')}
                />
            )}
            {!detailsCount && <p style={{ paddingTop: '10px' }}>{t('passengerHasNoMoreContactDetails')}</p>}
        </StyledTabPanel>
    );
};

// & Addresses components
// * Addresses property box
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MoreAddressPropertyBox: FC<{ address: string }> = ({ address }) => {
    const { t } = useTranslation();
    return (
        <StyledDetailsProperty>
            <StyledPropTitle>{t('address')}: </StyledPropTitle>
            <p style={{ margin: 0 }}>{address}</p>
        </StyledDetailsProperty>
    );
};

// * Addresses Panel
export const AddressesTabPanel: FC<Props> = ({ selectedPassenger }) => {
    const { t } = useTranslation();
    const createAddressesBox = () => {
        return selectedPassenger.address.map((adrs: any, i: number) => {
            if (adrs.isDefault === '1') return null;
            return <MoreInfoPropertyBox title={t('address')} value={stringifyAddress(adrs)} />;
        });
    };

    return (
        <StyledTabPanel count={selectedPassenger.address.length - 1}>
            <div style={{ paddingTop: '10px' }}>
                {selectedPassenger.address.length > 1 ? createAddressesBox() : <p>לא הוגדו כתובות נוספות</p>}
            </div>
        </StyledTabPanel>
    );
};

const tabIndicatorProps = {
    style: {
        backgroundColor: '#333',
    },
};
// * Main part
const ts = {
    minHeight: 30,
    padding: '3px 0 0 0',
    '& .Mui-selected': { color: '#424242' },
};
const MyTab = styled(Tab)(ts);
const MyTabs = styled(Tabs)(ts);
export const DetailsTabs: FC<Props> = ({ selectedPassenger }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const handleChange = (e: React.ChangeEvent<{}>, newValue: any) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <StyledTabsContainer>
                <MyTabs
                    TabIndicatorProps={tabIndicatorProps}
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={(e, val) => handleChange(e, val)}
                    centered
                >
                    <MyTab label={t('moreContactInfo')} />
                    <MyTab label={t('moreAddresses')} />
                </MyTabs>
            </StyledTabsContainer>
            {selectedTab === 0 && <MoreCommunicationInfoTabPanel selectedPassenger={selectedPassenger} />}
            {selectedTab === 1 && <AddressesTabPanel selectedPassenger={selectedPassenger} />}
        </>
    );
};

const ExtraDetailsDisplay: React.FC<Props> = ({ selectedPassenger }) => {
    return (
        <div>
            <DetailsTabs selectedPassenger={selectedPassenger} />
        </div>
    );
};

export default ExtraDetailsDisplay;
