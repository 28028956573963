import { useTranslation } from 'react-i18next';
import { useRootAppSelector } from 'src/store/hooks';
import { courseBuildingFormSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';

const useFormHeader = () => {
    const { t } = useTranslation();

    const isEditForm = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form.isEditForm);

    const mainText = isEditForm ? t('editWaypoint') : t('addWaypoints');

    return {
        mainText,
    };
};

export default useFormHeader;
