import './ManualOrder.css';

import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from 'src/api/api';
import { getDepartments, getPassngerShifts } from 'src/api/manualOrderApi/manualOrderApi';
import { FcResponseState } from 'src/api/types';
import Input, { InputProp } from 'src/components/commons/Input/Input';
import { IDatePickerProps } from 'src/components/DatePicker/DatePicker';
import MyWayTitle from 'src/components/MyWayTitle/MyWayTitle';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import GlobalStyle from 'src/components/StyledComponents/StyledComponents.style';
import WeekDataPicker, { IWeekDatePicker } from 'src/components/WeekDatePicker/WeekDatePicker';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import {
    onFormShowChange,
    onRetrivePassengerSuccess,
    onRetriveShifts,
    selectTableWeek,
    setEditShift,
    setSelectedAllPassengers,
    setSelectedSingleShift,
} from 'src/store/actions/PassengersShiftActionType';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    editShiftSelector,
    passengerSelector,
    selectedSingleShiftSelector,
    shiftsSelector,
    tableDatesSelector,
} from 'src/store/selectores/passengerShiftsSelectores';
import { DateTypeEnum, IDateRange } from 'src/types/global';
import { IClient, IDepartment, IItem, StrictDropdownItem } from 'src/types/line';
import { IAccount, RefreshTokenStatus } from 'src/types/login';
import {
    dateify,
    getWeekStartAndEndDates,
    isAuthorized,
    isSameDate,
    removeDuplicates,
    stringifyDate,
} from 'src/utilis/utilis';

import { InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { SHORT } from 'src/constants/dates';
import useDisableDelay from 'src/hooks/useDelayV2';
import { containers } from 'src/Layouts/styles';
import {
    apiCallStatusesSelector,
    buildPassengersDictAction,
    displayPlacementFormAction,
    displayPlacementFormSelector,
    resetStateAction,
    screenClientCodeSelector,
    selectedPassengersSelector,
    setApiCallStatusAction,
    setClientsAction,
    setScreenClientCodeAction,
    setScreenProviderAccountCodeAction,
    setSelectedPassengersAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import Alert from 'src/components/commons/Alert/Alert';
import { MessagePort } from 'worker_threads';
import { useAlertV2 } from 'src/hooks/useAlert';
import manualOrdersBL from 'src/api/dataMappers/manualOrdersBl';
import { ApiCallTypes } from 'src/store/slices/manualOrders/types';
import { ApiCallStatus } from 'src/types/rideSettings/store/types.store';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import ComboBox, { IComboBox } from 'src/components/ComboBox/ComboBox';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Spinner from 'src/components/Spinner/Spinner';
import useLastSelectedClient from 'src/hooks/useLastSelectedClient';
import { PASSENGER_ID_INDEX } from 'src/store/slices/manualOrders/helpers';
import _ from 'lodash';
import { IDateRangeNew } from 'src/store/reducers/passengersShiftReudcer';
import DatePickerWithPrevNext from '../../components/DatePicker/DatePickerWithPrevNext';
import DropDown, { IProps as IDropDownProp } from '../../components/DropDown/DropDown';
import { setErrorMessage, setFcAccount, setTokenRefreshStatus } from '../../store/actions/loginAction';
import { DAYS_FOR_ORDERS_RANGE } from './components/PlacemenTransportation/hooks/useOrderDaysData';
import { onChangedShiftSelection } from '../../store/actions/PassengersShiftActionType';
import Orderinfo from './components/Orderinfo/Orderinfo';
import PlacemenProvider from './components/PlacemenTransportation/context';
import PlacemenTransportation from './components/PlacemenTransportation/PlacemenTransportation';
import useDateFilter from './hook/useDateFilter';
import styles from './ManualOrder.style';
import ManualOrderWrapper from './ManualOrderWrapper/ManualOrderWrapper';
import {
    getDateRanges,
    getDatesWithCountForPassenger,
    getSelectedDaysRange,
    handleSelectPassengers,
} from './utilis';
import { IPassengerShifts } from '../../types/manualOrders/api.types';
import manualOrdersHooks from './hook/hooks.manualOrdersTable';
import { manualOrdersCommonHooks } from './hook/manualOrdersHooks.common';
import { ExportToExcelBtn } from '../Reports/components/ReportsSection/Menu/ActionButtons/ActionButtons';
import { useExport } from './hook/hooks.exports';
import ExportedKendoTable from './components/ExportedKendoTable/ExportedKendoTable.index';
import { buttonSx, MenuButton } from '../Reports/components/ReportsSection/Menu/Menu.style';
import ExportBtns, { blocked } from './components/ExportsBtns';
import useEnvAuth from 'src/hooks/useEnvAuth';
import { useValidateClientList } from 'src/hooks/useClientFiltering';

const THREE_SECONDS = 3000;

// const getFormattedDateWithAddedDays = (startDate: moment.MomentInput, daysToAdd = 0) => {
//     return moment(startDate).add(daysToAdd, 'days').endOf('week').format('yyyy-MM-DD');
// };

export const ManualOrder: FC<{}> = () => {
    // console.log('ManualOrder');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();

    manualOrdersHooks.usePassengersDates();
    manualOrdersCommonHooks.useFormOpenStateSync();
    const {} = manualOrdersCommonHooks.usePlacementFormUtils();

    const {
        fcAccounts,
        selectedFcAccount,
        token,
        authorizationToken = {},
    } = useSelector((state: IRootReducer) => loginSelector(state));
    const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));
    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;
    const selectedEditShift = useSelector((state: IRootReducer) => editShiftSelector(state));
    const displayPlacementForm = useRootAppSelector(displayPlacementFormSelector);
    const isFormOpen: boolean = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form.isOpen
    );
    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);
    const tableDatesOnStore = useSelector(tableDatesSelector);

    const [isSwitchingClient, setIsSwitchingClient] = manualOrdersCommonHooks.useSwitchingLocation();

    const [clinets, setClinets] = useState<StrictDropdownItem[]>([]);
    const [lastSelectedClient, setLastSelectedClient] = useLastSelectedClient(clinets);

    // -- update provider code in the store
    useEffect(() => {
        if (selectedFcAccount?.accountCode) {
            setLastSelectedClient(null);
            setIsSwitchingClient(true);
            tkDispatch(
                setScreenProviderAccountCodeAction({
                    accountCode: String(selectedFcAccount.accountCode),
                    withStateReset: true,
                })
            );
            // tkDispatch(resetStateAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFcAccount?.accountCode, tkDispatch]);

    useEffect(() => {
        // console.log(11);
        // const updatedSelectedPassengers: [string][] = [];
        // // const PASSENGER_DATA_INDEX = 0;
        // allPassengers.forEach((p, i) => {
        //     if (p.isSelected) {
        //         updatedSelectedPassengers.push([p.passId, i]);
        //     }
        // });
        // tkDispatch(setSelectedPassengersAction(updatedSelectedPassengers));
    }, [allPassengers, tkDispatch]);

    const dispatchFormClosingActions = useCallback(() => {
        dispatch(onFormShowChange(false));
        tkDispatch(displayPlacementFormAction(false));
    }, [dispatch, tkDispatch]);

    const onSetCompanySelected = (payload: IAccount) => {
        dispatchFormClosingActions();
        dispatch(setFcAccount(payload));
    };

    const validateClientsList = useValidateClientList();

    const [providers, setProviders] = useState<IItem[]>([]);

    const [departments, setDepartments] = useState<IDepartment[]>([]);

    const [selectedClinet, setSelectedClinet] = useState<StrictDropdownItem>(
        lastSelectedClient || {
            value: '',
            name: '',
        }
    );

    // * sets the selected client code on manual orders  slice AND local storage ----------
    useEffect(() => {
        // console.log(selectedClinet);
        if (selectedClinet.value && lastSelectedClient?.value !== selectedClinet.value) {
            setIsSwitchingClient(true);
            tkDispatch(
                setScreenClientCodeAction({ clientCode: String(selectedClinet.value), withStateReset: false })
            );
            setLastSelectedClient(selectedClinet);
        }
    }, [
        lastSelectedClient,
        selectedClinet,
        selectedClinet.value,
        setIsSwitchingClient,
        setLastSelectedClient,
        tkDispatch,
    ]);
    // * ----------------------------------------------------------------

    // * Closes form if user changed client ------------------------
    useEffect(() => {
        if (selectedClinet.value) {
            dispatchFormClosingActions();
        }
    }, [dispatch, dispatchFormClosingActions, selectedClinet.value]);
    // * ------------------------------------------------

    const [manuallyType, setManuallyType] = useState<DateTypeEnum>(DateTypeEnum.weekly);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const [selectedWeek, setSelectedWeek] = manualOrdersCommonHooks.useFirstDayOfWeekSetting(); // console.log({ selectedWeek });

    useEffect(() => {
        // console.log(14);
        dispatch(
            selectTableWeek({
                dateRange: {
                    startingDate: moment(selectedWeek.startDate).format(SHORT),
                    endingDate: moment(selectedWeek.endDate).format(SHORT),
                },
            })
        );
    }, [dispatch, selectedWeek.endDate, selectedWeek.startDate]);

    const [selectedDepartment, setSelectedDepartment] = useState(loggedUserDepartmentCode || -1);

    // const { getDataForApiReqPayload: getDataForStatisticsApiReqPayload } = manualOrdersCommonHooks.useDepartmentFilterDataForStatisticsWs();

    const [globalFilter, setGlobalFilter] = useState('');

    const dateFilter = useDateFilter({
        weeklyDate: selectedWeek,
        dayilyDate: selectedDate,
        dateType: manuallyType,
    });

    const onChangeManuallyType = useCallback((event: React.MouseEvent<HTMLElement>, sortKey: string) => {
        if (+sortKey === DateTypeEnum.daily) setManuallyType(DateTypeEnum.daily);

        if (+sortKey === DateTypeEnum.weekly) setManuallyType(DateTypeEnum.weekly);
    }, []);

    useEffect(() => {
        // console.log(15);
        const res: IItem[] = fcAccounts.map(({ accountCode, accountName }: IAccount) => ({
            value: accountCode,
            name: accountName,
        }));

        setProviders(res);
    }, [fcAccounts]);

    useEffect(() => {
        // console.log(16);
        const { proxyUrl, dbUrl } = selectedFcAccount || {};
        if (dbUrl && selectedClinet.value) {
            getDepartments({
                dbUrl,
                clientCode: Number(selectedClinet.value),
                token,
            }).then((r) => {
                setDepartments(r.data.departments);
            });
        }
    }, [selectedFcAccount, token, selectedClinet]);

    useEffect(() => {
        // console.log(17);
        const { proxyUrl } = selectedFcAccount || {};

        if (!selectedFcAccount) return;

        getClients({
            proxyUrl,
            token,
            dbUrl: selectedFcAccount.dbUrl,
        }).then((res) => {
            const { clients = [] } = res.data;
            const clientsList = validateClientsList(clients);

            tkDispatch(setClientsAction({ clients: clientsList }));

            const mapedClients: StrictDropdownItem[] = clientsList
                .filter((x) =>
                    isAuthorized(routesEndpoints.MANUAL_ORDER.SCREEN_ID, +x.accountCode, authorizationToken)
                )
                .map(({ accountCode, clientName }: IClient) => ({
                    value: String(accountCode),
                    name: clientName,
                }));

            setClinets(mapedClients);
        });
    }, [selectedFcAccount, token, authorizationToken, tkDispatch]);

    useEffect(() => {
        // console.log(18);
        if (
            clinets?.length && // -- fetched clients
            lastSelectedClient?.value && // -- have last selected client data on LS
            clinets?.some((client) => client.value === lastSelectedClient.value) // -- last selected client is a client of...
            // --... the provider
        ) {
            setSelectedClinet(lastSelectedClient);
            return;
        }
        if (clinets?.length) {
            setSelectedClinet(clinets[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinets]);

    // * Handling refresh button click
    const [lastRefreshTime, setLastRefreshTime] = useState(new Date().getTime());

    const refreshState = useCallback(
        (currTimeInSeconds: React.SetStateAction<number>) => setLastRefreshTime(currTimeInSeconds),
        []
    );

    const [tempDisabled, setTempDisabled] = useDisableDelay(3000);

    const handleRefresh = useCallback(() => {
        setTempDisabled(true);
        const currTimeInSeconds = new Date().getTime();
        const diffSinceLastRefresh = currTimeInSeconds - lastRefreshTime;
        if (diffSinceLastRefresh > THREE_SECONDS) refreshState(currTimeInSeconds);
    }, [lastRefreshTime, refreshState, setTempDisabled]);

    // *
    useEffect(() => {
        // console.log(19);
        const { proxyUrl, dbUrl } = selectedFcAccount || {};
        if (
            !selectedFcAccount ||
            !selectedClinet?.value ||
            !dbUrl ||
            !selectedClinet.value ||
            !dateFilter.startDate ||
            !dateFilter.endDate
        )
            return;

        const passengersReqDateRange = getDateRanges(dateFilter.startDate, dateFilter.endDate);
        const shiftReqDateRange = getDateRanges(
            dateFilter.startDate,
            dateFilter.endDate,
            DAYS_FOR_ORDERS_RANGE.PLACEMENT_WIDGET
        );

        dispatch(
            onRetriveShifts({
                proxyUrl,
                dbUrl,
                token,
                fromDate: shiftReqDateRange.fromDate,
                toDate: shiftReqDateRange.toDate,
                clientCode: Number(selectedClinet.value),
            })
        );

        const dispatchStatusUpdateAction = (type: ApiCallTypes, newStatus: ApiCallStatus) => {
            tkDispatch(
                setApiCallStatusAction({
                    type,
                    newStatus,
                })
            );
        };

        dispatchStatusUpdateAction('tableGetPassengerShifts', 'LOADING');

        getPassngerShifts({
            proxyUrl,
            dbUrl,
            token,
            fromDate: passengersReqDateRange.fromDate,
            toDate: passengersReqDateRange.toDate,
            clientCode: +selectedClinet.value,
            passengers: '',
        }).then((res) => {
            // -- done in case response comes as text instead of json for some reason
            if (typeof res.data === 'string') {
                console.log('FATAL ERROR - res data was received as string');
                dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
                dispatchStatusUpdateAction('tableGetPassengerShifts', 'FAILED');
                return;
            }

            const { passengers = [], response } = res.data;

            if (response === FcResponseState.TokenExpired) {
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Invalid));
            }

            const processedPassengers = manualOrdersBL.processPassengerForDates(
                passengers,
                passengersReqDateRange
            );

            const passengersWithIsSelected = [];
            const passengerOrderDates = {};

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < processedPassengers.length; i++) {
                const currPassenger = processedPassengers[i];
                passengersWithIsSelected.push({
                    ...currPassenger,
                    isSelected: selectedPassengers.find(
                        (p) => p[PASSENGER_ID_INDEX] === currPassenger.passId
                    ),
                });
                passengerOrderDates[currPassenger.passId] = getDatesWithCountForPassenger(currPassenger);
            }

            dispatch(
                onRetrivePassengerSuccess({
                    passengers: passengersWithIsSelected,
                    passengerOrderDates,
                })
            );

            tkDispatch(buildPassengersDictAction({ passengers: processedPassengers }));
            dispatchStatusUpdateAction('tableGetPassengerShifts', 'SUCCESS');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClinet, selectedFcAccount, token, dateFilter, dispatch, lastRefreshTime]);

    const { selectedCity, cityFilterProp } = manualOrdersCommonHooks.useCitiesFilter();

    const filterPassengersShift = useMemo(() => {
        return (function () {
            if (selectedDepartment === -1) return allPassengers;

            return allPassengers.filter((p) => String(p.departmentCode) === String(selectedDepartment));
        })()
            .filter((p) => {
                // console.log(p.fullName, p.passId, { globalFilter });
                return p.fullName?.includes(globalFilter) || p.internalPassCode?.includes(globalFilter);
            })
            .filter((p) => (selectedCity ? p.city === selectedCity : true));
    }, [selectedDepartment, allPassengers, globalFilter, selectedCity]);

    const handleClosePlacement = useCallback(() => {
        dispatch(
            setSelectedAllPassengers({
                isSelected: false,
                passengers: handleSelectPassengers(allPassengers, false),
            })
        );
        dispatch(setSelectedSingleShift(null));
        dispatch(setEditShift(null));
        refreshState(new Date().getTime());
    }, [allPassengers, dispatch, refreshState]);

    const getWidget = useCallback((): JSX.Element => {
        if (displayPlacementForm && isFormOpen)
            return (
                <PlacemenTransportation
                    handleClose={handleClosePlacement}
                    date={selectedEditShift?.date || new Date()}
                    editShift={selectedEditShift || undefined}
                    selectedClinet={selectedClinet}
                />
            );

        return (
            <Orderinfo
                clientCode={Number(selectedClinet?.value)}
                selectedDayOnTable={selectedDate}
                manuallyType={manuallyType}
                selectedDepartmentCode={String(selectedDepartment)}
                allDepartmentsCodes={departments.map((d) => String(d.code))}
            />
        );
    }, [
        displayPlacementForm,
        handleClosePlacement,
        isFormOpen,
        manuallyType,
        selectedClinet,
        selectedDate,
        selectedDepartment,
        selectedEditShift,
    ]);

    // * Props
    const prodvierDrpodown: IDropDownProp = {
        formControlProp: {
            variant: 'outlined',
            style: { width: 208, marginRight: '5px' },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        labalName: t('provider'),
        label: t('provider'),
        menueItem: providers,

        native: false,
        value: selectedFcAccount?.accountCode,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => {
            const { value } = event.target;
            const fcAccountTarget = fcAccounts.find((f) => f.accountCode === value);
            if (fcAccountTarget) {
                onSetCompanySelected(fcAccountTarget);
            }
        },
    };

    const clinetFilterProp: IDropDownProp = {
        formControlProp: {
            variant: 'outlined',
            style: { width: 208 },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        menueItem: clinets,
        labalName: t('clinet'),
        label: t('clinet'),

        native: false,
        value: selectedClinet?.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | any;
            }>
        ) => {
            const { value } = event.target;

            const client = clinets?.find((x) => x.value === value) || {
                value: '',
                name: '',
            };

            if (typeof value === 'number' || typeof value === 'string') {
                setSelectedClinet(client);
            }
        },
    };

    const { firstDayOfWeek } = manualOrdersCommonHooks.useFirstDayOfWeekSelectors();

    const weekPickerProp: IWeekDatePicker = {
        // handleSetSelectedWeek,
        prop: {
            inputVariant: 'outlined',
            style: { width: '100%' },
            invalidDateMessage: 'תאריך תחילת שבוע לא תקין',
            size: 'small',
            format: 'dd.MM.yyyy',
            okLabel: t('ok'),
            cancelLabel: t('cancel'),
            labelFunc: (date: MaterialUiPickersDate, invalidLabel: string): string => {
                const dateClone = moment(date).clone();
                let startDate;
                let endDate;
                if (typeof firstDayOfWeek === 'number') {
                    const dates = getWeekStartAndEndDates(firstDayOfWeek, dateClone);
                    startDate = moment(dates.start);
                    endDate = moment(dates.end);
                } else {
                    startDate = dateClone.startOf('week');
                    endDate = dateClone.endOf('week');
                }
                return dateClone && dateClone.isValid()
                    ? `${startDate.format('DD.MM.yyyy')} - ${endDate.format('DD.MM.yyyy')}`
                    : invalidLabel;
            },
            onChange: (date: MaterialUiPickersDate) => {
                // dispatchPlacementFormClosingActions();
                if (date) {
                    if (typeof firstDayOfWeek === 'number') {
                        const { start: startDate, end: endDate } = getWeekStartAndEndDates(
                            firstDayOfWeek,
                            date
                        );

                        setSelectedWeek({
                            startDate,
                            endDate,
                        });
                    } else {
                        setSelectedWeek({
                            startDate: moment(date).startOf('week').toDate(),
                            endDate: moment(date).endOf('week').toDate(),
                        });
                    }
                }
            },

            value: selectedWeek.startDate,
        },
    };

    // * handling the changing of the selected date range in the store
    useEffect(() => {
        // if the same as current on slice -> return
        const selectedWeekForComparison: IDateRangeNew = {
            startingDate: stringifyDate(selectedWeek.startDate, SHORT),
            endingDate: selectedWeek.endDate ? stringifyDate(selectedWeek.endDate, SHORT) : null,
        };

        if (_.isEqual(selectedWeekForComparison, tableDatesOnStore)) return;

        dispatch(
            selectTableWeek({
                dateRange: getSelectedDaysRange(selectedWeek.startDate, selectedWeek.endDate),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        handleClosePlacement,
        selectedWeek.endDate,
        selectedWeek.startDate,
        tableDatesOnStore.startingDate,
        tableDatesOnStore.endingDate,
    ]);

    useEffect(() => {
        // console.log(13);
        if (!isFormOpen) {
            dispatch(onChangedShiftSelection({ isShiftSelected: false }));
        }
    }, [dispatch, isFormOpen]);

    // Close placement form if there are no passengers selected
    useEffect(() => {
        if (selectedPassengers.length === 0) {
            dispatchFormClosingActions();
        }
    }, [dispatchFormClosingActions, selectedPassengers.length]);

    const datePickerProps: IDatePickerProps = {
        showTodayButton: true,
        inputVariant: 'outlined',
        style: { width: '100%' },
        size: 'small',
        format: 'dd.MM.yyyy',
        className: 'DatePicker',
        value: selectedDate,
        onChange: (date: Date | null) => {
            setSelectedDate(date || new Date());
        },
        KeyboardButtonProps: {
            'aria-label': 'change date',
        },
    };

    // const { unSelectAllPassengers } = usePassengersSelection();

    const getDeptDropdownMenuItems = useCallback(() => {
        const menuItems = departments.map((d) => ({
            name: d.departmentName,
            value: d.code,
        }));

        if (!loggedUserDepartmentCode) {
            menuItems.unshift({ name: 'כולם', value: -1 });
        }

        return menuItems;
    }, [departments, loggedUserDepartmentCode]);

    const departmentProps: IDropDownProp = {
        labalName: 'מחלקה',
        menueItem: getDeptDropdownMenuItems(),
        defaultValue: selectedDepartment,
        onChange: (e) => {
            // unSelectAllPassengers();
            if (e.target.value) {
                const val = e.target.value as number;
                setSelectedDepartment(val);
            } else {
                setSelectedDepartment(-1);
            }
        },
        formControlProp: {
            variant: 'outlined',
            style: { width: '100%' },
            size: 'small',
        },
    };

    const GlobalFilterProp: InputProp = {
        value: globalFilter,
        label: t('search'),
        size: 'small',
        style: { width: '100%' },
        labelWidth: 50,
        onChange: (e: React.FormEvent<HTMLInputElement> | any) => {
            setGlobalFilter(e.target.value);
        },
    };

    const alertConfig = useAlertV2();
    const exports = useExport({});
    const envAuth = useEnvAuth();

    return (
        <styles.Container>
            <PlacemenProvider>
                <containers.MainSectionContainer
                    className="MainSectionContainer"
                    style={{ paddingTop: '20px' }}
                >
                    <styles.ManualOrdersHeaderContainer
                        withWarningColors={envAuth.isUsingProdAccountOnDev}
                        className="ManualOrdersHeaderContainer"
                    >
                        {/* <GlobalStyle.HeaderContainer> */}
                        <MyWayTitle />
                        <GlobalStyle.LeftHeaderContainer>
                            <div style={{ paddingLeft: '15px' }}>
                                <DropDown {...clinetFilterProp} />
                            </div>
                            <div style={{ paddingLeft: '20px' }}>
                                <DropDown {...prodvierDrpodown} />
                            </div>
                            <RefreshButton onClick={handleRefresh} disabled={tempDisabled} />
                        </GlobalStyle.LeftHeaderContainer>
                        {/* </GlobalStyle.HeaderContainer> */}
                    </styles.ManualOrdersHeaderContainer>
                    <GlobalStyle.Hr />
                    <styles.ManualOrdersFiltersContainer>
                        <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <div style={{ display: 'flex', marginBottom: '5px' }}>
                                {t('selectedPassengersCount')} ({selectedPassengers.length})
                            </div>
                            <ExportBtns isSwitchingClient={isSwitchingClient} exports={exports} />
                        </div>
                        <styles.FilterContainer style={isSwitchingClient ? blocked : {}}>
                            <styles.FilterItem flex={3}>
                                <Box display="flex" flexDirection="row-reverse" width="100%">
                                    <styles.StyledToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={manuallyType}
                                        onChange={onChangeManuallyType}
                                    >
                                        <ToggleButton style={{ width: '50%' }} value={DateTypeEnum.daily}>
                                            {t('daily')}
                                        </ToggleButton>
                                        <ToggleButton style={{ width: '50%' }} value={DateTypeEnum.weekly}>
                                            {t('weekly')}
                                        </ToggleButton>
                                    </styles.StyledToggleButtonGroup>
                                </Box>
                            </styles.FilterItem>
                            <styles.FilterItem flex={manuallyType === DateTypeEnum.weekly ? 4 : 2}>
                                <Box display="flex" width="100%" justifyContent="center">
                                    {manuallyType === DateTypeEnum.weekly && (
                                        <WeekDataPicker
                                            withArrows
                                            {...weekPickerProp}
                                            firstDayOfWeek={firstDayOfWeek}
                                        />
                                    )}

                                    {manuallyType === DateTypeEnum.daily && (
                                        <DatePickerWithPrevNext {...datePickerProps} />
                                    )}

                                    {/* <ManualOrderShiftDropDown selectedDate={dateFilter} /> */}
                                </Box>
                            </styles.FilterItem>
                            <styles.FilterItem flex={manuallyType === DateTypeEnum.weekly ? 2 : 3}>
                                <DropDown {...departmentProps} />
                            </styles.FilterItem>
                            <styles.FilterItem flex={2}>
                                <ComboBox {...cityFilterProp} />
                            </styles.FilterItem>
                            <styles.FilterItem flex={3}>
                                <Input
                                    {...GlobalFilterProp}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </styles.FilterItem>
                        </styles.FilterContainer>
                    </styles.ManualOrdersFiltersContainer>
                    {isSwitchingClient ? (
                        <Spinner containerStyle={{ width: '100%', height: '100%', paddingTop: '2em' }} />
                    ) : (
                        <>
                            {exports.isPrinting && (
                                <div style={{ paddingBottom: '2000px' }}>
                                    <Spinner
                                        containerStyle={{ width: '100%', height: '100%', paddingTop: '2em' }}
                                    />
                                </div>
                            )}
                            <styles.TableContainer isFormOpen={isFormOpen} className="TableContainer">
                                <ManualOrderWrapper
                                    startDate={dateFilter.startDate}
                                    endDate={
                                        dateFilter.endDate ||
                                        moment(dateFilter.startDate).endOf('day').toDate()
                                    }
                                    data={filterPassengersShift}
                                />
                            </styles.TableContainer>

                            <ExportedKendoTable
                                startDate={dateFilter.startDate}
                                endDate={
                                    dateFilter.endDate || moment(dateFilter.startDate).endOf('day').toDate()
                                }
                                data={filterPassengersShift}
                                exporting={{ isPrinting: exports.isPrinting, tableRef: exports.tableRef }}
                            />
                        </>
                    )}
                </containers.MainSectionContainer>

                <containers.WidgetsContainer isOpen className="WidgetsContainer">
                    {getWidget()}
                </containers.WidgetsContainer>
            </PlacemenProvider>
        </styles.Container>
    );
};

// if (
//     passengersWithIsSelected.length >
//     Object.keys(passengerOrderDates).length
// ) {
//     console.log(
//         'error in getPassengerShifts ',
//         passengersWithIsSelected,
//         passengerOrderDates
//     );
// }
// if (selectedEditShift)
//     return (
//         <PlacemenTransportation
//             handleClose={handleClosePlacement}
//             date={selectedEditShift.date}
//             editShift={selectedEditShift}
//             selectedClinet={selectedClinet}
//         />
//     );
// if (selectedSingleShift)
//     return (
//         <PlacemenTransportation
//             date={selectedSingleShift.date}
//             handleClose={handleClosePlacement}
//             selectedClinet={selectedClinet}
//         />
//     );

// const [passengerSelected, setPassengerSelected] = useState<
//     IPassengerShifts[]
// >([]);
