import React from 'react';
import s from './Filters.style';
import useReportFetch from './hooks/useReportFetch';
// import ShortOrdersReportFilters from './FiltersAccordionForEachReport/ShortOrdersReportFilters/ShortOrdersReportFilters';
import SelectedReportFiltersAccordion, {
    SelectedReportFiltersAccordionProps,
} from './SelectedReportFiltersAccordion/SelectedReportFiltersAccordion';

// import SelectedReportFiltersAccordion, {
//    SelectedReportFiltersAccordionProps,
// } from './SelectedReportFiltersAccordion/SelectedReportFiltersAccordion';

type Props = {
    // eslint-disable-next-line react/no-unused-prop-types
    tableRef?: any;
    setClientSideFilters: Function;
};

const Filters = ({ setClientSideFilters }: Props): JSX.Element => {
    // & hooks
    useReportFetch();

    // & local states

    // & Selectors

    // & prop builders
    const selectedReportFiltersAccordionProps: SelectedReportFiltersAccordionProps = {
        setClientSideFilters,
    };
    return (
        <s.FiltersBox>
            <SelectedReportFiltersAccordion {...selectedReportFiltersAccordionProps} />
        </s.FiltersBox>
    );
};

export default Filters;
