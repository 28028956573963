import { CSSProperties } from 'react';
import { colors } from 'src/style/themes/defaultTheme';
import styled, { css } from 'styled-components';

export const Container = styled.div``;
export const Title = styled.div``;

export const DaysContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
`;
export const DayContainer = styled.div<{
    disable: boolean;
    isFoucs: boolean;
    isCurrDay: boolean;
    canBeEnabled: boolean;
    isOrderedAlready: boolean;
    orderedShiftColor: string;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #b4bec94d;
    width: 13%;
    height: 48px;
    border-radius: 4px;
    margin: 5px 0;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    cursor: pointer;
    ${(props) =>
        props.disable &&
        props.canBeEnabled &&
        css`
            border: 1px solid ${colors.muiRed};
        `}
    ${({ disable }) =>
        disable &&
        css`
            opacity: 0.4;
            cursor: default;
            * {
                pointer: cursor;
            }
        `}
   ${({ isFoucs }) =>
        isFoucs &&
        css`
            border: 2px solid #40a8e2;
        `}

   ${(props) =>
        props.isOrderedAlready &&
        // !props.isFoucs &&
        props.orderedShiftColor &&
        `background-color: ${props.orderedShiftColor}`}
`;

export const WeekContainer = styled.div<{ style?: CSSProperties; isWeekOnTable?: boolean }>`
    ${(props) => {
        if (props.isWeekOnTable) {
            return css`
                border: 2px dashed ${colors.blue};
                padding: 0 5px;
                border-radius: 4px;
            `;
        }
    }}
`;
