import React, { useState } from 'react';
import useCommons from 'src/hooks/common/useCommons';
import {
    removeCourseByCodeAction,
    setSelectedCourseIdAction,
    toggleDeleteModalAction,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { ErrorMessages } from 'src/types/apiCommon.types';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import useCoursesData from './useCourseData';
import { useDispatchAlert } from 'src/hooks/useAlert';

const useDeleteModal = () => {
    const { t, dispatch, useRootAppSelector, dispatchI18nErrMsg } = useCommons();

    const [loading, setLoading] = useState(false);

    const deleteModal = useRootAppSelector((state) => uiSelector(state).deleteModal);
    const selectedClientCode = useRootAppSelector((state) => uiSelector(state).clientCode);

    const { getCourseByCode } = useCoursesData();
    const { getRouteClientData } = useBaseApiParams();
    const dispatchAlert = useDispatchAlert();

    const fullCourseData = getCourseByCode(deleteModal.courseCode);

    const onConfirm = async () => {
        setLoading(true);

        try {
            if (!fullCourseData || !selectedClientCode) return;

            const {
                clientPrice = null,
                departmentName,
                carTypeName,
                lineTypeName,
                passQty,
                courseTimes,
                ...rest
            } = fullCourseData;

            const params = {
                remarks: rest.remarks || '',
                ...rest,
                courseDirection: rest.courseDirection as any,
                clientCode: selectedClientCode,
            } as const;

            const r = await coursesBuildingRoutes.deleteCourse(getRouteClientData(params));

            if (r.code === CommonCodes.Ok) {
                dispatch(removeCourseByCodeAction(deleteModal.courseCode));
                dispatch(setSelectedCourseIdAction(''));
                dispatch(toggleDeleteModalAction({ isOpen: false, courseCode: '' }));
                dispatchAlert('success', t('operationWasSuccessful'));
            } else {
                dispatchI18nErrMsg(r.data.message || ErrorMessages.OperationFailed);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            dispatchI18nErrMsg(ErrorMessages.OperationFailed);
        }
    };

    const onClose = () => {
        dispatch(toggleDeleteModalAction({ isOpen: false, courseCode: '' }));
    };

    const Body = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    paddingRight: '11px',
                }}
            >
                <p style={{ fontSize: '20px' }}>{t('deleteThisCourse')}?</p>
                <p>{fullCourseData?.courseDescription}</p>
            </div>
        );
    };

    return {
        props: {
            isOpen: deleteModal.isOpen,
            Body,
            onConfirm,
            onClose,
            loading,
        },
    };
};

export default useDeleteModal;
