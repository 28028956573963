import { AxiosResponse } from 'axios';
import { ErrorMessagesOld } from 'src/store/type';
import { ReqConfig, WsResponse } from 'src/types/apiCommon.types';
import querystring from 'querystring';
import { MINUTE } from 'src/constants/times';
import { buildEncriptedUrl as buildEncryptedUrl, getInstanceWithTime } from '.';

const isEmptyParam = (val: unknown) => {
    if (val === undefined || val === null || val === '') {
        return true;
    }
    return false;
};

export const createPayload = (data: any): object => {
    const res = Object.keys(data).reduce((acc: any, curr: any): any => {
        if (!isEmptyParam(data[curr])) {
            acc[`$${curr}`] = data[curr];
        }
        return acc;
    }, {});
    return res;
};

export const temp = '';

// add dollar sign prefix to each key in the object
export const addDollarSignPrefix = (obj: { [key in string]: any }): { [key in string]: any } => {
    const res = Object.keys(obj).reduce((acc: any, curr: any): any => {
        acc[`$${curr}`] = obj[curr];
        return acc;
    }, {});
    return res;
};

export const isValidResponse = (
    status: number,
    wantedType: string,
    data: unknown
): { msg: String | null; details?: any } => {
    switch (status) {
        case 200:
            break;

        case 202:
            return {
                msg: ErrorMessagesOld.MissingWs,
                details: status,
            };
        default:
            return {
                msg: 'Invalid Ws response',
                details: status,
            };
    }
    if (typeof data !== wantedType) {
        return {
            msg: 'Data type is invalid',
            details: data,
        };
    }
    return { msg: null };
};

export const DEFAULT_DATA_KEY = 'data';

const isWsResponseOk = (axiosRes: AxiosResponse<any>) => {
    try {
        return axiosRes.status === 200 && axiosRes.data && +axiosRes.data.response === 0;
    } catch (error) {
        return false;
    }
};

const missingWsResult = (axiosRes: AxiosResponse<any>) => {
    return axiosRes.status === 202 || axiosRes.data === '';
};

// WS response type guard
export const isWsResponse = <TData>(res: unknown, dataKey = DEFAULT_DATA_KEY): res is WsResponse<TData> => {
    try {
        if (typeof res !== 'object' || res === null) {
            return false;
        }
        if (dataKey in res && 'response' in res) return true;
        return false;
    } catch (error) {
        return false;
    }
};

const isBadToken = (axiosRes: AxiosResponse<unknown>) => {
    try {
        if (axiosRes.status === 200 && isWsResponse<any>(axiosRes.data)) {
            return +axiosRes.data.response === 1;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
};

/**
 * Makes a call to the API.
 * @param {NewCallConfig<P>} config - the configuration object for the call.
 * @type {P} the requestParams type.
 * @type {T} the response data type.
 * @returns {AxiosResponse<T>} AxiosResponse<T> - the response from the API.
 */

export interface IApiCallConfig<T> extends ReqConfig<T> {
    endpoint: string;
}
/**
 * ! DEPRECATED #############################################
 * Makes an API call to the given endpoint with the given request parameters.
 * @param {string} dbUrl - the URL of the database to make the API call to.
 * @param {P} requestParams - the request parameters to send to the API.
 * @param {string} endpoint - the endpoint to make the API call to.
 * @param {number} [timeout=MINUTE] - the timeout for the API call.
 * @type (P) the requestParams type.
 * @type (T) the response data type.
 * @returns {Promise<AxiosResponse<T>>} - the response from the API call.
 */
export const makeApiCallV2 = async <P, T>({
    dbUrl,
    requestParams,
    endpoint,
    timeout = MINUTE,
}: IApiCallConfig<P>): Promise<AxiosResponse<T>> => {
    const requestParamsStr = querystring.stringify({
        ...createPayload(requestParams),
    });
    const axiosInstance = getInstanceWithTime(timeout);
    const req = axiosInstance.post(buildEncryptedUrl(dbUrl, endpoint), requestParamsStr);

    return req;
};

/**
 * Makes an API call to the given endpoint with the given request parameters.
 * @param {string} dbUrl - the URL of the database to make the API call to.
 * @param {P} requestParams - the request parameters to send to the API.
 * @param {string} endpoint - the endpoint to make the API call to.
 * @param {number} [timeout=MINUTE] - the timeout for the API call.
 * @type (P) the requestParams type.
 * @type (T) the response data type.
 * @returns {Promise<AxiosResponse<T>>} - the response from the API call.
 */
export const makeApiCallV3 = async <P, T>({
    dbUrl,
    requestParams,
    endpoint,
    timeout = MINUTE,
}: IApiCallConfig<P>): Promise<AxiosResponse<T>> => {
    const requestParamsStr = querystring.stringify({
        ...createPayload(requestParams),
    });
    const axiosInstance = getInstanceWithTime(timeout);
    const req = axiosInstance.post(buildEncryptedUrl(dbUrl, endpoint), requestParamsStr);

    return req;
};

export const responseValidators = {
    isOk: isWsResponseOk,
    isMissingWs: missingWsResult,
    isBadToken,
};
