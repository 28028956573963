import React from 'react';
import styled, { css } from 'styled-components';

const Btn = styled.button<{ isDisabled?: boolean }>`
    background: #ffffff;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #00a3ff;
    :hover {
        cursor: pointer;
        color: #4bbdff;
    }
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            color: #c4c4c4;
            pointer-events: none;
        `}
`;

const TextBtn: React.FC<{ text: string; onClick: (e: any) => void; isDisabled?: boolean }> = ({
    text,
    onClick,
    isDisabled = false,
}) => {
    return (
        <Btn type="button" onClick={onClick} isDisabled={isDisabled}>
            {text}
        </Btn>
    );
};

export default TextBtn;
