import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

import { StyledTimePickerTextField } from 'src/components/TimePicker/TimePickerIndex';
import useCommons from 'src/hooks/common/useCommons';
import {
    updateTimesSettingsTabValidAction,
    updateSettingBoxByIdAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import { TIME_FORMATS } from '../../../../../../constants/dates';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dateHelpers from 'src/utilis/dateHelpers';
import moment from 'moment';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import i18n from 'src/i18n';

const TimeInput: React.FC<{
    value: Date | string;
    onChange: (value: Date | null, keyboardInputVal?: string) => void;
    label?: string;
    minTime?: Date;
    errorMsg?: string;
}> = ({ value, onChange, minTime, label, errorMsg }) => {
    const { t } = useCommons();

    return (
        <InputWithErrorsWrapper errorMessage={errorMsg}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label={label || t('pickTime')}
                    value={value}
                    onChange={onChange}
                    ampm={false}
                    minTime={minTime}
                    inputFormat={TIME_FORMATS.TWENTY_FOUR}
                    renderInput={(params) => (
                        <StyledTimePickerTextField
                            id="time"
                            variant="outlined"
                            size="small"
                            {...params as any}
                            InputLabelProps={{ shrink: true }}
                            label={label || t('pickTime')}
                            error={!!errorMsg}
                        />
                    )}
                />
            </LocalizationProvider>
        </InputWithErrorsWrapper>
    );
};

const timeErrorMessages = Object.freeze({
    invalidTime: i18n.t('invalidTime'),
    endTimeBeforeStartTime: i18n.t('endTimeBeforeStartTime'),
});

const getErrorMsg = (timeType: 'startTime' | 'endTime', boxData: TimesSettingsBox): string => {
    if (timeType === 'endTime') {
        if (!dateHelpers.isValidDate(boxData.endTime)) {
            // console.log('invalid date', boxData.endTime);
            return timeErrorMessages.invalidTime;
        }

        if (!dateHelpers.isValidDate(boxData.startTime)) {
            // console.log('invalid date', boxData.startTime);
            return '';
        }

        // set both start and end time to be on the same date
        const startTime = moment(boxData.startTime).set('year', 2022).set('month', 1).set('date', 1);
        const endTime = moment(boxData.endTime).set('year', 2022).set('month', 1).set('date', 1);

        if (startTime.diff(endTime, 'minute') > 0) {
            return timeErrorMessages.endTimeBeforeStartTime;
        }

        return '';
    }

    if (timeType === 'startTime') {
        if (!dateHelpers.isValidDate(boxData.startTime)) {
            return timeErrorMessages.invalidTime;
        }
    }

    return '';
};

// times input component
const TimesInput: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();

    const onTimeChange = (timeType: 'startTime' | 'endTime', newDate: Date | null, textValue?: string) => {
        const newVal = dateHelpers.isValidDate(newDate) ? newDate : textValue || '';
        dispatch(updateSettingBoxByIdAction({ ...boxData, [timeType]: newVal }));
    };

    const startTimeInputProps = {
        label: 'התחלה',
        onChange: (newDate: any, textValue: any) => onTimeChange('startTime', newDate, textValue),
        value: boxData.startTime,
        errorMsg: getErrorMsg('startTime', boxData),
    } as const;

    const endTimeInputProps = {
        label: 'סיום',
        onChange: (newDate: any, textValue: any) => onTimeChange('endTime', newDate, textValue),
        value: boxData.endTime,
        errorMsg: getErrorMsg('endTime', boxData),
    } as const;

    React.useEffect(() => {
        const isEndTimeValid = !endTimeInputProps.errorMsg;
        const isStartTimeValid = !startTimeInputProps.errorMsg;

        const isValid = isEndTimeValid && isStartTimeValid;

        dispatch(
            updateTimesSettingsTabValidAction({
                actionType: isValid ? 'remove' : 'add',
                errorType: 'invalidTimes',
            })
        );
    }, [dispatch, endTimeInputProps.errorMsg, startTimeInputProps.errorMsg]);

    return (
        <div style={{ display: 'flex', gap: '1em' }}>
            <TimeInput {...startTimeInputProps} />
            <TimeInput {...endTimeInputProps} />
        </div>
    );
};

export default TimesInput;
