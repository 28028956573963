/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import DatePickerWithPrevNext from 'src/components/DatePicker/DatePickerWithPrevNext';
import { getDateErrorMessage } from 'src/screens/Main/components/AddLineForm/components/Inputs/DatePicker';

const datePickerProps = {
    showTodayButton: true,
    inputVariant: 'outlined',
    style: { width: '100%' },
    size: 'small' as const,
    format: 'dd.MM.yyyy',
} as const;

type ControlledDatePickerProps<T> = {
    currValue: Date;
    setValue: (date: Date | null) => void;
    errors: FieldError | undefined;
    label?: string;
};

// is FieldError type check
const isFieldError = (error: any): error is FieldError => {
    return error !== undefined && 'type' in error;
};

const RHFDatePicker = <T extends Record<string, unknown>>(
    props: ControlledDatePickerProps<T> & { children?: ReactNode }
) => {
    const { label, setValue, errors, currValue } = props;

    const { t } = useTranslation();

    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        if (currValue instanceof Date || !currValue) {
            setDate(currValue || null);
        }
    }, [currValue]);

    return (
        <div
            style={{
                textAlign: 'right',
                width: '100%',
            }}
            className="ControllerChild"
        >
            <DatePickerWithPrevNext
                {...datePickerProps}
                label={label}
                value={date}
                error={!!errors}
                placeholder="dd.MM.yyyy"
                InputLabelProps={{ shrink: true }}
                onChange={(newDate) => {
                    setValue(newDate);
                }}
                style={{ ...datePickerProps.style, borderRight: '1px solid #a6a6a6y' }}
            />
            <p>{t(getDateErrorMessage(errors))}</p>
        </div>
    );
};

export default RHFDatePicker;
