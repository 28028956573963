/* eslint-disable react/button-has-type */
import { IconButton } from '@material-ui/core';
import React, { FC, MouseEventHandler, useCallback } from 'react';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { iconButtonStyle } from 'src/components/styles/styles';
import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import { useAppDispatch } from 'src/store/hooks';
import { editPassengerAction, removePassengerFromStationAction } from 'src/store/slices/lines/linesSlice';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';

const Container = styled.div`
    background: linear-gradient(90deg, #e4e7eb 52%, rgba(228, 231, 235, 0) 110.5%);
    height: 40px;
    width: 145px;
    border-radius: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: left;
    left: 0px;
    gap: 5px;
    padding-left: 10px;
`;
export const DeleteBtn = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
    const { t } = useTranslation();
    return (
        <MuiTooltipV3 title={t('delete')}>
            <IconButton onClick={onClick} style={iconButtonStyle} aria-label="מחיקה" size="small">
                <TrashIcon height="18" color="" />
            </IconButton>
        </MuiTooltipV3>
    );
};

export const EditBtn = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
    const { t } = useTranslation();

    return (
        <MuiTooltipV3 title={t('edit')}>
            <IconButton onClick={onClick} style={iconButtonStyle} aria-label="עריכה" size="small">
                <MyEditPencilIcon height="17px" width="17px" color="black" />
            </IconButton>
        </MuiTooltipV3>
    );
};

type Props = {
    stationId: string;
    passenger: ExistingPassenger;
};
const PassengerRowActions: FC<Props> = ({ stationId, passenger }) => {
    const dispatch = useAppDispatch();

    const onDelete = useCallback(() => {
        dispatch(
            removePassengerFromStationAction({
                stationId,
                passengerId: passenger.passCode,
            })
        );
    }, [dispatch, passenger.passCode, stationId]);

    const onEdit = useCallback(() => {
        dispatch(editPassengerAction({ passenger }));
    }, [dispatch, passenger]);
    return (
        <Container>
            <EditBtn onClick={onEdit} />
            <DeleteBtn onClick={onDelete} />
        </Container>
    );
};

export default PassengerRowActions;
