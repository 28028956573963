import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import { uiSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { colors } from 'src/style/themes/defaultTheme';
import { WsCourseDataItem, CourseType } from 'src/types/passengersManager/courseChoosingTypes';
import Panel from './Panel/Panel';
import { Styles, tabsSx } from './styles';

interface Props {}
const CourseTypeTabs = (props: Props) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<CourseType>('pickup');

    return (
        <div>
            <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                sx={tabsSx}
            >
                <Tab value="pickup" label={t('choosingPickup')} />
                <Tab value="drop" label={t('choosingDrop')} />
            </Tabs>
            <Styles.TabContainer>
                <Panel selectedTab={selectedTab} value="pickup" />
                <Panel selectedTab={selectedTab} value="drop" />
            </Styles.TabContainer>
        </div>
    );
};

export default CourseTypeTabs;
