/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { FilterDescriptor } from '@progress/kendo-data-query';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { TilePosition } from '@progress/kendo-react-layout';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { IAddress } from 'src/api/passengerMangerApi/types';
import { IAuthorization } from 'src/api/types';
import { RELATIVE, SHORT } from 'src/constants/dates';
import { valueCanNotBeFalsyError } from 'src/errors/errors';
import { DateObj, Immutable, IObject, StorageKeys } from 'src/types/global';
import { UI_TIME } from 'src/screens/ManualOrder/utilis';
import i18n from 'src/i18n';
import { FillerValues } from 'src/constants/misc';
import { v4 } from 'uuid';
import { sendClientCounter } from 'src/api/miscApis/googleCounterApi';
import { GoogleCounterApiResponse } from 'src/types/googleCounterApi/googleCounterApi';
import { AxiosResponse } from 'axios';

export const mobilePhoneRegex = new RegExp(/^05[0-9]{1}(-)?\d{7}$/);

export const digitRegex = new RegExp('[0-9]');
export const onlyDigitRegex = new RegExp('^[0-9]*$');
export const isoDateRegex = new RegExp(/^\d{4}–\d{2}–\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/);
export const positiveNumberRegex = /^[0-9]*$/;
export const fleetDateFormat = new RegExp(/^\d{4}[.|-]\d{2}[.|-]\d{2}\s\d{2}:\d{2}$/);
export const digitOrPlusSymbolRegex = /^$|^([+]?\d{1,32})$/;
export const digitOrCharRegex = /[A-Za-z0-9]/;

// 'hh:mm'
export const timeDigit = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

/**
 * Returns the format of the date string.
 * @param {string} date - the date string
 * @returns {string | null} - the format of the date string.
 */
export const getDateFormat = (date: string): 'DD-MM-YY' | 'YYYY-MM-DD' | undefined => {
    // const possibleFormats = [SHORT, RELATIVE];
    // console.log({ date });
    if (date.includes('-')) {
        if (date.length === 6) {
            return SHORT;
        }
        if (date.length === 8) return RELATIVE;
    }
    // if (date.includes('.')) {
    // }
};
/**
 * Returns the total size of the local storage in kilobytes.
 * @returns {number} The total size of the local storage in kilobytes.
 */
export function getLocalStorageSize(): number {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _lsTotal = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _x;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _xLen;
    // eslint-disable-next-line no-restricted-syntax
    for (_x in localStorage) {
        // eslint-disable-next-line no-prototype-builtins
        if (!localStorage.hasOwnProperty(_x)) {
            // eslint-disable-next-line no-continue
            continue;
        }
        // eslint-disable-next-line no-underscore-dangle
        _xLen = (localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
        // console.log(`${_x.substr(0, 50)} = ${(_xLen / 1024).toFixed(2)} KB`);
    }
    return _lsTotal / 1024;
}

/**
 * Logs an error to local storage.
 * @param {any} error - the error to log.
 * @param {string} [msg] - a message to add to the error.
 * @returns None
 */
export const logToLocalStorage = (error: any, msg: any) => {
    if (getLocalStorageSize() > 4900) {
        localStorage.setItem('errors', JSON.stringify([])); // clearing errors array
    }

    let strMsg = msg;
    if (typeof msg !== 'string') {
        strMsg = JSON.stringify(msg);
        if (strMsg.length > 500) strMsg.slice(0, 500); // limiting to 500 chars
    }

    const errs = JSON.parse(localStorage.getItem('errors') || '[]');
    errs.push({ time: moment(), error, msg: strMsg });
    localStorage.setItem('errors', JSON.stringify(errs));
};

/**
 * Takes in a string of code and parses it as JSON if it is valid.
 * @param {string} str - the string to parse as JSON
 * @returns {any} - the parsed JSON object, or an error object if the string is invalid
 */
export const parseJsonIfValid = (str: string): any => {
    try {
        const json = JSON.parse(str);
        return json;
    } catch (e) {
        logToLocalStorage(e, 'Invalid JSON parsed');
        return 'Invalid JSON parsed';
    }
};

/**
 * Takes in a date and returns a string of the date in the given format.
 * @param {string | Moment | Date} date - the date to format
 * @param {string} format - the format to format the date in
 * @returns {string} - the formatted date
 */
export const formatDateToString = (
    date: string | Moment | Date,
    format: string,
    currFormat?: string
): string => {
    let result;
    if (moment.isMoment(date)) {
        result = moment(date).format(format);
    } else {
        result = moment(date, currFormat).format(format);
    }
    return result;
};

export const isRelativeNOTStable = (date: string) => {
    return date.length === 10;
};
export const mailRegex = new RegExp(/^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/);

export const stringToBoolean = (value: string | undefined): boolean => {
    if (!value) return false;

    switch (value.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
        case null:
            return false;
        default:
            return Boolean(value);
    }
};

/**
 * Deep clones an object or array.
 * @param {any[] | object} val - the object or array to clone
 * @returns {any[] | object} - the cloned object or array
 */
export const deepClone = <TValType>(val: TValType): TValType => _.cloneDeep(val);

export const isNumber = (value: unknown): boolean => {
    return value !== undefined && value !== null && !Number.isNaN(value);
};

export const isTypeofNumber = (value: unknown): value is number => {
    return typeof value === 'number';
};

export const isBoolean = (value: unknown): boolean => {
    return typeof value === 'boolean' || value === 0 || value === 1;
};

export const isDate = (value: unknown): boolean => {
    return moment.isDate(value);
};

export const buildFilter = (
    { field, editor }: GridColumnProps,
    value: string | boolean | number | Date,
    operator?: string
): FilterDescriptor => {
    let filterOpratore: string | Function = 'contains';
    let filterValue;

    if (!operator) {
        if (editor === 'numeric') filterOpratore = 'eq';
        if (editor === 'date') filterOpratore = 'eq';
        if (editor === 'boolean') filterOpratore = 'eq';
    }

    if (editor === 'numeric') filterValue = +value;

    if (editor === 'date') filterValue = (isDate(value) && value) || undefined;

    if (editor === 'boolean') filterValue = (isBoolean(value) && !!value) || undefined;

    if (editor === 'text' || !editor) filterValue = value;

    return {
        field,
        operator: filterOpratore,
        value: filterValue,
    };
};

export const distinct = <T = any>(array: Array<T>, key: string): Array<T> => {
    if (array.length && typeof array[0] === 'object' && key) {
        return Array.from(new Map(array.map((item: any) => [item[key], item])).values());
    }

    return Array.from(new Set(array));
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeDeep = (...objects: any[]): object => {
    const isObject = (obj: unknown) => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach((key) => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                // eslint-disable-next-line no-param-reassign
                prev[key] = pVal.concat(...oVal);
            } else if (isObject(pVal) && isObject(oVal)) {
                // eslint-disable-next-line no-param-reassign
                prev[key] = mergeDeep(pVal, oVal);
            } else {
                // eslint-disable-next-line no-param-reassign
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
};

export const getLocalStorageValue = (key: StorageKeys): string => {
    return localStorage.getItem(key) || '';
};

export const RemoveLocalStorageKey = (key: StorageKeys): void => {
    localStorage.removeItem(key);
};

export const setLocalStorageValue = (key: StorageKeys, value: string): void => {
    localStorage.setItem(key, value);
};

export const getSessionStorageValue = (key: StorageKeys): string => {
    return sessionStorage.getItem(key) || '';
};

export const RemoveSessionStorageKey = (key: StorageKeys): void => {
    sessionStorage.removeItem(key);
};

export const setSessionStorageValue = (key: StorageKeys, value: string): void => {
    sessionStorage.setItem(key, value);
};

export const jsonDateReviver = (key: string, value: unknown): unknown => {
    if (typeof value === 'string' && isoDateRegex.test(value)) {
        return new Date(value);
    }

    return value;
};

export const getDatesBetween = (startDate: Date, EndDate: Date, includeEndDate?: boolean): Array<Date> => {
    const a = moment(startDate);
    const b = moment(EndDate);

    const result = [];
    for (let m = moment(a); m.isBefore(b, 'day'); m.add(1, 'days')) {
        result.push(m.toDate());
    }
    if (includeEndDate) {
        result.push(EndDate);
    }
    return result;
};

export function* getDatesBetweenItrator(startDate: Date, EndDate: Date): Iterator<Date> {
    const a = moment(startDate);
    const b = moment(EndDate);

    for (let m = moment(a); m.isBefore(b, 'day'); m.add(1, 'days')) {
        yield m.toDate();
    }
    return null;
}

/**
 * Takes in an address object and returns a string of the address.
 * @param {IAddress | undefined | null} address - the address object.
 * @returns {string} - the address as a string.
 */
export const buidAdress = (address: IAddress | undefined | null): string => {
    if (!address) return '';
    return `${address.city ? `${address.city},` : ''} ${address.street || ''} ${
        address.houseNum || ''
    }`.trimEnd();
};
/**
 * Checks if two dates are the same.
 * @param {Moment | Date} dateOne - the first date to compare
 * @param {Moment | Date} dateTwo - the second date to compare
 * @returns {boolean} - true if the dates are the same, false otherwise
 */
export const isSameDate = (date1: Moment | Date, date2: Moment | Date): boolean => {
    return moment(date1).isSame(moment(date2), 'day');
};

export const addOrUpdateItemById = <T>(orignalArray: Array<T>, data: T, idPropName: keyof T): Array<T> => {
    let isUpdate = false;
    const arr = _.cloneDeep(orignalArray);
    arr.every((item, index) => {
        if (item[idPropName] === data[idPropName]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.assign(arr[index], data);
            isUpdate = true;
            return false;
        }
        return true;
    });

    if (!isUpdate) arr.push(data);

    return arr;
};

/**
 * Checks if the client is authorized to view the requested screen.
 * @param {number} screenOrOptionId - the id of the requested screen or feature (option).
 * @param {number} clinetId - the id of the client.
 * @param {IAuthorization} [authorizationToken] - the authorization token.
 * @returns {boolean} - true if the client is authorized to view the requested screen.
 */
export const isAuthorized = (
    screenOrOptionId: number,
    clinetId: number,
    authorizationToken?: IAuthorization
): boolean => {
    if (!authorizationToken || !authorizationToken[screenOrOptionId]) return false;

    return authorizationToken[screenOrOptionId].clients.some((cId) => cId === clinetId);
};

export const setWidgetLayout = (
    key: string,
    tilePosition: Array<TilePosition>,
    storageKey?: StorageKeys
): void => {
    const widgetLayout: string = getLocalStorageValue(storageKey || StorageKeys.WidgetLayout);

    const widgetLayoutObject = (widgetLayout && JSON.parse(widgetLayout)) || {};

    widgetLayoutObject[key] = tilePosition;

    setLocalStorageValue(storageKey || StorageKeys.WidgetLayout, JSON.stringify(widgetLayoutObject));
};

export const getSavedGridColumnProps = (
    defaultGridColumn: Array<GridColumnProps>,
    key?: string
): Array<GridColumnProps> => {
    if (!key) return defaultGridColumn;

    const savedColumns = JSON.parse(getLocalStorageValue(StorageKeys.GridColumns) || '{}');

    if (!savedColumns[key]) return defaultGridColumn;

    const newLineColumns: Array<{
        width: string;
        field: string;
        orderIndex: number;
    }> = savedColumns[key];

    // return orginal defaultGridColumn with new width and reorderd by orderIndex
    // remove orderIndex do to bug in kendo library
    return defaultGridColumn
        .map((x) => {
            const currentField = newLineColumns.find((y) => y.field === x.field);
            if (currentField)
                return {
                    ...x,
                    width: currentField.width,
                    sortProp: currentField.orderIndex,
                };

            return x;
        })
        .sort((x: any, y: any) => x.sortProp - y.sortProp);
};

/**
 * Get the day of the week by the given date.
 * @param {string} date - the date to get the day of the week for.
 * @returns {string} the day of the week.
 */
export const getWeekdayByDate = (date: string): string => {
    const dayOfWeek = new Date(date).getDay();
    return Number.isNaN(dayOfWeek)
        ? ''
        : ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'][dayOfWeek];
};

/**
 * Takes in an array and a key and returns a dictionary with the key as the key and the value as the value.
 * @param {any[]} arr - the array to build the dictionary from.
 * @param {string | number} key - the key to use to build the dictionary.
 * @returns {object} - the dictionary.
 */
export const buildDictByKey = (arr: any[], key: string | number) => {
    return Object.assign(
        {},
        ...arr.map((item: any) => ({
            [item[key]]: item,
        }))
    );
};

/**
 * Checks if an object has a truthy value.
 * @param {any} obj - the object to check for truthiness
 * @returns {boolean} - true if the object has a truthy value, false otherwise.
 */
export const objHasTruthyValue = (obj: any) => {
    return Object.values(obj).some((value) => {
        if (value) return true;
        return false;
    });
};
/**
 * Returns an array of moments between two dates.
 * @param {Date | Moment} startDate - the start date of the range.
 * @param {Date | Moment} endDate - the end date of the range.
 * @returns {Moment[]} - an array of moments between the start and end date.
 */
export const enumerateDaysBetweenDates = (startDate: Date | Moment, endDate: Date | Moment): Moment[] => {
    const dates: Moment[] = [moment(startDate)];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(moment(currDate));
    }

    return dates;
};

// export const exportToExcel = (data: any[], title: string) => {
//    const newData = data.map((row: { tableData: any }) => {
//       delete row.tableData;
//       return row;
//    });
//    console.log(title);
//    const workSheet = XLSX.utils.json_to_sheet(newData);
//    const workBook = XLSX.utils.book_new();
//    XLSX.utils.book_append_sheet(workBook, workSheet, 'report');
//    // Buffer
//    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
//    // Binary string
//    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
//    // Download
//    XLSX.writeFile(workBook, `${'דוח myWayStudio'}.xlsx`);
// };

export const exportToPdf = (columns: any[], data: any, titleTranslated: string | string[], title: any) => {
    // const doc = new jsPDF({ filters: ['ASCIIHexEncode'] });
    // doc.addFileToVFS('RobotoMono-Regular.ttf', font);
    // doc.addFont(
    //    'RobotoMono-Regular.ttf',
    //    'RobotoMono-Regular',
    //    'normal',
    // );
    // doc.text(titleTranslated, 20, 10);
    // // doc.setLanguage('he');
    // doc.setFont('RobotoMono-Regular');
    // const columnFields = columns.map(
    //    (column: { field: any }) => column.field,
    // );
    // (doc as any).autoTable({
    //    theme: 'grid',
    //    head: [columnFields],
    //    columns: columns.map((col: any) => ({
    //       ...col,
    //       dataKey: col.field,
    //    })),
    //    body: data,
    // });
    // doc.save(`${titleTranslated}.pdf`);
};
// export const exportToPdf = (
//    columns: any[],
//    data: any,
//    titleTranslated: string | string[],
//    title: any,
// ) => {
//    const doc = new jsPDF({ filters: ['ASCIIHexEncode'] });
//    doc.addFileToVFS('RobotoMono-Regular.ttf', font);
//    doc.addFont(
//       'RobotoMono-Regular.ttf',
//       'RobotoMono-Regular',
//       'normal',
//    );
//    doc.text(titleTranslated, 20, 10);
//    // doc.setLanguage('he');
//    doc.setFont('RobotoMono-Regular');

//    const columnFields = columns.map(
//       (column: { field: any }) => column.field,
//    );
//    (doc as any).autoTable({
//       theme: 'grid',
//       head: [columnFields],
//       columns: columns.map((col: any) => ({
//          ...col,
//          dataKey: col.field,
//       })),
//       body: data,
//    });
//    doc.save(`${titleTranslated}.pdf`);
// };

/**
 * Takes in a date and returns a string of the date in the wanted format OR RELATIVE if not given.
 * @param {Moment | Date} date - the date to format
 * @param {string} [wantedFormat] - the format to return the date in.
 * @returns {string} - the formatted date
 */
export const stringifyDate = (date: Moment | Date, wantedFormat?: string): string =>
    moment(date).format(wantedFormat || RELATIVE);

/**
 * Takes in a date string and converts it to the wanted format.
 * @param {string} date - the date to convert
 * @param {string} wantedFormat - the format to convert the date to
 * @param {string} currFormat - the format of the date string
 * @returns {string} the date in the wanted format
 */
export const changeDateFormat = (date: string, wantedFormat: string, currFormat: string): string => {
    return moment(date, currFormat).format(wantedFormat);
};

/**
 * Takes in a string of date and returns a Date object.
 * @param {string} dateStr - the date to convert to a Date object.
 * @param {string} currFormat - the format of the date string.
 * @returns {Date} - the date object.
 */
export const getDateObject = (dateStr: string, currFormat?: string): Date => {
    return moment(dateStr, currFormat || RELATIVE).toDate();
};
/**
 * ! DEPRECATED
 * Checks if two dates are on the same day.
 * @param {Date} first - the first date to compare
 * @param {Date} second - the second date to compare
 * @returns {boolean} - true if the dates are on the same day, false otherwise
 */
export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
    new Date(first).getFullYear() === new Date(second).getFullYear() &&
    new Date(first).getMonth() === new Date(second).getMonth() &&
    new Date(first).getDate() === new Date(second).getDate();

/**
 * Checks if the given parameter is a string and if it is not falsy.
 * @param {any} param - the parameter to check for falsyness
 * @returns {boolean} - true if the parameter is a string and not falsy, false otherwise
 */
export const validStringParamLength = (param: any) => {
    if (typeof param === 'string' && !param) {
        throw valueCanNotBeFalsyError;
    } else {
        return true;
    }
};

/**
 * Returns the current path of the page.
 * @returns {string} The current path of the page.
 */
export const getCurrentPath = (): string => window.location.href.split('/')[3];

/**
 * UNSTABLE Takes in a date and returns a date that is a Date object.
 * @param {Date | string | Moment} date - the date to format
 * @returns {Date} - the formatted date
 */
export const dateify = (date: Moment | string | Date, format: string = RELATIVE): Date => {
    if (moment.isMoment(date) || date instanceof Date) {
        return moment(date).toDate();
    }
    if (typeof date === 'string' && format) {
        return moment(date, format).toDate();
    }
    throw new Error('dateify - missing date format');
};

/**
 * Takes in a string and returns it as TemplateStringsArray type.
 * @param {string} str - the string to convert to a TemplateStringsArray.
 * @returns {TemplateStringsArray} - the string as a TemplateStringsArray.
 */
export const getStrAsTemplateStringArr = (str: string) => str as unknown as TemplateStringsArray;

export const getAsTemplateStrArr = (str: string): TemplateStringsArray =>
    str as unknown as TemplateStringsArray;
export const asTSR = getAsTemplateStrArr;
/**
 * Counts the number of objects in an array that have a truthy value for the given key.
 * @param {object[]} arr - the array of objects to count the truthy values of.
 * @param {string} valueToCount - the key to count the truthy values of.
 * @returns {number} - the number of objects in the array that have a truthy value for the given key.
 */
export const getTruthyValueCountOnArrayObjects = (arr: object[], valueToCount: string) => {
    let count = 0;

    arr.forEach((object) => {
        if (object[valueToCount] === true) {
            count++;
        }
    });

    return count;
};

/**
 * Checks if any element in arr1 is also in arr2.
 * @param {any[]} arr1 - the first array to check against
 * @param {any[]} arr2 - the second array to check against
 * @returns {boolean} - true if any element in arr1 is also in arr2, false otherwise
 */
export const arrsHaveSomeMatchingElement = (arr1: any[], arr2: any[]): boolean =>
    arr1.some((element: any) => arr2.indexOf(element) >= 0);

/**
 * ! DEPRECATED
 * Returns the later of two hours.
 * @param {string} hour1 - the first hour
 * @param {string} hour2 - the second hour
 * @param {string} [format=UI_TIME] - the format of the hours
 * @returns {string} the later of the two hours
 */
export const getLaterHour = (hour1: string, hour2: string, format = UI_TIME): string => {
    if (moment(hour1, format).diff(moment(hour2, format), 'hours') > 0) return hour1;
    return hour2;
};
/**
 * Returns the total size of the local storage in kilobytes.
 * @returns {number} The total size of the local storage in kilobytes.
 */
// export function getLocalStorageSize(): number {
//    let _lsTotal = 0;
//    let _xLen;
//    let _x;
//    for (_x in localStorage) {
//       if (!localStorage.hasOwnProperty(_x)) {
//          continue;
//       }
//       _xLen = (localStorage[_x].length + _x.length) * 2;
//       _lsTotal += _xLen;
//       // console.log(`${_x.substr(0, 50)} = ${(_xLen / 1024).toFixed(2)} KB`);
//    }
//    return _lsTotal / 1024;
// }

/**
 * Removes an object from an array.
 * @param {IObject} element - the object to remove from the array
 * @param {IObject[]} arr - the array to remove the object from
 * @returns {IObject[]} - the array with the object removed
 */
export const removeObjFromArr = (element: IObject, arr: any[]): IObject[] => {
    return arr.filter((filterElement: any) => JSON.stringify(filterElement) !== JSON.stringify(element));
};

/**
 * Logs an error to local storage.
 * @param {any} error - the error to log.
 * @param {string} [msg] - a message to add to the error.
 * @returns None
 */
// export const logToLocalStorage = (error: any, msg = '') => {
//    if (getLocalStorageSize() > 4900) {
//       localStorage.setItem('errors', JSON.stringify([])); // clearing errors array
//    }
//    const errs = JSON.parse(localStorage.getItem('errors') || '[]');
//    errs.push({ time: moment(), error, msg });
//    localStorage.setItem('errors', JSON.stringify(errs));
// };

/**
 * Takes in an array and returns a deep copy of the array sorted using the given sort function.
 * @param {any[]} arr - the array to sort
 * @param {Function} [sortFunc] - the sort function to use.
 * @returns {any[]} a deep copy of the array sorted using the given sort function.
 */
export const sortWithCopy = (arr: any[], sortFunc?: (a: any, b: any) => any): any[] => {
    if (!sortFunc) {
        sortFunc = (a: any, b: any) => a - b;
    }
    return deepClone<any[]>(arr).sort(sortFunc);
};

export const getCurrDateAsString: () => string = () => new Date().toISOString();

/**
 * Gets the error message from an error object.
 * @param {unknown} err - the error object.
 * @param {string} defaultMsg - the default message to use if the error object is not an instance of Error.
 * @param {boolean} useDefaultPrefix - whether or not to prefix the default message with "caught error - "
 * @returns {string} the error message.
 */
export const getErrorMessage = (err: unknown = {}, defaultMsg = '', useDefaultPrefix = true): string => {
    if (err instanceof Error) {
        return err.message;
    }
    return useDefaultPrefix ? `caught error - ${defaultMsg}` : defaultMsg;
};
/**
 * Returns whether the application is running in production mode.
 * @returns {boolean} - Whether the application is running in production mode.
 */
// export const isEnvProduction: () => number = () => Number(process.env.REACT_APP_IS_PROD || '0');
export const isEnvProduction: () => number = () => 1;

/**
 * Returns the Maps API key from the environment variable.
 * @returns The Maps API key.
 */

export enum EnvKeys {
    GoogleMapsApiKey = 'REACT_APP_MAPS_JS_API_KEY',
    VersionNumber = 'REACT_APP_VERSION_NUMBER',
    IsProduction = 'REACT_APP_IS_PROD',
    NodeEnvironment = 'NODE_ENV',
    GoogleMapsApiKeyNoRestrict = 'REACT_APP_MAPS_JS_API_KEY_NO_RESTRICT',
}

export const getMapsAPIKey: () => string = () => process.env[EnvKeys.GoogleMapsApiKey] || '';
export const getMapsAPIKeyNoRestrict: () => string = () =>
    process.env[EnvKeys.GoogleMapsApiKeyNoRestrict] || '';

/**
 * Checks if the given string is of the given length.
 * @param {string} str - the string to check the length of.
 * @param {number} [min=-Infinity] - the minimum length of the string.
 * @param {number} [max=Infinity] - the maximum length of the string.
 * @returns {boolean} - true if the string is of the given length, false otherwise.
 */
export const isStrOfLength = (str: string, min = -Infinity, max = Infinity): boolean => {
    if (str.length < min || str.length > max) {
        return false;
    }
    return true;
};

/**
 * Takes in an array of immutable objects and a new immutable object.
 * If the new immutable object is not in the array, it is pushed into the array in place.
 * @param {Immutable[]} arr - the array of immutable objects.
 * @param {Immutable} newVal - the new immutable object.
 * @returns {Immutable[]} - the array with the new immutable object in it.
 */
export const pushIfNotExists = (arr: Immutable[], newVal: Immutable): Immutable[] => {
    if (!arr.includes(newVal)) {
        arr.push(newVal);
    }
    return arr;
};

/**
 * Takes in an array of DateObj and a DateObj. If the DateObj is not already in the array, it will be added.
 * @param {DateObj[]} arr - the array of DateObj's to check for the DateObj.
 * @param {DateObj} date - the DateObj to check for in the array.
 * @returns {DateObj[]} - the array of DateObj's with the DateObj added if it was not already in the array.
 */
export const pushDateIfNotExists = (arr: DateObj[], date: DateObj): DateObj[] => {
    let found = false;
    arr.forEach((arrDate) => {
        if (isSameDate(arrDate, date)) {
            found = true;
        }
    });
    if (!found) {
        arr.push(date);
    }
    return arr;
};

/**
 * Removes the first occurrence of the given element from the given array.
 * @param {any[]} arr - the array to remove the element from
 * @param {any} element - the element to remove from the array
 * @returns None
 */
export const removeFirstOccurrence = (arr: any[], element: any): any[] => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === element) {
            arr.splice(i, 1);
            break;
        }
    }
    return arr;
};

/**
 * Generates a random UUID.
 * @returns {string} A random UUID.
 */
export const generateWeakUuid = (): number => Math.floor(Math.random() * Date.now());

/**
 * Removes the given item from the given array.
 * @param {any[]} arr - the array to remove the item from
 * @param {any} itemToRemove - the item to remove from the array
 * @returns {any[]} the array with the item removed
 */

type Immutables = string | number | boolean | null | undefined;
export const createArrWithoutItem = (arr: any[], itemToRemove: Immutables, key?: string): any[] => {
    if (key) {
        return arr.filter((currItem: IObject) => currItem[key] !== itemToRemove);
    } // else
    return arr.filter((currItem: Immutables | IObject) => currItem !== itemToRemove);
};

/**
 * Adds an item to an array return new deep copied array.
 * @param {any[]} arr - the array to add the item to
 * @param {any} item - the item to add to the array
 * @returns {any[]} - the array with the item added
 */
export const createArrWithAddedItem = (arr: any[], item: any): any[] => {
    const arrCopy = _.cloneDeep(arr);
    arrCopy.push(item);
    return arrCopy;
};

/**
 * Returns true if the value is not undefined or null.
 * @param {any} val - the value to check
 * @returns {boolean} - true if the value is not undefined or null
 */
export const notUndefinedOrNull = (val: any) => val !== undefined && val !== null;
// const TestFormatDateToString = (
//    date: string,
//    wantedFormat: string,
//    currFormat: string | undefined,
// ) => {
//    const params = {
//       // date,
//       // currFormat,
//    };
//    if (!date) {
//       // throw valueCanNotBeFalsyError;
//       console.log('no date provided');
//       return -1;
//    }
//    if (!currFormat) {
//       console.log('no curr format provided');
//       return 1;
//    }
//    if (date.length !== currFormat.length) {
//       console.log('format mismatch - length');
//       return -1;
//    }
//    if (date.includes('-') && !currFormat.includes('-')) {
//       console.log('format mismatch - separator', params);
//       return -1;
//    }
//    if (date.includes('.') && !currFormat.includes('.')) {
//       console.log('format mismatch - separator', params);
//       return -1;
//    }
// }

/**
 * Adds or updates an item in an array.
 * @param {string} key - the key to use to find the item in the array.
 * @param {TItem} newData - the new data to add or update.
 * @param {TItem[]} arr - the array to add or update the item in.
 * @returns {TItem[]} - the new array with the item added or updated.
 */
export const addOrUpdateArr = <TItem>(key: string, newData: TItem, arr: TItem[]): TItem[] => {
    const arrCopy = _.cloneDeep(arr);
    let updated = false;
    const newArr = arrCopy.map((currItem) => {
        if (currItem[key] === newData[key]) {
            updated = true;
            return newData;
        }
        return currItem;
    });
    if (!updated) {
        newArr.push(newData);
    }
    return newArr;
};
/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
export function getDaysInMonthUTC(month: number, year: number): Date[] {
    const date = new Date(Date.UTC(year, month, 1));
    const days: Date[] = [];
    while (date.getUTCMonth() === month) {
        days.push(new Date(date));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

export const isTruthy = (val: any): boolean => {
    return !!val;
};
export function isEven(n: number): boolean {
    return n % 2 === 0;
}

// ! DEPRECATED
export const lorem: (length: number) => string = (length: number) => {
    let result = '';
    const characters = 'abcd efgh ijkl mnop qrst uvw xyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

// build key to index dict
/**
 * Builds a dictionary that maps the value of a key to the index of the item in the array.
 * @param {any[]} arr - the array to build the dictionary from.
 * @param {string} key - the key to map the value of to the index of the item in the array.
 * @returns {[key: string]: number} - the dictionary mapping the value of the key to the index of the item in the array.
 */
export const buildKeyToIndexDict: (arr: any[], key: string) => { [key: string]: number } = (arr, key) => {
    const dict = {};
    arr.forEach((currItem, index) => {
        dict[currItem[key]] = index;
    });
    return dict;
};

// remove duplicate values from arr
export const removeDuplicates: (arr: string[], removeEmpty?: boolean) => string[] = (
    arr,
    removeEmpty = false
): string[] => {
    return arr.filter((item, index) => {
        if (removeEmpty && !item) return false;
        return arr.indexOf(item) === index;
    });
};

export const removeDuplicatesObjs: <T>(arr: T[], removeEmpty?: boolean) => T[] = (
    arr,
    removeEmpty = false
) => {
    return arr.filter((ele1) => {
        if (removeEmpty && !ele1) return false;
        return !!arr.some((ele2) => JSON.stringify(ele2) === JSON.stringify(ele1));
    });
};

/**
 * Gets the start date of the target's day num;
 * @param {number} target - the target day number of the week (0 = Sunday, 1 = Monday, etc.)
 * @param {number} curr - the current day number of the week (0 = Sunday, 1 = Monday, etc.)
 * @param {Date} currDate - the current date
 * @returns {Date} The target's day num actual date
 */
export const getStartDateByTargetDayNum = (target: number, curr: number, currDate: Date) => {
    const daysToSubtract = curr >= target ? curr - target : 7 - (target - curr);
    return moment(currDate).clone().subtract(daysToSubtract, 'd').toDate();
};

/**
 * Returns the start and end dates of the week that the given date falls in.
 * @param {number} targetNum - the target day number of the week.
 * @param {Moment | Date} currDate - the current date.
 * @returns {Object} - an object with the start and end dates of the week.
 */
export const getWeekStartAndEndDates: (
    targetNum: number,
    currDate: Moment | Date
) => {
    start: Date;
    end: Date;
} = (targetNum, currDate) => {
    if (targetNum === -1) {
        return {
            start: moment(currDate).toDate(),
            end: moment(currDate).toDate(),
        };
    }
    const start = getStartDateByTargetDayNum(targetNum, moment(currDate).day(), moment(currDate).toDate());
    const end = moment(start).clone().add(6, 'd').toDate();
    return { start, end };
};
export const addWithoutMenuItem = (menuItems: any[]) => {
    const r = [...menuItems];
    r.unshift({ name: i18n.t('without'), value: FillerValues.Without });
    return r;
};
export interface BaseDepartment {
    departmentName: string;
    code: string;
}
export const getDepartmentFilterMenuItems = (
    departments: BaseDepartment[],
    loggedUserDepartmentCode: string | null
) => {
    let usersDept: null | BaseDepartment = null;

    if (loggedUserDepartmentCode) {
        usersDept =
            departments.find((dept) => String(dept.code) === String(loggedUserDepartmentCode)) || null;
    }

    const menuItems = usersDept
        ? [
              {
                  name: usersDept.departmentName,
                  value: usersDept.code,
              },
          ]
        : [
              {
                  name: i18n.t('all'),
                  value: FillerValues.All,
              },
              {
                  name: i18n.t('withoutDepartment'),
                  value: FillerValues.Without,
              },
          ];

    try {
        departments.forEach((department) => {
            if (usersDept && department.code === usersDept.code) return;

            menuItems.push({
                name: department.departmentName,
                value: department.code,
            });
        });
    } catch (error) {
        console.log(error);
        return menuItems;
    }
    return menuItems;
};

/**
 * Checks if the given object is a dictionary, i.e {...}, and not an array, function, date etc
 * @param {any} obj - the object to check
 * @returns {boolean} - true if the object is a dictionary, false otherwise
 */
export const isDict = (obj: any): boolean => {
    if (!obj) return false;
    return obj && typeof obj === 'object' && obj.constructor === Object;
};

export const isDictV2 = (obj: any): obj is Record<string, unknown> => {
    try {
        if (!obj) return false;
        return obj && typeof obj === 'object' && obj.constructor === Object;
    } catch (error) {
        return false;
    }
};

// ! DEPRECATED
export const getTimeBetweenDates = (
    start: Date | Moment,
    end: Date | Moment,
    type?: 'minutes' | 'seconds'
): number => {
    const date1 = moment(start).toDate();
    const date2 = moment(end).toDate();

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    if (type === 'minutes') {
        return diffInTime / 1000 / 60;
    }
    return diffInTime / 1000;
};

// is valid number
export const isValidNumber = (num: string): boolean => {
    try {
        if (!num) return false;

        return !Number.isNaN(parseFloat(num)) && Number.isFinite(parseFloat(num));
    } catch (e) {
        return false;
    }
};

export const uuid = v4;

// is uuid
export const isUuid = (str: string): boolean => {
    return str && str.length > 20 && str.length < 60 ? true : false;
};

export const filtersHelpers = {
    getDepartmentFilterMenuItems,
    addWithoutMenuItem,
};

// google Api Counter Interface

export const initialGoogleApiCounter = async (accountCode: number) => {
    const localStorageClientGoogleCounter = getLocalStorageValue(StorageKeys.ClientGoogleApiCounter);
    const localStorageGoogleApiAccountCode = getLocalStorageValue(StorageKeys.GoogleApiAccountCode);

    if (
        localStorageClientGoogleCounter &&
        localStorageGoogleApiAccountCode !== null &&
        localStorageGoogleApiAccountCode !== undefined
    ) {
        // needs to send adam the counter and account code from local storage

        const clientCounter = Number(localStorageClientGoogleCounter);

        if (!Number.isNaN(clientCounter)) {
            try {
                const res: AxiosResponse<GoogleCounterApiResponse> = await sendClientCounter({
                    account_code: localStorageGoogleApiAccountCode,
                    client_counter: clientCounter,
                });
            } catch (error) {
                console.log('error', error);
            }
        }
    }

    // reset

    setLocalStorageValue(StorageKeys.ClientGoogleApiCounter, '0');
    setLocalStorageValue(StorageKeys.GoogleApiAccountCode, accountCode.toString());
};

export const updateGoogleApiCounter = async (accountCode: number, interval: number) => {
    const counterString = getLocalStorageValue(StorageKeys.ClientGoogleApiCounter);

    if (counterString) {
        let counter = Number(counterString);

        if (!Number.isNaN(counter)) {
            counter++;

            if (counter >= interval) {
                try {
                    const res: AxiosResponse<GoogleCounterApiResponse> = await sendClientCounter({
                        account_code: accountCode.toString(),
                        client_counter: counter,
                    });

                    if (res && res.data && res.data.IsSuccess) {
                        setLocalStorageValue(StorageKeys.ClientGoogleApiCounter, '0');
                        return res.data;
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }

            setLocalStorageValue(StorageKeys.ClientGoogleApiCounter, counter.toString());
        }
    }

    return null;
};

export const getGoogleApiInterfaceStatus = async (accountCode: number) => {
    try {
        const res: AxiosResponse<GoogleCounterApiResponse> = await sendClientCounter({
            account_code: accountCode.toString(),
            client_counter: 0,
        });

        if (res && res.data) {
            return res.data;
        }

        return null;
    } catch (error) {
        console.log('error');
    }
};

export const isGoogleApiInterfaceCounterExceeded = (res: GoogleCounterApiResponse): boolean => {
    let isExceeded: boolean = false;

    if (res && res.IsCounterExceeded) {
        isExceeded = true;
    }

    return isExceeded;
};
