import { Box } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { FunctionComponent, CSSProperties } from 'react';
import useLineStatusTranslate from 'src/screens/Main/components/Lines/hooks/useLineStatusTranslate';
import { LineStatus } from 'src/types/line';

const LineStatusColumn: FunctionComponent<GridCellProps> = ({ dataItem }: GridCellProps) => {
    const lineStatusTranslate = useLineStatusTranslate();

    let color = '';
    if (dataItem.lineStatus !== LineStatus.Undifined)
        color = dataItem.lineStatus === LineStatus.Ended ? '#23DD04' : '#EBB835';

    const borderStyle: CSSProperties =
        (color && {
            borderRight: `solid 2px ${color}`,
        }) ||
        {};
    return (
        <td className="Line-status-column">
            <Box
                style={borderStyle}
                display="flex"
                flexDirection="column"
                height="40px"
                alignItems="center"
                justifyContent="center"
            >
                <div>{lineStatusTranslate(dataItem.lineStatus)}</div>
            </Box>
        </td>
    );
};

export default LineStatusColumn;
