import { useSelector } from 'react-redux';
import useCommons from 'src/hooks/common/useCommons';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    optimizationsRootSelector,
    setIsCsvLoadDoneModalOpenAction,
    setIsGeneralLoadingAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { WsResponse } from 'src/api/types';
import { setOptimizationStations, setServerOptimizationWaypoints } from '../api/optimizationApi';
import { IOptimizationCsvWaypoint, IOptimizationWaypoint } from '../api/types';
import { useGetOptimizationWaypoints } from './useGetOptimizationWaypoints';

export const useSetOptimizationStations = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));

    const {
        ui: { clientCode },
        data: {
            getSimulationResult: { data: simulationResult },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();

    const handleSetOptimizationStationsResponse = async (res: WsResponse<IOptimizationWaypoint[]>) => {
        const responseNum = Number(res.response);

        dispatch(setIsGeneralLoadingAction({ isLoading: false }));

        if (responseNum === 0) {
            dispatchAlert('success', 'תחנות נשמרו בהצלחה');
        } else {
            dispatchAlert('error', t('errorReceived'));
        }
    };

    const setOptimizationWaypoints = async () => {
        try {
            if (
                selectedFcAccount &&
                selectedFcAccount.dbUrl &&
                clientCode &&
                simulationResult &&
                simulationResult.stationToWayPoints
            ) {
                // tamir - to remove "wayPointPlaceId" before sending sybase

                const stationsToSend: any = [];

                simulationResult.stationToWayPoints.forEach((station) => {
                    const { waypointsId, wayPointsPlaceId, ...rest } = station;

                    stationsToSend.push(rest);
                });

                const res = await setOptimizationStations({
                    dbUrl: selectedFcAccount?.dbUrl,
                    requestParams: {
                        token,
                        jsonData: JSON.stringify(stationsToSend),
                        clientCode,
                    },
                });

                if (res && res.data) {
                    handleSetOptimizationStationsResponse(res.data);
                } else {
                    dispatchAlert('error', t('errorReceived'));
                }
            } else {
                dispatchAlert('error', t('errorReceived'));
            }
        } catch (error) {
            dispatchAlert('error', t('errorReceived'));
            console.log('error');
        }
    };

    return {
        setOptimizationWaypoints,
    };
};
