import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import { images } from 'src/assets';
import { Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Styles from 'src/screens/ManualOrder/ManualOrderTable/Shiftcell/Shiftcell.Style';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    onConfirm: () => void;
    Body: () => JSX.Element;
    loading?: boolean;
    Img: string | (() => JSX.Element);
    isRedirectPopup?: boolean;
    height?: number;
    width?: number;
}

export const DEFAULT_BODY_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    paddingRight: '11px',
} as const;

// Generic version of the delete modal component
const ActionConfirmModal: FC<Props> = ({
    isOpen,
    onClose,
    onConfirm,
    Body,
    loading: showSpinner,
    Img,
    height,
    width,
    isRedirectPopup,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={isRedirectPopup ? onConfirm : onClose}>
            <Styles.ModalContainer
                style={{ height: height ? `${height}px` : '203px', width: width ? `${width}px` : '293px' }}
            >
                <Styles.CircleModal>
                    {typeof Img === 'string' ? <img src={Img} alt="img" /> : <Img />}
                </Styles.CircleModal>
                <Styles.ModalBody>{Body()}</Styles.ModalBody>
                <Styles.ModalAction>
                    <LoadingButton onClick={onConfirm} color="primary" loading={showSpinner}>
                        {t('ok')}
                    </LoadingButton>
                    {!isRedirectPopup && (
                        <Button onClick={onClose} style={{ color: 'gray' }}>
                            {t('cancel')}
                        </Button>
                    )}
                </Styles.ModalAction>
            </Styles.ModalContainer>
        </Dialog>
    );
};

export default ActionConfirmModal;
