import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedCourse, helpers, TabsTypes, SEPARATOR } from './common';
import useCommons from 'src/hooks/common/useCommons';
import { DAYS_HE_SHORT } from 'src/constants/dates';
import { IExtendedCourseData } from 'src/types/coursesBuilding/commonTypes';
import { isLastItem } from '../../../RideSettings/utils/general';
import { StyledCourseTypeTabs as Styled } from './styles';
import SYMBOLS from 'src/constants/symbols';

const TimesSchedule = ({ timesByDay }: { timesByDay: IExtendedCourseData[] }) => {
    const { t } = useTranslation();

    const groupedTimesByDay = useMemo(
        () => helpers.groupByDays(helpers.groupByTimes(timesByDay)),
        [timesByDay]
    );

    return (
        <div>
            {groupedTimesByDay
                ? groupedTimesByDay.map(({ time: stringifiedTimes, days }) => {
                    const parsedTimesObjs = stringifiedTimes
                        .split(SEPARATOR)
                        .map((timeStr) => JSON.parse(timeStr));

                    return (
                        <Styled.DetailLineContainer>
                            <Styled.Title>
                                {days.length ? (
                                    <>
                                        <span>
                                            {days.length === 1 ? t('day') : t('days')}
                                            {SYMBOLS.SPACE}
                                        </span>
                                        {days.map((day, i) => (
                                            <span>
                                                {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/*  @ts-ignore */}
                                                {`${DAYS_HE_SHORT[day]}`}
                                                {isLastItem(i, days) ? ' - ' : `, `}
                                            </span>
                                        ))}
                                    </>
                                ) : null}
                            </Styled.Title>
                            <Styled.ValueText>
                                {parsedTimesObjs.map((timeObj, i) => (
                                    <span>
                                        {timeObj.start} - {timeObj.end}
                                        {isLastItem(i, parsedTimesObjs) ? (
                                            ''
                                        ) : (
                                            <span style={{ color: '#B4BEC9' }}>{` | `}</span>
                                        )}
                                    </span>
                                ))}
                            </Styled.ValueText>
                        </Styled.DetailLineContainer>
                    );
                })
                : null}
        </div>
    );
};

const GeneralDetailsPanel = ({ isHidden, course }: { isHidden: boolean; course: ExtendedCourse }) => {
    const { t } = useCommons();

    const carNames = React.useMemo(() => {
        return course.courseTimes.reduce((acc: string[], curr) => {
            curr.carTypes.forEach((carType) => {
                const carName = carType.carTypeName;

                if (!carName || acc.includes(carName)) return;

                acc.push(carName);
            });

            return acc;
        }, []);
    }, [course.courseTimes]);

    const hasTimes = course.courseTimes.some((c) => c.times.length);

    return (
        <Styled.Container
            role="tabpanel"
            hidden={isHidden}
            id={`simple-tabpanel-${TabsTypes.GeneralDetails}`}
            className="Container"
        >
            {hasTimes ? (
                <Styled.Detail withoutBottomBorder={!carNames.length} className="Detail">
                    <TimesSchedule timesByDay={course.courseTimes} />
                </Styled.Detail>
            ) : null}
            {carNames.length ? (
                <Styled.Detail withoutBottomBorder={!course.courseRemarks} className="Detail">
                    <Styled.DetailLineContainer>
                        <Styled.Title>{t('activeCarTypes')}</Styled.Title>
                        <div>
                            {carNames.map((carName, i) => (
                                <Styled.ValueText>
                                    {carName}
                                    {isLastItem(i, carNames) ? '' : `, `}
                                </Styled.ValueText>
                            ))}
                        </div>
                    </Styled.DetailLineContainer>
                </Styled.Detail>
            ) : null}
            {course.courseRemarks ? (
                <Styled.Detail className="Detail" withoutBottomBorder>
                    <Styled.DetailLineContainer>
                        <Styled.Title>{t('remarks')}</Styled.Title>
                        <Styled.ValueText>{course.courseRemarks}</Styled.ValueText>
                    </Styled.DetailLineContainer>
                </Styled.Detail>
            ) : null}
        </Styled.Container>
    );
};

export default GeneralDetailsPanel;
