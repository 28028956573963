import React, { FC, useEffect, useState } from 'react';
import {
    TileLayout,
    TileLayoutItem,
    TileLayoutRepositionEvent,
    TilePosition,
} from '@progress/kendo-react-layout';
import OptimizationWidgetMap from './OptimizationWidgetMap';

const OptimizationWidgetLayoutContainer: React.FC<{}> = () => {
    const tiles: Array<TileLayoutItem> = [
        {
            item: <OptimizationWidgetMap />,
            reorderable: false,
            resizable: false,
        },
    ];

    const defaultTilePosition = [
        {
            col: 1,
            colSpan: 1,
            rowSpan: 14,
        },
    ];

    const [tilePosition, setTilePosition] = useState<Array<TilePosition>>(defaultTilePosition);

    const [layout, setLayout] = useState<Array<TilePosition>>(tilePosition);

    return (
        <TileLayout
            autoFlow="column"
            columns={1}
            rowHeight={60}
            positions={layout}
            gap={{
                rows: 10,
                columns: 10,
            }}
            style={{ height: '100%', padding: 0 }}
            items={tiles}
            className="tile-layout-course-building"
        />
    );
};

export default OptimizationWidgetLayoutContainer;
