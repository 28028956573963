import React, { useEffect, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getDepartments, getPassengerType } from 'src/api/api';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import { IProps as IDropDown } from 'src/components/DropDown/DropDown';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { useTranslation } from 'react-i18next';
import { InputProp } from 'src/components/commons/Input/Input';
import { IItem } from 'src/types/line';
import { IAddress, IPassenger } from 'src/api/passengerMangerApi/types';
import { IComboBox } from 'src/components/ComboBox/ComboBox';
import { TextField } from '@material-ui/core';
import { IRootReducer } from 'src/store/reducers';
import { passengerCitySelector } from 'src/store/selectores/passengersSelector';
import { useRootAppSelector } from 'src/store/hooks';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';

export interface DropDownState {
    options: IItem[];
    value: null | number | string;
}

export interface DropDownFilter {
    options: string[];
    value: string | undefined;
}

interface IProps {
    setFilters: Dispatch<SetStateAction<CompositeFilterDescriptor | undefined>>;
    clientId?: number | string | undefined | null;
}

interface IfilterHookResult {
    globalFilterProp: InputProp;
    cityFilterProp: IComboBox;
    departmentFilterProp: IDropDown;
    passengerTypeFilterProp: IDropDown;
    emplyeeeTypeFilterProp: IDropDown;
    restFilters: () => void;
}

const useFilters = ({ setFilters, clientId }: IProps): IfilterHookResult => {
    const { t } = useTranslation();

    const citys = useSelector((state: IRootReducer) => passengerCitySelector(state));

    const selectedFcAccount = useSelector((state: any) => selectedFcAccountSelector(state));

    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;

    const token = useSelector((state: any) => tokenSelector(state));

    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [cityFilterInput, setCityFilterInput] = useState<string>('');
    const [cityFilter, setCityFilter] = useState<string>('');
    const [departmentFilter, setDepartmentFilter] = useState<DropDownState>({
        options: [],
        value: loggedUserDepartmentCode || '',
    });
    const [passengerTypeFilter, setPassengerTypeFilter] = useState<DropDownState>({
        options: [],
        value: '',
    });
    const [emplyeeStatusFilter, setEmplyeeStatusFilter] = useState<DropDownState>({
        options: [
            { name: t('active'), value: '1' },
            { name: t('nonActive'), value: '0' },
        ],
        value: '1',
    });

    const restValueDropDown = (preState: DropDownState): DropDownState => ({
        ...preState,
        value: '',
    });

    //  const restValueComboBox = (preState: DropDownFilter): DropDownFilter => ({
    //      ...preState,
    //      value: '',
    //  });

    const restFilters = () => {
        setGlobalFilter('');
        setCityFilter('');
        setDepartmentFilter(restValueDropDown);
        setPassengerTypeFilter(restValueDropDown);
        setEmplyeeStatusFilter(restValueDropDown);
        setFilters(undefined);
    };

    const buildFilters = (): void => {
        const globalFilterArray: Array<FilterDescriptor> = globalFilter
            ? [
                  {
                      field: '',
                      operator: (item: IPassenger, currValue: string) => {
                          const passIdMatch = item.passId.toString().indexOf(currValue) !== -1;
                          return (
                              item &&
                              (passIdMatch ||
                                  item.firstName?.includes(currValue) ||
                                  item.lastName.includes(currValue) ||
                                  item.mobilePhone.includes(currValue))
                          );
                      },
                      value: globalFilter,
                      ignoreCase: true,
                  },
              ]
            : [];

        const cityFilterArray: Array<FilterDescriptor> = cityFilter
            ? [
                  {
                      field: 'address',
                      operator: (items: IAddress[], currValue: string) => {
                          return items?.some(
                              (p: IAddress) => p.isDefault === '1' && p.city?.includes(currValue)
                          );
                      },
                      value: cityFilter,
                      ignoreCase: true,
                  },
              ]
            : [];

        const departmentFilterArray: Array<FilterDescriptor> = departmentFilter.value
            ? [
                  {
                      field: 'departmentCode',
                      operator: 'eq',
                      value: departmentFilter.value.toString(),
                  },
              ]
            : [];

        const passengerTypeFilterArray: Array<FilterDescriptor> = passengerTypeFilter.value
            ? [
                  {
                      field: 'passTypeCode',
                      operator: 'eq',
                      value: `${passengerTypeFilter.value}`,
                  },
              ]
            : [];

        const emplyeeeStatusFilterArray: Array<FilterDescriptor> = emplyeeStatusFilter.value
            ? [
                  {
                      field: 'isActive',
                      operator: 'eq',
                      value: emplyeeStatusFilter.value,
                  },
              ]
            : [];

        const spesificFiltersArr = [
            ...cityFilterArray,
            ...departmentFilterArray,
            ...passengerTypeFilterArray,
            ...emplyeeeStatusFilterArray,
        ];

        if (globalFilterArray.length && spesificFiltersArr.length) {
            setFilters({
                logic: 'and',
                filters: [
                    { logic: 'and', filters: spesificFiltersArr },
                    { logic: 'or', filters: globalFilterArray },
                ],
            });

            return;
        }

        if (!globalFilterArray.length && spesificFiltersArr.length) {
            setFilters({
                logic: 'and',
                filters: spesificFiltersArr,
            });

            return;
        }

        if (globalFilterArray.length && !spesificFiltersArr.length) {
            setFilters({
                logic: 'or',
                filters: globalFilterArray,
            });

            return;
        }

        setFilters(undefined);
    };

    useEffect(buildFilters, [
        globalFilter,
        cityFilter,
        departmentFilter,
        passengerTypeFilter,
        emplyeeStatusFilter,
        setFilters,
    ]);

    useEffect(() => {
        const { dbUrl } = selectedFcAccount || {};

        if (token && dbUrl) {
            getPassengerType({
                dbUrl,
                token,
            }).then((res) => {
                const { passTypes } = res.data;
                setPassengerTypeFilter((preState) => ({
                    ...preState,
                    options: passTypes.map((d) => ({
                        value: d.passTypeCode,
                        name: d.passType,
                    })),
                }));
            });
        }
    }, [selectedFcAccount, clientId, token]);

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};

        if (selectedFcAccount && clientId) {
            getDepartments({
                proxyUrl,
                dbUrl: selectedFcAccount.dbUrl,
                token,
                clientCode: clientId,
            }).then((res) => {
                const { departments } = res.data;
                setDepartmentFilter((preState) => ({
                    ...preState,
                    options: departments.map((d) => ({
                        value: d.code,
                        name: d.departmentName,
                    })),
                }));
            });
        }
    }, [selectedFcAccount, clientId, token]);

    const globalFilterProp: InputProp = {
        value: globalFilter,
        label: t('search'),
        size: 'small',
        style: { width: '30%' },
        labelWidth: 50,
        onChange: (e: React.FormEvent<HTMLInputElement> | any) => {
            const { value } = e.currentTarget;
            setGlobalFilter(value);

            buildFilters();
        },
    };

    const cityFilterProp: IComboBox = {
        options: citys,
        style: { width: '16%' },
        getOptionLabel: (option: string) => option,
        renderInput: (params) => <TextField {...params} label={t('city')} size="small" variant="outlined" />,
        autoSelect: true,
        autoComplete: true,
        inputValue: cityFilterInput,
        value: cityFilter,
        onInputChange: (event, newInputValue) => {
            setCityFilterInput(newInputValue);
        },
        onChange: (event: React.ChangeEvent<{}>, newValue: string | undefined) => {
            setCityFilter(newValue || '');

            buildFilters();
        },
    };

    // const cityFilterProp: InputProp = {
    //    value: cityFilter,
    //    label: t('city'),
    //    size: 'small',
    //    style: { width: '19%' },
    //    labelWidth: 50,
    //    onChange: (e: React.FormEvent<HTMLInputElement> | any) => {
    //       const { value } = e.currentTarget;
    //       setCityFilter(value);

    //       buildFilters();
    //    },
    // };

    const dropDownplaceholder: IItem[] = [{ name: t('all'), value: '' }];

    const getDeptDropdownMenuItems = useCallback(() => {
        const menuItems = [...departmentFilter.options];

        if (!loggedUserDepartmentCode) {
            menuItems.unshift(...dropDownplaceholder);
        }
        return menuItems;
    }, [departmentFilter.options, dropDownplaceholder, loggedUserDepartmentCode]);

    const departmentFilterProp: IDropDown = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '16%' },
            size: 'small',
        },
        defaultValue: loggedUserDepartmentCode || -1,
        disabled: (!clientId && true) || false,
        autoWidth: false,
        multiple: false,
        labalName: t('department'),
        label: t('department'),
        menueItem: getDeptDropdownMenuItems(),
        native: false,
        value: departmentFilter.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | unknown;
            }>
        ) => {
            const { value } = event.target;

            if (typeof value === 'number' || typeof value === 'string') {
                setDepartmentFilter((preState) => ({ ...preState, value }));
            }

            buildFilters();
        },
    };

    const passengerTypeFilterProp: IDropDown = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '16%' },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        labalName: t('passengerType'),
        label: t('passengerType'),
        menueItem: [...passengerTypeFilter.options, ...dropDownplaceholder],
        native: false,
        value: passengerTypeFilter.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | unknown;
            }>
        ) => {
            const { value } = event.target;

            if (typeof value === 'number' || typeof value === 'string') {
                setPassengerTypeFilter((preState) => ({ ...preState, value }));
            }

            buildFilters();
        },
    };

    const emplyeeeTypeFilterProp: IDropDown = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '16%' },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        labalName: t('passengerStatus'),
        label: t('passengerStatus'),
        menueItem: [...emplyeeStatusFilter.options, ...dropDownplaceholder],
        native: false,
        value: emplyeeStatusFilter.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | unknown;
            }>
        ) => {
            const { value } = event.target;

            if (typeof value === 'number' || typeof value === 'string') {
                setEmplyeeStatusFilter((preState) => ({ ...preState, value }));
            }

            buildFilters();
        },
    };

    return {
        globalFilterProp,
        cityFilterProp,
        departmentFilterProp,
        passengerTypeFilterProp,
        emplyeeeTypeFilterProp,
        restFilters,
    };
};

export default useFilters;
