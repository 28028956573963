import { useCallback, useEffect, useState } from 'react';
import { StrictDropdownItem, IItem } from 'src/types/line';
import useLocalStorage from './useLocalStorage';

const useProvidersClientList = (providerClientsArg?: StrictDropdownItem[]) => {
    const [providersClients, setProvidersClients] = useState<StrictDropdownItem[]>(providerClientsArg || []);

    useEffect(() => {
        if (Array.isArray(providerClientsArg)) {
            setProvidersClients(providerClientsArg);
        }
    }, [providerClientsArg, providersClients]);

    return [providersClients, setProvidersClients] as const;
};

const useLastSelectedClient = (providerClientsArg?: StrictDropdownItem[]) => {
    const [allClients, setAllClients] = useProvidersClientList(providerClientsArg);

    const [lastSelectedClient, setLastSelectedClient] = useLocalStorage<StrictDropdownItem | null>(
        'selectedClient',
        null
    );

    const handleSetLastSelectedClient = useCallback(
        (val: StrictDropdownItem | null) => setLastSelectedClient(val),
        [setLastSelectedClient]
    );

    useEffect(() => {
        // -- if last selected client is NOT a client of the provider - Remove
        if (
            lastSelectedClient?.value &&
            allClients.length &&
            !allClients.some((client) => String(client.value) === lastSelectedClient?.value)
        ) {
            setLastSelectedClient(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(allClients), lastSelectedClient?.value, providerClientsArg]);

    const getNameByValue = useCallback(
        (value: string | null) => {
            return String(allClients.find((client) => client.value === value)?.name) || '';
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(allClients)]
    );

    return [lastSelectedClient, handleSetLastSelectedClient, setAllClients, getNameByValue] as const;
};

export default useLastSelectedClient;
