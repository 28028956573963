/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CoursesBuildingIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.59974 22.6777C6.63734 22.7398 6.51578 22.617 6.48319 22.4726C5.8967 19.8715 4.62846 17.6085 3.1146 15.462C2.5657 14.6845 2.03435 13.893 1.48294 13.1167C-0.0434494 10.9703 -0.19258 8.73008 1.1709 6.47596C2.56069 4.17752 4.66857 3.07706 7.31156 3.33793C10.0084 3.60387 11.8619 5.15009 12.7993 7.71826C13.3469 9.22143 13.3181 10.7575 12.5136 12.1771C11.7792 13.4726 10.9045 14.687 10.1801 15.9863C8.94071 18.2126 7.77148 20.4781 6.59974 22.6777V22.6777ZM10.1124 9.81028C10.1074 7.89935 8.51838 6.32147 6.63608 6.35946C4.78637 6.39618 3.27501 7.94874 3.28379 9.80395C3.29256 11.7313 4.85028 13.2763 6.75514 13.2459C8.60109 13.2155 10.1175 11.6642 10.1124 9.81028V9.81028Z"
                fill={color}
            />
            <path
                d="M19.7976 12.6753C19.8215 12.7137 19.7443 12.6377 19.7236 12.5484C19.3509 10.939 18.545 9.53876 17.583 8.21063C17.2341 7.72952 16.8965 7.2398 16.5461 6.75947C15.5761 5.43134 15.4813 4.04523 16.3478 2.65049C17.231 1.22833 18.5704 0.547422 20.25 0.708835C21.9638 0.873382 23.1416 1.83011 23.7373 3.41916C24.0853 4.34925 24.067 5.29971 23.5557 6.17807C23.089 6.97966 22.5332 7.73109 22.0729 8.53502C21.2853 9.91251 20.5422 11.3143 19.7976 12.6753V12.6753ZM22.0299 4.7136C22.0267 3.53121 21.0169 2.5549 19.8207 2.57841C18.6453 2.60113 17.6849 3.56177 17.6905 4.70969C17.696 5.90226 18.6859 6.8582 19.8964 6.8394C21.0694 6.82059 22.033 5.86073 22.0299 4.7136V4.7136Z"
                fill={color}
            />
            <path
                d="M19.4155 12.841C18.715 12.9828 17.9857 13.0474 17.3252 13.2969C16.9217 13.4501 16.3715 13.8693 16.3477 14.2036C16.3227 14.5531 16.7788 15.0495 17.146 15.2952C17.8277 15.7511 18.621 16.0322 19.3266 16.4552C21.9445 18.0267 21.9859 20.4632 19.4494 22.1791C17.9205 23.2137 16.1861 23.5544 14.4003 23.6038C12.2636 23.6633 10.1244 23.6177 7.87988 23.6177C8.25835 22.2817 8.63807 20.9394 9.02906 19.5565C10.9327 19.5565 12.8187 19.573 14.7048 19.5464C15.3163 19.5375 15.9617 19.5134 16.5269 19.3108C16.9818 19.1475 17.5947 18.7562 17.6686 18.3737C17.74 18.0014 17.2901 17.4341 16.9355 17.0922C16.4354 16.6084 15.77 16.3032 15.2537 15.8334C14.4166 15.0711 14.4241 14.3632 15.28 13.6223C16.4843 12.5789 17.9205 12.5333 19.4155 12.8423V12.841Z"
                fill={color}
            />
        </svg>
    );
}

export default CoursesBuildingIcon;
