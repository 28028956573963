import { GridRowModes } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getClientBudget } from 'src/api/reportsApi/reportsApi';
import { DISPLAY_UI } from 'src/constants/dates';
import { useRefreshToken } from 'src/hooks/api/useRefreshToken';
import { dropsDisplayHooks } from 'src/screens/DropsDisplay/hooks';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    filtersByReportSelector,
    departmentsSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    dataSelector,
    selectedReportNameSelector,
    clientIdSelector,
} from 'src/store/selectores/reportsSelectors';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { onFetchBudgetDataStatusUpdateAction } from 'src/store/slices/reports/reportsSlice';
import { ReportNames } from 'src/store/slices/reports/types';
import {
    errorMessagesI18 as translatedErrorMessages,
    ErrorMessages,
    HttpStatusCodes,
    ResponseTypes,
} from 'src/types/apiCommon.types';
import { stringifyDate } from 'src/utilis/utilis';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import { BudgetData } from 'src/types/reports/slice.types';

export interface IDepartmentsWsFiltering {
    departmentCodes: string;
    isAllDepartments: '1' | '0';
}

const useMultDepartmentsForFiltering: (departmentCodesOnFilter: string[]) => IDepartmentsWsFiltering = (
    departmentCodesOnFilter
) => {
    const allDepartmentsCodes: string[] = useRootAppSelector(departmentsSelector).map((d) => String(d.code));
    const loggedUserDepartmentCode: string | null = useRootAppSelector(userDetailsSelector).departmentCode;

    const isAllDepartments =
        allDepartmentsCodes.length === departmentCodesOnFilter.length && !loggedUserDepartmentCode
            ? '1'
            : '0';

    return {
        departmentCodes: departmentCodesOnFilter.join(', '),
        isAllDepartments,
    };
};

const useSingleDepartmentFilter: () => IDepartmentsWsFiltering = () => {
    // Selectors
    const departmentCodeOnFilter: string | undefined = useAppSelector(
        (state) => filtersSelector(state).departmentCode
    );
    const loggedUserDepartmentCode: string | null = useRootAppSelector(userDetailsSelector).departmentCode;
    const allDepartmentsCodes: string[] = useRootAppSelector(departmentsSelector).map((d) => String(d.code));

    // Variables
    const departmentCodesForFiltering: string[] =
        String(departmentCodeOnFilter) === '0' ? allDepartmentsCodes : [departmentCodeOnFilter || ''];

    return useMultDepartmentsForFiltering(departmentCodesForFiltering || [loggedUserDepartmentCode || '']);
};

const useDatesRange = () => {
    const { fromDate, toDate } = useAppSelector((state) => filtersSelector(state));
    const applyFiltersBtnClickCount: number = useAppSelector((state) =>
        selectApplyFiltersBtnClickCount(state)
    );

    const [datesAfterApply, setDatesAfterApply] = useState({ fromDate, toDate });

    useEffect(() => {
        setDatesAfterApply({ fromDate, toDate });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFiltersBtnClickCount]);

    const dateRangeStr = `${stringifyDate(datesAfterApply.fromDate, DISPLAY_UI)} - ${stringifyDate(
        datesAfterApply.toDate,
        DISPLAY_UI
    )}`;
    return { dateRangeStr, datesAfterApply };
};

const useBudgetApiData = (dateToUseForWsCall: Date) => {
    const dispatch = useAppDispatch();

    const reportName = useRootAppSelector(selectedReportNameSelector);
    const clientCode = useRootAppSelector(clientIdSelector);

    const relativeDate = stringifyDate(dateToUseForWsCall);

    const { refreshToken } = useRefreshToken();
    const { dbUrl, token } = useGetApiRequestBasicParams();

    const [tokenRefreshCount, setTokenRefreshCount] = useState(0);
    const TOKEN_REFRESH_LIMIT = 1;

    const fetchData = async () => {
        const handleError = (message: string) => {
            dispatch(onFetchBudgetDataStatusUpdateAction(null));
            dispatch(setErrorMessage(message));
        };

        try {
            if (!token || !dbUrl || !clientCode) return;

            // FC REQUEST

            const res = await getClientBudget({
                dbUrl,
                endpoint: 'ws_MyWayStudio_Get_Client_Budget',
                requestParams: {
                    token,
                    clientCode,
                    relativeDate,
                },
            });

            if (res.status === HttpStatusCodes.MissingWs || res.data.data === '') {
                handleError(translatedErrorMessages.missingWs);
                return;
            }

            if (res.status === HttpStatusCodes.Ok) {
                const processedData: BudgetData = {
                    dailyCost: res.data.data.dailyPrice,
                    totalVisasCost: res.data.data.monthlyPrice,
                    monthlyBudget: res.data.data.clientBudget,
                    currencyId: res.data.data.currencyId,
                };

                // Error - Bad token
                if (res.data.response === ResponseTypes.TokenExpiredOrDoesntExist) {
                    // Limiting recursion depth to TOKEN_REFRESH_LIMIT
                    if (tokenRefreshCount < TOKEN_REFRESH_LIMIT) {
                        await refreshToken();
                        fetchData();
                    }
                    setTokenRefreshCount((prev) => prev + 1);
                }
                setTokenRefreshCount(0);

                // -- Success
                if (res.data.response === ResponseTypes.Ok && Object.keys(res.data.data).length) {
                    dispatch(onFetchBudgetDataStatusUpdateAction({ data: processedData }));
                    return;
                }

                // Error - Unknown
                handleError(translatedErrorMessages.generalError);
            }

            // Error - Unknown
            handleError(translatedErrorMessages.generalError);
        } catch (error) {
            handleError(translatedErrorMessages.generalError);
        }
    };

    useEffect(() => {
        if (reportName === ReportNames.visasReport || reportName === ReportNames.passengersReport) {
            fetchData();
        }
    }, [reportName, relativeDate]);
};

const widgetsHooks = {
    useMultDepartmentsForFiltering,
    useSingleDepartmentFilter,
    useDatesRange,
    useBudgetApiData,
};

export default widgetsHooks;
