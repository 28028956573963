/* eslint-disable consistent-return */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';

export interface IColumnHook {
    overCell: React.ReactNode | any;
    headerSelectionValue?: boolean;
}

const useColumns = ({ overCell, headerSelectionValue }: IColumnHook): { columns: Array<GridColumnProps> } => {
    const { t } = useTranslation();
    const selectedPassengers = useSelector(
        (state: IRootReducer) => passengersUISelector(state).selectedPassengers
    );

    const { width } = window.screen;

    const getWidth = useCallback(
        (field: string) => {
            if (field === 'remarks' && width < 1660) return 50;
            if (field === 'remarks' && width < 1660) return 100;
        },
        [width]
    );

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'isSelected',
                headerSelectionValue,
                reorderable: false,
                width: 30,
                className: 'isSelected-col',
                resizable: false,
            },
            {
                field: 'passId',
                title: t('passengerCode'),
                editor: 'text',
                width: 100,
                minResizableWidth: 50,
            },
            {
                field: 'firstName',
                width: 90,
                title: t('privateName'),
                minResizableWidth: 50,
            },
            {
                field: 'lastName',
                width: 90,
                title: t('familyName'),
                minResizableWidth: 50,
            },
            {
                field: 'mobilePhone',
                title: t('mobilePhone'),
                minResizableWidth: 50,
                width: 110,
            },
            {
                field: 'departmentName',
                title: t('department'),
                editor: 'text',
                width: 70,
                minResizableWidth: 50,
            },
            {
                field: 'mainAdress',
                title: t('mainAdress'),
                editor: 'text',
                width: getWidth('mainAdress'),
                minResizableWidth: 50,
            },
            {
                field: 'remarks',
                title: t('remarks'),
                editor: 'text',
                // cell: overCell,
                width: getWidth('remarks'),
                minResizableWidth: 50,
            },
            {
                field: 'actions',
                title: t('actions'),
                editor: 'text',
                cell: overCell,
                headerClassName: 'k-header k-actions-cell-header',
                headerCell: () => null,
                width: 77,
                resizable: false,
            },
        ],
        [headerSelectionValue, t, getWidth, overCell]
    );

    return { columns };
};

export default useColumns;
