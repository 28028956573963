/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-continue */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import moment, { Moment } from 'moment';
import {
    IShifts,
    IPassengerShifts,
    IPassengerDate,
    IPickupDropOrder,
} from 'src/types/manualOrders/api.types';
import DATE_FORMATS from 'src/constants/dates';
import {
    deepClone,
    formatDateToString,
    getWeekStartAndEndDates,
    logToLocalStorage,
    removeFirstOccurrence,
    removeObjFromArr,
    stringifyDate,
} from 'src/utilis/utilis';
import store from 'src/store';
import { array } from 'yup/lib/locale';
import { isBefore } from 'date-fns';
import _ from 'lodash';
import { v4 } from 'uuid';
import { IShiftCapsule, ShiftToDelete } from 'src/store/slices/manualOrders/types';
import passengersShiftReudcer, {
    BlockedOrdersDatesForPassengers,
    IDateRangeNew,
} from '../../store/reducers/passengersShiftReudcer';
// eslint-disable-next-line import/no-cycle
import { TWO_WEEKS } from './components/PlacemenTransportation/components/Moredays/MoreDays';
import { ShallowBoolean } from '../../types/global';
import { GeneralDate, ShiftType } from './types';
import { IPlacment, PlacementDate } from './components/PlacemenTransportation/types';
import { valueCanNotBeFalsyError } from '../../errors/errors';
import { getLaterHour } from '../../utilis/utilis';
import { isRelativeDate } from './components/PlacemenTransportation/utilis';

export interface PassengerDates {
    passId: string;
    dates: IPassengerDate[];
}

const { SHORT, LONG, RELATIVE } = DATE_FORMATS;

export enum ShiftID {
    Noon = 1,
    Evening,
    SaturdayMorning,
    EveningNoon,
    SaturdayEvening,
    SpicalDay,
    Saturday,
}

// type ColorShiftType = { [id in ShiftID]: string };
const colors = [
    '#E6F6FE',
    '#F4E2A6',
    '#E8E8F8',
    '#A4D0E5',
    '#919AEE',
    '#C4D5DD',
    '#CEB7FF',
    '#B1B1D1',
    '#D6B769',
];
const shiftColors: { [key: number]: string } = {};
/**
 * Creates a map of colors that can be used to shift colors.
 * @returns None
 */
const createShiftColors = () => {
    let colorIdx = 0;
    for (let i = 1; i < 100; i++) {
        shiftColors[String(i)] = colors[colorIdx];
        colorIdx++;
        if (colorIdx === 8) colorIdx = 1;
    }
};
createShiftColors();

export interface OrderTimesProp {
    pickupTime: string;
    dropTime: string;
}
export const UI_TIME = 'hh:mm';

export const nonDisplayStyle = {
    display: 'none',
};
export const displayWithRedFontStyle = {
    color: '#cf003e',
};

export const isBeforeNineAM = (hour: string): boolean => {
    const diff = moment(hour, 'hh:mm').diff(moment('09:00', 'hh:mm'), 'hours');
    return diff < 0 && diff > -9;
};

export const isPlacedOnNextDay = (orderTimes: OrderTimesProp) => {
    const { dropTime, pickupTime } = orderTimes;
    if (!pickupTime) return false;
    if (dropTime === getLaterHour(dropTime, pickupTime)) return false;
    if (isBeforeNineAM(dropTime)) return true;
    return false;
};

/**
 * Gets the valid dates for a shift.
 * @param {number} shiftId - the shift id to get the valid dates for.
 * @param {IShifts[]} shifts - the array of shifts to get the valid dates from.
 * @returns {string[]} - the valid dates for the shift.
 */
export const getShiftValidDates = (shiftId: number, shifts: IShifts[]): string[] => {
    const currShift = shifts.find((s) => s.shiftId === shiftId);

    const validDates = currShift?.dates.filter((d) => +d.isActive).map((d) => d.relativeDate.split(' ')[0]);

    return validDates || [];
};

/**
 * Gets the color shift value for the given shift ID.
 * @param {ShiftID} id - the ID of the color shift to get.
 * @returns The color shift value for the given ID.
 */
export const getColorShift = (id: number | string | null): string => {
    if (!id) return '';
    if (typeof id === 'string') {
        return shiftColors[+id];
    }
    // console.log(shiftColors);
    return shiftColors[id];
};

export const getShiftColorById: (shiftId: string, allShifts: IShifts[]) => string = (
    shiftId,
    allShifts
): string => {
    const shiftIndex = allShifts.find((s) => String(s.shiftId) === shiftId)?.shiftIndex;
    return getColorShift(shiftIndex || null);
};

export const getOrdersCount = (orders: IShiftCapsule[]) => {
    let ordersCount = 0;
    orders.forEach((order: any) => {
        if (order.startTime) ordersCount += 1;
        if (order.endTime) ordersCount += 1;
    });
    return ordersCount;
};

export const getSelectedDaysRange = (
    startDate: moment.MomentInput,
    endDate: moment.MomentInput
): IDateRangeNew => {
    const startingDate = startDate ? moment(startDate).format(DATE_FORMATS.SHORT) : null;

    const endingDate = endDate ? moment(endDate).format(DATE_FORMATS.SHORT) : null;
    return { startingDate, endingDate };
};

/**
 * Finds the first date that is not in the past.
 * @param {Moment} currStartingDate - the starting date to check against.
 * @returns {string} the first date that is not in the past.
 */
export const findFirstNonPastDate = (currStartingDate: Moment): string => {
    const currDate = moment();
    if (moment(currDate).diff(currStartingDate, 'day') < 0) return moment(currStartingDate).format(RELATIVE);
    return moment(currDate).format(RELATIVE);
};

/**
 * Gets the blocked days for a shift.
 * @param {any[]} shifts - the array of shifts.
 * @param {any} shiftId - the id of the shift to get the blocked days for.
 * @returns {relativeFormatString[]} - the array of blocked days for the shift in relative format.
 */
export const getShiftBlockedDays = (shifts: any[], shiftIds: number[], all = false) => {
    let selectedShifts = shifts;
    if (!all) {
        selectedShifts = shifts.filter((shiftData: { shiftId: any }) => {
            return shiftIds.includes(shiftData.shiftId);
        });
    }

    // console.log({ shifts, selectedShifts, shiftIds });

    const blockedDays: Moment[] = [];

    selectedShifts.forEach((shift) => {
        shift.dates.forEach((date: { isActive: string; relativeDate: any }) => {
            if (date.isActive === '0') {
                blockedDays.push(moment(date.relativeDate, RELATIVE));
            }
        });
    });
    return blockedDays;
};

export const getBlockedDaysOfShift = (shiftData: IShifts) => {
    const blockedDays: string[] = [];
    shiftData.dates.forEach((date: { isActive: string; relativeDate: any }) => {
        if (date.isActive === '0') {
            blockedDays.push(date.relativeDate.split(' ')[0]);
        }
    });
    // console.log({ blockedDays, shiftData: shiftData.shiftName });
    return blockedDays;
};

/**
 * Checks if the given day is on the blocked days list for the given shift.
 * @param {Moment[]} blockedDaysForShift - the list of blocked days for the shift.
 * @param {Moment} day - the day to check against the blocked days list.
 * @returns {boolean} - true if the day is on the blocked days list, false otherwise.
 */

export const isDayOnBlockedList = (blockedDaysForShift: Moment[], day: Moment): boolean => {
    let dayIsBlocked = false;
    blockedDaysForShift.forEach((blockedDay) => {
        if (moment(blockedDay).isSame(day, 'day')) dayIsBlocked = true;
    });
    if (dayIsBlocked) return true;
    return false;
};

export const rangeDate = (
    currDate: string,
    daysToAdd: number,
    firstDayOfWeek?: number
): { startDate: Moment; endDate: Moment } => {
    if (!currDate || !isRelativeDate(currDate)) {
        console.log('Invalid date', currDate);
        // throw valueCanNotBeFalsyError;
        return { startDate: moment(), endDate: moment() };
    }

    const date = moment(currDate, RELATIVE);
    // console.log(date);
    const weekdays = moment(date).get('weekdays');

    if (typeof firstDayOfWeek === 'number') {
        const dates = getWeekStartAndEndDates(firstDayOfWeek, date);
        return { startDate: moment(dates.start), endDate: moment(dates.end) };
    }
    const startDate = moment(date).subtract(weekdays, 'd');
    const endDate = moment(startDate).add(daysToAdd, 'd');

    return { startDate, endDate };
};

/**
 * Takes in an array of shift ids and returns an array of shift objects.
 * @param {number[]} shiftIds - the array of shift ids to filter by.
 * @param {IShifts[]} allShifts - the array of all shifts to filter.
 * @returns {IShifts[]} - the array of shifts that match the shiftIds array.
 */
export const getShiftsDataByIds = (shiftIds: number[], allShifts: IShifts[]): IShifts[] => {
    if (!shiftIds.length) return [];
    return allShifts.filter((shift: { shiftId: number }) => shiftIds.includes(shift.shiftId));
};

export const getBlockedDaysOfSelectedShifts = (
    shifts: IShifts[],
    selectedShiftsIds: number[],
    useRelativeFormat?: boolean
): any[] => {
    const selectedShifts = getShiftsDataByIds(selectedShiftsIds, shifts);

    const blockedDays: any[] = [];
    if (selectedShifts.length) {
        selectedShifts.forEach((shift: IShifts) =>
            shift.dates.forEach((dateConfig) => {
                const relativeDate = dateConfig.relativeDate.split(' ')[0];
                if (+dateConfig.isActive) return;
                blockedDays.push(useRelativeFormat ? relativeDate : moment(relativeDate, LONG));
            })
        );
    }
    return blockedDays;
};

export const hasTwoShiftsOnSelectedDate = (
    passengerOrdersForSelectedDates: any,
    orders: IPlacment
): boolean => {
    return passengerOrdersForSelectedDates.some((shiftsData: any) => {
        const shiftCount = shiftsData.pickupOrders.length + shiftsData.dropOrders.length;
        if (shiftCount >= 3) return true;
        return false;
    });
};
const getPlacementsDates = (placement: IPlacment, format: string): string[] => {
    return placement.dates.map((placementDate: { relativeDate: any }) =>
        formatDateToString(placementDate.relativeDate, format)
    );
};

/**
 * Determines if a passenger will get more then two shifts on one day.
 * @param {IPlacement} placement - the placement object
 * @param {any[]} selectedPassengersFullData - the full data of the selected passengers
 * @returns {boolean} - true if the passenger will get more then two shifts on one day, false otherwise
 */
export const passengerWillGetMoreThenTwoShiftsOnOneDay = (
    placement: IPlacment,
    selectedPassengersFullData: any[]
): boolean => {
    const selectedDaysAsRelativeDate = getPlacementsDates(placement, RELATIVE);

    const hasTwoShifts: boolean = selectedPassengersFullData.some((passenger: { dates: any[] }) => {
        const passengerOrdersForSelectedDates = passenger.dates.filter((passengerShiftDate: any) => {
            if (selectedDaysAsRelativeDate.includes(passengerShiftDate.relativeDate)) return true;
        });
        return hasTwoShiftsOnSelectedDate(passengerOrdersForSelectedDates, placement);
    });

    return hasTwoShifts;
};

/**
 * Checks if a passenger has two orders on a given date.
 * @param {GeneralDate} date - the date to check for two orders.
 * @param {string} passengerId - the passenger to check for two orders.
 * @param {BlockedOrdersDatesForPassengers} ordersCountForPassengers - the object that holds the count of orders for each passenger.
 * @returns {ShallowBoolean} - true if the passenger has two orders on the given date, false otherwise.
 */
export const hasMoreThenThreeOrdersOnDate = (
    date: GeneralDate,
    passengerId: string,
    ordersCountForPassengers: BlockedOrdersDatesForPassengers
): ShallowBoolean => {
    if (!ordersCountForPassengers) return;
    const countForPassenger = ordersCountForPassengers[passengerId];
    if (!countForPassenger) return;

    const formattedDate = formatDateToString(date, RELATIVE);
    try {
        const { totalCount } = countForPassenger[formattedDate];
        if (totalCount >= 3) {
            return true;
        }
    } catch (error) {
        logToLocalStorage(error, 'hasMoreThenThreeOrdersOnDate');
        console.log('CATCHED ERROR', countForPassenger, formattedDate);
    }
};

/**
 * Returns true if the date is in the past.
 * @param {Moment} date - The date to check.
 * @returns {ShallowBoolean} - True if the date is in the past.
 */
export const datePassed = (date: Moment): ShallowBoolean => moment().diff(date, 'day') > 0;

/**
 * Checks if the given date is blocked for the given passenger.
 * @param {BlockedOrdersDatesForPassengers} blockedOrdersDatesForPassengers - the object that holds the blocked orders for each passenger.
 * @param {string} passengerId - the id of the passenger.
 * @param {GeneralDate} date - the date to check against.
 * @returns {ShallowBoolean} - true if the date is blocked for the given passenger.
 */
export const passengerOrderBlockedByDate = (
    blockedOrdersDatesForPassengers: BlockedOrdersDatesForPassengers,
    passengerId: string,
    date: GeneralDate
): ShallowBoolean => {
    if (blockedOrdersDatesForPassengers[passengerId][formatDateToString(date, RELATIVE)]) {
        return true;
    }
};

/**
 * Checks if a passenger has two orders on a given date.
 * @param {GeneralDate} date - the date to check for two orders
 * @param {string} passId - the passenger id to check for two orders
 * @param {BlockedOrdersDatesForPassengers} passengersOrdersCountByDates - the object that holds the number of orders for each passenger on each date
 * @returns {ShallowBoolean} - true if the passenger has two orders on the given date, false otherwise
 */
// export const onePassengerHasTwoOrdersOneDate = (
//    date: GeneralDate,
//    passengersList: { passId: string }[],
//    passengersOrdersCountByDates: BlockedOrdersDatesForPassengers,
// ): ShallowBoolean => {
//    for (let i = 0; i < passengersList.length; i++) {
//       const { passId } = passengersList[i];
//       const hasTwoOrders = hasMoreThenThreeOrdersOnDate(
//          date,
//          passId,
//          passengersOrdersCountByDates,
//       );
//       return hasTwoOrders;
//    }
// };

export const getPassengerIdsWithTwoOrdersOneDate = (
    date: GeneralDate,
    passengersList: { passId: string }[],
    passengersOrdersCountByDates: BlockedOrdersDatesForPassengers
): string[] => {
    const passengerIds = [];
    for (let i = 0; i < passengersList.length; i++) {
        const { passId } = passengersList[i];
        const hasTwoOrders = hasMoreThenThreeOrdersOnDate(date, passId, passengersOrdersCountByDates);
        if (hasTwoOrders) {
            passengerIds.push(passId);
        }
    }
    return passengerIds;
};
interface OrdersCount {
    pickupCount: number;
    dropCount: number;
    totalCount: number;
    ordersData: {
        pickupOrders: IPickupDropOrder[];
        dropOrders: IPickupDropOrder[];
    };
}

export interface DatesOrdersCount {
    [key: string]: OrdersCount;
}
/**
 * Takes in a passenger and returns an object with the dates and the number of orders for each date.
 * @param {IPassengerShifts} passenger - the passenger to get the dates for
 * @returns {DatesOrdersCount} - an object with the dates and the number of orders for each date
 */
export const getDatesWithCountForPassenger = (passenger: IPassengerShifts): DatesOrdersCount => {
    const dates = {};
    if (!passenger.dates) return {};
    passenger.dates.forEach((date) => {
        dates[date.relativeDate] = {
            pickupCount: date.pickupOrders.length,
            dropCount: date.dropOrders.length,
            totalCount: date.pickupOrders.length + date.dropOrders.length,
            ordersData: {
                pickupOrders: date.pickupOrders,
                dropOrders: date.dropOrders,
            },
        };
    });
    return dates;
};

/**
 * Gets the updated orders count for a passenger.
 * @param {string} passId - the passenger ID
 * @param {string[]} codes - the codes to remove from the passenger's orders count
 * @param {string} date - the date of the order
 * @param {BlockedOrdersDatesForPassengers} ordersCounter - the current orders count for each passenger
 * @returns {BlockedOrdersDatesForPassengers} - the updated orders count for each passenger
 */
export const getUpdatedOrdersCount = (
    passId: string,
    codes: string[],
    date: string,
    ordersCounter: BlockedOrdersDatesForPassengers
): { [x: string]: any } => {
    const ordersForPassenger = ordersCounter[passId];
    const updatedOrdersCounter = deepClone(ordersForPassenger);
    updatedOrdersCounter[date].totalCount -= codes.length;
    // console.log({ updatedOrdersCounter });
    return updatedOrdersCounter;
};

/**
 * Takes in an array of passengers and sets the isSelected property to the given value.
 * @param {IPassengerShifts[]} passengers - the array of passengers to update.
 * @param {boolean} selectionValue - the value to set the isSelected property to.
 * @returns {IPassengerShifts[]} - the updated array of passengers.
 */
export const handleSelectPassengers = (
    passengers: IPassengerShifts[],
    selectionValue: boolean
): IPassengerShifts[] => {
    const cloned = deepClone(passengers);
    // eslint-disable-next-line no-param-reassign
    cloned.forEach(
        // eslint-disable-next-line no-param-reassign
        (passenger: IPassengerShifts) =>
            // eslint-disable-next-line no-param-reassign
            (passenger.isSelected = selectionValue)
    );
    return cloned;
};
interface ShiftDuplicate {
    pickupTime: string;
    dropTime: string;
    shiftId: string;
}
export interface ManualPlacement {
    pickup: {
        pickupTime: string;
        pickupShiftId: string;
    };
    drop: {
        dropTime: string;
        dropShiftId: string;
    };
}
export const buildPlacementShiftObj = (
    shiftType: ShiftType,
    shiftData: {
        pickupTime?: string;
        dropTime?: string;
        shiftId?: string | number;
    }
): ShiftDuplicate | ManualPlacement => {
    if (shiftType === 'manual') {
        return {
            pickup: {
                pickupTime: shiftData.pickupTime || '',
                pickupShiftId: shiftData.pickupTime ? String(shiftData.shiftId) : '',
            },
            drop: {
                dropTime: shiftData.dropTime || '',
                dropShiftId: shiftData.dropTime ? String(shiftData.shiftId) : '',
            },
        };
    }
    return {
        pickupTime: shiftData.pickupTime || '',
        dropTime: shiftData.dropTime || '',
        shiftId: String(shiftData.shiftId) || '',
    };
};

export const getShiftIdByTimes = (dropTime: string, pickupTime: string, shifts: IShifts[]): number | null => {
    const shiftWithMatchingDates = shifts.find((shift) => {
        if (dropTime && pickupTime) {
            if (shift.dropTime === dropTime && shift.pickupTime === pickupTime) {
                return true;
            }
        }
        if (dropTime && !pickupTime) {
            if (shift.dropTime === dropTime) {
                return true;
            }
        }
        if (!dropTime && pickupTime) {
            if (shift.pickupTime === pickupTime) {
                return true;
            }
        }
    });
    return shiftWithMatchingDates?.shiftId || null;
};

// * utils
export const isShiftItemDisabled = (
    shift: IShifts,
    placementDates: PlacementDate[],
    isShiftOrdered: Function,
    allShifts?: IShifts[],
    shiftData?: {
        shiftType: 'manual' | 'auto';
        isPickup: boolean;
    }
) => {
    const isPickup = shiftData?.isPickup;
    const shiftType = shiftData?.shiftType;

    const chosenDates = placementDates.map((d) => d.relativeDate);

    //  let pickupTime;
    //  let dropTime;
    //  if (shiftType === 'manual') {
    //      if (isPickup) {
    //          pickupTime = shift.pickupTime;
    //          dropTime = '';
    //      } else {
    //          pickupTime = '';
    //          dropTime = shift.dropTime;
    //      }
    //  } else {
    //      dropTime = shift.dropTime;
    //      pickupTime = shift.pickupTime;
    //  }

    //  if (isShiftOrdered(pickupTime, dropTime)) return true;

    // console.log({ allShifts, shiftType });
    if (shiftType === 'manual' && allShifts) {
        if (!chosenDates.length) return false;
        const validDatesForShift = getShiftValidDates(shift.shiftId, allShifts);
        // console.log({ validDatesForShift, chosenDates, shift });
        return !chosenDates.every((d) => validDatesForShift.includes(d));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const date of placementDates) {
        if (getBlockedDaysOfShift(shift).includes(date.relativeDate)) {
            return true;
        }
    }
};

export interface ShiftsTimes {
    dropTimes: any[];
    pickupTimes: any[];
}
/**
 * Takes in an array of shifts and returns an object with two arrays of objects.
 * The first array is the drop times and the second is the pickup times.
 * @param {IShifts[]} shifts - the array of shifts to get the times from.
 * @returns {object} - an object with two arrays of objects.
 */
export const getInitialSortedShifts = (shifts: IShifts[], chosenPickupTime: string): ShiftsTimes => {
    const dropShifts: any[] = [];
    const pickupShifts: any[] = [];

    shifts.forEach((s) => {
        if (s.dropTime) {
            if (!chosenPickupTime) {
                dropShifts.push({
                    shiftId: s.shiftId,
                    dropTime: s.dropTime,
                });
            } else if (
                (chosenPickupTime && s.dropTime === getLaterHour(s.dropTime, chosenPickupTime)) ||
                isBeforeNineAM(s.dropTime)
            ) {
                dropShifts.push({
                    shiftId: s.shiftId,
                    dropTime: s.dropTime,
                });
            }
        }
        if (s.pickupTime) {
            pickupShifts.push({
                shiftId: s.shiftId,
                pickupTime: s.pickupTime,
            });
        }
    });

    return {
        // dropTimes: _.uniqBy(dropTimes, 'dropTime'),
        // pickupTimes: _.uniqBy(pickupTimes, 'pickupTime'),
        dropTimes: dropShifts,
        pickupTimes: pickupShifts,
    };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _pushNextDayShiftsToLast = (shifts: any[]): any[] => {
    let copy = [...shifts];
    // eslint-disable-next-line no-restricted-syntax
    for (const time of shifts) {
        if (isBeforeNineAM(time.dropTime)) {
            // eslint-disable-next-line no-param-reassign
            copy = removeObjFromArr(time, copy);
            copy.push(time);
        }
    }
    return copy;
};

/**
 * Takes in a ShiftsTimes object and sorts the dropTimes and pickupTimes arrays.
 * @param {ShiftsTimes} times - the ShiftsTimes object to sort
 * @param {string} [chosenPickupTime] - the time that the user has chosen to pickup the
 *  shift.
 * @returns {object} - an object with the dropTimes and pickupTimes arrays sorted.
 */
export const sortShiftsTimes = (
    times: ShiftsTimes
    // chosenPickupTime?: string,
): { dropTimes: IShifts[]; pickupTimes: IShifts[] } => {
    const { dropTimes, pickupTimes } = times;
    // console.log({ times });
    dropTimes.sort((a, b) => {
        const aTime = moment(a.dropTime, 'hh:mm');
        const bTime = moment(b.dropTime, 'hh:mm');
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });

    pickupTimes.sort((a, b) => {
        const aTime = moment(a.pickupTime, 'hh:mm');
        const bTime = moment(b.pickupTime, 'hh:mm');
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });

    const dropTimesCopy = [...times.dropTimes];
    // eslint-disable-next-line no-restricted-syntax
    const fcOrderedDropTimes = _pushNextDayShiftsToLast(dropTimesCopy);

    return {
        dropTimes: fcOrderedDropTimes,
        pickupTimes: times.pickupTimes,
    };
};

/**
 * Sorts the hours in the given array.
 * @param {string[]} hours - the hours to sort.
 * @returns {string[]} - the sorted hours.
 */
export const sortHours = (hours: string[]): string[] => {
    const hoursCopy = [...hours];
    hoursCopy.sort((a, b) => {
        const aTime = moment(a, 'hh:mm');
        const bTime = moment(b, 'hh:mm');
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });
    return hoursCopy;
};

/**
 * Takes in an array of times and returns a new array with the first occurrence of a time that is before 9AM removed.
 * @param {string[]} times - the array of times to remove the first occurrence of a time that is before 9AM from.
 * @returns {string[]} - the new array with the first occurrence of a time that is before 9AM removed.
 */
const pushNextDayDropTimeToLast = (times: string[]): string[] => {
    let copy = [...times];
    // eslint-disable-next-line no-restricted-syntax
    for (const time of times) {
        if (isBeforeNineAM(time)) {
            copy = removeFirstOccurrence(copy, time);
            copy.push(time);
        }
    }
    return copy;
};
/**
 * Takes in an array of hours and sorts them into the correct order according to the FC configuration.
 * @param {string[]} hours - the array of hours to sort.
 * @returns {string[]} - the sorted array of hours.
 */
export const sortHoursWithFcConfigs = (hours: string[]): string[] => {
    const sorted = sortHours(hours);
    const c = [...sorted];
    // eslint-disable-next-line no-restricted-syntax
    const result = pushNextDayDropTimeToLast(c);
    return result;
};

export const sortHoursWithFcConfigsV2 = (hours: { time: string; relativeDate: string }[]) => {
    const sorted = [...hours].sort((a, b) => {
        const aTime = moment(a.time, 'hh:mm');
        const bTime = moment(b.time, 'hh:mm');
        return aTime.isBefore(bTime) ? -1 : 1; // true
    });

    // eslint-disable-next-line no-restricted-syntax
    let copy = [...sorted];
    // eslint-disable-next-line no-restricted-syntax
    for (const order of sorted) {
        if (isBeforeNineAM(order.time)) {
            copy = removeFirstOccurrence(copy, order);
            copy.push(order);
        }
    }
    return copy;
};

// const _sorterForSortShiftsTimes = (
//    a: { dropTime: moment.MomentInput },
//    b: { dropTime: moment.MomentInput },
// ) => {
//    const aTime = moment(a.dropTime, 'hh:mm');
//    const bTime = moment(b.dropTime, 'hh:mm');
//    return aTime.isBefore(bTime) ? -1 : 1; // true
// };

export const getShiftById = (shifts: IShifts[], shiftId: number | undefined): IShifts | null => {
    if (!shiftId) return null;
    const match = shifts.find((shift) => shift.shiftId === shiftId);
    return match || null;
};

interface OrderTimesForDate {
    pickups: string[];
    drops: string[];
}

const getValIfNotIncluded = (arr: string[], valueToAdd: string): string => {
    return arr.includes(valueToAdd) ? '' : valueToAdd;
};

export const getCapsulesByCellData = (item: {
    passenger: PassengerDates;
    shifts: IShifts[];
    currDate: Date;
}): IShiftCapsule[] => {
    const capsules: IShiftCapsule[] = [];
    const { passenger: passengerData, shifts, currDate } = item;

    const psngrOrdersForDate = passengerData.dates.find((date) =>
        moment(currDate).isSame(date.relativeDate, 'D')
    );

    if (!psngrOrdersForDate) return [];

    const currTimes: OrderTimesForDate = { pickups: [], drops: [] };

    // * Finds and pushes full shift
    psngrOrdersForDate.pickupOrders.forEach((pickupOrder) => {
        psngrOrdersForDate.dropOrders.forEach((dropOrder) => {
            shifts.forEach((shift) => {
                if (shift.dropTime === dropOrder.shiftTime && shift.pickupTime === pickupOrder.shiftTime) {
                    const { pickups, drops } = currTimes;

                    // -- handling two shifts with same drop or pickup time -
                    // -- if already exists - we only use the other time
                    const startTime = getValIfNotIncluded(pickups, pickupOrder.shiftTime);
                    const endTime = getValIfNotIncluded(drops, dropOrder.shiftTime);

                    capsules.push({
                        shiftId: shift.shiftId,
                        startTime,
                        endTime,
                        orderCodes: [
                            startTime ? pickupOrder.orderCode : '',
                            endTime ? dropOrder.orderCode : '',
                        ],
                        color: getColorShift(Number(shift.shiftIndex)),
                        id: v4(),
                        date: currDate,
                        times: JSON.stringify([pickupOrder.shiftTime, dropOrder.shiftTime]),
                    });

                    // -- created for later comparison made easier
                    currTimes.pickups = [...currTimes.pickups, pickupOrder.shiftTime];
                    currTimes.drops = [...currTimes.drops, dropOrder.shiftTime];
                }
            });
        });
    });

    psngrOrdersForDate.pickupOrders.forEach((pickup) => {
        for (let i = 0; i < capsules.length; i++) {
            if (capsules[i].startTime === pickup.shiftTime) return;
        }

        let color = 'white';
        const shiftByPickup = shifts.find((s) => s.pickupTime === pickup.shiftTime);
        if (shiftByPickup) {
            color = getColorShift(Number(shiftByPickup.shiftIndex));
        }

        capsules.push({
            startTime: pickup.shiftTime,
            endTime: '',
            orderCodes: [pickup.orderCode],
            id: v4(),
            date: currDate,
            times: JSON.stringify([pickup.shiftTime, '']),
            color,
        });
    });

    psngrOrdersForDate.dropOrders.forEach((drop) => {
        const shiftByDrop = shifts.find((s) => s.dropTime === drop.shiftTime);

        let color = 'white';
        if (shiftByDrop) {
            color = getColorShift(Number(shiftByDrop.shiftIndex));
        }

        const defaultCapsuleData = {
            startTime: '',
            endTime: drop.shiftTime,
            orderCodes: [drop.orderCode],
            id: v4(),
            date: currDate,
            times: JSON.stringify(['', drop.shiftTime]),
            color,
        };

        if (shiftByDrop) {
            for (let i = 0; i < capsules.length; i++) {
                if (capsules[i].endTime === drop.shiftTime) return;
            }

            const pickupIndex = capsules.findIndex((d) => d.startTime === shiftByDrop.pickupTime);

            if (pickupIndex !== -1) {
                capsules[pickupIndex] = {
                    ...capsules[pickupIndex],
                    endTime: drop.shiftTime,
                    orderCodes: [...capsules[pickupIndex].orderCodes, drop.orderCode],
                };
            } else {
                capsules.push(defaultCapsuleData);
            }
        } else {
            capsules.push(defaultCapsuleData);
        }
    });

    const uniqueOrdersByTime = _.uniqBy(capsules, 'times');

    return uniqueOrdersByTime;
};

/**
 * Gets the order codes for the given passId and date.
 * @param {string} passId - the passId of the passenger
 * @param {Date} date - the date of the order
 * @param {PassengerDates[]} passengersDates - the array of passenger dates
 * @returns {string[]} the array of order codes for the given passId and date
 */
export const getOrderPassCodesForPassIdAndDate = (
    passId: string,
    shiftToDelete: ShiftToDelete,
    passengersDates: PassengerDates[],
    orderCodesToForDeletion: string[]
): string[] => {
    const passenger = passengersDates.find((passengerDate) => passengerDate.passId === passId);

    if (!passenger) return [];

    const dateData = passenger.dates.find((loopDataDate) =>
        moment(shiftToDelete.date).isSame(moment(loopDataDate.relativeDate, RELATIVE), 'D')
    );
    if (!dateData) return [];

    const getOrderCodes = (orders: IPickupDropOrder[]) => {
        return orders
            .filter((order) => orderCodesToForDeletion.includes(order.orderCode))
            .map((order) => order.orderCode);
    };

    const codes = [...getOrderCodes(dateData.pickupOrders), ...getOrderCodes(dateData.dropOrders)];

    return codes;
};

export interface DateRange {
    fromDate: string;
    toDate: string;
}

export const getDateRanges = (
    filterStartDate: Date,
    filterEndDate: Date,
    daysToAddToEndDate?: number
): DateRange => {
    const fromDate = moment(filterStartDate).startOf('week').format(RELATIVE);

    let toDate;
    if (daysToAddToEndDate) {
        toDate = moment(filterEndDate).add(daysToAddToEndDate, 'days').endOf('week').format(RELATIVE);
    } else {
        toDate = moment(filterEndDate).format(RELATIVE);
    }

    return { fromDate, toDate };
};
