import React, { useEffect, useRef, useState } from 'react';
import {
    optimizationsRootSelector,
    setClientCodeAction,
    setIsDeleteModalOpenAction,
    setIsFormOpenAction,
    setMapIsExpandedAction,
    setWaypointStockAction,
    setTimeDeltaAction,
    selectWaypointAction,
    setIsCsvLoadDoneModalOpenAction,
    setCsvWaypointsObjectAction,
    setIsGeneralLoadingAction,
    setWaypointsOnFormEditAction,
    setIsEditFormAction,
    setCenterMapOnEditAction,
    setZoomOnEditAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import WaypointManagementScreen from './WaypointManagementScreen';
import OptimizationSimulationScreen from './OptimizationSimulationScreen';
import { useRootAppSelector } from 'src/store/hooks';

const optimizationSimulationButtonStyle = {
    backgroundColor: 'white',
    border: '1px solid rgb(33, 150, 243) ',
    borderRadius: '5px',
    padding: '8px',
    color: 'rgb(33, 150, 243)',
    fontSize: '16px',
    cursor: 'pointer',
};

const OptimizationsScreen = () => {
    const {
        ui: {
            optimizationSimulationScreen: { isOpen },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    return <>{isOpen ? <OptimizationSimulationScreen /> : <WaypointManagementScreen />}</>;
};

export default OptimizationsScreen;
