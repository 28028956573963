import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { msColors } from './themes/defaultTheme';
import { mediumRubik } from './styledCSS';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        // minHeight: '28px',
        // margin: '0 27px',
        width: '100%',
        '&:not(:first-of-type)': {
            // margin: '0',
            borderRadius: '4px',
        },
        '&:first-of-type': {
            borderRadius: '4px',
            // margin: '0 1.2em',
            margin: 0,
        },
        '&.Mui-selected': {
            border: '2px solid #40A8E2 !important',
            // marginLeft: '1px',
            //  boxSizing: 'border-box',
            //  borderRadius: '4px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
        },
    },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
    // fontFamily: mediumRubik,
    borderRadius: '4px',
    color: msColors.darkTextcolor,
    backgroundColor: 'white',
    border: '2px solid lightGray !important',
    width: '80px ',
    height: window.screen.width > 1715 ? '28px' : '50px',
    fontFamily: 'Rubik',
    // fontWeight: '400',
    fontSize: '12px',
    margin: '0 1.2em',
}));
