import { CSSProperties } from 'react';
import styled from 'styled-components';

const ExtraDetailsBox = styled.div<{ style?: CSSProperties }>`
    background: #f9f9f9;
    border: 1px solid #e8ebef;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ExtraDetailsSectionStyles = {
    ExtraDetailsBox,
};

export default ExtraDetailsSectionStyles;
