import React, { useContext, useMemo } from 'react';
import DropDown from 'src/components/DropDown/DropDown';
import { PlacemenContext } from '../../context';
import hooks from './hooks.PlacementAddressSelection';
import styles from './styles.PlacementAddressSelection';

interface Props {
    selectedPassengerCode: string;
    selectedTab: 'auto' | 'manual';
}

const PlacementAddressSelection = ({ selectedPassengerCode, selectedTab }: Props) => {
    const { createProps } = hooks.useDropdownProps(selectedPassengerCode, selectedTab);

    return (
        <styles.AddressSelectionContainer>
            <DropDown {...createProps('pickup')} />
            <DropDown {...createProps('drop')} />
        </styles.AddressSelectionContainer>
    );
};

export default PlacementAddressSelection;
