import axios from 'axios';
import { isoDateRegex, fleetDateFormat } from 'src/utilis/utilis';

export const proxy = 'https://israelProxy.y-it.co.il/ProxyService/ProxyService.svc/Proxy';

export const encryptProxy = 'https://israelProxy.y-it.co.il/ProxyService/ProxyService.svc/EncryptProxy';

export const newProxy = 'https://studio-proxy.y-it.co.il/ProxyService/ProxyService.svc/EncryptProxy';

export const studioProxy = 'https://studio-proxy.y-it.co.il:543/ProxyServices/EncryptProxy';

export const myWayUrl = 'https://4ynczhja78.execute-api.eu-west-1.amazonaws.com';

export const gpsServerUrl = 'https://vp7ldm0qg0.execute-api.eu-west-1.amazonaws.com';

export const TRANSIT_PROD_URL_PREFIX = 'https://interfaceserviceapi.y-it.co.il';
export const TRANSIT_STAGING_URL =
    'http://interfaceserviceapi.ebstage.y-it.co.il/MyWayApplicationsFiles/MyWayApplicationsFilesService.svc';

export const ITURAN_API_URL = 'https://c6ubzg27cb.execute-api.eu-west-1.amazonaws.com/v1';

export const EMAIL_SERVICE_URL = `${TRANSIT_PROD_URL_PREFIX}/reports/ws_send_email` as const;

/**
 * Builds the URL for the proxy server to use to encrypt the URL.
 * @param {string} fcDbUrl - encrypted fcDbUrl. // eg "==AbyR3Y0xmZvADOwgjO5EjMuITMx4CO3EjLwgzLvoDc0RHa"
 * @param {string} wsEndpoint - the endpoint to use for the proxy server. // eg "ws_MyWayStudio_Get_Shifts_By_Dates"
 * @returns {string} The URL to use for the proxy server.
 */
export const buildEncriptedUrl = (fcDbUrl: string, wsEndpoint: string): string => {
    return `${encryptProxy}?url=${fcDbUrl}&action=${wsEndpoint}`;
};

const dateConvert = (key: string, value: unknown): unknown => {
    if (
        typeof value === 'string' &&
        (fleetDateFormat.test(value) || isoDateRegex.test(value)) // checking whether the date is valid
    )
        return new Date(value);

    return value;
};

const instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        // system: 'MyWayStudio',
        // request_origin: 'YIT',
    },
    transformResponse: (res) => {
        try {
            return JSON.parse(res, dateConvert);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, res);
            return res;
        }
    },
});

export const getInstanceWithTime = (timeout: number) => {
    return axios.create({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            // system: 'MyWayStudio',
            // request_origin: 'YIT',
        },
        timeout,
        transformResponse: (res) => {
            try {
                return JSON.parse(res, dateConvert);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error, res);
                return res;
            }
        },
    });
};
export default instance;

// const FirstProxyBaseUrl = 'https://proxy1.y-it.co.il/ProxyService/ProxyService.svc/Proxy';
// const SecondaryProxyBaseUrl = 'https://proxy2.y-it.co.il/ProxyService/ProxyService.svc/Proxy';
// const ThirdProxyBaseUrl = 'https://yitadmin.y-it.co.il/api/Customer/SendUrlRequestToProxy';
