import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

interface Prop extends AlertProps {
    open: boolean;
    onClose: () => void;
}

const Alert = ({ open, onClose, ...props }: Prop) => (
    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        open={open}
        onClose={onClose}
    >
        <MuiAlert
            style={{ fontFamily: 'Rubik' }}
            elevation={6}
            variant="filled"
            onClose={onClose}
            {...props}
        />
    </Snackbar>
);

export default Alert;
