/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function MyEditPencilIcon({ width = '20', height = '20', color = 'rgba(0, 0, 0, 0.54)' }) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6662 1.33367L16.526 1.47386L16.6662 1.33367C16.0696 0.737007 15.2603 0.401807 14.4165 0.401807C13.5727 0.401807 12.7634 0.737007 12.1667 1.33367L12.1667 1.33368L2.19342 11.3078C2.19341 11.3078 2.19339 11.3078 2.19338 11.3078C1.93797 11.5632 1.75361 11.8807 1.65853 12.2291L1.65852 12.2291L0.49602 16.4925L0.496011 16.4925C0.457805 16.6327 0.457317 16.7806 0.494597 16.921C0.531878 17.0615 0.605603 17.1896 0.708313 17.2924C0.811022 17.3953 0.939069 17.4691 1.0795 17.5066C1.21992 17.544 1.36775 17.5437 1.50801 17.5056L1.50826 17.5055L5.77159 16.343L5.77163 16.343C6.12004 16.248 6.43759 16.0636 6.69291 15.8082C6.69292 15.8082 6.69292 15.8082 6.69293 15.8081L16.6662 5.83318C17.2629 5.2365 17.5981 4.42724 17.5981 3.58343C17.5981 2.73961 17.2629 1.93035 16.6662 1.33367ZM11.4998 4.33295L13.667 6.50008L5.52507 14.6403L5.52421 14.6412C5.47251 14.6935 5.40812 14.7315 5.33734 14.7515C5.33711 14.7516 5.33688 14.7516 5.33665 14.7517L2.46735 15.5342L3.25026 12.6636L3.25029 12.6635C3.26971 12.5921 3.3074 12.5271 3.35965 12.4748L11.4998 4.33295ZM14.8331 5.33392L12.666 3.16676L13.3315 2.50127C13.3317 2.50105 13.3319 2.50082 13.3321 2.5006C13.6204 2.21842 14.0084 2.06128 14.4118 2.06332C14.8156 2.06536 15.2022 2.22666 15.4877 2.51216C15.7732 2.79766 15.9345 3.1843 15.9366 3.58806C15.9386 3.99148 15.7815 4.37943 15.4993 4.66772C15.4991 4.66796 15.4989 4.6682 15.4986 4.66844L14.8331 5.33392Z"
                strokeWidth="0.4"
                stroke={color}
            />
        </svg>
    );
}
export default MyEditPencilIcon;
