/* eslint-disable react/prop-types */
import React, { ReactElement, useMemo } from 'react';
import { StyledFormButtonsContainer } from 'src/screens/styles/Widgets.style';
// import { formBtnStyle } from 'src/screens/styles/styles';
import { useTranslation } from 'react-i18next';
import { Button, ButtonClasses, ButtonProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormBtnsConfig } from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';

interface Props extends FormBtnsConfig {
    data?: any;
}

const btnSx = {
    fontFamily: 'Rubik',
    fontWeight: 400,
} as const;

const submitBtnSx = {
    ...btnSx,
    background: '#2196F3',
    width: '183px',
} as const;
const quitBtnSx = {
    ...btnSx,
    color: '#000000',
    marginLeft: '40px',
} as const;

export const FromBtn: React.FC<{
    type: 'confirm' | 'cancel';
    btnProps?: ButtonProps;
    text: string;
}> = ({ type, btnProps, text }) => {
    return (
        <Button
            variant={type === 'confirm' ? 'contained' : undefined}
            sx={
                type === 'cancel'
                    ? {
                          background: '#C4C4C4',
                          color: '#FFFFFF',
                      }
                    : {
                          background: '#2196F3',
                          color: '#FFFFFF',
                      }
            }
            {...btnProps}
        >
            {text}
        </Button>
    );
};

const CancelAndApproveBtns: React.FC<Props> = ({
    onCancel,
    onSubmit,
    data,
    formChanged,
    disabled,
    submitText,
    withLoader,
    isSubmitting,
}) => {
    const { t } = useTranslation();
    const isDisabled = useMemo(() => {
        if (!formChanged === undefined) return formChanged;
        return disabled;
    }, [disabled, formChanged]);
    return (
        <>
            <LoadingButton
                // style={formBtnStyle}
                loading={withLoader && isSubmitting}
                onClick={(e) => onSubmit(e)}
                type="button"
                // fullWidth
                disabled={isDisabled}
                variant="contained"
                color="primary"
                sx={submitBtnSx}
            >
                {submitText || t('save')}
            </LoadingButton>
            <Button
                // style={formBtnStyle}
                type="button"
                // fullWidth
                // variant="contained"
                // disabled={!formChanged}
                onClick={() => {
                    onCancel();
                }}
                // color="primary"
                sx={quitBtnSx}
            >
                {t('cancel')}
            </Button>
        </>
    );
};

export default CancelAndApproveBtns;
