/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    coursesBuildingSelectors,
    setUpdatedCoordsOnMapAction,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { Marker, MarkerProps } from '@react-google-maps/api';
import { StationSchema } from 'src/types/coursesBuilding/form/formTypes';
import { Coords } from 'google-map-react';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import { isValidCoords } from '../../CourseMap/CourseWidgetMap';
import { mapHelpers } from '../utils/common';

export const useMapCenter = ({
    setZoom,
    setCenter,
}: {
    setZoom: (zoom: number) => void;
    setCenter: (center: Coords) => void;
}) => {
    const {
        fieldsCopy,
        isOnEditMode,
        updatedCoords: coordsDraggedTo,
        editedStationId,
        centerCoords,
    } = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map);

    const getMapCenter = React.useCallback(() => {
        if (!fieldsCopy.length) return defaultCenter;

        if (isOnEditMode) {
            // Centering map on new dragged location
            if (coordsDraggedTo) return coordsDraggedTo;

            const editedStationData = fieldsCopy.find((station) => station.stationId === editedStationId);

            const editedStationHasCoords = mapHelpers.objectHasValidCoords(editedStationData);

            // Centering map on edited map curr coords if exists
            if (editedStationHasCoords) {
                // editedStationData should be of type StationSchema
                return {
                    lat: editedStationData?.lat || defaultCenter.lat,
                    lng: editedStationData?.lng || defaultCenter.lng,
                };
            }

            if (fieldsCopy.length === 1) {
                return {
                    lat: fieldsCopy[0].lat || defaultCenter.lat,
                    lng: fieldsCopy[0].lng || defaultCenter.lng,
                };
            }

            return defaultCenter;
        }

        if (fieldsCopy.length >= 2) {
            const coords1 = mapHelpers.getCoordsFromField(fieldsCopy[0]);
            const coords2 = mapHelpers.getCoordsFromField(fieldsCopy[fieldsCopy.length - 1]);

            if (mapHelpers.isValidCoords(coords1) && mapHelpers.isValidCoords(coords2)) {
                return mapHelpers.handleTwoPointsCenter(coords1, coords2);
            }
        }

        // Must return coords otherwise map will not be rendered
        return {
            lat: fieldsCopy[0]?.lat || defaultCenter.lat,
            lng: fieldsCopy[0]?.lng || defaultCenter.lng,
        };
    }, [fieldsCopy, isOnEditMode, coordsDraggedTo, editedStationId]);

    React.useEffect(() => {
        if (centerCoords) {
            setCenter(centerCoords);
        }
    }, [centerCoords, setCenter]);

    React.useEffect(() => {
        if (coordsDraggedTo) {
            setCenter(coordsDraggedTo);
        }
    }, [coordsDraggedTo, setCenter]);

    React.useEffect(() => {
        // Centering map on entering edit mode
        if (isOnEditMode) {
            setCenter(getMapCenter());
        }
    }, [isOnEditMode, setCenter]);

    return { getMapCenter };
};

export interface MarkerType2 {
    markerId: string;
    markerProps: MarkerProps;
}

const useMap = () => {
    const dispatch = useAppDispatch();

    const { fieldsCopy, editedStationId, isOnEditMode } = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.map
    );

    const [markers, setMarkers] = useState<MarkerType2[]>([]);

    const createMarkerPropsFromStation = (
        station: StationSchema,
        index: number,
        coords?: Coords
    ): MarkerType2 => {
        const stationCoords = {
            lat: station.lat,
            lng: station.lng,
        };

        const stationCoordsAreValid = isValidCoords(stationCoords);

        const getValidCoords = (): Coords => {
            if (coords) return coords;
            if (stationCoordsAreValid) return stationCoords;
            return defaultCenter;
        };

        const isMarkerVisible = () => {
            if (isOnEditMode) return true;
            return stationCoordsAreValid || false;
        };

        return {
            markerProps: {
                position: isOnEditMode ? getValidCoords() : stationCoords,
                draggable: isOnEditMode && editedStationId === station.stationId ? true : false,
                onDragEnd: (e) => {
                    if (!e.latLng) return;
                    dispatch(setUpdatedCoordsOnMapAction({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
                },
                icon: {
                    url: mapHelpers.getIconUrl(
                        index,
                        fieldsCopy.length - 1 === index,
                        editedStationId === station.stationId
                    ),
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 25),
                },
                visible: isMarkerVisible(),
            },
            markerId: station.stationId,
        };
    };

    useEffect(() => {
        if (fieldsCopy) {
            const newMarkers: MarkerType2[] = [];

            fieldsCopy.forEach((station, index) => {
                if (station.lat && station.lng) {
                    newMarkers.push(createMarkerPropsFromStation(station, index));
                }
            });

            setMarkers(newMarkers);
        }
    }, [fieldsCopy, editedStationId]);

    // Adding a new marker at the default location when the edited station doesnt have valid coordinates
    React.useEffect(() => {
        if (isOnEditMode) {
            const editedStationIndex = fieldsCopy.findIndex(
                (station) => station.stationId === editedStationId
            );

            if (editedStationIndex === -1) return;

            const editedStationData = fieldsCopy[editedStationIndex];

            if (editedStationData.lat && editedStationData.lng) {
                return;
            }

            setMarkers((prev) => [
                ...prev,
                createMarkerPropsFromStation(editedStationData, editedStationIndex, defaultCenter),
            ]);
        }
    }, [editedStationId, isOnEditMode]);

    return { markers };
};

export default useMap;
