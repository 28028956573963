import { IconButton } from '@mui/material';
import React, { CSSProperties, MouseEventHandler } from 'react';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import { useTranslation } from 'react-i18next';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';

const size = '30px';

const defaultIconButtonStyle: CSSProperties = {
    backgroundColor: '#EDBD41',
    height: size,
    width: size,
};

const disabledIconStyle = {
    opacity: 0.5,
} as const;
export interface BtnsProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    iconBtnStyle?: CSSProperties;
    iconStyle?: {
        color?: string;
        height?: string;
        width?: string;
    };
}

export const DeleteBtn = ({ onClick, iconBtnStyle = {}, iconStyle = {}, disabled = false }: BtnsProps) => {
    const { t } = useTranslation();

    let iconStyleCopy = { ...iconBtnStyle };

    if (disabled) {
        iconStyleCopy = { ...iconStyleCopy, ...disabledIconStyle };
    }

    return (
        <MuiTooltipV3 title={t('delete')}>
            <IconButton
                onClick={onClick}
                style={{
                    ...defaultIconButtonStyle,
                    ...iconStyleCopy,
                }}
                aria-label="מחיקה"
                size="small"
                className="DeleteBtn"
                disabled={disabled}
            >
                <TrashIcon height="18" color="" {...iconStyle} />
            </IconButton>
        </MuiTooltipV3>
    );
};

export const EditBtn = ({ onClick, iconBtnStyle = {}, iconStyle = {}, disabled = false }: BtnsProps) => {
    const { t } = useTranslation();

    let iconStyleCopy = { ...iconBtnStyle };

    if (disabled) {
        iconStyleCopy = { ...iconStyleCopy, ...disabledIconStyle };
    }

    return (
        <MuiTooltipV3 title={t('edit')}>
            <IconButton
                onClick={onClick}
                style={{ ...defaultIconButtonStyle, ...iconStyleCopy }}
                aria-label="עריכה"
                size="small"
                className="EditBtn"
                disabled={disabled}
            >
                <MyEditPencilIcon height="17px" width="17px" color="black" {...iconStyle} />
            </IconButton>
        </MuiTooltipV3>
    );
};

export const DuplicateBtn = ({ onClick, iconBtnStyle = {}, iconStyle = {}, disabled = false }: BtnsProps) => {
    const { t } = useTranslation();

    let iconStyleCopy = { ...iconBtnStyle };

    if (disabled) {
        iconStyleCopy = { ...iconStyleCopy, ...disabledIconStyle };
    }

    return (
        <MuiTooltipV3 title={t('duplicate')}>
            <IconButton
                onClick={onClick}
                style={{
                    ...defaultIconButtonStyle,
                    ...iconStyleCopy,
                }}
                aria-label="שכפול"
                size="small"
                className="DuplicateBtn"
                disabled={disabled}
            >
                <ContentCopyIcon
                    sx={{ height: '18px', width: '18px' }}
                    htmlColor="black"
                    {...(iconStyle as any)}
                />
            </IconButton>
        </MuiTooltipV3>
    );
};

export const RestoreBtn = ({ onClick, iconBtnStyle = {}, iconStyle = {}, disabled = false }: BtnsProps) => {
    const { t } = useTranslation();

    let iconStyleCopy = { ...iconBtnStyle };

    if (disabled) {
        iconStyleCopy = { ...iconStyleCopy, ...disabledIconStyle };
    }

    return (
        <MuiTooltipV3 title={t('restore')}>
            <IconButton
                aria-label="שחזור"
                size="small"
                onClick={onClick}
                style={{
                    ...defaultIconButtonStyle,
                    ...iconStyleCopy,
                }}
                disabled={disabled}
            >
                <SettingsBackupRestoreOutlinedIcon color="inherit" fontSize="small" />
            </IconButton>
        </MuiTooltipV3>
    );
};
