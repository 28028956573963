/* eslint-disable consistent-return */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps, GridColumnProps } from '@progress/kendo-react-grid';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import OptionsCell from '../components/OptionsCell';
import { MuiTooltipV2 } from 'src/components/MuiTooltip/MuiTooltip';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { InfoCellProps } from 'src/screens/Main/components/Lines/hooks/useColumns';
import { asTSR } from 'src/utilis/utilis';
import styled from 'styled-components';
import SportsScoreIcon from '@mui/icons-material/SportsScore';

const StyledTd = styled.td`
    padding: 0;
    border: none;
    pointer-events: none;
`;

/* const StyledTd = styled.td`
    padding: 0;
    border: none;
    z-index: 9999;
    background: white !important;
    pointer-events: none
    
`; */

const LocationOffCell = (props: React.PropsWithChildren<GridCellProps>) => {
    const { t } = useTranslation();
    const waypointData: any | undefined = props.dataItem || {};

    return (
        <StyledTd
            style={{
                padding: 0,
                border: 'none',
                zIndex: 9999,
                pointerEvents: 'none',
            }}
            className="location-cell-td"
        >
            {waypointData && !waypointData.isRecognized ? (
                <MuiTooltipV2 arrow title={<>{t(asTSR('unRecognizedWaypoint'))}</>} placement="right">
                    <LocationOffIcon sx={{ pointerEvents: 'all' }} fontSize="small" />
                </MuiTooltipV2>
            ) : null}
        </StyledTd>
    );
};

export interface IColumnHook {
    headerSelectionValue?: boolean;
    hoverRowWaypointId: number | null;
}

const useColumns = ({
    headerSelectionValue,
    hoverRowWaypointId,
}: IColumnHook): { columns: Array<GridColumnProps> } => {
    const { t } = useTranslation();

    const { width } = window.screen;

    // const getWidth = useCallback(
    //     (field: string) => {
    //         if (field === 'remarks' && width < 1660) return 50;
    //         if (field === 'remarks' && width < 1660) return 100;
    //     },
    //     [width]
    // );

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'info',
                title: '',
                width: 25,
                cell: (cellProps) => <LocationOffCell {...cellProps} />,
                headerClassName: 'k-header k-info-cell-header',
                sortable: false,
                reorderable: false,
                resizable: false,
                headerCell: () => {
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    return <th style={{ display: 'none', background: 'white', border: 'none' }} />;
                },
            },
            {
                field: 'isSelected',
                headerSelectionValue,
                reorderable: false,
                width: 34,
                className: 'isSelected-col',
                resizable: false,
            },
            {
                field: 'waypointName',
                title: t('waypoint'),
                editor: 'text',
                width: 250,
                minResizableWidth: 50,
            },
            {
                field: 'passId',
                title: t('passengerCode'),
                editor: 'text',
                width: 100,
                minResizableWidth: 50,
            },
            {
                field: 'firstName',
                width: 90,
                title: t('privateName'),
                minResizableWidth: 50,
            },
            {
                field: 'lastName',
                width: 90,
                title: t('familyName'),
                minResizableWidth: 50,
            },
            {
                field: 'remarks',
                width: 'auto',
                title: t('remarks'),
                minResizableWidth: 50,
            },
            {
                field: 'actions',
                title: t('actions'),
                width: 130,
                headerClassName: 'k-header k-actions-cell-header',
                headerCell: () => null,
                cell: (cellProps) => (
                    <OptionsCell cellProps={cellProps} hoveredWaypointId={hoverRowWaypointId} />
                ),
                resizable: false,
            },
        ],
        [headerSelectionValue, t, width, hoverRowWaypointId]
    );

    return { columns };
};

export default useColumns;
