import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { CourseDetailsFormSchema } from 'src/types/coursesBuilding/form/formTypes';
import { useTranslation } from 'react-i18next';
import TextInputV3 from 'src/components/ReactHookFormInputs/TextInputV3';
import RHFSelect from 'src/components/ReactHookFormInputs/Select';
import CarTypeDropdownV2 from 'src/components/ReactHookFormInputs/CarTypeDropdownV2/CarTypeDropdownV2';
import RHFDatePicker from 'src/components/ReactHookFormInputs/DatePicker/RHFDatePicker';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Spinner from 'src/components/Spinner/Spinner';
import useFormDisplayDelay from 'src/screens/CoursesBuilding/hooks/formHooks/useFormDisplayDelay';
import useDropdownProps from 'src/screens/CoursesBuilding/hooks/formHooks/useDropdownProps';

const DetailsPanel: React.FC<{}> = ({}) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        control,
        getValues,
        watch,
        setValue,
        trigger,
    } = useFormContext<CourseDetailsFormSchema>();

    const ddProps = useDropdownProps();

    React.useEffect(() => {
        // console.log(getValues());
    }, [getValues()]);

    const activeFromDateVal = getValues('activeFromDate');

    React.useEffect(() => {
        trigger('activeUntilDate');
    }, [trigger, activeFromDateVal]);

    return (
        <>
            <DisplayWrapper show style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                <Controller
                    name="courseId"
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextInputV3
                                field={field}
                                errors={errors[field.name]}
                                fieldProps={{ type: 'number', InputProps: { inputProps: { min: 1 } } }}
                            />
                        );
                    }}
                />
                <Controller
                    name="courseDescription"
                    control={control}
                    render={({ field }) => {
                        return <TextInputV3 field={field} errors={errors[field.name]} />;
                    }}
                />
                <Controller
                    name="remarks"
                    control={control}
                    render={({ field }) => {
                        return <TextInputV3 field={field} errors={errors[field.name]} />;
                    }}
                />
                <Controller
                    name="courseDirection"
                    control={control}
                    render={({ field }) => {
                        return <RHFSelect {...ddProps.getCourseDirectionDropdownProps(field)} />;
                    }}
                />
                <Controller
                    name="departmentCode"
                    control={control}
                    render={({ field }) => {
                        return <RHFSelect {...ddProps.getDeptProps(field)} />;
                    }}
                />
                <Controller
                    name="lineTypeCode"
                    control={control}
                    render={({ field }) => {
                        return <RHFSelect {...ddProps.getLineTypesProps(field)} />;
                    }}
                />
                <CarTypeDropdownV2 fieldName="carTypeCode" />
                <div style={{ display: 'flex', gap: '15px' }}>
                    <RHFDatePicker {...ddProps.activeFromDateProps} />
                    <RHFDatePicker {...ddProps.activeUntilDateProps} />
                </div>
            </DisplayWrapper>
        </>
    );
};

export default DetailsPanel;
