import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from 'src/api/api';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import useLastSelectedClient from 'src/hooks/useLastSelectedClient';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { DropDownState } from 'src/screens/PassengerManger/components/Passengers/hooks/useFilters';
import { setErrorMessage, setFcAccount } from 'src/store/actions/loginAction';
import { onSetClientId } from 'src/store/actions/passengerAction';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { SetStateAction } from 'src/types/global';
import { IClient, IItem, StrictDropdownItem } from 'src/types/line';
import { IAccount } from 'src/types/login';
import { isAuthorized } from 'src/utilis/utilis';
import { ClientFilterDropdownState } from '../types/rideSettings/components/types.screen';
import { ReduxDispatch } from 'src/store/type';
import { useDispatchAlert } from './useAlert';
import { errorMessagesI18 } from 'src/types/apiCommon.types';

export const useValidateClientList = () => {
    const dispatchAlert = useDispatchAlert();

    const validateClientsList = (originalClients: unknown) => {
        const isValidClientsList = Array.isArray(originalClients) ? true : false;

        if (!isValidClientsList) {
            dispatchAlert('error', errorMessagesI18.missingWs);
        }

        const clientsList = (isValidClientsList ? originalClients : []) as IClient[];
        return clientsList;
    };

    return validateClientsList;
};

const useClientFiltering = (
    reduxConfigs: {
        setClientIdAction: Function;
    },
    routeScreenId: number,
    isPrivateRoute?: boolean
): {
    onRefresh: () => void;
    providers: IItem[];
    clinetFilter: DropDownState;
    setClinetFilter: SetStateAction<ClientFilterDropdownState>;
    onSetSelectedFcAccount: (payload: IAccount) => ReduxDispatch<IAccount>;
} => {
    const dispatch = useDispatch();

    // * Selectors
    const {
        fcAccounts,
        selectedFcAccount,
        token,
        authorizationToken = {},
    } = useSelector((state: IRootReducer) => loginSelector(state));

    const isClientAuthorized = (clientCode: string | undefined) => {
        if (!clientCode) return false;
        return isAuthorized(routeScreenId, +clientCode, authorizationToken);
    };

    // * Local states
    const [providers, setProviders] = useState<StrictDropdownItem[]>([]);

    const [lastSelectedClient, setLastSelectedClient, setProvidersClients] = useLastSelectedClient();

    const [clinetFilter, setClinetFilter] = useState<ClientFilterDropdownState>({
        options: [],
        value: isClientAuthorized(lastSelectedClient?.value) ? String(lastSelectedClient?.value) : '',
    });

    const validateClientsList = useValidateClientList();

    // * Handlers
    const onSetSelectedFcAccount = (payload: IAccount) => dispatch(setFcAccount(payload));

    const onSetSelectedClient = (payload: string) => {
        dispatch(
            reduxConfigs.setClientIdAction({
                clientCode: payload || '',
            })
        );
    };

    useEffect(() => {
        setProvidersClients(clinetFilter.options);
    }, [clinetFilter.options, setProvidersClients]);

    const onRefresh = () => {
        if (selectedFcAccount) onSetSelectedFcAccount({ ...selectedFcAccount });
    };

    // * Effects
    useEffect(() => {
        const res: StrictDropdownItem[] = fcAccounts.map(({ accountCode, accountName }: IAccount) => ({
            value: String(accountCode),
            name: accountName,
        }));

        setProviders(res);
    }, [fcAccounts]);

    useEffect(() => {
        if (isPrivateRoute && !isClientAuthorized(clinetFilter.value)) return;

        onSetSelectedClient(clinetFilter.value);
        setLastSelectedClient({
            value: String(clinetFilter.value),
            name:
                String(clinetFilter.options.find((client) => client.value === clinetFilter.value)?.name) ||
                '',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinetFilter.value, routeScreenId, authorizationToken]);

    const { proxyUrl, dbUrl } = selectedFcAccount || {};

    const fetchClientsFromServer = () => {
        if (dbUrl && token && Object.keys(authorizationToken).length) {
            getClients({
                proxyUrl,
                token,
                dbUrl,
            }).then((res) => {
                const { clients = [] } = res.data;

                const clientsList = validateClientsList(clients);

                let authorizedClients: IClient[];
                if (isPrivateRoute) {
                    authorizedClients = clientsList.filter((client) =>
                        isAuthorized(
                            // routesEndpoints.MANAGE_PASSENGERS.SCREEN_ID,
                            routeScreenId,
                            +client.accountCode,
                            authorizationToken
                        )
                    );
                } else {
                    authorizedClients = clientsList;
                }

                setClinetFilter((preState) => ({
                    value: authorizedClients.some((client) => String(client.accountCode) === preState.value)
                        ? preState.value || ''
                        : String(authorizedClients.find((client) => client)?.accountCode || ''),
                    options: authorizedClients.map((c) => ({
                        value: String(c.accountCode),
                        name: c.clientName,
                    })),
                }));
            });
        }
    };

    useEffect(() => {
        fetchClientsFromServer();
    }, [authorizationToken, dbUrl, proxyUrl, routeScreenId, token]);

    return {
        onRefresh,
        providers,
        clinetFilter,
        setClinetFilter,
        onSetSelectedFcAccount,
    };
};

export default useClientFiltering;
