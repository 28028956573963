import React, { FunctionComponent, useState } from 'react';

import { Button, Form, TextInput } from './Password.style';

type Props = {
    onSubmit: (userName: string, password: string) => void;
    userNamelabel: string;
    passwordLable: string;
    buttonLable: string;
};

type LoginObject = {
    password: string;
    userName: string;
    isUserNameValid: boolean;
    isPasswordValid: boolean;
};

const Password: FunctionComponent<Props> = ({
    onSubmit,
    userNamelabel,
    passwordLable,
    buttonLable,
}: Props) => {
    const [passwordObject, setPassword] = useState({
        password: '',
        userName: '',
        isUserNameValid: true,
        isPasswordValid: true,
    });

    const onSubmitForm = (e: React.SyntheticEvent) => {
        e.preventDefault();

        onSubmit(passwordObject.userName, passwordObject.password);
    };

    const validationInput = (input: string): boolean => {
        if (!input) return false;
        return input.length <= 10;
    };

    const isFormIsValid = (): boolean => {
        if (!passwordObject.password || !passwordObject.userName) return false;
        return passwordObject.isPasswordValid && passwordObject.isUserNameValid;
    };

    const onBlurPassword = (): void => {
        setPassword(() => {
            return {
                ...passwordObject,
                isPasswordValid: validationInput(passwordObject.password),
            };
        });
    };

    const onBlurUserName = (): void => {
        setPassword(() => {
            return {
                ...passwordObject,
                isUserNameValid: validationInput(passwordObject.userName),
            };
        });
    };

    const handleChange = (prop: keyof LoginObject) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...passwordObject, [prop]: event.target.value });
    };

    return (
        <Form onSubmit={onSubmitForm}>
            <TextInput
                label={userNamelabel}
                value={passwordObject.userName}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ maxLength: 10 }}
                required
                onBlur={onBlurUserName}
                error={!passwordObject.isUserNameValid}
                variant="outlined"
                onChange={handleChange('userName')}
            />

            <TextInput
                type="password"
                label={passwordLable}
                onBlur={onBlurPassword}
                error={!passwordObject.isPasswordValid}
                value={passwordObject.password}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ maxLength: 10 }}
                required
                variant="outlined"
                onChange={handleChange('password')}
            />

            <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isFormIsValid()}
                onSubmit={onSubmitForm}
            >
                {buttonLable}
            </Button>
        </Form>
    );
};

export default Password;
