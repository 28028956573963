import { SECOND } from 'src/constants/times';
import { DropLine } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import { isEven, lorem } from 'src/utilis/utilis';
import { v4 } from 'uuid';

const getNumOfRides = (): number => {
    const h = window.screen.height;
    const numOfRides = Math.ceil(h * 0.012);
    // console.log(numOfRides);
    return numOfRides;
};

export const TIME_UNTIL_NEXT_RIDES = SECOND * 3;
export const NUMBER_OF_RIDES = getNumOfRides();

const generateRides = () => {
    let i = 10;
    const rides = [];
    while (i < 35) {
        rides.push({
            id: v4(),
            time: `${i >= 10 ? i - 9 : `0${i}`}:00`,
            description: lorem(i + (i % 2)),
            remarks: 'פיזור בלבד',
            vehicleType: 'אוטובוס ',
            driverName: 'מוניות רחובות',
            colorIndex: (isEven(i) ? 0 : 1) as 0 | 1,
        });
        i += 1;
    }
    return rides;
};
export const DUMMY_DATA: DropLine[] = generateRides();
