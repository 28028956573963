import React, { CSSProperties } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import themes from 'src/style/themes';
import { AnyFunction, IObject } from 'src/types/global';

interface Props {
    onClick: any;
    type?: 'add' | 'remove';
    disabled?: boolean;
    style?: IObject;
    text: string;
    withoutIcon?: boolean;
    sx?: CSSProperties;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const NakedBtn: React.FC<Props> = ({
    onClick,
    disabled,
    style,
    type,
    text,
    withoutIcon = false,
    sx = {},
}) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            sx={{
                color: disabled ? themes.colors.gray : themes.colors.blue,
                ...style,
                height: '25px',
                padding: '5px',
                // display: disabled && 'none',
                fontWeight: 400,
                ...sx,
            }}
        >
            {!withoutIcon && (type === 'remove' ? <RemoveIcon /> : <AddIcon />)}
            {text}
        </Button>
    );
};
export default NakedBtn;
