import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemStyle } from 'src/screens/RideSettings/styles/styledCSS';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';

/* eslint-disable react/button-has-type */
import { ListItem } from '@mui/material';

import { DAYS_HE } from 'src/constants/dates';
import { SpanBoldItalic } from 'src/screens/RideSettings/styles/styledComponents';
import useIsAuth from 'src/hooks/auth/useIsAuth';
import { FEATURES_IDS } from 'src/constants/auth';
import { useRootAppSelector } from '../../../../../../store/hooks';
import { StyledList } from '../PermissionsManagment/styles';
import SettingDescription from '../SettingDescription';
import { routesEndpoints } from 'src/routes/routes_endpoints';

type Props = {};

/**
 * A component that renders the General Settings accordion body.
 * @param {Props} props - The props for the component.
 * @returns A component that renders the General Settings accordion body.
 */
const GeneralSettingsAccordionBody = (props: Props) => {
    const { t } = useTranslation();

    const settings = useRootAppSelector((state) => selectors.selectData(state).generalSettings.settings);
    const clientCode = useRootAppSelector((state) => selectors.clientDataSelector(state).clientCode);

    const auth = useIsAuth();

    const [hover, setHover] = useState(false);

    const getSettingDescriptionText = (settingType: string): string => {
        switch (settingType) {
            case 'hidePassPhone':
                return t('ofPassengersOnDriversApp');

            default:
                return '';
        }
    };

    return (
        <StyledList sx={{ padding: 0 }}>
            {settings ? (
                <>
                    {' '}
                    <ListItem style={getItemStyle(false, hover)}>
                        <SettingDescription
                            mainText={t('hideSlashShowPassPhones')}
                            secondaryText={getSettingDescriptionText('hidePassPhone')}
                        />
                        <SpanBoldItalic>
                            {settings.hidePassPhone === '1'
                                ? t('theNumbersAreHidden')
                                : t('theNumbersAreDisplayed')}
                        </SpanBoldItalic>
                    </ListItem>
                    {clientCode && auth.isAuthFor(routesEndpoints.MANUAL_ORDER.SCREEN_ID, clientCode) && (
                        <ListItem style={getItemStyle(true, hover)}>
                            <SettingDescription mainText={t('firstDayOfWeek')} />
                            <SpanBoldItalic>
                                {settings.firstDayOfWeek ? `יום ${DAYS_HE[settings.firstDayOfWeek]}` : ''}
                            </SpanBoldItalic>
                        </ListItem>
                    )}
                </>
            ) : (
                <div>שגיאה בקבלת הגדרות - אנא נסע שנית</div>
            )}
        </StyledList>
    );
};

export default GeneralSettingsAccordionBody;
