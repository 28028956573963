import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getStrAsTemplateStringArr, getAsTemplateStrArr as asTSA } from 'src/utilis/utilis';
import { useAppDispatch } from 'src/store/hooks';
import { FEATURES_IDS } from 'src/constants/auth';
import useIsInViewport from 'src/hooks/useIsInViewport';
import { StyledInputContainer, StyledSectionContainer, StyledSectionTitle } from '../../../styles';
import { MainFormInputConfig, InputNames, Inputs } from '../../../types';
import GenericTextInput from '../../Inputs/GenericTextInput';
import styled from './styles';
import PhoneInput from '../../Inputs/PhoneInput';
import ShowSectionBtn from './ShowSectionBtn';

const ExtraDetailsSection = React.forwardRef(({ ref2 }: any, ref): JSX.Element => {
    // * hooks
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const fullFormMethods = useFormContext<Inputs>();
    const {
        register,
        formState: { errors },
        watch,
        getValues,
    } = fullFormMethods;

    const getInputConfig = React.useCallback(
        (name: InputNames): MainFormInputConfig => {
            return {
                register,
                errors,
                name,
            };
        },
        [errors, register]
    );

    return (
        <div ref={ref as React.LegacyRef<HTMLDivElement>}>
            <StyledSectionContainer ref={ref2} style={{ paddingTop: '20px' }}>
                <StyledSectionTitle>{t('extraDetails')}</StyledSectionTitle>
                <styled.ExtraDetailsBox>
                    <StyledInputContainer className="StyledInputContainer">
                        <GenericTextInput {...getInputConfig('invContactName')} />
                    </StyledInputContainer>
                    <PhoneInput
                        errors={errors}
                        register={register}
                        names={{
                            mainPart: 'invContactPhone',
                            prefix: 'invContactPhonePrefix',
                        }}
                    />
                    <StyledInputContainer className="StyledInputContainer">
                        <GenericTextInput {...getInputConfig('flightNum')} />
                    </StyledInputContainer>
                    <StyledInputContainer className="StyledInputContainer">
                        <GenericTextInput {...getInputConfig('clientOrderCode')} />
                    </StyledInputContainer>
                </styled.ExtraDetailsBox>
            </StyledSectionContainer>
        </div>
    );
});

export default ExtraDetailsSection;
