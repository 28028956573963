import React, { useEffect, useMemo } from 'react';
import useCommons from 'src/hooks/common/useCommons';
import styled, { css } from 'styled-components';

const TextContainer = styled.div<{}>`
    display: flex;
    justify-content: center;
    padding: 3px 0;
    gap: 6px;
    background: #e6f9ff;
    color: #2196f3;
    border-radius: 4px;
    min-width: 144px;
    text-align: left;
    width: 150px;
    max-width: 400px;
    font-size: 14px;
    align-self: flex-end;
    margin-bottom: 5px;
    font-weight: bold;
    margin-top: 15px;
`;

export const DisplayLineTotals: React.FC<{ time: number; distance: number }> = ({ time, distance }) => {
    const { t } = useCommons();

    return (
        <TextContainer>
            <span>{`${distance} ק"מ | ${time} דק'`}</span>{' '}
        </TextContainer>
    );
};
