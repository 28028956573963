import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const HeaderText = styled.span`
    font-size: 1.7rem;
`;

const Container = styled.div`
    padding: 20px;
    flex-grow: 1;
`;

const LinesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const HeadLineContainer = styled(Grid)`
    height: 10%;
`;

const GridItem = styled(Grid)`
    height: 100%;
`;

const LayotGridItem = styled(Grid)`
    height: 100%;
    background: #ffedb1;
    box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.25);
`;

const Div = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1.6875em;
    height: 8%;
`;

const FilterContiner = styled.div`
    width: 100%;
    height: 99vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25em 0;
`;

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em 1.6875em 0 1.6875em;
    height: 75%;
    box-sizing: border-box;
    overflow: hidden;
`;

export const StyledKendoGridContainer = styled.div`
    overflow: auto;
    .k-grid table tr:hover td {
        background: #ffedb1 !important;
        cursor: pointer !important;
    }
    .k-grid table tr:hover td.location-cell-td {
        background: white !important;
    }
`;
export default {
    Container,
    HeadLineContainer,
    GridItem,
    LinesContainer,
    LayotGridItem,
    HeaderText,
    Div,
    TableContainer,
    FilterContiner,
};
