import React from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import { stationsSelector } from 'src/store/slices/lines/linesSlice';
import StationInput from '../../../Inputs/StationInput/StationInput';
import { StationInputHandler } from '../../hooks.FullForm';
import { DisplayTimeFromPrev } from '../DisplayTimeFromPrev';
import { isAllObjectsContainsCoords } from 'src/screens/Main/hooks/useDirectionsApiLines';

type Props = {
    stationInputsHandling: StationInputHandler;
    isDisabled?: boolean;
};

function StationsInputsBox({ stationInputsHandling, isDisabled = false }: Props): JSX.Element {
    const { stations, endAddress, startAddress } = useRootAppSelector(
        (state) => state.linesSlice.ui.form.data
    );

    const getStationTime = (id: string): number => {
        const relevantStation = stations.find((station) => station.stationId === id);

        if (relevantStation && relevantStation.timing) {
            return relevantStation.timing;
        }

        return 0;
    };

    const GetStationInputs = React.useCallback(() => {
        return stationInputsHandling.stationsIdsOnStore.map((id, index) => {
            return (
                <div key={id} style={{ paddingBottom: '8px', display: 'flex', flexDirection: 'column' }}>
                    {isAllObjectsContainsCoords([...stations, endAddress, startAddress]) &&
                        getStationTime(id) !== 0 && <DisplayTimeFromPrev time={getStationTime(id)} />}
                    <StationInput
                        key={id}
                        stationId={id}
                        stationIndex={index}
                        removeStation={stationInputsHandling.removeStation}
                        isDisabled={isDisabled}
                    />
                </div>
            );
        });
    }, [stationInputsHandling.removeStation, stationInputsHandling.stationsIdsOnStore, isDisabled]);

    return <div>{GetStationInputs()}</div>;
}

export default StationsInputsBox;
