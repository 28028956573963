import React, { useEffect, useRef, useState } from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import { useRootAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useSetOptimizationWaypoints } from '../hooks/useSetOptimizationWaypoints';
import { border } from '@mui/system';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';

function sliceString(str: string, limit: number): string {
    if (str.length <= limit) return str;
    return `${str.slice(0, limit)}...`;
}

export interface TargetCompProps {
    isDisabled?: boolean;
}

const OptimizationTargetSection: React.FC<TargetCompProps> = ({ isDisabled }) => {
    const { t } = useTranslation();
    const setOptimizationWaypointsFromServer = useSetOptimizationWaypoints();

    const {
        data: {
            getWaypointsFromServer: { data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);

    const removeTargetFromWaypoint = () => {
        if (currentTargetWaypoint)
            setOptimizationWaypointsFromServer.setOptimizationWaypoints([
                { ...currentTargetWaypoint, isTarget: false },
            ]);
    };

    // needs to the target

    return (
        <div style={isDisabled ? { pointerEvents: 'none' } : {}}>
            {currentTargetWaypoint ? (
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: '#E6F6FE',
                        paddingRight: '2em',
                        padding: '7px',
                        borderRadius: '5px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid black',
                    }}
                >
                    <div>
                        <span style={{ fontWeight: 'bolder' }}> {t('originDestinationPoint')}:</span>{' '}
                        {currentTargetWaypoint && currentTargetWaypoint.waypointName
                            ? sliceString(currentTargetWaypoint.waypointName, 30)
                            : ''}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bolder' }}> {t('remarks')}:</span>{' '}
                        {currentTargetWaypoint && currentTargetWaypoint.remarks
                            ? sliceString(currentTargetWaypoint.remarks, 30)
                            : ''}
                    </div>
                    <MuiTooltipV3 title={t('removeTargetFromWaypoint')}>
                        <button
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                padding: '0',
                                cursor: 'pointer',
                            }}
                            type="button"
                            onClick={removeTargetFromWaypoint}
                        >
                            <FlagIcon color="primary" />
                        </button>
                    </MuiTooltipV3>
                </div>
            ) : null}
        </div>
    );
};
export default OptimizationTargetSection;
