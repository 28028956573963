import React, { FC } from 'react';
import { separationLineStyle } from '../styles/styles';

interface Props {
    mt?: number; // margin-top
    mb?: number; // margin-bottom
}
// eslint-disable-next-line react/prop-types
const SeparationLine: FC<Props> = ({ mb, mt }) => {
    return <div style={{ ...separationLineStyle, marginTop: mt, marginBottom: mb }} />;
};

export default SeparationLine;
