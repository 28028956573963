import React, { FC, useEffect, useMemo, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { useAppSelector } from 'src/store/hooks';
import { selectedReportNameSelector } from 'src/store/selectores/reportsSelectors';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import RidesInfo from '../RidesInfo/RidesInfo';
import { useOrdersInfoSelectors, useWidgetsConfigData } from './hooks/common';
import OrdersInfo from './OrdersInfo';
import widgetsHooks from '../hooks/common.hooks';

interface Props {}

const StatsWidget: FC<Props> = ({}) => {
    // useFetchStatistics();
    const { selectedReportType, dateAsStr, dateAfterApplyingFilters, clientCode, dateOnWidgets } =
        useWidgetsConfigData();

    const reportName = useAppSelector(selectedReportNameSelector);

    const isOnRidesReport: boolean = useMemo(() => selectedReportType === 'rides', [selectedReportType]);

    const ridesInfoProps = useMemo(() => {
        return {
            dateAfterApplyingFilters,
            dateAsStr,
            clientCode,
        };
    }, [clientCode, dateAfterApplyingFilters, dateAsStr]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <>
            <WidgetHeader
                title={isOnRidesReport ? 'ridesInfo' : 'orderInfo'}
                date={reportName === 'passengers' ? dateOnWidgets.toDate() : dateAfterApplyingFilters}
            />
            {isOnRidesReport ? <RidesInfo {...ridesInfoProps} /> : <OrdersInfo />}
        </>
    );
};

export default StatsWidget;
