import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';

import { CSSObject, List, ListItem, ListItemText, Select, Switch, ToggleButton } from '@mui/material';

import { useRootAppSelector } from 'src/store/hooks';
import {
    GeneralSettingsData,
    GeneralSettingTypes,
    HidePassPhoneTypes,
} from 'src/types/rideSettings/store/types.store';
import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import TextInput from 'src/components/Inputs/TextInput';
import { StyledToggleButton, StyledToggleButtonGroup } from 'src/style/styledMuiComponents';
import { FEATURES_IDS } from 'src/constants/auth';
import useIsAuth from 'src/hooks/auth/useIsAuth';
import { useIsFirstRender } from '../../../../../hooks/useIsFirstRender';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useFormController } from './hooks';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { googleMapsApiLanguage } from 'src/i18n/googleMapsApiLanguage';

type Props = {};

const styles = {
    listItem: {
        padding: '0 0 10px 0',
    },
    listItemText: {
        textAlign: 'right',
        width: '100%',
        '.MuiListItemText-primary': { fontFamily: 'Rubik' },
    },
    list: { width: '100%' },
    switch: { position: 'relative', right: '10px' },
    listItemContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    hidePassPhone: { toggleButton: { minWidth: '50px', maxHeight: '28px' } },
    firstDayOfWeek: { toggleButton: { minWidth: '30px', maxHeight: '28px' } },
} as const;

const MyListItem: React.FC<{ text: string; containerStyle?: React.CSSProperties; titleFlex?: number }> = ({
    text,
    containerStyle,
    children,
    titleFlex,
}) => {
    const { t } = useTranslation();

    return (
        <ListItem sx={styles.listItem} disablePadding disableGutters>
            <div
                style={
                    containerStyle || { width: '100%', height: '100%', display: 'flex', alignItems: 'center' }
                }
            >
                <ListItemText
                    id="switch-list-label-wifi"
                    primary={text}
                    sx={{ ...styles.listItemText, flexGrow: titleFlex || undefined }}
                />
                {children}
            </div>
        </ListItem>
    );
};

const GeneralSettingsFormBody = ({}) => {
    // const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // * Selectors
    const { formState, handleChange: handleGeneralSettingChange, getValueByType } = useFormController();
    const clientCode = useRootAppSelector((state) => selectors.clientDataSelector(state).clientCode);

    const auth = useIsAuth();

    // const settings = useRootAppSelector((state) => selectors.selectData(state).generalSettings.settings);

    //* Local States
    // * Event handlers

    // * Effects
    const currValues = {
        hidePassPhone: getValueByType('hidePassPhone'),
        firstDayOfWeek: getValueByType('firstDayOfWeek'),
        languageForAddresses: getValueByType('languageForAddresses'),
    };

    return (
        <>
            <List disablePadding sx={styles.list}>
                <MyListItem
                    containerStyle={styles.listItemContainer}
                    text={t('hidePassPhonesOnDriversApp')}
                    titleFlex={1}
                >
                    <StyledToggleButtonGroup
                        onChange={(e, val) => val && handleGeneralSettingChange('hidePassPhone', val)}
                        value={currValues.hidePassPhone}
                        fullWidth
                        exclusive
                        sx={{ flex: 4, '& .MuiToggleButtonGroup-grouped': { margin: '0 15px' } }}
                    >
                        <StyledToggleButton sx={styles.hidePassPhone.toggleButton} value="0">
                            {t('show')}
                        </StyledToggleButton>
                        <StyledToggleButton sx={styles.hidePassPhone.toggleButton} value="1">
                            {t('hide')}
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                </MyListItem>
                <MyListItem
                    containerStyle={styles.listItemContainer}
                    text={t('languageForAddresses')}
                    titleFlex={1}
                >
                    <StyledToggleButtonGroup
                        onChange={(e, val) => val && handleGeneralSettingChange('languageForAddresses', val)}
                        value={currValues.languageForAddresses}
                        fullWidth
                        exclusive
                        sx={{ flex: 4, '& .MuiToggleButtonGroup-grouped': { margin: '0 15px' } }}
                    >
                        <StyledToggleButton
                            sx={styles.hidePassPhone.toggleButton}
                            value={googleMapsApiLanguage.Hebrew}
                        >
                            {t('hebrew')}
                        </StyledToggleButton>
                        <StyledToggleButton
                            sx={styles.hidePassPhone.toggleButton}
                            value={googleMapsApiLanguage.English}
                        >
                            {t('english')}
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                </MyListItem>
                {clientCode && auth.isAuthFor(routesEndpoints.MANUAL_ORDER.SCREEN_ID, clientCode) && (
                    <MyListItem text={t('firstDayOfWeek')}>
                        <StyledToggleButtonGroup
                            fullWidth
                            onChange={(e, val) => val && handleGeneralSettingChange('firstDayOfWeek', val)}
                            value={currValues.firstDayOfWeek || ''}
                            sx={{ '& .MuiToggleButtonGroup-grouped': { margin: '0 15px' } }}
                            exclusive
                        >
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="1">
                                {t('sun')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="2">
                                {t('mon')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="3">
                                {t('tues')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="4">
                                {t('wed')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="5">
                                {t('thurs')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="6">
                                {t('fri')}
                            </StyledToggleButton>
                            <StyledToggleButton sx={styles.firstDayOfWeek.toggleButton} value="7">
                                {t('sat')}
                            </StyledToggleButton>
                        </StyledToggleButtonGroup>
                    </MyListItem>
                )}
            </List>
        </>
    );
};

export default GeneralSettingsFormBody;
