import { createTheme } from '@mui/material/styles';

// ! DEPRECATED - NOT WORKING
export const muiTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    '$.Mui-selected': {
                        fontWeight: 'bold',
                        color: 'orange',
                    },
                },
            },
        },
    },
});
