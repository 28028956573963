import React, { FunctionComponent, useState, useRef, KeyboardEvent, useEffect, useCallback } from 'react';
import 'date-fns';
import moment from 'moment';
import { v4 as GuidService } from 'uuid';

import DateFnsUtils from '@date-io/date-fns';
import heLocal from 'date-fns/locale/he';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CalendarTodayIcon from '@material-ui/icons/Today';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton, Box, TextFieldProps, withStyles, makeStyles } from '@material-ui/core';
import useDisableDelay from 'src/hooks/useDelayV2';
import MyCalenderIcon from '../Icons/MyCalenderIcon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDatePickerProps extends KeyboardDatePickerProps {
    withDebounceTime?: boolean;
    minValidDate?: Date;
    maxValidDate?: Date;
    minValidDateErrorMessage?: string;
}

const DatePickerWithPrevNext: FunctionComponent<IDatePickerProps> = ({
    okLabel,
    cancelLabel,
    todayLabel,
    label,
    onChange,
    value,
    withDebounceTime,
    maxDate,
    minDate,
    maxValidDate,
    minValidDate,
    minValidDateErrorMessage,
    ...datePickerProps
}: IDatePickerProps): JSX.Element => {
    const { t } = useTranslation();
    const okLabaleText = okLabel || t('ok');
    const cancelLabelText = cancelLabel || t('cancel');
    const labelText = label || t('date');
    const labelTodayText = todayLabel || t('today');

    const [isOpen, setIsOpen] = useState(false);
    const input = useRef<any>(null);

    const setIsOpenPicker = () => {
        setIsOpen(true);
    };

    const [tempDisabled, setTempDisabled] = useDisableDelay(1500);

    const setNextDay = useCallback(() => {
        setTempDisabled(true);
        const nextDate = moment(value).add('day', 1).isValid() && moment(value).add('day', 1).toDate();
        if (nextDate) onChange(nextDate, nextDate.toDateString());
    }, [onChange, setTempDisabled, value]);

    const setPreviousDay = useCallback(() => {
        setTempDisabled(true);
        const nextDate = moment(value).add('day', -1).isValid() && moment(value).add('day', -1).toDate();
        if (nextDate) onChange(nextDate, nextDate.toDateString());
    }, [onChange, setTempDisabled, value]);

    useEffect(() => {
        if (input.current) input.current?.focus();
    }, [value, input]);

    const onKeyDown = useCallback(
        ({ keyCode }: KeyboardEvent<HTMLDivElement>) => {
            const keyUp = 38;
            const keyDown = 40;

            if (keyCode === keyUp) setNextDay();

            if (keyCode === keyDown) setPreviousDay();
        },
        [setNextDay, setPreviousDay]
    );

    // -- Handling the disabled state of the arrows
    const [isArrowUpDisabled, setIsArrowUpDisabled] = useState(false);
    const [isArrowDownDisabled, setIsArrowDownDisabled] = useState(false);
    useEffect(() => {
        // Both arrows state
        if (!value) {
            setIsArrowDownDisabled(true);
            setIsArrowUpDisabled(true);
            return;
        }
        if (withDebounceTime) {
            setIsArrowDownDisabled(tempDisabled);
            setIsArrowUpDisabled(tempDisabled);
            return;
        }

        // Max date validation for up arrow
        if (maxDate && moment(value).add(1, 'days').isAfter(maxDate, 'days')) {
            setIsArrowUpDisabled(true);
        } else {
            setIsArrowUpDisabled(false);
        }

        // Min date validation for down arrow
        if (minDate && moment(value).subtract(1, 'days').isBefore(minDate, 'days')) {
            setIsArrowDownDisabled(true);
        } else {
            setIsArrowDownDisabled(false);
        }
    }, [maxDate, minDate, tempDisabled, value, withDebounceTime]);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (minValidDate && moment(value).isBefore(minValidDate, 'days')) {
            setErrorMessage(minValidDateErrorMessage || 'תאריך מוקדם מידי');
            return;
        }
        if (maxValidDate && moment(value).isAfter(maxValidDate, 'days')) {
            setErrorMessage('תאריך מאוחר מידי');
            return;
        }
        setErrorMessage('');
    }, [maxValidDate, minValidDate, minValidDateErrorMessage, value]);

    // Custom TextField from KeyboardDatePicker loses focus when typing in date
    // using useEffect to focus back to div
    const CustomizedTextField = React.useCallback(
        (props: TextFieldProps) => {
            return (
                <div>
                    <TextField
                        onKeyDown={onKeyDown}
                        style={{}}
                        {...props}
                        error={!!errorMessage.length || datePickerProps.error}
                        InputProps={{
                            inputRef: input,
                            endAdornment: (
                                <Box display="flex" flexWrap="no-wrap">
                                    <IconButton
                                        style={{ margin: 0, padding: 0 }}
                                        onClick={setIsOpenPicker}
                                        size="small"
                                    >
                                        <MyCalenderIcon />
                                    </IconButton>

                                    <Box display="flex" flexWrap="no-wrap" flexDirection="column">
                                        <IconButton
                                            size="small"
                                            onClick={setNextDay}
                                            style={{ padding: '0px' }}
                                            disabled={isArrowUpDisabled}
                                        >
                                            <KeyboardArrowUpIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            disabled={isArrowDownDisabled}
                                            size="small"
                                            onClick={setPreviousDay}
                                            style={{ padding: '0px' }}
                                        >
                                            <KeyboardArrowDownIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ),
                        }}
                    />
                    <p style={{ textAlign: 'right', fontSize: '14px' }}>{errorMessage}</p>
                </div>
            );
        },
        [errorMessage, isArrowDownDisabled, isArrowUpDisabled, onKeyDown, setNextDay, setPreviousDay]
    );

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocal}>
                <KeyboardDatePicker
                    className="DatePicker"
                    value={value}
                    onChange={onChange}
                    {...datePickerProps}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    helperText=""
                    minDate={minDate}
                    maxDate={maxDate}
                    maxDateMessage=""
                    minDateMessage=""
                    autoOk
                    open={isOpen}
                    label={labelText}
                    cancelLabel={cancelLabelText}
                    todayLabel={labelTodayText}
                    okLabel={okLabaleText}
                    TextFieldComponent={CustomizedTextField}
                    // style={{ padding: '0 !important' }}
                />
            </MuiPickersUtilsProvider>
        </>
    );
};

export default DatePickerWithPrevNext;
