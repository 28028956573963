/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, CombinedState, Reducer } from '@reduxjs/toolkit';
import { SummaryData } from 'src/screens/Reports/components/widgets/types';
import { ErrorState } from 'src/types/global';
import {
    CurrFiltersValues,
    PassengersFilterValues,
    WeeklyCounts,
} from 'src/types/reports/generalReportsTypes';
import { IRootReducer } from '../reducers';
import {
    ShortDepartmentData,
    ReportNames,
    UiState,
    SelectedClientId,
    DataState,
    ReportState,
    ReportName,
} from '../slices/reports/types';

// * Ui selectors
export const uiSelector = (state: any): UiState => state.reports.ui;

// -- filtering
export const filtersSelector = (state: IRootReducer, reportName?: ReportNames): CurrFiltersValues => {
    if (reportName === ReportNames.passengersReport) {
        return state.reports.ui.main.filters.byReport.passengers;
    }
    return state.reports.ui.main.filters.currFiltersValues;
};
export const allFiltersSelector = (state: IRootReducer) => state.reports.ui.main.filters;
export const filtersByReportSelector = (
    state: IRootReducer,
    reportName: ReportName
): PassengersFilterValues => {
    return state.reports.ui.main.filters.byReport[reportName];
};
export const selectApplyFiltersBtnClickCount = (state: IRootReducer): number =>
    state.reports.ui.main.filters.applyFiltersBtnClickCount;
export const selectResetFiltersClickCount = (state: IRootReducer): number =>
    state.reports.ui.main.filters.resetFiltersClickCount;

// -- misc
export const clientIdSelector = (state: IRootReducer): SelectedClientId =>
    state.reports.ui.main.selectedClientId;
export const selectRefreshBtnClickCount = (state: IRootReducer): number =>
    state.reports.ui.common.refreshPageBtnClickCount;
export const selectedReportNameSelector = (state: IRootReducer): ReportNames => {
    return state.reports.ui.main.selectedReport;
};
export const selectedReportTypeSelector = (state: IRootReducer) => {
    return state.reports.ui.main.selectedReportType;
};
export const isPrintingSelector = (state: IRootReducer): boolean => state.reports.ui.common.isPrinting;
export const selectError = (state: IRootReducer): ErrorState => state.reports.ui.common.error;
export const refreshWidgetsBtnClickCountSelector = (state: IRootReducer): number =>
    state.reports.ui.widgets.refreshWidgetsBtnClickCount;
export const widgetsUiSelector = (state: IRootReducer) => state.reports.ui.widgets;
// * data selectors
export const dataSelector = (state: IRootReducer): DataState => state.reports.data;
export const departmentsSelector = (state: IRootReducer): ShortDepartmentData[] =>
    state.reports.data.departments;
export const shortOrdersSelector = (state: IRootReducer) => state.reports.data.reports.shortOrders;
export const shortOrdersReportDataSelector = (state: IRootReducer) =>
    state.reports.data.reports.shortOrders.reportData;
export const selectedReportSelector = (state: IRootReducer): ReportState<any> => {
    const selectedReportName = state.reports.ui.main.selectedReport;
    return state.reports.data.reports[selectedReportName];
};
export const dateOnReportSelector = (state: IRootReducer) => state.reports.data.reports.passengers.date;
// ^ widgets data
export const ridesInfoCardsCountsSelector = (state: IRootReducer): WeeklyCounts =>
    state.reports.data.widgets.ridesStatistics.weeklyCounts;
export const ridesInfoSummaryDataSelector = (state: IRootReducer): SummaryData =>
    state.reports.data.widgets.ridesStatistics.summaryData;
export const widgetsDataSelector = (state: IRootReducer) => state.reports.data.widgets;

// * general selectors
export const generalSelector = (state: any) => state.reports.general;
export const authorizedReportsSelector = (state: any): ReportNames[] =>
    generalSelector(state).authorizedReports;
