/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState } from 'react';
import {
    rideSettingsActions,
    rideSettingsSelectors,
    rideSettingsSelectors as selectors,
} from 'src/store/slices/rideSettings/rideSettings';
import { settingTypeByStr, SettingTypes, SlSettingTypes } from 'src/types/rideSettings/store/types.store';

import { AccordionExpansion, Endpoints } from 'src/types/rideSettings/components/types.screen';
import { useAppDispatch, useRootAppSelector } from '../../../../store/hooks';
import { SettingsListContainer } from '../../styles/styledComponents';
import SettingAccordion from './SettingAccordion';
import { useAccordionExpansion } from '../../hooks/useAccordionExpansion';

interface Props {}

const SettingsList: FC<Props> = ({}) => {
    const allSettingsData = useRootAppSelector(selectors.selectData);

    const settingTypes: SlSettingTypes[] = ['generalSettings', 'permissionManagement'];

    const accordionExpansion = useAccordionExpansion();

    return (
        <SettingsListContainer>
            {settingTypes.map((type, i) => {
                return (
                    <SettingAccordion
                        key={type}
                        accordionExpansion={accordionExpansion}
                        settingType={type as SlSettingTypes}
                        disabled={false}
                        isAccordion={false}
                    />
                );
            })}
        </SettingsListContainer>
    );
};

export default SettingsList;
