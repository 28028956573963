import { WsResponse, ReqConfig, AxiosRes } from 'src/types/apiCommon.types';
import { IGetCourseTimesResDataItem } from 'src/types/coursesBuilding/apiTypes';
import {
    GetCourseStationsRequest,
    GetCourseStationsResponse,
    GetCourseStationsReqParams,
    CourseStationSettingWsDataItem,
    CourseStationTypes,
} from 'src/types/lines/api/types';
import { BaseParams } from '../types';
import { makeApiCallV2 } from '../utilis';

//  WS -- MyWayStudio_Get_Course_Times ---------------------------------------------------------------------------
interface GetCoursesTimesReqParams {
    token: string;
    courseCode: string;
}

type GetCoursesTimesResponse = WsResponse<IGetCourseTimesResDataItem[]>;
export type GetCoursesTimesReqConfig = ReqConfig<GetCoursesTimesReqParams>;

// FC REQUEST

const getCourseTimes = (requestConfig: GetCoursesTimesReqConfig): AxiosRes<GetCoursesTimesResponse> =>
    makeApiCallV2<GetCoursesTimesReqParams, GetCoursesTimesResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Course_Times',
    });

// WS -- Get Course Station Settings ---------------------------------------------------------------------------
type Response = WsResponse<CourseStationSettingWsDataItem[]>;
export type Config = ReqConfig<{ token: string; courseCode: string }>;

// FC REQUEST

export const getCourseStationsSettings = (requestConfig: Config): AxiosRes<Response> =>
    makeApiCallV2<{ token: string; courseCode: string }, Response>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Course_Stations_Settings',
    });

// WS -- Set Course ---------------------------------------------------------------------------
enum SetCourseResponseCodes {
    Success = '0',
    InvalidToken = '1',
    MissingClientCode = '2',
    MissingCourseDescription = '3',
    SQLError = '99',
}

export interface SetCourseResponse {
    response: SetCourseResponseCodes;
    message: string;
}

export interface SetAccountCodeParams extends BaseParams {
    courseCode: string; // if provided, updates the course, otherwise creates a new one
    courseId: string | undefined;
    courseDirection: 1 | 2 | 3;
    courseDescription: string; // required length of at least 1
    departmentCode: string;
    lineTypeCode: string;
    carTypeCode: string;
    remarks: string;
    activeFromDate: string; // Relative format
    activeUntilDate: string; // Relative format
    isActive: '1' | '0'; // if not sent - defaults to 1
}
export type SetCourseConfig = ReqConfig<SetAccountCodeParams>;

// FC REQUEST

export const setAccountCourseDetails = (requestConfig: SetCourseConfig): AxiosRes<SetCourseResponse> =>
    makeApiCallV2<SetAccountCodeParams, SetCourseResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_Account_Course_Details',
    });

// WS -- Set Course Stations---------------------------------------------------------------------------
enum SetCourseStationsResCodes {
    Success = '0',
    InvalidToken = '1',
    MissingClientCode = '2',
    MissingJsonData = '3',
    SQLError = '99',
}

export interface ISetCourseStationsWsResponse {
    response: SetCourseStationsResCodes;
    data: [] | string;
}

export interface ISetCourseStationJson {
    // time: string; // hh:mm required
    timing: string | null;
    city: string;
    street: string;
    house: string;
    type: CourseStationTypes | '';
    isActive: '1' | '0'; // if deleting send 0
    index: number; // if deleting - send 0
    stationCode: string; // if new station -> dont send, if existing station -> send
    lat: number;
    lon: number;
    stationRemark: string;
}

export interface ISetCourseStationsParams {
    token: string;
    courseCode: string; // if provided, updates the course, otherwise creates a new one
    jsonData: string; // stringified JSON array of stations (StationJson[])
}

export type SetCourseStationsReqConfig = ReqConfig<ISetCourseStationsParams>;

// FC REQUEST

export const setCourseStationsWs = (
    requestConfig: SetCourseStationsReqConfig
): AxiosRes<ISetCourseStationsWsResponse> =>
    makeApiCallV2<ISetCourseStationsParams, ISetCourseStationsWsResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_Course_Stations',
    });

// WS -- Set_Course_Times_Settings ---------------------------------------------------------------------------
enum SetCourseTimesSettingsCodes {
    Success = '0',
    InvalidToken = '1',
    MissingClientCode = '2',
    MissingJsonData = '3',
    SQLError = '99',
}

export interface SetCourseTimesSettingsWsRes {
    response: SetCourseTimesSettingsCodes;
    data: [] | string;
}

export interface ISetCourseTimesSettingsJsonItem {
    day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    startTime: string; // hh:mm
    endTime: string; // hh:mm
    carTypeCode: string;
    carsQty: number;
    isActive: '1' | '0'; // if deleting send 0
    code: string | null; // numeric string, only for deleting or editing
    // When day doesnt have a code it is considered active by BE (isActive = 1)
}
export interface ISetCourseTimesSettings {
    token: string;
    courseCode: string;
    jsonData: string; // stringified JSON array of times settings (ISetCourseTimesSettingsJsonItem[])
}

export type SetCourseTimesSettingsReqConfig = ReqConfig<ISetCourseTimesSettings>;

// FC REQUEST

export const setCourseTimesSettingsWs = (
    requestConfig: SetCourseTimesSettingsReqConfig
): AxiosRes<SetCourseTimesSettingsWsRes> =>
    makeApiCallV2<ISetCourseTimesSettings, SetCourseTimesSettingsWsRes>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Set_Course_Times_Settings',
    });

// WS -- Get_Course_Times_Settings ---------------------------------------------------------------------------
enum GetCourseTimesSettingsCodes {
    Success = '0',
    InvalidToken = '1',
    MissingCourseCode = '2',
    SQLError = '99',
}

export interface IGetCourseTimesSettings {
    token: string;
    courseCode: string; // if provided, updates the course, otherwise creates a new one
}

export interface DayRowCode {
    day: string; // '1' / '2' ...
    code: string; // numeric string
}
export interface ITimesSettingsDataItem {
    days: string; // '1, 2, 3', days separated by comma
    startTime: string; // HH:mm
    endTime: string; // HH:mm
    carTypeCode: string;
    carTypeName: string;
    carsQty: string;
    daysRowsCode: DayRowCode[]; // [{day: '1', code: '75345'}, {day: '2', code: '2342'}]
}

export type GetCourseTimesSettingsReqConfig = ReqConfig<IGetCourseTimesSettings>;

export interface GetCourseTimesSettingsWsRes {
    response: GetCourseTimesSettingsCodes;
    data: ITimesSettingsDataItem[]; // if sql error - will be string
}

// FC REQUEST

export const getCourseTimesSettingsWs = (
    requestConfig: GetCourseTimesSettingsReqConfig
): AxiosRes<GetCourseTimesSettingsWsRes> =>
    makeApiCallV2<IGetCourseTimesSettings, GetCourseTimesSettingsWsRes>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_Course_Times_Settings',
    });

const courseApi = {
    getCourseTimes,
    getCourseStationsSettings,
    getCourseTimesSettingsWs,
    setAccountCourseDetails,
    setCourseStations: setCourseStationsWs,
    setCourseTimesSettingsWs,
};

export default courseApi;
