/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IProps as IDropDown } from 'src/components/DropDown/DropDown';
import i18n from 'src/i18n';
import { DropDownState } from 'src/screens/PassengerManger/components/Passengers/hooks/useFilters';
import { ReduxDispatch } from 'src/store/type';
import { IObject } from 'src/types/global';
import { IItem } from 'src/types/line';
import { IAccount } from 'src/types/login';

import { IProps as IDropDownProp } from '../../../../components/DropDown/DropDown';
import { IDropdownV2Props } from 'src/components/DropDown/DropDownV2';

export const getClientFilterProp = ({
    clinetFilter,
    setClinetFilter,
    style,
}: {
    t?: Function;
    clinetFilter: DropDownState;
    setClinetFilter: Function;
    style?: IObject;
}): IDropdownV2Props => {
    return {
        formControlProp: {
            variant: 'outlined',
            style: style || { width: '208px' },
            size: 'small',
        },
        labelName: i18n.t('clientName'),
        selectProps: {
            autoWidth: false,
            multiple: false,
            label: i18n.t('clinet'),
            menuItems: clinetFilter.options,
            native: false,
            value: clinetFilter.value,
            onChange: (
                event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: number | string | unknown;
                }>
            ) => {
                const { value } = event.target;

                if (typeof value === 'number' || typeof value === 'string') {
                    setClinetFilter((preState: any) => ({
                        ...preState,
                        value,
                    }));
                }
            },
        },
    };
};

export const getProviderDropdownProp = ({
    providers,
    selectedFcAccount,
    fcAccounts,
    onSetSelectedFcAccount,
}: {
    t?: Function;
    providers: IItem[];
    selectedFcAccount: IAccount | null | undefined;
    fcAccounts: IAccount[];
    onSetSelectedFcAccount: (payload: IAccount) => ReduxDispatch<IAccount>;
}): IDropdownV2Props => {
    return {
        formControlProp: {
            variant: 'outlined',
            style: { width: '208px' },
            size: 'small',
        },
        labelName: i18n.t('provider'),
        selectProps: {
            autoWidth: false,
            multiple: false,
            label: i18n.t('provider'),
            menuItems: providers,
            native: false,
            value: selectedFcAccount?.accountCode,
            onChange: (
                event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                }>
            ) => {
                const { value } = event.target;
                const fcAccountTarget = fcAccounts.find((f) => String(f.accountCode) === String(value));

                if (fcAccountTarget) {
                    onSetSelectedFcAccount(fcAccountTarget);
                }
            },
        },
    };
};
