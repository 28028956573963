import React, { MouseEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MyWayTitle from 'src/components/MyWayTitle/MyWayTitle';
import { IconButton, Tooltip } from '@mui/material';
import { setClientCodeAction, uiSelector } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import DropDown from 'src/components/DropDown/DropDown';
import useClientFilters from 'src/hooks/useClientFilter';
import { getClientFilterProp } from 'src/screens/RideSettings/components/props/props';
import { asRootReducer } from 'src/store/utils';
import { DropDownState } from 'src/screens/PassengerManger/components/Passengers/hooks/useFilters';
import { DISPLAY_UI_LONG } from '../../../../constants/dates';
import { stringifyDate } from '../../../../utilis/utilis';
import { styles } from '../../styles';
import DropDownV2 from '../../../../components/DropDown/DropDownV2';

type Props = {
    handleExitClick: MouseEventHandler<HTMLButtonElement>;
};
const popperProps = {
    sx: {
        '.MuiTooltip-tooltip': {
            borderRadius: '3px',
            height: '40px',
            fontSize: '14px',
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
        },
    },
};

const useClientFiltersInput: () => [DropDownState, Function] = () => {
    const [clientFilter, setClientFilter] = useClientFilters({});
    const dispatch = useAppDispatch();

    // * effects
    useEffect(() => {
        // -- updates client input on change
        if (clientFilter.value) {
            dispatch(
                setClientCodeAction({
                    clientCode: Number(clientFilter.value),
                })
            );
        }
    }, [clientFilter, dispatch]);

    return [clientFilter, setClientFilter];
};

const Header: React.FunctionComponent<Props> = ({ handleExitClick }) => {
    const { t } = useTranslation();

    const date = useAppSelector((state) => uiSelector(asRootReducer(state)).date);

    const [clientFilter, setClientFilter] = useClientFiltersInput();

    return (
        <div style={styles.header}>
            <div style={styles.titleAndClientFilter}>
                <div className="headerTitle" style={styles.headerTitle}>
                    {t('dropsBoard')}
                </div>
                <div className="clientFilterDropdown" style={styles.clientDropdown}>
                    <DropDownV2
                        {...getClientFilterProp({
                            t,
                            clinetFilter: clientFilter,
                            setClinetFilter: setClientFilter,
                            style: styles.dropdown,
                        })}
                        labelName="לקוח"
                    />
                </div>
            </div>
            <div className="headerDate" style={styles.headerDate}>
                {stringifyDate(date, DISPLAY_UI_LONG)}
            </div>
            <div className="headerEnding" style={styles.headerEnding}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MyWayTitle fontSize="24px" />
                </div>
                <div style={styles.exitAppIcon}>
                    <Tooltip PopperProps={popperProps} title={t('exitLong')} arrow>
                        <IconButton onClick={handleExitClick} aria-label="back">
                            <ExitToAppIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default Header;
