import {
    styled as MuiStyled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { fontFamilies } from 'src/style/themes/defaultTheme';

export const StyledTableRow = MuiStyled(TableRow, {
    name: 'StyledTableRow',
})({
    '&.MuiTableRow-root': {
        height: '28px !important',
    },
});
export const StyledTableCell = MuiStyled(TableCell, {
    name: 'StyledTableCell',
})`
   &.MuiTableCell-root {
      border: none;
      border-bottom: 1px solid #e8e8e8;
      font-size: 16px;
      padding: 0;
      font-family: fontFamilies.Rubik;
   };
`;
export const tableContainerStyle = {
    fontFamily: fontFamilies.Rubik,
    paddingLeft: '30px',
    paddingTop: '10px',
};

export const colHeaderStyle = {
    color: '#BEBEBE',
    fontSize: '14px !important',
    border: 'none !important',
};
export const headerCellStyle = {
    display: 'flex',
    alignItems: 'center',
};
export const bodyCellStyle = {
    paddingRight: '6px',
};
export const lastBodyCellStyle = {
    ...bodyCellStyle,
    border: 'none !important',
};
export const iconStyle = {
    fontSize: '16px',
};

export const emptyPassengersLstStyle = {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Rubik',
    padding: '5px 0',
};
