import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import React from 'react';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { MainFormInputConfig, InputProps, PassengerInput } from '../../types';
import { getTranslatedErrorMessage } from './GenericTextInput';

interface Props extends InputProps<any> {
    names: {
        mainPart: string;
        prefix: string;
    };
}

const PhoneInput = ({ errors, register, names }: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ flex: 5, textAlign: 'right' }}>
                <TextField
                    {...register(names.mainPart)}
                    error={!!errors[names.mainPart]}
                    variant="outlined"
                    size="small"
                    label={t(getAsTemplateStrArr(names.mainPart))}
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    inputProps={{
                        maxlength: 12,
                    }}
                />
                {errors[names.mainPart] && <p>{getTranslatedErrorMessage(t, names.mainPart, errors)}</p>}
            </div>
            <span
                style={{
                    height: '100%',
                    padding: '10px 5px 0 5px',
                }}
            >
                -
            </span>
            <div style={{ flex: 1, textAlign: 'right' }}>
                <TextField
                    style={{ backgroundColor: 'white' }}
                    {...register(names.prefix)}
                    placeholder="05"
                    error={!!errors[names.prefix]}
                    variant="outlined"
                    size="small"
                    fullWidth
                    inputProps={{
                        maxlength: 3,
                    }}
                />
                {errors[names.prefix] && <p>{getTranslatedErrorMessage(t, names.prefix, errors)}</p>}
            </div>
        </div>
    );
};

export default PhoneInput;
