import React, { CSSProperties, FC } from 'react';
import { useTheme } from 'styled-components';
import styles from './MyWayTitle.style';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMyWayTitle {
    fontSize?: string;
}

const MyWayTitle: FC<IMyWayTitle> = ({ fontSize }) => {
    const { msColors } = useTheme();

    const headlineCss: CSSProperties = {
        fontSize: fontSize || '30px',
    };

    return (
        <styles.ResponsiveHeaderContainer show>
            <styles.TextContainer className="hidden-wl-1280">
                <styles.Text style={headlineCss} color={msColors.darkTextcolor}>
                    Studio
                </styles.Text>
                <styles.Text style={{ whiteSpace: 'break-spaces' }}> </styles.Text>
                <styles.Text style={headlineCss} color={msColors.yellow}>
                    MyWay
                </styles.Text>
            </styles.TextContainer>
        </styles.ResponsiveHeaderContainer>
    );
};

export default MyWayTitle;
