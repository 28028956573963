import { routesEndpoints } from 'src/routes/routes_endpoints';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
    UseFormHandleSubmit,
    SubmitHandler,
    useForm,
    UseFormGetValues,
    UseFormReturn,
    SubmitErrorHandler,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FormBtnsConfig } from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    closeFormAction,
    closePassengersFormAction,
    formSelector,
    onSubmitAction,
    fetchPassengersShortDetailsAction as reqGetPassengersShortDetailsAction,
    onSubmitExistingPassengersFormAction,
    addPassengerFormDisplayChangeAction,
    reqSetNewPassengerAction,
    apiRequestStatusSelector,
    setLineReqStatusSelector,
    newPassengerFormSelector,
    oldPassengerDataSelector,
    passengersFormSelector,
    onSubmitPassengersForCreatingStationsAction,
    isStationsInputValid,
    stationsSelector,
    setEditModeAction,
    setEditedLineClientIdAction,
} from 'src/store/slices/lines/linesSlice';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import { createArrWithoutItem } from 'src/utilis/utilis';
import { ReqSetPassengerConfig } from 'src/types/lines/api/types';
import cotValidationHooks from 'src/hooks/reactHookForm/useCotValidation';
import { setErrorMessage } from 'src/store/actions/loginAction';
import i18n from 'src/i18n';

import { TripDirections } from 'src/types/line';
import { linesSelector } from 'src/store/selectores/linesSelectores';
import { emptyValues, initialNewPassenger } from './constants';
import { schema, passengerSchema } from './schema';
import {
    ExistingPassenger,
    inputNamesArr,
    Inputs,
    PassengerInput,
    BtnsConfigArgs,
    FormTypes,
    FormSchema,
} from './types';
import { buildAddressJsonForNewPassengerOnAddLine } from '../../utils';
import { storedCoursesHooks, DialogControl } from './components/subForms/storedCourses/storedCourses.hooks';
import { useHistory } from 'react-router-dom';

export const useCurrFormType = (): FormTypes => {
    const isPassengersFormOpen = useAppSelector((state) => formSelector(state).passengersForm.isOpen);
    const isStoredCoursesFormOpen = useAppSelector(
        (state) => formSelector(state).storedCoursesImportForm.isOpen
    );

    const { isAddingNewPassenger, isOpen: isNewPassengerFormOpen } =
        useRootAppSelector(newPassengerFormSelector);

    const selectedStationId = useRootAppSelector((state) => passengersFormSelector(state).stationId);

    if (isNewPassengerFormOpen) {
        return isAddingNewPassenger ? 'newPassenger' : 'editingPassenger';
    }
    if (isPassengersFormOpen && selectedStationId) {
        return 'addingPassengersToStation';
    }
    if (isPassengersFormOpen && !selectedStationId) {
        return 'creatingStationsFromPassengers';
    }
    if (isStoredCoursesFormOpen) {
        return 'storedCourses';
    }

    return 'fullForm';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
/**
 * A custom hook that returns a configuration for the form close and submit btns.
 * @param {Function} handleSubmit - the function that is used to submit the form.
 * @param {boolean} isDirty - whether the form has been changed since the last submit.
 * @param {boolean} isValid - whether the form is valid.
 * @param {Function} onCancel - the function to call when the cancel button is clicked.
 * @returns {Function} a function that can be used to submit the form.
 */

export const useBtnsConfig = ({
    handleSubmit: onSubmit,
    onCancel,
    disabled,
    isSubmitting,
}: BtnsConfigArgs): FormBtnsConfig => {
    const { t } = useTranslation();
    const isOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.isOnEdit);

    return {
        disabled,
        onCancel,
        onSubmit,
        submitText: isOnEdit ? t('update') : t('add2'),
        isSubmitting,
        withLoader: true,
    };
};

export const usePassengersDetailsFetching = ({ clientCode }: { clientCode: number }): void => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));

    const { dbUrl = '' } = selectedFcAccount || {};
    const dispatch = useAppDispatch();

    // FC REQUEST

    useEffect(() => {
        if (dbUrl && token && clientCode) {
            dispatch(
                reqGetPassengersShortDetailsAction({
                    dbUrl,
                    endpoint: 'ws_MyWayStudio_Get_Passengers_Short_Details',
                    requestParams: {
                        token,
                        clientCode,
                    },
                })
            );
        }
    }, [dispatch, dbUrl, token, clientCode]);
};

export const useFullForm = () => {
    // const editedLineId = useRootAppSelector((state) => formSelector(state).editedLineId);

    // const l = useLineData();
    // console.log(editedLineId && l.getLineFormDataByLineId(editedLineId));
    // const [x, sx] = useState(editedLineId ? l.getLineFormDataByLineId(editedLineId) : emptyValues);

    // useEffect(() => {
    //     if (editedLineId) {
    //         sx(l.getLineFormDataByLineId(editedLineId));
    //     }
    // }, [editedLineId]);

    const fullForm = useForm<Inputs>({
        resolver: yupResolver(schema) as any,
        mode: 'onChange',
        defaultValues: emptyValues,
    });

    const {
        handleSubmit: handleFullFormSubmit,
        formState: { errors, isDirty, isValid },
        getValues,
        resetField,
    } = fullForm;

    return {
        fullForm,
        handleFullFormSubmit,
        errors,
        isDirty,
        isValid,
        getValues,
        resetField,
    };
};

/**
 * Hook that returns the form state and functions for the passengers form.
 * @returns {PassengersFormState} - The form state and functions for the passengers form.
 */

const processToMatchPassengerFormSchema = (oldData: ExistingPassenger): PassengerInput => {
    if (_.isEmpty(oldData)) {
        throw new Error("Can't process empty passenger data");
    }
    return {
        internalCode: oldData.internalCode,
        firstName: oldData.firstName,
        lastName: oldData.lastName,
        phone: oldData.mobilePhone,
        passengerRemarks: oldData.remarks,
        city: oldData.city,
        street: oldData.street,
        houseNum: oldData.houseNum,
        phonePrefix: '',
        email: '',
    };
};

export const usePassengersForm = () => {
    const { isAddingNewPassenger, oldPassengerData } = useAppSelector((state: any) =>
        newPassengerFormSelector(state as IRootReducer)
    );

    const passengersForm = useForm<PassengerInput>({
        resolver: yupResolver(passengerSchema) as any,
        mode: 'onChange',
        defaultValues:
            !isAddingNewPassenger && !_.isEmpty(oldPassengerData)
                ? processToMatchPassengerFormSchema(oldPassengerData as ExistingPassenger)
                : initialNewPassenger,
    });

    const dispatch = useAppDispatch();

    const {
        // register: fullFormRegister,
        handleSubmit: handlePassengersFormSubmit,
        formState: { errors: passengersErrors, isDirty: passengersIsDirty, isValid: passengersIsValid },
        getValues,
        reset,
        trigger,
    } = passengersForm;

    return {
        passengersForm,
        handlePassengersFormSubmit,
        passengersErrors,
        passengersIsDirty,
        passengersIsValid,
        trigger,
        passengersGetValues: getValues,
        onCancel: () => {
            reset();
            dispatch(addPassengerFormDisplayChangeAction({ isOpen: false }));
        },
        reset,
    };
};

// is spreadble to object
export const isSpreadable = (obj: any): boolean => {
    return _.isObject(obj) && !_.isArray(obj) && !_.isFunction(obj);
};
export const toString = (val: any): string => {
    if (val === null || val === undefined) {
        return '';
    }
    return JSON.stringify(val);
};

const getValuesAsFormSchema = (currValues: any): FormSchema | null => {
    if (isSpreadable(currValues)) {
        return { ...currValues, clientCode: toString(currValues.clientCode) };
    }
    return null;
};

/**
 * Returns the arguments for the BtnsConfig hook.
 * @param {Function} handleFullFormSubmit - The function to call when the full form is submitted.
 * @param {Function} handlePassengersFormSubmit - The function to call when the passengers form is submitted.
 * @param {Function} onPassengersCancel - The function to call when the passengers form is cancelled.
 * @returns {BtnsConfigArgs} - The arguments for the BtnsConfig hook.
 */
export const useGetBtnsConfigArgs = ({
    handleFullFormSubmit,
    handlePassengersFormSubmit,
    onNewPassengersCancel,
    passengersGetValues,
    clientCode,
    fullFormMethods,
    dialogControl,
    submissionStatusState,
}: {
    handleFullFormSubmit: UseFormHandleSubmit<Inputs>;
    handlePassengersFormSubmit: UseFormHandleSubmit<PassengerInput>;
    onNewPassengersCancel: () => void;
    passengersGetValues: UseFormGetValues<PassengerInput>;
    clientCode: string;
    fullFormMethods: UseFormReturn<Inputs, any>;
    dialogControl: DialogControl;
    submissionStatusState: {
        isSubmittingCourse: boolean;
        setIsSubmittingCourse: React.Dispatch<React.SetStateAction<boolean>>;
    };
}): BtnsConfigArgs => {
    const dispatch = useAppDispatch();
    const vanillaDispatch = useDispatch();
    const history = useHistory();

    // -- selectors
    const newPassengerReqStatus = useAppSelector(apiRequestStatusSelector);

    const setLineReqStatus = useAppSelector(setLineReqStatusSelector);

    const preEditValues: ExistingPassenger = useAppSelector((state) => {
        const valuesOnState = oldPassengerDataSelector(state);
        return valuesOnState as ExistingPassenger;
    });

    const isStationValid = useRootAppSelector(isStationsInputValid);

    // -- Helper hooks
    const currForm = useCurrFormType();
    const { dbUrl, token } = useGetApiRequestBasicParams();

    // Stored courses hooks
    const submitHelpers = storedCoursesHooks.useSubmitHelpers(
        fullFormMethods.setValue,
        submissionStatusState.setIsSubmittingCourse
    );

    const storedCoursesBtnsConfigs = storedCoursesHooks.useCourseImportBtnsConfigs({
        currValues: getValuesAsFormSchema(fullFormMethods.getValues()),
    });

    const { validateStartTime } = cotValidationHooks.useCotValidationMethods(fullFormMethods);
    const { getClientCOTVal } = cotValidationHooks.useClientCOT();
    const lineOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.lineOnEdit);
    const { stations, startAddress, endAddress } = useRootAppSelector(
        (state) => state.linesSlice.ui.form.data
    );

    // console.log(fullFormMethods.getValues());

    // * returned values
    // -- on full form configs
    if (currForm === 'fullForm') {
        return {
            handleSubmit: async () => {
                //  validate all inputs EXCEPT start time
                const inputsToValidate = createArrWithoutItem(inputNamesArr.concat(), 'startTime');
                fullFormMethods.trigger(inputsToValidate);

                //  validate start time
                // doing so separately solves the issue of the trigger() method overriding the real startTime errors
                const [startTime, date] = fullFormMethods.getValues(['startTime', 'date']);

                const COT = getClientCOTVal(String(clientCode));

                if (typeof COT !== 'number') return;

                const isValidStartTime = await validateStartTime(startTime, date, COT);

                // done in order to stop the handleFullFormSubmit method from overriding the startTime errors ...
                //  ... by running the schema test method without the context values (COT && selected date)
                if (!isValidStartTime) return;

                const onSubmitSuccess: SubmitHandler<Inputs> = (formData) => {
                    dispatch(setEditedLineClientIdAction({ editedLineClientId: null }));

                    if (isStationValid) {
                        dispatch(
                            onSubmitAction({
                                formData: { ...formData, stations, endAddress, startAddress },
                                dbUrl,
                                token,
                                isRoundTrip: formData.tripDirection === TripDirections.RoundTrip,
                                history,
                            })
                        );
                    }
                };
                const onSubmitFailure: SubmitErrorHandler<Inputs> = (errors) => {
                    if (_.isEmpty(errors)) vanillaDispatch(setErrorMessage(i18n.t('InvalidInputs')));
                };

                handleFullFormSubmit(onSubmitSuccess, onSubmitFailure)();
            },
            onCancel: () => {
                dispatch(closeFormAction());
                history.push(routesEndpoints.HOME);
                dispatch(setEditModeAction({ isOnEdit: false, lineOnEdit: null }));
                dispatch(setEditedLineClientIdAction({ editedLineClientId: null }));
            },
            isSubmitting: setLineReqStatus === 'LOADING',
        };
    }
    // -- on adding  passengers to stations
    if (currForm === 'addingPassengersToStation') {
        return {
            handleSubmit: () => {
                dispatch(onSubmitExistingPassengersFormAction());
                dispatch(closePassengersFormAction());
            },
            disabled: false,
            onCancel: () => dispatch(closePassengersFormAction()),
        };
    }
    // -- on courses form
    if (currForm === 'storedCourses') {
        return storedCoursesBtnsConfigs.getConfigs({
            submitHelpers,
            submissionStatusState,
            dialogControl,
        });
    }
    // -- on creating stations from passengers
    if (currForm === 'creatingStationsFromPassengers') {
        return {
            handleSubmit: () => {
                dispatch(onSubmitPassengersForCreatingStationsAction());
                dispatch(closePassengersFormAction());
            },
            disabled: false,
            onCancel: () => dispatch(closePassengersFormAction()),
        };
    }
    // -- on new passenger form
    return {
        handleSubmit: handlePassengersFormSubmit(() => {
            const {
                internalCode,
                firstName,
                lastName,
                passengerRemarks,
                phone,
                phonePrefix,
                city,
                street,
                houseNum,
                email,
            } = passengersGetValues();

            const addressJson = buildAddressJsonForNewPassengerOnAddLine(
                passengerRemarks || '',
                city || '',
                street || '',
                houseNum || '',
                preEditValues.addressCode || ''
            );

            // FC REQUEST

            if (dbUrl && token) {
                const requestConfig: ReqSetPassengerConfig = {
                    dbUrl,
                    endpoint: 'ws_MyWayStudio_Set_Passenger',
                    requestParams: {
                        token,
                        passCode: preEditValues?.passCode,
                        addressJson,
                        clientCode,
                        internalCode,
                        firstName,
                        lastName,
                        mobilePhone: `${phonePrefix}${phone}`,
                        eMail: email,
                        isActive: 1,
                    },
                };
                dispatch(reqSetNewPassengerAction({ requestConfig }));
            }
        }),
        onCancel: onNewPassengersCancel,
        isSubmitting: newPassengerReqStatus === 'LOADING',
    };
};

export const useScroll = (formBodyRef: RefObject<HTMLDivElement>) => {
    const stations = useRootAppSelector(stationsSelector);
    const [reachedBottomOfForm, setReachedBottomOfForm] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const onScroll = useCallback(() => {
        if (formBodyRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = formBodyRef.current;

            const diff = Math.floor(scrollHeight - (scrollTop + clientHeight));

            if (diff === 0) {
                setReachedBottomOfForm(true);
                return;
            }

            setReachedBottomOfForm(false);
        }
    }, [formBodyRef.current]);

    useLayoutEffect(() => {
        if (formBodyRef.current) {
            const { scrollHeight, clientHeight } = formBodyRef.current;
            setIsOverflowing(scrollHeight !== clientHeight);
        }
    }, [stations]);

    return {
        onScroll,
        reachedBottomOfForm,
        isOverflowing,
    };
};

// const useLineData = () => {
//     const lines = useSelector((state) => linesSelector(state)) || [];
//     const filterDate = useSelector((state: IRootReducer) => loginSelector(state).selectedDate);

//     const getLineFormDataByLineId = (id: string) => {
//         const originalData = lines.find((line) => String(line.lineCode) === id) || null;
//         if (!originalData) return {};

//         const dataInFormFormat = {
//             clientCode: originalData.accountCode,
//             clientOrderCode: originalData.clientOrderCode,
//             flightNum: originalData.flightNumber,
//             invContactName: originalData.invContactName,
//             invContactPhone: originalData.invContactPhone,
//             startAddress: '',
//             endAddress: '',
//             lineRemarks: originalData.longRemarks,
//             date: filterDate,
//             startTime: originalData.startTime,
//             invContactPhonePrefix: '',
//         };
//         return dataInFormFormat;
//     };

//     return { getLineFormDataByLineId };
// };
