/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import { ReqStatus } from 'src/api/types';
import { onSetPassengers } from 'src/store/actions/passengerAction';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { clientIdSelector } from 'src/store/selectores/passengersSelector';
import { setErrorCode, setErrorMessage } from 'src/store/actions/loginAction';
import { ISetSagaPassenger } from '../../../../../api/passengerMangerApi/types';

interface Props {
    setAlertMsg: Function;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRestore = ({ setAlertMsg }: Props): any => {
    const dispatch = useDispatch();

    // * Selectors
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const clientId = useSelector((state: IRootReducer) => clientIdSelector(state));
    const loadingPassengers = useSelector((state: IRootReducer) => state.passengerReudcer.loadingPassengers);

    // * Dispatchers
    const onSetPassengersDispatch = (payload: ISetSagaPassenger) => {
        dispatch(onSetPassengers(payload));
    };

    // * Local States
    const [passengerToRestore, setPassengerToRestore] = useState<IPassenger | null>(null);
    const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false);

    // * Handlers
    const handleRestore = (passenger: IPassenger) => {
        setPassengerToRestore(passenger);
        setIsRestoreDialogOpen(true);
    };
    const { t } = useTranslation();
    const onRestoreConfirmed = () => {
        if (!passengerToRestore || !selectedFcAccount || !clientId || !token) return;
        // -- passId = internalCode
        if (!passengerToRestore.passId) {
            setAlertMsg(t('psngrMustHaveInternalCodePlsEditPsngr'));
            setPassengerToRestore(null);
            setIsRestoreDialogOpen(false);
            return;
        }
        const { dbUrl = '', proxyUrl } = selectedFcAccount;

        const restorePassenger = () => {
            const payload: ISetSagaPassenger = {
                clientCode: clientId,
                dbUrl,
                proxyUrl,
                token,
                passenger: { ...passengerToRestore, isActive: '1' },
                ...passengerToRestore,
                passTypeCode: Number(passengerToRestore.passTypeCode),
                internalCode: passengerToRestore.passId,
                isActive: '1',
            };
            onSetPassengersDispatch(payload);
        };
        restorePassenger();

        setPassengerToRestore(null);
        setIsRestoreDialogOpen(false);
    };

    const onCloseRestoreDialog = () => setIsRestoreDialogOpen(false);

    // * Effects
    useEffect(() => {
        if (passengerToRestore && loadingPassengers === ReqStatus.FAIL) alert('error');
    }, [loadingPassengers]);

    return {
        onCloseRestoreDialog,
        setIsRestoreDialogOpen,
        isRestoreDialogOpen,
        onRestoreConfirmed,
        handleRestore,
    };
};
