/* eslint-disable import/no-named-as-default-member */
import { CSSProperties } from 'react';
import defaultTheme, { fontFamilies } from './themes/defaultTheme';

const rubik = fontFamilies.Rubik;
const primaryFontStyle = {
    fontFamily: rubik,
    color: defaultTheme.msColors.primaryTextcolor,
    fontStyle: 'normal',
};
// * Fonts
// bolds
export const boldRubik: CSSProperties = {
    ...primaryFontStyle,
    fontWeight: 'bold',
};
export const mediumRubikBold: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '14px',
    fontWeight: 'bold',
};
export const mediumLargeRubikBold: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '18px',
    fontWeight: 'bold',
};

// regulars
export const mediumRubik: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '14px',
};
export const mediumLargeRubik: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '18px',
};
export const largeRubik: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '24px',
    // fontWeight: 400,
};

export const formHeaderFont: CSSProperties = {
    ...primaryFontStyle,
    fontSize: '28px',
    // padding: '0 0 10px 0',
};
export const headlineCss: CSSProperties = { fontSize: '30px' };
// * Layout
export const noPaddingNMargin: CSSProperties = {
    padding: 0,
    margin: 0,
};
export const full: CSSProperties = {
    width: '100%',
    height: '100%',
    // backgroundColor: 'cyan',
};
export const fullWithBorder: CSSProperties = {
    width: '100%',
    height: '100%',
    // backgroundColor: 'cyan',
    border: '1px dotted gray',
};

export const centeredFlexItem: CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center ',
    alignItems: 'center',
};
