import ButtonBase from '@material-ui/core/ButtonBase';
import styled from 'styled-components';

export const LinkStyle = styled(ButtonBase)`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-size: 16px;
    &.MuiButtonBase-root {
        margin-top: 15px;
    }
`;

export const Header = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const HeaderText = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
`;

export const SpaceAround = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 80%;
`;
