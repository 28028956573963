import styled from 'styled-components';

const Header = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #2196f3;
`;

const HeaderText = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    color: ${({ theme }) => theme.colors.blue};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
`;

export default { Header, HeaderText };
