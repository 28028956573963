import {
    IOptimizationFormWaypoint,
    IOptimizationWaypoint,
    IOptimumRouteData,
    IOptimumWaypoint,
} from './api/types';

export const buildWaypointName = (waypoint: IOptimizationWaypoint | IOptimizationFormWaypoint) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum, waypoint.placeName];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildWaypointNameForOptimizationResult = (waypoint: IOptimumWaypoint) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const getRoutesByIds = (
    routeIdsWithColor: { routeId: string; color: string }[],
    routes: IOptimumRouteData[]
): IOptimumRouteData[] => {
    const routeIds: string[] = [];
    routeIdsWithColor.forEach((routeIdWithColor) => {
        routeIds.push(routeIdWithColor.routeId);
    });

    return routes.filter((route) => routeIds.includes(route.localId));
};

export const buildRouteName = (route: IOptimumRouteData) => {
    const fields = [route.optimumRoute[0].city, route.optimumRoute[0].street, route.optimumRoute[0].houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    const routeLength = route.optimumRoute.length;
    const lastFields = [
        route.optimumRoute[routeLength - 1].city,
        route.optimumRoute[routeLength - 1].street,
        route.optimumRoute[routeLength - 1].houseNum,
    ];
    const lastRelevantFields = lastFields.filter(
        (field) => field !== '' && field !== null && field !== undefined
    );

    const firstWaypointName = relevantFields.join(', ');
    const lastWaypointName = lastRelevantFields.join(', ');

    const routeName = `${firstWaypointName} - ${lastWaypointName}`;

    return routeName;
};

export const updateWaypoints = (waypoints1: IOptimizationWaypoint[], waypoints2: IOptimizationWaypoint[]) => {
    const updatedWaypoints: IOptimizationWaypoint[] = [];
    const waypointDict: { [key: string]: IOptimizationWaypoint } = {};

    waypoints1.forEach((waypoint1) => {
        waypointDict[waypoint1.waypointId] = waypoint1;
    });

    waypoints2.forEach((waypoint2) => {
        if (waypointDict[waypoint2.waypointId]) {
            waypointDict[waypoint2.waypointId] = { ...waypointDict[waypoint2.waypointId], ...waypoint2 };
        } else {
            waypointDict[waypoint2.waypointId] = waypoint2;
        }
    });

    Object.values(waypointDict).forEach((waypoint) => {
        updatedWaypoints.push(waypoint);
    });

    return updatedWaypoints;
};
