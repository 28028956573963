import useDelay from 'src/hooks/useDelay';
import { useAppDispatch } from 'src/store/hooks';
import { incrementRefreshPageBtnClickCountAction } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

const useScreenRefresh = () => {
    const dispatch = useAppDispatch();

    const { delay, setDelay, setDelayWithTimeout } = useDelay(false);

    const onRefreshClick = () => {
        if (!delay) {
            dispatch(incrementRefreshPageBtnClickCountAction());
            setDelay(true);
            setDelayWithTimeout(false, 1000);
        }
    };

    return { delay, onRefreshClick };
};

export default useScreenRefresh;
