import React, { useCallback, useEffect, useState } from 'react';
import useDisableDelay from 'src/hooks/useDelayV2';
import { useRootAppSelector } from 'src/store/hooks';
import { selectedPassengersIdsSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { manualOrdersCommonHooks } from './manualOrdersHooks.common';

function useAddShiftBtn(data: any[]): {
    handleClick: () => void;
    showBtn: boolean;
    tempDisabled: boolean;
} {
    // -- handling Add shift Button show --------------------------------
    const [showBtn, setShowBtn] = useState(false);
    const [tempDisabled, setTempDisabled] = useDisableDelay(1000);

    const selectedPassengerIds = useRootAppSelector(selectedPassengersIdsSelector);

    // -- Handling btn show state
    useEffect(() => {
        const EST_TABLE_RENDER_TIME = 80;
        let timeout: NodeJS.Timeout;

        if (data.length && selectedPassengerIds.length) {
            timeout = setTimeout(() => setShowBtn(true), EST_TABLE_RENDER_TIME);
        } else {
            setShowBtn(false);
        }

        return () => clearTimeout(timeout);
    }, [data.length, selectedPassengerIds.length]);

    const dispatchOpenFormActions = manualOrdersCommonHooks.useClickOpen();

    const handleClick = useCallback(() => {
        setTempDisabled(true);
        dispatchOpenFormActions();
    }, [dispatchOpenFormActions, setTempDisabled]);

    return { handleClick, showBtn, tempDisabled };
}

export default useAddShiftBtn;
