import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { setCoursesOnFormAction } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { ChosenCourse, WsCourseDataItem, CourseType } from 'src/types/passengersManager/courseChoosingTypes';
import { Par, StyledCourseChoiceCard } from '../styles';
import { CourseData, useCourseChoiceCardSelectors } from './utils';
import useCardProps from '../../../../hooks/useCardProps';

interface Props {
    course: WsCourseDataItem;
    i: number;
    courseType: CourseType;
}

const CourseChoiceCard = ({ course, i, courseType }: Props): JSX.Element => {
    // eslint-disable-next-line prettier/prettier
    const { courseCode, departmentName, courseDescription, groupName } = course;

    const isPickup = courseType === 'pickup';

    const dispatch = useAppDispatch();

    const { coursesOnForm, currAddressCode } = useCourseChoiceCardSelectors();

    const { cardProps } = useCardProps({
        courseCode,
        coursesOnForm,
        currAddressCode,
        isPickup,
        i,
    });

    const onChangeSelection = useCallback((): void => {
        const newChosenCourses: ChosenCourse[] = coursesOnForm.map((loopCourse: ChosenCourse) => {
            // -- finding current course and editing it, else we return -
            // -- the course untouched because its not the one we need -
            // -- to edit
            // * if found it
            if (loopCourse.addressCode === currAddressCode) {
                // -- if clicked on selected course we remove it from the state
                if (isPickup && courseCode === loopCourse.pickup) {
                    return {
                        addressCode: currAddressCode,
                        pickup: '',
                        drop: loopCourse.drop,
                    };
                }
                // -- if clicked on selected course we remove it from the state
                if (!isPickup && courseCode === loopCourse.drop) {
                    return {
                        addressCode: currAddressCode,
                        pickup: loopCourse.pickup,
                        drop: '',
                    };
                }
                // -- else - we add it to the state
                return {
                    addressCode: currAddressCode,
                    pickup: isPickup ? courseCode : loopCourse.pickup,
                    drop: !isPickup ? courseCode : loopCourse.drop,
                };
            }
            // * else (not found it)
            return loopCourse;
        });

        dispatch(setCoursesOnFormAction({ chosenCourses: newChosenCourses }));
    }, [courseCode, coursesOnForm, currAddressCode, dispatch, isPickup]);

    return (
        <StyledCourseChoiceCard key={i} onClick={onChangeSelection} {...cardProps}>
            <Par flex={1}>{course.courseId}</Par>
            <Par flex={1}>{departmentName}</Par>
            <Par flex={2}>{courseDescription}</Par>
            <Par flex={1}>{groupName}</Par>
        </StyledCourseChoiceCard>
    );
};

export default CourseChoiceCard;
