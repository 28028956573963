import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { IAuthorization, ReqStatus } from 'src/api/types';
import { PassengersErrorMessagesEnum } from 'src/types/global';
import { IAccount, RefreshTokenStatus, SingupType } from '../types/login';

export type ReduxDispatch<T> = {
    type: string;
    payload: T;
};

export type ReduxReducer<T> = {
    type: string;
    payload: T;
};

export type State = any;

export interface IReduxProvider<T = {}> {
    (state: State, payload: T): State;
}

export interface ISagaRequest<T = {}> {
    payload: T;
    type: string;
}

export interface DepartmentData {
    departmentCode: number;
    departmentName: string;
    contactName: string;
    contactPhone: string;
    contactMphone: string;
    contactEmail: string;
    departmentRemarks: string;
}

export interface ILoginState extends DefaultRootState {
    isAuthenticated: ReqStatus | null;
    fcAccounts: IAccount[]; // Fc accounts / providers for mobile phone
    errorMassege?: string | null;
    token: string; // auth token
    errorCode?: PassengersErrorMessagesEnum | null;
    userUUID?: string; // ?
    mobile?: string; // contact mobile number
    gps_server: string;
    gps_token: string;
    loginType: SingupType | null; // 1 - quick,  2 - pin code, 3 - ? , 4 - one time3
    refreshTokenStatus?: RefreshTokenStatus | undefined; // 1 - init, 2 - ok, 3 - invalid
    selectedFcAccount: IAccount | null; // eg Maya Tour
    selectedDate?: Date;
    isIgnoreInitialRoute?: boolean;
    authorizationToken?: IAuthorization; // token for feature & screen authorization
    googleApiInterfaceInterval?: number | null;
}

export enum ErrorMessagesOld {
    MissingWs = 'נדרש עדכון גרסה - אנא פנה לספק ההסעות',
    SqlError = 'oopsSomethingWentWrongTryAgain',
    GeneralError = 'oopsSomethingWentWrongTryAgain',
    UnknownError = 'oopsSomethingWentWrongTryAgain',
}
