/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
// import { styleIcon } from '../../../../Passengers/css-styles';
import themes from 'src/style/themes';
import { Button } from '@material-ui/core';
import RemoveIcon from '@mui/icons-material/Remove';
import { fontFamilies } from '../../../../../../../style/themes/defaultTheme';

interface Props {
    handleAddAddressClick?: any;
    handleRemoveAddressClick?: any;
    disabled: boolean;
    style: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AddAddressInputBtn: React.FC<Props> = ({ handleAddAddressClick, disabled, style }) => {
    return (
        <>
            <Button
                onClick={handleAddAddressClick}
                disabled={disabled}
                style={{
                    color: disabled ? themes.colors.gray : themes.colors.blue,
                    ...style,
                    height: '25px',
                    padding: '5px',
                    fontFamily: fontFamilies.Rubik,
                    fontWeight: 400,
                }}
            >
                <AddIcon />
                הוסף כתובת
            </Button>
        </>
    );
};
const RemoveAddressInputBtn: React.FC<Props> = ({ handleRemoveAddressClick, disabled, style }) => {
    return (
        <>
            <Button
                onClick={handleRemoveAddressClick}
                disabled={disabled}
                style={{
                    color: disabled ? themes.colors.gray : themes.colors.blue,
                    ...style,
                    height: '25px',
                    padding: '5px',
                    display: disabled && 'none',
                    fontFamily: fontFamilies.Rubik,
                    fontWeight: 400,
                }}
            >
                <RemoveIcon />
                מחק כתובת
            </Button>
        </>
    );
};
export { AddAddressInputBtn, RemoveAddressInputBtn };
