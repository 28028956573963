import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorFetchingDataText from 'src/components/ErrorFetchingDataText';
import Progressbar from 'src/components/Progressbar/Progressbar';
import Spinner from 'src/components/Spinner/Spinner';
import { useRootAppSelector } from 'src/store/hooks';
import { statisticsSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import * as Styles from './Dailycard.Style';

interface Props {
    pickup: number;
    drop: number;
}

export const useStatisticsDataLoading = () => {
    const statisticsData = useRootAppSelector(statisticsSelector);

    const isInStatus = (status: ApiCallStatus, type: 'daily' | 'weekly') => {
        return statisticsData[type].status === status;
    };

    return {
        isInStatus,
    };
};
const Dailycard: FC<Props> = ({ pickup, drop }) => {
    const { t } = useTranslation();
    const loadingHook = useStatisticsDataLoading();

    return (
        <Styles.Container>
            <Styles.Header>
                <Styles.Text>{t('dailyCompare')}</Styles.Text>
            </Styles.Header>
            <Styles.Body>
                {loadingHook.isInStatus('LOADING', 'daily') ? <Spinner /> : null}
                {loadingHook.isInStatus('SUCCESS', 'daily') ? (
                    <>
                        <Styles.Row>
                            <Styles.Text>{t('pickUp')}</Styles.Text>
                            <Progressbar count={pickup} position="Horizontal" color="#81B1D8" />
                        </Styles.Row>
                        <Styles.Row>
                            <Styles.Text>{t('drop')}</Styles.Text>
                            <Progressbar count={drop} position="Horizontal" color="#BCEFFF" />
                        </Styles.Row>
                    </>
                ) : null}
                {loadingHook.isInStatus('FAILED', 'daily') ? <ErrorFetchingDataText /> : null}
            </Styles.Body>
        </Styles.Container>
    );
};

export default Dailycard;
