import axios from 'axios';
import _ from 'lodash';
import { AxiosRes, BaseReqParams } from 'src/api/types';
import { makeApiCallV2 } from 'src/api/utilis';
import i18n from 'src/i18n';
import { FormSchema, Station } from 'src/screens/Main/components/AddLineForm/types';
import {
    stringifyAddress,
    stringifyAddressV2,
} from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/utils';
import storeIndex from 'src/store/index';
import { IRootReducer } from 'src/store/reducers';
import { dataSelector, passengersDataSelector, stationsSelector } from 'src/store/slices/lines/linesSlice';
import { TripDirections } from 'src/types/line';
import dateHelpers from 'src/utilis/dateHelpers';

// On Debug Switch to dev

const devUrlPrefix = 'http://interfacesservicestest.yit.co.il/EmailService/EmailService.svc/';
const prodUrlPrefix = 'https://interfaceserviceapi.y-it.co.il/EmailService/EmailService.svc/';
const wsSendMail = 'ws_send_email_json';

const addStrudelPrefix = (bodyObj: Object) => {
    const newObj = {};
    Object.keys(bodyObj).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, spaced-comment
        // @ts-ignore
        newObj[`@${key}`] = bodyObj[key]
    });
    return newObj;
};

const sendEmail = (reqBody: SendMailParams): AxiosRes<any> => {
    const url = `${prodUrlPrefix}${wsSendMail}`;
    const temp = addStrudelPrefix(reqBody);
    const tempJson = JSON.stringify(temp);

    // return axios.post(url, tempJson, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }); stream
    return axios.post(url, tempJson, { headers: { 'Content-Type': 'application/json' } });
};

export interface SendMailParams {
    as_smtp_sender: string; // כתובת מייל שולח
    as_smtp_sender_name: string; // שם השולח
    as_recipient: string; // נמענים
    as_subject: string; // נושא
    as_details: string; // תוכן מייל
    as_content_type: string; // סוג תוכן
}

export interface LineConfirmationEmailDataStruct {
    referEmailList: string;
    reference: string;
    startCode: string;
    returnCode: string;
    siteName: string;
    rideType: string; // הלוך חזור וכד
    lineDate: string; // "2022-12-13"
    startStartTime: string; // "12:00",
    startEndTime: string; // "13:00",
    flightNumber: string; // "12312",
    invContactName: string; // "ישראל ישראלי",
    invContactPhone: string | number; // "05023213212",
    passQty: number; // 14,
    returnStartTime: string; // "12:00",
    returnEndTime: string; // "13:00",
    startStations: {
        type: string; // motza \ yaad \ stationNum
        time: string; // "12:00",
        description: string;
    }[];
    carName: string;
    returnStations:
        | {
              type: string; // motza \ yaad \ stationNum
              time: string; // "12:00"";
              description: string;
          }[]
        | null;
}

export const getStoreState = () => {
    return storeIndex.store.getState() as unknown as IRootReducer;
};

/**
 * Gets the passengers data for the given passenger ids.
 * @param {string[]} passengersIdsOnStation - the passenger ids on the station.
 * @returns {Passenger[]} - the passengers data for the given passenger ids.
 */
export const getPassengersDataByIds = (passengersIdsOnStation: string[]) => {
    const store = getStoreState();

    const allPassengers = passengersDataSelector(store);
    const coursePassengers = dataSelector(store).lastUsedCoursePassengers;

    // get passengers from client's passengers list
    const passengers = allPassengers.filter((passenger) =>
        passengersIdsOnStation.includes(passenger.passCode)
    );

    // for some reason
    if (passengers.length !== passengersIdsOnStation.length) {
        // get passengers from course's passengers list
        const passengersNotOnClientsPassengersList = passengersIdsOnStation.filter(
            (passengerId) => !passengers.find((passenger) => passenger.passCode === passengerId)
        );

        coursePassengers.forEach((passenger) => {
            if (passengersNotOnClientsPassengersList.includes(passenger.passCode)) {
                passengers.push(passenger);
            }
        });
    }

    return passengers;
};

/**
 * Gets the passengers data for all passengers in the stations.
 * @returns {Passenger[]} - The passengers data for all passengers in the stations.
 */
export const getStationsPassengersData = () => {
    try {
        const store = getStoreState();

        const stations = stationsSelector(store);

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const getPassengersIdsFromStations = (stations: Station[]) => {
            const passengersIdsLst: string[] = [];
            stations.forEach((station) => {
                station.passengers.forEach((p) => {
                    passengersIdsLst.push(p);
                });
            });

            return passengersIdsLst;
        };

        const passIds = getPassengersIdsFromStations(stations);

        const fullData = getPassengersDataByIds(passIds);

        return fullData;
    } catch (error) {
        return null;
    }
};

const HE_RIDE_TYPES = {
    [TripDirections.OneWay]: 'הלוך',
    [TripDirections.RoundTrip]: 'הלוך חזור',
    [TripDirections.Linked]: 'צמוד',
};

/**
 * Builds the list of stations for the confirmation email.
 * @param {Station[]} stations - the list of stations for the line
 * @param {FormSchema['startAddress']} startAddress - the start address of the line
 * @param {FormSchema['startAddress']} endAddress - the end address of the line
 * @param {LineConfirmationEmailDataStruct['startStations']} lineTimes - the start and end times of the line
 * @returns {LineConfirmationEmailDataStruct['startStations']} - the list of stations for the line
 */
const buildStationsLst = (
    stations: Station[],
    startAddress: FormSchema['startAddress'],
    endAddress: FormSchema['startAddress'],
    lineTimes: {
        startTime: string;
        endTime: string;
    }
): LineConfirmationEmailDataStruct['startStations'] => {
    const stationsLst = [
        {
            type: 'מוצא',
            time: lineTimes.startTime,
            description: stringifyAddressV2(startAddress),
            remarks: '',
        },
    ];

    stations.forEach((station, idx) => {
        stationsLst.push({
            type: `${i18n.t('station')} ${idx + 1}`,
            time: '',
            description: stringifyAddressV2(station),
            remarks: '',
        });
    });

    stationsLst.push({
        type: 'יעד',
        time: lineTimes.endTime,
        description: stringifyAddressV2(endAddress),
        remarks: '',
    });

    return stationsLst;
};

const getCarNameById = (carId: string) => {
    const store = getStoreState();
    const cars = store.linesSlice.data.getCarTypes.data || [];
    const car = cars.find((c) => String(c.id) === carId);
    return car?.type || '';
};

/**
 * Calls the email service with the given form data.
 * @param {FormSchema} formData - The form data to send.
 * @param {string} lineCodes - The line codes to send.
 * @returns None
 */

const getStationHtml = (stations: any) => {
    const startStationLength = stations.length;
    let stationsHtml = '';

    stationsHtml += `
    <div style="background-color: #EEEEEE; margin-bottom: 5px; margin-left: 80px; border-radius: 3px; padding: 7px;">
        <b style="padding: 20px; font-size: 18px;">מוצא <span style="font-weight: 400;">${stations[0].description}</span></b> 
    </div>
    `;

    for (let i = 1; i < startStationLength - 1; i++) {
        stationsHtml += `
        <div style="background-color: #EEEEEE; margin-bottom: 5px; margin-left: 80px; border-radius: 3px; padding: 7px;">
            <b style="padding: 20px; font-size: 16px;">תחנה <span style="font-weight: 400;">${stations[i].description}</span></b>
        </div>
        `;
    }

    stationsHtml += `
    <div style="background-color: #EEEEEE; margin-left: 80px; border-radius: 3px; padding: 7px; margin-bottom: 20px;">
        <b style="padding: 20px; font-size: 18px;">יעד <span style="font-weight: 400; ">${
            stations[startStationLength - 1].description
        }</span></b>
    </div>
    `;

    return stationsHtml;
};

export const callEmailService = async (
    formData: FormSchema,
    lineCodes: {
        startLineCode: string;
        returnLineCode: string;
    }
) => {
    const store = storeIndex.store.getState() as unknown as IRootReducer;
    const lineCreatorUser = store.commonSlice.userDetails;
    const passengers = getStationsPassengersData();
    const stations = stationsSelector(store) || [];
    const clients = dataSelector(store).clients || [];

    if (!passengers) return FuncSuccess.Fail;

    const emailData: LineConfirmationEmailDataStruct = {
        referEmailList:
            clients.find((c) => String(c.clientCode) === String(formData.clientCode))?.referEmailList || '',
        reference: formData.clientOrderCode ? formData.clientOrderCode : '',
        carName: formData.carId ? getCarNameById(formData.carId) : '',
        startCode: lineCodes.startLineCode,
        returnCode: lineCodes.returnLineCode || '',
        siteName: clients.find((c) => String(c.clientCode) === String(formData.clientCode))?.clientName || '',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rideType: HE_RIDE_TYPES[formData.tripDirection],
        lineDate: dateHelpers.stringifyDate(formData.date, 'DD.MM.YY'),
        startStartTime: formData.startTime,
        startEndTime: formData.endTime || '',
        flightNumber: formData.flightNum || '',
        invContactName: formData.invContactName || '',
        invContactPhone: `${formData.invContactPhonePrefix}-${formData.invContactPhone}`,
        passQty: passengers.length,
        returnStartTime: formData.returnTripStartTime || '',
        returnEndTime: formData.returnTripEndTime || '',
        startStations: buildStationsLst(stations, formData.startAddress, formData.endAddress, {
            startTime: formData.startTime,
            endTime: formData.endTime || '',
        }),
        returnStations:
            formData.tripDirection === TripDirections.RoundTrip
                ? buildStationsLst(
                      _.cloneDeep(stations).reverse(),
                      formData.endAddress,
                      formData.startAddress,
                      {
                          startTime: formData.returnTripStartTime || '',
                          endTime: formData.returnTripEndTime || '',
                      }
                  )
                : null,
    };

    const passengersWithEmail = passengers.filter((p) => p.email);

    let startStationsHtml = '';
    let returnStationsHtml = '';

    if (emailData.startStations) {
        startStationsHtml = getStationHtml(emailData.startStations);
    }

    if (emailData.returnStations) {
        returnStationsHtml = getStationHtml(emailData.returnStations);
    }

    const isMoreDetails =
        (emailData.invContactPhone && emailData.invContactPhone !== '-') ||
        emailData.invContactName ||
        emailData.flightNumber ||
        emailData.reference;

    const mailBody = `
    <body dir="rtl" style="margin: 0px; font-family: Arial, Helvetica, sans-serif;">
      <table cellspacing="0" width = "100%">

        <tr style="height: 72px; color: white; background-color: #2196F3;">

            <td width = "25%" style="font-size: 20px; font-weight: 700; padding-right: 20px;">
                אישור נסיעה 
            </td>

            <td width = "25%" style="font-size: 20px; font-weight: 700;">
                Order Confirmation
            </td>
            

            <td  width = "25%" style="font-size: 14px; font-weight: 700; padding-right: 20px;">
                קוד נסיעה בהלוך ${emailData.startCode}
            </td>

            <td  width = "25%" style="font-size: 14px; font-weight: 700;">
            ${emailData.returnCode ? ` קוד נסיעה בחזור ${emailData.returnCode}` : ''}
            </td>

         </tr>

         <tr style="height: 120px">
            <td style="padding-right: 20px;" colspan="2">
                <b>שם האתר </b>
                <span style="font-weight: 100;">${emailData.siteName}</span>
                <br />
                <br />

                <b>סוג נסיעה </b>
                <span style="font-weight: 100;">${emailData.rideType}</span>
                <br />
                <br />

                <b>תאריך </b>
                <span style="font-weight: 100;">${emailData.lineDate}</span>

                <br />
                <br />

                <span><b>סוג רכב </b></span>
                <span style="font-weight: 100;">${emailData.carName ? emailData.carName : 'ללא רכב'}</span>

                <br />
                <br />

                <b>כמות נוסעים </b>
                <span style="font-weight: 100;">${emailData.passQty}</span>
            </td>

            <td  colspan="2">
                <b>
                    <span style="margin-left: 40px !important;">הלוך</span>
                </b>

                <br />
                <br />

                <b>
                    <span>שעת התחלה </span>
                    <span style="font-weight: 100;">${emailData.startStartTime}</span>
                </b>

                <br />

                ${
                    emailData.startEndTime
                        ? `
                        <b>
                        <span>שעת סיום </span>
                        <span style="font-weight: 100;">${emailData.startEndTime}</span>
                        </b>
                        `
                        : ''
                }

                <br />
                <br />

                ${
                    emailData.returnStartTime
                        ? `
                <b>
                    <span style="margin-left: 40px !important;">חזור</span>
                </b>
                `
                        : ''
                }

                <br />
                <br />

                ${
                    emailData.returnStartTime
                        ? `
                <b>
                    <span>שעת התחלה </span>
                    <span style="font-weight: 100;">${emailData.returnStartTime}</span>
                </b>
                `
                        : ''
                }

                <br />

                ${
                    emailData.returnEndTime
                        ? `
                <b>
                    <span>שעת סיום </span>
                    <span style="font-weight: 100;">${emailData.returnEndTime}</span>
                </b>
                `
                        : ''
                }

            </td>
            
         </tr>

         <tr >
            <td colspan="4">
                <hr>
            </td>

         </tr>

         <tr>
            <td colspan="4">
                <div style="font-size: 24px; font-weight: 700; margin-bottom: 20px;">
                    ${startStationsHtml.length > 0 ? `נסיעה הלוך` : ``}
                </div>
                ${startStationsHtml}
            </td>
         </tr>


         <tr>
            ${
                returnStationsHtml.length > 0
                    ? ` 
            <td colspan="4" >
                <div style="font-size: 24px; font-weight: 700;  margin-bottom: 20px;">
                    נסיעה חזור
            </div>
            ${returnStationsHtml}
            </td>

     `
                    : ''
            }          

         </tr>

         ${
             isMoreDetails
                 ? `
            <tr >
                <td colspan="4">
                    <hr>
                </td>
            </tr>
    
            <tr >
                <td colspan="4">
                    <div style="font-size: 24px; font-weight: 700; padding-right: 20px; margin-bottom: 20px;">
                        פרטים נוספים
                    </div>
                </td>
    
            </tr>
         
         <tr >

            ${
                emailData.invContactName
                    ? `
            <td colspan="2" style="padding-right: 20px;">
                <b>איש קשר </b>
                <span style="font-weight: 100;">${emailData.invContactName}</span>

                <br />
                <br />

                ${
                    emailData.invContactPhone && emailData.invContactPhone !== '-'
                        ? `
                <b>טלפון </b>
                <span style="font-weight: 100;">${emailData.invContactPhone}</span>
                `
                        : ''
                }

            </td>
            `
                    : ''
            }


            ${
                emailData.flightNumber
                    ? `
            <td colspan="2">
                <b>מספר טיסה </b>
                <span style="font-weight: 100;">${emailData.flightNumber}</span>

                <br />
                <br />

                ${
                    emailData.reference
                        ? `
                <b>אסמכתא </b>
                <span style="font-weight: 100;">${emailData.reference}</span>
                `
                        : ''
                }
            </td>
            `
                    : ''
            }
           
         </tr>
            
         `
                 : ''
         }

         <tr >
            <td colspan="4">
                <hr>
            </td>
         </tr>

      </table>
   </body>
    `;
    console.log(emailData);

    passengersWithEmail.forEach(async (p) => {
        console.log('p.email', p.email);
        const res = await sendEmail({
            as_smtp_sender: 'no-reply@y-it.co.il',
            as_smtp_sender_name: 'YIT',
            as_recipient: p.email,
            as_subject: 'הודעה בדבר אישור נסיעה',
            as_details: mailBody,
            as_content_type: 'text/html',
        });
    });

    if (lineCreatorUser.email) {
        const res = await sendEmail({
            as_smtp_sender: 'no-reply@y-it.co.il',
            as_smtp_sender_name: 'YIT',
            as_recipient: lineCreatorUser.email,
            as_subject: 'הודעה בדבר אישור נסיעה',
            as_details: mailBody,
            as_content_type: 'text/html',
        });
    }

    if (emailData.referEmailList) {
        const res = await sendEmail({
            as_smtp_sender: 'no-reply@y-it.co.il',
            as_smtp_sender_name: 'YIT',
            as_recipient: emailData.referEmailList,
            as_subject: 'הודעה בדבר אישור נסיעה',
            as_details: mailBody,
            as_content_type: 'text/html',
        });
    }
};
