import { MockRouteResponse, CommonCodes } from '../../types/mockServer/routes.types';

import {
    DeleteFileParams,
    S3FilesReqErrorCodes,
    UploadFileEnums,
    UploadFileMappers,
    UploadFileParams,
} from 'src/types/uploadFileFt/uploadFileApiTypes';
import { uploadFilesApi } from 'src/api/miscApis/uploadFileServiceApi';
import { GetFileParams, GetFileResponse } from '../../types/uploadFileFt/uploadFileApiTypes';
import { addDollarSignPrefix } from 'src/api/utilis';

const { UniqueMockServerCodes } = UploadFileEnums;
export interface UploadFileClientData {
    requestConfig: UploadFileParams;
}

export type UploadFileResult = MockRouteResponse<null>;
const uploadFile = async (clientData: UploadFileClientData): Promise<UploadFileResult> => {
    const generalErrorCode = UniqueMockServerCodes.UploadFailed;

    const result: UploadFileResult = {
        code: null,
        data: {},
    };

    try {
        const endpointResponse = await uploadFilesApi.reqUploadFile(clientData.requestConfig as any);

        // console.log(endpointResponse);

        if (endpointResponse.data.IsSuccess) {
            result.code = CommonCodes.Ok;
            result.data.data = null;

            return result;
        }

        if (endpointResponse.data.ErrorCode) {
            result.code = endpointResponse.data.ErrorCode;
            result.data.data = null;
            result.data.message = UploadFileMappers.errorMessagesByCode[endpointResponse.data.ErrorCode];

            return result;
        }

        const code = generalErrorCode;
        result.code = code;
        result.data = { message: UploadFileMappers.errorMessagesByCode[code] };

        return result;
    } catch (error) {
        result.code = generalErrorCode;
        result.data = {
            message: UploadFileMappers.errorMessagesByCode[generalErrorCode],
        };

        return result;
    }
};

export interface DeleteFileClientData {
    requestConfig: DeleteFileParams;
}
type DeleteFileResult = MockRouteResponse<null>;
const deleteFile = async (clientData: DeleteFileClientData): Promise<DeleteFileResult> => {
    const generalErrorCode = UniqueMockServerCodes.DeleteFailed;

    const result: DeleteFileResult = {
        code: null,
        data: {},
    };

    try {
        const endpointResponse = await uploadFilesApi.reqDeleteFile(clientData.requestConfig);

        if (endpointResponse.data.IsSuccess) {
            result.code = CommonCodes.Ok;
            result.data.data = null;

            return result;
        }

        if (endpointResponse.data.ErrorCode) {
            result.code = endpointResponse.data.ErrorCode;
            result.data.data = null;
            result.data.message = UploadFileMappers.errorMessagesByCode[endpointResponse.data.ErrorCode];

            return result;
        }

        const code = generalErrorCode;
        result.code = code;
        result.data = { message: UploadFileMappers.errorMessagesByCode[code] };

        return result;
    } catch (error) {
        result.code = generalErrorCode;
        result.data = { message: UploadFileMappers.errorMessagesByCode[generalErrorCode] };

        return result;
    }
};

export interface GetFileClientData {
    requestConfig: GetFileParams;
}
export type GetFileResult = MockRouteResponse<GetFileResponse | null>;

const getFile = async (clientData: GetFileClientData): Promise<GetFileResult> => {
    const generalErrorCode = UniqueMockServerCodes.GetFailed;

    const result: GetFileResult = {
        code: null,
        data: {},
    };

    try {
        const endpointResponse = await uploadFilesApi.reqGetFile(clientData.requestConfig);

        if (
            endpointResponse.data.IsSuccess ||
            endpointResponse.data.ErrorCode === S3FilesReqErrorCodes.FetchingFromDbFailed
        ) {
            result.code = CommonCodes.Ok;
            result.data.data = endpointResponse.data;

            return result;
        }

        if (endpointResponse.data.ErrorCode) {
            result.code = endpointResponse.data.ErrorCode;
            result.data.data = null;
            result.data.message = UploadFileMappers.errorMessagesByCode[endpointResponse.data.ErrorCode];

            return result;
        }

        const code = generalErrorCode;
        result.code = code;
        result.data = { message: UploadFileMappers.errorMessagesByCode[code] };

        return result;
    } catch (error) {
        result.code = generalErrorCode;
        result.data = { message: UploadFileMappers.errorMessagesByCode[generalErrorCode] };

        return result;
    }
};

const uploadFileRoutes = {
    uploadFile,
    deleteFile,
    getFile,
};

export default uploadFileRoutes;
