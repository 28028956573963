/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { DialogContentText } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';

export const RestoreDialogContent = (t: Function) => {
    return (
        <DialogContentText>
            <span>{t('doYouApproveToRestorePassenger')} </span>
        </DialogContentText>
    );
};

export const RestoreDialogTitle = (t: Function) => {
    return <span> {t('approveRestore')} </span>;
};
