import { AxiosResponse } from 'axios';
import { ApiCallRequest, CallGetShiftTimesRequestPayload, CallGetShiftTimesResponse } from '../types';
import { newMakeApiCall } from '../utils';

export type CallGetShiftRequest = ApiCallRequest<CallGetShiftTimesRequestPayload>;

// FC REQUEST

export const callGetShiftTimes = (
    requestData: CallGetShiftRequest
): Promise<AxiosResponse<CallGetShiftTimesResponse>> => {
    return newMakeApiCall<CallGetShiftTimesResponse>({
        ...requestData,
        endpoint: 'ws_MyWayStudio_Get_Shifts_Times_Report',
    });
};
