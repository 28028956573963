/* eslint-disable react/button-has-type */
import { IconButton } from '@mui/material';
import React from 'react';
import Spinner from 'src/components/Spinner/Spinner';
import { CenterFlexDiv } from 'src/style/styledComponents.style';
import { UploadFileEnums, UploadFileMappers } from 'src/types/uploadFileFt/uploadFileApiTypes';
import hooks from '../hooks.uploadFiles';
import Styled from '../styles.uploadFile';
import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import { ApiReqStatus } from 'src/store/slices/generalFeatures/types.generalFeatures';
import uploadFileHelpers, { FileTypes } from '../helpers.uploadFile';

const NO_NAME_TEXT = 'ללא שם';

const officeDocsRedirect = 'https://view.officeapps.live.com/op/embed.aspx?src=';

const { GeneralErrorMessages } = UploadFileEnums;

const HTML_SPACE_SYMBOL = '\u00A0';

interface Props {}

// Component to display file using iframe
const FileDisplayIframe: React.FC<{ fileUrl: string; fileType: FileTypes | null }> = ({
    fileUrl,
    fileType,
}) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe
                loading="lazy"
                allowFullScreen
                src={fileType === 'xlsx' ? `${officeDocsRedirect}${encodeURIComponent(fileUrl)}` : fileUrl}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="file"
            />
        </div>
    );
};

const DeleteBtn: React.FC<{}> = (props) => {
    const { handleDeleteFileClick } = hooks.useDeleteFileClick();
    const { sliceState } = hooks.useFileFtSlice();

    return sliceState.data.actionsStatus.delete === ApiReqStatus.Loading ? (
        <div style={{ paddingRight: '2px' }}>
            <Spinner />
        </div>
    ) : (
        <IconButton onClick={handleDeleteFileClick}>
            <Styled.DeleteBtnContainer>
                <TrashIcon width="18px" height="18px" color="#FF0000" />
            </Styled.DeleteBtnContainer>
        </IconButton>
    );
};

const FileDisplaySection: React.FC<Props> = ({}) => {
    const { t } = hooks.useCommons();
    const { sliceState } = hooks.useFileFtSlice();

    const file = {
        name: sliceState.data.fileData?.fileName || NO_NAME_TEXT,
        url: sliceState.data.fileData?.fileLink || '',
    };

    const { isExpired: isUrlExpired } = hooks.useIsFileUrlExpired();

    return (
        <Styled.FileDisplaySectionContainer className="FileDisplaySectionContainer">
            {sliceState.data.actionsStatus.get === ApiReqStatus.Succeeded ? (
                <>
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <strong>
                                {t('currFile')}:{HTML_SPACE_SYMBOL}
                            </strong>
                            <span>{file.name}</span>
                            <DeleteBtn />
                        </div>
                        {isUrlExpired ? (
                            <CenterFlexDiv fill>{t('fileLinkExpired')}</CenterFlexDiv>
                        ) : (
                            <FileDisplayIframe
                                fileUrl={file.url}
                                fileType={uploadFileHelpers.getFileTypeByFileName(file.name)}
                            />
                        )}
                    </>
                </>
            ) : (
                <CenterFlexDiv fill>
                    {sliceState.data.actionsStatus.get === ApiReqStatus.Failed ? (
                        <div>{t(GeneralErrorMessages.GetFailed)}</div>
                    ) : (
                        <></>
                    )}
                    {sliceState.data.actionsStatus.get === ApiReqStatus.Loading ? <Spinner /> : <></>}
                </CenterFlexDiv>
            )}
        </Styled.FileDisplaySectionContainer>
    );
};

export default FileDisplaySection;
