/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ApiCallRequest, ApiRequestActionPayload } from 'src/api/reportsApi/types';
import { ReportNames, RideTypes } from 'src/store/slices/reports/types';
import { handleError } from 'src/utilis/errorHandling';
import {
    clientIdSelector,
    departmentsSelector,
    selectError,
    selectRefreshBtnClickCount,
} from 'src/store/selectores/reportsSelectors';
import { useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { CurrFiltersValues } from 'src/types/reports/generalReportsTypes';
import { RELATIVE } from 'src/constants/dates';
import moment from 'moment';
import initialStates from 'src/store/slices/reports/initialStates';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { stringifyDate } from 'src/utilis/utilis';
import { PassengersFilterValues } from '../../../../../../types/reports/generalReportsTypes';

const isValidTime = (time: string | undefined): boolean => {
    try {
        if (!time || +time === 0 || time.length !== 5) return false;
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIsPickupValue = (rideType: any): string | null => {
    if (!rideType) return null;
    switch (rideType) {
        case 'all':
            return null;
        case 'pickup':
            return '1';
        case 'drop':
            return '0';
        default:
            return null;
    }
};

export interface IFetchReportPayload {
    readonly dbUrl: string;
    readonly proxyUrl?: string | undefined;
    readonly requestParams: {
        clientCode: string;
        token: string;
        isAllDepartments?: '0' | '1' | null;
        isPickup?: string | null;
        relativeDate?: string;
        departmentCodes?: string;
        departmentCode?: string | null;
        fromDate?: string;
        toDate?: string;
        fromTime?: string;
        toTime?: string;
        pickupTime?: string | undefined | null;
        dropTime?: string | undefined | null;
        daysInWeek?: string;
    };
}

// const isAllDepartments = (
//     allDepartments: ShortDepartmentData[],
//     loggedUserDepartmentCode: string | null,
//     selectedDepartmentCodes: string[]
// ): '1' | '0' => {
//     if (loggedUserDepartmentCode) return '0';
//     if (allDepartments.map((d) => d.code).length === selectedDepartmentCodes.length) return '1';
//     return '0';
// };

// const ALL_DEPARTMENTS_KEY = '0';

const useRequestPayload = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const clientCode = useAppSelector((state) => clientIdSelector(state));

    const createPayload = useCallback(
        (filtersVals, reportName: ReportNames) => {
            if (!clientCode || !selectedFcAccount?.dbUrl) {
                return null;
            }

            const { dbUrl, proxyUrl } = selectedFcAccount || {};

            if (reportName === ReportNames.passengersReport) {
                const { fromTime: initialFromTime, toTime: initialToTime } =
                    initialStates.reports.ui.main.filters.byReport.passengers;
                const { departmentCodes, days, fromTime, toTime, dates, fromDate } =
                    filtersVals as PassengersFilterValues;
                return {
                    dbUrl,
                    proxyUrl: proxyUrl || '',
                    requestParams: {
                        token,
                        clientCode,
                        fromDate: stringifyDate(moment(fromDate).clone().set('date', 1)),
                        departmentCodes: departmentCodes.join(','),
                        daysInWeek: days.join(','),
                        fromTime: fromTime !== 'Invalid date' ? fromTime : initialFromTime,
                        toTime: toTime !== 'Invalid date' ? toTime : initialToTime,
                        dates: dates.map((d) => stringifyDate(d)).join(','),
                    },
                } as const;
            }

            const { fromDate, toDate, rideType, departmentCode, pickupTime, dropTime } =
                filtersVals as CurrFiltersValues;

            return {
                dbUrl: selectedFcAccount?.dbUrl || '',
                requestParams: {
                    relativeDate: stringifyDate(fromDate, RELATIVE),
                    fromDate: stringifyDate(fromDate),
                    toDate: stringifyDate(toDate),
                    proxyUrl: selectedFcAccount?.proxyUrl || '',
                    token,
                    clientCode,
                    departmentCode: (departmentCode && +departmentCode) || null, // sending '0' as null
                    pickupTime: isValidTime(pickupTime) ? pickupTime : null,
                    dropTime: isValidTime(dropTime) ? dropTime : null,
                    isPickup: getIsPickupValue(rideType),
                },
            };
        },
        [clientCode, selectedFcAccount, token]
    );
    return { createPayload };
};

export default useRequestPayload;
