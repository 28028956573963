/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { useRootAppSelector } from 'src/store/hooks';
import { selectedReportNameSelector, widgetsDataSelector } from 'src/store/selectores/reportsSelectors';
import { BudgetData } from 'src/types/reports/slice.types';
import { stringifyDate } from 'src/utilis/utilis';
import { v4 } from 'uuid';
import widgetsHooks from '../../hooks/common.hooks';
import { Styled } from './styles';

const { Common, MonthlyBudgetBox: StyledMonthlyBudgetBox, DailyCost } = Styled;

const DailyBox = (props: { dailyCost: number }) => {
    const { t } = useTranslation();
    const isSuccess = useRootAppSelector((state) => widgetsDataSelector(state).budget.request.isSuccess);
    const currencyId = useRootAppSelector((state) => widgetsDataSelector(state).budget.data.currencyId);

    const tooltipId = v4();
    return (
        <DailyCost.TotalDailyCostBox className="TotalDailyCostBox">
            <Common.HeaderContainer className="HeaderContainer">
                <Common.HeaderText>{t('totalDailyCost')}</Common.HeaderText>
            </Common.HeaderContainer>
            <DailyCost.DailyBoxBody>
                {isSuccess ? (
                    <>
                        <p data-for={tooltipId} data-tip style={{ fontSize: 40, fontWeight: 500 }}>
                            {props.dailyCost.toFixed(0)}
                        </p>
                        <p style={{ fontSize: 20 }}>{currencyId}</p>
                    </>
                ) : (
                    <p>{t('errorFetchingData')}</p>
                )}
            </DailyCost.DailyBoxBody>
            <ReactTooltip id={tooltipId} place="right" type="light" borderColor="gray" border>
                <p>סה''כ עלות נסיעות יומית לפני מע''מ</p>
            </ReactTooltip>
        </DailyCost.TotalDailyCostBox>
    );
};

const MonthlyBudgetBox = ({ budgetData, date }: { budgetData: BudgetData; date: Date }) => {
    const { t } = useTranslation();
    const percentageFromTotalMonthlyBudget = (budgetData.totalVisasCost / budgetData.monthlyBudget) * 100;

    const isSuccess = useRootAppSelector((state) => widgetsDataSelector(state).budget.request.isSuccess);
    const currencyId = useRootAppSelector((state) => widgetsDataSelector(state).budget.data.currencyId);

    const tooltipId = v4();

    return (
        <StyledMonthlyBudgetBox.MonthlyBudgetBox className="MonthlyBudgetBox" style={{ flexGrow: 2 }}>
            <Common.HeaderContainer className="HeaderContainer">
                <Common.HeaderText>{t('monthlyBudget')}</Common.HeaderText>
            </Common.HeaderContainer>
            <StyledMonthlyBudgetBox.BodyContainer>
                {isSuccess ? (
                    <>
                        <div data-for={tooltipId} data-tip style={{}}>
                            <span>({percentageFromTotalMonthlyBudget.toFixed(0)}%)&nbsp;</span>
                            <span>
                                {' '}
                                {budgetData.totalVisasCost.toFixed(1)} <span>{currencyId}</span>
                            </span>
                        </div>
                        <div style={{ display: 'flex', gap: '15px' }}>
                            <p style={{ display: 'flex' }}>
                                {budgetData.monthlyBudget.toFixed(1)}
                                <span>&nbsp;{currencyId}</span>
                            </p>
                            <StyledMonthlyBudgetBox.ProgressBar className="ProgressBar">
                                <StyledMonthlyBudgetBox.ProgressResult
                                    perc={percentageFromTotalMonthlyBudget}
                                />
                            </StyledMonthlyBudgetBox.ProgressBar>
                        </div>
                    </>
                ) : (
                    <p>{t('errorFetchingData')}</p>
                )}
                <MyReactTooltip id={tooltipId}>
                    <p>סה"כ עלות הנסיעות המצטברת לחודש {stringifyDate(date, 'MM.YY')}</p>
                </MyReactTooltip>
            </StyledMonthlyBudgetBox.BodyContainer>
        </StyledMonthlyBudgetBox.MonthlyBudgetBox>
    );
};

interface Props {
    date: Date;
}

const BudgetBI = ({ date }: Props) => {
    const requestData = useRootAppSelector((state) => widgetsDataSelector(state).budget);

    return (
        <Common.Container className="Container">
            <DailyBox dailyCost={requestData.data.dailyCost} />
            <MonthlyBudgetBox date={date} budgetData={requestData.data} />
        </Common.Container>
    );
};

export default BudgetBI;
