/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react';

type ResettableType = string | number | boolean;
function useStateReset(
    stateSetter: ResettableType,
    initialState: ResettableType,
    millisecondsCount: number
): any {
    const [value, setValue] = useState(stateSetter);
    useEffect(() => setValue(stateSetter), [stateSetter]);

    useEffect(() => {
        setTimeout(() => setValue(initialState), millisecondsCount);
    }, [initialState, millisecondsCount, value]);

    return value;
}

export default useStateReset;
