import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { getClients, getDepartments } from 'src/api/api';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import { IProps as DropDwonProp } from 'src/components/DropDown/DropDown';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { useTranslation } from 'react-i18next';
import { InputProp } from 'src/components/commons/Input/Input';
import { IItem, IPassenger } from 'src/types/line';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { buildFilter } from 'src/utilis/utilis';
import { useAppDispatch } from 'src/store/hooks';
import { setClientsOfProviderAction } from 'src/store/slices/lines/linesSlice';
import { IDropdownV2Props } from 'src/components/DropDown/DropDownV2';
import useColumns from './useColumns';
import { useValidateClientList } from 'src/hooks/useClientFiltering';

interface DropDownState {
    options: IItem[];
    value: null | number | string;
}

interface Props {
    filters: CompositeFilterDescriptor | undefined;
    setFilters: Dispatch<SetStateAction<CompositeFilterDescriptor | undefined>>;
}

const useFilters: ({ filters, setFilters }: Props) => {
    GlobalFilterProp: InputProp;
    ClinetFilterProp: IDropdownV2Props;
    DepartmentFilterProp: DropDwonProp;
    PassengerFilterProp: InputProp;
    StatusLineFilterProp: DropDwonProp;
    restFilters: () => void;
} = ({ filters, setFilters }) => {
    const { t } = useTranslation();
    const { columns } = useColumns();

    const selectedFcAccount = useSelector((state: any) => selectedFcAccountSelector(state));

    const token = useSelector((state: any) => tokenSelector(state));

    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [clinetFilter, setClinetFilter] = useState<DropDownState>({
        options: [],
        value: '',
    });
    const [departmentFilter, setDepartmentFilter] = useState<DropDownState>({
        options: [],
        value: '',
    });
    const [passengerFilter, setPassengerFilter] = useState<string>('');
    const [statusLineFilter, setStatusLineFilter] = useState<DropDownState>({
        options: [
            { value: 0, name: t('withoutStatus') },
            { value: 3, name: t('ride') },
            { value: 4, name: t('ended') },
        ],
        value: '',
    });
    const restValueDropDown = (preState: DropDownState): DropDownState => ({
        ...preState,
        value: '',
    });

    const restFilters = () => {
        setGlobalFilter('');
        setClinetFilter(restValueDropDown);
        setDepartmentFilter(restValueDropDown);
        setPassengerFilter('');
        setStatusLineFilter(restValueDropDown);
        setFilters(undefined);
    };

    const buildFilters = (): void => {
        const globalFilterArray: Array<FilterDescriptor> = globalFilter
            ? columns.map((gridProps: GridColumnProps) => buildFilter(gridProps, globalFilter))
            : [];

        const statusFilterArray: Array<FilterDescriptor> = statusLineFilter.value
            ? [
                  {
                      field: 'lineStatus',
                      operator: 'eq',
                      value: statusLineFilter.value,
                      ignoreCase: true,
                  },
              ]
            : [];

        const passengerFilterArray: Array<FilterDescriptor> = passengerFilter
            ? [
                  {
                      field: 'passengers',
                      operator: (items: IPassenger[], currValue: string) => {
                          return items.some(
                              (p: IPassenger) =>
                                  p.fullName?.indexOf(currValue) > -1 ||
                                  p.code?.indexOf(currValue) > -1 ||
                                  p.phone1?.indexOf(currValue) > -1
                          );
                      },
                      value: passengerFilter,
                      ignoreCase: true,
                  },
              ]
            : [];

        const departmentFilterArray: Array<FilterDescriptor> = departmentFilter.value
            ? [
                  {
                      field: 'departmentCode',
                      operator: 'eq',
                      value: departmentFilter.value.toString(),
                      ignoreCase: true,
                  },
              ]
            : [];

        const clinetFilterArray: Array<FilterDescriptor> = clinetFilter.value
            ? [
                  {
                      field: 'accountCode',
                      operator: 'eq',
                      value: clinetFilter.value,
                      ignoreCase: true,
                  },
              ]
            : [];

        const spesificFiltersArr = [
            ...clinetFilterArray,
            ...departmentFilterArray,
            ...passengerFilterArray,
            ...statusFilterArray,
        ];

        if (globalFilterArray.length && spesificFiltersArr.length) {
            setFilters({
                logic: 'and',
                filters: [
                    { logic: 'and', filters: spesificFiltersArr },
                    { logic: 'or', filters: globalFilterArray },
                ],
            });

            return;
        }

        if (!globalFilterArray.length && spesificFiltersArr.length) {
            setFilters({
                logic: 'and',
                filters: spesificFiltersArr,
            });

            return;
        }

        if (globalFilterArray.length && !spesificFiltersArr.length) {
            setFilters({
                logic: 'or',
                filters: globalFilterArray,
            });

            return;
        }

        setFilters(undefined);
    };

    useEffect(buildFilters, [
        globalFilter,
        passengerFilter,
        clinetFilter,
        statusLineFilter,
        departmentFilter,
        columns,
        setFilters,
    ]);

    const tkDispatch = useAppDispatch();

    const validateClientsList = useValidateClientList();

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};

        // setClinetFilter(restValueDropDown);
        setDepartmentFilter(restValueDropDown);

        if (selectedFcAccount) {
            getClients({
                proxyUrl,
                token,
                dbUrl: selectedFcAccount.dbUrl,
            }).then((res) => {
                const { clients = [] } = res.data;

                const clientsList = validateClientsList(clients);

                setClinetFilter((preState) => ({
                    ...preState,
                    options: clientsList.map((c) => ({
                        value: c.accountCode,
                        name: c.clientName,
                    })),
                }));
                tkDispatch(setClientsOfProviderAction({ clients: clientsList }));
            });
        }
    }, [selectedFcAccount, tkDispatch, token]);

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};

        if (selectedFcAccount) {
            getDepartments({
                proxyUrl,
                dbUrl: selectedFcAccount.dbUrl,
                token,
                clientCode: clinetFilter.value,
            }).then((res) => {
                const { departments = [] } = res.data;
                setDepartmentFilter((preState) => ({
                    ...preState,
                    options: departments.map((d) => ({
                        value: d.code,
                        name: d.departmentName,
                    })),
                }));
            });
        }
    }, [selectedFcAccount, clinetFilter.value, token]);

    const GlobalFilterProp: InputProp = {
        value: globalFilter,
        label: t('search'),
        size: 'small',
        style: { width: '20%' },
        labelWidth: 50,
        onChange: (e: React.FormEvent<HTMLInputElement> | any) => {
            const { value } = e.currentTarget;
            setGlobalFilter(value);

            buildFilters();
        },
    };

    const dropDownplaceholder: IItem[] = [{ name: t('all'), value: '' }];

    useEffect(() => {
        if (!clinetFilter.value) setDepartmentFilter(restValueDropDown);
    }, [clinetFilter]);

    const ClinetFilterProp: IDropdownV2Props = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '208px' },
            size: 'small',
        },
        labelName: t('clientName'),
        inputLabelProps: {
            disableAnimation: true,
        },
        selectProps: {
            autoWidth: false,
            multiple: false,
            menuItems: [...clinetFilter.options, ...dropDownplaceholder],
            native: false,
            value: clinetFilter.value,
            onChange: (
                event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: number | string | unknown;
                }>
            ) => {
                const { value } = event.target;

                if (typeof value === 'number' || typeof value === 'string') {
                    setClinetFilter((preState) => ({ ...preState, value }));
                }

                buildFilters();
            },
        },
    };

    const DepartmentFilterProp: DropDwonProp = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '20%' },
            size: 'small',
        },
        disabled: (!clinetFilter.value && true) || false,
        autoWidth: false,
        multiple: false,
        labalName: 'מחלקה לנסיעה',
        label: t('department'),
        menueItem: [...departmentFilter.options, ...dropDownplaceholder],
        native: false,
        value: departmentFilter.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | unknown;
            }>
        ) => {
            const { value } = event.target;

            if (typeof value === 'number' || typeof value === 'string') {
                setDepartmentFilter((preState) => ({
                    ...preState,
                    value,
                }));
            }

            buildFilters();
        },
    };

    const PassengerFilterProp: InputProp = {
        value: passengerFilter,
        label: t('passenger'),
        size: 'small',
        labelWidth: 40,
        style: { width: '15%' },
        onChange: (e) => {
            const { value } = e.currentTarget;
            setPassengerFilter(value);

            buildFilters();
        },
    };

    const StatusLineFilterProp: DropDwonProp = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '20%' },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        labalName: t('status'),
        label: t('status'),
        menueItem: [...statusLineFilter.options, ...dropDownplaceholder],
        native: false,
        value: statusLineFilter.value,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: number | string | unknown;
            }>
        ) => {
            const { value } = event.target;

            if (typeof value === 'number' || typeof value === 'string') {
                setStatusLineFilter((preState) => ({
                    ...preState,
                    value,
                }));
            }

            buildFilters();
        },
    };

    return {
        GlobalFilterProp,
        ClinetFilterProp,
        DepartmentFilterProp,
        PassengerFilterProp,
        StatusLineFilterProp,
        restFilters,
    };
};

export default useFilters;
