import i18n from 'src/i18n';

export const screenHeight = `${window.screen.height * 0.9}px`;

export const BIG_INT = 9223372036854775807;

export const DEFAULT_ERROR_MESSAGE = i18n.t('oopsSomethingWentWrongTryRefreshing');

export enum FillerValues {
    All = '0',
    Without = '-1',
}
