import React, { useCallback, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeSelection, onUnSelectPassenger } from 'src/store/actions/passengerAction';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';

import { getSelectedState, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import {
    State,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
    process,
} from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import KendoGrid, { TableProp } from 'src/components/KendoGrid/KendoGrid';
import KendoDeleteEditeCell from 'src/components/KendoGridCutomeColumn/KendoDeleteEditeCell';

import { IPassenger } from 'src/api/passengerMangerApi/types';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useParentDimensions from 'src/hooks/useParentDimensions';
import { getTruthyValueCountOnArrayObjects } from 'src/utilis/utilis';
import { getMaxHeight } from 'src/screens/Reports/components/ReportsSection/Table/DataGrids/KendoDataGrid/KendoDataGrid';
import useColumns from '../hooks/useColumns';
import { rowRender } from './tableProps';
import { updatePassengers } from './utils';
import { StyledKendoGridContainer } from '../Passengers.style';

const dataItemKey = 'passCode';
const selectedField = 'isSelected';
const idGetter = getter(dataItemKey);
const PAGE_SIZE = 25;
export const getMaxHeightPassengersMngr = () => {
    const { height } = window.screen;
    if (height > 800) return height * 0.7;
    if (height > 700) return height * 0.65;
    if (height > 600) return height * 0.55;
};
interface IPassengersGrid {
    data: IPassenger[];
    compositeFilters: CompositeFilterDescriptor | undefined;
    onDelete: (dataItem: IPassenger) => unknown;
    onEdit: (dataItem: IPassenger) => unknown;
    onRestore: (dataItem: IPassenger) => unknown;
}

const PassengersGrid: FunctionComponent<IPassengersGrid> = ({
    data,
    compositeFilters,
    onDelete,
    onEdit,
    onRestore,
}: IPassengersGrid) => {
    const dispatch = useDispatch();

    // Local states
    const [take, setTake] = useState<number>(PAGE_SIZE);
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const [modifyingPassenger, setModifingPassenger] = useState<boolean>(false);
    const [isViewingOptions, setIsViewingOptions] = useState<boolean>(false);

    const handleSetIsViewingOptions = (newState: boolean) => setIsViewingOptions(newState);

    // Selectors
    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));

    // const selectedPassengers = useSelector(
    //    (state: IRootReducer) => passengersUISelector(state).selectedPassengers,
    // );
    // Event handlers
    // change passenger event

    const onSelectionChange = React.useCallback(
        (event) => {
            setIsViewingOptions(event.syntheticEvent?.target.checked || false);
            const newSelectedState = getSelectedState({
                event,
                selectedState,
                dataItemKey,
            });
            setSelectedState(newSelectedState);
            setModifingPassenger(false);
        },
        [selectedState, setIsViewingOptions]
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        // console.log('onHeaderSelectionChange CALLED');
        const checkboxElement = event.syntheticEvent.target;
        const { checked } = checkboxElement;
        const newSelectedState: any = {};
        event.dataItems.forEach((item: any) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setTake(event.dataState?.take || PAGE_SIZE);
            setSkip(event.dataState?.skip || 0);
            setSort(event.dataState?.sort || []);
        },
        [setTake, setSkip, setSort]
    );

    // Edit passenger button clicked handler
    const onEditeCell = useCallback(
        (dataItem: IPassenger) => {
            setSelectedState({ [dataItem[dataItemKey]]: true });
            setModifingPassenger(true);
            onEdit(dataItem);
        },
        [onEdit]
    );

    const DeleteUpdateCell = useCallback(
        (props) => (
            <KendoDeleteEditeCell
                {...props}
                onEdit={onEditeCell}
                onDelete={onDelete}
                onRestore={onRestore}
                handleSetIsViewingOptions={handleSetIsViewingOptions}
            />
        ),
        [onDelete, onEditeCell, onRestore]
    );

    // Miscellaneous
    const dataState: State = {
        take,
        skip,
        sort,
        filter: compositeFilters,
    };

    const processedData = process(data, dataState);

    const [hoverRowItemCode, setHoverRowItemCode] = useState<any>(null);

    const { columns } = useColumns({
        overCell: (props: any) => <DeleteUpdateCell {...props} hoverRowItemCode={hoverRowItemCode} />,
        // headerSelectionValue,
    });

    // Effects
    // dispatch the change selection action
    useEffect(() => {
        const selectedPassengerPasscode = Object.keys(selectedState)[0];
        const selectedStateAsArray = Object.entries(selectedState);
        const selectedPassengers: number[] = [];
        const activeSelectedPassengers: number[] = [];
        selectedStateAsArray.forEach(([key, value]) => {
            if (typeof value === 'boolean' && value === true) {
                selectedPassengers.push(+key);
            }
        });

        const mapped: any[] = [];
        data.forEach((item) => {
            mapped.push({
                ...item,
                [selectedField]: selectedState[idGetter(item)],
            });
            if (+item.isActive && selectedPassengers.includes(item.passCode)) {
                activeSelectedPassengers.push(item.passCode);
            }
        });

        dispatch(
            onChangeSelection({
                selectedPassengers,
                activeSelectedPassengers,
                passengers: mapped,
                selectedPassengerPasscode: Number(selectedPassengerPasscode),
                modifyingPassenger,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState, modifyingPassenger]);

    useEffect(() => setSelectedState({}), [selectedFcAccount]);

    // & Handle the unselecting of passengers if double clicked
    const handleRowDoubleClick = (e: any) => {
        setSelectedState({});
        const updatedPassengers = updatePassengers(data, e.dataItem.passCode);
        dispatch(onUnSelectPassenger(updatedPassengers));
    };

    // Building the passengers table
    const tableProp: TableProp = {
        columns,
        className: 'k-rtl',
        style: {
            height: '100%',
            width: '100%',
            borderRadius: '0.3em',
            maxHeight: '76vh',
            // pointerEvents: isFormOpen ? 'none' : 'auto',
        },
        wrapperDivStyle: {
            height: '100%',
            width: '100%',
            paddingBottom: '30px',
        },
        selectedField,
        selectable: {
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
        },
        onRowDoubleClick: handleRowDoubleClick,
        onSelectionChange,
        onHeaderSelectionChange,
        dataItemKey,
        rowHeight: 46,
        navigatable: false,
        data: processedData,
        total: processedData.total,
        pageSize: PAGE_SIZE,
        skip,
        sort,
        sortable: true,
        rowRender: (trElement, props) => rowRender(trElement, props, setHoverRowItemCode),
        resizable: true,
        reorderable: true,
        scrollable: 'virtual',
        onDataStateChange,
    };

    return (
        <StyledKendoGridContainer className="passengersGrid">
            <KendoGrid {...tableProp} />
        </StyledKendoGridContainer>
    );
};

export default PassengersGrid;

// const selectedPassengersIds = useSelector(
//    (state: IRootReducer) => passengersUISelector(state).selectedPassengers,
// );
// const headerSelectionValue = useMemo(() => {
//    let selectedCount = 0;
//    fori
//    Object.values(selectedState).forEach((passId) => {
//       console.log(selectedState);
//       if (selectedState[String(passId)] === true) {
//          // eslint-disable-next-line no-plusplus
//          selectedCount++;
//       }
//    });
//    return selectedCount > 0;
// }, [selectedState]);
