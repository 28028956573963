import { getWeekdayByDate } from 'src/utilis/utilis';

export const getFormattedCurrentDate = (inDaysCount = 0) => {
    const date = new Date();
    let currDay = `${date.getDate() + inDaysCount}`;
    let currMonth = `${date.getMonth() + 1}`;
    if (currDay.length === 1) currDay = `0${currDay}`;
    if (currMonth.length === 1) currMonth = `0${currMonth}`;
    return `${currDay}.${currMonth}`;
};
export const getShortDateWithMsg = (fullDate: string): string => {
    const currentDate = getFormattedCurrentDate();
    const splitted = fullDate.split('-').reverse();
    const lineDate = `${splitted[0]}.${splitted[1]}`;
    if (lineDate === currentDate) return `היום, ${lineDate}`;
    if (lineDate === getFormattedCurrentDate(1)) return `מחר, ${lineDate}`;
    return `${getWeekdayByDate(fullDate)}, ${lineDate}`;
};

export const getLinesOrderedByDate = (lines: any[]) => {
    const linesByDate: any[] = [];
    let currDateLines: any[] = [];
    let currDate = lines[0].line_date;
    lines.forEach((line: any) => {
        // eslint-disable-next-line eqeqeq
        if (line.line_date == currDate) {
            currDateLines.push(line);
        } else {
            linesByDate.push(currDateLines);
            currDateLines = [line];
            currDate = line.line_date;
        }
    });
    linesByDate.push(currDateLines);
    return linesByDate;
};
