/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, FC } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
// import { TextField, TextFieldProps } from '@material-ui/core';

import { styled } from '@mui/system';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TextField, TextFieldProps } from '@mui/material';
import moment from 'moment';

export interface TimePickerProps {
    time: string;
    setTime: (newVal: string) => void;
    textFieldProps: TextFieldProps;
}

export const StyledTimePickerTextField = styled(TextField)({
    width: '100%',
    backgroundColor: 'white',
    '.MuiOutlinedInput-notchedOutline': {
        textAlign: 'right ',
    },
    '.MuiOutlinedInput-input': {
        marginLeft: '10px',
        borderLeft: '1px solid #EBE6E6',
        paddingLeft: '0px',
    },
    '.MuiInputLabel-root': {
        transformOrigin: 'top right',
        lineHeight: '',
        left: 'auto',
    },
    '.MuiOutlinedInput-root.Mui-error': {
        fieldset: {
            borderColor: '#f44336',
        },
    },
    '.MuiInputLabel-root.Mui-error': {
        color: '#f44336',
    },
    '.MuiIconButton-root': {
        padding: 0,
    },
    '.MuiSvgIcon-root': {
        color: '#40A8E2',
    },
});

const TIME_FORMAT_24 = 'HH:mm';

const TimePicker: FC<TimePickerProps> = ({ time, setTime, textFieldProps }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiTimePicker
                label={textFieldProps.label || t('pickTime')}
                value={moment(time, TIME_FORMAT_24).toDate()}
                onChange={(newDate: Date | null) => {
                    setTime(moment(newDate).format(TIME_FORMAT_24));
                }}
                ampm={false}
                inputFormat={TIME_FORMAT_24}
                renderInput={(params) => (
                    <StyledTimePickerTextField
                        id="time"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        {...params}
                        {...textFieldProps as any}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimePicker;
