import { AnyAction } from 'redux';
import { RefreshTokenStatus, SingupType, IAccount } from 'src/types/login';
import { PassengersErrorMessagesEnum } from 'src/types/global';
import { IAuthorization } from 'src/api/types';

import { LoginActionType } from './actionType';
import { ReduxDispatch } from '../type';
import {
    getGoogleApiInterfaceStatus,
    initialGoogleApiCounter,
    isGoogleApiInterfaceCounterExceeded,
} from 'src/utilis/utilis';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiCounterExceededPopUpOpenAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from '../slices/common/commonSlice';

/* eslint-disable import/no-cycle */
import store from '..';
import { setClientCodeAction } from '../slices/optimizations/optimizationsSlice';

export const initConnect = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LoginActionType.initialConfiguration,
        payload,
    };
};

export const initConnectSuccess = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LoginActionType.initialConfigurationSuccess,
        payload,
    };
};

export const initConnectFail = <T = {}>(payload: T): ReduxDispatch<T> => {
    return {
        type: LoginActionType.initialConfigurationFail,
        payload,
    };
};

export const setAuthorizationToken = (payload: IAuthorization): ReduxDispatch<IAuthorization> => {
    return {
        type: LoginActionType.setAuthorizationToken,
        payload,
    };
};

export const setFcAccount = (payload: IAccount): ReduxDispatch<IAccount> => {
    if (payload && payload.accountCode) {
        store.store.dispatch(
            setClientCodeAction({
                clientCode: '',
            })
        );

        initialGoogleApiCounter(payload.accountCode);

        getGoogleApiInterfaceStatus(payload.accountCode).then((data) => {
            if (data) {
                const isExceeded = isGoogleApiInterfaceCounterExceeded(data);
                store.store.dispatch(
                    setGoogleApiInterfaceIntervalAction({ interval: data.ClientsRequestsInterval })
                );

                if (isExceeded !== null) {
                    store.store.dispatch(setIsGoogleApiInterfaceCounterExceededAction({ isExceeded }));
                    store.store.dispatch(
                        setIsGoogleApiCounterExceededPopUpOpenAction({ isOpen: isExceeded })
                    );
                }
            }
        });
    }

    return {
        type: LoginActionType.SetFcAccount,
        payload,
    };
};

export const setDate = <Date>(payload: Date): ReduxDispatch<Date> => {
    return {
        type: LoginActionType.SetDate,
        payload,
    };
};

export const setLogout = (): AnyAction => {
    return {
        type: LoginActionType.Logout,
    };
};

export const setLoginType = (payload: SingupType): AnyAction => {
    return {
        type: LoginActionType.SetLoginType,
        payload,
    };
};

export const setTokenRefreshStatus = (payload: RefreshTokenStatus): AnyAction => {
    return {
        type: LoginActionType.SetTokenRefreshStatus,
        payload,
    };
};

export const setErrorMessage = (payload: string | undefined): AnyAction => {
    return {
        type: LoginActionType.SetErrorMessage,
        payload,
    };
};

export const setErrorCode = (payload: PassengersErrorMessagesEnum | undefined): AnyAction => {
    return {
        type: LoginActionType.SetErrorCode,
        payload,
    };
};
