import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootAppSelector } from 'src/store/hooks';
import { courseBuildingFormSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';
import { IOptimizationWaypoint } from '../api/types';

const useSimulationFilters = () => {
    const [filterText, setFilterText] = useState('');

    const routes = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getSimulationResult.data?.optimumRouteResult
    );
    const searchTerm = filterText.toLowerCase();

    const filteredRoutes = useMemo(() => {
        if (routes && routes.length > 0) {
            return routes.filter((route) => {
                const matchedByFilterText =
                    route.routeName && route.routeName.toLowerCase().includes(searchTerm);

                return matchedByFilterText;
            });
        }
    }, [routes, searchTerm]);

    return { filteredRoutes, setFilterText };
};

export default useSimulationFilters;
