import React, { CSSProperties, FC } from 'react';

interface Props {
    errorMessage?: string;
    style?: CSSProperties;
}

const InputWithErrorsWrapper: FC<Props> = ({ children, errorMessage = '', style = {} }) => {
    return (
        <div style={{ ...style, textAlign: 'right', width: '100%' }}>
            {children}
            <p>{errorMessage}</p>
        </div>
    );
};

export default InputWithErrorsWrapper;
