import { AddressInfo } from './types';

export const initialAddressInfo: AddressInfo = {
    city: '',
    street: '',
    houseNum: '',
};

export const getInitialAddressInfo = (): AddressInfo => {
    return { city: '', street: '', houseNum: '' };
};
