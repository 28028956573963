/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormControlProps } from '@material-ui/core';
import { DatePickerView } from '@material-ui/pickers';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDatePickerProps } from 'src/components/DatePicker/DatePickerWithPrevNext';
import { IProps as IDropDownProp } from 'src/components/DropDown/DropDown';
import { hooks } from 'src/components/MultipleDropDown/hooks';
import { MultipleDropDownProps } from 'src/components/MultipleDropDown/MultipleDropDown';
import { FilterTypes } from 'src/store/reducers/reportsReducers/uiReducers';
import { filtersSelector, selectedReportNameSelector } from 'src/store/selectores/reportsSelectors';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { onChangeFilterAction, onChangeFilterByReportAction } from 'src/store/slices/reports/reportsSlice';
import { ReportName } from 'src/store/slices/reports/types';
import { DayNum } from 'src/types/global';
import { stringifyDate } from 'src/utilis/utilis';

import C from 'src/constants/byScreen/reports/reports';
import { useAppDispatch, useAppSelector, useRootAppSelector } from '../../../../../../store/hooks';
import {
    useMultipleDaysDropdownConfig,
    useMultipleDepartmentsDropdownConfig,
    useMultipleShiftsDropdownConfig,
    useSelectors,
} from './useDropdownPropsHelperHooks';
import { getDepartmentFilterMenuItems, getMaxDate, getShiftTimeMenuItems } from './utils';

const formControlProp: FormControlProps = {
    variant: 'outlined',
    style: { width: '100%' },
    size: 'small',
};

const inputsStyle = { backgroundColor: 'white' };

// ** Helper Hooks
const useHelpers = () => {
    const dispatch = useAppDispatch();

    const dispatchFilterChange = useCallback(
        (filterType: string, newValue: string | unknown): void => {
            dispatch(
                onChangeFilterAction({
                    filterType,
                    newValue,
                })
            );
        },
        [dispatch]
    );
    return { dispatchFilterChange };
};

const useDepartmentFilterAuth = () => {
    const { dispatchFilterChange } = useHelpers();

    const selectedReport = useAppSelector((state) => selectedReportNameSelector(state));

    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;

    const filtersVal = useAppSelector((state) => filtersSelector(state, selectedReport));

    useEffect(() => {
        if (!Number(filtersVal.departmentCode) && loggedUserDepartmentCode) {
            dispatchFilterChange('departmentCode', loggedUserDepartmentCode);
        }
    }, [dispatchFilterChange, filtersVal.departmentCode, loggedUserDepartmentCode]);

    return { loggedUserDepartmentCode };
};

type DateRangeTypes = FilterTypes.FromDate | FilterTypes.ToDate;

const useDateProps = ({
    report,
    dateRangeVal,
    label,
}: {
    report: ReportName;
    dateRangeVal: DateRangeTypes;
    label?: string;
}) => {
    const dispatch = useAppDispatch();

    const { dispatchFilterChange } = useHelpers();

    const { filtersVal } = useSelectors();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    useEffect(
        () => setSelectedDate(filtersVal[dateRangeVal]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dateRangeVal, filtersVal.toDate, filtersVal.fromDate]
    );

    const handleDatePickerChange = useCallback(
        (date: Date | null) => {
            const newSelectedDate = date || new Date();

            setSelectedDate(newSelectedDate);

            if (report === 'passengers') {
                dispatch(
                    onChangeFilterByReportAction({
                        filterType: dateRangeVal,
                        newValue: moment(newSelectedDate).startOf('month').toDate(),
                        reportName: 'passengers',
                    })
                );
            } else dispatchFilterChange(dateRangeVal, newSelectedDate);
        },
        [dateRangeVal, dispatch, dispatchFilterChange, report]
    );

    const reportsDatePickerProps = {
        views: ['year', 'month', 'date'],
        format: 'MM.yyyy',
    };

    const getPropsByReport = useCallback(() => {
        const isDropDate = dateRangeVal === FilterTypes.ToDate;

        const defaultProps = {
            label: label || 'תאריך',
        };

        const getDropProps = (options: { daysToAddToMaxDate: number }) => {
            return {
                maxDate: getMaxDate(filtersVal.fromDate, options.daysToAddToMaxDate),
                label: label || 'תאריך',
                minValidDate: filtersVal.fromDate ? filtersVal.fromDate : undefined,
                minValidDateErrorMessage: 'תאריך קטן מתאריך התחלה',
            };
        };

        switch (report) {
            case undefined:
                return {};

            case 'passengers':
                return reportsDatePickerProps;

            case 'shortOrders':
                if (isDropDate) {
                    return getDropProps({ daysToAddToMaxDate: 90 });
                }
                return defaultProps;
            case 'detailedOrders':
                if (isDropDate) {
                    return getDropProps({ daysToAddToMaxDate: 90 });
                }
                return defaultProps;
            case 'visas':
                if (isDropDate) {
                    return getDropProps({ daysToAddToMaxDate: 70 });
                }
                return defaultProps;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRangeVal, filtersVal.fromDate, filtersVal.toDate, report]);

    const datePickerProps: IDatePickerProps = useMemo(
        () => ({
            showTodayButton: true,
            inputVariant: 'outlined',
            style: { width: '100%', ...inputsStyle },
            size: 'small',
            format: 'dd.MM.yyyy',
            label,
            value: selectedDate,
            onChange: handleDatePickerChange,
            KeyboardButtonProps: {
                'aria-label': 'change date',
            },
            ...getPropsByReport(),
        }),
        [getPropsByReport, handleDatePickerChange, label, selectedDate]
    );

    return { datePickerProps };
};

// ** Index Hook
const useDropdownProps = ({ report }: { report: ReportName }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { loggedUserDepartmentCode } = useDepartmentFilterAuth();

    const { departments, shiftsTimes, filtersVal } = useSelectors();

    const dispatchFilterChange = useCallback(
        (filterType: string, newValue: string | unknown): void => {
            dispatch(
                onChangeFilterAction({
                    filterType,
                    newValue,
                })
            );
        },
        [dispatch]
    );

    const selectedReportName = useRootAppSelector(selectedReportNameSelector);

    // -- Date props ----------------------------------------------------
    const { datePickerProps: fromDateProps } = useDateProps({
        report,
        dateRangeVal: FilterTypes.FromDate,
        label: C.REPORTS_WITH_DATE_RANGE.includes(selectedReportName) ? t('fromDate') : '',
    });
    const { datePickerProps: toDateProps } = useDateProps({
        report,
        dateRangeVal: FilterTypes.ToDate,
        label: C.REPORTS_WITH_DATE_RANGE.includes(selectedReportName) ? t('toDate') : '',
    });

    // -- other props ----------------------------------------------------
    const departmentProp: IDropDownProp = useMemo(
        () => ({
            labalName: 'מחלקה',
            value: filtersVal.departmentCode,
            menueItem: getDepartmentFilterMenuItems(departments, loggedUserDepartmentCode),
            onChange: (e) => {
                dispatchFilterChange('departmentCode', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
        }),
        [departments, dispatchFilterChange, filtersVal.departmentCode, loggedUserDepartmentCode]
    );

    const rideTypeProp: IDropDownProp = useMemo(
        () => ({
            labalName: t('rideType'),
            value: filtersVal.rideType,
            menueItem: [
                { name: t('all'), value: 'all' },
                { name: t('pickUp'), value: 'pickup' },
                { name: t('drop'), value: 'drop' },
            ],
            onChange: (e) => {
                dispatchFilterChange('rideType', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
        }),
        [dispatchFilterChange, filtersVal.rideType, t]
    );

    const pickUpTimeProp: IDropDownProp = useMemo(
        () => ({
            labalName: t('pickUpTime'),
            value: filtersVal.pickupTime,
            menueItem: getShiftTimeMenuItems(shiftsTimes.pickupTimes, t),
            onChange: (e) => {
                dispatchFilterChange('pickupTime', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
        }),
        [dispatchFilterChange, filtersVal.pickupTime, shiftsTimes.pickupTimes, t]
    );

    const dropOffTimeProp: IDropDownProp = useMemo(
        () => ({
            labalName: t('dropOffTime'),
            value: filtersVal.dropTime,
            menueItem: getShiftTimeMenuItems(shiftsTimes.dropTimes, t),
            onChange: (e) => {
                dispatchFilterChange('dropTime', e.target.value);
            },
            formControlProp,
            style: inputsStyle,
        }),
        [dispatchFilterChange, filtersVal.dropTime, shiftsTimes.dropTimes, t]
    );

    // -- multiple select props
    const daysDropdownConfig = useMultipleDaysDropdownConfig();
    const daysProps: MultipleDropDownProps = hooks.useMultipleDropdownProps<DayNum>(daysDropdownConfig);

    const deptsDropdownConfig = useMultipleDepartmentsDropdownConfig();
    const multDeptsProps: MultipleDropDownProps = hooks.useMultipleDropdownProps<string>(deptsDropdownConfig);

    return {
        departmentProp,
        rideTypeProp,
        pickUpTimeProp,
        dropOffTimeProp,
        datePickerProps: {
            fromDateProps,
            toDateProps,
        },
        daysProps,
        multDeptsProps,
    };
};

export default useDropdownProps;

// const cityProp: IDropDownProp = {
//    labalName: t('freeSearch'),
//    menueItem: [],
//    onChange: (e) => {
//       console.log(e.target.value);
//    },
//    formControlProp,
//    style: inputsStyle,
// };

// const useDaysProps = (): MultipleDropDownProps => {
//    const { t } = useTranslation();
//    const dispatch = useAppDispatch();

//    const days = useAppSelector(
//       (state: any) =>
//          filtersByReportSelector(state, 'passengers').days,
//    );

//    const handleChange = (event: any) => {
//       const {
//          target: { value },
//       } = event;
//       let result: DayNum[];
//       if (value === -1 && days.length < 7) {
//          result = DAYS_NUMBERS;
//       } else if (value === -1 && days.length === 7) {
//          result = [];
//       } else if (!days.includes(value)) {
//          const copy = [...days];
//          copy.push(value);
//          result = copy;
//       } else {
//          const removed = days.filter((day) => day !== value);
//          result = removed;
//       }

//       dispatch(
//          onChangeFilterByReportAction({
//             filterType: FilterTypes.Days,
//             newValue: result,
//             reportName: 'passengers',
//          }),
//       );
//    };

//    return {
//       label: t('days'),
//       value: days,
//       menuItems: [
//          {
//             name: t('all'),
//             value: -1,
//             checked: days.length === 7,
//          },
//          ...DAYS_NUMBERS.map((dayNum) => ({
//             name: DAYS_HE[dayNum],
//             value: dayNum,
//             checked: days.includes(dayNum),
//          })),
//       ],
//       onChange: handleChange,
//       formControlProp,
//       style: inputsStyle,
//       renderValue: (selected: any) => {
//          if (selected.length) {
//             return selected
//                .map(
//                   (dayNum: string | number) => DAYS_HE_SHORT[dayNum],
//                )
//                .join(', ');
//          }
//       },
//    };
// };

// const shiftsDropdownConfig = useMultipleShiftsDropdownConfig();
// const multShiftsProps: MultipleDropDownProps =
//    hooks.useMultipleDropdownProps<string>(shiftsDropdownConfig);

// const shiftsProps: IDropDownProp = {
//    labalName: t('shifts'),
//    value: 'all',
//    menueItem: [],
//    onChange: (e) => {
//       // dispatchFilterChange('dropTime', e.target.value);
//    },
//    formControlProp,
//    style: inputsStyle,
// };
