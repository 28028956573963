import React, { FC, ReactNode } from 'react';

import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog as MetrialDialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export interface IDialog extends DialogProps {
    DialogActionsChildren?: ReactNode;
    DialogTitleChildren?: ReactNode;
    DialogContentChildren?: ReactNode;
}

const Dialog: FC<IDialog> = ({
    DialogActionsChildren,
    DialogTitleChildren,
    DialogContentChildren,
    ...prop
}: IDialog) => {
    return (
        <MetrialDialog {...prop}>
            {DialogTitleChildren && <DialogTitle>{DialogTitleChildren}</DialogTitle>}

            {DialogContentChildren && <DialogContent> {DialogContentChildren} </DialogContent>}

            {DialogActionsChildren && <DialogActions>{DialogActionsChildren}</DialogActions>}
        </MetrialDialog>
    );
};

export default Dialog;
