import React, { useEffect, useState } from 'react';

const useDisableDelay = (delayInMillis: number) => {
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (disabled) {
            setTimeout(() => setDisabled(false), delayInMillis);
        }
    }, [delayInMillis, disabled]);

    return [disabled, setDisabled] as const;
};
export default useDisableDelay;
