import React from 'react';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import useCommons from 'src/hooks/common/useCommons';
import styles from './CoursesTable.styles';
import useFetchSelectedCourseTimes from '../hooks/useFetchSelectedCourseTimes';
import useStationsFetch from '../hooks/useFetchCourseStations';
import NoRecordsText from 'src/components/NoRecordsText';
import DeleteConfirmModal from 'src/components/Modals/DeleteConfirmModal';
import useDeleteModal from '../hooks/useDeleteModal';
import CourseTableRow, { FLEX } from './CourseTableRow';
import useRestoreModal from '../hooks/useRestoreModal';
import ActionConfirmModal from 'src/components/Modals/ActionConfirmModal';

const CoursesTable: React.FC<{ coursesLst: WsCourseDataItem[] }> = ({ coursesLst }) => {
    const { t } = useCommons();

    useFetchSelectedCourseTimes();
    useStationsFetch();

    const deleteModal = useDeleteModal();
    const restoreModal = useRestoreModal();

    return (
        <>
            <DeleteConfirmModal {...deleteModal.props} />
            <ActionConfirmModal {...restoreModal.props} />
            <styles.TableContainer className="TableContainer">
                {/* Header */}
                <styles.HeaderRow className="HeaderRow">
                    <styles.HeaderCell>{t('lineNumber')}</styles.HeaderCell>
                    <styles.HeaderCell flex={FLEX.COURSE_DESCRIPTION}>
                        {t('courseDescription')}
                    </styles.HeaderCell>
                    <styles.HeaderCell flex={FLEX.DEPARTMENT}>{t('department')}</styles.HeaderCell>
                    <styles.HeaderCell>{t('tripDirection')}</styles.HeaderCell>
                </styles.HeaderRow>
                {/* Body */}
                <styles.BodyRowsContainer className="BodyRowsContainer">
                    {coursesLst.length ? (
                        coursesLst.map((course) => <CourseTableRow course={course} />)
                    ) : (
                        <NoRecordsText />
                    )}
                </styles.BodyRowsContainer>
            </styles.TableContainer>
        </>
    );
};

export default CoursesTable;
