import { MapWindow } from 'src/components/Map/MapType2/MapType2';
import useCommons from 'src/hooks/common/useCommons';
import { FromBtn } from 'src/screens/RideSettings/components/widgets/CancelAndApproveBtns';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setCenterMapOnEditAction,
    setIsFormDirtyAction,
    setIsMapEditModeAction,
    setUpdatedCoordsOnMapAction,
    triggerInputByCoordsAction,
    updateFormWaypointAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import React from 'react';
import { stat } from 'fs';
import { getMapsAPIKey, getMapsAPIKeyNoRestrict, updateGoogleApiCounter } from 'src/utilis/utilis';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from 'src/store/slices/common/commonSlice';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';
import { useCsvReverseGeo } from '../hooks/useCsvReverseGeo';
import { useFetchAddressFromCoords } from '../hooks/useFetchAddressFromCoords';
/* eslint-disable no-debugger */

const editWindowStyle = {
    bottom: '10px',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '400px',
    justifyContent: 'center',
} as const;

export const OptimizationConfirmationWindow: React.FC<{}> = () => {
    const { t, dispatch } = useCommons();

    const { updatedCoords, waypoints, waypointOnMapEditMode } = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form
    );

    const fetchAddressFromCoords = useFetchAddressFromCoords();

    return (
        <MapWindow style={editWindowStyle}>
            <div style={{ display: 'flex', gap: '16px', width: '250px' }}>
                <FromBtn
                    text={t('confirmLocation')}
                    btnProps={{
                        onClick: async () => {
                            if (waypoints && waypoints.length > 0 && updatedCoords && waypointOnMapEditMode) {
                                const formWaypointsListCopy = [...waypoints];

                                const index = waypoints.findIndex(
                                    (waypoint) => waypoint.localId === waypointOnMapEditMode.localId
                                );

                                const newAddress = await fetchAddressFromCoords.fetchAddressFromCoords(
                                    updatedCoords.lat,
                                    updatedCoords.lng
                                );

                                if (index !== -1 && newAddress) {
                                    const updatedWaypoint = {
                                        localId: formWaypointsListCopy[index].localId,
                                        city: newAddress.city,
                                        street: newAddress.street,
                                        houseNum: newAddress.houseNum,
                                        placeName: '',
                                        remarks: formWaypointsListCopy[index].remarks,
                                        lat: updatedCoords.lat,
                                        lng: updatedCoords.lng,
                                        waypointId: formWaypointsListCopy[index].waypointId
                                            ? formWaypointsListCopy[index].waypointId
                                            : undefined,
                                        passId: formWaypointsListCopy[index].passId,
                                    };

                                    formWaypointsListCopy[index] = updatedWaypoint;

                                    dispatch(setIsFormDirtyAction({ isDirty: true }));
                                    dispatch(updateFormWaypointAction({ waypoints: formWaypointsListCopy }));
                                    dispatch(
                                        setIsMapEditModeAction({
                                            isMapEditMode: false,
                                            waypointOnEditMode: null,
                                        })
                                    );
                                    dispatch(setUpdatedCoordsOnMapAction(null));
                                    dispatch(setCenterMapOnEditAction(updatedCoords));
                                    dispatch(triggerInputByCoordsAction());
                                }
                            }
                        },
                        fullWidth: true,
                    }}
                    type="confirm"
                />
                <FromBtn
                    text={t('cancel')}
                    btnProps={{
                        onClick: () => {
                            dispatch(
                                setIsMapEditModeAction({
                                    isMapEditMode: false,
                                    waypointOnEditMode: null,
                                })
                            );
                            dispatch(setUpdatedCoordsOnMapAction(null));
                            dispatch(setCenterMapOnEditAction(null));
                        },
                        fullWidth: true,
                    }}
                    type="cancel"
                />
            </div>
        </MapWindow>
    );
};
