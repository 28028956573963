import { TextFieldProps } from '@mui/material';

export const defaultTextFieldProps: TextFieldProps = {
    type: 'text',
    size: 'small',
    inputMode: 'text',
    variant: 'outlined',
    fullWidth: true,
};

export const defaultStyle = {
    display: 'flex',
    gap: '15px',
    maxWidth: 'fit-content',
};
