import { request } from 'https';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import React from 'react';

const useBaseApiParams = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));

    const dbUrl = selectedFcAccount?.dbUrl || '';

    const getRouteClientData = React.useCallback(
        <T>(requestParams: T) => {
            return {
                requestConfig: {
                    dbUrl,
                    requestParams: {
                        token,
                        ...requestParams,
                    },
                },
            };
        },
        [dbUrl, token]
    );

    return { dbUrl, token, proxyUrl: selectedFcAccount?.proxyUrl || null, getRouteClientData };
};

export default useBaseApiParams;
