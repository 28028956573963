import { MenuItemProps } from 'src/components/DropDown/DropDown';
import i18n from 'src/i18n';
import { enums } from 'src/types/coursesBuilding/commonTypes';

const { CourseTypes } = enums;

export const courseTypesMenuItems: MenuItemProps[] = [
    {
        name: i18n.t('active'),
        value: CourseTypes.Active,
    },
    {
        name: i18n.t('inactive'),
        value: CourseTypes.InActive,
    },
    {
        name: i18n.t('all'),
        value: CourseTypes.All,
    },
];
