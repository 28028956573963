import querystring from 'querystring';
import { AxiosResponse } from 'axios';

import instance, { buildEncriptedUrl } from '../index';
import { IResponse } from '../types';
import {
    IDeletePassengerApiRequest,
    IFutureLine,
    IGetFutureLinesRequest,
    IPassengerRequest,
    IPassengerResponse,
    IResponseAddOrUpdatePassenger,
    ISetPassengerRequest,
} from './types';
import { createPayload } from '../utilis';

// FC REQUEST

export const getPassengers = ({
    proxyUrl,
    dbUrl,
    ...payload
}: IPassengerRequest): Promise<AxiosResponse<IPassengerResponse>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Passengers_Details'), data);
};

// FC REQUEST

export const GetFutureLines = ({
    proxyUrl,
    dbUrl,
    ...payload
}: IGetFutureLinesRequest): Promise<AxiosResponse<IResponse<IFutureLine[]>>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Future_Lines'), data);
};

// FC REQUEST

export const deletePassenger = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: IDeletePassengerApiRequest): Promise<AxiosResponse<IResponse<void>>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Del_Passengers'), data);
};

// FC REQUEST

export const addOrUpdatePassenger = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: ISetPassengerRequest): Promise<AxiosResponse<IResponseAddOrUpdatePassenger>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });
    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Set_Passenger'), data);
};
