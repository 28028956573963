import React, { useState } from 'react';

import useCommons from 'src/hooks/common/useCommons';
import {
    apiDataSelector,
    courseBuildingFormSelector,
    coursesBuildingSelectors,
    formTempDisabledSelector,
    resetCourseFormAction,
    setCurrTabAction,
    setSelectedCourseIdAction,
    setSettingsListAction,
    setTempDisabledAction,
    timesSettingsTabSelector,
    triggerApiCallAction,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { CourseDetailsFormSchema } from 'src/types/coursesBuilding/form/formTypes';
import detailsSchema from './reactHookFormConfigs/detailsSchema';
import { courseFormDefaultValues } from './utils/common';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import useBaseApiParams from '../../../../hooks/api/useBaseApiParams';
import dateHelpers from 'src/utilis/dateHelpers';
import { FillerValues } from 'src/constants/misc';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { AssertsShape } from 'yup/lib/object';
import { useUpdateApiCall } from '../../hooks/useFiltersFetching';
import { CourseBuildingTabs } from 'src/types/coursesBuilding/storeTypes';
import { StationValidationErrors } from './CourseFormBody/StationsPanel/utils.StationInputBox';
import { asTSR } from 'src/utilis/utilis';
import i18n from 'src/i18n';

const validCourseDirections = ['1', '2', '3'] as const;

// Helpers -------------------------------------------------------------------------
const getNonFillerValue = (value: any) => {
    const fillerValues = Object.values(FillerValues);
    if (fillerValues.includes(value as any)) {
        return '';
    }
    return value;
};

const createErrorMessage = (routeErrorMsg: string) => {
    return `${i18n.t('serverErrorDataWasNotSaved')} ( ${i18n.t(routeErrorMsg)} )`;
};

// Custom Hooks -------------------------------------------------------------------------
const useTempDisable = () => {
    const dispatch = useAppDispatch();

    const isTempDisabled = useRootAppSelector(formTempDisabledSelector);

    React.useEffect(() => {
        if (!isTempDisabled) return;

        // if disabled, activating after X ms
        const timeout = setTimeout(() => {
            dispatch(setTempDisabledAction(false));
        }, 1000);

        return () => clearTimeout(timeout);
    }, [dispatch, isTempDisabled]);

    return isTempDisabled;
};

const useSubmitBtnState = (methods: UseFormReturn<AssertsShape<any>, any>) => {
    const [submitLoading, setSubmitLoading] = React.useState(false);

    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const isTempDisabled = useTempDisable();

    const isMapOnEditMode = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.map.isOnEditMode
    );

    const timesSettingsTabIsDirty = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.timesSettingsTab.isDirty
    );

    const stationErrors = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.courseStationsTab.errors
    );

    const isDisabled = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.isDisabled);

    const stationsOrDetailsAreDirty = methods.formState.isDirty;

    React.useEffect(() => {
        if (
            isMapOnEditMode ||
            (!stationsOrDetailsAreDirty && !timesSettingsTabIsDirty) ||
            isTempDisabled ||
            isDisabled ||
            Object.keys(stationErrors).length !== 0 // checks if the object is emtpy
        ) {
            setSubmitDisabled(true);
            return;
        }

        setSubmitDisabled(false);
    }, [isDisabled, isMapOnEditMode, isTempDisabled, stationsOrDetailsAreDirty, timesSettingsTabIsDirty]);

    return {
        submitLoading,
        setSubmitLoading,
        submitDisabled,
        setSubmitDisabled,
    };
};

const useSelectors = () => {
    const selectedClientCode = useRootAppSelector(
        (state) => coursesBuildingSelectors.uiSelector(state).clientCode
    );

    const { courseCode } = useRootAppSelector(courseBuildingFormSelector);

    const { errors: timesTabErrors, settingsList: timesSettingsLst } =
        useRootAppSelector(timesSettingsTabSelector);

    const currTab = useRootAppSelector(
        (state) => coursesBuildingSelectors.uiSelector(state).courseBuildingForm.currTab
    );

    const stationsErrorsOnSlice = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).courseStationsTab.errors
    );

    const allStations = useRootAppSelector((state) => apiDataSelector(state).getCourseStations.data);

    return {
        selectedClientCode,
        courseCode,
        timesTabErrors,
        currTab,
        stationsErrorsOnSlice,
        timesSettingsLst,
        allStations,
    };
};

const useReactHookForm = () => {
    // -- Selectors
    const {
        selectedClientCode,
        courseCode,
        timesTabErrors,
        currTab,
        stationsErrorsOnSlice,
        timesSettingsLst,
        allStations,
    } = useSelectors();

    // -- Helper hooks
    const { getRouteClientData, token } = useBaseApiParams();

    const { t, dispatch, dispatchI18nErrMsg } = useCommons();

    const dispatchAlert = useDispatchAlert();

    const methods = useForm<CourseDetailsFormSchema>({
        resolver: yupResolver(detailsSchema.schema) as any,
        mode: 'onChange',
        defaultValues: courseFormDefaultValues.details,
    });

    const submitBtnState = useSubmitBtnState(methods);

    // -- Helpers
    const validateTabs = (): 'ok' | CourseBuildingTabs => {
        // handling times settings tab errors
        if (Object.values(timesTabErrors).some((err) => err === true)) {
            if (currTab !== CourseBuildingTabs.TimesAndFrequencySettings) {
                dispatch(setCurrTabAction(CourseBuildingTabs.TimesAndFrequencySettings));
            }

            return CourseBuildingTabs.TimesAndFrequencySettings;
        }

        // handling course details tab errors
        const invalidFieldsByRHF = Object.keys(methods.formState.errors);

        if (invalidFieldsByRHF.length && !invalidFieldsByRHF.includes('stations')) {
            dispatch(setCurrTabAction(CourseBuildingTabs.Details));
            return CourseBuildingTabs.Details;
        }

        // handle stations tab errors
        // const stationsList = methods.getValues('stations');

        const haveInvalidStations = Object.values(stationsErrorsOnSlice).some((errMsg) =>
            errMsg.length && errMsg !== StationValidationErrors.MissingCoords ? true : false
        );

        if (invalidFieldsByRHF.includes('stations') || haveInvalidStations) {
            dispatch(setCurrTabAction(CourseBuildingTabs.CourseStationsBuilding));
            return CourseBuildingTabs.CourseStationsBuilding;
        }

        return 'ok';
    };

    return {
        ...methods,
        submitBtnState,
        onSubmit: async () => {
            submitBtnState.setSubmitLoading(true);

            await methods.trigger();

            const tabsValidationResult = validateTabs();

            if (tabsValidationResult !== 'ok') {
                submitBtnState.setSubmitLoading(false);
                dispatchAlert('error', `${t('errorReceived')} - "${t(tabsValidationResult)}"`);
                return () => {};
            }

            return methods.handleSubmit(async (data) => {
                const dataParser = () => {
                    const result = {
                        token,
                        clientCode: selectedClientCode || '',
                        courseCode,
                        courseId: data.courseId,
                        courseDirection: validCourseDirections.includes(data.courseDirection)
                            ? data.courseDirection
                            : '',
                        courseDescription: data.courseDescription,
                        departmentCode: getNonFillerValue(data.departmentCode),
                        lineTypeCode: getNonFillerValue(data.lineTypeCode),
                        carTypeCode: getNonFillerValue(data.carTypeCode),
                        remarks: data.remarks || '',
                        activeFromDate: dateHelpers.stringifyDate(data.activeFromDate),
                        activeUntilDate: dateHelpers.stringifyDate(data.activeUntilDate),
                        isActive: data.isActive ? (data.isActive as '1' | '0') : '1',
                        times: timesSettingsLst,
                        stationsFields: data.stations,
                        allStations,
                    } as const;

                    return result;
                };

                try {
                    const routeResponse = await coursesBuildingRoutes.setCourseForm(
                        getRouteClientData(dataParser())
                    );

                    if (routeResponse.code === CommonCodes.Ok) {
                        dispatchAlert('success', t('operationWasSuccessful'));
                        dispatch(resetCourseFormAction({}));
                        dispatch(setSelectedCourseIdAction(''));
                        dispatch(triggerApiCallAction('getAccountCourses'));
                    } else {
                        dispatchI18nErrMsg(createErrorMessage(routeResponse.data.message || ''));
                    }
                } catch (error) {
                    console.log(error);
                    dispatchI18nErrMsg(createErrorMessage('99'));
                }

                submitBtnState.setSubmitLoading(false);
            })();
        },
    };
};

const useFetchTimesSettingsFromApi = () => {
    const { t, dispatch, dispatchI18nErrMsg } = useCommons();

    const { getRouteClientData } = useBaseApiParams();

    const { updateApiCall } = useUpdateApiCall();

    const { courseCode } = useRootAppSelector(courseBuildingFormSelector);

    const fetchData = async () => {
        updateApiCall('getCourseTimesSettings', 'LOADING', []);

        try {
            const routeResponse = await coursesBuildingRoutes.getCourseTimesSettings(
                getRouteClientData({ courseCode })
            );

            if (routeResponse.code === CommonCodes.Ok && Array.isArray(routeResponse.data.data)) {
                updateApiCall('getCourseTimesSettings', 'SUCCESS', routeResponse.data.data);

                if (routeResponse.data.data.length) {
                    dispatch(setSettingsListAction(routeResponse.data.data));
                }
            } else {
                updateApiCall('getCourseTimesSettings', 'FAILED', []);

                dispatchI18nErrMsg(routeResponse.data.message || ErrorMessages.OperationFailed);
            }
        } catch (error) {
            console.error(error);
            updateApiCall('getCourseTimesSettings', 'FAILED', []);
            dispatchI18nErrMsg(ErrorMessages.OperationFailed);
        }
    };

    React.useEffect(() => {
        if (courseCode) fetchData();
    }, [courseCode]);

    return {
        fetchTimesSettings: fetchData,
    };
};

const courseFormHooks = {
    useReactHookForm,
    useFetchTimesSettingsFromApi,
};

export default courseFormHooks;
