import * as Common from '../apiCommon.types';

// Mock server

// Actual API
export enum S3FilesReqErrorCodes {
    MissingToken = 0,
    General = 1,
    Authentication = 2,
    FileTooLarge = 3,
    S3UploadFailed = 4,
    S3DeleteFailed = 5,
    S3LinkGenerationFailed = 6,
    InsertionToDbFailed = 7,
    FetchingFromDbFailed = 8, // user doesn't have a file
    DeletingFromDbFailed = 9,
}

enum GeneralErrorMessages {
    UploadFailed = 'uploadFailedTryAgain',
    DeleteFailed = 'deleteFailedTryAgain',
    GetFailed = 'getFailedTryAgain',
    FileTooLarge = 'fileTooLarge',
}

enum UniqueMockServerCodes {
    UploadFailed = 4,
    DeleteFailed = 5,
    GetFailed = 62,
}

const errorMessagesByCode = {
    [S3FilesReqErrorCodes.MissingToken]: 'serverErrorType0',
    [S3FilesReqErrorCodes.General]: 'serverErrorType1',
    [S3FilesReqErrorCodes.Authentication]: 'serverErrorType2',
    [S3FilesReqErrorCodes.FileTooLarge]: 'fileTooLarge',
    [S3FilesReqErrorCodes.S3UploadFailed]: GeneralErrorMessages.UploadFailed,
    [S3FilesReqErrorCodes.S3DeleteFailed]: GeneralErrorMessages.DeleteFailed,
    [S3FilesReqErrorCodes.S3LinkGenerationFailed]: 'linkGenerationFailedTryAgain',
    [S3FilesReqErrorCodes.InsertionToDbFailed]: 'serverErrorType7',
    [S3FilesReqErrorCodes.FetchingFromDbFailed]: 'serverErrorType8',
    [S3FilesReqErrorCodes.DeletingFromDbFailed]: 'serverErrorType9',
    [UniqueMockServerCodes.GetFailed]: GeneralErrorMessages.GetFailed,
};

export interface BaseResponseProperties {
    IsSuccess: boolean;
    ErrorMessage: string;
    ErrorCode: S3FilesReqErrorCodes;
}
export interface BaseReqParams {
    account_code: string; // client identifier wapid
    account_guid: string; // second client identifier (more unique)
    site_id: string; // מזהה אתר
}

// Upload file
export interface UploadFileParams extends BaseReqParams {
    file_name: string;
    File_data: string; // תוכן הקובץ בפורמט string base 64
}
export interface UploadFileResponse extends BaseResponseProperties {}

// Get file
export interface GetFileResponse extends BaseResponseProperties {
    FileName: string | null;
    FileLink: string | null;
    ExpirationByMinutes: number;
}

export interface GetFileParams extends BaseReqParams {}

// Delete file
export interface DeleteFileResponse extends BaseResponseProperties {}

export interface DeleteFileParams extends BaseReqParams {}

export const UploadFileMappers = {
    errorMessagesByCode,
};

export const CODE_KEY = 'code';

export const UploadFileEnums = {
    EndpointErrorCodes: S3FilesReqErrorCodes,
    GeneralErrorMessages,
    UniqueMockServerCodes,
};
