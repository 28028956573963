import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MuiButton from '@mui/material/Button';

import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { logout, setLoginType } from 'src/api/api';
import { selectedLoginType, userUUIDSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';
import { SingupType } from 'src/types/login';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { setLoginType as setLoginTypeAction, setLogout } from 'src/store/actions/loginAction';
import { getLocalStorageValue, setLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        headerContainer: {
            padding: theme.spacing(1),
            fontSize: '2vw',
        },
        subtitle: {
            padding: theme.spacing(2),
            fontSize: '1rem',
            fontFamily: 'Rubik',
        },
        padding: {
            padding: theme.spacing(2),
        },
        spartor: {
            borderBottom: 'solid 1px #e5e5e5',
        },
        spartorMargin: {
            borderBottom: 'solid 1px #e5e5e5',
            // margin: '0 2px 0 30px',
        },
        arrowIcon: {
            color: 'black',
            padding: theme.spacing(2),
            fontSize: '2.125rem',
        },
        button: {
            width: '100%',
            padding: theme.spacing(2),
        },
        editeButton: {
            backgroundColor: '#2196F3',
            float: 'right',
        },
        selectedButton: {
            backgroundColor: '#FFEDB1',
            width: '100%',
            padding: theme.spacing(2),
        },
    })
);

interface ILoginTypeObject {
    id: SingupType;
    desc: string;
    isConfirmable: boolean;
    route: string;
}

const Setting: FunctionComponent<{}> = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loginType = useSelector((state) => selectedLoginType(state));
    const userUUID = useSelector((state: IRootReducer) => userUUIDSelector(state));

    const onChangeLoginTypeAction = (payload: SingupType) => dispatch(setLoginTypeAction(payload));
    const onSetLogout = () => dispatch(setLogout());

    const loginTypesWithDesc: Array<ILoginTypeObject> = [
        {
            id: SingupType.Quick,
            desc: t('settingQuickLogin'),
            isConfirmable: false,
            route: `${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP_OPTIONS}`,
        },
        {
            id: SingupType.PinCode,
            desc: t('settingLoginByPinCode'),
            isConfirmable: true,
            route: `${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SIGNUP_WITH_PIN_CODE}`,
        },
        {
            id: SingupType.OnTimeLogin,
            desc: t('onTimeLogin'),
            isConfirmable: false,
            route: `${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SINGUP_OPTIONS}`,
        },
    ];

    const userLoginType = loginTypesWithDesc.find((x) => x.id === loginType);

    const goBack = (): void => {
        history.goBack();
    };

    const onEditLoginType = () => {
        if (userLoginType) history.push(userLoginType?.route);
    };

    const onChangeLoginType = async ({ route, id, isConfirmable }: ILoginTypeObject) => {
        if (id !== SingupType.OnTimeLogin && !getLocalStorageValue(StorageKeys.DeviceToken)) {
            const newDeviceToken = uuidv4();
            setLocalStorageValue(StorageKeys.DeviceToken, newDeviceToken);
        }

        if (isConfirmable) {
            history.push(route);
            return;
        }

        if (id === SingupType.OnTimeLogin) {
            onChangeLoginTypeAction(id);
            history.push(routesEndpoints.HOME);
            return;
        }

        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);

        if (deviceToken && userUUID) {
            const res = await setLoginType({
                autologin: id,
                userUUID,
                deviceToken,
            });

            if (res.response === '0') {
                onChangeLoginTypeAction(id);
                history.push(routesEndpoints.HOME);
            }
        }
    };

    const logoutFromAllDevices = async () => {
        if (userUUID) {
            const res = await logout({
                userUUID,
            });
            if (res.response === '0') onSetLogout();
            return;
        }

        onSetLogout();
    };

    return (
        <Grid container xs={8} className={classes.root}>
            <Grid item xs={2} spacing={0}>
                <Typography variant="h4" align="left">
                    <IconButton aria-label="go back" color="inherit" onClick={goBack}>
                        <ArrowForwardIcon />
                    </IconButton>
                    {t('settings')}
                </Typography>
            </Grid>
            <Grid xs={12} className={classes.spartor} />
            <Grid container xs={12} spacing={2}>
                <Typography
                    variant="subtitle1"
                    style={{
                        textDecoration: 'underline',
                        fontWeight: 'lighter',
                    }}
                    className={classes.subtitle}
                >
                    {t('settingLoginToSystem')}
                </Typography>
            </Grid>
            <Grid container xs={12} spacing={2}>
                <Typography align="right" variant="subtitle2" className={classes.subtitle}>
                    {t('settingLoginToThisSystem')}
                </Typography>
            </Grid>
            <Grid container xs={12} spacing={2} className={classes.padding}>
                <Grid
                    container
                    xs={6}
                    spacing={2}
                    alignItems="flex-start"
                    justify="flex-start"
                    className={classes.padding}
                >
                    <Paper className={classes.selectedButton}>
                        <span style={{ float: 'right' }}>{userLoginType?.desc}</span>

                        {userLoginType?.isConfirmable && (
                            <Button
                                size="large"
                                variant="contained"
                                className={classes.editeButton}
                                onClick={onEditLoginType}
                            >
                                {t('settingEditButton')}
                            </Button>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
                <Typography align="right" variant="subtitle2" className={classes.subtitle}>
                    {t('settingchangeLoginType')}
                </Typography>
            </Grid>
            {loginTypesWithDesc
                .filter((x) => x.id !== loginType)
                .map((logType) => (
                    <Grid container xs={12} key={logType.id} spacing={2} className={classes.padding}>
                        <Grid
                            container
                            xs={6}
                            spacing={2}
                            alignItems="flex-start"
                            justify="flex-start"
                            className={classes.padding}
                        >
                            <Button
                                style={{
                                    justifyContent: 'end',
                                    backgroundColor: '#FFFFFF',
                                }}
                                size="large"
                                variant="contained"
                                className={classes.button}
                                onClick={() => onChangeLoginType(logType)}
                            >
                                <span style={{ float: 'right' }}>{logType.desc}</span>
                            </Button>
                        </Grid>
                    </Grid>
                ))}

            <Grid xs={12} className={classes.spartorMargin} />
            <Grid container xs={12} spacing={2} className={classes.padding}>
                <Grid
                    container
                    xs={6}
                    spacing={2}
                    alignItems="flex-start"
                    justify="flex-start"
                    className={classes.padding}
                >
                    <MuiButton
                        style={{ color: 'white' }}
                        // size=""
                        variant="contained"
                        className={classes.editeButton}
                        onClick={logoutFromAllDevices}
                    >
                        {t('logoutFromAllDevices')}
                    </MuiButton>
                </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
                {/* <Typography
               variant="subtitle1"
               style={{ textDecoration: 'underline', fontWeight: 'lighter' }}
               className={classes.subtitle}
            >
               בקשה לשינוי מספר נייד במערכת
            </Typography> */}
            </Grid>
            <Grid container xs={12} spacing={2}>
                {/* <Typography paragraph variant="body2" className={classes.padding}>
               מספר הטלפון המוגדר במערכות: 000-0000000 <br />
               בכדי לשנות את מספר הטלפון, עלייך.....
            </Typography> */}
            </Grid>
        </Grid>
    );
};

export default Setting;
