import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useDate = () => {
    const { t } = useTranslation();

    const shortsDays = useMemo(
        () => ({
            0: t('sun'),
            1: t('mon'),
            2: t('tues'),
            3: t('wed'),
            4: t('thurs'),
            5: t('fri'),
            6: t('sat'),
        }),
        [t]
    );
    const fullDays = useMemo<{ [id: number]: string }>(
        () => ({
            0: t('sunday'),
            1: t('monday'),
            2: t('tuesday'),
            3: t('wednesday'),
            4: t('thursday'),
            5: t('friday'),
            6: t('saturday'),
        }),
        [t]
    );

    return { shortsDays, fullDays };
};

export default useDate;
