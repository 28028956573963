import React, { useRef } from 'react';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import {
    setFileInputDataAction,
    toggleModalAction,
    toggleSuccessModalAction,
} from 'src/store/slices/generalFeatures/generalFeaturesSlice';
import Spinner from 'src/components/Spinner/Spinner';
import { UploadFileEnums, UploadFileMappers } from 'src/types/uploadFileFt/uploadFileApiTypes';
import { setErrorMessage } from 'src/store/actions/loginAction';
import hooks from '../hooks.uploadFiles';
import helpers from '../helpers.uploadFile';
import Styled from '../styles.uploadFile';
import { Tooltip } from '@mui/material';
import { ApiReqStatus } from 'src/store/slices/generalFeatures/types.generalFeatures';

const NO_NAME_TEXT = 'ללא שם';

const { GeneralErrorMessages } = UploadFileEnums;

const allowedFileTypes = [
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
];

// is of type T

interface Props {}

const UploadInputArea: React.FC<Props> = ({}) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    const { t, dispatch } = hooks.useCommons();

    const {
        actions,
        sliceState: { data },
    } = hooks.useFileFtSlice();

    // const [isLoading, setIsLoading] = React.useState(false);
    const api = hooks.useApi();

    const changeHandler = (event: any) => {
        if (!event || !event.target.files[0]) return;

        actions.updateActionStatus({
            status: ApiReqStatus.Loading,
            actionType: 'upload',
        });

        const file: any = event.target.files[0];

        console.log(file);

        if (file && (file.type || file.type === '') && !allowedFileTypes.includes(file.type)) {
            actions.updateActionStatus({
                status: ApiReqStatus.Failed,
                actionType: 'upload',
            });
            dispatch(setErrorMessage(t('fileTypeError')));

            return;
            // Handle the valid file
            // ... Your existing logic for handling the file
        }

        const apiReqAsyncHandler = async () => {
            const FILE_SIZE_LIMIT_MB = 4; // MB

            try {
                if (helpers.isFile(file)) {
                    const genericFileName = helpers.createGenericFileName(file.name);

                    const fileSize = file.size / 1024 / 1024; // MB

                    if (fileSize > FILE_SIZE_LIMIT_MB) {
                        throw new Error(GeneralErrorMessages.FileTooLarge);
                    }

                    const fileAsBase64 = await helpers.getBase64StrFile(file);

                    if (helpers.isBase64(fileAsBase64)) {
                        const uploadResponse = await api.uploadFileToServer({
                            name: genericFileName,
                            data: fileAsBase64,
                        });

                        if (helpers.isApiReqSuccessful(uploadResponse)) {
                            dispatch(setFileInputDataAction({ fileName: genericFileName || NO_NAME_TEXT }));

                            dispatch(toggleModalAction(false));

                            actions.updateActionStatus({
                                status: ApiReqStatus.Succeeded,
                                actionType: 'upload',
                            });

                            // delay for 0.1 sec for ux purposes
                            setTimeout(() => {
                                dispatch(toggleSuccessModalAction(true));
                            }, 100);

                            return;
                        }

                        let errorMessage;

                        if (uploadResponse !== -1 && 'code' in uploadResponse) {
                            errorMessage = helpers.getErrorMessageByCode(
                                uploadResponse.code,
                                GeneralErrorMessages.UploadFailed
                            );
                        } else {
                            errorMessage = GeneralErrorMessages.UploadFailed;
                        }
                        throw new Error(errorMessage);

                        // dispatch upload failed
                    } else {
                        throw new Error(GeneralErrorMessages.UploadFailed);
                    }
                }
                throw new Error(GeneralErrorMessages.UploadFailed);
            } catch (error: any) {
                console.log(error);
                actions.updateActionStatus({
                    status: ApiReqStatus.Failed,
                    actionType: 'upload',
                });
                dispatch(setErrorMessage(t(error.message)));
            }
        };

        apiReqAsyncHandler();
    };

    const handleButtonClick = () => {
        if (inputFileRef.current) {
            // `current` points to the mounted file input element
            inputFileRef.current.click();
        }
    };

    return (
        <Styled.UploadBtnContainer onClick={handleButtonClick} className="UploadBtnContainer">
            {data.actionsStatus.upload !== ApiReqStatus.Loading ? (
                <>
                    <div style={{ pointerEvents: 'none' }}>
                        <AddIconNoText disabled={false} onClick={() => {}} />
                    </div>
                    <input
                        onChange={changeHandler}
                        type="file"
                        id="file"
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        accept=".png, .jpg, .jpeg, .xlsx, .docx, .pdf"
                    />
                    {t('uploadNewFile')}
                </>
            ) : (
                <Spinner />
            )}
        </Styled.UploadBtnContainer>
    );
};

const UploadSection: React.FC<{ withBorder?: boolean }> = ({ withBorder }) => {
    const clientCode = hooks.useFileFtSlice().sliceState.data.selectedClientCode;

    const isUploadDisabled = clientCode ? false : true;

    return (
        <Styled.UploadedSectionContainer className="UploadedSectionContainer">
            <p style={{ fontSize: '13px' }}>כאן באפשרותך להעלות קבצים אשר יוצגו לנוסעים באפליקצייה.</p>
            <Tooltip title={isUploadDisabled ? 'יש לבחור אתר' : ''} placement="right">
                <div>
                    <div style={isUploadDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
                        <UploadInputArea />
                    </div>
                </div>
            </Tooltip>
        </Styled.UploadedSectionContainer>
    );
};

export default UploadSection;
