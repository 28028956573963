import styled, { css } from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';
import { colors } from 'src/style/themes/defaultTheme';

export const warningRed = '#c55252';
const Conatiner = styled.div<{ overflow?: string; withWarningColors: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    overflow: ${(props) => props.overflow || 'auto'};
    background-color: ${({ theme, withWarningColors }) =>
        withWarningColors ? warningRed : theme.colors.seaBlue};
`;

const Item = styled(ButtonBase)`
    width: 100%;
    height: 8%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Text = styled.p<{ isActive: boolean }>`
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${({ isActive, theme }) => (isActive ? theme.colors.blue : theme.colors.black)};
`;

const BtnsContainer = styled.div`
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
`;

export default { Conatiner, Item, Text, BtnsContainer };
