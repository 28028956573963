import moment, { Moment } from 'moment';

import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RELATIVE, DISPLAY_UI } from 'src/constants/dates';
import useSummaryDepartment from 'src/screens/ManualOrder/components/Orderinfo/components/Summarycard/useSummaryChart';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    filtersSelector,
    clientIdSelector,
    selectApplyFiltersBtnClickCount,
    selectedReportTypeSelector,
    widgetsUiSelector,
    selectedReportNameSelector,
} from 'src/store/selectores/reportsSelectors';
import { ReportName, ReportNames, ReportsTypes } from 'src/store/slices/reports/types';
import { asRootReducer } from 'src/store/utils';
import { dateify, stringifyDate } from 'src/utilis/utilis';
import { IProgressData } from '../../types';

export const useOrdersInfoSelectors: () => {
    dateOnFiltersPreApplyFiltersBtnClick: Date;
    clientCode: number | undefined;
    applyFiltersBtnClickCount: number;
    selectedReportType: ReportsTypes;
    dateOnWidgets: moment.Moment;
    selectedReportName: ReportNames;
} = () => {
    const dateOnFiltersPreApplyFiltersBtnClick = useAppSelector((state) => filtersSelector(state).fromDate);

    const clientCode = useAppSelector((state) => {
        const code = clientIdSelector(state);
        if (code) return +code;
    });

    const applyFiltersBtnClickCount: number = useAppSelector((state) =>
        selectApplyFiltersBtnClickCount(state)
    );

    const selectedReportType = useAppSelector((state) => selectedReportTypeSelector(state));

    const dateOnWidgets = moment(
        useAppSelector((state) => widgetsUiSelector(asRootReducer(state)).dateOnWidget)
    );

    const selectedReportName = useRootAppSelector(selectedReportNameSelector);

    return {
        dateOnFiltersPreApplyFiltersBtnClick,
        clientCode,
        applyFiltersBtnClickCount,
        selectedReportType,
        dateOnWidgets,
        selectedReportName,
    };
};

export const useWidgetsConfigData = (): {
    dateAsStr: string;
    clientCode: number | undefined;
    selectedReportType: ReportsTypes;
    dateAfterApplyingFilters: Date;
    dateOnWidgets: Moment;
} => {
    const {
        dateOnFiltersPreApplyFiltersBtnClick,
        applyFiltersBtnClickCount,
        clientCode,
        selectedReportType,
        dateOnWidgets,
        selectedReportName,
    } = useOrdersInfoSelectors();

    const [dateAfterApplyingFilters, setDateAfterApplyingFilters] = useState(
        dateOnFiltersPreApplyFiltersBtnClick
    );

    useEffect(() => {
        setDateAfterApplyingFilters(dateOnFiltersPreApplyFiltersBtnClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFiltersBtnClickCount, selectedReportName]);

    return {
        dateAsStr: stringifyDate(dateAfterApplyingFilters, DISPLAY_UI),
        clientCode,
        selectedReportType,
        dateAfterApplyingFilters,
        dateOnWidgets,
    };
};
