export enum PlaceTypes {
    PointOfInterest = 'point_of_interest',
    FullAddress = 'street_address',
    Establishment = 'establishment',
    Premise = 'premise',
    ColloquialArea = 'colloquial_area',
    Neighborhood = 'neighborhood',
}
export enum AddressTypes {
    City = 'locality',
    Street = 'route',
    HouseNum = 'street_number',
}

export interface AutocompleteAddress {
    city: string;
    street: string;
    houseNum: string;
    placeName: string;
    lat?: number;
    lng?: number;
    timing?: number;
    distanceFromPrevStation?: number;
}
