import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import React, { FunctionComponent } from 'react';
import PeopleAltSharpIcon from '@material-ui/icons/PeopleAltSharp';
import { Box } from '@material-ui/core';

const PersonKendoHeader: FunctionComponent<GridHeaderCellProps> = ({
    onClick,
    children,
}: GridHeaderCellProps | any) => {
    return (
        <Box onClick={onClick} component="div" style={{ textAlign: 'center', cursor: 'pointer' }}>
            <span>
                <PeopleAltSharpIcon style={{ marginBottom: '-8px' }} />
            </span>
            {children}
        </Box>
    );
};

export default PersonKendoHeader;
