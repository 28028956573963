import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
    show: boolean;
    style?: CSSProperties;
}

/**
 * A React component that wraps its children in a div that can be toggled to display or not.
 * @param {Props} props - the props for the component.
 * @returns None
 */
const DisplayWrapper: React.FunctionComponent<Props> = React.forwardRef(({ children, show, style }, ref) => {
    return (
        <div
            ref={ref as React.RefObject<HTMLDivElement>}
            style={
                !show
                    ? {
                          ...style,
                          display: 'none',
                      }
                    : { ...style }
            }
        >
            {children}
        </div>
    );
});

export default DisplayWrapper;
