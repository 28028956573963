/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { CSSProperties, FunctionComponent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IPassengerShifts } from 'src/types/manualOrders/api.types';
import { getStyle } from 'src/screens/ManualOrder/ManualOrderTable/Shiftcell/Shiftcell';
import { onClickedBlockedTable } from 'src/store/actions/PassengersShiftActionType';
import { IRootReducer } from 'src/store/reducers';

const EmplyeeColumn: FunctionComponent<GridCellProps> = ({ dataItem }: GridCellProps) => {
    const dispatch = useDispatch();
    const passenger: IPassengerShifts = dataItem;

    const { isShiftSelected, isOpen } = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form
    );
    const textContainerStyle = useMemo(() => getStyle(isShiftSelected, isOpen), [isOpen, isShiftSelected]);

    return (
        <td
            className="emplyee-column"
            onClick={() => {
                isShiftSelected && dispatch(onClickedBlockedTable());
            }}
            style={{
                ...getStyle(isShiftSelected, isOpen),
                height: '1px',
                padding: 0,
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    cursor: isShiftSelected && isOpen ? 'not-allowed' : 'default',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingRight: '8px',
                    textOverflow: 'ellipsis',
                }}
                onClick={() => {
                    isShiftSelected && dispatch(onClickedBlockedTable());
                }}
            >
                <div style={textContainerStyle}>
                    <b style={getStyle(isShiftSelected, isOpen)}>{passenger.fullName}</b>
                </div>
                <div
                    style={textContainerStyle}
                >{`${passenger.internalPassCode} ${passenger.departmentName}`}</div>
                <div style={textContainerStyle}>{passenger.city}</div>
            </div>
        </td>
    );
};

export default EmplyeeColumn;

// using grid
// import { GridCellProps } from '@progress/kendo-react-grid';
// import React, { FunctionComponent } from 'react';
// import Grid from '@material-ui/core/Grid';
// import { IPassenger } from 'src/types/manualOrders/api.types';

// const EmplyeeColumn: FunctionComponent<GridCellProps> = ({
//    dataItem,
// }: GridCellProps) => {
//    const passnger: IPassenger = dataItem;

//    return (
//       <td className="emplyee-column">
//          <Grid
//             container
//             spacing={1}
//             direction="column"
//             style={{ width: '100%' }}
//             justify="center"
//          >
//             <Grid container direction="row">
//                <Grid item xs={12}>
//                   <b>{passnger.fullName}</b>
//                </Grid>
//             </Grid>
//             <Grid container spacing={3} direction="row">
//                <Grid item xs={6}>
//                   {passnger.passId}
//                </Grid>
//                <Grid item xs={6}>
//                   {passnger.departmentName}
//                </Grid>
//             </Grid>
//          </Grid>
//       </td>
//    );
// };

// export default EmplyeeColumn;
