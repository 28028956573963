import querystring from 'querystring';
import { AxiosResponse } from 'axios';

import { SECOND } from 'src/constants/times';
import instance, { buildEncriptedUrl, getInstanceWithTime } from '../index';
import { IResponse } from '../types';
import {
    IShifts,
    IShiftRequest,
    IPassengerRequest,
    IResponsePassenger,
    ISetShiftOrder,
    IDelShiftOrderApi,
    IPassengerDate,
    ISetPassengersOrders,
    IPassengerShiftUpdate,
    IPayloadDepartments,
    IDepartment,
    IStatistics,
    IPayloadStatistics,
    IDepartmentsStatistics,
    SetPassengersResponseCodes,
} from '../../types/manualOrders/api.types';
import { createPayload } from '../utilis';
import { makeApiCall } from '../reportsApi/utils';

// FC REQUEST

export const getShifts = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: IShiftRequest): Promise<AxiosResponse<IResponse<Array<IShifts>>>> => {
    // const data = querystring.stringify({
    //    ...createPayload(payload),
    // });

    // return instance.post(
    //    buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Shifts_By_Dates'),
    //    data,
    // );
    return makeApiCall<
        IResponse<Array<IShifts>>,
        {
            departmentCode?: number | undefined;
            token: string;
            fromDate: string;
            toDate: string;
            clientCode: number;
        }
    >({
        dbUrl,
        payload,
        endpoint: 'ws_MyWayStudio_Get_Shifts_By_Dates',
    });
};

// FC REQUEST

export const getPassngerShifts = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: IPassengerRequest): Promise<AxiosResponse<IResponsePassenger>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });
    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Passsengers_4_Dates'), data);
};

// FC REQUEST

export const setPassngerShifts = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: ISetShiftOrder): Promise<AxiosResponse<IPassengerDate>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Set_Shift_Order'), data);
};

// FC REQUEST

export const delPassngerShifts = async ({
    proxyUrl,
    dbUrl,
    ...payload
}: IDelShiftOrderApi): Promise<AxiosResponse<any>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Del_Orders'), data);
};

// FC REQUEST

export const setPassengersOrders = async ({
    proxyUrl,
    dbUrl,
    jsonData,
    clientCode,
    ...rest
}: ISetPassengersOrders): Promise<
    AxiosResponse<{
        message: IPassengerShiftUpdate[];
        response: SetPassengersResponseCodes;
    }>
> => {
    const params = new URLSearchParams();
    params.append('$jsonData', `[${JSON.stringify(jsonData)}]`);
    params.append('$clientCode', clientCode);
    params.append('$dropCity', rest.dropCity);
    params.append('$dropHouseNum', rest.dropHouseNum);
    params.append('$dropStreet', rest.dropStreet);
    params.append('$pickupCity', rest.pickupCity);
    params.append('$pickupHouseNum', rest.pickupHouseNum);
    params.append('$pickupStreet', rest.pickupStreet);
    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Set_Passengers_Orders'), params);
};

// FC REQUEST

export const getDepartments = async ({
    dbUrl,
    ...payload
}: IPayloadDepartments): Promise<AxiosResponse<{ response: string; departments: IDepartment[] }>> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Departments'), data);
};

// FC REQUEST

export const getShiftsStatistics = async ({
    dbUrl,
    ...payload
}: IPayloadStatistics): Promise<
    AxiosResponse<{
        response: string;
        shiftsStatistics: IStatistics[];
        fromDate: string;
        toDate: string;
    }>
> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'Ws_MyWayStudio_Get_Shifts_Statistics'), data);
};

// FC REQUEST

export const getDepartmentsStatistics = async ({
    dbUrl,
    ...payload
}: IPayloadStatistics): Promise<
    AxiosResponse<{
        response: string;
        departmentsStatistics: IDepartmentsStatistics[];
        fromDate: string;
        toDate: string;
    }>
> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return instance.post(buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Departments_Statistics'), data);
};

// FC REQUEST

export const reqGetPassengerAddress = async ({
    dbUrl,
    ...payload
}: {
    dbUrl: string;
    clientCode: string;
    passCode: string;
    token: string;
    pickupTime: string;
    dropTime: string;
}): Promise<
    AxiosResponse<{
        response: string;
        data: { city: string; street: string; houseNum: string }[];
    }>
> => {
    const data = querystring.stringify({
        ...createPayload(payload),
    });

    return getInstanceWithTime(SECOND * 3).post(
        buildEncriptedUrl(dbUrl, 'ws_MyWayStudio_Get_Passenger_Address'),
        data
    );
};
