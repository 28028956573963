import React from 'react';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import useCommons from 'src/hooks/common/useCommons';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import {
    coursesBuildingActions,
    selectedCourseIdSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { ApiCallStatus, ErrorMessages } from 'src/types/apiCommon.types';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { asTSR } from 'src/utilis/utilis';
import { coursesBuildingSelectors } from '../../../store/slices/coursesBuilding/coursesBuildingSlice';
import { ApiCallsNames } from 'src/types/coursesBuilding/storeTypes';

export const useTriggerCount = (callName: ApiCallsNames) => {
    const { useRootAppSelector } = useCommons();

    const apiCallTriggerCount = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state)[callName].triggerCount
    );

    return apiCallTriggerCount;
};

const useStationsFetch = () => {
    const { getRouteClientData } = useBaseApiParams();

    const { dispatch, dispatchI18nErrMsg, useRootAppSelector } = useCommons();

    const triggerCount = useTriggerCount('getCourseStations');

    const selectedCourseId = useRootAppSelector(selectedCourseIdSelector);

    const updateApiCall = React.useCallback(
        <T>(status: ApiCallStatus, data: T) => {
            dispatch(
                coursesBuildingActions.updateApiCall({
                    data,
                    callName: 'getCourseStations',
                    status,
                })
            );
        },
        [dispatch]
    );

    const fetchData = async () => {
        updateApiCall('LOADING', []);

        try {
            const r = await coursesBuildingRoutes.getCourseStations(
                getRouteClientData({ courseCode: selectedCourseId })
            );

            if (r.code === CommonCodes.Ok && Array.isArray(r.data.data)) {
                updateApiCall('SUCCESS', r.data.data);
            } else {
                updateApiCall('FAILED', []);
                dispatchI18nErrMsg(r.data.message || ErrorMessages.GeneralError);
            }
        } catch (error) {
            console.log(error);
            updateApiCall('FAILED', []);
            dispatchI18nErrMsg(ErrorMessages.GeneralError);
        }
    };

    React.useEffect(() => {
        if (selectedCourseId) {
            fetchData();
        }
    }, [selectedCourseId, triggerCount]);
};

export default useStationsFetch;
