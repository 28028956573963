import { CourseStation, CourseStationTypes, ParsedCourseStation } from 'src/types/lines/api/types';
import { CourseLocations, FormSchema, Station } from 'src/screens/Main/components/AddLineForm/types';

const createAddressObj = (data: ParsedCourseStation) => {
    return {
        city: data.city || '',
        street: data.street || '',
        houseNum: data.house || '',
        placeName: '',
    };
};

const processCourseLocations: (rawData: ParsedCourseStation[]) => CourseLocations = (rawData) => {
    // create Station objects with passengers,
    const stations: ParsedCourseStation[] = [];
    let startAddress: ParsedCourseStation | null = null;
    let endAddress: ParsedCourseStation | null = null;

    rawData.forEach((address) => {
        if (address.type === CourseStationTypes.Origin) {
            startAddress = address;
            return;
        }
        if (address.type === CourseStationTypes.Destination) {
            endAddress = address;
            return;
        }
        if (address.type === CourseStationTypes.Normal) {
            stations.push(address);
        }
    });

    return {
        stations,
        startAddress,
        endAddress,
    } as const;
};

const isDirtyAddress = (address: FormSchema['endAddress']) => {
    if (address?.city || address?.street || address?.houseNum || address?.placeName) {
        return true;
    }
    return false;
};

export const willOverrideValues = (currValues: FormSchema, stations: Station[]): boolean => {
    if (
        stations.length ||
        currValues.carId ||
        currValues.linePrice ||
        currValues.departmentCode ||
        currValues.passQty ||
        currValues.description ||
        isDirtyAddress(currValues.startAddress) ||
        isDirtyAddress(currValues.endAddress)
    ) {
        return true;
    }

    return false;
};

const storedCoursesHelpers = {
    createAddressObj,
    processCourseLocations,
    willOverrideValues,
};

export default storedCoursesHelpers;
