import React, { CSSProperties } from 'react';
import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import { stationPassengersTableStyles as styles } from '../../../styles';
import PassengerRowActions from '../PassengerRowActions/PassengerRowActions';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { flexbox } from '@mui/system';

type PassengerRowProps = {
    setHover: (arg0: null | string) => void;
    passenger: ExistingPassenger;
    hover: null | string;
    stationId: string;
    actionsAreDisabled: boolean;
};
const style: CSSProperties = {
    position: 'absolute',
};

const PassengerRow = (props: PassengerRowProps): JSX.Element => (
    <styles.StyledTableRow
        onMouseEnter={() => props.setHover(props.passenger.passCode)}
        onMouseLeave={() => props.setHover(null)}
        hover={props.hover === props.passenger.passCode}
        isDataRow
        className="StyledTableRow"
    >
        <styles.StyledTableCell isTd>
            <p
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {props.passenger.email && (
                    <MailOutlineIcon style={{ position: 'absolute', right: '-40px', top: '-4.4px' }} />
                )}
                {props.passenger.internalCode}
            </p>
        </styles.StyledTableCell>
        <styles.StyledTableCell isTd>
            <p> {props.passenger.firstName}</p>
        </styles.StyledTableCell>
        <styles.StyledTableCell isTd>
            <p>{props.passenger.lastName}</p>
        </styles.StyledTableCell>
        <styles.StyledTableCell isTd>
            <p>{props.passenger.mobilePhone}</p>
        </styles.StyledTableCell>
        {/* <p>{passenger.phone}</p> */}
        <styles.StyledTableCell isTd>
            <p style={style}>{props.passenger.remarks || ' '}</p>
            {props.hover === props.passenger.passCode && !props.actionsAreDisabled && (
                <PassengerRowActions passenger={props.passenger} stationId={props.stationId} />
            )}
        </styles.StyledTableCell>
    </styles.StyledTableRow>
);

export default PassengerRow;
