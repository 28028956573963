import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { shiftsSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { IRootReducer } from 'src/store/reducers';

import OutlinedBtn from 'src/components/buttons/OutlinedBtn/OutlinedBtn';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import { IPassengerShifts } from 'src/types/manualOrders/api.types';
import { useRootAppSelector } from 'src/store/hooks';
import { selectedPassengersSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { PASSENGER_ID_INDEX } from 'src/store/slices/manualOrders/helpers';
import styles from './ManualOrderWrapper.style';
import ManualOrderTable from '../ManualOrderTable/ManualOrderTable';
import hooks from '../hook/hooks.manualOrdersTable';
import useAddShiftBtn from '../hook/useAddShiftBtn';
import ExportedKendoTable from '../components/ExportedKendoTable/ExportedKendoTable.index';

const btnSx = {
    marginRight: '0px',
    position: 'absolute',
    zIndex: 999,
    bottom: '5em',
    left: '3em',
    height: '44px',
    width: '135px',
    fontWeight: 400,
    fontSize: 14,
    opacity: 1,
    '&.Mui-disabled': {
        background: '#C4C4C4',
    },
};
interface IProps {
    startDate: Date;
    endDate: Date;
    data: IPassengerShifts[];
}

const ManualOrderWrapper: FC<IProps> = ({ startDate, endDate, data }: IProps) => {
    // console.log('ManualOrderWrapper');

    const { t } = useTranslation();
    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));

    const [filters] = useState<CompositeFilterDescriptor>();

    hooks.usePassengerShiftCapsulesData();

    const { handleClick, showBtn, tempDisabled } = useAddShiftBtn(data);

    return (
        <styles.Container className="Container">
            <styles.TableContainer className="TableContainer">
                <styles.StyledKendoGridContainer className="StyledKendoGridContainer">
                    <ManualOrderTable
                        startDate={startDate}
                        endDate={endDate}
                        data={data}
                        compositeFilters={filters}
                        shifts={shifts}
                    />
                </styles.StyledKendoGridContainer>
                <DisplayWrapper show={showBtn}>
                    <OutlinedBtn
                        variant="contained"
                        handleClick={handleClick}
                        sx={btnSx}
                        isDisabled={tempDisabled}
                    >
                        {t('addShift')}
                    </OutlinedBtn>
                </DisplayWrapper>
            </styles.TableContainer>
        </styles.Container>
    );
};

export default ManualOrderWrapper;

// const { t } = useTranslation();
// const [globalFilter, setGlobalFilter] = useState<string>('');
// const GlobalFilterProp: InputProp = {
//    value: globalFilter,
//    label: t('freeSearch'),
//    size: 'small',
//    style: { width: '20%', marginRight: 10 },
//    labelWidth: 78,
//    onChange: (e: any) => {
//       const { value } = e.currentTarget;
//       setGlobalFilter(value);

//       const globalFilterArray: CompositeFilterDescriptor | undefined = value
//          ? {
//               filters: [
//                  {
//                     operator: 'contains',
//                     field: 'fullName',
//                     value,
//                  },
//                  {
//                     operator: 'eq',
//                     field: 'passId',
//                     value,
//                  },
//                  {
//                     operator: 'contains',
//                     field: 'departmentName',
//                     value,
//                  },
//               ],
//               logic: 'or',
//            }
//          : undefined;

//       setFilters(globalFilterArray);
//    },
// };
