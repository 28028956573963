import { useTranslation } from 'react-i18next';

import React, { useCallback, useState } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { onErrorAction } from 'src/store/slices/reports/reportsSlice';
import { getCurrDateAsString } from 'src/utilis/utilis';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../store/actions/loginAction';

type Props = {};

const useErrorHandler: () => {
    errorMsg: string;
    handleError: (args: { err?: any; msg: string; details?: any; isFetal?: boolean }) => void;
} = () => {
    const dispatch = useAppDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const handleError = useCallback(
        (args: { err?: any; msg: string; details?: any; isFetal?: boolean }) => {
            const { err, msg, details, isFetal } = args;
            setErrorMsg(msg);
            if (isFetal) {
                dispatch(onErrorAction({ msg, code: getCurrDateAsString() }));
            }
        },
        [dispatch]
    );
    return {
        errorMsg,
        handleError,
    };
};

export const useErrorMessageDispatcher = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dispatchErrorMsg = (msg?: string) => {
        dispatch(setErrorMessage(msg || t('oopsSomethingWentWrongTryAgain')));
    };
    return [dispatchErrorMsg];
};
export default useErrorHandler;
