export const ALL_DEPARTMENTS_FILTER_VALUE = '-1';

const FILLER_ID = -1;

const EMPTY_SHIFT = {
    shiftId: FILLER_ID,
    shiftName: '',
    dates: [],
    pickupTime: '',
    dropTime: '',
} as const;

export const manualOrdersConstants = {
    ALL_DEPARTMENTS_FILTER_VALUE,
    EMPTY_SHIFT,
    FILLER_ID,
};
