/* eslint-disable consistent-return */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import LineStatusColumn from 'src/components/KendoGridCutomeColumn/LineStatusColumn';
import PersonKendoHeader from 'src/components/KendoGridCutomeColumn/PersonKendoHeader';
import { stringifyDate } from 'src/utilis/utilis';
import DATE_FORMATS from 'src/constants/dates';
import { shortOrdersWidths } from '../../misc/columnsConfigs';
import { ReportName } from 'src/store/slices/reports/types';
import useColumnsFromPrevSession from './useColumnsFromPrevSessions';
import { getFieldValueByLocalStorage } from './utils';

const { width } = window.screen;

enum ColIds {
    PassId = 'col-shortOrders-passId',
    FullName = 'col-shortOrders-fullName',
    DepartmentName = 'col-shortOrders-departmentName',
    City = 'col-shortOrders-city',
    StreetWithHouseNum = 'col-shortOrders-streetWithHouseNum',
    WorkStart = 'col-shortOrders-workStart',
    WorkEnd = 'col-shortOrders-workEnd',
    PickupCourseId = 'col-shortOrders-pickupCourseId',
    DropCourseId = 'col-shortOrders-dropCourseId',
    RelativeDate = 'col-shortOrders-relativeDate',
}
const useShortOrdersReportColumns = (): {
    columns: Array<GridColumnProps>;
} => {
    const { t } = useTranslation();
    const {
        passId,
        passName,
        streetWithHouseNum,
        pickupCourseId,
        departmentName,
        city,
        workEnd,
        workStart,
        dropCourseId,
    } = shortOrdersWidths;

    const { localStorageCols } = useColumnsFromPrevSession();

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'passId',
                title: t('passId'),
                minResizableWidth: 50,
                headerClassName: 'broken-header-col',
                id: ColIds.PassId,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.PassId, 'width', passId),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.PassId,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'fullName',
                title: t('fullName'),
                minResizableWidth: 50,
                id: ColIds.FullName,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.FullName, 'width', passName),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.FullName,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'departmentName',
                title: t('department'),
                minResizableWidth: 50,

                id: ColIds.DepartmentName,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DepartmentName,
                    'width',
                    departmentName
                ),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DepartmentName,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'city',
                title: t('city'),
                minResizableWidth: 50,
                id: ColIds.City,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.City, 'width', city),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.City,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'streetWithHouseNum',
                title: t('street'),
                minResizableWidth: 50,

                id: ColIds.StreetWithHouseNum,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.StreetWithHouseNum,
                    'width',
                    streetWithHouseNum
                ),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.StreetWithHouseNum,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'workStart',
                title: t('workStart'),
                minResizableWidth: 50,
                id: ColIds.WorkStart,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.WorkStart, 'width', workStart),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.WorkStart,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'pickupCourseId',
                title: t('pickUpRouteCode'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.PickupCourseId,
                    'orderIndex',
                    undefined
                ),
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.PickupCourseId,
                    'width',
                    pickupCourseId
                ),
                id: ColIds.PickupCourseId,
            },
            {
                field: 'workEnd',
                title: t('workEnd'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(localStorageCols, ColIds.WorkEnd, 'orderIndex', 7),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.WorkEnd, 'width', workEnd),
                id: ColIds.WorkEnd,
            },
            {
                field: 'dropCourseId',
                className: 'person-kendo-header',
                title: t('dropOffRouteCode'),
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DropCourseId,
                    'width',
                    dropCourseId
                ),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DropCourseId,
                    'orderIndex',
                    undefined
                ),
                minResizableWidth: 50,
                id: ColIds.DropCourseId,
            },
            {
                field: 'relativeDate',
                title: t('date'),
                minResizableWidth: 50,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.RelativeDate,
                    'orderIndex',
                    undefined
                ),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.RelativeDate, 'width', 80),
                cell: (cellProps) => (
                    <td style={{ padding: '0 5px 0 0' }}>
                        <div>
                            {cellProps.dataItem.relativeDate
                                ? stringifyDate(cellProps.dataItem.relativeDate, DATE_FORMATS.DISPLAY_UI)
                                : ''}
                        </div>
                    </td>
                ),
                id: ColIds.RelativeDate,
            },
        ],
        [
            city,
            departmentName,
            dropCourseId,
            passId,
            passName,
            pickupCourseId,
            streetWithHouseNum,
            t,
            workEnd,
            workStart,
            localStorageCols,
        ]
    );

    return { columns };
};

export default useShortOrdersReportColumns;
