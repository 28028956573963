/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/button-has-type */
import React, { FC, useState } from 'react';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    setMapIsExpandedAction,
    triggerDirectionsApiAction,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

import MapType2 from 'src/components/Map/MapType2/MapType2';
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import useMarkers, { useMapCenter } from './hooks';
import _ from 'lodash';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import {
    ConfirmationWindow,
    DistanceDisplay,
    DurationDisplayWindow,
    EditInstructionsWindow,
} from './windows';
import TopViewBtn, { useTopViewBtn } from './TopView';
import { DEFAULT_ZOOM } from '../utils/common';
import useDirections from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

const CourseBuildingMap: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const isFormOpen = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.isOpen);
    const formMapState = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map);
    const isLocationChanged = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.courseStationsTab.isLocationChanged
    );
    const totalDistance = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.map.totalDistance
    );
    const isMapExpanded = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map.isExpanded);

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [zoom, setZoom] = React.useState<number | undefined>(DEFAULT_ZOOM);

    const [center, setCenter] = useState(defaultCenter);

    const { getMapCenter } = useMapCenter({ setZoom, setCenter });
    const { toggleFullView } = useTopViewBtn({ setZoom, setCenter, getMapCenter });

    const { markers } = useMarkers();

    const { rendererProps } = useDirections({ toggleFullView: () => toggleFullView(true) });

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(this.getZoom());
            } catch (error) {
                console.log(error);
            }
        },
        onLoad: (googleMap) => {
            // Saw this on official docs, not doing anything with it yet
            setMap(googleMap);
            dispatch(triggerDirectionsApiAction());
        },
        onUnmount: () => {
            // Saw this on official docs, not doing anything with it yet
            setMap(null);
        },
        options: {
            fullscreenControl: false,
            minZoom: 9,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const totalTime = React.useMemo(
        () => formMapState.fieldsCopy.reduce((acc, curr) => acc + (curr?.timeFromPrev || 0), 0),
        [formMapState.fieldsCopy]
    );

    const getEditWindowComponent = React.useCallback(() => {
        const editedStationData = formMapState.fieldsCopy.find(
            (station) => station.stationId === formMapState.editedStationId
        );

        if (
            formMapState.updatedCoords &&
            !_.isEqual(formMapState.updatedCoords, {
                lat: editedStationData?.lat,
                lng: editedStationData?.lng,
            })
        ) {
            return <ConfirmationWindow />;
        }

        return <EditInstructionsWindow />;
    }, [formMapState.editedStationId, formMapState.fieldsCopy, formMapState.updatedCoords]);

    if (formMapState.fieldsCopy.length === 0 || !isFormOpen) return null;

    return (
        <styles.Container className="Container">
            <styles.MapDiv className="MapDiv" isOpen={formMapState.isExpanded}>
                <DurationDisplayWindow totalTime={totalTime} />
                <DistanceDisplay isMapExpanded={isMapExpanded} totalDistance={totalDistance} />
                <styles.ExpandMapIcon
                    style={{ position: 'absolute', zIndex: 999 }}
                    onClick={() => {
                        dispatch(setMapIsExpandedAction(!formMapState.isExpanded));
                    }}
                >
                    <img style={{ width: '20px' }} src={styles.imgUrl} alt="expand-map" />
                </styles.ExpandMapIcon>

                {formMapState.isExpanded ? <TopViewBtn toggle={toggleFullView} /> : null}

                {formMapState.isExpanded && formMapState.editedStationId && formMapState.isOnEditMode
                    ? getEditWindowComponent()
                    : null}
                <MapType2 GoogleMapProps={GoogleMapProps}>
                    <>
                        {rendererProps ? <DirectionsRenderer {...rendererProps} /> : null}
                        {markers.map((m) => (
                            <Marker key={m.markerId} {...m.markerProps} />
                        ))}
                    </>
                </MapType2>
            </styles.MapDiv>
        </styles.Container>
    );
};

export default CourseBuildingMap;
