import React, { useState } from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import {
    coursesBuildingSelectors,
    filtersSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { FillerValues } from 'src/constants/misc';

const { apiDataSelector } = coursesBuildingSelectors;

const useFilteredCourses = () => {
    const { getAccountCourses } = useRootAppSelector((state) => apiDataSelector(state));
    const filtersVals = useRootAppSelector(filtersSelector);

    const [filtered, setFiltered] = useState<WsCourseDataItem[]>(getAccountCourses.data || []);

    // update filtered courses when search changes
    React.useEffect(() => {
        const freeSearch = filtersVals.freeSearch;
        const courseId = filtersVals.courseId;

        const filteredRes = getAccountCourses.data
            ?.filter((course) => course.courseDescription.includes(freeSearch))
            .filter((course) => {
                if (courseId === FillerValues.All) return true;
                if (courseId === FillerValues.Without) {
                    if (course.courseId) return false;
                    return true;
                }
                return course.courseId === courseId;
            });

        setFiltered(filteredRes || []);
    }, [filtersVals.courseId, filtersVals.freeSearch, getAccountCourses.data]);

    return { filtered };
};

export default useFilteredCourses;
