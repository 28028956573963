import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReqStatus } from 'src/api/types';
import { useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { passengerRootDataSelector } from 'src/store/selectores/passengerShiftsSelectores';
import {
    selectedReportNameSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectRefreshBtnClickCount,
    uiSelector,
} from 'src/store/selectores/reportsSelectors';
import { getCurrentPath } from 'src/utilis/utilis';

const useGridDataLoadingStatus = () => {
    const manualOrdersFetchStatus = useSelector((state: IRootReducer) => {
        return passengerRootDataSelector(state).loadingPassengers;
    });
    const passengersMngFetchStatus = useSelector((state: IRootReducer) => {
        return state.passengerReudcer.loadingPassengers;
    });
    const reportFetchStatus = useAppSelector((state: any) => {
        const currReportName = uiSelector(state).main.selectedReport;
        return state.reports.data.reports[currReportName].apiCallStatus;
    });
    const pathStatus = {
        manualOrder: {
            path: '/manualOrder',
            status: manualOrdersFetchStatus,
        },
        managePassengers: {
            path: '/managePassengers',
            status: passengersMngFetchStatus,
        },
        reports: {
            path: '/reports',
            status: reportFetchStatus,
        },
    };

    return pathStatus;
};
export default useGridDataLoadingStatus;
