import React from 'react';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import useCommons from 'src/hooks/common/useCommons';
import commonRoutes from 'src/mockServer/routes/commonRoutes';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { useApiCallStatusUpdate } from '../useAllCoursesFetch';

const useGetLineTypesFromServer = () => {
    const { dispatchI18nErrMsg } = useCommons();

    const { getRouteClientData } = useBaseApiParams();

    const { updateApiCall } = useApiCallStatusUpdate();

    const fetchData = async () => {
        try {
            const routeResponse = await commonRoutes.getLineTypes(getRouteClientData({}));

            if (routeResponse.code === CommonCodes.Ok) {
                updateApiCall('getLineTypes', 'SUCCESS', routeResponse.data.data);
                return;
            }

            updateApiCall('getLineTypes', 'FAILED', routeResponse.data);
            dispatchI18nErrMsg(routeResponse.data.message || ErrorMessages.GeneralError);
        } catch (error) {
            dispatchI18nErrMsg(ErrorMessages.GeneralError);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [getRouteClientData]);
};
export default useGetLineTypesFromServer;
