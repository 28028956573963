import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
    id: string;
};

const MyReactTooltip: FC<Props> = ({ id, children }) => {
    return (
        <ReactTooltip id={id} place="right" type="light" borderColor="gray" border>
            {children}
        </ReactTooltip>
    );
};
export default MyReactTooltip;
