import React, { CSSProperties } from 'react';
import { Hr } from 'src/screens/ManualOrder/components/PlacemenTransportation/PlacemenTransportation.Style';
import { colors, msColors } from 'src/style/themes/defaultTheme';

const gray = '#EDEDED';
type Props = {
    day: string;
    date: string;
    count: number;
};

const getStyles: (hasRides: boolean) => { [key: string]: CSSProperties } = (hasRides) => {
    return {
        dayCountContainer: {
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            borderBottom: '1px solid #EDEDED',
            display: 'flex',
            width: '70px',
            height: '70px',
            flexDirection: 'column',
            alignItems: 'center',
            fontWeight: hasRides ? 'bold' : 'normal',
        },
        grayTextPar: { color: hasRides ? msColors.darkTextcolor : msColors.veryLightTextColor },
    };
};

const DayCount: React.FC<Props> = React.memo(({ day, date, count }) => {
    const styles = getStyles(!!count);

    return (
        <td style={styles.dayCountContainer}>
            <div style={styles.grayTextPar}>{day}</div>
            <div style={styles.grayTextPar}>{date}</div>
            <div>{count || '-'}</div>
        </td>
    );
});
export default DayCount;
