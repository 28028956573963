/* eslint-disable consistent-return */
import styled, { css, CSSObject } from 'styled-components';
import defaultTheme, { colors } from 'src/style/themes/defaultTheme';

const MyP = styled.p<{ size: number; bold?: boolean }>`
   font-family: ${defaultTheme.fontFamilies.Rubik}
   color:${defaultTheme.msColors.primaryTextcolor}
   fontSize: ${(props) => props.size}
   fontWeight: ${(props) => props.bold && 'bold'}
`;

export const MySpan = styled.span<{ size: number; bold?: boolean }>`
   font-family: ${defaultTheme.fontFamilies.Rubik}
   color:${defaultTheme.msColors.primaryTextcolor}
   fontSize: ${(props) => props.size}
   fontWeight: ${(props) => props.bold && 'bold'}
`;

export const CenterFlexDiv = styled('div')<{
    pd?: string;
    mg?: string;
    jstCont?: string;
    fill?: boolean;
    alnItms?: string;
}>`
    display: flex;
    justify-content: ${(props) => props.jstCont || 'center'};
    align-items: ${(props) => props.alnItms || 'center'};
    padding: ${(props) => props.pd || 0};
    margin: ${(props) => props.mg || 0};
    ${(props) =>
        props.fill &&
        css`
            width: 100%;
            height: 100%;
        `}
`;

export const FlexContainer = styled.div<{ style?: CSSObject }>`
    width: 100%;
    height: 100%;
    display: flex;
    ${({ style = {} }) => css(style)}
`;

const FullWidth = styled.div<{ style?: CSSObject }>`
    width: 100%;
    ${({ style = {} }) => css(style)}
`;

export const cs = {
    FullWidth,
};

export default { MyP, MySpan, FullWidth };
