import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Header = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #2196f3;
`;

const HeaderText = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    color: ${({ theme }) => theme.colors.blue};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
`;

const SpaceAround = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 90%;
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
`;

export default { Header, HeaderText, SpaceAround, Text };
