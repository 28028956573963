import { Button } from '@mui/material';
import React from 'react';
import { colors } from 'src/style/themes/defaultTheme';
import { IObject } from 'src/types/global';
import { OutlinedBtnVariant } from 'src/types/misc';

interface Props {
    isDisabled?: boolean;
    handleClick: any;
    variant?: OutlinedBtnVariant;
    sx?: IObject;
}

const outlinedSx = {
    fontFamily: 'Rubik',
    marginRight: '10px',
    color: colors.blue,
    border: `1px solid ${colors.blue}`,
};
const containedSx = {
    fontFamily: 'Rubik',
    marginRight: '10px',
    background: colors.blue,
};

const OutlinedBtn: React.FC<Props> = React.forwardRef(
    ({ isDisabled, handleClick, variant = 'outlined', sx = {}, children }) => {
        return (
            <Button
                disabled={isDisabled}
                onClick={handleClick}
                variant={variant}
                sx={variant === 'contained' ? { ...containedSx, ...sx } : { ...outlinedSx, ...sx }}
            >
                {children}
            </Button>
        );
    }
);

export default OutlinedBtn;
