import React from 'react';
import { useAppSelector } from 'src/store/hooks';
import { formSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';

export interface CourseData {
    code: string;
    idx: number;
}

/**
 * Returns the coursesOnForm and currAddressCode from the store.
 * @returns {Object} - An object containing the coursesOnForm and currAddressCode from the store.
 */
export const useCourseChoiceCardSelectors = () => {
    const coursesOnForm = useAppSelector((state: any) => formSelector(state).coursesOnForm);
    const currAddressCode = useAppSelector((state: any) => formSelector(state).currAddressCode);
    return { coursesOnForm, currAddressCode };
};
