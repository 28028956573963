import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userUUIDSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';
import { useHistory } from 'react-router-dom';

import { setErrorMessage, setLoginType as dispatchSetLoginType } from 'src/store/actions/loginAction';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { SingupType } from 'src/types/login';

import { setLoginType } from '../../../api/api';
import styles from './SingupOptions.style';

interface Props {
    name: string;
}

const SingupOptions: FC<Props> = ({ name }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userUUID = useSelector((state: IRootReducer) => userUUIDSelector(state));
    const onChangeLoginType = (payload: any) => dispatch(dispatchSetLoginType(payload));

    const onSelectQuickSingup = async () => {
        try {
            const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);

            if (!userUUID || !deviceToken) {
                //  dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
                alert(t('oopsSomethingWentWrongTryAgain'));
                return;
            }

            const httpResponse = await setLoginType({
                deviceToken,
                userUUID,
                autologin: SingupType.Quick,
            });

            if (httpResponse.response !== '0') return;

            history.push(routesEndpoints.HOME);
            onChangeLoginType(SingupType.Quick);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('onSelectQuickSingup', { error });
        }
    };

    const onOneTimeSingup = () => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);

        if (!userUUID || !deviceToken) {
            //  dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
            alert(t('oopsSomethingWentWrongTryAgain'));
            return;
        }
        onChangeLoginType(SingupType.OnTimeLogin);
        history.push(routesEndpoints.HOME);
    };

    const onClickLoginType = (type: SingupType) => {
        switch (type) {
            case SingupType.Quick:
                onSelectQuickSingup();
                break;
            case SingupType.PinCode:
                history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SIGNUP_WITH_PIN_CODE}`);
                break;
            case SingupType.User:
                history.push(`${routesEndpoints.ROOT_AUTH}${routesEndpoints.AUTH.SIGNUP_WITH_USER}`);
                break;
            case SingupType.OnTimeLogin:
                onOneTimeSingup();
                break;
            default:
                break;
        }
    };

    return (
        <styles.Container>
            <styles.TitleContianer>
                <styles.Title>{`${t('welcome')}`}</styles.Title>
                <styles.Text>{t('howDoYouWantToLogin')}</styles.Text>
            </styles.TitleContianer>
            <styles.CardsContainer>
                <styles.Card onClick={() => onClickLoginType(SingupType.Quick)}>
                    <styles.Text>{t('quickLogin')}</styles.Text>
                </styles.Card>
                <styles.Card onClick={() => onClickLoginType(SingupType.PinCode)}>
                    <styles.Text>{t('loginWithPinCode')}</styles.Text>
                </styles.Card>
                {/* <styles.Card onClick={() => onClickLoginType(SingupType.User)}>
               <styles.Text>{t('setPasswordAndUserName')}</styles.Text>
            </styles.Card> */}
                <styles.Card onClick={() => onClickLoginType(SingupType.OnTimeLogin)}>
                    <styles.Text>{t('onTimeLogin')}</styles.Text>
                </styles.Card>
            </styles.CardsContainer>
        </styles.Container>
    );
};

export default SingupOptions;
