import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateToken } from 'src/api/api';
import { setErrorCode, setTokenRefreshStatus } from 'src/store/actions/loginAction';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { PassengersErrorMessagesEnum } from 'src/types/global';
import { RefreshTokenStatus } from 'src/types/login';

const useRefreshToken = (): void => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { fcAccounts, refreshTokenStatus, token } = useSelector((state: IRootReducer) =>
        loginSelector(state)
    );

    useEffect(() => {
        if (!isLoading && (refreshTokenStatus === RefreshTokenStatus.Invalid || !refreshTokenStatus)) {
            const promises: Array<Promise<void>> = [];
            dispatch(setErrorCode(PassengersErrorMessagesEnum.GenralError));
            setIsLoading(true);
            fcAccounts.forEach(({ proxyUrl, contactUUID, dbUrl }) => {
                promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
            });

            Promise.all(promises).then(() => {
                setIsLoading(false);
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Ok));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTokenStatus, fcAccounts, token, dispatch]);
};

export default useRefreshToken;
