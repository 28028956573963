import { FormControlProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { DAYS_HE_SHORT } from '../../constants/dates';
import { MenuItemProps, MultipleDropDownProps } from './MultipleDropDown';

const formControlProp: FormControlProps = {
    variant: 'outlined',
    style: { width: '100%' },
    size: 'small',
};
const inputsStyle = { backgroundColor: 'white' };

type OnChangeAction<TValue> = (result: TValue[]) => {
    payload: any;
    type: string;
};

/**
 * A type that represents a set of select values.
 * @typedef {Object} SelectValues
 * @property {string} id - id for the name.
 * @property {string} value - name.
 */
export type SelectValues = { [id: string]: string };

export interface UseMultipleDropDownProps<TValue> {
    selectedValsKeys: TValue[];
    allValuesKeys: TValue[];
    onChangeAction: OnChangeAction<TValue>;
    labelText: string;
    allValuesMap: SelectValues;
    useShortText?: boolean;
    shortTextValuesMap?: SelectValues;
}

const ALL_VALS_KEY = 'all';

const useMultipleDropdownProps = <TValue>({
    selectedValsKeys,
    allValuesKeys,
    allValuesMap,
    onChangeAction,
    labelText,
    useShortText,
    shortTextValuesMap: shortTextValues,
}: UseMultipleDropDownProps<TValue>): MultipleDropDownProps => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;

    const handleChange = (event: any) => {
        const { value } = event.target;
        let result: TValue[];

        // -- selecting all values
        if (value === ALL_VALS_KEY && selectedValsKeys.length < allValuesKeys.length) {
            result = allValuesKeys;
        } else if (
            // -- un-selecting all values
            value === ALL_VALS_KEY &&
            selectedValsKeys.length === allValuesKeys.length
        ) {
            result = [];
        } else if (!selectedValsKeys.includes(value)) {
            // -- adding value to selected list
            const copy = [...selectedValsKeys];
            copy.push(value);
            result = copy;
        } else {
            // -- removing value from selected list
            const removed = selectedValsKeys.filter((currVal: TValue) => currVal !== value);
            result = removed;
        }
        dispatch(onChangeAction(result));
    };

    const generateMenuItems = (): MenuItemProps[] => {
        const menuItems = [
            ...allValuesKeys.map((key) => ({
                sx: { borderBottom: '1px solid #e5f6ff' },
                name: allValuesMap[String(key)],
                value: key,
                checked: selectedValsKeys.includes(key),
            })),
        ];

        if (!loggedUserDepartmentCode) {
            menuItems.unshift({
                name: t('all'),
                value: ALL_VALS_KEY as any,
                checked: selectedValsKeys.length === allValuesKeys.length,
                sx: { borderBottom: '1px solid #e5f6ff' },
            });
        }
        return menuItems;
    };

    return {
        label: labelText,
        value: selectedValsKeys,
        menuItems: generateMenuItems(),
        onChange: handleChange,
        formControlProp,
        style: inputsStyle,
        renderValue: (selected) => {
            if (Array.isArray(selected) && selected.length) {
                return selected
                    .map((key: string | number) =>
                        useShortText && shortTextValues ? shortTextValues[String(key)] : allValuesMap[key]
                    )
                    .join(', ');
            }
        },
    };
};

export const hooks = {
    useMultipleDropdownProps,
};
