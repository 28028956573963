import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const StyleTypography = styled(Typography)``;

const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const AddressConatiner = styled.div`
    display: flex;
    overflow-x: hidden;
    padding-right: 10px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin-top: 1em;
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.05em;
    margin: 0 7px;
`;

const StyledDetailsDisplay = styled.div`
    border-radius: 15px;
    margin: 0 15px 0 15px;
`;

export default {
    StyledDetailsDisplay,
    Header,
    StyleTypography,
    AddressConatiner,
    Text,
};
