import { colors } from 'src/style/themes/defaultTheme';
import styled, { css } from 'styled-components';

const StationsLstContainer = styled.div<{}>`
    width: 100%;
    height: 67vh;
    display: flex;
    flex-direction: column;
    // gap: 24px;
    padding-left: 20px;
    padding-right: 12px;
    /* border-right: 1px solid #c5e5ff; */
    overflow: auto;
`;

const outlinedBtnSx = {
    color: colors.blue,
    height: '40px',
    fontSize: window.screen.width > 1550 ? '14px' : '12px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
};

const JiraBtn = styled.button<{ isDisabled?: boolean }>`
    :hover {
        background: #e3e3e3;
    }
    background: white;
    border: 1px solid #c4c4c4;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const TextContainer = styled.div<{}>`
    display: flex;
    justify-content: center;
    padding: 3px 0;
    gap: 6px;
    background: #ffedb1;
    border-radius: 4px;
    min-width: 144px;
    font-size: 12px;
`;
const X = styled.span<{}>`
    :hover {
        cursor: pointer;
    }
`;

const Row1 = styled.div<{}>`
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
`;

const StationInputContainer = styled.div<{ error: boolean; removeBottomPadding?: boolean }>`
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    :hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
    }
    :active {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
    }
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    align-items: center;
    margin-bottom: 24px;
    ${({ error }) =>
        css`
            border: 1px solid ${error ? 'red' : '#ffffff'};
        `}
    ${({ removeBottomPadding }) =>
        removeBottomPadding &&
        css`
            padding-bottom: 3px;
        `}
`;

export const StationsPanelStyles = {
    StationsLstContainer,
    outlinedBtnSx,
    JiraBtn,
    TextContainer,
    X,
    StationInputContainer,
    Row1,
};
