import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReqStatus } from 'src/api/types';
import { initConnect, initConnectSuccess } from 'src/store/actions/loginAction';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { ILoginState } from 'src/store/type';
import { StorageKeys } from 'src/types/global';
import { SingupType } from 'src/types/login';
import { getLocalStorageValue, getSessionStorageValue } from 'src/utilis/utilis';
import { routesEndpoints } from '../routes_endpoints';

const useInitalRoute = (): { isAuthenticated: ReqStatus | null } => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onInitConnect = (payload: { deviceToken: string }) => dispatch(initConnect(payload));
    const onOnTimeConnect = (payload: ILoginState) => dispatch(initConnectSuccess(payload));
    const { isAuthenticated, loginType, isIgnoreInitialRoute } = useSelector((state: IRootReducer) =>
        loginSelector(state)
    );

    useEffect(() => {
        // if(history.location.pathname === '')
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);

        const onTimeLoginObject: ILoginState = JSON.parse(
            getSessionStorageValue(StorageKeys.OnTimeLogin) || '{}'
        );

        if (onTimeLoginObject && onTimeLoginObject.loginType === SingupType.OnTimeLogin) {
            onOnTimeConnect(onTimeLoginObject);
            return;
        }

        onInitConnect({ deviceToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isIgnoreInitialRoute) return;

        const authRoutes = routesEndpoints.AUTH;
        if (loginType) {
            switch (loginType) {
                case SingupType.Quick:
                    history.push(`${routesEndpoints.HOME}`);
                    break;
                case SingupType.PinCode:
                    history.push(`${routesEndpoints.ROOT_AUTH}${authRoutes.LOGIN_WITH_PIN_CODE}`);
                    break;
                case SingupType.User:
                    history.push(`${routesEndpoints.ROOT_AUTH}${authRoutes.LOGIN_WITH_USER}`);
                    break;
                case SingupType.OnTimeLogin:
                    history.push(`${routesEndpoints.HOME}`);
                    break;
                default:
                    history.push(`${routesEndpoints.ROOT_AUTH}${authRoutes.SINGUP}`);
                    break;
            }
        }
    }, [loginType, isAuthenticated, history, isIgnoreInitialRoute]);

    return { isAuthenticated };
};

export default useInitalRoute;
