import React, { useCallback, useState } from 'react';

const useDelay = <T>(initialState: T) => {
    const [delay, setDelay] = useState(initialState);

    const setDelayWithTimeout = useCallback(
        (newValue: typeof initialState, delayInMilliseconds: number) =>
            setTimeout(() => setDelay(newValue), delayInMilliseconds),
        [initialState]
    );

    return { delay, setDelay, setDelayWithTimeout };
};

export default useDelay;
