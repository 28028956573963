import React from 'react';
import NakedBtn from 'src/components/buttons/NakedBtn';
import ApiGeneratedDataWrapper from 'src/components/Wrappers/ApiGeneratedDataWrapper';

import useCommons from 'src/hooks/common/useCommons';
import {
    addSettingsBoxAction,
    coursesBuildingSelectors,
    timesSettingsTabSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import TimeSettingsBox from './TimeSettingBox';

const AddTimeSettingBtn = () => {
    const { t, dispatch } = useCommons();

    const onClick = () => {
        dispatch(addSettingsBoxAction(undefined));
    };

    return (
        <div style={{ textAlign: 'right', paddingBottom: '8px' }}>
            <NakedBtn type="add" onClick={onClick} text={t('addingRidesTimes')} />
        </div>
    );
};

const TimesSettingsPanel: React.FC<{}> = ({}) => {
    const { useRootAppSelector, dispatch } = useCommons();
    const timesSettingsTabState = useRootAppSelector(timesSettingsTabSelector);

    const apiCallStatus = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseTimesSettings.status
    );

    // useTabValidations();

    return (
        <div style={{ height: '72vh', width: '100%', overflow: 'auto', paddingLeft: '20px' }}>
            <ApiGeneratedDataWrapper apiCallStatus={apiCallStatus}>
                <AddTimeSettingBtn />
                <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                    {timesSettingsTabState.settingsList.map((setting) =>
                        setting.isActive ? <TimeSettingsBox boxData={setting} /> : null
                    )}
                </div>
            </ApiGeneratedDataWrapper>
        </div>
    );
};

export default TimesSettingsPanel;

// const useTabValidations = () => {
//     const { useRootAppSelector, dispatch } = useCommons();
//     const timesSettingsTabState = useRootAppSelector(timesSettingsTabSelector);

//     const activeTimeSettingBoxesCount = React.useMemo(() => {
//         return timesSettingsTabState.settingsList.filter((box) => box.isActive).length;
//     }, [timesSettingsTabState.settingsList]);

//     // if have no active times settings, dispatch error action
//     React.useEffect(() => {
//         dispatch(
//             updateTimesSettingsTabValidAction({
//                 actionType: activeTimeSettingBoxesCount === 0 ? 'add' : 'remove',
//                 errorType: 'invalidSettingsCount',
//             })
//         );
//     }, [dispatch, activeTimeSettingBoxesCount]);
// };
