import moment, { Moment } from 'moment';
import { SHORT, RELATIVE } from 'src/constants/dates';
import { getStartDateByTargetDayNum } from './utilis';

const isDate = (value: unknown): boolean => {
    return moment.isDate(value);
};

// is valid date check
const isValidDate = (date: unknown): date is Date => {
    try {
        return date instanceof Date ? moment(date).isValid() : false;
    } catch (e) {
        return false;
    }
};

const getDatesBetween = (startDate: Date, EndDate: Date, includeEndDate?: boolean): Array<Date> => {
    const a = moment(startDate);
    const b = moment(EndDate);

    const result = [];
    for (let m = moment(a); m.isBefore(b, 'day'); m.add(1, 'days')) {
        result.push(m.toDate());
    }
    if (includeEndDate) {
        result.push(EndDate);
    }
    return result;
};

/**
 * Checks if two dates are the same.
 * @param {Moment | Date} dateOne - the first date to compare
 * @param {Moment | Date} dateTwo - the second date to compare
 * @returns {boolean} - true if the dates are the same, false otherwise
 */
const isSameDay = (date1: Moment | Date, date2: Moment | Date): boolean => {
    return moment(date1).isSame(moment(date2), 'day');
};

/**
 * Takes in a date or string and returns a date object.
 * @param {Moment | string} date - the date to format
 * @param {moment.MomentFormatSpecification | null} format - the format to use when parsing the date
 * @returns {Date | -1} - the date object, or -1 if the date is invalid
 */
const dateify = (date: Moment | string, format: moment.MomentFormatSpecification | null): Date | -1 => {
    if (moment.isMoment(date)) {
        return date.toDate();
    }
    if (typeof date === 'string' && format) {
        return moment(date, format).toDate();
    }
    return -1;
};

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
function getDaysInMonthUTC(month: number, year: number): Date[] {
    const date = new Date(Date.UTC(year, month, 1));
    const days: Date[] = [];
    while (date.getUTCMonth() === month) {
        days.push(new Date(date));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

/**
 * Returns the start and end dates of the week that the given date falls in.
 * @param {number} targetNum - the target day number of the week.
 * @param {Moment | Date} currDate - the current date.
 * @returns {Object} - an object with the start and end dates of the week.
 */
const getWeekStartAndEndDates: (
    targetNum: number,
    currDate: Moment | Date
) => {
    start: Date;
    end: Date;
} = (targetNum, currDate) => {
    if (targetNum === -1) {
        return {
            start: moment(currDate).toDate(),
            end: moment(currDate).toDate(),
        };
    }
    const start = getStartDateByTargetDayNum(targetNum, moment(currDate).day(), moment(currDate).toDate());
    const end = moment(start).clone().add(6, 'd').toDate();
    return { start, end };
};

/**
 * Takes in a date and returns a string of the date in the wanted format OR RELATIVE if not given.
 * @param {Moment | Date} date - the date to format
 * @param {string} [wantedFormat] - the format to return the date in.
 * @returns {string} - the formatted date
 */
const stringifyDate = (date: Moment | Date, wantedFormat?: string): string =>
    moment(date).format(wantedFormat || RELATIVE);

/**
 * Checks if the given date string is in the format of a relative date ( YYYY-MM-DD ).
 * @param {string} dateString - the date string to check
 * @returns {boolean} - true if the date string is in the format of a relative date, false otherwise.
 */
const isRelativeDate = (dateString: string): boolean => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;

    const MONTH_INDEX = 1;
    const monthNum = dateString.split('-')[MONTH_INDEX];

    if (+monthNum > 12) return false;
    return true;
};

const getTimeBetweenDates = (
    start: Date | Moment,
    end: Date | Moment,
    type?: 'minutes' | 'seconds'
): number => {
    const date1 = moment(start).toDate();
    const date2 = moment(end).toDate();

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    if (type === 'minutes') {
        return diffInTime / 1000 / 60;
    }
    return diffInTime / 1000;
};

const dateHelpers = {
    stringifyDate,
    isDate,
    getDatesBetween,
    isSameDay,
    dateify,
    getDaysInMonthUTC,
    getWeekStartAndEndDates,
    isRelativeDate,
    isValidDate,
    getTimeBetweenDates,
};

export default dateHelpers;
