import { Position } from 'src/types/global';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div<{
    position: keyof typeof Position;
}>`
    display: flex;
    align-items: center;
    flex-direction: ${({ position }) => (position === 'Vertical' ? 'column-reverse' : 'row')};
    max-width: 80px;
    width: 60px;
`;

const ProgressKeyframe = keyframes`
0% { height: 0 }
100% { top: 100%}
`;
export const Progress = styled.div<{
    count: number;
    color: string;
    position: keyof typeof Position;
    withBottomRadius: boolean;
    height: number;
}>`
    background-color: ${({ color }) => color};
    width: ${({ count, position }) => `${position === 'Horizontal' ? `${count * 10}%` : '13px'}`};

    border-radius: ${({ withBottomRadius }) => (withBottomRadius ? '0 0 4px 4px' : '4px 4px 0 0')};
    height: ${({ height }) => `${height}%`};
    animation: ${ProgressKeyframe};
    animation-duration: 1s;
`;

export const Count = styled.span`
    margin: 0 5px;
    font-weight: 500;
`;

export const Label = styled.span`
    font-size: 14px;
    min-height: 40px;
    max-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 5px 5px 0 5px;
    :hover {
        display: block;
        overflow: auto;
    }
`;

export const TooltipLable = styled.div<{
    isPick?: boolean;
}>`
    &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-left: 5px;
        border-radius: 5px;
        background-color: ${({ isPick, theme }) => (isPick ? theme.colors.blueOcean : theme.colors.blue)};
    }
`;
