import React, { CSSProperties, MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled, { css } from 'styled-components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';
import { lineSelector } from 'src/store/selectores/linesSelectores';

const Text = styled.p<{}>`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;
    padding-left: 3px;
    color: #494949;
`;

const Container = styled.div<{ style?: CSSProperties }>`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const useExpand = () => {
    const selectedLine = useSelector((state) => lineSelector(state));

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsExpanded(false);
    }, [selectedLine]);

    const onClick = () => {
        setIsExpanded(!isExpanded);
    };

    return { isExpanded, onClick };
};
interface Props {
    onClick: Function;
    isExpanded: boolean;
}
const AccordionExpandIcon = ({ onClick, isExpanded }: Props) => {
    const { t } = useTranslation();

    return (
        <Container onClick={onClick as MouseEventHandler<HTMLDivElement>}>
            <Text>{t('toExtraDetails')}</Text>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Container>
    );
};

export default AccordionExpandIcon;
