import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { onlyDigitRegex } from 'src/utilis/utilis';
import { inputLabelStyle } from 'src/screens/PassengerManger/components/PassengerForm/styles/styles';

export interface IPhoneNumber {
    mobilePhone?: string;
    supplierPhoneNumber?: string;
    phoneNumber?: string;
    size?: 'small' | 'medium';
    labal: React.ReactNode | string;
    isRequired: boolean;
    onChangePhoneNumber: (item: string) => void;
    onChangeSuplliernumber?: (item: string) => void;
    style?: any;
}

const PhoneNumber: FC<IPhoneNumber> = ({
    supplierPhoneNumber = '',
    phoneNumber = '',
    size,
    labal,
    isRequired,
    style,
    onChangePhoneNumber,
    onChangeSuplliernumber,
}: IPhoneNumber) => {
    const mobileNumberProp: TextFieldProps = {
        value: phoneNumber || '',
        size: size || 'small',
        // inputProps: { maxLength: 9 },
        style: { width: '100%' },
        required: isRequired,
        variant: 'outlined',
        onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            if (!target.value || onlyDigitRegex.test(target.value)) onChangePhoneNumber(target.value);
        },
    };

    return (
        <Grid container spacing={1} justifyContent="flex-start" alignItems="center" style={style || {}}>
            <Grid item xs={12}>
                <div style={{ ...inputLabelStyle, margin: 0 }}>{labal}</div>
            </Grid>
            <Grid item xs={12}>
                <TextField {...mobileNumberProp} />
            </Grid>
        </Grid>
    );
};

export default PhoneNumber;
