import { IPassenger } from 'src/api/passengerMangerApi/types';
import { IRootReducer } from '../reducers';
import { IPassengerState } from '../reducers/passengerReducer';

export const passengerRootDataSelector = (state: IRootReducer): IPassengerState => state.passengerReudcer;

export const passengerSelector = (state: IRootReducer): Array<IPassenger> =>
    passengerRootDataSelector(state).passengers;

export const passengerCitySelector = (state: IRootReducer): Array<string> =>
    passengerRootDataSelector(state).citys;

export const clientIdSelector = (state: IRootReducer): string | number | undefined =>
    passengerRootDataSelector(state).clientId;

export const modefiedPassengerSelector = (state: IRootReducer): IPassenger | undefined =>
    passengerRootDataSelector(state).modefiedPassenger;

// Selector for handling the UI for editting / adding a passenger
interface ModifiedPassengerUiState {
    selectedPassengerPasscode: string | null | undefined;
    modifyingPassenger: boolean;
    addingPassenger: boolean;
}
export const modifyingOrAddingPassengerUiSelecter = (state: IRootReducer): ModifiedPassengerUiState => {
    const { selectedPassengers } = passengerRootDataSelector(state).ui;
    const selectedPassengerPasscode = String(selectedPassengers.length === 1 ? selectedPassengers[0] : null);

    const modifyingPassengerUiState: ModifiedPassengerUiState = {
        selectedPassengerPasscode,
        modifyingPassenger: passengerRootDataSelector(state).ui.modifyingPassenger,
        addingPassenger: passengerRootDataSelector(state).ui.addingPassenger,
    };
    return modifyingPassengerUiState;
};

export const passengersUISelector = (state: IRootReducer) => {
    return state.passengerReudcer.ui;
};

export const selectedPassengerPasscodeSelector = (state: IRootReducer) => {
    const { selectedPassengers } = passengersUISelector(state);
    if (selectedPassengers.length === 1) {
        return selectedPassengers[0];
    }
    return null;
};
