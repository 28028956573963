import { useTranslation } from 'react-i18next';
import React from 'react';
import { CSSObject } from 'styled-components';

interface Props {
    show?: boolean;
    style?: CSSObject;
    withFiltered?: boolean;
}

const NoRecordsText = ({ show = true, style = {}, withFiltered = true }: Props) => {
    const { t } = useTranslation();
    return show ? <p style={style}>{withFiltered ? t('noRecordWasFound') : t('noRecords')}</p> : <></>;
};

export default NoRecordsText;
