import React, { useCallback } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { onErrorAction } from 'src/store/slices/reports/reportsSlice';

const useErrorHandling = (): {
    dispatchErrorAction: (e: unknown, msg?: string | undefined, details?: {}) => void;
} => {
    const dispatch = useAppDispatch();
    const dispatchErrorAction = useCallback(
        (e: unknown, msg?: string, details = {}) => {
            const error = e as any;
            dispatch(
                onErrorAction({
                    msg: error?.message || msg || 'unknown error',
                    details,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return { dispatchErrorAction };
};

export default useErrorHandling;
