/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import styles from './styles';
import { uiSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { useRootAppSelector } from 'src/store/hooks';
import { Coords } from 'google-map-react';
import { DEFAULT_ZOOM, mapHelpers } from '../utils/common';

export const useTopViewBtn = ({
    setZoom,
    setCenter,
    getMapCenter,
}: {
    setZoom: (zoom: number) => void;
    setCenter: (center: Coords) => void;
    getMapCenter: () => Coords;
}) => {
    const fieldsCopy = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map.fieldsCopy);

    const toggleFullView = (on: boolean) => {
        if (on) {
            if (fieldsCopy.length >= 2) {
                const distanceBetweenEdges = mapHelpers.getDistanceBetweenEdgeCoords(
                    fieldsCopy[0],
                    fieldsCopy[fieldsCopy.length - 1]
                );

                const newZoom = mapHelpers.calcTopViewZoom(distanceBetweenEdges) + 1;

                setZoom(newZoom);
                setCenter(getMapCenter());
                return;
            }

            setZoom(DEFAULT_ZOOM);
        }
    };

    // set zoom to on
    // Dont think this is necessary, however, i dont want to touch this
    React.useEffect(() => {
        return () => {
            setZoom(DEFAULT_ZOOM + 1);
        };
    }, []);

    return { toggleFullView, setZoom };
};

const TopViewBtn: React.FC<{ toggle: (on: boolean) => void }> = ({ toggle }) => {
    return (
        <styles.ExpandMapIcon
            style={{ zIndex: 999, left: '46px', width: '100px' }}
            onClick={() => {
                toggle(true);
            }}
        >
            <p style={{ fontFamily: 'Rubik', fontSize: '16px' }}>מבט על</p>
        </styles.ExpandMapIcon>
    );
};

export default TopViewBtn;
