import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {}

const styles = {
    Container: styled.div`
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    `,
    Dvider: styled.div`
        height: 50%;
        width: 2px;
        background-color: ${({ theme }) => theme.colors.black};
    `,
    Text: styled.p<{ disabled?: boolean }>`
        margin: 0;
        font-family: ${({ theme }) => theme.fontFamilies.Rubik};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: right;
        opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    `,
};
const Languages = (props: Props) => {
    const { t } = useTranslation();
    return (
        <styles.Container>
            <styles.Text>{t('he')}</styles.Text>
            <styles.Dvider />
            <styles.Text disabled>{t('en')}</styles.Text>
        </styles.Container>
    );
};

export default Languages;
