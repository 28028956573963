import React, { useEffect, useState } from 'react';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { IDateRange } from 'src/types/global';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { IPayloadStatistics } from 'src/types/manualOrders/api.types';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useAppDispatch } from 'src/store/hooks';
import {
    setWidgetStatisticsAction,
    updateWidgetsStatisticsStatusAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import helpers, { useStatisticsApiReq } from './helpers';
import { FilterBy, IProgressData } from '../../types';

export interface ChartStatistics {
    data: IProgressData[];
    max: number;
    datesRange: string;
}
const useSummaryChart = (
    selectedDate: Date,
    filterBy: FilterBy,
    clientCode: number,
    selectedWeek: IDateRange,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>,
    selectedDepartmentCode: string,
    allDepartmentsCodes: string[]
) => {
    const isFirstRender = useIsFirstRender();
    const dispatch = useAppDispatch();

    const statisticsApiReq = useStatisticsApiReq({
        filterBy,
    });

    const [daylyStatisticsData, setDaylyStatistics] = useState<ChartStatistics | null>(null);
    const [weeklyStatisticsData, setWeeklyStatistics] = useState<ChartStatistics | null>(null);
    const [monthlyStatisticsData, setMonthlyStatistics] = useState<ChartStatistics | null>(null);

    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));

    const [dailyPayload, setDailyPayload] = useState<IPayloadStatistics | null>(null);
    const [weeklyPayload, setWeeklyPayload] = useState<IPayloadStatistics | null>(null);
    const [monthlyPayload, setMonthlyPayload] = useState<IPayloadStatistics | null>(null);

    const reqStats = async (display: number) => {
        if (!dailyPayload || !weeklyPayload || !monthlyPayload) return;

        setIsFetching(true);
        try {
            if (display === 1) {
                dispatch(updateWidgetsStatisticsStatusAction({ dateRange: 'daily', status: 'LOADING' }));

                const resDaylyStatistics = await statisticsApiReq.getStatistics(dailyPayload);

                dispatch(setWidgetStatisticsAction({ dateRange: 'daily', data: resDaylyStatistics }));

                setDaylyStatistics(helpers.normalizeStatistic(resDaylyStatistics));

                dispatch(updateWidgetsStatisticsStatusAction({ dateRange: 'daily', status: 'SUCCESS' }));
            } else if (display === 2) {
                dispatch(updateWidgetsStatisticsStatusAction({ dateRange: 'weekly', status: 'LOADING' }));

                const resWeeklyStatistics = await statisticsApiReq.getStatistics(weeklyPayload);

                dispatch(setWidgetStatisticsAction({ dateRange: 'weekly', data: resWeeklyStatistics }));

                setWeeklyStatistics(helpers.normalizeStatistic(resWeeklyStatistics));

                dispatch(updateWidgetsStatisticsStatusAction({ dateRange: 'weekly', status: 'SUCCESS' }));
            } else if (display === 3) {
                const resMonthlyStatistics = await statisticsApiReq.getStatistics(monthlyPayload);

                setMonthlyStatistics(helpers.normalizeStatistic(resMonthlyStatistics));
            }

            setIsFetching(false);
        } catch (error) {
            console.log({ error });
            setIsFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const selectedWeekJSON = JSON.stringify(selectedWeek);

    useEffect(() => {
        const { dbUrl } = selectedFcAccount || {};

        if (!dbUrl || !token || !clientCode || !selectedDepartmentCode || !allDepartmentsCodes.length) return;
        const basePayloadArgs = {
            token,
            selectedDate,
            clientCode,
            selectedWeekArg: selectedWeek,
            selectedDepartmentCode,
            allDepartmentsCodes,
        };

        const updatedDailyPayload = helpers.buildPayload(dbUrl, {
            ...basePayloadArgs,
            display: 1,
        });

        if (!_.isEqual(updatedDailyPayload, dailyPayload)) {
            setDailyPayload(updatedDailyPayload);
        }

        const updatedWeeklyPayload = helpers.buildPayload(dbUrl, {
            ...basePayloadArgs,
            display: 2,
        });

        if (!_.isEqual(updatedWeeklyPayload, weeklyPayload)) {
            setWeeklyPayload(updatedWeeklyPayload);
        }

        const updatedMonthlyPayload = helpers.buildPayload(dbUrl, {
            ...basePayloadArgs,
            display: 3,
        });

        if (!_.isEqual(updatedMonthlyPayload, monthlyPayload)) {
            setMonthlyPayload(updatedMonthlyPayload);
        }
    }, [
        clientCode,
        selectedDepartmentCode,
        token,
        selectedDate,
        allDepartmentsCodes,
        selectedFcAccount?.dbUrl,
    ]);

    useEffect(() => {
        if (!isFirstRender) reqStats(1);
    }, [JSON.stringify(dailyPayload), filterBy]);

    useEffect(() => {
        if (!isFirstRender) reqStats(2);
    }, [JSON.stringify(weeklyPayload), filterBy]);

    useEffect(() => {
        if (!isFirstRender) reqStats(3);
    }, [JSON.stringify(monthlyPayload), filterBy]);

    return {
        daylyStatistics: daylyStatisticsData,
        weeklyStatistics: weeklyStatisticsData,
        monthlyStatistics: monthlyStatisticsData,
    };
};

export default useSummaryChart;
