export enum AddressInputComponentIds {
    StartAddress = 'start-address-id',
    EndAddress = 'end-address-id',
}
export enum AddressInputType {
    Manual = 'MANUAL',
    AutoComplete = 'AUTO_COMPLETE',
}
export enum AutoCompleteInputStatus {
    On = 'ON',
    Off = 'OFF',
}
