import styled from 'styled-components';
import MAvatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuItem from '@material-ui/core/MenuItem';

const Container = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0 0 0;
`;

const BORDER_RADIUS = 28;

const AvatarContianer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.white};
    width: ${BORDER_RADIUS * 2}px;
    height: ${BORDER_RADIUS * 2}px;
    border-radius: ${BORDER_RADIUS}px;
`;

const Avatar = styled(MAvatar)``;

const Btn = styled(ButtonBase)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Text = styled.p`
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
`;

const Item = styled(MenuItem)`
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
`;

const ItemText = styled.p<{ isLogout?: boolean }>`
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;
    color: ${({ isLogout, theme }) => (isLogout ? theme.colors.blue : theme.colors.black)};
`;

const LanguageContainer = styled.div`
    width: 100%;
    display: flex;
    width: 100%;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
`;

const Divider = styled.div`
    width: 100%;
    display: flex;
`;

export default {
    Container,
    Avatar,
    AvatarContianer,
    Btn,
    Text,
    Item,
    ItemText,
    LanguageContainer,
};
