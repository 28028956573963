/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function GoBackArrowIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 0L11.5325 2.4675L21.2975 12.25H0V15.75H21.2975L11.5325 25.5325L14 28L28 14L14 0Z"
                fill={color}
            />
        </svg>
    );
}

export default GoBackArrowIcon;
