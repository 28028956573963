import React, { useCallback, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeSelection, onUnSelectPassenger } from 'src/store/actions/passengerAction';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';

import {
    getSelectedState,
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridItemChangeEvent,
    GridRowClickEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import {
    State,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
    process,
} from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import KendoGrid, { TableProp } from 'src/components/KendoGrid/KendoGrid';
import KendoDeleteEditeCell from 'src/components/KendoGridCutomeColumn/KendoDeleteEditeCell';
import { StyledKendoGridContainer } from 'src/screens/PassengerManger/components/Passengers/Passengers.style';
import useSimulationColumns from '../../hooks/useSimulationColumns';
import { selectSimulationRouteAction } from 'src/store/slices/optimizations/optimizationsSlice';

const dataItemKey = 'passCode';
const selectedField = 'isSelected';
const idGetter = getter(dataItemKey);
const PAGE_SIZE = 25;

const getDataArr = (data: any): any[] => {
    try {
        if (Array.isArray(data)) {
            return data as any[];
        }
        return [];
    } catch (error) {
        console.log();
        return [];
    }
};

interface IWaypointStockGrid {
    data: any;
}

const SimulationGrid: FunctionComponent<IWaypointStockGrid> = ({ data }: IWaypointStockGrid) => {
    const dispatch = useDispatch();

    // Local states
    const [take, setTake] = useState<number>(PAGE_SIZE);
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [hoverRowRouteId, setHoverRowRouteId] = useState<null | string>(null);

    // Selectors

    const onSelectionChange = (ev: GridSelectionChangeEvent) => {
        if (ev.dataItem) {
            dispatch(selectSimulationRouteAction({ routeId: ev.dataItem.localId }));
        }
    };

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        const isSelected = event.nativeEvent.target.checked;
        console.log('onHeaderSelectionChange CALLED', isSelected);
    }, []);

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setTake(event.dataState?.take || PAGE_SIZE);
            setSkip(event.dataState?.skip || 0);
            setSort(event.dataState?.sort || []);
        },
        [setTake, setSkip, setSort]
    );

    // Miscellaneous
    const dataState: State = {
        take,
        skip,
        sort,
    };

    const processedData = useMemo(() => {
        return process(getDataArr(data), dataState);
    }, [data]);

    // & Handle the unselecting of passengers if double clicked
    const handleRowDoubleClick = (e: any) => {
        console.log('Row Double clicked');
    };

    const onRowClick = (ev: GridRowClickEvent) => {
        console.log(ev.dataItem);

        if (ev.dataItem) {
            dispatch(selectSimulationRouteAction({ routeId: ev.dataItem.localId }));
        }
    };
    const { columns } = useSimulationColumns({ hoverRowRouteId });

    // on save edit > setEditedDAta(updated)

    // Building the passengers table
    const tableProp: TableProp = {
        columns,
        className: 'k-rtl',
        style: {
            height: '100%',
            width: '100%',
            borderRadius: '0.3em',
            maxHeight: '76vh',
            // pointerEvents: isFormOpen ? 'none' : 'auto',
        },
        wrapperDivStyle: {
            height: '100%',
            width: '100%',
            paddingBottom: '30px',
        },
        selectedField,
        selectable: {
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
        },
        onRowDoubleClick: handleRowDoubleClick,
        onSelectionChange,
        onHeaderSelectionChange,
        onRowClick,
        dataItemKey,
        rowHeight: 50,
        navigatable: false,
        data: processedData,
        editField: 'inEdit',
        total: processedData.total,
        pageSize: PAGE_SIZE,
        skip,
        sort,
        sortable: true,
        resizable: true,
        reorderable: true,
        scrollable: 'virtual',
        onDataStateChange,
        rowRender: (trElement, props) => {
            const { dataItem } = props;

            return React.cloneElement(trElement, {
                onMouseLeave: () => {
                    setHoverRowRouteId(null);
                },
                onMouseEnter: () => {
                    setHoverRowRouteId(dataItem.localId);
                },
            } as any);
        },
    };

    return (
        <StyledKendoGridContainer className="line-grid-container">
            <KendoGrid {...tableProp} />
        </StyledKendoGridContainer>
    );
};

export default SimulationGrid;
