import React, { FC, useEffect, useState } from 'react';
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber';
import { useTranslation } from 'react-i18next';
import AddBtn from 'src/components/AddBtn/AddBtn';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@material-ui/icons/Add';
import MyButton from 'src/components/MyButton/MyButton';
import { useDispatch } from 'react-redux';
import { changedForm } from 'src/store/actions/passengerAction';
import { btnsContainer, phonesBoxContainer, phonesInputsContainer } from './styledCss';

type Props = {
    modefiedPassenger: any;
    onChangePhoneNumber: any;
    onChangePhone1: any;
    setModefiedPassenger: any;
};

const PhonesBox: FC<Props> = ({
    modefiedPassenger,
    onChangePhoneNumber,
    onChangePhone1,
    setModefiedPassenger,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [phonesCount, setPhonesCount] = useState(1);
    // const [addPhoneInput, setAddPhoneInput] = useState(false);

    useEffect(() => {
        setPhonesCount(modefiedPassenger.phone1 ? 2 : 1);
    }, [modefiedPassenger.phone1]);

    const addBtnProps = {
        handleClick: () => setPhonesCount((prev) => prev + 1),
        disabled: phonesCount === 2,
        text: t('addPhone'),
        Icon: AddIcon,
    };

    const handleDeletePhone1 = () => {
        setPhonesCount((prev) => prev - 1);
        if (!modefiedPassenger.phone1) return;
        dispatch(changedForm());
        setModefiedPassenger({
            ...modefiedPassenger,
            supplierphone1: '',
            noSupplierPhone1: '',
            isDirty: true,
        });
    };
    const removeBtnProps = {
        handleClick: handleDeletePhone1,
        disabled: phonesCount === 1,
        text: t('removePhone'),
        Icon: RemoveIcon,
    };

    return (
        <div style={phonesBoxContainer}>
            <div style={phonesInputsContainer}>
                <PhoneNumber
                    // style={{ width: '100%' }}
                    phoneNumber={modefiedPassenger.mobilePhone}
                    isRequired={false}
                    labal={t('mobilePhone')}
                    onChangePhoneNumber={onChangePhoneNumber('phoneNumber')}
                />
                {phonesCount === 2 && (
                    <PhoneNumber
                        phoneNumber={modefiedPassenger.phone1}
                        isRequired={false}
                        labal={t('anotherPhone')}
                        onChangePhoneNumber={onChangePhone1('phoneNumber')}
                    />
                )}
            </div>
            <div style={btnsContainer}>
                <MyButton {...addBtnProps} />
                {phonesCount === 2 && <MyButton {...removeBtnProps} />}
            </div>
        </div>
    );
};

export default PhonesBox;
