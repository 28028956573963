import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    rideSettingsSelectors as selectors,
    rideSettingsActions,
} from 'src/store/slices/rideSettings/rideSettings';
import { AccordionExpansion, Endpoints } from 'src/types/rideSettings/components/types.screen';
import { SettingTypes, SlSettingTypes } from 'src/types/rideSettings/store/types.store';
import actions from 'src/store/actions/rideSettings/rideSettingsActions';

const getPayload: (type: SlSettingTypes) => {
    settingType: SettingTypes;
    submitEndpoint: Endpoints;
} = (type) => {
    switch (type) {
        case 'generalSettings':
            return {
                settingType: SettingTypes.GeneralSettings,
                submitEndpoint: Endpoints.setGeneralSettings,
            };

        default:
            return {
                settingType: SettingTypes.PermissionManagement,
                submitEndpoint: Endpoints.setPermMng,
            };
    }
};

export const useAccordionExpansion: () => AccordionExpansion = () => {
    const dispatch = useAppDispatch();

    const accordionExpansion = useRootAppSelector(
        (state) => selectors.selectUi(state).settingsAccordionsExpansion
    );

    const currSettingBeingEdited = useRootAppSelector(
        (state) => selectors.formSelector(state).ui.currentForm
    );

    const isExpanded = (type: SlSettingTypes): boolean => accordionExpansion[type];

    const toggleExpansion = (type: SlSettingTypes, newState: 'close' | 'open'): void => {
        if (newState === 'open') {
            dispatch(actions.editSettingClick(getPayload(type)));
            dispatch(actions.onOpenForm({ isOnEditMode: true, formType: type }));
        } else if (currSettingBeingEdited === type) {
            dispatch(rideSettingsActions.onCloseForm());
        }

        dispatch(actions.setSettingsAccordionExpansion({ type, newState }));
    };

    const closeAllAccordions = (): void => {
        dispatch(actions.setSettingsAccordionExpansion({ type: null, newState: 'closeAll' }));
    };

    return { toggleExpansion, isExpanded, closeAllAccordions };
};
