/* eslint-disable no-prototype-builtins */
import { DirectionsRendererProps } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    injectStationsOnEditAction,
    setFormEndAddressAction,
    setFormStartAddressAction,
    setFormStationsAction,
    setIsDirectionsTriggeredFromEditAction,
} from 'src/store/slices/lines/linesSlice';
import { fullFormHooks } from '../components/AddLineForm/components/formSections/hooks.FullForm';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

export function isAllObjectsContainsCoords(array: any) {
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (
            obj === null ||
            !obj.hasOwnProperty('lat') ||
            !obj.hasOwnProperty('lng') ||
            !obj.lat ||
            !obj.lng ||
            (obj.lat === 0 && obj.lng === 0)
        ) {
            return false;
        }
    }
    return true;
}

export const getStationsWithUpdatedTimeFromPrev = (
    legs: google.maps.DirectionsLeg[],
    endAddress: any,
    stations: any
) => {
    const stationsWithTimingAndDistance: any = [];
    const stationsWithUpdatedDistanceFromPrev: any = [];

    stations.forEach((station: any, index: number) => {
        const currLeg = legs[index];
        if (currLeg) {
            const newTimeValue = currLeg.duration_in_traffic?.value || currLeg.duration?.value || 0;
            const newDistanceValue = currLeg.distance?.value || 0;
            const stationWithTimingAndDistance = {
                ...station,
                timing: Math.round(newTimeValue / 60),
                distanceFromPrevStation: Math.round(newDistanceValue / 1000),
            };
            const stationWithUpdatedDistanceFromPrev = {
                ...station,
                distanceFromPrevStation: Math.round(newDistanceValue / 1000),
            };

            stationsWithTimingAndDistance.push(stationWithTimingAndDistance);
            stationsWithUpdatedDistanceFromPrev.push(stationWithUpdatedDistanceFromPrev);
        }
    });

    const lastLeg = legs[legs.length - 1];

    const lastLegTimeNewValue = lastLeg.duration_in_traffic?.value || lastLeg.duration?.value || 0;
    const lastLegDistanceNewValue = lastLeg.distance?.value || 0;

    const endAddressWithPrevTimeAndDistance = {
        ...endAddress,
        timing: Math.round(lastLegTimeNewValue / 60),
        distanceFromPrevStation: Math.round(lastLegDistanceNewValue / 1000),
    };
    const endAddressWithPrevDistance = {
        ...endAddress,
        distanceFromPrevStation: Math.round(lastLegDistanceNewValue / 1000),
    };

    return {
        stationsWithTimingAndDistance,
        endAddressWithPrevTimeAndDistance,
        stationsWithUpdatedDistanceFromPrev,
        endAddressWithPrevDistance,
    };
};

type ServiceResponseCallBack = (
    a: google.maps.DirectionsResult | null,
    b: google.maps.DirectionsStatus
) => void;

const useDirectionsApiLines = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const today = new Date();
    const departureTime = moment(today).add(1, 'day').toDate();

    const [rendererProps, setRendererProps] = useState<DirectionsRendererProps | null>(null);
    const { endAddress, startAddress, stations } = useRootAppSelector(
        (state) => state.linesSlice.ui.form.data
    );
    const s = useRootAppSelector((state) => state.linesSlice);
    const { triggerDirections, isDirectionsTriggeredFromEdit } = useRootAppSelector(
        (state) => state.linesSlice.ui.form
    );

    // const googleCounterUp = useGoogleApiCounterUp()

    const fetchDirections = (directionsService: google.maps.DirectionsService) => {
        console.log('3');

        return new Promise((resolve) => {
            try {
                console.log('4');

                const isCoordsExistOnAllStations = isAllObjectsContainsCoords(stations);
                console.log('isCoordsExistOnAllStations', isCoordsExistOnAllStations);
                console.log('startAddress', startAddress);
                console.log('endAddress', endAddress);

                if (
                    isCoordsExistOnAllStations &&
                    startAddress &&
                    startAddress.lat &&
                    startAddress.lng &&
                    endAddress &&
                    endAddress.lat &&
                    endAddress.lng
                ) {
                    console.log('5');

                    const origin = { lat: startAddress.lat, lng: startAddress.lng };

                    const destination = {
                        lat: endAddress.lat,
                        lng: endAddress.lng,
                    };

                    const waypoints = stations.map((station): google.maps.DirectionsWaypoint => {
                        return {
                            location: { lat: station.lat ?? 0, lng: station.lng ?? 0 },
                            stopover: true,
                        };
                    });

                    const request: google.maps.DirectionsRequest = {
                        origin,
                        destination,
                        waypoints,
                        travelMode: google.maps.TravelMode.DRIVING,
                        avoidTolls: true,
                        drivingOptions: {
                            departureTime,
                            trafficModel: google.maps.TrafficModel.OPTIMISTIC,
                        },
                    };

                    const serviceResponseCb: ServiceResponseCallBack = (response, status) => {
                        // googleCounterUp.googleApiCounterUp();

                        console.log('6');

                        if (response && response.routes && response.routes[0] && response.routes[0].legs) {
                            const route = response.routes[0];

                            const stationsWithTiming = getStationsWithUpdatedTimeFromPrev(
                                route.legs,
                                endAddress,
                                stations
                            );

                            if (!isDirectionsTriggeredFromEdit) {
                                dispatch(
                                    setFormStationsAction({
                                        stations: stationsWithTiming.stationsWithTimingAndDistance,
                                    })
                                );
                                dispatch(
                                    setFormEndAddressAction({
                                        endAddress: stationsWithTiming.endAddressWithPrevTimeAndDistance,
                                    })
                                );
                            } else {
                                dispatch(
                                    setFormStationsAction({
                                        stations: stationsWithTiming.stationsWithUpdatedDistanceFromPrev,
                                    })
                                );
                                dispatch(
                                    setFormEndAddressAction({
                                        endAddress: stationsWithTiming.endAddressWithPrevDistance,
                                    })
                                );
                                // dispatch(setIsDirectionsTriggeredFromEditAction({ isFromEdit: false }))
                            }

                            setRendererProps({
                                directions: response,
                                options: {
                                    suppressMarkers: true,
                                    preserveViewport: true,
                                },
                            });
                        }
                    };

                    directionsService.route(request, serviceResponseCb);
                } else {
                    setRendererProps(null);
                    //  resolve with -1 to indicate error
                    resolve(-1);
                }
            } catch (error) {
                console.error(error);
                setRendererProps(null);
                //  resolve with -1 to indicate error
                resolve(-1);
            }
        });
    };

    useEffect(() => {
        console.log('1');

        if (triggerDirections === 0) return;

        const handleFetchingDirections = async () => {
            console.log('2');

            // Delay the fetch to avoid fetching before the map is ready
            const directionsService = new google.maps.DirectionsService();

            await fetchDirections(directionsService);
        };

        handleFetchingDirections();
    }, [triggerDirections]);

    return { rendererProps, fetchDirections };
};

export default useDirectionsApiLines;
