import { IconButton } from '@mui/material';
import React from 'react';
import { AnyAction } from 'redux';
import {
    disabledIcon,
    addBtnStyle,
} from 'src/screens/PassengerManger/components/Passengers/styles/css-styles';
import AddIcon from '@material-ui/icons/Add';
import { MuiTooltipV3 } from '../MuiTooltip/MuiTooltip';
import { useTranslation } from 'react-i18next';

type Props = {
    disabled?: boolean;
    onClick: any;
    withDivider?: boolean;
    tooltipTitle?: string;
};

const AddIconNoText = ({ disabled, onClick, withDivider, tooltipTitle }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div
            style={
                withDivider
                    ? {
                          borderLeft: '2px solid #bebebe',
                          paddingLeft: '7px',
                          maxHeight: '44px',
                      }
                    : { height: '44px' }
            }
        >
            <MuiTooltipV3 title={tooltipTitle || t('add')}>
                <IconButton size="small" onClick={onClick} disabled={disabled}>
                    <div style={disabled ? disabledIcon : addBtnStyle}>
                        <AddIcon fontSize="medium" />
                    </div>
                </IconButton>
            </MuiTooltipV3>
        </div>
    );
};

export default AddIconNoText;
