import { ReqConfig, WsResponse } from 'src/api/types';
import { CourseTypes } from '../coursesBuilding/commonTypes';

export interface GetAccountCoursesReqParams {
    clientCode: string;
    token: string;
    isActive: 0 | 1 | null;
    departmentCode?: string; // אם לא שולחים, מקבלים את כולם
    relativeDate?: string; // required for accurate course price
}

export const courseIsActiveMap = {
    [CourseTypes.Active]: 1,
    [CourseTypes.InActive]: 0,
    [CourseTypes.All]: 2,
} as const;

export enum CourseDirection {
    Start = '1',
    Return = '2',
    RoundTrip = '3',
}

export interface WsCourseDataItem {
    courseCode: string;
    courseDescription: string;
    departmentCode: string;
    departmentName: string;
    groupName: string;
    courseId: string;
    courseDirection: CourseDirection;
    lineTypeCode: string;
    lineTypeName: string;
    courseRemarks: string;
    carTypeCode: string;
    carTypeName: string;
    clientPrice: string;
    currencyId: string; // only accurate if sent relative date
    passQty: string;
    activeFromDate: string; // Relative date
    activeUntilDate: string; // Relative date
    isActive: '1' | '0';
    remarks?: string;
}
export interface CourseCodes {
    pickup: string;
    drop: string;
}

export interface ChosenCourse {
    addressCode: string;
    pickup: string;
    drop: string;
}

// -- WS -- Get Account Courses ---------------------------------------------------------------
export type GetAccountCoursesResponse = WsResponse<WsCourseDataItem[]>;
export type GetAccountCoursesRequest = ReqConfig<GetAccountCoursesReqParams>;

export type CourseType = 'pickup' | 'drop';
