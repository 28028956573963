import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonMUI from '@material-ui/core/Button';

const Form = styled.form`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
`;

const PhoneText = styled.bdi`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
`;

const Input = styled(OutlinedInput)`
    &.MuiOutlinedInput-root {
        width: 296px;
        text-align: center;
    }
`;

const Button = styled(ButtonMUI)`
    && {
        background-color: #2196f3;
        width: 296px;
    }
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`;

export default {
    Text,
    Input,
    Button,
    Center,
    Div,
    PhoneText,
    Form,
};
