import styled from 'styled-components';

const Wrapper = styled.div<{ lat: number; lng: number }>`
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    &:hover {
        z-index: 1;
    }
`;

const ContainerImage = styled.div<{ lat: number; lng: number }>`
    width: 30px;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`;

const MarkerImage = styled.img<{
    src: string;
    deg?: string;
    lat?: number;
    lng?: number;
    anchorPoint?: { x: number; y: number };
}>`
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    transform: rotate(${(props) => (props.deg ? +props.deg - 270 : '0')}deg);
    // translate(-50%, -50%);
`;

const Image = styled.img<{
    src: string;
    deg?: string;
    lat?: number;
    lng?: number;
    anchorPoint?: { x: number; y: number };
}>`
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const TextInput = styled.text``;

export default { ContainerImage, Wrapper, Image, MarkerImage, TextInput };
