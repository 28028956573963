import styled, { CSSObject, css } from 'styled-components';

export const HEIGHTS = {
    container: 142,
    header: 42,
};

const Container = styled.div<{ style?: CSSObject }>`
    padding-top: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    ${({ style = {} }) => css(style)}
`;
const TotalDailyCostBox = styled.div<{ style?: CSSObject }>`
    height: 142px;
    width: 145px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(106, 106, 106, 0.2);
    border-radius: 15px;
    ${({ style = {} }) => css(style)}
`;

const MonthlyBudgetBox = styled.div<{ style?: CSSObject }>`
    height: ${HEIGHTS.container}px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(106, 106, 106, 0.2);
    border-radius: 15px;
    position: relative;
    ${({ style = {} }) => css(style)}
`;
const ProgressBar = styled.div<{ style?: CSSObject }>`
    height: 22px;
    width: 100%;
    border: 1px solid #494949;
    border-radius: 4px;
    display: flex;
    flex-direction: row-reverse;
    ${({ style = {} }) => css(style)}
`;

const ProgressResult = styled.div<{ style?: CSSObject; perc: number }>`
    background: #80b1d8;
    height: 100%;
    border-right: 1px dashed #000000;
    ${({ perc }) => css`
        width: ${perc}%;
    `};
`;

const HeaderContainer = styled.div<{ style?: CSSObject }>`
    height: ${HEIGHTS.header}px;
    width: 100%;
    border-bottom: 1px solid #b4bec9;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ style = {} }) => css(style)}
`;

const HeaderText = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #494949;
`;

const BodyContainer = styled.div<{ style?: CSSObject }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${HEIGHTS.container - HEIGHTS.header}px;
    padding: 0 15px;
`;
const DailyBoxBody = styled.div<{ style?: CSSObject }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${HEIGHTS.container - HEIGHTS.header}px;
`;

export const Styled = {
    MonthlyBudgetBox: {
        ProgressBar,
        ProgressResult,
        MonthlyBudgetBox,
        BodyContainer,
    },
    DailyCost: {
        TotalDailyCostBox,
        DailyBoxBody,
    },
    Common: {
        HeaderContainer,
        Container,
        HeaderText,
    },
};
