/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import themes from 'src/style/themes';
import myStyles from 'src/style/styledComponents.style';
import { getWeekdayByDate } from 'src/utilis/utilis';
import { styleDateWithMsg, styleDateAndStatus, regularFontBold } from '../styles/styledCSS';
import {
    StatusColorIcon,
    StyledLineBox,
    StyledPropertyTitle,
    StyledDriverInfoBox,
    StyledDailyLinesBox,
    StyledBoxWithTextOverflow,
} from '../styles/styledComponents';
import { getLinesOrderedByDate, getShortDateWithMsg } from '../utils';

// ^ Components
// & Parent -> DataAndStatus 5
const StatusDisplay: React.FC<{ status: number }> = ({ status }) => {
    const [config, setConfig] = useState({ color: '', txt: '' });
    const { t } = useTranslation();
    useEffect(() => {
        const newConfig = { color: '', txt: '' };
        switch (status) {
            case 0 || 1 || 2 || 5:
                break;
            case 3:
                newConfig.color = '#EBB835';
                newConfig.txt = t('lineStatusRide');
                break;
            case 4:
                newConfig.color = '#4CB044';
                newConfig.txt = t('lineStatusEnded');
                break;
            default:
                break;
        }

        setConfig(newConfig);
    }, [status, t]);
    return (
        <StatusColorIcon color={config.color}>
            <Typography
                sx={{
                    marginBottom: '1px',
                    color: 'white',
                    fontSize: '14px',
                    fontFamily: themes.fontFamilies.Rubik,
                }}
            >
                {config.txt}
            </Typography>
        </StatusColorIcon>
    );
};

// & Line > 4
export const DataAndStatus: React.FC<{
    time: string;
    status: number;
}> = ({ time, status }) => {
    return (
        <div style={styleDateAndStatus}>
            <Typography sx={regularFontBold}>{time}</Typography>
            <StatusDisplay status={status} />
        </div>
    );
};

// & Parent -> LocationAndDriverInfo 5
export const DetailKeyValue: React.FC<{
    propertyName: string;
    value: string;
}> = ({ propertyName, value }) => {
    return (
        <StyledBoxWithTextOverflow>
            <StyledPropertyTitle>{propertyName}</StyledPropertyTitle>
            <myStyles.MySpan size={14}>{value}</myStyles.MySpan>
        </StyledBoxWithTextOverflow>
    );
};

// & Parent -> Line 4
export const LocationAndDriverInfo: React.FC<{ data: any }> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                padding: '5px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography sx={regularFontBold}>{data.line_description}</Typography>
            <StyledDriverInfoBox>
                <StyledBoxWithTextOverflow>
                    {data.driver_name && (
                        <DetailKeyValue propertyName={t('driver')} value={data.driver_name} />
                    )}
                </StyledBoxWithTextOverflow>
                <StyledBoxWithTextOverflow>
                    {data.driver_mobile && (
                        <DetailKeyValue propertyName={t('phone')} value={data.driver_mobile} />
                    )}
                </StyledBoxWithTextOverflow>
                <StyledBoxWithTextOverflow>
                    {data.client_name && (
                        <DetailKeyValue propertyName={t('company')} value={data.client_name} />
                    )}
                </StyledBoxWithTextOverflow>
            </StyledDriverInfoBox>
        </div>
    );
};

// & Parent -> DailyLinesBox > 3
const Line: React.FC<{ lineData: any }> = ({ lineData }) => {
    return (
        <StyledLineBox>
            <DataAndStatus
                time={`${lineData.start_time}-${lineData.end_time}`}
                status={lineData.line_status}
            />
            <LocationAndDriverInfo data={lineData} />
        </StyledLineBox>
    );
};

// & Parent -> AllLinesContainer > 2
const DailyLinesBox: React.FC<{ lines: any }> = ({ lines }) => {
    return (
        <StyledDailyLinesBox>
            <p style={styleDateWithMsg}>{getShortDateWithMsg(lines[0].line_date)}</p>
            {lines.map((line: any) => (
                <Line lineData={line} />
            ))}
        </StyledDailyLinesBox>
    );
};

// & 1
const AllLinesContainer: FC<{ lines: any }> = ({ lines }) => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                fontFamily: `${themes.fontFamilies.Rubik}`,
                padding: 0,
            }}
            className="AllLinesContainer"
        >
            {lines.length > 0 ? (
                getLinesOrderedByDate(lines).map((linesInDate) => <DailyLinesBox lines={linesInDate} />)
            ) : (
                <h4>{t('passengerHasNoLines')}</h4>
            )}
        </div>
    );
};

export default AllLinesContainer;
