import React from 'react';
/**
 * This Hook can be used for detecting clicks outside the Opened Menu
 */
export default function useClickOutside(
    ref: React.MutableRefObject<any>,
    onClickOutside: { (): void; (): void }
): void {
    React.useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event: { target: any }) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        // Bind
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // dispose
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClickOutside, ref]);
}
