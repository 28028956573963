import { CSSProperties } from 'react';

export const phonesInputsContainer: CSSProperties = {
    width: '100%',
};

export const phonesBoxContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
};

export const btnsContainer: CSSProperties = {
    display: 'flex',
    gap: '15px',
};
