/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import React from 'react';
import IconButton from '@mui/material/IconButton';
// eslint-disable-next-line import/no-unresolved
import themes from 'src/style/themes';
import MyInfoIcon from '../../../../../../components/Icons/MyInfoIcon';
import { StyledIconContainer } from '../.styles';

const detailsIconStyle = {
    backgroundColor: themes.msColors.primaryTextcolor,
    color: '#EDBD41',
    height: '30px',
    width: '30px',
};

function MailIcon() {
    return (
        <StyledIconContainer>
            <IconButton style={detailsIconStyle} aria-label="עריכה" size="small">
                <MailOutlineOutlinedIcon fontSize="small" />
            </IconButton>
        </StyledIconContainer>
    );
}
function PhoneIcon() {
    return (
        <StyledIconContainer>
            <IconButton style={detailsIconStyle} aria-label="עריכה" size="small">
                <LocalPhoneOutlinedIcon fontSize="small" />
            </IconButton>
        </StyledIconContainer>
    );
}
function AddressIcon() {
    return (
        <StyledIconContainer>
            <IconButton style={detailsIconStyle} aria-label="עריכה" size="small">
                <FmdGoodOutlinedIcon fontSize="small" />
            </IconButton>
        </StyledIconContainer>
    );
}
function PermissionsIcon() {
    return (
        <StyledIconContainer>
            <IconButton style={detailsIconStyle} aria-label="עריכה" size="small">
                <MyInfoIcon />
            </IconButton>
        </StyledIconContainer>
    );
}

export { MailIcon, PhoneIcon, PermissionsIcon, AddressIcon };
