import ButtonMUI from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const Header = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #2196f3;
`;

export const HeaderText = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    color: ${({ theme }) => theme.colors.blue};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
`;

export const SpaceAround = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 90%;
`;

export const Form = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 40px;
`;

export const Span = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 40px;
`;

export const InnerDiv = styled.div`
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
`;

export const Button = styled(ButtonMUI)`
    && {
        background-color: #2196f3;
        height: 44px;
        width: 296px;
        left: 0px;
        top: 0px;
        border-radius: 4px;
        margin-top: 20px;
    }
`;

export const TextInput = styled(TextField)`
    && {
        height: 44px;
        margin: 20px;
        width: 296px;
        left: 0px;
        top: 0px;
        border-radius: 4px;
    }
`;
