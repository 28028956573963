import { CSSProperties } from 'react';
import defaultTheme from 'src/style/themes';
import { breakPoints } from 'src/style/themes/defaultTheme';
import styled, { css, CSSObject } from 'styled-components';

export const StyledFiltersContainer = styled.div<{
    style?: CSSObject;
}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${defaultTheme.fontFamilies.Rubik};
    padding: 0 1.6875em 10px 1.6875em;
    box-sizing: border-box;
    overflow: hidden;
    ${({ style }) => css(style || {})}
`;

export const StyledInputsContainer = styled.div`
    flex-basis: 89%;
    padding-right: 2px;
    width: 100%;
`;

export const StyledBtnsContainer = styled.div`
    display: flex;
    flex-basis: 11%;
    height: 100%;
    // background-color: gray;
    justify-content: flex-end;
    align-items: end;
    width: 100%;
    // gap: 20px;
`;

export const SDivider = styled.div`
    border-right: 1px solid #bebebe;
    padding: 0;
    margin: 0 0 0 5px;
    @media (max-width: 1768px) {
        margin: 0 0 0 10px;
    }
`;
