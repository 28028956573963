import { IExtendedCourseData } from 'src/types/coursesBuilding/commonTypes';
import { CarType } from 'src/types/lines/api/apiProccessed.types';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';

export enum TabsTypes {
    GeneralDetails = 'generalDetails',
    StationsAndTimes = 'stationsAndTimes',
}
type Days = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type TimesByDay = {
    times: {
        start: string;
        end: string;
    }[];
    day: Days;
}[];

type ActiveCarTypes = { carTypeCode: string; carTypeName: string };
export interface ExtendedCourse extends WsCourseDataItem {
    courseTimes: IExtendedCourseData[];
}

const dummyTimesByDay: TimesByDay = [
    {
        times: [],
        day: 1,
    },
    {
        times: [],
        day: 2,
    },
];

const dummyCarTypes: ActiveCarTypes[] = [
    {
        carTypeCode: '1',
        carTypeName: 'קטגוריה 1',
    },
    {
        carTypeCode: '2',
        carTypeName: 'קטגוריה 2',
    },
    {
        carTypeCode: '3',
        carTypeName: 'קטגוריה 3',
    },
];

// export const DUMMY_COURSES: ExtendedCourse[] = [
//     {
//         courseCode: '55788',
//         courseDescription: 'בדיקה מסלולים קבועים',
//         departmentCode: '46',
//         departmentName: 'תוכנה',
//         carTypeCode: '3',
//         carTypeName: 'truck',
//         groupName: 'group1',
//         courseId: '4324',
//         courseDirection: '',
//         lineTypeCode: '7',
//         lineTypeName: 'lineName1',
//         courseRemarks: 'some long remarks',
//         clientPrice: '433',
//         passQty: '2',
//         courseTimes: [],
//         // timesByDay: dummyTimesByDay,
//         // activeCarTypes: dummyCarTypes,
//     },
//     {
//         courseCode: '55788',
//         courseDescription: 'בדיקה מסלולים קבועים',
//         departmentCode: '',
//         departmentName: '',
//         carTypeCode: '',
//         carTypeName: '',
//         groupName: '',
//         courseId: '',
//         courseDirection: '',
//         lineTypeCode: '',
//         lineTypeName: '',
//         courseRemarks: '',
//         clientPrice: '',
//         passQty: '0',
//         courseTimes: [],
//     },
// ];

const groupByTimes = (timesByDay: IExtendedCourseData[]) => {
    const result: {
        time: string;
        days: number[];
    }[] = [];
    const map: [string, number][] = [];

    timesByDay.forEach((tbd) => {
        // eslint-disable-next-line no-return-assign
        tbd.times.forEach((t) => map.push([JSON.stringify(t), tbd.day]));
    });

    for (let i = 0; i < map.length; i++) {
        const [currTime, currDay] = map[i];

        const daysForTime = { time: currTime, days: [currDay] };

        let j = i + 1;

        while (j < map.length) {
            const [time2, day2] = map[j];

            if (time2 === currTime) {
                daysForTime.days.push(day2);

                // remove day data from array
                map.splice(j, 1);
            } else {
                j++;
            }
        }

        result.push(daysForTime);
    }

    return result;
};
export const SEPARATOR = '&';

const groupByDays = (
    groupedTimesByDay: {
        time: string;
        days: number[];
    }[]
) => {
    const result: {
        time: string;
        days: number[];
    }[] = [];

    const duplicatesIdxs: number[] = [];

    groupedTimesByDay.forEach((group, i) => {
        const currCopy = { ...group };

        const duplicate = groupedTimesByDay.find((g, j) => {
            // checking if dates are the same for two different groups
            const isDuplicate = JSON.stringify(g.days) === JSON.stringify(group.days) && i !== j;

            if (isDuplicate) {
                // tracking duplicates indexes
                duplicatesIdxs.push(j);
                return true;
            }

            return false;
        });

        if (duplicate) {
            // storing as a string because its easier to work with later on
            currCopy.time = `${currCopy.time}${SEPARATOR}${duplicate.time}`;
        }

        // to avoid using having duplicates in the result
        if (duplicatesIdxs.includes(i)) return;

        result.push(currCopy);
    });

    return result;
};

export const helpers = {
    groupByTimes,
    groupByDays,
};
