import React, { FC, useState, useEffect, CSSProperties, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import {
    clientIdSelector,
    passengerSelector,
    selectedPassengerPasscodeSelector,
} from 'src/store/selectores/passengersSelector';
import { filterBy } from '@progress/kendo-data-query';

// import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import { IFutureLine, IPassenger } from 'src/api/passengerMangerApi/types';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { GetFutureLines } from 'src/api/passengerMangerApi/passengerMangerApi';
import { Box, InputAdornment, Typography, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Input from 'src/components/commons/Input/Input';
import { FcResponseState } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import { setTokenRefreshStatus } from 'src/store/actions/loginAction';
import defaultTheme from 'src/style/themes';
import PassengerLine from './components/old/PassengerLine/PassengerLine';

import AllLinesContainer from './components/AllLinesContainer';
import { widgetHeader } from './styles/styledCSS';
import { getOnlySelectedPassenger } from '../../utils';
import useSelectedPassenger from '../../hooks/useSelectedPassenger';
import { EmptyDataMsg } from '../PassengerDetails/PassengerDetails';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

const PassengerLines: FC<IProps> = () => {
    // * Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // * Local states
    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [lines, setLines] = useState<IFutureLine[]>([]);
    const [filteredLines, setFilteredLines] = useState<IFutureLine[]>([]);

    // const selectedPassengerPasscode = useSelector((state: IRootReducer) =>
    //    selectedPassengerPasscodeSelector(state),
    // );

    const selectedPassenger = useSelectedPassenger();
    // * Selectors
    // const passengers = useSelector((state: IRootReducer) =>
    //    passengerSelector(state),
    // );
    const clientId = useSelector((state: IRootReducer) => clientIdSelector(state));

    const { token, selectedFcAccount } = useSelector((state: IRootReducer) => loginSelector(state));

    // * Effects
    // & Filtering the lines for the Line components
    useEffect(() => {
        const runQuery = () => {
            if (!filterQuery) {
                setFilteredLines(lines);
                return;
            }

            setFilteredLines(
                filterBy(lines, {
                    logic: 'and',
                    filters: [
                        {
                            field: 'line_description',
                            operator: 'contains',
                            value: filterQuery,
                            ignoreCase: true,
                        },
                    ],
                })
            );
        };

        runQuery();
    }, [filterQuery, lines]);

    // & Fetching lines from API
    useEffect(() => {
        const getLines = async () => {
            if (!selectedFcAccount || !selectedFcAccount.dbUrl || !selectedPassenger) return;

            const apiResponse = await GetFutureLines({
                dbUrl: selectedFcAccount.dbUrl,
                proxyUrl: selectedFcAccount.dbUrl,
                passCode: selectedPassenger.passCode,
                token,
                clientCode: clientId,
            });

            const { data = [], response } = apiResponse.data;

            if (response === FcResponseState.TokenExpired)
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Invalid));

            setLines(data);
        };

        getLines();
    }, [selectedPassenger, token, selectedFcAccount, clientId, dispatch]);
    // * JSX returned
    return (
        <>
            <div
                style={{
                    maxHeight: '68px',
                    width: '100%',
                    padding: '10px 5px',
                }}
            >
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box display="flex" alignItems="center" flexWrap="wrap" marginRight="10px">
                        <div
                            style={{
                                background: '#ffedb1',
                                height: '30px',
                                width: '30px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '10px',
                                marginRight: '5px',
                            }}
                        >
                            <DepartureBoardIcon
                                style={{
                                    fontSize: '22px',
                                    marginLeft: '1px',
                                }}
                            />
                        </div>

                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 'bold',
                                fontFamily: defaultTheme.fontFamilies.Rubik,
                                color: defaultTheme.msColors.primaryTextcolor,
                            }}
                        >
                            {t('embeddedTravel')}
                        </Typography>
                    </Box>
                </Box>
            </div>
            <div style={{ padding: 0 }}>
                <Divider />
            </div>

            <div
                style={{
                    height: '100%',
                    width: '100%',
                    padding: '0 5px',
                    overflow: 'auto',
                }}
            >
                <div>
                    <div style={{ padding: '10px' }}>
                        {selectedPassenger ? <AllLinesContainer lines={filteredLines} /> : <EmptyDataMsg />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PassengerLines;
