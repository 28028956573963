import { isDictV2 } from './utilis';

interface Event {
    target: {
        value: unknown;
    };
}

const hasEventValue = (event: any): event is Event => {
    return event && typeof event === 'object' && 'target' in event && 'value' in (event as any).target;
};

export const isHistoryTypeGuard = (
    history: unknown
): history is {
    push: (url: string) => void;
} => {
    return isDictV2(history) && 'push' in history && typeof history.push === 'function';
};

const typeGuards = {
    hasEventValue,
};

export default typeGuards;
