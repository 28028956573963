import { GetDepartmentsArgs, getDepartments } from 'src/api/api';
import {
    CarTypePriceByCourseReqConfig,
    getCarTypesPriceByCourse,
    ICarTypePriceByCourseWsDataItem,
} from 'src/api/miscApis/getCarTypesPriceByCourseWs';
import { getLineTypesWs } from 'src/api/miscApis/getLineTypesWs';
import { responseValidators } from 'src/api/utilis';
import { CoursesBuildingApiMappers } from 'src/types/coursesBuilding/apiTypes';
import { IDepartment } from 'src/types/line';
import { CarType } from 'src/types/lines/api/apiProccessed.types';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { linesBl } from '../../api/dataMappers/linesBl';
import responseHandlers from '../helpers/responseHandlers';
import { getInitialResultCopy } from '../mockServerConstants';
import { ErrorMessages } from 'src/types/apiCommon.types';

export interface GetCoursesClientData {
    requestConfig: GetDepartmentsArgs;
}

const INITIAL_CODE = -1;

export interface Result<T> {
    code: number;
    data: {
        data: null | T;
        message: null | string; // should only be truthy if request failed!
    };
}

const DATA_KEY = 'departments';

const getDepartmentsRoute = async (clientData: GetCoursesClientData) => {
    const defaultErrorCode = CommonCodes.UnknownError;

    const result: Result<IDepartment[]> = {
        code: INITIAL_CODE,
        data: {
            data: null,
            message: null,
        },
    };

    try {
        const httpRes = await getDepartments(clientData.requestConfig);

        if (responseValidators.isOk(httpRes)) {
            result.code = CommonCodes.Ok;
            result.data.data = httpRes.data[DATA_KEY];

            return result;
        }

        if (responseValidators.isMissingWs(httpRes)) {
            result.code = CommonCodes.MissingWs;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        if (responseValidators.isBadToken(httpRes)) {
            result.code = CommonCodes.BadToken;
            result.data.data = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.data.message = CoursesBuildingApiMappers.errorMessagesByCode[result.code];

            return result;
        }

        throw new Error('GeneralBadHttpResponse');
    } catch (error) {
        console.log(error);

        result.code = defaultErrorCode;
        result.data.data = null;
        result.data.message = 'שגיאה במשיכת מחלקות';

        return result;
    }
};

const getLineTypesRoute = async (clientData: {
    requestConfig: { dbUrl: string; requestParams: { token: string } };
}) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    const result: Result<
        {
            lineType: string;
            lineTypeCode: string;
        }[]
    > = getInitialResultCopy();

    const requestConfig = { ...clientData.requestConfig };

    try {
        const httpRes = await getLineTypesWs(requestConfig);

        const dataParser = (data: any[]) => {
            return data.map((item) => ({
                lineTypeCode: String(item.lineTypeCode),
                lineType: item.lineType,
            }));
        };

        return responseHandlers.getResult(httpRes, result, { dataKey: 'lineTypes', dataParser });
    } catch (error) {
        return responseHandlers.getErrorResult(
            result,
            error,
            CoursesBuildingApiMappers.errorMessagesByCode[defaultErrorCode],
            defaultErrorCode
        );
    }
};

const getCarTypesPriceByCourseRoute = async (clientData: {
    requestConfig: CarTypePriceByCourseReqConfig;
}) => {
    const defaultErrorCode = CommonCodes.OperationFailed;

    // Parsed data from server
    const result: Result<CarType[]> = getInitialResultCopy();

    try {
        const dataParser = (rawData: ICarTypePriceByCourseWsDataItem[]) => {
            return linesBl.processGetCarTypes(rawData);
        };

        const requestConfig = { ...clientData.requestConfig };

        const httpRes = await getCarTypesPriceByCourse(requestConfig);
        const routeResult = responseHandlers.getResult(httpRes, result, { dataParser });

        return routeResult;
    } catch (error) {
        return responseHandlers.getErrorResult(result, error, ErrorMessages.GeneralError, defaultErrorCode);
    }
};

const commonRoutes = {
    getDepartments: getDepartmentsRoute,
    getLineTypes: getLineTypesRoute,
    getCarTypesPriceByCourseRoute,
};

export default commonRoutes;
