/* eslint-disable import/no-cycle */
import { IAuthorization, ReqStatus } from 'src/api/types';
import { PassengersErrorMessagesEnum, StorageKeys } from 'src/types/global';
import { IAccount, RefreshTokenStatus, SingupType } from 'src/types/login';
import { LoginActionType } from '../actions/actionType';
import { IContactLoginData } from '../sagas/loginSaga';
import { IReduxProvider, ILoginState } from '../type';
import {
    getGoogleApiInterfaceStatus,
    getLocalStorageValue,
    getSessionStorageValue,
    initialGoogleApiCounter,
    isGoogleApiInterfaceCounterExceeded,
    setLocalStorageValue,
} from 'src/utilis/utilis';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiCounterExceededPopUpOpenAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from '../slices/common/commonSlice';
import store from '..';

const initialState: ILoginState = {
    isAuthenticated: null,
    refreshTokenStatus: RefreshTokenStatus.Init,
    authorizationToken: {},
    token: '',
    fcAccounts: [],
    gps_server: '',
    gps_token: '',
    loginType: null,
    errorMassege: null,
    isIgnoreInitialRoute: false,
    selectedFcAccount: null,
    googleApiInterfaceInterval: null,
};

const initialConfiguration = (state: ILoginState, payload: { deviceToken: string }): ILoginState => {
    return {
        ...state,
        isAuthenticated: ReqStatus.LOADING,
    };
};

export interface AuthConfigPayload extends IContactLoginData {
    loginType: number;
}
const initialConfigurationSucces = (state: ILoginState, payload: AuthConfigPayload): ILoginState => {
    if (!state.selectedFcAccount && payload?.fcAccounts?.length) {
        const fcAccountCode = payload.fcAccounts[0].accountCode;

        if (fcAccountCode) {
            initialGoogleApiCounter(fcAccountCode);

            getGoogleApiInterfaceStatus(fcAccountCode).then((data) => {
                if (data) {
                    const isExceeded = isGoogleApiInterfaceCounterExceeded(data);
                    store.store.dispatch(
                        setGoogleApiInterfaceIntervalAction({ interval: data.ClientsRequestsInterval })
                    );

                    if (isExceeded !== null) {
                        store.store.dispatch(setIsGoogleApiInterfaceCounterExceededAction({ isExceeded }));
                        store.store.dispatch(
                            setIsGoogleApiCounterExceededPopUpOpenAction({ isOpen: isExceeded })
                        );
                    }
                }
            });
        }
    }

    if (state && state.selectedFcAccount && state.selectedFcAccount.accountCode) {
        initialGoogleApiCounter(state.selectedFcAccount.accountCode);

        getGoogleApiInterfaceStatus(state.selectedFcAccount.accountCode).then((data) => {
            // state.googleApiInterfaceInterval = interval;
            if (data) {
                const isExceeded = isGoogleApiInterfaceCounterExceeded(data);
                store.store.dispatch(
                    setGoogleApiInterfaceIntervalAction({ interval: data.ClientsRequestsInterval })
                );

                if (isExceeded !== null) {
                    store.store.dispatch(setIsGoogleApiInterfaceCounterExceededAction({ isExceeded }));
                    store.store.dispatch(
                        setIsGoogleApiCounterExceededPopUpOpenAction({ isOpen: isExceeded })
                    );
                }
            }
        });
    }

    return {
        ...state,
        ...payload,
        isAuthenticated: ReqStatus.SUCCESS,
        refreshTokenStatus: RefreshTokenStatus.Ok,
        selectedFcAccount:
            !state.selectedFcAccount && payload?.fcAccounts?.length
                ? payload?.fcAccounts[0]
                : payload?.fcAccounts?.find(
                      (x: IAccount) => x.contactUUID === state.selectedFcAccount?.contactUUID
                  ) || null,
        selectedDate: state.selectedDate || new Date(),
    };
};

const initConnectFail = (state: ILoginState, payload: any): ILoginState => {
    return {
        ...state,
        loginType: 0,
        isAuthenticated: ReqStatus.FAIL,
    };
};

const setAuthorizationToken = (state: ILoginState, payload: IAuthorization): ILoginState => {
    return {
        ...state,
        authorizationToken: payload,
    };
};

const setFcAccount = (state: ILoginState, payload: IAccount): ILoginState => {
    return {
        ...state,
        selectedFcAccount: payload,
    };
};

const setSelectedDate = (state: ILoginState, payload: Date): ILoginState => {
    return {
        ...state,
        selectedDate: payload,
    };
};

const setLogout = (state: ILoginState): ILoginState => {
    return {
        ...initialState,
        selectedFcAccount: null,
        loginType: 0,
    };
};

const setLoginType = (state: ILoginState, payload: SingupType): ILoginState => {
    return {
        ...state,
        loginType: payload,
        isIgnoreInitialRoute: true,
    };
};

const setTokenRefreshStatus = (state: ILoginState, payload: RefreshTokenStatus): ILoginState => {
    return {
        ...state,
        selectedFcAccount: state.selectedFcAccount,
        refreshTokenStatus: payload,
    };
};

const setErrorMessage = (state: ILoginState, payload: string): ILoginState => {
    return {
        ...state,
        errorMassege: payload,
    };
};

const setErrorCode = (state: ILoginState, payload: PassengersErrorMessagesEnum): ILoginState => {
    return {
        ...state,
        errorCode: payload,
    };
};

const handlerTypes: {
    [index: string]: IReduxProvider<any>;
} = {
    [LoginActionType.initialConfiguration]: initialConfiguration,
    [LoginActionType.initialConfigurationSuccess]: initialConfigurationSucces,
    [LoginActionType.initialConfigurationFail]: initConnectFail,
    [LoginActionType.setAuthorizationToken]: setAuthorizationToken,
    [LoginActionType.SetFcAccount]: setFcAccount,
    [LoginActionType.SetDate]: setSelectedDate,
    [LoginActionType.Logout]: setLogout,
    [LoginActionType.SetLoginType]: setLoginType,
    [LoginActionType.SetTokenRefreshStatus]: setTokenRefreshStatus,
    [LoginActionType.SetErrorMessage]: setErrorMessage,
    [LoginActionType.SetErrorCode]: setErrorCode,
};

const loginReducer = (
    state = initialState,
    { type, payload }: { type: LoginActionType; payload: object }
): ILoginState => {
    const handler = handlerTypes[type];
    if (handler) {
        return handler(state, payload);
    }
    return state;
};

export default loginReducer;
