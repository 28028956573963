/* eslint-disable no-plusplus */
import { useTranslation } from 'react-i18next';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPassengerShifts } from 'src/types/manualOrders/api.types';
import { RELATIVE } from 'src/constants/dates';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import { ReasonForBlockedDate } from 'src/screens/ManualOrder/types';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { editShiftSelector, passengerRootDataSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { passengersOrdersCountPerDateSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { ShallowBoolean } from 'src/types/global';
import { formatDateToString } from 'src/utilis/utilis';

type ManualOrdersPassenger = IPassengerShifts;
const { PassengerHasTwoOrdersOnDate } = ReasonForBlockedDate;

const useOrderUtils = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ordersCountForPassengers = useRootAppSelector(passengersOrdersCountPerDateSelector);

    const shiftAlreadyOrderedForDate = useCallback(
        (
            relativeDate: string,
            orderTimes: { pickupTime: string; dropTime: string },
            passengerId: string
        ): ShallowBoolean => {
            try {
                if (!ordersCountForPassengers) return false;

                const countForPassenger = ordersCountForPassengers[passengerId];

                if (!countForPassenger) return false;

                const ordersForCurrDate = countForPassenger[relativeDate]?.ordersData;

                if (!ordersForCurrDate) return;

                const pickupTimesForDate =
                    ordersForCurrDate.pickupOrders.map((pickupOrder) => pickupOrder.shiftTime) || [];
                const dropTimesForDate =
                    ordersForCurrDate.dropOrders.map((dropOrder) => dropOrder.shiftTime) || [];

                // console.log({
                //    pickupTimesForDate,
                //    dropTimesForDate,
                //    orderTimes,
                //    relativeDate,
                // });
                if (
                    pickupTimesForDate.includes(orderTimes.pickupTime) &&
                    dropTimesForDate.includes(orderTimes.dropTime)
                )
                    return true;
                // console.log('passed 1');
                if (!orderTimes.pickupTime) {
                    // check if drop is same
                    if (dropTimesForDate.includes(orderTimes.dropTime)) return true;
                }
                // console.log('passed 2');
                if (!orderTimes.dropTime) {
                    // check if pickup is same
                    if (pickupTimesForDate.includes(orderTimes.pickupTime)) return true;
                }
                // console.log('passed 3');
            } catch (error) {
                dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
            }
        },
        [dispatch, ordersCountForPassengers, t]
    );

    return { shiftAlreadyOrderedForDate };
};

export default useOrderUtils;
