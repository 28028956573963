import moment from 'moment';
import React, { useMemo } from 'react';
import sum from 'lodash.sum';
import { useRootAppSelector } from 'src/store/hooks';
import { statisticsSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';

const useOrderInfo = () => {
    const { daily: dailyStatistics, weekly: weeklyStatistics } = useRootAppSelector(statisticsSelector);

    const pickup = useMemo(
        () => sum(dailyStatistics.data?.data.filter((s) => s.isPickup === '1').map((s) => s.totalOrders)),
        [dailyStatistics]
    );

    const drop = useMemo(
        () => sum(dailyStatistics.data?.data.filter((s) => s.isPickup === '0').map((s) => s.totalOrders)),
        [dailyStatistics]
    );
    const countDailyOrder = useMemo(() => pickup + drop, [pickup, drop]);
    const countWeeklyOrder = useMemo(
        () => sum(weeklyStatistics.data?.data.map((w) => w.totalOrders)),
        [weeklyStatistics]
    );
    const dayDate = useMemo(
        () =>
            dailyStatistics.data?.fromDate
                ? moment(dailyStatistics.data?.fromDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                : '',
        [dailyStatistics]
    );
    const weekDate = useMemo(() => {
        return weeklyStatistics.data?.fromDate
            ? `${moment(weeklyStatistics.data?.fromDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}- ${moment(
                  weeklyStatistics.data?.toDate,
                  'YYYY-MM-DD'
              ).format('DD.MM.YYYY')}`
            : '';
    }, [weeklyStatistics]);
    return {
        countDailyOrder,
        countWeeklyOrder,
        pickup,
        drop,
        dayDate,
        weekDate,
    };
};

export default useOrderInfo;
