/* eslint-disable import/no-named-as-default-member */
import React, { FC, useEffect, useState } from 'react';
import { containers, MainSectionContainer } from 'src/Layouts/styles';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { isFormOpenSelector, triggerDirectionsAction } from 'src/store/slices/lines/linesSlice';
// eslint-disable-next-line import/no-named-as-default
import { Slide } from '@mui/material';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useDispatch } from 'react-redux';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Lines from './components/Lines/Lines';
import LineWidgetLayout from './components/LineWidgetLayout/LineWidgetLayout';
import styles from './Main.style';
import DailyRideFloatMap from './components/AddLineForm/components/DailyRideFloatMap';
import AddLinesForm from './components/AddLineForm/AddLinesForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const Main: FC<Props> = () => {
    const isFormOpen = useAppSelector((state) => isFormOpenSelector(state));
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({});

    React.useEffect(() => {
        getPlacePredictions({
            input: '',
        });
    }, []); // todo check if still works

    return (
        <containers.ScreenContainer>
            <containers.MainSectionContainer className="MainSectionContainer">
                {isFormOpen && <DailyRideFloatMap />}
                <Lines />
            </containers.MainSectionContainer>
            <containers.WidgetsContainer isOpen className="WidgetsContainer">
                {isFormOpen ? <AddLinesForm /> : <LineWidgetLayout />}
            </containers.WidgetsContainer>
        </containers.ScreenContainer>
    );
};

export default Main;
