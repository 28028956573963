import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { FcResponseState, ReqStatus, WsResponse as GenericWsResponse, WsResponse } from 'src/api/types';
import i18n from 'src/i18n';
import { CommonCodes, MockRouteResponse } from 'src/types/mockServer/routes.types';
import { setErrorMessage, setTokenRefreshStatus } from 'src/store/actions/loginAction';

import { onErrorAction as onReportsErrorAction } from 'src/store/slices/reports/reportsSlice';
import { ReportName } from 'src/store/slices/reports/types';
import { ErrorMessagesOld } from 'src/store/type';
import { PassengersErrorMessagesEnum } from 'src/types/global';
import { RefreshTokenStatus } from 'src/types/login';
import { getCurrentPath } from 'src/utilis/utilis';

interface ValidatePayloadConfig<T> {
    payload: T;
    failureAction: any;
}

export const getErrorCodeByResponse = (response: string): PassengersErrorMessagesEnum => {
    let errorCode = PassengersErrorMessagesEnum.GenralError;
    if (response === '1') errorCode = PassengersErrorMessagesEnum.TokenExpired;
    if (response === '2') errorCode = PassengersErrorMessagesEnum.ClientCodeIsMissing;
    if (response === '3') errorCode = PassengersErrorMessagesEnum.InternalPassengerIsMissing;
    if (response === '4') errorCode = PassengersErrorMessagesEnum.InternalPassengerCodeExiest;

    return errorCode;
};
/**
 * @param {ValidatePayloadConfig<T>} config - The configuration object.
 * @returns None
 */
export function* validatePayload<T>({ payload, failureAction }: ValidatePayloadConfig<T>) {
    if (!payload) {
        yield put(
            failureAction({
                status: ReqStatus.FAIL,
            })
        );
    }
}

/**
 * Makes a request to the given URL and returns the response.
 * @param {Function} requestFunc - The function to make the request with.
 * @param {Object} requestConfig - The configuration object for the request.
 * @returns {Object} - The response object.
 */
export function* makeRequest(requestFunc: Function) {
    const apiResponse: AxiosResponse<WsResponse<any>> = yield requestFunc();
    const { status } = apiResponse;
    const { response, data } = apiResponse.data;
    return { status, response, data };
}

export function* genericMakeRequest<TData>(requestFunc: Function) {
    const apiResponse: AxiosResponse<GenericWsResponse<TData>> = yield requestFunc();
    const { status } = apiResponse;
    const { response, data } = apiResponse.data;
    // console.log(apiResponse);
    return { status, response, data };
}

export interface HandleBadStatusConfig {
    status: number;
    details?: string;
    onErrorAction?: Function;
}
export function* handleBadStatus({
    status,
    details = '',
    onErrorAction: argErrorAction,
}: HandleBadStatusConfig) {
    let errorMsg = `invalid ws status code: ${status}`;

    if (status === 202) errorMsg = ErrorMessagesOld.MissingWs;
    if (argErrorAction) {
        yield put(
            argErrorAction({
                msg: errorMsg,
                details,
            })
        );
    } else {
        yield put(setErrorMessage(errorMsg));

        const path = getCurrentPath();
        // console.log({ path });
        if (path === 'reports') {
            yield put(
                // -- reports screen errors action
                onReportsErrorAction({
                    msg: errorMsg,
                    details,
                })
            );
        }
    }
}

export interface HandleResponseConfig<T> {
    response: string;
    data: T;
    status: number;
    successAction: Function;
    statusUpdateAction?: Function;
    successActionArgs?: any;
    statusUpdateActionArgs?: any;
    reportName?: ReportName;
}
/**
 * ### LEGACY ### Handles the response from the server.
 * @param {HandleResponseConfig<T>} config - The configuration object.
 * @returns None
 */
export function* handleResponse<T>({
    response,
    status,

    successAction, // action that causes mutation of the data in the state
    successActionArgs = {},

    statusUpdateAction, // action that updates the status of the request on the state
    statusUpdateActionArgs = {},

    data,
    reportName,
}: HandleResponseConfig<T>) {
    const isValidStatusCode = status === 200;
    let dispatchedErrorAction = false;

    if (!isValidStatusCode) {
        yield handleBadStatus({ status });
        dispatchedErrorAction = true;
    }

    if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

    if (+response === 0) {
        yield put(
            successAction({
                data,
                ...successActionArgs,
            })
        );
        if (statusUpdateAction) {
            yield put(
                statusUpdateAction({
                    status: ReqStatus.SUCCESS,
                    ...statusUpdateActionArgs,
                })
            );
        }
    } else {
        // response is not '0'
        let errorCode = null;
        if (response) {
            errorCode = getErrorCodeByResponse(response);
        }
        if (!dispatchedErrorAction) {
            yield put(
                onReportsErrorAction({
                    msg: `invalid response code ${response} - ${errorCode}`,
                    details: errorCode,
                })
            );
        }
        if (statusUpdateAction) {
            yield put(
                statusUpdateAction({
                    status: ReqStatus.FAIL,
                    reportName,
                })
            );
        }
    }
}

export interface HandleResponseConfigV2 {
    response: string | undefined | number;
    status: number;
    onFailure: Function;
    onSuccess: Function;
    errorMessagesEnum?: Object;
}
// ! LEGACY
export function* handleResponseV2({
    response,
    status,
    onSuccess, // action that causes mutation of the data in the state
    onFailure,
}: HandleResponseConfigV2) {
    const isValidStatusCode = status === 200;
    let dispatchedErrorAction = false;

    if (!isValidStatusCode) {
        yield handleBadStatus({ status });
        dispatchedErrorAction = true;
    }

    if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);
    // console.log({ response, status, onSuccess, onFailure });
    if (response && +response === 0) {
        // -- succuss based on response
        yield put(onSuccess());
    } else if (status === 202) {
        // -- failed based on status
        if (onFailure) {
            yield put(onFailure());
        }
        yield put(setErrorMessage(ErrorMessagesOld.MissingWs));
    } else {
        // -- failed based on response
        // response is not '0'
        let errorCode = null;
        if (response) {
            errorCode = getErrorCodeByResponse(String(response));
        }
        if (!dispatchedErrorAction) {
            let msg = `invalid response code ${response} - ${errorCode}`;
            if (response === '1') {
                msg = i18n.t('expiredTokenRefreshPage');
            }
            // -- reports error action
            yield put(
                onReportsErrorAction({
                    msg,
                    details: errorCode,
                })
            );
            // -- general error action
            yield put(setErrorMessage(msg));
        }
        if (onFailure) {
            yield put(onFailure());
        }
    }
}

export function* handleResponseV3({
    response,
    status,
    onSuccess,
    onFailure,
    errorMessagesEnum,
}: HandleResponseConfigV2) {
    const isValidStatusCode = status === 200;
    let dispatchedErrorAction = false;

    if (!isValidStatusCode) {
        yield handleBadStatus({ status });
        dispatchedErrorAction = true;
    }

    if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);
    // console.log({ response, status, onSuccess, onFailure });
    if (response !== undefined && +response === 0) {
        yield onSuccess();
    } else {
        // response is not '0'
        if (!dispatchedErrorAction) {
            let msg = i18n.t('oopsSomethingWentWrong');

            if (response !== undefined) {
                msg = errorMessagesEnum
                    ? errorMessagesEnum[response]
                    : `${i18n.t('oopsSomethingWentWrong')} - Bad response: ${response}`;
                // -- general error action
            }

            yield put(setErrorMessage(msg));
        }
        if (onFailure) {
            yield onFailure();
        }
    }
}

export interface HandleMockServerResponseConfigV1 {
    response: MockRouteResponse<any>;
    onFailure: Function;
    onSuccess: Function;
}
export function* handleMockServerResponseV1({
    response,
    onSuccess,
    onFailure,
}: HandleMockServerResponseConfigV1) {
    if (response.code === CommonCodes.BadToken) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

    if (response.code === CommonCodes.Ok) {
        yield onSuccess();
    } else {
        yield onFailure();
    }
}

export interface HandleErrorConfig {
    errorAction?: any;
    msg?: string;
    details?: string;
}
/**
 * A generator function that handles errors.
 * @param {HandleErrorConfig} config - The configuration object for the generator.
 * @returns None
 */
export function* handleSagaError({ errorAction, msg = '', details = '' }: HandleErrorConfig) {
    // console.error(error);
    if (errorAction)
        yield put(
            // # errors specified for the current request
            errorAction({
                status: ReqStatus.FAIL,
            })
        );
    yield put(
        // # global error handler for displaying errors - only for reports
        onReportsErrorAction({
            msg,
            details,
        })
    );
}
export function* dispatchGlobalErrorAction(error: unknown) {
    yield put(setErrorMessage((error as Error).message));
}
export function* handleUnknownError(onErrorActionArg: Function, error: unknown, details?: string) {
    console.log(error);
    yield put(
        onErrorActionArg({
            msg: (error as Error).message,
            details: details || '',
        })
    );
}
