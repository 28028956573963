import React, { useState } from 'react';

import DatePickerWithPrevNext from 'src/components/DatePicker/DatePickerWithPrevNext';
import DropDown from 'src/components/DropDown/DropDown';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Input from 'src/components/commons/Input/Input';
import { InputAdornment } from '@material-ui/core';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useRootAppSelector } from 'src/store/hooks';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { selectedReportNameSelector } from 'src/store/selectores/reportsSelectors';
import ApplyFiltersBtn from '../../ApplyFiltersBtn/ApplyFiltersBtn';

import s, { styledMui } from '../../Filters.style';
import useDropdownProps from '../../hooks/useDropdownProps';
import useGlobalFilter from '../../hooks/useGlobalFilter';
import ClearFiltersBtn from '../../ClearFiltersBtn/ClearFiltersBtn';
import { DefaultAccordionProps } from '../PassengerReportFilters/PassengersReportFilter';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Props = DefaultAccordionProps;

const ShortOrdersReportFilters = ({ setClientSideFilters, expandConfig }: Props): JSX.Element => {
    const selectedReport = useRootAppSelector(selectedReportNameSelector);

    const { departmentProp, rideTypeProp, pickUpTimeProp, dropOffTimeProp, datePickerProps } =
        useDropdownProps({ report: selectedReport });

    // -- global filter handling
    const { globalFilterProp } = useGlobalFilter({
        setFilters: setClientSideFilters,
    });

    return (
        <styledMui.FiltersAccordion expanded={expandConfig?.isExpanded} elevation={0}>
            <styledMui.MyAccordionSummary
                expandIcon={expandConfig?.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                    onClick: expandConfig?.toggle,
                    // style: { padding: '10px 0 10px 10px' },
                }}
            >
                <s.AccordionBtnsContainer
                    gtc="160px 160px 140px 120px 120px 80px 80px"
                    className="AccordionBtnsContainer"
                    paddingLeft="12px"
                >
                    <DatePickerWithPrevNext {...datePickerProps.fromDateProps} />
                    <DatePickerWithPrevNext {...datePickerProps.toDateProps} />
                    <Input
                        {...globalFilterProp}
                        className="reports-free-search"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                    <DropDown {...departmentProp} />
                    <DropDown {...rideTypeProp} />
                    <ApplyFiltersBtn />
                    <ClearFiltersBtn />
                </s.AccordionBtnsContainer>
            </styledMui.MyAccordionSummary>
            <AccordionDetails sx={{ width: '96.5%' }}>
                <s.AccordionBtnsContainer
                    gtc="160px 160px"
                    className="AccordionBtnsContainer"
                    paddingLeft="12px"
                >
                    <DropDown {...pickUpTimeProp} />
                    <DropDown {...dropOffTimeProp} />
                </s.AccordionBtnsContainer>
            </AccordionDetails>
        </styledMui.FiltersAccordion>
    );
};

export default ShortOrdersReportFilters;
