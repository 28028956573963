/* eslint-disable no-plusplus */
/* eslint-disable import/no-named-as-default */
/* eslint-disable array-callback-return */
import moment, { Moment } from 'moment';
/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DATE_FORMATS, { SHORT } from 'src/constants/dates';
import useDate from 'src/hooks/useDate';
import { IRootReducer } from 'src/store/reducers';
import {
    editShiftSelector,
    selectedSingleShiftSelector,
    tableSelector,
} from 'src/store/selectores/passengerShiftsSelectores';

import { useRootAppSelector } from 'src/store/hooks';
import { apiCallStatusesSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import Spinner from 'src/components/Spinner/Spinner';
import { isSameDate, stringifyDate } from 'src/utilis/utilis';
import { IPassengerShifts } from '../../../../../../types/manualOrders/api.types';
import { PlacemenContext } from '../../context';
// eslint-disable-next-line import/no-named-as-default-member
import useDatesStatus from '../../hooks/useOrderDaysData';
import { DateDataForOrdering, SelectableStatus } from '../../types';
import * as Styles from './MoreDays.Style';
import Day from './Day/Day';
import { moreDaysHooks, useOnAddDayUtils, useShiftExistsForAll } from '../../hooks/common';
import { isRelativeDate } from '../../utilis';
import { CenterFlexDiv } from '../../../../../../style/styledComponents.style';

// * Types
interface Props {
    // initialDate: Moment | Date;
    // relativeInitialDate: string;
    selectedPassengersFullData: IPassengerShifts[];
}

// * Constants
export const TWO_WEEKS = 13;

const useSelectors = () => {
    const selectedSingleShiftDate = useSelector(
        (state: IRootReducer) => selectedSingleShiftSelector(state)?.date
    );

    const editShiftDate = useSelector((state: IRootReducer) => editShiftSelector(state)?.date);
    return { editShiftDate, selectedSingleShiftDate };
};

// * React Components ------------------------------------------------
const MoreDays: FC<Props> = ({ selectedPassengersFullData }) => {
    // * Hooks -------------------------------------------------------
    const [placment, setPlacment] = useContext(PlacemenContext);
    const { t } = useTranslation();
    const { selectedSingleShiftDate, editShiftDate } = useSelectors();

    // & Util hooks ----------------------------------------------------
    const { shortsDays } = useDate();

    const datesForOrders = useDatesStatus(selectedPassengersFullData);

    const { weeks, isWeekOnTable } = moreDaysHooks.useWeeks(datesForOrders);

    const fetchedPlacementPassengerShifts = useRootAppSelector(
        (state) => apiCallStatusesSelector(state, 'placementGetPassengerShifts') === 'SUCCESS'
    );
    const fetchedTablePassengerShifts = useRootAppSelector(
        (state) => apiCallStatusesSelector(state, 'tableGetPassengerShifts') === 'SUCCESS'
    );

    const allPsngrsHaveChosenShiftOrderedAlreadyForDate = useShiftExistsForAll();

    const { onAddDay, selectedDaysCounter, setSelectedDaysCounter } = useOnAddDayUtils();

    // * Utils -------------------------------------------------

    const isDayFocused = useCallback(
        (mapLoopDate): boolean => {
            const orderPlacedOnDate = placment.dates.some((someLoopDate) => {
                return someLoopDate.relativeDate === moment(mapLoopDate.date).format(DATE_FORMATS.LONG);
            });
            if (orderPlacedOnDate) return true;
            return false;
        },
        [placment]
    );

    // * Effects -------------------------------------------------
    useEffect(() => {
        // console.log(235423);
        if (selectedSingleShiftDate || editShiftDate) setSelectedDaysCounter(1);
    }, [selectedSingleShiftDate, editShiftDate, setSelectedDaysCounter]);

    useEffect(() => {
        // console.log(23542213213);
        if (placment.dates.length === 1 && selectedDaysCounter === 0) {
            setPlacment((pre) => ({ ...pre, dates: [] }));
        }
    }, [placment.dates, selectedDaysCounter, setPlacment]);

    // * JSX ------------------------------------------------------
    return (
        <>
            {fetchedPlacementPassengerShifts && fetchedTablePassengerShifts ? (
                <Styles.Container>
                    <Styles.Title>{t('moreDays')}</Styles.Title>
                    {Object.values(weeks || {}).map((weekDatesForOrders) => (
                        // -- Row -> [Day, Day, Day, Day, Day, Day, Day]
                        <Styles.WeekContainer isWeekOnTable={isWeekOnTable(weekDatesForOrders[0]?.date)}>
                            <Styles.DaysContainer>
                                {weekDatesForOrders.map((mapLoopDate: DateDataForOrdering) => {
                                    // -- Day components on said row
                                    return (
                                        <Day
                                            date={mapLoopDate.date}
                                            shortsDays={shortsDays}
                                            disableData={mapLoopDate.selectableConfig}
                                            onAddDay={(val: boolean, disabled: boolean) =>
                                                onAddDay(val, mapLoopDate.date, disabled)
                                            }
                                            isCurrDay={isSameDate(new Date(), mapLoopDate.date)}
                                            isFoucs={isDayFocused(mapLoopDate)}
                                            isOrderedAlready={allPsngrsHaveChosenShiftOrderedAlreadyForDate(
                                                mapLoopDate
                                            )}
                                        />
                                    );
                                })}
                            </Styles.DaysContainer>
                        </Styles.WeekContainer>
                    ))}
                </Styles.Container>
            ) : (
                <CenterFlexDiv>
                    <Spinner />
                </CenterFlexDiv>
            )}
        </>
    );
};

export default MoreDays;
// const isBadStatus = (status: SelectableStatus | null): boolean => {
//    return status !== SelectableStatus.Selectable && status !== null;
// };

// const Day: FC<DayProps> = ({
//    date,
//    shortsDays,
//    disableData,
//    isCurrDay,
//    onAddDay,
//    isFoucs,
// }) => {
//    // const [isFoucs, setIsFoucs] = useState(isCurrDay);
//    const day = date.get('day');
//    const onClick = () => onAddDay(!isFoucs);
//    const canBeEnabled = !BLOCKED_FOR_RE_ENABLING_REASONS.includes(
//       disableData.reason,
//    );
//    // const [placment, setPlacment] = useContext(PlacemenContext);

//    const isShiftSelected = useSelector(
//       (state: IRootReducer) => formSelector(state).isShiftSelected,
//    );
//    const editedShift = useSelector((state: IRootReducer) =>
//       editShiftSelector(state),
//    );
//    const selectedSingleShiftDate = useSelector(
//       (state: IRootReducer) => selectedSingleShiftSelector(state)?.date,
//    );
//    const isDisabledResult = disableData.result;
//    const { removeDateFromPlacement } = usePlacement();

//    const isDisabled = useCallback(() => {
//       if (!canBeEnabled) return true;
//       if (
//          date.isSame(moment(editedShift?.date)) &&
//          isShiftSelected &&
//          canBeEnabled
//       ) {
//          return false;
//       }
//       if (!isShiftSelected || isDisabledResult) return true;
//       return false;
//    }, [canBeEnabled, date, editedShift, isDisabledResult, isShiftSelected]);

//    if (isDisabled()) {
//       if (editedShift?.date && !isSameDate(editedShift?.date, date)) {
//          // console.log('removed', date.format(RELATIVE));
//          removeDateFromPlacement(date);
//       } else if (
//          selectedSingleShiftDate &&
//          !isSameDate(selectedSingleShiftDate, date)
//       ) {
//          // console.log('removed', date.format(RELATIVE));
//          removeDateFromPlacement(date);
//       }
//    }

//    return (
//       <Styles.DayContainer
//          disable={isDisabled()}
//          canBeEnabled
//          isFoucs={isFoucs}
//          onClick={onClick}
//          isCurrDay={isCurrDay}
//       >
//          <div> {shortsDays[day]}</div>
//          <div>{date.format('DD.MM')}</div>
//       </Styles.DayContainer>
//    );
// };
