import _ from 'lodash';
import { PermissionDataKeys } from 'src/types/rideSettings/common';
import { Permission, SlPermissionTypes } from 'src/types/rideSettings/store/types.store';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isLastItem = (itemIdx: number, array: any[]): boolean => {
    return itemIdx === array.length - 1;
};

export const tempTranslate = (str: string) => {
    let translated = '';
    switch (str) {
        case 'level1':
            translated = 'רמה 1';
            break;
        case 'level2':
            translated = 'רמה 2';
            break;
        case 'level3':
            translated = 'רמה 3';
            break;
        case 'level4':
            translated = 'רמה 4';
            break;
        case 'level5':
            translated = 'רמה 5';
            break;
        default:
            break;
    }
    return translated;
};

const permissionDataKeys: PermissionDataKeys[] = ['permissionCode', 'permissionType'];

export const getLvlsWithPerm = (setting: Permission | undefined): SlPermissionTypes[] => {
    if (!setting) {
        return [];
    }

    const lvlEntries = Object.entries(setting).filter(([key, val]: [any, any]) => {
        if (permissionDataKeys.includes(key) || val === '0') return false;
        return true;
    });

    const lvlsWithPerm: string[] = [];

    lvlEntries.forEach(([permLvl, hasPerm]) => {
        if (Number(hasPerm)) lvlsWithPerm.push(permLvl);
    });

    return lvlsWithPerm as SlPermissionTypes[];
};

export const getAllowedLevelsStr = (setting: Permission): string => {
    let allowedLevelsString = '';

    const p = Object.entries(setting)
        .filter(([key, val]) => {
            if (permissionDataKeys.includes(key as any) || val === '0') return false;
            return true;
        })
        .map((item) => item[0]);

    p.forEach((item, i) => {
        allowedLevelsString += `${tempTranslate(item)}${i < p.length - 1 ? ', ' : ''}`;
    });

    return allowedLevelsString;
};

export const getFormattedNewSettings = (
    havePermissions: string[],
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    initialSettings: any
): any => {
    const levels = ['level1', 'level2', 'level3', 'level4', 'level5'];

    const newSettings = { ...initialSettings };

    const lvlsWithoutPermission = levels.filter((level) => !havePermissions.includes(level));

    havePermissions.forEach((level) => {
        newSettings[level] = '1';
    });
    lvlsWithoutPermission.forEach((level) => {
        newSettings[level] = '0';
    });

    return newSettings;
};

export const prmsAreEquals = (initialSettings: any, newSettings: any): boolean => {
    return _.isEqual(initialSettings, getFormattedNewSettings(newSettings, initialSettings));
};
