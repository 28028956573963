import { useTranslation } from 'react-i18next';
import { useRootAppSelector } from 'src/store/hooks';
import { courseBuildingFormSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import useGetCourseDataByCode from '../useGetCourseDataByCode';

const useFormHeader = () => {
    const { t } = useTranslation();
    const formSlice = useRootAppSelector(courseBuildingFormSelector);

    const { getCourseDataByCode } = useGetCourseDataByCode();

    const isEditingCourse = !!formSlice.courseCode;

    const mainText = isEditingCourse ? t('courseEditing') : t('crouseBuilding');

    return {
        mainText,
        secondaryText: formSlice.courseCode
            ? ` - ${getCourseDataByCode(formSlice.courseCode)?.courseDescription}`
            : '',
    };
};

export default useFormHeader;
