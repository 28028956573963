import minibus from './images/Minibus.png';
import target from './images/Vector.svg';
import frontViewSmallBus from './images/frontViewSmallBus.png';
import busAlertIcon from './images/bus_alert_icon.svg';
import alartIcon from './icons/alart.svg';
import steeringWheelIcon from './icons/SteeringWheel.svg';
import edit from './icons/edit.svg';
import trash from './icons/trash.svg';
import grayTrash from './icons/gray_trash.svg';
import restoreIcon from './icons/restoreIcon.svg';

export const images = {
    minibus,
    target,
    steeringWheelIcon,
    frontViewSmallBus,
    busAlertIcon,
    alartIcon,
    edit,
    trash,
    grayTrash,
    restoreIcon,
};
