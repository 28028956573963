/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from 'src/store/actions/loginAction';

function useLocalStorage<T>(key: string, initialValue: T) {
    const dispatch = useDispatch();

    const dispatchErrorAction = () => {
        dispatch(setErrorMessage('Error - useLocalStorage'));
    };
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            dispatchErrorAction();

            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T), withManualEventDispatch?: boolean) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }

            /*
                Sometimes needed to be fired manually because:
                MDN - The storage event of the Window interface fires when a storage area (localStorage or sessionStorage) 
                has been modified in the context of another document.
            */
            if (withManualEventDispatch) {
                const event = new Event('storage');
                window.dispatchEvent(event);
            }
        } catch (error) {
            dispatchErrorAction();
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue] as const;
}

export default useLocalStorage;
