/* eslint-disable consistent-return */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import OptionsCell from '../components/OptionsCell';
import SimulationOptionsCell from '../components/SimulationOptionsCell';

export interface IColumnHook {
    headerSelectionValue?: boolean;
    hoverRowRouteId: string | null;
}

const useSimulationColumns = ({
    headerSelectionValue,
    hoverRowRouteId,
}: IColumnHook): { columns: Array<GridColumnProps> } => {
    const { t } = useTranslation();

    const { width } = window.screen;

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'isSelected',
                headerSelectionValue,
                reorderable: false,
                width: 30,
                className: 'isSelected-col',
                resizable: false,
                headerCell: () => null,
            },
            {
                field: 'routeName',
                title: t('suggestedRoutes'),
                editor: 'text',
                width: 278,
                minResizableWidth: 50,
            },
            {
                field: 'actions',
                title: t('actions'),
                width: 50,
                headerClassName: 'k-header k-actions-cell-header',
                headerCell: () => null,
                cell: (cellProps) => (
                    <SimulationOptionsCell cellProps={cellProps} hoverRowRouteId={hoverRowRouteId} />
                ),
                resizable: false,
            },
        ],
        [headerSelectionValue, t, width, hoverRowRouteId]
    );

    return { columns };
};

export default useSimulationColumns;
