/* eslint-disable import/no-cycle */

import { SagaIterator } from 'redux-saga';
import { takeLatest, put, StrictEffect } from 'redux-saga/effects';
import { IAccount } from 'src/types/login';
import { validateToken, updateToken } from '../../api/api';
import { LoginActionType } from '../actions/actionType';
import * as actions from '../actions/loginAction';
import { ILoginState } from '../type';

// interface IRes {
//     response: string;
//     data: object;
//     autoLogin: string;
// }
export interface IContactLoginData {
    fcAccounts: IAccount[];
    gps_token: string;
    token: string;
    userUUID: string;
}
/*  set validateToken and update token */

export interface IValidateTokenResData {
    autoLogin: '1' | '2' | '3' | '4';
    data: IContactLoginData[];
    response: '0' | '1' | '99';
}

function* initConnect({ payload }: Partial<{ payload: { deviceToken: string }; type: string }>): any {
    try {
        if (!payload || !payload.deviceToken) {
            yield put(actions.initConnectFail({}));
            return;
        }

        const response = yield validateToken({
            deviceToken: payload?.deviceToken,
        });

        const { data = {}, status } = response;
        const resData: IValidateTokenResData = data;

        if (data?.response === '0' && status === 200) {
            const promises: Array<Promise<void>> = [];

            const loginData: IContactLoginData = resData?.data[0] || {};
            const fcAccounts: IAccount[] = loginData.fcAccounts || [];
            const token: string = loginData.token || '';

            fcAccounts.forEach(({ proxyUrl, contactUUID, dbUrl }) => {
                promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
            });

            yield Promise.all(promises);

            yield put(
                actions.initConnectSuccess({
                    ...loginData,
                    loginType: Number(resData?.autoLogin),
                })
            );
        } else yield put(actions.initConnectFail({}));
    } catch (error) {
        console.log(error);
        yield put(actions.initConnectFail({}));
    }
}

function* initLogout({ payload }: Partial<{ payload: { token: string }; type: string }>): any {
    try {
        const response = yield validateToken({
            deviceToken: payload?.token || '',
        });
        const { data = {} } = response;

        // if (data?.response === '0') {
        //    console.log('initConnectSuccess data:', data);
        //    yield put(
        //       actions.initConnectSuccess({
        //          ...(data?.data[0] || {}),
        //          loginType: Number(data?.auoLogin),
        //       }),
        //    );
        // } else yield put(actions.initConnectFail({}));
    } catch (error) {
        console.log(error);

        yield put(actions.initConnectFail({}));
    }
}

export default function* loginSaga() {
    yield takeLatest(LoginActionType.initialConfiguration, initConnect);
    yield takeLatest(LoginActionType.Logout, initLogout);
}
