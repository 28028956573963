import { ReqConfig, AxiosRes } from 'src/types/apiCommon.types';
import { makeApiCallV2, makeApiCallV3 } from '../utilis';

// WS -- Get_CarTypes_Price_By_Course---------------------------------------------------------------------------
enum ResponseCodes {
    Success = '0',
    InvalidToken = '1',
    MissingCourseCode = '2',
    SQLError = '99',
}

interface Params {
    token: string;
    courseCode: string | number;
    relativeDate?: string; // YYYY-MM-DD
}

export interface ICarTypePriceByCourseWsDataItem {
    carTypeCode: number;
    carType: string;
    passQty: number; // >= 0
    clientPrice: string; // numeric string
}

export type CarTypePriceByCourseReqConfig = ReqConfig<Params>;

interface WsResponse {
    response: ResponseCodes;
    data: ICarTypePriceByCourseWsDataItem[]; // if sql error - will be string
}

// FC REQUEST

export const getCarTypesPriceByCourse = (
    requestConfig: CarTypePriceByCourseReqConfig
): AxiosRes<WsResponse> =>
    makeApiCallV3<Params, WsResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_CarTypes_Price_By_Course',
    });
