import React from 'react';

const ExtraCssBorderHider = ({
    type,
    hasTimeFromPrev,
}: {
    type: 'top' | 'bottom';
    hasTimeFromPrev: boolean;
}) => {
    const getTopDistance = () => {
        if (type === 'bottom' && hasTimeFromPrev) {
            return '45px';
        }
        if (type === 'bottom' && !hasTimeFromPrev) {
            return '20px';
        }

        // top
        return '-5px';
    };
    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    width: '10px',
                    height: type === 'top' ? '8px' : '35px',
                    zIndex: 999,
                    backgroundColor: '#ffffff',
                    position: 'absolute',
                    left: '0px',
                    top: getTopDistance(),
                }}
            />
        </div>
    );
};

export default ExtraCssBorderHider;
