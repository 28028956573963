import { Dialog } from '@mui/material';
import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import heLocal from 'date-fns/locale/he';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider, PickersDayProps } from '@mui/x-date-pickers';
import NakedBtn from 'src/components/buttons/NakedBtn';
import MyCalenderIcon from 'src/components/Icons/MyCalenderIcon';
import moment from 'moment';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';

const calcWidth = (w: number) => {
    if (window.screen.width > 1900) return w;
    if (window.screen.width > 1700) return w * 0.8;
    if (window.screen.width > 1600) return w * 0.7;
    return w * 0.6;
};

const getDaysCountInMonth = (month: Date): number => {
    return new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
};

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */

export function getDaysInMonth(month: number, year: number) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

const Day = ({
    day,
    selectedDays,
    selectedMonth,
    setSelectedDays,
    ...restProps
}: PickersDayProps<Date> & {
    selectedDays: Date[];
    selectedMonth: Date;
    setSelectedDays: (d: Date[]) => void;
}) => {
    const dayNum = day.getDate();
    const isSelected = selectedDays.findIndex((d) => d.getDate() === day.getDate()) !== -1;

    const isInCurrMonth = day.getMonth() === selectedMonth.getMonth();

    const handleDayClick = (dayVal: Date | null) => {
        if (dayVal) {
            const index = selectedDays.findIndex((d) => d.getDate() === dayVal.getDate());
            if (index !== -1) {
                // Remove date if it's already selected
                setSelectedDays(selectedDays.filter((_, i) => i !== index));
            } else {
                // Add date if it's not selected
                setSelectedDays([...selectedDays, dayVal]);
            }
        }
    };

    return (
        <div
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
                display: 'flex',
                width: '35px',
                margin: '0 3px',
            }}
        >
            {isInCurrMonth ? (
                <button
                    style={{
                        borderRadius: '50%',
                        border: 'none',
                        flex: 1,
                        height: '100%',
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: isSelected ? '#51a8ff' : 'white',
                        color: isSelected ? 'white' : 'black',
                    }}
                    onClick={() => handleDayClick(day)}
                    type="button"
                >
                    {dayNum}
                </button>
            ) : null}
        </div>
    );
};

const Input: React.FC<{
    containerProps?: Record<string, any>;
    selectedDays: Date[];
}> = ({ containerProps = {}, selectedDays }) => {
    const getText = (): string => {
        if (!selectedDays.length) return '';

        const daysCountInCurrMonth = getDaysCountInMonth(selectedDays[0]);

        if (selectedDays.length === daysCountInCurrMonth) {
            // only display month
            return moment(selectedDays[0]).format('MM/YYYY');
        }

        return selectedDays.map((d) => moment(d).format('DD/MM')).join(', ');
    };

    return (
        <MuiTooltipV3 title={getText()} placement="bottom-end">
            <div
                style={{
                    width: '100%',
                    maxWidth: `${calcWidth(210)}px`,
                    height: '40px',
                    border: '1px solid #b8b8b8',
                    borderRadius: '4px',
                    background: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 6px',
                    position: 'relative',
                }}
                {...containerProps}
            >
                {selectedDays.length ? (
                    <p
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '80%',
                        }}
                    >
                        {getText()}
                    </p>
                ) : null}
                <p
                    style={
                        selectedDays.length
                            ? {
                                  color: 'gray',
                                  fontSize: 11,
                                  position: 'absolute',
                                  top: -5,
                                  right: 9,
                                  background: 'white',
                                  lineHeight: 1,
                              }
                            : { color: 'gray' }
                    }
                >
                    תאריכים
                </p>
                <MyCalenderIcon />
            </div>
        </MuiTooltipV3>
    );
};

export const PassengersReportDatePicker: React.FC<{
    isOpen: boolean;
    currSavedValues: {
        days: Date[];
        month: Date;
    };
    onConfirm: (newValues: { days: Date[]; month: Date }) => void;
    onCancel: () => void;
    openCalender: () => void;
    closeCalender: () => void;
}> = ({ isOpen, onCancel, onConfirm, currSavedValues, openCalender, closeCalender }) => {
    const [currValues, setCurrValues] = React.useState(currSavedValues);

    const setSelectedDays = (newDays: Date[]) => {
        setCurrValues((prev) => ({ ...prev, days: newDays }));
    };

    const onClickToday = () => {
        const today = new Date();
        setCurrValues((prev) => ({
            ...prev,
            month: today,
        }));

        // use timeout because when changing the month it triggers the onMonthChange which resets the day,
        // so we need to re set the day to the current day
        setTimeout(() => {
            setCurrValues((prev) => ({
                ...prev,
                days: [today],
            }));
        }, 10);
    };

    const onClickSelectAll = () => {
        setCurrValues((prev) => ({
            ...prev,
            days: getDaysInMonth(prev.month.getMonth(), prev.month.getFullYear()),
        }));
    };

    const onClickRemoveAll = () => {
        setCurrValues((prev) => ({
            ...prev,
            days: [],
        }));
    };

    const isSelectedEntireMonth = getDaysCountInMonth(currValues.month) === currValues.days.length;

    return (
        <>
            <Dialog open={isOpen}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={heLocal}>
                    <StaticDatePicker
                        onChange={(val: Date | null) => {}}
                        value={currValues.month} // No single value is selected
                        onMonthChange={(m: Date) => {
                            setCurrValues((prev) => ({ days: [], month: m }));
                        }}
                        onYearChange={(y: Date) => {
                            setCurrValues((prev) => ({
                                days: [],
                                month: y,
                            }));
                        }}
                        renderDay={(day: Date, _: any, pickersDayProps: PickersDayProps<any>) => (
                            <Day
                                {...pickersDayProps}
                                day={day}
                                selectedDays={currValues.days}
                                selectedMonth={currValues.month}
                                setSelectedDays={setSelectedDays}
                            />
                        )}
                        renderInput={() => <div />}
                        ToolbarComponent={() => <div />}
                    />
                </LocalizationProvider>

                <div
                    style={{
                        justifySelf: 'end',
                        padding: 16,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <NakedBtn onClick={onClickToday} type="add" text="היום" withoutIcon sx={{}} />
                        {isSelectedEntireMonth ? (
                            <NakedBtn onClick={onClickRemoveAll} type="add" text="הסר הכל" withoutIcon />
                        ) : (
                            <NakedBtn onClick={onClickSelectAll} type="add" text="בחר הכל" withoutIcon />
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <NakedBtn
                            onClick={() => {
                                onConfirm(currValues);
                                closeCalender();
                            }}
                            type="add"
                            text="אישור"
                            withoutIcon
                            disabled={!currValues.days.length}
                        />
                        <NakedBtn
                            onClick={() => {
                                onCancel();
                                setCurrValues(currSavedValues); // resetting state
                            }}
                            type="add"
                            text="ביטול"
                            withoutIcon
                        />
                    </div>
                </div>
            </Dialog>
            <Input
                selectedDays={currSavedValues.days}
                containerProps={{
                    onClick: () => {
                        openCalender();
                    },
                }}
            />
        </>
    );
};
