import { CSSProperties } from 'react';
import defaultTheme from '../../../../style/themes/defaultTheme';

export const addressContainerStyle = { minHeight: '50px', margin: '5px 0px' };
export const addressSpanStyle = { textDecoration: 'underline' };
export const personIconStyle: CSSProperties = {
    height: '30px',
    width: '30px',
    marginLeft: '10px',
    background: '#ffedb1',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // eslint-disable-next-line import/no-named-as-default-member
    color: defaultTheme.msColors.primaryTextcolor,
};
