/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Severity } from 'src/hooks/useAlert';
import { IRootReducer } from 'src/store/reducers';
import { getCurrentPath } from 'src/utilis/utilis';

// Define the initial state using that type
export interface SetUserDetailsPayload {
    departmentCode: string | null;
    email: string;
    userName: string;
}
// * Slice
export interface CommonSliceState {
    path: string;
    alert: {
        severity: Severity | null;
        message: string;
    };
    userDetails: {
        departmentCode: string | null;
        email: string;
        userName: string;
    };
    isRedirectPopupOpen: boolean;
    googleApiInterfaceInterval: number | null | undefined;
    isGoogleApiInterfaceCounterExceeded: boolean;
    isGoogleApiCounterExceededPopUpOpen: boolean;
    lastTimeCalledIturan: string | null;
}

const initialAlertState = {
    severity: null,
    message: '',
};
const initialState: CommonSliceState = {
    path: '/',
    alert: initialAlertState,
    userDetails: {
        departmentCode: null,
        email: '',
        userName: '',
    },
    isRedirectPopupOpen: false,
    googleApiInterfaceInterval: null,
    isGoogleApiInterfaceCounterExceeded: false,
    isGoogleApiCounterExceededPopUpOpen: false,
    lastTimeCalledIturan: null,
};
export type AlertConfigPayload = null | {
    severity: Severity | null;
    message: string;
};
export const commonSlice = createSlice({
    name: 'common',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLastTimeCalledIturan: (state: CommonSliceState, action: PayloadAction<string>) => {
            if (action.payload) {
                state.lastTimeCalledIturan = action.payload;
                return;
            }
            state.alert.message = initialAlertState.message;
        },
        onPathChange: (state: CommonSliceState) => {
            state.path = getCurrentPath();
        },
        setAlertConfig: (state: CommonSliceState, action: PayloadAction<AlertConfigPayload>) => {
            if (action.payload) {
                state.alert = action.payload;
                return;
            }
            state.alert.message = initialAlertState.message;
        },
        setUserDetails: (state: CommonSliceState, action: PayloadAction<SetUserDetailsPayload>) => {
            state.userDetails = action.payload;
        },
        setIsRedirectPopupOpen: (
            state: CommonSliceState,
            action: PayloadAction<{ isRedirectPopupOpen: boolean }>
        ) => {
            state.isRedirectPopupOpen = action.payload.isRedirectPopupOpen;
        },
        setGoogleApiInterfaceInterval: (
            state: CommonSliceState,
            action: PayloadAction<{ interval: number | null | undefined }>
        ) => {
            state.googleApiInterfaceInterval = action.payload.interval;
        },
        setIsGoogleApiInterfaceCounterExceeded: (
            state: CommonSliceState,
            action: PayloadAction<{ isExceeded: boolean }>
        ) => {
            state.isGoogleApiInterfaceCounterExceeded = action.payload.isExceeded;
        },
        setIsGoogleApiCounterExceededPopUpOpen: (
            state: CommonSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ) => {
            state.isGoogleApiCounterExceededPopUpOpen = action.payload.isOpen;
        },
    },
});

// * Exports
// ^ Actions
export const {
    onPathChange: onPathChangeAction,
    setAlertConfig: setAlertConfigAction,
    setUserDetails: setUserDetailsAction,
    setIsRedirectPopupOpen: setIsRedirectPopupOpenAction,
    setGoogleApiInterfaceInterval: setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiInterfaceCounterExceeded: setIsGoogleApiInterfaceCounterExceededAction,
    setIsGoogleApiCounterExceededPopUpOpen: setIsGoogleApiCounterExceededPopUpOpenAction,
    setLastTimeCalledIturan: setLastTimeCalledIturanAction,
} = commonSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// ^ Selectors
export const commonRootSelector = (state: IRootReducer): CommonSliceState => state.commonSlice;

export const alertConfigSelector = (state: IRootReducer) => commonRootSelector(state).alert;

export const userDetailsSelector = (state: IRootReducer) => commonRootSelector(state).userDetails;

export default commonSlice.reducer;
