import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import styled from 'styled-components';

const BtnStyle = styled(ButtonBase)`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-size: 16px;
    &.MuiButtonBase-root {
        margin-top: 15px;
        text-decoration: underline;
    }
`;

interface Props {
    btnText: string;
    onClick: () => void;
}

const BtnLink = ({ btnText, onClick }: Props) => {
    return <BtnStyle onClick={onClick}>{btnText}</BtnStyle>;
};

export default BtnLink;
