// detect language by number of chars
export const detectLanguageByCharCount = (str: string): 'iw' | 'en' | 'ar' => {
    const hebrewCharsCount = (str.match(/[א-ת]/g) || []).length;
    const englishCharsCount = (str.match(/[a-zA-Z]/g) || []).length;
    const arabicCharsCount = (str.match(/[ء-ي]/g) || []).length;

    if (arabicCharsCount > hebrewCharsCount && arabicCharsCount > englishCharsCount) {
        return 'ar';
    }
    if (hebrewCharsCount > arabicCharsCount && hebrewCharsCount > englishCharsCount) {
        return 'iw';
    }
    return 'en';
};

export const lorem: (length: number) => string = (length: number) => {
    let result = '';
    const characters = 'abcd efgh ijkl mnop qrst uvw xyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

const langHelpers = {
    detectLanguageByCharCount,
    lorem,
};

export default langHelpers;
