import { Button } from '@mui/material';
import React from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { changePickCourseFormShowAction } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { colors } from 'src/style/themes/defaultTheme';
import { useTranslation } from 'react-i18next';

export {};

type Props = {
    addressCode?: string | undefined;
    isDisabled: boolean;
    isCourseSelected: boolean;
};
const sx = {
    fontFamily: 'Rubik',
    marginRight: '10px',
    color: colors.blue,
    border: `1px solid ${colors.blue}`,
    height: '40px',
};
const withSelectedCourseSx = {
    fontFamily: 'Rubik',
    marginRight: '10px',
    background: colors.blue,
    height: '40px',
};
const ChooseCourseBtn = ({ addressCode, isCourseSelected, isDisabled }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const handleClick = () => {
        dispatch(
            changePickCourseFormShowAction({
                show: true,
                addressCode: addressCode || null,
            })
        );
    };
    return (
        // <div />
        <Button
            disabled={isDisabled}
            onClick={handleClick}
            variant={isCourseSelected ? 'contained' : 'outlined'}
            sx={isCourseSelected ? withSelectedCourseSx : sx}
        >
            {t('courseChoosing')}
        </Button>
    );
};
export default ChooseCourseBtn;
