import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { stringifyDate } from 'src/utilis/utilis';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { useAppSelector } from 'src/store/hooks';
import {
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectedReportNameSelector,
} from 'src/store/selectores/reportsSelectors';
import Dailycard from './components/Dailycard/Dailycard';
import SummaryCard from './components/Summarycard/Summarycard';
import useStatistics from './hooks/useFetchStatistics';
import { DISPLAY_UI, DISPLAY_UI_LONG, DISPLAY_UI_SHORT } from '../../../../../constants/dates';
import InfoCard from './components/InfoCard';
import { StyledContainer } from '../RidesInfo/RidesInfo';
import useSummaryDepatment from './components/Summarycard/useSummaryDepatment';
import { useWidgetsConfigData } from './hooks/common';
import widgetsHooks from '../hooks/common.hooks';

const infoCardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '20px',
};

const OrdersInfoTooltips = (): JSX.Element => {
    const { t } = useTranslation();

    const { dateRangeStr } = widgetsHooks.useDatesRange();

    return (
        <div>
            <MyReactTooltip id="ordersForPeriod">
                <p>{`${t('orderTo')} ${dateRangeStr}`}</p>
            </MyReactTooltip>
            <MyReactTooltip id="passengersForPeriod">
                <p>{`${t('passengersFor')} - ${dateRangeStr}`}</p>
            </MyReactTooltip>
        </div>
    );
};

const OrdersInfo = (): JSX.Element => {
    const { t } = useTranslation();

    const { dateAfterApplyingFilters, clientCode } = useWidgetsConfigData();

    const { ordersCount, passengersCount } = useStatistics(); // using table data NOT api

    const [orderBy, setOrderBy] = useState(1);

    const apiStats = useSummaryDepatment(orderBy, clientCode);

    return (
        <StyledContainer>
            <div style={infoCardContainerStyle}>
                <InfoCard
                    margin="0 0 0 20px"
                    count={ordersCount.pickupsCount + ordersCount.dropsCount}
                    text={`${t('ordersForPeriod')}`}
                    tooltipId="ordersForPeriod"
                />
                <InfoCard
                    count={passengersCount}
                    text={`${t('passengersForPeriod')}`}
                    tooltipId="passengersForPeriod"
                />
                <OrdersInfoTooltips />
            </div>
            <div style={{ paddingBottom: '20px' }}>
                <Dailycard pickup={ordersCount.pickupsCount} drop={ordersCount.dropsCount} />
            </div>
            {clientCode && (
                <SummaryCard
                    setOrderBy={setOrderBy}
                    orderBy={orderBy}
                    date={dateAfterApplyingFilters}
                    clientCode={clientCode}
                    stats={apiStats}
                />
            )}
        </StyledContainer>
    );
};

export default OrdersInfo;
