import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';

import { getErrorMessage } from 'src/screens/Main/utils';

import { AddressKeys } from 'src/components/AddressInputs/types';
import { ManualAndGoogleAddressInputProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import { initialAddressInfo } from 'src/components/AddressInputs/constants';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    stationsSelector,
    setStationAction,
    removeStationByIdAction,
    setCarTypesAction,
    carTypesLstSelector,
    setSelectedCarIdAction,
    passengersFormSelector,
    formSelector,
    setFormStartAddressAction,
    setFormEndAddressAction,
    triggerDirectionsAction,
    setIsTriggeredFromImportAction,
} from 'src/store/slices/lines/linesSlice';
import { isValidNumber, stringifyDate, pushIfNotExists } from 'src/utilis/utilis';
import { v4 as uuid } from 'uuid';
import { AutocompleteAddress } from 'src/components/Inputs/types';
import { ReactChangeEvent } from 'src/types/global';
import { MenuItemProps } from 'src/components/DropDown/DropDown';
import { reqGetCarTypes } from 'src/api/linesApi/linesApi';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { ErrorMessagesOld } from 'src/store/type';
import { CarType } from 'src/types/lines/api/apiProccessed.types';
import { linesBl } from 'src/api/dataMappers/linesBl';
import { AddressInput, InputNames, Inputs, Station } from '../../types';
import useGetApiRequestBasicParams from '../../../../../../hooks/useGetApiRequestBasicParams';
import { endTimeSchema } from '../../schema';
import { AddressInputComponentIds } from 'src/types/lines/addLineForm.types';
import { FillerValues } from 'src/constants/misc';
import i18n from 'src/i18n';
import commonRoutes from '../../../../../../mockServer/routes/commonRoutes';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import useBaseApiParams from 'src/hooks/api/useBaseApiParams';
import useCommons from 'src/hooks/common/useCommons';
import { useDispatch } from 'react-redux';

const useHelpers = () => {
    const fullFormMethods = useFormContext<Inputs>();

    const { setValue } = fullFormMethods;

    const updateValue = useCallback(
        (inputType: InputNames, newValue: any) => {
            setValue(inputType, newValue, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [setValue]
    );
    return { updateValue };
};

const useAddressData = ({ isDisabled }: { isDisabled?: boolean }) => {
    const { t } = useTranslation();
    const fullFormMethods = useFormContext<Inputs>();
    const {
        formState: { errors },
        getValues,
    } = fullFormMethods;
    const dispatch = useDispatch();
    const { triggerDirections, isDirectionsTriggeredFromEdit, isTriggeredFromFromImport } =
        useRootAppSelector((state) => state.linesSlice.ui.form);

    const { updateValue } = useHelpers();

    const setAddress = useCallback(
        (address: AddressInput | AutocompleteAddress, field: InputNames): void => {
            // tamir
            console.log('------ isDirectionsTriggeredFromEdit', isDirectionsTriggeredFromEdit);
            if (!isDirectionsTriggeredFromEdit && !isTriggeredFromFromImport) {
                console.log('------ in setAddress', address);

                const processedAddress = { ...address };

                // add trigger

                if (!address.city && address.placeName) {
                    processedAddress.city = address.placeName;
                }

                processedAddress.street = address.street || '';
                processedAddress.houseNum = address.houseNum || '';
                processedAddress.placeName = address.placeName || '';

                if ('lat' in processedAddress && 'lat' in address) {
                    processedAddress.lat = address.lat || 0;
                    processedAddress.lng = address.lng || 0;
                }

                if (field === 'startAddress') {
                    dispatch(setFormStartAddressAction({ startAddress: processedAddress }));
                }

                if (field === 'endAddress') {
                    dispatch(setFormEndAddressAction({ endAddress: processedAddress }));
                }

                updateValue(field, processedAddress as AutocompleteAddress);
                dispatch(triggerDirectionsAction());
            } else {
                dispatch(setIsTriggeredFromImportAction({ isFromImport: false }));
            }
        },
        [updateValue, isDirectionsTriggeredFromEdit, isTriggeredFromFromImport]
    );

    const getAddressProps = useCallback(
        (type: 'startAddress' | 'endAddress', componentId: AddressInputComponentIds) => {
            return {
                componentId,
                addressInfo: getValues(type),
                autocompleteConfig: {
                    props: {
                        error: !!errors[type],
                        setAddressData: (address: AutocompleteAddress) => {
                            console.log('address', address);
                            setAddress(address, type);
                        },
                        label: t(type),
                        errorMessage: getErrorMessage((errors[type] as any) || null),
                        disabled: isDisabled,
                        useCoords: true,
                    },
                },
                manualConfig: {
                    props: {
                        isStation: false,
                        values: getValues(type),
                        setValue: (field: AddressKeys, value: string) => {
                            const newAddressData = {
                                ...getValues(type),
                                [field]: value,
                            };

                            setAddress(newAddressData, type);
                        },
                        withExitBtn: true,
                        errors: errors[type],
                        disabled: isDisabled,
                    },
                },
            };
        },
        [errors, getValues, setAddress, t, isDisabled]
    );
    const addressProps: {
        [key: string]: ManualAndGoogleAddressInputProps;
    } = {
        start: getAddressProps('startAddress', AddressInputComponentIds.StartAddress) as any,
        end: getAddressProps('endAddress', AddressInputComponentIds.EndAddress) as any,
    };

    return { addressProps };
};

export interface StationInputHandler {
    stationsIdsOnStore: string[];
    addStation: () => void;
    removeStation: (id: string) => void;
}

const useStationInputsHandling = (): StationInputHandler => {
    const dispatch = useAppDispatch();
    const stationsIdsOnStore = useRootAppSelector(stationsSelector).map((s) => s.stationId);

    const addStation = () => {
        const newStationId = uuid();

        dispatch(
            setStationAction({
                station: {
                    ...initialAddressInfo,
                    stationId: newStationId,
                },
            })
        );
    };

    const removeStation = (id: string) => {
        dispatch(removeStationByIdAction({ stationId: id }));
        dispatch(triggerDirectionsAction());
    };

    return {
        stationsIdsOnStore,
        addStation,
        removeStation,
    };
};

export const buildCarTypeMenuItems = (carTypesLst: CarType[]) => {
    const itemsResult = carTypesLst.map((car) => {
        return {
            name: `${car.type} (${car.passQty} ${i18n.t('places')})`,
            value: car.id,
        };
    });

    itemsResult.unshift({
        name: 'ללא רכב',
        value: FillerValues.Without,
    });

    return itemsResult;
};

const useCarTypes = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { token, dbUrl } = useGetApiRequestBasicParams();
    const carTypesLst = useRootAppSelector(carTypesLstSelector);

    useEffect(() => {
        (async () => {
            if (!dbUrl || !token) return;

            // FC REQUEST

            try {
                const r = await reqGetCarTypes({
                    dbUrl,
                    endpoint: 'ws_MyWayStudio_Get_CarTypes',
                    requestParams: { token },
                });

                let data: CarType[] = [];

                if (r.data.response === '0') {
                    data = linesBl.processGetCarTypes(r.data.data);
                } else if (+r.data.response === 1) {
                    dispatch(setErrorMessage(t('expiredTokenRefreshPage')));
                } else if (r.status === 202 || !r.data) {
                    dispatch(setErrorMessage(ErrorMessagesOld.MissingWs));
                } else {
                    dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
                }

                dispatch(setCarTypesAction({ data }));
            } catch (error) {
                dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
            }
        })();
    }, [dbUrl, dispatch, t, token]);

    const menuItems: MenuItemProps[] = useMemo(() => {
        return buildCarTypeMenuItems(carTypesLst);
    }, [carTypesLst]);

    return { menuItems };
};

/**
 * Fetching car types with prices based on date and imported course code
 * @returns None
 */
export const useCarTypesWithPrice = ({ lineDate }: { lineDate: Date | null }) => {
    const { dispatch, dispatchI18nErrMsg, t } = useCommons();
    const { getRouteClientData } = useBaseApiParams();

    const importedCourseCode = useRootAppSelector((state) => formSelector(state).importedCourseCode);

    const [lastUsedPayload, setLastUsedPayload] = useState('');

    React.useEffect(() => {
        const fetchData = async () => {
            if (!importedCourseCode) return;

            try {
                const payload = { courseCode: importedCourseCode };

                // To get car price for selected date
                if (lineDate) {
                    payload['relativeDate'] = stringifyDate(lineDate);
                }

                const payloadJsonStr = JSON.stringify(payload);

                // To avoid unnecessary requests
                if (lastUsedPayload === payloadJsonStr) {
                    return;
                }

                setLastUsedPayload(payloadJsonStr);

                const r = await commonRoutes.getCarTypesPriceByCourseRoute(getRouteClientData(payload));

                if (r.code === CommonCodes.Ok && Array.isArray(r.data.data)) {
                    dispatch(setCarTypesAction({ data: r.data.data }));
                } else {
                    dispatchI18nErrMsg(r.data.message || ErrorMessagesOld.GeneralError);
                }
            } catch (error) {
                console.log(error);

                dispatchI18nErrMsg(ErrorMessagesOld.GeneralError);
            }
        };

        fetchData();
    }, [lineDate, importedCourseCode]);
};

const VAL_VALIDATION = {
    MAX: 999,
    MIN: 0,
} as const;

export const getCarById = (allCarsLst: CarType[], id: string) => {
    return allCarsLst.find((car) => car.id === id) || null;
};

const usePassQty = () => {
    const { setValue, watch, getValues } = useFormContext<Inputs>();

    const stations: Station[] = useRootAppSelector(stationsSelector);
    const carTypesLst = useRootAppSelector(carTypesLstSelector);

    const [inputValue, setInputValue] = useState('');

    const updateValue = useCallback(
        (newValue: string) => {
            setValue('passQty', isValidNumber(newValue) ? +newValue : null, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [setValue]
    );

    const selectedCarId = watch('carId');

    const totalNumOfPassengersOnStations: number = useMemo(() => {
        let sum = 0;

        // eslint-disable-next-line no-return-assign
        stations.forEach((station) => (sum += station.passengers.length));

        return sum;
    }, [stations]);

    const onChange = useCallback(
        (e: ReactChangeEvent) => {
            const { value } = e.target;

            let carPassQtyLimit: number = VAL_VALIDATION.MAX;

            if (selectedCarId) {
                const carLimit = getCarById(carTypesLst, selectedCarId)?.passQty;
                if (carLimit) {
                    carPassQtyLimit = carLimit;
                }
            }

            const isValid = (+value <= carPassQtyLimit && +value > VAL_VALIDATION.MIN) || value === '';

            if (isValid) {
                setInputValue(value);
            }
        },
        [carTypesLst, selectedCarId]
    );

    // Resetting passQty INPUT value when carId changes
    useEffect(() => {
        if (!selectedCarId) return;
        const currValue = getValues('passQty');

        const carLimit = getCarById(carTypesLst, selectedCarId)?.passQty;

        if (carLimit && currValue && carLimit < +currValue) {
            setInputValue(carLimit.toString());
        }
    }, [carTypesLst, getValues, inputValue, selectedCarId, updateValue]);

    // Updating the passQty input to the sum of passengers on stations
    useEffect(() => {
        if (totalNumOfPassengersOnStations > VAL_VALIDATION.MIN)
            setInputValue(String(totalNumOfPassengersOnStations));
    }, [setValue, totalNumOfPassengersOnStations, updateValue]);

    // Update the passQty input value when input changes
    useEffect(() => {
        updateValue(inputValue);
    }, [inputValue, updateValue]);

    return { onChange, inputValue };
};

const useSelectedCarData = () => {
    const currCarId = useRootAppSelector((state) => formSelector(state).data.carId);
    const carTypesLst = useRootAppSelector(carTypesLstSelector);

    const carData = useMemo(
        () => (currCarId ? getCarById(carTypesLst, currCarId) : null),
        [carTypesLst, currCarId]
    );

    // console.log({ currCarId, carData });
    return carData;
};

const useSyncCarIdToSlice = () => {
    const dispatch = useAppDispatch();

    const { watch } = useFormContext<Inputs>();

    const currCarId = watch('carId');
    const isPassengersFormOpen = useRootAppSelector(passengersFormSelector).isOpen;

    useEffect(() => {
        if (currCarId) {
            dispatch(setSelectedCarIdAction({ id: currCarId }));
            return;
        }
        if (!isPassengersFormOpen && !currCarId) {
            dispatch(setSelectedCarIdAction(null));
        }
    }, [currCarId, dispatch, isPassengersFormOpen]);
};

const useDidReachPassQtyLimit = () => {
    const { watch } = useFormContext<Inputs>();

    const selectedCarData = useSelectedCarData();
    const currPassQty = watch('passQty');
    const currCarId = watch('carId');

    const reachedCarPassQtyLimit: boolean = useMemo(() => {
        if (currPassQty && currCarId) {
            const carPassQtyLimit = selectedCarData?.passQty || Infinity;
            return currPassQty >= carPassQtyLimit;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currCarId, currPassQty, selectedCarData?.passQty]);

    return { reachedCarPassQtyLimit };
};

const useEndTimeValidation = () => {
    const fullFormMethods = useFormContext<Inputs>();
    const validate = useCallback(
        (startTime: string, endTime: string) => {
            const optionsContext: { startTime: string } = {
                startTime,
            };
            const validationResultPromise = endTimeSchema
                .validate(endTime, { context: optionsContext })
                .then(() => {
                    fullFormMethods.clearErrors('endTime');
                    return true;
                })
                .catch((error: any) => {
                    fullFormMethods.setError('endTime', { type: 'test', message: error?.message });
                    return false;
                });

            return Promise.all([validationResultPromise]).then((val) => {
                return val[0];
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fullFormMethods.clearErrors, fullFormMethods.setError, fullFormMethods.getValues]
    );
    return { validate };
};

const useDisabledFields = (linePrice: number | null) => {
    const haveImportedCourseData = useRootAppSelector((state) => formSelector(state).importedCourseCode);

    const disabledFields: Array<InputNames | 'stations'> = React.useMemo(() => {
        const result: Array<InputNames | 'stations'> = [];

        if (haveImportedCourseData && linePrice !== 0 && linePrice) {
            result.push(...(['clientCode', 'passQty', 'stations', 'startAddress', 'endAddress'] as const));
        }

        // To only allow one way trips for imported courses
        if (haveImportedCourseData && linePrice !== 0 && linePrice) {
            pushIfNotExists(result, 'tripDirection');
        }

        return result;
    }, [haveImportedCourseData, linePrice]);

    return { disabledFields };
};

export const fullFormHooks = {
    useAddressData,
    useHelpers,
    useStationInputsHandling,
    usePassQty,
    useCarTypes,
    useDidReachPassQtyLimit,
    useSelectedCarData,
    useSyncCarIdToSlice,
    useEndTimeValidation,
    useDisabledFields,
};

// const setStartAddress = useCallback(
//     (address: AddressInput) =>
//         updateValue('startAddress', address),
//     [updateValue],
// );

// const setEndAddress = useCallback(
//     (address: AddressInput) => updateValue('endAddress', address),
//     [updateValue],
// );
