import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 99vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25em 0;
`;

const Div = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1.6875em;
    height: 8%;
`;

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.6875em 0 1.6875em;
    height: 84%;
    box-sizing: border-box;
    overflow: hidden;
    .k-grid tbody tr:hover,
    .k-grid tbody tr.k-state-hover {
        background: none;
    }
    position: relative;
`;

const Hr = styled.hr`
    width: 100%;
    color: #dfdfdf;
`;

const StyledKendoGridContainer = styled.div`
    overflow: auto;
    .k-grid table tr:hover td {
        background: #ffedb1 !important;
        cursor: pointer !important;
    }
`;

export default {
    Container,
    Div,
    TableContainer,
    Hr,
    StyledKendoGridContainer,
};
