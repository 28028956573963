import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderWithDropdown from 'src/components/HeaderWithDropdown/HeaderWithDropdown';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';

import { routesEndpoints } from 'src/routes/routes_endpoints';
import { containers } from 'src/Layouts/styles';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import useDisableDelay from 'src/hooks/useDelayV2';
import { setFcAccount } from 'src/store/actions/loginAction';
import { IAccount } from 'src/types/login';
import Alert from 'src/components/commons/Alert/Alert';
import useAlert from 'src/hooks/useAlert';
import { Slide } from '@mui/material';
import SettingsList from './SettingsList/SettingsList';
import SettingForm from './widgets/SettingForm';
import { SettingTypes } from '../../../types/rideSettings/store/types.store';

interface Props {}

const RideSettings: FC<Props> = ({}) => {
    // * Hooks
    const dispatch = useAppDispatch();

    // * Selectors
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const clientCode = useRootAppSelector((state) => selectors.clientDataSelector(state).clientCode);
    const isFormOpen = useRootAppSelector((state) => selectors.formSelector(state).ui.isOpen);

    // -- Refresh btn logic
    const [tempDisabled, setTempDisabled] = useDisableDelay(2000);
    const [refreshCount, setRefreshCount] = useState(0);
    const onRefresh = () => {
        setTempDisabled(true);
        // eslint-disable-next-line no-return-assign, no-param-reassign
        setRefreshCount((prev: number) => (prev += 1));
    };

    // * Effects
    useEffect(() => {
        // & Resetting the state for when switching to the screen
        const resetScreenState = () => {
            dispatch(actions.onCloseForm());
            dispatch(
                actions.editSettingClick({
                    settingType: null,
                    submitEndpoint: null,
                })
            );
            dispatch(actions.setSettingsAccordionExpansion({ type: null, newState: 'closeAll' }));
        };
        resetScreenState();

        // & Fetching the settings
        const { dbUrl = '', proxyUrl = '' } = selectedFcAccount || {};

        if (!dbUrl || !token || !clientCode) return;

        const payload = {
            dbUrl,
            proxyUrl,
            token,
            clientCode,
        };

        // FC REQUEST

        dispatch(actions.reqGetGeneralSettingsAction(payload));
        dispatch(
            actions.onFetchSettings({
                ...payload,
                endpoint: 'ws_MyWayStudio_Get_Internet_Permissions',
                settingType: SettingTypes.PermissionManagement,
            })
        );
    }, [clientCode, dispatch, refreshCount, selectedFcAccount, token]);

    const { alertProps, errorMsg } = useAlert(selectors.errorSelector, actions.onErrorAction);

    return (
        <containers.ScreenContainer className="ScreenContainer">
            <containers.MainSectionContainer className="MainSectionContainer">
                <HeaderWithDropdown
                    setClientIdAction={actions.onSelectClientId}
                    routeScreenId={routesEndpoints.PASSENGER_SETTINGS.SCREEN_ID}
                    onRefreshClick={onRefresh}
                    delay={tempDisabled}
                    style={{ paddingTop: '32px', paddingBottom: '20px' }}
                />
                <SettingsList />
            </containers.MainSectionContainer>
            <containers.WidgetsContainer isOpen className="WidgetsContainer">
                <Slide direction="up" in={isFormOpen} unmountOnExit>
                    <SettingForm />
                </Slide>
            </containers.WidgetsContainer>
            <Alert {...alertProps}>{errorMsg}</Alert>
        </containers.ScreenContainer>
    );
};

export default RideSettings;
