/* eslint-disable array-callback-return */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';
import { onFormShowChange } from 'src/store/actions/PassengersShiftActionType';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getShifts } from 'src/api/manualOrderApi/manualOrderApi';
import { IShifts } from 'src/types/manualOrders/api.types';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import _ from 'lodash';
import {
    getBlockedDaysOfSelectedShifts,
    getBlockedDaysOfShift,
    getColorShift,
    getShiftBlockedDays,
    isShiftItemDisabled,
} from 'src/screens/ManualOrder/utilis';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { shiftsSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { RELATIVE } from 'src/constants/dates';
import * as Styles from './Regularshiftplacement.Style';
import './Regularshiftplacement.css';
import { initialManualOrderState, PlacemenContext } from '../../context';
import { onChangedShiftSelection } from '../../../../../../store/actions/PassengersShiftActionType';
import { editShiftSelector } from '../../../../../../store/selectores/passengerShiftsSelectores';
import { buildPlacementShiftObj } from '../../../../utilis';
import { ShiftType } from '../../../../types';
import ClearSelectionBtn from '../ClearSelectionBtn/ClearSelectionBtn';

interface Props {
    shifts: IShifts[];
}

const Regularshiftplacement: FC<Props> = ({ shifts }) => {
    // const [placment, setPlacment] = useContext(PlacemenContext);
    const { placment, setPlacment, addShiftToPlacement, isShiftOrdered } = usePlacement();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onSelectShift = (shiftId: unknown): JSX.Element | null => {
        const selectShift = shifts.find((s) => s.shiftId === Number(shiftId));
        if (!selectShift) return null;
        // dispatch(onFormShowChange(true));

        const { pickupTime = '', dropTime = '' } = selectShift;

        return <div>{`${selectShift?.shiftName} (${pickupTime} ⬅ ${dropTime})`}</div>;
    };
    const shiftsWithAllDates = useSelector((state: IRootReducer) => shiftsSelector(state));

    const editShift = useSelector((state: IRootReducer) => editShiftSelector(state));
    const [selectShift, setSelectShift] = useState<IShifts | undefined>(undefined);

    useEffect(() => {
        if (editShift?.shiftId) {
            setSelectShift(shifts.find((s) => s.shiftId === editShift?.shiftId));
        }
    }, [addShiftToPlacement, editShift, shifts]);

    const onChange = (
        val: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const selected = shifts.find((s) => s.shiftId === Number(val.target.value));
        setSelectShift(selected);
        // console.log(val.target.value, { selected });
        if (!selected) {
            dispatch(onChangedShiftSelection({ isShiftSelected: false }));
            return;
        }
        const { pickupTime = '', dropTime = '', shiftId } = selected;

        dispatch(
            onChangedShiftSelection({
                isShiftSelected: true,
                shiftId,
            })
        );

        const blockedDaysForSelectedShift = getBlockedDaysOfSelectedShifts(
            shiftsWithAllDates,
            [selected.shiftId],
            true
        );
        // console.log(blockedDaysForSelectedShift);

        const newValidOrderDates = placment.dates.filter((date) => {
            return !blockedDaysForSelectedShift.includes(date.relativeDate);
        });

        setPlacment((pre) => ({
            ...pre,
            dates: newValidOrderDates,
            autoOrder: {
                pickupTime,
                dropTime,
                shiftId: `${shiftId}`,
            },
            manualOrder: initialManualOrderState,
        }));
    };

    // fixes bug where need to click selected shift twice before it updates the placement
    useEffect(() => {
        if (!selectShift) return;
        const selectedShiftInPlacementFormat = buildPlacementShiftObj(ShiftType.Auto, selectShift);
        if (!_.isEqual(selectedShiftInPlacementFormat, placment.autoOrder)) {
            addShiftToPlacement(ShiftType.Auto, selectedShiftInPlacementFormat);
        }
    }, [addShiftToPlacement, placment.autoOrder, selectShift]);

    // };
    return (
        <>
            <Styles.Container variant="outlined">
                <FormControl variant="outlined" size="small" error={!placment.autoOrder.shiftId.length}>
                    <InputLabel variant="outlined" id="demo-simple-select-label">
                        {t('selectShift')} *
                    </InputLabel>
                    <Styles.StyledSelect
                        id="demo-simple-select-label"
                        renderValue={onSelectShift}
                        onChange={onChange}
                        style={{
                            backgroundColor: selectShift
                                ? getColorShift(Number(selectShift.shiftIndex))
                                : 'transparent',
                        }}
                        value={placment.autoOrder.shiftId}
                        fullWidth
                        required
                        label={`${t('selectShift')} *`}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        {shifts.map((shift) => {
                            const shiftMenuItem = (
                                <MenuItem
                                    disabled={isShiftItemDisabled(shift, placment.dates, isShiftOrdered)}
                                    value={shift.shiftId}
                                >
                                    <Styles.ShiftTime
                                        color={getColorShift(Number(shift.shiftIndex))}
                                    >{`${shift.pickupTime}  ⬅  ${shift.dropTime}`}</Styles.ShiftTime>
                                    <em>{` - ${shift.shiftName}`}</em>
                                </MenuItem>
                            );
                            return shiftMenuItem;
                        })}
                    </Styles.StyledSelect>
                </FormControl>
            </Styles.Container>
            <ClearSelectionBtn setSelectShift={setSelectShift} shiftTypeForSelection={ShiftType.Auto} />
        </>
    );
};

export default Regularshiftplacement;
