import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyReactTooltip from 'src/components/MyReactTooltip/MyReactTooltip';
import { DISPLAY_UI_LONG, RELATIVE } from 'src/constants/dates';
import useFilterValuesBySelectedReport from 'src/screens/Reports/hooks/useFilterValuesByReport';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    refreshWidgetsBtnClickCountSelector,
    ridesInfoCardsCountsSelector,
    ridesInfoSummaryDataSelector,
    selectApplyFiltersBtnClickCount,
    selectedReportNameSelector,
    widgetsUiSelector,
} from 'src/store/selectores/reportsSelectors';
import { ReportNames } from 'src/store/slices/reports/types';
import { asRootReducer } from 'src/store/utils';
import { stringifyDate } from 'src/utilis/utilis';
import styled, { css, CSSObject } from 'styled-components';
import InfoCard from '../OrdersInfo/components/InfoCard';
import BudgetBI from './FullBudgetDisplay/FullBudgetDisplay.index';
import useRidesApiData from './hooks/useRidesApiData';
import RidesSummaryCard from './RidesSummaryCard/RidesSummaryCard';
import { widgetsDataSelector } from '../../../../../store/selectores/reportsSelectors';
import widgetsHooks from '../hooks/common.hooks';

type Props = {
    clientCode: number | undefined;
};
export const StyledContainer = styled.div`
    padding-top: 15px;
    margin-top: -10px;
    border-top: 2px solid #eef1f5;
    height: 100%;
    width: 100%;
`;
const StyledInfoCardContainer = styled.div<{ style?: CSSObject }>`
    display: flex;
    padding-bottom: 20px;
    ${({ style = {} }) => css(style)}
`;

const RidesInfoTooltips = ({ date, dateRange }: { date: Date | moment.Moment; dateRange: any }) => {
    const { t } = useTranslation();
    return (
        <div>
            <MyReactTooltip id="dailyRides">
                <p>{`${t('ridesFor')} ${stringifyDate(date, DISPLAY_UI_LONG)}`}</p>
            </MyReactTooltip>
            <MyReactTooltip id="weeklyRides">
                <p>{`${t('ridesFor')}  ${dateRange}`}</p>
            </MyReactTooltip>
            <MyReactTooltip id="dailyPassengers">
                <p>{`${t('passengersFor')} - ${stringifyDate(date, DISPLAY_UI_LONG)}`}</p>
            </MyReactTooltip>
            <MyReactTooltip id="weeklyPassengers">
                <p>{`${t('passengersFor')} - ${dateRange}`}</p>
            </MyReactTooltip>
        </div>
    );
};

const useSelectors = () => {
    const dateOnWidget = useAppSelector((state) =>
        moment(widgetsUiSelector(asRootReducer(state)).dateOnWidget)
    );
    const stats = useAppSelector((state) => ridesInfoSummaryDataSelector(asRootReducer(state)));
    const reportName = useAppSelector(selectedReportNameSelector);

    return { dateOnWidget, stats, reportName };
};

// * EXPORTED COMPONENT --------------------------------------------------------------------------------
const RidesInfo = ({ clientCode }: Props): JSX.Element => {
    const { t } = useTranslation();

    const { dateOnWidget, stats, reportName } = useSelectors();

    const { getFilterValuesByReport } = useFilterValuesBySelectedReport();

    const dateToUseForWsCall = useMemo(
        () =>
            reportName === 'passengers'
                ? dateOnWidget.toDate()
                : getFilterValuesByReport('applied', reportName)?.fromDate,
        [dateOnWidget, getFilterValuesByReport, reportName]
    );

    useRidesApiData({
        date: dateToUseForWsCall,
        clientCode,
    });

    const {
        rides: ridesCount,
        passengers: passengersCount,
        fromDate,
        toDate,
    } = useAppSelector(ridesInfoCardsCountsSelector);

    const dateRange = useMemo((): string => {
        if (!fromDate || !toDate) return '';
        return `${moment(fromDate, RELATIVE).format(DISPLAY_UI_LONG)} - ${moment(toDate, RELATIVE).format(
            DISPLAY_UI_LONG
        )}`;
    }, [fromDate, toDate]);

    // Budget Ft logic
    widgetsHooks.useBudgetApiData(dateToUseForWsCall);
    const budgetRequestData = useRootAppSelector((state) => widgetsDataSelector(state).budget);
    const showBudgetBi = budgetRequestData.data.monthlyBudget > 0;

    return (
        <StyledContainer>
            <StyledInfoCardContainer style={{ gap: '14px' }}>
                <InfoCard
                    count={stats.rides.dailyStatistics.max}
                    text={`${t('dailyRides')}`}
                    tooltipId="dailyRides"
                />
                <InfoCard count={ridesCount} text={`${t('ridesForWeek')}`} tooltipId="weeklyRides" />
                {showBudgetBi ? (
                    <>
                        <InfoCard
                            count={stats.passengers.dailyStatistics.max}
                            text={`${t('dailyPassengers')}`}
                            tooltipId="dailyPassengers"
                        />
                        <InfoCard
                            count={passengersCount}
                            text={`${t('weeklyPassengers')}`}
                            tooltipId="weeklyPassengers"
                        />
                    </>
                ) : (
                    <></>
                )}
            </StyledInfoCardContainer>
            {!showBudgetBi ? (
                <StyledInfoCardContainer style={{ gap: '14px' }}>
                    <InfoCard
                        count={stats.passengers.dailyStatistics.max}
                        text={`${t('dailyPassengers')}`}
                        tooltipId="dailyPassengers"
                    />
                    <InfoCard
                        count={passengersCount}
                        text={`${t('weeklyPassengers')}`}
                        tooltipId="weeklyPassengers"
                    />
                </StyledInfoCardContainer>
            ) : (
                <></>
            )}

            <RidesInfoTooltips dateRange={dateRange} date={dateToUseForWsCall} />

            {clientCode && <RidesSummaryCard />}

            {showBudgetBi ? <BudgetBI date={dateToUseForWsCall} /> : <></>}
        </StyledContainer>
    );
};

export default RidesInfo;
