/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { PureComponent, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton, withStyles, createStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { getStartDateByTargetDayNum } from 'src/utilis/utilis';
import PseudoRelativeWrapper from '../PseudoRelativeWrapper/PseudoRelativeWrapper';

interface Props {
    value: ParsableDate;
    onChange: any;
    firstDayOfWeek?: number;
}

const DatePickerArrows: React.FC<Props> = ({ value, onChange, firstDayOfWeek }) => {
    const [firstDayOfPickedWeek, setFirstDayOfPickedWeek] = useState<Date | null>(null);

    useEffect(
        () => onChange(firstDayOfPickedWeek),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [firstDayOfPickedWeek]
    );

    const setToNextWeek = () => {
        if (!value) return;
        if (typeof firstDayOfWeek === 'number' && value instanceof Date) {
            setFirstDayOfPickedWeek(
                moment(getStartDateByTargetDayNum(firstDayOfWeek, moment(value).day(), value))
                    .add(7, 'days')
                    .toDate()
            );
        } else {
            setFirstDayOfPickedWeek(moment(value).startOf('week').add(7, 'days').toDate());
        }
    };

    const setToPrevWeek = () => {
        if (!value) return;
        if (typeof firstDayOfWeek === 'number' && value instanceof Date) {
            setFirstDayOfPickedWeek(
                moment(getStartDateByTargetDayNum(firstDayOfWeek, moment(value).day(), value))
                    .subtract(7, 'days')
                    .toDate()
            );
        } else {
            setFirstDayOfPickedWeek(moment(value).startOf('week').subtract(7, 'days').toDate());
        }
    };

    return (
        <PseudoRelativeWrapper left={5}>
            <IconButton size="small" onClick={setToNextWeek} style={{ padding: '0px' }}>
                <KeyboardArrowUpIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={setToPrevWeek} style={{ padding: '0px' }}>
                <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
        </PseudoRelativeWrapper>
    );
};

export default DatePickerArrows;
