import { Endpoints } from 'src/types/rideSettings/components/types.screen';
import { ActiveStatus } from 'src/types/global';
import { SubmitConfigs } from 'src/types/rideSettings/store/types.sagas';
import { ReqStatus } from '../../../api/types';
import { googleMapsApiLanguage } from 'src/i18n/googleMapsApiLanguage';

// Setting types ------------------------------------------------------------------------------
export enum SettingTypes {
    GeneralSettings = 'generalSettings',
    PermissionManagement = 'permissionManagement',
    AccountDepartments = 'accountDepartments',
    ShiftManagement = 'shiftManagement',
    OrderTimesManagement = 'orderTimesManagement',
}

export type SlSettingTypes =
    | 'generalSettings'
    | 'permissionManagement'
    | 'accountDepartments'
    | 'shiftManagement'
    | 'orderTimesManagement';

export const settingTypeByStr = (str: SlSettingTypes): SettingTypes => {
    switch (str) {
        case 'generalSettings':
            return SettingTypes.GeneralSettings;

        default:
            return SettingTypes.PermissionManagement;
    }
};
// .--------------------------------------------------------------------------------------------

// Define a type for the slice state ------------------------------------------------------------------------------
interface SettingData<T> {
    type: SettingTypes;
    settings: T;
    loadingSettingsStatus: ReqStatus;
}
export type ApiCallStatus = 'ON_STAND_BY' | 'SUCCESS' | 'LOADING' | 'FAILED';

// General Settings ------------------------------------------------------------------------------
export type GeneralSettingTypes = 'hidePassPhone' | 'firstDayOfWeek' | 'languageForAddresses';
export type HidePassPhoneTypes = '1' | '0';
export type FirstDayOfWeek = '1' | '2' | '3' | '4' | '5' | '6' | '7';
export type LanguageForAddresses =
    | 'af'
    | 'am'
    | 'ar'
    | 'as'
    | 'az'
    | 'be'
    | 'bg'
    | 'bn'
    | 'br'
    | 'bs'
    | 'ca'
    | 'cs'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'fi'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gu'
    | 'he'
    | 'hi'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'id'
    | 'is'
    | 'it'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kk'
    | 'km'
    | 'kn'
    | 'ko'
    | 'ku'
    | 'ky'
    | 'lb'
    | 'lo'
    | 'lt'
    | 'lv'
    | 'mg'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'nb'
    | 'ne'
    | 'nl'
    | 'nn'
    | 'or'
    | 'os'
    | 'pa'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'se'
    | 'si'
    | 'sk'
    | 'sl'
    | 'so'
    | 'sq'
    | 'sr'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'tr'
    | 'ts'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'zh'
    | 'zu';

export interface GeneralSettingsData {
    hidePassPhone: HidePassPhoneTypes | null;
    firstDayOfWeek: FirstDayOfWeek | null;
    languageForAddresses: LanguageForAddresses | null;
}

export interface UpdateGenSetsFormValuePayload<T> {
    type: GeneralSettingTypes;
    newVal: T;
}
// .--------------------------------------------------------------------------------------------

// Internet permissions ------------------------------------------------------------------------------
export enum PermissionTypes {
    DEPARTMENT_MGR = 'DEPARTMENT_MGR',
    WEB_ACCESS = 'WEB_ACCESS',
}
export type SlPermissionTypes = keyof typeof PermissionTypes;

export interface Permission {
    permissionCode: number;
    permissionType: PermissionTypes;
    level1: '1' | '0';
    level2: '1' | '0';
    level3: '1' | '0';
    level4: '1' | '0';
    level5: '1' | '0';
}

export interface PermissionsManagementSettings {
    permissions: Permission[];
}
// .--------------------------------------------------------------------------------------------

export interface FormState {
    ui: {
        isOpen: boolean;
        isOnEditMode: boolean | null;
        currentForm: SlSettingTypes | null;
    };
    valuesByForm: {
        generalSettings: GeneralSettingsData | null;
        permissionManagement: PermissionsManagementSettings | null;
    };
    apiCallsStatus: {
        formSubmitStatus: ReqStatus;
        responseMsg: string;
    };
    apiSettings: {
        submitConfigs: SubmitConfigs;
    };
}

export interface ClientCodePayload {
    clientCode: string;
}

export interface RideSettingsState {
    data: {
        [SettingTypes.GeneralSettings]: SettingData<GeneralSettingsData>;
        [SettingTypes.PermissionManagement]: SettingData<{ permissions: Permission[] }>;
        [SettingTypes.AccountDepartments]: SettingData<any>;
        [SettingTypes.ShiftManagement]: SettingData<any>;
        [SettingTypes.OrderTimesManagement]: SettingData<any>;
    };
    ui: {
        settingsAccordionsExpansion: { readonly [key in SlSettingTypes]: boolean };
        filters: {
            activeStatus: ActiveStatus;
        };
        common: {
            error: {
                msg: string;
            };
        };
    };
    clientData: {
        clientCode: null | string;
    };
    form: FormState;
}

export interface Department {
    departmentCode: number | null;
    departmentName: string;
    contactName: string;
    contactPhone: string;
    contactMphone: string;
    contactEmail: string;
    departmentRemarks: string;
    isActive: string;
}

export interface SetSettingsAccordionExpansionPayload {
    type: SlSettingTypes | null;
    newState: 'close' | 'open' | 'closeAll';
}
