import styled, { CSSObject, css } from 'styled-components';

const inputsStyle = { backgroundColor: 'white' };

export const AddressSelectionContainer = styled.div<{ style?: CSSObject }>`
    display: flex;
    gap: 15px;
    padding-top: 20px;
    ${(props) => css(props.style || {})};
`;

const styles = {
    inputsStyle,
    AddressSelectionContainer,
};

export default styles;
