import moment from 'moment';
import { FillerValues } from 'src/constants/misc';
import i18n from 'src/i18n';
import { TripDirections } from 'src/types/line';
import { CourseDirection } from 'src/types/passengersManager/courseChoosingTypes';
import dateHelpers from 'src/utilis/dateHelpers';
import * as yup from 'yup';

enum ErrorMessages {
    IsRequired = 'isRequired',
    MustBeLessThan = 'mustBeLessThan',
    InvalidInputFormat = 'invalidInputFormat',
    NumMustBePositive = 'numMustBePositive',
    MustBeNum = 'mustBeNum',
    InvalidEmail = 'invalidEmail',
}

const { IsRequired, InvalidEmail, MustBeLessThan, InvalidInputFormat, NumMustBePositive, MustBeNum } =
    ErrorMessages;

export const courseStationSchema = yup.object({
    stationId: yup.string().required(IsRequired),
    city: yup.string(),
    street: yup.string(),
    houseNum: yup.string(),
    timeFromPrev: yup.number().nullable(),
    isManualTimeFromPrev: yup.boolean().nullable(),
    lat: yup.number().required(IsRequired),
    lng: yup.number().required(IsRequired),
    stationRemark: yup.string(),
});

const schema = yup
    .object({
        courseId: yup.string().max(8, `${MustBeLessThan}-8`),
        courseDescription: yup.string().min(1, IsRequired).max(240, `${MustBeLessThan}-240`).required(),
        remarks: yup.string().max(240, `${MustBeLessThan}-240`),
        departmentCode: yup.string().nullable(),
        carTypeCode: yup.string(),
        lineTypeCode: yup.string().nullable(),
        activeFromDate: yup.date().required(IsRequired),
        activeUntilDate: yup
            .date()
            .required(IsRequired)
            .when('activeFromDate', (activeFromDate, sch) => {
                if (dateHelpers.isValidDate(activeFromDate)) {
                    // const minDate = moment(new Date());
                    // console.log('activeFromDate', activeFromDate);
                    // minDate.setDate(activeFromDate.getDate() - 1);
                    // remove one day from minDate
                    // console.log('minDate', activeFromDate);
                    const minDate = moment(activeFromDate).subtract(1, 'days');
                    return sch.min(minDate);
                }
                return sch;
            }),
        courseDirection: yup.mixed().oneOf([...Object.values(CourseDirection), FillerValues.Without]),
        isActive: yup.string().oneOf(['1', '0']).required(IsRequired),
        // yup array of CourseStation
        stations: yup.array().of(courseStationSchema).required(IsRequired),
    })
    .required();

const detailsSchema = {
    schema,
};

export default detailsSchema;
