import { CSSProperties } from 'react';
import themes from 'src/style/themes';

export const styleDateWithMsg: CSSProperties = {
    margin: '0 0 5px 0',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.05em',
};

export const styleDateAndStatus: CSSProperties = {
    padding: '5px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '125px',
    borderLeft: `1px solid ${themes.colors.mediumLightGray}`,
};

export const regularFontBold = {
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: `${themes.fontFamilies.Rubik}`,
};

export const widgetHeader: CSSProperties = {
    fontWeight: 'bold',
    fontFamily: themes.fontFamilies.Rubik,
    color: themes.msColors.primaryTextcolor,
};
