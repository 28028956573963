import styled, { css } from 'styled-components';

export const Root = styled.div`
    height: 100%;
    width: 100%;
`;

export const Container = styled.div`
    border-radius: 20px;
    height: 95%;
    width: 100%;
    // margin-top: 20px;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    padding: 15px 24px;
    // justify-content: space-between;
    // flex
    flex-wrap: wrap;
    gap: 20px;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

export const OrderHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.p`
    font-size: 25px;
    font-weight: 500;
`;

export const WidgetTitle = styled.p`
    font-size: 30px;
`;

export const ReloadBtn = styled.p<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    color: #494949;
    padding-bottom: 15px;
    :hover {
        cursor: pointer;
    }
    :active {
        transform: translateY(2px);
    }
    ${(props) =>
        props.disabled &&
        css`
            cursor: default;
            opacity: 0.3;
            pointer-events: none;
            transition: opacity 0.3 linear;
        `}
`;

export const Card = styled.div<{
    justifyContent?: string;
}>`
    background: white;
    box-shadow: 0px 0px 8px 0px rgba(106, 106, 106, 0.2);
    border-radius: 15px;
    height: 100px;
    width: 45%;
    display: flex;
    flex-direction: column;
    ${({ justifyContent }) =>
        justifyContent &&
        css`
            justify-content: ${justifyContent};
        `}
`;

export const Lable = styled.p`
    font-weight: 700;
    font-size: 15px;
    color: rgba(73, 73, 73, 1);
`;

export const Value = styled.p`
    font-weight: 500;
    font-size: 50px;
    color: rgba(73, 73, 73, 1);
`;

export const Icon = styled.img`
    background-color: ${({ theme }) => theme.colors.lightYellow};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
`;
