import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    changePickCourseFormShowAction,
    formSelector,
    onFormSaveAction,
} from 'src/store/slices/passengersManager/passengersManagerSlice';
import CancelAndApproveBtns from 'src/screens/RideSettings/components/widgets/CancelAndApproveBtns';
import GoBackArrowIcon from 'src/components/Icons/GoBackArrowIcon';
import { styles } from '../../styles';
import CourseTypeTabs from './CourseTypeTabs/CourseTypeTabs';

type Props = {
    show: boolean;
};

const CoursesList = ({ show }: Props) => {
    const dispatch = useAppDispatch();
    const onCancel = () => {
        dispatch(changePickCourseFormShowAction({ show: false }));
    };
    const onSubmit = () => {
        dispatch(onFormSaveAction());
    };
    const wasTouched = useAppSelector((state: any) => formSelector(state).wasTouched);
    return (
        <styles.formContainer show={show}>
            <styles.HeaderContainer className="HeaderContainer">
                <styles.GoBackBtn onClick={onCancel}>
                    <GoBackArrowIcon />
                </styles.GoBackBtn>
                <styles.titleText>בחירת מסלול</styles.titleText>
            </styles.HeaderContainer>
            <styles.FromBodyContainer className="FormBodyContainer">
                <CourseTypeTabs />
            </styles.FromBodyContainer>
            <styles.FormBtnsContainer className="FormBtnsContainer">
                <styles.BtnsBox>
                    <CancelAndApproveBtns disabled={!wasTouched} onCancel={onCancel} onSubmit={onSubmit} />
                </styles.BtnsBox>
            </styles.FormBtnsContainer>
        </styles.formContainer>
    );
};
export default CoursesList;
