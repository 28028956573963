import React, { useMemo } from 'react';
import { images } from 'src/assets';
import { useRootAppSelector } from 'src/store/hooks';
import {
    coursesBuildingSelectors,
    coursesBuildingSelectors as selectors,
    selectedCourseIdSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { CourseInfoWidgetStyles as styled } from './styles';
import CourseTypeTabs from './CourseTypeTabs';
import Spinner from 'src/components/Spinner/Spinner';
import useCommons from 'src/hooks/common/useCommons';
import ApiGeneratedDataWrapper from 'src/components/Wrappers/ApiGeneratedDataWrapper';

interface Props {}

const useSelectedCourseData = () => {
    const selectedCourseId = useRootAppSelector(selectedCourseIdSelector);

    const allCourses = useRootAppSelector((state) => selectors.apiDataSelector(state).getAccountCourses.data);

    const selectedCourseData = useMemo(
        () => allCourses.find((course) => course.courseCode === selectedCourseId),

        [allCourses, selectedCourseId]
    );

    return { selectedCourseData };
};

interface Props {}

const CourseInfoWidget = (props: Props) => {
    const { selectedCourseData: course } = useSelectedCourseData();
    const getCourseTimesResult = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseTimes
    );

    return (
        <styled.Card className="StyledCard">
            {course ? (
                <>
                    <styled.Header>
                        <styled.Icon src={images.alartIcon} />

                        <>
                            <styled.HeaderText>{`${course.courseDescription}`}</styled.HeaderText>
                            <p className="no-shrink">
                                ({course.courseId}{' '}
                                {course.courseDirection ? `- ${course.courseDirection}` : ''})
                            </p>
                        </>
                    </styled.Header>
                    <ApiGeneratedDataWrapper apiCallStatus={getCourseTimesResult.status}>
                        <CourseTypeTabs course={{ ...course, courseTimes: getCourseTimesResult.data }} />
                    </ApiGeneratedDataWrapper>
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <h4>לא נבחר מסלול</h4>
                </div>
            )}
        </styled.Card>
    );
};

export default CourseInfoWidget;
