import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import useCommons from 'src/hooks/common/useCommons';
import FormHeader from 'src/components/formComponents/FormHeader';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { msColors } from 'src/style/themes/defaultTheme';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import {
    optimizationsRootSelector,
    setIsGeneralLoadingAction,
    setIsOptimizationSimulationScreenOpenAction,
    setIsParametersFormOpenAction,
    setMaxTravelTimeAction,
    setMaxTravelTimeRingTwoAction,
    setSimulationResultAction,
    setSimulationResultFirstRingAction,
    setTravelMethodAction,
    setTravelTypeAction,
    setVehicleCapacityAction,
    setWalkingDistanceFromStationAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import NumberSlider from '../NumberSlider';
import { useRootAppSelector } from 'src/store/hooks';
import { useSetOptimizationSimulation } from '../../hooks/useSetOptimizationSimulation';
import { buildWaypointName, buildWaypointNameForOptimizationResult } from '../../helpers';
import { useDispatchAlert } from 'src/hooks/useAlert';

const Input = styled(MuiInput)`
    width: 42px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        // minHeight: '28px',
        // margin: '0 27px',
        width: '100%',
        '&:not(:first-of-type)': {
            // margin: '0',
            borderRadius: '4px',
        },
        '&:first-of-type': {
            borderRadius: '4px',
            // margin: '0 1.2em',
            margin: 0,
        },
        '&.Mui-selected': {
            border: '2px solid #40A8E2 !important',
            // marginLeft: '1px',
            //  boxSizing: 'border-box',
            //  borderRadius: '4px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
        },
    },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
    // fontFamily: mediumRubik,
    borderRadius: '4px',
    color: msColors.darkTextcolor,
    backgroundColor: 'white',
    border: '2px solid lightGray !important',
    width: '80px ',
    height: window.screen.width > 1715 ? '28px' : '50px',
    fontFamily: 'Rubik',
    // fontWeight: '400',
    fontSize: '12px',
    margin: '0 1.2em',
}));

const maxWalkingDistanceValue = 999;
const maxTravelTimeValue = 480;
const maxTravelTimeRingTwoValue = 480;
const maxVehicleCapacity = 60;

interface Props {}

const OptimizationParametersForm: FC<Props> = React.forwardRef((props, ref) => {
    const { dispatch, t } = useCommons();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        maxTravelTime,
        maxTravelTimeRingTwo,
        travelMethod,
        travelType,
        walkingDistanceFromStation,
        vehicleCapacity,
    } = useRootAppSelector((state) => optimizationsRootSelector(state).ui.parametersForm);
    const firstRingSimulationResult = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getSimulationResult.firstRingData
    );
    const waypointStock = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );
    const allWaypoints = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );
    const waypointsSelectedIds = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsSelectedIds
    );
    const dispatchAlert = useDispatchAlert();

    const setOptimizationSimulation = useSetOptimizationSimulation();

    useEffect(() => {
        console.log('useEffect before');

        const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);

        if (
            firstRingSimulationResult &&
            firstRingSimulationResult.wayPointNotInRoutes &&
            currentTargetWaypoint
        ) {
            console.log('useEffect after', firstRingSimulationResult);

            if (firstRingSimulationResult.wayPointNotInRoutes.length > 0) {
                const waypointsJson = firstRingSimulationResult.wayPointNotInRoutes.map((waypoint) => {
                    return {
                        stationName: buildWaypointNameForOptimizationResult(waypoint),
                        city: waypoint.city,
                        street: waypoint.street,
                        houseNum: waypoint.houseNum,
                        lat: waypoint.lat,
                        lng: waypoint.lng,
                        place_id: `${waypoint.lat},${waypoint.lng}`,
                        waypointId: waypoint.waypointId,
                        isTarget: waypoint.isTarget ? true : false,
                        isStation: true,
                    };
                });

                waypointsJson.push({
                    stationName: buildWaypointName(currentTargetWaypoint),
                    city: currentTargetWaypoint.city,
                    street: currentTargetWaypoint.street,
                    houseNum: currentTargetWaypoint.houseNum,
                    lat: currentTargetWaypoint.lat,
                    lng: currentTargetWaypoint.lng,
                    place_id: `${currentTargetWaypoint.lat},${currentTargetWaypoint.lng}`,
                    waypointId: currentTargetWaypoint.waypointId,
                    isTarget: true,
                    isStation: true,
                });
                setOptimizationSimulation.setOptimizationSimulation(
                    waypointsJson,
                    maxTravelTimeRingTwo,
                    true
                );
            } else {
                dispatch(setSimulationResultAction({ simulationResult: firstRingSimulationResult }));
                dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: true }));
            }
        }

        dispatch(setSimulationResultFirstRingAction({ simulationResult: null }));
    }, [firstRingSimulationResult]);

    const onCancel = () => {
        // cancel here

        console.log('cancelled');
        // reset form
        dispatch(setIsParametersFormOpenAction({ isParameterFormOpen: false }));
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        // save here
        dispatch(setIsGeneralLoadingAction({ isLoading: true }));

        const currentTargetWaypoint = waypointStock.find((waypoint) => waypoint.isTarget);

        const selectedWaypoints = allWaypoints.filter((waypoint) =>
            waypointsSelectedIds.includes(waypoint.waypointId)
        );

        if (currentTargetWaypoint) {
            const waypointsJson = selectedWaypoints.map((waypoint) => {
                return {
                    stationName: buildWaypointName(waypoint),
                    city: waypoint.city,
                    street: waypoint.street,
                    houseNum: waypoint.houseNum,
                    lat: waypoint.lat,
                    lng: waypoint.lng,
                    place_id: `${waypoint.lat},${waypoint.lng}`,
                    waypointId: waypoint.waypointId,
                    isTarget: waypoint.isTarget ? true : false,
                    isStation: true,
                };
            });

            setOptimizationSimulation.setOptimizationSimulation(waypointsJson, maxTravelTime, false);
        } else {
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
            dispatchAlert('error', t('targetWaypointIsRequired'));
        }

        setIsSubmitting(false);
    };

    const handleTravelMethodChange = (event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
        console.log('newAlignment', newAlignment);

        if (newAlignment !== null) {
            dispatch(setTravelMethodAction({ travelMethod: newAlignment }));
        }
    };

    const handleTravelTypeChange = (event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
        if (newAlignment !== null) {
            dispatch(setTravelTypeAction({ travelType: newAlignment }));
        }
    };

    // tamir matz
    const handleWalkingDistanceSliderChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (typeof newValue === 'number')
            dispatch(
                setWalkingDistanceFromStationAction({
                    walkingDistanceFromStation: newValue,
                    maxValue: maxWalkingDistanceValue,
                })
            );
    };

    const handleWalkingDistanceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > maxWalkingDistanceValue || Number(event.target.value) < 0) return;
        dispatch(
            setWalkingDistanceFromStationAction({ walkingDistanceFromStation: Number(event.target.value) })
        );
    };

    const handleVehicleCapacitySliderChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (typeof newValue === 'number')
            dispatch(setVehicleCapacityAction({ vehicleCapacity: newValue, maxValue: maxVehicleCapacity }));
    };

    const handleVehicleCapacityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > maxVehicleCapacity || Number(event.target.value) < 0) return;
        dispatch(setVehicleCapacityAction({ vehicleCapacity: Number(event.target.value) }));
    };

    const handleMaxTravelTimeSliderChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (typeof newValue === 'number')
            dispatch(setMaxTravelTimeAction({ maxTravelTime: newValue, maxValue: maxTravelTimeValue }));
    };

    const handleMaxTravelTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > maxTravelTimeValue || Number(event.target.value) < 0) return;
        dispatch(setMaxTravelTimeAction({ maxTravelTime: Number(event.target.value) }));
    };

    const handleMaxTravelTimeRingTwoSliderChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (typeof newValue === 'number')
            dispatch(
                setMaxTravelTimeRingTwoAction({
                    maxTravelTimeRingTwo: newValue,
                    maxValue: maxTravelTimeRingTwoValue,
                })
            );
    };

    const handleMaxTravelTimeRingTwoInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > maxTravelTimeRingTwoValue || Number(event.target.value) < 0) return;
        dispatch(setMaxTravelTimeRingTwoAction({ maxTravelTimeRingTwo: Number(event.target.value) }));
    };

    return (
        <div
            ref={ref as React.RefObject<HTMLDivElement>}
            style={{ height: '100%', paddingTop: '30px', textAlign: 'right' }}
        >
            <WidgetFormWrapper
                btnsConfig={{
                    onSubmit,
                    onCancel,
                    isSubmitting,
                    withLoader: true,
                    disabled: false,
                    submitText: t('continue'),
                }}
                HeaderComponent={() =>
                    FormHeader({
                        mainText: 'הגדרת חוקים לאופטימיזציה',
                        secondaryText: '',
                    })
                }
            >
                <div style={{ display: 'flex', gap: '30px', marginBottom: '20px', marginTop: '20px' }}>
                    <div>
                        <div style={{ marginBottom: '5px' }}>אופטימיזציית</div>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            onChange={handleTravelMethodChange}
                            value={travelMethod}
                            sx={{ flexDirection: 'row-reverse' }}
                            aria-label="Platform"
                        >
                            <ToggleButton value={1}>תחנות</ToggleButton>
                            <ToggleButton value={2}>מסלולים</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div>
                        <div style={{ marginBottom: '5px' }}>סוג נסיעה</div>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            onChange={handleTravelTypeChange}
                            value={travelType}
                            sx={{ flexDirection: 'row-reverse' }}
                            aria-label="Platform"
                        >
                            <ToggleButton value={1}>פיזור</ToggleButton>
                            <ToggleButton value={2}>איסוף</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {travelMethod === 1 && (
                        <div>
                            <div style={{ marginBottom: '10px' }}>מרחק הליכה מתחנה (במטר)</div>
                            <NumberSlider
                                handleInputChange={handleWalkingDistanceInputChange}
                                handleSliderChange={handleWalkingDistanceSliderChange}
                                value={walkingDistanceFromStation}
                                max={maxWalkingDistanceValue}
                                min={0}
                                step={100}
                            />
                        </div>
                    )}

                    {travelMethod === 2 && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <div>
                                <div style={{ marginBottom: '10px' }}>משך נסיעה קרובה (בדקות)</div>
                                <NumberSlider
                                    handleInputChange={handleMaxTravelTimeInputChange}
                                    handleSliderChange={handleMaxTravelTimeSliderChange}
                                    value={maxTravelTime}
                                    max={maxTravelTimeValue}
                                    min={0}
                                    step={100}
                                />
                            </div>
                            <div>
                                <div style={{ marginBottom: '10px' }}>משך נסיעה רחוקה (בדקות)</div>
                                <NumberSlider
                                    handleInputChange={handleMaxTravelTimeRingTwoInputChange}
                                    handleSliderChange={handleMaxTravelTimeRingTwoSliderChange}
                                    value={maxTravelTimeRingTwo}
                                    max={maxTravelTimeRingTwoValue}
                                    min={0}
                                    step={100}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                        <div style={{ marginBottom: '10px' }}>מקסימום נוסעים ברכב</div>
                        <NumberSlider
                            handleInputChange={handleVehicleCapacityInputChange}
                            handleSliderChange={handleVehicleCapacitySliderChange}
                            value={vehicleCapacity}
                            max={maxVehicleCapacity}
                            min={0}
                            step={100}
                        />
                    </div>
                </div>
            </WidgetFormWrapper>
        </div>
    );
});

export default OptimizationParametersForm;
