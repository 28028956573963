import { containers } from 'src/Layouts/styles';

import React, { useEffect, useRef, useState } from 'react';
import WidgetsContainerV2 from 'src/Layouts/WidgetsContainer/WidgetsContainerV2';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import Alert from 'src/components/commons/Alert/Alert';
import { useAlertV2, useDispatchAlert } from 'src/hooks/useAlert';
import RefWrapper from 'src/components/Wrappers/RefWrapper/RefWrapper';
import WidgetWrapperV2 from 'src/components/Wrappers/Widgets/WidgetCardWrapperV2/WidgetCardWrapperV2';
import WidgetsLayoutContainer from '../CoursesBuilding/components/WidgetsLayoutContainer';
import useDelayedWidgetsDisplay from '../CoursesBuilding/hooks/useDelayedWidgetsDisplay';
import HeaderWithDropdown from 'src/components/HeaderWithDropdown/HeaderWithDropdown';
import {
    optimizationsRootSelector,
    setClientCodeAction,
    setIsDeleteModalOpenAction,
    setIsFormOpenAction,
    setMapIsExpandedAction,
    setWaypointStockAction,
    setTimeDeltaAction,
    selectWaypointAction,
    setIsCsvLoadDoneModalOpenAction,
    setCsvWaypointsObjectAction,
    setIsGeneralLoadingAction,
    setWaypointsOnFormEditAction,
    setIsEditFormAction,
    setCenterMapOnEditAction,
    setZoomOnEditAction,
    setIsOptimizationSimulationScreenOpenAction,
    setSimulationResultAction,
    setIsEditRouteNameModalOpenAction,
    setRouteOnEditAction,
    setOptimumRoutesAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import { Input, Slide, Switch, TextField } from '@material-ui/core';
import SearchInput from 'src/components/SearchInput/SearchInput';
import ComboBox from 'src/components/ComboBox/ComboBox';
import { FormControlLabel, IconButton, MenuItem, MenuList, Popover } from '@mui/material';
import WaypointStockGrid from './WaypointStockGrid/WaypointStockGrid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import TrashIcon from '../PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import { iconStyle } from '../Reports/components/ReportsSection/Table/DataGrids/VisasReportPassengersGrid/styles';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import { disabledIcon, styleIcon } from '../PassengerManger/components/Passengers/styles/css-styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { avatarType } from '@progress/kendo-react-layout/dist/npm/card/interfaces/Enums';
import CourseWidgetMap from '../CoursesBuilding/components/CourseMap/CourseWidgetMap';
import Map from 'src/components/Map/Map';
import OptimizationWidgetLayoutContainer from './components/OptimizationWidgetLayoutContainer';
import CourseForm from '../CoursesBuilding/components/CourseForm/CourseForm';
import OptimizationForm from './components/optimizationForm/OptimizationForm';
import OptimizationWidgetMap from './components/OptimizationWidgetMap';
import OptimizationFloatMap from './components/OptimizationFloatMap';
import { deleteOptimizationWaypoints, getServerOptimizationWaypoints } from './api/optimizationApi';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { IOptimizationFormWaypoint, IOptimizationWaypoint } from './api/types';
import { WsResponse } from 'src/api/types';
import moment from 'moment';
import { buildWaypointName } from './helpers';
import DeleteConfirmModal from 'src/components/Modals/DeleteConfirmModal';
import { borderColor, margin } from '@mui/system';
import { useGetOptimizationWaypoints } from './hooks/useGetOptimizationWaypoints';
import { useDelOptimizationWaypoints } from './hooks/useDelOptimizationWaypoints';
import Papa from 'papaparse';
import { useCsvReverseGeo } from './hooks/useCsvReverseGeo';
import ActionConfirmModal from 'src/components/Modals/ActionConfirmModal';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WrapperLoadingIndication from 'src/components/Wrappers/WrapperLoadingIndication';
import LoadingComponent from 'src/components/commons/Spinner/LoadingComponent';
import useOptimizationFilters from './hooks/useOptimizationFilters';
import Spinner from 'src/components/Spinner/Spinner';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { uuid } from 'src/utilis/utilis';
import HeaderWithBack from 'src/components/HeaderWithBack/HeaderWithBack';

import SimulationForm from './components/optimizationSimulation/SimulationForm';
import SimulationGrid from './components/optimizationSimulation/SimulationGrid';
import useSimulationFilters from './hooks/useSimulationFilters';
import OptimizationTargetSection from './components/OptimizationTargetSection';

const OptimizationSimulationScreen = () => {
    const { t } = useTranslation();
    const {
        ui: {
            optimizationSimulationScreen: {
                isOpen,
                routesSelectedIdsWithColor,
                isEditRouteNameModalOpen,
                routeOnEdit,
            },
        },
        data: {
            getSimulationResult: { data: simulationResult },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const simulationFilters = useSimulationFilters();

    const dispatch = useAppDispatch();

    const OnBackToWaypointsManagement = () => {
        dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: false }));
        console.log('OnBackToWaypointsManagement');
    };

    const headerWithBack = {
        onBackClick: OnBackToWaypointsManagement,
        style: { paddingTop: '32px', paddingBottom: '20px' },
    };

    useEffect(() => {
        let updatedRoutes = simulationResult?.optimumRouteResult;

        if (simulationResult && updatedRoutes && Array.isArray(updatedRoutes)) {
            const routeIds: string[] = [];
            routesSelectedIdsWithColor.forEach((routeIdWithColor) => {
                routeIds.push(routeIdWithColor.routeId);
            });

            updatedRoutes = updatedRoutes.map((route) => {
                const selected = routeIds.includes(route.localId);
                return { ...route, isSelected: selected };
            });

            dispatch(
                setSimulationResultAction({
                    simulationResult: { ...simulationResult, optimumRouteResult: updatedRoutes },
                })
            );
        }
    }, [routesSelectedIdsWithColor]);

    const onChangeRouteNameConfirm = () => {
        if (simulationResult) {
            const routes = [...simulationResult.optimumRouteResult];

            if (routes && routeOnEdit) {
                const index = routes.findIndex((route) => route.localId === routeOnEdit.localId);

                if (index !== -1) {
                    routes[index] = routeOnEdit;
                    dispatch(setOptimumRoutesAction({ route: routes }));
                }
            }
        }
        dispatch(setIsEditRouteNameModalOpenAction({ isOpen: false }));
    };

    const onChangeRouteNameModalClose = () => {
        dispatch(setIsEditRouteNameModalOpenAction({ isOpen: false }));
    };

    const handleRouteNameInputChange = (ev: any) => {
        if (routeOnEdit) {
            dispatch(setRouteOnEditAction({ route: { ...routeOnEdit, routeName: ev.target.value } }));
        }
    };

    return (
        <>
            <containers.ScreenContainer className="screen-container">
                <ActionConfirmModal
                    Body={() => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ marginBottom: '10px' }}>שנה שם מסלול</div>
                            <TextField
                                onChange={handleRouteNameInputChange}
                                value={routeOnEdit?.routeName}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    )}
                    isOpen={isEditRouteNameModalOpen}
                    Img={() => <MyEditPencilIcon color="" />}
                    onConfirm={onChangeRouteNameConfirm}
                    onClose={onChangeRouteNameModalClose}
                />
                <containers.MainSectionContainer className="main-section-container">
                    <HeaderWithBack {...headerWithBack} />

                    <div style={{ padding: '1.76em' }}>
                        <div style={{ marginBottom: '15px' }}>
                            <OptimizationTargetSection isDisabled />
                        </div>
                        <div style={{ display: 'flex', gap: '19px', height: '80vh' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                                    <SearchInput
                                        onChange={(ev) => {
                                            simulationFilters.setFilterText(ev.target.value);
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            paddingTop: '12px',
                                            paddingBottom: '12px',
                                            gap: '5px',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div style={{ color: '#2196F3' }}>
                                            {routesSelectedIdsWithColor
                                                ? routesSelectedIdsWithColor.length
                                                : 0}{' '}
                                            {t('selectedCourses')}
                                        </div>
                                        <div>
                                            {simulationFilters.filteredRoutes
                                                ? simulationFilters.filteredRoutes.length
                                                : 0}{' '}
                                            {t('sumCourses')}
                                        </div>
                                    </div>
                                    <SimulationGrid data={simulationFilters.filteredRoutes} />
                                </div>
                            </div>
                            <div style={{ border: '1px solid black', width: '65%' }}>layout</div>
                        </div>
                    </div>
                </containers.MainSectionContainer>
                <WidgetsContainerV2 isOpen>
                    {/* Widgets */}
                    <Slide direction="up" in={isOpen} unmountOnExit>
                        <SimulationForm />
                    </Slide>
                </WidgetsContainerV2>
            </containers.ScreenContainer>
        </>
    );
};

export default OptimizationSimulationScreen;
