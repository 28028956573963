import { ExistingPassenger, Station } from 'src/screens/Main/components/AddLineForm/types';
import { v4 } from 'uuid';

const createStationFromPassenger = (passenger: ExistingPassenger) => {
    const station: Station = {
        stationId: v4(),
        passengers: [passenger.passCode],
        city: passenger.city,
        street: passenger.street,
        houseNum: passenger.houseNum,
        isFromPassenger: true,
    };
    return station;
};

export const linesHelpers = {
    createStationFromPassenger,
};
