/* eslint-disable consistent-return */
import { CSSProperties } from 'react';
import { activeEffect, activeEffectBackgroundColor } from 'src/style/elementStyleAttributes';
import { colors } from 'src/style/themes/defaultTheme';
import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div<{
    isBlocked: boolean;
    isEmpty: boolean;
    isTargetToScheduling: boolean;
    isShiftSelected: boolean;
    formIsOpen: boolean;
    isEditOrAddingShiftOnCell: any;
    // validForBeingAddedToPlacement: boolean;
}>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px;
    background: transparent;
    position: relative;

    ${(props) => props.isShiftSelected && props.formIsOpen && 'cursor: not-allowed'};

    ${({ isBlocked }) =>
        isBlocked &&
        css`
            background-image: linear-gradient(
                135deg,
                transparent 44.44%,
                #d8d8d8 44.44%,
                #d8d8d8 50%,
                transparent 50%,
                transparent 94.44%,
                #d8d8d8 94.44%,
                #d8d8d8 100%
            );
            background-size: 12.73px 12.73px;
            pointer-events: none;
        `}s

    ${({ isEmpty, isBlocked, isTargetToScheduling, isShiftSelected }) =>
        !isShiftSelected &&
        isEmpty &&
        !isBlocked &&
        css`
            :hover {
                border: ${!isTargetToScheduling && '1px dashed black'};
                cursor: pointer;
                span {
                    ${isTargetToScheduling && 'display: none'}
                }
            }
        `}
`;

export const SingleShiftContainer = styled.div<{
    wasJustAdded?: boolean;
    color: string;
    isPast: boolean;
    isShiftSelected: boolean;
    formIsOpen: boolean;
    isBeingEdited?: boolean;
    isTargetToDelete?: boolean;
}>`
    ${({
        isPast,
        color = 'white',
        theme,
        isShiftSelected,
        // wasJustAdded,
        // isBeingEdited,
        isTargetToDelete,
        formIsOpen,
    }) => `
   position: relative;
   background-color: ${color};
   border: 1px solid ${color === 'white' ? 'lightGray' : color};
   border-radius: 4px;
   height: 30px;
   color: ${theme.msColors.primaryTextcolor};
   font-weight: 400;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 3px;
   :hover {
      cursor: ${isShiftSelected && formIsOpen ? 'not-allowed' : 'pointer'};
   }
   font-size: 0.9em;
   ${isPast && `opacity: 0.6`};
   ${
       isTargetToDelete
           ? css`
                 border: 2px dashed red;
                 transition: border 0.2s ease-in-out;
             `
           : ''
   };
   `};
`;

export const EmptyContainer = styled.div`
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20%;
    :active {
        transform: translate(-50%, -48%);
    }
`;

export const IconContainer = styled.div<{
    right?: boolean;
    style?: CSSProperties;
}>`
    background-color: ${({ theme }) => theme.colors.blue};
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: -5.5px;
    ${activeEffect}
    ${({ right }) => right && `right: -5.5px;`}
   z-index: 999;
    ${({ style }) => style && `${style}`}
`;

export const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    height: 203px;
    width: 293px;
    position: relative;
    padding: 0 0 10px 0;
    border-radius: 9px;
    box-shadow: 0px 5px 12px rgba(217, 226, 233, 0.5);
    color: black;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
`;

export const ModalAction = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto;
`;

export const CircleModal = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #ebb835;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    transform: translateY(-50%);
    z-index: 9999;
`;

export const AddMoreShift = styled.div<{
    isTargetToScheduiling: boolean;
    isShiftSelected?: boolean;
}>`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #494949;
    cursor: pointer;
    transform: translateY(2px);
    border-radius: 4px;
    ${(props) => {
        if (props.isTargetToScheduiling)
            return `:active {
            background-color: rgba(0, 0, 0, 0.1);
            transition: all 0.2s;
         }
         display: none;`;
    }}
`;

// const shakeAnimation = keyframes`
//    0% { transform: translate(1px, 1px) rotate(0deg); }
//    10% { transform: translate(-1px, -2px) rotate(-1deg); }
//    20% { transform: translate(-3px, 0px) rotate(1deg); }
//    30% { transform: translate(3px, 2px) rotate(0deg); }
//    40% { transform: translate(1px, -1px) rotate(1deg); }
//    50% { transform: translate(-1px, 2px) rotate(-1deg); }
//    60% { transform: translate(-3px, 1px) rotate(0deg); }
//    70% { transform: translate(3px, 1px) rotate(-1deg); }
//    80% { transform: translate(-1px, -1px) rotate(1deg); }
//    90% { transform: translate(1px, 2px) rotate(0deg); }
//    100% { transform: translate(1px, -2px) rotate(-1deg); }
// `;
