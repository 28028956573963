import { commonErrorMessagesByCode } from '../apiCommon.types';

// Actual API
enum EndpointErrorCodes {}

enum RouteErrorMessages {
    getAccountCoursesFailedTryAgain = 'getAccountCoursesFailedTryAgain',
}

enum UniqueMockServerCodes {
    getAccountCoursesFailed = 92,
    // getCourseStationsFailed = 93,
}

const errorMessagesByCode = {
    ...commonErrorMessagesByCode,
    [UniqueMockServerCodes.getAccountCoursesFailed]: RouteErrorMessages.getAccountCoursesFailedTryAgain,
};

// -- Ws Get_Course_Times ------------------------------------------------
export interface IGetCourseTimesResDataItem {
    day: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
    times: {
        startTime: string;
        endTime: string;
    }[];
    carTypes: { carTypeCode: string; carTypeName: string }[];
}

// -- Exports ----------------------------------------------------------------
export const CoursesBuildingApiMappers = {
    errorMessagesByCode,
};

export const CoursesBuildingApiEnums = {
    EndpointErrorCodes,
    GeneralErrorMessages: RouteErrorMessages,
    UniqueMockServerCodes,
};
