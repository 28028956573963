import React, { FunctionComponent, PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme, Tooltip as MaterialTooltip, TooltipProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrow: {
            '&:before': {
                border: '1px solid #E6E8ED',
            },
            color: theme.palette.common.white,
        },
        tooltip: {
            backgroundColor: theme.palette.common.white,
            border: '1px solid #E6E8ED',
            color: 'black',
            fontSize: '14px',
        },
    })
);

export interface ITooltipProps extends TooltipProps {
    arrowClassName?: string;
    tooltipClassName?: string;
}

const Tooltip: FunctionComponent<PropsWithChildren<ITooltipProps>> = ({
    arrowClassName,
    tooltipClassName,
    ...prop
}: PropsWithChildren<ITooltipProps>) => {
    const classes = useStyles();

    return (
        <MaterialTooltip
            {...prop}
            classes={{
                arrow: arrowClassName || classes.arrow,
                tooltip: tooltipClassName || classes.tooltip,
            }}
        />
    );
};

export default Tooltip;
