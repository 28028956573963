/* eslint-disable react/prop-types */
import React from 'react';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import Divider from '@material-ui/core/Divider';
import { containerStyle, dividerStyle } from './styles';

interface Props {
    selectedPassenger: IPassenger | any;
}

const DepartmentAndPassTypeDisplay: React.FC<Props> = ({ selectedPassenger }) => {
    return (
        <div style={containerStyle}>
            {/* <p>{selectedPassenger?.departmentName || 'לא מוגדרת מחלקה'}</p> */}
            <p style={{ margin: 0 }}>{selectedPassenger?.departmentName}</p>
            {selectedPassenger?.departmentName && selectedPassenger?.passTypeName && (
                <p style={dividerStyle}>|</p>
            )}
            {/* <p>{selectedPassenger?.passTypeName || 'לא מוגדר סוג נוסע'}</p> * With empty data msg */}
            <p style={{ margin: 0 }}>{selectedPassenger?.passTypeName}</p>
        </div>
    );
};

export default DepartmentAndPassTypeDisplay;
