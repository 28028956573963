import React, { useState } from 'react';

import AddressAutoComplete from 'src/components/Inputs/AddressAutoComplete';
import { cs } from 'src/style/styledComponents.style';
import { AddressInfo } from 'src/components/AddressInputs/types';
import WithManualInputBtn from 'src/components/AddressInputs/ManualAndGoogleAddressInput/WithManualInputBtn/WithManualInputBtn';
import { AddressInput } from 'src/screens/Main/components/AddLineForm/types';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import { initialAddress } from 'src/screens/Main/components/AddLineForm/constants';
import ManualAddressInputVer2, { Props as ManualInputProps } from '../ManualAddress/v2/ManualAddressVer2';

export type Ver2AddressInputProps = {
    defaultType?: 'autocomplete' | 'manual';
    autocompleteConfig: {
        props: {
            setFullAddressData: (newVal: AddressInput) => void;
            label: string;
            setIsInputEmpty?: Function;
        };
        errorMessage?: string;
    };
    manualConfig: {
        props: ManualInputProps;
    };
};

const Ver2ManualAndGoogleAddressInput: React.FC<Ver2AddressInputProps> = ({
    defaultType,
    autocompleteConfig,
    manualConfig,
}): JSX.Element => {
    const [usingAutocomplete, setUsingAutocomplete] = useState(defaultType !== 'manual');

    React.useEffect(() => {
        if (defaultType === 'manual') {
            setUsingAutocomplete(false);
        }
    }, [defaultType]);

    return (
        <cs.FullWidth>
            {usingAutocomplete ? (
                <cs.FullWidth>
                    <InputWithErrorsWrapper errorMessage={autocompleteConfig.errorMessage}>
                        <AddressAutoComplete
                            setAddressData={autocompleteConfig.props.setFullAddressData}
                            error={!!autocompleteConfig.errorMessage}
                            label={autocompleteConfig.props.label}
                            setIsInputEmpty={autocompleteConfig.props.setIsInputEmpty}
                        />
                    </InputWithErrorsWrapper>
                    <WithManualInputBtn
                        usingManualInput={!usingAutocomplete}
                        setUsingManualInput={(val: boolean) => {
                            setUsingAutocomplete(!val);
                            autocompleteConfig.props.setFullAddressData(initialAddress);
                        }}
                    />
                </cs.FullWidth>
            ) : (
                <ManualAddressInputVer2
                    {...manualConfig.props}
                    onClickExit={() => {
                        manualConfig.props.onClickExit();
                        setUsingAutocomplete(true);
                    }}
                />
            )}
        </cs.FullWidth>
    );
};

export default Ver2ManualAndGoogleAddressInput;
