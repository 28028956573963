import React from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    setMapIsOnEditModeAction,
    setStationsErrorsAction,
    setUpdatedCoordsOnMapAction,
    setNewCenterAction,
    uiSelector,
    setIsLocationChangedAction,
    setLastIndexEditedAction,
    setIsConfirmLocationButtonAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import useCommons from 'src/hooks/common/useCommons';
import { UseFieldArrayUpdate } from 'react-hook-form';
import { StationSchema } from 'src/types/coursesBuilding/form/formTypes';
import { courseStationSchema } from '../../reactHookFormConfigs/detailsSchema';
import _ from 'lodash';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import usePrevious from 'src/hooks/usePreviousState';
import { StationValidationErrors } from './utils.StationInputBox';
import { DropResult } from 'react-beautiful-dnd';

export interface StationsErrors {
    [stationId: string]: '' | StationValidationErrors;
}

const useValidations = () => {
    const { dispatch } = useCommons();

    const fieldsCopy = useRootAppSelector((state) => courseBuildingFormSelector(state).map.fieldsCopy);

    const validateStations = () => {
        const newErrors = {};

        fieldsCopy.forEach((station) => {
            if (!station.city) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newErrors[station.stationId] = StationValidationErrors.MissingCity;
                return;
            }

            if (!station.lat || !station.lng) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newErrors[station.stationId] = StationValidationErrors.MissingCoords;
                return;
            }

            try {
                courseStationSchema.validateSync(station); // throws error if not valid
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (newErrors[station.stationId]) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    delete newErrors[station.stationId];
                }
            } catch (error) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newErrors[station.stationId] = StationValidationErrors.GeneralError;
            }
        });

        dispatch(setStationsErrorsAction({ errors: newErrors }));
    };

    React.useEffect(() => {
        validateStations();
    }, [fieldsCopy]);
};

export type StationUpdateMethod = UseFieldArrayUpdate<StationSchema>;

export const useCoordsMapUpdate = (fields: StationSchema[], update: StationUpdateMethod) => {
    const isFirstRender = useIsFirstRender();
    const { dispatch } = useCommons();

    const { triggerFieldCoordsUpdateCount, updatedCoords, editedStationId } = useRootAppSelector(
        (state) => uiSelector(state).courseBuildingForm.map
    );

    const prevTriggerCount = usePrevious(triggerFieldCoordsUpdateCount);

    const updateCoords = () => {
        const index = fields.findIndex((field) => field.stationId === editedStationId);

        dispatch(setLastIndexEditedAction({ index }));

        const oldData = fields[index];

        if (oldData) update(index, { ...oldData, ...updatedCoords });
    };

    React.useEffect(() => {
        if (
            triggerFieldCoordsUpdateCount > 0 &&
            !isFirstRender &&
            prevTriggerCount !== triggerFieldCoordsUpdateCount
        ) {
            updateCoords();
            dispatch(
                setMapIsOnEditModeAction({
                    isOnEditMode: false,
                    editedStationId: null,
                })
            );

            dispatch(setUpdatedCoordsOnMapAction(null));
        }
    }, [isFirstRender, triggerFieldCoordsUpdateCount, dispatch]);
};

type UseFieldDragArgs = {
    fields: StationSchema[];
    methods: {
        update: (index: number, data: StationSchema) => void;
        move: (index: number, newIndex: number) => void;
    };
};

export const useFieldDrag = ({ fields, methods }: UseFieldDragArgs) => {
    const { dispatch } = useCommons();

    const updateTimeFromPrevStation = (stationId: string, newVal: number | null) => {
        const index = fields.findIndex((f) => f.stationId === stationId);

        if (index === -1) return;

        methods.update(index, { ...fields[index], timeFromPrev: newVal, isManualTimeFromPrev: false });
    };

    const [timesFromPrevStationResetQueue, setTimesFromPrevStationResetQueue] = React.useState<string[]>([]);

    function onDragEnd(result: DropResult) {
        if (!result.destination) return;

        const originIndex = result.source.index;
        const destinationIndex = result.destination.index;

        const s1 = fields[originIndex];
        const s2 = fields[destinationIndex];

        const idxToReset = originIndex < destinationIndex ? destinationIndex + 1 : destinationIndex + 2;

        const s3 = fields[idxToReset];

        const newResetQueue = [];

        if (s1) newResetQueue.push(s1.stationId);
        if (s2) newResetQueue.push(s2.stationId);
        if (s3) newResetQueue.push(s3.stationId);

        // done separately because of RHF limitations with stacking actions
        setTimesFromPrevStationResetQueue(newResetQueue);

        methods.move(result.source.index, result.destination.index);

        dispatch(setIsLocationChangedAction({ isLocationChanged: true }));
        dispatch(setIsConfirmLocationButtonAction({ isConfirmLocationButton: false }));
    }

    React.useEffect(() => {
        if (!timesFromPrevStationResetQueue.length) return;

        timesFromPrevStationResetQueue.forEach((stationId) => {
            updateTimeFromPrevStation(stationId, null);
        });

        setTimesFromPrevStationResetQueue([]);
    }, [timesFromPrevStationResetQueue]);

    return { onDragEnd };
};

export default useValidations;
