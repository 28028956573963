import React, { FC, useEffect, useState } from 'react';
import {
    TileLayout,
    TileLayoutItem,
    TileLayoutRepositionEvent,
    TilePosition,
} from '@progress/kendo-react-layout';
import { StorageKeys } from 'src/types/global';
import { useLocation } from 'react-router-dom';
import { getLocalStorageValue, setWidgetLayout } from 'src/utilis/utilis';
import CourseInfoWidget from './CourseInfoWidget/CourseInfoWidget';
import CourseWidgetMap from './CourseMap/CourseWidgetMap';

const WidgetsLayoutContainer: React.FC<{}> = () => {
    const location = useLocation();

    const tiles: Array<TileLayoutItem> = [
        {
            item: <CourseInfoWidget />,
            resizable: 'vertical',
            style: { overflowY: 'auto' },
        },
        {
            item: <CourseWidgetMap />,
            reorderable: false,
            resizable: false,
        },
    ];

    const defaultTilePosition = [
        {
            col: 1,
            colSpan: 1,
            rowSpan: 14,
        },
        {
            col: 1,
            colSpan: 1,
            rowSpan: 14,
        },
    ];

    const [tilePosition, setTilePosition] = useState<Array<TilePosition>>(defaultTilePosition);

    const [layout, setLayout] = useState<Array<TilePosition>>(tilePosition);

    const handleReposition = (e: TileLayoutRepositionEvent) => {
        const [firstElement, secondElement] = e.value;
        setTilePosition([firstElement, secondElement]);
        setLayout([firstElement, secondElement]);
    };

    useEffect(() => {
        const widgets = JSON.parse(getLocalStorageValue(StorageKeys.CoursesBuildingWidgetsLayout) || '{}');

        if (!widgets[location.pathname]) return;

        const cachedLayout = widgets[location.pathname];
        setTilePosition(cachedLayout);
        setLayout(cachedLayout);
    }, [location.pathname]);

    useEffect(() => {
        setWidgetLayout(location.pathname, tilePosition, StorageKeys.CoursesBuildingWidgetsLayout);
    }, [location.pathname, tilePosition]);

    return (
        <TileLayout
            autoFlow="column"
            columns={1}
            rowHeight={22}
            positions={layout}
            gap={{
                rows: 10,
                columns: 10,
            }}
            style={{ height: '100%', padding: 0 }}
            items={tiles}
            onReposition={handleReposition}
            className="tile-layout-course-building"
        />
    );
};

export default WidgetsLayoutContainer;
