const isNumericString = (val: unknown): val is string => {
    return typeof val === 'string' && val.length && !val.includes(' ') && !Number.isNaN(Number(val))
        ? true
        : false;
};

const isTypeofNumber = (value: unknown): value is number => {
    return typeof value === 'number';
};

/**
 * Takes in a number and formats it to have commas in the thousands place.
 * @param {number} num - the number to format
 * @returns {string} the formatted number
 */
export const formatNumberWithCommas = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const numberHelpers = {
    isNumericString,
    isTypeofNumber,
    formatNumberWithCommas,
};

export default numberHelpers;
