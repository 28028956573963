/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextFieldProps } from '@mui/material';
import moment, { Moment } from 'moment';
import { StyledTimePickerTextField } from 'src/components/TimePicker/TimePickerIndex';
import { UseFormReturn } from 'react-hook-form';
import { TIME_FORMATS } from '../../../constants/dates';

export interface TimePickerProps<T> {
    formMethods: UseFormReturn<any, any>;
    textFieldProps: TextFieldProps;
    inputName: string;
}

/* 
 ^ -- Limitations:
 ^ A. Limited Type checking:
 ^  1. inputName not checked to be in form methods schema
 ^  2. value on schema must be a string
*/
const MyTimePicker: <T extends unknown>(props: TimePickerProps<T>) => JSX.Element = ({
    formMethods,
    textFieldProps,
    inputName,
}) => {
    const { t } = useTranslation();
    const [time, setTime] = useState<Moment | null>(null);

    const INPUT_NAME = inputName as any;

    const value = formMethods.getValues(INPUT_NAME) as string;

    useEffect(() => {
        formMethods.register(INPUT_NAME);
    }, [INPUT_NAME, formMethods]);

    useEffect(() => {
        setTime(moment(value, TIME_FORMATS.TWENTY_FOUR) || null);
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label={textFieldProps.label || t('pickTime')}
                value={time}
                onChange={(newDate) => {
                    if (newDate) {
                        let newValue = moment(newDate).format(TIME_FORMATS.TWENTY_FOUR);
                        if (newValue === 'Invalid date') newValue = '';

                        formMethods.setValue(INPUT_NAME, newValue as any, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                        });
                    }
                }}
                ampm={false}
                inputFormat={TIME_FORMATS.TWENTY_FOUR}
                renderInput={(params) => (
                    <StyledTimePickerTextField
                        id="time"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        {...params}
                        {...textFieldProps as any}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default MyTimePicker;
