import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateToken } from 'src/api/api';
import { setTokenRefreshStatus } from 'src/store/actions/loginAction';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { dropsDisplaySelectors } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import { asRootReducer } from 'src/store/utils';
import { RefreshTokenStatus } from 'src/types/login';
import useGetApiRequestBasicParams from '../useGetApiRequestBasicParams';

type Props = {};

export const useRefreshToken = () => {
    const dispatch = useDispatch();

    const [isLoadingToken, setIsLoadingToken] = useState(false);

    const { dbUrl, token, proxyUrl, contactUUID } = useGetApiRequestBasicParams();

    const refreshToken = useCallback(async () => {
        if (!isLoadingToken && contactUUID && token && dbUrl) {
            setIsLoadingToken(true);

            const promises: Array<Promise<void>> = [];

            promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));

            Promise.all(promises).then(() => {
                setIsLoadingToken(false);
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Ok));
            });
        }
    }, [contactUUID, dbUrl, dispatch, isLoadingToken, proxyUrl, token]);

    return { refreshToken, isLoadingToken };
};
