import _ from 'lodash';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { rideSettingsSelectors as selectors } from 'src/store/slices/rideSettings/rideSettings';
import { mediumRubik } from 'src/style/styledCSS';

import { List, ListItem, ListItemText } from '@mui/material';

import { Permission, SettingTypes } from 'src/types/rideSettings/store/types.store';
import actions from 'src/store/actions/rideSettings/rideSettingsActions';
import { listItemStyle } from './stylesCss';
import ToggleBtns from './ToggleBtns';

type Props = {};

const listItemSx = { padding: '0 0 10px 0' };
const listItemTextSx = {
    ...mediumRubik,
    '.MuiListItemText-primary': { fontFamily: 'Rubik' },
};
const PermissionManagementFormBody: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // * Selectors
    const currentSettings = useRootAppSelector(
        (state) => selectors.selectData(state).permissionManagement.settings
    );

    //* Local States
    const [newSettingsData, setNewSettingsData] = useState<{
        DEPARTMENT_MGR: Permission | null;
        WEB_ACCESS: Permission | null;
    }>({
        DEPARTMENT_MGR:
            currentSettings.permissions.find((setting) => setting.permissionType === 'DEPARTMENT_MGR') ||
            null,
        WEB_ACCESS:
            currentSettings.permissions.find((setting) => setting.permissionType === 'WEB_ACCESS') || null,
    });
    // * Event handlers

    // * Effects
    useEffect(() => {
        // & handle the form changed event
        const permissions: Permission[] = [];

        Object.values(newSettingsData).forEach((val) => {
            if (val) permissions.push(val);
        });

        const sameAsInitialSettings = _.isEqual(
            Object.entries(currentSettings.permissions).sort(),
            Object.entries(permissions).sort()
        );

        if (!sameAsInitialSettings) {
            dispatch(
                actions.formChangedAction<{ permissions: Permission[] }>({
                    settingType: SettingTypes.PermissionManagement,
                    updatedValues: { permissions },
                })
            );
        } else {
            // dispatch(actions.onChangeIsSameAsInitialState());
        }
    }, [currentSettings, dispatch, newSettingsData]);

    useEffect(() => {
        dispatch(
            actions.hasSubmitConfigs({
                stringify: true,
                dataParamName: 'jsonData',
            })
        );
    }, [dispatch]);

    return (
        <>
            <List disablePadding style={{ width: '100%' }}>
                {newSettingsData.WEB_ACCESS ? (
                    <ListItem disablePadding disableGutters style={listItemStyle} sx={listItemSx}>
                        <ListItemText
                            id="switch-list-label-wifi"
                            primary={t('WEB_ACCESS')}
                            sx={listItemTextSx}
                        />
                        <ToggleBtns
                            initialSettings={currentSettings.permissions.find(
                                (setting) => setting.permissionType === 'WEB_ACCESS'
                            )}
                            setNewSettingsData={setNewSettingsData}
                            permissionType="WEB_ACCESS"
                        />
                    </ListItem>
                ) : (
                    <></>
                )}
                {newSettingsData.DEPARTMENT_MGR ? (
                    <ListItem sx={listItemSx} disablePadding disableGutters style={listItemStyle}>
                        <ListItemText
                            id="switch-list-label-wifi"
                            primary={t('DEPARTMENT_MGR')}
                            sx={listItemTextSx}
                        />
                        <ToggleBtns
                            initialSettings={currentSettings.permissions.find(
                                (setting) => setting.permissionType === 'DEPARTMENT_MGR'
                            )}
                            setNewSettingsData={setNewSettingsData}
                            permissionType="DEPARTMENT_MGR"
                        />
                    </ListItem>
                ) : (
                    <></>
                )}
            </List>
        </>
    );
};

export default PermissionManagementFormBody;
