/* eslint-disable import/no-cycle */
import { SagaIterator } from 'redux-saga';
import { put, call, takeLatest } from 'redux-saga/effects';
import { getDepartmentsStatistics, getReport, getShortReport } from 'src/api/reportsApi/reportsApi';
import { CallGetShiftTimesResponse, ShortReportGetRequest } from 'src/api/reportsApi/types';

import { fetchGeneralSettings } from 'src/api/rideSettingsApi/rideSettingsApi';
import { RefreshTokenStatus } from 'src/types/login';
import {
    fetchDepartmentsStatisticsAction,
    fetchReportAction,
    fetchShiftTimesAction,
    fetchShortOrdersAction,
    onErrorAction,
    onFetchReportStatusUpdateAction,
    onFetchReportSuccessAction,
    onFetchShiftTimesAction,
    onFetchShortOrdersStatusUpdateAction,
} from 'src/store/slices/reports/reportsSlice';
import { handleError } from 'src/utilis/errorHandling';
import { getErrorMessage } from 'src/utilis/utilis';
import { AxiosResponse } from 'axios';
import { DepartmentStatisticsResponse } from 'src/types/reports/generalReportsTypes';
import { FcResponseState, ReqStatus } from 'src/api/types';
import { setTokenRefreshStatus, setErrorCode } from '../actions/loginAction';
import { SettingTypes } from '../../types/rideSettings/store/types.store';
import { ISagaRequest } from '../type';
import { callGetShiftTimes, CallGetShiftRequest } from '../../api/reportsApi/filters/filtersApi';
import {
    handleSagaError,
    handleResponse,
    validatePayload,
    handleBadStatus,
    getErrorCodeByResponse,
} from './utils/sagasUtils';

function* fetchShortOrdersReportWatcher({ payload }: ISagaRequest<{ dbUrl: string; requestParams: any }>) {
    try {
        yield validatePayload({
            payload,
            failureAction: onFetchShortOrdersStatusUpdateAction,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiResponse: { data: any } = yield call(() => {
            return getShortReport(payload);
        });
        const { response, data } = apiResponse.data;

        if (response === FcResponseState.TokenExpired) setTokenRefreshStatus(RefreshTokenStatus.Invalid);

        if (+response === 0) {
            yield put(
                onFetchShortOrdersStatusUpdateAction({
                    data,
                    status: ReqStatus.SUCCESS,
                })
            );
        } else {
            const errorCode = getErrorCodeByResponse(response);
            // yield put(setErrorCode(errorCode));
            // handleError({ details: apiResponse.data });
            yield put(
                onFetchShortOrdersStatusUpdateAction({
                    status: ReqStatus.FAIL,
                })
            );
            yield put(
                onErrorAction({
                    msg: getErrorMessage(
                        null,
                        `${errorCode} - error on fetchShortOrdersReportWatcher`,
                        false
                    ),
                    details: apiResponse.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield handleSagaError({
            errorAction: onFetchShortOrdersStatusUpdateAction,
            msg: getErrorMessage(error, 'fetchShortOrdersReportWatcher'),
        });
    }
}
function* callGetShiftTimesWatcher({ payload }: ISagaRequest<CallGetShiftRequest>) {
    try {
        const apiResponse: AxiosResponse<CallGetShiftTimesResponse> = yield call(() =>
            callGetShiftTimes(payload)
        );

        const { response, accountShifts } = apiResponse.data;

        yield handleResponse({
            response,
            status: apiResponse.status,
            data: accountShifts,
            successAction: onFetchShiftTimesAction,
        });
    } catch (error) {
        yield handleSagaError({
            msg: getErrorMessage(error, 'callGetShiftTimesWatcher'),
        });
    }
}

function* callGetReportWatcher({ payload }: ISagaRequest<any>) {
    try {
        const apiResponse: AxiosResponse<any> = yield call(() => getReport(payload));
        const { status } = apiResponse;
        const { response, data } = apiResponse.data;
        const { reportName, dataProcessFunc } = payload;

        yield handleResponse({
            response,
            status,
            data,
            successAction: onFetchReportSuccessAction,
            successActionArgs: {
                reportName,
                dataProcessFunc,
            },
            statusUpdateActionArgs: { reportName },
            statusUpdateAction: onFetchReportStatusUpdateAction,
            reportName,
        });
    } catch (error) {
        yield handleSagaError({
            errorAction: onFetchReportStatusUpdateAction,
            msg: getErrorMessage(error, 'callGetReportWatcher'),
        });
    }
}
function* callGetDepartmentsStatisticsWatcher({ payload }: ISagaRequest<any>) {
    try {
        const apiResponse: AxiosResponse<DepartmentStatisticsResponse> = yield call(() =>
            getDepartmentsStatistics(payload)
        );
        const { status } = apiResponse;
    } catch (error) {
        console.log(error);
        // yield handleSagaError({
        //    errorAction: onFetchReportStatusUpdateAction,
        //    msg: getErrorMessage(error, 'callGetReportWatcher'),
        // });
    }
}
export default function* reportsSaga(): SagaIterator<any> {
    yield takeLatest(fetchShortOrdersAction, fetchShortOrdersReportWatcher);
    yield takeLatest(fetchShiftTimesAction, callGetShiftTimesWatcher);
    yield takeLatest(fetchReportAction, callGetReportWatcher);
    yield takeLatest(fetchDepartmentsStatisticsAction, callGetDepartmentsStatisticsWatcher);
}
// -- replaced handleResponse
