import { Controller, useFormContext } from 'react-hook-form';
import { useRootAppSelector } from 'src/store/hooks';
import { carTypesLstSelector, formSelector, linesDataSelector } from 'src/store/slices/lines/linesSlice';
import React from 'react';
import { fullFormHooks, useCarTypesWithPrice } from '../../hooks.FullForm';
import { Inputs } from '../../../../types';
import DropDownV2 from 'src/components/DropDown/DropDownV2';
import { FillerValues } from 'src/constants/misc';
import useCommons from 'src/hooks/common/useCommons';

interface Props {}

const CarTypeDropdown: React.FC<Props> = (props) => {
    const { t, dispatchI18nErrMsg } = useCommons();

    const fullFormMethods = useFormContext<Inputs>();
    const { control, getValues, setValue } = fullFormMethods;

    const { menuItems } = fullFormHooks.useCarTypes();

    useCarTypesWithPrice({
        lineDate: getValues('date'),
    });

    const carTypesLst = useRootAppSelector(carTypesLstSelector);

    const importedCourseCode = useRootAppSelector((state) => formSelector(state).importedCourseCode);

    const allCoursesData = useRootAppSelector((state) => linesDataSelector(state).getAccountCourses.data);

    // Resetting car field if value does not exist in carTypesLst
    const carIdOnSlice = useRootAppSelector((state) => formSelector(state).data.carId);

    React.useEffect(() => {
        const allCarIds: any[] = carTypesLst.map((carType) => carType.id);

        if (carIdOnSlice !== FillerValues.Without && !allCarIds.includes(carIdOnSlice)) {
            setValue('carId', FillerValues.Without);
        }
    }, [carIdOnSlice, carTypesLst, setValue]);

    const shrink = !!getValues('carId');

    return (
        <Controller
            name="carId"
            control={control}
            render={({ field }) => (
                <DropDownV2
                    {...{
                        labelName: t('wantedCarType'),
                        fullWidth: true,
                        formControlProp: {
                            style: { width: '100%' },
                            variant: 'outlined',
                            size: 'small',
                        },
                        selectProps: {
                            ...field,
                            menuItems,
                            notched: shrink,
                            id: 'car-type-dropdown-select',
                            onChange: (event) => {
                                try {
                                    // Handling new line price change based on car type
                                    const newCarId = event.target.value;

                                    const carData = carTypesLst.find((c) => c.id === String(newCarId));

                                    if (carData && importedCourseCode && newCarId !== FillerValues.Without) {
                                        fullFormMethods.setValue('linePrice', carData.price || null);
                                    }

                                    const importedCourseData = allCoursesData.find(
                                        (course) => course.courseCode === importedCourseCode
                                    );

                                    // Resetting line price to course price if user did not select a car to avoid
                                    // using the old selected car price
                                    if (
                                        importedCourseData?.clientPrice &&
                                        newCarId === FillerValues.Without
                                    ) {
                                        fullFormMethods.setValue(
                                            'linePrice',
                                            Number(importedCourseData.clientPrice)
                                        );
                                    }

                                    field.onChange(event);
                                } catch (err) {
                                    console.error(err);
                                    dispatchI18nErrMsg();
                                }
                            },
                        },
                        inputLabelProps: {
                            shrink,
                        },
                    }}
                />
            )}
        />
    );
};

export default CarTypeDropdown;
