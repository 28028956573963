/* eslint-disable react/button-has-type */
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import { cs } from 'src/style/styledComponents.style';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import styled, { css } from 'styled-components';
import { stationPassengersTableStyles as styles } from '../../styles';
import PassengerRow from './PassengerRow/PassengerRow';
import PassengerRowActions from './PassengerRowActions/PassengerRowActions';

type Props = {
    passengers: ExistingPassenger[];
    stationId: string;
    actionsAreDisabled: boolean;
};
const FIELDS = ['internalCode', 'firstName', 'lastName', 'mobilePhone', 'remarks'];

const TableHeadRow = () => {
    const { t } = useTranslation();
    return (
        <>
            {FIELDS.map((field) => (
                <styles.StyledTableCell>{t(getAsTemplateStrArr(field))}</styles.StyledTableCell>
            ))}{' '}
        </>
    );
};
const StationPassengersTable = ({ passengers, stationId, actionsAreDisabled }: Props): JSX.Element => {
    const [hover, setHover] = React.useState<null | string>(null);

    return (
        <cs.FullWidth>
            <styles.StyledTableHeader className="StyledTableHeader">
                <TableHeadRow />
            </styles.StyledTableHeader>
            <styles.StyledTableBody className="StyledTableBody">
                {passengers.map((passenger: ExistingPassenger) => {
                    return (
                        <PassengerRow
                            hover={hover}
                            setHover={setHover}
                            passenger={passenger}
                            stationId={stationId}
                            actionsAreDisabled={actionsAreDisabled}
                        />
                    );
                })}
            </styles.StyledTableBody>
        </cs.FullWidth>
    );
};

export default StationPassengersTable;
