import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { FieldErrors } from 'react-hook-form';

interface Props {
    errors: FieldErrors;
    name: string;
    type?: string;
    required?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value: string | number | null;
    disabled?: boolean;
}

export const getTranslatedErrorMessage = (t: Function, name: string, errors: { [x: string]: any }) => {
    const messageParts = errors[name]?.message.split('-');
    if (messageParts.length > 1) {
        return `${t(messageParts[0])} - ${messageParts[1]}`;
    }
    return t(messageParts[0]);
};

function GenericTextInputV2({
    errors,
    name,
    type,
    required,
    onChange,
    value,
    disabled = false,
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', textAlign: 'right' }}>
            <TextField
                error={errors[name] ? true : false}
                variant="outlined"
                size="small"
                label={t(getAsTemplateStrArr(name))}
                fullWidth
                required={required}
                type={type || 'text'}
                style={{ backgroundColor: 'white' }}
                onChange={onChange}
                value={value}
                disabled={disabled}
            />
            {errors[name] && <p>{getTranslatedErrorMessage(t, name, errors)}</p>}
        </div>
    );
}

export default GenericTextInputV2;
