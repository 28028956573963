import { FEATURES_IDS } from 'src/constants/auth';

const HOME_PATH = '/dailyRides';

export const routesEndpoints = {
    ROOT_AUTH: '/auth',
    HOME: HOME_PATH,
    ADD_LINE: `${HOME_PATH}/addLine`,
    EDIT_LINE: `${HOME_PATH}/editLine`,
    MANUAL_ORDER: { SCREEN_ID: 1, SCREEN_PATH: '/manualOrder' },
    MANAGE_PASSENGERS: {
        SCREEN_ID: 2,
        SCREEN_PATH: '/managePassengers',
    },
    PASSENGER_SETTINGS: {
        SCREEN_ID: 3,
        SCREEN_PATH: '/passengerSettings',
    },
    REPORTS: { SCREEN_ID: 99, SCREEN_PATH: '/reports' },
    SHIFTS: { SCREEN_ID: 5, SCREEN_PATH: '/shifts' },
    COURSES_BUILDING: { SCREEN_ID: 4, SCREEN_PATH: '/coursesBuilding' },
    SETTINGS: { SCREEN_ID: 6, SCREEN_PATH: '/settings' },
    OPTIMIZATIONS: { SCREEN_ID: 7, SCREEN_PATH: '/optimizations' },
    AUTH: {
        LOGIN: '/login',
        LOGIN_WITH_PIN_CODE: '/login/pin-code',
        LOGIN_WITH_USER: '/login/user',
        SINGUP: '/signup',
        SINGUP_OPTIONS: '/signup-options',
        SIGNUP_WITH_PIN_CODE: '/signup-options/create-pin-code',
        SIGNUP_WITH_USER: '/signup-options/create-user',
    },
} as const;
