import styled from 'styled-components';

export const StyledWidgetHeaderContainer = styled.div`
    padding: 0 10px 0 10px;
    width: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

export const StyledFormButtonsContainer = styled.div`
    display: flex;
    gap: 100px;
    justify-content: space-between;
`;
