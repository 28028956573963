import React, { FC } from 'react';

import { formHeaderFont, mediumLargeRubik } from 'src/style/styledCSS';

import Typography from '@material-ui/core/Typography';

const FormHeader: FC<{ mainText: string; secondaryText: string }> = ({ mainText, secondaryText }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography align="left" style={formHeaderFont}>
                {mainText}
            </Typography>
            {secondaryText && (
                <Typography align="left" style={mediumLargeRubik}>
                    {secondaryText}
                </Typography>
            )}
        </div>
    );
};

export default FormHeader;
