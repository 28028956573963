import moment from 'moment';
import { getDepartmentsStatistics, getShiftsStatistics } from 'src/api/manualOrderApi/manualOrderApi';
import { IPayloadStatistics } from 'src/types/manualOrders/api.types';
import { ALL_DEPARTMENTS_FILTER_VALUE } from 'src/constants/byScreen/manualOrders/components';
import { IDateRange } from 'src/types/global';
import { stringifyDate } from 'src/utilis/utilis';
import { FilterBy, IProgressData, IProgressDataPayload } from '../../types';

export interface ChartData {
    data: IProgressDataPayload[];
    fromDate: string;
    toDate: string;
}

const defaultFormatDate = 'YYYY-MM-DD';
const uiFormatDate = 'DD.MM.YYYY';

const normalizeDate = (fromDate: string, toDate: string): string => {
    if (toDate === fromDate) return moment(fromDate, defaultFormatDate).format(uiFormatDate);
    return `${moment(toDate, defaultFormatDate).format(uiFormatDate)}-${moment(
        fromDate,
        defaultFormatDate
    ).format(uiFormatDate)}`;
};
const buildPayload = (
    dbUrl: string,
    data: {
        selectedDate: Date;
        clientCode: number;
        selectedWeekArg: IDateRange;
        selectedDepartmentCode: string;
        allDepartmentsCodes: string[];
        display: number;
        token: string;
    }
): IPayloadStatistics => {
    const {
        selectedWeekArg,
        selectedDepartmentCode,
        allDepartmentsCodes,
        display,
        token,
        clientCode,
        selectedDate,
    } = data;

    // const fromDate = stringifyDate(selectedWeekArg.startDate);
    const toDate = stringifyDate(selectedWeekArg.endDate || selectedDate);

    const deptAuthParams = {
        departmentCodes:
            selectedDepartmentCode === ALL_DEPARTMENTS_FILTER_VALUE
                ? allDepartmentsCodes.join(', ')
                : selectedDepartmentCode,
        isAllDepartments: selectedDepartmentCode === ALL_DEPARTMENTS_FILTER_VALUE ? '1' : '0',
    } as const;

    const relativeSelectedDate = moment(selectedDate).format('YYYY-MM-DD');

    const payload = {
        dbUrl,
        display,
        clientCode,
        token,
        relativeDate: relativeSelectedDate,
        fromDate: display === 2 ? '' : relativeSelectedDate,
        toDate: display === 2 ? '' : toDate,
        ...deptAuthParams,
    } as const;

    return payload;
};

const normalizeStatistic = (statistics: {
    data: IProgressDataPayload[];
    fromDate: string;
    toDate: string;
}) => {
    const { fromDate, toDate } = statistics;
    const data = statistics.data.reduce((acc: IProgressData[], curr) => {
        if (!curr) return acc; // TODO

        const { code, name, totalOrders, isPickup } = curr;
        const statisticsIndex = acc.findIndex((s) => s.code === code);
        if (statisticsIndex === -1) {
            acc.push({
                code,
                name,
                pick: isPickup === '1' ? totalOrders : 0,
                drop: isPickup === '0' ? totalOrders : 0,
            });
        } else {
            acc[statisticsIndex] = {
                ...acc[statisticsIndex],
                pick: isPickup === '1' ? totalOrders : acc[statisticsIndex].pick,
                drop: isPickup === '0' ? totalOrders : acc[statisticsIndex].drop,
            };
        }

        return acc;
    }, []);

    return {
        data,
        max: Math.max(...data.flatMap((d) => d.pick + d.drop)),
        datesRange: normalizeDate(fromDate, toDate),
    };
};

export const useStatisticsApiReq = (args: { filterBy: FilterBy }) => {
    const { filterBy } = args;

    const getStatistics = async (payload: IPayloadStatistics): Promise<ChartData> => {
        return new Promise((resolve, reject): void => {
            try {
                if (filterBy === FilterBy.Departments) {
                    getDepartmentsStatistics(payload)
                        .then((r) => {
                            const {
                                departmentsStatistics,
                                fromDate: responseFromDate,
                                toDate: responseToDate,
                            } = r.data;
                            return resolve({
                                data:
                                    departmentsStatistics.map((d) => ({
                                        name: d.departmentName,
                                        code: d.departmentCode,
                                        ...d,
                                    })) || [],
                                fromDate: responseFromDate,
                                toDate: responseToDate,
                            });
                        })
                        .catch((e) => {
                            reject(e);
                        });
                } else {
                    getShiftsStatistics(payload)
                        .then((r) => {
                            const { shiftsStatistics } = r.data;
                            return resolve({
                                data:
                                    shiftsStatistics.map((s) => ({
                                        name: s.shiftName,
                                        code: s.shiftCode,
                                        ...s,
                                    })) || [],
                                fromDate: r.data.fromDate,
                                toDate: r.data.toDate,
                            });
                        })
                        .catch((e) => {
                            reject(e);
                        });
                }
            } catch (e) {
                console.log(e);
            }
        });
    };

    return { getStatistics };
};

const summaryCardHelpers = {
    normalizeDate,
    normalizeStatistic,
    buildPayload,
};

export default summaryCardHelpers;
