import React from 'react';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';

import useCommons from 'src/hooks/common/useCommons';
import {
    updateSettingBoxByIdAction,
    updateTimesSettingsTabValidAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { StyledToggleButton, StyledToggleButtonGroup } from 'src/style/styledMuiComponents';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';
import { DayNum } from 'src/types/global';
import styled, { CSSObject, css } from 'styled-components';
import { DAYS_HE_SHORT } from '../../../../../../constants/dates';
import { colors } from 'src/style/themes/defaultTheme';

const SmallText = styled.p<{ color?: string }>`
    font-size: 14px;
    text-align: right;
    color: #242424;
    ${({ color }) => color && `color: ${color};`}
`;

const DaysInput: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();

    const btnStyle = {
        height: '28px',
    };

    const errorMessage = !boxData.days.length ? t('isRequired') : '';

    React.useEffect(() => {
        dispatch(
            updateTimesSettingsTabValidAction({
                actionType: errorMessage ? 'add' : 'remove',
                errorType: 'invalidDays',
            })
        );
    }, [errorMessage, dispatch]);

    return (
        <InputWithErrorsWrapper errorMessage={errorMessage}>
            <SmallText color={errorMessage ? colors.muiRed : '#242424'}>{t('days')}</SmallText>
            <StyledToggleButtonGroup
                fullWidth
                value={boxData.days}
                onChange={(e, newVal: DayNum[]) => {
                    dispatch(updateSettingBoxByIdAction({ ...boxData, days: newVal }));
                }}
            >
                <StyledToggleButton sx={btnStyle} value={1}>
                    {DAYS_HE_SHORT[1]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={2}>
                    {DAYS_HE_SHORT[2]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={3}>
                    {DAYS_HE_SHORT[3]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={4}>
                    {DAYS_HE_SHORT[4]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={5}>
                    {DAYS_HE_SHORT[5]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={6}>
                    {DAYS_HE_SHORT[6]}
                </StyledToggleButton>
                <StyledToggleButton sx={btnStyle} value={7}>
                    {DAYS_HE_SHORT[7]}
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </InputWithErrorsWrapper>
    );
};

export default DaysInput;
