/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import useColumnsFromPrevSession from './useColumnsFromPrevSessions';
import { getFieldValueByLocalStorage } from './utils';

const getWidth = (field: string): number | undefined => {
    switch (field) {
        case 'description':
            return 200;
        default:
            break;
    }
};

enum ColIds {
    InternalCode = 'col-passengers-internalCode',
    FirstName = 'col-passengers-firstName',
    LastName = 'col-passengers-lastName',
    DepartmentName = 'col-passengers-departmentName',
    TotalRidesCount = 'col-passengers-totalRidesCount',
}

const usePassengerReportColumns = (
    isPrinting: boolean
): {
    columns: Array<GridColumnProps>;
} => {
    const { t } = useTranslation();
    const { localStorageCols } = useColumnsFromPrevSession();

    const columns = React.useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'internalCode',
                title: t('internalCode'),
                // width: 150,
                minResizableWidth: 50,
                id: ColIds.InternalCode,
                headerClassName: 'broken-header-col',
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.InternalCode, 'width', 150),
                // orderIndex: localStorageCols ? localStorageCols[ColIds.InternalCode].orderIndex : undefined,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.InternalCode,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'firstName',
                title: t('firstName'),
                id: ColIds.FirstName,
                // width: 150,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.FirstName, 'width', 150),
                minResizableWidth: 50,
                // orderIndex: localStorageCols ? localStorageCols[ColIds.FirstName].orderIndex : undefined,
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.FirstName,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'lastName',
                title: t('lastName'),
                id: ColIds.LastName,
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.LastName, 'width', 150),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.LastName,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'departmentName',
                title: t('department'),
                minResizableWidth: 50,
                id: ColIds.DepartmentName,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DepartmentName,
                    'width',
                    isPrinting ? 'auto' : getWidth('description')
                ),
                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.DepartmentName,
                    'orderIndex',
                    undefined
                ),
            },
            {
                field: 'totalRidesCount',
                title: t('monthlyRidesCount'),
                id: ColIds.TotalRidesCount,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.TotalRidesCount,
                    'width',
                    undefined
                ),
                minResizableWidth: 50,

                orderIndex: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.TotalRidesCount,
                    'orderIndex',
                    undefined
                ),
            },
        ],
        [isPrinting, localStorageCols, t]
    );

    return { columns };
};

export default usePassengerReportColumns;
