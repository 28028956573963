import { Card, Grid, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IModefiedPassenger } from 'src/api/passengerMangerApi/types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import { dirtyUp, INITIAL_ADDRESS_OBJECT } from '../../utils';
import { RawInputAddress } from '../types';
import usePrevious from '../../../../../../../../hooks/usePreviousState';
import { onChangeAddress } from '../../../../../../../../store/actions/passengerAction';

type Props = {
    modefiedPassenger: IModefiedPassenger;
    setModifiedPassenger: any;
    setAddress: any;
    addressInfo: RawInputAddress;
};

const ManualAddressInput: FC<Props> = ({
    modefiedPassenger,
    setModifiedPassenger,
    setAddress,
    addressInfo,
}) => {
    const { t } = useTranslation();
    // console.log('manual address input');
    const dispatch = useDispatch();
    const [cityInput, setCityInput] = useState('');
    const [streetInput, setStreetInput] = useState('');
    const [houseNumInput, setHouseNumInput] = useState('');
    const [, setTouched] = useState(false);

    useEffect(
        () => setAddress({ ...addressInfo, ...INITIAL_ADDRESS_OBJECT }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const hasValidAddress = useSelector(
        (state: IRootReducer) => passengersUISelector(state).form.hasValidAddress
    );
    const handleChange = (e: any, field: string) => {
        if (field === 'city') setCityInput(e.target.value);
        if (field === 'houseNum') if (+e.target.value >= 0) setHouseNumInput(e.target.value);
        if (field === 'street') setStreetInput(e.target.value);

        setTouched(true);
    };
    useEffect(() => {
        if (!cityInput && !houseNumInput && !streetInput) {
            dispatch(onChangeAddress(false));
            return;
        }
        if (!hasValidAddress) dispatch(onChangeAddress(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityInput, houseNumInput, streetInput]);
    return (
        <div
            style={{
                display: 'flex',
                gap: '15px',
            }}
            onMouseLeave={() =>
                setAddress({
                    ...addressInfo,
                    city: cityInput,
                    houseNum: houseNumInput,
                    street: streetInput,
                    remarks: addressInfo.remarks || '',
                })
            }
        >
            <TextField
                className="input-ltr"
                size="small"
                style={{ flex: 3 }}
                value={streetInput}
                onChange={(e) => handleChange(e, 'street')}
                variant="outlined"
                label={t('street')}
                fullWidth
            />
            <TextField
                className="input-ltr"
                size="small"
                style={{ flex: 1.5 }}
                value={houseNumInput}
                onChange={(e) => handleChange(e, 'houseNum')}
                variant="outlined"
                label={t('houseNum')}
                type="number"
                fullWidth
            />
            <TextField
                className="input-ltr"
                size="small"
                style={{ flex: 1.5 }}
                inputMode="text"
                value={cityInput}
                onChange={(e) => handleChange(e, 'city')}
                variant="outlined"
                label={t('city')}
                fullWidth
            />
        </div>
    );
};

export default ManualAddressInput;
