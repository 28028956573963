import { Moment } from 'moment';

export enum StorageKeys {
    DeviceToken = 'deviceToken',
    OnTimeLogin = 'onTimeLogin',
    ReduxState = 'reduxState',
    WidgetLayout = 'widgetLayout',
    GridColumns = 'gridColumns',
    CoursesBuildingWidgetsLayout = 'coursesBuildingWidgetsLayout',
    ClientGoogleApiCounter = 'ClientGoogleCounter',
    GoogleApiAccountCode = 'GoogleApiAccountCode',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction = (...args: any) => any;
export enum StorageType {
    LocalStorage,
    SessionStorag,
}

export enum DateTypeEnum {
    daily = 1,
    weekly = 2,
}

export interface IDateRange {
    startDate: Date;
    endDate: Date | undefined;
}

export enum PassengersErrorMessagesEnum {
    ClientCodeIsMissing,
    GenralError,
    InternalPassengerCodeExiest,
    InternalPassengerIsMissing,
    ClientCodeExsiest,
    TokenExpired,
}

export enum ActiveStatus {
    active = '1',
    nonActive = '0',
    all = '-1',
}
export enum Position {
    Vertical,
    Horizontal,
}

export enum Time {
    Daily = 0,
    weekly = 1,
    Monthly = 2,
}

export interface IObject {
    [key: string]: any;
}

export type Func = () => void;

export type ShallowBoolean = boolean | undefined | null;

export interface ErrorState {
    code?: string | null;
    msg: string | null;
}
export type StrOfLenTen = StringOfLength<10, 10>;

export type StringOfLength<Min, Max> = string & {
    readonly StringOfLength: unique symbol; // this is the phantom type
};

// This is a type guard function which can be used to assert that a string
// is of type StringOfLength<Min,Max>
const isStringOfLength = <Min extends number, Max extends number>(
    str: string,
    min: Min,
    max: Max
): str is StringOfLength<Min, Max> => str.length >= min && str.length <= max;

// type constructor function
export const stringOfLength = <Min extends number, Max extends number>(
    input: unknown,
    min: Min,
    max: Max
): StringOfLength<Min, Max> => {
    if (typeof input !== 'string') {
        throw new Error('invalid input');
    }

    if (!isStringOfLength(input, min, max)) {
        throw new Error('input is not between specified min and max');
    }

    return input; // the type of input here is now StringOfLength<Min,Max>
};

export type StringOrNull = string | null;
export type NumberOrNull = number | null;
export type BooleanOrNull = boolean | null;

export type Immutable = string | number | boolean | null | undefined;
export type DateObj = Date | Moment;

export type ReactChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export type SetStateAction<TValue> = React.Dispatch<React.SetStateAction<TValue>>;

export type DayNum = 7 | 1 | 2 | 3 | 4 | 5 | 6;

declare global {
    enum FuncSuccess {
        Success = 0,
        Fail = -1,
    }
    type SetStateAction<TValue> = React.Dispatch<React.SetStateAction<TValue>>;
}
