import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'src/components/Tabs/Tabs';
import Card from '../components/Card/Card';
import Container from '../components/Container/Container';
import Title from '../components/Title/Title';
import InitialIdentification from './InitialIdentification/InitialIdentification';
import styles from './Signup.style';

interface Props {}

const Signup = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Container>
            {/* <Title>{t('signup')}</Title> */}
            <Card>
                <styles.Header>
                    <styles.HeaderText>{t('loginWithPhone')}</styles.HeaderText>
                </styles.Header>
                <InitialIdentification />
            </Card>
        </Container>
    );
};

export default Signup;
