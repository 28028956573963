/* eslint-disable react/prop-types */
import React from 'react';
import { IPassenger } from 'src/api/passengerMangerApi/types';
import Typography from '@material-ui/core/Typography';
import themes from 'src/style/themes';

interface Props {
    selectedPassenger: IPassenger | any;
}

const PassengerName: React.FC<Props> = ({ selectedPassenger }) => {
    return (
        <Typography
            variant="h5"
            style={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                fontFamily: themes.fontFamilies.Rubik,
            }}
        >
            {selectedPassenger?.fullName ||
                `${selectedPassenger?.firstName && selectedPassenger?.firstName} ${
                    selectedPassenger?.lastName && selectedPassenger?.lastName
                }`}
        </Typography>
    );
};

export default PassengerName;
