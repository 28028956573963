import React, { useCallback, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeSelection, onUnSelectPassenger } from 'src/store/actions/passengerAction';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';

import {
    getSelectedState,
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridRowClickEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import {
    State,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
    process,
} from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import KendoGrid, { TableProp } from 'src/components/KendoGrid/KendoGrid';
import KendoDeleteEditeCell from 'src/components/KendoGridCutomeColumn/KendoDeleteEditeCell';

import { IPassenger } from 'src/api/passengerMangerApi/types';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useParentDimensions from 'src/hooks/useParentDimensions';
import { getTruthyValueCountOnArrayObjects } from 'src/utilis/utilis';
import { getMaxHeight } from 'src/screens/Reports/components/ReportsSection/Table/DataGrids/KendoDataGrid/KendoDataGrid';
import { IOptimizationWaypoint } from '../api/types';
import Alert from 'src/components/commons/Alert/Alert';
import { StyledKendoGridContainer } from 'src/screens/PassengerManger/components/Passengers/Passengers.style';
import { rowRender } from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/tableProps';
import useColumns from '../hooks/useColumns';
import {
    optimizationsRootSelector,
    selectAllWaypointAction,
    selectWaypointAction,
    setLastSelectedAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { useRootAppSelector } from 'src/store/hooks';

const dataItemKey = 'passCode';
const selectedField = 'isSelected';
const idGetter = getter(dataItemKey);
const PAGE_SIZE = 25;

interface IWaypointStockGrid {
    data: IOptimizationWaypoint[];
    compositeFilters: CompositeFilterDescriptor | undefined;
    onDelete: (dataItem: IOptimizationWaypoint) => unknown;
    onEdit: (dataItem: IOptimizationWaypoint) => unknown;
    onRestore: (dataItem: IOptimizationWaypoint) => unknown;
}

const WaypointStockGrid: FunctionComponent<IWaypointStockGrid> = ({
    data,
    compositeFilters,
    onDelete,
    onEdit,
    onRestore,
}: IWaypointStockGrid) => {
    const dispatch = useDispatch();

    // Local states
    const [take, setTake] = useState<number>(PAGE_SIZE);
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    const [hoverRowWaypointId, setHoverRowWaypointId] = useState<null | number>(null);

    // Selectors
    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));

    const waypoints = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );

    const onSelectionChange = (ev: GridSelectionChangeEvent) => {
        if (ev.dataItem) {
            const waypointToSelect = waypoints?.find(
                (waypoint) => waypoint.waypointId === ev.dataItem.waypointId
            );

            if (waypointToSelect) {
                dispatch(setLastSelectedAction({ waypoint: ev.dataItem }));
                dispatch(selectWaypointAction({ waypointId: waypointToSelect.waypointId }));
            }
        }
    };

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        const isSelected = event.nativeEvent.target.checked;
        dispatch(selectAllWaypointAction({ isSelected }));
    }, []);

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setTake(event.dataState?.take || PAGE_SIZE);
            setSkip(event.dataState?.skip || 0);
            setSort(event.dataState?.sort || []);
        },
        [setTake, setSkip, setSort]
    );

    // Edit passenger button clicked handler
    const onEditCell = () => {
        console.log('editCell');
    };

    const DeleteUpdateCell = useCallback(
        (props) => (
            <KendoDeleteEditeCell {...props} onEdit={onEditCell} onDelete={onDelete} onRestore={onRestore} />
        ),
        [onDelete, onEditCell, onRestore]
    );

    // Miscellaneous
    const dataState: State = {
        take,
        skip,
        sort,
        filter: compositeFilters,
    };

    const processedData = process(data, dataState);

    const [hoverRowItemCode, setHoverRowItemCode] = useState<any>(null);

    // Effects
    // dispatch the change selection action
    useEffect(() => {});

    useEffect(() => setSelectedState({}), [selectedFcAccount]);

    // & Handle the unselecting of passengers if double clicked
    const handleRowDoubleClick = (e: any) => {
        // setSelectedState({});
        // const updatedPassengers = updatePassengers(data, e.dataItem.passCode);
        // dispatch(onUnSelectPassenger(updatedPassengers));
        console.log('Row Double clicked');
    };

    const { columns } = useColumns({
        hoverRowWaypointId,
        // headerSelectionValue,
    });

    const onRowClick = (ev: GridRowClickEvent) => {
        if (ev.dataItem) {
            const waypointToSelect = waypoints?.find(
                (waypoint) => waypoint.waypointId === ev.dataItem.waypointId
            );

            if (waypointToSelect) {
                dispatch(setLastSelectedAction({ waypoint: ev.dataItem }));
                dispatch(selectWaypointAction({ waypointId: waypointToSelect.waypointId }));
            }
        }
    };

    // Building the passengers table
    const tableProp: TableProp = {
        columns,
        className: 'line-grid k-rtl',
        style: {
            height: '100%',
            width: '100%',
            borderRadius: '0.3em',
            maxHeight: '76vh',
            border: 'none',
            // pointerEvents: isFormOpen ? 'none' : 'auto',
        },
        wrapperDivStyle: {
            height: '100%',
            width: '100%',
            paddingBottom: '30px',
        },
        selectedField,
        selectable: {
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
        },
        onRowDoubleClick: handleRowDoubleClick,
        onSelectionChange,
        onHeaderSelectionChange,
        onRowClick,
        dataItemKey,
        rowHeight: 50,
        navigatable: false,
        data: processedData,
        total: processedData.total,
        pageSize: PAGE_SIZE,
        skip,
        sort,
        sortable: true,
        // rowRender: (trElement, props) => rowRender(trElement, props, setHoverRowItemCode),
        resizable: true,
        reorderable: true,
        scrollable: 'virtual',
        onDataStateChange,
        rowRender: (trElement, props) => {
            const { dataItem } = props;

            return React.cloneElement(trElement, {
                onMouseLeave: () => {
                    setHoverRowWaypointId(null);
                },
                onMouseEnter: () => {
                    setHoverRowWaypointId(dataItem.waypointId);
                },
            } as any);
        },
    };

    return (
        <StyledKendoGridContainer className="line-grid-container">
            <KendoGrid {...tableProp} />
        </StyledKendoGridContainer>
    );
};

export default WaypointStockGrid;
