/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-plusplus */
import React, { FC, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MuiTooltip from 'src/components/MuiTooltip/MuiTooltip';
import { ReasonForBlockedDate } from 'src/screens/ManualOrder/types';
import { IRootReducer } from 'src/store/reducers';
import {
    datesAreOnSameDay,
    getStrAsTemplateStringArr as asTSA,
    isSameDate,
    stringifyDate,
} from 'src/utilis/utilis';
import { useRootAppSelector } from 'src/store/hooks';
import { blockedDatesSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { RELATIVE } from 'src/constants/dates';
import * as Styles from '../MoreDays.Style';
import { DateAvailabilityStatus, SelectableConfig } from '../../../types';
import { useDayComponentProps, useShiftData, useUnknownUtil } from '../../../hooks/common';

// * Constants
export const TWO_WEEKS = 13;

const useSelectors = (date: moment.Moment) => {
    const datesAvailabilityStatus = useRootAppSelector(blockedDatesSelector);

    const reasonForBlock: ReasonForBlockedDate | null | undefined = React.useMemo(
        () =>
            datesAvailabilityStatus.find((data: DateAvailabilityStatus) => {
                return datesAreOnSameDay(data.date, date.toDate());
            })?.reason,
        [date, datesAvailabilityStatus]
    );

    return {
        reasonForBlock,
    };
};

interface DayProps {
    date: Moment;
    shortsDays: { [key: number]: string };
    disableData: SelectableConfig;
    onAddDay: (val: boolean, disabled: boolean) => void;
    isCurrDay: boolean;
    isFoucs: boolean;
    isOrderedAlready: boolean;
}

// * React Components --------------------------------------------
const Day: FC<DayProps> = ({
    date,
    shortsDays,
    disableData,
    isCurrDay,
    onAddDay,
    isOrderedAlready,
    isFoucs,
}) => {
    const { t } = useTranslation();

    const { reasonForBlock } = useSelectors(date);

    const { onClick, isDayDisabled, canBeEnabled } = useDayComponentProps(
        disableData,
        date,
        onAddDay,
        isFoucs
    );
    useEffect(() => {
        // console.log(stringifyDate(date), {
        //     onClick,
        //     isDayDisabled,
        //     canBeEnabled,
        //     date,
        //     shortsDays,
        //     disableData,
        //     isCurrDay,
        //     onAddDay,
        //     isOrderedAlready,
        //     isFoucs,
        // });
    }, [
        canBeEnabled,
        date,
        disableData,
        isCurrDay,
        isDayDisabled,
        isFoucs,
        isOrderedAlready,
        onAddDay,
        onClick,
        shortsDays,
    ]);
    // if (isSameDate(date, moment('2022-07-19', RELATIVE))) {
    //     console.log({
    //         onClick,
    //         isDayDisabled,
    //         canBeEnabled,
    //         date,
    //         shortsDays,
    //         disableData,
    //         isCurrDay,
    //         onAddDay,
    //         isOrderedAlready,
    //         isFoucs,
    //     });
    // }
    const { getShiftColor } = useShiftData();

    const dayNum = date.get('day');

    // -- dont know what this does
    useUnknownUtil(date, isDayDisabled);

    return (
        <>
            <MuiTooltip title={isDayDisabled ? t(asTSA(reasonForBlock || '')) : ''}>
                <Styles.DayContainer
                    disable={isDayDisabled}
                    canBeEnabled={canBeEnabled}
                    isFoucs={isFoucs}
                    onClick={onClick}
                    isCurrDay={isCurrDay}
                    isOrderedAlready={isOrderedAlready}
                    orderedShiftColor={getShiftColor() || ''}
                >
                    <>
                        <div> {shortsDays[dayNum]}</div>
                        <div>{date.format('DD.MM')}</div>
                    </>
                </Styles.DayContainer>
            </MuiTooltip>
        </>
    );
};
export default Day;
// const [isFoucs, setIsFoucs] = useState(isCurrDay);

// const onClick = (disabled: boolean) =>
//    onAddDay(!isFoucs, disabled);

// const canBeEnabled = useMemo(
//    () =>
//       !BLOCKED_FOR_RE_ENABLING_REASONS.includes(
//          disableData.reason,
//       ) && isShiftSelected,
//    [disableData.reason, isShiftSelected],
// );

// const {
//    PassengerHasTwoOrdersOnDate,
//    DatePassed,
//    Unknown,
//    DateOnBlockedList,
// } = ReasonForBlockedDate;
// const BLOCKED_FOR_RE_ENABLING_REASONS: any[] = [DatePassed, Unknown];
