/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function MyRefreshIcon({ width = '20', height = '20', color = 'rgba(0, 0, 0, 0.54)' }) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 28 28"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9167 14C25.9167 7.41861 20.5814 2.08333 14 2.08333V0.25C21.5939 0.25 27.75 6.40608 27.75 14C27.75 17.9844 26.0544 21.5732 23.3483 24.0833H27.75V25.9167H20.4167V18.5833H22.25V22.5992C24.511 20.4295 25.9167 17.3791 25.9167 14ZM4.65175 3.91667H0.25V2.08333H7.58333V9.41667H5.75V5.40084C3.48897 7.5705 2.08333 10.6209 2.08333 14C2.08333 20.5814 7.41861 25.9167 14 25.9167V27.75C6.40608 27.75 0.25 21.5939 0.25 14C0.25 10.0156 1.94556 6.42683 4.65175 3.91667Z"
                fill={color || '#2196F3'}
            />
        </svg>
    );
}
export default MyRefreshIcon;
