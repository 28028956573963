/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { Button } from '@mui/material';
import { colors } from 'src/style/themes/defaultTheme';

const WithManualInputBtn: FC<{
    usingManualInput: boolean;
    setUsingManualInput: Function;
}> = ({ usingManualInput, setUsingManualInput }) => {
    return (
        <div style={{ textAlign: 'right' }}>
            <Button
                sx={{ color: colors.blue }}
                disabled={usingManualInput}
                onClick={() => setUsingManualInput(true)}
            >
                לא מוצא את הכתובת שלך?
            </Button>
        </div>
    );
};

export default WithManualInputBtn;
