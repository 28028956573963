/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useTranslation } from 'react-i18next';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppSelector } from 'src/store/hooks';
import { dataSelector, DropLine } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import React, { useEffect, useState } from 'react';
import { IRootReducer } from 'src/store/reducers';
import { Slide } from '@mui/material';
import { TransitionMotion, TransitionPlainStyle } from 'react-motion';
import _ from 'lodash';
import { getAsTemplateStrArr, isEven } from 'src/utilis/utilis';
import { dropsDisplayHooks as hooks, useNextRides } from '../../hooks';

const StyledBodyCell = styled(TableCell)({
    fontSize: '20px',
    borderRight: '1px solid rgba(180, 190, 201, 0.303)',
    fontFamily: 'Rubik',
});
const StyledHeaderCell = styled(TableCell)({
    fontSize: '20px',
    fontFamily: 'Rubik',
});

const rowSx = {
    display: 'grid',
    gridTemplateColumns: '1fr 8fr 6fr 2fr 2fr',
};
const StyledTableRow = styled(TableRow)({
    ...rowSx,
});
const tableStyle = {
    padding: '0 30px 30px 30px',
};
const TABLE_HEADER_FIELDS = ['time', 'description', 'remarks', 'vehicleType', 'driverName'];
const Rows = (styles: TransitionPlainStyle[]) => {
    return styles.map(({ key, style, data }, i: any) => {
        return (
            <StyledTableRow
                className="StyledTableRow"
                key={key}
                style={{
                    ...style,
                    backgroundColor: isEven(data.colorIndex) ? '#FAFDFF' : '#FFF6D7',
                    overflow: 'hidden',
                    height: style.height === 60 ? 'auto' : style.height,
                    // transition: 'height 0.1s',
                }}
            >
                <StyledBodyCell sx={{ fontWeight: 'bold' }} align="right">
                    {data.time}
                </StyledBodyCell>

                <StyledBodyCell align="right">{data.description}</StyledBodyCell>
                <StyledBodyCell align="right">{data.remarks}</StyledBodyCell>
                <StyledBodyCell align="right">{data.vehicleType}</StyledBodyCell>
                <StyledBodyCell
                    sx={{
                        borderLeft: '1px solid rgba(180, 190, 201, 0.303)',
                    }}
                    align="right"
                >
                    {data.driverName}
                </StyledBodyCell>
            </StyledTableRow>
        );
    });
};
const EmptyTableMessage: React.FC<any> = () => {
    return <p style={{ fontSize: '24px', marginTop: '5px' }}>לא קיימות נסיעות בטווח הזמן הנוכחי</p>;
};

export default function RidesTable(): JSX.Element {
    const { data: rides, status } = useAppSelector(
        (state) => dataSelector(state as unknown as IRootReducer).getDrops
    );
    const { t } = useTranslation();
    const currRides = useNextRides(rides);

    const { getDefaultStyles, getStyles, willEnter, willLeave } = hooks.useAnimation(currRides || []);

    return (
        <div style={tableStyle}>
            {status !== 'FAILED' ? (
                <TableContainer component="div">
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow sx={rowSx} className="TableRow-head">
                                {TABLE_HEADER_FIELDS.map((field) => (
                                    <StyledHeaderCell align="right">
                                        {t(getAsTemplateStrArr(field))}
                                    </StyledHeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {rides.length ? (
                            <TransitionMotion
                                defaultStyles={getDefaultStyles()}
                                styles={getStyles()}
                                willLeave={willLeave}
                                willEnter={willEnter}
                            >
                                {(styles) => <TableBody>{Rows(styles)}</TableBody>}
                            </TransitionMotion>
                        ) : (
                            <EmptyTableMessage />
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <p>אופס... שגיאה במשיכת נתונים</p>
            )}
        </div>
    );
}

// {
//    /* <StyledHeaderCell align="right">
//                            {t('time')}
//                         </StyledHeaderCell>
//                         <StyledHeaderCell align="right">
//                            {t('description')}
//                         </StyledHeaderCell>
//                         <StyledHeaderCell align="right">
//                            {t('remarks')}
//                         </StyledHeaderCell>
//                         <StyledHeaderCell align="right">
//                            {t('vehicleType')}
//                         </StyledHeaderCell>
//                         <StyledHeaderCell align="right">
//                            {t('driverName')}
//                         </StyledHeaderCell> */
// }

// ':nth-child(odd)': {
//    backgroundColor: '#FAFDFF',
// },
// ':nth-child(even)': {
//    backgroundColor: '#FFF6D7',
// },
