import { AlertColor } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateToken } from 'src/api/api';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import { setTokenRefreshStatus } from 'src/store/actions/loginAction';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { errorMessageSelector, loginSelector } from 'src/store/selectores/loginSelectors';
import {
    DropLine,
    dropsDisplaySelectors,
    dropsDisplaySelectors as selectors,
    exitScreenAction,
    reqGetDropsAction,
} from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import { RefreshTokenStatus } from 'src/types/login';
import { TransitionMotion, spring, presets, TransitionPlainStyle } from 'react-motion';
import { THREE_MINUTES, ONE_HOUR } from 'src/constants/times';
import { asRootReducer } from 'src/store/utils';
import { NUMBER_OF_RIDES, TIME_UNTIL_NEXT_RIDES } from './constants';

export const useNextRides = (rides: DropLine[]): DropLine[] => {
    const [startIdx, setStartIdx] = useState(0);
    const [nextRides, setNextRides] = useState(rides.slice(startIdx, startIdx + NUMBER_OF_RIDES));

    setTimeout(() => {
        const newStartIdx = startIdx + NUMBER_OF_RIDES * 0.5 >= rides.length ? 0 : startIdx + 1;
        setStartIdx(newStartIdx);
    }, TIME_UNTIL_NEXT_RIDES);

    useEffect(() => {
        setNextRides(rides.slice(startIdx, startIdx + NUMBER_OF_RIDES));
        // return () => clearTimeout(timeout);
    }, [rides, startIdx]);
    return nextRides;
};

const useEscapeExit = (): (() => void) => {
    const dispatch = useAppDispatch();

    const handleExitClick = useCallback(() => {
        dispatch(exitScreenAction());
    }, [dispatch]);

    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                handleExitClick();
            }
        },
        [handleExitClick]
    );

    useEffect(() => {
        document.addEventListener('keydown', escFunction);

        return () => {
            document.removeEventListener('keydown', escFunction);
        };
    }, [escFunction]);
    return handleExitClick;
};

const useAlert = () => {
    const errorMessage = useSelector((state: IRootReducer) => errorMessageSelector(state));
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        if (errorMessage) setShowErrorAlert(true);
    }, [errorMessage]);

    const alertProps = {
        open: showErrorAlert,
        onClose: () => setShowErrorAlert(false),
        severity: 'error' as AlertColor,
    };

    useEffect(() => {
        if (showErrorAlert) {
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 1000 * 15);
        }
    }, [showErrorAlert]);

    return { alertProps, errorMessage };
};

/**
 * A custom hook that refreshes the data every 3 minutes.
 * @returns None
 */
const useRefreshData = (refreshTokenOnly?: boolean): { refreshToken: () => void } => {
    const dispatch = useDispatch();

    const clientCode = useSelector((state) => selectors.clientCodeSelector(asRootReducer(state)));

    const { refreshTokenStatus } = useSelector((state: IRootReducer) => loginSelector(state));

    const { minutesAfter, minutesBefore } = useRootAppSelector(
        dropsDisplaySelectors.displayTimeRangeSelector
    );

    const [isLoadingToken, setIsLoadingToken] = useState(false);

    const { dbUrl, token, proxyUrl, contactUUID } = useGetApiRequestBasicParams();

    const refreshToken = useCallback(() => {
        // console.log({ dbUrl, token, proxyUrl, contactUUID });
        if (
            !isLoadingToken &&
            contactUUID &&
            (refreshTokenStatus === RefreshTokenStatus.Invalid || !refreshTokenStatus)
        ) {
            const promises: Array<Promise<void>> = [];
            setIsLoadingToken(true);
            promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
            Promise.all(promises).then(() => {
                setIsLoadingToken(false);
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Ok));
            });
        }
    }, [contactUUID, dbUrl, dispatch, isLoadingToken, proxyUrl, refreshTokenStatus, token]);

    const refreshDrops = useCallback(() => {
        if (dbUrl && token && clientCode) {
            dispatch(
                reqGetDropsAction({
                    requestConfig: {
                        dbUrl,
                        requestParams: {
                            token,
                            clientCode,
                            minutesAfter,
                            minutesBefore,
                        },
                    },
                })
            );
        }
    }, [clientCode, dbUrl, dispatch, minutesAfter, minutesBefore, token]);

    useEffect(() => {
        if (refreshTokenOnly) return;

        const interval = setInterval(() => {
            if (!isLoadingToken) {
                refreshDrops();
            }
        }, THREE_MINUTES);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [isLoadingToken, refreshDrops, refreshToken, refreshTokenOnly]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoadingToken) {
                refreshToken();
            }
        }, ONE_HOUR);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [isLoadingToken, refreshDrops, refreshToken]);

    return { refreshToken };
};

/**
 * Custom hook for fetching drop lines data
 * @returns None
 */
const useInitialReqGetDrops = (): void => {
    const dispatch = useAppDispatch();
    const { dbUrl, token } = useGetApiRequestBasicParams();

    const clientCode = useSelector((state: any) => selectors.clientCodeSelector(state));

    const { minutesAfter, minutesBefore } = useRootAppSelector(
        dropsDisplaySelectors.displayTimeRangeSelector
    );

    useEffect(() => {
        if (dbUrl && token && clientCode) {
            dispatch(
                reqGetDropsAction({
                    requestConfig: {
                        dbUrl,
                        requestParams: {
                            token,
                            clientCode,
                            minutesAfter,
                            minutesBefore,
                        },
                    },
                })
            );
        }
    }, [clientCode, dbUrl, dispatch, minutesAfter, minutesBefore, token]);
};

const ROW_HEIGHT = 60;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAnimation = (rides: DropLine[]) => {
    const getDefaultStyles = useCallback(() => {
        return rides.map((ride) => ({
            data: ride,
            key: ride.id,
            style: { height: ROW_HEIGHT, opacity: 1 },
        }));
    }, [rides]);

    const getStyles = useCallback(() => {
        return rides.map((ride) => {
            return {
                data: ride,
                key: ride.id,
                style: {
                    height: spring(ROW_HEIGHT, presets.gentle),
                    opacity: spring(1, presets.gentle),
                    // height: 'auto',
                },
            };
        });
    }, [rides]);

    const willEnter = useCallback(() => {
        return {
            // minHeight: 0,
            height: 0,
            opacity: 1,
        };
    }, []);

    const willLeave = useCallback(() => {
        return {
            // minHeight: spring(0, presets.gentle),
            height: spring(0),
            opacity: spring(0),
        };
    }, []);

    return { getDefaultStyles, getStyles, willEnter, willLeave };
};

export const dropsDisplayHooks = {
    useInitialReqGetDrops,
    useAlert,
    useRefreshData,
    useEscapeExit,
    useAnimation,
};
