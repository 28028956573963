import styled from 'styled-components';
import { Card } from '../../Orderinfo.Style';

export const Container = styled(Card)`
    width: 100%;
`;

export const Header = styled.div`
    border-bottom: 2px Solid #eef1f5;
    height: 30%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
`;
export const Body = styled.div`
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 1rem;
`;
export const Row = styled.div`
    display: flex;
    width: 100%;
`;

export const Text = styled.p`
    text-align: right;
    font-weight: 400;
    margin-left: 10px;
    min-width: 40px;
`;
