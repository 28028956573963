import { useEffect } from 'react';

/**
 * A hook that calls a callback when the component is unmounted.
 * @param {Function} cb - the callback to call when the component is unmounted.
 * @returns None
 */
const useUnmountCB = (cb: Function) => {
    useEffect(() => {
        return () => {
            cb();
        };
    }, []);
};

export default useUnmountCB;
