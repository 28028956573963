import { IPassenger } from '../../api/passengerMangerApi/types';

export const myLog = (componentName: string, msg: any): void => {
    // console.log(`Component: ${componentName} \n`, msg);
};

export const initialPassengerData: IPassenger = {
    passCode: 0,
    contactName: '',
    fullName: '',
    address: [],
    contactPhone1: '',
    eMail: '',
    firstName: '',
    internetLevel: '',
    isActive: '1',
    lastName: '',
    mobilePhone: '',
    passId: '',
    passTypeCode: '',
    phone1: '',
    mainAdress: '',
};

export const getOnlySelectedPassenger = (
    passengersLst: IPassenger[],
    selectedPassengerPassCode: number | null
): IPassenger | undefined => {
    // console.log({ passengersLst, selectedPassengerPassCode });
    if (passengersLst.length === 0 || !selectedPassengerPassCode) return undefined;
    return passengersLst.find((passenger: IPassenger) => +passenger.passCode === +selectedPassengerPassCode);
};
