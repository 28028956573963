import React, { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import ReactTooltip from 'react-tooltip';
// import { images } from 'src/assets';
// import DatePicker, {
//    IDatePickerProps,
// } from 'src/components/DatePicker/DatePicker';
// import Dailycard from './components/Dailycard/Dailycard';
// import SummaryCard from './components/Summarycard/Summarycard';
import ReactTooltip from 'react-tooltip';
import { images } from 'src/assets';
import DatePicker, { IDatePickerProps } from 'src/components/DatePicker/DatePicker';
import CachedIcon from '@mui/icons-material/Cached';
import moment, { Moment } from 'moment';
import { dateify, datesAreOnSameDay, getWeekStartAndEndDates, stringifyDate } from 'src/utilis/utilis';
import { DateTypeEnum, IDateRange } from 'src/types/global';
import styled from 'styled-components';
import { fontFamilies, fontSizes, msColors } from 'src/style/themes/defaultTheme';
import DatePickerWithPrevNext from 'src/components/DatePicker/DatePickerWithPrevNext';
import MyRefreshIcon from 'src/components/Icons/MyRefreshIcon';
import MyInfoIcon from 'src/components/Icons/MyInfoIcon';
import ManualOrdersInfoIcon from 'src/components/Icons/ManualOrdersInfoIcon';

import useDisableDelay from 'src/hooks/useDelayV2';
import * as CommonStyles from 'src/components/Wrappers/Widgets/commonStyles';
import { useSelector } from 'react-redux';
import { tableDatesSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { SHORT } from 'src/constants/dates';
import Dailycard, { useStatisticsDataLoading } from './components/Dailycard/Dailycard';
import SummaryCard from './components/Summarycard/Summarycard';
import * as Styles from './Orderinfo.Style';
import useOrderInfo from './useOrderInfo';
import { manualOrdersCommonHooks } from '../../hook/manualOrdersHooks.common';
import { useRootAppSelector } from 'src/store/hooks';
import { statisticsSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { ApiCallRequest } from 'src/api/reportsApi/types';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import Spinner from 'src/components/Spinner/Spinner';
import ErrorFetchingDataText from 'src/components/ErrorFetchingDataText';

const StyledWidgetsFeatureHeader = styled.p`
    font-family: ${fontFamilies.Rubik};
    width: 100%;
    font-weight: normal;
    font-size: 33px;
    color: ${msColors.darkTextcolor};
    text-align: right;
`;
const iconStyle = {
    height: '30px',
    width: '30px',
    backgroundColor: '#FFEDB1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginLeft: '10px',
};
interface Props {
    clientCode: number;
    selectedWeek?: IDateRange;
    selectedDayOnTable: Date;
    manuallyType: DateTypeEnum;
    selectedDepartmentCode: string;
    allDepartmentsCodes: string[];
}
const Tooltip: FC<{ id: string; text: string }> = ({ id, text }) => {
    return (
        <ReactTooltip id={id} place="right" type="light" borderColor="gray" border>
            <p>{text}</p>
        </ReactTooltip>
    );
};

const DataDisplay: React.FC<{
    count: number;
    labelText: string;
    type: 'daily' | 'weekly';
}> = ({ count, labelText, type }) => {
    const loadingHook = useStatisticsDataLoading();

    if (loadingHook.isInStatus('SUCCESS', type)) {
        return (
            <>
                <Styles.Value>{count}</Styles.Value>
                <Styles.Lable>{labelText}</Styles.Lable>
            </>
        );
    }

    if (loadingHook.isInStatus('LOADING', type)) {
        return (
            <>
                <Spinner />
            </>
        );
    }

    if (loadingHook.isInStatus('ON_STAND_BY', type)) {
        return <></>;
    }

    return <ErrorFetchingDataText />;
};

const useFirstDayOfWeekConfig = (date: string) => {
    const { firstDayOfWeek } = manualOrdersCommonHooks.useFirstDayOfWeekSelectors();

    const [datesRange, setDatesRange] = useState(
        getWeekStartAndEndDates(firstDayOfWeek || -1, dateify(date))
    );

    useEffect(() => {
        const result = getWeekStartAndEndDates(firstDayOfWeek || -1, dateify(date));
        setDatesRange(result);
        // console.log(r.start, r.end, date);
    }, [date, firstDayOfWeek]);

    return { startDate: datesRange.start, endDate: datesRange.end };
};

const useSyncDatesToTable = (
    selectedDayOnTable: Date,
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>,
    manuallyType: DateTypeEnum
) => {
    const selectedWeekOnTable = useSelector(tableDatesSelector);

    useEffect(() => {
        if (manuallyType === DateTypeEnum.weekly && selectedWeekOnTable.startingDate) {
            setSelectedDate(dateify(selectedWeekOnTable.startingDate, SHORT));
            return;
        }
        if (manuallyType === DateTypeEnum.daily) {
            setSelectedDate(selectedDayOnTable);
        }
    }, [manuallyType, selectedDayOnTable, selectedWeekOnTable.startingDate, setSelectedDate]);
};

const Orderinfo: FC<Props> = ({
    clientCode,
    selectedDayOnTable,
    selectedDepartmentCode,
    manuallyType,
    allDepartmentsCodes,
}) => {
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(selectedDayOnTable);

    const selectedWeek = useFirstDayOfWeekConfig(stringifyDate(selectedDate));

    useSyncDatesToTable(selectedDayOnTable, setSelectedDate, manuallyType);

    const { countWeeklyOrder, countDailyOrder, pickup, drop, dayDate, weekDate } = useOrderInfo();

    const [tempDisabled, setTempDisabled] = useDisableDelay(2000);

    const datePickerProps: IDatePickerProps = {
        showTodayButton: true,
        inputVariant: 'outlined',
        style: { width: '100%', marginRight: 'auto' },
        size: 'small',
        format: 'dd.MM.yyyy',
        value: selectedDate,
        onChange: (date: Date | null) => {
            setSelectedDate(date || new Date());
        },
        KeyboardButtonProps: {
            'aria-label': 'change date',
        },
    };

    const reload: () => void = useCallback(() => {
        setTempDisabled(true);
        setSelectedDate((pre) => new Date(pre));
    }, [setTempDisabled]);

    return (
        <Styles.Root>
            <Styles.Header>
                <StyledWidgetsFeatureHeader>{/* {t('widgetsTitle')} */}</StyledWidgetsFeatureHeader>
                <CommonStyles.ReloadBtn disabled={tempDisabled} onClick={reload}>
                    <MyRefreshIcon width="16px" height="16px" color="black" />

                    <p style={{ paddingRight: '8px' }}>{t('refresh')}</p>
                </CommonStyles.ReloadBtn>
            </Styles.Header>
            <Styles.Container style={{ marginTop: '0px' }}>
                <Styles.OrderHeader>
                    <div style={{ display: 'flex', width: '100%', marginTop: '3px' }}>
                        <div style={iconStyle}>
                            <ManualOrdersInfoIcon />
                        </div>
                        <Styles.Title>{t('orderInfo')}</Styles.Title>
                    </div>
                    <div style={{ width: '80%' }}>
                        <DatePickerWithPrevNext {...datePickerProps} />
                    </div>
                </Styles.OrderHeader>

                <div
                    style={{
                        borderTop: '2px solid rgb(238, 241, 245)',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '15px',
                        marginTop: '14px',
                        gap: '20px',
                    }}
                >
                    <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
                        <Styles.Card
                            style={{ width: '100%' }}
                            justifyContent="center"
                            data-for="dalyOrder"
                            data-tip
                        >
                            <DataDisplay
                                count={countDailyOrder}
                                labelText={`${t('dalyOrder')}`}
                                type="daily"
                            />
                        </Styles.Card>

                        <Styles.Card
                            style={{ width: '100%' }}
                            justifyContent="center"
                            data-for="weeklyOrder"
                            data-tip
                        >
                            <DataDisplay
                                count={countWeeklyOrder}
                                labelText={`${t('weeklyOrder')}`}
                                type="weekly"
                            />
                            {/* <Styles.Value>{countWeeklyOrder}</Styles.Value>
                            <Styles.Lable>{`${t('weeklyOrder')}`}</Styles.Lable> */}
                        </Styles.Card>
                    </div>

                    <Dailycard pickup={pickup} drop={drop} />

                    <SummaryCard
                        date={selectedDate}
                        clientCode={clientCode}
                        selectedWeek={selectedWeek}
                        selectedDepartmentCode={selectedDepartmentCode}
                        allDepartmentsCodes={allDepartmentsCodes}
                    />

                    {/* Tooltips */}
                    <Tooltip id="dalyOrder" text={`${t('orderTo')} ${dayDate}`} />
                    <Tooltip id="weeklyOrder" text={`${t('orderTo')} ${weekDate}`} />
                </div>
            </Styles.Container>
        </Styles.Root>
    );
};

export default Orderinfo;
// const didMountRef = useRef(false);
// useEffect(() => {
//     if (didMountRef.current) {
//         if (manuallyType === DateTypeEnum.daily) {
//             setSelectedDate(selectedDay);
//         } else if (manuallyType === DateTypeEnum.weekly) {
//             if (datesAreOnSameDay(moment(new Date()).startOf('week').toDate(), selectedWeek.startDate))
//                 setSelectedDate(new Date());
//             else setSelectedDate(selectedWeek.startDate);
//         }
//     }

//     didMountRef.current = true;
// }, [selectedDay, manuallyType, selectedWeek.startDate]);
