import React, { useCallback, useRef } from 'react';
import useClickOutside from 'src/hooks/useClickOutside';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { FilterTypes } from 'src/store/reducers/reportsReducers/uiReducers';
import { filtersByReportSelector } from 'src/store/selectores/reportsSelectors';
import { onChangeFilterByReportAction } from 'src/store/slices/reports/reportsSlice';
import { ReportNames } from 'src/store/slices/reports/types';
import { PassengersFilterValuesKeys } from 'src/types/reports/generalReportsTypes';

export default function useDropdownOpenState() {
    const [openDropdown, setOpenDropdown] = React.useState<PassengersFilterValuesKeys | null>(null);

    const dropdownRef = useRef<any>(null);

    useClickOutside(dropdownRef, () => setOpenDropdown(null));

    const toggleDropdown = useCallback(
        (dropdownType: PassengersFilterValuesKeys, open: boolean) =>
            setOpenDropdown(open ? dropdownType : null),
        []
    );

    return { openDropdown, toggleDropdown, dropdownRef };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTimeInput = () => {
    const dispatch = useAppDispatch();

    const { fromTime, toTime } = useAppSelector((state: unknown) =>
        filtersByReportSelector(state as IRootReducer, 'passengers')
    );

    const handleChange = (newValue: string, type: FilterTypes.ToTime | FilterTypes.FromTime) => {
        dispatch(
            onChangeFilterByReportAction({
                filterType: type,
                newValue,
                reportName: 'passengers',
            })
        );
    };
    return { fromTime, toTime, handleChange };
};
const useAccordionExpansion = () => {
    const [accordionExpansionState, setAccordionExpansionState] = useLocalStorage('accordionExpansionState', {
        passengers: false,
        visas: false,
        shortOrders: false,
        detailedOrders: false,
    });

    const getExpandConfig = (reportName: ReportNames) => {
        return {
            isExpanded: accordionExpansionState[reportName],
            toggle: () => {
                setAccordionExpansionState((prev) => ({
                    ...prev,
                    [reportName]: !prev[reportName],
                }));
            },
        };
    };

    return { getExpandConfig };
};

export const reportsHooks = {
    useTimeInput,
    useAccordionExpansion,
};
