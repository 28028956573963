import _ from 'lodash';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'src/components/Spinner/Spinner';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { passengerSelector, shiftsSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { moSliceConstants } from 'src/store/slices/manualOrders/helpers';
import {
    apiCallStatusesSelector,
    passengersDatesSelector,
    selectedPassengersSelector,
    setPassengerDatesAction,
    setShiftCapsulesByPassengerAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { dateify } from 'src/utilis/utilis';
import { getCapsulesByCellData } from '../utilis';

const useNewPassDates = () => {
    const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));

    const [dates, setDates] = useState({});
    useEffect(() => {
        const newDates = allPassengers.map((p) => ({
            passId: p.passId,
            dates: p.dates,
        }));
        if (!_.isEqual(newDates, dates)) {
            setDates(newDates);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPassengers]);

    return dates;
};
const usePassengersDates = (): void => {
    const dispatch = useAppDispatch();

    const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));

    const dates = useNewPassDates();

    useEffect(() => {
        // console.log(1);
        const passDates = allPassengers.map((p) => ({
            passId: p.passId,
            dates: p.dates,
        }));

        dispatch(setPassengerDatesAction(passDates));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dates]);
};

const usePassengerShiftCapsulesData = () => {
    const dispatch = useAppDispatch();
    // -- Custom hooks

    // -- Selectors
    const passengersDates = useRootAppSelector(passengersDatesSelector);
    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));

    // -- Effects
    useEffect(() => {
        if (passengersDates?.length && shifts?.length) {
            // console.log('23423');
            const capsules = {};
            passengersDates.forEach((passenger) => {
                passenger.dates.forEach((date) => {
                    const resultCapsules = getCapsulesByCellData({
                        passenger,
                        shifts,
                        currDate: dateify(date.relativeDate),
                    });
                    if (resultCapsules.length) {
                        const currCapsules = capsules[passenger.passId] || [];
                        capsules[passenger.passId] = [...currCapsules, ...resultCapsules];
                    }
                });
            });

            dispatch(setShiftCapsulesByPassengerAction(capsules));
        }
    }, [dispatch, passengersDates, shifts]);
};

const spinnerContainerStyle: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 99,
};
export const disabledStyle = {
    pointerEvents: 'none',
    opacity: 0.7,
} as const;

const useDataLoadingSpinner = (passengersCount: number) => {
    const isFetchingPassengersForTable = useRootAppSelector(
        (state) => apiCallStatusesSelector(state, 'tableGetPassengerShifts') === 'LOADING'
    );

    const containerStyle: CSSProperties = isFetchingPassengersForTable ? disabledStyle : {};

    const DataIsLoadingSpinner = useMemo(() => {
        const SpinnerComponent: React.FC<{}> = () => {
            return <Spinner containerStyle={spinnerContainerStyle} />;
        };
        return SpinnerComponent;
    }, []);

    return {
        DataIsLoadingSpinner,
        containerStyle,
        showSpinner: !!passengersCount && isFetchingPassengersForTable,
    };
};

const manualOrdersHooks = {
    usePassengerShiftCapsulesData,
    usePassengersDates,
    useDataLoadingSpinner,
};
export default manualOrdersHooks;
