/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useSelector } from 'react-redux';
import { IFetchSettingsRequest } from 'src/types/rideSettings/api/types.api';
import clientDataReducers from 'src/store/reducers/rideSettingsReducers/clientDataReducers';

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';
import { IGeneralFormSubmitReqConfig, ISetSettingSaga } from 'src/types/rideSettings/store/types.sagas';
import { ReqStatus } from '../../../api/types';
import { Endpoints } from '../../../types/rideSettings/components/types.screen';
import formReducers from '../../reducers/rideSettingsReducers/formReducers';
import uiReducers from '../../reducers/rideSettingsReducers/uiReducers';
import {
    ClientCodePayload,
    GeneralSettingsData,
    HidePassPhoneTypes,
    RideSettingsState,
    SettingTypes,
    SlSettingTypes,
    SetSettingsAccordionExpansionPayload,
} from '../../../types/rideSettings/store/types.store';
import { initialState, initialAccordionExpansionState } from './initialStates';

import type { RootState } from '../../index';
import { IRootReducer } from '../../reducers/index';

// Define the initial state using that type
// * Slice
export const rideSettingsSlice = createSlice({
    name: 'rideSettings',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        // ^ UI reducers
        onActiveStatusChange: (state: RideSettingsState, action) =>
            uiReducers.onActiveStatusChange(state, action),
        editSettingClick: (
            state: RideSettingsState,
            action: PayloadAction<{
                settingType: SettingTypes | null;
                submitEndpoint: Endpoints | null;
            }>
        ) => {
            state.form.apiSettings.submitConfigs.endpoint = action.payload.submitEndpoint;
        },
        setSettingsAccordionExpansion: (
            state: RideSettingsState,
            action: PayloadAction<SetSettingsAccordionExpansionPayload>
        ) => {
            const { type, newState } = action.payload;

            if (newState === 'closeAll') {
                state.ui.settingsAccordionsExpansion = initialAccordionExpansionState;
                return;
            }

            if (!type) return;

            const isOpen = newState === 'open';
            state.ui.settingsAccordionsExpansion = { ...initialAccordionExpansionState, [type]: isOpen };
        },
        // -- Common reducers
        onError: (state: RideSettingsState, action: PayloadAction<ErrorActionPayload>) =>
            uiReducers.onError(state, action),

        // ^ Api requests reducers
        getGeneralSettingsFetch: (state, action) => {
            state.data.generalSettings.loadingSettingsStatus = ReqStatus.LOADING;
        },

        onFetchSettingsFail: (state, action: PayloadAction<SettingTypes>) => {
            const setting = action.payload;
            state.data[setting]!.loadingSettingsStatus = ReqStatus.FAIL;
        },

        onFetchSettingsSuccess: <T>(
            state: RideSettingsState,
            action: PayloadAction<{
                settingType: SettingTypes;
                settings: T;
            }>
        ) => {
            const { settingType, settings } = action.payload;

            state.data[settingType].settings = settings;
            state.data[settingType].loadingSettingsStatus = ReqStatus.SUCCESS;
        },
        onFetchSettings: (state, action: PayloadAction<IFetchSettingsRequest>) => {
            state.data[action.payload.settingType].loadingSettingsStatus = ReqStatus.LOADING;
        },
        // ^ Client Data reducers
        onSelectClientId: (state, action: PayloadAction<ClientCodePayload>) =>
            clientDataReducers.onSelectClientId(state, action),

        // ^ Form Reducers
        formChanged: (state: RideSettingsState, action) => formReducers.onFormChanged(state, action),

        onOpenForm: (
            state: RideSettingsState,
            action: PayloadAction<{
                isOnEditMode: boolean;
                formType: SlSettingTypes;
            }>
        ) => formReducers.onOpenForm(state, action),

        onCloseForm: (state: RideSettingsState) => formReducers.onCloseForm(state),

        onSubmitFormFailure: (state, action) => formReducers.onSubmitFormFailureReducer(state, action),

        onSubmitFormSuccess: (
            state,
            action: PayloadAction<{
                settingType: SlSettingTypes;
                settings: unknown;
            }>
        ) => formReducers.onSubmitFormSuccessReducer(state, action),

        onPermissionManagementFormSubmit: (state, action) => formReducers.onFormSubmitReducer(state, action),

        onGeneralSettingsFormSubmit: (state, action: PayloadAction<ISetSettingSaga>) =>
            formReducers.onGeneralSettingsFormSubmitReducer(state, action),

        hasSubmitConfigs: (state, action) => formReducers.hasSubmitConfigs(state, action),

        onClearSubmitConfigs: (state) => formReducers.clearSubmitConfigs(state),

        updateGenSetsFormValue: (state, action) => formReducers.updateGenSetsFormValue(state, action),
    },
});

// * Exports

// ^ Actions

export const rideSettingsActions = rideSettingsSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// ^ Selectors
const rootSelector = (state: IRootReducer) => state.rideSettings;

const uiSelector = (state: IRootReducer) => rootSelector(state).ui;
const dataSelector = (state: IRootReducer) => rootSelector(state).data;
const formSelector = (state: IRootReducer) => rootSelector(state).form;
const clientDataSelector = (state: IRootReducer) => rootSelector(state).clientData;
const currentSettingOnFormSelector = (state: IRootReducer) => rootSelector(state).form.ui.currentForm;
const errorSelector = (state: IRootReducer) => uiSelector(state).common.error;
const settingsSelector = (state: IRootReducer) => state.rideSettings;

export const rideSettingsSelectors = {
    selectUi: uiSelector,
    selectData: dataSelector,
    formSelector,
    errorSelector,
    clientDataSelector,
    currentSettingOnFormSelector,
    settingsSelector,
};

export default rideSettingsSlice.reducer;
