import { ApiReqStatus, GeneralFeaturesSliceState } from './types.generalFeatures';

const initialGeneralFeaturesSliceState: GeneralFeaturesSliceState = {
    uploadFileFt: {
        data: {
            fileData: null,
            actionsStatus: {
                upload: ApiReqStatus.Idle,
                get: ApiReqStatus.Idle,
                delete: ApiReqStatus.Idle,
            },
            selectedClientCode: null,
        },
        ui: {
            mainModal: {
                isOpen: false,
            },
            successModal: {
                isOpen: false,
            },
            fileInput: null,
        },
    },
};

export default initialGeneralFeaturesSliceState;
