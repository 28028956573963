import { CSSProperties } from 'react';

export const getRandColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
function randomRgba(opacity?: number | undefined) {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)}, ${opacity || 0.1})`;
}

export const getTestingBox = (): CSSProperties => {
    // return { border: `2px dotted ${getRandColor()}` };
    const c = randomRgba();
    return { backgroundColor: c };
};

export const getTestingFullBox = (opacity?: number): CSSProperties => {
    const c = randomRgba(opacity || undefined);
    return { backgroundColor: c };
};
