import React from 'react';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import { ShiftType } from 'src/screens/ManualOrder/types';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { onResetShiftSelection } from '../../../../../../store/actions/PassengersShiftActionType';

interface Props {
    shiftTypeForSelection: ShiftType;
    setSelectShift?: Function;
}

const ClearSelectionBtn = (props: Props) => {
    const { resetToInitialShiftData } = usePlacement();
    const dispatch = useDispatch();

    const resetShiftOnPlacementAndRedux = () => {
        resetToInitialShiftData(props.shiftTypeForSelection);

        dispatch(onResetShiftSelection(null));

        if (props.setSelectShift) props.setSelectShift(undefined);
    };

    return (
        <Button sx={{ marginTop: '5px' }} onClick={resetShiftOnPlacementAndRedux}>
            נקה בחירה
        </Button>
    );
};

export default ClearSelectionBtn;
