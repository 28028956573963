/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavigtionRoute } from 'src/routes/type';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Fotter from 'src/components/Footer/Footer';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Pofile from './Profile/Profile';
import styles from './NavBar.style';
import useEnvAuth from 'src/hooks/useEnvAuth';

interface Prop {
    routes: INavigtionRoute[];
}

const StyledNavBtn = styled.div<{ isActive: boolean }>`
    width: 120px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.isActive && '#cfe9fc'};
    border-radius: 8px;
    margin: 0.5em 0.5em 0 0.5em;
    cursor: pointer;
    @media (max-width: 1500px) {
        height: 95px;
        width: 90px;
    }
    @media (max-height: 780px) {
        height: 85px;
        width: 90px;
    }
`;

const NavBar: FC<Prop> = ({ routes }: Prop) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const onClickItem = (path: string) => {
        // dispatch(onSwitchPath({ path }));
        history.push(path);
        // console.log(window.location.href);
    };

    const envAuth = useEnvAuth();

    return (
        <styles.Conatiner overflow="hidden" withWarningColors={envAuth.isUsingProdAccountOnDev}>
            <Pofile />
            <styles.BtnsContainer>
                {routes
                    .filter((route) => !route.isHidden)
                    .map((route) => (
                        <Grid
                            className={route.isDisabled ? 'de-activ' : ''}
                            item
                            key={route.routeName}
                            onClick={() => !route.isDisabled && onClickItem(route.path)}
                        >
                            <div>
                                <StyledNavBtn isActive={location.pathname.includes(route.path)}>
                                    {route.icon(location.pathname.includes(route.path))}
                                    <styles.Text isActive={location.pathname.includes(route.path)}>
                                        {route.routeName}
                                    </styles.Text>
                                </StyledNavBtn>
                            </div>
                        </Grid>
                    ))}
            </styles.BtnsContainer>
            {/* </Grid> */}
            <Box display="flex" justifyContent="center">
                <Fotter
                    style={{
                        borderTop: '1px solid rgb(23 35 45 / 8%)',
                        marginTop: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                />
            </Box>
        </styles.Conatiner>
    );
};

export default NavBar;
