/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAddress } from 'src/api/passengerMangerApi/types';
// import GoogleAddressSearchBar from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/GoogleAddressSearchBar';
import { inputLabelStyle } from 'src/screens/PassengerManger/components/PassengerForm/styles/styles';
import { IRootReducer } from 'src/store/reducers';
import {
    modefiedPassengerSelector,
    modifyingOrAddingPassengerUiSelecter,
    passengerRootDataSelector,
} from 'src/store/selectores/passengersSelector';

import { Grid } from '@material-ui/core';

import { resetFormState } from 'src/store/actions/passengerAction';
import {
    coursesOnFormSelector,
    onAddAddressAction,
    onRemoveAddressAction,
} from 'src/store/slices/passengersManager/passengersManagerSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { IModefiedPassenger } from '../../../../../../api/passengerMangerApi/types';
import { myLog } from '../../../../utils';
import { AddressTypes } from '../../types';
// eslint-disable-next-line import/no-named-as-default
import AddressInput from './AddressForm/AddressInput';
import { AddAddressInputBtn, RemoveAddressInputBtn } from './AddressForm/Btns';
import RemarksInput from './AddressForm/RemarksInput';
import { stringifyAddress } from './AddressForm/utils';
import { RawInputAddress, SecondaryIdentifier } from './AddressForm/types';
import {
    getInitialInputsCount,
    getInitialAddressState,
    findAddress,
    dirtyUp,
    getInitialAddressData,
} from './utils';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// eslint-disable-next-line @typescript-eslint/naming-convention
const _ = require('lodash');

interface Props {
    setModifiedPassenger: any;
    citys?: string[];
    modefiedPassenger: IModefiedPassenger;
    addressesState: any;
}

// ^ Initial data

// ^ Actual Component
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AddressesBox: FC<Props> = ({
    setModifiedPassenger,
    modefiedPassenger,
    addressesState,
}): JSX.Element => {
    const { t } = useTranslation();
    // console.log('address box rendered');
    const dispatch = useDispatch();
    // * Selectors
    const addresses = useSelector((state: IRootReducer) => {
        const modPassenger = passengerRootDataSelector(state).modefiedPassenger;
        if (modPassenger) return modPassenger.address;
        return [];
    });
    const addingPassenger = useSelector(
        (state: IRootReducer) => passengerRootDataSelector(state).ui.addingPassenger
    );

    const chosenCourses = useAppSelector((state: any) => coursesOnFormSelector(state));

    // * States
    const { mainAddress, secondaryOne, secondaryTwo, setMainAddress, setSecondaryOne, setSecondaryTwo } =
        addressesState;

    const [numberOfSecondaryAddresses, setNumberOfSecondaryAddresses] = useState(
        getInitialInputsCount(addingPassenger, addresses, chosenCourses.length)
    );

    const toolkitDispatch = useAppDispatch();
    // * Event handlers
    const handleAddAddressClick = () => {
        const currCount = numberOfSecondaryAddresses;
        setNumberOfSecondaryAddresses((prev) => prev + 1);
        toolkitDispatch(onAddAddressAction({ addressIndex: `${currCount + 1}` }));
    };

    const handleRemoveAddressClick = () => {
        const currCount = numberOfSecondaryAddresses;
        const newCount = numberOfSecondaryAddresses - 1;
        let addressRemovedCode = '';
        const addressThatChanged = newCount === 1 ? AddressTypes.SecondaryTwo : AddressTypes.SecondaryOne;
        if (addressThatChanged === AddressTypes.SecondaryOne) {
            if (!_.isEqual(secondaryOne, getInitialAddressData('1')))
                dirtyUp(modefiedPassenger, setModifiedPassenger);
            setSecondaryOne({
                addressCode: secondaryOne.addressCode,
                ...getInitialAddressData('1'),
            });
            addressRemovedCode = secondaryOne.addressCode;
        } else {
            if (!_.isEqual(secondaryTwo, getInitialAddressData('2')))
                dirtyUp(modefiedPassenger, setModifiedPassenger);

            setSecondaryTwo({
                addressCode: secondaryTwo.addressCode,
                ...getInitialAddressData('2'),
            });
            addressRemovedCode = secondaryTwo.addressCode;
        }
        setNumberOfSecondaryAddresses(newCount);
        toolkitDispatch(
            onRemoveAddressAction({
                addressCode: addressRemovedCode,
                addressIndex: `${currCount}`,
            })
        );
    };
    // console.log(numberOfSecondaryAddresses);
    // * Effects
    // & getting new addresses data and count if changing passengers
    useEffect(() => {
        // console.log('1');
        // ^ Resetting addresses input
        setMainAddress(getInitialAddressState(findAddress('main', modefiedPassenger), '0'));
        setSecondaryOne(getInitialAddressState(findAddress('secondaryOne', modefiedPassenger), '1'));
        setSecondaryTwo(getInitialAddressState(findAddress('secondaryTwo', modefiedPassenger), '2'));
        // *  Resetting Form State
        dispatch(resetFormState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modefiedPassenger.passCode]);

    useEffect(() => {
        // console.log('1');

        // ^ Resetting addresses input initial count state
        setNumberOfSecondaryAddresses(
            getInitialInputsCount(addingPassenger, addresses, chosenCourses.length)
        );
    }, [modefiedPassenger.passCode, chosenCourses.length]);
    return (
        <div style={{ padding: 0, textAlign: 'right' }}>
            {/* Main address */}
            <p style={inputLabelStyle}>{t('mainAdress')}</p>
            <AddressInput
                addressInfo={mainAddress}
                addressSetter={setMainAddress}
                modefiedPassenger={modefiedPassenger}
                setModifiedPassenger={setModifiedPassenger}
                addressCode={mainAddress?.addressCode}
                indexForCoursePicking="0"
            />

            {/* secondary addresses */}
            {numberOfSecondaryAddresses >= 1 && (
                <>
                    <p style={inputLabelStyle}>{t('secondaryAddress')}</p>
                    <AddressInput
                        modefiedPassenger={modefiedPassenger}
                        addressInfo={secondaryOne}
                        addressSetter={setSecondaryOne}
                        setModifiedPassenger={setModifiedPassenger}
                        addressCode={secondaryOne?.addressCode}
                        indexForCoursePicking="1"
                    />
                </>
            )}

            {numberOfSecondaryAddresses === 2 && (
                <AddressInput
                    modefiedPassenger={modefiedPassenger}
                    addressInfo={secondaryTwo}
                    setModifiedPassenger={setModifiedPassenger}
                    addressSetter={setSecondaryTwo}
                    addressCode={secondaryTwo?.addressCode}
                    indexForCoursePicking="2"
                />
            )}

            {/* add address btn */}
            <AddAddressInputBtn
                style={{ marginTop: '-20px' }}
                handleAddAddressClick={handleAddAddressClick}
                disabled={numberOfSecondaryAddresses === 2}
            />
            {numberOfSecondaryAddresses > 0 && (
                <RemoveAddressInputBtn
                    style={{ marginTop: '-20px' }}
                    handleRemoveAddressClick={handleRemoveAddressClick}
                    disabled={numberOfSecondaryAddresses === 0}
                />
            )}
        </div>
    );
};

export default AddressesBox;
