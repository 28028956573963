/* eslint-disable no-prototype-builtins */
import { DirectionsRendererProps } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    injectStationsOnEditAction,
    setFormEndAddressAction,
    setFormStartAddressAction,
    setFormStationsAction,
    setIsDirectionsTriggeredFromEditAction,
} from 'src/store/slices/lines/linesSlice';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';
import { IOptimumRouteData } from '../api/types';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';
import { isAllObjectsContainsCoords } from 'src/screens/Main/hooks/useDirectionsApiLines';
import { getRoutesByIds } from '../helpers';

export const getColorByRoute = (
    route: IOptimumRouteData,
    routesSelectedIdsWithColor: { routeId: string; color: string }[]
) => {
    const relevantRoute = routesSelectedIdsWithColor.find((selected) => selected.routeId === route.localId);

    if (relevantRoute) {
        return relevantRoute.color;
    }
    return '#2c95f8';
    console.log('Route Not Found');
};

type ServiceResponseCallBack = (
    a: google.maps.DirectionsResult | null,
    b: google.maps.DirectionsStatus,
    c: string
) => void;

const today = new Date();
const departureTime = moment(today).add(1, 'day').toDate();

const useSimulationDirections = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        ui: {
            optimizationSimulationScreen: { isOpen, routesSelectedIdsWithColor },
        },
        data: {
            getSimulationResult: { data: simulationResult },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const [rendererProps, setRendererProps] = useState<DirectionsRendererProps[]>([]);

    const googleCounterUp = useGoogleApiCounterUp();

    const fetchDirections = (directionsService: google.maps.DirectionsService) => {
        return new Promise((resolve) => {
            try {
                const requests: { request: google.maps.DirectionsRequest; color: string }[] = [];

                if (simulationResult && simulationResult.optimumRouteResult) {
                    const routes = getRoutesByIds(
                        routesSelectedIdsWithColor,
                        simulationResult.optimumRouteResult
                    );
                    const rendererPropsCopy: DirectionsRendererProps[] = [];

                    routes.forEach((optimizationRoute) => {
                        const isCoordsExistOnAllWaypoints = isAllObjectsContainsCoords(
                            optimizationRoute.optimumRoute
                        );

                        if (isCoordsExistOnAllWaypoints && optimizationRoute.optimumRoute.length > 1) {
                            const routeLength = optimizationRoute.optimumRoute.length;

                            const origin = {
                                lat: optimizationRoute.optimumRoute[0].lat,
                                lng: optimizationRoute.optimumRoute[0].lng,
                            };

                            const destination = {
                                lat: optimizationRoute.optimumRoute[routeLength - 1].lat,
                                lng: optimizationRoute.optimumRoute[routeLength - 1].lng,
                            };

                            const stopWaypoints = optimizationRoute.optimumRoute.slice(1, -1);

                            const waypoints = stopWaypoints.map(
                                (waypoint): google.maps.DirectionsWaypoint => {
                                    // todo - name
                                    return {
                                        location: { lat: waypoint.lat, lng: waypoint.lng },
                                        stopover: true,
                                    };
                                }
                            );

                            const request: google.maps.DirectionsRequest = {
                                origin,
                                destination,
                                waypoints,
                                travelMode: google.maps.TravelMode.DRIVING,
                                avoidTolls: true,
                                drivingOptions: {
                                    departureTime,
                                    trafficModel: google.maps.TrafficModel.OPTIMISTIC,
                                },
                            };

                            const color = getColorByRoute(optimizationRoute, routesSelectedIdsWithColor);

                            requests.push({ request, color });
                        }
                    });

                    const serviceResponseCb: ServiceResponseCallBack = (response, status, color) => {
                        googleCounterUp.googleApiCounterUp();

                        if (response && response.routes && response.routes[0] && response.routes[0].legs) {
                            // todo

                            // tamir
                            rendererPropsCopy.push({
                                directions: response,
                                options: {
                                    suppressMarkers: true,
                                    preserveViewport: true,
                                    polylineOptions: {
                                        strokeColor: color ? color : '#28c2ff',
                                        strokeOpacity: 0.7,
                                        strokeWeight: 7,
                                    },
                                },
                            });
                        }
                    };

                    const promises: Promise<google.maps.DirectionsResult>[] = [];

                    requests.forEach((request) => {
                        const responsePromise = directionsService.route(request.request, (response, status) =>
                            serviceResponseCb(response, status, request.color)
                        );
                        promises.push(responsePromise);
                    });

                    Promise.all(promises).then(() => {
                        setRendererProps(rendererPropsCopy);
                    });
                } else {
                    setRendererProps([]);
                    //  resolve with -1 to indicate error
                    resolve(-1);
                }
            } catch (error) {
                console.error(error);
                setRendererProps([]);
                //  resolve with -1 to indicate error
                resolve(-1);
            }
        });
    };

    useEffect(() => {
        if (simulationResult && routesSelectedIdsWithColor) {
            const directionsService = new google.maps.DirectionsService();
            fetchDirections(directionsService);
        }
    }, [routesSelectedIdsWithColor]);

    return { rendererProps };
};

export default useSimulationDirections;
