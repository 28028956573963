/* eslint-disable no-debugger */
import moment, { Moment } from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { dateify, deepClone, stringifyDate } from 'src/utilis/utilis';
import { format, SHORT } from 'src/constants/dates';
import { useDispatch, useSelector } from 'react-redux';
import {
    buildPlacementShiftObj,
    getColorShift,
    getDateRanges,
    getDatesWithCountForPassenger,
    ManualPlacement as ManualPlacementObj,
} from 'src/screens/ManualOrder/utilis';
import { IRootReducer } from 'src/store/reducers';
import {
    editShiftSelector,
    formSelector,
    shiftsSelector,
    tableSelector,
} from 'src/store/selectores/passengerShiftsSelectores';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import { ReasonForBlockedDate, ShiftType } from 'src/screens/ManualOrder/types';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    screenClientCodeSelector,
    selectedAddressForPlacementSelector,
    selectedPassengersSelector,
    setApiCallStatusAction,
    setPassengersOrdersCountPerDateAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { setErrorMessage, setTokenRefreshStatus } from 'src/store/actions/loginAction';
import { useTranslation } from 'react-i18next';
import { getPassngerShifts as getPassengerShifts } from 'src/api/manualOrderApi/manualOrderApi';
import { FcResponseState } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import { PassengersOrdersCountPerDate } from 'src/store/slices/manualOrders/types';
import manualOrdersBL from 'src/api/dataMappers/manualOrdersBl';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import useOrderUtils from './useUtils/useUtils';
import { DateDataForOrdering, SelectableConfig, SelectableStatus } from '../types';
import { PlacemenContext } from '../context';
import { DAYS_FOR_ORDERS_RANGE } from './useOrderDaysData';
import { PASSENGER_ID_INDEX } from '../../../../../store/slices/manualOrders/helpers';
import { manualOrdersApiConsts } from 'src/api/manualOrderApi/constants';
import { useErrorMessageDispatcher } from 'src/hooks/useErrorHandler';
import manualOrdersRoutes from 'src/mockServer/routes/manualOrdersRoutes';
import { removePassengersShift } from 'src/store/actions/PassengersShiftActionType';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { buildAddresseesParams } from '../utilis';
import { IEditShift } from 'src/types/manualOrders/api.types';

export const useShiftExistsForAll = () => {
    const [placement] = useContext(PlacemenContext);
    const { autoOrder, manualOrder } = placement;
    const { shiftAlreadyOrderedForDate } = useOrderUtils();
    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    const onAutoOrder = !!autoOrder.pickupTime;

    const isAllPsngrsHaveChosenShiftOrderedAlreadyForDate = useCallback(
        (mapLoopDate: DateDataForOrdering): boolean => {
            if (!selectedPassengers.length) return false;
            return selectedPassengers.every((psngr) =>
                shiftAlreadyOrderedForDate(
                    stringifyDate(mapLoopDate.date),
                    {
                        pickupTime: onAutoOrder ? autoOrder.pickupTime : manualOrder.pickup.pickupTime,
                        dropTime: onAutoOrder ? autoOrder.dropTime : manualOrder.drop.dropTime,
                    },
                    psngr[PASSENGER_ID_INDEX]
                )
            );
        },
        [
            autoOrder.dropTime,
            autoOrder.pickupTime,
            manualOrder.drop.dropTime,
            manualOrder.pickup.pickupTime,
            onAutoOrder,
            selectedPassengers,
            shiftAlreadyOrderedForDate,
        ]
    );

    return isAllPsngrsHaveChosenShiftOrderedAlreadyForDate;
};

export const useOnAddDayUtils = () => {
    const [placement, setPlacement] = useContext(PlacemenContext);
    const [selectedDaysCounter, setSelectedDaysCounter] = useState(0);

    const onAddDay = useCallback(
        (val: boolean, day: Moment, disabled: boolean) => {
            if (disabled) return;

            let newDates: {
                relativeDate: string;
            }[] = [];

            if (val) {
                newDates = [...placement.dates, { relativeDate: day.format(format) }];
                setSelectedDaysCounter((prev) => prev + 1);
            } else {
                newDates = placement.dates.filter((d) => d.relativeDate !== day.format(format));
                setSelectedDaysCounter((prev) => prev - 1);
            }
            setPlacement((pre) => ({ ...pre, dates: newDates }));
        },
        [placement.dates, setPlacement]
    );

    return { onAddDay, selectedDaysCounter, setSelectedDaysCounter };
};

export const useShiftData = () => {
    const [placement, setPlacement] = useContext(PlacemenContext);

    const shifts = useSelector((state: IRootReducer) => shiftsSelector(state));

    const getShiftIndex = useCallback(
        (shiftId: number) => {
            const found = shifts.find((shift) => shift.shiftId === shiftId);
            return found ? found.shiftIndex : null;
        },
        [shifts]
    );

    const getShiftColor = useCallback(() => {
        try {
            const { autoOrder, manualOrder } = placement;
            if (autoOrder.shiftId) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const idx = getShiftIndex(+autoOrder!.shiftId!);
                return idx ? getColorShift(+idx) : null;
            }
            if (manualOrder.drop.dropShiftId || manualOrder.pickup.pickupShiftId) {
                const { dropShiftId } = manualOrder!.drop!;
                const { pickupShiftId } = manualOrder!.pickup!;
                return getColorShift(
                    dropShiftId ? getShiftIndex(+dropShiftId) : getShiftIndex(+pickupShiftId)
                );
            }

            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }, [getShiftIndex, placement]);

    return { getShiftColor };
};

export const useUnknownUtil = (date: Moment, isDayDisabled: boolean) => {
    const { removeDateFromPlacement } = usePlacement();
    const selectedCell = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.selectedSingleShift
    );

    // ! Effect removed placement date if said date is both not the date of the
    // ! ...selected CELL on the table AND said date is DISABLED
    // ! Bug - Caused the removal of the date from placement when editing order and switching order type...
    // ! (manual / auto)
    // useEffect(() => {
    //     // console.log('3');
    //     // const daysAreDiff = !moment(addShiftBoxData?.date).isSame(date);
    //     // if (isDayDisabled && daysAreDiff) {
    //     //     // should reach here???
    //     //     removeDateFromPlacement(date);
    //     //     console.log('3.1');
    //     // }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [
    //     isDayDisabled,
    //     date,
    //     removeDateFromPlacement,
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     // addShiftBoxData?.date,
    // ]);
};

const { PassengerHasTwoOrdersOnDate, DatePassed, Unknown, DateOnBlockedList } = ReasonForBlockedDate;

const BLOCKED_FOR_RE_ENABLING_REASONS: ReasonForBlockedDate[] = [DatePassed, Unknown, DateOnBlockedList];

export const useDayComponentProps = (
    daySelectabilityData: SelectableConfig,
    date: moment.Moment,
    onAddDay: {
        (val: boolean, disabled: boolean): void;
        (arg0: boolean, arg1: boolean): any;
    },
    isDayFocused: boolean
) => {
    const isShiftSelected = useSelector((state: IRootReducer) => formSelector(state).isShiftSelected);
    const editedShift = useSelector((state: IRootReducer) => editShiftSelector(state));
    const { date: editedShiftDate } = editedShift || {};

    const canBeEnabled: boolean = useMemo(() => {
        if (
            daySelectabilityData.reason && // -- is soft blocked
            !BLOCKED_FOR_RE_ENABLING_REASONS.includes(daySelectabilityData.reason) && // -- is hard block
            isShiftSelected // -- chose shift
        ) {
            return true;
        }
        return false;
    }, [daySelectabilityData.reason, isShiftSelected]);

    const isReplacingOrderOnDayWithMaxOrders = useCallback(() => {
        return (
            daySelectabilityData.reason === PassengerHasTwoOrdersOnDate &&
            moment(editedShift?.date).isSame(date) &&
            isShiftSelected
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        date,
        daySelectabilityData.reason,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editedShift?.date,
        isShiftSelected,
    ]);

    const isDayDisabled = useMemo(() => {
        if (!isShiftSelected) return true;
        if (!daySelectabilityData.reason) return false;

        if (BLOCKED_FOR_RE_ENABLING_REASONS.includes(daySelectabilityData.reason)) return true;

        if (isReplacingOrderOnDayWithMaxOrders()) {
            return false;
        }

        return true;
    }, [daySelectabilityData.reason, isReplacingOrderOnDayWithMaxOrders, isShiftSelected]);

    // console.log({ isDayDisabled, daySelectabilityData }, date.toISOString());
    const onClick = () => onAddDay(!isDayFocused, isDayDisabled);

    return { onClick, isDayDisabled, canBeEnabled };
};

/*
      get selected client
      get date filter -- redux table date range state
       fetch shifts for 27 days
       fetch passengers for 27 days
       process data
   */
const useDataReFetchingForPlacement = () => {
    const dispatch = useAppDispatch();
    const tkDispatch = useAppDispatch();
    const { t } = useTranslation();

    const isFormOpen = useSelector(formSelector).isOpen;
    const screenClientCode = useRootAppSelector(screenClientCodeSelector);
    const { startingDate, endingDate } = useSelector(tableSelector).dateRange;
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    useEffect(() => {
        if (!isFormOpen || !startingDate || !endingDate || !screenClientCode) return;

        const { proxyUrl, dbUrl } = selectedFcAccount || {};

        if (!dbUrl) return;

        const { fromDate, toDate } = getDateRanges(
            dateify(startingDate, SHORT),
            dateify(endingDate, SHORT),
            DAYS_FOR_ORDERS_RANGE.PLACEMENT_WIDGET
        );

        if (!fromDate || !toDate) return;

        const apiReqPayload = {
            proxyUrl,
            dbUrl,
            token,
            fromDate,
            toDate,
            clientCode: Number(screenClientCode),
        };

        const reqGetPassengersShifts = async () => {
            tkDispatch(
                setApiCallStatusAction({
                    type: 'placementGetPassengerShifts',
                    newStatus: 'LOADING',
                })
            );

            const {
                data: { passengers = [], response },
            } = await getPassengerShifts({
                ...apiReqPayload,
                passengers: selectedPassengers.map((p) => p[PASSENGER_ID_INDEX]).join(','),
            });

            if (response === FcResponseState.TokenExpired)
                dispatch(setTokenRefreshStatus(RefreshTokenStatus.Invalid));
            else {
                const processedPassengers = manualOrdersBL.processPassengerForDates(passengers, {
                    fromDate,
                    toDate,
                });

                const passengerOrderDates: PassengersOrdersCountPerDate = {};
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < processedPassengers.length; i++) {
                    const currPassenger = processedPassengers[i];
                    passengerOrderDates[currPassenger.passId] = getDatesWithCountForPassenger(currPassenger);
                }

                tkDispatch(setPassengersOrdersCountPerDateAction(passengerOrderDates));

                tkDispatch(
                    setApiCallStatusAction({
                        type: 'placementGetPassengerShifts',
                        newStatus: 'SUCCESS',
                    })
                );
            }
        };
        try {
            reqGetPassengersShifts();
        } catch (error) {
            console.log(error);
            dispatch(setErrorMessage(t('oopsSomethingWentWrongTryAgain')));
        }
    }, [
        dispatch,
        endingDate,
        isFormOpen,
        screenClientCode,
        selectedFcAccount,
        selectedPassengers,
        startingDate,
        t,
        tkDispatch,
        token,
    ]);
};

// -- useWeeks ----------------------------------------------------------------
type WeekNums = 'week1' | 'week2' | 'week3' | 'week4';
type Weeks = {
    [key in WeekNums]: DateDataForOrdering[];
};
const INCLUDED_MARK = '[]';
const WEEK_LENGTH = 7;

const useWeeks = (datesForOrders: DateDataForOrdering[]) => {
    const tableDates = useSelector((state: IRootReducer) => tableSelector(state)).dateRange;

    // ----------------------------------------------------------------
    const [weeks, setWeeks] = useState<null | Weeks>(null);

    const splitAllDatesToWeeks = useCallback((): Weeks => {
        const allDays = { week1: [], week2: [], week3: [], week4: [] };

        let currWeek = 1;

        datesForOrders.forEach((dateForOrder) => {
            const week = `week${currWeek}`;

            allDays[week].push(dateForOrder);

            if (allDays[week].length === WEEK_LENGTH) {
                // -- move to next week
                currWeek += 1;
            }
        });

        return allDays;
    }, [datesForOrders]);

    useEffect(() => {
        setWeeks(splitAllDatesToWeeks());
    }, [datesForOrders, splitAllDatesToWeeks]);

    // ----------------------------------------------------------------
    const isWeekOnTable = useCallback(
        (firstItemDate: Moment): boolean => {
            const { startingDate, endingDate } = tableDates;
            // if in between starting and ending date return true, else false
            const isBetween = moment(firstItemDate).isBetween(
                moment(startingDate, SHORT),
                moment(endingDate, SHORT),
                undefined,
                INCLUDED_MARK
            );

            return isBetween;
        },
        [tableDates]
    );

    return { weeks, isWeekOnTable };
};

export const moreDaysHooks = {
    useWeeks,
};

const useEditOrderPlacementApi = () => {
    const { t } = useTranslation();
    const [dispatchErrorMsg] = useErrorMessageDispatcher();
    const dispatch = useDispatch();
    const { placment: placement } = usePlacement();

    // & Selectors
    const selectedAddresses = useRootAppSelector(selectedAddressForPlacementSelector);
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const clientCode = useRootAppSelector(screenClientCodeSelector);
    const editingShift = useSelector((state: IRootReducer) => state.passengersShiftReudcer.editShift);
    const selectedPassengers = useRootAppSelector(selectedPassengersSelector);

    const editOrder = async ({
        setTempDisabled,
        date,
        selectedClient,
        setErrorState,
        editShift,
        handleResponse: handleSetOrderResponse,
    }: any) => {
        const { codes = [], passId = '' } = editingShift || {};
        const { pickupTime, dropTime, shiftId } = placement.autoOrder;

        if (selectedFcAccount && clientCode) {
            setTempDisabled(true);

            // -- Payloads ----------------------------------------------
            const delOrderPayload = {
                dbUrl: selectedFcAccount.dbUrl,
                proxyUrl: selectedFcAccount?.proxyUrl,
                orders: codes.join(','),
                relative_date: moment(date).format('YYYY-MM-DD'),
                pass_id: passId,
                token,
                pickupTime: editingShift?.pickupTime || '',
                dropTime: editingShift?.dropTime || '',
                clientCode,
            };

            const setOrderPayload = {
                dbUrl: selectedFcAccount.dbUrl,
                proxyUrl: selectedFcAccount?.proxyUrl,
                jsonData: {
                    token,
                    passengers: selectedPassengers.map((p) => p[PASSENGER_ID_INDEX]).join(','),
                    pickupTime,
                    shiftId,
                    dropTime,
                    dates: placement.dates,
                },
                ...buildAddresseesParams(selectedAddresses),
                clientCode: selectedClient.value,
            };
            // ----------------------------------------------

            try {
                // -- Request to route --------------------------------
                const editOrderRes = await manualOrdersRoutes.editOrder({
                    requestPayloads: {
                        deleteOrder: delOrderPayload,
                        setOrder: setOrderPayload,
                    },
                });
                // --------------------------------

                // -- Response handling --------------------------------
                if (editOrderRes.data.response) handleSetOrderResponse(editOrderRes.data);

                if (editOrderRes.code === CommonCodes.Ok) {
                    setErrorState({
                        message: t('successOrder'),
                        isShow: true,
                        severity: 'success',
                    });
                    if (!editShift)
                        dispatch(
                            removePassengersShift({
                                passId,
                                orderCodes: codes,
                            })
                        );

                    return 0;
                }

                const errorMessage =
                    (editOrderRes.data.response &&
                        manualOrdersApiConsts.EDIT_DEL_ORDERS_REQ_ERROR_STINGS[editOrderRes.data.response]) ||
                    t('oopsSomethingWentWrongTryAgain');

                dispatchErrorMsg(errorMessage);

                // -------------------------------------------------
            } catch (error) {
                console.log(error);
                dispatchErrorMsg(`ERROR - ${t('oopsSomethingWentWrongTryAgain')}`);
            }
            return -1;
        }
    };

    return { editOrder };
};

export interface Order {
    drop: {
        time: string;
        id: string;
    };
    pickup: {
        time: string;
        id: string;
    };
}
export interface DeleteOrderData {
    pickupTime: string;
    dropTime: string;
    dropCode: string;
    pickupCode: string;
}
const useChangedOrderData = ({ isAutoOrder, placement }: any) => {
    const originalEditedShift = useSelector((state: IRootReducer) => state.passengersShiftReudcer.editShift);

    const getCurrOrder = useCallback((): Order => {
        const currPlacementData = {
            drop: { time: '', id: '' },
            pickup: { time: '', id: '' },
        };

        if (isAutoOrder) {
            const { dropTime, pickupTime, shiftId } = placement.autoOrder;

            currPlacementData.drop.time = dropTime;
            currPlacementData.drop.id = shiftId;
            currPlacementData.pickup.time = pickupTime;
            currPlacementData.pickup.id = shiftId;
        } else {
            const {
                pickup: { pickupTime, pickupShiftId },
                drop: { dropTime, dropShiftId },
            } = placement.manualOrder;

            currPlacementData.drop.time = dropTime;
            currPlacementData.drop.id = dropShiftId;
            currPlacementData.pickup.time = pickupTime;
            currPlacementData.pickup.id = pickupShiftId;
        }

        return currPlacementData;
    }, [isAutoOrder, placement.autoOrder, placement.manualOrder]);

    const deletedOrder = useMemo(() => {
        const currOrders: Order = getCurrOrder();

        const memoedDeletedOrder: DeleteOrderData = {
            dropCode: '',
            pickupCode: '',
            pickupTime: '',
            dropTime: '',
        };

        if (!originalEditedShift) return memoedDeletedOrder;

        const originalShiftId = String(originalEditedShift.shiftId);
        const [pickupCode, dropCode] = originalEditedShift.codes;

        if (isAutoOrder) {
            // Deleting full edited shift
            memoedDeletedOrder.pickupCode = pickupCode;
            memoedDeletedOrder.pickupTime = originalEditedShift.pickupTime || '';
            memoedDeletedOrder.dropCode = dropCode;
            memoedDeletedOrder.dropTime = originalEditedShift.dropTime;
        } else {
            // Handling drop
            if (
                // Id changed
                currOrders.drop.id !== originalShiftId ||
                // both ids did not change
                (originalShiftId === currOrders.drop.id && originalShiftId === currOrders.pickup.id)
            ) {
                memoedDeletedOrder.dropTime = originalEditedShift.dropTime;
                memoedDeletedOrder.dropCode = dropCode;
            }

            // Handling pickup
            if (
                // Id changed
                currOrders.pickup.id !== originalShiftId ||
                // both ids did not change
                (originalShiftId === currOrders.drop.id && originalShiftId === currOrders.pickup.id)
            ) {
                if (
                    // Have pickup time on original order
                    originalEditedShift.pickupTime
                ) {
                    memoedDeletedOrder.pickupTime = originalEditedShift.pickupTime;
                    memoedDeletedOrder.pickupCode = pickupCode;
                }
            }
        }
        return memoedDeletedOrder;
    }, [
        getCurrOrder,
        isAutoOrder,
        originalEditedShift?.codes,
        originalEditedShift?.dropTime,
        originalEditedShift?.pickupTime,
        originalEditedShift?.shiftId,
    ]);

    if (!originalEditedShift) {
        return { getCurrOrder };
    }
    return { getCurrOrder, deletedOrder };
};

const useEditedShiftData = ({
    editShift,
    selectedTab,
}: {
    selectedTab: 0 | 1;
    editShift: IEditShift | undefined;
}) => {
    const [tabChangeCount, setTabChangeCount] = useState(0);
    const { setPlacment } = usePlacement();

    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender) return;
        setTabChangeCount((prev) => prev + 1);
    }, [selectedTab, isFirstRender]);

    // -- Setting manual order to be same as edited shift IF is FULL shift (has both pickup AND drop)
    // -- Possible issues:
    // -- 1. is a good feature as long as manual placement RESETS when switching to AUTO tab
    useEffect(() => {
        console.log(tabChangeCount);
        const isAutoShift = editShift?.codes.length !== 2;
        if (!editShift || selectedTab === 0 || isAutoShift || tabChangeCount > 2) return;

        const { pickupTime, dropTime, shiftId } = editShift;

        const selectedShiftInPlacementFormat = buildPlacementShiftObj(ShiftType.Manual, {
            pickupTime,
            dropTime,
            shiftId: shiftId || '',
        });

        setPlacment((prev) => {
            return {
                ...prev,
                manualOrder: selectedShiftInPlacementFormat as ManualPlacementObj,
            };
        });
    }, [setPlacment, editShift?.shiftId, selectedTab]);
};

export const placementTransportationHooks = {
    useDataReFetchingForPlacement,
    useEditOrderPlacementApi,
    useChangedOrderData,
    useEditedShiftAsInitialManualOrderData: useEditedShiftData,
};
