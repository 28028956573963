import moment, { Moment } from 'moment';
import { BIG_INT } from 'src/constants/misc';
import i18n from 'src/i18n';
import { TripDirections } from 'src/types/line';
import {
    digitOrPlusSymbolRegex,
    notUndefinedOrNull,
    onlyDigitRegex,
    positiveNumberRegex,
    timeDigit,
} from 'src/utilis/utilis';
import * as yup from 'yup';
import { TIME_FORMATS } from '../../../../constants/dates';

enum ErrorMessages {
    IsRequired = 'isRequired',
    MustBeLessThan = 'mustBeLessThan',
    InvalidInputFormat = 'invalidInputFormat',
    NumMustBePositive = 'numMustBePositive',
    MustBeNum = 'mustBeNum',
    InvalidEmail = 'invalidEmail',
}

const { IsRequired, InvalidEmail, MustBeLessThan, InvalidInputFormat, NumMustBePositive, MustBeNum } =
    ErrorMessages;

export const passengerSchema = yup.object({
    internalCode: yup
        .string()
        .required(IsRequired)
        .matches(/(^[0-9]+$|^$)/, MustBeNum),
    // passCode: yup.string().default(''),
    firstName: yup.string().required(IsRequired).max(264, `${MustBeLessThan}-264`),
    lastName: yup.string().required(IsRequired).max(264, `${MustBeLessThan}-264`),
    passengerRemarks: yup.string(),
    city: yup
        .string()
        .max(264, `${MustBeLessThan}-264`)
        .when('street', {
            is: (street: string) => street.length > 0,
            then: (schema) => schema.required(IsRequired),
        })
        .when('houseNum', {
            is: (houseNum: string) => houseNum,
            then: (schema) => schema.required(IsRequired),
        }),
    street: yup.string().max(264, `${MustBeLessThan}-264`),
    houseNum: yup.string().matches(positiveNumberRegex, NumMustBePositive).max(264, `${MustBeLessThan}-264`),
    phone: yup.string().max(16, `${MustBeLessThan}-16`).matches(digitOrPlusSymbolRegex, MustBeNum),
    phonePrefix: yup
        .string()
        .max(3, `${MustBeLessThan}-3`)
        .matches(/^|[0-9]+$|^$/, MustBeNum),
    email: yup.string().matches(/^$|^.*@.*\..*$/, InvalidEmail),
});

export const stationSchema = yup.object({
    city: yup
        .string()
        // .required(IsRequired)
        .max(60, `${MustBeLessThan}-60`),
    street: yup.string().max(60, `${MustBeLessThan}-60`),
    houseNum: yup.string().matches(positiveNumberRegex, NumMustBePositive).max(264, `${MustBeLessThan}-264`),

    passengers: yup.array().of(yup.string()).default([]),
    stationId: yup.string().required(),
});

const addressObject = {
    city: yup.string().required(IsRequired),
    street: yup.string(),
    houseNum: yup
        .string()
        // .matches(positiveNumberRegex, NumMustBePositive)
        .max(264, `${MustBeLessThan}-264`),
    placeName: yup.string(),
};

export const addressSchema = yup.object(addressObject);

export const minDate = moment(new Date().setHours(0, 0, 0, 0)).toDate();

export const startTimeSchema = yup
    .string()
    .required(IsRequired)
    .matches(timeDigit, InvalidInputFormat)
    .test('is-above-COT', 'invalidTimeInputByCOT', function test(value) {
        if (typeof value !== 'string' || value.length < 3) return false;

        const { cot, selectedDate } = this.options.context || {};
        if (typeof cot !== 'number' && !selectedDate) return true;

        const currTime = moment(new Date());

        const [hours, minutes] = value.split(':');

        const selectedTime = moment(selectedDate).set({
            hour: Number(hours),
            minute: Number(minutes),
        });

        const minValidTime = currTime.clone().add(cot, 'minutes');

        return selectedTime.isAfter(minValidTime);
    })
    .nullable();

export const endTimeSchema = yup.string().when(['startTime', 'tripDirection'], {
    is: (startTime: any, tripDirection: any) => startTime && tripDirection !== TripDirections.OneWay,
    then: (schema: any) =>
        schema
            .required(IsRequired)
            .test(
                'isAfterStartTime',
                'mustBeLaterThanStartTime',
                (value: string | undefined, testContext: any) => {
                    const { startTime } = testContext.options.parent || {};

                    return moment(value, TIME_FORMATS.TWENTY_FOUR).isAfter(
                        moment(startTime, TIME_FORMATS.TWENTY_FOUR)
                    );
                }
            ),
});

const returnTripStartTimeSchema = yup
    .string()
    .nullable()
    .when(['tripDirection', 'startTime'], {
        is: (tripDirection: any, startTime: any) => tripDirection === TripDirections.RoundTrip && startTime,
        then: (schema) =>
            schema
                .required(IsRequired)
                .test(
                    'isAfterStartTripEnded',
                    'mustBeAfterStartTripHasEnded',
                    (value: string | undefined | null, testContext: any) => {
                        const { endTime } = testContext.options.parent || {};

                        return moment(value, TIME_FORMATS.TWENTY_FOUR).isAfter(
                            moment(endTime, TIME_FORMATS.TWENTY_FOUR)
                        );
                    }
                ),
    });

const returnTripEndTimeSchema = yup.string().when(['returnTripStartTime', 'tripDirection'], {
    is: (returnTripStartTime: any, tripDirection: any) =>
        returnTripStartTime && tripDirection === TripDirections.RoundTrip,
    then: (schema: any) =>
        schema
            .required(IsRequired)
            .test('isAfterStartTime', 'mustBeLaterThanStartTime', (value: string, testContext: any) => {
                const { returnTripStartTime } = testContext.options.parent || {};

                return moment(value, TIME_FORMATS.TWENTY_FOUR).isAfter(
                    moment(returnTripStartTime, TIME_FORMATS.TWENTY_FOUR)
                );
            }),
});

export const schema = yup
    .object({
        date: yup.date().required().min(minDate),
        departmentCode: yup.string().nullable(),
        courseCode: yup.string().nullable(),
        linePrice: yup.number().nullable(),
        startTime: startTimeSchema,
        description: yup.string().nullable(),
        endTime: endTimeSchema,
        tripDirection: yup.mixed().oneOf(Object.values(TripDirections)),
        returnTripStartTime: returnTripStartTimeSchema,
        returnTripEndTime: returnTripEndTimeSchema,
        startAddress: yup.object(addressObject).required(IsRequired),
        endAddress: yup.object(addressObject).required(IsRequired),
        lineRemarks: yup.string().max(240, `${MustBeLessThan}-240`),
        clientCode: yup.number().required(),
        passQty: yup.number().nullable(),
        carId: yup.string().nullable(),
        invContactName: yup.string().max(60, `${MustBeLessThan}-60`),
        invContactPhone: yup
            .string()
            .max(12, `${MustBeLessThan}-12`)
            .matches(digitOrPlusSymbolRegex, MustBeNum),
        invContactPhonePrefix: yup
            .string()
            .max(3, `${MustBeLessThan}-3`)
            .matches(positiveNumberRegex, MustBeNum),
        flightNum: yup.string().max(6, `${MustBeLessThan}-6`),
        clientOrderCode: yup
            .string()
            .max(12, `${MustBeLessThan}-12`)
            .matches(onlyDigitRegex, MustBeNum)
            .test(
                'is-smaller-than-big-int',
                `${i18n.t('mustBeSmallerThan')} ${BIG_INT}`,
                (value: string | undefined) => {
                    // -- empty string or undefined
                    if (!value) {
                        return true;
                    }
                    // -- is number
                    if (+value) {
                        return +value < BIG_INT;
                    }
                    return false;
                }
            )

            .nullable(),
    })
    .required();
// ([startTime, tripDirection], s): any => {
//     console.log({ startTime, tripDirection, s });
//     return {} as any;
//     if (currSchema && 'required' in (currSchema as any)) {
//         const typedCurrSchema = currSchema as yup.StringSchema;
//         const [startTime, tripDirection] = values;

//         if (tripDirection !== TripDirections.OneWay) {
//             const updatedSchema = typedCurrSchema.required(IsRequired);
//             if (startTime) {
//                 return updatedSchema.test(

//                 );
//             }
//             return updatedSchema;
//         }

//         return currSchema as any;
//     }
// };
