import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import {
    selectedReportNameSelector,
    selectResetFiltersClickCount,
} from 'src/store/selectores/reportsSelectors';
import { getOperatorPerReport } from './utils';

interface IProps {
    setFilters: Function;
}
interface FilterHookResult {
    globalFilterProp: {
        value: string;
        label: string;
        size: 'small' | 'medium';
        style?: {
            backgroundColor: string;
        };
        labelWidth?: number;
        inputStyle?: {
            backgroundColor: string;
        };
        onChange: (e: React.FormEvent<HTMLInputElement> | any) => void;
    };
}

// * hooks
const useGlobalFilter = ({ setFilters }: IProps): FilterHookResult => {
    const { t } = useTranslation();
    const [globalFilter, setGlobalFilter] = useState<string>('');

    const resetFiltersClickCount = useAppSelector((state: any) => selectResetFiltersClickCount(state));
    useEffect(() => setGlobalFilter(''), [resetFiltersClickCount]);
    const selectedReportName = useSelector((state: any) => selectedReportNameSelector(state));
    const buildFilters = (): void => {
        const globalFilterArray: Array<FilterDescriptor> = globalFilter
            ? [
                  {
                      field: '',
                      operator: getOperatorPerReport(selectedReportName),
                      value: globalFilter,
                      ignoreCase: true,
                  },
              ]
            : [];

        if (globalFilterArray.length) {
            setFilters({
                logic: 'or',
                filters: globalFilterArray,
            });

            return;
        }

        setFilters(undefined);
    };

    useEffect(buildFilters, [globalFilter, setFilters]);

    const globalFilterProp = {
        value: globalFilter,
        label: t('freeSearch'),
        size: 'small' as const,
        labelWidth: 90,
        inputStyle: { backgroundColor: 'white' },
        onChange: (e: React.FormEvent<HTMLInputElement> | any) => {
            const { value } = e.currentTarget;
            setGlobalFilter(value);
            buildFilters();
        },
    };

    return {
        globalFilterProp,
    };
};

export default useGlobalFilter;
