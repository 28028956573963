import { IAddress, IPassenger } from 'src/api/passengerMangerApi/types';
import { IAddressWithIdAndIsDefault } from 'src/types/misc';
import { v4 } from 'uuid';
import { MenuItem } from './types.PlacementAddressSelection';

export const SUCCESS = '0';
export const EXPIRED_TOKEN = '1';

function removeDuplicateObjectFromArray<T>(array: T[], key: string) {
    return array.filter((obj, index, self) => index === self.findIndex((el) => el[key] === obj[key]));
}
/**
 * Builds the menu items for the address dropdown menu.
 * @param {IAddressWithIdAndIsDefault[]} addressesMap - the addresses to build the menu items from.
 * @returns {MenuItem[]} - the menu items for the address dropdown menu.
 */
const buildMenuItems = (addressesMap: IAddressWithIdAndIsDefault[]) => {
    const menuItems: MenuItem[] = [];

    addressesMap.forEach((address) => {
        const { id, city, street, houseNum } = address;

        // Name is for example "הרצל 12, תל אביב"
        menuItems.push({
            name: `${street ? `${street},` : ''} ${houseNum ? `${houseNum},` : ''} ${city}`,
            value: id,
        });
    });

    return removeDuplicateObjectFromArray<MenuItem>(menuItems, 'name');
};

/**
 * Takes in a passenger object and returns an array of address objects with the following
 * properties:
 * - id: the address code OR a generated unique id by v4
 * - city: the city
 * - street: the street
 * - houseNum: the house number
 * - isDefault: whether the address is the default address
 * @param {IPassenger} passenger - the passenger object
 * @returns {IAddressWithIdAndIsDefault[]} - an array of address objects
 */

export interface IWeakAddress {
    addressCode?: string;
    city?: string;
    street?: string;
    houseNum?: string;
    isDefault?: string;
}

const buildAddressMap = (addresses: IWeakAddress[]): IAddressWithIdAndIsDefault[] => {
    return addresses.map((address) => ({
        id: address.addressCode || v4(),
        city: address.city || '',
        street: address.street || '',
        houseNum: address.houseNum || '',
        isDefault: String(address.isDefault) === '1',
    }));
};

const placementAddressHelpers = { buildAddressMap, buildMenuItems };

export default placementAddressHelpers;
