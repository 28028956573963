import React, { useEffect, useRef } from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    coursesBuildingSelectors,
    formTriggerSelector,
    setFieldsCopyAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { ICourseStation } from '../../../CourseInfoWidget/StationsAndTimesPanel';
import useCommons from 'src/hooks/common/useCommons';
import { StationsPanelStyles as styles } from './styles';
import NakedBtn from 'src/components/buttons/NakedBtn';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CourseDetailsFormSchema, StationSchema } from 'src/types/coursesBuilding/form/formTypes';
import { getEmptyStation } from '../../utils/common';
import StationInputBox from './StationInputBox';
import useValidations, { StationUpdateMethod, useCoordsMapUpdate, useFieldDrag } from './hooks.StationsPanel';
import { isTypeofNumber } from 'src/utilis/utilis';
import ApiGeneratedDataWrapper from 'src/components/Wrappers/ApiGeneratedDataWrapper';

const useUpdateByTrigger = () => {
    const { setValue } = useFormContext<CourseDetailsFormSchema>();

    const trigger = useRootAppSelector((state) => formTriggerSelector(state).formStations);

    React.useEffect(() => {
        if (trigger.count) {
            setValue('stations', trigger.data || []); // [] in case is null
        }
    }, [setValue, trigger.count]);
};

const StationsList: React.FC<{}> = () => {
    const { t, dispatch } = useCommons();

    const { data: allStations } = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseStations
    );

    useUpdateByTrigger();

    const courseCodeOnForm = useRootAppSelector((state) => courseBuildingFormSelector(state).courseCode);

    const { control, getValues, setValue, resetField } = useFormContext<CourseDetailsFormSchema>();

    const fieldArray = useFieldArray({
        control,
        name: 'stations', // unique name for your Field Array
        shouldUnregister: true,
    });

    const { fields, ...methods } = fieldArray;

    const allStationsValues = getValues('stations');

    useValidations();

    useCoordsMapUpdate(fields, methods.update as unknown as StationUpdateMethod);

    const { onDragEnd } = useFieldDrag({ fields, methods });

    React.useEffect(() => {
        dispatch(setFieldsCopyAction(allStationsValues));
    }, [allStationsValues, dispatch]);

    /*
        After rendering finishes, useEffect will check the list of dependency values against the values from
        the last render, and will call your effect function if any one of them has changed.
    */
    React.useEffect(() => {
        if (allStations.length) {
            setValue('stations', allStations);
            return;
        }

        resetField('stations');
    }, [courseCodeOnForm, allStations]);

    return (
        <div style={{ textAlign: 'right' }}>
            {' '}
            <div style={{ paddingBottom: '15px' }}>
                <NakedBtn
                    type="add"
                    onClick={() => {
                        const INDEX_FOR_LAST_NORMAL_STATION = fields.length - 1;
                        methods.insert(INDEX_FOR_LAST_NORMAL_STATION, getEmptyStation());

                        const element = document.getElementById('courseBuildingFormBodyComponent');
                        if (element) {
                            setTimeout(() => {
                                element.scrollTo({
                                    top: element.scrollHeight - element.clientHeight,
                                    behavior: 'smooth',
                                });
                            }, 0);
                        }
                    }}
                    text={t('addStation')}
                />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <styles.StationsLstContainer
                            className="StationsLstContainer"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {fields.map((station, i) => {
                                const stationData: ICourseStation = {
                                    ...station,
                                    city: station.city || '',
                                    street: station.street || '',
                                    houseNum: station.houseNum || '',
                                    timeFromPrev: isTypeofNumber(station.timeFromPrev)
                                        ? station.timeFromPrev
                                        : null,
                                    type: '',
                                    stationRemark: station.stationRemark || '',
                                };

                                return (
                                    <Draggable
                                        draggableId={stationData.stationId}
                                        index={i}
                                        key={stationData.stationId}
                                    >
                                        {(provided2) => (
                                            <div
                                                ref={provided2.innerRef}
                                                {...provided2.draggableProps}
                                                {...provided2.dragHandleProps}
                                            >
                                                <StationInputBox
                                                    stationData={stationData}
                                                    index={i}
                                                    fieldArray={fieldArray as any}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </styles.StationsLstContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

const StationsPanel: React.FC<{}> = (props) => {
    const { status } = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseStations
    );

    return (
        <div>
            <ApiGeneratedDataWrapper apiCallStatus={status}>
                <StationsList />
            </ApiGeneratedDataWrapper>
        </div>
    );
};

export default StationsPanel;
