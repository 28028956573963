import React from 'react';
import { StyledTableRow, StyledTableCell } from '../styles';
import { getStyle } from '../utils';

interface Props {}
function PassengerDataGridRow({
    passengers,
    psngr,
    idx,
}: {
    passengers: any;
    psngr: any;
    idx: number;
}): JSX.Element {
    return (
        <StyledTableRow>
            <StyledTableCell sx={getStyle(passengers, idx)} component="th">
                {psngr.time}
            </StyledTableCell>
            <StyledTableCell sx={getStyle(passengers, idx)} component="th">
                {psngr.fullName}
            </StyledTableCell>
            <StyledTableCell sx={getStyle(passengers, idx)} component="th">
                {psngr.address}
            </StyledTableCell>
            <StyledTableCell sx={getStyle(passengers, idx)} component="th">
                {psngr.passId}
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default PassengerDataGridRow;
