/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useLayoutEffect, useRef, useState } from 'react';

const useParentDimensions = (currRef = null) => {
    const targetRef = useRef(null);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    useLayoutEffect(() => {
        const ref = currRef || targetRef;
        if (ref.current) {
            setDimensions({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight,
            });
        }
    }, [currRef, targetRef]);

    return {
        targetRef,
        dimensions,
    };
};

export default useParentDimensions;
