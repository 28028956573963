import React, { useState } from 'react';
import DatePickerWithPrevNext from 'src/components/DatePicker/DatePickerWithPrevNext';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Input from 'src/components/commons/Input/Input';
import { InputAdornment } from '@material-ui/core';
import ApplyFiltersBtn from '../../ApplyFiltersBtn/ApplyFiltersBtn';
import s, { styledMui } from '../../Filters.style';
import useDropdownProps from '../../hooks/useDropdownProps';
import useGlobalFilter from '../../hooks/useGlobalFilter';
import ClearFiltersBtn from '../../ClearFiltersBtn/ClearFiltersBtn';
import { DefaultAccordionProps } from '../PassengerReportFilters/PassengersReportFilter';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Props = DefaultAccordionProps;

const VisasReportFilters = ({ setClientSideFilters, expandConfig }: Props): JSX.Element => {
    // -- global filter handling
    const { globalFilterProp } = useGlobalFilter({
        setFilters: setClientSideFilters,
    });
    // -- dropdown props
    const { datePickerProps } = useDropdownProps({ report: 'visas' });

    return (
        <styledMui.FiltersAccordion expanded={expandConfig?.isExpanded} elevation={0}>
            <styledMui.MyAccordionSummary
                expandIcon={expandConfig?.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                    onClick: expandConfig?.toggle,
                }}
            >
                <s.AccordionBtnsContainer
                    gtc="160px 160px 140px repeat(2, 1fr) 80px 80px"
                    className="AccordionBtnsContainer"
                    paddingLeft="12px"
                >
                    <DatePickerWithPrevNext {...datePickerProps.fromDateProps} />
                    <DatePickerWithPrevNext {...datePickerProps.toDateProps} />
                    <Input
                        {...globalFilterProp}
                        className="reports-free-search"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                    <div />
                    <div />
                    <ApplyFiltersBtn />
                    <ClearFiltersBtn />
                </s.AccordionBtnsContainer>
            </styledMui.MyAccordionSummary>
        </styledMui.FiltersAccordion>
    );
};

export default VisasReportFilters;
