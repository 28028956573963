import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchInput from 'src/components/SearchInput/SearchInput';
import { ExistingPassenger } from 'src/screens/Main/components/AddLineForm/types';
import {
    getParStyle,
    Par,
    StyledCourseChoiceCard,
    StyledTitle,
} from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/Components/CoursesList/CourseTypeTabs/styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    formSelector,
    onPassengerCardClickAction,
    passengersDataSelector,
    selectedPassengersLstSelector,
    stationsSelector,
} from 'src/store/slices/lines/linesSlice';
import { css } from 'styled-components';
import { cs } from 'src/style/styledComponents.style';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { fullFormHooks } from '../../formSections/hooks.FullForm';

type Props = {};
const getTitleStyle = (flex = 1) => {
    return { ...getParStyle(flex), fontSize: '14px' };
};

const ExistingPassengersForm: React.FunctionComponent<Props> = ({}) => {
    // -- Vanilla hooks
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const dispatchAlert = useDispatchAlert();
    const selectedCarData = fullFormHooks.useSelectedCarData();

    // -- selectors
    const stationsLst = useRootAppSelector(stationsSelector);
    const passengers = useAppSelector(passengersDataSelector);
    const selectedPassengers = useAppSelector(selectedPassengersLstSelector);
    const stationId = useRootAppSelector((state) => formSelector(state).passengersForm.stationId);

    // -- Local states
    const [filtered, setFiltered] = useState<ExistingPassenger[]>(passengers);

    // -- Effects
    useEffect(() => {
        setFiltered(passengers);
    }, [passengers]);

    // -- Helpers
    const onFilterChange = React.useCallback(
        (e: any) => {
            const val = e.target.value;
            setFiltered(
                passengers.filter(
                    (p) =>
                        p.firstName.includes(val) ||
                        p.lastName.includes(val) ||
                        p.internalCode.includes(val) ||
                        p.remarks.includes(val) ||
                        p.mobilePhone.includes(val)
                )
            );
        },
        [passengers]
    );

    const onPassengerCardClick = (passenger: ExistingPassenger) => {
        const isPasSelected = selectedPassengers.find((loopCurr) => loopCurr.passCode === passenger.passCode);

        const passengersOnOtherStationsSum: number = stationsLst.reduce((acc, currStation) => {
            return stationId !== currStation.stationId ? acc + currStation.passengers.length : acc;
        }, 0);

        if (
            selectedCarData?.passQty === passengersOnOtherStationsSum + selectedPassengers.length &&
            !isPasSelected
        ) {
            dispatchAlert('warning', 'הרכב מלא, לא ניתן להוסיף נוסעים נוספים');
            return;
        }

        dispatch(onPassengerCardClickAction({ passenger }));
    };

    return (
        <cs.FullWidth>
            <SearchInput onChange={onFilterChange} style={{ paddingBottom: '20px' }} />
            <StyledTitle>
                <p style={getTitleStyle()}>{t('internalCode')}</p>
                <p style={getTitleStyle()}>{t('firstName')}</p>
                <p style={getTitleStyle()}>{t('lastName')}</p>
                <p style={getTitleStyle()}>{t('mobilePhone')}</p>
                <p style={getTitleStyle(2)}>{t('remarks')}</p>
            </StyledTitle>
            {filtered.map((psngr: ExistingPassenger) => {
                return (
                    <StyledCourseChoiceCard
                        isChosen={
                            !!selectedPassengers.find((loopCurr) => loopCurr.passCode === psngr.passCode)
                        }
                        key={psngr.passCode}
                        onClick={() => onPassengerCardClick(psngr)}
                    >
                        <Par fontSize="12px">{psngr.internalCode}</Par>
                        <Par fontSize="12px">{psngr.firstName}</Par>
                        <Par fontSize="12px">{psngr.lastName}</Par>
                        <Par fontSize="12px">{psngr.mobilePhone}</Par>
                        <Par
                            flex={2}
                            fontSize="12px"
                            styledCss={css`
                                text-align: left;
                                padding-right: 3px;
                                width: 100%;
                            `}
                        >
                            {psngr.remarks}
                        </Par>
                    </StyledCourseChoiceCard>
                );
            })}
        </cs.FullWidth>
    );
};

export default ExistingPassengersForm;
