import { useTranslation } from 'react-i18next';
/* eslint-disable react/button-has-type */
import React, { FC, useMemo, useRef } from 'react';
import { ReqStatus } from 'src/api/types';
import Spinner from 'src/components/Spinner/Spinner';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    isPrintingSelector,
    selectedReportNameSelector,
    selectedReportSelector,
    selectError,
} from 'src/store/selectores/reportsSelectors';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import useParentDimensions from 'src/hooks/useParentDimensions';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import useFilterValuesBySelectedReport from 'src/screens/Reports/hooks/useFilterValuesByReport';
import { stringifyDate } from 'src/utilis/utilis';
import { DISPLAY_UI_LONG } from 'src/constants/dates';
import moment from 'moment';
import KendoDataGrid from './DataGrids/KendoDataGrid/KendoDataGrid';
import { getAsTemplateStrArr as asTSR } from '../../../../../utilis/utilis';

const useAppliedDates = () => {
    const selectedReportName = useRootAppSelector(selectedReportNameSelector);

    const { getFilterValuesByReport } = useFilterValuesBySelectedReport();
    const values = getFilterValuesByReport('applied');

    const datesText = useMemo(() => {
        if (!values) return '';

        return selectedReportName === 'passengers'
            ? moment(values.fromDate).format('MM.YYYY')
            : `
            ${stringifyDate(values.fromDate, DISPLAY_UI_LONG)} - 
            ${stringifyDate(values.toDate, DISPLAY_UI_LONG)} 
            `;
    }, [selectedReportName, values]);

    return { datesText };
};

const ReportDatesDisplayTableRow = () => {
    const { t } = useTranslation();
    const { datesText } = useAppliedDates();

    return (
        <tr style={{ position: 'absolute', opacity: 0 }}>
            <td style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {t('reportToDates')} - {datesText}
            </td>
        </tr>
    );
};
const DatesDisplayForPrint: FC = () => {
    const { datesText } = useAppliedDates();
    const selectedReportName = useRootAppSelector(selectedReportNameSelector);
    const { t } = useTranslation();
    return (
        <div style={{ padding: '20px' }}>
            <p>{t(asTSR(`${selectedReportName}Report`))}</p>
            <p style={{ fontSize: '24px' }}>{datesText.split(' ').reverse().join(' ')}</p>
        </div>
    );
};

type Props = {
    excelExportRef: React.MutableRefObject<any>;
    tableRef: React.MutableRefObject<null>;
    setClientSideFilters: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor | null>>;
    clinetSideFilters: CompositeFilterDescriptor | null;
};

const Table = ({ excelExportRef, setClientSideFilters, clinetSideFilters, tableRef }: Props): JSX.Element => {
    const reqStatus = useAppSelector((state) => selectedReportSelector(state).apiCallStatus);
    const isPrinting: boolean = useAppSelector((state) => isPrintingSelector(state));

    const { dimensions } = useParentDimensions(tableRef as any);

    return (
        <div style={{ position: 'relative' }}>
            {isPrinting && (
                <div style={{ height: '1000px' }}>
                    <Spinner />
                </div>
            )}
            <div id="table" ref={tableRef} style={{ width: '100%' }}>
                {reqStatus === ReqStatus.FAIL ? (
                    <p> שגיאה במשיכת נתונים</p>
                ) : (
                    <table id="kendo-table" style={{ width: '100%' }}>
                        <ReportDatesDisplayTableRow />
                        {isPrinting ? <DatesDisplayForPrint /> : ''}
                        <KendoDataGrid
                            parentDimensions={dimensions}
                            isPrinting
                            setClientSideFilters={setClientSideFilters}
                            clinetSideFilters={clinetSideFilters}
                            excelExportRef={excelExportRef}
                        />
                    </table>
                )}
                {/* <Test /> */}
            </div>
        </div>
    );
};

export default Table;

// const getTableStyle = (
//    rows: readonly { [key: string]: any }[],
//    headerHeight: number,
// ) => ({
//    // height: `${rows.length * 52 + headerHeight}px`,
//    // maxHeight: '400px',
// });
// const headerHeight = 36;
