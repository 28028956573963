import moment from 'moment';
import { WsDrop } from 'src/api/dropsDisplay/types';
import { DropLine } from 'src/store/slices/dropsDisplay/dropDisplaySlice';
import { isEven } from 'src/utilis/utilis';
import { v4 } from 'uuid';

const TIME_TYPE = 'startTime';
export const processDropsDisplayWsData = (data: WsDrop[]): DropLine[] => {
    const processed = data
        .sort((a, b) => {
            return new Date(a[TIME_TYPE]).valueOf() - new Date(b[TIME_TYPE]).valueOf();
        })
        .map((drop, i) => {
            return {
                id: v4(),
                time: moment(drop[TIME_TYPE]).format('HH:mm'),
                description: drop.lineDescription,
                remarks: drop.remarks,
                vehicleType: drop.carTypeName,
                driverName: drop.driverName,
                colorIndex: (isEven(i) ? 0 : 1) as 0 | 1,
            };
        });
    return processed;
};
