import styled from 'styled-components';

const Container = styled.div`
   width: 100%;
   height: 32px;
   background-color: ${({ theme }) => theme.colors.seaBlue}
   // background: ${({ theme }) => theme.colors.white};
   box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
   display: flex;
   flex-direction: row;
`;

const TextLink = styled.a`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.msColors.primaryTextcolor};
    margin: 0 5px;
`;

export default { Container, TextLink };
