/* eslint-disable import/no-cycle */

import { all } from 'redux-saga/effects';
// Import your saga files here
import passengerShiftSaga from './passengerShiftsSaga';
import passengerSaga from './passengerSaga';
import rideSettingsSaga from './rideSettingsSaga';
import reportsSaga from './reportsSaga';
import dropsDisplaySaga from './dropsDisplaySaga';
import loginSaga from './loginSaga';
import lineSaga from './lineSaga';

export default function* watchRootSaga(): Iterator<any> {
    yield all([
        passengerShiftSaga(),
        passengerSaga(),
        rideSettingsSaga(),
        lineSaga(),
        reportsSaga(),
        dropsDisplaySaga(),
        loginSaga(),
    ]);
}
