/* eslint-disable import/no-cycle */
import React, {
    FunctionComponent,
    useState,
    useEffect,
    useRef,
    MutableRefObject,
    ReactChild,
    CSSProperties,
    useCallback,
} from 'react';
import moment from 'moment';
import { ChangeEventValue, Coords, fitBounds } from 'google-map-react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Map from 'src/components/Map/Map';

import { AVLData, getActualDriver, getLocation } from 'src/api/api';
import { useDispatch, useSelector } from 'react-redux';
import { lineSelector } from 'src/store/selectores/linesSelectores';
import { loginSelector, selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';
import { images } from 'src/assets/index';
import { IRootReducer } from 'src/store/reducers';
import Polyline from 'src/components/Map/Polyline';
import { GpsResponseEnum } from 'src/types/line';
import MarkerGps from 'src/components/Map/Marker';

import Spinner from 'src/components/Spinner/Spinner';
import { MINUTE } from 'src/constants/times';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useRootAppSelector } from 'src/store/hooks';
import { updateGoogleApiCounter } from 'src/utilis/utilis';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from 'src/store/slices/common/commonSlice';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';
import { GoogleMarkerClusterer, Marker } from '@react-google-maps/api';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { height } from '@mui/system';
import useIturanData, {
    defaultCenter,
    getCenter,
    IMarkers,
    ReqIturanResult,
    shouldUseIturanLocation,
} from '../Lines/hooks/useIturanData';
import Style from './LineMap.style';

// & UTILS
const getToolTip = (time?: Date): ReactChild | string => {
    return (time && moment(time).format('DD/MM/yyyy HH:mm:ss')) || '';
};

// & CSS STYLES
const imageCSS: CSSProperties = {
    position: 'absolute',
    left: '3%',
    bottom: '10%',
    boxShadow: '0px 1px 10px rgb(0 0 0 / 10%)',
    borderRadius: '66px',
    cursor: 'pointer',
    width: '30px',
};
const responseMsgBoxCSS: CSSProperties = {
    position: 'absolute',
    left: '5%',
    top: '1%',
    borderRadius: '10px',
    width: '60%',
};
const styles: {
    spinnerWrapper: CSSProperties;
} = {
    spinnerWrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f2f3f5',
    },
};

// & CONSTANTS

const intervalTime = MINUTE;
const defaultZoom = 15;

const GET_LOCATION_DATE_FORMAT = 'yyyy-MM-DD HH:mm';

const getCurrLocationMsg: (driverPosition: IMarkers | undefined, t: Function) => string = (
    driverPosition,
    t
) => {
    return `${t('driverCurrentLoactionMassage')} ${
        driverPosition ? moment(driverPosition.date).format('DD/MM/yyyy HH:mm') : ''
    }`;
};

// ^ ACTUAL REACT COMPONENT ---------------------------------------------------
const LineMap: FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const { handleIturanReq } = useIturanData();
    const dispatch = useDispatch();
    const intervalRef: MutableRefObject<NodeJS.Timeout | undefined> = useRef();
    const myRef = useRef<HTMLInputElement | null>(null);

    // * Local states
    const [actualDriver, setActualDriver] = useState<{ driverId: number | string; accountCode: string }>();

    const [mapProps, setMapProps] = useState<{
        mapsLoaded: boolean;
        map: any; // map prop
        maps: any; // mapApi
    }>({
        mapsLoaded: false,
        map: null,
        maps: null,
    });

    const [gpsResponse, setGpsResponse] = useState<GpsResponseEnum | undefined>();
    const [driverPosition, setDriverPosition] = useState<IMarkers | undefined>();
    const [markers, setMarkers] = useState<Array<IMarkers>>([]);
    const [zoom, setZoom] = useState<number>(defaultZoom);
    const [center, setCenter] = useState<Coords>(defaultCenter);
    const [isZoom, setIsZoom] = useState<boolean>(true);
    const [avlData, setAvlData] = useState<AVLData | null>(null);
    const [isUsingIturan, setIsUsingIturan] = useState<boolean>(false);
    const [ituranStatusMessage, setIturanStatusMessage] = useState<string>('');
    const [locationStatusMessage, setLocationStatusMessage] = useState('');
    const [actualDriverError, setActualDriverError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // * Selectors
    const selectedLine = useSelector((state) => lineSelector(state));

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { gps_server, gps_token } = useSelector((state: IRootReducer) => loginSelector(state));

    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));
    const googleCounterUp = useGoogleApiCounterUp();

    // * Event handlers
    const onFocus = () => {
        setCenter(markers.length ? markers[markers.length - 1] : defaultCenter);
        setZoom(defaultZoom);
        setIsZoom(true);
    };

    const onDrag = () => {
        setIsZoom(false);
    };

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    const onMapLoaded = (map: any, maps: any) => {
        setMapProps({
            mapsLoaded: true,
            map,
            maps,
        });
    };

    const onLineChange = async () => {
        try {
            if (!selectedLine || !selectedFcAccount || !actualDriver?.driverId) return;
            let driverPath: Array<IMarkers> = [];

            if (
                shouldUseIturanLocation({
                    lineCarNumber: selectedLine.carNumber,
                    AVLType: avlData?.AVLType,
                }) &&
                avlData
            ) {
                setIsUsingIturan(true);

                const result = await handleIturanReq({
                    avlData,
                    driverPath,
                    setDriverPosition,
                    setMarkers,
                    setCenter,
                    setIsLoading,
                    setIturanStatusMessage,
                });

                if (result === ReqIturanResult.OK) {
                    setIsLoading(false);
                    return;
                }
            }

            setIsUsingIturan(false);

            getLocation({
                gps_server,
                accountCode: +actualDriver.accountCode || selectedFcAccount.accountCode,
                getGPSData: 1,
                from: moment(selectedLine.startTime).format(GET_LOCATION_DATE_FORMAT),
                to: moment(selectedLine.endTime).format(GET_LOCATION_DATE_FORMAT),
                token: gps_token,
                driverCode: +actualDriver.driverId,
            }).then((result) => {
                const { response, currentPos, gpsData } = result.data;
                const responseGpsData = gpsData || null;
                const gpsCallResponse = response;
                console.log({ currentPos });
                if (
                    intervalRef.current &&
                    (response === GpsResponseEnum.tripEnded ||
                        response === GpsResponseEnum.tripEndedWithCurrentPostion)
                )
                    clearInterval(intervalRef.current);

                if (!isZoom && !driverPath.length) setCenter(defaultCenter);

                driverPath = responseGpsData.map((resGpsData) => {
                    return {
                        lat: resGpsData.lt,
                        lng: resGpsData.lg,
                        date: resGpsData.t,
                    };
                });

                if (currentPos) {
                    driverPath.push({
                        lat: currentPos.lt,
                        lng: currentPos.lg,
                        date: currentPos.t,
                    });
                }

                setDriverPosition(
                    (currentPos && {
                        lat: currentPos.lt,
                        lng: currentPos.lg,
                        deg: currentPos.h,
                        date: currentPos.t,
                    }) ||
                        null
                );
                setGpsResponse(gpsCallResponse);
                setMarkers(driverPath);
                if (
                    isZoom &&
                    (response === GpsResponseEnum.currenLoactionExiest ||
                        response === GpsResponseEnum.tripEndedWithCurrentPostion ||
                        response === GpsResponseEnum.driverCureentPostionNotUpdated ||
                        isUsingIturan)
                ) {
                    setCenter(getCenter(driverPath));
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            dispatch(setErrorMessage(t(ErrorMessages.GeneralError)));
        }
    };

    useEffect(() => {
        googleCounterUp.googleApiCounterUp();
    }, []);

    // * Effects

    useEffect(() => {
        setActualDriverError('');
        if (selectedLine) {
            setIsLoading(true);
        }
        setIturanStatusMessage('');
        setLocationStatusMessage('');
        setIsZoom(true);
    }, [selectedLine]);

    useEffect(() => {
        // console.log(6);

        setMarkers([]);
        setCenter(defaultCenter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // tamir

        if (selectedLine && selectedLine.passengers) {
            // Calculate the bounding box of the selected waypoints

            const stationsWithCoords: { lat: number; lng: number }[] = [];

            selectedLine.passengers.forEach((passenger) => {
                if (passenger.lat && passenger.lng) {
                    stationsWithCoords.push({ lat: passenger.lat, lng: passenger.lng });
                }
            });

            if (stationsWithCoords.length > 0) {
                const bounds = stationsWithCoords.reduce((acc, { lat, lng }) => {
                    const latLng = new google.maps.LatLng(lat ?? 0, lng);
                    const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                    return acc.extend(coords);
                }, new google.maps.LatLngBounds());

                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();

                const neCoords: Coords = {
                    lat: ne.lat(),
                    lng: ne.lng(),
                };

                const swCoords: Coords = {
                    lat: sw.lat(),
                    lng: sw.lng(),
                };

                const rect = myRef.current?.getBoundingClientRect();

                if (rect) {
                    const fittedBounds = fitBounds(
                        { ne: neCoords, sw: swCoords },
                        { width: rect.width, height: rect.height }
                    );

                    setCenter(fittedBounds.center);
                    setZoom(fittedBounds.zoom);
                }
            }
        }
    }, [selectedLine]);

    // -- getting actual_driver ws call
    useEffect(() => {
        // console.log(4);

        setGpsResponse(undefined);
        setIsLoading(true);
        if (selectedLine && selectedFcAccount && selectedLine.driverCode) {
            getActualDriver({
                accountCode: selectedFcAccount.accountCode,
                lineCode: selectedLine.lineCode,
            })
                .then((result) => {
                    const { driverCode, accountCode, response, ...restData } = result.data;
                    if (response === '0') {
                        setActualDriver({
                            driverId: driverCode || selectedLine.driverCode,
                            accountCode: accountCode || String(selectedLine.driverCode),
                        });
                        setAvlData({ ...restData });
                    } else {
                        setIsLoading(false);

                        // tamir change text
                        setActualDriverError(t('thereIsNoLocationDataForThisLine'));
                        console.log(`Bad response getting actual driver - ${response}`);
                    }
                })
                .catch((e) => {
                    // tamir change text

                    setIsLoading(false);
                    setActualDriverError(t('thereIsNoLocationDataForThisLine'));
                    console.log(`Bad response getting actual driver - ${e}`);
                    dispatch(setErrorMessage(t(ErrorMessages.GeneralError)));
                });
        } else {
            setIsLoading(false);
        }
    }, [selectedLine, selectedFcAccount, setActualDriver, setGpsResponse, dispatch, t]);

    // todo useAnimationFrame
    useEffect(() => {
        // -- Getting map line location
        // console.log({
        //     gps_server,
        //     gps_token,
        //     actualDriver,
        //     selectedLine,
        //     selectedFcAccount,
        //     avlData,
        //     isZoom,
        //     isUsingIturan,
        //     dispatch,
        //     t,
        // });
        // console.log(3);

        const clearIntervalFunc = () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };

        if (intervalRef.current) clearInterval(intervalRef.current);

        if (!selectedLine) {
            setMarkers([]);
            setCenter(defaultCenter);
            return clearIntervalFunc;
        }

        onLineChange();

        const interval = setInterval(() => {
            onLineChange();
        }, intervalTime);

        intervalRef.current = interval;

        return clearIntervalFunc;
    }, [gps_server, gps_token, actualDriver, selectedLine, selectedFcAccount, avlData, dispatch, t]);

    // * Utils
    const buildPolyline = useCallback(() => {
        return (
            <div>
                <Polyline map={mapProps.map} maps={mapProps.maps} markers={markers} />
            </div>
        );
    }, [mapProps.map, mapProps.maps, markers]);

    const getGpsResponseMessage = React.useCallback(
        (): string => {
            if (actualDriverError) return actualDriverError;

            if (isUsingIturan && ituranStatusMessage) return ituranStatusMessage;

            if (selectedLine && !actualDriver?.driverId) return t('driverNotFound');

            if (isUsingIturan && markers.length) return getCurrLocationMsg(driverPosition, t);

            if (gpsResponse === undefined) return t('thereIsNoLocationDataForThisLine');

            if (gpsResponse === GpsResponseEnum.currenLoactionExiest)
                return getCurrLocationMsg(driverPosition, t);

            if (gpsResponse === GpsResponseEnum.dirverLocationNotExiest) return t('driverLocationUnknown');

            if (gpsResponse === GpsResponseEnum.tripEndedWithCurrentPostion)
                return markers.length
                    ? t('tripEndedWithCurrentPosition')
                    : t('noLocationWasFoundForTheCureenFilters');

            if (gpsResponse === GpsResponseEnum.driverCureentPostionNotUpdated)
                return driverPosition
                    ? `${t('driverLocation')} ${moment(driverPosition.date).format('DD/MM/yyyy HH:mm')}`
                    : t('noLocationWasFoundForTheCureenFilters');

            if (gpsResponse === GpsResponseEnum.tripEnded) return t('oldLocationOver24HDriverMassage');

            if (gpsResponse === GpsResponseEnum.tripHaventStarted) return t('tripHaventStarted');

            return '';
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            actualDriver?.driverId,
            driverPosition,
            gpsResponse,
            markers.length,
            selectedLine,
            ituranStatusMessage,
            isUsingIturan,
            actualDriverError,
            t,
        ]
    );

    useEffect(() => {
        // console.log(1);
        const gpsLocationResultMsg = getGpsResponseMessage();
        setLocationStatusMessage(gpsLocationResultMsg);
    }, [
        driverPosition,
        gpsResponse,
        markers.length,
        selectedLine,
        ituranStatusMessage,
        isUsingIturan,
        t,
        getGpsResponseMessage,
        actualDriverError,
    ]);

    /*
        If the markers array is empty, we want to remove the driver's position ( car icon ) from the map,
        and setDriverPosition(undefined) does just that.
    */
    useEffect(() => {
        // console.log(2);

        if (!markers.length) {
            setDriverPosition(undefined);
        }
    }, [markers.length]);

    return (
        <>
            {isLoading ? (
                <div style={styles.spinnerWrapper}>
                    <Spinner />
                </div>
            ) : (
                <div ref={myRef} style={{ height: '100%', width: '100%' }}>
                    <Map
                        yesIWantToUseGoogleMapApiInternals
                        center={center}
                        onDrag={onDrag}
                        zoom={zoom}
                        defaultCenter={defaultCenter}
                        onChange={onMapChange}
                        onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
                    >
                        {mapProps.mapsLoaded ? buildPolyline() : ''}
                        {markers.map((gpsData) => (
                            <MarkerGps
                                key={gpsData.id}
                                lat={gpsData.lat}
                                lng={gpsData.lng}
                                message={getToolTip(gpsData.date)}
                            />
                        ))}

                        {selectedLine &&
                            selectedLine.passengers &&
                            selectedLine.passengers.length > 0 &&
                            selectedLine.passengers.map((passenger) =>
                                passenger.lat && passenger.lng ? (
                                    <div
                                        key={passenger.passCode}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        // eslint-disable-next-line react/no-unknown-property
                                        lat={passenger.lat}
                                        // eslint-disable-next-line react/no-unknown-property
                                        lng={passenger.lng}
                                    >
                                        <LocationOnIcon htmlColor="#DE5454" />
                                    </div>
                                ) : null
                            )}

                        {driverPosition && (
                            <Style.MarkerImage
                                anchorPoint={{
                                    x: driverPosition.lat,
                                    y: driverPosition.lng,
                                }}
                                lat={driverPosition.lat}
                                lng={driverPosition.lng}
                                src={images.minibus}
                                style={{ width: '25px' }}
                                deg={driverPosition.deg}
                            />
                        )}
                    </Map>
                </div>
            )}
            {!isLoading && selectedLine && locationStatusMessage && (
                <Container maxWidth="sm" style={{ width: '80%' }}>
                    <Box
                        component="span"
                        display="block"
                        p={1}
                        m={1}
                        bgcolor="background.paper"
                        style={responseMsgBoxCSS}
                    >
                        {(() => {
                            const msg = locationStatusMessage;
                            return msg;
                        })()}
                    </Box>
                </Container>
            )}
            {selectedLine && markers.length > 0 && (
                <Style.Image alt="targe selection" src={images.target} onClick={onFocus} style={imageCSS} />
            )}
        </>
    );
};

export default LineMap;

// useEffect(() => {
//    const setBound = () => {
//       if (!mapProps.map || !mapProps.maps) return;

//       const bounds = new mapProps.maps.LatLngBounds();

//       if (!markers.length) {
//          mapProps.map.fitBounds(bounds);
//          // mapProps.map.zoom = zoom;
//          return;
//       }

//       markers.forEach((marker) => {
//          bounds.extend(new mapProps.maps.LatLng(marker.lat, marker.lng));
//       });

//       mapProps.map.fitBounds(bounds);
//       // mapProps.map.zoom = zoom;
//    };

//    // setBound();
//    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [mapProps, markers]);
