import React, { useState, useEffect, useCallback, FunctionComponent, useMemo } from 'react';
import {
    getSelectedState,
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridRowClickEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { State, SortDescriptor, CompositeFilterDescriptor, process } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { useDispatch } from 'react-redux';

import { onSelectedLineChange } from 'src/store/actions/LineAction';
import { ILine } from 'src/types/line';
import KendoGrid, { TableProp } from 'src/components/KendoGrid/KendoGrid';
import useColumns from '../hooks/useColumns';
import './linesGrid.css';
import { focusLineAction, selectAllLinesAction, selectLineAction } from 'src/store/slices/lines/linesSlice';
import { useRootAppSelector } from 'src/store/hooks';

interface Props {
    data: ILine[];
    compositeFilters: CompositeFilterDescriptor | undefined;
}

export const getMaxHeightMain = () => {
    const { height } = window.screen;
    if (height > 800) return height * 0.7;
    if (height > 700) return height * 0.65;
    if (height > 600) return height * 0.55;
};

const LinesGrid: FunctionComponent<Props> = ({ data, compositeFilters }: Props) => {
    const dispatch = useDispatch();

    const onChangeSelectedLine = (payload: ILine | undefined) => dispatch(onSelectedLineChange(payload));

    const [hoverRowLineId, setHoverRowLineId] = useState<null | number>(null);

    const { columns } = useColumns(hoverRowLineId);
    const linesSelectedIds = useRootAppSelector((state) => state.linesSlice.ui.common.linesSelectedIds);
    const lineFocusedId = useRootAppSelector((state) => state.linesSlice.ui.common.focusedLineId);

    const currentLines = useRootAppSelector((state) => state.lineReducer.lines);
    const isFormOpen = useRootAppSelector((state) => state.linesSlice.ui.form.isOpen);

    const pageSize = 25;
    const dataItemKey = 'lineCode';
    const selectedField = 'isSelected';
    const idGetter = getter(dataItemKey);

    const [take, setTake] = useState<number>(pageSize);
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'startTime', dir: 'asc' }]);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState,
                dataItemKey,
            });

            setSelectedState(newSelectedState);
            console.log('event', event);
            if (event && event.dataItem) {
                dispatch(selectLineAction({ lineCode: event.dataItem.lineCode }));
            }
        },
        [selectedState]
    );

    const dataState: State = {
        take,
        skip,
        sort,
        filter: compositeFilters,
    };

    const onRowClick = (ev: GridRowClickEvent) => {
        if (ev.dataItem && ev.dataItem.lineCode) {
            dispatch(focusLineAction({ lineCode: ev.dataItem.lineCode }));
        }
    };

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setTake(event.dataState?.take || pageSize);
            setSkip(event.dataState?.skip || 0);

            const sortArray = event.dataState?.sort || [];
            sortArray.push({ field: 'startTime', dir: 'asc' });

            setSort(sortArray);
        },
        [setTake, setSkip, setSort]
    );

    // const processedData = data.map(line => {
    //     const isSelected = linesSelectedIds.includes(line.lineCode);
    //     return { ...line, isSelected }
    // }).filter((line) => line.isActive === '1');

    const processedData = process(
        data
            .map((item) => {
                // return {...item,
                // [selectedField]: selectedState[idGetter(item)]}
                const isSelected = linesSelectedIds.includes(item.lineCode);
                return { ...item, isSelected };
            })
            .filter((line) => line.isActive === '1'),
        dataState
    );

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        console.log('hello', event);
        const isSelected: boolean = event.nativeEvent.target.checked;

        const relevantLines = event.dataItems.filter(
            (line) => !(line.creationType === '9' && line.isCreatedByStudio !== '1')
        );

        dispatch(selectAllLinesAction({ isSelected, lines: relevantLines }));
    }, []);

    useEffect(() => {
        onChangeSelectedLine(data.find((item) => selectedState[idGetter(item)]));

        return () => {
            onChangeSelectedLine(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState, data]);

    const tableProp: TableProp = {
        columns,
        className: 'line-grid k-rtl',
        style: {
            height: '100%',
            maxHeight: '76vh',
            width: '100%',
            borderRadius: '0.3em',
            borderRight: 'none',
            borderTop: 'none',
            borderBottom: 'none',
        },
        wrapperDivStyle: {
            height: '100%',
            width: '100%',
            paddingBottom: '30px',
        },
        dataItemKey,
        rowHeight: 50,
        selectable: {
            cell: false,
            enabled: true,
            mode: 'multiple',
            drag: false,
        },
        onSelectionChange,
        selectedField,
        navigatable: false,
        data: processedData,
        total: processedData.total,
        pageSize,
        skip,
        sort,
        sortable: true, // { allowUnsort: true, mode: 'multiple' },
        resizable: true,
        reorderable: true,
        scrollable: 'virtual',
        onDataStateChange,
        onHeaderSelectionChange,
        onRowClick,
        rowRender: (trElement, props) => {
            const { dataItem } = props;

            const marked = {
                backgroundColor: '#ffc880',
            };
            const trProps = {
                style: dataItem.lineCode === lineFocusedId ? marked : {},
                onMouseLeave: () => {
                    setHoverRowLineId(null);
                },
                onMouseEnter: () => {
                    setHoverRowLineId(dataItem.lineCode);
                },
            };

            return React.cloneElement(trElement, {
                ...trProps,
            } as any);
        },
    };

    return (
        <div>
            {isFormOpen ? (
                <div className="line-grid-container" style={{ cursor: 'not-allowed', height: '100%' }}>
                    <KendoGrid {...tableProp} style={{ pointerEvents: 'none', height: '100%' }} />
                </div>
            ) : (
                <div className="line-grid-container">
                    <KendoGrid {...tableProp} />
                </div>
            )}
        </div>
    );
};

export default LinesGrid;
