import styled, { css, CSSObject } from 'styled-components';

export const Root = styled.div<{ style?: CSSObject }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 20px;
    ${({ style = {} }) => style && css(style)}
`;

export const Card = styled.div`
    border-radius: 20px;
    flex: 1;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    padding: 15px 24px 0 24px;
    flex-wrap: wrap;
    gap: 20px;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
`;

export const ReloadBtn = styled.p<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    color: #494949;
    padding-bottom: 15px;
    :hover {
        cursor: pointer;
    }
    :active {
        transform: translateY(2px);
    }
    ${(props) =>
        props.disabled &&
        css`
            cursor: default;
            opacity: 0.3;
            pointer-events: none;
            transition: opacity 0.3 linear;
        `}
`;
