import React, { useEffect } from 'react';
import SearchInput from 'src/components/SearchInput/SearchInput';
import useCommons from 'src/hooks/common/useCommons';
import { TabsTypes } from './common';
import { StationsAndTimesPanelStyled as Styled, StyledCourseTypeTabs as styles } from './styles';
import { coursesBuildingSelectors } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import Spinner from 'src/components/Spinner/Spinner';
import NoRecordsText from 'src/components/NoRecordsText';
import ErrorFetchingDataText from 'src/components/ErrorFetchingDataText';
import ExtraCssBorderHider from './ExtraCssBorderHider';

export interface ICourseStation {
    stationId: string;
    city: string;
    street: string;
    houseNum: string;
    timeFromPrev: number | null;
    isManualTimeFromPrev: boolean | null | undefined;
    lat: number;
    lng: number;
    type: string;
    stationRemark: string;
}

const StationDataItem = ({
    station,
    isFirst,
    isLast,
}: {
    station: ICourseStation;
    isFirst: boolean;
    isLast: boolean;
}) => {
    const hasTimeFromPrev = station.timeFromPrev !== null;

    return (
        <styles.StationDataItemContainer isFirst={isFirst}>
            {isFirst || isLast ? (
                <ExtraCssBorderHider hasTimeFromPrev={hasTimeFromPrev} type={isLast ? 'bottom' : 'top'} />
            ) : null}
            <div
                style={{
                    marginRight: '5px',
                    display: 'flex',
                    gap: '5px',
                    flexDirection: 'column',
                }}
            >
                {hasTimeFromPrev ? (
                    <Styled.TravelTime>{station.timeFromPrev} דקות נסיעה</Styled.TravelTime>
                ) : null}
                <div style={{ position: 'relative' }}>
                    <Styled.City style={{ fontWeight: 'bold' }}>
                        {station.city}
                        {station.street || station.houseNum ? ', ' : ' '}
                    </Styled.City>
                    <Styled.ExtraData>
                        {station.street} {station.houseNum}
                    </Styled.ExtraData>
                    <Styled.Circle className="Circle" bordered={isLast || isFirst ? true : false} />
                </div>
            </div>
        </styles.StationDataItemContainer>
    );
};

const StationsLst: React.FC<{ allStations: ICourseStation[]; filtered: ICourseStation[] }> = ({
    allStations,
    filtered,
}): JSX.Element => {
    return filtered.length ? (
        <>
            {filtered.map((station, index) => (
                <StationDataItem
                    isFirst={index === 0}
                    isLast={index === allStations.length - 1}
                    station={station}
                    key={station.stationId}
                />
            ))}
        </>
    ) : (
        <NoRecordsText withFiltered={false} />
    );
};

const filter = (stations: ICourseStation[], searchTerm: string) => {
    if (!searchTerm) return stations;

    return stations.filter((station) => {
        return (
            station.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
            station.street.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
};

const StationsAndTimesPanel = ({ isHidden }: { isHidden: boolean }) => {
    const { t, useRootAppSelector } = useCommons();

    const { status, data: allStations } = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseStations
    );

    const [filtered, setFiltered] = React.useState<ICourseStation[]>([]);

    useEffect(() => {
        setFiltered(allStations);
    }, [allStations]);

    return (
        <div role="tabpanel" hidden={isHidden} id={`simple-tabpanel-${TabsTypes.StationsAndTimes}`}>
            <Styled.FiltersBar>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'right', alignSelf: 'center' }}>
                        <p style={{ fontSize: '16px' }}>
                            {t('stationsCount')}: <strong>{filtered.length}</strong>
                        </p>
                    </div>
                    <SearchInput
                        onChange={(e) => {
                            setFiltered(filter(allStations, e.target.value));
                        }}
                        style={{ flex: 1 }}
                    />
                </div>
            </Styled.FiltersBar>
            <div style={{ paddingRight: '10px' }} className="StationsContainer">
                {status === 'SUCCESS' ? <StationsLst filtered={filtered} allStations={allStations} /> : null}
                <Spinner show={status === 'LOADING'} />
                <ErrorFetchingDataText show={status === 'FAILED'} />
            </div>
        </div>
    );
};

export default StationsAndTimesPanel;
