import { CSSProperties } from 'react';
import { colors } from 'src/style/themes/defaultTheme';

export const styleIcon: CSSProperties = {
    fill: '#2196F3',
    border: '1px solid #2196F3',
    borderRadius: '4px',
    color: '#2196F3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
};
export const addBtnStyle: CSSProperties = {
    ...styleIcon,
    backgroundColor: colors.blue,
    color: 'white',
};
export const disabledIcon: CSSProperties = {
    fill: 'rgba(0, 0, 0, 0.26)',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
};
