import { useSelector } from 'react-redux';
import { getServerOptimizationWaypoints } from '../api/optimizationApi';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    resetSelectedWaypointsIdsAction,
    selectAllWaypointAction,
    selectWaypointAction,
    setGetWaypointsFromServerStatusAction,
    setTimeDeltaAction,
    setWaypointStockAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { WsResponse, WsResponseWithDelta } from 'src/api/types';
import { IOptimizationWaypoint } from '../api/types';
import { buildWaypointName, updateWaypoints } from '../helpers';
import useCommons from 'src/hooks/common/useCommons';
import moment from 'moment';
import { useDispatchAlert } from 'src/hooks/useAlert';

export const useGetOptimizationWaypoints = () => {
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));

    const {
        ui: { clientCode },
        data: {
            getWaypointsFromServer: { timeDelta, data: waypointStock },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();

    const handleGetOptimizationWaypointsResponse = (
        res: WsResponseWithDelta<IOptimizationWaypoint[]>,
        isFromTarget?: boolean
    ) => {
        const responseNum = Number(res.response);

        if (responseNum === 0) {
            const parsed: IOptimizationWaypoint[] = [];

            const waypointsChanged = res.data;

            dispatch(resetSelectedWaypointsIdsAction());

            const updatedWaypoints = updateWaypoints(waypointStock, waypointsChanged);

            updatedWaypoints.forEach((waypoint) => {
                if (waypoint.isActive) {
                    parsed.push({
                        ...waypoint,
                        waypointName: buildWaypointName(waypoint),
                        isSelected: waypoint.isTarget ? true : waypoint.isSelected,
                        isRecognized: waypoint.lat && waypoint.lng ? true : false,
                    });

                    if (waypoint.isSelected || waypoint.isTarget) {
                        dispatch(selectWaypointAction({ waypointId: waypoint.waypointId }));
                    }
                }
            });

            // dispatch(selectAllWaypointAction({ isSelected: false }))
            dispatch(setGetWaypointsFromServerStatusAction({ status: 'SUCCESS' }));
            dispatch(setWaypointStockAction({ waypointStock: parsed }));
            dispatch(setTimeDeltaAction({ timeDelta: res.lastRetrievalTime }));
        } else if (res.response === '1') {
            dispatchAlert('error', t('errorReceived'));
            dispatch(setGetWaypointsFromServerStatusAction({ status: 'FAILURE' }));
        } else {
            dispatchAlert('error', t('errorReceived'));
            dispatch(setGetWaypointsFromServerStatusAction({ status: 'FAILURE' }));
        }
    };

    const getOptimizationWaypoints = async (isFromTarget?: boolean) => {
        try {
            dispatch(setGetWaypointsFromServerStatusAction({ status: 'LOADING' }));

            if (selectedFcAccount && selectedFcAccount.dbUrl && clientCode) {
                const res = await getServerOptimizationWaypoints({
                    dbUrl: selectedFcAccount?.dbUrl,
                    requestParams: {
                        clientCode,
                        isGetNotActive: 1,
                        lastAction: timeDelta,
                        token,
                    },
                });

                if (res?.data) {
                    handleGetOptimizationWaypointsResponse(res.data, isFromTarget);
                }
            }
        } catch (error) {
            dispatchAlert('error', t('errorReceived'));
            dispatch(setGetWaypointsFromServerStatusAction({ status: 'FAILURE' }));
        }
    };

    return {
        getOptimizationWaypoints,
    };
};
