import { breakPoints } from 'src/style/themes/defaultTheme';
import styled from 'styled-components';

export const StatusColorIcon = styled.div`
    border-radius: 15px;
    background-color: ${(props) => props.color};
    width: 59px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledPropertyTitle = styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    color: ${({ theme }) => theme.msColors.veryLightTextColor};
    margin: 0;
    padding: 0 0 0 5px;
`;
export const StyledLineBox = styled.div`
    display: flex;
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    background-color: ${({ theme }) => theme.colors.veryLightGray};
    border: 1px solid ${({ theme }) => theme.colors.mediumLightGray};
    border-radius: 4px;
    width: 100%;
    min-height: 67px;
    margin-bottom: 15px;
    // *MAIN_CONTAINER:
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
`;
export const StyledDriverInfoBox = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr;
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-items: start;
    @media ${breakPoints.laptopM} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;

export const StyledDailyLinesBox = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
`;

export const StyledBoxWithTextOverflow = styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Rubik};
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
