import React, { useEffect } from 'react';
import { onSelectReportAction } from 'src/store/slices/reports/reportsSlice';
import {
    authorizedReportsSelector,
    clientIdSelector,
    selectedReportNameSelector,
} from 'src/store/selectores/reportsSelectors';
import { ReportNames } from 'src/store/slices/reports/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { isOfTypeReportName, isReportAuthorized } from '../../../../../utils';

/**
 * Uses the last report selected by the user after the page has been closed.
 * @returns None
 */
const chosenReportValueOnLocalStorage = 'chosenReport';

export const useLastReportAfterPageClose = (): void => {
    const dispatch = useAppDispatch();
    const selectedReportName: ReportNames = useAppSelector((state) => selectedReportNameSelector(state));
    const authorizedReports: ReportNames[] = useAppSelector((state: any) => authorizedReportsSelector(state));
    // const clientId = useAppSelector((state: any) =>
    //    clientIdSelector(state),
    // );
    const reportOnLocalStorage: any = localStorage.getItem(chosenReportValueOnLocalStorage);

    useEffect(() => {
        if (!isReportAuthorized(reportOnLocalStorage, authorizedReports)) {
            if (authorizedReports[0]) {
                dispatch(
                    onSelectReportAction({
                        reportName: authorizedReports[0],
                    })
                );
            }
            return;
        }
        if (!reportOnLocalStorage) {
            localStorage.setItem(chosenReportValueOnLocalStorage, selectedReportName);
        } else if (
            isOfTypeReportName(reportOnLocalStorage)
            // selectedReportName !== reportOnLocalStorage
        ) {
            dispatch(
                onSelectReportAction({
                    reportName: reportOnLocalStorage,
                })
            );
        }
    }, [
        // clientId,
        authorizedReports,
        dispatch,
        reportOnLocalStorage,
        selectedReportName,
    ]);
};
