import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userUUIDSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';

import { useTranslation } from 'react-i18next';
import BtnLink from 'src/components/commons/Buttons/BtnLink/BtnLink';
import { setLoginType as setLoginTypeAction } from 'src/store/actions/loginAction';
import { getLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';
import { SingupType } from 'src/types/login';

import { routesEndpoints } from '../../../../routes/routes_endpoints';
import { setLoginType } from '../../../../api/api';
import Password from '../../../../components/Password/Password';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';

import { SpaceAround, HeaderText, Header } from './InitPassword.style';

const InitPassword: FunctionComponent<{}> = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userUUID = useSelector((state: IRootReducer) => userUUIDSelector(state));
    const onChangeLoginType = (payload: any) => dispatch(setLoginTypeAction(payload));

    const onSubmit = async (userName: string, password: string) => {
        const deviceToken = getLocalStorageValue(StorageKeys.DeviceToken);
        if (!userUUID || !deviceToken) return;

        const fcResponse = await setLoginType({
            deviceToken,
            userUUID,
            userName,
            userPass: password,
            autologin: SingupType.User,
        });

        if (fcResponse.response === '0') {
            onChangeLoginType(SingupType.User);
            history.push(routesEndpoints.HOME);
        }
    };

    const onAlterSinupOptions = () => {
        history.goBack();
    };

    return (
        <Container>
            {/* <Title>{t('signup')}</Title> */}
            <Card>
                <Header>
                    <HeaderText>{t('setUserNameAndPasswordSubTitle')}</HeaderText>
                </Header>
                <SpaceAround>
                    <Password
                        onSubmit={onSubmit}
                        userNamelabel={t('userName')}
                        passwordLable={t('password')}
                        buttonLable={t('ok')}
                    />
                </SpaceAround>
            </Card>
            <BtnLink btnText={t('anotherSignup')} onClick={onAlterSinupOptions} />
        </Container>
    );
};

export default InitPassword;
