import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAppSelector } from '../store/hooks';
import { uiSelector } from '../store/selectores/reportsSelectors';

const HTMLTableToExcel = ({ table, filename }) => {
    const { t } = useTranslation();
    const selectedReportName = useAppSelector((state) => uiSelector(state).main.selectedReport);

    return (
        <ReactHTMLTableToExcel
            id="test-table"
            className="download-table-xls-button"
            table={table || 'kendo-table'}
            filename={filename || t(`${selectedReportName}Report`)}
            sheet="ד''וח"
            buttonText={`${t('exportTo')} EXCEL`}
        />
    );
};

HTMLTableToExcel.propTypes = {
    table: PropTypes.string,
    filename: PropTypes.string,
};

export default HTMLTableToExcel;
