import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { storedCoursesFormDisplayChangeAction } from 'src/store/slices/lines/linesSlice';
import { asTSR } from 'src/utilis/utilis';
import styled, { css, CSSObject } from 'styled-components';

const StyledHeader = styled.div`
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: space-between;
`;

const FormHeader: React.FunctionComponent<{ text?: string }> = ({ text }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.isOnEdit);

    const addOrEdit = isOnEdit ? t('editingLine') : t('addingLine');

    return (
        <StyledHeader>
            {text ? text : addOrEdit}
            <Button
                size="small"
                sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#333333',
                    textDecoration: 'underline',
                    fontFamily: 'Rubik',
                    ':hover': {
                        backgroundColor: '#bbbbbb15',
                        textDecoration: 'underline',
                    },
                }}
                onClick={() => {
                    dispatch(storedCoursesFormDisplayChangeAction({ isOpen: true }));
                }}
            >
                {t('courseDataImport')}
            </Button>
        </StyledHeader>
    );
};

export default FormHeader;
