import React, { useState } from 'react';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import useCommons from 'src/hooks/common/useCommons';
import { DeleteBtn, EditBtn, RestoreBtn } from 'src/components/buttons/ActionsBtns/ActionBtns';
import styles from './CoursesTable.styles';
import {
    courseBuildingFormSelector,
    resetCourseFormAction,
    selectedCourseIdSelector,
    setSelectedCourseIdAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import useRowActions from '../hooks/useRowActions';

export const FLEX = {
    COURSE_DESCRIPTION: 4,
    DEPARTMENT: 2,
} as const;

const CourseTableRow: React.FC<{ course: WsCourseDataItem }> = ({ course }) => {
    const { dispatch, useRootAppSelector } = useCommons();

    const formSlice = useRootAppSelector(courseBuildingFormSelector);

    const selectedCourseId = useRootAppSelector(selectedCourseIdSelector);

    const actions = useRowActions();

    const [hovered, setHovered] = useState<null | string>(null);

    const onRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, rowCourseCode: string) => {
        if (formSlice.courseCode === rowCourseCode) {
            return;
        }

        dispatch(setSelectedCourseIdAction(rowCourseCode));
        dispatch(resetCourseFormAction({}));
    };

    return (
        <styles.BodyRow
            onMouseLeave={() => setHovered(null)}
            onMouseEnter={() => setHovered(course.courseCode)}
            onClick={(e) => onRowClick(e, course.courseCode)}
            id={course.courseCode}
            className="BodyRow"
            isSelected={course.courseCode === selectedCourseId}
            isHovered={course.courseCode === hovered}
        >
            <styles.BodyCell>
                <styles.BodyCellText>{course.courseId}</styles.BodyCellText>
                {course.courseDirection ? (
                    <span style={{ fontSize: '14px' }}>{`-${course.courseDirection}`}</span>
                ) : null}
            </styles.BodyCell>
            <styles.BodyCell style={{ fontWeight: 'bold' }} flex={FLEX.COURSE_DESCRIPTION}>
                <styles.BodyCellText>{course.courseDescription}</styles.BodyCellText>
            </styles.BodyCell>
            <styles.BodyCell flex={FLEX.DEPARTMENT}>
                <styles.BodyCellText>{course.departmentName}</styles.BodyCellText>
            </styles.BodyCell>
            <styles.BodyCell style={{ justifyContent: 'space-between' }}>
                <styles.BodyCellText>{course.lineTypeName}</styles.BodyCellText>
                {hovered === course.courseCode ? (
                    <div style={styles.actionBtnsContainerStyle}>
                        <EditBtn
                            iconBtnStyle={styles.actionBtnStyle}
                            onClick={(e) => {
                                e.stopPropagation();
                                actions.onEdit(course.courseCode);
                            }}
                        />
                        {course.isActive === '1' ? (
                            <DeleteBtn
                                iconBtnStyle={styles.actionBtnStyle}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    actions.onDelete(course.courseCode);
                                }}
                                disabled={formSlice.isOpen}
                            />
                        ) : null}
                        {course.isActive === '0' ? (
                            <RestoreBtn
                                iconBtnStyle={{ ...styles.actionBtnStyle, color: 'black' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    actions.onRestore(course);
                                }}
                                disabled={formSlice.isOpen}
                            />
                        ) : null}
                    </div>
                ) : null}
            </styles.BodyCell>
        </styles.BodyRow>
    );
};

export default CourseTableRow;
