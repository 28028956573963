import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootAppSelector } from 'src/store/hooks';
import { courseBuildingFormSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { optimizationsRootSelector } from 'src/store/slices/optimizations/optimizationsSlice';
import { IOptimizationWaypoint } from '../api/types';

const useOptimizationFilters = () => {
    const [filterText, setFilterText] = useState('');
    const [isShowUnrecognizedWaypoints, setIsShowUnrecognizedWaypoints] = useState(false);

    const waypointsStock = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );
    const searchTerm = filterText.toLowerCase();

    const filteredWaypoints = useMemo(
        () =>
            waypointsStock.filter((waypoint) => {
                const matchedByFilterText =
                    (waypoint.waypointName && waypoint.waypointName.toLowerCase().includes(searchTerm)) ||
                    (waypoint.remarks && waypoint.remarks.toLowerCase().includes(searchTerm)) ||
                    (waypoint.passId && waypoint.passId.toLowerCase().includes(searchTerm)) ||
                    (waypoint.firstName && waypoint.firstName.toLowerCase().includes(searchTerm)) ||
                    (waypoint.lastName && waypoint.lastName.toLowerCase().includes(searchTerm));

                const matchedByFilterRecognize = waypoint.isRecognized === !isShowUnrecognizedWaypoints;

                if (isShowUnrecognizedWaypoints) {
                    return matchedByFilterRecognize && matchedByFilterText;
                }

                return matchedByFilterText && !waypoint.isTarget;
            }),
        [isShowUnrecognizedWaypoints, waypointsStock, searchTerm]
    );

    return { filteredWaypoints, setFilterText, setIsShowUnrecognizedWaypoints, isShowUnrecognizedWaypoints };
};

export default useOptimizationFilters;
