import React, { FC, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

import { setFcAccount } from 'src/store/actions/loginAction';
import {
    onAddPassengerBtnClicked,
    onDeletePassengers,
    onRetrivePassengersSuccess,
    onSetClientId,
    onSetModefiedPassenger,
} from 'src/store/actions/passengerAction';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { routesEndpoints } from 'src/routes/routes_endpoints';

import { loginSelector } from 'src/store/selectores/loginSelectors';
import { passengerSelector, passengersUISelector } from 'src/store/selectores/passengersSelector';

import { IRootReducer } from 'src/store/reducers';
import { StrictDropdownItem, IClient, IItem } from 'src/types/line';
import { IDeletePassengerSagaRequest, IPassenger } from 'src/api/passengerMangerApi/types';
import { IProps as IDropDown } from 'src/components/DropDown/DropDown';
import { IAccount } from 'src/types/login';

import { getPassengers } from 'src/api/passengerMangerApi/passengerMangerApi';
import { getClients } from 'src/api/api';

import styles from 'src/components/StyledComponents/StyledComponents.style';
import MyWayTitle from 'src/components/MyWayTitle/MyWayTitle';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import Input from 'src/components/commons/Input/Input';
import ComboBox from 'src/components/ComboBox/ComboBox';
import { DialogContentText, Grid, IconButton, InputAdornment } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';

import { buidAdress, isAuthorized } from 'src/utilis/utilis';

import defaultTheme from 'src/style/themes';
import Alert from 'src/components/commons/Alert/Alert';
import useDisableDelay from 'src/hooks/useDelayV2';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { onErrorAction, uiSelector } from 'src/store/slices/passengersManager/passengersManagerSlice';
import { linesContainers } from 'src/screens/Main/components/Lines/styles';
import { getErrorCodeByResponse } from 'src/store/sagas/utils/sagasUtils';
import useLastSelectedClient from 'src/hooks/useLastSelectedClient';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import useEnvAuth from 'src/hooks/useEnvAuth';
import DropDown, { IProps as IDropDownProp } from '../../../../components/DropDown/DropDown';
import useFilters, { DropDownState } from './hooks/useFilters';
import PassengersGrid from './PassengersGrid/PassengersGrid';
import { styleIcon, disabledIcon, addBtnStyle } from './styles/css-styles';
import { useRestore } from './hooks/useRestore';
import { initialPassengerData } from '../../utils';
import {
    SDivider,
    StyledBtnsContainer,
    StyledFiltersContainer,
    StyledInputsContainer,
} from './styles/styledComponents';
import { RestoreDialogContent, RestoreDialogTitle } from './RestoreDialog/RestoreDialogComponents';
import TrashIcon from './PassengersGrid/TrashIcon';
import { useValidateClientList } from 'src/hooks/useClientFiltering';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPassengerComponents {}

const Passengers: FC<IPassengerComponents> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [providers, setProviders] = useState<IItem[]>([]);

    // * Client filtering
    const [providerClients, setProviderClients] = useState<StrictDropdownItem[]>([]);

    const [lastSelectedClient, setLastSelectedClient] = useLastSelectedClient(providerClients);

    const [clinetFilter, setClinetFilter] = useState<DropDownState>({
        options: [],
        value: lastSelectedClient?.value || '',
    });

    const getClientNameByValue = useCallback(() => {
        return (
            clinetFilter.options.find((client) => String(client.value) === String(clinetFilter.value))
                ?.name || ''
        );
    }, [clinetFilter.options, clinetFilter.value]);

    const selectedClientValue = lastSelectedClient?.value;
    useEffect(() => {
        // console.log(534);
        if (clinetFilter.value && selectedClientValue !== String(clinetFilter?.value)) {
            setLastSelectedClient({
                value: String(clinetFilter.value),
                name: getClientNameByValue(),
            });
        }
    }, [
        clinetFilter.options,
        clinetFilter.value,
        getClientNameByValue,
        selectedClientValue,
        setLastSelectedClient,
    ]);

    const [filters, setFilters] = useState<CompositeFilterDescriptor>();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
        isDeleteAllDialog?: boolean;
        isDeleteDialog?: boolean;
    }>({});

    const [deletePassanger, setDeletePassanger] = useState<IPassenger | undefined>();

    const {
        fcAccounts,
        selectedFcAccount,
        token,
        authorizationToken = {},
    } = useSelector((state: IRootReducer) => loginSelector(state));

    const passengers = useSelector((state: IRootReducer) => passengerSelector(state));
    // passengers.forEach((p) => {
    //    if (p.address.length) {
    //       p.address.forEach((address) => {
    //          if (!address.addressCode) {
    //             console.log(p);
    //          } else {
    //             // console.log(address);
    //          }
    //       });
    //    }
    // });
    // -- alert handling
    const tkDispatch = useAppDispatch();
    const error = useAppSelector((state: any) => uiSelector(state).common.error);
    const [alertMsg, setAlertMsg] = useState('');
    const closeAlert = useCallback(() => {
        setAlertMsg('');
        tkDispatch(onErrorAction(null));
    }, [tkDispatch]);

    useEffect(() => {
        if (error.msg) {
            setAlertMsg(error.msg);
        } else {
            closeAlert();
        }
    }, [closeAlert, error.msg]);
    // --

    const { isRestoreDialogOpen, onCloseRestoreDialog, handleRestore, onRestoreConfirmed } = useRestore({
        setAlertMsg,
    });

    const {
        globalFilterProp,
        cityFilterProp,
        departmentFilterProp,
        passengerTypeFilterProp,
        emplyeeeTypeFilterProp,
        restFilters,
    } = useFilters({
        setFilters,
        clientId: clinetFilter.value,
    });

    const onSetSelectedFcAccount = (payload: IAccount) => dispatch(setFcAccount(payload));

    const onDeletePassengersDispatch = (payload: IDeletePassengerSagaRequest) =>
        dispatch(onDeletePassengers(payload));

    const onSetSelectedClient = (payload: string | number | undefined | null) =>
        dispatch(onSetClientId(payload));

    const loadPassengers = useCallback(async () => {
        if (selectedFcAccount) {
            if (!clinetFilter.value || !selectedFcAccount?.dbUrl) {
                return;
            }
            const apiResponse = await getPassengers({
                dbUrl: selectedFcAccount?.dbUrl || '',
                proxyUrl: selectedFcAccount?.proxyUrl || '',
                token,
                clientCode: clinetFilter.value,
            });

            const { passList = [], response } = apiResponse.data;
            if (response !== '0') {
                dispatch(
                    onErrorAction({
                        msg: `Invalid WebService Response ${getErrorCodeByResponse(response)}`,
                    })
                );
                return;
            }
            const mappedPassengers = passList.map((x) => ({
                ...x,
                mainAdress: buidAdress(x.address.find((y) => y.isDefault === '1')),
                remarks: x.address.find((y) => y.isDefault === '1')?.remarks,
            }));

            dispatch(onRetrivePassengersSuccess(mappedPassengers));
        }
    }, [clinetFilter.value, dispatch, selectedFcAccount, token]);

    const [tempDisabled, setTempDisabled] = useDisableDelay(2000);
    const onRefresh = () => {
        setTempDisabled(true);
        if (selectedFcAccount) loadPassengers();
        // if (selectedFcAccount) onSetSelectedFcAccount({ ...selectedFcAccount });
    };

    const onDelete = React.useCallback(
        (dataItem: IPassenger) => {
            setDeletePassanger(dataItem);
            setIsDeleteDialogOpen({
                isDeleteAllDialog: false,
                isDeleteDialog: true,
            });
        },
        [setDeletePassanger, setIsDeleteDialogOpen]
    );

    const onEdit = React.useCallback(
        (dataItem: IPassenger) => {
            dispatch(onSetModefiedPassenger(dataItem));
        },
        [dispatch]
    );

    const onClickDeleteAllSelected = React.useCallback(() => {
        const deletedPassanger = passengers.filter((x) => x.isSelected);

        if (!deletedPassanger.length) return;
        setDeletePassanger(deletedPassanger[0]);

        setIsDeleteDialogOpen({
            isDeleteAllDialog: true,
            isDeleteDialog: false,
        });
    }, [passengers]);

    const onAddPassenger = React.useCallback(() => {
        const newPassenger: IPassenger = initialPassengerData;
        dispatch(onAddPassengerBtnClicked(null));
        dispatch(onSetModefiedPassenger(newPassenger));
    }, [dispatch]);

    const onDeleteAllSelectedConfirmed = () => {
        const deletedPassanger = passengers.filter((x) => x.isSelected).map((x) => x.passCode);

        if (!selectedFcAccount) return;

        const { dbUrl = '', proxyUrl } = selectedFcAccount;

        onDeletePassengersDispatch({
            token,
            dbUrl,
            proxyUrl,
            passengers: deletedPassanger,
        });

        setDeletePassanger(undefined);
        setIsDeleteDialogOpen({
            isDeleteAllDialog: false,
            isDeleteDialog: false,
        });
    };

    const onDeleteConfirmed = () => {
        if (!deletePassanger) return;
        const deletedPassanger = [deletePassanger.passCode];

        if (!selectedFcAccount) return;

        const { dbUrl = '', proxyUrl } = selectedFcAccount;

        onDeletePassengersDispatch({
            token,
            dbUrl,
            proxyUrl,
            passengers: deletedPassanger,
        });

        setDeletePassanger(undefined);
        setIsDeleteDialogOpen({
            isDeleteAllDialog: false,
            isDeleteDialog: false,
        });
    };

    const onCloseDeleteDialog = () => {
        setDeletePassanger(undefined);
        setIsDeleteDialogOpen({
            isDeleteAllDialog: false,
            isDeleteDialog: false,
        });
    };

    useEffect(() => {
        const res: IItem[] = fcAccounts.map(({ accountCode, accountName }: IAccount) => ({
            value: accountCode,
            name: accountName,
        }));

        setProviders(res);
    }, [fcAccounts]);

    const validateClientsList = useValidateClientList();

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};

        if (selectedFcAccount) {
            getClients({
                proxyUrl,
                token,
                dbUrl: selectedFcAccount.dbUrl,
            }).then((res) => {
                const { clients = [] } = res.data;

                const clientsList = validateClientsList(clients);

                const authorizedClients = clientsList.filter((x) =>
                    isAuthorized(
                        routesEndpoints.MANAGE_PASSENGERS.SCREEN_ID,
                        +x.accountCode,
                        authorizationToken
                    )
                );

                setProviderClients(
                    clientsList.map((client) => {
                        return {
                            value: String(client.accountCode),
                            name: client.clientName,
                        };
                    })
                );

                const isLastSelectedClientAutherized = () => {
                    if (!lastSelectedClient?.value) return false;

                    return isAuthorized(
                        routesEndpoints.MANAGE_PASSENGERS.SCREEN_ID,
                        +lastSelectedClient.value,
                        authorizationToken
                    );
                };

                setClinetFilter((preState: any) => ({
                    // eslint-disable-next-line no-nested-ternary
                    value: isLastSelectedClientAutherized()
                        ? lastSelectedClient?.value
                        : authorizedClients.some((x) => x.accountCode === preState.value)
                        ? preState.value || ''
                        : authorizedClients.find((x) => x)?.accountCode,
                    options: authorizedClients.map((c) => ({
                        value: c.accountCode,
                        name: c.clientName,
                    })),
                }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFcAccount, token, dispatch, authorizationToken]);

    useEffect(() => {
        dispatch(onSetModefiedPassenger(undefined));
    }, [clinetFilter.value, dispatch, selectedFcAccount]);

    useEffect(() => {
        onSetSelectedClient(clinetFilter.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinetFilter.value]);

    useEffect(() => {
        if (selectedFcAccount) loadPassengers();
    }, [selectedFcAccount, token, clinetFilter, dispatch, loadPassengers]);

    const clinetFilterProp: IDropDown = useMemo(
        () => ({
            formControlProp: {
                variant: 'outlined',
                style: { width: '208px' },
                size: 'small',
            },
            autoWidth: false,
            multiple: false,
            labalName: t('clinet'),
            label: t('clinet'),
            menueItem: clinetFilter.options,
            native: false,
            value: clinetFilter.value,
            onChange: (
                event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: number | string | unknown;
                }>
            ) => {
                const { value } = event.target;

                if (typeof value === 'number' || typeof value === 'string') {
                    setClinetFilter((preState) => ({ ...preState, value }));
                }
            },
        }),
        [clinetFilter.options, clinetFilter.value, t]
    );

    const prodvierDrpodown: IDropDownProp = {
        formControlProp: {
            variant: 'outlined',
            style: { width: '208px' },
            size: 'small',
        },
        autoWidth: false,
        multiple: false,
        labalName: t('provider'),
        label: t('provider'),
        menueItem: providers,
        native: false,
        value: selectedFcAccount?.accountCode,
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => {
            const { value } = event.target;
            const fcAccountTarget = fcAccounts.find((f) => f.accountCode === value);
            if (fcAccountTarget) {
                onSetSelectedFcAccount(fcAccountTarget);
            }
        },
    };
    const { selectedPassengers, activeSelectedPassengers } = useSelector((state: IRootReducer) =>
        passengersUISelector(state)
    );

    const isDeleteDisabled = (() => {
        if (selectedPassengers.length === 0 || selectedPassengers.length !== activeSelectedPassengers.length)
            return true;
        return false;
    })();

    const DeleteAllDialogContent = () => {
        return (
            <DialogContentText>
                <span>{t('isApproveDelete')} </span>{' '}
                {(passengers.filter((x) => x.isSelected).length === 1 && (
                    <span>{`${t('passenger')}: ${deletePassanger?.fullName}`}</span>
                )) || <span>{`${selectedPassengers.length} ${t('passengers')}`}</span>}
            </DialogContentText>
        );
    };

    const DeleteDialogContent = () => {
        return (
            <DialogContentText>
                <span>{t('isApproveDelete')} </span>{' '}
                <span>{`${t('passenger')}: ${deletePassanger?.fullName}`}</span>
            </DialogContentText>
        );
    };

    const DeleteDialogTitle = () => {
        return <span> {t('approveDelete')} </span>;
    };

    const envAuth = useEnvAuth();

    return (
        <>
            <ConfirmationDialog
                isDialogOpen={isDeleteDialogOpen.isDeleteAllDialog || false}
                onCloseDialog={onCloseDeleteDialog}
                onConfirmClick={onDeleteAllSelectedConfirmed}
                DialogContent={DeleteAllDialogContent()}
                DialogTitle={DeleteDialogTitle()}
            />
            <ConfirmationDialog
                isDialogOpen={isDeleteDialogOpen.isDeleteDialog || false}
                onCloseDialog={onCloseDeleteDialog}
                onConfirmClick={onDeleteConfirmed}
                DialogContent={DeleteDialogContent()}
                DialogTitle={DeleteDialogTitle()}
            />
            <ConfirmationDialog
                isDialogOpen={isRestoreDialogOpen}
                onCloseDialog={onCloseRestoreDialog}
                onConfirmClick={onRestoreConfirmed}
                DialogContent={RestoreDialogContent(t)}
                DialogTitle={RestoreDialogTitle(t)}
            />

            <styles.Container style={{ paddingBottom: '0px' }}>
                <linesContainers.HeaderContainer withWarningColors={envAuth.isUsingProdAccountOnDev}>
                    <MyWayTitle />
                    <linesContainers.HeaderDropdownContainer>
                        <DropDown {...clinetFilterProp} />
                        <DropDown {...prodvierDrpodown} />
                        <RefreshButton onClick={onRefresh} disabled={tempDisabled} />
                    </linesContainers.HeaderDropdownContainer>
                </linesContainers.HeaderContainer>
                <styles.Hr />
                <StyledFiltersContainer style={{ overflow: 'inherit' }}>
                    <StyledBtnsContainer>
                        <styles.FlexContainerWithSpace>
                            <MuiTooltipV3 title={t('add')}>
                                <IconButton
                                    size="small"
                                    onClick={onAddPassenger}
                                    disabled={!clinetFilter.value}
                                >
                                    <div style={(clinetFilter.value && addBtnStyle) || disabledIcon}>
                                        <AddIcon fontSize="medium" />
                                    </div>
                                </IconButton>
                            </MuiTooltipV3>
                            <MuiTooltipV3 title={t('delete')}>
                                <IconButton
                                    size="small"
                                    disabled={isDeleteDisabled}
                                    onClick={onClickDeleteAllSelected}
                                >
                                    <div style={(!isDeleteDisabled && styleIcon) || disabledIcon}>
                                        <TrashIcon
                                            color={isDeleteDisabled ? 'rgba(0, 0, 0, 0.26)' : '#2196F3'}
                                        />
                                    </div>
                                </IconButton>
                            </MuiTooltipV3>
                            <SDivider />
                        </styles.FlexContainerWithSpace>
                    </StyledBtnsContainer>
                    <StyledInputsContainer>
                        <styles.FilterHeaderContianer>
                            <styles.FilterText>{`${t('passengersList')} (${
                                filters ? filterBy(passengers, filters).length : passengers.length
                            })`}</styles.FilterText>
                            <styles.Btn size="small" disabled={!filters} onClick={restFilters}>
                                <styles.FilterText
                                    style={{
                                        margin: '0px',
                                        color: defaultTheme.colors.gray,
                                        fontSize: '15px',
                                        fontWeight: 400,
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {t('clearFilter')}
                                </styles.FilterText>
                            </styles.Btn>
                        </styles.FilterHeaderContianer>
                        <styles.FilterContainer>
                            <Input
                                className="filtersSearchInput"
                                {...globalFilterProp}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <ComboBox className="filtersCitiesInput" {...cityFilterProp} />
                            <DropDown className="filtersDepartmentInput" {...departmentFilterProp} />
                            <DropDown className="filtersPassengerTypeInput" {...passengerTypeFilterProp} />
                            <DropDown className="filtersEmployeeInput" {...emplyeeeTypeFilterProp} />
                        </styles.FilterContainer>
                    </StyledInputsContainer>
                </StyledFiltersContainer>

                <styles.TableContainer>
                    <PassengersGrid
                        data={passengers}
                        compositeFilters={filters}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onRestore={handleRestore}
                    />
                </styles.TableContainer>
                <Alert
                    // eslint-disable-next-line no-unneeded-ternary
                    open={alertMsg.length ? true : false}
                    onClose={closeAlert}
                    severity="error"
                >
                    {alertMsg}
                </Alert>
            </styles.Container>
        </>
    );
};

export default Passengers;
