import React, { Props } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { ReportNames } from 'src/store/slices/reports/types';
import {
    selectedReportNameSelector,
    filtersSelector,
    selectApplyFiltersBtnClickCount,
    selectRefreshBtnClickCount,
} from 'src/store/selectores/reportsSelectors';
import { stringifyDate } from 'src/utilis/utilis';
import { IRootReducer } from 'src/store/reducers';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { styledMui } from '../Filters.style';
import ShortOrdersReportFilters from '../FiltersAccordionForEachReport/ShortOrdersReportFilters/ShortOrdersReportFilters';
import VisasReportFilters from '../FiltersAccordionForEachReport/VisasReportFilters/VisasReportFilters';
import PassengersReportFilters from '../FiltersAccordionForEachReport/PassengerReportFilters/PassengersReportFilter';
import { reportsHooks as hooks } from '../hooks/common';

export interface SelectedReportFiltersAccordionProps {
    setClientSideFilters: Function;
}

const SelectedReportFiltersAccordion = ({
    setClientSideFilters,
}: SelectedReportFiltersAccordionProps): JSX.Element => {
    // & Selectors
    const selectedReportName: ReportNames = useAppSelector((state) => selectedReportNameSelector(state));

    const { getExpandConfig } = hooks.useAccordionExpansion();

    // & props
    const getDefaultAccordionProps = React.useCallback(
        (reportName: ReportNames) => {
            return {
                setClientSideFilters,
                expandConfig: getExpandConfig(reportName),
            };
        },
        [getExpandConfig, setClientSideFilters]
    );

    // & utils
    const getSelectedReportFiltersAccordion: () => JSX.Element = () => {
        switch (selectedReportName) {
            case 'shortOrders':
                return (
                    <ShortOrdersReportFilters {...getDefaultAccordionProps(ReportNames.shortOrdersReport)} />
                );
            case 'detailedOrders':
                return (
                    <ShortOrdersReportFilters
                        {...getDefaultAccordionProps(ReportNames.detailedOrdersReport)}
                    />
                );
            case 'visas':
                return <VisasReportFilters {...getDefaultAccordionProps(ReportNames.visasReport)} />;
            case 'passengers':
                return (
                    <PassengersReportFilters {...getDefaultAccordionProps(ReportNames.passengersReport)} />
                );

            default:
                return <>error</>; // -- should not happen
        }
    };

    // & JSX
    return getSelectedReportFiltersAccordion();
};

export default SelectedReportFiltersAccordion;
