/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import {
    FetchActionPayload,
    IReportsState,
    OnChangeDateForFilteringPayload,
    ReportNames,
    ShortReportRow,
} from 'src/store/slices/reports/types';
import { sortHours, sortHoursWithFcConfigs, sortHoursWithFcConfigsV2 } from 'src/screens/ManualOrder/utilis';
import moment from 'moment';
import { dateify, deepClone } from 'src/utilis/utilis';
import { getDepartmentName } from 'src/screens/Reports/utils';
import { applyMiddleware } from 'redux';
import { ProcessedStatistics, StatisticsRequestConfig } from 'src/types/reports/generalReportsTypes';
import { initialSummaryData } from 'src/store/slices/reports/initialStates';
import { RELATIVE } from 'src/constants/dates';
import { ReqStatus } from '../../../api/types';
import { OnSelectReportPayload, ReportName } from '../../slices/reports/types';
import { AccountShift, ShortOrdersApiDataElement } from '../../../api/reportsApi/types';
import { CallGetShiftRequest } from '../../../api/reportsApi/filters/filtersApi';

const { LOADING } = ReqStatus;
const fetchShortOrders = (state: IReportsState, action: PayloadAction<FetchActionPayload>): void => {
    state.data.reports.shortOrders.apiCallStatus = LOADING;
};

const fetchReport = (state: IReportsState, action: PayloadAction<FetchActionPayload>): void => {
    const { reportName, requestParams, dateKey } = action.payload;
    if (!reportName) return;
    state.data.reports[reportName].reportData = [];
    state.data.reports[reportName].sortedData = [];
    state.data.reports[reportName].apiCallStatus = LOADING;
    if (dateKey) state.data.reports[reportName].date = requestParams[dateKey];
};

const onFetchReportStatusUpdate = (
    state: IReportsState,
    action: PayloadAction<{ reportName: string; status: ReqStatus }>
): void => {
    const { reportName, status } = action.payload;
    state.data.reports[reportName].apiCallStatus = status;
};

const onFetchReportSuccess = <T>(
    state: IReportsState,
    action: PayloadAction<{
        dataProcessFunc: Function;
        reportName: string;
        data: T;
    }>
): void => {
    const { reportName, data, dataProcessFunc } = action.payload;
    const departments = deepClone(state.data.departments);
    const processedData = dataProcessFunc(data, departments);
    state.data.reports[reportName].reportData = processedData;
    state.data.reports[reportName].apiCallStatus = ReqStatus.SUCCESS;
};

export interface OrderData {
    time: string;
    relativeDate: string;
}
const onFetchShortOrdersStatusUpdate = (
    state: IReportsState,
    action: PayloadAction<{ data?: ShortOrdersApiDataElement[]; status: ReqStatus }>
): void => {
    const { data = [], status } = action.payload;

    const processedData: ShortReportRow[] = [];

    data.forEach((passenger) => {
        const { orders, pickupCourse, dropCourse, street, houseNum, ...neededProperties } = passenger;

        const departments = deepClone(state.data.departments);
        const departmentName = getDepartmentName(passenger, departments);

        const pickups: OrderData[] = [];
        const drops: OrderData[] = [];

        passenger.orders.forEach((order) => {
            if (+order.is_pickup) pickups.push({ time: order.shiftTime, relativeDate: order.relativeDate });
            else drops.push({ time: order.shiftTime, relativeDate: order.relativeDate });
        });

        const pickupsSorted = [...pickups].sort((a, b) => {
            const aTime = moment(a.time, 'hh:mm');
            const bTime = moment(b.time, 'hh:mm');
            return aTime.isBefore(bTime) ? -1 : 1; // true
        });

        const dropsSorted = sortHoursWithFcConfigsV2(drops);

        const longestOrdersTypeList = drops.length > pickups.length ? drops : pickups;

        const { dropTime: dropTimeFilter, pickupTime: pickupTimeFilter } =
            state.ui.main.filters.currFiltersValues;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < longestOrdersTypeList.length; i++) {
            if (dropTimeFilter !== '0' || pickupTimeFilter !== '0') {
                if (
                    (pickupTimeFilter !== '0' && pickupsSorted[i].time === pickupTimeFilter) ||
                    (dropTimeFilter !== '0' && dropsSorted[i].time === dropTimeFilter)
                ) {
                    processedData.push({
                        ...neededProperties,
                        streetWithHouseNum: `${passenger.street}, ${passenger.houseNum}`,
                        workStart: pickupsSorted[i].time || '',
                        workEnd: dropsSorted[i].time || '',
                        relativeDate: dateify(pickupsSorted[i].relativeDate, RELATIVE),
                        pickupCourseId: pickupCourse,
                        dropCourseId: dropCourse,
                        departmentName,
                    });
                }
            } else {
                let date;
                try {
                    date = dateify(
                        pickupsSorted[i] ? pickupsSorted[i].relativeDate : dropsSorted[i].relativeDate,
                        RELATIVE
                    );
                } catch (error) {
                    date = '' as const;
                }

                processedData.push({
                    ...neededProperties,
                    streetWithHouseNum: `${passenger.street}, ${passenger.houseNum}`,
                    workStart: pickupsSorted[i]?.time || '',
                    workEnd: dropsSorted[i]?.time || '',
                    relativeDate: date,
                    pickupCourseId: pickupCourse,
                    dropCourseId: dropCourse,
                    departmentName,
                });
            }
        }
        processedData.sort((a, b) => {
            const aTime = moment(a.relativeDate);
            const bTime = moment(b.relativeDate);
            return aTime.isBefore(bTime) ? -1 : 1; // true
        });
    });
    state.data.reports.shortOrders.apiCallStatus = status;
    state.data.reports.shortOrders.reportData = processedData;
};
const onFetchDepartments = (state: IReportsState, action: PayloadAction<any>): void => {
    state.data.departments = action.payload.departments;
};
const onApiCallTimeout = (state: IReportsState, action: PayloadAction<{ reportName: ReportName }>): void => {
    state.data.reports[action.payload.reportName].apiCallStatus = ReqStatus.FAIL;
};
const fetchShiftTimes = (state: IReportsState, action: PayloadAction<CallGetShiftRequest>): void => {};

const fetchDepartmentsStatistics = (state: IReportsState, action: PayloadAction<any>): void => {};

const onFetchShiftTimes = (state: IReportsState, action: PayloadAction<any>): void => {
    const { data } = action.payload;

    const dropTimes: string[] = [];
    const pickupTimes: string[] = [];

    data.forEach((shiftData: AccountShift) => {
        if (!dropTimes.includes(shiftData.dropTime)) dropTimes.push(shiftData.dropTime);
        if (!pickupTimes.includes(shiftData.pickupTime)) pickupTimes.push(shiftData.pickupTime);
    });

    state.data.shiftTimes.fullData = action.payload.data;
    state.data.shiftTimes.dropTimes = dropTimes.sort();
    state.data.shiftTimes.pickupTimes = pickupTimes.sort();
};

const onFetchRidesStatisticsSuccess = (
    state: IReportsState,
    action: PayloadAction<{ statistics: ProcessedStatistics }>
): void => {
    if (!action.payload.statistics) return;
    state.data.widgets.ridesStatistics = action.payload.statistics;
};

const resetRidesStatisticsData = (state: IReportsState): void => {
    state.data.widgets.ridesStatistics = {
        weeklyCounts: {
            rides: 0,
            passengers: 0,
            fromDate: '',
            toDate: '',
        },
        summaryData: initialSummaryData,
    };
};
const dataReducers = {
    fetchShortOrders,
    onFetchShortOrdersStatusUpdate,
    onFetchDepartments,
    fetchShiftTimes,
    onFetchShiftTimes,
    onFetchReportStatusUpdate,
    onFetchReportSuccess,
    fetchDepartmentsStatistics,
    fetchReport,
    onApiCallTimeout,
    onFetchRidesStatisticsSuccess,
    resetRidesStatisticsData,
};

export default dataReducers;
