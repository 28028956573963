import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IPassenger } from 'src/types/line';

import styles from './Passenger.style';
import HailIcon from '@mui/icons-material/Hail';

type Props = Partial<IPassenger>;

const Passenger: FC<Props> = ({
    passId,
    fullName,
    city,
    street,
    phone1,
    remarks,
    time,
    onBoard,
    ...props
}: Props) => {
    const { t } = useTranslation();

    const style: CSSProperties = {
        fontWeight: 700,
        fontSize: '14px',
        marginRight: '1em',
        marginLeft: '3px',
    };

    const styleBold: CSSProperties = {
        fontWeight: 700,
        fontSize: '14px',
    };

    return (
        <styles.AccordionContainer>
            <Accordion>
                <AccordionSummary
                    style={{ minHeight: '45px', maxHeight: '45px' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <styles.Text width="20%" style={{ ...styleBold, display: 'flex', alignItems: 'center' }}>
                        {time}
                    </styles.Text>
                    <styles.Text width="30%" style={{ ...styleBold, display: 'flex', alignItems: 'center' }}>
                        {fullName}
                    </styles.Text>
                    <styles.Text width="50%" style={{ display: 'flex', alignItems: 'center' }}>
                        <styles.Text style={style}>
                            {city}
                            {city && ','}
                        </styles.Text>
                        <styles.Text>{street}</styles.Text>
                    </styles.Text>
                    <styles.Text style={{ display: 'flex', alignItems: 'center', marginLeft: '0px' }}>
                        {onBoard ? (
                            <HailIcon sx={{ fontSize: '25px' }} />
                        ) : (
                            <HailIcon sx={{ fontSize: '25px', opacity: '0.2' }} />
                        )}
                    </styles.Text>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" justify="flex-start" alignContent="flex-start">
                        <Grid item xs={5} style={{ textAlign: 'start' }}>
                            <styles.Text opacity="0.5">{t('passengerCode')}</styles.Text>
                            <styles.Text>{passId}</styles.Text>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'start' }}>
                            <Divider style={{ height: '60%' }} orientation="vertical" flexItem />
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'start' }}>
                            <styles.Text opacity="0.5">{t('phone')}</styles.Text>
                            <styles.Text>{phone1}</styles.Text>
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'start' }}>
                            <styles.Text opacity="0.5">{t('remarks')}</styles.Text>
                            <styles.Text>{remarks}</styles.Text>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'start' }}>
                            <Divider style={{ height: '60%' }} orientation="vertical" flexItem />
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'start' }}>
                            <styles.Text opacity="0.5">{t('departmentName')}</styles.Text>
                            <styles.Text>{props.departmentName}</styles.Text>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </styles.AccordionContainer>
    );
};

export default Passenger;
