import React, { ReactElement, useState, useEffect, CSSProperties, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { ILine, IItem, IEditLine, IStation } from 'src/types/line';
import { IAccount } from 'src/types/login';
import AppName from 'src/components/AppName/AppName';
import { useSelector, useDispatch } from 'react-redux';
import {
    authorizationTokenSelector,
    loginSelector,
    selectedFcAccountSelector,
    tokenSelector,
} from 'src/store/selectores/loginSelectors';
import { linesSelector } from 'src/store/selectores/linesSelectores';

import Input from 'src/components/commons/Input/Input';
import styles from 'src/components/StyledComponents/StyledComponents.style';

import { useTheme } from 'styled-components';
import { IRootReducer } from 'src/store/reducers';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import DatePickerWithPrevNext from 'src/components/DatePicker/DatePickerWithPrevNext';

import { headlineCss } from 'src/style/styledCSS';
import useDisableDelay from 'src/hooks/useDelayV2';
import { colors } from 'src/style/themes/defaultTheme';
import {
    SDivider,
    StyledInputsContainer,
} from 'src/screens/PassengerManger/components/Passengers/styles/styledComponents';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import {
    closeFormAction,
    lastFormSubmitSelector,
    onSubmitAction,
    openFormAction,
    selectAllLinesAction,
    setIsDirectionsTriggeredFromEditAction,
    setSelectedClientFilterAction,
    toggleConfirmationDialogAction,
    toggleCopyRideModalAction,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import axios, { AxiosResponse } from 'axios';
import { getIturanLocation, getLineByCode } from 'src/api/api';
import useIsAuth from 'src/hooks/auth/useIsAuth';
import { FEATURES_IDS } from 'src/constants/auth';
import { IObject } from 'src/types/global';
import Alert from 'src/components/commons/Alert/Alert';
import { useAlertV2, useDispatchAlert } from 'src/hooks/useAlert';
import { isAuthorized } from 'src/utilis/utilis';
import useLastSelectedClient from 'src/hooks/useLastSelectedClient';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';
import DropDownV2, { IDropdownV2Props } from 'src/components/DropDown/DropDownV2';
import useEnvAuth from 'src/hooks/useEnvAuth';
import { useHistory } from 'react-router-dom';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import useFilters from './hooks/useFilters';

import { setFcAccount, setDate } from '../../../../store/actions/loginAction';
import { onRetriveLines } from '../../../../store/actions/LineAction';
import DatePicker, { IDatePickerProps } from '../../../../components/DatePicker/DatePicker';

import DropDown, { IProps as DropDwonProp } from '../../../../components/DropDown/DropDown';
import LinesGrid from './LinesGrid/LinesGrid';
import { LinesTopPartFlex } from './LinesTopPartFlex.styled';
import headerStyles from '../../../../components/MyWayTitle/MyWayTitle.style';
import { linesContainers } from './styles';
import useIturanData from './hooks/useIturanData';
import useLineActionsConfirmation from './hooks/useLineActionsConfirmation';
import LineDeleteModal from './components/LineDeleteModal/LineDeleteModal';
import usePassengerDeptFilter from './hooks/usePassengerDeptFilter';
import ActionConfirmModal from 'src/components/Modals/ActionConfirmModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { reqSetLine } from 'src/api/linesApi/linesApi';
import { IResponse } from 'src/api/types';
import querystring from 'querystring';
import { buildStationsJson, getDatesInRange } from '../../utils';
import useDirectionsApiLines from '../../hooks/useDirectionsApiLines';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';
import { IconButton } from '@material-ui/core';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { iconButtonStyle } from 'src/components/styles/styles';
import { disabledIcon, styleIcon } from 'src/screens/PassengerManger/components/Passengers/styles/css-styles';

interface Props {}

export default function Lines(props: Props): ReactElement {
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatchAlert = useDispatchAlert();

    const isOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.isOnEdit);
    const isCopyRideModalOpen = useRootAppSelector(
        (state) => state.linesSlice.ui.common.lineActions.isCopyRideModalOpen
    );
    const dataItem = useRootAppSelector((state) => state.linesSlice.ui.common.lineActions.dataItem);
    const lineData = 'lineCode' in Object(dataItem) ? (dataItem as ILine) : null;

    const linesSelectedIds = useRootAppSelector((state) => state.linesSlice.ui.common.linesSelectedIds);

    const onSetCompanySelected = useCallback(
        (payload: IAccount) => dispatch(setFcAccount(payload)),
        [dispatch]
    );

    const onSetDateSelected = useCallback((payload: Date | null) => dispatch(setDate(payload)), [dispatch]);

    const [filters, setFilters] = useState<CompositeFilterDescriptor>();

    const [copyRideStartDate, setCopyRideStartDate] = useState<Date | null>(null);
    const [copyRideEndDate, setCopyRideEndDate] = useState<Date | null>(null);

    const lines: Array<ILine> = useSelector((state) => linesSelector(state)) || [];

    const token = useSelector((state: IRootReducer) => tokenSelector(state));

    const {
        GlobalFilterProp,
        DepartmentFilterProp,
        PassengerFilterProp,
        StatusLineFilterProp,
        restFilters,
        ClinetFilterProp,
    } = useFilters({
        filters,
        setFilters,
    });

    const {
        value: passengerDeptFilterValue,
        componentProps: passengerDeptDropdownProps,
        resetValue: resetPassDeptFilter,
    } = usePassengerDeptFilter(DepartmentFilterProp);

    const resetFilters = () => {
        restFilters();
        resetPassDeptFilter();
    };

    // Lines for grid
    const [filteredLines, setFilteredLines] = useState(lines);

    // Filter lines by line's passengers departments ( passengerDeptFilterValue )
    React.useEffect(() => {
        const filtered = passengerDeptFilterValue
            ? lines.filter((line) => {
                  const linePassengers = line.passengers;

                  const match = linePassengers.some((passenger) => {
                      return passenger.departmentCode === passengerDeptFilterValue;
                  });

                  return match ? true : false;
              })
            : lines;

        setFilteredLines(filtered);
    }, [lines, passengerDeptFilterValue]);

    const [, setLastSelectedClient, setProvidersClients, getNameByValue] = useLastSelectedClient();

    useEffect(() => {
        setProvidersClients(
            ClinetFilterProp.selectProps.menuItems.map((item) => ({
                value: String(item.value),
                name: item.name,
            })) || []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(ClinetFilterProp.selectProps.menuItems)]);

    useEffect(() => {
        if (ClinetFilterProp.selectProps.value) {
            setLastSelectedClient({
                value: String(ClinetFilterProp.selectProps.value),
                name: getNameByValue(String(ClinetFilterProp.selectProps.value)),
            });
        }

        // Avoid filtering by department that does not exist on client (site)
        resetPassDeptFilter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ClinetFilterProp.selectProps.value]);

    const lastFormSubmitTime = useAppSelector((state: any) => lastFormSubmitSelector(state));

    const [providers, setProviders] = useState<IItem[]>([]);

    const { fcAccounts, selectedDate, authorizationToken } = useSelector((state: IRootReducer) =>
        loginSelector(state)
    );

    const selectedFcAccount = useSelector((state) => selectedFcAccountSelector(state));

    const [atLeastOneClientIsAuthToAddLine, setAtLeastOneClientIsAuthToAddLine] = useState(false);

    // iterate over client filter values, check if at least one is authorized
    // if so - set the state to true
    useEffect(() => {
        let found = false;

        if (!ClinetFilterProp || !('menuItems' in ClinetFilterProp.selectProps)) return;

        ClinetFilterProp.selectProps.menuItems.forEach((item: IObject) => {
            if ('value' in item && isAuthorized(FEATURES_IDS.ADD_LINE, item.value, authorizationToken)) {
                found = true;
            }
        });

        setAtLeastOneClientIsAuthToAddLine(found);
    }, [ClinetFilterProp, authorizationToken]);

    useIturanData();
    // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    const handleDateChange = useCallback(
        (date: Date | null) => {
            onSetDateSelected(date);
        },
        [onSetDateSelected]
    );

    useEffect(() => {
        if (fcAccounts) {
            const res: IItem[] = fcAccounts.map(({ accountCode, accountName }: IAccount) => ({
                value: accountCode,
                name: accountName,
            }));

            setProviders(res);
        }
    }, [fcAccounts]);

    useEffect(() => {
        if (selectedFcAccount && selectedDate) {
            dispatch(
                onRetriveLines({
                    token,
                    clinetProxy: selectedFcAccount.proxyUrl,
                    dbUrl: selectedFcAccount.dbUrl,
                    relativeDate: moment(selectedDate).format('yyyy.MM.DD'),
                    // departmentPassCode: passengerDeptFilterValue,
                })
            );
            dispatch(selectAllLinesAction({ isSelected: false }));
        }
    }, [selectedFcAccount, selectedDate, token, dispatch, lastFormSubmitTime]);

    const [tempDisabled, setTempDisabled] = useDisableDelay(2000);

    const onRefresh = useCallback(() => {
        setTempDisabled(true);
        if (selectedFcAccount) onSetCompanySelected({ ...selectedFcAccount });
    }, [onSetCompanySelected, selectedFcAccount, setTempDisabled]);

    const prodvierDrpodown: IDropdownV2Props = useMemo(
        () => ({
            formControlProp: {
                variant: 'outlined',
                style: { width: '208px' },
                size: 'small',
            },
            labelName: t('provider'),
            inputLabelProps: {},
            selectProps: {
                autoWidth: false,
                multiple: false,
                menuItems: providers,
                native: false,
                value: selectedFcAccount?.accountCode,
                onChange: (
                    event: React.ChangeEvent<{
                        name?: string | undefined;
                        value: unknown;
                    }>
                ) => {
                    const { value } = event.target;
                    const fcAccountTarget = fcAccounts.find((f) => f.accountCode === value);
                    if (fcAccountTarget) {
                        onSetCompanySelected(fcAccountTarget);
                    }
                    tkDispatch(closeFormAction());
                    history.push(routesEndpoints.HOME);
                },
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fcAccounts, onSetCompanySelected, providers, selectedFcAccount?.accountCode, t, tkDispatch]
    );

    const datePickerProps: IDatePickerProps = useMemo(
        () => ({
            showTodayButton: true,
            inputVariant: 'outlined',
            style: { width: '160px' },
            size: 'small',
            format: 'dd.MM.yyyy',
            value: selectedDate,
            onChange: handleDateChange,
            KeyboardButtonProps: {
                'aria-label': 'change date',
            },
        }),
        [handleDateChange, selectedDate]
    );

    // const headlineCss: CSSProperties = { fontSize: '30px' };
    const onAddLineClick = useCallback(() => {
        tkDispatch(openFormAction(null));
        // add /addLine to curr url
        history.push(routesEndpoints.ADD_LINE);
    }, [history, tkDispatch]);

    // -- add lines auth handling
    useEffect(() => {
        // -- set selected client on slice
        if (typeof ClinetFilterProp?.selectProps.value === 'number') {
            tkDispatch(setSelectedClientFilterAction(ClinetFilterProp?.selectProps.value));
        }
        // -- closing form
        tkDispatch(closeFormAction());
        history.push(routesEndpoints.HOME);
    }, [ClinetFilterProp.selectProps.value, history, tkDispatch]);

    const alertConfig = useAlertV2();
    const { props: p } = useLineActionsConfirmation();
    const envAuth = useEnvAuth();

    // Copy Ride

    const handleGetLineByCode = async (res: AxiosResponse<IResponse<IEditLine>>) => {
        if (!selectedFcAccount || !selectedFcAccount.dbUrl || !copyRideStartDate || !copyRideEndDate) return;

        if (res && res.data && res.data.response && res.data.data && res.data.response === '0') {
            const {
                carTypeCode,
                accountCode,
                flightNumber,
                startTime,
                endTime,
                clientOrderCode,
                invContactName,
                invContactPhone,
                lineDescription,
                passQty,
                stations,
                longRemarks,
            } = res.data.data;

            const normalStations: IStation[] = [];
            const datesErrors: string[] = [];

            const datesArray: string[] = getDatesInRange(copyRideStartDate, copyRideEndDate);

            if (stations) {
                // set as stations lst on slice
                stations.forEach((station) => {
                    if (station.type === '0')
                        normalStations.push({ ...station, addressIndex: '8', stationIndex: station.index });

                    if (station.type === '1')
                        normalStations.push({ ...station, addressIndex: '1', stationIndex: station.index });

                    if (station.type === '2')
                        normalStations.push({ ...station, addressIndex: '9', stationIndex: station.index });
                });
            }

            datesArray.forEach(async (date, index) => {
                console.log('date', date);
                // tamir

                const result = await reqSetLine({
                    dbUrl: selectedFcAccount.dbUrl,
                    requestParams: {
                        token,
                        carTypeCode,
                        clientCode: accountCode.toString(),
                        flightNumber,
                        clientOrderCode,
                        invContactName,
                        invContactPhone,
                        lineDate: date,
                        lineDescription,
                        longRemarks: longRemarks ? longRemarks : '',
                        passQty,
                        startTime: startTime.toString(),
                        endTime: endTime.toString(),
                        stationsJson: JSON.stringify({ stations: normalStations }),
                    },
                });

                // dates not copied successfully

                if (result && result.data && result.data.response) {
                    if (result.data.response !== '0') {
                        datesErrors.push(date);
                    }
                } else {
                    datesErrors.push(date);
                }
            });

            if (datesErrors.length === 0) {
                dispatchAlert('success', t('rideCopiedSuccessfully'));
            } else {
                dispatchAlert('error', t('rideCopyFailure'));
            }
        } else if (res && res.data && res.data.response && res.data.response === '1') {
            dispatchAlert('error', t('unknown'));

            console.log('token not valid');
        } else {
            dispatchAlert('error', t('unknown'));
        }
    };

    const onCopyRideConfirm = async () => {
        if (!copyRideStartDate || !copyRideEndDate) {
            dispatchAlert('error', t('rangeNotValid'));
            return;
        }

        if (lineData) {
            if (!selectedFcAccount || !selectedFcAccount.dbUrl || !lineData || !lineData.lineCode) return;

            const res = await getLineByCode({
                dbUrl: selectedFcAccount.dbUrl,
                token,
                lineCode: lineData.lineCode.toString(),
            });

            await handleGetLineByCode(res);
            dispatch(toggleCopyRideModalAction({ isOpen: false }));
        } else {
            if (!selectedFcAccount || !selectedFcAccount.dbUrl) return;

            const promises: Promise<unknown>[] = [];

            linesSelectedIds.forEach((lineCode) => {
                promises.push(
                    getLineByCode({
                        dbUrl: selectedFcAccount.dbUrl,
                        token,
                        lineCode: lineCode.toString(),
                    }).then(async (res) => {
                        await handleGetLineByCode(res);
                    })
                );
            });

            await Promise.all(promises);

            dispatch(toggleCopyRideModalAction({ isOpen: false }));
        }
    };

    const onCopyRideClose = () => {
        dispatch(toggleCopyRideModalAction({ isOpen: false }));
        setCopyRideStartDate(null);
        setCopyRideEndDate(null);
    };

    const handleStartDateChange = (date: Date | null) => {
        setCopyRideStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setCopyRideEndDate(date);
    };

    const onCopyLinesClick = () => {
        dispatch(toggleCopyRideModalAction({ isOpen: true }));
    };

    const startDatePickerProps: IDatePickerProps = {
        showTodayButton: true,
        inputVariant: 'outlined',
        style: { width: '200px' },
        size: 'small',
        format: 'dd.MM.yyyy',
        value: copyRideStartDate,
        onChange: handleStartDateChange,
        KeyboardButtonProps: {
            'aria-label': 'change date',
        },
        label: t('startDate'),
        maxDate: copyRideEndDate ? copyRideEndDate : moment().add(1, 'months').toDate(),
        minDate: moment(),
    };

    const endDatePickerProps: IDatePickerProps = {
        showTodayButton: true,
        inputVariant: 'outlined',
        style: { width: '200px' },
        size: 'small',
        format: 'dd.MM.yyyy',
        value: copyRideEndDate,
        onChange: handleEndDateChange,
        KeyboardButtonProps: {
            'aria-label': 'change date',
        },
        label: t('endDate'),
        maxDate: moment().add(1, 'months').toDate(),
        minDate: copyRideStartDate ? copyRideStartDate : moment(),
    };

    return (
        <div style={isOnEdit ? { cursor: 'not-allowed' } : {}}>
            <ActionConfirmModal
                Body={() => (
                    <div>
                        <div style={{ marginBottom: '20px' }}>{t('copyRideDetailsToMoreDates')}</div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '15px',
                            }}
                        >
                            <DatePickerWithPrevNext withDebounceTime {...startDatePickerProps} />
                            <DatePickerWithPrevNext withDebounceTime {...endDatePickerProps} />
                        </div>
                    </div>
                )}
                isOpen={isCopyRideModalOpen}
                onConfirm={onCopyRideConfirm}
                height={260}
                onClose={onCopyRideClose}
                Img={() => <ContentCopyIcon />}
            />

            <styles.Container
                style={isOnEdit ? { paddingBottom: '0px', pointerEvents: 'none' } : { paddingBottom: '0px' }}
            >
                <LineDeleteModal {...p} />
                {/* MyWay Studio title */}
                <linesContainers.HeaderContainer
                    withWarningColors={envAuth.isUsingProdAccountOnDev}
                    className="headerContainer"
                >
                    <AppName />
                    <linesContainers.HeaderDropdownContainer className="headerDropdownContainer">
                        <DropDownV2 {...ClinetFilterProp} />
                        <DropDownV2 {...prodvierDrpodown} />
                        <RefreshButton onClick={onRefresh} disabled={tempDisabled} />
                    </linesContainers.HeaderDropdownContainer>
                </linesContainers.HeaderContainer>

                {/* hr for line break  */}
                <styles.Hr className="styled-lines-hr" />

                {/* Filters and table */}
                <linesContainers.filtersMenuContainer className="filtersMenuContainer">
                    <linesContainers.psngrCountAndClearFiltersContainer className="psngrCountAndClearFiltersContainer">
                        <styles.FilterText>{`${t('lineList')} (${
                            filters ? filterBy(lines, filters).length : lines.length
                        })`}</styles.FilterText>
                        <styles.Btn size="small" disabled={!filters} onClick={resetFilters}>
                            <styles.FilterText
                                style={{
                                    margin: '0px',
                                    color: colors.gray,
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('clearFilter')}
                            </styles.FilterText>
                        </styles.Btn>
                    </linesContainers.psngrCountAndClearFiltersContainer>
                    <linesContainers.filtersDropdownContainer>
                        <AddIconNoText
                            disabled={!atLeastOneClientIsAuthToAddLine}
                            onClick={onAddLineClick}
                            withDivider
                        />
                        <div
                            style={{
                                borderLeft: '2px solid #bebebe',
                                paddingLeft: '7px',
                                maxHeight: '44px',
                            }}
                        >
                            <MuiTooltipV3 title={t('copyRides')}>
                                <IconButton
                                    onClick={(ev) => onCopyLinesClick()}
                                    size="small"
                                    disabled={linesSelectedIds.length === 0}
                                >
                                    <div style={linesSelectedIds.length === 0 ? disabledIcon : styleIcon}>
                                        <ContentCopyIcon />
                                    </div>
                                </IconButton>
                            </MuiTooltipV3>
                        </div>

                        <Input
                            {...GlobalFilterProp}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                        <DatePickerWithPrevNext {...datePickerProps} />
                        <DropDown {...DepartmentFilterProp} />
                        <DropDown {...passengerDeptDropdownProps} />
                        <Input {...PassengerFilterProp} />
                        {/* <DropDown {...StatusLineFilterProp} /> */}
                    </linesContainers.filtersDropdownContainer>
                </linesContainers.filtersMenuContainer>

                <styles.TableContainer className="tableContainer" style={{ paddingRight: '5px' }}>
                    <LinesGrid data={filteredLines} compositeFilters={filters} />
                </styles.TableContainer>
            </styles.Container>
        </div>
    );
}
