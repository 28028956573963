import React, { FunctionComponent } from 'react';
import { TileLayoutItem } from '@progress/kendo-react-layout';
import WidgetLayout from 'src/components/WidgetLayout/WidgetLayout';
import LineDetails from '../LineDetails/LineDetails';
import LineMap from '../LineMap/LineMap';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'src/store/reducers';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';

const LineWidgetLayout: FunctionComponent<{}> = () => {
    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));

    const tiles: Array<TileLayoutItem> = [
        {
            item: <LineDetails />,
            resizable: 'vertical',
            style: { overflowY: 'auto' },
        },
        {
            item: <LineMap key={selectedFcAccount?.accountCode} />,
            reorderable: false,
            resizable: false,
        },
    ];

    return <WidgetLayout tiles={tiles} />;
};

export default LineWidgetLayout;
