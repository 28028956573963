import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store/hooks';
import { clearFiltersAction } from 'src/store/slices/reports/reportsSlice';
import { colors } from 'src/style/themes/defaultTheme';

const btnStyle = { maxHeight: '40px' };
const textStyle = {
    color: 'gray',
    textDecoration: 'underline',
    fontSize: '14px',
    fontFamily: 'Rubik',
};

const ClearFiltersBtn = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(clearFiltersAction());
    };
    return (
        <Button onClick={handleClick} sx={btnStyle}>
            <p style={textStyle}>{t('clearFilter')}</p>
        </Button>
    );
};

export default ClearFiltersBtn;
