import { Position } from 'src/types/global';
import styled from 'styled-components';

export const Container = styled.div<{ position: keyof typeof Position }>`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: ${({ position }) => (position === 'Vertical' ? 'column-reverse' : 'row')};
`;

export const Progress = styled.div<{
    count: number;
    color: string;
    position: keyof typeof Position;
}>`
    background-color: ${({ color }) => color};
    width: ${({ count, position }) => `${position === 'Horizontal' ? `${count * 10}%` : '13px'}`};
    height: ${({ position, count }) => (position === 'Vertical' ? `${count * 10}px` : '13px')};
    border-radius: 4px;
    transition: width 1s;
`;

export const Count = styled.span`
    margin: 0 5px;
    font-weight: 500;
`;
