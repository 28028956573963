import { StrictDropdownItem } from 'src/types/line';
import { SlSettingTypes } from '../store/types.store';

export enum Endpoints {
    setPermMng = 'ws_MyWayStudio_Set_Internet_Permissions',
    setGeneralSettings = 'ws_MyWayStudio_Set_Client_General_Settings',
    setAccountDept = 'ws_MyWayStudio_Set_Account_Department',
}

export interface ClientFilterDropdownState {
    options: StrictDropdownItem[];
    value: string;
}

export interface AccordionExpansion {
    toggleExpansion: (type: SlSettingTypes, newState: 'close' | 'open') => void;
    isExpanded: (type: SlSettingTypes) => boolean;
    closeAllAccordions: () => void;
}
