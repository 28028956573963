import React from 'react';
import { useTheme } from 'styled-components';
import styles from './HeaderAuth.style';

const HeaderAuth = () => {
    const { msColors } = useTheme();
    return (
        <styles.Container>
            <styles.Hr />
            <styles.TextContainer>
                <styles.Text color={msColors.yellow}>MyWay</styles.Text>
                <styles.Text color={msColors.darkTextcolor}>Studio</styles.Text>
            </styles.TextContainer>
        </styles.Container>
    );
};

export default HeaderAuth;
