/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import Dialog from 'src/components/Dialog/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import { PaperProps as PaperPropsType } from '@mui/material';
import { TransitionHandlerProps } from '@mui/material/transitions';

interface DialogV2Props {
    onCloseDialog: () => void;
    onConfirmClick: () => void;
    isDialogOpen: boolean;
    DialogContent: ReactNode;
    DialogTitle: ReactNode;
    isRestoreDialog?: boolean;
    loading?: boolean;
    emptyActions?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    onEntering?: TransitionHandlerProps['onEntering'];
    PaperProps?: Partial<PaperPropsType>;
}

const DialogV2: FC<DialogV2Props> = ({
    onCloseDialog,
    onConfirmClick: onConfirmaDialog,
    isDialogOpen,
    DialogContent,
    DialogTitle,
    isRestoreDialog,
    loading,
    emptyActions,
    disableBackdropClick,
    disableEscapeKeyDown,
    onEntering,
    PaperProps,
}: DialogV2Props) => {
    const { t } = useTranslation();

    const DialogAction = () => {
        return (
            <>
                <LoadingButton loading={loading} onClick={onConfirmaDialog} color="primary">
                    {isRestoreDialog ? t('approve') : t('ok')}
                </LoadingButton>
                <Button onClick={onCloseDialog} color="primary">
                    {isRestoreDialog ? t('cancel') : t('no')}
                </Button>
            </>
        );
    };

    return (
        <Dialog
            DialogActionsChildren={emptyActions ? undefined : DialogAction()}
            DialogContentChildren={DialogContent}
            DialogTitleChildren={DialogTitle}
            open={isDialogOpen}
            onClose={onCloseDialog}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            onEntering={onEntering}
            PaperProps={PaperProps}
        />
    );
};

export default DialogV2;
