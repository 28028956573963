import { Coords } from 'google-map-react';
import moment from 'moment';
import { FillerValues } from 'src/constants/misc';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import { CourseDetailsFormSchema, StationSchema } from 'src/types/coursesBuilding/form/formTypes';
import { CourseDirection } from 'src/types/passengersManager/courseChoosingTypes';
import dateHelpers from 'src/utilis/dateHelpers';
import distanceHelpers from 'src/utilis/distanceHelpers';
import { uuid } from 'src/utilis/utilis';
import { ICourseStation } from '../../CourseInfoWidget/StationsAndTimesPanel';
import { boolean } from 'yup';

// first day of year
const firstDayOfYear = moment(new Date()).startOf('year').toDate();
const endOfMillennium = moment(new Date()).endOf('year').set('year', 2999).toDate();

export const getEmptyStation = (): ICourseStation => {
    return {
        stationId: uuid(),
        city: '',
        street: '',
        timeFromPrev: null,
        isManualTimeFromPrev: false,
        houseNum: '',
        lat: 0,
        lng: 0,
        type: '',
        stationRemark: '',
    };
};

const defaultValues: CourseDetailsFormSchema = {
    courseId: '',
    courseDescription: '',
    remarks: '',
    departmentCode: '',
    carTypeCode: '',
    lineTypeCode: '',
    activeFromDate: firstDayOfYear,
    activeUntilDate: endOfMillennium,
    courseDirection: FillerValues.Without,
    isActive: '1',
    stations: [getEmptyStation(), getEmptyStation()],
};

export const courseFormDefaultValues = {
    details: defaultValues,
};

// Type Checkers
export const isStationSchema = (ele: unknown): ele is StationSchema => {
    try {
        return ele &&
            typeof ele === 'object' &&
            'stationId' in ele &&
            'lat' in ele &&
            'lng' in ele &&
            'street' in ele &&
            'houseNum' in ele &&
            'city' in ele &&
            'timeFromPrev' in ele
            ? true
            : false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const ZOOM_LEVELS = {
    LONG: 9,
    MID_LONG: 10,
    MID: 11,
    CLOSE: 12,
    VERY_CLOSE: 14,
} as const;

export const DEFAULT_ZOOM = ZOOM_LEVELS.MID_LONG;

export const possibleZoomLvls: Array<number> = Object.values(ZOOM_LEVELS);

const getDistanceBetweenEdgeCoords = (station1: Coords, station2: Coords) => {
    const lat1 = station1.lat;
    const lng1 = station1.lng;
    const lat2 = station2.lat;
    const lng2 = station2.lng;

    const centerCoords = distanceHelpers.getDistanceBetweenCoordinates(lat1, lng1, lat2, lng2);

    if (centerCoords) return centerCoords;

    return DEFAULT_ZOOM;
};

const calcTopViewZoom = (distanceBetweenEdgePoints: number) => {
    if (distanceBetweenEdgePoints < 5) {
        return ZOOM_LEVELS.VERY_CLOSE;
    }
    if (distanceBetweenEdgePoints < 10) {
        return ZOOM_LEVELS.CLOSE;
    }
    if (distanceBetweenEdgePoints < 25) {
        return ZOOM_LEVELS.MID;
    }
    if (distanceBetweenEdgePoints < 50) {
        return ZOOM_LEVELS.MID_LONG;
    }
    return ZOOM_LEVELS.LONG;
};

const handleTwoPointsCenter = (coords1: Coords, coords2: Coords) => {
    const lat1 = coords1.lat;
    const lng1 = coords1.lng;
    const lat2 = coords2.lat;
    const lng2 = coords2.lng;

    if (lat1 && lng1 && lat2 && lng2) {
        const centerCoords = distanceHelpers.getCenterCoordinates(lat1, lng1, lat2, lng2);
        if (centerCoords) return centerCoords;
    }

    return defaultCenter;
};

const getCoordsFromField = (field: { lat: number; lng: number }) => {
    return {
        lat: field.lat,
        lng: field.lng,
    };
};

const isValidCoords = (coords: Coords) => {
    return coords.lat && coords.lng ? true : false;
};

const getIconUrl = (
    index: number,
    isDestination?: boolean,
    isEdited?: boolean,
    isRegularMarker?: boolean,
    isSelected?: boolean,
    isTarget?: boolean
) => {
    if (isTarget) return '/targetMarker.svg';
    if (isSelected) return '/selectedMarker.svg';
    if (isRegularMarker) return '/regularMarker.svg';
    if (isEdited) return '/editedMarker.svg';
    if (isDestination) return '/destinationMarker.svg';

    // index 0 is the origin marker
    return `/marker${index}.svg`;
};

const objectHasValidCoords = (
    objWithCoords: { lat: number; lng: number; [key: string]: any } | null | undefined
) => {
    if (!objWithCoords) return false;

    return isValidCoords(objWithCoords);
};

export const mapHelpers = {
    getDistanceBetweenEdgeCoords,
    calcTopViewZoom,
    handleTwoPointsCenter,
    getCoordsFromField,
    isValidCoords,
    getIconUrl,
    objectHasValidCoords,
};
