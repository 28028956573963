import React from 'react';
import useCommons from 'src/hooks/common/useCommons';
import { StationValidationErrors } from 'src/screens/CoursesBuilding/components/CourseForm/CourseFormBody/StationsPanel/utils.StationInputBox';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setWaypointsErrorsAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { checkPassIdDuplicates } from './useCsvReverseGeo';
import { IOptimizationFormWaypoint } from '../api/types';

export const useValidations = () => {
    const { dispatch } = useCommons();

    const formWaypointsList = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypoints
    );
    const {
        data: {
            getWaypointsFromServer: { data: waypointStock },
        },
        ui: {
            form: { lastEditedWaypointsPassIds },
        },
    } = useRootAppSelector(optimizationsRootSelector);

    const isEditForm = useRootAppSelector((state) => optimizationsRootSelector(state).ui.form.isEditForm);

    const validateWaypoints = () => {
        const newErrors = {};

        const checkIsWayPointPassIdInStock = (formWaypoint: IOptimizationFormWaypoint) => {
            let res: boolean = false;

            waypointStock.forEach((waypoint) => {
                if (waypoint.passId === formWaypoint.passId) {
                    res = true;
                }
            });

            return res;
        };

        const checkIsWayPointPassIdInLastEditedWaypointsPassIds = (
            formWaypoint: IOptimizationFormWaypoint
        ) => {
            let res: boolean = false;

            lastEditedWaypointsPassIds.forEach((passId) => {
                if (passId === formWaypoint.passId) {
                    res = true;
                }
            });

            return res;
        };

        const isPassIdDuplicatesInForm = checkPassIdDuplicates(formWaypointsList);

        formWaypointsList.forEach((waypoint) => {
            if (waypoint.localId) {
                const isWayPointPassIdInStock = checkIsWayPointPassIdInStock(waypoint);
                const isWaypointPassIdInLastEditedWaypointsPassIds =
                    checkIsWayPointPassIdInLastEditedWaypointsPassIds(waypoint);

                console.log('isWayPointPassIdInStock', isWayPointPassIdInStock);

                if (!waypoint.city && waypoint.localId) {
                    newErrors[waypoint.localId] = StationValidationErrors.MissingCity;
                }

                if (!waypoint.passId) {
                    newErrors[waypoint.localId] = 'קוד נוסע הינו שדה חובה';
                }

                if (isPassIdDuplicatesInForm && waypoint.passId !== '') {
                    newErrors[waypoint.localId] = 'קוד נוסע חייב להיות ייחודי';
                }

                if (isWayPointPassIdInStock && !isWaypointPassIdInLastEditedWaypointsPassIds) {
                    newErrors[waypoint.localId] = 'קוד נוסע קיים במאגר';
                }

                if (!waypoint.lat || !waypoint.lng) {
                    newErrors[waypoint.localId] = StationValidationErrors.MissingCoords;
                }
            }
        });

        dispatch(setWaypointsErrorsAction({ errors: newErrors }));
    };

    React.useEffect(() => {
        validateWaypoints();
    }, [formWaypointsList]);
};
