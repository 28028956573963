import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { breakPoints, widgetsSidebarConfig } from 'src/style/themes/defaultTheme';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const LinesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const GridContainer = styled(Grid)`
    height: 100%;
    overflow: hidden;
`;

const GridItem = styled(Grid)`
    height: 100%;
`;

const LayoutGridItem = styled.div`
    height: 100%;
    overflow-y: auto;
    min-width: 650px;
    max-width: 650px;
    background: #ffedb1;
    box-shadow: inset 6px 2px 20px rgba(0, 0, 0, 0.4);
    @media ${breakPoints.laptopM} {
        min-width: ${widgetsSidebarConfig.mediumWidth};
        max-width: ${widgetsSidebarConfig.mediumWidth};
    }
    @media ${breakPoints.laptopS} {
        min-width: ${widgetsSidebarConfig.smallWidth};
        max-width: ${widgetsSidebarConfig.smallWidth};
    }
`;

export default {
    Container,
    GridContainer,
    GridItem,
    LinesContainer,
    LayoutGridItem,
};
