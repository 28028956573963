import { fontSizes } from 'src/style/themes/defaultTheme';
import styled, { css } from 'styled-components';

export const Root = styled.div`
    height: 100%;
    width: 100%;
    // border: 3px solid red;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    // border: 1px solid cyan;
`;

export const OrderHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.p`
    font-size: 25px;
    font-weight: 500;
`;

export const WidgetTitle = styled.p`
    font-size: 30px;
`;

export const ReloadBtn = styled.p<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    color: #494949;
    padding-bottom: 15px;
    :hover {
        cursor: pointer;
    }
    :active {
        transform: translateY(2px);
    }
    ${(props) =>
        props.disabled &&
        css`
            cursor: default;
            opacity: 0.3;
            pointer-events: none;
            transition: opacity 0.3 linear;
        `}
`;

export const Lable = styled.p`
    font-weight: 700;
    font-size: 15px;
    color: rgba(73, 73, 73, 1);
`;

export const Value = styled.p`
    font-weight: 500;
    font-size: 50px;
    color: rgba(73, 73, 73, 1);
`;

export const Icon = styled.img`
    background-color: ${({ theme }) => theme.colors.lightYellow};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
`;
