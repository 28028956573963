import React from 'react';
import Alert from 'src/components/commons/Alert/Alert';
import Header from './components/Header/Header';
import Table from './components/Table/Table';
import { dropsDisplayHooks as hooks } from './hooks';
import { styles } from './styles';

type Props = {};

const DropDisplayScreen: React.FunctionComponent<Props> = ({}) => {
    const handleExitClick = hooks.useEscapeExit();

    hooks.useInitialReqGetDrops();
    hooks.useRefreshData();

    const { alertProps, errorMessage } = hooks.useAlert();
    return (
        <>
            <div style={styles.screen}>
                <Header handleExitClick={handleExitClick} />
                <Table />
            </div>
            <Alert {...alertProps}>{errorMessage}</Alert>
        </>
    );
};

export default DropDisplayScreen;
