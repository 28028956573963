/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumnProps } from '@progress/kendo-react-grid';
import DATE_FORMATS from 'src/constants/dates';
import { stringifyDate } from 'src/utilis/utilis';
import { useRootAppSelector } from 'src/store/hooks';
import { widgetsDataSelector } from 'src/store/selectores/reportsSelectors';
import useColumnsFromPrevSession from './useColumnsFromPrevSessions';
import { getFieldValueByLocalStorage } from './utils';

const { width } = window.screen;

const getWidth = (field: string): number | undefined => {
    switch (field) {
        case 'description':
            if (width < 1660) {
                if (width > 1420) return 200;
                return 100;
            }
            return 250;
        case 'passengersCount':
            if (width < 1660) {
                if (width > 1420) return 75;
                return 60;
            }
            return 90;
        default:
            break;
    }
};

const VISA_COST_FIELD_NAME = 'visaClientPrice';

enum ColIds {
    OrderCode = 'col-visas-orderCode',
    RideCode = 'col-visas-rideCode',
    RelativeDate = 'col-visas-relativeDate',
    StartTime = 'col-visas-startTime',
    EndTime = 'col-visas-endTime',
    Description = 'col-visas-description',
    Km = 'col-visas-km',
    PassengersCount = 'col-visas-passengersCount',
    Duration = 'col-visas-duration',
    RouteCode = 'col-visas-routeCode',
    CarNumber = 'col-visas-carNumber',
    CarType = 'col-visas-carType',
    DriverName = 'col-visas-driverName',
    VisaClientPrice = 'col-visas-visaClientPrice',
}

const useVisasReportColumns = (
    isPrinting: boolean
): {
    columns: Array<GridColumnProps>;
} => {
    const { t } = useTranslation();

    const hasBudgetFeature =
        useRootAppSelector((state) => widgetsDataSelector(state).budget.data.monthlyBudget) > 0;

    const { localStorageCols } = useColumnsFromPrevSession();

    const getOrderIndexVal = useCallback(
        (colId: ColIds) => {
            return getFieldValueByLocalStorage(localStorageCols, colId, 'orderIndex', undefined);
        },
        [localStorageCols]
    );

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'orderCode',
                title: t('orderCode'),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.OrderCode, 'width', 70),
                minResizableWidth: 50,
                id: ColIds.OrderCode,
                headerClassName: 'broken-header-col',
                orderIndex: getOrderIndexVal(ColIds.OrderCode),
            },
            {
                field: 'rideCode',
                title: t('rideCode'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.RideCode, 'width', 70),
                orderIndex: getOrderIndexVal(ColIds.RideCode),
                id: ColIds.RideCode,
            },
            {
                field: 'relativeDate',
                title: t('date'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.RelativeDate, 'width', 80),
                orderIndex: getOrderIndexVal(ColIds.RelativeDate),
                cell: (cellProps) => (
                    <td style={{ padding: '0 5px 0 0' }}>
                        <div>
                            {cellProps.dataItem?.relativeDate
                                ? stringifyDate(cellProps.dataItem.relativeDate, DATE_FORMATS.DISPLAY_UI)
                                : ''}
                        </div>
                    </td>
                ),
                id: ColIds.RelativeDate,
            },
            {
                field: 'startTime',
                title: t('startTime'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.StartTime, 'width', 80),
                orderIndex: getOrderIndexVal(ColIds.StartTime),
                id: ColIds.StartTime,
            },
            {
                field: 'endTime',
                title: t('endTime'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.EndTime, 'width', 80),
                orderIndex: getOrderIndexVal(ColIds.EndTime),
                id: ColIds.EndTime,
            },
            {
                field: 'description',
                title: t('description'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.Description,
                    'width',
                    getWidth(t('description'))
                ),
                orderIndex: getOrderIndexVal(ColIds.Description),
                id: ColIds.Description,
            },
            {
                field: 'duration',
                title: t('duration'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.Duration, 'width', 50),
                orderIndex: getOrderIndexVal(ColIds.Duration),
                id: ColIds.Duration,
            },
            {
                field: 'km',
                title: t('km'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.Km, 'width', 50),
                orderIndex: getOrderIndexVal(ColIds.Km),
                id: ColIds.Km,
            },
            {
                field: 'passengersCount',
                title: t('passengersCount'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    ColIds.PassengersCount,
                    'width',
                    getWidth('passengersCount')
                ),
                orderIndex: getOrderIndexVal(ColIds.PassengersCount),
                id: ColIds.PassengersCount,
            },
            {
                field: 'routeCode',
                title: t('routeCode'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.RouteCode, 'width', 80),
                orderIndex: getOrderIndexVal(ColIds.RouteCode),
                id: ColIds.RouteCode,
            },
            {
                field: 'carNumber',
                title: t('carNumber'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.CarNumber, 'width', 70),
                orderIndex: getOrderIndexVal(ColIds.CarNumber),
                id: ColIds.CarNumber,
            },
            {
                field: 'carType',
                title: t('carType'),
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.CarType, 'width', 70),
                orderIndex: getOrderIndexVal(ColIds.CarType),
                id: ColIds.CarType,
            },
            {
                field: 'driverName',
                title: t('driverName'),
                id: ColIds.DriverName,
                minResizableWidth: 50,
                width: getFieldValueByLocalStorage(
                    localStorageCols,
                    'driverName',
                    'width',
                    isPrinting ? 150 : 70
                ),
                orderIndex: getOrderIndexVal(ColIds.DriverName),
            },
        ],
        [t, localStorageCols, getOrderIndexVal, isPrinting]
    );

    useEffect(() => {
        if (hasBudgetFeature && !columns.some((column) => column.field === VISA_COST_FIELD_NAME)) {
            columns.push({
                field: VISA_COST_FIELD_NAME,
                title: t('price'),
                width: getFieldValueByLocalStorage(localStorageCols, ColIds.VisaClientPrice, 'width', 70),
                orderIndex: getOrderIndexVal(ColIds.VisaClientPrice),
                minResizableWidth: 20,
                editor: 'numeric',
                id: ColIds.VisaClientPrice,
            });
        }
    }, [hasBudgetFeature]);

    return { columns };
};

export default useVisasReportColumns;
