/* eslint-disable no-param-reassign */

import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DropDown from 'src/components/DropDown/DropDown';
import { getClientFilterProp } from 'src/screens/RideSettings/components/props/props';
import useClientFilters from 'src/hooks/useClientFilter';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    formSelector,
    openPassengersFormAction,
    selectedClientOnFiltersSelector,
} from 'src/store/slices/lines/linesSlice';

import ControlledManualAndGoogleAddressInput from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import { FEATURES_IDS } from 'src/constants/auth';
import { DropDownState } from 'src/screens/PassengerManger/components/Passengers/hooks/useFilters';
import { StyledInputContainer, StyledSectionContainer } from '../../styles';
import { MainFormInputConfig, Inputs } from '../../types';
import ControlledDatePicker from '../Inputs/DatePicker';
import GenericTextInput from '../Inputs/GenericTextInput';
import AddPassengersOrStationBtns from './components/stations/AddPassengersOrStationBtns/AddPassengersOrStationBtns';
import StationsInputsBox from './components/stations/StationsInputsBox';
import { fullFormHooks, fullFormHooks as hooks } from './hooks.FullForm';
import TripDirectionDropdown from './components/TripDirectionDropdown';
import TripTimesInput from './components/TripTimesInput';
import CarTypeDropdown from './components/CarTypeDropdown/CarTypeDropdown.index';
import GenericTextInputV2 from '../../../../../../components/Inputs/GenericTextInputV2';
import DropDownV2 from 'src/components/DropDown/DropDownV2';
import numberHelpers from 'src/utilis/numberHelpers';
import { DisplayTimeFromPrev } from './components/DisplayTimeFromPrev';
import { DisplayLineTotals } from './components/DisplayLineTotals';
import { isAllObjectsContainsCoords } from 'src/screens/Main/hooks/useDirectionsApiLines';

const featureId = FEATURES_IDS.ADD_LINE;

const GeneralDetailsInputsSections = (): JSX.Element => {
    // * hooks
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { onChange: onPassQtyChange, inputValue } = fullFormHooks.usePassQty();

    const importedCourseCode = useRootAppSelector((state) => formSelector(state).importedCourseCode);
    const accountCourses = useRootAppSelector((state) => state.linesSlice.data.getAccountCourses.data);
    const { isOnEdit, editedLineClientId } = useRootAppSelector((state) => state.linesSlice.ui.form);
    const { endAddress, startAddress, stations } = useRootAppSelector(
        (state) => state.linesSlice.ui.form.data
    );

    const getTotals = () => {
        const relevantStations = [...stations, endAddress];

        const totalTime = relevantStations.reduce((acc, station) => {
            if (station && station.timing) {
                acc += station.timing;
            }
            return acc;
        }, 0);

        const totalDistance = relevantStations.reduce((acc, station) => {
            if (station && station.distanceFromPrevStation) {
                acc += station.distanceFromPrevStation;
            }
            return acc;
        }, 0);

        return { totalTime, totalDistance };
    };

    let currency = '₪';

    if (importedCourseCode && accountCourses) {
        const currentCourse = accountCourses.find((course) => {
            return importedCourseCode === course.courseCode;
        });

        if (currentCourse !== undefined && currentCourse.currencyId) {
            currency = currentCourse.currencyId;
        }
    }

    fullFormHooks.useSyncCarIdToSlice();

    fullFormHooks.useSelectedCarData();

    const fullFormMethods = useFormContext<Inputs>();
    const {
        register,
        formState: { errors },
        getValues,
    } = fullFormMethods;

    const linePrice = getValues('linePrice');

    const { disabledFields } = fullFormHooks.useDisabledFields(linePrice);

    const { addressProps } = hooks.useAddressData({ isDisabled: disabledFields.includes('startAddress') });

    const { updateValue } = hooks.useHelpers();

    const stationInputsHandling = hooks.useStationInputsHandling();

    const [clientFilter, setClientFilter] = useClientFilters({
        routeScreenId: featureId,
        isPrivateRoute: true,
    });

    // * Selectors
    const selectedClientIdOnTable = useRootAppSelector(selectedClientOnFiltersSelector);

    // * Variables
    const remarksInputConfig: MainFormInputConfig = useMemo(
        () => ({
            register,
            errors,
            name: 'lineRemarks',
        }),
        [errors, register]
    );

    const lineDescriptionConfig: MainFormInputConfig = useMemo(
        () => ({
            register,
            errors,
            name: 'description',
        }),
        [errors, register]
    );

    const passQtyInputConfig = {
        errors,
        name: 'passQty',
        type: 'number',
        onChange: onPassQtyChange,
        value: inputValue,
        disabled: disabledFields.includes('passQty'),
    };

    // * effects
    useEffect(() => {
        // -- updates client input on change
        if (clientFilter.value) {
            updateValue('clientCode', +clientFilter.value);
        }
    }, [clientFilter, updateValue]);

    useEffect(() => {
        // -- sets the client filter as the one chosen on the table filters
        setClientFilter((prev: DropDownState) => ({
            ...prev,
            value: editedLineClientId ? editedLineClientId : selectedClientIdOnTable,
        }));
    }, [selectedClientIdOnTable, setClientFilter]);

    const clientDropdownProps = React.useMemo(() => {
        const props = getClientFilterProp({
            t,
            clinetFilter: clientFilter,
            setClinetFilter: setClientFilter,
            style: { flex: 1 },
        });
        props.labelName = 'שם אתר';
        props.selectProps.disabled = disabledFields.includes('clientCode');

        return props;
    }, [clientFilter, disabledFields, setClientFilter, t]);

    return (
        <StyledSectionContainer>
            <StyledInputContainer>
                <DropDownV2 {...clientDropdownProps} />
                <TripDirectionDropdown disabled={disabledFields.includes('tripDirection') || isOnEdit} />
            </StyledInputContainer>

            <StyledInputContainer style={{ paddingTop: 20 }} className="StyledInputContainer">
                <ControlledDatePicker />
            </StyledInputContainer>

            <div style={{ paddingTop: 3 }}>
                <TripTimesInput />
            </div>

            <StyledInputContainer style={{ paddingTop: 10 }} className="StyledInputContainer">
                <GenericTextInput {...lineDescriptionConfig} />
            </StyledInputContainer>

            <StyledInputContainer style={{ paddingTop: 20 }} gap="10px" className="StyledInputContainer">
                <CarTypeDropdown />
                <GenericTextInputV2 {...passQtyInputConfig} />
            </StyledInputContainer>

            <StyledInputContainer
                style={{
                    padding: '15px 0px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                }}
                withBottomBorder
                className="StyledInputContainer"
            >
                <div style={{ fontSize: '14px' }}>
                    {t('priceBeforeVat')}:{' '}
                    <strong>
                        {linePrice ? `${numberHelpers.formatNumberWithCommas(linePrice)} ${currency}` : '-'}
                    </strong>
                </div>

                <div style={{ fontSize: '14px', color: 'red' }}>
                    <div>{linePrice ? 'למסלול זה מוגדר מחיר - חלק מהשדות יהיו נעולים לשינוי' : ''}</div>
                </div>
            </StyledInputContainer>

            <div style={{ paddingTop: 10 }}>
                <AddPassengersOrStationBtns
                    addStation={stationInputsHandling.addStation}
                    stationsCount={stationInputsHandling.stationsIdsOnStore.length}
                    addPassengers={() => dispatch(openPassengersFormAction({}))}
                    disabled={disabledFields.includes('stations')}
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginBottom: '20px' }}>
                {/* Start Address */}
                <div style={{ paddingTop: 20 }}>
                    <ControlledManualAndGoogleAddressInput
                        {...addressProps.start}
                        disabled={disabledFields.includes('startAddress')}
                    />
                </div>

                {/* Stations */}
                {stationInputsHandling.stationsIdsOnStore.length ? (
                    <div style={{ paddingRight: '40px' }}>
                        <StationsInputsBox
                            stationInputsHandling={stationInputsHandling}
                            isDisabled={disabledFields.includes('stations')}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {/* End Address */}
            </div>
            {isAllObjectsContainsCoords([startAddress, endAddress, ...stations]) &&
            endAddress &&
            endAddress.timing &&
            endAddress.timing !== 0 ? (
                <DisplayTimeFromPrev time={endAddress.timing} />
            ) : null}
            <ControlledManualAndGoogleAddressInput
                {...addressProps.end}
                disabled={disabledFields.includes('endAddress')}
            />

            {isAllObjectsContainsCoords([startAddress, endAddress, ...stations]) &&
            endAddress &&
            endAddress.timing &&
            endAddress.timing !== 0 ? (
                <DisplayLineTotals time={getTotals().totalTime} distance={getTotals().totalDistance} />
            ) : null}
            <StyledInputContainer style={{ paddingTop: 20 }} className="StyledInputContainer">
                <GenericTextInput {...remarksInputConfig} />
            </StyledInputContainer>
        </StyledSectionContainer>
    );
};

export default GeneralDetailsInputsSections;
