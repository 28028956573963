import { ReqStatus } from 'src/api/types';

import { ActiveStatus } from '../../../types/global';
import { FormState, RideSettingsState, SettingTypes } from '../../../types/rideSettings/store/types.store';

export const initialSubmitConfigs = {
    endpoint: null,
    stringify: false,
    dataParamName: '',
};
export const initialAccordionExpansionState = {
    generalSettings: false,
    permissionManagement: false,
    accountDepartments: false,
    shiftManagement: false,
    orderTimesManagement: false,
};

export const initialFormState: FormState = {
    ui: {
        isOpen: false,
        isOnEditMode: null,
        currentForm: null,
    },
    valuesByForm: {
        generalSettings: null,
        permissionManagement: null,
    },
    apiCallsStatus: {
        formSubmitStatus: ReqStatus.ON_STAND_BY,
        responseMsg: '',
    },
    apiSettings: {
        submitConfigs: initialSubmitConfigs,
    },
};

// * Initial states
export const initialState: RideSettingsState = {
    data: {
        generalSettings: {
            type: SettingTypes.GeneralSettings,
            settings: { hidePassPhone: null, firstDayOfWeek: null, languageForAddresses: 'he' },
            loadingSettingsStatus: ReqStatus.ON_STAND_BY,
        },
        permissionManagement: {
            type: SettingTypes.PermissionManagement,
            settings: { permissions: [] },
            loadingSettingsStatus: ReqStatus.ON_STAND_BY,
        },
        accountDepartments: {
            type: SettingTypes.AccountDepartments,
            settings: {},
            loadingSettingsStatus: ReqStatus.ON_STAND_BY,
        },
        shiftManagement: {
            type: SettingTypes.ShiftManagement,
            settings: {},
            loadingSettingsStatus: ReqStatus.ON_STAND_BY,
        },
        orderTimesManagement: {
            type: SettingTypes.OrderTimesManagement,
            settings: {},
            loadingSettingsStatus: ReqStatus.ON_STAND_BY,
        },
    },
    clientData: {
        clientCode: null,
    },
    ui: {
        settingsAccordionsExpansion: initialAccordionExpansionState,
        filters: {
            activeStatus: ActiveStatus.active,
        },
        common: { error: { msg: '' } },
    },
    form: initialFormState,
};
