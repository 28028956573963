import React, { FC, useEffect, useRef, useState } from 'react';
import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    lastNewPassengerFormSubmitSelector,
    setIsInjectionCompletedAction,
} from 'src/store/slices/lines/linesSlice';
import {
    useBtnsConfig,
    useCurrFormType,
    usePassengersDetailsFetching,
    useFullForm,
    useGetBtnsConfigArgs,
    usePassengersForm,
    useScroll,
} from './addLinesForm.hooks';
import { StyledFormBodyContainer, StylingCompatibilityWrapper } from './styles';
import FullForm from './components/subForms/FullForm';
import PassengersForm from './components/subForms/PassengersForm';
import { getFormHeader } from './utils';
import StoredCoursesFormBody from './components/subForms/storedCourses/StoredCoursesFormBody/StoredCoursesFormBody.index';
import { FormProvider } from 'react-hook-form';
import { storedCoursesHooks } from './components/subForms/storedCourses/storedCourses.hooks';
import WrapperLoadingIndication from 'src/components/Wrappers/WrapperLoadingIndication';

const useSelectors = () => {
    const lastNewPassengerFormSubmit = useAppSelector(lastNewPassengerFormSubmitSelector);
    return { lastNewPassengerFormSubmit };
};

type Props = {};
const AddLinesForm: FC<Props> = (): JSX.Element => {
    // -- selectors
    const { status: setLineStatus } = useRootAppSelector((state) => state.linesSlice.data.setLine);
    const dispatch = useAppDispatch();
    const { lastNewPassengerFormSubmit } = useSelectors();

    const formBodyRef = useRef<any>(null);

    const [isSubmittingCourse, setIsSubmittingCourse] = useState(false);

    // -- util hooks -------------------------------------------------------------------
    // & Form Methods -----
    const fullFormMethods = useFullForm();

    const { handleFullFormSubmit, getValues } = fullFormMethods;

    const passengersFormMethods = usePassengersForm();

    const {
        passengersForm,
        handlePassengersFormSubmit,
        onCancel: onNewPassengersCancel,
        passengersGetValues,
        reset: resetNewPassengerForm,
    } = passengersFormMethods;

    // & Btns config
    const courseImportDialogControl = storedCoursesHooks.useDialogControl(
        setIsSubmittingCourse,
        fullFormMethods.fullForm.setValue
    );

    const btnsConfigArgs = useGetBtnsConfigArgs({
        handlePassengersFormSubmit,
        handleFullFormSubmit,
        onNewPassengersCancel,
        passengersGetValues,
        clientCode: String(getValues('clientCode')),
        fullFormMethods: fullFormMethods.fullForm,
        dialogControl: courseImportDialogControl,
        submissionStatusState: {
            isSubmittingCourse,
            setIsSubmittingCourse,
        },
    });

    const btnsConfig = useBtnsConfig(btnsConfigArgs);

    const currForm = useCurrFormType();

    // & UI hooks
    const scroll = useScroll(formBodyRef);

    // & data hooks
    usePassengersDetailsFetching({ clientCode: getValues('clientCode') });
    // --------------------------------------------------------------------------------------

    // -- effects
    useEffect(() => {
        // -- resetting the new passenger form after successful submission
        if (lastNewPassengerFormSubmit) {
            resetNewPassengerForm();
        }
    }, [lastNewPassengerFormSubmit, resetNewPassengerForm]);

    useEffect(() => {
        return () => {
            // this will run on unmount
            dispatch(setIsInjectionCompletedAction({ isInjectionCompleted: false }));
        };
    }, []);

    // -- jsx
    return (
        <StylingCompatibilityWrapper className="StylingCompatibilityWrapper">
            {/* <Slide in direction="up" timeout={{ enter: 2900, exit: 1000 }}> */}
            <WrapperLoadingIndication isLoading={setLineStatus === 'LOADING'}>
                <WidgetFormWrapper
                    btnsConfig={btnsConfig}
                    HeaderComponent={getFormHeader(currForm, btnsConfigArgs)}
                    formBodyRef={formBodyRef}
                    onScroll={scroll.onScroll}
                    pd="10px 24px 0 0px"
                >
                    <StyledFormBodyContainer className="FormBodyContainer">
                        <FormProvider {...(fullFormMethods.fullForm as any)}>
                            <FullForm
                                reachedBottomOfForm={scroll.reachedBottomOfForm}
                                isOverflowing={scroll.isOverflowing}
                            />
                            {currForm === 'storedCourses' ? (
                                <StoredCoursesFormBody
                                    clientCode={String(getValues('clientCode'))}
                                    date={getValues('date')}
                                    dialogControl={courseImportDialogControl}
                                />
                            ) : (
                                <></>
                            )}
                        </FormProvider>
                        <PassengersForm form={passengersForm} />
                    </StyledFormBodyContainer>
                </WidgetFormWrapper>
            </WrapperLoadingIndication>
            {/* </Slide> */}
        </StylingCompatibilityWrapper>
    );
};

export default AddLinesForm;

// const [reachedBottomOfForm, setReachedBottomOfForm] = useState(false);

// const onScroll = useCallback(() => {
//     if (formBodyRef.current) {
//         const { scrollTop, scrollHeight, clientHeight } =
//             formBodyRef.current;
//         // console.log({ scrollTop, clientHeight, scrollHeight });
//         const diff = scrollHeight - (scrollTop + clientHeight);
//         if (diff >= 0 && diff < 1) {
//             console.log('reached bottom');
//             setReachedBottomOfForm(true);
//             return;
//         }
//         setReachedBottomOfForm(false);
//     }
// }, []);
