import React from 'react';
import useCommons from 'src/hooks/common/useCommons';
import { useRootAppSelector } from 'src/store/hooks';
import {
    courseBuildingFormSelector,
    setFormIsReadyToDisplayAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

const useFormDisplayDelay = () => {
    const { dispatch } = useCommons();

    const isFormReadyToDisplay = useRootAppSelector(
        (state) => courseBuildingFormSelector(state).isReadyToDisplay
    );

    React.useEffect(() => {
        if (isFormReadyToDisplay) return;

        const timeout = setTimeout(() => {
            dispatch(setFormIsReadyToDisplayAction(true));
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [dispatch, isFormReadyToDisplay]);

    return { showSpinner: !isFormReadyToDisplay };
};

export default useFormDisplayDelay;
