/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import MyTimePicker from 'src/components/ReactHookFormInputs/TimePicker/TimePicker';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import moment from 'moment';
import cotValidationHooks from 'src/hooks/reactHookForm/useCotValidation';
import { Inputs } from '../../types';
import { startTimeSchema } from '../../schema';
import { fullFormHooks } from '../formSections/hooks.FullForm';

const ControlledTimePicker: React.FunctionComponent<{
    label?: string;
    inputName: string;
}> = (props) => {
    const { t } = useTranslation();

    const formMethods = useFormContext<Inputs>();
    const {
        formState: { errors },
        watch,
    } = formMethods;

    cotValidationHooks.useCotValidation(formMethods);

    return (
        <InputWithErrorsWrapper
            errorMessage={t(getAsTemplateStrArr(errors[props.inputName as any]?.message || ''))}
        >
            <MyTimePicker
                formMethods={formMethods}
                textFieldProps={{
                    label: props.label,
                    error: !!errors[props.inputName as any],
                }}
                inputName={props.inputName}
            />
        </InputWithErrorsWrapper>
    );
};

export default ControlledTimePicker;
