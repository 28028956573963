import styled, { CSSObject, css } from 'styled-components';

const StyledDisabledDiv = styled.div<{ disabled: boolean; style?: CSSObject }>`
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.7;
            transition: opacity 0.3s ease;
        `}
    ${({ style = {} }) => css(style)}
`;

export default StyledDisabledDiv;
