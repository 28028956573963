import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 610px;
    height: 412px;
`;

interface Props {
    children: React.ReactNode;
}

const Card = ({ children }: Props): JSX.Element => {
    return <Container>{children}</Container>;
};

export default Card;
