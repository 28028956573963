import { DefaultRootState } from 'react-redux';
import { Reducer } from 'redux';

import { ILine } from 'src/types/line';
import { ReqStatus } from 'src/api/types';
import { LineActionType } from '../actions/actionType';
import { IReduxProvider } from '../type';

export interface ILineState extends DefaultRootState {
    lines: Array<ILine>;
    selectedLine?: ILine;
    loadingStatus: ReqStatus;
}

const initialState: ILineState = {
    lines: [],
    loadingStatus: ReqStatus.SUCCESS,
};

const onRetriveLines = (state: any, payload: any): any => {
    return {
        ...state,
        loadingStatus: ReqStatus.LOADING,
    };
};

const onRetriveLinesSuccess = (state: ILineState, payload: any): any => {
    return {
        ...state,
        lines: [...payload],
        loadingStatus: ReqStatus.SUCCESS,
    };
};

const removeLine = (state: ILineState, payload: any) => {
    return {
        ...state,
        lines: state.lines.filter((line) => line.lineCode !== payload.deletedLineCode),
    };
};

const onRetriveLinesFail = (state: any, payload: any): any => {
    return {
        ...state,
        loadingStatus: ReqStatus.FAIL,
    };
};

const onSelectedLineChange = (state: any, payload: any): ILineState => {
    return {
        ...state,
        selectedLine: payload,
        loadingStatus: ReqStatus.FAIL,
    };
};

const handlerTypes: {
    [index: string]: IReduxProvider<ILineState>;
} = {
    [LineActionType.retriveLines]: onRetriveLines,
    [LineActionType.retriveLineSuccess]: onRetriveLinesSuccess,
    [LineActionType.retriveLineFaild]: onRetriveLinesFail,
    [LineActionType.selectedLineChange]: onSelectedLineChange,
    [LineActionType.removeLine]: removeLine,
};

const lineReducer = (
    state = initialState,
    { type, payload }: { type: LineActionType; payload: ILineState }
): any => {
    const handler = handlerTypes[type];
    if (handler) {
        return handler(state, payload);
    }
    return state;
};

export default lineReducer;
