import React, { FunctionComponent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { v4 as uuid } from 'uuid';
import { InputLabelProps, MenuItem, SxProps, Theme } from '@mui/material';
import { DefaultMenuProps, LoadingDropdown } from './DropDown';

export interface DropDownV2MenuItem {
    name: string;
    value: string | number | undefined;
    disabled?: boolean;
    child?: JSX.Element;
    sx?: SxProps<Theme>;
}

export interface SelectInputProps extends SelectProps {
    menuItems: Array<DropDownV2MenuItem>;
}
export interface IDropdownV2Config {
    labelName: string;
    selectProps: SelectInputProps;
    inputLabelProps?: InputLabelProps;
}
export interface IDropdownV2Props extends IDropdownV2Config {
    formControlProp: FormControlProps;
    isLoading?: boolean;
}

export type DropdownOnChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

const DropDownV2: FunctionComponent<IDropdownV2Props> = ({
    labelName,
    formControlProp,
    selectProps,
    inputLabelProps = {},
    isLoading = false,
}) => {
    const labelId = uuid();

    const updatedInputLabelProps = {
        ...inputLabelProps,
        disableAnimation: true,
    } as const;

    return (
        <FormControl {...formControlProp}>
            {isLoading ? (
                <LoadingDropdown />
            ) : (
                <>
                    <InputLabel id={labelId} {...(updatedInputLabelProps as any)}>
                        {labelName}
                    </InputLabel>
                    <Select
                        labelId={labelId}
                        style={{ backgroundColor: 'white' }}
                        label={labelName}
                        {...selectProps}
                        MenuProps={{ ...(selectProps.MenuProps || {}), ...DefaultMenuProps }}
                    >
                        {selectProps.menuItems.map((d: DropDownV2MenuItem) => (
                            <MenuItem disabled={d.disabled} sx={d.sx} key={d.value} value={d.value}>
                                {d.child || d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}
        </FormControl>
    );
};

export default DropDownV2;
