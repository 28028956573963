import styled, { css, CSSObject } from 'styled-components';

const Header = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
`;
const Icon = styled.img<{
    src: string;
}>`
    background: #ffedb1;
    border-radius: 50%;
    padding: 3px;
`;
const HeaderText = styled.div`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    text-align: right;
    color: #494949;
`;

const Card = styled.div`
    /* flex: 1; */
    background: white;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: flex-start; */
    background: #ffffff;
    height: 100%;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */
    /* border-radius: 15px; */
`;

export const CourseInfoWidgetStyles = {
    Header,
    Icon,
    HeaderText,
    Card,
};

// #### StationsAndTimesPanelStyled ###############################################################

const FiltersBar = styled.div<{}>`
    display: flex;
    background: #e6f6fe;
    padding: 8px;
    width: 100%;
`;

const City = styled.span<{}>`
    font-weight: 400;
    font-size: 16px;
    color: #494949;
`;

const ExtraData = styled.span<{}>`
    font-weight: 400;
    font-size: 16px;
    color: #494949;
`;

const TravelTime = styled.div`
    background: #ffedb1;
    border-radius: 4px;
    font-size: 14px;
    color: #494949;
    width: 120px;
    text-align: center;
`;

const Circle = styled.div<{ bordered?: boolean; isForStationsBuildingForm?: boolean }>`
    position: absolute;
    width: 10px;
    height: 10px;
    right: -16px;
    top: 7px;
    background: #2196f3;
    border-radius: 50%;
    ${({ bordered, isForStationsBuildingForm }) =>
        bordered &&
        css`
            border: 3px solid #c5e5ff;
            width: 16px;
            height: 16px;
            ${isForStationsBuildingForm ? '' : 'right: -19px'};
            ${isForStationsBuildingForm ? '' : 'top: 4px'};
        `};
    ${({ isForStationsBuildingForm, bordered }) =>
        isForStationsBuildingForm
            ? css`
                  right: auto;
                  left: ${bordered ? '5px' : '8px'};
                  top: ${bordered ? '-8px' : '-6px'};
              `
            : ''};
`;

const StationDataContainer = styled.div`
    text-align: right;
    padding: 5px;
    border-right: 1px dashed #c5e5ff;
    max-height: 300px;
    overflow: auto;
`;
export const StationsAndTimesPanelStyled = {
    FiltersBar,
    City,
    ExtraData,
    TravelTime,
    Circle,
    StationDataContainer,
};

// #### CourseTypeTabs styles ###############################################################
const Detail = styled.div<{ withoutBottomBorder?: boolean; style?: CSSObject }>`
    text-align: right;
    ${({ withoutBottomBorder: isLast }) =>
        isLast ? '' : 'border-bottom: 1px solid rgba(180, 190, 201, 0.303);'};
    ${({ style }) => css(style || {})};
`;

const DetailLineContainer = styled.div<{ style?: CSSObject }>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 5px;

    ${({ style }) => css(style || {})}
`;

const Container = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    color: #494949;
`;
const ValueText = styled.span`
    font-size: 16px;
    text-align: right;
    color: #494949;
`;

const StationDataItemContainer = styled.div<{ isFirst: boolean }>`
    ${({ isFirst }) => css`
        text-align: right;
        display: flex;
        padding: ${isFirst ? '5px' : '0px 5px 5px 5px'};
        border-right: 1px dashed #c5e5ff;
    `}
`;

export const StyledCourseTypeTabs = {
    Detail,
    DetailLineContainer,
    Container,
    Title,
    ValueText,
    StationDataItemContainer,
};

export const cssStyles = {};
