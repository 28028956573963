/* eslint-disable import/no-cycle */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './index';
import { IRootReducer } from './reducers';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): any => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootReducer> = useSelector;

export const useRootAppSelector: TypedUseSelectorHook<IRootReducer> = useSelector;
