import styled, { css, CSSObject, keyframes } from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import { CSSProperties } from 'react';

const KeyframeContainer = keyframes`
  0% { transform: translateY(100px); }
  100% { transform: translateY(0px);}
`;

export const shakeAnimation = keyframes`
   0% { transform: translate(1px, 1px) rotate(0deg); }
   10% { transform: translate(-1px, -2px) rotate(-1deg); }
   20% { transform: translate(-3px, 0px) rotate(1deg); }
   30% { transform: translate(3px, 2px) rotate(0deg); }
   40% { transform: translate(1px, -1px) rotate(1deg); }
   50% { transform: translate(-1px, 2px) rotate(-1deg); }
   60% { transform: translate(-3px, 1px) rotate(0deg); }
   70% { transform: translate(3px, 1px) rotate(-1deg); }
   80% { transform: translate(-1px, -1px) rotate(1deg); }
   90% { transform: translate(1px, 2px) rotate(0deg); }
   100% { transform: translate(1px, -2px) rotate(-1deg); }
`;
export const Container = styled('div')<{ blockedTableClickCount?: number }>`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 98%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px 0px 20px 0px #00000033;
    padding: 20px;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    text-align: right;
    position: relative;
    // Animation
    animation: ${({ blockedTableClickCount }) => blockedTableClickCount && shakeAnimation};
    animation-duration: 0.3s;
`;

export const Title = styled.div`
    font-size: 18px;

    span {
        font-size: 14px;
        margin-right: 4px;
    }
`;

export const Hr = styled.div`
    width: 100%;
    height: 2px;
    background-color: #eef1f5;
    margin: 10px 0;
`;

export const TimeContainer = styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
`;

export const StyledTabs = styled(Tabs)`
    font-size: 16px;
    .MuiTab-root {
        width: 50%;
    }
    display: block;
    &:after {
        content: '';
        display: block;
        height: 0.5px;
        width: 100%;
        margin-top: -5px;
        background: #b4bec9;
    }
`;
export const TabContainer = styled.div`
    margin-top: 15px;
`;

export const DaysContainer = styled.div`
    margin-top: 15px;
`;

export const Footer = styled.div`
    border-top: 1px solid #2196f3;
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
`;
