import { warningRed } from 'src/components/NavBar/NavBar.style';
import styled from 'styled-components';

const isTesting = 0;

const HeaderContainer = styled.div<{ withWarningColors: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    // border: 1px solid orange;
    padding: 12px 1.6875em;
    ${(props) => {
        return props.withWarningColors
            ? `
                background-color: ${warningRed};
                color: white;
                
            `
            : ``;
    }}
`;

const HeaderDropdownContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: flex;
    // border: 1px solid cyan;
    gap: 20px;
`;

const filtersMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
    // border: 1px solid blue;
`;
const psngrCountAndClearFiltersContainer = styled.div`
    width: 100%;
    display: flex;
    // border: 1px solid yellow;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6875em;
`;
const filtersDropdownContainer = styled.div`
    width: 100%;
    display: flex;
    // border: 1px solid purple;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 1.6875em;
`;
export const linesContainers = {
    HeaderDropdownContainer,
    HeaderContainer,
    filtersDropdownContainer,
    filtersMenuContainer,
    psngrCountAndClearFiltersContainer,
};
