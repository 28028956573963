// Transit Request

const MAIN_URL =
    'https://interfaceserviceapi.y-it.co.il/MyWayApplicationsFiles/MyWayApplicationsFilesService.svc';

const ENDPOINTS = {
    UPLOAD_FILE: 'json_ws_MyWay_Upload_File',
    GET_FILE: 'json_ws_MyWay_Get_File',
    DELETE_FILE: 'json_ws_MyWay_Delete_File',
} as const;

export const uploadFileConstants = {
    MAIN_URL,
    ENDPOINTS,
} as const;
