import React from 'react';
import AddIconNoText from 'src/components/AddIconNoText/AddIconNoText';
import DropDown from 'src/components/DropDown/DropDown';
import styles from 'src/components/StyledComponents/StyledComponents.style';
import useCommons from 'src/hooks/common/useCommons';
import { linesContainers } from 'src/screens/Main/components/Lines/styles';
import { colors } from 'src/style/themes/defaultTheme';
import SearchInput from 'src/components/SearchInput/SearchInput';
import { CountAndClearFiltersContainer, FiltersDropdownContainer } from '../styles/styles';
import {
    courseBuildingFormSelector,
    coursesBuildingSelectors,
    openCourseFormAction,
    resetCourseFormAction,
    resetFiltersAction,
    setSelectedCourseIdAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import filtersHooks from '../hooks/filtersHooks';
import { useRootAppSelector } from 'src/store/hooks';
import { MuiTooltipV3 } from 'src/components/MuiTooltip/MuiTooltip';

const filterTextCss = {
    margin: '0px',
    color: colors.gray,
    fontSize: '15px',
    fontWeight: 400,
    textDecoration: 'underline',
};

type Props = {
    filteredCoursesLst: WsCourseDataItem[];
};

const Filters = ({ filteredCoursesLst }: Props) => {
    const { t, dispatch } = useCommons();

    const { searchDDProp, departmentDDProp, lineNumDDProp, courseTypeDDProp } =
        filtersHooks.useFiltersDropdownsProps();

    const formSlice = useRootAppSelector(courseBuildingFormSelector);

    const resetFilters = () => {
        dispatch(resetFiltersAction());
    };

    const handleAddCourseClick = () => {
        dispatch(setSelectedCourseIdAction(''));

        // if currently editing a course we reset the form
        if (formSlice.courseCode && formSlice.isOpen) {
            dispatch(resetCourseFormAction({ keepOpen: true }));
            return;
        }

        // else
        dispatch(openCourseFormAction({ courseCode: '' }));
    };

    return (
        <linesContainers.filtersMenuContainer className="filtersMenuContainer">
            <CountAndClearFiltersContainer className="CountAndClearFiltersContainer">
                <styles.FilterText>{`${t('coursesList')} (${filteredCoursesLst.length})`}</styles.FilterText>
                <styles.Btn
                    size="small"
                    disabled={false}
                    onClick={() => {
                        resetFilters();
                    }}
                >
                    <styles.FilterText style={filterTextCss}>{t('clearFilter')}</styles.FilterText>
                </styles.Btn>
            </CountAndClearFiltersContainer>
            <FiltersDropdownContainer className="FiltersDropdownContainer">
                <AddIconNoText withDivider disabled={false} onClick={handleAddCourseClick} />

                <SearchInput onChange={searchDDProp.onChange} style={{ flex: 3 }} />

                <div style={{ flex: 2 }}>
                    <DropDown {...lineNumDDProp} />
                </div>
                <div style={{ flex: 2 }}>
                    <DropDown {...courseTypeDDProp} />
                </div>
                <div style={{ flex: 2 }}>
                    <DropDown {...departmentDDProp} />
                </div>
            </FiltersDropdownContainer>
        </linesContainers.filtersMenuContainer>
    );
};

export default Filters;
