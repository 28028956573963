import i18n from 'src/i18n';

export const SET_ORDERS_REQ_ERROR_STINGS = {
    1: i18n.t('invalidToken'),
    2: i18n.t('dateOrTimeBeforeCurrent'),
    3: i18n.t('invalidDepartment'),
    4: i18n.t('sqlError'),
    5: i18n.t('blockedPickupUp'),
    6: i18n.t('blockedDrop'),
    7: i18n.t('blockedForToday'),
    8: i18n.t('blockedForTomorrow'),
    9: i18n.t('blockedForWeekend'),
    10: i18n.t('blockedForSunday'),
    11: i18n.t('clientNotFound'),
};
export const DEL_ORDERS_REQ_ERROR_STINGS = {
    1: i18n.t('invalidToken'),
    2: i18n.t('dateOrTimeBeforeCurrent'),
    3: i18n.t('invalidDepartment'),
    4: i18n.t('sqlError'),
    5: i18n.t('delBlockedPickupUp'),
    6: i18n.t('delBlockedDrop'),
    7: i18n.t('delBlockedForToday'),
    8: i18n.t('delBlockedForTomorrow'),
    9: i18n.t('delBlockedForWeekend'),
    10: i18n.t('delBlockedForSunday'),
    11: i18n.t('clientNotFound'),
};

export const EDIT_DEL_ORDERS_REQ_ERROR_STINGS = {
    1: i18n.t('invalidToken'),
    2: i18n.t('dateOrTimeBeforeCurrent'),
    3: i18n.t('invalidDepartment'),
    4: i18n.t('sqlError'),
    5: i18n.t('editDelBlockedPickupUp'),
    6: i18n.t('editDelBlockedDrop'),
    7: i18n.t('editDelBlockedForToday'),
    8: i18n.t('editDelBlockedForTomorrow'),
    9: i18n.t('editDelBlockedForWeekend'),
    10: i18n.t('editDelBlockedForSunday'),
    11: i18n.t('clientNotFound'),
};

export const manualOrdersApiConsts = {
    SET_ORDERS_REQ_ERROR_STINGS,
    DEL_ORDERS_REQ_ERROR_STINGS,
    EDIT_DEL_ORDERS_REQ_ERROR_STINGS,
};
