import React, { useState } from 'react';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { apiDataSelector, uiSelector } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import styled, { css, keyframes } from 'styled-components';
import { useRootAppSelector } from 'src/store/hooks';

const pulseAnimation = keyframes`
    0% {
    };
    50% {
        padding: 5px;
        transform: scale(1.2);
    }
    100% {
    }
`;

export const AnimatedBtnContainer = styled.div<{ animate: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    ${({ animate }) =>
        animate &&
        css`
            animation: ${pulseAnimation} 0.5s;
        `}
`;

const useTimesDisplayAnimation = (totalTime: unknown) => {
    const [animate, setAnimate] = useState(false);
    const isFirstRender = useIsFirstRender();

    const allStations = useRootAppSelector((state) => apiDataSelector(state).getCourseStations);

    const fieldsCopy = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map.fieldsCopy);

    React.useEffect(() => {
        if (isFirstRender || !fieldsCopy.length || animate || allStations.status !== 'SUCCESS') return;

        const initialTimes = allStations.data.reduce((acc, curr) => acc + (curr?.timeFromPrev || 0), 0);

        if (totalTime === initialTimes) return;

        setAnimate(true);
    }, [totalTime]);

    React.useEffect(() => {
        if (!animate) return;
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 1500);

        return () => clearTimeout(timeout);
    }, [animate]);

    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         setAnimate((prev) => !prev);
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);

    return animate;
};

export default useTimesDisplayAnimation;
