import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps, GridColumnProps } from '@progress/kendo-react-grid';
import LineStatusColumn from 'src/components/KendoGridCutomeColumn/LineStatusColumn';
import PersonKendoHeader from 'src/components/KendoGridCutomeColumn/PersonKendoHeader';
import { ILine } from 'src/types/line';
import OptionsCell from '../components/OptionsCell';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Portal from '@mui/material/Portal';
import { MuiTooltipV2 } from 'src/components/MuiTooltip/MuiTooltip';
import { asTSR } from 'src/utilis/utilis';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { useRootAppSelector } from 'src/store/hooks';
import { useDispatch } from 'react-redux';
import { selectLineAction } from 'src/store/slices/lines/linesSlice';

export interface InfoCellProps extends React.PropsWithChildren<GridCellProps> {}

const InfoCell = (props: InfoCellProps) => {
    const lineData: ILine | undefined = props.dataItem || {};

    const { t } = useTranslation();

    return (
        <td style={{ padding: 0, border: 'none', zIndex: 9999 }} className="info-cell-td">
            {lineData && lineData.creationType === '9' && lineData.isCreatedByStudio !== '1' ? (
                <MuiTooltipV2 arrow title={<>{t(asTSR('createdBySupplier'))}</>} placement="right">
                    <ManageAccountsRoundedIcon sx={{ pointerEvents: 'all' }} fontSize="small" />
                </MuiTooltipV2>
            ) : null}
        </td>
    );
};

const useColumns = (
    hoverRowLineId?: number | null,
    headerSelectionValue?: boolean
): { columns: Array<GridColumnProps> } => {
    const { t } = useTranslation();
    const [lineHovered, setLineHovered] = useState<string>('');
    const dispatch = useDispatch();

    const columns = useMemo<Array<GridColumnProps>>(
        () => [
            {
                field: 'info',
                title: '',
                width: 25,
                cell: (cellProps) => <InfoCell {...cellProps} />,
                headerClassName: 'k-header k-info-cell-header',
                sortable: false,
                reorderable: false,
                resizable: false,
                headerCell: () => {
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    return <th style={{ display: 'none', background: 'white', border: 'none' }} />;
                },
            },
            {
                field: 'isSelected',
                headerSelectionValue,
                reorderable: false,
                width: 34,
                resizable: true,
                cell: (cellProps) => {
                    return (
                        <td
                            className="isSelected-col"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50px',
                                border: '1px solid rgba(180, 190, 201, 0.303)',
                            }}
                        >
                            <input
                                type="checkbox"
                                className="k-checkbox"
                                checked={cellProps.dataItem.isSelected}
                                style={
                                    cellProps.dataItem.creationType === '9' &&
                                    cellProps.dataItem.isCreatedByStudio !== '1'
                                        ? { backgroundColor: '#d8d8d8', minWidth: '16px' }
                                        : { minWidth: '16px' }
                                }
                                onChange={() => {
                                    if (
                                        cellProps &&
                                        cellProps.dataItem &&
                                        !(
                                            cellProps.dataItem.creationType === '9' &&
                                            cellProps.dataItem.isCreatedByStudio !== '1'
                                        )
                                    )
                                        dispatch(selectLineAction({ lineCode: cellProps.dataItem.lineCode }));
                                }}
                            />
                        </td>
                    );
                },
            },
            {
                field: 'lineStatus',
                title: t('status'),
                editor: 'numeric',
                cell: LineStatusColumn,
                width: 60,
                minResizableWidth: 50,
                headerClassName: 'broken-header-col-main',
            },
            {
                field: 'startTime',
                width: '90px',
                title: t('startTime'),
                format: '{0:HH:mm}',
                editor: 'date',
                minResizableWidth: 50,
            },
            {
                field: 'endTime',
                width: '76px',
                title: t('endTime'),
                format: '{0:HH:mm}',
                editor: 'date',
                minResizableWidth: 50,
            },
            {
                field: 'clientName',
                title: t('clientName'),
                width: 60,
                editor: 'text',
                minResizableWidth: 20,
            },
            {
                field: 'lineDescription',
                title: t('description'),
                editor: 'text',
                width: 150,
                minResizableWidth: 50,
            },
            {
                field: 'driverName',
                title: t('driverName'),
                editor: 'text',
                width: 100,
                minResizableWidth: 50,
            },
            {
                field: 'carTypeName',
                title: t('carType'),
                editor: 'text',
                width: 100,
                minResizableWidth: 50,
            },
            {
                field: 'carNumber',
                title: t('carNumber'),
                editor: 'text',
                width: 'auto',
                minResizableWidth: 50,
            },
            {
                field: 'passQty',
                className: 'person-kendo-header',
                headerCell: PersonKendoHeader,
                title: t('passQty'),
                editor: 'numeric',
                minResizableWidth: 50,
                width: 50,
            },
            {
                field: 'visaNumber',
                title: t('visaNumber'),
                editor: 'text',
                width: 'auto',
                minResizableWidth: 50,
            },
            {
                field: 'actions',
                title: t('actions'),
                width: 130,
                headerClassName: 'k-header k-actions-cell-header',
                headerCell: () => null,
                cell: (cellProps) => (
                    <OptionsCell
                        cellProps={cellProps}
                        hoveredLineCode={hoverRowLineId ? String(hoverRowLineId) : ''}
                    />
                ),
                resizable: false,
            },
        ],
        [t, hoverRowLineId, headerSelectionValue]
    );

    return { columns };
};

export default useColumns;
