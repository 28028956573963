/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IRootReducer } from 'src/store/reducers';
import initialGeneralFeaturesSliceState from './contants.generalFeatures';
import { GeneralFeaturesSliceState } from './types.generalFeatures';
import uploadFileReducers from './uploadFileReducers';

export const generalFeaturesSlice = createSlice({
    name: 'generalFeatures',
    initialState: initialGeneralFeaturesSliceState,
    reducers: {
        ...uploadFileReducers,
    },
});

// * Exports
// ^ Actions
export const {
    // Upload file actions
    setExistingFileData: setExistingFileDataAction,
    toggleModal: toggleModalAction,
    updateActionStatus: updateActionStatusAction,
    toggleSuccessModal: toggleSuccessModalAction,
    setFileInputData: setFileInputDataAction,
    setSelectedClientCode: setSelectedClientCodeAction,
    resetState: resetStateAction,
} = generalFeaturesSlice.actions;

// ^ Selectors
const rootSelector = (state: IRootReducer): GeneralFeaturesSliceState => state.generalFeaturesSlice;
export const uploadFileFtSelector = (state: IRootReducer) => rootSelector(state).uploadFileFt;

const generalFeaturesSliceReducer = generalFeaturesSlice.reducer;

export default generalFeaturesSliceReducer;
