import i18n from 'src/i18n';
import { CourseStationTypes } from 'src/types/lines/api/types';

export const getLabel = (index: number, totalLength: number): string => {
    if (index === 0) return 'מוצא';
    if (index === totalLength - 1) return 'יעד';
    return `${i18n.t('station')} ${index}`;
};

export const useStationType = (fieldsCount: number) => {
    const getStationType = (stationIndex: number): CourseStationTypes => {
        if (stationIndex === 0) return CourseStationTypes.Origin;
        if (stationIndex === fieldsCount - 1) return CourseStationTypes.Destination;
        return CourseStationTypes.Normal;
    };

    return { getStationType };
};

export enum StationValidationErrors {
    MissingCity = 'cityIsRequired',
    GeneralError = 'invalidStation',
    MissingCoords = 'stationDoesNotExistInPool',
}

export const isSmallScreen = window.screen.width < 1800;
