/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function MyInfoIcon({ width = '20', height = '20', color = 'rgba(0, 0, 0, 0.54)' }) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.288 6.43974H13.7122V8.86388H11.288V6.43974ZM12.5001 18.5604C13.1667 18.5604 13.7122 18.015 13.7122 17.3484V12.5001C13.7122 11.8334 13.1667 11.288 12.5001 11.288C11.8334 11.288 11.288 11.8334 11.288 12.5001V17.3484C11.288 18.015 11.8334 18.5604 12.5001 18.5604ZM12.5001 0.379395C5.80946 0.379395 0.379395 5.80946 0.379395 12.5001C0.379395 19.1907 5.80946 24.6208 12.5001 24.6208C19.1907 24.6208 24.6208 19.1907 24.6208 12.5001C24.6208 5.80946 19.1907 0.379395 12.5001 0.379395ZM12.5001 22.1966C7.15486 22.1966 2.80353 17.8453 2.80353 12.5001C2.80353 7.15486 7.15486 2.80353 12.5001 2.80353C17.8453 2.80353 22.1966 7.15486 22.1966 12.5001C22.1966 17.8453 17.8453 22.1966 12.5001 22.1966Z"
                fill="#EDBD41"
            />
        </svg>
    );
}
export default MyInfoIcon;
