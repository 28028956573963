import { PayloadAction } from '@reduxjs/toolkit';
import { rideSettingsActions } from 'src/store/slices/rideSettings/rideSettings';
import { SettingTypes, UpdateGenSetsFormValuePayload } from 'src/types/rideSettings/store/types.store';
import { IFormSubmitPayload, ReqGetGenPermsPayload } from 'src/types/rideSettings/store/types.sagas';

const {
    onClearSubmitConfigs,
    editSettingClick,
    onActiveStatusChange,
    onFetchSettingsSuccess: onFetchSettingsSuccessAction,
    onFetchSettingsFail,
    getGeneralSettingsFetch,
    onSubmitFormFailure,
    onSubmitFormSuccess,
    onPermissionManagementFormSubmit: onFormSubmit,
    onCloseForm,
    onFetchSettings,
    onOpenForm,
    hasSubmitConfigs,
    formChanged,
    onSelectClientId,
    onError: onErrorAction,
    updateGenSetsFormValue,
    onGeneralSettingsFormSubmit,
    setSettingsAccordionExpansion,
} = rideSettingsActions;

const formChangedAction = formChanged as <TPayload>(payload: {
    settingType: SettingTypes;
    updatedValues: TPayload;
}) => PayloadAction<{
    settingType: SettingTypes;
    updatedValues: TPayload;
}>;

const reqGetGeneralSettingsAction = getGeneralSettingsFetch as (
    payload: ReqGetGenPermsPayload
) => PayloadAction<ReqGetGenPermsPayload>;

const formSubmitAction = onFormSubmit as <TData>(
    payload: IFormSubmitPayload<TData>
) => PayloadAction<IFormSubmitPayload<TData>>;

const updateGenSetsFormValueAction = updateGenSetsFormValue as <TVal>(
    payload: UpdateGenSetsFormValuePayload<TVal>
) => PayloadAction<UpdateGenSetsFormValuePayload<TVal>>;

// const onGeneralSettingsFormSubmitAction = onGeneralSettingsFormSubmit as (payload:any) =>
const actions = {
    onClearSubmitConfigs,
    setSettingsAccordionExpansion,
    editSettingClick,
    onActiveStatusChange,
    onFetchSettingsFail,
    getGeneralSettingsFetch,
    onSubmitFormFailure,
    onSubmitFormSuccess,
    onCloseForm,
    onFetchSettings,
    onOpenForm,
    hasSubmitConfigs,
    onSelectClientId,
    onGeneralSettingsFormSubmit,
    // updated
    onFetchSettingsSuccessAction,
    reqGetGeneralSettingsAction,
    onErrorAction,
    formChangedAction,
    formSubmitAction,
    updateGenSetsFormValueAction,
};
export default actions;
