export enum CourseTypes {
    Active = 'ACTIVE',
    InActive = 'INACTIVE',
    All = 'ALL',
}
export type CoursesFilters = 'courseType' | 'freeSearch' | 'courseId' | 'departmentId';

export interface IExtendedCourseData {
    day: number;
    times: {
        start: string;
        end: string;
    }[];
    carTypes: { carTypeCode: string; carTypeName: string }[];
}

export const enums = {
    CourseTypes,
};
// const x = 5;
