/* eslint-disable no-param-reassign */
import { isValidResponse } from 'src/api/utilis';
import { initialStatistics, initialSummaryData } from 'src/store/slices/reports/initialStates';
import { ReportName } from 'src/store/slices/reports/types';
import { ErrorMessagesOld } from 'src/store/type';
import {
    LineStatistics,
    ProcessedStatistics,
    RidesStatisticsResponse,
    WeeklyCounts,
} from 'src/types/reports/generalReportsTypes';
import { deepClone } from 'src/utilis/utilis';
import { Statistics, RidesProgressData } from './components/widgets/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface ReportsMenuItem {
    type: 'headline' | 'report';
    value: ReportName | 'headline1' | 'headline2';
    name: string;
}
export const getMenuItems = (t: Function): ReportsMenuItem[] => [
    {
        type: 'headline',
        value: 'headline1',
        name: t('orders'),
    },
    {
        type: 'report',
        value: 'shortOrders',
        name: t('shortOrdersReport'),
    },
    {
        type: 'report',
        value: 'detailedOrders',
        name: t('detailedOrdersReport'),
    },
    {
        type: 'headline',
        value: 'headline2',
        name: t('rides'),
    },
    {
        type: 'report',
        value: 'visas',
        name: t('visasReport'),
    },
    {
        type: 'report',
        value: 'passengers',
        name: t('passengersReport'),
    },
];

export interface DepartmentsMap {
    code: number;
    departmentName: string;
}
/**
 * Takes in a passenger object and returns the name of the department that the passenger belongs to.
 * @param {object} psngr - the passenger object
 * @param {DepartmentsMap[]} departments - the list of departments
 * @returns {string} the name of the department that the passenger belongs to
 */
export const getDepartmentName = (
    psngr: { departmentCode: string | number },
    departments: DepartmentsMap[]
) => {
    const department = departments.find((dept: { code: number }) => dept.code === +psngr.departmentCode);
    let departmentName = '';
    if (department) departmentName = department.departmentName;
    return departmentName;
};

export const isReportAuthorized = (reportName: string, authorizedReports: string[]) => {
    return authorizedReports.includes(reportName);
};

export const isOfTypeReportName = (val: any) => {
    const reportNames: ReportName[] = ['visas', 'shortOrders', 'detailedOrders', 'passengers'];
    return reportNames.includes(val);
};

/**
 * Takes in an array of LineStatistics objects and groups them by the departmentCode and
 * departmentName.
 * @param {LineStatistics[]} linesArr - the array of LineStatistics objects
 * @param {'totalLines' | 'totalPassengers'} key - the key to group by
 * @returns {RidesProgressData[]} - the array of grouped LineStatistics objects
 */
export const groupLinesObj = (
    linesArr: LineStatistics[],
    key: 'totalLines' | 'totalPassengers'
): RidesProgressData[] => {
    const result: RidesProgressData[] = [];
    const copy = deepClone(linesArr);
    copy.forEach((line: LineStatistics) => {
        const { departmentCode, departmentName } = line;
        const match: RidesProgressData | undefined = result.find((r) => r.code === departmentCode);
        if (match) {
            match.totalCount += line[key];
        } else {
            result.push({
                code: departmentCode,
                name: departmentName,
                totalCount: line[key],
            });
        }
    });
    return result;
};

/**
 * Process the raw statistics from the Rides API and return a WeeklyCounts object.
 * @param {RidesStatisticsResponse} rawStatistics - the raw statistics from the Rides API.
 * @returns {WeeklyCounts} - the processed statistics.
 */
const processCardsStatistics = (rawStatistics: RidesStatisticsResponse): WeeklyCounts => {
    const { fromDate, toDate, linesStatistics } = rawStatistics;

    let ridesCount = 0;
    let passengersCount = 0;

    linesStatistics.forEach((line) => {
        passengersCount += line.totalPassengers;
        ridesCount += line.totalLines;
    });

    const weeklyCounts = {
        fromDate,
        toDate,
        rides: ridesCount,
        passengers: passengersCount,
    };

    return weeklyCounts;
};

// todo: return Statistics
/**
 * Processes the raw statistics from the API and returns a formatted object.
 * @param {RidesStatisticsResponse} rawStatistics - the raw statistics from the API.
 * @param {'rides' | 'passengers'} type - the type of statistics to process.
 * @returns {Statistics} - the formatted statistics object.
 */
const processSummaryStatistics = (
    rawStatistics: RidesStatisticsResponse,
    type: 'rides' | 'passengers'
): Statistics => {
    const { fromDate, toDate, linesStatistics } = rawStatistics;

    // const max = 0;
    const relativeDate = `${fromDate} - ${toDate}`;

    const data: RidesProgressData[] = groupLinesObj(
        linesStatistics,
        type === 'rides' ? 'totalLines' : 'totalPassengers'
    );
    const max = data.reduce(
        // eslint-disable-next-line no-return-assign
        (acc, line) => acc + line.totalCount,
        0
    );
    return { data, max, relativeDate };
};

/**
 * Processes the statistics from the API and returns a processed object.
 * @param {RidesStatisticsResponse} dailyStats - The daily statistics.
 * @param {RidesStatisticsResponse} weeklyStats - The weekly statistics.
 * @param {RidesStatisticsResponse} monthlyStats - The monthly statistics.
 * @returns {ProcessedStatistics} - The processed statistics.
 */
export const processAllStatistics = (
    dailyStats: RidesStatisticsResponse,
    weeklyStats: RidesStatisticsResponse,
    monthlyStats: RidesStatisticsResponse
): ProcessedStatistics => {
    const processedData: ProcessedStatistics = {
        weeklyCounts: {
            rides: 0,
            passengers: 0,
            fromDate: '',
            toDate: '',
        },
        summaryData: deepClone(initialSummaryData),
    };
    processedData.weeklyCounts = processCardsStatistics(weeklyStats);
    processedData.summaryData.rides.dailyStatistics = processSummaryStatistics(dailyStats, 'rides');
    processedData.summaryData.rides.weeklyStatistics = processSummaryStatistics(weeklyStats, 'rides');
    processedData.summaryData.rides.monthlyStatistics = processSummaryStatistics(monthlyStats, 'rides');
    processedData.summaryData.passengers.dailyStatistics = processSummaryStatistics(dailyStats, 'passengers');
    processedData.summaryData.passengers.weeklyStatistics = processSummaryStatistics(
        weeklyStats,
        'passengers'
    );
    processedData.summaryData.passengers.monthlyStatistics = processSummaryStatistics(
        monthlyStats,
        'passengers'
    );
    return processedData;
};

export const allResponsesValid = (responses: any[], errorActionDispatcher: Function): boolean => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < responses.length; i++) {
        const response = responses[i];
        const x = isValidResponse(response.status, 'object', response.data);
        if (x.msg) {
            errorActionDispatcher(null, x.msg, x.details);
            return false;
        }
    }
    return true;
};
