import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedFcAccountSelector } from 'src/store/selectores/loginSelectors';

const PROD_PATH = 'mywaystudio.y-it.co.il';
export const isOnProduction = window.location.href.includes(PROD_PATH);
export const devFcAccountCode = 1;

const useEnvAuth = () => {
    const selectedFcAccount = useSelector((state) => selectedFcAccountSelector(state));

    const [isUsingProdAccountOnDev, setUsUsingProdAccountOnDev] = useState(
        !isOnProduction && selectedFcAccount?.accountCode !== devFcAccountCode
    );
    const [showAlert, setShowAlert] = useState(isUsingProdAccountOnDev);

    React.useEffect(() => {
        if (!isOnProduction && selectedFcAccount?.accountCode !== devFcAccountCode) {
            setUsUsingProdAccountOnDev(true);
            setShowAlert(true);
        } else {
            setUsUsingProdAccountOnDev(false);
            setShowAlert(false);
        }
    }, [selectedFcAccount]);

    return {
        isUsingProdAccountOnDev,
        alertDisplayState: {
            showAlert,
            setShowAlert,
        },
    };
};

export default useEnvAuth;
