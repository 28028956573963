import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TIME_FORMATS } from 'src/constants/dates';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import linesRoutes, { Codes } from 'src/mockServer/routes/linesRoutes';
import { onRetriveLines } from 'src/store/actions/LineAction';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { setAlertConfigAction } from 'src/store/slices/common/commonSlice';
import {
    lineActionsSelector,
    delLineApiRequestSelector,
    toggleConfirmationDialogAction,
    updateDelLineReqStatusAction,
} from 'src/store/slices/lines/linesSlice';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import { ILine } from 'src/types/line';
import { stringifyDate } from 'src/utilis/utilis';
import { getAsTemplateStrArr as asTSR } from '../../../../../utilis/utilis';

// const stringifyTime = (time: string | Date): string => {
//     return typeof time === 'string' ? time : moment(time).format(TIME_FORMATS.TWENTY_FOUR);
// };

// const Content = (dataItem: ILine) => (
//     <div style={{}}>
//         <DialogContentText style={{ fontFamily: 'Rubik' }}>
//             {stringifyTime(dataItem.startTime)} - {stringifyTime(dataItem.endTime)}
//         </DialogContentText>
//         <DialogContentText style={{ fontFamily: 'Rubik' }}>{dataItem.lineDescription}</DialogContentText>
//     </div>
// );

// const Title = () => <p>האם מאשר מחיקת נסיעה?</p>;

export interface IDeleteDialogProps {
    onClose: () => void;
    onConfirmClick: () => void;
    isOpen: boolean;
    loading?: boolean;
    startTime: string;
    endTime: string;
    date: string;
    isRestoreDialog?: boolean;
}

const useLineActionsConfirmation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { isConfirmationDialogOpen, dataItem } = useRootAppSelector((state) => lineActionsSelector(state));
    const delLineReqData = useRootAppSelector((state) => delLineApiRequestSelector(state));
    const selectedDate = useSelector((state: IRootReducer) => loginSelector(state).selectedDate);

    const { token, dbUrl, proxyUrl } = useGetApiRequestBasicParams();

    React.useEffect(() => {
        if (delLineReqData.status === 'SUCCESS') {
            dispatch(toggleConfirmationDialogAction({ isOpen: false }));
        }
    }, [delLineReqData.status, dispatch, toggleConfirmationDialogAction]);

    const handleApiResult = (status: ApiCallStatus, message: string) => {
        dispatch(
            setAlertConfigAction({
                severity: status === 'SUCCESS' ? 'success' : 'error',
                message: t(asTSR(message)),
            })
        );
        dispatch(
            updateDelLineReqStatusAction({
                status,
            })
        );
    };

    const isDataItemTypeLine = () => 'lineCode' in Object(dataItem);

    const getLineTimeStr = useCallback(
        (type: 'startTime' | 'endTime'): string => {
            if (!isDataItemTypeLine()) {
                let msg = '';
                setTimeout(() => {
                    msg = t('oopsSomethingWentWrongTryAgain');
                }, 500);
                return msg;
            }
            const lineData = dataItem as ILine;

            if (typeof lineData[type] === 'string') {
                return lineData[type] as string;
            }
            return stringifyDate(moment(lineData[type]), TIME_FORMATS.TWENTY_FOUR);
        },
        [dataItem, isDataItemTypeLine, t]
    );

    const startTime: string = useMemo(() => getLineTimeStr('startTime'), [getLineTimeStr]);
    const endTime: string = useMemo(() => getLineTimeStr('endTime'), [getLineTimeStr]);

    const date: string = useMemo(() => {
        if (!selectedDate) return t('oopsSomethingWentWrongTryAgain');

        return stringifyDate(selectedDate);
    }, [selectedDate, t]);

    const props: IDeleteDialogProps = {
        isOpen: isConfirmationDialogOpen,
        onClose: () => {
            dispatch(toggleConfirmationDialogAction({ isOpen: false }));
        },
        startTime,
        endTime,
        date,
        onConfirmClick: () => {
            if (!isDataItemTypeLine()) return;

            const lineDataItem = dataItem as ILine;

            dispatch(
                updateDelLineReqStatusAction({
                    status: 'LOADING',
                })
            );

            linesRoutes
                .deleteLine({
                    requestConfig: {
                        dbUrl,
                        requestParams: {
                            token: String(token),
                            lineCode: String(lineDataItem.lineCode),
                        },
                    },
                })
                .then((result) => {
                    if (result.code === Codes.Ok) {
                        handleApiResult('SUCCESS', 'operationWasSuccessful');
                        dispatch(
                            onRetriveLines({
                                token,
                                clinetProxy: proxyUrl,
                                dbUrl,
                                relativeDate: moment(selectedDate).format('yyyy.MM.DD'),
                            })
                        );
                        return;
                    }
                    handleApiResult('FAILED', result.data.message || 'operationFailed');
                })
                .catch((error) => {
                    handleApiResult('FAILED', 'operationFailed');
                });
        },
        loading: delLineReqData.status === 'LOADING',
    };

    return { props };
};

export default useLineActionsConfirmation;
