import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import linesRoutes, { GetCourseStationByIdData } from 'src/mockServer/routes/linesRoutes';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { useTranslation } from 'react-i18next';

import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import {
    accountCoursesResponseSelector,
    carTypesLstSelector,
    fetchCoursesUpdateAction,
    importCourseStationsAction,
    setFormEndAddressAction,
    setFormStartAddressAction,
    setIsTriggeredFromImportAction,
    setSelectedCourseCodeAction,
    setSelectedCourseLocationsAction,
    stationsSelector,
    storedCoursesFormDisplayChangeAction,
    storedCoursesFormSelector,
    storedCoursesImportFormSelector,
    toggleAutoCompleteAction,
    toggleConfirmStationsImportModalOpenAction,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { ErrorMessages } from 'src/types/apiCommon.types';
import { asTSR } from 'src/utilis/utilis';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { ApiCallStatus } from 'src/types/rideSettings/store/types.store';
import { FormSchema, InputNames, Inputs } from '../../../types';
import { UseFormSetValue } from 'react-hook-form';
import _ from 'lodash';
import { ParsedCourseStation } from 'src/types/lines/api/types';
import { AddressInputComponentIds, AutoCompleteInputStatus } from 'src/types/lines/addLineForm.types';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import useCommons from 'src/hooks/common/useCommons';
import { SetStateAction } from 'src/types/global';
import storedCoursesHelpers from './storedCourses.helpers';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import { TripDirections } from 'src/types/line';
import { useTripDirectionHelpers } from '../../formSections/components/TripDirectionDropdown';

const useCoursesFetch = ({ clientCode, relativeDate }: { clientCode: string; relativeDate: string }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // Selectors
    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const { dbUrl = '' } = selectedFcAccount || {};

    const dispatchReqResultUpdate = (status: ApiCallStatus, data: WsCourseDataItem[] | null) => {
        dispatch(
            fetchCoursesUpdateAction({
                status,
                data: data || undefined,
            })
        );
    };

    const fetchAllCourses = React.useCallback(async () => {
        try {
            if (!dbUrl || !token || !clientCode || clientCode === '0' || !relativeDate) return;

            dispatchReqResultUpdate('LOADING', null);

            const fetchResult = await coursesBuildingRoutes.getAccountCourses({
                requestConfig: {
                    dbUrl,
                    requestParams: {
                        token,
                        clientCode,
                        relativeDate,
                        // Filters
                        isActive: 1,
                    },
                },
            });

            if (fetchResult.code === CommonCodes.Ok && Array.isArray(fetchResult.data.data)) {
                const sortedData = _.sortBy(fetchResult.data.data, ['courseId', 'courseDescription']);
                dispatchReqResultUpdate('SUCCESS', sortedData);
                return;
            }

            dispatchReqResultUpdate('FAILED', null);
            dispatch(setErrorMessage(t(asTSR(fetchResult.data.message || ErrorMessages.GeneralError))));
        } catch (error) {
            dispatchReqResultUpdate('FAILED', null);
            dispatch(setErrorMessage(t(asTSR(ErrorMessages.GeneralError))));
        }
    }, [dbUrl, token, clientCode, relativeDate]);

    useEffect(() => {
        fetchAllCourses();
    }, [fetchAllCourses]);
};

const useFetchCourseLocations = () => {
    const { t, dispatch } = useCommons();

    const { dbUrl, token } = useGetApiRequestBasicParams();

    const fetchCourseLocations = useCallback(
        async (courseCode: string) => {
            try {
                const result = await linesRoutes.getCourseStationsById({
                    courseCode,
                    token,
                    dbUrl,
                });
                console.log(result.data.data?.stations);
                if (result.code === CommonCodes.Ok && Array.isArray(result.data.data?.stations)) {
                    const data = result.data.data as GetCourseStationByIdData;

                    const courseLocations = storedCoursesHelpers.processCourseLocations(data.stations);

                    dispatch(
                        setSelectedCourseLocationsAction({
                            locations: courseLocations,
                            passengers: data.passengers,
                        })
                    );

                    return courseLocations;
                }
                // Else
                throw new Error(result.data.message || ErrorMessages.GeneralError);
            } catch (error: any) {
                console.log(error);
                dispatch(setErrorMessage(t(error.message)));
                return -1;
            }
        },
        [dbUrl, dispatch, t, token]
    );

    return { fetchCourseLocations };
};

const useHandleImportCourseStation = (updateValue: (inputType: InputNames, newValue: any) => void) => {
    const { t, dispatch } = useCommons();

    const { selectedCourseData } = useRootAppSelector(storedCoursesImportFormSelector);
    const fetchCourseLocationsHelpers = useFetchCourseLocations();

    // Fetching course locations on selected course change
    useEffect(() => {
        if (selectedCourseData.code)
            fetchCourseLocationsHelpers.fetchCourseLocations(selectedCourseData.code);
    }, [selectedCourseData?.code]);

    const handleAddressUpdate = useCallback(
        (type: 'startAddress' | 'endAddress', data: ParsedCourseStation) => {
            // Register form values
            updateValue(type, storedCoursesHelpers.createAddressObj(data));

            // Toggle manual address input
            dispatch(
                toggleAutoCompleteAction({
                    status: AutoCompleteInputStatus.Off,
                    componentId:
                        type === 'startAddress'
                            ? AddressInputComponentIds.StartAddress
                            : AddressInputComponentIds.EndAddress,
                })
            );
        },
        [dispatch, updateValue]
    );

    const setPreviousLocationsData = useCallback(
        (userConfirmedStationsImport: boolean) => {
            const { locations: courseLocations } = selectedCourseData;

            if (courseLocations) {
                if (userConfirmedStationsImport) {
                    // set as stations lst on slice

                    dispatch(importCourseStationsAction({ rawStationsLst: courseLocations.stations || [] }));
                    dispatch(setIsTriggeredFromImportAction({ isFromImport: true }));
                    dispatch(triggerDirectionsAction());
                }

                if (courseLocations.startAddress) {
                    handleAddressUpdate('startAddress', courseLocations.startAddress);
                    dispatch(setFormStartAddressAction({ startAddress: courseLocations.startAddress }));
                }
                if (courseLocations.endAddress) {
                    handleAddressUpdate('endAddress', courseLocations.endAddress);
                    dispatch(setFormEndAddressAction({ endAddress: courseLocations.endAddress }));
                }
                return 0;
            }
            // Else
            console.log('No course locations');
            dispatch(setErrorMessage(t(ErrorMessages.GeneralError)));
            return -1;
        },
        [dispatch, handleAddressUpdate, selectedCourseData?.locations, t]
    );

    return { setPreviousLocationsData };
};

const useSelectedCourseData = () => {
    const { selectedCourseData: selectedCourseCode } = useRootAppSelector(storedCoursesFormSelector);
    const { data: allCourses } = useRootAppSelector(accountCoursesResponseSelector);

    const getSelectedCourseData = () => {
        return allCourses.find((course) => course.courseCode === selectedCourseCode.code) || null;
    };

    return { getSelectedCourseData };
};

const useImportStoredCourseToForm = (setValue: UseFormSetValue<Inputs>) => {
    const carTypesLst = useRootAppSelector(carTypesLstSelector);
    const selectedCourseData = useSelectedCourseData();

    const tripDirectionHelpers = useTripDirectionHelpers();

    const updateValue = useCallback(
        (inputType: InputNames, newValue: any) => {
            setValue(inputType, newValue, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [setValue]
    );

    const helpers = useHandleImportCourseStation(updateValue);

    const setFormValuesBySelectedCourse = async (withStations: boolean, setValueOnFullForm: any) => {
        const courseData = selectedCourseData.getSelectedCourseData();

        try {
            if (courseData) {
                // Updates stations AND addresses
                await helpers.setPreviousLocationsData(withStations);
            }
            updateValue('courseCode', courseData?.courseCode || '');

            // Resetting trip direction field on main form
            updateValue('tripDirection', TripDirections.OneWay);
            tripDirectionHelpers.resetUnusedTimeFieldsV2(TripDirections.OneWay, setValueOnFullForm);

            // Only set car id if said car exists on full form
            const carMatch = carTypesLst.some((carType) => {
                return carType.id === courseData?.carTypeCode;
            });
            updateValue('carId', carMatch ? courseData?.carTypeCode : null);

            updateValue('departmentCode', courseData?.departmentCode || '');
            updateValue('passQty', courseData?.passQty ? +courseData.passQty : null);
            updateValue('linePrice', courseData ? +courseData.clientPrice : null);
            updateValue('description', courseData?.courseDescription || '');

            return [0, courseData?.courseCode];
        } catch (error) {
            return [-1, null];
        }
    };

    return { setFormValuesBySelectedCourse };
};

const useSubmitHelpers = (
    setValue: UseFormSetValue<Inputs>,
    setIsSubmittingCourse: SetStateAction<boolean>
) => {
    const formHelpers = useImportStoredCourseToForm(setValue);
    const { t, dispatch } = useCommons();

    const submit = async (withStationsImport: boolean) => {
        setIsSubmittingCourse(true);
        const [result, courseCode] = await formHelpers.setFormValuesBySelectedCourse(
            withStationsImport,
            setValue
        );

        if (result === 0 && courseCode) {
            // Success - closing form
            dispatch(storedCoursesFormDisplayChangeAction({ isOpen: false, courseCode: String(courseCode) }));
            setIsSubmittingCourse(false);
            return;
        }

        // Error
        setIsSubmittingCourse(false);
        dispatch(setErrorMessage(t(ErrorMessages.GeneralError)));
    };

    return { submit };
};
export enum LoadingState {
    NotLoading = '',
    LoadingConfirm = 'LOADING_CONFIRM',
    LoadingCancel = 'LOADING_CANCEL',
}

export interface DialogControl {
    onClickConfirm: () => void;
    onClickCancel: () => void;
    toggleDialog: (open: boolean) => void;
    state: {
        isOpen: boolean;
        isLoading: LoadingState;
    };
}

const useDialogControl = (
    setIsSubmittingCourse: SetStateAction<boolean>,
    setValue: UseFormSetValue<Inputs>
): DialogControl => {
    const dialogState = useRootAppSelector(storedCoursesFormSelector).importStationsModal;
    const { dispatch, t } = useCommons();

    const submitHelpers = useSubmitHelpers(setValue, setIsSubmittingCourse);

    const toggleDialog = (open: boolean) => {
        dispatch(toggleConfirmStationsImportModalOpenAction({ isOpen: open }));
    };

    const onClickConfirm = async () => {
        toggleDialog(false);
        await submitHelpers.submit(true);
    };

    const onClickCancel = async () => {
        toggleDialog(false);
        await submitHelpers.submit(false);
    };

    const control = {
        onClickConfirm,
        onClickCancel,
        toggleDialog,
        state: dialogState,
    };

    return control;
};

export interface Args {
    submitHelpers: {
        submit: (withStationsImport: boolean) => Promise<void>;
    };
    submissionStatusState: {
        isSubmittingCourse: boolean;
        setIsSubmittingCourse: React.Dispatch<React.SetStateAction<boolean>>;
    };
    dialogControl: DialogControl;
}

const useCourseImportBtnsConfigs = ({ currValues }: { currValues: FormSchema | null }) => {
    const dispatch = useAppDispatch();
    const {
        selectedCourseData: { locations: courseLocations },
    } = useRootAppSelector(storedCoursesImportFormSelector);

    const stations = useRootAppSelector(stationsSelector);

    const getConfigs = ({ submitHelpers, submissionStatusState, dialogControl }: Args) => {
        return {
            handleSubmit: () => {
                // If will override certain values, needs confirmation to replace them
                if (currValues && storedCoursesHelpers.willOverrideValues(currValues, stations)) {
                    dialogControl.toggleDialog(true);
                    return;
                }

                // Import stations directly without dialog confirmation
                if (courseLocations?.stations.length && !stations.length) {
                    submissionStatusState.setIsSubmittingCourse(true);
                    submitHelpers.submit(true);
                    return;
                }

                submissionStatusState.setIsSubmittingCourse(true);
                // else -> submit normally without importing stations
                submitHelpers.submit(false);
            },

            disabled: submissionStatusState.isSubmittingCourse,

            onCancel: () => {
                dispatch(storedCoursesFormDisplayChangeAction({ isOpen: false }));
                dispatch(setSelectedCourseCodeAction({ courseCode: '' }));
            },

            isSubmitting: submissionStatusState.isSubmittingCourse,
        };
    };
    return {
        getConfigs,
    };
};
export const storedCoursesHooks = {
    useCoursesFetch,
    useImportStoredCourseToForm,
    useDialogControl,
    useSubmitHelpers,
    useSelectedCourseData,
    useCourseImportBtnsConfigs,
};
