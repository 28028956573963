import React from 'react';

import { List, ListItem, styled } from '@mui/material';

export const StyledList = styled(List)<{ component?: React.ElementType }>({
    '&.MuiList-root': {
        backgroundColor: 'white',
        '& .MuiListItem-root': {
            ':hover': {
                backgroundColor: '#EEEEEE',
            },
        },
    },
});
