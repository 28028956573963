import React from 'react';
import { FieldError, Noop, RefCallBack } from 'react-hook-form';
import { OutlinedInputProps, TextField } from '@material-ui/core';
import i18n from 'src/i18n';
import { useTranslation } from 'react-i18next';

export interface FieldProps {
    InputProps?: Partial<OutlinedInputProps>;
    label?: string;
    type?: string;
}
export interface Props {
    field: {
        onChange: (...event: any[]) => void;
        onBlur: Noop;
        value: any;
        name: any;
        ref: RefCallBack;
    };
    fieldProps?: FieldProps;
    errors: FieldError | undefined;
}

export const getTranslatedErrorMessage = (errorMessage: string): string => {
    const messageParts = errorMessage.split('-');
    if (messageParts.length > 1) {
        return `${i18n.t(messageParts[0])} - ${messageParts[1]}`;
    }
    return i18n.t(messageParts[0]);
};

function TextInputV3({ field, errors, fieldProps = {} }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', textAlign: 'right' }}>
            <TextField
                error={!!errors?.message}
                variant="outlined"
                size="small"
                label={fieldProps.label || t(field.name)}
                fullWidth
                style={{ backgroundColor: 'white' } as const}
                {...field}
                {...fieldProps}
            />
            {errors?.message && <p>{getTranslatedErrorMessage(errors?.message)}</p>}
        </div>
    );
}

export default TextInputV3;
