import React from 'react';
import { MapWindow } from 'src/components/Map/MapType2/MapType2';
import useCommons from 'src/hooks/common/useCommons';
import { Button } from '@mui/material';
import {
    setCenterMapOnEditAction,
    setIsMapEditModeAction,
    setUpdatedCoordsOnMapAction,
} from 'src/store/slices/optimizations/optimizationsSlice';

const editWindowStyle = {
    bottom: '10px',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '400px',
    justifyContent: 'center',
} as const;

export const OptimizationEditInstructionsWindow: React.FC<{}> = () => {
    const { t, dispatch } = useCommons();

    return (
        <MapWindow style={editWindowStyle}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <p style={{ fontSize: '18px', paddingRight: '10px' }}>{t('dragMarkerToDesiredLocation')}</p>
                <Button
                    onClick={() => {
                        dispatch(
                            setIsMapEditModeAction({
                                isMapEditMode: false,
                                waypointOnEditMode: null,
                            })
                        );
                        dispatch(setUpdatedCoordsOnMapAction(null));
                        dispatch(setCenterMapOnEditAction(null));
                    }}
                    sx={{
                        fontSize: '18px',
                    }}
                >
                    {t('cancel')}
                </Button>
            </div>
        </MapWindow>
    );
};
