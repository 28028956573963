import { Reducer } from 'redux';
import { DefaultRootState } from 'react-redux';
import {
    IEditShift,
    IPassengerShifts,
    IPassengerShiftUpdate,
    IRemoveShift,
    ISelectedShifts,
    ISelectedSingleShift,
    IShifts,
} from 'src/types/manualOrders/api.types';
import { IPassenger } from 'src/types/line';

import { ReqStatus } from 'src/api/types';
import { GeneralDate, ShiftType } from 'src/screens/ManualOrder/types';
import { DateAvailabilityStatus } from 'src/screens/ManualOrder/components/PlacemenTransportation/types';
import { arrsHaveSomeMatchingElement, datesAreOnSameDay, deepClone, isSameDate } from 'src/utilis/utilis';
import { PassengersShiftActionType } from '../actions/actionType';
import { IReduxProvider } from '../type';

export interface IDateRangeNew {
    startingDate: string | null;
    endingDate: string | null;
}
export interface IShiftSelection {
    isShiftSelected: boolean;
    selectedManualShift?: boolean | null;
    shiftId?: number;
    dropOffShiftId?: number;
}

export interface IManualOrderForm extends IShiftSelection {
    isOpen: boolean;
    blockedTableClickCount: number;
}
export interface IManualOrderTable {
    dateRange: IDateRangeNew;
}
export interface BlockedOrdersDatesForPassengers {
    [key: string]: { [key: string]: any };
}

export interface IPassengerShiftState extends DefaultRootState {
    passengers: Array<IPassengerShifts>;
    // blockedOrdersDatesForPassengers: BlockedOrdersDatesForPassengers;
    shifts: Array<IShifts>;
    selectedShift?: ISelectedShifts | null;
    selectedShiftType?: ShiftType | null;
    selectedSingleShift?: ISelectedSingleShift;
    editShift?: IEditShift | null;
    loadingPassengers: ReqStatus;
    updatePassengers: ReqStatus;
    loadingShifts: ReqStatus;
    ui: {
        form: IManualOrderForm;
        table: IManualOrderTable;
        currPath?: string;
    };
}
const initialFormState = {
    isOpen: false,
    blockedTableClickCount: 0,
    isShiftSelected: false,
};

const initialState: IPassengerShiftState = {
    passengers: [],
    // blockedOrdersDatesForPassengers: {},
    shifts: [],
    loadingPassengers: ReqStatus.LOADING,
    updatePassengers: ReqStatus.SUCCESS,
    loadingShifts: ReqStatus.SUCCESS,
    editShift: null,
    ui: {
        form: initialFormState,
        table: {
            dateRange: { startingDate: null, endingDate: null },
        },
    },
};

const onResetShiftSelection = (state: IPassengerShiftState, payload: any) => {
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                isShiftSelected: false,
                shiftId: null,
                selectedManualShift: false,
            },
        },
        selectedShift: null,
    };
};

const onChangedShiftSelection = (state: IPassengerShiftState, payload: any): IPassengerShiftState | any => {
    const { isShiftSelected, selectedManualShift, shiftId, dropOffShiftId } = payload;
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                isShiftSelected,
                selectedManualShift,
                shiftId,
                dropOffShiftId,
                datesAvailabilityStatus: [],
            },
        },
        // TODO! FIX TYPE
        selectedShift: selectedManualShift ? null : state.shifts.find((shift) => shift.shiftId === shiftId),
    };
};

const onRetrivePassenger = (state: IPassengerShiftState): IPassengerShiftState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.LOADING,
    };
};

const onRetrivePassengerSuccess = (state: IPassengerShiftState, payload: any): IPassengerShiftState => {
    return {
        ...state,
        passengers: [...payload.passengers],
        // blockedOrdersDatesForPassengers: { ...payload.passengerOrderDates },
        loadingPassengers: ReqStatus.SUCCESS,
    };
};

const onRetrivePassengerFail = (state: IPassengerShiftState): IPassengerShiftState => {
    return {
        ...state,
        loadingPassengers: ReqStatus.FAIL,
    };
};

const onRetriveShiftsSuccess = (state: any, payload: any): IPassengerShiftState => {
    return {
        ...state,
        shifts: [...payload],
        loadingShifts: ReqStatus.SUCCESS,
    };
};

const onAddPassngerShifts = (
    state: IPassengerShiftState | any,
    payload: IPassengerShifts | any
): IPassengerShiftState => {
    return {
        ...state,
        updatePassengers: ReqStatus.LOADING,
    };
};

const onAddPassngerShiftsSuccess = (
    state: IPassengerShiftState | any,
    payload: IPassengerShifts | any
): IPassengerShiftState => {
    return {
        ...state,
        passengers: state.passengers.map((item: IPassengerShifts) => {
            if (item.passId === payload.passId) return { ...payload };

            return { ...item };
        }),
        updatePassengers: ReqStatus.SUCCESS,
    };
};

const onDelPassngerShifts = (
    state: IPassengerShiftState | any,
    payload: IPassengerShifts | any
): IPassengerShiftState => {
    return {
        ...state,
        updatePassengers: ReqStatus.LOADING,
    };
};

const onDelPassngerShiftsSuccess = (
    state: IPassengerShiftState | any,
    payload: IPassengerShifts | any
): IPassengerShiftState => {
    return {
        ...state,
        passengers: state.passengers.map((item: IPassengerShifts) => {
            if (item.passId === payload.passId) return { ...payload };

            return { ...item };
        }),
        updatePassengers: ReqStatus.SUCCESS,
    };
};

const setSelectedShift = (state: IPassengerShiftState, payload: IShifts | any): IPassengerShiftState => {
    let selectedShift: null | IShifts = null;
    let isFullShift = false;
    if (!payload) {
        // console.log('empty payload')
        // * get selected shift by the edit shift state
        if (state.editShift?.dropTime || state.editShift?.pickupTime) {
            const { dropTime, pickupTime } = state.editShift;

            // if has both dropTime and pickupTime -> is full shift (auto shift)
            if (dropTime && dropTime.length > 0 && pickupTime && pickupTime.length > 0) {
                isFullShift = true;
            }

            // getting the shift full data
            state.shifts.forEach((loopedShift: IShifts) => {
                if (isFullShift) {
                    if (loopedShift.dropTime === dropTime && loopedShift.pickupTime === pickupTime)
                        selectedShift = loopedShift;
                } else if (loopedShift.dropTime === dropTime || loopedShift.pickupTime === pickupTime) {
                    selectedShift = {
                        ...loopedShift,
                        dropTime: dropTime || '',
                        pickupTime: pickupTime || '',
                    };
                }
            });
        }
    }

    // console.log({selectedShift})
    return {
        ...state,
        selectedShift: payload || selectedShift,
        selectedShiftType: isFullShift ? ShiftType.Auto : ShiftType.Manual,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                isShiftSelected: true,
                selectedManualShift: !isFullShift,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                shiftId: payload?.shiftId || selectedShift ? selectedShift!.shiftId : undefined,
            },
        },
    };
};

const setSelectedPassenger = (
    state: IPassengerShiftState | any,
    payload:
        | {
              pass: IPassenger;
              isSelected: boolean;
              dateRange: IDateRangeNew;
          }
        | any
): IPassengerShiftState => {
    const { pass, isSelected, dateRange } = payload;

    return {
        ...state,
        passengers: state.passengers.map((p: IPassengerShifts) =>
            p.passId === pass.passId ? { ...p, isSelected } : p
        ),
        ui: isSelected
            ? {
                  ...state.ui,
                  table: {
                      dateRange,
                  },
              }
            : {
                  ...state.ui,
              },
    };
};
const setSelectedAllPassengers = (
    state: IPassengerShiftState | any,
    payload:
        | {
              isSelected: boolean;
              passengers: IPassengerShifts[];
          }
        | any
): IPassengerShiftState => {
    const { passengers: updatedPsngrs } = payload;

    return {
        ...state,
        passengers: updatedPsngrs.length ? updatedPsngrs : state.passengers,
    };
};

const selectTableWeek = (state: IPassengerShiftState, payload: { dateRange: IDateRangeNew } | any) => {
    return {
        ...state,
        ui: {
            ...state.ui,
            table: {
                dateRange: payload.dateRange,
            },
        },
    };
};

const setSelectedSingleShift = (state: IPassengerShiftState, payload: ISelectedSingleShift | any): any => {
    const updatedPassengerWithSelected = state.passengers.map((pass) => {
        if (pass?.passId === payload?.passId) {
            return {
                ...pass,
                isSelected: true,
            };
        }
        return pass;
    });
    return {
        ...state,
        passengers: updatedPassengerWithSelected,
        selectedSingleShift: payload,
        // editShift: null,
    };
};

const onFormShowChange = (state: IPassengerShiftState, payload: any): IPassengerShiftState => {
    if (payload) {
        // is open
        return {
            ...state,
            editShift: payload.resetEditShift ? null : state.editShift,
            ui: {
                ...state.ui,
                form: {
                    ...state.ui.form,
                    isOpen: payload.isOpen,
                    blockedTableClickCount: 0,
                },
            },
        };
    }
    // else -> (is closed) - reset state
    return {
        ...state,
        editShift: null,
        ui: {
            ...state.ui,
            form: initialFormState,
        },
        selectedSingleShift: undefined,
    };
};

/**
 * Removes the shift for the given passenger and order codes.
 * @param {IPassengerShiftState | any} state - The current state of the application.
 * @param {IRemoveShift | any} payload - The payload of the action.
 * @returns {IPassengerShiftState | any} - The new state of the application.
 */
const removePassengersShift = (state: IPassengerShiftState, payload: IRemoveShift | any) => {
    const { passId, orderCodes } = payload as IRemoveShift;

    return {
        ...state,
        passengers: state.passengers.map((p: IPassengerShifts) =>
            !passId.includes(p.passId)
                ? p
                : {
                      ...p,
                      dates: p.dates.map((d) => ({
                          ...d,
                          pickupOrders: d.pickupOrders.filter((pick) => !orderCodes.includes(pick.orderCode)),
                          dropOrders: d.dropOrders.filter((drop) => !orderCodes.includes(drop.orderCode)),
                      })),
                  }
        ),
    };
};

/**
 * Sets the editShift state to the payload.
 * @param {IPassengerShiftState | any} state - the state object.
 * @param {IRemoveShift | any} payload - the payload object.
 * @returns None
 */
const setEditShift = (
    state: IPassengerShiftState | any,
    payload: IRemoveShift | any
): IPassengerShiftState => {
    // if (!payload) return;
    let shiftId = null;
    if (payload?.dropTime || payload?.pickupTime) {
        const { dropTime, pickupTime } = payload;

        let isFullShift = false;
        if (dropTime && dropTime.length > 0 && pickupTime && pickupTime.length > 0) {
            isFullShift = true;
        }

        state.shifts.forEach((shift: IShifts) => {
            if (isFullShift) {
                if (shift.dropTime === dropTime && shift.pickupTime === pickupTime) shiftId = shift.shiftId;
            }
        });
        // console.log({shiftId, dropTime, pickupTime})
    }

    const updatedPassengersWithNewSelectedPassenger = state.passengers.map((pass: any) => {
        if (pass?.passId === payload?.passId) {
            return {
                ...pass,
                isSelected: true,
            };
        }
        return pass;
    });

    return {
        ...state,
        passengers: updatedPassengersWithNewSelectedPassenger,
        editShift: payload ? { ...payload, shiftId } : payload,
    };
};

const onClickedBlockedTable = (state: IPassengerShiftState): IPassengerShiftState => {
    return {
        ...state,
        ui: {
            ...state.ui,
            form: {
                ...state.ui.form,
                blockedTableClickCount: state.ui.form.blockedTableClickCount + 1,
            },
        },
    };
};

const setAsOnlySelectedPassenger = (state: IPassengerShiftState, payload: any) => {
    const updatedPassengers = state.passengers.map((pass: any) => {
        if (pass?.passId === payload?.passId) {
            return {
                ...pass,
                isSelected: true,
            };
        }
        return {
            ...pass,
            isSelected: false,
        };
    });

    return {
        ...state,
        passengers: updatedPassengers,
    };
};

const handlerTypes: {
    [index: string]: IReduxProvider<IPassengerShiftState>;
} = {
    [PassengersShiftActionType.retrivePassenger]: onRetrivePassenger,
    [PassengersShiftActionType.retrivePassengerSuccess]: onRetrivePassengerSuccess,
    [PassengersShiftActionType.addPassngerShifts]: onAddPassngerShifts,
    [PassengersShiftActionType.addPassngerShiftsSuccess]: onAddPassngerShiftsSuccess,
    [PassengersShiftActionType.delPassngerShifts]: onDelPassngerShifts,
    [PassengersShiftActionType.delPassngerShiftsSuccess]: onDelPassngerShiftsSuccess,
    [PassengersShiftActionType.retrivePassengerFail]: onRetrivePassengerFail,
    [PassengersShiftActionType.retriveShiftsSuccess]: onRetriveShiftsSuccess,
    [PassengersShiftActionType.setSelectedShift]: setSelectedShift,
    [PassengersShiftActionType.setSelectedPassenger]: setSelectedPassenger,
    [PassengersShiftActionType.setSelectedAllPassengers]: setSelectedAllPassengers,
    [PassengersShiftActionType.setSelectedSingleShift]: setSelectedSingleShift,
    [PassengersShiftActionType.editShift]: setEditShift,
    [PassengersShiftActionType.removePassengersShift]: removePassengersShift,
    [PassengersShiftActionType.onFormShowChange]: onFormShowChange,
    [PassengersShiftActionType.onClickedBlockedTable]: onClickedBlockedTable,
    [PassengersShiftActionType.onChangedShiftSelection]: onChangedShiftSelection,
    // [PassengersShiftActionType.updateDateAvailabilityStatus]:
    //    updateDateAvailabilityStatus,
    [PassengersShiftActionType.selectTableWeek]: selectTableWeek,
    [PassengersShiftActionType.setAsOnlySelectedPassenger]: setAsOnlySelectedPassenger,
    [PassengersShiftActionType.onResetShiftSelection]: onResetShiftSelection,
};

const passengersShiftReudcer = (
    state = initialState,
    { type, payload }: { type: PassengersShiftActionType; payload: IPassengerShiftState }
): any => {
    const handler = handlerTypes[type];
    if (handler) {
        return handler(state, payload);
    }
    return state;
};

export default passengersShiftReudcer;
