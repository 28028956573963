export interface IProgressData {
    code: string;
    name: string;
    pick: number;
    drop: number;
}

export interface IProgressDataPayload {
    code: string;
    name: string;
    isPickup: string;
    totalOrders: number;
}

export enum FilterBy {
    Departments = 1,
    Shifts = 2,
}
