import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { onSelectReportAction } from 'src/store/slices/reports/reportsSlice';
import { selectedReportNameSelector } from 'src/store/selectores/reportsSelectors';
import useAuthorizedReports from 'src/screens/Reports/hooks/useAuthorizedReports';
import { ReportNames } from 'src/store/slices/reports/types';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { StyledSelect, selectStyles } from './ReportTypeDropdown.style';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getMenuItems, isReportAuthorized } from '../../../../utils';
import { ReportName } from '../../../../../../store/slices/reports/types';
import { useLastReportAfterPageClose } from './hooks/useLastReportAfterPageClose';

type Props = {};

const ReportTypeDropdown = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { authorizedReports } = useAuthorizedReports();
    useLastReportAfterPageClose();

    const selectedReportName: ReportNames = useAppSelector((state) => selectedReportNameSelector(state));
    const onChange = (e: any) => {
        dispatch(onSelectReportAction({ reportName: e.target.value }));
        localStorage.setItem('chosenReport', e.target.value);
    };

    return (
        <div style={selectStyles.container}>
            <div style={selectStyles.icon}>
                <AssignmentIcon />
            </div>
            <StyledSelect
                margin="none"
                size="small"
                variant="standard"
                defaultValue={selectedReportName}
                fullWidth
                disableUnderline
                onChange={onChange}
                value={selectedReportName}
            >
                {getMenuItems(t).map((reportItem) => {
                    const isHeadline = reportItem.type !== 'report';
                    return (
                        <MenuItem
                            disabled={
                                !isReportAuthorized(reportItem.value, authorizedReports) &&
                                reportItem.type !== 'headline'
                            }
                            sx={isHeadline ? selectStyles.headline : selectStyles.reportTypeItem}
                            key={reportItem.value}
                            value={reportItem.value}
                        >
                            {reportItem.name}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </div>
    );
};

export default ReportTypeDropdown;
