export interface Data<T> {
    message?: string;
    response?: string | number;
    data?: T;
}

export interface MockRouteResponse<T> {
    code: number | null;
    data: Data<T>;
}

export enum CommonCodes {
    Ok = 200,
    ExceptionCaught = 1500,
    MissingWs = 202,
    BadToken = 1,
    UnknownFailure = 1599,
    UnknownError = 1588,
    OperationFailed = 1600,
}
