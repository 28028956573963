import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import useIsInViewport from 'src/hooks/useIsInViewport';
import { useAppSelector, useRootAppSelector } from 'src/store/hooks';
import {
    formSelector,
    importCourseStationsAction,
    injectStationsOnEditAction,
    newPassengerFormSelector,
    setFormEndAddressAction,
    setFormStartAddressAction,
    setIsInjectionCompletedAction,
    toggleAutoCompleteAction,
    triggerDirectionsAction,
} from 'src/store/slices/lines/linesSlice';
import { useCurrFormType } from '../../addLinesForm.hooks';
import ExtraDetailsSection from '../formSections/ExtraDetailsSection.tsx/ExtraDetailsSection.index';
import ShowSectionBtn from '../formSections/ExtraDetailsSection.tsx/ShowSectionBtn';
import GeneralDetailsInputsSections from '../formSections/GeneralDetailsInputsSections';
import { fullFormHooks } from '../formSections/hooks.FullForm';
import { update } from 'lodash';
import { IStation, TripDirections } from 'src/types/line';
import { useDispatch } from 'react-redux';
import { AddressInputComponentIds, AutoCompleteInputStatus } from 'src/types/lines/addLineForm.types';
import storedCoursesHelpers from './storedCourses/storedCourses.helpers';
import { ParsedCourseStation } from 'src/types/lines/api/types';

type Props = { reachedBottomOfForm: boolean; isOverflowing: boolean };

const FullForm: React.FunctionComponent<Props> = ({ reachedBottomOfForm, isOverflowing }) => {
    const currForm = useCurrFormType();
    const ref2 = useRef<any>(null);
    const isOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.isOnEdit);
    const lineOnEdit = useRootAppSelector((state) => state.linesSlice.ui.form.lineOnEdit);
    const dispatch = useDispatch();

    const { updateValue } = fullFormHooks.useHelpers();

    const handleAddressUpdate = useCallback(
        (type: 'startAddress' | 'endAddress', data: ParsedCourseStation) => {
            // Register form values
            updateValue(type, storedCoursesHelpers.createAddressObj(data));

            // Toggle manual address input
            dispatch(
                toggleAutoCompleteAction({
                    status: AutoCompleteInputStatus.Off,
                    componentId:
                        type === 'startAddress'
                            ? AddressInputComponentIds.StartAddress
                            : AddressInputComponentIds.EndAddress,
                })
            );
        },
        [dispatch, updateValue]
    );

    useEffect(() => {
        // Edit Form Injections

        const normalStations: IStation[] = [];
        let startStation;
        let endStation;

        if (lineOnEdit?.stations) {
            // set as stations lst on slice
            lineOnEdit.stations.forEach((station) => {
                if (station.type === '0') {
                    startStation = station;
                    // tamir
                    console.log('startStation', startStation);
                    dispatch(setFormStartAddressAction({ startAddress: startStation }));
                }

                if (station.type === '1') normalStations.push(station);

                if (station.type === '2') {
                    endStation = station;
                    dispatch(setFormEndAddressAction({ endAddress: endStation }));
                }
            });
        }

        updateValue('clientCode', lineOnEdit?.accountCode ? lineOnEdit?.accountCode : null);
        updateValue('invContactName', lineOnEdit?.invContactName ? lineOnEdit?.invContactName : '');
        updateValue('invContactPhone', lineOnEdit?.invContactPhone ? lineOnEdit?.invContactPhone : '');
        updateValue('flightNum', lineOnEdit?.flightNumber ? lineOnEdit?.flightNumber : '');
        updateValue('clientOrderCode', lineOnEdit?.clientOrderCode ? lineOnEdit?.clientOrderCode : '');
        updateValue('carId', lineOnEdit?.carTypeCode ? lineOnEdit?.carTypeCode : '');
        updateValue('lineRemarks', lineOnEdit?.longRemarks ? lineOnEdit?.longRemarks : '');
        updateValue(
            'invContactPhone',
            lineOnEdit?.invContactPhone ? lineOnEdit?.invContactPhone.substring(3) : ''
        );
        updateValue(
            'invContactPhonePrefix',
            lineOnEdit?.invContactPhone ? lineOnEdit?.invContactPhone.substring(0, 3) : ''
        );
        updateValue('description', lineOnEdit?.lineDescription ? lineOnEdit?.lineDescription : '');
        updateValue('startTime', lineOnEdit?.startTime ? lineOnEdit?.startTime : '');
        updateValue('date', lineOnEdit?.lineDate);
        if (startStation) handleAddressUpdate('startAddress', startStation);
        if (endStation) handleAddressUpdate('endAddress', endStation);

        if (lineOnEdit?.startTime === lineOnEdit?.endTime) {
            updateValue('tripDirection', TripDirections.OneWay);
        } else {
            updateValue('endTime', lineOnEdit?.endTime ? lineOnEdit?.endTime : '');
            updateValue('tripDirection', TripDirections.Linked);
        }

        if (normalStations && normalStations.length > 0) {
            // set as stations lst on slice
            dispatch(injectStationsOnEditAction({ rawStationsLst: normalStations || [] }));
        }

        if (lineOnEdit) {
            dispatch(setIsInjectionCompletedAction({ isInjectionCompleted: true }));
        }
    }, [lineOnEdit]);

    return (
        <>
            {currForm === 'fullForm' && !reachedBottomOfForm && isOverflowing && (
                <ShowSectionBtn
                    onClick={() => {
                        ref2.current.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }}
                />
            )}
            <DisplayWrapper show={currForm === 'fullForm'}>
                <GeneralDetailsInputsSections />
                <ExtraDetailsSection ref2={ref2} />
            </DisplayWrapper>
        </>
    );
};

export default FullForm;
