import React, { useState, ReactChild, useEffect } from 'react';
import { ChangeEventValue, Coords } from 'google-map-react';
import Map from 'src/components/Map/Map';

import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import Tooltip from 'src/components/ToolTip/ToolTip';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useRootAppSelector } from 'src/store/hooks';
import {
    coursesBuildingSelectors,
    selectedCourseIdSelector,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { CircularProgress } from '@mui/material';
import { mapHelpers } from '../CourseForm/utils/common';
import useCommons from 'src/hooks/common/useCommons';
import {
    setGoogleApiInterfaceIntervalAction,
    setIsGoogleApiInterfaceCounterExceededAction,
} from 'src/store/slices/common/commonSlice';
import { updateGoogleApiCounter } from 'src/utilis/utilis';
/* eslint-disable import/no-cycle */
import { useGoogleApiCounterUp } from '../../hooks/useDirectionsApi';

const defaultZoom = 12;

export interface IMarker extends Coords {
    message: ReactChild | string;
    index: number;
    isDestination: boolean;
}

export const Marker: React.FunctionComponent<IMarker> = ({ message, index, isDestination }: IMarker) => {
    const [isShown, setIsShown] = useState<boolean>(false);

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <Tooltip title={message} open={isShown} arrow placement="top">
                <div style={{ position: 'absolute', right: '-20px', top: '-30px' }}>
                    <img
                        src={mapHelpers.getIconUrl(index, isDestination)}
                        alt="marker"
                        style={{ width: '30px', height: '30px' }}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

const MapSpinner: React.FC<Coords> = (props) => {
    return (
        <div style={{ position: 'relative', left: '25px', bottom: '20px' }}>
            <CircularProgress
                sx={{
                    color: '#2196F3',
                }}
            />
        </div>
    );
};

export const isValidCoords = (coords: Coords): boolean => {
    return coords.lat && coords.lng ? true : false;
};

const CourseWidgetMap: React.FunctionComponent<{}> = () => {
    const { status, data: allStations } = useRootAppSelector(
        (state) => coursesBuildingSelectors.apiDataSelector(state).getCourseStations
    );

    const selectedCourseId = useRootAppSelector(selectedCourseIdSelector);

    const googleCounterUp = useGoogleApiCounterUp();
    const [zoom, setZoom] = useState<number>(defaultZoom);
    const [center, setCenter] = useState<Coords>(defaultCenter);

    // if stations are loaded, setCenter to be the lat and lng of the middle station.
    React.useEffect(() => {
        if (allStations && allStations.length > 0) {
            const stationWithValidCoords = allStations.find((station) =>
                isValidCoords({ lat: station.lat, lng: station.lng })
            );

            if (stationWithValidCoords) {
                setCenter({
                    lat: stationWithValidCoords.lat,
                    lng: stationWithValidCoords.lng,
                });
            }
        }
    }, [allStations]);

    useEffect(() => {
        googleCounterUp.googleApiCounterUp();
    }, []);

    // * Event handlers

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    const isFormOpen = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.isOpen);

    if (isFormOpen) return null;

    return (
        <>
            <div
                style={{
                    borderRadius: '15px',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Map
                    yesIWantToUseGoogleMapApiInternals
                    center={center}
                    zoom={zoom}
                    defaultCenter={defaultCenter}
                    onChange={onMapChange}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '15px',
                    }}
                >
                    {status === 'SUCCESS' && selectedCourseId
                        ? allStations.map((station, i) =>
                              isValidCoords({ lat: station.lat, lng: station.lng }) ? (
                                  <Marker
                                      index={i}
                                      isDestination={allStations.length - 1 === i}
                                      key={station.stationId}
                                      lat={station.lat}
                                      lng={station.lng}
                                      message={`${i + 1} - ${station.city} ${station.street} ${
                                          station.houseNum
                                      }`}
                                  />
                              ) : (
                                  <></>
                              )
                          )
                        : null}
                    {status === 'LOADING' ? <MapSpinner lat={center.lat} lng={center.lng} /> : null}
                </Map>
            </div>
        </>
    );
};

export default CourseWidgetMap;
