import { TextField } from '@material-ui/core';
import React from 'react';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import useCommons from 'src/hooks/common/useCommons';
import { updateSettingBoxByIdAction } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { TimesSettingsBox } from 'src/types/coursesBuilding/form/formTypes';

const CarQtyInput: React.FC<{ boxData: TimesSettingsBox }> = ({ boxData }) => {
    const { t, dispatch } = useCommons();
    const [input, setInput] = React.useState<number>(boxData.carsQty);

    // if input did not change for X seconds, then dispatch action
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (input !== boxData.carsQty) {
                dispatch(updateSettingBoxByIdAction({ ...boxData, carsQty: input }));
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [dispatch, input]);

    return (
        <InputWithErrorsWrapper>
            <TextField
                error={false}
                variant="outlined"
                size="small"
                label={t('carQty' as any)}
                fullWidth
                style={{ backgroundColor: 'white' } as const}
                type="number"
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                onChange={(event) => {
                    const { value } = event.target;
                    if (+value > 100) return;
                    setInput(+value);
                }}
                value={input}
                InputLabelProps={{ shrink: typeof input === 'number' }}
            />
        </InputWithErrorsWrapper>
    );
};

export default CarQtyInput;
