import React from 'react';
import { AccordionProps, styled as muiStyled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';

import styled, { css } from 'styled-components';

const jsStyles = {
    typographyContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
} as const;

const SettingItem = styled.div<{ isExpanded: boolean }>`
    ${({ isExpanded }) =>
        css`
            border: 1px solid #edededff;
            border-radius: 4px;
            height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-right: 17px;
            :hover {
                ${isExpanded ? '' : 'background-color: #eeeeee'}
            }
            ${isExpanded
                ? css`
                      background-color: #ffedb1;
                  `
                : ''}
        `}
`;

const Accordion = muiStyled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid  #edededff`,

    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const StyledMui = {
    Accordion,
};

export const SettingsListStyles = {
    SettingItem,
    jsStyles,
};
