/* eslint-disable react/destructuring-assignment */
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyRefreshIcon from 'src/components/Icons/MyRefreshIcon';
import ManualOrdersInfoIcon from 'src/components/Icons/ManualOrdersInfoIcon';
import styled, { css } from 'styled-components';
import { fontFamilies, msColors } from 'src/style/themes/defaultTheme';
import { dateify, stringifyDate } from 'src/utilis/utilis';
import MyCalenderIcon from 'src/components/Icons/MyCalenderIcon';
import { DISPLAY_UI, DISPLAY_UI_LONG } from 'src/constants/dates';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    selectedReportNameSelector,
    selectedReportTypeSelector,
    widgetsUiSelector,
} from 'src/store/selectores/reportsSelectors';
import { ReportNames } from 'src/store/slices/reports/types';
import DatePickerWithPrevNext, { IDatePickerProps } from 'src/components/DatePicker/DatePickerWithPrevNext';
import { setDateOnWidgetAction } from 'src/store/slices/reports/reportsSlice';
import { Moment } from 'moment';
import { asRootReducer } from 'src/store/utils';
import { getStrAsTemplateStringArr } from '../../../../../utilis/utilis';
import * as Styles from '../OrdersInfo/Orderinfo.Style';
import widgetsHooks from '../hooks/common.hooks';

/**
 * A HOC that displays a component after a timeout.
 * @param {JSX.Element} component - the component to display after the timeout
 * @param {number} timeout - the number of milliseconds to wait before displaying the component
 * @returns None
 */
function DisplayComponentAfterTimeout(component: JSX.Element, timeout: number) {
    const isWidgetDrawerOpen = useAppSelector((state) => widgetsUiSelector(asRootReducer(state)).isOpen);

    const [display, setDisplay] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (isWidgetDrawerOpen) {
                setDisplay(true);
            }
        }, timeout);
    }, [timeout, isWidgetDrawerOpen]);

    useEffect(() => {
        if (!isWidgetDrawerOpen) {
            setDisplay(false);
        }
    }, [isWidgetDrawerOpen]);
    return display ? component : <div style={{ width: '186px' }} />;
}

const StyledDateDisplayBoxContainer = styled.div<{ style?: CSSProperties; showEndDate: boolean }>`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    ${(props) => {
        return css``;
    }}
    @media (max-width: ${1620}px) {
    }
`;

const StaticDateDisplayBox = (props: {
    date1: Date | Moment;
    date2: Date | Moment;
    showEndDate: boolean;
}) => (
    <StyledDateDisplayBoxContainer showEndDate={props.showEndDate}>
        <p style={{ fontSize: '18px' }}>
            {props.showEndDate
                ? `${stringifyDate(props.date1, DISPLAY_UI_LONG)} - ${stringifyDate(
                      props.date2,
                      DISPLAY_UI_LONG
                  )}`
                : stringifyDate(props.date1, DISPLAY_UI_LONG)}
        </p>
    </StyledDateDisplayBoxContainer>
);

const DelayedDatePicker: React.FC<any> = ({ datePickerProps }) => {
    return DisplayComponentAfterTimeout(
        <DatePickerWithPrevNext withDebounceTime {...datePickerProps} />,
        300
    );
};
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
    },
    icon: {
        height: '30px',
        width: '30px',
        backgroundColor: '#FFEDB1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginLeft: '10px',
    },
};
const WidgetHeader = ({ date, title }: { date: Date; title: string }) => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const dispatch = useAppDispatch();
    const reportName = useAppSelector(selectedReportNameSelector);

    const { datesAfterApply } = widgetsHooks.useDatesRange();

    const handleDatePickerChange = useCallback(
        (newDate) => {
            const currTime = new Date();
            const newSelectedDate = newDate || currTime;
            setSelectedDate(newSelectedDate);
            dispatch(setDateOnWidgetAction({ newDate }));
        },
        [dispatch]
    );

    React.useEffect(() => {
        setSelectedDate(date);
    }, [date]);

    const datePickerProps: IDatePickerProps = useMemo(
        () => ({
            showTodayButton: true,
            inputVariant: 'outlined',
            style: { width: '100%', backgroundColor: 'white' },
            size: 'small',
            format: 'dd.MM.yyyy',
            value: selectedDate,
            onChange: handleDatePickerChange,
            KeyboardButtonProps: {
                'aria-label': 'change date',
            },
        }),
        [handleDatePickerChange, selectedDate]
    );

    return (
        <Styles.OrderHeader>
            <div style={styles.mainContainer} className="mainContainer">
                <div style={styles.titleContainer} className="titleContainer">
                    <div style={styles.icon} className="icon">
                        <ManualOrdersInfoIcon />
                    </div>
                    <Styles.Title>{t(getStrAsTemplateStringArr(title))}</Styles.Title>
                </div>
                <div className="dateBox">
                    {reportName === 'passengers' ? (
                        <DelayedDatePicker datePickerProps={datePickerProps} />
                    ) : (
                        <StaticDateDisplayBox
                            date1={datesAfterApply.fromDate}
                            date2={datesAfterApply.toDate}
                            showEndDate={reportName !== 'visas'}
                        />
                    )}
                </div>
            </div>
        </Styles.OrderHeader>
    );
};

export default WidgetHeader;
