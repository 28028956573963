import { GridCellProps } from '@progress/kendo-react-grid';
import moment from 'moment';
import React, { CSSProperties, FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IPassengerShifts, IShifts } from 'src/types/manualOrders/api.types';
import KendoGrid, { KendoColumnProps, TableProp } from 'src/components/KendoGrid/KendoGrid';
import { useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { shiftsSelector } from 'src/store/selectores/passengerShiftsSelectores';
import { shiftCapsulesSelector } from 'src/store/slices/manualOrders/manualOrdersSlice';
import { IShiftCapsule } from 'src/store/slices/manualOrders/types';
import { asTSR, getDatesBetween, isSameDate } from 'src/utilis/utilis';
import styled from 'styled-components';
import { FIELD_DATE_FORMAT } from '../../ManualOrderTable/Shiftcell/Shiftcell';

interface DataOrdersCellProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: IPassengerShifts;
    shifts: IShifts[];
}

const RotatedText = styled.span`
    transform: rotate(-90deg);
    font-weight: bold;
`;

export const Separator: React.FC<{}> = ({}) => {
    return <RotatedText> - </RotatedText>;
};

export const ShiftTimeDisplay: React.FC<{
    startTime: string;
    endTime: string;
}> = ({ startTime, endTime }) => {
    if (startTime && endTime) {
        return (
            <div>
                {startTime}
                <Separator />
                {endTime}
            </div>
        );
    }
    if (startTime && !endTime) {
        return (
            <div style={{ textAlign: 'left' }}>
                {startTime}
                <Separator />
            </div>
        );
    }
    return (
        <div style={{ textAlign: 'right' }}>
            <Separator />
            {endTime}
        </div>
    );
};

const DateOrdersCell: React.FC<DataOrdersCellProps> = ({ dataItem, shifts, field }) => {
    const capsulesForCell: IShiftCapsule[] = useRootAppSelector((state) => {
        const allCapsules = shiftCapsulesSelector(state);
        if (allCapsules) {
            const capsulesForPassenger = allCapsules[dataItem.passId];
            if (Array.isArray(capsulesForPassenger)) {
                return (
                    capsulesForPassenger.filter((capsule) =>
                        isSameDate(capsule.date, moment(field, FIELD_DATE_FORMAT))
                    ) || []
                );
            }
        }
        return [];
    });

    return (
        <td style={{ padding: 2, margin: 0 }}>
            {capsulesForCell.map(({ endTime, startTime }) => {
                return ShiftTimeDisplay({ startTime, endTime });
            })}
        </td>
    );
};

interface IProps {
    startDate: Date;
    endDate: Date | undefined;
    data: IPassengerShifts[];
    exporting: {
        isPrinting: boolean;
        tableRef: React.RefObject<any>;
    };
}

const ExportedKendoTable: FunctionComponent<IProps> = React.memo(
    ({ data, startDate, endDate, exporting }) => {
        const { t } = useTranslation();
        const { tableRef, isPrinting } = exporting;

        const shifts = useSelector((state: IRootReducer) => shiftsSelector(state)) || [];

        const columns = useMemo<Array<KendoColumnProps>>(() => {
            const dates = getDatesBetween(startDate, endDate || startDate, true);
            const result: Array<KendoColumnProps> = [
                {
                    field: 'fullName',
                    title: 'רשימת נוסעים',
                    sortable: false,
                    width: 150,
                },
                {
                    field: 'city',
                    width: 110,
                    title: t('city'),
                    sortable: false,
                },
            ];
            dates.forEach((date) => {
                const dayName: string = moment(date).format('dddd').toLowerCase();
                const dateStr = moment(date).format('DD.MM.YY');
                result.push({
                    field: dateStr,
                    title: `${t(asTSR(dayName))} ${dateStr}`,
                    editor: 'numeric',
                    sortable: false,
                    cell: (props) => <DateOrdersCell {...props} shifts={shifts} />,
                });
            });

            return result;
        }, [startDate, endDate, t, shifts]);

        const tableProp: TableProp = useMemo(
            () => ({
                columns,
                data,
                total: data.length,
                className: 'k-rtl',
                style: {
                    height: '100%',
                    width: '100%',
                    maxHeight: isPrinting ? 'none' : '100%',
                },
                wrapperDivStyle: {
                    height: '100%',
                    width: isPrinting ? 'auto' : ' 100%',
                    position: isPrinting ? 'absolute' : 'static',
                },
                selectable: {
                    enabled: false,
                    drag: false,
                    cell: false,
                    mode: 'multiple',
                },
                navigatable: false,
                sortable: false,
                resizable: false,
                reorderable: false,
            }),
            [columns, data, isPrinting]
        );

        return (
            <div ref={tableRef} style={{ overflow: 'auto' }} className="export-table-container">
                <table id="manual-orders-table">
                    <KendoGrid {...tableProp} />
                </table>
            </div>
        );
    }
);

export default ExportedKendoTable;
