import React from 'react';
import { useSelector } from 'react-redux';
import useCommons from 'src/hooks/common/useCommons';
import coursesBuildingRoutes from 'src/mockServer/routes/coursesBuildingRoutes';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import {
    apiCallTriggerCountSelector,
    coursesBuildingActions,
    filtersSelector,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import { CourseTypes } from 'src/types/coursesBuilding/commonTypes';
import { ApiCallsNames } from 'src/types/coursesBuilding/storeTypes';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { courseIsActiveMap } from 'src/types/passengersManager/courseChoosingTypes';

const ALL_DEPARTMENTS_KEY = '0';
export const useApiCallStatusUpdate = () => {
    const dispatch = useAppDispatch();

    const updateApiCall = React.useCallback(
        <T>(callName: ApiCallsNames, status: ApiCallStatus, data: T) => {
            dispatch(
                coursesBuildingActions.updateApiCall({
                    data,
                    callName,
                    status,
                })
            );
        },
        [dispatch]
    );
    return { updateApiCall };
};

const useAllCoursesFetch = () => {
    const { dispatch, dispatchI18nErrMsg } = useCommons();

    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const selectedClientCode = useRootAppSelector((state) => uiSelector(state).clientCode);
    const refreshBtnClickCount = useRootAppSelector((state) => uiSelector(state).refreshBtnClickCount);
    const dbUrl = selectedFcAccount?.dbUrl || '';
    const loggedUserDepartmentCode = useRootAppSelector(userDetailsSelector).departmentCode;

    const filtersVals = useRootAppSelector(filtersSelector);
    const apiCallTriggerCount = useRootAppSelector((state) =>
        apiCallTriggerCountSelector(state, 'getAccountCourses')
    );

    React.useEffect(() => {
        const fetchData = async ({ clientCode }: { clientCode: string }) => {
            dispatch(
                coursesBuildingActions.updateApiCallStatus({
                    callName: 'getAccountCourses',
                    status: 'LOADING',
                })
            );

            const departmentId = filtersVals.departmentId;

            const departmentCodeParam =
                departmentId && departmentId !== ALL_DEPARTMENTS_KEY ? departmentId : '';

            // To avoid fetching courses for all departments if user is from CERTAIN department
            if (loggedUserDepartmentCode && !departmentCodeParam) return;

            const r = await coursesBuildingRoutes.getAccountCourses({
                requestConfig: {
                    dbUrl,
                    requestParams: {
                        token,
                        clientCode,
                        // Filters
                        isActive:
                            filtersVals.courseType === CourseTypes.All
                                ? null
                                : courseIsActiveMap[filtersVals.courseType],
                        departmentCode: departmentCodeParam,
                    },
                },
            });

            if (r.code !== CommonCodes.Ok) {
                dispatchI18nErrMsg(r.data.message);
                dispatch(
                    coursesBuildingActions.updateApiCall({
                        data: [],
                        callName: 'getAccountCourses',
                        status: 'FAILED',
                    })
                );
                return;
            }

            dispatch(
                coursesBuildingActions.updateApiCall({
                    data: r.data.data,
                    callName: 'getAccountCourses',
                    status: 'SUCCESS',
                })
            );
        };

        let handler: NodeJS.Timeout | null = null;

        if (selectedClientCode && dbUrl && token) {
            // delay to wait for loggedUserDepartmentCode to be updated
            handler = setTimeout(() => {
                try {
                    fetchData({ clientCode: selectedClientCode });
                } catch (e) {
                    console.error(e);
                }
            }, 500);
        }

        return () => {
            if (handler) clearTimeout(handler);
        };
    }, [
        dbUrl,
        dispatch,
        dispatchI18nErrMsg,
        filtersVals.courseType,
        filtersVals.departmentId,
        selectedClientCode,
        token,
        refreshBtnClickCount,
        loggedUserDepartmentCode,
        apiCallTriggerCount,
    ]);
};

export default useAllCoursesFetch;
