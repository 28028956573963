/* eslint-disable react/prop-types */
import React, { FC, useState, useEffect } from 'react';
import { Coords } from 'google-map-react';

export interface IPolyline {
    markers: Array<Coords>;
    map: any;
    maps: any; // api
}

export const Polyline: FC<IPolyline> = ({ markers, map, maps }: IPolyline) => {
    const [polilineMarkers, setPolilineMarkers] = useState<Array<Coords>>(markers);

    const [polyline, setPolyline] = useState<any>();

    useEffect(() => {
        setPolilineMarkers(markers);
    }, [markers]);

    useEffect(() => {
        const setPolylineusingMapapi = () => {
            if (!maps || !map) return;

            if (polyline) {
                polyline.setMap(null);
            }

            if (polilineMarkers.length) {
                const geodesicPolyline = new maps.Polyline({
                    path: polilineMarkers,
                    geodesic: true,
                    strokeColor: '#00a1e1',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: {
                                path: maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            },
                            offset: '100%',
                            repeat: '50px',
                        },
                    ],
                });
                geodesicPolyline.setMap(map);
                setPolyline(geodesicPolyline);
            }
        };

        setPolylineusingMapapi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, maps, polilineMarkers]);

    return <></>;
};

export default Polyline;
