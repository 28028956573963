import React, { FC, useState } from 'react';
import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import useCommons from 'src/hooks/common/useCommons';
import FormHeader from 'src/components/formComponents/FormHeader';
import useFormHeader from '../../hooks/useFormHeader';
import {
    optimizationsRootSelector,
    setCenterMapOnEditAction,
    setIsEditFormAction,
    setIsFormDirtyAction,
    setIsFormOpenAction,
    setIsMapEditModeAction,
    setIsOptimizationSimulationScreenOpenAction,
    setUpdatedCoordsOnMapAction,
    updateFormWaypointAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import OptimizationWaypointInputBox from '../OptimizationWaypointInputBox';
import { useRootAppSelector } from 'src/store/hooks';
import NakedBtn from 'src/components/buttons/NakedBtn';
import { uuid } from 'src/utilis/utilis';
import { useSelector } from 'react-redux';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { IRootReducer } from 'src/store/reducers';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useSetOptimizationWaypoints } from '../../hooks/useSetOptimizationWaypoints';
import SimulationWidgetMap from './SimulationWidgetMap';
import { useSetOptimizationStations } from '../../hooks/useSetOptimizationStations';
import { TextField } from '@material-ui/core';

interface Props {}

const SimulationForm: FC<Props> = React.forwardRef((props, ref) => {
    const { dispatch } = useCommons();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const setOptimizationStations = useSetOptimizationStations();

    const onCancel = () => {
        console.log('cancel');
        dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: false }));
    };

    const onSubmit = async () => {
        setIsSubmitting(true);

        setOptimizationStations.setOptimizationWaypoints();
        // save
        setIsSubmitting(false);
        dispatch(setIsOptimizationSimulationScreenOpenAction({ isOpen: false }));
    };

    const headerTexts = useFormHeader();

    return (
        <div
            ref={ref as React.RefObject<HTMLDivElement>}
            style={{ height: '100%', paddingTop: '30px', textAlign: 'right' }}
        >
            <WidgetFormWrapper
                btnsConfig={{
                    onSubmit,
                    onCancel,
                    isSubmitting,
                    withLoader: true,
                    disabled: false,
                }}
                HeaderComponent={() =>
                    FormHeader({
                        mainText: 'מגרש משחקים',
                        secondaryText: '',
                    })
                }
            >
                <SimulationWidgetMap />
            </WidgetFormWrapper>
        </div>
    );
});

export default SimulationForm;
