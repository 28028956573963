/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccountCourses } from 'src/api/reportsApi/reportsApi';
import { setErrorCode } from 'src/store/actions/loginAction';
import { useAppDispatch } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { getErrorCodeByResponse } from 'src/store/sagas/utils/sagasUtils';
import { selectedFcAccountSelector, tokenSelector } from 'src/store/selectores/loginSelectors';
import {
    clientIdSelector,
    passengersUISelector,
    selectedPassengerPasscodeSelector,
} from 'src/store/selectores/passengersSelector';
import {
    onErrorAction,
    onGetAccountCoursesSuccessAction,
    resetCoursesUiStateAction,
} from 'src/store/slices/passengersManager/passengersManagerSlice';
import { ErrorMessagesOld } from 'src/store/type';
import { GetAccountCoursesRequest } from 'src/types/passengersManager/courseChoosingTypes';

const useSelectors = () => {
    const selectedFcAccount = useSelector((state: IRootReducer) => selectedFcAccountSelector(state));
    const token = useSelector((state: IRootReducer) => tokenSelector(state));
    const clientId = useSelector((state: IRootReducer) => clientIdSelector(state));
    const selectedPassengers = useSelector((state: IRootReducer) => selectedPassengerPasscodeSelector(state));
    const isAddingPassenger = useSelector(
        (state: IRootReducer) => passengersUISelector(state).addingPassenger
    );

    return {
        selectedFcAccount,
        token,
        clientId,
        isAddingPassenger,
        selectedPassengers,
    };
};

const useAccountCourses = (): void => {
    const dispatch = useAppDispatch();
    const {
        selectedFcAccount,
        token,
        clientId,
        isAddingPassenger,
        selectedPassengers: selectedPassengersCount,
    } = useSelectors();

    const haveDataForApiCall = useMemo(
        (): boolean => !!(selectedFcAccount?.dbUrl && token && clientId),
        [clientId, selectedFcAccount, token]
    );

    useEffect(() => {
        dispatch(resetCoursesUiStateAction());
    }, [dispatch, isAddingPassenger, selectedPassengersCount]);

    useEffect(() => {
        if (!haveDataForApiCall || !clientId || !selectedFcAccount?.dbUrl) return;
        const requestConfig: GetAccountCoursesRequest = {
            dbUrl: selectedFcAccount.dbUrl,
            requestParams: {
                isActive: 1,
                clientCode: String(clientId),
                token,
            },
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (async () => {
            const { data, status } = await getAccountCourses(requestConfig);
            if (status !== 200) {
                let errorMsg = `invalid ws status code: ${status}`;
                if (status === 202) errorMsg = ErrorMessagesOld.MissingWs;
                dispatch(
                    onErrorAction({
                        msg: errorMsg,
                    })
                );
                return;
            }
            const { data: courses, response } = data;
            if (response === '0') {
                dispatch(onGetAccountCoursesSuccessAction({ courses }));
            } else {
                const errorCode = getErrorCodeByResponse(response);
                dispatch(setErrorCode(errorCode));
            }
            //  console.log({ courses, response });
        })();
    }, [clientId, dispatch, haveDataForApiCall, selectedFcAccount, token]);
};

export default useAccountCourses;
