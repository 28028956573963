import { Button, TextFieldProps } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'src/components/Inputs/TextInput';
import { Station, StationInput, ValidationError } from 'src/screens/Main/components/AddLineForm/types';
import { XBtn } from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/AddressInput';
import { RawInputAddress } from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/AddressForm/types';
import { INITIAL_ADDRESS_OBJECT } from 'src/screens/PassengerManger/components/PassengerForm/Components/AddressesBox/utils';
import { IRootReducer } from 'src/store/reducers';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
import { IObject, ReactChangeEvent } from 'src/types/global';
import { AssertsShape } from 'yup/lib/object';
import { colors } from 'src/style/themes/defaultTheme';
import { defaultStyle, defaultTextFieldProps } from '../../styles';
import InputWithErrorsWrapper from '../../../Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';

export type Props = {
    onClickExit: () => void;
    formMethods: {
        formState: {
            errors: ValidationError;
        };
        register: Function;
    };
    style?: IObject;
    shrink?: boolean;
};

const getTranslatedErrorMsg = (errorForField: FieldError | undefined, t: (str: string) => string) => {
    if (errorForField) {
        return t(errorForField?.message || '');
    }
    return '';
};

const ManualAddressInputVer2: FC<Props> = ({ onClickExit, style, shrink = true, formMethods }) => {
    const { t } = useTranslation();

    const {
        formState: { errors },
        register,
    } = formMethods;

    return (
        <>
            <div style={{ display: 'flex', gap: '5px' }}>
                <div style={style || defaultStyle}>
                    <InputWithErrorsWrapper
                        style={{ flex: 1.5 }}
                        errorMessage={getTranslatedErrorMsg(errors?.city, t)}
                    >
                        <TextInput
                            {...defaultTextFieldProps}
                            InputLabelProps={{ shrink }}
                            error={!!errors?.city}
                            label={t('city')}
                            {...register('city')}
                        />
                    </InputWithErrorsWrapper>
                    <InputWithErrorsWrapper
                        style={{ flex: 3 }}
                        errorMessage={getTranslatedErrorMsg(errors?.street, t)}
                    >
                        <TextInput
                            {...defaultTextFieldProps}
                            label={t('street')}
                            error={!!errors?.street}
                            InputLabelProps={{ shrink }}
                            {...register('street')}
                        />
                    </InputWithErrorsWrapper>
                    <InputWithErrorsWrapper
                        style={{ flex: 1 }}
                        errorMessage={getTranslatedErrorMsg(errors?.houseNum, t)}
                    >
                        <TextInput
                            {...defaultTextFieldProps}
                            label={t('number')}
                            type="number"
                            error={!!errors?.houseNum}
                            InputLabelProps={{ shrink }}
                            {...register('houseNum')}
                        />
                    </InputWithErrorsWrapper>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button sx={{ color: colors.blue }} onClick={onClickExit}>
                    חפש כתובת ממאגר
                </Button>
            </div>
        </>
    );
};

export default ManualAddressInputVer2;
