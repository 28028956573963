import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsSx } from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/Components/CoursesList/CourseTypeTabs/styles';
import FullContainer from 'src/components/Wrappers/FullContainer';
import StationsAndTimesPanel from './StationsAndTimesPanel';
import { ExtendedCourse, TabsTypes } from './common';
import GeneralDetailsPanel from './GeneralDetailsPanel';

const CourseTypeTabs = ({ course }: { course: ExtendedCourse }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = React.useState<TabsTypes>(TabsTypes.GeneralDetails);

    return (
        <div style={{ width: '100%' }}>
            <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                sx={tabsSx}
            >
                <Tab value={TabsTypes.GeneralDetails} label={t('generalDetails')} />
                <Tab value={TabsTypes.StationsAndTimes} label={t('stationsAndTimes')} />
            </Tabs>
            <div className="TabContainer">
                <GeneralDetailsPanel course={course} isHidden={selectedTab !== TabsTypes.GeneralDetails} />
                <StationsAndTimesPanel isHidden={selectedTab !== TabsTypes.StationsAndTimes} />
            </div>
        </div>
    );
};

export default CourseTypeTabs;
