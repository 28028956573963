import { GoogleCounterApiResponse, GoogleCounterParams } from 'src/types/googleCounterApi/googleCounterApi';
import { AxiosRes } from '../types';
import axios from 'axios';

// SWITCH WHEN DONE

const MAIN_URL_DEV =
    'http://interfaceserviceapitest.yit.co.il/GoogleApiCounterService/GoogleApiCounterService.svc';
const MAIN_URL_STAGE =
    'https://interfaceserviceapi.ebstage.y-it.co.il/GoogleApiCounterService/GoogleApiCounterService.svc';
const MAIN_URL_PROD =
    'https://interfaceserviceapi.y-it.co.il/GoogleApiCounterService/GoogleApiCounterService.svc';

const createUrl = (endpoint: string) => `${MAIN_URL_PROD}/${endpoint}`;

const ENDPOINTS = {
    SEND_CLIENT_COUNTER_JSON: 'json_ws_GoogleApi_Counter_Update',
    SEND_CLIENT_COUNTER_FORM_URL_ENCODED: 'ws_GoogleApi_Counter_Update',
} as const;

const addDollarSignPrefix = (bodyObj: Object) => {
    const newObj = {};
    Object.keys(bodyObj).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[`$${key}`] = bodyObj[key];
    });
    return newObj;
};

export const sendClientCounter = (reqBody: GoogleCounterParams): AxiosRes<GoogleCounterApiResponse> => {
    return axios.post(createUrl(ENDPOINTS.SEND_CLIENT_COUNTER_JSON), addDollarSignPrefix(reqBody));
};
