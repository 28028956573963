/* eslint-disable import/no-unresolved */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { passengersUISelector } from 'src/store/selectores/passengersSelector';
// eslint-disable-next-line import/no-unresolved
import { onChangeAddress } from 'src/store/actions/passengerAction';
import { getMapsAPIKey } from 'src/utilis/utilis';
import { dirtyUp } from '../utils';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

const AddressAutoCompleteInput = ({
    // fullCurrentAddressObj,
    addressTxt,
    modefiedPassenger,
    setAddress,
    setModifiedPassenger,
    addressInfo,
    setChangesCount,
}) => {
    // const addressStr = stringifyAddress(fullCurrentAddressObj);
    // * Hooks
    const dispatch = useDispatch();
    const { placePredictions, getPlacePredictions, placesService } = usePlacesService({
        apiKey: getMapsAPIKey(),
        options: {
            language: 'iw',
            componentRestrictions: { country: 'isr' },
        },
    });
    const hasValidAddress = useSelector((state) => passengersUISelector(state).form.hasValidAddress);
    // console.log('autocomplete rendered');
    // * Local State
    const [text, setText] = useState('');
    const [addressTextChanged, setAddressTextChanged] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [addressComponents, setAddressComponents] = useState([]);
    const [selectedChanged, setSelectedChanged] = useState(false);
    const googleCounterUp = useGoogleApiCounterUp();


    // * Effects
    useEffect(() => {
        setAddressTextChanged(false);
        setText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modefiedPassenger.passCode]);

    useEffect(() => {
        if (selectedChanged) dirtyUp(modefiedPassenger, setModifiedPassenger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChanged]);

    useEffect(() => {
        if (!selectedAddress && selectedChanged) {
            setAddressComponents([]);
            return;
        }
        // eslint-disable-next-line no-empty-pattern
        const selectedPlace = placePredictions.find((place) => place.description === selectedAddress);
        if (!selectedPlace) return;

        googleCounterUp.googleApiCounterUp();

        placesService?.getDetails(
            {
                placeId: selectedPlace.place_id,
            },
            (placeDetails) => {
                setAddressComponents(placeDetails.address_components);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress, selectedChanged]);

    useEffect(
        () => {
            const createFormattedAddress = () => {
                const data = {};
                data.city = addressComponents.find((component) =>
                    component.types.includes('locality')
                )?.short_name;
                data.street = addressComponents.find((component) =>
                    component.types.includes('route')
                )?.short_name;
                data.houseNum = addressComponents.find((component) =>
                    component.types.includes('street_number')
                )?.short_name;
                setAddress({
                    ...addressInfo,
                    ...data,
                    remarks: addressInfo.remarks || '',
                });
            };

            if (addressComponents.length > 0) {
                createFormattedAddress();
            } else {
                setAddress({
                    ...addressInfo,
                    city: '',
                    street: '',
                    houseNum: '',
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [addressComponents]
    );

    const handleValidAddress = (addressStr) => {
        dispatch(onChangeAddress(true));
        setSelectedAddress(addressStr);
        setSelectedChanged(true);
    };

    // * JSX
    return (
        <>
            <Autocomplete
                // disableClearable
                onInputChange={(evt, newValue) => {
                    setText(newValue);
                    setChangesCount((prev) => prev + 1);
                    setAddressTextChanged(true);
                    if (!newValue) {
                        handleValidAddress('');
                        return;
                    }


                    googleCounterUp.googleApiCounterUp();


                    getPlacePredictions({ input: newValue });
                    if (hasValidAddress && newValue) dispatch(onChangeAddress(false));
                }}
                freeSolo
                onChange={(evt, value) => handleValidAddress(value)}
                size="small"
                inputValue={addressTextChanged ? text : addressTxt}
                disablePortal
                filterOptions={(options) => options}
                options={placePredictions.map((place) => place.description)}
                renderInput={(params) => {
                    return (
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            {...params}
                            label="כתובת"
                        />
                    );
                }}
            />
        </>
    );
};

export default AddressAutoCompleteInput;
