import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPassengers } from 'src/api/passengerMangerApi/passengerMangerApi';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants/misc';
import useGetApiRequestBasicParams from 'src/hooks/useGetApiRequestBasicParams';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import {
    screenClientCodeSelector,
    setPlacementAddressAction,
    selectedAddressForPlacementSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { IAddressWithIdAndIsDefault } from 'src/types/misc';
import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { FormControlProps } from '@material-ui/core';
import { myWayStudioWsPrefix } from 'src/api/constants';
import { ErrorMessagesOld } from 'src/store/type';
import querystring from 'querystring';
import { createPayload } from 'src/api/utilis';
import { reqGetPassengerAddress } from 'src/api/manualOrderApi/manualOrderApi';
import styles from './styles.PlacementAddressSelection';
import helpers, { SUCCESS } from './helpers.PlacementAddressSelection';
import { AddressDropdownProps } from './types.PlacementAddressSelection';
import { buildEncriptedUrl } from '../../../../../../api/index';
import { PlacemenContext } from '../../context';

const formControlProp: FormControlProps = {
    variant: 'outlined',
    style: { width: '100%' },
    size: 'small',
};

// const buildBody = (params: GetPassengerAddressParams) => {
//     const body = {};
//     Object.entries(params).forEach(([param, value]) => {
//         body[`$${param}`] = value;
//     });
//     return body;
// };

/**
 * A hook that fetches the passenger's addresses from the database.
 * @param {string} passCode - the pass code of the passenger.
 * @returns {(allAddresses: IAddressWithIdAndIsDefault[]) => void} - Array of the passengers addresses
 */
const INITIAL_ALL_ADDRESSES_VALUE = { pickups: [], drops: [] };
const usePassengerDataFetch = (passCode: string, selectedTab: 'manual' | 'auto') => {
    const dispatch = useAppDispatch();

    // Context
    const [placement] = useContext(PlacemenContext);

    // Selectors
    const clientCode = useRootAppSelector(screenClientCodeSelector);
    const { token, dbUrl, proxyUrl } = useGetApiRequestBasicParams();

    // Variables
    const selectedShift = useMemo(() => {
        if (selectedTab === 'manual') {
            return {
                pickupTime: placement.manualOrder.pickup.pickupTime,
                dropTime: placement.manualOrder.drop.dropTime,
            };
        }
        return { pickupTime: placement.autoOrder.pickupTime, dropTime: placement.autoOrder.dropTime };
    }, [
        placement.autoOrder.dropTime,
        placement.autoOrder.pickupTime,
        placement.manualOrder.drop,
        placement.manualOrder.pickup,
        selectedTab,
    ]);

    const isShiftSelected: boolean = !!(selectedShift.dropTime || selectedShift.pickupTime);
    const [isLoading, setIsLoading] = useState(false);

    // Local states
    const [allUserAddresses, setAllUserAddresses] = useState<{
        pickups: IAddressWithIdAndIsDefault[];
        drops: IAddressWithIdAndIsDefault[];
    }>(INITIAL_ALL_ADDRESSES_VALUE);

    // Handle the api calls for both the passenger address and the addresses from the combinations
    useEffect(() => dispatch(setPlacementAddressAction(null)), [dispatch, selectedTab]);

    useEffect(() => {
        const MISSING_WS_RESPONSE_CODE = 42;
        (async () => {
            if (token && dbUrl && passCode && clientCode && isShiftSelected) {
                setIsLoading(true);
                const reqCombinationsAddresses = async (isPickup: boolean) => {
                    const MISSING_WS_CODE_STATUS_CODE = 202;
                    const payload = {
                        token,
                        dbUrl,
                        passCode,
                        clientCode,
                        pickupTime: isPickup ? selectedShift.pickupTime : '',
                        dropTime: isPickup ? '' : selectedShift.dropTime,
                    };

                    if (!payload.pickupTime && !payload.dropTime) {
                        return { response: -1, data: [] } as const;
                    }

                    const {
                        data: { data, response },
                        status,
                    } = await reqGetPassengerAddress(payload);

                    if (status === MISSING_WS_CODE_STATUS_CODE) {
                        return { data: [], response: MISSING_WS_RESPONSE_CODE };
                    }

                    if (status === 200) {
                        return { data, response };
                    }

                    return { data: [], response: -1 };
                };

                try {
                    // Api calls
                    const pickupCombinationsAddressesResponse = await reqCombinationsAddresses(true);
                    const dropCombinationsAddressesResponse = await reqCombinationsAddresses(false);

                    const getPassengersWsRes = await getPassengers({
                        token,
                        dbUrl,
                        clientCode,
                        passCode,
                        proxyUrl: proxyUrl || '',
                    });

                    if (String(getPassengersWsRes?.data?.response) === SUCCESS) {
                        // Getting passenger data, passList should always contain only one passenger, at index 0

                        const passenger = getPassengersWsRes.data.passList[0];

                        const handleCombinationAddress = (type: 'pickup' | 'drop') => {
                            const wsResponse =
                                type === 'pickup'
                                    ? pickupCombinationsAddressesResponse
                                    : dropCombinationsAddressesResponse;

                            if (wsResponse.response === MISSING_WS_RESPONSE_CODE) {
                                dispatch(setErrorMessage(ErrorMessagesOld.MissingWs));
                            }

                            const combinationAddressLst = wsResponse.data;

                            const addressesMap = helpers.buildAddressMap([
                                ...passenger?.address,
                                ...combinationAddressLst,
                            ]);

                            const defaultAddress = addressesMap.find((address) => address.isDefault);

                            if (defaultAddress) {
                                // Setting the default address as the selected address
                                dispatch(
                                    setPlacementAddressAction({
                                        type,
                                        addressData: defaultAddress,
                                    })
                                );
                            }

                            return addressesMap;
                        };

                        const dropsAddressesMap = handleCombinationAddress('drop');
                        const pickupsAddressesMap = handleCombinationAddress('pickup');

                        setAllUserAddresses({ pickups: pickupsAddressesMap, drops: dropsAddressesMap });
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        setAllUserAddresses(INITIAL_ALL_ADDRESSES_VALUE);
                        dispatch(
                            setErrorMessage(
                                `${'oopsSomethingWentWrongTryAgain'}${getPassengersWsRes.data.response}`
                            )
                        );
                    }
                } catch (error: any) {
                    setIsLoading(false);
                    setAllUserAddresses(INITIAL_ALL_ADDRESSES_VALUE);
                    console.log(error);

                    dispatch(setErrorMessage(DEFAULT_ERROR_MESSAGE));
                }
            }
        })();
    }, [
        clientCode,
        dbUrl,
        dispatch,
        isShiftSelected,
        passCode,
        proxyUrl,
        selectedShift.dropTime,
        selectedShift.pickupTime,
        token,
    ]);

    return { allAddresses: allUserAddresses, isShiftSelected, isLoading };
};

const useDropdownProps = (passCode: string, selectedTab: 'manual' | 'auto') => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Selectors
    const selectedAddresses = useRootAppSelector(selectedAddressForPlacementSelector);

    const { allAddresses, isShiftSelected, isLoading } = usePassengerDataFetch(passCode, selectedTab);

    const createProps = (type: 'pickup' | 'drop'): AddressDropdownProps => {
        const allAddressesByShiftType = type === 'pickup' ? allAddresses.pickups : allAddresses.drops;
        // const value = ;

        return {
            labalName: `${t('address')} ${t(getAsTemplateStrArr(type))}`,
            value: isShiftSelected && !isLoading ? selectedAddresses[type]?.id || '' : '',
            disabled: !allAddressesByShiftType.length || !isShiftSelected || isLoading,
            menueItem: helpers.buildMenuItems(allAddressesByShiftType),
            onChange: (e: React.ChangeEvent<any>) => {
                if (!e.target.value) {
                    dispatch(setPlacementAddressAction(null));
                    return;
                }

                const addressMatch = allAddressesByShiftType.find((address) => address.id === e.target.value);

                // Should always be true as event value should corelate to one of the addresses ids
                if (addressMatch) {
                    dispatch(
                        setPlacementAddressAction({
                            type,
                            addressData: addressMatch,
                        })
                    );
                }
            },
            formControlProp,
            style: styles.inputsStyle,
        };
    };

    return { createProps };
};

const placementAddressHooks = {
    useDropdownProps,
    usePassengerDataFetch,
};

export default placementAddressHooks;
