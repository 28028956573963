import React from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/styles';
import styled from 'styled-components';
import GoBackArrowIcon from 'src/components/Icons/GoBackArrowIcon';
import { useAppDispatch } from 'src/store/hooks';
import { localStyles } from '../../../PassengersFormHeader';

type Props = { onCancel: () => void };

const StoredCoursesHeader: React.FunctionComponent<Props> = ({ onCancel }) => {
    const { t } = useTranslation();

    return (
        <localStyles.StyledHeader>
            <div style={localStyles.mainHeaderStyle}>
                <styles.GoBackBtn onClick={onCancel}>
                    <GoBackArrowIcon />
                </styles.GoBackBtn>
                <p>{t('courseDataImport')}</p>
            </div>
        </localStyles.StyledHeader>
    );
};

export default StoredCoursesHeader;
