/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    GetPassengersShortDetailsReqConfig,
    ParsedCoursePassenger,
    ParsedCourseStation,
    ReqSetPassengerConfig,
} from 'src/types/lines/api/types';
import {
    CourseLocations,
    ExistingPassenger,
    Station,
    StationAddress,
    StationPayload,
} from 'src/screens/Main/components/AddLineForm/types';
import { IRootReducer } from 'src/store/reducers';
import { ISetLinePayload } from 'src/store/sagas/lineSaga';
import { createMsg } from 'src/store/utils';
import { ErrorActionPayload } from 'src/types/reports/generalReportsTypes';
import {
    createArrWithoutItem,
    createArrWithAddedItem,
    getCurrDateAsString,
    addOrUpdateArr,
} from 'src/utilis/utilis';
import _ from 'lodash';
import { CarType } from 'src/types/lines/api/apiProccessed.types';
import { IClient, IEditLine, ILine, IStation } from 'src/types/line';
import { WsCourseDataItem } from 'src/types/passengersManager/courseChoosingTypes';
import { AddressInputComponentIds, AutoCompleteInputStatus } from 'src/types/lines/addLineForm.types';
import { Form, LinesSliceState, NewClient, UiState } from 'src/types/lines/slice.types';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import { linesHelpers } from 'src/helpers/lines/linesHelpers';
import { LoadingState } from 'src/screens/Main/components/AddLineForm/components/subForms/storedCourses/storedCourses.hooks';
// Define the initial state using that type
// * Slice

const initialFormData = {
    stations: [],
    carId: null,
    startAddress: null,
    endAddress: null,
};
const initialUiFormState: Form = {
    data: initialFormData,
    isOnEdit: false,
    lineOnEdit: null,
    editedLineClientId: null,
    isStationValid: true,
    isOpen: false,
    isSubmitting: false,
    importedCourseCode: null,
    addressesInputConfig: {
        autoCompleteInputIds: [AddressInputComponentIds.StartAddress, AddressInputComponentIds.EndAddress],
    },
    passengersForm: {
        newPassengerForm: {
            isAddingNewPassenger: false,
            isOpen: false,
            oldPassengerData: {},
        },
        isOpen: false,
        lastSubmit: null,
        stationId: '',
        selectedPassengersLst: [],
    },
    triggerDirections: 0,
    isDirectionsTriggeredFromEdit: false,
    isTriggeredFromFromImport: false,
    storedCoursesImportForm: {
        isOpen: false,
        selectedCourseData: { code: '', locations: null, passengers: [] },
        importStationsModal: {
            isOpen: false,
            isLoading: LoadingState.NotLoading,
        },
    },
    isFloatMapExpanded: true,
    isInjectionCompleted: false,
};

const initialUiState: UiState = {
    form: initialUiFormState,
    common: {
        focusedLineId: null,
        lastFormSubmit: null,
        selectClientIdOnTableFilters: null,
        linesSelectedIds: [],
        error: {
            msg: '',
            code: null,
        },
        lineActions: {
            isConfirmationDialogOpen: false,
            dataItem: null,
            isCopyRideModalOpen: false,
        },
    },
};

const initialState: LinesSliceState = {
    ui: initialUiState,
    data: {
        getPassengersShortDetails: {
            status: 'ON_STAND_BY',
            data: [],
        },
        setPassenger: {
            status: 'ON_STAND_BY',
            data: { passCode: '' },
        },
        setLine: {
            status: 'ON_STAND_BY',
            data: {},
        },
        getCarTypes: {
            status: null,
            data: [],
        },
        delLine: {
            status: 'ON_STAND_BY',
            data: {},
        },
        getAccountCourses: {
            status: 'ON_STAND_BY',
            data: [],
        },
        clients: [],
        lastUsedCoursePassengers: [],
    },
};
export const linesSlice = createSlice({
    name: 'linesSlice',
    initialState,
    reducers: {
        // ^ UI reducers
        // * Common
        onError: (state: LinesSliceState, action: PayloadAction<ErrorActionPayload>): void => {
            if (!action.payload) {
                state.ui.common.error = {
                    msg: '',
                    code: null,
                };
                return;
            }
            const { msg, details } = action.payload;

            state.ui.common.error = {
                msg: createMsg({ msg, details }),
                code: getCurrDateAsString(),
            };
        },
        setSelectedClientFilter: (state: LinesSliceState, action: PayloadAction<number>) => {
            const clientId = action.payload;
            state.ui.common.selectClientIdOnTableFilters = clientId;
        },
        openForm: (
            state: LinesSliceState,
            action: PayloadAction<{ editedLineId: string; open: boolean } | null>
        ): void => {
            const { editedLineId, open = true } = action.payload || {};

            state.ui.form.isOpen = open;
            if (action.payload?.editedLineId) {
                state.ui.form.editedLineId = action.payload.editedLineId;
            } else {
                state.ui.form.editedLineId = null;
            }
        },
        toggleAutoComplete: (
            state: LinesSliceState,
            action: PayloadAction<{ status: AutoCompleteInputStatus; componentId: string }>
        ): void => {
            const { status, componentId } = action.payload;
            const copy = [...state.ui.form.addressesInputConfig.autoCompleteInputIds];

            if (status === AutoCompleteInputStatus.On) {
                if (copy.includes(componentId)) return;
                state.ui.form.addressesInputConfig.autoCompleteInputIds = [...copy, componentId];
                return;
            }

            state.ui.form.addressesInputConfig.autoCompleteInputIds = copy.filter((id) => id !== componentId);
        },
        toggleConfirmStationsImportModalOpen: (
            state: LinesSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            const { isOpen } = action.payload;
            state.ui.form.storedCoursesImportForm.importStationsModal.isOpen = isOpen;
        },
        toggleConfirmStationsImportModalLoading: (
            state: LinesSliceState,
            action: PayloadAction<{ loadingState: LoadingState }>
        ): void => {
            const { loadingState } = action.payload;
            state.ui.form.storedCoursesImportForm.importStationsModal.isLoading = loadingState;
        },
        storedCoursesFormDisplayChange: (
            state: LinesSliceState,
            action: PayloadAction<{ isOpen: boolean; courseCode?: string }>
        ): void => {
            state.ui.form.storedCoursesImportForm.isOpen = action.payload.isOpen;
            if ('courseCode' in action.payload) {
                state.ui.form.importedCourseCode = action.payload.courseCode || null;
            }
        },
        openPassengersForm: (state: LinesSliceState, action: PayloadAction<{ stationId?: string }>): void => {
            try {
                const { stationId } = action.payload;

                state.ui.form.passengersForm.isOpen = true;

                const allPassengers = state.data.getPassengersShortDetails.data;

                // -- handling passengers pick for existing station
                if (stationId) {
                    state.ui.form.passengersForm.stationId = stationId;
                    // using the selected stations curr passengers as initial
                    // selected passengers on the form
                    const { passengers: passengerIds } =
                        state.ui.form.data.stations.find((st) => st.stationId === stationId) || {};

                    if (passengerIds) {
                        const passengers = allPassengers.filter((p) => passengerIds.includes(p.passCode));
                        if (Array.isArray(passengers)) {
                            state.ui.form.passengersForm.selectedPassengersLst = passengers;
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        onSubmitExistingPassengersForm: (state: LinesSliceState): void => {
            const { stationId: stationIdOnPassengersForm, selectedPassengersLst } =
                state.ui.form.passengersForm;

            const updatedStations = state.ui.form.data.stations.map((s) => {
                if (s.stationId === stationIdOnPassengersForm) {
                    s.passengers = selectedPassengersLst.map((pass) => pass.passCode);
                }
                return s;
            });
            state.ui.form.data.stations = updatedStations;
        },
        onSubmitPassengersForCreatingStations: (state: LinesSliceState): void => {
            const { selectedPassengersLst } = state.ui.form.passengersForm;

            const currStations = state.ui.form.data.stations;
            const newStations: Station[] = selectedPassengersLst.map((passenger) =>
                linesHelpers.createStationFromPassenger(passenger)
            );

            state.ui.form.data.stations = [...currStations, ...newStations];
        },
        importCourseStations: (
            state: LinesSliceState,
            action: PayloadAction<{ rawStationsLst: ParsedCourseStation[] }>
        ): void => {
            const newStations: Station[] = action.payload.rawStationsLst.map((station) => ({
                stationId: station.stationCode,
                passengers: station.passengers,
                city: station.city,
                street: station.street,
                houseNum: station.house,
                isFromImportedCourse: true,
                lat: station.lat,
                lng: station.lng,
                distanceFromPrevStation: station.distanceFromPrevStation,
                timing: station.timing,
            }));

            state.ui.form.data.stations = newStations;
        },
        injectStationsOnEdit: (
            state: LinesSliceState,
            action: PayloadAction<{ rawStationsLst: IStation[] }>
        ): void => {
            const newStations: Station[] = [];

            let passengersStringIds: string[] = [];

            action.payload.rawStationsLst.forEach((station) => {
                station.passengers.forEach((passenger) => {
                    passengersStringIds.push(passenger.passCode);
                });

                const newStation = {
                    stationId: station.code,
                    city: station.city,
                    passengers: passengersStringIds,
                    street: station.street,
                    houseNum: station.house,
                    lat: station.lat,
                    lng: station.lng,
                    timing: station.timing,
                    distanceFromPrevStation: station.distanceFromPrevStation,
                };

                newStations.push(newStation);

                passengersStringIds = [];
            });

            state.ui.form.data.stations = newStations;
        },
        onPassengerCardClick: (
            state: LinesSliceState,
            action: PayloadAction<{ passenger: ExistingPassenger }>
        ): void => {
            const { passenger } = action.payload;
            const { selectedPassengersLst } = state.ui.form.passengersForm;

            const addPassenger = () => {
                return createArrWithAddedItem(selectedPassengersLst, passenger);
            };
            const removePassenger = () => {
                return createArrWithoutItem(selectedPassengersLst, passenger.passCode, 'passCode');
            };

            const matchOnSelectedList = state.ui.form.passengersForm.selectedPassengersLst.find(
                (psngr) => psngr.passCode === passenger.passCode
            );

            let updatedPassengersList;
            if (matchOnSelectedList) {
                updatedPassengersList = removePassenger();
            } else {
                updatedPassengersList = addPassenger();
            }
            state.ui.form.passengersForm.selectedPassengersLst = updatedPassengersList;
        },
        removePassengerFromStation: (
            state: LinesSliceState,
            action: PayloadAction<{
                passengerId: string;
                stationId: string;
            }>
        ): void => {
            const { passengerId, stationId } = action.payload;
            const { stations } = state.ui.form.data;
            const updatedStations = stations.map((s: Station) => {
                if (s.passengers && s.stationId === stationId) {
                    s.passengers = s.passengers.filter((p: string) => p !== passengerId);
                }
                return s;
            });
            state.ui.form.data.stations = updatedStations;
        },
        closePassengersForm: (state: LinesSliceState): void => {
            state.ui.form.passengersForm.isOpen = false;
            state.ui.form.passengersForm.stationId = '';
            state.ui.form.passengersForm.selectedPassengersLst = [];
        },
        addPassengerFormDisplayChange: (
            state: LinesSliceState,
            action: PayloadAction<{
                isOpen: boolean;
                isEditingPassenger?: boolean;
            }>
        ): void => {
            const { isOpen } = action.payload;
            const { newPassengerForm } = state.ui.form.passengersForm;
            newPassengerForm.isAddingNewPassenger = isOpen;
            // reset old passenger data
            newPassengerForm.oldPassengerData = {};
            // open form
            newPassengerForm.isOpen = isOpen;
        },
        editPassenger: (
            state: LinesSliceState,
            action: PayloadAction<{ passenger: ExistingPassenger }>
        ): void => {
            const { passenger } = action.payload;
            const { newPassengerForm } = state.ui.form.passengersForm;
            newPassengerForm.isAddingNewPassenger = false;
            newPassengerForm.isOpen = true;
            newPassengerForm.oldPassengerData = passenger;
        },

        closeForm: (state: LinesSliceState): void => {
            state.ui.form = initialUiFormState;
        },
        setStation: (state: LinesSliceState, action: PayloadAction<{ station: StationPayload }>): void => {
            const newStation = _.cloneDeep(action.payload.station);
            const { stations } = state.ui.form.data;

            let passengers: string[] = [];

            const matchingStation = state.ui.form.data.stations.find(
                (station: Station) => station.stationId === newStation.stationId
            );

            if (matchingStation) {
                passengers = matchingStation.passengers;
                if (newStation.placeName && !newStation.street) {
                    newStation.street = newStation.placeName;
                }
            }

            // replace station with new station
            const updatedStations = stations.map((s) => {
                if (s.stationId === newStation.stationId) {
                    return { ...newStation, passengers };
                }
                return s;
            });

            if (!matchingStation) {
                updatedStations.push({ ...newStation, passengers: [] });
            }

            state.ui.form.data.stations = updatedStations;
        },
        removeStationById: (state: LinesSliceState, action: PayloadAction<{ stationId: string }>): void => {
            const { stations } = state.ui.form.data;
            state.ui.form.data.stations = stations.filter(
                (station: Station) => station.stationId !== action.payload.stationId
            );

            if (!stations.length) {
                state.ui.form.isStationValid = true;
            }
        },
        onSubmit: (state: LinesSliceState, action: PayloadAction<ISetLinePayload>): void => {
            state.data.setLine.status = 'LOADING';
        },
        onSubmitStatusUpdate: (state: LinesSliceState, action: PayloadAction<{ status: ApiCallStatus }>) => {
            const { status } = action.payload;
            if (status === 'SUCCESS') {
                state.ui.form = initialUiFormState;
                state.ui.common.lastFormSubmit = new Date();
                state.data.setLine.status = status;
            } else {
                state.ui.form.isSubmitting = false;
                state.data.setLine.status = status;
                state.ui.common.lastFormSubmit = new Date();
            }
        },
        fetchPassengersShortDetails: (
            state: LinesSliceState,
            action: PayloadAction<GetPassengersShortDetailsReqConfig>
        ) => {
            state.data.getPassengersShortDetails.status = 'LOADING';
        },
        fetchPassengersShortDetailsStatusUpdate: (
            state: LinesSliceState,
            action: PayloadAction<{
                status: ApiCallStatus;
                data: ExistingPassenger[];
            }>
        ) => {
            state.data.getPassengersShortDetails.status = action.payload.status;
            state.data.getPassengersShortDetails.data = action.payload.data;
        },
        fetchCoursesUpdate: (
            state: LinesSliceState,
            action: PayloadAction<{
                status: ApiCallStatus;
                data?: WsCourseDataItem[];
            }>
        ) => {
            state.data.getAccountCourses.status = action.payload.status;
            if (action.payload.data) {
                state.data.getAccountCourses.data = action.payload.data;
            }
        },
        reqSetNewPassenger: (
            state: LinesSliceState,
            action: PayloadAction<{
                requestConfig: ReqSetPassengerConfig;
            }>
        ) => {
            state.data.setPassenger.status = 'LOADING';
            state.data.setPassenger.data.passCode = '';
        },
        reqSetNewPassengerStatusUpdate: (
            state: LinesSliceState,
            action: PayloadAction<{
                status: ApiCallStatus;
                passengerData?: ExistingPassenger;
            }>
        ) => {
            const { status, passengerData } = action.payload;

            state.data.setPassenger.status = status;

            if (status === 'SUCCESS' && passengerData && passengerData.passCode) {
                // state.data.newPassenger.data = data;
                const { newPassengerForm } = state.ui.form.passengersForm;

                newPassengerForm.isAddingNewPassenger = false;
                newPassengerForm.isOpen = false;
                state.ui.form.passengersForm.lastSubmit = new Date();

                const currPassengersList = state.data.getPassengersShortDetails.data;

                state.data.getPassengersShortDetails.data = addOrUpdateArr(
                    'passCode',
                    passengerData,
                    currPassengersList
                );
            } else {
                // failed etc
                state.data.setPassenger.data = { passCode: '' };
            }
        },
        setIsStationValid: (
            state: LinesSliceState,
            action: PayloadAction<{
                isValid: boolean;
            }>
        ) => {
            state.ui.form.isStationValid = action.payload.isValid;
        },
        setClientsOfProvider: (state: LinesSliceState, action: PayloadAction<{ clients: IClient[] }>) => {
            state.data.clients = action.payload.clients.map(
                ({ accountCode, maxMinuteToBlockNewLine, referEmailList, ...rest }): NewClient => ({
                    ...rest,
                    clientCode: String(accountCode),
                    maxMinuteToBlockNewLine: Number(maxMinuteToBlockNewLine || 0),
                    referEmailList: referEmailList || '',
                })
            );
        },
        setCarTypes: (state: LinesSliceState, action: PayloadAction<{ data: CarType[] } | null>) => {
            if (action.payload?.data) {
                state.data.getCarTypes.data = action.payload.data;
                return;
            }
            state.data.getCarTypes.data = [];
        },
        setSelectedCarId: (state: LinesSliceState, action: PayloadAction<{ id: string } | null>) => {
            if (action.payload?.id) {
                state.ui.form.data.carId = action.payload.id;
                return;
            }
            state.ui.form.data.carId = null;
        },
        toggleConfirmationDialog: (
            state: LinesSliceState,
            action: PayloadAction<{ isOpen: boolean; dataItem?: ILine }>
        ) => {
            state.ui.common.lineActions.isConfirmationDialogOpen = action.payload.isOpen;
            state.ui.common.lineActions.dataItem = action.payload.dataItem || null;
        },
        toggleCopyRideModal: (
            state: LinesSliceState,
            action: PayloadAction<{ isOpen: boolean; dataItem?: ILine }>
        ) => {
            state.ui.common.lineActions.isCopyRideModalOpen = action.payload.isOpen;
            state.ui.common.lineActions.dataItem = action.payload.dataItem || null;
        },
        updateDelLineReqStatus: (
            state: LinesSliceState,
            action: PayloadAction<{ status: ApiCallStatus; data?: any }>
        ) => {
            state.data.delLine.status = action.payload.status;
            if (action.payload.data) {
                state.data.delLine.data = action.payload.data;
            }
        },
        setSelectedCourseCode: (state: LinesSliceState, action: PayloadAction<{ courseCode: string }>) => {
            state.ui.form.storedCoursesImportForm.selectedCourseData.code = action.payload.courseCode;
        },
        setSelectedCourseLocations: (
            state: LinesSliceState,
            action: PayloadAction<{ locations: CourseLocations; passengers: ParsedCoursePassenger[] }>
        ) => {
            state.ui.form.storedCoursesImportForm.selectedCourseData.locations = action.payload.locations;
            state.ui.form.storedCoursesImportForm.selectedCourseData.passengers = action.payload.passengers;
            if (action.payload.passengers.length) {
                state.data.lastUsedCoursePassengers = action.payload.passengers;
            }
        },
        resetCoursesFormState: (state: LinesSliceState) => {
            state.ui.form.storedCoursesImportForm.selectedCourseData = {
                code: '',
                locations: null,
                passengers: [],
            };
        },
        setEditMode: (
            state: LinesSliceState,
            action: PayloadAction<{ isOnEdit: boolean; lineOnEdit: IEditLine | null }>
        ) => {
            state.ui.form.isOnEdit = action.payload.isOnEdit;
            state.ui.form.lineOnEdit = action.payload.lineOnEdit;
        },
        setEditedLineClientId: (
            state: LinesSliceState,
            action: PayloadAction<{ editedLineClientId: number | null }>
        ) => {
            state.ui.form.editedLineClientId = action.payload.editedLineClientId;
        },
        setIsFloatMapExpanded: (state: LinesSliceState, action: PayloadAction<{ isExpanded: boolean }>) => {
            state.ui.form.isFloatMapExpanded = action.payload.isExpanded;
        },
        setFormStartAddress: (
            state: LinesSliceState,
            action: PayloadAction<{ startAddress: StationAddress | IStation | ParsedCourseStation | null }>
        ) => {
            state.ui.form.data.startAddress = action.payload.startAddress;
        },
        setFormEndAddress: (
            state: LinesSliceState,
            action: PayloadAction<{ endAddress: StationAddress | IStation | ParsedCourseStation | null }>
        ) => {
            state.ui.form.data.endAddress = action.payload.endAddress;
        },
        setFormStations: (state: LinesSliceState, action: PayloadAction<{ stations: Station[] }>) => {
            state.ui.form.data.stations = action.payload.stations;
        },
        triggerDirections: (state: LinesSliceState) => {
            state.ui.form.triggerDirections += 1;
        },
        setIsDirectionsTriggeredFromEdit: (
            state: LinesSliceState,
            action: PayloadAction<{ isFromEdit: boolean }>
        ) => {
            state.ui.form.isDirectionsTriggeredFromEdit = action.payload.isFromEdit;
        },
        setIsTriggeredFromImport: (
            state: LinesSliceState,
            action: PayloadAction<{ isFromImport: boolean }>
        ) => {
            state.ui.form.isTriggeredFromFromImport = action.payload.isFromImport;
        },
        selectLine: (state: LinesSliceState, action: PayloadAction<{ lineCode: number }>): void => {
            let foundIndex = -1;

            state.ui.common.linesSelectedIds.forEach((id, index) => {
                if (id === action.payload.lineCode) {
                    foundIndex = index;
                }
            });

            if (foundIndex !== -1) {
                state.ui.common.linesSelectedIds.splice(foundIndex, 1);
            } else {
                state.ui.common.linesSelectedIds.push(action.payload.lineCode);
            }
        },
        selectAllLines: (
            state: LinesSliceState,
            action: PayloadAction<{ isSelected: boolean; lines?: ILine[] }>
        ): void => {
            state.ui.common.linesSelectedIds = [];

            if (action.payload.isSelected && action.payload.lines) {
                action.payload.lines.forEach((line) => {
                    state.ui.common.linesSelectedIds.push(line.lineCode);
                });
            }
        },
        focusLine: (state: LinesSliceState, action: PayloadAction<{ lineCode: number }>): void => {
            state.ui.common.focusedLineId = action.payload.lineCode;
        },
        setIsInjectionCompleted: (
            state: LinesSliceState,
            action: PayloadAction<{ isInjectionCompleted: boolean }>
        ): void => {
            state.ui.form.isInjectionCompleted = action.payload.isInjectionCompleted;
        },
    },
});

// * Exports
// ^ Actions
export const {
    onError: onErrorAction,
    openForm: openFormAction,
    closeForm: closeFormAction,
    onSubmit: onSubmitAction,
    onSubmitStatusUpdate: onSubmitStatusUpdateAction,
    setStation: setStationAction,
    fetchPassengersShortDetails: fetchPassengersShortDetailsAction,
    fetchPassengersShortDetailsStatusUpdate: fetchPassengersShortDetailsStatusUpdateAction,
    openPassengersForm: openPassengersFormAction,
    closePassengersForm: closePassengersFormAction,
    addPassengerFormDisplayChange: addPassengerFormDisplayChangeAction,
    onPassengerCardClick: onPassengerCardClickAction,
    onSubmitExistingPassengersForm: onSubmitExistingPassengersFormAction,
    reqSetNewPassenger: reqSetNewPassengerAction,
    reqSetNewPassengerStatusUpdate: reqSetNewPassengerStatusUpdateAction,
    removePassengerFromStation: removePassengerFromStationAction,
    editPassenger: editPassengerAction,
    onSubmitPassengersForCreatingStations: onSubmitPassengersForCreatingStationsAction,
    removeStationById: removeStationByIdAction,
    setIsStationValid: setIsStationValidAction,
    setSelectedClientFilter: setSelectedClientFilterAction,
    setClientsOfProvider: setClientsOfProviderAction,
    setCarTypes: setCarTypesAction,
    setSelectedCarId: setSelectedCarIdAction,
    toggleConfirmationDialog: toggleConfirmationDialogAction,
    toggleCopyRideModal: toggleCopyRideModalAction,
    updateDelLineReqStatus: updateDelLineReqStatusAction,
    storedCoursesFormDisplayChange: storedCoursesFormDisplayChangeAction,
    fetchCoursesUpdate: fetchCoursesUpdateAction,
    setSelectedCourseCode: setSelectedCourseCodeAction,
    importCourseStations: importCourseStationsAction,
    injectStationsOnEdit: injectStationsOnEditAction,
    toggleAutoComplete: toggleAutoCompleteAction,
    toggleConfirmStationsImportModalOpen: toggleConfirmStationsImportModalOpenAction,
    setSelectedCourseLocations: setSelectedCourseLocationsAction,
    resetCoursesFormState: resetCoursesFormStateAction,
    toggleConfirmStationsImportModalLoading: toggleConfirmStationsImportModalLoadingAction,
    setEditMode: setEditModeAction,
    setEditedLineClientId: setEditedLineClientIdAction,
    setIsFloatMapExpanded: setIsFloatMapExpandedAction,
    setFormStartAddress: setFormStartAddressAction,
    setFormEndAddress: setFormEndAddressAction,
    setFormStations: setFormStationsAction,
    triggerDirections: triggerDirectionsAction,
    setIsDirectionsTriggeredFromEdit: setIsDirectionsTriggeredFromEditAction,
    selectLine: selectLineAction,
    selectAllLines: selectAllLinesAction,
    focusLine: focusLineAction,
    setIsTriggeredFromImport: setIsTriggeredFromImportAction,
    setIsInjectionCompleted: setIsInjectionCompletedAction,
} = linesSlice.actions;

// ^ Selectors
export const uiSelector = (state: any): UiState => state.linesSlice.ui;
export const dataSelector = (state: IRootReducer) => state.linesSlice.data;

export const selectedClientOnFiltersSelector = (state: IRootReducer) =>
    state.linesSlice.ui.common.selectClientIdOnTableFilters;

export const isFormOpenSelector = (state: IRootReducer): boolean => state.linesSlice.ui.form.isOpen;
export const isStationsInputValid = (state: IRootReducer): boolean => state.linesSlice.ui.form.isStationValid;

export const linesDataSelector = (state: IRootReducer) => state.linesSlice.data;
export const formSelector = (state: IRootReducer) => state.linesSlice.ui.form;
export const storedCoursesFormSelector = (state: IRootReducer) => formSelector(state).storedCoursesImportForm;
export const lastFormSubmitSelector = (state: IRootReducer): Date | null =>
    state.linesSlice.ui.common.lastFormSubmit;

export const passengersFormSelector = (state: IRootReducer) => state.linesSlice.ui.form.passengersForm;

export const lastNewPassengerFormSubmitSelector = (state: IRootReducer): Date | null =>
    state.linesSlice.ui.form.passengersForm.lastSubmit;

export const selectedPassengersLstSelector = (state: IRootReducer): ExistingPassenger[] =>
    state.linesSlice.ui.form.passengersForm.selectedPassengersLst;

export const passengersDataSelector = (state: IRootReducer): ExistingPassenger[] =>
    state.linesSlice.data.getPassengersShortDetails.data;
export const stationsSelector = (state: IRootReducer): Station[] => state.linesSlice.ui.form.data.stations;

export const apiRequestStatusSelector = (state: IRootReducer): ApiCallStatus =>
    state.linesSlice.data.setPassenger.status;
export const accountCoursesResponseSelector = (state: IRootReducer) =>
    linesDataSelector(state).getAccountCourses;

export const setLineReqStatusSelector = (state: IRootReducer): ApiCallStatus =>
    state.linesSlice.data.setLine.status;

export const newPassengerFormSelector = (state: IRootReducer) =>
    state.linesSlice.ui.form.passengersForm.newPassengerForm;

export const oldPassengerDataSelector = (state: IRootReducer) =>
    state.linesSlice.ui.form.passengersForm.newPassengerForm.oldPassengerData;

export const passengersListForStationIdSelector = (state: IRootReducer, stationId: string): string[] => {
    const station = state.linesSlice.ui.form.data.stations.find((st: Station) => st.stationId === stationId);
    if (station) return station.passengers;
    return [];
};
export const carTypesLstSelector = (state: IRootReducer): CarType[] => {
    return state.linesSlice.data.getCarTypes.data;
};
export const clientsSelector = (state: IRootReducer) => state.linesSlice.data.clients;
export const lineActionsSelector = (state: IRootReducer) => state.linesSlice.ui.common.lineActions;
export const delLineApiRequestSelector = (state: IRootReducer) => state.linesSlice.data.delLine;
export const storedCoursesImportFormSelector = (state: IRootReducer) =>
    state.linesSlice.ui.form.storedCoursesImportForm;

// ^ Reducer export
const linesSliceReducer = linesSlice.reducer;
export default linesSliceReducer;
