import { useSelector } from 'react-redux';
import {
    getOptimizationSimulationResult,
    getServerOptimizationWaypoints,
    initOptimizationProcess,
    setWayPointsForOptimization,
} from '../api/optimizationApi';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    selectAllWaypointAction,
    setGetSimulationResultAction,
    setGetWaypointsFromServerStatusAction,
    setIsGeneralLoadingAction,
    setIsOptimizationSimulationScreenOpenAction,
    setSimulationHashIdAction,
    setSimulationResultAction,
    setTimeDeltaAction,
    setWaypointStockAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { WsResponse, WsResponseWithDelta } from 'src/api/types';
import { IOptimizationWaypoint, ISimulationResult } from '../api/types';
import { buildRouteName, buildWaypointName, updateWaypoints } from '../helpers';
import useCommons from 'src/hooks/common/useCommons';
import moment from 'moment';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { uuid } from 'src/utilis/utilis';
import { useGetOptimizationSimulation } from './useGetOptimizationSimulationResult';
import { buildStationName } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/Controlled/ControlledManualAndGoogleAddressInput';
import { setInterval } from 'timers';
import { useEffect } from 'react';

export const useSetOptimizationSimulation = () => {
    const { dispatch, t } = useCommons();
    const dispatchAlert = useDispatchAlert();
    const getOptimizationSimulation = useGetOptimizationSimulation();

    const { travelMethod, travelType, walkingDistanceFromStation, vehicleCapacity } = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm
    );

    const handleSimulationResponse = (
        res: ISimulationResult,
        hashId: string,
        isRouteMethod: boolean,
        isMerge: boolean
    ) => {
        console.log('res1', res);

        if (res && res.isSuccess) {
            dispatch(setSimulationHashIdAction({ hashId }));

            getOptimizationSimulation.getOptimizationSimulation(hashId, isRouteMethod, isMerge);

            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
        } else {
            dispatch(setGetSimulationResultAction({ status: 'FAILURE' }));
            dispatchAlert('error', t('unknown'));
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
        }
    };

    const getOptimizationToken = async () => {
        try {
            const tokenRes = await initOptimizationProcess();
            const hashID = tokenRes.data;

            return hashID;
        } catch (err) {
            dispatchAlert('error', t('unknown'));
        }
    };

    const setOptimizationSimulation = async (
        waypointsJson: {
            stationName: string;
            city: string;
            street: string;
            houseNum: string;
            lat: number;
            lng: number;
            place_id: string;
            waypointId: string;
            isTarget: boolean;
            isStation: boolean;
        }[],
        maxValueLimitInRoute: number,
        isMerge: boolean
    ) => {
        try {
            // Get Optimization Token

            const hashID = await getOptimizationToken();

            if (hashID) {
                const isRoutesMethod = travelMethod === 2;

                const res = await setWayPointsForOptimization({
                    maxValueLimitInRoute: isRoutesMethod ? maxValueLimitInRoute : 0,
                    maxWalkingDistanceFromWayPoint: isRoutesMethod ? 0 : walkingDistanceFromStation,
                    passengerLimitInVehicle: vehicleCapacity,
                    hashID,
                    toTarget: travelType === 2 ? true : false,
                    waypointsJson: [...waypointsJson],
                });

                handleSimulationResponse(res.data, hashID, isRoutesMethod, isMerge);
            } else {
                dispatchAlert('error', t('unknown'));
                console.log('token error');
            }
        } catch (error) {
            dispatchAlert('error', t('unknown'));
            console.log('error', error);
            dispatch(setIsGeneralLoadingAction({ isLoading: false }));
        }
    };

    return {
        setOptimizationSimulation,
    };
};
