import React from 'react';
import Header from 'src/components/HeaderAuth/HeaderAuth';
import User from 'src/screens/Auth/Login/User/User';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Signup from 'src/screens/Auth/Signup/Signup';
import SingupOptions from 'src/screens/Auth/SingupOptions/SingupOptions';
import InitPinCode from 'src/screens/Auth/SingupOptions/InitPinCode/InitPinCode';
import InitPassword from 'src/screens/Auth/SingupOptions/initPassword/InitPassword';
import PinCode from 'src/screens/Auth/Login/PinCode/PinCode';
import { routesEndpoints } from '../routes_endpoints';

interface Props {}

const Auth: React.FC = (props: Props) => {
    const { url } = useRouteMatch();

    return (
        <>
            <Header />
            <Switch>
                <Route path={`${url}${routesEndpoints.AUTH.LOGIN_WITH_PIN_CODE}`} component={PinCode} />
                <Route path={`${url}${routesEndpoints.AUTH.LOGIN_WITH_USER}`} component={User} />
                <Route
                    exact
                    path={`${url}${routesEndpoints.AUTH.SINGUP_OPTIONS}`}
                    component={SingupOptions}
                />
                <Route path={`${url}${routesEndpoints.AUTH.SIGNUP_WITH_PIN_CODE}`} component={InitPinCode} />
                <Route path={`${url}${routesEndpoints.AUTH.SIGNUP_WITH_USER}`} component={InitPassword} />
                <Route path={`${url}${routesEndpoints.AUTH.SINGUP}`} component={Signup} />
            </Switch>
        </>
    );
};

export default Auth;
