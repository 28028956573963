import styled, { css, CSSObject, CSSProperties } from 'styled-components';

export const StylingCompatibilityWrapper = styled.div`
    padding: 30px 0 0 0;
    height: 100%;
    width: 100%;
    position: relative;
`;
export const StyledFormBodyContainer = styled.div`
    padding-top: 10px;
    height: 80.4vh;
    overflow: auto;
    padding-left: 24px;
    padding-bottom: 10px;
`;
export const StyledSectionContainer = styled.div<{
    withTopBorder?: boolean;
    style?: CSSObject;
}>`
    display: flex;
    // gap: 20px;
    flex-direction: column;
    width: 100%;
    ${({ withTopBorder }) => css`
        ${withTopBorder
            ? css`
                  border-top: 1px solid rgba(44, 39, 56, 0.04);
                  padding-top: 10px;
              `
            : css``}
    `}
    ${({ style }) => style && css(style)}
`;

export const StyledInputContainer = styled.div<{
    direction?: 'row' | 'column';
    gap?: string;
    withBottomBorder?: boolean;
    style?: CSSObject;
}>`
    ${({ direction, gap, withBottomBorder, style }) => css`
        display: flex;
        gap: ${gap || '24px'};
        width: 100%;
        flex-direction: ${direction || 'row'};
        ${withBottomBorder
            ? css`
                  border-bottom: 1px solid #e8ebef;
                  padding-bottom: 24px;
              `
            : 'none'}
        ${style ? css(style) : ''}
    `}
`;
export const StyledSectionTitle = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #494949;
`;
