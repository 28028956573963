import React, { FC, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontFamilies, msColors } from 'src/style/themes/defaultTheme';
import MyRefreshIcon from 'src/components/Icons/MyRefreshIcon';
import { useAppDispatch } from 'src/store/hooks';
import { onRefreshBtnClickAction } from 'src/store/slices/reports/reportsSlice';
import FormTypeHeader from 'src/screens/PassengerManger/components/PassengerForm/Components/Headers/FormTypeHeader';
import SeparationLine from 'src/screens/PassengerManger/components/PassengerForm/Components/SeperationLine';
import * as styles from 'src/screens/PassengerManger/components/PassengerForm/Components/coursePicking/styles';
import CancelAndApproveBtns from 'src/screens/RideSettings/components/widgets/CancelAndApproveBtns';
import * as CommonStyles from '../commonStyles';
import { formStyles, StyledFormCard } from './styles';
// import useOrderInfo from './useOrderInfo';

// const StyledWidgetsFeatureHeader = styled.p`
//     font-family: ${fontFamilies.Rubik};
//     width: 100%;
//     font-weight: normal;
//     font-size: 33px;
//     color: ${msColors.darkTextcolor};
//     text-align: right;
// `;
export interface FormBtnsConfig {
    onSubmit: (e?: React.MouseEvent<any>) => void;
    onCancel: Function;
    formChanged?: boolean;
    disabled?: boolean;
    submitText?: string;
    isSubmitting?: boolean;
    withLoader?: boolean;
}
interface Props {
    HeaderComponent: (() => JSX.Element) | React.FunctionComponent;
    btnsConfig: FormBtnsConfig;
    formBodyRef?: React.RefObject<HTMLDivElement> | null;
    onScroll?: Function;
    pd?: string;
}
const WidgetFormWrapper: FC<Props> = React.forwardRef(
    ({ children, HeaderComponent, btnsConfig, formBodyRef, onScroll = () => {}, pd }, ref) => {
        return (
            <CommonStyles.Root ref={ref as any} className="widget-root-container">
                <StyledFormCard className="widget-body-container">
                    <formStyles.FormHeader className="FormHeader">
                        <HeaderComponent />
                    </formStyles.FormHeader>
                    <div style={{ margin: '0 24px' }}>
                        <SeparationLine />
                    </div>
                    <formStyles.FromBodyContainer
                        id="courseBuildingFormBodyComponent"
                        ref={formBodyRef}
                        className="FormBodyContainer"
                        onScroll={() => onScroll()}
                        pd={pd}
                    >
                        {children}
                    </formStyles.FromBodyContainer>
                    <formStyles.FormBtnsContainer className="FormBtnsContainer">
                        <formStyles.BtnsBox className="BtnsBox">
                            <CancelAndApproveBtns {...btnsConfig} />
                        </formStyles.BtnsBox>
                    </formStyles.FormBtnsContainer>
                </StyledFormCard>
            </CommonStyles.Root>
        );
    }
);

export default WidgetFormWrapper;
