/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { useRootAppSelector } from 'src/store/hooks';
import HeaderWithDropdown from 'src/components/HeaderWithDropdown/HeaderWithDropdown';
import Alert from 'src/components/commons/Alert/Alert';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { containers } from 'src/Layouts/styles';
import { useAlertV2 } from 'src/hooks/useAlert';

import FullContainer from 'src/components/Wrappers/FullContainer';
import WidgetWrapperV2 from 'src/components/Wrappers/Widgets/WidgetCardWrapperV2/WidgetCardWrapperV2';
import useScreenRefresh from './hooks/useScreenRefresh';
import {
    coursesBuildingSelectors,
    resetStateAction,
    setClientCodeAction,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import WidgetsContainerV2 from 'src/Layouts/WidgetsContainer/WidgetsContainerV2';
import useWidgetsDrawer from './hooks/useWidgetsDrawer';
import Spinner from 'src/components/Spinner/Spinner';
import useAllCoursesFetch from './hooks/useAllCoursesFetch';
import CoursesTable from './components/CoursesTable';
import Filters from './components/Filters';
import useCommons from 'src/hooks/common/useCommons';
import useFilteringFetching from './hooks/useFiltersFetching';
import useFilteredCourses from './hooks/useFilteredCourses';
import { Slide } from '@mui/material';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import CourseForm from './components/CourseForm/CourseForm';
import WidgetsLayoutContainer from './components/WidgetsLayoutContainer';
import RefWrapper from 'src/components/Wrappers/RefWrapper/RefWrapper';
import CourseBuildingMap from './components/CourseForm/CourseBuildingMap/CourseBuildingMap';
import useUnmountCB from 'src/hooks/useUnmountCb';
import useDelayedWidgetsDisplay from './hooks/useDelayedWidgetsDisplay';

const { apiDataSelector } = coursesBuildingSelectors;

interface Props {}

const CoursesBuildingScreen: React.FC<Props> = ({}) => {
    const { t, dispatch } = useCommons();

    const editCourseForm = useRootAppSelector((state) => uiSelector(state).courseBuildingForm);
    const { getAccountCourses } = useRootAppSelector((state) => apiDataSelector(state));

    const alertConfig = useAlertV2();

    const mountWidgetsSlide = useDelayedWidgetsDisplay();

    // Resetting slice state after component unmounts
    useUnmountCB(() => dispatch(resetStateAction()));

    useFilteringFetching();

    useAllCoursesFetch();

    const { filtered } = useFilteredCourses();

    const widgetsDrawer = useWidgetsDrawer();

    const refresh = useScreenRefresh();

    const headerWithDropdownProps = {
        setClientIdAction: setClientCodeAction,
        onRefreshClick: refresh.onRefreshClick,
        routeScreenId: routesEndpoints.COURSES_BUILDING.SCREEN_ID,
        delay: refresh.delay,
        style: { paddingTop: '32px', paddingBottom: '20px' },
        isPrivateRoute: true,
    };

    return (
        <containers.ScreenContainer className="screen-container">
            <containers.MainSectionContainer className="main-section-container">
                <CourseBuildingMap />
                <DisplayWrapper show style={{ height: '100%' }}>
                    <HeaderWithDropdown {...headerWithDropdownProps} />
                    <FullContainer style={{ display: 'flex' }} className="main-section-and-fab-container">
                        <FullContainer
                            style={{
                                padding: '5px 1.6875em 1.3em 1.6875em',
                            }}
                            className="main-section"
                        >
                            <Filters filteredCoursesLst={filtered} />

                            {getAccountCourses.status === 'SUCCESS' ? (
                                <>
                                    <CoursesTable coursesLst={filtered} />
                                </>
                            ) : null}
                            {getAccountCourses.status === 'FAILED' ? (
                                <div>{t('errorFetchingData')}</div>
                            ) : null}
                            {getAccountCourses.status === 'LOADING' ? <Spinner /> : null}
                        </FullContainer>
                    </FullContainer>
                </DisplayWrapper>
            </containers.MainSectionContainer>
            <WidgetsContainerV2 isOpen={widgetsDrawer.isDrawerOpen}>
                {/* Form */}
                <Slide direction="up" in={editCourseForm.isOpen} unmountOnExit>
                    {editCourseForm.isOpen ? <CourseForm /> : <div />}
                </Slide>
                {/* Widgets */}
                <DisplayWrapper show={mountWidgetsSlide}>
                    <RefWrapper>
                        <WidgetWrapperV2 containerStyle={{ padding: '20px 0' }}>
                            <WidgetsLayoutContainer />
                        </WidgetWrapperV2>
                    </RefWrapper>
                </DisplayWrapper>
            </WidgetsContainerV2>
        </containers.ScreenContainer>
    );
};

export default CoursesBuildingScreen;

// const onWidgetsReloadClick = () => {
//     dispatch(triggerApiCallAction(['getCourseStations', 'getCourseTimes']));
// };
