import React, { useCallback } from 'react';
import { AddressAutoCompleteProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/types.ManualAddressControlled';
import { AddressInfo, AddressKeys } from 'src/components/AddressInputs/types';
import { AddressInput } from 'src/screens/Main/components/AddLineForm/types';
import { AutocompleteAddress } from '../../types';
import { cs } from 'src/style/styledComponents.style';
import AddressAutoComplete from '../../AddressAutoComplete';
import WithManualInputBtn from 'src/components/AddressInputs/ManualAndGoogleAddressInput/WithManualInputBtn/WithManualInputBtn';
import { AutoCompleteInputStatus as Status } from 'src/types/lines/addLineForm.types';
import { getInitialAddressInfo } from 'src/components/AddressInputs/constants';

import { CoreTextFieldV2 } from 'src/components/Inputs/TextInput';
import InputWithErrorsWrapper from 'src/components/Wrappers/InputWithErrorsWrapper.tsx/InputWithErrorsWrapper';
import { IObject, ReactChangeEvent } from 'src/types/global';
import { colors } from 'src/style/themes/defaultTheme';
import { Button } from '@mui/material';
import { defaultStyle } from 'src/components/AddressInputs/styles';

export type ManualAddressControlledProps = {
    onClickExit: () => void;
    values: AddressInfo;
    setValue: (field: AddressKeys, newValue: string) => void;
    style?: IObject;
    shrink?: boolean;
    withExitBtn?: boolean;
    useJoinedErrorsArr?: boolean;
    register?: Function;
    isStation?: boolean;
    showErrorMsg?: boolean;
    errors: {
        street?: string;
        houseNum?: string;
        city?: string;
        fullError?: string;
    };
};

interface Props {
    addressInfo: AddressInfo;
    autocompleteConfig: {
        props: AddressAutoCompleteProps;
    };
    manualConfig: {
        props: {
            values: AddressInfo;
            setValue: (field: AddressKeys, newValue: string) => void;
            style?: IObject;
            shrink?: boolean;
            withExitBtn?: boolean;
            useJoinedErrorsArr?: boolean;
            isStation?: boolean;
            showErrorMsg?: boolean;
            errors: {
                street?: string;
                houseNum?: string;
                city?: string;
                fullError?: string;
            };
        };
    };
    componentId: string;
    showErrorMsg?: boolean;
}

const useAddressData = (updateValue: (newData: AddressInput) => void) => {
    const setAddress = useCallback(
        (address: AddressInput | AutocompleteAddress): void => {
            const processedAddress = { ...address };
            if (!address.city && address.placeName) {
                processedAddress.city = address.placeName;
            }

            processedAddress.street = address.street || '';
            processedAddress.houseNum = address.houseNum || '';
            processedAddress.placeName = address.placeName || '';

            updateValue(processedAddress as AddressInput);
        },

        [updateValue]
    );

    const getAddressProps = useCallback(
        (
            currAddressData: AddressInput,
            componentId: string,
            inputProps?: {
                label?: string;
                errorMessage?: string;
            }
        ): Props => {
            return {
                componentId,
                addressInfo: currAddressData,
                autocompleteConfig: {
                    props: {
                        error: !!inputProps?.errorMessage,
                        setAddressData: (address: AutocompleteAddress) => setAddress(address),
                        label: inputProps?.label ? inputProps?.label : '',
                        errorMessage: inputProps?.errorMessage ? inputProps?.errorMessage : undefined,
                        useCoords: true,
                    },
                },
                manualConfig: {
                    props: {
                        isStation: false,
                        values: currAddressData,
                        setValue: (field: AddressKeys, value: string) => {
                            const newAddressData = {
                                ...currAddressData,
                                [field]: value,
                            };

                            setAddress(newAddressData);
                        },
                        withExitBtn: true,
                        errors: {},
                    },
                },
            };
        },
        [setAddress]
    );

    return { getAddressProps };
};

export const ToggleableAddressAutocompleteHooks = {
    useAddressData,
};

const Manual: React.FC<ManualAddressControlledProps> = ({
    onClickExit,
    style = defaultStyle,
    errors,
    values,
    setValue,
    showErrorMsg = true,
}) => {
    const handleChange = React.useCallback(
        (e: ReactChangeEvent, field: AddressKeys) => {
            setValue(field, e.target.value);
        },
        [setValue]
    );

    return (
        <div style={{}}>
            <InputWithErrorsWrapper errorMessage={showErrorMsg ? errors.fullError : undefined}>
                <div style={style}>
                    <InputWithErrorsWrapper errorMessage={errors.city} style={{ flex: 1.5 }}>
                        <CoreTextFieldV2
                            onChange={(e) => handleChange(e, 'city')}
                            error={!!errors.city}
                            value={values.city}
                        />
                    </InputWithErrorsWrapper>
                    <InputWithErrorsWrapper errorMessage={errors.street} style={{ flex: 3 }}>
                        <CoreTextFieldV2
                            value={values.street}
                            error={!!errors.street}
                            onChange={(e) => handleChange(e, 'street')}
                        />
                    </InputWithErrorsWrapper>
                    <InputWithErrorsWrapper errorMessage={errors.houseNum} style={{ flex: 1 }}>
                        <CoreTextFieldV2
                            value={values.houseNum}
                            error={!!errors.houseNum}
                            onChange={(e) => handleChange(e, 'houseNum')}
                        />
                    </InputWithErrorsWrapper>
                </div>
            </InputWithErrorsWrapper>
            <div style={{ textAlign: 'right' }}>
                <Button sx={{ color: colors.blue }} onClick={onClickExit}>
                    חפש כתובת ממאגר
                </Button>
            </div>
        </div>
    );
};

const addressHasValues = (address: AddressInfo): boolean => {
    return address.street || address.city || address.houseNum ? true : false;
};

const ToggleableAddressAutocomplete = ({
    addressInfo,
    autocompleteConfig,
    showErrorMsg,
    componentId,
    manualConfig,
}: Props) => {
    const [usingAutocomplete, setUsingAutocomplete] = React.useState(
        addressHasValues(addressInfo) ? false : true
    );

    const toggleAutoComplete = (newStatus: Status) => {
        setUsingAutocomplete(newStatus === Status.On ? true : false);
    };

    const autocompleteProps = { ...autocompleteConfig.props, showErrorMsg };

    return (
        <cs.FullWidth>
            {usingAutocomplete ? (
                <cs.FullWidth>
                    <AddressAutoComplete {...autocompleteProps} />
                    <WithManualInputBtn
                        usingManualInput={!usingAutocomplete}
                        setUsingManualInput={(val: boolean) =>
                            toggleAutoComplete(val ? Status.Off : Status.On)
                        }
                    />
                </cs.FullWidth>
            ) : (
                <Manual
                    {...manualConfig.props}
                    onClickExit={() => {
                        autocompleteConfig.props.setAddressData({
                            ...getInitialAddressInfo(),
                            id: componentId,
                        });
                        toggleAutoComplete(Status.On);
                    }}
                    values={addressInfo}
                    showErrorMsg={showErrorMsg}
                />
            )}
        </cs.FullWidth>
    );
};

export default ToggleableAddressAutocomplete;
