/* eslint-disable react/button-has-type */
import React, { FC, useState } from 'react';
import {
    setMapIsOnEditModeAction,
    setUpdatedCoordsOnMapAction,
    setNewCenterAction,
    triggerFieldCoordsUpdateCountAction,
    uiSelector,
    setIsLocationChangedAction,
    setIsConfirmLocationButtonAction,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';

import { MapWindow } from 'src/components/Map/MapType2/MapType2';
import { Button } from '@mui/material';
import { FromBtn } from 'src/screens/RideSettings/components/widgets/CancelAndApproveBtns';
import { useRootAppSelector } from 'src/store/hooks';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import useTimesDisplayAnimation, { AnimatedBtnContainer } from './useTimesDisplayAnimation';
import useCommons from 'src/hooks/common/useCommons';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';

const editWindowStyle = {
    bottom: '10px',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '400px',
    justifyContent: 'center',
} as const;

export const EditInstructionsWindow: React.FC<{}> = () => {
    const { t, dispatch } = useCommons();

    return (
        <MapWindow style={editWindowStyle}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <p style={{ fontSize: '18px', paddingRight: '10px' }}>{t('dragMarkerToDesiredLocation')}</p>
                <Button
                    onClick={() => {
                        dispatch(
                            setMapIsOnEditModeAction({
                                isOnEditMode: false,
                                editedStationId: null,
                            })
                        );

                        dispatch(setUpdatedCoordsOnMapAction(null));
                    }}
                    sx={{
                        fontSize: '18px',
                    }}
                >
                    {t('cancel')}
                </Button>
            </div>
        </MapWindow>
    );
};

export const ConfirmationWindow: React.FC<{}> = () => {
    const { t, dispatch } = useCommons();

    const { updatedCoords } = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.map);

    return (
        <MapWindow style={editWindowStyle}>
            <div style={{ display: 'flex', gap: '16px', width: '250px' }}>
                <FromBtn
                    text={t('confirmLocation')}
                    btnProps={{
                        onClick: () => {
                            dispatch(triggerFieldCoordsUpdateCountAction());
                            if (updatedCoords) {
                                dispatch(setIsLocationChangedAction({ isLocationChanged: true }));
                                dispatch(setIsConfirmLocationButtonAction({ isConfirmLocationButton: true }));

                                dispatch(setNewCenterAction({ newCoords: updatedCoords }));
                            }
                        },
                        fullWidth: true,
                    }}
                    type="confirm"
                />
                <FromBtn
                    text={t('cancel')}
                    btnProps={{
                        onClick: () => {
                            dispatch(
                                setMapIsOnEditModeAction({
                                    isOnEditMode: false,
                                    editedStationId: null,
                                })
                            );

                            dispatch(setUpdatedCoordsOnMapAction(null));
                        },
                        fullWidth: true,
                    }}
                    type="cancel"
                />
            </div>
        </MapWindow>
    );
};

export const DurationDisplayWindow: React.FC<{ totalTime: number }> = ({ totalTime }) => {
    const { t } = useCommons();

    const animate = useTimesDisplayAnimation(totalTime);

    return (
        <MapWindow style={{ top: '10px', right: '10px', alignItems: 'center' }}>
            <AnimatedBtnContainer animate={animate}>
                <DirectionsBusIcon htmlColor="white" />
                <span>{`${totalTime} ${t('minutes')}`}</span>
            </AnimatedBtnContainer>
        </MapWindow>
    );
};

const distanceDisplayStyles = {
    bottomDisplay: {
        top: '56px',
        right: '10px',
        alignItems: 'center',
    },
    leftDisplay: {
        top: '10px',
        right: '120px',
        alignItems: 'center',
    },
} as const;

export const DistanceDisplay: React.FC<{ isMapExpanded: boolean; totalDistance: number | null }> = ({
    isMapExpanded,
    totalDistance,
}) => {
    const { t } = useCommons();

    const [animate, setAnimate] = useState(false);

    // animate every time totalDistance changes
    React.useEffect(() => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 1500);
    }, [totalDistance]);

    if (!totalDistance) return null;

    return (
        <MapWindow
            style={isMapExpanded ? distanceDisplayStyles.leftDisplay : distanceDisplayStyles.bottomDisplay}
        >
            <AnimatedBtnContainer animate={animate}>
                <StraightenOutlinedIcon htmlColor="white" />
                <span>{`${totalDistance} ${t('km')}`}</span>
            </AnimatedBtnContainer>
        </MapWindow>
    );
};
