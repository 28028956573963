import { useTranslation } from 'react-i18next';
import React from 'react';
import { CSSObject } from 'styled-components';

interface Props {
    show?: boolean;
    style?: CSSObject;
}

const ErrorFetchingDataText = ({ show = true, style = {} }: Props) => {
    const { t } = useTranslation();
    return show ? <p style={style}>{t('errorFetchingData')}</p> : <></>;
};

export default ErrorFetchingDataText;
