/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function MyCalenderIcon({ width = '20', height = '20', color = 'rgba(0, 0, 0, 0.54)' }) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.3333 1.68H15V0H13.3333V1.68H6.66667V0H5V1.68H1.66667C0.75 1.68 0 2.436 0 3.36V18.32C0 19.244 0.75 20 1.66667 20H18.3333C19.25 20 20 19.244 20 18.32V3.36C20 2.436 19.25 1.68 18.3333 1.68ZM18.3333 18.32H1.66667V8.4H18.3333V18.32ZM18.3333 6.72H1.66667V3.36H5V5.04H6.66667V3.36H13.3333V5.04H15V3.36H18.3333V6.72Z"
                fill="#494949"
            />
        </svg>
    );
}

export default MyCalenderIcon;
