import React, { FC } from 'react';

import WidgetFormWrapper from 'src/components/Wrappers/Widgets/WidgetFormWrapper/WidgetFormWrapper';
import useCommons from 'src/hooks/common/useCommons';
import { resetCourseFormAction } from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import FormHeader from 'src/components/formComponents/FormHeader';
import useFormHeader from '../../hooks/formHooks/useFormHeader';
import CourseFormBody from './CourseFormBody/CourseFormBody';
import { FormProvider } from 'react-hook-form';
import useGetLineTypesFromServer from '../../hooks/formHooks/useGetLineTypesFromServer';
import courseFormHooks from './courseFormHooks';
import { useRootAppSelector } from 'src/store/hooks';

interface Props {}

const CourseForm: FC<Props> = React.forwardRef((props, ref) => {
    const { dispatch, t } = useCommons();

    const { submitBtnState, onSubmit, ...methods } = courseFormHooks.useReactHookForm();
    const courseCode = useRootAppSelector(
        (state) => state.coursesBuildingSlice.ui.courseBuildingForm.courseCode
    );

    courseFormHooks.useFetchTimesSettingsFromApi();

    useGetLineTypesFromServer();

    const onCancel = () => {
        dispatch(resetCourseFormAction({}));
    };

    const headerTexts = useFormHeader();

    return (
        <FormProvider {...methods as any}>
            <div ref={ref as React.RefObject<HTMLDivElement>} style={{ height: '100%', paddingTop: '30px' }}>
                <WidgetFormWrapper
                    btnsConfig={{
                        onSubmit,
                        onCancel,
                        disabled: submitBtnState.submitDisabled,
                        isSubmitting: submitBtnState.submitLoading,
                        withLoader: true,
                        submitText: courseCode ? t('update') : t('save'),
                    }}
                    HeaderComponent={() =>
                        FormHeader({
                            mainText: headerTexts.mainText,
                            secondaryText: headerTexts.secondaryText,
                        })
                    }
                >
                    <CourseFormBody />
                </WidgetFormWrapper>
            </div>
        </FormProvider>
    );
});

export default CourseForm;
