import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

const Spinner: React.FunctionComponent<{
    containerStyle?: React.CSSProperties;
    show?: boolean;
}> = ({ containerStyle, show = true }) => {
    return show ? (
        <div
            style={{
                overflow: 'hidden',
                ...containerStyle,
            }}
        >
            <CircularProgress
                sx={{
                    color: 'gray',
                }}
            />
        </div>
    ) : (
        <></>
    );
};

export default Spinner;
