import { InputAdornment, TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { AddressAutoCompleteProps } from 'src/components/AddressInputs/ManualAndGoogleAddressInput/types.ManualAddressControlled';
import AddressAutoComplete from 'src/components/Inputs/AddressAutoComplete';
import NakedBtn from 'src/components/buttons/NakedBtn';
import useCommons from 'src/hooks/common/useCommons';
import {
    LocationSelectionBtn,
    RemarkInput,
} from 'src/screens/CoursesBuilding/components/CourseForm/CourseFormBody/StationsPanel/StationInputBox';
import { IOptimizationFormWaypoint } from '../api/types';
import { useRootAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setCenterMapOnEditAction,
    setIsFormDirtyAction,
    setIsMapEditModeAction,
    setLastWaypointEditedIndexAction,
    updateFormWaypointAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import DisplayWrapper from 'src/components/Wrappers/DisplayWrapper';
import { DeleteBtn } from 'src/screens/Main/components/AddLineForm/components/formSections/components/stations/PassengersAccordionForStation/PassengerRowActions/PassengerRowActions';
import { useValidations } from '../hooks/useValidations';
import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import { buildWaypointName } from '../helpers';
import { asTSR } from 'src/utilis/utilis';
import { colors } from 'src/style/themes/defaultTheme';
import { width } from '@mui/system';
import SportsScoreIcon from '@mui/icons-material/SportsScore';

type Props = {
    waypoint: IOptimizationFormWaypoint;
};
// ^ INDEX
const OptimizationWaypointInputBox: React.FC<Props> = ({ waypoint }) => {
    const { t, dispatch } = useCommons();

    const formWaypointsList = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypoints
    );
    const errorMsg = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.form.waypointsErrors[waypoint.localId]
    );
    const isMapEditMode = useRootAppSelector((state) => optimizationsRootSelector(state).ui.isMapEditMode);
    const { waypointOnMapEditMode, triggerInputByCoords, isEditForm, lastWaypointEditedIndex } =
        useRootAppSelector((state) => optimizationsRootSelector(state).ui.form);
    const [inputText, setInputText] = useState('');

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    useValidations();

    const setAddressData = (newVal: IOptimizationFormWaypoint) => {
        const formWaypointsListCopy = [...formWaypointsList];

        const index = formWaypointsList.findIndex(
            (formWaypoint) => waypoint.localId === formWaypoint.localId
        );

        if (index !== -1) {
            if (newVal.lat !== 0 && newVal.lng !== 0) {
                formWaypointsListCopy[index] = {
                    ...formWaypointsListCopy[index],
                    city: newVal.city,
                    houseNum: newVal.houseNum,
                    placeName: newVal.placeName,
                    lat: newVal.lat,
                    lng: newVal.lng,
                    street: newVal.street,
                    localId: waypoint.localId,
                };

                dispatch(updateFormWaypointAction({ waypoints: formWaypointsListCopy }));
                dispatch(setLastWaypointEditedIndexAction({ lastWaypointEditedIndex: index }));
                dispatch(setIsMapEditModeAction({ isMapEditMode: false, waypointOnEditMode: null }));
                dispatch(setIsFormDirtyAction({ isDirty: true }));
            }
        }
    };

    const removeWaypoint = () => {
        const index = formWaypointsList.findIndex(
            (formWaypoint) => waypoint.localId === formWaypoint.localId
        );
        const formWaypointsListCopy = [...formWaypointsList];

        if (index !== -1) {
            formWaypointsListCopy.splice(index, 1);
            dispatch(updateFormWaypointAction({ waypoints: formWaypointsListCopy }));

            if (index === formWaypointsList.length - 1) {
                setInputText(buildWaypointName(formWaypointsListCopy[index - 1]));
            } else {
                setInputText(buildWaypointName(formWaypointsListCopy[index]));
            }

            dispatch(setIsFormDirtyAction({ isDirty: true }));
        }
    };

    const handleEdit = () => {
        dispatch(setIsMapEditModeAction({ isMapEditMode: true, waypointOnEditMode: waypoint }));

        if (!waypoint.lat && !waypoint.lng) {
            dispatch(setCenterMapOnEditAction({ lat: defaultCenter.lat, lng: defaultCenter.lng }));
        } else if (waypoint.lat && waypoint.lng) {
            dispatch(setCenterMapOnEditAction({ lat: waypoint.lat, lng: waypoint.lng }));
        }
    };

    useEffect(() => {
        console.log(
            'waypoint',
            waypoint,
            'formWaypoint',
            formWaypointsList,
            'lastWaypointEditedIndex',
            lastWaypointEditedIndex
        );

        const index = formWaypointsList.findIndex(
            (formWaypoint) => waypoint.localId === formWaypoint.localId
        );

        setInputText(buildWaypointName(formWaypointsList[index]));
    }, [triggerInputByCoords]);

    useEffect(() => {
        const formWaypointsCopy = [...formWaypointsList];

        const index = formWaypointsList.findIndex(
            (formWaypoint) => waypoint.localId === formWaypoint.localId
        );

        if (index !== -1) {
            formWaypointsCopy[index] = {
                ...formWaypointsCopy[index],
                city: inputText,
                street: '',
                houseNum: '',
            };

            dispatch(updateFormWaypointAction({ waypoints: formWaypointsCopy }));
        }
    }, [inputText]);

    const handleFieldChange = (fieldName: string, limit: number) => (ev: { target: { value: any } }) => {
        if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value.length <= limit) {
            const formWaypointsCopy = [...formWaypointsList];

            const index = formWaypointsList.findIndex(
                (formWaypoint) => waypoint.localId === formWaypoint.localId
            );

            if (index !== -1) {
                formWaypointsCopy[index] = { ...formWaypointsCopy[index], [fieldName]: ev.target.value };

                dispatch(updateFormWaypointAction({ waypoints: formWaypointsCopy }));
                dispatch(setIsFormDirtyAction({ isDirty: true }));
            }
        }
    };

    const props: AddressAutoCompleteProps = {
        setAddressData,
        error: false,
        showErrorMsg: false,
        useCoords: true,
        freeSolo: true,
        inputTextControl: {
            inputText,
            setInputText,
        },
        textFieldProps: {
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <LocationSelectionBtn
                            errorMsg={errorMsg}
                            isOnEditMode={isMapEditMode}
                            handleEdit={handleEdit}
                        />
                    </InputAdornment>
                ),
            },
        },
    };

    return (
        <div
            style={{
                marginBottom: '24px',
                width: '95%',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <strong>{t('waypoint')}</strong>
            <span style={{ color: colors.muiRed, paddingRight: '10px' }}>
                {errorMsg ? t(asTSR(errorMsg)) : ''}
            </span>
            <div
                style={{
                    backgroundColor: '#ffffff',
                    padding: '10px 32px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                    border: !errorMsg ? '1px solid #8080804f' : '1px solid red',
                    position: 'relative',
                }}
            >
                <div style={{ display: 'flex', gap: '15px', marginBottom: '10px', paddingLeft: '20px' }}>
                    <TextField
                        required
                        value={waypoint.passId ? waypoint.passId : ''}
                        variant="outlined"
                        size="small"
                        label="קוד נוסע"
                        onChange={handleFieldChange('passId', 20)}
                    />
                    <TextField
                        value={waypoint.firstName ? waypoint.firstName : ''}
                        variant="outlined"
                        size="small"
                        label="שם פרטי"
                        onChange={handleFieldChange('firstName', 60)}
                    />
                    <TextField
                        value={waypoint.lastName ? waypoint.lastName : ''}
                        variant="outlined"
                        size="small"
                        label="שם משפחה"
                        onChange={handleFieldChange('lastName', 60)}
                    />
                </div>

                <div style={{ paddingLeft: '20px' }}>
                    <AddressAutoComplete {...props} />
                </div>

                <div style={{ paddingLeft: '20px' }}>
                    <RemarkInput
                        value={waypoint.remarks}
                        onChange={handleFieldChange('remarks', 100)}
                        placeholder={t('typeRemarkToWaypoint')}
                    />
                </div>
                <DisplayWrapper show style={{ position: 'absolute', left: '-20px', top: '-15px' }}>
                    {formWaypointsList.length !== 1 && !isEditForm && (
                        <DeleteBtn
                            onClick={() => {
                                removeWaypoint();
                            }}
                        />
                    )}
                </DisplayWrapper>
            </div>
        </div>
    );
};

export default OptimizationWaypointInputBox;
