import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { DEFAULT_DATA_KEY, responseValidators, isWsResponse } from 'src/api/utilis';
import { ErrorMessagesOld } from 'src/store/type';
import { Result, WsResponse } from 'src/types/apiCommon.types';
import { CoursesBuildingApiMappers } from 'src/types/coursesBuilding/apiTypes';
import { CommonCodes } from 'src/types/mockServer/routes.types';

const getErrorResult = <P>(result: Result<P>, error: unknown, errorMessage: string, errorCode: number) => {
    console.log(error);

    const resultCopy = _.cloneDeep(result);
    resultCopy.code = errorCode;
    resultCopy.data.data = null;
    resultCopy.data.message = errorMessage;

    return resultCopy;
};

const getResult = <P>(
    httpRes: AxiosResponse<any>,
    result: Result<P>,
    options: {
        dataKey?: string;
        dataParser?: null | ((args: any) => P);
    } = {
            dataKey: DEFAULT_DATA_KEY,
            dataParser: null,
        }
) => {
    const resultCopy = _.cloneDeep(result);

    const { dataKey = DEFAULT_DATA_KEY, dataParser } = options;

    // Success
    if (responseValidators.isOk(httpRes)) {
        resultCopy.code = CommonCodes.Ok;
        resultCopy.data.data = dataParser ? dataParser(httpRes.data[dataKey]) : httpRes.data[dataKey];

        return resultCopy;
    }

    // Error handlings
    if (responseValidators.isMissingWs(httpRes)) {
        resultCopy.code = CommonCodes.MissingWs;
        resultCopy.data.data = null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultCopy.data.message = CoursesBuildingApiMappers.errorMessagesByCode[resultCopy.code];

        return resultCopy;
    }

    if (responseValidators.isBadToken(httpRes)) {
        resultCopy.code = CommonCodes.BadToken;
        resultCopy.data.data = null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultCopy.data.message = CoursesBuildingApiMappers.errorMessagesByCode[resultCopy.code];

        return resultCopy;
    }

    if (isWsResponse(httpRes)) {
        resultCopy.code = Number(httpRes.data.response);
        resultCopy.data.data = null;
        resultCopy.data.message = ErrorMessagesOld.GeneralError;

        return resultCopy;
    }

    throw new Error('GeneralBadHttpResponse');
};

const responseHandlers = {
    getResult,
    getErrorResult,
};
export default responseHandlers;
