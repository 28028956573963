import { GetFileResult } from 'src/mockServer/routes/uploadFileRoutes';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { UploadFileMappers } from 'src/types/uploadFileFt/uploadFileApiTypes';

// & Helpers
const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

// function to test object if is valid file
function isFile(file: any): file is File {
    return file && 'name' in file && 'size' in file && 'type' in file;
}

function isValidParams(obj: any) {
    // all object values are true
    return Object.values(obj).every((x) => x);
}

// is valid base64 string
function isBase64(str: unknown): str is string {
    try {
        return typeof str === 'string' && btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

// is of type GetFileResult
function isGetFileResult(response: any): response is GetFileResult {
    return (
        response !== -1 &&
        response &&
        'code' in response &&
        'data' in response &&
        'FileName' in response.data.data &&
        'FileLink' in response.data.data &&
        'ExpirationByMinutes' in response.data.data
    );
}

const isApiReqSuccessful = (response: any): boolean => {
    return response !== -1 && 'code' in response && response.code === CommonCodes.Ok;
};

// get string after substring
const getStrAfterSubstr = (str: string, substr: string) => {
    const index = str.indexOf(substr);
    if (index === -1) return str;
    return str.slice(index + substr.length);
};

const RESULT_BASE_64_PREFIX = ';base64,';

const getBase64StrFile = async (file: File) => {
    const base64Res = await toBase64(file);

    if (typeof base64Res === 'string') {
        const fileAsBase64 = getStrAfterSubstr(base64Res, RESULT_BASE_64_PREFIX);
        return fileAsBase64;
    }

    return -1;
};

// get error message by code or default message
const getErrorMessageByCode = (code: number | undefined | null, defaultMessage: string) => {
    if (code === undefined || code === null) return defaultMessage;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = UploadFileMappers.errorMessagesByCode[code];

    if (errorMessage) return errorMessage;

    return defaultMessage;
};

export type FileTypes = 'pdf' | 'xlsx';

const getFileTypeByFileName = (fileName: string): FileTypes | null => {
    try {
        const VALID_FILE_TYPES: FileTypes[] = ['pdf', 'xlsx'];
        const fileNameParts: any[] = fileName.split('.');

        return VALID_FILE_TYPES.includes(fileNameParts[fileNameParts.length - 1])
            ? (fileNameParts[fileNameParts.length - 1] as FileTypes)
            : null;
    } catch (error) {
        return null;
    }
};

const createGenericFileName = (oldName: string) => {
    const PREFIX = 'upload';

    try {
        // get part of file after last dot
        const fileExtension = oldName.split('.').pop();

        return `${PREFIX}.${fileExtension}`;
    } catch (error) {
        return `${PREFIX}-unknownType`;
    }
};

const uploadFileHelpers = {
    toBase64,
    isFile,
    isValidParams,
    isBase64,
    isGetFileResult,
    isApiReqSuccessful,
    getStrAfterSubstr,
    getBase64StrFile,
    getErrorMessageByCode,
    getFileTypeByFileName,
    createGenericFileName,
};

export default uploadFileHelpers;
