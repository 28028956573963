import { ReqConfig, AxiosRes } from 'src/types/apiCommon.types';
import { makeApiCallV2 } from '../utilis';

// WS -- ws_MyWayStudio_Get_LineTypes ---------------------------------------------------------------------------
enum ResponseCodes {
    Success = '0',
    InvalidToken = '1',
    SQLError = '99',
}

interface Params {
    token: string;
}

interface IDataItem {
    lineTypeCode: number;
    lineType: string;
}

type RequestConfig = ReqConfig<Params>;

interface WsResponse {
    response: ResponseCodes;
    data: IDataItem[]; // if sql error - will be string
}

// FC REQUEST

export const getLineTypesWs = (requestConfig: RequestConfig): AxiosRes<WsResponse> =>
    makeApiCallV2<Params, WsResponse>({
        ...requestConfig,
        endpoint: 'ws_MyWayStudio_Get_LineTypes',
    });
