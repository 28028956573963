/* eslint-disable react/prop-types */
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { iconButtonStyle } from '../styles/styles';
import MyEditPencilIcon from '../Icons/MyEditPencil';
import { useTranslation } from 'react-i18next';
import { MuiTooltipV3 } from '../MuiTooltip/MuiTooltip';

interface Props {
    onEdit: any;
    dataItem?: any;
}
// const size = '30px';
// const style = { ...iconButtonStyle, width: size, height: size };
const EditPassengerBtn: React.FC<Props> = ({ onEdit, dataItem }) => {
    const { t } = useTranslation();

    return (
        <MuiTooltipV3 title={t('edit')}>
            <IconButton
                onClick={() => typeof onEdit === 'function' && onEdit(dataItem)}
                style={iconButtonStyle}
                aria-label="עריכה"
                size="small"
            >
                <MyEditPencilIcon height="17px" width="17px" color="black" />
            </IconButton>
        </MuiTooltipV3>
    );
};

export default EditPassengerBtn;
