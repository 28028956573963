import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import * as Styles from '../Summarycard.Style';

const filters = [
    {
        value: 'נוסעים',
        id: 1,
    },
    {
        value: 'נסיעות',
        id: 2,
    },
];
const TypeOfSummaryDropdown: React.FunctionComponent<{
    t: Function;
    orderBy: unknown;
    handleChangeSelect: any;
}> = ({ t, orderBy, handleChangeSelect }) => {
    return (
        <FormControl variant="outlined">
            <InputLabel variant="outlined" id="summaryOrderBy-label">
                {t('summaryOrderBy')}
            </InputLabel>

            <Styles.Dropdown
                id="summaryOrderBy-label"
                defaultValue={1}
                label={t('summaryOrderBy')}
                value={orderBy}
                onChange={handleChangeSelect}
            >
                {filters.map((d) => (
                    <MenuItem value={d.id}>{d.value}</MenuItem>
                ))}
            </Styles.Dropdown>
        </FormControl>
    );
};

export default TypeOfSummaryDropdown;
