import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { colors, fontSizes } from 'src/style/themes/defaultTheme';
import { fontFamilies } from '../../../../../../style/themes/defaultTheme';

export const StyledSelect = styled(Select)({
    borderRadius: '4px',
    color: colors.blue,
    fontWeight: 'bold',
    fontFamily: fontFamilies.Rubik,
    fontSize: '20px',
    direction: 'ltr',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',

    // '& .MuiSvgIcon-root-MuiSelect-icon': {
    //    borderRight: `1px solid rgba(33, 150, 243, 0.5)`,
    //    color: colors.blue,
    // },
    '& .MuiSelect-icon': {
        borderRight: `1px solid rgba(33, 150, 243, 0.5)`,
        color: colors.blue,
    },
    '&	.MuiSelect-iconOpen': {
        borderLeft: `1px solid rgba(33, 150, 243, 0.5)`,
        borderRight: `none`,
        color: colors.blue,
    },
    '& .MuiInput-input': {
        padding: '5px 40px 7px 30px !important',
        textAlign: 'right',
    },
    '@media (max-width: 1780px)': {
        fontSize: '20px',
    },
    '@media (max-width: 1540px)': {
        fontSize: '16px',
    },
});

const icon: CSSProperties = {
    position: 'relative',
    right: '35px',
    top: '2px',
};

const container: CSSProperties = {
    width: '100%',
    color: colors.blue,
    display: 'flex',
    // justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    right: '-33px',
};

export const selectStyles = {
    container,
    icon,
    reportTypeItem: {
        fontFamily: fontFamilies.Rubik,

        borderBottom: '1px solid lightGray',
    },
    headline: {
        fontFamily: fontFamilies.Rubik,
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        pointerEvents: 'none',
        borderBottom: '1px solid lightGray',
        fontWeight: 'bold',
    },
};
