import React, { useCallback, useState } from 'react';
// import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store/hooks';
import { incrementApplyFiltersBtnClickCountAction } from 'src/store/slices/reports/reportsSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { colors } from '../../../../../../style/themes/defaultTheme';
import useDelay from '../../../../../../hooks/useDelay';

interface Props {}

const ApplyFiltersBtn = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { delay, setDelay, setDelayWithTimeout } = useDelay(false);
    const handleApplyFiltersClick = useCallback(() => {
        if (!delay) {
            dispatch(incrementApplyFiltersBtnClickCountAction());
            setDelay(true);
            setDelayWithTimeout(false, 1000);
        }
    }, [delay, dispatch, setDelay, setDelayWithTimeout]);

    return (
        <LoadingButton
            // style={formBtnStyle}
            onClick={handleApplyFiltersClick}
            type="button"
            fullWidth
            disabled={delay}
            sx={{
                boxShadow: 'none',
                backgroundColor: colors.blue,
                maxHeight: '40px',
            }}
            variant="contained"
            color="primary"
        >
            {t('applyFilters')}
        </LoadingButton>
    );
};

export default ApplyFiltersBtn;
