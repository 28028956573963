import React, { PropsWithChildren } from 'react';
import { ApiCallStatus } from 'src/types/apiCommon.types';
import Spinner from '../Spinner/Spinner';
import StyledDisabledDiv from './StyledDisabledDiv';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color: #ffffff;
    align-items: center;
    opacity: 0.4;
    pointer-events: none;
`;

const WrapperLoadingIndication: React.FC<PropsWithChildren<{ isLoading: boolean }>> = ({
    isLoading,
    children,
}) => {
    return (
        <div style={{ backgroundColor: 'white', borderRadius: '9px' }}>
            {isLoading ? (
                <div>
                    <LoaderContainer style={{ cursor: 'not-allowed' }}>{children}</LoaderContainer>
                    <div style={{ position: 'absolute', right: '43%', top: '60%' }}>
                        <Spinner />
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

export default WrapperLoadingIndication;
