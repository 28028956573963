import React, { useState, ReactChild, useRef, useEffect } from 'react';
import { ChangeEventValue, Coords, fitBounds, NESWBounds } from 'google-map-react';
import Map from 'src/components/Map/Map';

import { defaultCenter } from 'src/screens/Main/components/Lines/hooks/useIturanData';
import Tooltip from 'src/components/ToolTip/ToolTip';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useRootAppSelector } from 'src/store/hooks';
import {
    coursesBuildingSelectors,
    selectedCourseIdSelector,
    uiSelector,
} from 'src/store/slices/coursesBuilding/coursesBuildingSlice';
import { CircularProgress } from '@mui/material';
import { mapHelpers } from 'src/screens/CoursesBuilding/components/CourseForm/utils/common';
import {
    optimizationsRootSelector,
    setLastMapFittedBoundsAction,
} from 'src/store/slices/optimizations/optimizationsSlice';
import { updateGoogleApiCounter } from 'src/utilis/utilis';
import useCommons from 'src/hooks/common/useCommons';
import { useGoogleApiCounterUp } from 'src/screens/CoursesBuilding/hooks/useDirectionsApi';

const defaultZoom = 12;

export interface IMarker extends Coords {
    message: ReactChild | string;
    index: number;
    isDestination: boolean;
    isRegularMarker: boolean;
    isSelected: boolean;
    isTargetMarker: boolean;
}

export const Marker: React.FunctionComponent<IMarker> = ({
    message,
    index,
    isDestination,
    isRegularMarker,
    isSelected,
    isTargetMarker,
}: IMarker) => {
    const [isShown, setIsShown] = useState<boolean>(false);

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <Tooltip title={message} open={isShown} arrow placement="top">
                <div
                    style={
                        isTargetMarker
                            ? { position: 'absolute', right: '-20px', top: '-30px', zIndex: 20 }
                            : { position: 'absolute', right: '-20px', top: '-30px', zIndex: 10 }
                    }
                >
                    <img
                        src={mapHelpers.getIconUrl(
                            index,
                            false,
                            false,
                            isRegularMarker,
                            isSelected,
                            isTargetMarker
                        )}
                        alt="marker"
                        style={
                            isTargetMarker
                                ? { width: '40px', height: '40px' }
                                : { width: '30px', height: '30px' }
                        }
                    />
                </div>
            </Tooltip>
        </div>
    );
};

const MapSpinner: React.FC<Coords> = (props) => {
    return (
        <div style={{ position: 'relative', left: '25px', bottom: '20px' }}>
            <CircularProgress
                sx={{
                    color: '#2196F3',
                }}
            />
        </div>
    );
};

export const isValidCoords = (coords: Coords): boolean => {
    return coords.lat && coords.lng ? true : false;
};

const OptimizationWidgetMap: React.FunctionComponent<{}> = () => {
    const allWaypoints = useRootAppSelector(
        (state) => optimizationsRootSelector(state).data.getWaypointsFromServer.data
    );
    const waypointsSelectedIds = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsSelectedIds
    );

    const lastWaypointSelected = useRootAppSelector(
        (state) => optimizationsRootSelector(state).ui.lastSelected
    );
    const { t, dispatch } = useCommons();
    const googleCounterUp = useGoogleApiCounterUp();

    const myRef = useRef<HTMLInputElement | null>(null);

    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>({ lat: 31.412177814285574, lng: 34.8404886515807 });

    useEffect(() => {
        if (myRef.current) {
            const rect = myRef.current.getBoundingClientRect();
            console.log(rect.width, rect.height);
        }
    }, []);

    // if stations are loaded, setCenter to be the lat and lng of the middle station.
    React.useEffect(() => {
        if (allWaypoints && allWaypoints.length > 1) {
            const waypointsWithValidCoords = allWaypoints.find((waypoint) =>
                isValidCoords({ lat: waypoint.lat, lng: waypoint.lng })
            );
        }
    }, [allWaypoints]);

    // Gets Center And ZoomTo One or Several Waypoints

    React.useEffect(() => {
        const selectedWaypointsWithCoords = allWaypoints.filter(
            (waypoint) =>
                waypointsSelectedIds.includes(waypoint.waypointId) && waypoint.lat !== 0 && waypoint.lng !== 0
        );

        if (selectedWaypointsWithCoords.length === 1) {
            setCenter({ lat: selectedWaypointsWithCoords[0].lat, lng: selectedWaypointsWithCoords[0].lng });
            setZoom(18);
        }

        if (selectedWaypointsWithCoords.length > 1 && myRef.current) {
            // Calculate the bounding box of the selected waypoints

            const bounds = selectedWaypointsWithCoords.reduce((acc, { lat, lng }) => {
                const latLng = new google.maps.LatLng(lat, lng);
                const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                return acc.extend(coords);
            }, new google.maps.LatLngBounds());

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const neCoords: Coords = {
                lat: ne.lat(),
                lng: ne.lng(),
            };

            const swCoords: Coords = {
                lat: sw.lat(),
                lng: sw.lng(),
            };

            const rect = myRef.current.getBoundingClientRect();

            const fittedBounds = fitBounds(
                { ne: neCoords, sw: swCoords },
                { width: rect.width, height: rect.height }
            );

            setCenter(fittedBounds.center);
            setZoom(fittedBounds.zoom);
            dispatch(setLastMapFittedBoundsAction({ bounds: fittedBounds }));
        }
    }, [waypointsSelectedIds]);

    React.useEffect(() => {
        googleCounterUp.googleApiCounterUp();
    }, []);

    // * Event handlers

    const onMapChange = (e: ChangeEventValue) => {
        setCenter(e.center);
        setZoom(e.zoom);
    };

    const isFormOpen = useRootAppSelector((state) => uiSelector(state).courseBuildingForm.isOpen);

    if (isFormOpen) return null;

    return (
        <>
            <div
                ref={myRef}
                style={{
                    borderRadius: '15px',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Map
                    yesIWantToUseGoogleMapApiInternals
                    center={center}
                    zoom={zoom}
                    defaultCenter={defaultCenter}
                    onChange={onMapChange}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '15px',
                    }}
                >
                    {allWaypoints
                        ? allWaypoints.map((waypoint, i) =>
                              isValidCoords({ lat: waypoint.lat, lng: waypoint.lng }) ? (
                                  <Marker
                                      index={i}
                                      isRegularMarker
                                      isDestination={false}
                                      isSelected={waypointsSelectedIds.includes(waypoint.waypointId)}
                                      isTargetMarker={waypoint.isTarget ? waypoint.isTarget : false}
                                      key={waypoint.waypointId}
                                      lat={waypoint.lat}
                                      lng={waypoint.lng}
                                      message={`${i + 1} - ${waypoint.city} ${waypoint.street} ${
                                          waypoint.houseNum
                                      }`}
                                  />
                              ) : (
                                  <></>
                              )
                          )
                        : null}
                </Map>
            </div>
        </>
    );
};

export default OptimizationWidgetMap;
