/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles, { PaddedAccordionContainer } from 'src/screens/RideSettings/styles/styledComponents';
import { SettingTypes, SlSettingTypes } from 'src/types/rideSettings/store/types.store';
import { largeRubik } from 'src/style/styledCSS';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Typography } from '@mui/material';

import { getAsTemplateStrArr } from 'src/utilis/utilis';
import { AccordionExpansion } from 'src/types/rideSettings/components/types.screen';
import GeneralSettingsAccordionBody from './AccordionsBodies/GeneralSettingsBody/GeneralSettingsAccordionBody';
import PermissionsManagementAccordionBody from './AccordionsBodies/PermissionsManagment/PermissionsManagementAccordionBody';
import { SettingsListStyles, StyledMui } from '../styles';

const getCorrectAccordionBody = (settingType: string): JSX.Element => {
    switch (settingType) {
        case SettingTypes.GeneralSettings:
            return <GeneralSettingsAccordionBody />;
        case SettingTypes.PermissionManagement:
            return <PermissionsManagementAccordionBody />;
        default:
            return <></>;
    }
};

interface Props {
    settingType: SlSettingTypes;
    disabled: boolean;
    accordionExpansion: AccordionExpansion;
    isAccordion?: boolean;
}

const SettingAccordion: FC<Props> = ({ disabled, settingType, accordionExpansion, isAccordion = false }) => {
    const { t } = useTranslation();

    const isExpanded = React.useMemo(() => {
        return accordionExpansion.isExpanded(settingType);
    }, [accordionExpansion.isExpanded, settingType]);

    const accordionSummaryProps = {
        disabled,
        expandIcon: <ExpandMoreIcon />,
        'aria-controls': 'panel1a-content',
        id: 'panel1a-header',
    };

    const accordionSx = {
        ':hover': {
            backgroundColor: isExpanded ? '#ffedb1' : '#eeeeee',
        },
    } as const;

    return (
        <PaddedAccordionContainer>
            {isAccordion ? (
                <StyledMui.Accordion
                    sx={isExpanded ? { ...accordionSx, backgroundColor: '#ffedb1' } : accordionSx}
                    expanded={isExpanded}
                    onChange={(e, open) =>
                        accordionExpansion.toggleExpansion(settingType, open ? 'open' : 'close')
                    }
                >
                    <AccordionSummary {...accordionSummaryProps}>
                        <div style={SettingsListStyles.jsStyles.typographyContainer}>
                            <Typography style={largeRubik}>{t(getAsTemplateStrArr(settingType))}</Typography>
                        </div>
                    </AccordionSummary>
                    <styles.AccordionSettingsContainer>
                        {getCorrectAccordionBody(settingType)}
                    </styles.AccordionSettingsContainer>
                </StyledMui.Accordion>
            ) : (
                <SettingsListStyles.SettingItem
                    className="SettingItem"
                    onClick={() => {
                        accordionExpansion.toggleExpansion(settingType, isExpanded ? 'close' : 'open');
                    }}
                    isExpanded={accordionExpansion.isExpanded(settingType)}
                >
                    <div style={SettingsListStyles.jsStyles.typographyContainer}>
                        <Typography style={largeRubik}>{t(getAsTemplateStrArr(settingType))}</Typography>
                    </div>
                </SettingsListStyles.SettingItem>
            )}
        </PaddedAccordionContainer>
    );
};

export default SettingAccordion;
