import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { useMemo, useCallback, useRef, useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import {
    isPrintingSelector,
    selectedReportNameSelector,
    selectedReportSelector,
} from 'src/store/selectores/reportsSelectors';
import { ReportNames } from 'src/store/slices/reports/types';
import PassengersReportRidesCountGrid from '../DataGrids/PassengersReportRidesCountGrid/PassengersReportRidesCountGrid';
import VisasReportPassengersGrid from '../DataGrids/VisasReportPassengersGrid/VisasReportPassengersGrid';

export const EXPANDABLE_REPORTS = ['visas', 'passengers'];

const useSelectors = () => {
    const reportName: ReportNames = useAppSelector((state: any) => selectedReportNameSelector(state));
    const reportData = useAppSelector(
        (state: unknown) => selectedReportSelector(state as IRootReducer).reportData
    );
    const isPrinting: boolean = useAppSelector((state: any) => isPrintingSelector(state));
    return { reportName, reportData, isPrinting };
};

export const getExpandKey = (reportName: ReportNames) => {
    switch (reportName) {
        case 'visas':
            return 'rideCode';

        case 'passengers':
            return 'passCode';
        default:
            return '';
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useExpandConfig = (
    processedData: any[],
    setProcessedData: {
        (value: React.SetStateAction<any[]>): void;
        (arg0: any): void;
    }
) => {
    const { isPrinting, reportData, reportName } = useSelectors();

    const sortedReportData = useAppSelector(
        (state: unknown) => selectedReportSelector(state as IRootReducer).sortedData
    );
    const isExpendable: boolean = useMemo(() => EXPANDABLE_REPORTS.includes(reportName), [reportName]);

    const getExpendableComponent = useCallback(() => {
        switch (reportName) {
            case 'visas':
                return VisasReportPassengersGrid;

            case 'passengers':
                return PassengersReportRidesCountGrid;
            default:
                break;
        }
    }, [reportName]);

    // * Event handlers
    const expandChange = (event: GridExpandChangeEvent, key: string) => {
        const newData = processedData.map((item: { expanded: boolean }) => {
            const itemCopy = { ...item };
            if (itemCopy[key] === event.dataItem[key]) {
                itemCopy.expanded = !event.dataItem.expanded;
            }
            return itemCopy;
        });
        setProcessedData(newData);
    };

    // -- is button on expand-all mode or condense-all mode
    const allExpandedRef = useRef(false);

    const toggleExpansionForAll = useCallback(() => {
        const allExpandedVal = allExpandedRef.current;
        const x = processedData.map((p) => {
            return {
                ...p,
                expanded: !allExpandedVal,
            };
        });
        setProcessedData(x);
        allExpandedRef.current = !allExpandedVal;
    }, [processedData, setProcessedData]);

    const showAllExpansionBtn = useMemo(() => {
        if (!isPrinting && isExpendable && processedData.length) {
            return true;
        }
        return false;
    }, [isExpendable, isPrinting, processedData.length]);

    // -- reset all-expansion button when report changes
    useEffect(() => {
        allExpandedRef.current = false;
    }, [sortedReportData, reportData, reportName]);

    return {
        getExpandKey,
        expandChange,
        getExpendableComponent,
        isExpendable,
        toggleExpansionForAll,
        allExpandedVal: allExpandedRef.current,
        showAllExpansionBtn,
    };
};

export default useExpandConfig;
