import React, { useState, createContext, FC, Dispatch, SetStateAction, useEffect } from 'react';
import { IPlacment, Shift } from './types';

export const initialManualOrderState = {
    pickup: {
        pickupTime: '',
        pickupShiftId: '',
    },
    drop: {
        dropTime: '',
        dropShiftId: '',
    },
};
export const initialAutoOrderState: Shift = {
    pickupTime: '',
    dropTime: '',
    shiftId: '',
};

export const initialState: IPlacment = {
    autoOrder: initialAutoOrderState,
    manualOrder: initialManualOrderState,
    dates: [],
    shiftsAlreadyOrdered: [],
};

export type ContextStateType = [IPlacment, Dispatch<SetStateAction<IPlacment>>];
export const PlacemenContext = createContext<ContextStateType>([initialState, () => {}]);

const PlacemenProvider: FC = ({ children }) => {
    const [state, setState] = useState<IPlacment>(initialState);
    const value: ContextStateType = [state, setState];

    useEffect(() => {
        if (state.dates.length > 1) {
            const datesWithoutInvalid = state.dates.filter((date) => {
                return date.relativeDate !== 'Invalid date';
            });
            if (datesWithoutInvalid.length !== state.dates.length) {
                setState((prev) => ({
                    ...prev,
                    dates: datesWithoutInvalid,
                }));
            }
        }
    }, [state.dates]);

    return <PlacemenContext.Provider value={value}>{children}</PlacemenContext.Provider>;
};

export default PlacemenProvider;
