import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

type Props = {};

export const useExport = (props: Props) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const tableRef = useRef<any>();

    const handleReactToPrint = useReactToPrint({
        content: () => tableRef.current,
        onAfterPrint: () => {
            setIsPrinting(false);
        },
    });

    return {
        tableRef,
        handlePrint: () => {
            setIsPrinting(true);

            setTimeout(() => handleReactToPrint(), 1);
        },
        isPrinting,
    };
};
