import _ from 'lodash';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IShifts } from 'src/types/manualOrders/api.types';
import { MenuItemProps } from 'src/components/DropDown/DropDown';
import { manualOrdersConstants } from 'src/constants/byScreen/manualOrders/components';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import {
    getInitialSortedShifts,
    getShiftById,
    isShiftItemDisabled,
    sortShiftsTimes,
} from 'src/screens/ManualOrder/utilis';
import { onChangedShiftSelection } from 'src/store/actions/PassengersShiftActionType';
import { IPlacment, PlacementDate, SortedShiftsTimes, Time } from '../../types';
import ManualShiftItemChild from './ManualShiftItemChild/ManualShiftItemChild';

export const useDidUserPickShift = () => {
    const { placment: placement } = usePlacement();

    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        // console.log('2');

        if (!placement.manualOrder.drop.dropShiftId && !placement.manualOrder.pickup.pickupShiftId) {
            setIsValid(false);
            return;
        }
        setIsValid(true);
    }, [placement.manualOrder.drop.dropShiftId, placement.manualOrder.pickup.pickupShiftId]);

    return isValid;
};

export const useSortedShiftTimes = (shifts: IShifts[]) => {
    const { placment: placement } = usePlacement();

    const unsortedShiftTimes = getInitialSortedShifts(shifts, placement.manualOrder.pickup.pickupTime);

    const { dropTimes, pickupTimes } = sortShiftsTimes(unsortedShiftTimes);

    return { dropTimes, pickupTimes };
};

export const useRemoveShiftFromPlacementIfDisabled = (
    shifts: IShifts[],
    distinctShiftsTimes: {
        dropTimes: IShifts[];
        pickupTimes: IShifts[];
    }
) => {
    // -- getting unique shift times for shift time dropdown
    const { placment, removeManualShiftShift } = usePlacement();

    useEffect(() => {
        // console.log('1');
        // -- removing drop time from placement context if it was removed
        // -- from the form because its not included in the distinct shift time
        const { dropTimes, pickupTimes } = distinctShiftsTimes;
        if (!dropTimes.map((d) => d.dropTime).includes(placment.manualOrder.drop.dropTime)) {
            removeManualShiftShift('drop');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distinctShiftsTimes]);
};

const getMenuItemsForShiftType = (
    shifts: IShifts[],
    sortedShiftsTimes: SortedShiftsTimes,
    placementDates: PlacementDate[],
    isShiftOrdered: Function,
    type: Time,
    placementPickupTime: string
) => {
    // console.log({ placementDates });

    // -- getting disabled data for shift
    const getShiftDisplayConfig = (shift: IShifts) => {
        const isDisabled = isShiftItemDisabled(shift, placementDates, isShiftOrdered, shifts, {
            shiftType: 'manual',
            isPickup: true,
        });

        return { isDisabled };
    };

    // -- getting shifts times sorted in active vs disabled times
    const activeTimes: IShifts[] = [];
    const disabledTimes: IShifts[] = [];

    sortedShiftsTimes[`${type}s`]?.forEach((s: IShifts) => {
        const { isDisabled } = getShiftDisplayConfig(s);
        if (isDisabled) disabledTimes.push(s);
        else activeTimes.push(s);
    });

    // -- getting menu items
    // * util
    const getShiftName = (shift: { shiftId: any }): string | undefined => {
        return getShiftById(shifts, shift.shiftId || undefined)?.shiftName;
    };

    const getMenuItemsByType = (shiftsForFunc: IShifts[], timeType: Time, disabled: boolean) => {
        return shiftsForFunc.map((s: IShifts) => ({
            value: s.shiftId,
            name: `${s[timeType]} ${timeType === 'pickupTime' ? ` - ${getShiftName(s)}` : ''}` ?? '',
            disabled,
            child:
                timeType === 'dropTime' ? (
                    <ManualShiftItemChild
                        shift={s}
                        orderTimes={{
                            pickupTime: placementPickupTime,
                            dropTime: s.dropTime || '',
                        }}
                    />
                ) : undefined,
        }));
    };

    const activeMenuItems = getMenuItemsByType(activeTimes, type, false);
    activeMenuItems.unshift({
        value: -1,
        name: type === 'dropTime' ? 'ללא פיזור' : 'ללא איסוף',
        disabled: false,
        child: undefined,
    });
    const disabledMenuItems = getMenuItemsByType(disabledTimes, type, true);

    return { activeMenuItems, disabledMenuItems };
};

export const usePickupDropdownProps = (shifts: IShifts[], sortedShiftsTimes: SortedShiftsTimes) => {
    const { placment, isShiftOrdered, setPlacment } = usePlacement();
    const dispatch = useDispatch();
    // const removedShiftsTimes: any[] = [];
    const { activeMenuItems, disabledMenuItems } = useMemo(
        () =>
            getMenuItemsForShiftType(
                shifts,
                sortedShiftsTimes,
                placment.dates,
                isShiftOrdered,
                'pickupTime',
                placment.manualOrder.pickup.pickupTime
            ),
        [isShiftOrdered, placment.dates, placment.manualOrder.pickup.pickupTime, shifts, sortedShiftsTimes]
    );

    const pickups: MenuItemProps[] = [...activeMenuItems, ...disabledMenuItems];

    const onChangePickup = useCallback(
        (
            val: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ): void => {
            const { value } = val.target;
            const { EMPTY_SHIFT } = manualOrdersConstants;
            const selectShift =
                shifts.find((s) => s.shiftId === Number(value)) ||
                (value === EMPTY_SHIFT.shiftId ? EMPTY_SHIFT : null);

            if (!selectShift) {
                dispatch(
                    onChangedShiftSelection({
                        isShiftSelected: false,
                        selectedManualShift: null,
                    })
                );
                return;
            }
            const { pickupTime = '', shiftId } = selectShift || {};

            dispatch(
                onChangedShiftSelection({
                    isShiftSelected: true,
                    shiftId,
                    selectedManualShift: true,
                })
            );

            setPlacment((pre) => ({
                ...pre,
                manualOrder: {
                    ...pre.manualOrder,
                    pickup: {
                        pickupTime,
                        pickupShiftId: `${shiftId}`,
                    },
                },
            }));
        },
        [dispatch, setPlacment, shifts]
    );
    return { pickups, onChangePickup };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDropsDropdownProps = (shifts: IShifts[], sortedShiftsTimes: SortedShiftsTimes) => {
    const { placment, isShiftOrdered, setPlacment } = usePlacement();
    const dispatch = useDispatch();
    const { activeMenuItems, disabledMenuItems } = useMemo(
        () =>
            getMenuItemsForShiftType(
                shifts,
                sortedShiftsTimes,
                placment.dates,
                isShiftOrdered,
                'dropTime',
                placment.manualOrder.pickup.pickupTime
            ),
        [isShiftOrdered, placment.dates, placment.manualOrder.pickup.pickupTime, shifts, sortedShiftsTimes]
    );

    const drops: MenuItemProps[] = [...activeMenuItems, ...disabledMenuItems];

    const onChangeDrop = useCallback(
        (
            val: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ): void => {
            const { value } = val.target;
            const { EMPTY_SHIFT } = manualOrdersConstants;
            const selectShift =
                shifts.find((s) => s.shiftId === Number(value)) ||
                (value === EMPTY_SHIFT.shiftId ? EMPTY_SHIFT : null);

            if (!selectShift) return;

            const { dropTime = '', shiftId } = selectShift;
            dispatch(
                onChangedShiftSelection({
                    isShiftSelected: true,
                    selectedManualShift: true,
                    dropOffShiftId: shiftId,
                })
            );
            setPlacment((pre) => ({
                ...pre,
                manualOrder: {
                    ...pre.manualOrder,
                    drop: {
                        dropTime,
                        dropShiftId: `${shiftId}`,
                    },
                },
            }));
        },
        [dispatch, setPlacment, shifts]
    );

    return { drops, onChangeDrop };
};

export interface CurrManualOrderData {
    pickup: {
        shiftId: string;
        time: string;
    };
    drop: {
        shiftId: string;
        time: string;
    };
    dates: string[];
}
export const useSimplifiedManualOrderData = (): CurrManualOrderData => {
    const { placment: placement } = usePlacement();
    const {
        manualOrder: { pickup, drop },
        dates,
    } = placement;

    const result = useMemo(
        () => ({
            pickup: {
                shiftId: pickup.pickupShiftId,
                time: pickup.pickupTime,
            },
            drop: {
                shiftId: drop.dropShiftId,
                time: drop.dropTime,
            },
            dates: dates.map((d) => d.relativeDate.split(' ')[0]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dates, placement.manualOrder]
    );

    // console.log({ result });
    return result;
};
