import React, { useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReqStatus } from 'src/api/types';
import { updateToken, validateSms } from 'src/api/api';
import { initConnectSuccess } from 'src/store/actions/loginAction';
import { ILoginState } from 'src/store/type';
import { IAccount, ResPinCodeSignup } from 'src/types/login';
import Alert from 'src/components/commons/Alert/Alert';
import { getLocalStorageValue, setLocalStorageValue } from 'src/utilis/utilis';
import { StorageKeys } from 'src/types/global';

import styles from './InitialIdentification.style';
import Phone from './Phone/Phone';
import Code from './Code/Code';

interface Props {}

const InitialIdentification = (props: Props): JSX.Element => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onSetLoginData = (payload: ILoginState) => dispatch(initConnectSuccess(payload));
    const [errorAlert, setErrorAlert] = useState({
        isShow: false,
        text: '',
    });
    const [keyCode, setKeyCode] = useState<string>(uuidv4());
    const [isExpiredPinCode, setIsExpiredPinCode] = useState<boolean>(false);
    const [phone, setPhone] = useState('');
    const [isTypeCodeMode, setisTypeCodeMode] = useState(false);
    const decodePhone = useMemo(
        () => (phone.length === 10 ? phone.replace(phone.substring(3, 7), '****') : ''),
        [phone]
    );
    useEffect(() => {
        if (!getLocalStorageValue(StorageKeys.DeviceToken)) {
            const newDeviceToken = uuidv4();
            setLocalStorageValue(StorageKeys.DeviceToken, newDeviceToken);
        }
    }, []);

    useEffect(() => {
        if (isExpiredPinCode)
            setTimeout(() => {
                history.push('/');
            }, 3000);
    }, [isExpiredPinCode, history]);

    const onSendSmsSuccess = (phoneNumber: string) => {
        setPhone(phoneNumber);
        setisTypeCodeMode(true);
    };

    const onValidateSms = async (code: string) => {
        try {
            const res = await validateSms({
                userMobile: phone,
                verifyCode: code,
            });

            if (res.data.response === ResPinCodeSignup.SUCCESS) {
                const { data } = res.data;

                const promises: Array<Promise<void>> = [];
                const loginData: ILoginState = data[0] || {};
                const fcAccounts: IAccount[] = loginData.fcAccounts || [];
                const token: string = loginData.token || '';

                fcAccounts.forEach(({ proxyUrl, contactUUID, dbUrl }) => {
                    promises.push(updateToken({ token, proxyUrl, dbUrl, contactUUID }));
                });
                Promise.all(promises).then(() => {
                    onSetLoginData({
                        isAuthenticated: ReqStatus.SUCCESS,
                        ...data[0],
                    });
                    history.push('signup-options');
                });
            } else if (res.data.response === ResPinCodeSignup.WRONG_PIN_CODE) {
                setKeyCode(uuidv4());
                setErrorAlert({ isShow: true, text: t('wrongCode') });
            } else if (res.data.response === ResPinCodeSignup.WRONG_PIN_CODE_TO_MANY_TIMES) {
                setKeyCode(uuidv4());
                setErrorAlert({ isShow: true, text: t('wrongCodeToManyTimes') });
            } else if (res.data.response === ResPinCodeSignup.EXPIRED_TOKEN) {
                setKeyCode(uuidv4());
                setIsExpiredPinCode(true);
                setErrorAlert({ isShow: true, text: t('expiredPinCode') });
            }
        } catch (error) {
            setKeyCode(uuidv4());
            console.log('e', error);
        }
    };

    const handleCloseSnack = () => setErrorAlert((preState) => ({ ...preState, isShow: false }));

    const TitleComponent = (
        <>
            <styles.Text>{t('sendSmsForPhone')}</styles.Text>
            <styles.PhoneText>{decodePhone}</styles.PhoneText>
        </>
    );

    const onPhoneError = (errorMassage: string) => {
        setErrorAlert({ isShow: true, text: errorMassage });
    };

    const isAutoSubmit = true;

    return (
        <styles.SpaceAround>
            {isTypeCodeMode ? (
                <Code
                    key={keyCode}
                    isRefresh={errorAlert.isShow}
                    isAutoSubmit={isAutoSubmit}
                    Title={TitleComponent}
                    onSubmit={onValidateSms}
                    btnText={t('connect')}
                />
            ) : (
                <Phone onSubmit={onSendSmsSuccess} onError={onPhoneError} />
            )}
            <Alert open={errorAlert.isShow} onClose={handleCloseSnack} severity="error">
                {errorAlert.text}
            </Alert>
        </styles.SpaceAround>
    );
};

export default InitialIdentification;
